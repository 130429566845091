import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Tooltip } from 'react-tooltip';

import Routes from '../routes';
import ConnectionCheck from './ConnectionCheck';
import { persistor } from '../utils/store';

// import ActivityModal from '../modals/ActivityModal'
// import ModalMaster, {Modal} from '../containers/ModalMaster'

function Root({ store, history }) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectionCheck>
          <Routes store={store} history={history} />
          <Tooltip id="tooltip" className="react-tooltip" variant="light" border="1px solid #bababa" />
        </ConnectionCheck>
      </PersistGate>
    </Provider>
  );
}

Root.propTypes = {
  store: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default Root;
