import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { actions } from '../../SuperUser/Report/redux/actions';
import { PERMISSIONS } from '../../../utils/userPermissions';
import { chartTypeEnum } from '../../../components/Graphs/GenericCategoryGraph';
import { graphContainer } from './graphContainers';
import EsiPatientsDistributionGraph from '../../../components/Graphs/EsiPatientsDistributionGraph';

export const getEsiPatientsStateMetadata = (skipPremissionCheck, allowConfig) => {
  return {
    id: 'PatientsState',
    name: 'Patients State',
    permission: skipPremissionCheck ? undefined : PERMISSIONS.PATIENTS_ADHERENCE_ACTIVE,
    defProps: { i: 'PatientsState', w: 4, h: 4, minW: 2, minH: 2 },
    configuration: allowConfig
      ? {
          chartType: {
            type: 'select',
            name: 'chartType',
            label: 'Chart Type',
            defaultValue: chartTypeEnum.bar,
            options: [
              { label: 'Bar chart', value: chartTypeEnum.bar },
              { label: 'Area chart', value: chartTypeEnum.area },
              { label: 'Line chart', value: chartTypeEnum.line },
              { label: 'Pie chart', value: chartTypeEnum.pie },
            ],
            canBeSetByDashboard: false,
          },
        }
      : undefined,
  };
};

function EsiPatientsStats(props) {
  const [data, setData] = useState();

  React.useEffect(() => {
    let request = {
      start_time: moment()
        .subtract(1, 'month')
        .startOf('month')
        .format(),
      end_time: moment()
        .subtract(1, 'month')
        .endOf('month')
        .format(),
    };
    props.getEsiPatientsStats(request).then(resp => {
      setData(resp.response);
    });
  }, []);

  return graphContainer(
    <EsiPatientsDistributionGraph
      graphData={
        data
          ? [
              {
                status: 'Active Billable',
                count: data?.active_billable_patients || 0,
              },
              {
                status: 'Dormant Billable',
                count: data?.active_dormant_patients || 0,
              },
              {
                status: 'Inactive',
                count: data?.inactive_patients || 0,
              },
            ]
          : []
      }
      colorPalette={props.colorPalette}
      graphType={props.config?.chartType?.value || chartTypeEnum.bar}
    />,
    'ESI Patients State',
  );
}

EsiPatientsStats.propTypes = {
  getEsiPatientsStats: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getEsiPatientsStats: request => dispatch(actions.getEsiPatientStats(request)),
});

export default connect(null, mapDispatchToProps)(EsiPatientsStats);
