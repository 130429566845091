import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';

import { WizardForm } from '../../components/WizardForm/WizardForm';
import { TextInputWithLabelField } from '../../fields/TextInputWithLabelField';
import Strings from '../../Strings';
import { composeValidators } from '../../utils/validators/composeValidators';
import { makeRequired } from '../../utils/validators/isRequired';
import styles from '../forms.module.scss';
import { AutosuggestWithLabelField } from '../../fields/AutosuggestWithLabelField';
import { MODE_ONE_COLUMN, states } from '../../constants';
import { GoogleUtils } from '../../utils/googleUtils';

export class ESIPatientEditAddressForm extends React.PureComponent {
  static propTypes = {
    initialValues: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  refWizardForm = React.createRef();

  state = { addressSuggestionsError: '' };

  findAddressFromQuery = async (value, setValue) => {
    return GoogleUtils.getPlacePredictions(value, setValue, this.setSuggestionError);
  };

  setSuggestionError = value => {
    this.setState({ addressSuggestionsError: value });
  };

  onAutosuggestSelected = place => {
    if (place.status !== 'OK') return;
    this.refWizardForm.current.setValue('state', place.addressDetails.state);
    this.refWizardForm.current.setValue('city', place.addressDetails.city);
    this.refWizardForm.current.setValue('zip', place.addressDetails.zip);
  };

  render() {
    const { onCancel, onSubmit, initialValues = {} } = this.props;

    return (
      <WizardForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        ref={this.refWizardForm}
        initialValues={initialValues}
        titles={[Strings.editAddress]}
        submitButtonTitle={Strings.save}
      >
        <WizardForm.Page>
          <AutosuggestWithLabelField
            mode={MODE_ONE_COLUMN}
            name="street_address"
            title={Strings.streetAddress}
            style={{ textAlign: 'left' }}
            onSuggestionsFetch={this.findAddressFromQuery}
            onAutosuggestSelected={this.onAutosuggestSelected}
            error={this.state.addressSuggestionsError}
          />

          <TextInputWithLabelField
            mode={MODE_ONE_COLUMN}
            name="address_extra"
            title={Strings.apartmentSuiteOptional}
            style={{ textAlign: 'left' }}
          />

          <div className={styles.twoCols}>
            <div>
              <TextInputWithLabelField
                mode={MODE_ONE_COLUMN}
                name="zip"
                title={Strings.esiPatientInfo.zip}
                style={{ textAlign: 'left' }}
                onlyDigits
              />
            </div>
            <div>
              <AutosuggestWithLabelField
                mode={MODE_ONE_COLUMN}
                name="state"
                title="&nbsp;"
                style={{ textAlign: 'left' }}
                suggestions={states.map(x => ({
                  ...x,
                  name: capitalize(x.name),
                }))}
              />
            </div>
          </div>

          <div className={styles.twoCols}>
            <div>
              <TextInputWithLabelField
                mode={MODE_ONE_COLUMN}
                name="city"
                title={Strings.city}
                style={{ textAlign: 'left' }}
              />
            </div>
            <div>
              <TextInputWithLabelField
                mode={MODE_ONE_COLUMN}
                name="country"
                disabled="disabled"
                title={Strings.country}
                validate={composeValidators(makeRequired(Strings.fieldIsRequired))}
                style={{ textAlign: 'left' }}
              />
            </div>
          </div>
        </WizardForm.Page>
      </WizardForm>
    );
  }
}
