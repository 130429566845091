import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closeModal } from 'actions/modal';

import { Modal } from '../../containers';

class EmptyModal extends PureComponent {
  static MODAL_NAME = 'empty-modal';

  static propTypes = {
    onOpen: PropTypes.func,
    onCancel: PropTypes.func,
    data: PropTypes.shape({ data: PropTypes.any }),
  };

  render() {
    const { onOpen, onCancel, data, ...props } = this.props;
    return (
      <Modal onOpen={onOpen} name={EmptyModal.MODAL_NAME} withoutContainer {...props}>
        {data.data}
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({ onCancel: () => dispatch(closeModal(EmptyModal.MODAL_NAME)) });

export default connect(null, mapDispatchToProps)(EmptyModal);
