import { CLEAR_ENTITIES } from '../actions/action-types';

export const membershipsReducer = (reducer, types, actionToKey) => {
  const nextReducer = reducer(types, actionToKey);

  return (state, action) => {
    const newState = { ...nextReducer(state, action) };
    switch (action.type) {
      case CLEAR_ENTITIES:
        if (action.entities && action.entities.indexOf('memberships') !== -1) {
          return {
            ...newState,
            objects: {},
          };
        }
        return newState;
      default:
        return newState;
    }
  };
};
