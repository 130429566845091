import merge from 'deepmerge';

import { CLEAR_ENTITIES, POST_ADMIN_INVITE_RESULT } from '../actions/action-types';

export const doctorsReducer = (reducer, types, actionToKey) => {
  const nextReducer = reducer(types, actionToKey);

  return (state, action) => {
    const newState = { ...nextReducer(state, action) };
    switch (action.type) {
      case POST_ADMIN_INVITE_RESULT:
        if (action.response && action.response.doctor) {
          return merge(newState, { objects: { [action.response.doctor.id]: action.response.doctor } });
        }
        return newState;
      case CLEAR_ENTITIES:
        if (action.entities && action.entities.indexOf('doctors') !== -1) {
          return {
            ...newState,
            objects: {},
          };
        }
        return newState;
      default:
        return newState;
    }
  };
};
