import PropTypes from 'prop-types';
import { Line } from 'components/Login/Background/Line';
import React, { PureComponent } from 'react';

import termLogo from '../../images/rx/termLogo.png';
import styles from './style.module.scss';

export class WhiteBackground extends PureComponent {
  render() {
    const { title, children, noLines = false } = this.props;

    const lines = [];
    if (!noLines) {
      for (let i = 0; i < 8; i += 1) lines.push(<Line key={i} />);
    }

    const css = `  html, body, .full-height {
    overflow: auto !important;
    height: auto !important;
  }`;

    return (
      <React.Fragment>
        <style>{css}</style>
        <div className={styles.background}>
          <div className={styles.animationContainer}>{lines}</div>
          <div className={styles.container}>
            <img src={termLogo} alt="" className={styles.termLogoImg} />
            {title && <div className={styles.title}>{title}</div>}
            {typeof children !== 'undefined' && <div className={styles.content}>{children}</div>}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

WhiteBackground.propTypes = {
  children: PropTypes.any,
  noLines: PropTypes.bool,
  title: PropTypes.any,
};
