const LS_SELECTED_COLOR_THEME = 'selected-color-theme';
const DEFAULT_THEME = 'blue';

export const colorThemes = {
  red: {
    mainColor: '#AB3439',
  },
  blue: {
    mainColor: '#0460A9',
  },
  white: {
    mainColor: '#0060A8',
  },
  petrol: {
    mainColor: '#003E52',
  },
};

export function setTheme(theme) {
  document.documentElement.setAttribute('data-theme', theme);
  if (localStorage) {
    try {
      localStorage.setItem(LS_SELECTED_COLOR_THEME, theme);
    } catch (e) {}
  }
}

export function setThemeFromLS() {
  let theme = DEFAULT_THEME;
  try {
    theme = localStorage.getItem(LS_SELECTED_COLOR_THEME) || DEFAULT_THEME;
  } catch (e) {}
  document.documentElement.setAttribute('data-theme', theme);
}

export function getColorsForColorTheme() {
  const theme = document.documentElement.getAttribute('data-theme');
  return colorThemes[theme] || colorThemes[DEFAULT_THEME];
}
