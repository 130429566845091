import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import BLEManage from './BLEManage';
import HubManage from './HubManage';
import LTEManage from './LTEManage';
import AddDevices from './AddDevices';

const Tab = ({ children }) => <div>{children}</div>;

Tab.propTypes = { children: PropTypes.any };

const label = {
  font: 'inherit',
  marginRight: '10px',
  marginTop: '10px',
  cursor: 'pointer',
  padding: '8px 63px 7px 62px',
  backgroundColor: '#fff',
  boxShadow: 'none',
};

const activeLabel = {
  ...label,
  borderRadius: '18px',
  border: 'solid 1px #bababa',
  backgroundColor: '#f7f7f7',
};
class Tabs extends React.Component {
  state = { activeIndex: this.props.defaultIndex || 0 };

  render() {
    const { activeIndex } = this.state;

    const tabs = React.Children.map(this.props.children, (child, index) => {
      const style = activeIndex === index ? activeLabel : label;
      return (
        <button style={style} className="tabButton" onClick={() => this.setState({ activeIndex: index })}>
          {child.props.label}
        </button>
      );
    });
    return (
      <div>
        <div>{tabs}</div>
        {this.props.children[this.state.activeIndex]}
      </div>
    );
  }
}

Tabs.propTypes = {
  children: PropTypes.any,
  defaultIndex: PropTypes.number,
};
class Devices extends PureComponent {
  render() {
    const { organizationId, type, organizationName } = this.props;
    let index = 0;
    switch (type) {
      case 'ble':
        index = 0;
        break;
      case 'lte':
        index = 1;
        break;
      case 'hub':
        index = 2;
        break;
      default:
        index = 0;
        break;
    }
    return (
      <div className="devices">
        <Tabs defaultIndex={index}>
          <Tab label="Bluetooth">
            <BLEManage organizationId={organizationId}></BLEManage>
          </Tab>
          <Tab label="LTE">
            <LTEManage organizationId={organizationId} />
          </Tab>
          <Tab label="Hub">
            <HubManage organizationId={organizationId} organizationName={organizationName} />
          </Tab>
          <Tab label="Add Devices">
            <AddDevices orgUuid={organizationId} />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

Devices.propTypes = {
  organizationId: PropTypes.any,
  organizationName: PropTypes.string,
  type: PropTypes.any,
};

export default Devices;
