export const GET_ORAGANIZATION_DEVICES_BILLING = 'get-organization-device-billing';
export const GET_ORAGANIZATION_DEVICES_BILLING_RESULT = `${GET_ORAGANIZATION_DEVICES_BILLING}/result`;
export const GET_ORAGANIZATION_DEVICES_BILLING_ERROR = `${GET_ORAGANIZATION_DEVICES_BILLING}/error`;

export const GET_DEVICES_FILTER = 'get-device-filter';
export const GET_DEVICES_FILTER_RESULT = `${GET_DEVICES_FILTER}/result`;
export const GET_DEVICES_FILTER_ERROR = `${GET_DEVICES_FILTER}/error`;

export const LTE = 'lte';
export const BLE = 'ble';
export const HUB = 'hub';
