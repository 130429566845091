import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { DivWithTooltip } from '../../utils';
import styles from './Switch2.module.scss';

export class Switch2 extends PureComponent {
  ref = React.createRef();
  static propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    isBusy: PropTypes.bool,
    name: PropTypes.string,
    onBeforeChange: PropTypes.func,
    onChange: PropTypes.func,
    tooltip: PropTypes.shape({ length: PropTypes.number }),
    value: PropTypes.string,
  };

  state = {
    checked: this.props.checked || false,
    isBusy: this.props.isBusy || false,
  };

  componentDidMount() {
    this.ref && this.ref.current.focus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.checked !== this.props.checked) {
      this.state.checked = nextProps.checked;
    }
  }

  isChecked() {
    return this.state.checked;
  }

  setChecked(checked) {
    this.setState({ checked });
  }

  setBusy(isBusy) {
    this.setState({ isBusy });
  }

  onChange = () => {
    const { onBeforeChange, onChange, disabled = false } = this.props;
    const { isBusy } = this.state;

    if (disabled || isBusy) return;

    const checked = !this.state.checked;

    if (onBeforeChange && !onBeforeChange(this, checked)) {
      return;
    }

    this.setState({ checked });

    if (onChange) {
      onChange(this, checked);
    }
  };

  getTooltip = () => {
    const { name, tooltip } = this.props;
    if (typeof tooltip === 'string') return tooltip;
    if (tooltip.length === 1) return tooltip[0];
    const value = this.state[name];
    return tooltip[value ? 1 : 0];
  };

  render() {
    const { tooltip, name, value = 'on' } = this.props;

    const { checked, isBusy } = this.state;

    const divProps = { className: styles.checkboxes_and_radios };

    const input = (
      <React.Fragment>
        <input type="checkbox" name={name} value={value} checked={checked} />
        <label
          className={isBusy ? styles.busy : ''}
          onClick={this.onChange}
          onKeyDown={e => {
            const charCode = e.which ? e.which : e.keyCode;
            if (charCode === 13) {
              this.onChange();
            }
          }}
          ref={this.ref}
          tabIndex={0}
        />
      </React.Fragment>
    );

    if (!tooltip) {
      return <div {...divProps}>{input}</div>;
    }

    return (
      <DivWithTooltip tt-id={`tt-${name}`} tt-text={this.getTooltip} {...divProps}>
        {input}
      </DivWithTooltip>
    );
  }
}
