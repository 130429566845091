import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import {
  GRAPH_AXIS_FONT_SIZE,
  GRAPH_X_AXIS_FONT_COLOR,
  GRAPH_Y_AXIS_FONT_COLOR,
  FIRST_IN_MONTH_MARKER,
  FIRST_IN_DAY_MARKER,
  GRAPH_X_AXIS_FONT_COLOR_REPEATED,
} from './GraphConstants';
import { DATE_FORMAT_MONTH_NAME } from '../../constants';

const IsValidMoment = date => {
  return date != null && date instanceof moment && date.isValid();
};

function calcTick(minValue, maxValue, maxTicks, minTick) {
  const tickCandidates = [
    0.1,
    0.2,
    0.5,
    1,
    2,
    5,
    10,
    20,
    50,
    100,
    200,
    500,
    1000,
    2000,
    5000,
    10000,
    20000,
    50000,
    100000,
    200000,
    500000,
    1000000,
  ];
  const minTickEffective = minTick || tickCandidates[0];
  const diff = maxValue - minValue;

  return tickCandidates.find(tc => tc >= minTickEffective && diff / tc < maxTicks);
}
export function roundExt(value, precision) {
  return Math.round(value / precision) * precision;
}
export function getNumericAxisParams(values, options) {
  const maxTicks = options && options.maxTicks ? options.maxTicks : 10;
  const minTick = options && options.minTick ? options.minTick : null;
  const extraValues = options && options.extraValues ? options.extraValues : [];

  const minValue = Math.min(...values, ...extraValues);
  const maxValue = Math.max(...values, ...extraValues);

  const tickCalculated = calcTick(minValue, maxValue, maxTicks, minTick);
  const tick = minTick && tickCalculated < minTick ? minTick : tickCalculated;
  const domain = [minValue - tick / 2, maxValue + tick / 2];

  const minValueRounded = roundExt(minValue, tick);
  const maxValueRounded = roundExt(maxValue, tick);
  const tickCount = (maxValueRounded - minValueRounded) / tick + 1;
  const ticks = Array.from({ length: tickCount }, (_, i) => i * tick + minValueRounded);

  return {
    minValue,
    maxValue,
    domain,
    ticks,
  };
}

export class CustomizedYAxisTickNumeric extends PureComponent {
  render() {
    const { x, y, payload, yMin, yMax, width, tick, unit, isRight } = this.props; // stroke
    const useK = tick >= 1000;
    const displayValue = `${useK ? payload.value / 1000 : payload.value}${useK ? 'k' : ''}${unit ? ` ${unit}` : ''}`;
    const shouldDisplayValue = payload.value >= yMin && payload.value <= yMax;

    const dx = isRight ? (width === undefined ? 35 : width / 2 - 2) : width === undefined ? -35 : -width / 2;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          className="span"
          fontSize={GRAPH_AXIS_FONT_SIZE}
          dy={5}
          dx={dx}
          textAnchor="middle"
          fill={GRAPH_Y_AXIS_FONT_COLOR}
        >
          {shouldDisplayValue ? displayValue : ''}
        </text>
      </g>
    );
  }
}
CustomizedYAxisTickNumeric.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.any,
  payload: PropTypes.any,
  yMin: PropTypes.number,
  yMax: PropTypes.number,
  width: PropTypes.number,
  tick: PropTypes.number,
  unit: PropTypes.string,
  isRight: PropTypes.bool,
};

export class CustomizedYAxisTickHours extends PureComponent {
  render() {
    const { x, y, payload, width, isRight } = this.props; // stroke

    const isNeg = payload.value < 0;
    const absValue = Math.abs(payload.value);
    const hrs = Math.floor(absValue);
    const mins = Math.round((absValue - hrs) * 60);
    const displayValue = `${isNeg ? '-' : '+'}${hrs}${mins !== 0 ? `:${mins}` : 'h'}`;
    const dx = isRight ? (width === undefined ? 35 : width / 2) : width === undefined ? -35 : -width / 2;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          className="span"
          fontSize={GRAPH_AXIS_FONT_SIZE}
          dy={5}
          dx={dx}
          textAnchor="middle"
          fill={GRAPH_Y_AXIS_FONT_COLOR}
        >
          {displayValue}
        </text>
      </g>
    );
  }
}
CustomizedYAxisTickHours.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.any,
  payload: PropTypes.any,
  yMin: PropTypes.number,
  yMax: PropTypes.number,
  width: PropTypes.number,
  tick: PropTypes.number,
  isRight: PropTypes.bool,
};

export class CustomizedXAxisTickNumeric extends PureComponent {
  render() {
    const { x, y, payload, xMin, xMax, height, tick, unit } = this.props; // stroke
    const useK = tick >= 1000;
    const displayValue = `${useK ? payload.value / 1000 : payload.value}${useK ? 'k' : ''}${unit ? ` ${unit}` : ''}`;
    const shouldDisplayValue = payload.value >= xMin && payload.value <= xMax;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          className="span"
          fontSize={GRAPH_AXIS_FONT_SIZE}
          dx={5}
          // dy={height === undefined ? -35 : -height / 3}
          dy={10}
          textAnchor="end"
          fill={GRAPH_Y_AXIS_FONT_COLOR}
        >
          {shouldDisplayValue ? displayValue : ''}
        </text>
      </g>
    );
  }
}
CustomizedXAxisTickNumeric.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.any,
  payload: PropTypes.any,
  xMin: PropTypes.number,
  xMax: PropTypes.number,
  height: PropTypes.number,
  tick: PropTypes.number,
  unit: PropTypes.string,
};

export class CustomizedXAxisTickIrregular extends PureComponent {
  render() {
    const { x, y, payload, isCondensed } = this.props; // stroke

    const isFirstMonth = payload.value.includes(FIRST_IN_MONTH_MARKER);
    const isFirstDay = isFirstMonth || payload.value.includes(FIRST_IN_DAY_MARKER);

    const sDate = payload.value.replace(FIRST_IN_DAY_MARKER, '').replace(FIRST_IN_MONTH_MARKER, '');
    const day = moment(sDate);
    const dayOfMonth = IsValidMoment(day) ? day.date() : -1;
    const month = IsValidMoment(day) ? day.format('MMM') : '';
    return (
      <g transform={`translate(${x},${y})`}>
        {!isCondensed && (
          <text
            className="span"
            fontSize={GRAPH_AXIS_FONT_SIZE}
            dy={-30}
            textAnchor="middle"
            fill={isFirstDay ? GRAPH_X_AXIS_FONT_COLOR : GRAPH_X_AXIS_FONT_COLOR_REPEATED}
          >
            {dayOfMonth > 0 ? dayOfMonth : ''}
          </text>
        )}

        {isFirstMonth && (
          <text
            className="span"
            fontSize={GRAPH_AXIS_FONT_SIZE}
            dy={-5}
            textAnchor="middle"
            fill={isCondensed ? GRAPH_X_AXIS_FONT_COLOR : GRAPH_X_AXIS_FONT_COLOR_REPEATED}
          >
            {month}
          </text>
        )}
      </g>
    );
  }
}
CustomizedXAxisTickIrregular.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.any,
  isCondensed: PropTypes.bool,
};

export class CustomizedXAxisTickIrregularMinMaxOnly extends PureComponent {
  render() {
    const { x, y, payload, valueFirst, valueLast, isTop } = this.props; // stroke

    const isFirst = valueFirst === payload.value;
    const isLast = valueLast === payload.value;

    const sDate = payload.value.replace(FIRST_IN_DAY_MARKER, '').replace(FIRST_IN_MONTH_MARKER, '');
    const day = moment(sDate);
    const label = (isFirst || isLast) && IsValidMoment(day) ? day.format(DATE_FORMAT_MONTH_NAME) : '';

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          className="span"
          fontSize={GRAPH_AXIS_FONT_SIZE}
          dy={isTop ? -5 : 15}
          dx={isFirst ? -15 : 15}
          textAnchor={isFirst ? 'start' : 'end'}
          fill={GRAPH_X_AXIS_FONT_COLOR}
          opacity={0.56}
        >
          {label}
        </text>
      </g>
    );
  }
}
CustomizedXAxisTickIrregularMinMaxOnly.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.any,
  valueFirst: PropTypes.string,
  valueLast: PropTypes.string,
  isTop: PropTypes.bool,
};
