import { API_CALL } from '../../../../middleware/api';
import {
  GET_INBOX_ARCHIVED,
  GET_INBOX_MESSAGES,
  GET_MESSAGE_DETAILS,
  SEND_MESSAGE,
  TOGGLE_ARCHIVED,
  TOGGLE_READ,
  SEND_SMS,
  GET_PREFAB_REPLIES,
  ADD_PREFAB_REPLY,
  EDIT_PREFAB_REPLY,
  DELETE_PREFAB_REPLY,
  CLEAR_DATA,
  CLEAR_MESSAGES,
} from './constants';
const qs = require('qs');

export const actions = {
  getInboxMessages: pageRequest => ({
    [API_CALL]: {
      endpoint: `/v2/conversation/inbox`,
      method: 'get',
      params: pageRequest,
    },
    type: GET_INBOX_MESSAGES,
  }),

  getArchivedMessages: pageRequest => ({
    [API_CALL]: {
      endpoint: `/v2/conversation/archived`,
      method: 'get',
      params: pageRequest,
    },
    type: GET_INBOX_ARCHIVED,
  }),

  archiveToggle: data => ({
    [API_CALL]: {
      endpoint: `/v2/conversation/archive/set`,
      method: 'PUT',
      data,
    },
    type: TOGGLE_ARCHIVED,
  }),

  readToggle: data => ({
    [API_CALL]: {
      endpoint: `/v2/conversation/read/set`,
      method: 'PUT',
      data,
    },
    type: TOGGLE_READ,
  }),

  getMessageDetails: (pageRequest, userId) => ({
    [API_CALL]: {
      endpoint: `/v2/conversation/${userId}/messages`,
      method: 'get',
      params: pageRequest,
    },
    type: GET_MESSAGE_DETAILS,
  }),

  sendMessage: data => ({
    [API_CALL]: {
      endpoint: `/v2/conversation/send/message`,
      method: 'POST',
      data,
    },
    type: SEND_MESSAGE,
  }),

  sendSMS: data => ({
    [API_CALL]: {
      endpoint: `/portal/sms`,
      method: 'POST',
      data,
    },
    type: SEND_SMS,
  }),

  clearMessages: () => ({
    type: CLEAR_MESSAGES,
  }),

  clearData: () => ({
    type: CLEAR_DATA,
  }),

  getPrefabReplies: request => ({
    [API_CALL]: {
      endpoint: '/v2/conversation/prefabs/',
      method: 'get',
      params: request,
    },
    type: GET_PREFAB_REPLIES,
  }),

  addPrefabReply: data => ({
    [API_CALL]: {
      endpoint: '/v2/conversation/prefabs/',
      method: 'POST',
      data,
    },
    type: ADD_PREFAB_REPLY,
  }),

  editPrefabReply: (data, id) => ({
    [API_CALL]: {
      endpoint: `/v2/conversation/prefabs/${id}`,
      method: 'PUT',
      data,
    },
    type: EDIT_PREFAB_REPLY,
  }),

  deletePrefabReply: id => ({
    [API_CALL]: {
      endpoint: `/v2/conversation/prefabs/${id}`,
      method: 'DELETE',
    },
    type: DELETE_PREFAB_REPLY,
  }),
};
