import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { connect } from 'react-redux';
import { notificationActions } from 'components/Notification/redux/actions';

import styles from './Notification.module.scss';

class Notification extends PureComponent {
  static propTypes = {
    message: PropTypes.string.isRequired,
    timeout: PropTypes.number,
    isError: PropTypes.bool,
    hideNotification: PropTypes.func,
  };

  static defaultProps = { timeout: 5000, isError: false };

  state = { isShown: false };

  timeout = null;

  hide = () => {
    this.setState({ isShown: false });

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    // Waiting for animation ending
    setTimeout(this.props.hideNotification, 500);
  };

  componentDidMount() {
    // this timeout is need for proper rendering and css animation
    setTimeout(() => {
      this.setState({ isShown: true });
    }, 1);

    this.timeout = setTimeout(this.hide, this.props.timeout);
  }

  render() {
    const { message, isError } = this.props;
    const { isShown } = this.state;

    return (
      <div
        className={classNames(styles.notification, {
          [styles.shown]: isShown === true,
          [styles.error]: isError === true,
        })}
      >
        {message}
        <div
          className={classNames(styles.iconContainer, {
            [styles.error]: isError === true,
          })}
        >
          <div className={classNames(styles.icon, styles.delete)} onClick={this.hide} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({ hideNotification: () => dispatch(notificationActions.hide()) });
export default connect(null, mapDispatchToProps)(Notification);
