import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { closeModal } from '../../actions/modal';
import { patientAction } from '../../actions/patient';
import { BUTTON_TYPE_DARK_BLUE } from '../../components/Buttons/Button';
import { DATE_FORMAT_MONTH_NAME } from '../../constants';
import Strings from '../../Strings';
import { downloadFileOnly, downloadFiles } from '../../utils';
import NewConfirmationModal from '../NewConfirmationModal/NewConfirmationModal';
import './DownloadHistoryModal.scss';

class DownloadHistoryModal extends PureComponent {
  state = {
    errors: [],
    allMeds: true,
    filteredMeds: false,
  };

  onSubmit = () => {
    const { onCancel } = this.props;

    const { allMeds } = cloneDeep(this.state);
    const { data } = cloneDeep(this.props.data);
    if (allMeds) {
      delete data.startDate;
      delete data.endDate;
      data.scheduleId = '';
      data.filterBy = '';
    }
    if (!allMeds && data.history === 0) {
      return;
    }
    delete data.history;
    this.props.downloadPatientData(data).then(req => {
      const fileName = `Patient_${this.props.data.data.patientId}`;
      downloadFileOnly(req, fileName);

      onCancel();
    });
  };

  onAllMeds = event => {
    if (event.target.checked) {
      this.setState({
        allMeds: true,
        filteredMeds: false,
      });
      this.forceUpdate();
    }
  };

  onFilteredMeds = event => {
    if (event.target.checked) {
      this.setState({
        filteredMeds: true,
        allMeds: false,
      });
      this.forceUpdate();
    }
  };

  componentDidMount() {}

  render() {
    const { onCancel, data, ...props } = this.props;
    const { errors, allMeds, filteredMeds } = this.state;
    return (
      <NewConfirmationModal
        caption={Strings.downloadHistory}
        s
        data={{
          confirmText: Strings.download,
          onConfirm: this.onSubmit,
          onCancel,
          submitButtonType: BUTTON_TYPE_DARK_BLUE,
          isSubmitButtonDisabled: !!(filteredMeds && data.data.history === 0),
        }}
        {...props}
      >
        <div className="download-history content-block" key="schedule-form">
          <div className="row title">
            <span>{Strings.downloadLast24Months}</span>
            <div className="input-content">
              <input onChange={this.onAllMeds} type="checkbox" id="confirmation-checkbox" checked={allMeds} />
              <label />
            </div>
          </div>

          <div className="row title">
            <span>{Strings.downloadFilteredHistory}</span>
            <div className="input-content">
              <input onChange={this.onFilteredMeds} type="checkbox" id="filteredHistory" checked={filteredMeds} />
              <label />
            </div>
          </div>
          <div key="errors-block" className="errors-block">
            {errors.length ? <div>{errors.join(' ')}</div> : null}
          </div>

          <div className="dose-remain-box">
            <div>
              <div className="big-label">
                {data.data.search === '' || allMeds ? Strings.patientDashboard.allMeds : data.data.search}
              </div>
              {!allMeds ? (
                <React.Fragment>
                  <div className="light-label">from</div>
                  <div className="blue-label" style={{ fontWeight: 'bold' }}>
                    {moment(data.data.startDate).format(DATE_FORMAT_MONTH_NAME)} -{' '}
                    {moment(data.data.endDate).format(DATE_FORMAT_MONTH_NAME)}
                  </div>
                </React.Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
          {filteredMeds && data.data.history === 0 && (
            <div id="errors" className="errors-block">
              Strings.noDataAvailable
            </div>
          )}
        </div>
      </NewConfirmationModal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onCancel: () => dispatch(closeModal('download-history')),
  onNavigate: path => dispatch(push(path)),
  downloadPatientData: data => dispatch(patientAction.downloadPatientData(data)),
});

export default connect(null, mapDispatchToProps)(DownloadHistoryModal);
