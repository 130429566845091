import React from 'react';
import PropTypes from 'prop-types';

function HubMetrics(props) {
  const { value, label, color, tooltip, dragging } = props;
  return (
    <React.Fragment>
      <div className="boxWithVl-box">
        <div className={`boxWithVl-valueBox ${color}`}>{value}</div>
        <div
          className="boxWithVl-labelBox"
          data-tooltip-content={tooltip}
          data-tooltip-id={tooltip && !dragging ? 'tooltip' : undefined}
        >
          {label}
        </div>
      </div>
    </React.Fragment>
  );
}

HubMetrics.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  dragging: PropTypes.bool,
};

export default HubMetrics;
