import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment, { now } from 'moment-timezone';

import { TIME_FORMAT_12_UPPERCASE, DATE_MONTH_DAY_YEAR, PAGE_LIMIT } from '../../../../../constants';
import TableWithLocalPagination, { Column } from '../../../../../containers/Table/TableWithLocalPagination';
import { actions, PLOT_DATA_RESULT } from '../Graph/actions';
import Strings from '../../../../../Strings';
import { PageHeader } from '../../../../../components/PageHeader';
import { Button } from '../../../../../components/PageHeader/Button';
import { HAILIE_INHALER } from '../../constant';
import { hailieEventSubTypeDisplay } from '../../../../../utils';

function HailieInhalerTable(props) {
  const pageRequest = useRef({
    offset: 0,
    startDate: props.startDate,
    endDate: props.endDate,
    deviceId: props.deviceId,
    dataType: HAILIE_INHALER,
    limit: PAGE_LIMIT,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [refreshEnable, setRefreshEnable] = useState(true);
  const [refreshTimestamp, setRefreshTimestamp] = useState(now());

  useEffect(() => {
    pageRequest.current.startDate = props.startDate;
    pageRequest.current.endDate = props.endDate;
    pageRequest.current.deviceId = props.deviceId;
    pageRequest.current.hubId = props.hubId;
    pageRequest.current.kitId = props.kitId;
    pageRequest.current.patientId = props.patientId;

    getEvents();
  }, [refreshTimestamp, props.deviceId, props.startDate, props.endDate, props.hubId, props.kitId]);

  const onRefresh = () => {
    setRefreshEnable(false);
    setTimeout(() => {
      setRefreshEnable(true);
    }, 10000);
    setRefreshTimestamp(now());
  };

  const getEvents = () => {
    setIsLoading(true);
    props.getEvents(pageRequest.current, props.hubId, props.deviceId).then(resp => {
      if (resp.type === PLOT_DATA_RESULT) {
        setData(resp.response.sort((a, b) => b.timestamp - a.timestamp));
      }
      setIsLoading(false);
    });
  };

  const physioParamsDisplay = data => {
    let ret = '';

    data.forEach(e => {
      ret += `${e.name}: ${e.value}, `;
    });

    return ret;
  };

  const columns = [
    <Column key="date" title={Strings.date} value={e => moment.unix(e.timestamp).format(DATE_MONTH_DAY_YEAR)} />,
    <Column key="time" title={Strings.time} value={e => moment.unix(e.timestamp).format(TIME_FORMAT_12_UPPERCASE)} />,
    <Column key="event_type" title={Strings.eventType} value={e => hailieEventSubTypeDisplay(e.event_sub_type)} />,
    <Column key="physio_params" title={Strings.physioParams} value={e => physioParamsDisplay(e.physio_params)} />,
  ];

  const getTable = () => {
    return (
      <React.Fragment>
        <TableWithLocalPagination
          name="hailie-inhaler-table"
          uuid="297320e8-3a47-4b80-911b-007270d582ee"
          data={data}
          isLoading={isLoading}
          columns={columns}
          enableColumnFiltering
        />
      </React.Fragment>
    );
  };

  const getPageHeader = () => {
    const { dateRangeSelect } = props;

    return (
      <PageHeader
        right={
          <React.Fragment>
            <Button class="refreshTop" disabled={!refreshEnable} onClick={onRefresh} />
            {dateRangeSelect}
          </React.Fragment>
        }
      />
    );
  };

  return (
    <React.Fragment>
      {getPageHeader()}
      {getTable()}
    </React.Fragment>
  );
}

HailieInhalerTable.propTypes = {
  deviceId: PropTypes.string.isRequired,
  patientId: PropTypes.string,
  hubId: PropTypes.string,
  kitId: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  getEvents: PropTypes.func.isRequired,
  dateRangeSelect: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getEvents: pageRequest => dispatch(actions.getPlotData(pageRequest)),
});

export default connect(null, mapDispatchToProps)(HailieInhalerTable);
