import React, { PureComponent } from 'react';

import _ from 'lodash';

import styles from './Line.module.scss';

const TYPE_HORIZONTAL = 'h';
const TYPE_VERTICAL = 'v';

const DIRECTION_FORWARD = 'f';
const DIRECTION_BACKWARD = 'b';

export class Line extends PureComponent {
  firstLaunch = true;

  state = {
    type: null,
    direction: null,
    position: null,
    animationDelay: null,
    animationName: null,
  };

  // Setting start params for the line
  resetState() {
    const type = _.sample([TYPE_HORIZONTAL, TYPE_VERTICAL]);
    const direction = _.sample([DIRECTION_FORWARD, DIRECTION_BACKWARD]);
    const position = 5 + Math.random() * 85;
    let animationDelay = null;
    const animationDuration = `${2000 + Math.round(Math.random() * 8 * 1000)}ms`;
    const animationName = ''; // Clear animation name so we can restart it later

    // Animation delay is only needed at first render so not all lines start at the same time
    if (this.firstLaunch) {
      animationDelay = `${Math.round(Math.random() * 2 * 1000)}ms`;
      this.firstLaunch = false;
    }

    this.setState({
      type,
      direction,
      position,
      animationDelay,
      animationDuration,
      animationName,
    });
  }

  // After component mount we should set animation params
  componentDidMount() {
    this.resetState();
  }

  // After animation is ended we should set new animation params

  onAnimationEnd = () => {
    this.resetState();
  };

  render() {
    const { type, direction, animationDelay, animationName, animationDuration, position } = this.state;

    // component was not mounted yet
    if (!type) return null;

    const style = {
      animationDelay,
      animationDuration,
      animationName,
    };

    if (type === TYPE_HORIZONTAL) {
      style.top = `${position}%`;
    } else {
      style.left = `${position}%`;
    }

    return (
      <div
        onAnimationEnd={this.onAnimationEnd}
        style={style}
        className={`${styles.line} ${styles[type]} ${styles[direction]}`}
      />
    );
  }

  componentDidUpdate() {
    // If we don't have animation name - we should set it, so animation begins
    if (!this.state.animationName) {
      // timeout is necessary so react can detect changes in state.
      setTimeout(() => {
        this.setState({ animationName: styles[this.state.type + this.state.direction] });
      }, 1);
    }
  }
}
