import PropTypes from 'prop-types';
import { userProfile } from 'actions/auth';
import { resetForm } from 'actions/forms';
import { closeModal } from 'actions/modal';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import LoadingRenderer from '../components/LoadingRenderer';
import { Modal } from '../containers';
import Strings from '../Strings';
import { setIdleTime } from '../utils';
import './editStudyModal.scss';

export const MODAL_CONFIRM_YOU_THERE = 'confirm-you-there';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal(MODAL_CONFIRM_YOU_THERE)),
  onSubmit: () =>
    dispatch(userProfile()).then(response => {
      if (response) {
        setIdleTime();
        dispatch(closeModal(MODAL_CONFIRM_YOU_THERE));
      }
      return response;
    }),
  onOpen: () => dispatch(resetForm(MODAL_CONFIRM_YOU_THERE, ownProps.data)),
});
class ConfirmYouThere extends PureComponent {
  onSubmit = () => {
    this.props.onSubmit();
  };

  render() {
    const { onOpen, onCancel, ...props } = this.props;

    return (
      <Modal name={MODAL_CONFIRM_YOU_THERE} onOpen={onOpen} additionalClasses={['form-modal']} {...props}>
        <LoadingRenderer>
          {Strings.idleSessionMessage}

          <div className="button-bar reverse extend-button" key="button-bar">
            <button className="brand-blue" key="button" type="button" onClick={this.onSubmit}>
              {Strings.extend}
            </button>
          </div>
        </LoadingRenderer>
      </Modal>
    );
  }
}

export default connect(null, mapDispatchToProps)(ConfirmYouThere);

ConfirmYouThere.propTypes = {
  onCancel: PropTypes.any,
  onOpen: PropTypes.any,
  onSubmit: PropTypes.func,
};
