import { API_CALL } from '../../../../middleware/api';

export const AUTHORIZE_DEXCOM = 'authorize-dexcom';
export const AUTHORIZE_DEXCOM_RESULT = `${AUTHORIZE_DEXCOM}/result`;
export const AUTHORIZE_DEXCOM_ERROR = `${AUTHORIZE_DEXCOM}/error`;

export const GET_DEXCOM_CONFIG = 'get-dexcom-config';
export const GET_DEXCOM_CONFIG_RESULT = `${GET_DEXCOM_CONFIG}/result`;
export const GET_DEXCOM_CONFIG_ERROR = `${GET_DEXCOM_CONFIG}/error`;

export const FLAG_PATIENT_FOR_DEXCOM = 'flag-patient-for-dexcom';
export const FLAG_PATIENT_FOR_DEXCOM_RESULT = `${FLAG_PATIENT_FOR_DEXCOM}/result`;
export const FLAG_PATIENT_FOR_DEXCOM_ERROR = `${FLAG_PATIENT_FOR_DEXCOM}/error`;

export const dexcomActions = {
  authorizeDexcom: params => ({
    [API_CALL]: {
      endpoint: '/dexcom/authorize',
      method: 'PUT',
      params,
    },
    type: AUTHORIZE_DEXCOM,
  }),

  getDexcomConfig: () => ({
    [API_CALL]: {
      endpoint: '/dexcom/data',
      method: 'GET',
    },
    type: GET_DEXCOM_CONFIG,
  }),

  flagPatientForDexcom: (patientId, state) => ({
    [API_CALL]: {
      endpoint: '/dexcom/state',
      method: 'PUT',
      params: { patientId },
      data: { state: state },
    },
    type: FLAG_PATIENT_FOR_DEXCOM,
  }),
};
