import React from 'react';
import PropTypes from 'prop-types';

import './graph.scss';
import Strings from '../../Strings';
import GenericCategoryGraph from './GenericCategoryGraph';

export default function EsiPatientsDistributionGraph(props) {
  const { graphData, colorPalette } = props;
  const dataSeries = [
    {
      name: 'Patients',
      color: colorPalette?.interpolate(0.73),
      selector: item => item.count,
    },
  ];

  return (
    <GenericCategoryGraph
      graphData={graphData}
      hasBorder={true}
      forceCondensed
      xAxisTopPosition={false}
      yAxisWidth={40}
      yAxisUnit=""
      tooltipTitle={Strings.patients}
      dataSeries={dataSeries}
      categorySelector={item => item.status}
      hasReferenceAreas={true}
      graphType={props.graphType}
      colorPalette={colorPalette}
    />
  );
}

EsiPatientsDistributionGraph.propTypes = { graphData: PropTypes.array };
