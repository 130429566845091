import { API_CALL } from '../../../middleware/api';
import { ESI_PATIENT_LOGIN_CONFIG, SKIP_ON_BOARDING } from '../../../pages/ESI/PatientLogin/redux/constants';

export const actions = {
  updateConfig: data => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: '/esi/user/config/',
      data,
    },
    type: ESI_PATIENT_LOGIN_CONFIG,
  }),

  skipOnBoarding: () => ({
    type: SKIP_ON_BOARDING,
  }),
};
