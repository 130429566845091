import React from "react";

import "./RadioButtons.scss";

export interface RadioButtonDef {
  label: string;
  value: string;
}

export interface RadioButtonsProps {
  buttons: RadioButtonDef[];
  title: string;
  onClick: (value: string) => void;
  initSelectedValue?: string;
}

function RadioButtons(props: RadioButtonsProps) {
  const [selected, setSelected] = React.useState(props.initSelectedValue ? props.initSelectedValue : props.buttons[0].value);

  const onClick = (value: string) => {
    setSelected(value);
    props.onClick(value);
  }

  return (
    <div className="radio-buttons">
      <label className="title-label">{props.title}:</label>
      {
        props.buttons.map((e) => {
          return (
            <>
              <div className="radio-label-selector" onClick={() => onClick(e.value)}>
                <div className={selected === e.value ? 'checkbox-checked' : 'checkbox-unchecked'} />
                <label className="radio-label">{e.label}</label>
              </div>
            </>
          )
        })
      }
    </div>
  )
}

export default RadioButtons;