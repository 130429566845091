export function getMetricDataForDateRange(data, dateRange, metricId) {
  const metricData = data[metricId].data.filter(
    i => i.dt.unix() >= dateRange.start.unix() && i.dt.unix() <= dateRange.end.unix() + 24 * 60 * 60,
  );
  const inRangeCount = metricData.length;
  const prev = data[metricId].data.findLast(i => i.dt.unix() < dateRange.start.unix());
  const next = data[metricId].data.find(i => i.dt.unix() > dateRange.end.unix() + 24 * 60 * 60);
  if (prev) metricData.splice(0, 0, prev);
  if (next) metricData.push(next);

  return { data: metricData, inRangeCount, hasPrev: !!prev, hasNext: !!next };
}
