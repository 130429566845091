import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import SpirometryFlowVolumeCurveGraph from '../../../../../../components/Graphs/SpirometryFlowVolumeCurveGraph';
import SpirometryTimeCurveGraph from '../../../../../../components/Graphs/SpirometryTimeCurveGraph';
import { roundExt } from '../../../../../../components/Graphs/GraphAxes';
import './Spirometry.scss';
import SpirometryMeasurements from './SpirometryMeasurements';
import DashboardLayout from '../../../../../Dashboards/DashboardLayout';

export const DASHBOARD_ID = 'Spirometry';

const SpirometryMeasuremetsMetadata = {
  id: 'SpirometryMeasuremetsWidget',
  name: 'Measurements',
  permission: '',
  defProps: { i: 'SpirometryMeasuremetsWidget', isResizable: false, w: 4, h: 2, minW: 4, minH: 2 },
};

const SpirometryFlowVolumeCurveMetadata = {
  id: 'SpirometryFlowVolumeCurve',
  name: 'Flow Volume Curve',
  permission: '',
  defProps: { i: 'SpirometryFlowVolumeCurve', isResizable: false, w: 4, h: 2, minW: 4, minH: 2 },
};

const SpirometryTimeCurveMetadata = {
  id: 'SpirometryTimeCurve',
  name: 'Volume Time Curve',
  permission: '',
  defProps: { i: 'SpirometryTimeCurve', isResizable: false, w: 4, h: 2, minW: 4, minH: 2 },
};

export const WidgetMetadata = [
  SpirometryMeasuremetsMetadata,
  SpirometryFlowVolumeCurveMetadata,
  SpirometryTimeCurveMetadata,
];

const l = [
  { ...SpirometryMeasuremetsMetadata.defProps, x: 0, y: 0, w: 4, h: 2 },
  { ...SpirometryFlowVolumeCurveMetadata.defProps, x: 4, y: 0, w: 4, h: 2 },
  { ...SpirometryTimeCurveMetadata.defProps, x: 8, y: 0, w: 4, h: 2 },
];

const m = [
  { ...SpirometryMeasuremetsMetadata.defProps, x: 0, y: 0, w: 4, h: 2 },
  { ...SpirometryFlowVolumeCurveMetadata.defProps, x: 4, y: 0, w: 4, h: 2 },
  { ...SpirometryTimeCurveMetadata.defProps, x: 4, y: 1, w: 4, h: 2 },
];

const s = [
  { ...SpirometryMeasuremetsMetadata.defProps, x: 0, y: 0, w: 12, h: 2 },
  { ...SpirometryFlowVolumeCurveMetadata.defProps, x: 0, y: 1, w: 12, h: 2 },
  { ...SpirometryTimeCurveMetadata.defProps, x: 0, y: 2, w: 12, h: 2 },
];

export const DefaultLayouts = { l, m, s };

function SpirometryDashboard(props) {
  const [inhalePoints, setInhalePoints] = useState([]);
  const [exhalePoints, setExhalePoints] = useState([]);
  const [timeCurvePoints0Based, setTimeCurvePoints0Based] = useState([]);
  const { data } = props;

  useEffect(() => {
    prepareData();
  }, [props.data]);

  const prepareData = () => {
    const ai = true; // adds last tick to zero in inhale, first and last tick to zero in exhale, moves exhale to right to fvc, adds (0,0) point in exhale time curve

    const flowPoints = data.flowVolumeCurve.points;
    const fvc = data.fvc;

    const inhaleBeginIndex = 0;
    const inhaleEndIndex = flowPoints.findIndex((p, index) => index > 0 && p[0] !== 0 && p[1] === 0 && p[2] === 0);
    const exhaleBeginIndex = inhaleEndIndex + 1;
    const exhaleEndIndex = flowPoints.findIndex(
      (p, index) => index > exhaleBeginIndex && p[0] !== 0 && p[1] === 0 && p[2] === 0,
    );

    const inhalePoints = flowPoints
      .slice(inhaleBeginIndex, inhaleEndIndex)
      .filter(p => p[2] >= 0)
      .map(p => {
        return { x: p[1], y: p[2] };
      });
    if (ai && inhalePoints[inhalePoints.length - 1].x < fvc) inhalePoints.push({ x: fvc, y: 0 });
    setInhalePoints(inhalePoints);

    const exhalePoints = flowPoints
      .slice(inhaleBeginIndex, exhaleEndIndex)
      .filter(p => p[2] < 0)
      .map(p => {
        return { x: p[1], y: p[2] };
      });
    if (exhalePoints && exhalePoints.length > 0) {
      if (ai && exhalePoints[exhalePoints.length - 1].y !== 0)
        exhalePoints.push({ x: exhalePoints[exhalePoints.length - 1].x, y: 0 });
      // temporary turned off, it seemed needed at some point, but now better graph is achieved without this calculation
      // if (ai && exhalePoints[0].x !== fvc) {
      //   const diffExhaleStartAndFvc = fvc - exhalePoints[0].x;
      //   exhalePoints.forEach(p => {
      //     p.x += diffExhaleStartAndFvc;
      //   });
      // }
      if (ai && exhalePoints[0].y < 0) exhalePoints.splice(0, 0, { x: fvc, y: 0 });
      setExhalePoints(exhalePoints);
    }

    const timeCurvePointsRaw = flowPoints
      .slice(inhaleBeginIndex, inhaleEndIndex)
      .filter(p => p[2] > 0)
      .map(p => {
        return { x: roundExt(p[0], 0.01), y: p[1] };
      })
      .sort(function(a, b) {
        return parseFloat(a.x) - parseFloat(b.x);
      });

    const timeCurveGrouped = _.groupBy(timeCurvePointsRaw, p => p.x);
    const timeCurvePoints = Object.entries(timeCurveGrouped)
      .map(([x, entries]) => {
        return { x, y: entries.map(e => e.y).reduce((a, b) => a + b, 0) / entries.length };
      })
      .sort(function(a, b) {
        return parseFloat(a.x) - parseFloat(b.x);
      });

    const minS = Math.min(...timeCurvePoints.map(p => p.x));
    const timeCurvePoints0Based = timeCurvePoints.map(p => {
      return { x: p.x - minS, y: p.y };
    });
    if (ai && timeCurvePoints0Based[0].y > 0) timeCurvePoints0Based.splice(0, 0, { x: 0, y: 0 });
    setTimeCurvePoints0Based(timeCurvePoints0Based);
  };

  const SpirometryMeasuremetsWidget = {
    ...SpirometryMeasuremetsMetadata,
    render: <SpirometryMeasurements data={data} />,
    noPadding: true,
  };
  const SpirometryFlowVolumeCurveWidget = {
    ...SpirometryFlowVolumeCurveMetadata,
    render: <SpirometryFlowVolumeCurveGraph fvc={data.fvc} inhalePoints={inhalePoints} exhalePoints={exhalePoints} />,
  };
  const SpirometryTimeCurveWidget = {
    ...SpirometryTimeCurveMetadata,
    render: <SpirometryTimeCurveGraph fet={data.fet} fvc={data.fvc} points={timeCurvePoints0Based} />,
  };
  const widgets = [SpirometryMeasuremetsWidget, SpirometryFlowVolumeCurveWidget, SpirometryTimeCurveWidget];

  return data && widgets.length > 0 && data.acceptable === true && !_.isEmpty(DefaultLayouts) ? (
    <DashboardLayout
      dashboardId={DASHBOARD_ID}
      widgets={widgets}
      defaultLayouts={DefaultLayouts}
      breakpoints={{ l: 1100, m: 800, s: 0 }}
      rowHeight={228}
      isReadOnly
    />
  ) : (
    <></>
  );
}

SpirometryDashboard.propTypes = {
  data: PropTypes.object,
};

export default SpirometryDashboard;
