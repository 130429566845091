import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';

import { Modal } from '../containers';
import Form, { Input } from '../containers/Form';
import { closeModal } from '../actions/modal';
import { notificationActions } from '../components/Notification/redux/actions';
import { resetForm } from '../actions/forms';
import LoadingRenderer from '../components/LoadingRenderer';
import { patientAction } from '../actions/patient';
import Strings from '../Strings';
import { SEND_INVITE_ERROR, SEND_INVITE_RESULT } from '../actions/action-types';
import './editStudyModal.scss';

class EditPatientModal extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      patient: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),

      action: PropTypes.func.isRequired,
      actionType: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const newPatient = {
      id: 0,
      email: '',
    };

    this.state = {
      errors: [],
      patient: this.props.data.patient ? cloneDeep(this.props.data.patient) : cloneDeep(newPatient),
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onTextChange(event) {
    const patient = cloneDeep(this.state.patient);
    patient[event.target.getAttribute('name')] = event.target.value;
    this.setState({ patient });
  }

  onSubmit() {
    const { patient } = this.state;

    const validationErrors = [];

    if (!patient.email.length) {
      validationErrors.push(Strings.errors.emptyEmailAddress);
    }

    if (validationErrors.length) {
      this.setState({ errors: validationErrors });
      return;
    }

    this.props.onSubmit(patient).then(response => {
      if (response.type === SEND_INVITE_ERROR && response.response.data) {
        const responseData = response.response.data;
        const errors = Object.keys(responseData).map(key => `${key}: ${responseData[key]}`);
        this.setState({ errors });
      } else if (response && response.type === SEND_INVITE_RESULT) {
        this.props.showNotification(Strings.patientAddedSuccessfully);

        const dateRange = this.props.dateFilter
          ? this.props.dateFilter
          : {
              infimum: moment()
                .subtract(1, 'month')
                .toDate(),
              supremum: moment().toDate(),
            };

        this.pageRequest = {
          startDate: dateRange.infimum,
          endDate: dateRange.supremum,
          offset: 0,
          search: '',
        };
        this.props.onInvitedSuccess(this.pageRequest);
      }
      return response;
    });
  }

  render() {
    const { onOpen, onCancel, editPatientModalLoading, ...props } = this.props;

    const { errors } = this.state;

    const patient = cloneDeep(this.state.patient);

    return (
      <Modal name="new-trial" onOpen={onOpen} additionalClasses={['form-modal', 'user-invite']} {...props}>
        <LoadingRenderer loading={editPatientModalLoading}>
          <Form onSubmit={this.onSubmit} onCancel={onCancel} id="new-trial">
            <div className="flex-row">
              <div key="patient-email" className="row-item">
                <Input
                  name="email"
                  id="email"
                  className="dialog-form"
                  label=""
                  placeholder={Strings.placeholder.emailAddress}
                  type="email"
                  defaultValue={patient.email}
                  onChange={this.onTextChange}
                />
              </div>
            </div>
            <div key="errors-block" className="errors-block">
              {errors.length ? <div>{errors.join(' ')}</div> : null}
            </div>
            <div className="button-bar reverse" key="button-bar">
              <button className="brand-blue" key="submit" type="submit">
                {Strings.done}
              </button>

              <button className="white gray-text" key="cancel" type="cancel">
                {Strings.cancel}
              </button>
            </div>
          </Form>
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  editPatientModalLoading: state.entities.patients.loading,
  dateFilter: state.filter.dateFilter,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('new-patient')),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  onInvitedSuccess: pageRequest => dispatch(patientAction.actionList(pageRequest, true)),
  onSubmit: patient =>
    dispatch(patient.id ? ownProps.data.action(patient.id, patient) : ownProps.data.action(patient)).then(response => {
      if (response && response.type === `${ownProps.data.actionType}/result`) {
        dispatch(closeModal('new-patient'));
      }
      return response;
    }),
  onOpen: () => dispatch(resetForm('new-patient', ownProps.data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPatientModal);
