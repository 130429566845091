import React from 'react';
import PropTypes from 'prop-types';

import Strings from '../../../../Strings';
import { makeValid } from '../../../../utils';
import moment from 'moment-timezone';
import { DATE_MONTH_DAY_YEAR } from '../../../../constants';
import TableWidget from '../component/TableWidget';
import { PERMISSIONS, hasPermission } from '../../../../utils/userPermissions';

function TransplantDetails(props) {
  const { transplantDetails, isLoading } = props;
  let data = [];
  if (transplantDetails && transplantDetails.length > 0) {
    transplantDetails.forEach(td => {
      data.push([Strings.capPatient.transplantOrganType, makeValid(td.organType)]);
      data.push([
        Strings.capPatient.transplantDate,
        td.transplantDate ? moment(td.transplantDate).format(DATE_MONTH_DAY_YEAR) : '-',
      ]);
    });
  }
  const canEdit = hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_INFO_EDIT);

  return (
    <TableWidget
      title={Strings.capPatient.transplantDetails}
      buttons={canEdit ? [{ icon: 'edit', onClick: props.onEdit }] : undefined}
      data={data}
      isLoading={isLoading}
      firstColumnClassName="transplant-details-col"
    />
  );
}

TransplantDetails.propTypes = {
  isLoading: PropTypes.any,
  transplantDetails: PropTypes.arrayOf(
    PropTypes.shape({
      organType: PropTypes.string,
      transplantDate: PropTypes.string,
    }),
  ),
  patientId: PropTypes.any,
  onEdit: PropTypes.func,
};
TransplantDetails.widgetId = 'TransplantDetailsWidget';

export default TransplantDetails;
