import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import AdherenceTable from 'components/AdherenceTable/AdherenceTable';

import { PageHeader } from '../components/PageHeader';
import { Button } from '../components/PageHeader/Button';
import TabView, { Tab } from '../components/TabView/index';
import adminsAction from '../actions/admins';
import { patientAction } from '../actions/patient';
import { trialsAction } from '../actions/trials';
import SliderPicker from '../components/SliderPicker/index';
import { changeFilter, clearEntitiesAction } from '../actions/index';
import { openModalAction } from '../actions/modal';
import { formResetAction } from '../actions/forms';
import { downloadFiles } from '../utils';
import { HEADER_BUTTON_BLUE, LocationUSCenter } from '../constants';
import StudyOverView from './study/tabs/StudyOverView';
import StudyPatients from './study/tabs/StudyPatients';
import StudyInfo from './study/tabs/StudyInfo';
import { UPDATE_TRIAL, UPDATE_TRIAL_ERROR } from '../actions/action-types';
import './navigation.scss';
import Strings from '../Strings';

const today = moment()
  .endOf('day')
  .toDate();
const yearAgo = moment()
  .subtract(1, 'year')
  .startOf('day')
  .toDate();

class Study extends PureComponent {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    adherence: PropTypes.any,
    adherenceTotal: PropTypes.number,
    clearEntities: PropTypes.func.isRequired,
    downloadPatientsData: PropTypes.func.isRequired,
    events: PropTypes.shape({ length: PropTypes.any }),
    formData: PropTypes.object,
    isLoading: PropTypes.bool,
    match: PropTypes.object.isRequired,
    maxDate: PropTypes.instanceOf().isRequired,
    minDate: PropTypes.instanceOf().isRequired,
    onEditStudy: PropTypes.func,
    onLoadDoctorsMemberships: PropTypes.func.isRequired,
    onLoadStudyInfo: PropTypes.func.isRequired,
    onNavigate: PropTypes.func.isRequired,
    onNewPatient: PropTypes.func.isRequired,
    onUpdateTrial: PropTypes.func.isRequired,
    patients: PropTypes.object,
    reloadTimestamp: PropTypes.func.isRequired,
    trial: PropTypes.object.isRequired,
    trialId: PropTypes.string,
  };

  state = {
    trialId: this.props.match.params.id,
    isMounted: false,
  };

  componentDidMount() {
    this.props.clearEntities(['trials']);
    this.props.onLoadStudyInfo();
  }

  onRowSelected = id => {
    this.props.onNavigate(`/patient/${id}/overview`);
  };

  onSubmitEdit = () => {
    const { formData } = this.props;
    this.props.onUpdateTrial(formData).then(action => {
      if (action.type !== UPDATE_TRIAL_ERROR) {
        this.changeEditMode(false);
      }
    });
  };

  onTimeStampReload = () => {
    this.props.clearEntities(['events']);
    this.props.reloadTimestamp();
  };

  onDownloadBtnClick = () => {
    this.props.downloadPatientsData({ studyId: this.props.trialId }).then(this.onDownloadBtnClickSuccessHandler);
  };
  onDownloadBtnClickSuccessHandler = req => {
    const fileName = req.response && req.response.name ? req.response.name : 'doc.zip';
    downloadFiles(req, fileName);
  };
  getDevicesUsageData = () => {
    const { patients = {} } = this.props;
    const allPatients = Object.values(patients);
    const patientsWithDevice = allPatients.filter(patient => patient.hasDevice === true);
    return {
      device: patientsWithDevice.length,
      appOnly: allPatients.length - patientsWithDevice.length,
    };
  };

  getAdherencePlotData = () => {
    let { trial: { analytics } = { analytics: null } } = this.props;

    if (analytics === null) {
      analytics = {
        result: {
          complied: 0,
          missed: 0,
          skipped: 0,
          total: 0,
        },
      };
    }

    return {
      complied: analytics.result.complied,
      missed: analytics.result.missed,
      skipped: analytics.result.skipped,
      total: analytics.result.total,
    };
  };

  loadData = () => {
    this.props.onLoadDoctorsMemberships();
  };

  getHeaderComponents() {
    const { trial = {}, onNewPatient, activeTab, onEditStudy, events, adherenceTotal } = this.props;

    switch (activeTab) {
      case 'Overview':
        return <Button class={HEADER_BUTTON_BLUE} onClick={this.onDownloadBtnClick} title={Strings.downloadAllData} />;
      case 'Patients':
        return <Button class={HEADER_BUTTON_BLUE} onClick={onNewPatient} title={Strings.addPatient} />;
      case 'Adherence':
        return `${Strings.totalAdherence} ${
          adherenceTotal || adherenceTotal === 0 ? `${adherenceTotal}%` : Strings.na
        }`;
      case 'Info':
        return trial.isOwner ? (
          <Button
            class={HEADER_BUTTON_BLUE}
            onClick={() => {
              onEditStudy(trial);
            }}
            title={Strings.editInfo}
          />
        ) : null;
      case 'Timestamp':
        return events && events.length ? (
          <Button class={HEADER_BUTTON_BLUE} onClick={this.onTimeStampReload} title={Strings.reloadGraph} />
        ) : null;
      default:
        return null;
    }
  }

  onBackClick = () => {
    this.props.onNavigate('/studies');
  };

  render() {
    const { trial = {}, activeTab, minDate, maxDate, isLoading } = this.props;

    const { isMounted, trialId } = this.state;
    const sliderPicker = <SliderPicker min={minDate} max={maxDate} />;

    return (
      <React.Fragment>
        <PageHeader
          onBackClick={this.onBackClick}
          title={trial.name}
          getHeaderComponents={() => this.getHeaderComponents()}
          noLeftPadding
        />
        <TabView className="study-page-content" activeTab={activeTab} routeMode>
          <Tab name="Overview" path={`/study/${trialId}/overview`}>
            {sliderPicker}
            <StudyOverView trialId={trialId} />
          </Tab>
          <Tab name="Patients" path={`/study/${trialId}/patients`}>
            {sliderPicker}
            <StudyPatients trialId={trialId} onRowSelected={this.onRowSelected} />
          </Tab>
          <Tab name="Adherence" path={`/study/${trialId}/overview/adherence`}>
            <AdherenceTable trialId={trialId} />
          </Tab>
          <Tab name="Info" path={`/study/${trialId}/overview/info`}>
            <StudyInfo trial={trial} isMounted={isMounted} isLoading={isLoading} />
          </Tab>
        </TabView>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { trials, eventLocations } = state.entities;
  const { dateFilter } = state.filter;

  const dateRange = dateFilter || {
    infimum: moment()
      .subtract(1, 'month')
      .toDate(),
    supremum: moment().toDate(),
  };
  const maxDate = new Date(Date.parse(dateRange.supremum));
  const minDate = new Date(Date.parse(dateRange.infimum));
  const allLocations = Object.values(eventLocations.objects || {});

  const defaultLocation = allLocations.reduce(
    (data, notification) => {
      data.lat += notification.location.lat / (allLocations.length || 1); // eslint-disable-line no-param-reassign
      data.lng += notification.location.lng / (allLocations.length || 1); // eslint-disable-line no-param-reassign

      return data;
    },
    {
      lat: 0,
      lng: 0,
    },
  );

  if (allLocations.length === 0) {
    defaultLocation.lat = LocationUSCenter.lat;
    defaultLocation.lng = LocationUSCenter.lng;
  }

  const study = { study: trials.trialInfo };

  const { events, adherence } = state.entities.trials;

  return {
    trial: trials.trialInfo,
    adherenceTotal: trials.adherenceTotal ? trials.adherenceTotal.value : null,
    isLoading: trials.loading,
    maxDate: new Date(Math.max(maxDate || today, today)),
    minDate: new Date(Math.min(minDate || yearAgo, yearAgo)),
    defaultLocation,
    data: study,
    events,
    adherence: adherence ? adherence.adherence || {} : {},
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onLoadStudyInfo: () => dispatch(trialsAction.actionGetInfo(props.match.params.id)),
  onLoadAdherence: (year, month) =>
    dispatch(
      trialsAction.adherence({
        id: props.match.params.id,
        year,
        month,
      }),
    ),
  onUpdateTrial: data => dispatch(trialsAction.actionUpdate(props.match.params.id, data)),
  onNavigate: path => dispatch(push(path)),
  onChangeDateFilter: payload => dispatch(changeFilter('dateFilter', payload)),
  onNewPatient: () => dispatch(openModalAction('new-trial-patient', { trialId: props.match.params.id })),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  resetFormData: (formId, data) => dispatch(formResetAction(formId, data)),
  clearEntities: () =>
    dispatch(clearEntitiesAction(['patients', 'trials', 'memberships', 'doctors', 'patientCaregivers'])),
  onLoadDoctorsMemberships: () => dispatch(adminsAction.actionList(false)),
  loadActivityEvents: (start, end, patients) =>
    dispatch(patientAction.actionAllActivityEventsList(start, end, patients)),
  onEditStudy: study =>
    dispatch(
      openModalAction('new-trial', {
        action: trialsAction.actionUpdate,
        actionType: UPDATE_TRIAL,
        study,
      }),
    ),
  downloadPatientsData: params => dispatch(patientAction.downloadPatientsData(params)),
  reloadTimestamp: () => dispatch(trialsAction.reloadTimeStampGraph()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Study);
