import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Row } from './Row';
import styles from './style.module.scss';

export class Rows extends PureComponent {
  render() {
    const { noGrow, rows } = this.props;

    if (!noGrow) {
      while (rows.length < 4) {
        rows.push(<Row />);
      }
    }

    return <div className={styles.rows}>{rows}</div>;
  }
}

Rows.propTypes = {
  noGrow: PropTypes.any,
  rows: PropTypes.shape({
    length: PropTypes.number,
    push: PropTypes.func,
  }),
};
