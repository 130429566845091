import { LIST_NOTIFICATIONS, LIST_NOTIFICATIONS_RESULT } from '../actions/action-types';

const notificationsReducer = (reducer, types, actionToKey) => {
  const nextReducer = reducer(types, actionToKey);

  return (state, action) => {
    const newState = { ...nextReducer(state, action) };
    switch (action.type) {
      case LIST_NOTIFICATIONS:
        return {
          ...newState,
          loading: true,
        };
      case LIST_NOTIFICATIONS_RESULT:
        return {
          ...newState,
          loading: false,
        };
      default:
        return newState;
    }
  };
};
export default notificationsReducer;
