import React from 'react';
import { connect } from 'react-redux';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../components/PageHeader/Button';
import {
  DELETE_PUBLIC_KEY_RESULT,
  GET_EXTERNAL_API_STATUS_RESULT,
  REGISTER_PUBLIC_KEY_RESULT,
  actions,
} from './actions';

import './ExternalApi.scss';
import { openModalAction } from '../../../actions/modal';
import { notificationActions } from '../../../components/Notification/redux/actions';
import Strings from '../../../Strings';

function ExternalApiOauth(props) {
  const [publicKey, setPublicKey] = React.useState('');
  const [isExternalApiEnabled, setIsExternalApiEnabled] = React.useState(false);

  const onSave = () => {
    props.registerPublicKey(publicKey).then(resp => {
      if (resp.type === REGISTER_PUBLIC_KEY_RESULT) {
        props.openModal({
          clientId: resp.response.client_id,
          clientSecret: resp.response.client_secret,
        });
        setIsExternalApiEnabled(true);
        setPublicKey('');
      } else {
        resp.response.status === 400 && props.showNotification(Strings.publicKeyIsInvalid, 5000);
        resp.response.status === 500 && props.showNotification(Strings.errors.internalError, 5000);
      }
    });
  };

  const onDelete = () => {
    props.deletePublicKey().then(resp => {
      if (resp.type === DELETE_PUBLIC_KEY_RESULT) {
        setIsExternalApiEnabled(false);
        props.showNotification(Strings.publicKeyHasBeenDeleted, 5000);
      } else {
        props.showNotification(Strings.internalError, 5000);
      }
    });
  };

  React.useEffect(() => {
    props.getExternalApiStatus().then(resp => {
      if (resp.type === GET_EXTERNAL_API_STATUS_RESULT) {
        if (resp.response.status == 'enabled') {
          setIsExternalApiEnabled(true);
        } else {
          setIsExternalApiEnabled(false);
        }
      } else {
        props.showNotification(Strings.internalError, 5000);
      }
    });
  }, []);

  return (
    <div className="external-api-key-config-container">
      <div className="textarea">
        <label>Public Key (RSA, ECDSA, Ed25519)</label>
        <textarea
          value={publicKey}
          onChange={e => setPublicKey(e.target.value)}
          placeholder="-----BEGIN PUBLIC KEY-----&#10;...&#10;-----END PUBLIC KEY-----"
        />
      </div>
      <div className="buttons">
        <Button class={HEADER_BUTTON_DARK_BLUE} onClick={onSave} title="Save" disabled={publicKey.length === 0} />
        <Button
          class={HEADER_BUTTON_DARK_BLUE}
          onClick={onDelete}
          title={Strings.delete}
          disabled={!isExternalApiEnabled}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getExternalApiStatus: () => dispatch(actions.getStatus(ownProps.organizationId)),
  registerPublicKey: public_key => dispatch(actions.registerPublicKey(ownProps.organizationId, public_key)),
  deletePublicKey: () => dispatch(actions.deletePublicKey(ownProps.organizationId)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  openModal: credentials =>
    dispatch(
      openModalAction('external-api-modal', {
        credentials,
      }),
    ),
});

export default connect(null, mapDispatchToProps)(ExternalApiOauth);
