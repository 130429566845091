import PropTypes from 'prop-types';
import cn from 'classnames';
import React, { PureComponent } from 'react';

import styles from './style.module.scss';

export class RowImage extends PureComponent {
  render() {
    const { image, style = {}, list = '' } = this.props;

    style.backgroundImage = `url(${image})`;
    return <div className={cn(styles.image, { [styles.list]: Boolean(list) })} style={style} />;
  }
}

RowImage.propTypes = {
  image: PropTypes.any,
  list: PropTypes.string,
  style: PropTypes.object,
};
