import moment from 'moment-timezone/moment-timezone';
import { DATE_FORMAT_MONTH_SPACE_YEAR, DATE_MONTH_DAY_YEAR } from '../constants';
import Strings from '../Strings';

export const DateFilters = {
  Custom: {
    label: Strings.custom,
    value: 'custom',
  },
  AllTime: {
    label: Strings.alltime,
    value: 'AllTime',
    dates: {
      startDate: () => moment(0).format(), // is should be lamda since calling format before setting default timezone might lead to wrong result
      endDate: () =>
        moment()
          .endOf('day')
          .format(),
    },
  },
  Last48Hours: {
    label: Strings.last2days,
    value: 'Last48Hours',
    dates: {
      startDate: () =>
        moment()
          .subtract(48, 'hour')
          .format(),
      endDate: () => moment().format(),
    },
  },
  Last7Days: {
    label: Strings.last7days,
    value: 'Last7Days',
    dates: {
      startDate: () =>
        moment()
          .subtract(7, 'day')
          .startOf('day')
          .format(),
      endDate: () => moment().format(),
    },
  },

  Last30Days: {
    label: Strings.last30days,
    value: 'Last30Days',
    dates: {
      startDate: () =>
        moment()
          .subtract(29, 'day')
          .startOf('day')
          .format(),
      endDate: () => moment().format(),
    },
  },
  ThisMonth: {
    label: Strings.thisMonth,
    value: 'ThisMonth',
    dates: {
      startDate: () =>
        moment()
          .startOf('month')
          .format(),
      endDate: () =>
        moment()
          .endOf('month')
          .format(),
    },
  },
  LastMonth: {
    label: Strings.lastMonth,
    value: 'LastMonth',
    dates: {
      startDate: () =>
        moment()
          .subtract(1, 'month')
          .startOf('month')
          .format(),
      endDate: () =>
        moment()
          .subtract(1, 'month')
          .endOf('month')
          .format(),
    },
  },
  Last2Months: {
    label: Strings.patient_medications.last2Months,
    value: 'Last2Months',
    dates: {
      startDate: () =>
        moment()
          .subtract(2, 'month')
          .startOf('month')
          .format(),
      endDate: () =>
        moment()
          .endOf('month')
          .format(),
    },
  },
  Last6Months: {
    label: Strings.patient_medications.last6Months,
    value: 'Last6Months',
    dates: {
      startDate: () =>
        moment()
          .subtract(6, 'month')
          .startOf('month')
          .format(),
      endDate: () =>
        moment()
          .subtract(1, 'month')
          .endOf('month')
          .format(),
    },
  },
  Last12Months: {
    label: Strings.last12Months,
    value: 'Last12Months',
    dates: {
      startDate: () =>
        moment()
          .subtract(12, 'month')
          .startOf('month')
          .format(),
      endDate: () =>
        moment()
          .subtract(1, 'month')
          .endOf('month')
          .format(),
    },
  },
  ThisYear: {
    label: Strings.thisYear,
    value: 'ThisYear',
    dates: {
      startDate: () =>
        moment()
          .startOf('year')
          .format(),
      endDate: () =>
        moment()
          .endOf('month')
          .format(),
    },
  },
};
