import { API_CALL, API_CANCEL } from '../../../middleware/api';
import {
  DELETE_CAPS,
  DELETE_USER_CAPS,
  ORGANIZATION_LIST,
  SHIPPER_CAPS,
  DOWNLOAD_REPORT_DATA,
  SEND_SECURITY_CODE_CAP,
  FEATURE_CONFIGURATION,
  SHIPPER_CAPS_CANCEL,
  GET_DEVICE_STATUS_HISTORY,
  CHANGE_DEVICE_STATUS_HISTORY,
} from './constants';

export const actions = {
  deleteCaps: request => ({
    [API_CALL]: {
      method: 'DELETE',
      endpoint: `/v1/organizations/devices/${request.device_id}/`,
    },
    type: DELETE_CAPS,
    request,
  }),

  deleteCapManagerCaps: request => ({
    [API_CALL]: {
      method: 'DELETE',
      endpoint: `/v1/organizations/devices/${request.device_id}/`,
    },
    type: DELETE_USER_CAPS,
    request,
  }),

  getCaps: (request, signal) => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/devices',
      params: request,
      signal,
    },
    type: SHIPPER_CAPS,
  }),

  cancelGetCaps: controller => ({
    type: SHIPPER_CAPS_CANCEL,
    [API_CANCEL]: { controller },
  }),

  getOrgs: request => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/v1/orgs/',
      params: request,
    },
    type: ORGANIZATION_LIST,
  }),

  downloadReport: params => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/devices/download/events',
      params,
    },
    type: DOWNLOAD_REPORT_DATA,
  }),

  downloadDetails: params => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/devices/download/details',
      params,
    },
    type: DOWNLOAD_REPORT_DATA,
  }),

  deleteCap: request => ({
    [API_CALL]: {
      method: 'Delete',
      endpoint: '/v1/orgs/',
      params: request,
    },
    type: ORGANIZATION_LIST,
  }),

  sendCapsPin: (capId, codes) => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: '/v1/hubs/add-pin/',
      data: { hubId: capId, pins: codes },
    },
    type: SEND_SECURITY_CODE_CAP,
  }),

  featureConfiguration: configuration => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: '/v1/caps/feature_configuration/',
      data: configuration,
    },
    type: FEATURE_CONFIGURATION,
  }),

  getDeviceStatusHistory: deviceId => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: `/devices/${deviceId}/status/history`,
      },
      type: GET_DEVICE_STATUS_HISTORY,
    };
  },

  changeDeviceStatus: (deviceId, data) => {
    return {
      [API_CALL]: {
        method: 'POST',
        endpoint: `/devices/${deviceId}/status`,
        data,
      },
      type: CHANGE_DEVICE_STATUS_HISTORY,
    };
  },
};
