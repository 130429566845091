import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment-timezone';
import { cloneDeep, debounce } from 'lodash';
import axios from 'axios';

import { closeModal, openModalAction } from '../../actions/modal';
import {
  AsyncSelectField,
  DateInput,
  Input,
  MultiSelectField,
  PhoneInputField,
  SelectField,
  TextArea,
} from '../../containers/Form';
import { actions as cohortActions } from '../../pages/SuperUser/Patients/Cohorts/redux/actions';
import { notificationActions } from '../../components/Notification/redux/actions';
import Strings from '../../Strings';
import { validateRpmPatient } from '../../utils/validators/rpmPatient';
import './EditRpmPatientModal.scss';
import Wizard from '../../containers/Modal/Wizard';
import { SelectedOptions } from '../../components/Select/Multiselect';
import { ASSIGN_PATIENT_TO_FACILITY_ERROR } from '../../pages/SuperUser/Patients/Cohorts/redux/constants';
import { DATE_FORMAT_YEAR_MONTH_DAY } from '../../constants';
import { RpmPatientModalPages } from '../_ModalsMetadata/ModalsMetadata';
import { actions } from '../../pages/SuperUser/Patients/redux/actions';

function EditRpmPatientModal(props) {
  const getPatientFromProps = patient => {
    return {
      ...patient,
      dateOfBirth: patient.dateOfBirth ? new Date(moment(patient.dateOfBirth, 'YYYY-MM-DD').format()) : '',
      conditions: Object.keys(patient.conditions),
      medicalHistory: null,
      transplantDetails: patient.transplantDetails.map(td => ({
        ...td,
        transplantDate: td.transplantDate ? new Date(moment(td.transplantDate, 'YYYY-MM-DD').format()) : '',
      })),
    };
  };

  const [patient, setPatient] = useState(
    props.data.patient
      ? getPatientFromProps(props.data.patient)
      : {
          centerDetails: [{}],
          insurance: [{}],
          transplantDetails: [{}],
          language: Object.keys(Strings.languagesEnum)[0],
        },
  );
  const [highlightInvalidFields, setHighlightMissingFields] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);

  useEffect(() => {
    props.getConditions();
    props.getFacilities();
    if (props.data?.patient?.id) {
      props.getMedications(props.data.patient.id);
    }
  }, []);

  useEffect(() => {
    props.facilities &&
      props.conditions &&
      setValidationErrors(validateRpmPatient(patient, props.conditions, props.facilities));
  }, [patient, props.conditions, props.facilities]);

  const onTextChange = event => {
    const { name, value } = event.target;
    setPatient(p => ({
      ...p,
      [name]: value,
    }));
  };

  const onZipCodeSelected = option => {
    setPatient(p => ({
      ...p,
      homeAddress: option.address,
    }));
  };

  const onDateChange = (date, field) => {
    setPatient(p => ({
      ...p,
      [field]: date,
    }));
  };

  const onPhoneChange = (event, field) => {
    setPatient(p => ({
      ...p,
      [field]: event.target.value,
    }));
  };

  const onSelectedOption = (option, field) => {
    if (option.value) {
      setPatient(p => ({
        ...p,
        [field]: option.value,
      }));
    }
  };

  const onConditionsChange = options => {
    setPatient(p => ({
      ...p,
      conditions: options.map(o => o.value),
    }));
  };

  const onRemoveCondition = id => {
    setPatient(p => ({
      ...p,
      conditions: p.conditions.filter(h => h !== id),
    }));
  };

  const addListItem = list => {
    setPatient(p => ({
      ...p,
      [list]: p[list].concat({}),
    }));
  };

  const editListItem = (event, list, index) => {
    const field = event.target.name;
    const value = event.target.value;
    setPatient(p => ({
      ...p,
      [list]: p[list].map((h, i) => {
        if (i === index) {
          return {
            ...h,
            [field]: value,
          };
        }
        return h;
      }),
    }));
  };

  const removeListItem = (list, index) => {
    setPatient(p => ({
      ...p,
      [list]: p[list].filter((_, i) => i !== index),
    }));
  };

  const getDataForSelect = response => {
    if (!response?.results) {
      return [];
    }
    return response.results.map(d => {
      return {
        label: d.formatted,
        value: d.place_id,
        address: `${d.address_line1}\n${d.address_line2}`,
      };
    });
  };
  const _loadOptions = (value, callback) => {
    axios
      .get(
        `https://api.geoapify.com/v1/geocode/autocomplete?text=${value}&apiKey=977d45083f854be6ba51b9fb6b3988a7&format=json`,
      )
      .then(response => {
        callback(getDataForSelect(response.data, value));
      });
  };
  const loadOptions = debounce(_loadOptions, 500);

  const onSave = () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);

    const data = cloneDeep(patient);
    const editedPatient = props.data.patient;

    data.dateOfBirth = moment(patient.dateOfBirth).format(DATE_FORMAT_YEAR_MONTH_DAY);
    // data.timezone = Strings.capPatient.defaultTimeZone;
    data.homeAddress = patient.homeAddress?.trim();
    /* eslint-disable */
    data.transplantDetails.map(transplantDetails => {
      transplantDetails.transplantDate = transplantDetails.transplantDate
        ? moment(transplantDetails.transplantDate).format(DATE_FORMAT_YEAR_MONTH_DAY)
        : null;
    });
    /* eslint-enable */
    data.medications = patient.medications?.filter(m => m.trim().length > 0).map(m => m.trim());

    if (editedPatient && _.isEqual(editedPatient, data)) {
      return;
    }

    const onSuccess = patientData => {
      props.showNotification(Strings.formatString(Strings.capPatient.patientSaved, patientData.patientName));
      props.closeModalWithNextAction(patientData);
    };

    props.onSubmit(data).then(response => {
      if (response) {
        if (response.type === `${props.data.actionType}/error`) {
          const error = response.response?.data?.error?.message;
          props.showNotification(error, 5000, true);
        } else if (patient.facilityId && patient.facilityId !== props.data.patient?.facilityId) {
          props.assignPatientToFacility(parseInt(response.response?.id, 10), patient.facilityId).then(resp => {
            if (resp && resp.type === ASSIGN_PATIENT_TO_FACILITY_ERROR) {
              const assignError = resp.response.data?.error?.message;
              props.showNotification(assignError, 5000, true);
            } else {
              onSuccess({ ...response.response, facilityId: patient.facilityId });
            }
            return resp;
          });
        } else {
          const patientHasReminders = props.patientMedications?.some(m => m.sms_reminder);
          if (props.data?.patient?.id && props.data?.patient?.language !== patient.language && patientHasReminders) {
            const data = {
              title: <span>{Strings.remindersChangeRequired}</span>,
              caption: Strings.remindersChangeRequiredCaption,
              hideCaution: true,
              onConfirmAction: () => {
                props.onNavigate(`/cap-patients/${props.data.patient.id}/medications`),
                  props.closeModal('confirmation-modal');
              },
              onCancelAction: null,
            };
            props.openConfirmModal(data);
          }
          onSuccess(response.response);
        }
      }
      return response;
    });
    setSaveBtnClicked(false);
  };

  const patientDetailsPage = (
    <React.Fragment>
      <Input
        name="firstName"
        id="firstName"
        label={Strings.firstName}
        type="text"
        value={patient.firstName || ''}
        onChange={onTextChange}
        isRequired
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'firstName')?.errors || []}
      />
      <Input
        name="lastName"
        id="lastName"
        label={Strings.lastName}
        type="text"
        value={patient.lastName || ''}
        onChange={onTextChange}
        isRequired
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'lastName')?.errors || []}
      />
      <Input
        name="mrn"
        id="mrn"
        label={Strings.capPatient.mrn}
        type="text"
        value={patient.mrn || ''}
        onChange={onTextChange}
        isRequired
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'mrn')?.errors || []}
      />
      <div className="horizontal-flex">
        <DateInput
          name="dateOfBirth"
          id="dateOfBirth"
          label={Strings.dateOfBirth}
          value={patient?.dateOfBirth || ''}
          format="MM / dd / yyyy"
          maxDate={new Date()}
          onChange={e => onDateChange(e, 'dateOfBirth')}
          isRequired
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'dateOfBirth')?.errors || []}
        />
        <SelectField
          name="gender"
          id="gender"
          value={patient.gender}
          label={Strings.gender}
          placeholder={Strings.SelectField}
          isRequired
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'gender')?.errors || []}
          onChange={option => onSelectedOption(option, 'gender')}
          data={Object.values(Strings.genders).map(v => ({ value: v, label: v }))}
        />
      </div>
      <SelectField
        name="language"
        id="language"
        value={patient.language}
        label={Strings.language}
        placeholder={Strings.SelectField}
        isRequired
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'language')?.errors || []}
        onChange={option => onSelectedOption(option, 'language')}
        data={Object.entries(Strings.languagesEnum).map(([k, v]) => ({ value: k, label: v }))}
      />
    </React.Fragment>
  );

  const contactInformationPage = (
    <React.Fragment>
      <div className="horizontal-flex">
        <PhoneInputField
          align="left"
          name="textPhoneNo"
          id="textPhoneNo"
          value={patient.textPhoneNo}
          onChange={e => onPhoneChange(e, 'textPhoneNo')}
          label={Strings.capPatient.textNumber}
          isRequired
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'textPhoneNo')?.errors || []}
        />
        <PhoneInputField
          align="left"
          name="primaryPhoneNo"
          id="primaryPhoneNo"
          value={patient.primaryPhoneNo}
          onChange={e => onPhoneChange(e, 'primaryPhoneNo')}
          label={Strings.capPatient.phoneNumber}
          isRequired
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'primaryPhoneNo')?.errors || []}
        />
      </div>
      <Input
        name="email"
        id="email"
        label={Strings.email}
        type="text"
        value={patient.email || ''}
        onChange={onTextChange}
        isRequired
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'email')?.errors || []}
      />
      {/* {!patient.homeAddress && (
        <AsyncSelectField
          id="zip-code"
          name="zip-code"
          label="Search address"
          placeholder={Strings.search}
          loadOptions={loadOptions}
          onChange={onZipCodeSelected}
          isRequired
          highlightInvalid={highlightInvalidFields}
        />
      )} */}
      <TextArea
        name="homeAddress"
        id="homeAddress"
        rows={5}
        value={patient.homeAddress || ''}
        label={Strings.capPatient.address}
        onChange={onTextChange}
        isRequired
        highlightInvalid={highlightInvalidFields}
        errorsForTooltip={validationErrors.find(v => v.property === 'homeAddress')?.errors || []}
      />
    </React.Fragment>
  );

  const medicalHistoryPage = (
    <React.Fragment>
      {props.conditions && (
        <MultiSelectField
          name="primaryCondition"
          id="primaryCondition"
          label={Strings.condition}
          placeholder={Strings.selectCondition}
          value={patient.conditions || []}
          onChange={onConditionsChange}
          data={props.conditions.map(c => ({ value: c.id, label: c.title }))}
          isRequired
          highlightInvalid={highlightInvalidFields}
          checkboxFirst
        />
      )}
      <div className="hr" />
      {patient.conditions && patient.conditions.length > 0 && props.conditions ? (
        <SelectedOptions
          items={patient.conditions.map(m => {
            return { value: m, label: props.conditions?.find(c => c.id === m)?.title };
          })}
          onRemove={e => onRemoveCondition(e.value)}
          itemsPerRow={1}
        />
      ) : (
        <div>{Strings.noConditionsSelectFielded}</div>
      )}
      <div className="control-with-top-right-text">
        <TextArea
          name="medications"
          id="medications"
          rows={5}
          value={patient.medications?.join('\n') || ''}
          label={Strings.medications}
          onChange={e =>
            onTextChange({ ...e, target: { ...e.target, value: e.target.value.split('\n'), name: 'medications' } })
          }
        />
        <div
          className="right-top-text"
          data-tooltip-content={Strings.capPatient.medicationsTooltip}
          data-tooltip-id="tooltip"
        >
          <span className="icon">?</span>
        </div>
      </div>
    </React.Fragment>
  );

  const centerDetailsPage = (
    <React.Fragment>
      {props.facilities && props.facilities.length > 0 && (
        <SelectField
          name="facility"
          id="facility"
          label={Strings.facility}
          value={patient.facilityId}
          onChange={option => onSelectedOption(option, 'facilityId')}
          placeholder={Strings.selectFacility}
          data={props.facilities.map(f => {
            return { value: f.id, label: f.title };
          })}
          isRequired
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'facilityId')?.errors || []}
        />
      )}
      <div className="hr" />
      {patient.centerDetails &&
        patient.centerDetails.length > 0 &&
        patient.centerDetails.map((c, i) => (
          <React.Fragment key={`centerDetails_${i}`}>
            <div className="horizontal-flex">
              <div className="vertical-flex">
                <Input
                  name="orderingProvider"
                  id="orderingProvider"
                  label={Strings.capPatient.orderingProvider}
                  type="text"
                  value={c.orderingProvider || ''}
                  onChange={e => editListItem(e, 'centerDetails', i)}
                  errorsForTooltip={
                    validationErrors.find(
                      v => v.property === 'centerDetails' && v.index === i && v.field === 'orderingProvider',
                    )?.errors || []
                  }
                />
                <PhoneInputField
                  align="left"
                  name="clinicContact"
                  id="clinicContact"
                  label={Strings.capPatient.clinicContact}
                  type="text"
                  value={c.clinicContact || ''}
                  onChange={e =>
                    editListItem({ ...e, target: { ...e.target, name: 'clinicContact' } }, 'centerDetails', i)
                  }
                  errorsForTooltip={
                    validationErrors.find(
                      v => v.property === 'centerDetails' && v.index === i && v.field === 'clinicContact',
                    )?.errors || []
                  }
                />
              </div>
              <div className="button-container mt-20">
                <button
                  className="minus-button high"
                  onClick={() => removeListItem('centerDetails', i)}
                  disabled={patient.centerDetails.length === 1}
                />
              </div>
            </div>
            <div className="hr" />
          </React.Fragment>
        ))}
      <div className="button-container ">
        <button className="plus-button" onClick={() => addListItem('centerDetails')}></button>
      </div>
    </React.Fragment>
  );

  const insurancePage = (
    <React.Fragment>
      {patient.insurance &&
        patient.insurance.length > 0 &&
        patient.insurance.map((ins, i) => (
          <React.Fragment key={`ins_${i}`}>
            <div className=" horizontal-flex">
              <div className="vertical-flex">
                <Input
                  name="insuranceName"
                  id="insuranceName"
                  label={Strings.capPatient.insuranceName}
                  type="text"
                  value={ins.insuranceName || ''}
                  onChange={e => editListItem(e, 'insurance', i)}
                  errorsForTooltip={
                    validationErrors.find(
                      v => v.property === 'insurance' && v.index === i && v.field === 'insuranceName',
                    )?.errors || []
                  }
                />
                <div className="horizontal-flex">
                  <Input
                    name="policyId"
                    id="policyId"
                    label={Strings.capPatient.policyId}
                    type="text"
                    value={ins.policyId || ''}
                    onChange={e => editListItem(e, 'insurance', i)}
                    errorsForTooltip={
                      validationErrors.find(v => v.property === 'insurance' && v.index === i && v.field === 'policyId')
                        ?.errors || []
                    }
                  />
                  <Input
                    name="groupNumber"
                    id="groupNumber"
                    label={Strings.capPatient.groupNumber}
                    type="number"
                    value={ins.groupNumber || ''}
                    onChange={e => editListItem(e, 'insurance', i)}
                    errorsForTooltip={
                      validationErrors.find(
                        v => v.property === 'insurance' && v.index === i && v.field === 'groupNumber',
                      )?.errors || []
                    }
                  />
                </div>
              </div>
              <div className="button-container ">
                <button
                  className="minus-button high"
                  onClick={() => removeListItem('insurance', i)}
                  disabled={patient.insurance.length === 1}
                />
              </div>
            </div>
            <div className="hr" />
          </React.Fragment>
        ))}
      <div className="button-container ">
        <button className="plus-button" onClick={() => addListItem('insurance')}></button>
      </div>
    </React.Fragment>
  );

  const transplantDetailsPage = (
    <React.Fragment>
      {patient.transplantDetails &&
        patient.transplantDetails.length > 0 &&
        patient.transplantDetails.map((t, i) => (
          <React.Fragment key={`transplantDet_${i}`}>
            <div className="horizontal-flex">
              <div className="vertical-flex">
                <Input
                  name="organType"
                  id="organType"
                  label={Strings.capPatient.transplantOrganType}
                  type="text"
                  value={t.organType || ''}
                  onChange={e => editListItem(e, 'transplantDetails', i)}
                  errorsForTooltip={
                    validationErrors.find(
                      v => v.property === 'transplantDetails' && v.index === i && v.field === 'organType',
                    )?.errors || []
                  }
                />
                <DateInput
                  name="transplantDate"
                  id="transplantDate"
                  label={Strings.capPatient.transplantDate}
                  placeholder="MM / DD / YYYY"
                  value={t.transplantDate || ''}
                  format="MM / dd / yyyy"
                  onChange={e => editListItem({ target: { value: e, name: 'transplantDate' } }, 'transplantDetails', i)}
                  highlightInvalid={highlightInvalidFields}
                  errorsForTooltip={
                    validationErrors.find(
                      v => v.property === 'transplantDetails' && v.index === i && v.field === 'transplantDate',
                    )?.errors || []
                  }
                />
              </div>
              <div className="button-container ">
                <button
                  className="minus-button high"
                  onClick={() => removeListItem('transplantDetails', i)}
                  disabled={patient.transplantDetails.length === 1}
                />
              </div>
            </div>
            <div className="hr" />
          </React.Fragment>
        ))}
      <div className="button-container ">
        <button className="plus-button" onClick={() => addListItem('transplantDetails')}></button>
      </div>
    </React.Fragment>
  );

  const patientDetailsFields = ['firstName', 'lastName', 'mrn', 'dateOfBirth', 'gender'];
  const contactInformationFields = ['primaryPhoneNo', 'textPhoneNo', 'email', 'homeAddress', 'gender'];
  const medicalHistoryFields = ['conditions'];
  const insuranceFields = ['insurance'];
  const centerDetailsFields = ['facility', 'centerDetails'];
  const transplantDetailsFields = ['transplantDetails'];
  const allFields = [
    ...patientDetailsFields,
    ...contactInformationFields,
    ...medicalHistoryFields,
    ...insuranceFields,
    ...centerDetailsFields,
    ...transplantDetailsFields,
  ];

  const pages = [
    {
      id: RpmPatientModalPages.patientDetails.id,
      title: RpmPatientModalPages.patientDetails.name,
      content: patientDetailsPage,
      emptyFieldsCount: validationErrors.filter(v => patientDetailsFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => patientDetailsFields.includes(v.property)).length === 0,
    },
    {
      id: RpmPatientModalPages.contactInformation.id,
      title: RpmPatientModalPages.contactInformation.name,
      content: contactInformationPage,
      emptyFieldsCount: validationErrors.filter(v => contactInformationFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => contactInformationFields.includes(v.property)).length === 0,
    },
    {
      id: RpmPatientModalPages.medicalHistory.id,
      title: RpmPatientModalPages.medicalHistory.name,
      content: medicalHistoryPage,
      emptyFieldsCount: validationErrors.filter(v => medicalHistoryFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => medicalHistoryFields.includes(v.property)).length === 0,
    },
    {
      id: RpmPatientModalPages.centerDetails.id,
      title: RpmPatientModalPages.centerDetails.name,
      content: centerDetailsPage,
      emptyFieldsCount: validationErrors.filter(v => centerDetailsFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => centerDetailsFields.includes(v.property)).length === 0,
    },
    {
      id: RpmPatientModalPages.insurance.id,
      title: RpmPatientModalPages.insurance.name,
      content: insurancePage,
      emptyFieldsCount: validationErrors.filter(v => insuranceFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => insuranceFields.includes(v.property)).length === 0,
    },
    {
      id: RpmPatientModalPages.transplantDetails.id,
      title: RpmPatientModalPages.transplantDetails.name,
      content: transplantDetailsPage,
      emptyFieldsCount: validationErrors.filter(v => transplantDetailsFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => transplantDetailsFields.includes(v.property)).length === 0,
    },
    {
      id: RpmPatientModalPages.summary.id,
      title: RpmPatientModalPages.summary.name,
      content: (
        <React.Fragment>
          <div className="page-info-header">{Strings.capPatient.patientDetails}</div>
          {patientDetailsPage}
          <div className="page-info-header">{Strings.capPatient.contactInformation}</div>
          {contactInformationPage}
          <div className="page-info-header">{Strings.capPatient.medicalHistory}</div>
          {medicalHistoryPage}
          <div className="page-info-header">{Strings.capPatient.providerCenterDetails}</div>
          {centerDetailsPage}
          {props.pagesVisibility.find(p => p.id === RpmPatientModalPages.insurance.id)?.visible && (
            <React.Fragment>
              <div className="page-info-header">{Strings.capPatient.insurance}</div>
              {insurancePage}
            </React.Fragment>
          )}
          {props.pagesVisibility.find(p => p.id === RpmPatientModalPages.transplantDetails.id)?.visible && (
            <React.Fragment>
              <div className="page-info-header">{Strings.capPatient.transplantDetails}</div>
              {transplantDetailsPage}
            </React.Fragment>
          )}
        </React.Fragment>
      ),
      emptyFieldsCount: validationErrors.filter(v => allFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => allFields.includes(v.property)).length === 0,
    },
  ];

  const getpagesToShow = () => {
    const ret =
      props.data.pages && props.data.pages.length > 0
        ? props.data.pages.map(p => pages.find(pg => pg.id === p.id))
        : pages;

    if (props.data.ignoreFilters || !props.pagesVisibility) {
      return ret;
    }
    return ret.filter(p => props.pagesVisibility?.some(pg => pg.id === p.id && pg.visible));
  };

  return (
    <Wizard
      name="edit-rpm-patient"
      pages={getpagesToShow()}
      onNextButtonHover={e => setHighlightMissingFields(e)}
      onSubmit={onSave}
      showPagesFilter={!props.data.ignoreFilters}
    />
  );
}

EditRpmPatientModal.propTypes = {
  data: PropTypes.shape({
    pages: PropTypes.array,
    patient: PropTypes.any,
    action: PropTypes.func,
    actionType: PropTypes.string,
    ignoreFilters: PropTypes.bool,
    workflow: PropTypes.any,
  }),
  onCancel: PropTypes.any,
  onSubmit: PropTypes.func,
  getConditions: PropTypes.func,
  conditions: PropTypes.array,
  getFacilities: PropTypes.func,
  facilities: PropTypes.array,
  assignPatientToFacility: PropTypes.func,
  showNotification: PropTypes.func,
  closeModalWithNextAction: PropTypes.func,
  pagesVisibility: PropTypes.array,
  getMedications: PropTypes.func,
  openConfirmModal: PropTypes.func,
  closeModal: PropTypes.func,
  onNavigate: PropTypes.func,
  patientMedications: PropTypes.array,
};

EditRpmPatientModal.defaultProps = { data: { ignoreFilters: false } };

const mapStateToProps = state => {
  return {
    conditions: state.superUser.cohorts?.conditions,
    facilities: state.superUser.cohorts?.facilities,
    patientMedications: state.superUser?.patients?.patientMedications?.medications,
    pagesVisibility: state.modalsVisibility.pagesVisibility.find(m => m.id === 'edit-rpm-patient').pages,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('edit-rpm-patient')),
  closeModalWithNextAction: patient => {
    dispatch(closeModal('edit-rpm-patient'));
    if (ownProps.data.nextAction) dispatch(ownProps.data.nextAction);
    if (ownProps.data.onSuccess) {
      ownProps.data.onSuccess(patient);
    }
  },
  onSubmit: data => {
    return dispatch(ownProps.data.action(data, data.id));
  },
  getConditions: () => dispatch(cohortActions.getConditions()),
  getFacilities: () => dispatch(cohortActions.getFacilities()),
  assignPatientToFacility: (patientId, facilityId) =>
    dispatch(cohortActions.assignPatientToFacility(patientId, facilityId)),
  getMedications: patientId => dispatch(actions.getMedications(patientId)),
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  closeModal: modal => dispatch(closeModal(modal)),
  onNavigate: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRpmPatientModal);
