import React from 'react';
import PropTypes from 'prop-types';

export const Tab = ({ children }) => <div>{children}</div>;

Tab.propTypes = { children: PropTypes.any };

export class Tabs extends React.Component {
  state = { activeIndex: this.props.defaultIndex || 0 };

  render() {
    const { activeIndex } = this.state;

    const tabs = React.Children.map(this.props.children, (child, index) => {
      const style = activeIndex === index ? 'tabButton tab-label active-tab-label' : 'tabButton tab-label';
      return (
        <button className={style} onClick={() => this.setState({ activeIndex: index })}>
          {child.props.label}
        </button>
      );
    });
    return (
      <div>
        <div className="tab-buttons-row">{tabs}</div>
        {this.props.children[this.state.activeIndex]}
      </div>
    );
  }
}

Tabs.propTypes = {
  children: PropTypes.any,
  defaultIndex: PropTypes.number,
};

Tabs.defaultProps = { defaultIndex: 0 };
