import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isBrowser } from 'react-device-detect';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { isESIPatient } from '../../utils/userRoles';
import TZ from '../TZ';
import styles from './SideMenu.module.scss';
import DiagnosticInfo from './DiagnosticInfo';

export class SideMenu extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.element),
    path: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    visible: false,
    path: '',
  };

  render() {
    const { visible, children, path, onChange, className } = this.props;
    const isPatient = isESIPatient();
    const isDev = process.env.NODE_ENV === 'development';
    const showDiagnosticInfo = isDev && !isPatient;

    return isBrowser ? (
      <nav
        className={cn(styles.sideMenu, className, {
          [styles.expanded]: Boolean(visible),
          [styles.collapsed]: !visible,
        })}
      >
        <OverlayScrollbarsComponent
          className={cn(styles.menuItems, {
            [styles.substractDiagInfoHeight]: showDiagnosticInfo,
            [styles.collapsed]: !visible,
          })}
          defer
          options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100', theme: 'os-theme-sidebar' } }}
        >
          {children
            .filter(x => x)
            .map(x =>
              React.cloneElement(x, {
                key: x.props.tag,
                active: (x.props.isActive && x.props.isActive(path)) || path.startsWith(x.props.to),
                onClick: x.props.onClick || onChange,
              }),
            )}
        </OverlayScrollbarsComponent>
        <div className={cn(styles.emptyMenuItem, { [styles.levelUp]: showDiagnosticInfo })}>
          {Boolean(visible) && (
            <React.Fragment>
              <TZ />
            </React.Fragment>
          )}
        </div>
        {showDiagnosticInfo && <DiagnosticInfo visible={visible} />}
      </nav>
    ) : (
      <nav
        className={cn(styles.mobileMenu, {
          [styles.mobileMenuOpened]: visible,
        })}
      >
        <OverlayScrollbarsComponent
          className={cn(styles.mobileMenuScroll, 'scrollbar-right-margin')}
          defer
          options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100', theme: 'os-theme-sidebar' } }}
        >
          <div className={styles.mobileMenuItems}>
            {children
              .filter(x => x)
              .map(x =>
                React.cloneElement(x, {
                  key: x.props.tag,
                  active: (x.props.isActive && x.props.isActive(path)) || path.startsWith(x.props.to),
                  onClick: x.props.onClick || onChange,
                }),
              )}
          </div>
        </OverlayScrollbarsComponent>
      </nav>
    );
  }
}
