import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actionsDashboard as actions } from './redux/actions';
import Strings from '../../Strings';
import { DropdownTableFilter } from '../../components/DropdownTableFilter';
import { GetWidgetsVisibility } from './DashboardsMetadata';

const mapStateToProps = (state, ownProps) => {
  const { dashboardFilters } = state.dashboardState;
  const { permissions } = state.auth;
  const filters = GetWidgetsVisibility(
    ownProps.dashboardId,
    dashboardFilters[ownProps.dashboardId],
    ownProps.additionalData,
    permissions,
  );
  return {
    filters,
    permissions,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeVisibility: (widgetId, visible, permissions) =>
    dispatch(actions.changeVisibility(ownProps.dashboardId, widgetId, visible, ownProps.additionalData, permissions)),
  resetToDefault: permissions =>
    dispatch(actions.resetToDefault(ownProps.dashboardId), ownProps.additionalData, permissions),
});

function DashboardFilter(props) {
  return (
    <div className="widgetSelectionContainer">
      <DropdownTableFilter
        filters={props.filters ? props.filters : []}
        resetToDefaults={() => props.resetToDefault(props.permissions)}
        handleChange={(item, value) => props.changeVisibility(item, value, props.permissions)}
        topLabelText={Strings.showSelectedWidgets}
        dontDisableFirstEntry
      />
    </div>
  );
}

DashboardFilter.propTypes = {
  filters: PropTypes.array.isRequired,
  dashboardId: PropTypes.string,
  onResetToDefaults: PropTypes.func,
  additionalData: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardFilter);
