import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { returnOrCall } from '../../../utils';
import styles from './style.module.scss';

export class Panel extends PureComponent {
  render() {
    const { title, buttonTitle, onClick, children, ariaLabel } = this.props;

    return (
      <div className={styles.panel}>
        <div className={styles.title}>
          {returnOrCall(title)}
          <div className={styles.button_container}>
            <button onClick={onClick} aria-label={ariaLabel} role="button">
              {buttonTitle}
            </button>
          </div>
        </div>
        {children}
      </div>
    );
  }
}

Panel.propTypes = {
  buttonTitle: PropTypes.any,
  children: PropTypes.any,
  onClick: PropTypes.any,
  title: PropTypes.any,
};
