import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions } from '../../modals/_ModalsMetadata/redux/actions';
import { getModalsDetails } from '../../modals/_ModalsMetadata/ModalsMetadata';
import './Workflows.scss';
import { hasAnyPermissionInGroup, hasPermission } from '../../utils/userPermissions';

const WorkflowsSettings = props => {
  const workflows = props.modalsVisibility?.filter(
    w =>
      (w.permissionGroup ? hasAnyPermissionInGroup(w.permissionGroup) : true) &&
      (w.permission ? hasPermission(w.permission) : true),
  );

  return (
    <React.Fragment>
      {workflows &&
        workflows.map(workflow => (
          <div key={`flow_${workflow.id}`}>
            <h3>{workflow.name}</h3>

            {workflow.modals && workflow.modals.length > 1 && (
              <div>
                {workflow.modals.map(modal => (
                  <div key={`modal_${workflow.id}_${modal.id}`} className="modals-checkboxes">
                    <input
                      type="checkbox"
                      className="filter-label"
                      checked={modal.visible}
                      disabled={modal.isRequired}
                      onChange={() => props.changeModalVisibility(workflow.id, modal.id)}
                    />
                    {modal.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    modalsVisibility: getModalsDetails(state.modalsVisibility.modalsVisibility),
  };
};

WorkflowsSettings.propTypes = {
  changeModalVisibility: PropTypes.func,
  modalsVisibility: PropTypes.any,
};

const mapDispatchToProps = dispatch => ({
  changeModalVisibility: (workflowId, modalId) => dispatch(actions.changeModalVisibility(workflowId, modalId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowsSettings);
