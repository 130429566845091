import React from 'react';
import PropTypes from 'prop-types';

import TableWidget from '../pages/SuperUser/Patients/component/TableWidget';

const ListOfTables = ({ data, rows, title, onClick }) => {
  return (
    <div className="list-table">
      {data.map((item, index) => (
        <div key={index} className="list-table-border">
          <TableWidget
            key={index}
            title={title(item)}
            data={rows.map(r => {
              return [r.props.title, r.props.value(item)];
            })}
            isCollapsible
            onClick={() => onClick(item)}
          />
        </div>
      ))}
    </div>
  );
};

ListOfTables.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.func.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  title: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default ListOfTables;
