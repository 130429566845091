export const scatterDotShapeEnum = Object.freeze({
  none: 'none',
  circle: 'circle',
  cross: 'cross',
  crossFat: 'crossFat',
  diamond: 'diamond',
  square: 'square',
  star: 'star',
  triangle: 'triangle',
  wye: 'wye',
  smileUp: 'smileUp',
  smileDown: 'smileDown',
  plus: 'plus',
});

export const symbolDotSizeEnum = Object.freeze({
  micro: 6,
  small: 9,
  normal: 12,
  big: 25,
});

const _metricsFormat = [
  {
    stroke: '#003f5c',
    strokeWidth: 1.5,
    dotR: 1.5,
    type: 'monotone',
    strokeDasharray: '',
    dotShape: scatterDotShapeEnum.star,
    dotSize: symbolDotSizeEnum.small,
  },
  {
    stroke: '#008e89',
    strokeWidth: 1.5,
    dotR: 1.5,
    type: 'monotone',
    strokeDasharray: '',
    dotShape: scatterDotShapeEnum.square,
    dotSize: symbolDotSizeEnum.small,
  },
  {
    stroke: '#85d34c',
    strokeWidth: 1.5,
    dotR: 1.5,
    type: 'monotone',
    strokeDasharray: '',
    dotShape: scatterDotShapeEnum.diamond,
    dotSize: symbolDotSizeEnum.small,
  },
  {
    stroke: '#fae622',
    strokeWidth: 1.5,
    dotR: 1.5,
    type: 'monotone',
    strokeDasharray: '',
    dotShape: scatterDotShapeEnum.square,
    dotSize: symbolDotSizeEnum.small,
  },
  {
    stroke: '#00b375',
    strokeWidth: 1.5,
    dotR: 1.5,
    type: 'monotone',
    strokeDasharray: '',
    dotShape: scatterDotShapeEnum.star,
    dotSize: symbolDotSizeEnum.small,
  },
  {
    stroke: '#006680',
    strokeWidth: 1.5,
    dotR: 1.5,
    type: 'monotone',
    strokeDasharray: '',
    dotShape: scatterDotShapeEnum.cross,
    dotSize: symbolDotSizeEnum.small,
  },
];
export function getMetricDefaultFormat(index) {
  if (index >= _metricsFormat.length) return _metricsFormat[_metricsFormat.length - 1];
  return _metricsFormat[index];
}
