import cn from 'classnames';

import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Calendar as ReactBigCalendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { connect } from 'react-redux';

import { patientAction } from '../../actions/patient';
import { SELECT_TYPE_LEFT, SELECT_TYPE_RIGHT } from '../../components/Inputs/SelectInput';
import { PageHeader } from '../../components/PageHeader';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../components/PageHeader/Button';
import Select from '../../components/Select/SelectOld';
import Strings from '../../Strings';
import { downloadFileOnly, formatTimeFromSeconds, momentHourMinutesToSeconds } from '../../utils';
import DayInfo from './DayInfo';
import style from './index.module.scss';
import { calendarActions } from './redux/actions';

const mapStateToProps = state => {
  const { history, loading } = state.entities.history;
  return {
    history,
    isLoading: loading !== false,
  };
};

const mapDispatchToProps = dispatch => ({
  loadSchedules: selectedMonth => dispatch(calendarActions.getAdherence(selectedMonth)),
  downloadPatientData: data => dispatch(patientAction.downloadPatientData(data)),
});

moment.locale('en-GB'); // To set first day to monday
const localizer = momentLocalizer(moment);

const statuses = [Strings.statusAll, Strings.statusMissed, Strings.statusUpcoming, Strings.statusTaken];

export const dateToYYYYMMDD = date => {
  const y = date.getFullYear().toString();
  let m = (date.getMonth() + 1).toString();
  let d = date.getDate().toString();

  if (m < 10) m = `0${m}`;
  if (d < 10) d = `0${d}`;

  return y + m + d;
};
class Calendar extends PureComponent {
  refreshEnable = true;
  state = {
    selectedDate: new Date(),
    selectedMonth: new Date().getMonth(),
    selectedStatus: null,
    selectedTime: null,
    selectedMedication: null,
    selectedMonthDate: new Date(),
  };

  componentDidMount() {
    this.loadData();
  }

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 30000);
    this.loadData();
  };

  loadData(date = undefined) {
    this.props.loadSchedules(
      moment(date)
        .utc()
        .startOf('month')
        .unix(),
    );
  }

  customToolbar = props => {
    const changeMonth = step => {
      const date = props.date;
      const newDate = new Date(date.setMonth(date.getMonth() + step));
      this.setState({ selectedMonth: newDate.getMonth() });
      this.loadData(newDate);
      this.setState({ selectedMonthDate: newDate });
      props.onNavigate('prev', newDate);
    };

    const goToBack = () => {
      changeMonth(-1);
    };

    const goToNext = () => {
      changeMonth(1);
    };

    const date = moment(props.date);

    return (
      <div className={style.customToolbar}>
        <button onClick={goToBack} className={style.left} aria-label="Previous month" />
        <div className={style.title}>
          {date.format('MMMM')}, {date.format('YYYY')}
        </div>
        <button onClick={goToNext} className={style.right} aria-label="Next month" />
      </div>
    );
  };

  customDateHeader = props => {
    const { history } = this.props;
    const { label, onDrillDown, date } = props;
    const { selectedDate } = this.state;

    const selectedDay = dateToYYYYMMDD(selectedDate);

    const dateDay = date?.getDate();

    const dayData = history?.days[dateDay];

    let showPerformance = false;

    if (dayData?.medicines.length) {
      dayData.medicines.forEach(e => {
        if (e.status !== 'upcoming') {
          showPerformance = true;
        }
      });
    }

    return (
      <a
        href="#"
        className={cn(
          moment(date).format('YYYYMMDD') === selectedDay ? 'selected' : null,
          showPerformance ? dayData?.performance : null,
        )}
        onClick={onDrillDown}
      >
        {label}
      </a>
    );
  };

  customCellWrapper = props => {
    const { value, children } = props;
    const { selectedDate } = this.state;

    const selectedDay = dateToYYYYMMDD(selectedDate);

    return (
      <div className={cn('rbc-cell-wrapper', moment(value).format('YYYYMMDD') === selectedDay ? 'selected' : null)}>
        {children}
      </div>
    );
  };

  customEventWrapper = props => {
    const { event, children } = props;
    const { selectedDate } = this.state;

    const selectedDay = dateToYYYYMMDD(selectedDate);

    return (
      <div
        className={cn('rbc-event-wrapper', moment(event.start).format('YYYYMMDD') === selectedDay ? 'selected' : null)}
      >
        {children}
      </div>
    );
  };

  onTimeFilterChange = value => {
    if (value === 'All Times') {
      value = null; // eslint-disable-line no-param-reassign
    }

    this.setState({ selectedTime: value });
  };

  onStatusFilterChange = value => {
    this.setState({
      selectedStatus:
        value === 'Status - All'
          ? null
          : value
              .split(' ')
              .slice(-1)[0]
              .toLowerCase(),
    });
  };

  onMedFilterChange = value => {
    if (value === Strings.patientDashboard.allMeds) {
      value = null; // eslint-disable-line no-param-reassign
    }

    this.setState({ selectedMedication: value });
  };

  getEvents = () => {
    const { isLoading, history } = this.props;

    const events = [];

    if (isLoading || !history) return events;

    Object.values(history.days).map((day, idx) => {
      const medicines = [];

      day.medicines.forEach(med => {
        medicines.push(med);
      });

      if (medicines.length === 0) return;

      const medsByStatuses = {};

      medicines.forEach(med => {
        if (!medsByStatuses[med.status]) medsByStatuses[med.status] = [];
        medsByStatuses[med.status].push(med);
      });

      Object.keys(medsByStatuses).forEach(status => {
        const meds = medsByStatuses[status];

        const txtStatus =
          status === 'upcoming'
            ? Strings.scheduled
            : status === 'missed'
            ? Strings.missed
            : status === 'taken'
            ? Strings.taken
            : 'Unknown';

        const momentDate = moment.unix(meds[0].time);

        const dateStart = new Date(
          momentDate.year(),
          momentDate.month(),
          momentDate.date(),
          momentDate.hour(),
          momentDate.minute(),
          momentDate.second(),
        );

        const dateEnd = new Date(
          momentDate.year(),
          momentDate.month(),
          momentDate.date(),
          momentDate.hour(),
          momentDate.minute(),
          momentDate.second(),
        );

        events.push({
          id: `${idx}_${status}`,
          title: txtStatus,
          medicines: meds,
          start: dateStart,
          end: dateEnd,
        });
      });
    });

    return events;
  };

  getFilteredEvents = () => {
    const { selectedMedication, selectedStatus, selectedTime } = this.state;

    const events = this.getEvents();

    const filteredEvents = [];

    if (!events) return filteredEvents;

    events.map(e => {
      const medicines = e.medicines;
      e.medicines = [];

      medicines.map(med => {
        if (selectedStatus && selectedStatus !== med.status) return;
        if (selectedMedication && selectedMedication !== med.name) return;
        if (selectedTime && selectedTime !== moment.unix(med.time).format('hh:mm A')) return;

        e.medicines.push(med);
      });

      if (e.medicines.length) {
        e.title = `${e.medicines.length} ${e.title}`;
        filteredEvents.push(e);
      }
    });

    return filteredEvents;
  };

  renderTopPanel() {
    const events = this.getEvents();
    const timeDataText = [Strings.allTimes];
    const timeDataIntegers = [];

    events.forEach(e => {
      e.medicines.forEach(m => {
        const mom = moment.unix(m.time);
        timeDataIntegers.push(momentHourMinutesToSeconds(mom));
      });
    });

    const timeData = _.uniq(timeDataIntegers);
    timeData.sort((a, b) => a - b);

    timeData.forEach(e => {
      timeDataText.push(formatTimeFromSeconds(e));
    });

    return (
      <div className={cn(style.panel, style.topPanel)}>
        <Select
          type={SELECT_TYPE_LEFT}
          autoWidth
          classes={[Select.CLASS_WHITE_BLUE, Select.CLASS_BIG, Select.CLASS_NO_MARGIN_LEFT, Select.CLASS_LEFT_OPTIONS]}
          data={timeDataText}
          onChange={this.onTimeFilterChange}
          aria-label="select timings from list"
          id="timeSelect"
        />
        <Select
          classes={[Select.CLASS_WHITE_BLUE, Select.CLASS_BIG]}
          style={{ textAlignLast: 'right' }}
          data={statuses}
          onChange={this.onStatusFilterChange}
          aria-label="select status from list"
          id="statusSelect"
        />
      </div>
    );
  }

  selectDate(date) {
    const { selectedMonth } = this.state;

    if (date.getMonth() !== selectedMonth) return;

    this.setState({ selectedDate: date });
  }

  handleSelectEvent = ({ start }) => {
    this.selectDate(start);
  };

  handleDrillDown = date => {
    this.selectDate(date);
  };

  handleSelectSlot = ({ start }) => {
    this.selectDate(start);
  };

  renderCalendar() {
    const { isLoading } = this.props;
    return (
      <div className={style.panel}>
        {isLoading && <div className="spinner" />}
        <ReactBigCalendar
          localizer={localizer}
          events={this.getFilteredEvents()}
          views={['month']}
          selectable
          onSelectSlot={this.handleSelectSlot}
          onSelectEvent={this.handleSelectEvent}
          onDrillDown={this.handleDrillDown}
          components={{
            toolbar: this.customToolbar,
            dateHeader: this.customDateHeader,
            dateCellWrapper: this.customCellWrapper,
            eventWrapper: this.customEventWrapper,
          }}
        />
      </div>
    );
  }

  renderRightPanel() {
    const { selectedDate } = this.state;
    const momentDate = moment(selectedDate);
    const formattedDate = dateToYYYYMMDD(selectedDate);
    const events = this.getFilteredEvents();

    const currentEvents = events.filter(e => {
      return dateToYYYYMMDD(e.start) === formattedDate;
    });

    return (
      <div className={cn(style.panel, style.rightPanel)}>
        <div className={style.title}>{momentDate.format('MMMM DD - dddd')}</div>
        <DayInfo events={currentEvents} />
      </div>
    );
  }

  onDownloadCalendar = () => {
    const { selectedMonthDate } = this.state;
    const data = {
      startDate: moment(selectedMonthDate)
        .startOf('month')
        .toDate(),
      endDate: moment(selectedMonthDate)
        .endOf('month')
        .toDate(),
      search: '',
    };
    this.props.downloadPatientData(data).then(req => {
      downloadFileOnly(req, req.response?.name ?? 'doc.zip');
    });
  };

  getHeaderComponentsRight() {
    const events = this.getEvents();
    const medData = [{ value: Strings.patientDashboard.allMeds, text: Strings.patientDashboard.allMeds }];

    const medNames = [];
    events.forEach(e => {
      e.medicines.forEach(m => {
        medNames.push(m.name);
      });
    });

    _.uniq(medNames).forEach(e => {
      medData.push({ value: e, text: _.truncate(e, { length: 120 }) });
    });

    return (
      <Select
        type={SELECT_TYPE_RIGHT}
        autoWidth
        classes={[
          Select.CLASS_BIG,
          Select.CLASS_DARK,
          Select.CLASS_NO_MARGIN_RIGHT,
          Select.CLASS_NO_BORDER,
          Select.CLASS_MAX_WIDTH_600,
        ]}
        data={medData}
        onChange={this.onMedFilterChange}
        aria-label="select medicine from list"
        id="medicineSelect"
      />
    );
  }

  render() {
    return (
      <>
        <PageHeader
          noLeftPadding
          left={
            <div style={{ display: 'flex' }}>
              <Button
                class={HEADER_BUTTON_DARK_BLUE}
                onClick={this.onDownloadCalendar}
                title={Strings.downloadCalendar}
                first
              />
              <button
                className="refresh_button"
                style={{ marginLeft: '15px' }}
                disabled={!this.refreshEnable}
                onClick={this.onRefresh}
                aria-label="Refresh the data'"
              ></button>
            </div>
          }
          right={() => this.getHeaderComponentsRight()}
        />
        {this.renderTopPanel()}
        <div className={style.mainPanel}>
          {this.renderCalendar()}
          {this.renderRightPanel()}
        </div>
      </>
    );
  }
}

Calendar.propTypes = {
  adherenceClass: PropTypes.func,
  downloadPatientData: PropTypes.func,
  event: PropTypes.any,
  history: PropTypes.shape({ days: PropTypes.any }),
  isLoading: PropTypes.any,
  loadSchedules: PropTypes.func,
  label: PropTypes.any,
  onDrillDown: PropTypes.any,
  date: PropTypes.any,
  children: PropTypes.any,
  value: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
