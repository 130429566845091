import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Wrapper, Button as MenuButton, MenuItem, Menu } from 'react-aria-menubutton';

import './ModalsVisibilityMenu.scss';
import { actions } from './redux/actions';
import { getPagesDetails } from './ModalsMetadata';

const ModalsVisibilityMenu = props => {
  const [isListOpen, setIsListOpen] = useState(false);

  useEffect(() => {
    if (!props.pagesVisibility) {
      props.init(props.modalId);
    }
  }, [props.pagesVisibility]);

  const handleSelection = value => {
    props.changePageVisibility(props.modalId, value);
  };

  return (
    <Wrapper
      className="addMenu"
      onSelection={handleSelection}
      onMenuToggle={({ isOpen }) => {
        setIsListOpen(isOpen);
      }}
      closeOnSelection={false}
    >
      <MenuButton className="addMenu-button visibility-icon">
        <div className="filtervisibility-container relative">
          <div className={`${isListOpen ? 'filtervisibility-icon-on' : 'filtervisibility-icon'}`} />
        </div>
      </MenuButton>
      {props.pagesVisibility && (
        <Menu className="addMenu-menu visibility-icon">
          {props.pagesVisibility.map(page => (
            <MenuItem className="addMenu-menuItem with-checkbox" value={page.id} id={page.id} key={page.id}>
              <input
                type="checkbox"
                className="filter-label"
                checked={page.visible}
                disabled={page.isRequired}
                readOnly
              />
              {page.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state, props) => {
  return {
    pagesVisibility: getPagesDetails(state.modalsVisibility.pagesVisibility.find(v => v.id === props.modalId)),
  };
};

ModalsVisibilityMenu.propTypes = {
  modalId: PropTypes.string,
  changePageVisibility: PropTypes.func,
  pagesVisibility: PropTypes.array,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  init: modalId => dispatch(actions.resetToDefault(modalId)),
  changePageVisibility: (modalId, pageId) => dispatch(actions.changePageVisibility(modalId, pageId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalsVisibilityMenu);
