import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import styles from './EditStockModal.module.scss';

export class EditStockModalEditRow extends PureComponent {
  addPillsRef = React.createRef();
  state = {
    value: 0,
    btnApplyDisabled: true,
    btnResetDisabled: true,
  };

  componentDidMount() {
    this.addPillsRef && this.addPillsRef.current?.focus();
  }

  setValue = value => {
    this.setState({ value });

    this.setState({
      btnApplyDisabled: value === 0,
      btnResetDisabled: value === 0,
    });
  };

  clearValue = () => {
    this.setValue(0);
  };

  onReset = e => {
    e.preventDefault();
    e.stopPropagation();
    this.clearValue();
  };

  onApply = e => {
    e.preventDefault();
    e.stopPropagation();
    const value = this.state.value;
    this.clearValue();
    this.props.onApply(value);
  };

  onKeyDown = e => {
    const key = e.key;

    if (key.length === 1 && /\D/.test(key)) {
      e.preventDefault();
    }
  };

  onChange = e => {
    const parsedInt = parseInt(e.target.value, 10);
    if (!isNaN(parsedInt)) {
      this.setValue(parsedInt);
    } else {
      this.clearValue();
    }
  };

  render() {
    const { className, pillsRef, type } = this.props;
    const { value, btnApplyDisabled, btnResetDisabled } = this.state;
    return (
      <React.Fragment>
        <label htmlFor={`${type}id`} class="visuallyhidden">
          {' '}
          {type + ' pills input'}
        </label>
        <input
          type="text"
          ref={pillsRef && this.addPillsRef}
          value={value}
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
          maxLength="5"
          id={`${type}id`}
        />
        <button
          className={className}
          onClick={this.onApply}
          disabled={btnApplyDisabled}
          tabIndex={0}
          role="button"
          aria-label={`${type} ${value} pills`}
        />
        <button
          className={styles.reset}
          onClick={this.onReset}
          disabled={btnResetDisabled}
          tabIndex={0}
          role="button"
          aria-label={`reset pills`}
        />
      </React.Fragment>
    );
  }
}

EditStockModalEditRow.propTypes = {
  className: PropTypes.any,
  onApply: PropTypes.func,
  pillsRef: PropTypes.any,
};
