import { openModalAction } from '../../actions/modal';
import { store } from '../../utils/store';
import { EditCohortModalPages, workflows } from '../_ModalsMetadata/ModalsMetadata';
import { actions as cohortActions } from '../../pages/SuperUser/Patients/Cohorts/redux/actions';
import { executePatientOnboarding } from './OnboardPatient';
import { CREATE_COHORT } from '../../pages/SuperUser/Patients/Cohorts/redux/constants';

export function executeRpmOrgSetup(nextAction) {
  const modals = store?.getState().modalsVisibility.modalsVisibility.find(w => w.id === workflows.setupRpm.id)?.modals;
  const modalsFiltered = modals?.filter(m => m.visible);

  const getNextModal = modalId => {
    const currentModalId = modalsFiltered.findIndex(m => m.id === modalId);
    if (currentModalId < modalsFiltered.length - 1) {
      const ret = { ...modalsFiltered[currentModalId + 1] };
      return ret.id;
    }
    return null;
  };

  const startAddConditions = callNext => {
    store?.dispatch(
      openModalAction('edit-conditions', {
        workflow: workflows.setupRpm.id,
        onSuccess: () => callNext('edit-conditions'),
      }),
    );
  };

  const startAddFacilities = callNext => {
    store?.dispatch(
      openModalAction('edit-facilities', {
        workflow: workflows.setupRpm.id,
        onSuccess: () => callNext('edit-facilities'),
      }),
    );
  };

  const startAddCohort = callNext => {
    store?.dispatch(
      openModalAction('edit-cohort', {
        action: cohortActions.createCohort,
        actionType: CREATE_COHORT,
        workflow: workflows.setupRpm.id,
        pages: [EditCohortModalPages.data],
        onSuccess: () => callNext('edit-cohort'),
      }),
    );
  };

  const startNew = (id, callNext) => {
    switch (id) {
      case 'edit-cohort':
        startAddCohort(callNext);
        break;
      case 'edit-rpm-patient':
        executePatientOnboarding();
        break;
      case 'bulk-add-patients':
        store?.dispatch(openModalAction('bulk-add-patients'));
        break;
      default:
        break;
    }
  };

  const showSummary = callNext => {
    store?.dispatch(
      openModalAction('done-rpm-setup', {
        workflow: workflows.setupRpm.id,
        onOptionClicked: modal => startNew(modal, callNext),
      }),
    );
  };

  const onSuccess = modalId => {
    if (nextAction) {
      store?.dispatch(nextAction);
    }
    const modalToOpen = getNextModal(modalId);
    if (modalToOpen) {
      switch (modalToOpen) {
        case 'edit-conditions':
          startAddConditions(onSuccess);
          break;
        case 'edit-facilities':
          startAddFacilities(onSuccess);
          break;
        case 'edit-cohort':
          startAddCohort(onSuccess);
          break;
        case 'done-rpm-setup':
          showSummary(onSuccess);
          break;
        default:
          break;
      }
    }
  };

  startAddConditions(onSuccess);
}
