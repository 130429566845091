import { userProfile } from 'actions/auth';
import { closeModal, openModalAction } from 'actions/modal';
import { userSettingsAction } from 'actions/user-settings';
import { CodeInput } from 'components/Inputs/CodeInput';
import { notificationActions } from 'components/Notification/redux/actions';
import { Timer } from 'components/Timer';
import { Changeable } from 'containers/Changeable';

import NewConfirmationModal from 'modals/NewConfirmationModal/NewConfirmationModal';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../containers';
import { ESIPatientEditDetailsForm } from '../../features/ESIPatientEditDetailsForm/ESIPatientEditDetailsForm';
import Strings from '../../Strings';
import '../editStudyModal.scss';
import { actions } from './redux/actions';

class ESIPatientEditDetailsModal extends PureComponent {
  static MODAL_NAME = 'esi-patient-edit-details-modal';

  state = { errors: [] };

  refCodeInput = React.createRef();
  refChangeable = React.createRef();
  confirmationModal = null;

  reopen(values = null) {
    this.props.openSelf(values || this.props.data);
  }

  changeChangeable(content) {
    if (this.refChangeable.current) {
      this.refChangeable.current.setState({ content });
    }
  }

  checkResponse(r) {
    if (r.error) {
      alert(r.error); // eslint-disable-line no-alert
      return false;
    }

    if (!(r.response && r.response.success)) {
      alert(Strings.errors.internalError); // eslint-disable-line no-alert
      return false;
    }

    return true;
  }

  onCodeChange = () => {
    const length = this.refCodeInput.current.getValue().length;
    this.confirmationModal.setState({ isSubmitButtonDisabled: length !== 6 });
  };

  sendCodeAndShowPopup({ fnSend, fnResend, fnVerify }, field, values) {
    fnSend(field).then(r => {
      if (!this.checkResponse(r)) return;

      const codeExpiresAt = r.response.success.code_expires_at;

      this.props.onCancel();

      let verificationComplete = false;

      this.props.openConfirmModal({
        title: Strings.verificationCode,
        cancelText: Strings.resend,
        submitText: Strings.next,
        isSubmitButtonDisabled: true,

        text: (
          <React.Fragment>
            <div>
              <Changeable ref={this.refChangeable}>
                {Strings.esiPatientLoginStep2}
                {codeExpiresAt ? (
                  <Timer expiresAt={codeExpiresAt} onExpired={this.props.closeConfirmModal} inlineMode />
                ) : (
                  undefined
                )}
              </Changeable>
            </div>
            <br />
            <CodeInput ref={this.refCodeInput} onChange={this.onCodeChange} />
          </React.Fragment>
        ),
        noCloseAfterConfirm: true,
        afterOpen: modal => {
          this.confirmationModal = modal;
        },
        afterClose: () => {
          if (!verificationComplete) {
            this.reopen({ user: this.getInitialValues() });
          }
        },
        onConfirm: () => {
          const code = this.refCodeInput.current.getValue();

          fnVerify(code).then(res => {
            const error =
              (res.response && res.response.data && res.response.data.error && res.response.data.error.messages) ||
              res.error;
            if (error) {
              this.changeChangeable(<span style={{ color: 'red' }}>{error}</span>);
              return;
            }

            verificationComplete = true;
            this.props.refreshUserProfile();
            this.props.showNotification(Strings.verificationCompleteText);
            this.props.closeConfirmModal();
            this.reopen({ user: values });
          });
        },
        onCancel: () => {
          fnResend(field).then(res => {
            if (!this.checkResponse(res)) return;
            const codeExpiresAt2 = res.response.success.code_expires_at;

            this.changeChangeable([
              Strings.codeHasBeenResent,
              codeExpiresAt2 ? (
                <Timer expiresAt={codeExpiresAt} onExpired={this.props.closeConfirmModal} inlineMode />
              ) : (
                undefined
              ),
            ]);
          });
        },
      });
    });
  }

  onPhoneSendCode = values => {
    const { phoneNew, phoneResend, phoneVerify } = this.props;

    this.sendCodeAndShowPopup(
      {
        fnSend: phoneNew,
        fnResend: phoneResend,
        fnVerify: phoneVerify,
      },
      values.mobile_phone,
      values,
    );
  };

  onEmailSendCode = values => {
    const { emailNew, emailResend, emailVerify } = this.props;

    this.sendCodeAndShowPopup(
      {
        fnSend: emailNew,
        fnResend: emailResend,
        fnVerify: emailVerify,
      },
      values.email,
      values,
    );
  };

  onSubmit = values => {
    this.props
      .postSettings({
        first_name: values.first_name,
        last_name: values.last_name,
      })
      .then(() => {
        this.props.showNotification(Strings.profileUpdated);
        this.props.refreshUserProfile();
        this.props.onCancel();
      });
  };

  getInitialValues() {
    const {
      data: { user },
    } = this.props;

    return {
      first_name: user.first_name,
      last_name: user.last_name,
      mobile_phone: user.mobile_phone,
      email: user.email,
    };
  }

  render() {
    const { onOpen, onCancel, ...props } = this.props;

    return (
      <Modal
        onOpen={onOpen}
        errors={this.state.errors}
        name={ESIPatientEditDetailsModal.MODAL_NAME}
        additionalClasses={['form-modal', 'user-invite']}
        withoutContainer
        {...props}
      >
        <ESIPatientEditDetailsForm
          errors={this.state.errors}
          onSubmit={this.onSubmit}
          onCancel={onCancel}
          onPhoneSendCode={this.onPhoneSendCode}
          onEmailSendCode={this.onEmailSendCode}
          initialValues={this.getInitialValues()}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onCancel: () => dispatch(closeModal(ESIPatientEditDetailsModal.MODAL_NAME)),
  openSelf: data => dispatch(openModalAction(ESIPatientEditDetailsModal.MODAL_NAME, data)),
  postSettings: data => dispatch(userSettingsAction.actionPost(data)),
  phoneVerify: field => dispatch(actions.phoneVerify(field)),
  phoneNew: field => dispatch(actions.phoneNew(field)),
  phoneResend: field => dispatch(actions.phoneResend(field)),
  emailVerify: field => dispatch(actions.emailVerify(field)),
  emailNew: field => dispatch(actions.emailNew(field)),
  emailResend: field => dispatch(actions.emailResend(field)),
  openConfirmModal: data => dispatch(openModalAction(NewConfirmationModal.MODAL_NAME, data)),
  closeConfirmModal: () => dispatch(closeModal(NewConfirmationModal.MODAL_NAME)),
  refreshUserProfile: () => dispatch(userProfile()),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
});

export default connect(null, mapDispatchToProps)(ESIPatientEditDetailsModal);
