import {
  WIDGET_GET_HUBS_WITHOUT_READINGS_COUNT,
  WIDGET_GET_HUBS_WITHOUT_READINGS_COUNT_RESULT,
  WIDGET_GET_HUBS_WITHOUT_READINGS_COUNT_ERROR,
} from './constants';

export const widgetHubsWithoutReadingsReducer = (state = {}, action) => {
  switch (action.type) {
    case WIDGET_GET_HUBS_WITHOUT_READINGS_COUNT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        count: undefined,
      };
    case WIDGET_GET_HUBS_WITHOUT_READINGS_COUNT_RESULT:
      return {
        ...state,
        isLoading: false,
        isError: false,
        count: action.response,
      };

    case WIDGET_GET_HUBS_WITHOUT_READINGS_COUNT_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        count: undefined,
      };
    }
    default:
      return state;
  }
};
