import {
  PATIENT_PORTAL_GET_MEDICATIONS,
  PATIENT_PORTAL_GET_MEDICATIONS_RESULT,
  PATIENT_PORTAL_TAKE_MEDICATIONS,
  PATIENT_PORTAL_TAKE_MEDICATIONS_ERROR,
  PATIENT_PORTAL_TAKE_MEDICATIONS_RESULT,
} from '../actions/action-types';

const patientPortalReducer = (reducer, types, actionToKey) => {
  const nextReducer = reducer(types, actionToKey);

  return (state, action) => {
    const newState = { ...nextReducer(state, action) };
    switch (action.type) {
      case PATIENT_PORTAL_GET_MEDICATIONS:
        return {
          ...newState,
          loading: true,
        };
      case PATIENT_PORTAL_GET_MEDICATIONS_RESULT:
        return {
          ...newState,
          loading: false,
        };
      case PATIENT_PORTAL_TAKE_MEDICATIONS:
        return {
          ...newState,
          loading: true,
        };
      case PATIENT_PORTAL_TAKE_MEDICATIONS_ERROR:
        return {
          ...newState,
          loading: false,
        };
      case PATIENT_PORTAL_TAKE_MEDICATIONS_RESULT:
        if (action.response.id in state.objects) {
          state.objects[action.response.id].status = 'complied'; // eslint-disable-line no-param-reassign
        }

        return {
          ...state,
          objects: state.objects,
          loading: false,
        };
      default:
        return newState;
    }
  };
};
export default patientPortalReducer;
