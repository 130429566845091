import { cloneDeep, omitBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Select from '../../../components/Select';
import { GetTimezoneTextMapping } from '../../../constants';
import Strings from '../../../Strings';
import { actions } from '../Organization/redux/actions';
import './Configuration.scss';
import { onPermissionRender, twoFaEnabled } from './helpers';
import { colorThemes } from '../../../utils/colorTheme';
import { supportedBrands } from '../../../utils/brand';

class OrgPermissions extends PureComponent {
  state = {
    permissions: {},
    support: {},
    settings: {},
    sectionsExpanded: {
      Preferences: false,
      Permissions: false,
      Support: false,
      Settings: false,
    },
  };

  componentDidMount() {
    this.pageRequest = {
      organizationId: decodeURIComponent(this.props.organizationId),
    };

    this.props.getAllPermission(this.pageRequest).then(() => {
      this.props.getOrgConfig(this.pageRequest).then(() => {
        const { permissions, template } = this.props;
        const { preferences } = this.props.orgConfig;
        const { support } = template;
        const currentSupport = this.props.orgConfig?.support;
        const { two_fa_enabled } = this.props.orgConfig;
        const updatedSupport = _.merge(support, currentSupport);
        const currentSettings = this.props.orgConfig?.settings;
        let settings = _.cloneDeep(template.settings);
        _.merge(settings, currentSettings);
        // we probably don't want to have this in configuration on portal (yet)
        delete settings.pricing;

        this.setState({
          permissions,
          theme: preferences.theme,
          brand: preferences.brand,
          timezone: preferences.timezone,
          support: updatedSupport,
          two_fa_enabled: two_fa_enabled,
          settings,
        });
      });
    });
  }

  handleCheckbox = e => {
    const { name, value, checked } = e.target;
    const preState = this.state.permissions || {};
    const previousState = this.state.permissions[name] || {};
    this.setState({
      permissions: {
        ...preState,
        [name]: { ...previousState, [value]: checked },
      },
    });
  };

  handleTwoFa = e => {
    const { name, value, checked } = e.target;
    this.setState({ two_fa_enabled: checked });
  };

  handleSupportCheckbox = e => {
    const { name, value, checked } = e.target;
    const preState = this.state.support || {};
    const previousState = this.state.support[name] || {};
    this.setState({
      support: {
        ...preState,
        [name]: { ...previousState, [value]: checked },
      },
    });
  };

  handleSections = (e, oname, sname) => {
    const support = this.state.support;
    support.sections[oname][sname] = e.target.value;
    this.setState({ ...support });
    this.forceUpdate();
  };

  handleSettings = (value, oname, sname, sname1 = null) => {
    this.setState(s => ({
      ...s,
      settings: {
        ...s.settings,
        [oname]: {
          ...s.settings[oname],
          [sname]: sname1 ? { ...s.settings[oname][sname], [sname1]: value } : value,
        },
      },
    }));
    this.forceUpdate();
  };

  onFlagsRender = () => {
    const { support } = this.state;
    return (
      support &&
      Object.entries(support).map(([name, options]) => {
        if (name === 'flags') {
          return (
            <div className="config-container" key={name}>
              <div className="head-item">{name}</div>
              <div className="container">
                {Object.entries(options).map(([oname, ooptions]) => {
                  return (
                    <div className="flex-container">
                      <div className="flex-item">
                        <input
                          type="checkbox"
                          onClick={this.handleSupportCheckbox}
                          name={name}
                          id={oname + name}
                          value={oname}
                          checked={typeof support[name][oname] === 'object' ? false : support[name][oname]}
                        />
                        <label key={oname} for={oname + name}>
                          {oname}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }
        if (name === 'sections') {
          return (
            <div className="config-container" key={name}>
              <div className="head-item">{name}</div>
              {Object.entries(options).map(([oname, ooptions]) => {
                return (
                  <div className="flex-container">
                    <div className="flex-flag-item">
                      <div className="head-item">{oname}</div>
                      {Object.entries(ooptions).map(([sname, poptions]) => {
                        return (
                          <div className="text-container">
                            <div className="label-box">
                              <label key={sname} for={sname + name}>
                                {sname}
                              </label>
                            </div>
                            <div className="text-box">
                              <input
                                name={sname}
                                value={support[name][oname][sname]}
                                onChange={e => this.handleSections(e, oname, sname)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }
      })
    );
  };

  renderSettings = () => {
    const { settings } = this.state;
    return (
      <div className="config-container">
        {settings &&
          Object.entries(settings).map(([oname, ooptions]) => {
            return (
              <div className="flex-container">
                <div className="flex-flag-item">
                  <div className="head-item">{oname}</div>
                  {Object.entries(ooptions).map(([sname, poptions]) => {
                    console.log(typeof settings[oname][sname], settings[oname][sname]);
                    if (typeof settings[oname][sname] === 'object') {
                      return (
                        <React.Fragment>
                          <div className="head-item">{sname}</div>
                          {Object.entries(settings[oname][sname]).map(([sname1, poptions1]) => (
                            <div className="text-container">
                              <div className="label-box">
                                <label key={sname1} for={sname1}>
                                  {sname1}
                                </label>
                              </div>
                              <div className={typeof settings[oname][sname][sname1] !== 'boolean' ? 'text-box' : ''}>
                                <input
                                  type={typeof settings[oname][sname][sname1] === 'boolean' ? 'checkbox' : 'text'}
                                  name={sname1}
                                  value={
                                    typeof settings[oname][sname][sname1] === 'boolean'
                                      ? settings[oname][sname][sname1]
                                      : undefined
                                  }
                                  checked={
                                    typeof settings[oname][sname][sname1] === 'boolean'
                                      ? settings[oname][sname][sname1]
                                      : undefined
                                  }
                                  onChange={e =>
                                    this.handleSettings(
                                      typeof settings[oname][sname][sname1] === 'boolean'
                                        ? !settings[oname][sname][sname1]
                                        : e.target.value,
                                      oname,
                                      sname,
                                      sname1,
                                    )
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </React.Fragment>
                      );
                    }
                    return (
                      <div className="text-container">
                        <div className="label-box">
                          <label key={sname} for={sname}>
                            {sname}
                          </label>
                        </div>
                        <div className={typeof settings[oname][sname] !== 'boolean' ? 'text-box' : ''}>
                          <input
                            type={typeof settings[oname][sname] === 'boolean' ? 'checkbox' : 'text'}
                            name={sname}
                            value={typeof settings[oname][sname] !== 'boolean' ? settings[oname][sname] : undefined}
                            checked={typeof settings[oname][sname] === 'boolean' ? settings[oname][sname] : undefined}
                            onChange={e =>
                              this.handleSettings(
                                typeof settings[oname][sname] === 'boolean' ? !settings[oname][sname] : e.target.value,
                                oname,
                                sname,
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  updatePermission = () => {
    const { theme, brand, timezone, support, two_fa_enabled, settings } = this.state;
    const { permissions } = cloneDeep(this.state);

    Object.entries(permissions).map(([name, options]) => {
      if (permissions[name]) {
        Object.entries(permissions[name]).map(([oname, ooptions]) => {
          if (oname === name) {
            if (permissions[name][oname] === true) {
              permissions[name] = {};
            } else {
              delete permissions[name];
              return;
            }
          } else {
            if (permissions[name][oname] === true) {
              permissions[name][oname] = {};
            } else {
              delete permissions[name][oname];
              if (Object.keys(permissions[name]).length === 0) {
                delete permissions[name];
                return;
              }
              return;
            }
          }
        });
      }
    });

    const data = {
      permissions: permissions,
      preferences: {
        theme,
        brand,
        timezone,
        units: 'imperial',
      },
      support,
      settings: Object.assign(this.props.template.settings, settings),
      two_fa_enabled,
    };

    this.props.updateOrg(data, this.pageRequest).then(() => {
      this.setState({ isSuccess: true });
      setTimeout(() => {
        this.setState({ isSuccess: false });
      }, 3000);
    });
  };

  changeSectionExpansion = section => {
    this.setState(s => ({
      ...s,
      sectionsExpanded: {
        ...s.sectionsExpanded,
        [section]: !s.sectionsExpanded[section],
      },
    }));
  };

  renderSectionHeader = name => (
    <div className="config-section-header">
      <div className="buttonContainer">
        <button
          className={`minimize-button ${this.state.sectionsExpanded[name] ? '' : 'collapsed'}`}
          onClick={() => this.changeSectionExpansion(name)}
        />
      </div>
      <h4>{name}</h4>
    </div>
  );

  render() {
    const { theme, brand, timezone, isSuccess, sectionsExpanded } = this.state;
    const timezones = GetTimezoneTextMapping();
    return (
      <React.Fragment>
        <div className="config-sections">
          {this.renderSectionHeader('Preferences')}
          {sectionsExpanded.Preferences && (
            <div className="dd-container">
              <div className="dd-ele">
                <div className="dd-title">{Strings.selectTheme}</div>
                <Select
                  name="theme"
                  id="theme"
                  value={theme}
                  onChange={o => this.setState({ theme: o.value })}
                  placeholder={Strings.selectTheme}
                  data={Object.keys(colorThemes).map(theme => {
                    return { value: theme, label: Strings.theme[theme] };
                  })}
                />
              </div>
              <div className="dd-ele">
                <div className="dd-title">{Strings.selectBrand}</div>
                <Select
                  name="brand"
                  id="brand"
                  value={brand}
                  onChange={o => this.setState({ brand: o.value })}
                  placeholder={Strings.selectBrand}
                  data={Object.keys(supportedBrands).map(b => {
                    return { value: b, label: supportedBrands[b].name };
                  })}
                />
              </div>
              <div className="dd-ele">
                <div className="dd-title">{Strings.selectTimezone}</div>
                <Select
                  name="timezone"
                  id="timezone"
                  value={timezone}
                  onChange={o => this.setState({ timezone: o.value })}
                  data={[{ value: '', label: Strings.selectTimezone }].concat(
                    Object.keys(timezones).map(key => {
                      return { value: key, label: timezones[key] };
                    }),
                  )}
                />
              </div>
            </div>
          )}
          {this.renderSectionHeader('Permissions')}
          {sectionsExpanded.Permissions && onPermissionRender(this.state.permissions, this.handleCheckbox)}
          {this.renderSectionHeader('Support')}
          {sectionsExpanded.Support && this.onFlagsRender()}
          {this.renderSectionHeader('Settings')}
          {sectionsExpanded.Settings && this.renderSettings()}

          {twoFaEnabled(this.state.two_fa_enabled, this.handleTwoFa)}
          <button className="brand-blue" key="submit" onClick={this.updatePermission} disabled={!this.state.theme}>
            {Strings.submit}
          </button>
          {isSuccess && <div className="success-text">{Strings.permissionsUpdated} </div>}
        </div>
      </React.Fragment>
    );
  }
}

OrgPermissions.propTypes = {
  getAllPermission: PropTypes.func,
  getOrgConfig: PropTypes.func,
  orgConfig: PropTypes.shape({
    preferences: PropTypes.shape({
      theme: PropTypes.any,
      timezone: PropTypes.any,
    }),
  }),
  organizationId: PropTypes.any,
  permissions: PropTypes.any,
  updateOrg: PropTypes.func,
};

const mapStateToProps = state => {
  const { organizations } = state.superUser;
  const orgConfig = cloneDeep(organizations?.orgConfig?.permissions);
  const template = organizations?.template;
  const orgPermissions = template?.permissions;

  let permissions = {};

  orgPermissions &&
    Object.entries(orgPermissions).map(([name, options]) => {
      if (orgPermissions[name]) {
        if (Object.keys(orgPermissions[name]).length === 0) {
          orgPermissions[name][name] = false;
        }
      }
      Object.entries(orgPermissions[name]).map(([oname, ooptions]) => {
        if (orgPermissions[name][oname]) {
          orgPermissions[name][oname] = false;
        }
      });
    });

  if (orgConfig) {
    Object.entries(orgConfig).map(([name, options]) => {
      if (orgConfig[name]) {
        if (Object.keys(orgConfig[name]).length === 0) {
          orgConfig[name][name] = true;
        }
        Object.entries(orgConfig[name]).map(([oname, ooptions]) => {
          if (orgConfig[name][oname]) {
            orgConfig[name][oname] = true;
          }
        });
      }
    });
  }

  permissions = _.merge(orgPermissions, orgConfig);

  return {
    orgRoleConfig: organizations?.orgRoleConfig,
    isLoading: organizations?.isLoading,
    orgConfig: organizations?.orgConfig,
    permissions,
    template,
  };
};

const mapDispatchToProps = dispatch => ({
  getOrgRoleConfig: pageRequest => dispatch(actions.getOrgRoleConfig(pageRequest)),
  updateOrg: (data, pageRequest) => dispatch(actions.updateOrg(data, pageRequest)),
  getOrgConfig: pageRequest => dispatch(actions.getOrgConfig(pageRequest)),
  getAllPermission: pageRequest => dispatch(actions.getAllSupportedPermission(pageRequest)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgPermissions);
