import {
  LIST_CAREGIVERS_RESULT,
  LIST_CAREGIVERS,
  CLEAR_ENTITIES,
  DELETE_CAREGIVER_RESULT,
  DELETE_CAREGIVER_ERROR,
  DELETE_CAREGIVER,
  LIST_CAREGIVERS_ERROR,
} from '../actions/action-types';

const patientCaregiversReducer = (state = { patientCaregivers: {} }, action) => {
  switch (action.type) {
    case LIST_CAREGIVERS:
      return {
        ...state,
        loading: true,
        preparing: true,
      };
    case LIST_CAREGIVERS_RESULT:
      return {
        ...state,
        patientCaregivers: action.response,
        loading: false,
        preparing: false,
      };
    case LIST_CAREGIVERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ENTITIES:
      if (action.entities && action.entities.indexOf('patientCaregivers') !== -1) {
        return {
          ...state,
          patientCaregivers: {},
          preparing: true,
          loading: true,
        };
      }
      return state;
    case DELETE_CAREGIVER:
      return {
        ...state,
        deletedCaregiverId: action.caregiverId,
        loading: false,
        preparing: true,
      };
    case DELETE_CAREGIVER_RESULT: // eslint-disable-line no-case-declarations
      const caregivers = state.patientCaregivers; // eslint-disable-line prefer-destructuring
      const caregiverIndex = caregivers.findIndex(element => element.id === action.caregiverId);
      if (caregiverIndex !== -1) {
        delete state.patientCaregivers[caregiverIndex]; // eslint-disable-line no-param-reassign
      }

      return {
        ...state,
        loading: false,
        preparing: false,
      };
    case DELETE_CAREGIVER_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default patientCaregiversReducer;
