import { CLEAR_ENTITIES, FILTER_CHANGE } from './action-types';

export const changeFilter = (filter, props) => ({
  type: FILTER_CHANGE,
  filter,
  payload: props,
});

export const clearEntitiesAction = entities => ({
  type: CLEAR_ENTITIES,
  entities,
});
