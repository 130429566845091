import { API_CALL } from 'middleware/api';

export const actions = {
  updateCapId: (schedule_id, cap_id) => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: `/esi/v1/cap/assign`,
      data: { schedule_id, cap_id },
    },
    type: 'esi_patient_update_hardware_cap_id',
  }),
};
