import React from 'react';

import image from '../images/rx/group-5@3x.png';
import TwoColumnsLayout from '../layouts/TwoColumnsLayout';
import Strings from '../Strings';

// eslint-disable react/no-danger
const LandingLayout = ({ title, caption, hideBasicLogo, showMore = false }) => Component => props => (
  <TwoColumnsLayout title={title} hideBasicLogo={hideBasicLogo}>
    <div className="content">
      <img alt={title} className="landing-image" src={image} />
      <div className="h1-wrapper">
        <h1>{title}</h1>
      </div>
      <div className="caption" dangerouslySetInnerHTML={{ __html: caption }} />
      {showMore ? (
        <a role="button" className="white link" href="https://rxcap.com/">
          {Strings.findOutMore}
        </a>
      ) : null}
    </div>
    <Component {...props} />
  </TwoColumnsLayout>
);

export default LandingLayout;
