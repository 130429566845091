import { ADD_ORAGANIZATION, ADD_ORAGANIZATION_ERROR, ADD_ORAGANIZATION_RESULT } from './constants';

export const reducer = (state = null, action) => {
  switch (action.type) {
    case ADD_ORAGANIZATION:
      return {
        ...state,
        addOrganization: {
          isLoading: true,
        },
      };
    case ADD_ORAGANIZATION_ERROR:
      return {
        ...state,
        addOrganization: {
          isLoading: false,
          data: [],
        },
      };
    case ADD_ORAGANIZATION_RESULT:
      return {
        ...state,
        addOrganization: {
          isLoading: false,
          data: action.response.data ? action.response.data : [],
        },
      };
    default:
      return state;
  }
};
