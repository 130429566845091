import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class LoadingRenderer extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.any,
  };

  render() {
    const { loading, children } = this.props;

    if (loading) {
      return (
        <div className="spinner-container">
          <div className="spinner-white-box">
            <div className="spinner" />
          </div>
        </div>
      );
    }

    return children;
  }
}

export default LoadingRenderer;
