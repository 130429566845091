import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent, useEffect, useRef, useState } from 'react';
import {
  BarChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
} from 'recharts';

import { DATE_FORMAT_YEAR_MONTH_DAY, TIME_FORMAT_12_LOWERCASE } from '../../constants';
import {
  GRAPH_AXIS_LINE_COLOR,
  GRAPH_AXIS_LINE_WIDTH,
  GRAPH_ROW_BK,
  GRAPH_ROW_BK_ALTERNATE,
  MINIMUM_PIXELS_PER_DAY,
  OPTIMUM_PIXELS_PER_DAY_SLEEP,
  OPTIMUM_PIXELS_PER_DAY_STEPS,
  RANGE_PICKER_HEIGHT,
  RANGE_PICKER_MOBILE_WITH_TOGGLES_HEIGHT,
} from './GraphConstants';
import './graph.scss';
import { CustomReferenceArea, CustomReferenceLine, calcDays, referenceAreas } from './GraphComponents';
import { DEFAULT_DATE_RANGE_DAYS, graphMetricEnum, graphMetrics, mobileDataRangeTypes } from './CompositeGraph/Metrics';
import { RangePicker } from './CompositeGraph/RangePicker';
import {
  CustomizedXAxisProportional,
  calcXAxisParams_Hours,
  calcXAxisParams_Time,
  calcXAxisParams_TimeMobile,
  drawXAxis,
} from './CompositeGraph/Axes';
import {
  X_AXIS_CHART_HEIGHT_TINY,
  X_AXIS_HEIGHT_TINY,
  X_AXIS_PADDING,
  Y_AXIS_LEFT_WIDTH,
  Y_AXIS_RIGHT_WIDTH,
  Y_AXIS_WIDTH_TINY,
} from './CompositeGraph/Constants';
import { useGesture } from '@use-gesture/react';
import {
  calcDateRangeAround,
  defaultOnDrag,
  defaultOnDragEnd,
  defaultOnDragEnd_Mobile,
  defaultOnDrag_Mobile,
} from './CompositeGraph/ZoomHelpers';
import { isDesktop, isMobile } from 'react-device-detect';
import { MobileRangePicker } from './CompositeGraph/MobileRangePicker';
import { GraphLegendWithToggle } from './CompositeGraph/Legend';
import { scatterDotShapeEnum, symbolDotSizeEnum } from './CompositeGraph/Formatting';
import CompositeGraphXAxis from './CompositeGraph/CompositeGraphXAxis';

const graphAxisFontSize = 12;
const graphYAxisFontColor = '#4F4F4F';
const graphAxisFontColorWeekend = '#bbb';

const graphDotColorLightSleep = '#5EC4FC';
const graphDotColorDeepSleep = '#645EFC';
const graphDotColorRemSleep = '#FC5EDC';
const graphDotColorNoSleep = '#FCAC5E';
const graphMaxBarSize = 6;
const graphBarRadius = 10;

const graphWarningFillOpacity = 0.1;
const graphWarningFill = '#F00';

const graphReferenceVerticalLineColor = '#eeeeee';

const graphAxisPaddingTB = 30;

const sAwake = 'Awake';
const sREM = 'REM';
const sLight = 'Light';
const sDeep = 'Deep';

const CustomizedYAxisTick = props => {
  const {
    x,
    y,
    width,
    orientation,
    payload,
    axisMin,
    axisLine,
    sleepStartTick,
    sleepEndTick,
    dateMin,
    dateMax,
    withoutHeader,
    hourOnly,
    isRight,
  } = props; // stroke

  const textDx = isRight ? (width === undefined ? 35 : width / 2 - 2) : width === undefined ? -35 : -width / 2;
  //console.log(props);
  // console.log(payload.value, sleepStartTick, sleepEndTick);

  if (payload.value === axisMin && dateMin instanceof moment && dateMax instanceof moment) {
    //console.log(props);
    return withoutHeader ? (
      <></>
    ) : (
      <g transform={`translate(${x},${y})`}>
        <rect
          x={-x}
          y={0 - payload.offset - axisLine.strokeWidth / 2}
          width={x + 1}
          height={axisLine.strokeWidth}
          fill={axisLine.stroke}
        />
        <rect
          y={-y}
          x={
            0 -
            payload.offset -
            axisLine.strokeWidth / 2 +
            (orientation === 'left' ? GRAPH_AXIS_LINE_WIDTH : -GRAPH_AXIS_LINE_WIDTH)
          }
          height={y + 1}
          width={axisLine.strokeWidth}
          fill={axisLine.stroke}
        />
        <text
          className="span"
          fontSize={graphAxisFontSize}
          dy={-35}
          dx={-width / 2}
          textAnchor="middle"
          fill={graphAxisFontColorWeekend}
        >
          {dateMin.format('MMM D -').toUpperCase()}
        </text>
        <text
          className="span"
          fontSize={graphAxisFontSize}
          dy={-20}
          dx={-width / 2}
          textAnchor="middle"
          fill={graphAxisFontColorWeekend}
        >
          {dateMax.format('MMM D').toUpperCase()}
        </text>
      </g>
    );
  }

  if (payload.value === sleepStartTick || payload.value === sleepEndTick) {
    const leadingZero = payload.value >= 0 && payload.value < 10 ? '0' : '';
    const value = payload.value < 0 ? 24 + payload.value : payload.value;
    const postfix = hourOnly ? '' : payload.value < 0 ? ':00 pm' : ':00 am';

    if (payload.value === sleepStartTick) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            className="span"
            fontSize={graphAxisFontSize}
            dy={5}
            dx={textDx}
            textAnchor="middle"
            fill={graphYAxisFontColor}
          >
            &#9790;
            {!hourOnly && String.fromCharCode(160)}
            {leadingZero}
            {value}
            {postfix}
          </text>
        </g>
      );
    }

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          className="span"
          fontSize={graphAxisFontSize}
          dy={5}
          dx={textDx}
          textAnchor="middle"
          fill={graphYAxisFontColor}
        >
          &#9728;
          {!hourOnly && String.fromCharCode(160)}
          {leadingZero}
          {value}
          {postfix}
        </text>
      </g>
    );
  }

  return null;
};
CustomizedYAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.any,
  payload: PropTypes.any,
  axisMin: PropTypes.number,
  sleepStartTick: PropTypes.number,
  sleepEndTick: PropTypes.number,
  dateMin: PropTypes.any,
  dateMax: PropTypes.any,
  axisLine: PropTypes.any,
  withoutHeader: PropTypes.bool,
  hourOnly: PropTypes.bool,
};

class CustomizedYAxisTickHourly extends PureComponent {
  render() {
    const { x, y, payload, axisMin, axisLine } = this.props; // stroke
    const value =
      payload.value === 'a'
        ? sAwake
        : payload.value === 'r'
        ? sREM
        : payload.value === 'l'
        ? sLight
        : payload.value === 'd'
        ? sDeep
        : '';

    return (
      <g transform={`translate(${x},${y})`}>
        {payload.value === axisMin && (
          <g>
            <rect
              x={-x}
              y={0 - payload.offset - axisLine.strokeWidth / 2 - graphAxisPaddingTB}
              width={x + 2}
              height={axisLine.strokeWidth}
              fill={axisLine.stroke}
            />
            <rect y={-500} x={0} height={1000} width={axisLine.strokeWidth} fill={axisLine.stroke} />
          </g>
        )}
        <text
          className="span"
          fontSize={graphAxisFontSize}
          dy={5}
          dx={-60}
          textAnchor="start"
          fill={graphYAxisFontColor}
        >
          {value}
        </text>
      </g>
    );
  }
}
CustomizedYAxisTickHourly.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.any,
  payload: PropTypes.any,
  axisLine: PropTypes.any,
  axisMin: PropTypes.string,
};

export function WarningReferenceArea(props) {
  const { x, y, width, height, fill, fillOpacity } = props;
  const paddingW = isMobile ? 0 : 5;
  const paddingH = isMobile ? 3 : 8;

  return (
    <g>
      <rect
        x={x + paddingW}
        y={paddingH}
        width={width - 2 * paddingW}
        height={height + y - 2 * paddingH}
        fillOpacity={fillOpacity}
        fill={fill}
        rx={5}
        ry={5}
      />
    </g>
  );
}
WarningReferenceArea.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.any,
  fillOpacity: PropTypes.number,
  viewBox: PropTypes.any,
};

function formatSecondsAsHours(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor(seconds / 60) % 60;

  return `${h}h ${m}min`;
}

export default function SleepGraph(props) {
  const allowHourlyGraph = false;

  const calcInitialDateRange = range => {
    const lastEvent = props.graphData[props.graphData.length - 1];
    if (!isMobile) {
      const newStart = lastEvent
        ? lastEvent.date
            .clone()
            .subtract(range, 'days')
            .startOf('day')
        : moment()
            .subtract(range, 'days')
            .startOf('day');
      const newEnd = lastEvent
        ? lastEvent.date
            .clone()
            .add(1, 'day')
            .startOf('day')
        : moment()
            .add(1, 'day')
            .startOf('day');

      const maxRangeStart = props.minDate.clone().startOf('day');
      const maxRangeEnd = props.maxDate.clone().startOf('day');

      return {
        start: newStart.unix() >= maxRangeStart.unix() ? newStart : maxRangeStart,
        end: newEnd,
      };
    } else {
      const dr = calcDateRangeAround(lastEvent ? lastEvent.date : moment(), mobileDataRangeTypes.twoWeeks);
      return dr;
    }
  };

  const [graphWidth, setGraphWidth] = useState();
  const [dateRange, setDateRange] = useState(calcInitialDateRange(DEFAULT_DATE_RANGE_DAYS));

  const [minMaxHourHint, setMinMaxHourHint] = useState({
    day: undefined,
    minHour: undefined,
    maxHour: undefined,
  });

  const [maxRange, setMaxRange] = useState({
    start: props.minDate.clone().startOf('day'),
    end: props.maxDate.startOf('day'),
  });
  const graphContainerRef = useRef();

  useEffect(() => {
    const width = graphContainerRef?.current?.clientWidth;
    setGraphWidth(width);
    setDateRange(calcInitialDateRange(width / OPTIMUM_PIXELS_PER_DAY_SLEEP));
  }, [graphContainerRef.current]);

  //console.log(props.maxDate.format());

  const fixDays = (xyzData, days, unit) => {
    const ret = [];

    const offset = 12 * 60 * 60;

    for (let index = 0; index <= days.length - 1; index += 1) {
      const thisDay = days[index].unix();
      //console.log('SEEKING ', thisDay, 'in', xyzData);
      const daySleeps = xyzData.filter(x => x.x === thisDay);
      if (daySleeps.length > 0) ret.push({ date: days[index], x: thisDay + offset, sleeps: daySleeps });
      else ret.push({ date: days[index], x: thisDay + offset });
    }

    return ret;
  };

  const calcYAxisParams = (rawData, days) => {
    let dataMin = 24;
    let dataMax = -24;
    let anySleep = false;
    days.forEach(day => {
      const thisDay = day.format(DATE_FORMAT_YEAR_MONTH_DAY);
      const daySleepsMins = rawData
        .filter(x => x.x === thisDay)
        .map(x =>
          x.sleepStart.format(DATE_FORMAT_YEAR_MONTH_DAY) === thisDay ? x.sleepStartHour : x.sleepStartHour - 24,
        );
      if (daySleepsMins.length > 0) {
        const min = Math.min(...daySleepsMins);
        if (min < dataMin) dataMin = min;
      }
      const daySleepsMaxs = rawData
        .filter(x => x.x === thisDay)
        .map(x => (x.sleepEnd.format(DATE_FORMAT_YEAR_MONTH_DAY) === thisDay ? x.sleepEndHour : x.sleepEndHour - 24));
      if (daySleepsMaxs.length > 0) {
        const max = Math.max(...daySleepsMaxs);
        if (max > dataMax) dataMax = max;
      }
      if (!anySleep) {
        anySleep = daySleepsMins.length > 0;
      }
    });

    if (!anySleep) {
      return {
        dataMin: -4,
        dataMax: 10,
        axisMin: -4,
        axisMax: 10,
        axisTick: 2,
        axisTicks: (10 - -4) / 2 + 1,
      };
    }

    const extraMargin = 0; // 2 or 0

    const axisTick = 2;
    const axisHalfTick = 1;
    const axisMin =
      (dataMin >= 0 ? dataMin - (dataMin % axisTick) - axisHalfTick : dataMin + (-dataMin % axisTick) - axisHalfTick) -
      1 -
      extraMargin;
    const axisMax = dataMax - (dataMax % axisTick) + axisHalfTick + 1 + extraMargin;
    const axisDelta = axisMax - axisMin;

    return {
      dataMin,
      dataMax,
      axisMin,
      axisMax,
      axisTick,
      axisTicks: axisDelta / axisTick + 1,
    };
  };

  const onClick = e => {
    if (!allowHourlyGraph) return;

    //console.log(e);
    if (e && e.date) {
      const day = e.date.clone().startOf('day');
      setDateRange({
        start: day,
        end: day,
      });
      if (e.data?.sleeps) {
        const min = e.data.sleeps[0].sleepStart;
        const minIsToday =
          min
            .clone()
            .startOf('day')
            .unix() === day.unix();
        const max = e.data.sleeps[e.data.sleeps.length - 1].sleepEnd;
        const minHour = minIsToday ? 0 : min.hours() - 24;
        const maxHour = max.hours() + 1;
        setMinMaxHourHint({
          day,
          minHour,
          maxHour,
        });
      }
    }
  };

  const calcParts = (fixedData, yAxis) => {
    return fixedData.map(d1 => {
      const y = yAxis.axisMax;
      const d2 = { date: d1.date, x: d1.x, y, data: d1, yAxis };
      return d2;
    });
  };

  const calcPartsHourly = (rawData, dateStart) => {
    const parts = [];
    let prevSleepEnd = 0;

    rawData.forEach(sleep => {
      //console.log(sleep);
      const start = sleep.sleepStart.unix();
      const end = sleep.sleepEnd.unix();

      const diff = start - prevSleepEnd;
      prevSleepEnd = end;

      parts.push({ d: diff, t: ' ' });
      parts.push(
        ...sleep.parts.map(p => {
          return { d: p.duration * 60, t: p.depth[0] };
        }),
      );
    });

    //console.log(parts);

    return [
      { y: 'a', xs: parts },
      { y: 'r', xs: parts },
      { y: 'l', xs: parts },
      { y: 'd', xs: parts },
    ];
  };

  const normalizePair = (min1, max1, val1_1, val1_2, min2, max2) => {
    let val2_1 = min2;
    let val2_2 = max2;

    if (val1_1 <= min1) val2_1 = min2;
    else if (val1_1 >= max1) val2_1 = max2;
    else {
      const ratio = (val1_1 - min1) / (max1 - min1);
      val2_1 = min2 + Math.round((max2 - min2) * ratio);
    }

    if (val1_2 <= min1) val2_2 = min2;
    else if (val1_2 >= max1) val2_2 = max2;
    else {
      const ratio = (val1_2 - min1) / (max1 - min1);
      val2_2 = min2 + Math.round((max2 - min2) * ratio);
    }

    return { v1: val2_1, v2: val2_2 };
  };

  const calcSubParts = (yAxis, yStart, yEnd, data) => {
    if (!data || !data.sleeps || data.sleeps.length === 0) return null;

    //console.log('SUBPARTS', yAxis, data.sleeps);

    const offset = 12 * 60 * 60;
    const parts = [];
    data.sleeps.forEach(sleep => {
      const yStartToday = data.x - offset <= sleep.sleepStart.unix();
      //console.log(yStartToday, data.x, sleep.sleepStart.unix());

      const durationMinutesStart =
        sleep.sleepStart.hours() * 60 + sleep.sleepStart.minutes() - (yStartToday ? 0 : 24 * 60);
      let durationMinutesSum = 0;

      sleep.parts.forEach(part => {
        const partStart = (durationMinutesStart + durationMinutesSum) / 60;
        const partEnd = (durationMinutesStart + durationMinutesSum + part.duration) / 60;
        const type = part.depth[0];
        //console.log('NORMALIZING', yAxis.axisMin, yAxis.axisMax, partStart, partEnd, yStart, yEnd);
        const p = normalizePair(yAxis.axisMin, yAxis.axisMax, partStart, partEnd, yStart, yEnd);
        parts.push({ y1: p.v1, y2: p.v2, type });
        durationMinutesSum += part.duration;
      });
    });

    //console.log('RESULT', parts);

    return parts;
  };

  const calcXAxisParams_PseudoCat = (dateMinDay, dateMaxDay, graphWidth) => {
    let ret = undefined;
    if (allowHourlyGraph) {
      ret = calcXAxisParams_Time(
        dateMinDay,
        dateMaxDay.clone().add(1, 'days'),
        false,
        graphWidth,
        OPTIMUM_PIXELS_PER_DAY_STEPS * 2,
        undefined,
        ['2h', '4h', '7d', '3m'],
      );
      if (ret.unit !== 'hour') {
        ret = calcXAxisParams_Time(
          dateMinDay,
          dateMaxDay,
          false,
          graphWidth,
          OPTIMUM_PIXELS_PER_DAY_STEPS,
          ['d', 'm', 'y'],
          ['2h', '4h', '7d', '3m'],
        );
      }
    } else {
      ret = calcXAxisParams_Time(
        dateMinDay,
        dateMaxDay,
        false,
        graphWidth,
        OPTIMUM_PIXELS_PER_DAY_STEPS,
        ['d', 'm', 'y'],
        ['7d', '3m'],
      );
    }

    if (ret.unit !== 'hour') {
      ret.max = ret.max + 24 * 60 * 60;
    } else if (ret.unit === 'hour') {
      let deltaL = -30 * 60;
      let deltaR = 30 * 60;

      if (
        dateMinDay.unix() === dateMaxDay.unix() &&
        minMaxHourHint.day.unix() === dateMaxDay.unix() &&
        minMaxHourHint.minHour !== undefined &&
        minMaxHourHint.maxHour !== undefined
      ) {
        // console.log(ret);
        ret = calcXAxisParams_Hours(
          dateMinDay.clone().add(minMaxHourHint.minHour, 'hours'),
          dateMinDay.clone().add(minMaxHourHint.maxHour, 'hours'),
          graphWidth,
          OPTIMUM_PIXELS_PER_DAY_STEPS,
          ['2h', '4h'],
        );
      }

      ret.min = ret.min + deltaL;
      ret.max = ret.max + deltaR;
    }

    return ret;
  };

  const calcRefLines = (days, xAxisParams) => {
    // days => 1 week refLines
    if (xAxisParams.unit === 'day') {
      const ret = [];
      for (
        let i = moment(xAxisParams.min * 1000)
          .startOf('week')
          .add(1, 'week');
        i.unix() < xAxisParams.max;
        i.add(1, 'weeks')
      ) {
        ret.push(i.unix());
      }
      return ret;
    }

    // weeks or months => 1 month refLines
    if (xAxisParams.unit === 'month') {
      const ret = [];
      for (
        let i = moment(xAxisParams.min * 1000)
          .startOf('month')
          .add(1, 'month');
        i.unix() < xAxisParams.max;
        i.add(1, 'months')
      ) {
        ret.push(i.unix());
      }
      return ret;
    }

    // no refLines otherwise
    return [];
  };

  const { graphData, selectedPhase } = props;

  const rawData = graphData.map(d => {
    return { ...d, x: d.date.unix() };
  });

  let xAxisParams = calcXAxisParams_PseudoCat(dateRange.start, dateRange.end, graphWidth);
  if (isMobile && xAxisParams.unit !== 'hour') {
    xAxisParams = calcXAxisParams_TimeMobile(dateRange);
  }
  const days = calcDays(dateRange.start, dateRange.end);
  const yAxisParams = calcYAxisParams(graphData, days);
  const fixedData = fixDays(rawData, days, xAxisParams.unit);
  const partsData = calcParts(fixedData, yAxisParams);

  const partsDataHourly = xAxisParams.unit === 'hour' ? calcPartsHourly(graphData, dateRange.start) : [];
  const maxParts = xAxisParams.unit === 'hour' ? partsDataHourly[0].xs.length : 0;

  // console.log(graphData);
  // console.log(graphWidth);
  // console.log(xAxisParams);
  // console.log(yAxisParams);
  // console.log(fixedData);
  // console.log(partsData);

  const refLines = calcRefLines(days, xAxisParams);

  const badDay = day => {
    if (day.date >= moment()) return false;
    if (!day.sleeps || day.sleeps.length === 0) return true;
    const sleepTime = day.sleeps.reduce((partialSum, a) => partialSum + a.sleepEnd.diff(a.sleepStart) / 1000 / 60, 0);

    return sleepTime < 7 * 60;
  };

  const warningDays = fixedData.filter(d => d.date && badDay(d)).map(d => d.date.unix());

  const CustomTooltip = ({ active, label }) => {
    if (active && fixedData) {
      const payload = fixedData.filter(d => d.x === label && d.sleeps != null && d.sleeps.length > 0);

      if (payload && payload.length > 0) {
        return (
          <div className="customTooltip">
            <div>
              <p className="customTooltipTitle">
                Sleep
                <br />
                {moment(payload[0].date).format('ll')}
              </p>
              <div className="customTooltipDescr">
                {payload[0].sleeps.map((sleep, i) => (
                  <div key={`ttline_${i}`}>
                    {formatSecondsAsHours(sleep.sleepEnd.diff(sleep.sleepStart) / 1000)} of sleep starting at{' '}
                    {sleep.sleepStart.format(TIME_FORMAT_12_LOWERCASE)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      }
    }
    return null;
  };

  const DayBar = props => {
    const { x, width, payload, background } = props;

    if (payload.data && payload.data.sleeps && payload.data.sleeps.length > 0) {
      const yStart = background.y;
      const yEnd = background.y + background.height;

      // console.log('DAYBAR', props);
      // console.log(yStart, yEnd, payload.data);

      const parts = calcSubParts(payload.yAxis, yStart, yEnd, payload.data);
      if (!parts) return <></>;

      // console.log(parts);

      const ret = (
        <g>
          {parts.map((i, index) => {
            const color =
              i.type === 'l'
                ? graphDotColorLightSleep
                : i.type === 'd'
                ? graphDotColorDeepSleep
                : i.type === 'r'
                ? graphDotColorRemSleep
                : graphDotColorNoSleep;

            const opacity = !selectedPhase ? '100%' : selectedPhase[0] === i.type ? '100%' : '10%';

            //console.log('PART', i, 'at (', background.x, i.y1, width, i.y2 - i.y1, ')');

            return (
              <rect
                key={`${x}_part_${index}`}
                x={background.x}
                y={i.y1}
                width={width}
                height={i.y2 - i.y1}
                fill={color}
                opacity={opacity}
              />
            );
          })}
        </g>
      );

      return ret;
    }

    return <></>;
  };

  const onBrushChange = (startTimestamp, endTimestamp) => {
    const start = moment(startTimestamp * 1000);
    const end = moment(endTimestamp * 1000);
    if (start.isValid() && end.isValid()) {
      const range = {
        start: start,
        end: end.startOf('day'),
      };
      setDateRange(range);
    }
  };

  const calcMaxSelectionDays = () => {
    return Math.floor(graphWidth / MINIMUM_PIXELS_PER_DAY);
  };
  const calcMinSelectionDays = () => {
    return 7;
  };

  const bindGestures = isDesktop
    ? () => {}
    : useGesture(
        {
          onDrag: state =>
            !isMobile
              ? defaultOnDrag(state)
              : defaultOnDrag_Mobile(
                  state,
                  graphWidth,
                  { dateRange, maxRange },
                  newDateRange => setDateRange(newDateRange),
                  false,
                ),
          onDragEnd: state =>
            !isMobile
              ? defaultOnDragEnd(
                  state,
                  1.8,
                  dateRange.start,
                  dateRange.end,
                  maxRange.start,
                  maxRange.end,
                  calcMinSelectionDays() - 1,
                  calcMaxSelectionDays(),
                  newDateRange => setDateRange(newDateRange),
                )
              : defaultOnDragEnd_Mobile(state, dateRange, maxRange, newDateRange => setDateRange(newDateRange)),
        },
        {
          drag: { threshold: 5 },
        },
      );

  const mobileMetricsPicker = (
    <GraphLegendWithToggle
      metrics={[
        {
          ...graphMetrics.find(m => m.id === graphMetricEnum.awakeDuration),
          isSelected: true,
          format: {
            stroke: graphDotColorNoSleep,
            dotShape: scatterDotShapeEnum.square,
            dotSize: symbolDotSizeEnum.small,
          },
        },
        {
          ...graphMetrics.find(m => m.id === graphMetricEnum.remDuration),
          isSelected: true,
          format: {
            stroke: graphDotColorRemSleep,
            dotShape: scatterDotShapeEnum.square,
            dotSize: symbolDotSizeEnum.small,
          },
        },
        {
          ...graphMetrics.find(m => m.id === graphMetricEnum.lightSleepDuration),
          isSelected: true,
          format: {
            stroke: graphDotColorLightSleep,
            dotShape: scatterDotShapeEnum.square,
            dotSize: symbolDotSizeEnum.small,
          },
        },
        {
          ...graphMetrics.find(m => m.id === graphMetricEnum.deepSleepDuration),
          isSelected: true,
          format: {
            stroke: graphDotColorDeepSleep,
            dotShape: scatterDotShapeEnum.square,
            dotSize: symbolDotSizeEnum.small,
          },
        },
      ]}
      // toggleMetric={id =>
      //   setSelectedMetrics(
      //     selectedMetrics.includes(id) ? selectedMetrics.filter(m => m !== id) : [...selectedMetrics, id],
      //   )
      // }
    />
  );

  const useTinyAxes = isMobile;
  const linesStrokeWidth = isMobile ? 0 : GRAPH_AXIS_LINE_WIDTH;

  return (
    <div
      className="graphDiv"
      style={{
        userSelect: 'none',
        touchAction: 'none',
        WebkitUserSelect: 'none',
      }}
      {...bindGestures()}
    >
      {isMobile && (
        <MobileRangePicker
          dateRange={dateRange}
          maxRange={maxRange}
          allowedRangeTypes={[mobileDataRangeTypes.week, mobileDataRangeTypes.twoWeeks, mobileDataRangeTypes.month]}
          onChange={newRange => setDateRange(newRange)}
          metricsToggles={mobileMetricsPicker}
        />
      )}
      <div
        style={{
          height: `calc(100% - ${
            useTinyAxes ? RANGE_PICKER_MOBILE_WITH_TOGGLES_HEIGHT + X_AXIS_CHART_HEIGHT_TINY : RANGE_PICKER_HEIGHT
          }px)`,
          minHeight: 100,
          paddingRight: isMobile ? Y_AXIS_WIDTH_TINY : 0,
          position: 'relative',
        }}
        className={useTinyAxes ? 'graph-surface-background' : undefined}
        ref={graphContainerRef}
        //{...bindGestures()}
      >
        {useTinyAxes && (
          <React.Fragment>
            <div
              style={{
                position: 'absolute',
                top: 0,
                height: 1,
                width: '100%',
                borderTop: 'solid 1px #D9D9D9',
              }}
            />
            <div
              style={{
                position: 'absolute',
                bottom: -1,
                height: 1,
                width: '100%',
                borderTop: 'solid 1px #D9D9D9',
              }}
            />
            <div
              style={{
                position: 'absolute',
                right: Y_AXIS_WIDTH_TINY - 1,
                height: '100%',
                width: 1,
                borderRight: 'solid 1px #D9D9D9',
              }}
            />
            <div
              style={{
                position: 'absolute',
                left: Y_AXIS_WIDTH_TINY - 1,
                height: '100%',
                width: 1,
                borderRight: 'solid 1px #D9D9D9',
              }}
            />
          </React.Fragment>
        )}
        {xAxisParams.unit !== 'hour' && (
          <ResponsiveContainer className="graph-responsive-container">
            <BarChart
              data={partsData}
              defaultShowTooltip={false}
              margin={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            >
              <Tooltip content={<CustomTooltip />} dataKey="x" isAnimationActive={false} />

              {/* reference areas to mimic alternate rows */}
              {referenceAreas(yAxisParams, useTinyAxes, X_AXIS_PADDING)}

              {warningDays.map((warningDay, index) => (
                <ReferenceArea
                  x1={warningDay + 60 * 60 * 12 - 60 * 60 * 10}
                  x2={warningDay + 60 * 60 * 12 + 60 * 60 * 10}
                  stroke={graphWarningFill}
                  fill={graphWarningFill}
                  fillOpacity={graphWarningFillOpacity}
                  key={`refareaWarning${index}}`}
                  shape={<WarningReferenceArea />}
                  yAxisId="yAxis1"
                />
              ))}

              {refLines.map(rl => (
                <ReferenceLine
                  x={rl}
                  stroke={GRAPH_AXIS_LINE_COLOR}
                  fill={GRAPH_AXIS_LINE_COLOR}
                  key={`refLine_${rl}`}
                  shape={<CustomReferenceLine xAxisParams={xAxisParams} skipAxis={isMobile} />}
                  yAxisId="yAxis1"
                />
              ))}

              {drawXAxis(xAxisParams, useTinyAxes ? 0 : 60, 'top', null, useTinyAxes)}
              <YAxis
                type="number"
                dataKey="y"
                name="hours"
                yAxisId="yAxis1"
                orientation="left"
                tick={
                  useTinyAxes ? (
                    false
                  ) : (
                    <CustomizedYAxisTick
                      axisMin={yAxisParams.axisMin}
                      sleepStartTick={yAxisParams.axisMin + 2}
                      sleepEndTick={yAxisParams.axisMax - 2}
                      dateMin={dateRange.start}
                      dateMax={dateRange.end}
                      axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: linesStrokeWidth }}
                      withoutHeader={useTinyAxes}
                      hourOnly={useTinyAxes}
                    />
                  )
                }
                domain={[yAxisParams.axisMin, yAxisParams.axisMax]}
                tickCount={yAxisParams.axisTicks}
                tickSize={0}
                interval={0}
                width={useTinyAxes ? Y_AXIS_WIDTH_TINY : Y_AXIS_LEFT_WIDTH}
                axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: linesStrokeWidth }}
                reversed
              />
              <YAxis
                type="number"
                dataKey="y"
                name="hours"
                yAxisId="yAxis2"
                orientation="right"
                tick={
                  useTinyAxes ? (
                    <CustomizedYAxisTick
                      axisMin={yAxisParams.axisMin}
                      sleepStartTick={yAxisParams.axisMin + 2}
                      sleepEndTick={yAxisParams.axisMax - 2}
                      dateMin={dateRange.start}
                      dateMax={dateRange.end}
                      axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: linesStrokeWidth }}
                      withoutHeader={useTinyAxes}
                      hourOnly={useTinyAxes}
                      isRight
                    />
                  ) : (
                    false
                  )
                }
                domain={[yAxisParams.axisMin, yAxisParams.axisMax]}
                tickCount={yAxisParams.axisTicks}
                tickSize={0}
                interval={0}
                width={useTinyAxes ? Y_AXIS_WIDTH_TINY : 0}
                axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: linesStrokeWidth }}
                reversed
              />
              <Bar
                key="x"
                tooltipType="none"
                dataKey="y"
                stackId="stack0"
                fillOpacity={1}
                maxBarSize={graphMaxBarSize}
                onClick={e => onClick(e)}
                cursor={allowHourlyGraph ? 'pointer' : 'default'}
                shape={<DayBar />}
                yAxisId="yAxis1"
              />
            </BarChart>
          </ResponsiveContainer>
        )}
        {xAxisParams.unit === 'hour' && (
          <ResponsiveContainer width="100%" height="99%" debounce>
            <BarChart
              layout="vertical"
              data={partsDataHourly}
              defaultShowTooltip={false}
              margin={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            >
              {/* <Tooltip content={<CustomTooltip />} dataKey="y" isAnimationActive={false} /> */}

              {/* reference areas to mimic alternate rows */}

              <ReferenceArea
                y1="a"
                y2="a"
                stroke={GRAPH_ROW_BK_ALTERNATE}
                fill={GRAPH_ROW_BK_ALTERNATE}
                fillOpacity="1"
                key={`refarea_first}`}
                shape={<CustomReferenceArea isUpper />}
              />
              <ReferenceArea
                y1="a"
                y2="a"
                stroke={GRAPH_ROW_BK}
                fill={GRAPH_ROW_BK}
                fillOpacity="1"
                key={`refarea_a}`}
                shape={<CustomReferenceArea />}
              />
              <ReferenceArea
                y1="r"
                y2="r"
                stroke={GRAPH_ROW_BK_ALTERNATE}
                fill={GRAPH_ROW_BK_ALTERNATE}
                fillOpacity="1"
                key={`refarea_r}`}
                shape={<CustomReferenceArea />}
              />
              <ReferenceArea
                y1="l"
                y2="l"
                stroke={GRAPH_ROW_BK}
                fill={GRAPH_ROW_BK}
                fillOpacity="1"
                key={`refarea_l}`}
                shape={<CustomReferenceArea />}
              />
              <ReferenceArea
                y1="d"
                stroke={GRAPH_ROW_BK_ALTERNATE}
                fill={GRAPH_ROW_BK_ALTERNATE}
                fillOpacity="1"
                key={`refarea_d}`}
                shape={<CustomReferenceArea />}
              />

              <YAxis
                type="category"
                allowDuplicatedCategory={false}
                dataKey="y"
                tick={
                  <CustomizedYAxisTickHourly
                    axisMin="a"
                    //day={date}
                    axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: linesStrokeWidth }}
                  />
                }
                tickSize={0}
                width={80}
                axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: linesStrokeWidth }}
                padding={{ top: graphAxisPaddingTB, bottom: graphAxisPaddingTB }}
              />

              <XAxis
                type="number"
                dataKey="x"
                allowDataOverflow
                height={useTinyAxes ? X_AXIS_HEIGHT_TINY : 60}
                domain={[xAxisParams.min, xAxisParams.max]}
                ticks={xAxisParams.ticks}
                interval={0}
                name="day"
                orientation="top"
                tickSize={0}
                tick={
                  <CustomizedXAxisProportional
                    xAxisParams={xAxisParams}
                    xAxisLabelsMinMaxOnly={false}
                    leftCornerTexts={[
                      moment(xAxisParams.max * 1000)
                        .format('MMM D')
                        .toUpperCase(),
                    ]}
                    drawFirstAndLastTick
                    oneLine={useTinyAxes}
                  />
                }
                padding={{ left: X_AXIS_PADDING, right: X_AXIS_PADDING }}
                axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: linesStrokeWidth }}
              />

              {/* every 4 hour vertical lines */}
              {Array.from({ length: xAxisParams.axisMax - xAxisParams.axisMin }, (_, i) => i).map(
                i =>
                  i % 4 === 0 && (
                    <ReferenceLine x={i} label="" stroke={graphReferenceVerticalLineColor} key={`vLine${i}`} />
                  ),
              )}

              {Array.from({ length: maxParts }, (_, i) => i).map(i => (
                <Bar
                  key={`bar_${i}`}
                  tooltipType="none"
                  dataKey={`xs[${i}].d`}
                  stackId="stack0"
                  radius={graphBarRadius}
                  fillOpacity={1}
                  maxBarSize={graphMaxBarSize}
                >
                  {partsDataHourly.map((entry, index) => {
                    const color =
                      entry.y === 'l'
                        ? graphDotColorLightSleep
                        : entry.y === 'd'
                        ? graphDotColorDeepSleep
                        : entry.y === 'r'
                        ? graphDotColorRemSleep
                        : graphDotColorNoSleep;
                    const opacity = !selectedPhase
                      ? '100%'
                      : selectedPhase === 'awake' && entry.y !== 'a'
                      ? '10%'
                      : selectedPhase === 'rem' && entry.y !== 'r'
                      ? '10%'
                      : selectedPhase === 'light' && entry.y !== 'l'
                      ? '10%'
                      : selectedPhase === 'deep' && entry.y !== 'd'
                      ? '10%'
                      : '100%';
                    const visibility = entry.y !== entry.xs[i].t ? 'hidden' : 'visible';
                    return (
                      <Cell
                        key={`cell-${index}`}
                        stroke={color}
                        fill={color}
                        visibility={visibility}
                        opacity={opacity}
                      />
                    );
                  })}
                </Bar>
              ))}
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
      {!useTinyAxes && <div className="horizontal-rule thick" />}
      {useTinyAxes && <CompositeGraphXAxis dateRange={dateRange} xAxisParams={xAxisParams} useTinyAxes />}
      {!isMobile && (
        <RangePicker
          startTimestamp={dateRange.start.unix()}
          endTimestamp={dateRange.end.unix()}
          dataStartTimestamp={maxRange.start.unix()}
          resolution="days"
          ticks={Math.round((maxRange.end.unix() - maxRange.start.unix()) / (24 * 60 * 60))}
          width="100%"
          leftPadding={Y_AXIS_LEFT_WIDTH + 1}
          rightPadding={Y_AXIS_RIGHT_WIDTH}
          onChange={onBrushChange}
          dateRange={maxRange}
          maxSelectionTicks={calcMaxSelectionDays()}
          minSelectionTicks={calcMinSelectionDays()}
          disableTraveller={isMobile}
        />
      )}
    </div>
  );
}

SleepGraph.propTypes = {
  graphData: PropTypes.array,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  selectedPhase: PropTypes.string,
  onDayClick: PropTypes.func,
  hasBorder: PropTypes.bool,
};

SleepGraph.defaultProps = { hasBorder: true };
