import { API_CALL } from '../../../../../middleware/api';
import { WIDGET_GET_PATIENT_ENROLMENT } from './constants';

export const actions = {
  getPatientsEnrollment: () => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/rpm/dashboard/patient-counters',
    },
    type: WIDGET_GET_PATIENT_ENROLMENT,
  }),
};
