import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Strings from '../Strings';
import { Input, TextArea } from '../containers/Form';
import Wizard from '../containers/Modal/Wizard';
import { notificationActions } from '../components/Notification/redux/actions';
import { prefabType } from '../pages/SuperUser/Messages/PrefabReplies';
import { closeModal } from '../actions/modal';
import { actions } from '../pages/SuperUser/Messages/redux/actions';
import { MAX_MESSAGE_LENGTH } from '../constants';

const EditPrefabReplyModal = props => {
  const [highlightInvalidFields, setHighlightMissingFields] = useState(false);
  const [prefabReply, setPrefabReply] = useState(props.data?.prefab ? props.data.prefab : { type: prefabType.shared });
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);

  const onFieldChange = (field, value) => {
    setPrefabReply(p => ({
      ...p,
      [field]: value,
    }));
  };

  const onSave = () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);
    props.onSubmit(prefabReply).then(response => {
      if (response) {
        if (response.type === `${props.data.actionType}/error`) {
          const error = response.response?.data?.error?.message;
          props.showNotification(error, 5000, true);
        } else {
          props.showNotification(Strings.formatString(Strings.itemSaved, Strings.messages.prefabReply));
          props.closeModalWithNextAction();
        }
      }
      return response;
    });
    setSaveBtnClicked(false);
  };

  const content = (
    <React.Fragment>
      <Input
        id="title"
        name="title"
        label={Strings.title}
        placeholder={Strings.title}
        value={prefabReply?.title}
        onChange={e => onFieldChange('title', e.target.value)}
        highlightInvalid={highlightInvalidFields}
        isRequired
      />
      <Input
        id="shortcut"
        name="shortcut"
        label={Strings.messages.shortcut}
        placeholder="#"
        value={prefabReply?.shortcut}
        onChange={e => onFieldChange('shortcut', e.target.value)}
        highlightInvalid={highlightInvalidFields}
        isRequired
      />
      <TextArea
        id="message"
        name="message"
        label={Strings.message}
        value={prefabReply?.full_txt}
        onChange={e => onFieldChange('full_txt', e.target.value)}
        isRequired
        errorsForTooltip={
          prefabReply?.full_txt?.length > MAX_MESSAGE_LENGTH
            ? [
                Strings.formatString(
                  Strings.maxAndOverflowChars,
                  MAX_MESSAGE_LENGTH,
                  prefabReply.full_txt.length - MAX_MESSAGE_LENGTH,
                ),
              ]
            : undefined
        }
        highlightInvalid={highlightInvalidFields}
        rows={8}
      />
      <Input
        type="checkbox"
        name="share"
        id="share"
        rowClassName="checkbox-first"
        label={Strings.messages.shareWithCollegues}
        checked={prefabReply?.type === prefabType.shared}
        onChange={e => onFieldChange('type', e.target.checked ? prefabType.shared : prefabType.private)}
      />
    </React.Fragment>
  );

  const pages = [
    {
      id: 'prefab-reply',
      title: props.data?.title
        ? props.data?.title
        : props.data?.prefab?.id
        ? Strings.messages.editPrefab
        : Strings.messages.createNewPrefab,
      content,
      emptyFieldsCount: [!prefabReply?.full_txt, !prefabReply?.title, !prefabReply?.shortcut].filter(Boolean).length,
      canGoNext: !!prefabReply?.full_txt && !!prefabReply?.title && !!prefabReply?.shortcut,
    },
  ];

  return (
    <Wizard
      name="edit-prefab-reply"
      pages={pages}
      onNextButtonHover={e => setHighlightMissingFields(e)}
      onSubmit={onSave}
    />
  );
};

EditPrefabReplyModal.propTypes = {
  onCancel: PropTypes.func,
  data: PropTypes.shape({
    action: PropTypes.func,
    actionType: PropTypes.string,
    nextAction: PropTypes.func,
    title: PropTypes.string,
    prefab: PropTypes.shape({
      title: PropTypes.string,
      full_txt: PropTypes.string,
      shortcut: PropTypes.string,
      type: PropTypes.string,
      id: PropTypes.number,
    }),
  }),
  showNotification: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeModalWithNextAction: () => {
    dispatch(closeModal('edit-prefab-reply'));
    dispatch(actions.getPrefabReplies());
  },
  onSubmit: data => {
    return dispatch(ownProps.data.action(data, data.id));
  },
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
});

export default connect(null, mapDispatchToProps)(EditPrefabReplyModal);
