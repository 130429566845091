export const GET_ORAGANIZATION_DEVICES_BILLING = 'get-organization-device-billing';
export const GET_ORAGANIZATION_DEVICES_BILLING_RESULT = `${GET_ORAGANIZATION_DEVICES_BILLING}/result`;
export const GET_ORAGANIZATION_DEVICES_BILLING_ERROR = `${GET_ORAGANIZATION_DEVICES_BILLING}/error`;

export const GET_DEVICES_FILTER = 'get-device-filter';
export const GET_DEVICES_FILTER_RESULT = `${GET_DEVICES_FILTER}/result`;
export const GET_DEVICES_FILTER_ERROR = `${GET_DEVICES_FILTER}/error`;

export const GET_TASK_BILLING = 'get-task-billing';
export const GET_TASK_BILLING_RESULT = `${GET_TASK_BILLING}/result`;
export const GET_TASK_BILLING_ERROR = `${GET_TASK_BILLING}/error`;

export const DOWNLOAD_BILLING_DETAILS_DATA = 'get-billing-details-data';
export const DOWNLOAD_BILLING_DETAILS_DATA_RESULT = `${DOWNLOAD_BILLING_DETAILS_DATA}/result`;
export const DOWNLOAD_BILLING_DETAILS_DATA_ERROR = `${DOWNLOAD_BILLING_DETAILS_DATA}/error`;

export const GET_TASK_DETAILS = 'get-task-details';
export const GET_TASK_DETAILS_RESULT = `${GET_TASK_DETAILS}/result`;
export const GET_TASK_DETAILS_ERROR = `${GET_TASK_DETAILS}/error`;

export const GET_DEVICE_EVENTS = 'get-device-events';
export const GET_DEVICE_EVENTS_RESULT = `${GET_DEVICE_EVENTS}/result`;
export const GET_DEVICE_EVENTS_ERROR = `${GET_DEVICE_EVENTS}/error`;

export const DOWNLOAD_TASK_DATA = 'download-task-list-data';
export const DOWNLOAD_TASK_DATA_RESULT = `${DOWNLOAD_TASK_DATA}/result`;
export const DOWNLOAD_TASK_DATA_ERROR = `${DOWNLOAD_TASK_DATA}/error`;

export const DOWNLOAD_TASK_AGGREGATED_DATA = 'download-task-aggregated-data';
export const DOWNLOAD_TASK_AGGREGATED_DATA_RESULT = `${DOWNLOAD_TASK_AGGREGATED_DATA}/result`;
export const DOWNLOAD_TASK_AGGREGATED_DATA_ERROR = `${DOWNLOAD_TASK_AGGREGATED_DATA}/error`;

export const LTE = 'lte';
export const BLE = 'ble';
export const HUB = 'hub';
export const CUSTOM = 'custom';
