import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { ANDROID_LINK, IOS_LINK } from '../../constants';
import { getMobileOS } from '../../utils';
import './AppRedirection.scss';

// eslint-disable-next-line react/prefer-stateless-function
class AppRedirection extends PureComponent {
  render() {
    switch (getMobileOS()) {
      case 'Windows Phone':
      case 'unknown':
        window.location = '/login';
        break;
      case 'Android':
        window.location = ANDROID_LINK;
        break;
      case 'iOS':
        window.location = IOS_LINK;
        break;
      default:
        window.location = '/login';
        break;
    }

    return <div className="app-bg-color"></div>;
  }
}

const mapDispatchToProps = dispatch => ({ onNavigate: path => dispatch(push(path)) });

export default connect(null, mapDispatchToProps)(AppRedirection);
