import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './CareGiverSuccessPage.scss';
import { caregiverAction } from '../actions/caregiver';

class CareGiverSuccessPage extends PureComponent {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.myRef.current.scrollTo(0, 0);
  }

  render() {
    const width = 100;
    const style = { width: `${width}%` };
    return (
      <div className="full-height2">
        <div className="caregiver-container2" ref={this.myRef}>
          <div className="caregiver-header2">
            <div className="success-logo"></div>
            <div className="header-progress-bar mt-20">
              <div className="active-progess" style={style}></div>
            </div>
          </div>

          <div className="body-content2">
            <div className="content mt">
              <div className="text-container">
                <div className="heading">
                  Great <br />
                  You’re All Done
                </div>
                <div className="body mt">
                  You will now receive text messages whenever your dependant misses their schedules medication.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.caregiver.loading,
    error: state.caregiver.error,
  };
};

const mapDispatchToProps = dispatch => ({
  verifyAndRegisterAsCareGiver: data => dispatch(caregiverAction.acceptRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CareGiverSuccessPage);

CareGiverSuccessPage.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  verifyAndRegisterAsCareGiver: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};
