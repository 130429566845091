import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { clearEntitiesAction } from '../../actions';
import { UPDATE_PATIENT_SCHEDULE_ERROR, UPDATE_PATIENT_SCHEDULE_RESULT } from '../../actions/action-types';
import { resetForm } from '../../actions/forms';
import { closeModal } from '../../actions/modal';
import { patientAction } from '../../actions/patient';
import LoadingRenderer from '../../components/LoadingRenderer';
import { Modal } from '../../containers';
import Form, { Input } from '../../containers/Form';
import Strings from '../../Strings';
import './EditPatientSchedule.scss';

class EditPatientScheduleModal extends PureComponent {
  scheduleFormRef = React.createRef();
  newSchedule = {
    frequency: '',
    timesPerDay: '',
    time: [],
    schedule_timezone: this.props.timezone,
  };
  state = {
    lastKey: null,
    errors: [],
    schedule: this.props.data.data ? cloneDeep(this.props.data.data) : this.newSchedule,
  };

  componentDidMount() {
    if (this.scheduleFormRef) {
      this.scheduleFormRef?.current?.focus();
    }
  }

  onSubmit = () => {
    const schedule = cloneDeep(this.state.schedule);
    schedule.offset = schedule.time.map(offset => {
      return moment(offset, 'HH:mm A').diff(moment().startOf('day'), 'seconds');
    });

    delete schedule.time;
    delete schedule.timesPerDay;
    delete schedule.frequency;
    const patientId = schedule.patientId;
    delete schedule.patientId;

    const data = moment().endOf('day');
    schedule.start_dt = Math.round(data.unix() / 1000);
    this.props.onSubmit(patientId, schedule).then(response => {
      if (response.type === UPDATE_PATIENT_SCHEDULE_ERROR && response.response.data) {
        const responseData = response.response.data.error;
        const errors = [responseData.message];
        this.setState({ errors });
      }

      if (response && response.type === UPDATE_PATIENT_SCHEDULE_RESULT) {
        this.props.loadMedications(response.response.id);
        this.props.onNavigate(`/patient/${patientId}/medications/${response.response.id}/details`);
      }

      return response;
    });
  };

  updateTimeByKey = (item, operation, index) => {
    this.updateTime(item, operation, index);
  };

  updateTime = (item, operation, index, key) => {
    const schedule = cloneDeep(this.state.schedule);

    if (key) {
      const isNumber = /^[0-9]$/i.test(key.key);
      if (isNumber) {
        if (item === 'HH') {
          if (this.state.lastKey == 1) {
            if (key.key == 1) {
              this.setState({ lastKey: 11 });
              schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
                .set('hour', 11)
                .format('hh:mm a');
            } else if (key.key == 2) {
              this.setState({ lastKey: 12 });
              schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
                .set('hour', 12)
                .format('hh:mm a');
            } else {
              this.setState({ lastKey: key.key });
              schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
                .set('hour', key.key)
                .format('hh:mm a');
            }
          } else {
            this.setState({ lastKey: key.key });
            schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
              .set('hour', key.key)
              .format('hh:mm a');
          }
        } else if (item === 'MM') {
          if (
            this.state.lastKey == 1 ||
            this.state.lastKey == 2 ||
            this.state.lastKey == 3 ||
            this.state.lastKey == 4 ||
            this.state.lastKey == 5
          ) {
            const minutes = this.state.lastKey.concat(key.key);
            this.setState({ lastKey: minutes });
            schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
              .set('minutes', minutes)
              .format('hh:mm a');
          } else {
            this.setState({ lastKey: key.key });
            schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
              .set('minutes', key.key)
              .format('hh:mm a');
          }
        }
      } else if (item === 'HH') {
        if (key.key == 'ArrowUp') {
          this.setState({ lastKey: 0 });
          schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
            .add(1, 'hours')
            .format('hh:mm a');
        } else if (key.key == 'ArrowDown') {
          this.setState({ lastKey: 0 });
          schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
            .subtract(1, 'hours')
            .format('hh:mm a');
        } else if (key.key == 'Backspace') {
          schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
            .set('hour', 1)
            .format('hh:mm a');
        }
      } else if (item === 'MM') {
        if (key.key == 'ArrowUp') {
          this.setState({ lastKey: 0 });
          schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
            .add(1, 'minutes')
            .format('hh:mm a');
        } else if (key.key == 'ArrowDown') {
          this.setState({ lastKey: 0 });
          schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
            .subtract(1, 'minutes')
            .format('hh:mm a');
        } else if (key.key == 'Backspace') {
          schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
            .set('minutes', 0)
            .format('hh:mm a');
        }
      }
    } else {
      if (item === 'HH' || item === 'PM' || item === 'AM') {
        if (operation === 'DOWN') {
          this.setState({ lastKey: 0 });
          schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
            .subtract(1, 'hours')
            .format('hh:mm a');
        } else if (item === 'PM' || item === 'AM') {
          this.setState({ lastKey: 0 });
          schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
            .add(12, 'hours')
            .format('hh:mm a');
        } else {
          this.setState({ lastKey: 0 });
          schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
            .add(1, 'hours')
            .format('hh:mm a');
        }
      }
      if (item === 'MM') {
        if (operation === 'DOWN') {
          this.setState({ lastKey: 0 });
          schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
            .subtract(5, 'minutes')
            .format('hh:mm a');
        } else {
          this.setState({ lastKey: 0 });
          schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
            .add(5, 'minutes')
            .format('hh:mm a');
        }
      }
    }

    this.setState({ schedule });
    this.forceUpdate();
  };
  render() {
    const { onOpen, onCancel, data, ...props } = this.props;
    const { errors, schedule } = this.state;
    return (
      <Modal name="edit-patient-schedule" onOpen={onOpen} {...props} roundedCorner>
        <LoadingRenderer loading={false}>
          <Form onSubmit={this.onSubmit} onCancel={onCancel} id="edit-patient-schedule">
            <div className="schedule content-block" key="schedule-form">
              <div className="row title">
                <span>{Strings.patient_medications.frequency}</span>
                <span>
                  {schedule.frequency && (
                    <Input
                      name="frequency"
                      id="frequency"
                      label=""
                      placeholder={Strings.patient_medications.frequency}
                      type="text"
                      defaultValue={schedule.frequency}
                      disabled
                    />
                  )}
                </span>
              </div>

              <div className="row title">
                <span>{Strings.patient_medications.timesPerDay}</span>
                <span>
                  <Input
                    name="timesPerDay"
                    id="timesPerDay"
                    label=""
                    placeholder={Strings.patient_medications.timesPerDay}
                    type="text"
                    defaultValue={schedule.timesPerDay}
                    disabled
                  />
                </span>
              </div>

              {schedule.time && Array.isArray(schedule.time)
                ? schedule.time.map((scheduleData, index) => {
                    return (
                      <div className="row title" key={index}>
                        <span>
                          {Strings.patient_medications.time} {index + 1}
                        </span>
                        <div className="timePicker">
                          <div className="img-input-container">
                            <a className="step up" onClick={() => this.updateTime('HH', 'UP', index)} />

                            <input
                              autoComplete="off"
                              className="timeStep"
                              onChange={this.onChange}
                              type="text"
                              id="hh"
                              name="hh"
                              label=""
                              value={parseInt(scheduleData.split(':')[0], 10)}
                              placeholder={Strings.patient_medications.time}
                              onKeyDown={e => this.updateTime('HH', 'UP', index, e)}
                              ref={this.scheduleFormRef}
                              tabIndex={0}
                            />
                            <a className="step down" onClick={() => this.updateTime('HH', 'DOWN', index)} />
                          </div>
                          <div className="divider">:</div>
                          <div className="img-input-container">
                            <div className="step up" onClick={() => this.updateTime('MM', 'UP', index)} />
                            <input
                              autoComplete="off"
                              className="timeStep"
                              onChange={this.onChange}
                              type="text"
                              id="mm"
                              label=""
                              value={parseInt(scheduleData.split(':')[1], 10)}
                              placeholder={Strings.patient_medications.time}
                              onKeyDown={e => this.updateTime('MM', 'UP', index, e)}
                              tabIndex={0}
                            />
                            <div className="step down" onClick={() => this.updateTime('MM', 'DOWN', index)} />
                          </div>
                          <div className="ampmSwitch">
                            <div
                              className={`am block  ${
                                scheduleData.split(' ')[1] === 'am' || scheduleData.split(' ')[1] === 'AM'
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={() => this.updateTime('AM', 'UP', index)}
                              tabIndex={0}
                              onKeyDown={e => {
                                const charCode = e.which ? e.which : e.keyCode;
                                if (charCode === 13) {
                                  this.updateTime('AM', 'UP', index);
                                }
                              }}
                            >
                              {Strings.patient_medications.am}
                            </div>
                            <div
                              className={`pm block ${
                                scheduleData.split(' ')[1] === 'pm' || scheduleData.split(' ')[1] === 'PM'
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={() => this.updateTime('PM', 'UP', index)}
                              tabIndex={0}
                              onKeyDown={e => {
                                const charCode = e.which ? e.which : e.keyCode;
                                if (charCode === 13) {
                                  this.updateTime('PM', 'UP', index);
                                }
                              }}
                            >
                              {Strings.patient_medications.pm}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ''}

              <div className="clear" />
              <div id="errors" className="errors-block">
                {errors.join(' ')}
              </div>

              <div className="buttons" key="button-bar">
                <button className="brand-white-gray" key="cancel" type="cancel">
                  {Strings.cancel}
                </button>
                <button className="brand-blue" key="submit" type="submit">
                  {Strings.save}
                </button>
              </div>
            </div>
          </Form>
        </LoadingRenderer>
      </Modal>
    );
  }
}

EditPatientScheduleModal.propTypes = {
  data: PropTypes.shape({ data: PropTypes.any }),
  loadMedications: PropTypes.func,
  onCancel: PropTypes.any,
  onNavigate: PropTypes.func,
  onOpen: PropTypes.any,
  onSubmit: PropTypes.func,
  username: PropTypes.string,
  timezone: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    username: state.auth?.profile.username,
    timezone: state.auth?.profile?.preferences?.timezone,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('edit-patient-schedule')),
  onSubmit: (patientId, data) =>
    dispatch(patientAction.actionUpdateSchedule(data, patientId)).then(response => {
      if (response && response.type === UPDATE_PATIENT_SCHEDULE_RESULT) {
        dispatch(closeModal('edit-patient-schedule'));
        dispatch(clearEntitiesAction(['patientNotifications']));
      }

      return response;
    }),
  onNavigate: path => dispatch(push(path)),
  onOpen: () => dispatch(resetForm('edit-patient-schedule', ownProps.data)),
  loadMedications: medicationId => dispatch(patientAction.actionMedicationDetails(medicationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPatientScheduleModal);
