import { API_CALL } from '../../../middleware/api';
import {
  ESI_ADMIN_ADHERENCE,
  ESI_ADMIN_ADHERENCE_CLEAR,
  ESI_ADMIN_CAP_EVOLUTION,
  ESI_ADMIN_CAP_EVOLUTION_CLEAR,
} from './constants';

export const actions = {
  getAdherence: pageRequest => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/esi/v1/dashboard/adherence',
      params: pageRequest,
    },
    type: ESI_ADMIN_ADHERENCE,
  }),
  getCapEvolution: pageRequest => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/esi/v1/dashboard/cap-evolution',
      params: pageRequest,
    },
    type: ESI_ADMIN_CAP_EVOLUTION,
  }),
  clearDashboardDetails: () => ({
    type: ESI_ADMIN_ADHERENCE_CLEAR,
  }),
  clearCapEvolutiondata: () => ({
    type: ESI_ADMIN_CAP_EVOLUTION_CLEAR,
  }),
};
