import {
  LIST_SHEDULES,
  LIST_SHEDULES_RESULT,
  CLEAR_ENTITIES,
  LIST_SHEDULES_ERROR,
  LIST_MEDICATION,
  LIST_MEDICATION_RESULT,
  LIST_MEDICATION_DETAILS_RESULT,
  LIST_MEDICATION_DETAILS_ERROR,
  LIST_MEDICATION_DETAILS,
  CLEAR_SCHEDULES,
} from '../actions/action-types';

const initialState = [];
const schedulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_MEDICATION: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_MEDICATION_RESULT:
      return {
        medications: action.response.medications,
        adherence: action.response.adherence,
        active_medications: action.response.active_medications,
        performance: action.response.performance,
        pagination: action.response.pagination,
        loading: false,
        preparing: false,
      };

    case LIST_SHEDULES: {
      const tempSchedules = state.schedules;
      if (tempSchedules && Array.isArray(tempSchedules)) {
        const schdeules = tempSchedules.map(user => {
          const d = user;
          d.isLoading = true;
          return d;
        });
        return {
          ...state,
          schdeules,
          loading: true,
        };
      }
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_SHEDULES_RESULT:
      return {
        schedules: action.response,
        loading: false,
        preparing: false,
      };

    case CLEAR_SCHEDULES:
      return {
        ...state,
        schedules: [],
      };

    case LIST_SHEDULES_ERROR: {
      const tempSchedules = state.schedules;
      if (tempSchedules) {
        const schdeules = tempSchedules.map(user => {
          const d = user;
          d.isLoading = false;
          return d;
        });
        return {
          ...state,
          schdeules,
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    }

    case LIST_MEDICATION_DETAILS: {
      return {
        ...state,
        loading: true,
      };
    }

    case LIST_MEDICATION_DETAILS_RESULT: {
      return {
        ...state,
        medicationDetails: action.response,
        loading: false,
        preparing: false,
      };
    }

    case LIST_MEDICATION_DETAILS_ERROR: {
      return {
        ...state,
        medicationDetails: action.response,
        loading: false,
        preparing: false,
      };
    }

    case CLEAR_ENTITIES:
      if (action.entities && action.entities.indexOf('schedules') !== -1) {
        return {
          ...state,
          objects: {},
          preparing: true,
        };
      }
      return state;
    default:
      return state;
  }
};
export default schedulesReducer;
