import PropTypes from 'prop-types';
import { closeModal } from 'actions/modal';
import { notificationActions } from 'components/Notification/redux/actions';
import { WizardForm } from 'components/WizardForm/WizardForm';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../containers';
import { TextInputField } from '../../fields/TextInputField';
import Strings from '../../Strings';
import { composeValidators } from '../../utils/validators/composeValidators';
import { makeRequired } from '../../utils/validators/isRequired';
import '../editStudyModal.scss';
import { actions } from './redux/actions';

const mapDispatchToProps = dispatch => ({
  onCancel: () => dispatch(closeModal('esi-patient-update-hardware-cap-id-modal')),
  updateCapId: (schedule_id, cap_id) => dispatch(actions.updateCapId(schedule_id, cap_id)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
});

class ESIPatientUpdateHardwareCapIdModal extends PureComponent {
  static MODAL_NAME = 'esi-patient-update-hardware-cap-id-modal';

  state = { errors: [] };

  onSubmit = values => {
    const {
      data: { schedule_id, onSuccess },
    } = this.props;

    const oldCapId = this.props.data.cap_id;

    const { cap_id } = values;

    if (cap_id === oldCapId) {
      this.props.onCancel();
      return;
    }

    this.props.updateCapId(schedule_id, cap_id).then(r => {
      if (r.response && r.response.success && r.response.success.message === 'OK') {
        onSuccess();
        this.props.showNotification(Strings.hardwareIdUpdatedSuccessfully);
        this.props.onCancel();
        return;
      }
      window.location.reload();
    });
  };

  render() {
    const {
      data: { cap_id },
      onOpen,
      onCancel,
      ...props
    } = this.props;

    return (
      <Modal
        onOpen={onOpen}
        errors={this.state.errors}
        name={ESIPatientUpdateHardwareCapIdModal.MODAL_NAME}
        additionalClasses={['form-modal', 'user-invite']}
        withoutContainer
        {...props}
      >
        <WizardForm
          onSubmit={this.onSubmit}
          onCancel={onCancel}
          initialValues={{ cap_id }}
          titles={[Strings.registerYourSmartCap]}
          submitButtonTitle={Strings.save}
          adaptiveLayout
        >
          <WizardForm.Page>
            <div
              style={{
                color: '#4f4f4f',
                marginBottom: 20,
              }}
            >
              {Strings.findTheSmartCapID}
            </div>
            <TextInputField
              style={{ textAlign: 'left' }}
              name="cap_id"
              validate={composeValidators(makeRequired(Strings.fieldIsRequired))}
            />
          </WizardForm.Page>
        </WizardForm>
      </Modal>
    );
  }
}

ESIPatientUpdateHardwareCapIdModal.propTypes = {
  data: PropTypes.shape({ cap_id: PropTypes.any }),
  onCancel: PropTypes.func,
  onOpen: PropTypes.any,
  showNotification: PropTypes.func,
  updateCapId: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ESIPatientUpdateHardwareCapIdModal);
