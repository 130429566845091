import { select } from 'd3-selection';
import { axisBottom, axisLeft, axisRight } from 'd3-axis';
import { scaleLinear } from 'd3-scale';
import { interpolate } from 'd3-interpolate';

import Strings from '../../../Strings';

class TimeStampPlot {
  static defaultValues = { radius: 5 };

  constructor() {
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.destroy = this.destroy.bind(this);
    this.updateAxes = this.updateAxes.bind(this);
    this.updateViewBox = this.updateViewBox.bind(this);
    this.renderCircles = this.renderCircles.bind(this);
    this.installHandlers = this.installHandlers.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);
  }

  resizeHandler() {
    const { timeStamps } = this.props;

    const viewBox = this.updateViewBox();

    const x = scaleLinear()
      .range([0, viewBox.width])
      .domain(TimeStampPlot.getXBorders());

    const borders = TimeStampPlot.getYBorders();
    const yTicksCount = borders[1] - borders[0];

    const y = scaleLinear()
      .range([0, viewBox.height])
      .domain(borders)
      .nice();

    this.updateAxes(
      {
        x,
        y,
      },
      viewBox,
      yTicksCount,
    );
    this.renderCircles(timeStamps, x, y, viewBox);
  }

  installHandlers() {
    window.addEventListener('resize', this.resizeHandler);
  }

  create(node, props) {
    this.props = props;

    const { timeStamps } = props;

    this.container = select(node);

    this.svg = this.container.append('svg').attr('class', 'plot timestamp');

    this.gMain = this.svg.append('g').attr('class', 'main');
    const viewBox = this.updateViewBox();
    const x = scaleLinear()
      .range([0, viewBox.width])
      .domain(TimeStampPlot.getXBorders());

    const borders = TimeStampPlot.getYBorders();
    const yTicksCount = borders[1] - borders[0];

    const y = scaleLinear()
      .range([0, viewBox.height])
      .domain(borders)
      .nice();

    // this.gMain.append("g").attr("class", "grid vertical");

    this.gMain.append('g').attr('class', 'axis middle');

    this.gMain
      .append('g')
      .attr('class', 'axis middle-vertical')
      .attr('display', 'none');

    this.gMain.append('g').attr('class', 'axis middle-vertical-right');

    this.gMain.append('g').attr('class', 'chart');

    this.renderCircles(timeStamps, x, y, viewBox);
    this.updateAxes(
      {
        x,
        y,
      },
      viewBox,
      yTicksCount,
    );
    this.installHandlers();
  }

  static getXTicks() {
    const borders = TimeStampPlot.getXBorders();
    const ticks = [];

    for (let i = borders[0]; i <= borders[1]; i += 60) {
      ticks.push(i);
    }

    return ticks;
  }

  static getXBorders() {
    return [0, 1440];
  }

  static getYBorders() {
    return [-0.5, 6.5];
  }

  updateAxes(scales, viewBox, yTicksCount) {
    const { xTickFormat, yTickFormat } = this.props;

    const { x, y } = scales;

    const axes = {
      x: axisBottom(x)
        .tickValues(TimeStampPlot.getXTicks())
        .tickSize(viewBox.height - 30)
        .tickSizeOuter([0]),
      y: axisLeft(y)
        .ticks(yTicksCount)
        .tickSize(0)
        .tickPadding(0)
        .tickSizeOuter([2]),
    };

    if (xTickFormat) {
      axes.x.tickFormat(xTickFormat);
    }

    if (yTickFormat) {
      axes.y.tickFormat(yTickFormat);
    }

    const { x: axisX, y: axisY } = axes;

    this.gMain.select('g.axis.middle').call(axisX);

    this.gMain.select('g.axis.middle-vertical').call(axisY);

    ['g.axis.middle-vertical', 'g.axis.middle'].forEach(axisClassName => {
      this.gMain.selectAll(`${axisClassName} g.tick line`).each(function(d, i) {
        // eslint-disable-line func-names
        if (i === 0) {
          const el = select(this);
          el.attr('visibility', 'visible');
        }
      });
    });

    ['g.axis.middle'].forEach(axisClassName => {
      this.gMain.selectAll(`${axisClassName} g.tick text`).each(function() {
        // eslint-disable-line func-names
        const el = select(this);
        el.attr('transform', `translate(0,${44})`);
      });
    });

    ['g.axis.middle-vertical'].forEach(axisClassName => {
      this.gMain.selectAll(`${axisClassName} g.tick line`).each(function() {
        // eslint-disable-line func-names
        const el = select(this);
        el.attr('transform', `translate(0,-${viewBox.height / yTicksCount / 2})`);
        el.attr('stroke-dasharray', '4,2');
      });
    });

    ['g.axis.middle-vertical'].forEach(axisClassName => {
      this.gMain.selectAll(`${axisClassName} g.tick text`).each(function() {
        // eslint-disable-line func-names
        const el = select(this);
        el.attr('transform', 'translate(0,0)');
        el.attr('text-anchor', 'middle');
      });
    });

    this.gMain.select('g.grid.vertical').call(
      axisBottom(x)
        .tickSize(0)
        .tickFormat(''),
    );
    // .attr("transform", `translate(0,${viewBox.height})`);
    // .select('.domain')
    // .remove();

    this.gMain
      .select('g.axis.middle-vertical-right')
      .call(
        axisRight(y)
          .tickSize(0)
          .tickFormat(''),
      )
      .attr('transform', `translate(${viewBox.width},0)`);
  }

  renderCircles(data, x, y, viewBox) {
    const { maxTaken } = this.props;

    const getColor = taken => {
      const opacity = Math.ceil(40 + (taken * 60) / maxTaken) / 100;

      let r = 78;
      let g = 192;
      let b = 43; // #4ec02b;

      r += (255 - r) * (1 - opacity);
      g += (255 - g) * (1 - opacity);
      b += (255 - b) * (1 - opacity);

      return `rgb(${Math.floor(r)},${Math.floor(g)},${Math.floor(b)})`;
    };

    this.gMain
      .select('g.chart')
      .selectAll('circle')
      .data(data)
      .enter()
      .append('circle');

    if (data[0].formattedTime !== 'Invalid date') {
      const circles = this.gMain
        .select('g.chart')
        .selectAll('circle')
        .data(data)
        .attr('class', d => `dot ${d.key}`)
        .attr('cx', d => x(d.time))
        .attr('cy', d => y(d.weekDay))
        .attr('r', () => TimeStampPlot.defaultValues.radius)
        .attr('data-tip-content', `${d.taken} ${Strings.taken}`)
        .attr('data-tooltip-id', 'tooltip')
        .attr('fill', d => getColor(d.taken));

      this.gMain
        .select('g.chart')
        .selectAll('circle')
        .data(data)
        .exit()
        .remove();

      circles.transition().duration(500); // .ease('quad-out');

      const self = this;

      circles
        .on('mouseover', function(circleData) {
          // eslint-disable-line func-names
          const circle = select(this);

          // append lines to dots that will be used to show the precise data points.

          self.gMain
            .append('g')
            .attr('class', 'guide')
            .append('line')
            .attr('x1', circle.attr('cx'))
            .attr('x2', circle.attr('cx'))
            .attr('y1', 0)
            .attr('y2', +circle.attr('cy') - TimeStampPlot.defaultValues.radius)
            .style('stroke', circle.style('fill'))
            .transition()
            .duration(400)
            .styleTween('opacity', () => interpolate(0, 1));

          self.gMain
            .append('g')
            .attr('class', 'guide')
            .append('line')
            .attr('x1', circle.attr('cx'))
            .attr('x2', circle.attr('cx'))
            .attr('y1', +circle.attr('cy') + TimeStampPlot.defaultValues.radius)
            .attr('y2', viewBox.height)
            .style('stroke', circle.style('fill'))
            .transition()
            .duration(400)
            .styleTween('opacity', () => interpolate(0, 1));

          // create text with background (rect) on left side to show the exact time

          const textContainer = self.gMain.append('g').attr('class', 'guide');

          const textEl = textContainer
            .append('text')
            .attr('class', 'guide-tooltip')
            .text(circleData.formattedTime);
          const vertAxisContainer = select('g.axis.middle-vertical');
          const { height: containerHeight } = vertAxisContainer.node().getBBox();
          const { width: textWidth } = textEl.node().getBBox();

          const textHeight = 18;

          textEl.attr('x', circle.attr('cx'));
          textEl.attr('y', containerHeight + textHeight / 2 + 9);
          // create background for text
          const textBackground = textContainer.insert('rect', 'text.guide-tooltip');

          textBackground
            .attr('class', 'guide-tooltip')
            .attr('x', circle.attr('cx') - textWidth / 2 - 5)
            .attr('y', containerHeight)
            .attr('rx', 3)
            .attr('ry', 3)
            .attr('height', textHeight + 7)
            .attr('width', textWidth + 10);

          textEl
            .transition()
            // .delay(200)
            .duration(400)
            .styleTween('opacity', () => interpolate(0, 1));

          textBackground
            .transition()
            // .delay(200)
            .duration(400)
            .styleTween('opacity', () => interpolate(0, 1));
        })
        .on('mouseout', function() {
          // eslint-disable-next-line
          const circle = select(this);

          // fade out guide lines, then remove them
          self.gMain
            .selectAll('g.guide')
            .transition()
            .delay(200)
            .duration(100)
            .styleTween('opacity', () => interpolate(1, 0))
            .remove();
        });
    }
  }

  updateViewBox() {
    const brc = this.container.node().getBoundingClientRect();
    const { margin } = this.props;
    const viewBox = {
      x: 0,
      y: 0,
      width: brc.width + 100,
      height: brc.height - margin.top - margin.bottom,
    };

    this.svg.attr('viewBox', `0 0 ${brc.width} ${brc.height}`);
    this.gMain.attr('transform', `translate(-60,0)`);
    return viewBox;
  }

  update(node, props) {
    this.props = props;
    const { timeStamps } = props;
    this.container = select(node);
    this.svg = this.container.select('svg');
    this.gMain = this.svg.select('g.main');
    const viewBox = this.updateViewBox();

    const x = scaleLinear()
      .range([0, viewBox.width])
      .domain(TimeStampPlot.getXBorders());

    const borders = TimeStampPlot.getYBorders();
    const yTicksCount = borders[1] - borders[0];

    const y = scaleLinear()
      .range([0, viewBox.height])
      .domain(borders)
      .nice();

    this.renderCircles(timeStamps, x, y, viewBox);
    this.updateAxes(
      {
        x,
        y,
      },
      viewBox,
      yTicksCount,
    );
  }

  destroy() {
    window.removeEventListener('resize', this.resizeHandler);
  }
}

export default TimeStampPlot;
