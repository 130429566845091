import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment-timezone';

import '../Widgets.scss';
import Strings from '../../../../Strings';
import { actions } from '../../redux/actionsWidgets';
import { roundToDecimal } from '../../../../utils/unitConverters';
import DeviceStatsGraph from '../../../../components/Graphs/DeviceStatsGraph';
import { ColorPalette } from '../../DashboardColorPalettes';
import { graphContainer } from '../graphContainers';
import { chartTypeEnum } from '../../../../components/Graphs/GenericCategoryGraph';

export const deviceStatsWidgetTypes = {
  numbers: 'numbers',
  barChart: chartTypeEnum.bar,
  pieChart: chartTypeEnum.pie,
  lineChart: chartTypeEnum.line,
  areaChart: chartTypeEnum.area,
};

function getNameForStat(stat) {
  return stat.charAt(0).toUpperCase() + stat.replace('_number', '').slice(1);
}

function getTooltipForStat(stat, period, deviceType) {
  return Strings.formatString(Strings.widgetsTooltips[`${stat}_${period}`], Strings.devicesPlural[deviceType]);
}

function HubStats(props) {
  const [stats, setStats] = useState();

  useEffect(() => {
    // wait for timezone to be set
    if (!props.timezone) return;

    const request = { device_type: props.deviceType, type: props.statsPeriod };
    switch (props.statsPeriod) {
      case HubStatsPeriod.realTime:
        request.start_time = moment().format();
        break;
      case HubStatsPeriod.yesterday:
        request.start_time = moment()
          .subtract(1, 'day')
          .startOf('day')
          .format();
        break;
      case HubStatsPeriod.monthly:
        request.start_time = moment()
          .tz('UTC')
          .subtract(1, 'month')
          .format();
        break;
      default:
        break;
    }
    props.getActivityStatistics(request).then(resp => {
      setStats(resp.response);
    });
  }, [props.refreshTimestamp, props.timezone]);

  const getColorForStat = stat => {
    switch (stat) {
      case 'active_number':
      case 'online_number':
        return 'green';
      case 'offline_number':
      case 'new_number':
      case 'activated_number':
        return 'blue';
      default:
        return 'black';
    }
  };

  const supportedStates = [
    'active_number',
    'new_number',
    'offline_number',
    'online_number',
    'total_number',
    'activated_number',
  ];

  const prefix =
    props.statsPeriod === HubStatsPeriod.yesterday
      ? Strings.daily
      : props.statsPeriod === HubStatsPeriod.monthly
      ? Strings.monthly
      : '';

  const onClick = (stat, deviceType) => {
    const status = `["Status:${getNameForStat(stat)}"]`;
    const searchString = `?filters=${status}`;
    if (props.statsPeriod === HubStatsPeriod.realTime) {
      props.onClick(`/devices/${deviceType}`, null, stat === 'total_number' ? undefined : searchString);
    }
  };

  const widgetType = props.config.widgetType.value;
  return widgetType === deviceStatsWidgetTypes.numbers ? (
    <div className="box2colsWithVl">
      {stats &&
        Object.entries(stats)
          .filter(v => v[0] !== 'type' && supportedStates.includes(v[0]))
          .map(v => (
            <div className="box2colsWithVl-box" key={`stats-${props.statsPeriod}-${v[0]}`}>
              <div
                className={`box2colsWithVl-valueBox ${getColorForStat(v[0])}`}
                data-tooltip-content={`${
                  stats.total_number === 0 ? 0 : roundToDecimal((v[1] / stats.total_number) * 100, 0)
                }% off all ${Strings.devicesPlural[props.deviceType]}`}
                data-tooltip-id={
                  props.dragging || (stats.total_number && v[0] === 'total_number') ? undefined : 'tooltip'
                }
              >
                {v[1]}
              </div>
              <div
                onClick={() => onClick(v[0], props.deviceType)}
                className={`box2colsWithVl-labelBox ${
                  props.statsPeriod === HubStatsPeriod.realTime ? 'clickable' : ''
                }`}
                data-tooltip-content={getTooltipForStat(v[0], props.statsPeriod, props.deviceType)}
                data-tooltip-id={props.dragging ? undefined : 'tooltip'}
              >
                {props.statsPeriod === HubStatsPeriod.realTime && v[0] === 'activated_number'
                  ? Strings.formatString(Strings.widgets.devicesActivatedToday, Strings.devicesPlural[props.deviceType])
                  : `${prefix} ${Strings[v[0]] || getNameForStat(v[0])} ${Strings.devicesPlural[props.deviceType]}`}
              </div>
            </div>
          ))}
    </div>
  ) : (
    graphContainer(
      <DeviceStatsGraph
        graphData={
          stats
            ? Object.entries(stats)
                .filter(
                  v =>
                    v[0] !== 'type' &&
                    supportedStates.includes(v[0]) &&
                    v[0] !== 'total_number' &&
                    v[0] !== 'activated_number',
                )
                .map(([key, value]) => ({
                  state: getNameForStat(key),
                  count: value,
                }))
            : []
        }
        graphType={widgetType}
        colorPalette={props.colorPalette}
        onCategoryClicked={
          props.statsPeriod === HubStatsPeriod.realTime ? state => onClick(state, props.deviceType) : undefined
        }
      />,
      `${prefix} ${Strings.devicesPlural[props.deviceType]}: Total: ${stats?.total_number ||
        0}, Activated: ${stats?.activated_number || 0}`,
    )
  );
}

HubStats.widgetId = 'HubStatsWidget';

HubStats.propTypes = {
  getActivityStatistics: PropTypes.func,
  onClick: PropTypes.func,
  refreshTimestamp: PropTypes.any.isRequired,
  statsPeriod: PropTypes.string,
  deviceType: PropTypes.string,
  dragging: PropTypes.bool,
  config: PropTypes.any,
  colorPalette: PropTypes.any,
};

HubStats.defaultProps = { deviceType: 'hub', widgetType: deviceStatsWidgetTypes.graph };

const mapDispatchToProps = dispatch => ({
  getActivityStatistics: request => dispatch(actions.getActivityStatistics(request)),
  onClick: (path, params, search) => {
    dispatch(
      push({
        pathname: path,
        state: { params },
        search,
      }),
    );
  },
});

const mapStateToProps = state => {
  return {
    timezone: state.auth?.profile?.preferences?.timezone,
    colorPalette:
      ColorPalette[state.dashboardState.configuration?.allDashboards?.colorPalette] || Object.values(ColorPalette)[0],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HubStats);

export const HubStatsPeriod = {
  realTime: 'real_time',
  yesterday: 'daily',
  monthly: 'monthly',
};
