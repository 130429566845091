import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import React from 'react';
import { formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import ReactDOMServer from 'react-dom/server';

import {
  IDLE_TIME,
  PAGE_LIMIT,
  WEEKS,
  TIME_FORMAT_12_UPPERCASE,
  DATE_MONTH_DAY_YEAR_WITH_DASH,
  DATE_FORMAT_YEAR_MONTH_DAY,
} from './constants';
import Strings from './Strings';
import {
  convertCelsiusToFahrenheit,
  getUnitText,
  GlucoseUnitConverter,
  GLUCOSE_UNITS,
  roundToDecimal,
  WeightUnitConverter,
  WEIGHT_UNITS,
  TEMPERATURE_UNITS,
} from './utils/unitConverters';

const BLOOD_PRESSURE = `bp`;
const WEIGHING_MACHINE = 'wm';

const ASNEEDED = 'as_needed';
const EVERYDAY = 'daily';
const SPECIFICDAYS = 'weekly';
const INTERVAL = 'interval';

export function getPillsColor(doses) {
  const pills = parseInt(doses, 10);
  if (pills > 10) {
    return 'green';
  }
  if (pills <= 10 && pills > 5) {
    return 'orange';
  }
  if (pills <= 5) {
    return 'red';
  }
}

export function getMedsType(type) {
  switch (type) {
    case ASNEEDED:
      return 'As Needed';
    case EVERYDAY:
      return 'Every day';
    case SPECIFICDAYS:
      return 'Specific days';
    case INTERVAL:
      return 'Interval';

    default:
      return 'Every day';
  }
}

export function USPhoneNumberFormat(phoneNumberString) {
  if (phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '+' + match[1] + ' (' + match[2] + ') ' + match[3] + ' ' + match[4];
    }
    return phoneNumberString;
  }
}

export function cleanPhoneNumber(number) {
  return number.replace(/[^\d]+/g, '');
}

export function validateName(name) {
  return /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g.test(name);
}

export const pluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const returnOrCall = obj => (typeof obj === 'function' ? obj() : obj);

export function prependZeros(value) {
  const valueInt = parseInt(value, 10);
  if (Number.isNaN(valueInt)) return value;
  return valueInt < 10 ? `0${valueInt}` : valueInt;
}

export function momentHourMinutesToSeconds(mom) {
  return mom.hour() * 60 + mom.minute();
}

export function formatTimeFromSeconds(seconds) {
  let h = Math.floor(seconds / 60);
  const m = seconds - h * 60;
  let txtPmAm = Strings.patient_medications.am;
  if (h === 0) {
    h = 12;
  } else if (h === 12) {
    txtPmAm = Strings.patient_medications.pm;
  } else if (h >= 13) {
    txtPmAm = Strings.patient_medications.pm;
    h -= 12;
  }

  return `${(h < 10 ? '0' : '') + h}:${m < 10 ? '0' : ''}${m} ${txtPmAm}`;
}

export function getMobileOperatingSystem() {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export function InputWithTooltip(props) {
  return <ComponentWithTooltip componentType="input" {...props} />;
}

export function SelectWithTooltip(props) {
  return <ComponentWithTooltip componentType="select" {...props} />;
}

export function DivWithTooltip(props) {
  return <ComponentWithTooltip componentType="div" {...props} />;
}

export function getDivAlongWithTip(text) {
  if (text?.length < 19) {
    return <div className="flex-right">{text}</div>;
  }
  return (
    <DivWithTooltip className="flex-right" tt-id={text?.replace(/\s/g, '')} tt-text={text}>
      {text}
    </DivWithTooltip>
  );
}

export function ComponentWithTooltip(props) {
  const { componentType, ...p } = props;
  let id = '';

  if (!componentType) {
    throw new Error(`You MUST specify componentType for ${id}`);
  }

  let text = '';

  ['tt-id', 'id', 'name', 'placeholder'].forEach(function(e) {
    if (id) return;
    const v = props[e];
    if (!v) return;
    id = v;
  });

  if (!id) {
    console.warn(`Please specify tt-id, name or placeholder for tooltip ${componentType}`); // eslint-disable-line no-console
  }

  ['tt-text', 'placeholder'].forEach(function(e) {
    if (text) return;
    const v = props[e];
    if (!v) return;
    text = v;
  });

  if (!text) {
    console.warn(`Please specify tt-text or placeholder for tooltip ${id} ${componentType}`); // eslint-disable-line no-console
    return React.createElement(componentType, p);
  }

  const component = React.createElement(componentType, {
    ...p,
    'data-tooltip-html':
      typeof text === 'function' ? ReactDOMServer.renderToStaticMarkup(text) : typeof text === 'string' ? text : null,
    'data-tooltip-id': 'tooltip',
  });

  return component;
}

ComponentWithTooltip.propTypes = { componentType: PropTypes.any };

function signalDescriptionText(signalDescription) {
  return Strings.signalQualityEnum[signalDescription] || '-';
}
export function signalRender(signalData) {
  if (typeof signalData === 'undefined' || !signalData || !signalData.signal_overall) {
    return '-';
  }

  const value = signalData.signal_overall;
  let signalClassName = '';
  switch (value) {
    case 'Excellent':
      signalClassName = 'signal-high';
      break;
    case 'Good':
      signalClassName = 'signal-highm';
      break;
    case 'Medium':
      signalClassName = 'signal-lowm';
      break;
    case 'Weak':
      signalClassName = 'signal-low';
      break;
    case 'Not available':
      signalClassName = 'signal-unknown';
      break;
    default:
      break;
  }

  const content = (
    <div className={`signal-bars ${signalClassName}`}>
      {signalData.signal_overall === 'Not available' ? '-' : ''}
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      {signalData.problems ? <div className="signal-alert" /> : <React.Fragment />}
    </div>
  );

  const tooltip = `RSRP: ${
    signalData.rsrp
      ? `${signalData.rsrp} (${signalDescriptionText(signalData.rsrp_description)})`
      : signalDescriptionText(signalData.rsrp_description)
  }, RSRQ: ${
    signalData.rsrq
      ? `${signalData.rsrq} (${signalDescriptionText(signalData.rsrq_description)})`
      : signalDescriptionText(signalData.rsrq_description)
  }${signalData.problems ? `, ${signalData.problems} ${Strings.problems}` : ''}`;
  return toolTipForTable(content, tooltip);
}

export function getCapChargeText(value) {
  if (typeof value === 'undefined') return '-';
  const intValue = parseInt(value, 10);
  if (Number.isNaN(intValue) || intValue === -100) return '-';
  if (intValue <= 20) {
    return (
      <span className="battery_charge-2 ml-10">
        <span className="battery_charge_low-2"></span>
        <span className="battery_charge_finish-2"></span>
        <span className="battery_charge_finish-2"></span>
        <span className="battery_charge_finish-2"></span>
      </span>
    );
  }
  if (intValue > 80) {
    return (
      <span className="battery_charge-2 ml-10">
        <span className="battery_charge_high-2"></span>
        <span className="battery_charge_high-2"></span>
        <span className="battery_charge_high-2"></span>
        <span className="battery_charge_high-2"></span>
      </span>
    );
  }
  return (
    <span className="battery_charge-2 ml-10">
      <span className="battery_charge_medium-2"></span>
      <span className="battery_charge_medium-2"></span>
      <span className="battery_charge_finish-2"></span>
      <span className="battery_charge_finish-2"></span>
    </span>
  );
}

export const cityDisplay = value => {
  const location = value.split('"');
  const index = location.findIndex(e => e == 'city');
  if (index != -1) {
    return location[index + 2];
  }
  return '-';
};

export function getCapType(cap_type) {
  switch (cap_type) {
    case '38_mm':
      return 'LS002';
    case '42_mm':
      return 'LS001';
    default:
      return '-';
  }
}

export function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

export function getLocation(location) {
  if (location) {
    try {
      const loc = JSON.parse(location);
      const place = loc?.location;
      return `${place.lat.toFixed(2)},${place.lng.toFixed(2)}`;
    } catch (error) {
      return '-';
    }
  }
  return '-';
}

export function getFullLocation(location) {
  if (location) {
    try {
      const loc = JSON.parse(location);
      const place = loc?.location;
      return `${place.lat},${place.lng}`;
    } catch (error) {
      return '-';
    }
  }
  return '-';
}

export function getFullLocationObject(location) {
  if (location) {
    try {
      const loc = JSON.parse(location);
      const place = loc?.location;
      if (place) return { lat: place.lat, lng: place.lng };
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
}

export function getHubDeviceLable(deviceType) {
  switch (deviceType) {
    case BLOOD_PRESSURE:
      return Strings.bpMonitor;

    case WEIGHING_MACHINE:
      return Strings.scale;
    default:
      return Strings.ble;
  }
}

export function getWeight(weight, inUnit, outUnit = null) {
  if (!inUnit) return weight;
  try {
    const w = parseFloat(weight, 10);
    const value = roundToDecimal(WeightUnitConverter(w, inUnit, outUnit), 2);
    const unit = outUnit ? outUnit : inUnit;

    return `${value} ${getUnitText(unit)}`;
  } catch (error) {
    return '-';
  }
}

export function getHWValue(e) {
  if (e.activity_type == 'Signal strength') {
    if (e.activity_value == 'High') {
      return (
        <div className="signal-high signal-bars">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      );
    } else if (e.activity_value == 'High Medium') {
      return (
        <div className="signal-highm signal-bars">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      );
    } else if (e.activity_value == 'Low Medium') {
      return (
        <div className="signal-lowm signal-bars">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      );
    }
    return (
      <div className="signal-low signal-bars">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  } else if (e.activity_type == 'Location') {
    try {
      const activity_value = JSON.parse(e.activity_value);
      return activity_value.city;
    } catch (error) {
      return e.activity_value;
    }
  }
  return e.activity_value;
}

export function makeValid(value) {
  return value || '-';
}

export function getBatteryStatus(value) {
  if (!value) {
    return '-';
  }
  switch (value) {
    case 'Good':
      return (
        <span className="battery_charge-2">
          <span className="battery_charge_high-2"></span>
          <span className="battery_charge_high-2"></span>
          <span className="battery_charge_high-2"></span>
          <span className="battery_charge_high-2"></span>
        </span>
      );
    case 'Medium':
      return (
        <span className="battery_charge-2">
          <span className="battery_charge_medium-2"></span>
          <span className="battery_charge_medium-2"></span>
          <span className="battery_charge_finish-2"></span>
          <span className="battery_charge_finish-2"></span>
        </span>
      );
    case 'Poor':
      return (
        <span className="battery_charge-2">
          <span className="battery_charge_low-2"></span>
          <span className="battery_charge_finish-2"></span>
          <span className="battery_charge_finish-2"></span>
          <span className="battery_charge_finish-2"></span>
        </span>
      );
    default:
      return '-';
  }
}

function getWeekNumber(dd) {
  const d = new Date(+dd);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  const yearStart = new Date(d.getFullYear(), 0, 1);
  const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  return [d.getFullYear(), weekNo];
}

function weeksInYear(year) {
  const d = new Date(year, 11, 31);
  const week = getWeekNumber(d)[1];
  return week === 1 ? 52 : week;
}

export function howManyWeeksInThisMonth(selectedDate) {
  const month = moment(selectedDate).month();
  const clonedMoment = moment(selectedDate);
  let first = clonedMoment
    .startOf('month')
    .day('Monday')
    .week();
  if (month === 0) {
    first = 1;
  }
  if (month === 11) {
    first = +moment(`112${clonedMoment.year()}`, 'MMDDYYYY').format('w');

    const last = weeksInYear(clonedMoment.year());
    return last - first;
  }
  const endOfMonth = moment(selectedDate).endOf('month');
  let last = endOfMonth.isoWeek();
  if (first > last) {
    last = moment([selectedDate.year()]).week();
  }
  const weekNumber = last - first + 1;
  return weekNumber;
  // weekNumber > 5 ? 5 : weekNumber;
}

export function getWeeksArray(year, month) {
  const selelctedDate = moment([year, month, 1]).startOf('month');
  const getWeeksCount = howManyWeeksInThisMonth(selelctedDate);
  return WEEKS.slice(0, getWeeksCount);
}

export const getAdherencePercentage = adh => {
  if (adh) {
    return `${adh}%`;
  }
  return '0%';
};

export const diffDays = (day1, day2) => {
  const c = moment(day1);
  const d = moment(day2);
  return c.diff(d, 'days');
};

export const formatTimeSpanHHMM = durationMinutes => {
  return `${Math.floor(durationMinutes / 60)}h ${Math.round(durationMinutes) % 60}m`;
};

export const formatTimeSpanHHMMSS = durationSeconds => {
  const hours = Math.floor(durationSeconds / 3600);
  const minutes = Math.floor((durationSeconds % 3600) / 60);
  const seconds = Math.floor((durationSeconds % 3600) % 60);

  return `${hours ? hours : ''}${hours ? 'h' : ''} 
    ${minutes || hours ? minutes : ''}${minutes || hours ? 'm' : ''} 
    ${seconds}s
  `;
};

export const getAvgForCSS = adhe => {
  if (adhe) {
    if (adhe >= 72 && adhe !== 100) {
      return `${72}%`;
    }
    return `${adhe}%`;
  }
  return '0%';
};

export const getRemainAvg = adher => {
  if (adher) {
    if (adher >= 72 && adher !== 100) {
      return `${28}%`;
    }
    return `${100 - adher}%`;
  }
  return '0%';
};

export const getTotalAdherence = adheren => {
  const average = getAdherencePercentage(Math.round(adheren));
  return <div>{average}</div>;
};

export const convertUnixEpochToHumanReadable = unixEpoch => {
  return moment.unix(unixEpoch).format(DATE_MONTH_DAY_YEAR_WITH_DASH);
};

export const toolTipForTable = (data, dataForTooltip, place = 'bottom') => {
  let tooltip = dataForTooltip || data;

  if (dataForTooltip) {
    tooltip = tooltip.replaceAll(', ', ',<br />').replaceAll('\n', '<br />');
  }

  const divProps = {
    'data-tooltip-html': tooltip,
    'data-tooltip-id': tooltip !== '-' ? 'tooltip' : undefined,
    'data-tooltip-place': place,
    className: 'td',
  };

  return <div {...divProps}>{data}</div>;
};

export const toolTipWithValue = location => {
  if (location !== '-') {
    const lat = getLocation(location);

    const divProps = {
      'data-tooltip-content': lat !== '-' ? getFullLocation(location) : undefined,
      'data-tooltip-id': lat !== '-' ? 'tooltip' : undefined,
      className: 'td',
    };

    return <div {...divProps}>{lat}</div>;
  }
  return '-';
};

export const classNames = classes =>
  Object.keys(classes)
    .filter(k => classes[k])
    .join(' ');

export const getPropOrDefault = (obj, scope, prop, defaultValue) =>
  (obj && obj[scope] && obj[scope][prop]) || defaultValue;

export const filterTimezones = tz => {
  if (tz.indexOf('/') > 0 && tz.indexOf('Etc/')) {
    return true;
  }
  if (tz.indexOf('UTC') === 0 || tz === 'GMT') {
    return true;
  }
  return false;
};

const auth = {
  authenticated: false,
  username: '',
  token: '',
  id: null,
  is_doctor: false,
};

export const authCheck = () => {
  const authStore = { auth };
  const authentication_token = sessionStorage.getItem('authentication_token');
  if (authentication_token) {
    authStore.auth = {
      token: authentication_token,
      authenticated: true,
    };
  }

  return authStore;
};

export const setIdleTime = () => {
  const idleTime = moment()
    .add(IDLE_TIME, 'm')
    .valueOf();
  sessionStorage.setItem('interval', idleTime);
};

export const authSet = user => {
  sessionStorage.setItem('authentication_token', user.authentication_token);
  return {
    authenticated: true,
    token: user.authentication_token,
  };
};

export const authUnset = () => {
  sessionStorage.clear();
  return auth;
};

export const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

export function getOptionsYears() {
  const endYear = moment().format('YYYY');
  const data = [
    {
      value: 0,
      text: '---',
    },
  ];
  for (let i = 1900; i <= endYear; i += 1) {
    data[i] = {
      value: i,
      text: i,
    };
  }
  return data;
}

export function getOptionsMonths() {
  let currentDate = moment('1900-01-01');
  const data = [
    {
      value: 0,
      text: '---',
    },
  ];
  for (let i = 1; i <= 12; i += 1) {
    data[i] = {
      value: i,
      text: currentDate.format('MMMM'),
    };
    currentDate = currentDate.add(1, 'M');
  }

  return data;
}

export function isValidBirthDate(birthDate, birthMonth, birthYear) {
  if (!birthDate || !birthMonth || !birthYear) {
    return false;
  }
  const dateMoment = moment(`${birthYear}-${birthMonth}-${birthDate}`, 'YYYY-MM-DD');
  return dateMoment.isBefore(moment());
}

export function getOptionsDays(yearStr, monthStr) {
  let year = parseInt(yearStr, 10);
  if (year <= 0) {
    year = 1900;
  }
  let month = parseInt(monthStr, 10);
  if (month <= 0) {
    month = 1;
  }
  const date = `${year}-${month}-01 00:00:00Z`;
  const daysInMonths = moment(date, 'YYYY-M-DD HH:mm:ssZ').daysInMonth();
  const data = [
    {
      value: 0,
      text: '---',
    },
  ];

  for (let i = 1; i <= daysInMonths; i += 1) {
    data[i] = {
      value: i,
      text: i,
    };
  }
  return data;
}

export function getUserNotificationDelays() {
  const data = [
    {
      value: 0,
      text: '---',
    },
  ];

  for (let i = 1; i <= 60; i += 1) {
    data[i] = {
      value: i,
      text: `${i} minute${i > 1 ? 's' : ''}`,
    };
  }

  return data;
}

export function getTotalPagesCount(totalRecords, pageLimit = PAGE_LIMIT) {
  const add = totalRecords % pageLimit === 0 ? 0 : 1;
  const totalPages = parseInt(totalRecords / pageLimit + add, 10);
  return totalPages;
}

export function getAdherenceColored(percentage) {
  return <span className={`${percentage >= 80 ? 'high' : 'low'}`}>{percentage}%</span>;
}

export function getAdherenceColoredDiv(percentage) {
  return <div className={`${percentage >= 80 ? 'high' : 'low day'}`}>{percentage}%</div>;
}

export function getFormattedPhoneNumber(phone) {
  const formattedPhone = formatPhoneNumber(phone);
  const parsedPhone = parsePhoneNumber(phone);
  return `(+${parsedPhone.countryCallingCode}) ${formattedPhone}`;
}

export function getMedicationAdherance(d) {
  if (d.total - d.pending > 0) {
    return Math.round((d.complied / (d.total - d.pending)) * 10000) / 100;
  }
  return 0;
}

export class QueryString {
  constructor(queryString) {
    this.queryString = queryString;
    this.params = this.parse();
  }

  parse() {
    return (this.queryString[0] === '?' ? this.queryString.substr(1) : this.queryString)
      .split('&')
      .map(d => d.split('='))
      .reduce(
        (ret, [key, value = '']) => ({
          ...ret,
          [decodeURIComponent(key)]: decodeURIComponent(value),
        }),
        {},
      );
  }
}

export function getPartOfDay(hour) {
  if (hour >= 18) {
    return 'evening';
  }

  if (hour >= 12) {
    return 'day';
  }

  if (hour >= 6) {
    return 'morning';
  }

  return 'night';
}

function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function downloadFiles(req, fileName) {
  const blob = b64toBlob(req.response.data, 'application/zip');
  const link = document.createElement('a');
  if (navigator.msSaveBlob) {
    return navigator.msSaveBlob(blob, fileName);
  }
  document.body.appendChild(link);
  link.href = (window.URL ? URL : window.webkitURL).createObjectURL(blob);
  link.download = `${fileName}.zip`;
  link.click();
  return true;
}

export function downloadFileOnly(req, fileName) {
  const blob = new Blob([req.response], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (navigator.msSaveBlob) {
    return navigator.msSaveBlob(blob, fileName);
  }
  document.body.appendChild(link);
  link.href = (window.URL ? URL : window.webkitURL).createObjectURL(blob);
  link.download = `${fileName}.csv`;
  link.click();
  return true;
}

export function downloadFromURL(url) {
  const a = document.createElement('a');
  a.href = url;
  a.download = url.split('/').pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function getActiveWeekNumber() {
  const weeks = [1, 2, 3, 4, 5, 6];
  for (let i = 0; i < weeks.length; i += 1) {
    if (
      moment() <
      moment(moment().startOf('month'))
        .add(i, 'weeks')
        .endOf('isoWeek')
    ) {
      return i + 1;
    }
  }
  return 7;
}

export function enumerateDatesBetweenDates(bothdate, weekNumber) {
  const startDate = bothdate[0];
  const endDate = bothdate[1];
  const dates = [];

  const currDate = moment(startDate);
  const lastDate = moment(endDate);

  if (weekNumber === 1 && currDate.clone().format('D') > 7) {
    dates.push('');
  } else {
    dates.push(currDate.clone());
  }

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    if (weekNumber > 3 && currDate.clone().format('D') < 7) {
      dates.push('');
    } else if (weekNumber === 1 && currDate.clone().format('D') > 7) {
      dates.push('');
    } else {
      dates.push(currDate.clone());
    }
  }

  return dates;
}

export function enumerateDaysBetweenDates(bothdate, weekNumber) {
  const startDate = bothdate[0];
  const endDate = bothdate[1];
  const dates = [];

  const currDate = moment(startDate);
  const lastDate = moment(endDate);

  if (weekNumber === 1 && currDate.clone().format('D') > 7) {
    dates.push('');
  } else {
    dates.push(currDate.clone().format('D'));
  }

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    if (weekNumber > 3 && currDate.clone().format('D') < 7) {
      dates.push('');
    } else if (weekNumber === 1 && currDate.clone().format('D') > 7) {
      dates.push('');
    } else {
      dates.push(currDate.clone().format('D'));
    }
  }

  return dates;
}

export function getWeeksStartAndEndDateForAPI(weekNumber, selectedYear, selectedMonth) {
  if (weekNumber === 1) {
    return [
      moment([selectedYear, selectedMonth])
        .startOf('month')
        .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      moment(moment([selectedYear, selectedMonth]).startOf('month'))
        .add(weekNumber - 1, 'weeks')
        .endOf('isoWeek')
        .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    ];
  }

  const weeksArray = getWeeksArray(selectedYear, selectedMonth);

  if (
    weekNumber >= weeksArray.length &&
    +moment(moment([selectedYear, selectedMonth]).startOf('month'))
      .add(weekNumber - 1, 'weeks')
      .endOf('isoWeek')
      .format('D') > 0
  ) {
    return [
      moment(moment([selectedYear, selectedMonth]).startOf('month'))
        .add(weekNumber - 1, 'weeks')
        .startOf('isoWeek')
        .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      moment(moment([selectedYear, selectedMonth]))
        .endOf('month')
        .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    ];
  }

  return [
    moment(moment([selectedYear, selectedMonth]).startOf('month'))
      .add(weekNumber - 1, 'weeks')
      .startOf('isoWeek')
      .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    moment(moment([selectedYear, selectedMonth]).startOf('month'))
      .add(weekNumber - 1, 'weeks')
      .endOf('isoWeek')
      .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
  ];
}

export function getWeeksStartAndEndDate(weekNumber, selectedYear, selectedMonth) {
  return [
    moment(moment([selectedYear, selectedMonth]).startOf('month'))
      .add(weekNumber - 1, 'weeks')
      .startOf('isoWeek')
      .utc()
      .toDate(),
    moment(moment([selectedYear, selectedMonth]).startOf('month'))
      .add(weekNumber - 1, 'weeks')
      .endOf('isoWeek')
      .utc()
      .toDate(),
  ];
}

export function downloadRxEngageFiles(req, fileName) {
  const link = document.createElement('a');
  if (navigator.msSaveBlob) {
    return navigator.msSaveBlob(req.response, fileName);
  }
  document.body.appendChild(link);
  link.href = (window.URL ? URL : window.webkitURL).createObjectURL(req.response);
  link.download = fileName;
  link.click();
  return true;
}

export function getASCIIValueOfChar(character) {
  return character.charCodeAt(0);
}

export function isActivePeriod(week, selectedWeek, selectedYear, selectedMonth) {
  if (moment().year() <= selectedYear && moment().month() >= selectedMonth) {
    if (moment().year() < selectedYear) {
      return 'disabled ';
    }
    if (moment().year() === selectedYear && moment().month() < selectedMonth) {
      return 'disabled ';
    }
  }
  if (moment().year() === selectedYear && moment().month() <= selectedMonth) {
    if (moment().year() === selectedYear && moment().month() < selectedMonth) {
      return 'disabled ';
    }
    return week <= getActiveWeekNumber() ? 'active-period ' : 'disabled ';
  }
  return 'active-period ';
}

export function emptyPieChartSVG() {
  return (
    <div className="Chart">
      <svg className="plot" viewBox="-122.5 -122.5 245 245">
        <g>
          <text className="doughnutCenterText " textAnchor="middle" alignmentBaseline="middle"></text>
          <path
            className="new-gray"
            d="M7.133567605033332e-15,-116.5A116.5,116.5,0,1,1,-7.133567605033332e-15,116.5A116.5,116.5,0,1,1,7.133567605033332e-15,-116.5M-1.8828944536890554e-14,-102.5A102.5,102.5,0,1,0,1.8828944536890554e-14,102.5A102.5,102.5,0,1,0,-1.8828944536890554e-14,-102.5Z"
          ></path>
        </g>
      </svg>
      <div className="spinner" />
    </div>
  );
}

export function emptyPieChartSVGWithOutSpinner() {
  return (
    <div className="Chart">
      <svg className="plot" viewBox="-122.5 -122.5 245 245">
        <g>
          <text className="doughnutCenterText " textAnchor="middle" alignmentBaseline="middle"></text>
          <path
            className="new-gray"
            d="M7.133567605033332e-15,-116.5A116.5,116.5,0,1,1,-7.133567605033332e-15,116.5A116.5,116.5,0,1,1,7.133567605033332e-15,-116.5M-1.8828944536890554e-14,-102.5A102.5,102.5,0,1,0,1.8828944536890554e-14,102.5A102.5,102.5,0,1,0,-1.8828944536890554e-14,-102.5Z"
          ></path>
        </g>
      </svg>
    </div>
  );
}

export function emptyPieChartSVGNoData() {
  return (
    <div className="Chart">
      <div className="no-data-image-container">
        <div className="no-data-image">{Strings.noData}</div>
      </div>
      <svg className="plot" viewBox="-122.5 -122.5 245 245">
        <g>
          <text className="doughnutCenterText " textAnchor="middle" alignmentBaseline="middle"></text>
          <path
            className="new-gray"
            d="M7.133567605033332e-15,-116.5A116.5,116.5,0,1,1,-7.133567605033332e-15,116.5A116.5,116.5,0,1,1,7.133567605033332e-15,-116.5M-1.8828944536890554e-14,-102.5A102.5,102.5,0,1,0,1.8828944536890554e-14,102.5A102.5,102.5,0,1,0,-1.8828944536890554e-14,-102.5Z"
          ></path>
        </g>
      </svg>
    </div>
  );
}

export function emptyLast7DaysAdherence() {
  return (
    <div className=" box">
      <header>
        <div>Adherence Last 7 Days</div>
      </header>
      <div className="Chart">
        <div className="no-data-image-container">
          <div className="no-data-image">{Strings.noData}</div>
        </div>
        <svg className="plot adherence-7days" viewBox="0 0 260 235">
          <g className="main" transform="translate(37, 7.5)">
            <g
              className="axis bottom no-domain"
              transform="translate(0, 211)"
              fill="none"
              fontSize="10"
              fontFamily="sans-serif"
              textAnchor="middle"
            >
              <path className="domain" stroke="currentColor" d="M0.5,0.5H228.5"></path>
              <g className="tick" opacity="1" transform="translate(21.5,0)">
                <line stroke="currentColor" y2="0"></line>
                <text fill="currentColor" y="3" dy="0.71em" className="Th">
                  Th
                </text>
              </g>
              <g className="tick" opacity="1" transform="translate(52.5,0)">
                <line stroke="currentColor" y2="0"></line>
                <text fill="currentColor" y="3" dy="0.71em" className="F">
                  F
                </text>
              </g>
              <g className="tick" opacity="1" transform="translate(83.5,0)">
                <line stroke="currentColor" y2="0"></line>
                <text fill="currentColor" y="3" dy="0.71em" className="S">
                  S
                </text>
              </g>
              <g className="tick" opacity="1" transform="translate(114.5,0)">
                <line stroke="currentColor" y2="0"></line>
                <text fill="currentColor" y="3" dy="0.71em" className="Su">
                  Su
                </text>
              </g>
              <g className="tick" opacity="1" transform="translate(145.5,0)">
                <line stroke="currentColor" y2="0"></line>
                <text fill="currentColor" y="3" dy="0.71em" className="M">
                  M
                </text>
              </g>
              <g className="tick" opacity="1" transform="translate(176.5,0)">
                <line stroke="currentColor" y2="0"></line>
                <text fill="currentColor" y="3" dy="0.71em" className="T">
                  T
                </text>
              </g>
              <g className="tick" opacity="1" transform="translate(207.5,0)">
                <line stroke="currentColor" y2="0"></line>
                <text fill="currentColor" y="3" dy="0.71em" className="W">
                  W
                </text>
              </g>
            </g>
            <g className="axis left no-domain" fill="none" fontSize="10" fontFamily="sans-serif" textAnchor="end">
              <path className="domain" stroke="currentColor" d="M-6,203.5H0.5V0.5H-6"></path>
              <g className="tick" opacity="1" transform="translate(0,203.5)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">
                  0%
                </text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,162.5)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">
                  20%
                </text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,122.5)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">
                  40%
                </text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,81.5)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">
                  60%
                </text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,41.5)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">
                  80%
                </text>
              </g>
              <g className="tick" opacity="1" transform="translate(0,0.5)">
                <line stroke="currentColor" x2="-6"></line>
                <text fill="currentColor" x="-9" dy="0.32em">
                  100%
                </text>
              </g>
            </g>
            <g className="complied series">
              <rect x="11" y="203" height="0" width="20"></rect>
              <rect x="42" y="203" height="0" width="20"></rect>
              <rect x="73" y="203" height="0" width="20"></rect>
              <rect x="104" y="203" height="0" width="20"></rect>
              <rect x="135" y="203" height="0" width="20"></rect>
              <rect x="166" y="203" height="0" width="20"></rect>
              <rect x="197" y="203" height="0" width="20"></rect>
            </g>
            <g className="skipped series">
              <rect x="11" y="203" height="0" width="20"></rect>
              <rect x="42" y="203" height="0" width="20"></rect>
              <rect x="73" y="203" height="0" width="20"></rect>
              <rect x="104" y="203" height="0" width="20"></rect>
              <rect x="135" y="203" height="0" width="20"></rect>
              <rect x="166" y="203" height="0" width="20"></rect>
              <rect x="197" y="203" height="0" width="20"></rect>
            </g>
            <g className="missed series">
              <rect x="11" y="203" height="0" width="20"></rect>
              <rect x="42" y="203" height="0" width="20"></rect>
              <rect x="73" y="203" height="0" width="20"></rect>
              <rect x="104" y="203" height="0" width="20"></rect>
              <rect x="135" y="203" height="0" width="20"></rect>
              <rect x="166" y="203" height="0" width="20"></rect>
              <rect x="197" y="203" height="0" width="20"></rect>
            </g>
            <g className="pending series">
              <rect x="11" y="203" height="0" width="20"></rect>
              <rect x="42" y="203" height="0" width="20"></rect>
              <rect x="73" y="203" height="0" width="20"></rect>
              <rect x="104" y="203" height="0" width="20"></rect>
              <rect x="135" y="203" height="0" width="20"></rect>
              <rect x="166" y="203" height="0" width="20"></rect>
              <rect x="197" y="203" height="0" width="20"></rect>
            </g>
            <g className="empty series">
              <rect></rect>
              <rect></rect>
              <rect></rect>
              <rect></rect>
              <rect></rect>
              <rect></rect>
              <rect></rect>
            </g>
            <g className="empty series">
              <rect></rect>
              <rect></rect>
              <rect></rect>
              <rect></rect>
              <rect></rect>
              <rect></rect>
              <rect></rect>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
}

export const weekDays = {
  Monday: {
    name: 'Monday',
    shortName: 'M',
    mask: 0b00000010,
    schedules: [],
    first: true,
  },
  Tuesday: {
    name: 'Tuesday',
    shortName: 'Tu',
    mask: 0b00000100,
    schedules: [],
  },
  Wednesday: {
    name: 'Wednesday',
    shortName: 'W',
    mask: 0b00001000,
    schedules: [],
  },
  Thursday: {
    name: 'Thursday',
    shortName: 'Th',
    mask: 0b00010000,
    schedules: [],
  },
  Friday: {
    name: 'Friday',
    shortName: 'F',
    mask: 0b00100000,
    schedules: [],
  },
  Saturday: {
    name: 'Saturday',
    shortName: 'Sa',
    mask: 0b01000000,
    schedules: [],
  },
  Sunday: {
    name: 'Sunday',
    shortName: 'Su',
    mask: 0b00000001,
    schedules: [],
    last: true,
  },
};

export function areObjectsEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function areArraysEqual(array1, array2) {
  if (!Array.isArray(array1) || !Array.isArray(array2)) return false;

  return (
    array1.length === array2.length &&
    array1.every((value, index) => {
      const v1 = value;
      const v2 = array2[index];

      if (Array.isArray(v1) || Array.isArray(v2)) {
        return areArraysEqual(v1, v2);
      }
      return areObjectsEqual(v1, v2);
    })
  );
}

export function dateToFromNowDaily(myDate) {
  if (moment().diff(moment.unix(myDate), 'days') >= 1) {
    return moment.unix(myDate).fromNow();
  }
  return moment
    .unix(myDate)
    .calendar()
    .split(' ')[0];
}

export function periodValue(event) {
  switch (event) {
    case 'last2Months':
      return 'Last 2 Months';
    case 'lastMonth':
      return 'Last Month';
    case 'lastWeek':
      return 'Last Week';
    default:
      return '';
  }
}

export function getMobileOS() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

export function getBatterStatus(value) {
  let text = '';
  switch (value) {
    case 'High':
      text = 'High';
      break;
    case 'MedHigh':
      text = 'Medium';
      break;
    case 'MedLow':
      text = 'Medium';
      break;
    case 'Poor':
      text = 'Poor';
      break;
    default:
      text = '';
      break;
  }
  return text;
}

export function checkLength(text, appendText) {
  if (text) {
    return `${text}, ${appendText}`;
  }
  return appendText;
}

export function ucFirst(str) {
  var firstLetter = str.slice(0, 1);
  return firstLetter.toUpperCase() + str.substring(1);
}

export function hailieEventSubTypeDisplay(eventSubType) {
  switch (eventSubType) {
    case 'Medication':
      return Strings.hailieEventSubTypes.inhalation;
    case 'RespirationFlow':
      return Strings.hailieEventSubTypes.respirationFlow;
    case 'RespirationTiming':
      return Strings.hailieEventSubTypes.respirationTiming;
    case 'ShakeDetect':
      return Strings.hailieEventSubTypes.shakeDetection;
    default:
      return '-';
  }
}

export function lastActivityDisplay(e) {
  if (!e) {
    return '-';
  }
  const date = moment(isNaN(e) ? e : e * 1000);
  const today = moment();
  if (date.format(DATE_FORMAT_YEAR_MONTH_DAY) === today.format(DATE_FORMAT_YEAR_MONTH_DAY)) {
    return date.format(TIME_FORMAT_12_UPPERCASE);
  }
  return date.format(DATE_MONTH_DAY_YEAR_WITH_DASH);
}

export function getVitalsData(data) {
  let ret = [];
  if (data) {
    if (data.Weight) {
      ret.push(getWeight(data?.Weight.Value, WEIGHT_UNITS.kg, WEIGHT_UNITS.lbs));
    }

    if (data.HeartRate) {
      ret.push(`${Strings.heartRate}: ${roundToDecimal(data.HeartRate.Value, 0)} bpm`);
    }

    if (data.PressureDiastolic) {
      ret.push(`${Strings.diastolicPressure}: ${data.PressureDiastolic.Value} mmHg`);
    }

    if (data.PressureSystolic) {
      ret.push(`${Strings.systolicPressure}: ${data.PressureSystolic.Value} mmHg`);
    }

    if (data.SugarLevel) {
      ret.push(`${Strings.glucose}: ${roundToDecimal(data.SugarLevel.Value, 2)} ${GLUCOSE_UNITS.mg_dl}`);
    }

    if (data.ForcedExpiratoryVolume1s) {
      ret.push(`FVC1: ${data.ForcedExpiratoryVolume1s.Value} (L)`);
    }

    if (data.PeakExpiratoryFlow) {
      ret.push(`PEF: ${data.PeakExpiratoryFlow.Value} (L/s)`);
    }

    if (data.Sleep) {
      ret.push(`${Strings.sleep}: ${data.Sleep.Value} sec`);
    }

    if (data.Steps) {
      ret.push(`${Strings.steps}: ${data.Steps.Value}`);
    }

    if (data.PillsTaken) {
      ret.push(Strings.pillsTaken);
    }

    if (data.Temperature) {
      ret.push(`${Strings.temperature}: ${convertCelsiusToFahrenheit(data.Temperature.Value)} F`);
    }

    if (data.OxygenSaturation) {
      ret.push(`${Strings.oxygenSaturation}: ${data.OxygenSaturation.Value}%`);
    }

    if (data['PM2.5']) {
      ret.push(`PM2.5: ${data['PM2.5'].Value} µg/m³`);
    }

    if (data.InhalationTaken) {
      ret.push(Strings.inhalationTaken);
    }

    if (data.ProthrombinTime) {
      ret.push(`PT: ${data.ProthrombinTime.Value} s`);
    }

    if (data.InternationalNormalizedRatio) {
      ret.push(`INR: ${data.InternationalNormalizedRatio.Value}`);
    }
  }
  return ret.join(' | ');
}

export function getVitalsDate(data) {
  if (data) {
    const firstValue = Object.values(data)[0];
    if (firstValue) {
      return firstValue.Timestamp;
    }
  }
  return null;
}

export function getVitalsStatus(data) {
  if (Object.values(data).some(d => d.Alarming)) {
    return <div className="warning">{Strings.alert}</div>;
  }
  return Strings.normal;
}

export function getTzOffset(date, timezone) {
  if (timezone) {
    return (
      moment(date)
        .tz(timezone)
        .utcOffset() *
      60 *
      1000
    );
  }
  return moment(date).utcOffset() * 60 * 1000;
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function arrayAverage(array) {
  return array.reduce((a, b) => a + b, 0) / array.length;
}

export function getActiveTabNameFromUrl(url, Tabs) {
  const tab = Object.values(Tabs).find(tab => tab.urlId === url);
  return tab ? tab.DisplayName : '';
}
export const calculateInitials = name => {
  return name
    .match(/(\b\S)?/g)
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toUpperCase();
};

export const getVitals = vitals => {
  return (
    <div>
      {vitals &&
        vitals.map((v, i) =>
          v.conditions.map((c, k) =>
            c.diff ? (
              <p key={`${i}${k}`}>{getVitalsText(c.field, c.diff, c.unit, c.actual_value)}</p>
            ) : c.field === 'missed_doses' ? (
              <p key={`${i}${k}`}>{`${Strings.missedDoses}: ${v.medication_name}`}</p>
            ) : (
              <></>
            ),
          ),
        )}
    </div>
  );
};

export const ReadingsEnum = Object.freeze({
  billableTime: 'billableTime',
  bloodPressure: 'bloodPressure',
  bloodPressure7avg: 'avgBloodPressure',
  pulseox: 'oxygenSaturation',
  weight: 'weight',
  glucose: 'glucose',
  spirometer: 'spirometry',
  temperature: 'temperature',
  steps: 'steps',
  sleep: 'sleep',
  activity: 'activity',
  heartRate: 'heartRate',
  pm25: 'pm25',
  coagulation: 'coagulation',
});

export const getReadingsData = (reading, event, returnEmptyObjectIfNoReading = false) => {
  let vitals = null;
  let alerts = null;
  if (event) {
    vitals = event.vitals;
    alerts = event.alerts;
  }
  switch (reading) {
    case ReadingsEnum.temperature:
      if (isNaN(vitals?.Temperature?.Value) && !returnEmptyObjectIfNoReading) {
        return null;
      }
      return {
        title: Strings.temperature,
        value: !isNaN(vitals?.Temperature?.Value) ? convertCelsiusToFahrenheit(vitals?.Temperature?.Value) : '-',
        unit: TEMPERATURE_UNITS.f,
        valueWithUnit: !isNaN(vitals?.Temperature?.Value)
          ? `${convertCelsiusToFahrenheit(vitals?.Temperature?.Value)}${TEMPERATURE_UNITS.f}`
          : '-',
        date: vitals?.Temperature?.Timestamp ? moment(vitals?.Temperature?.Timestamp) : undefined,
        alert: alerts.temperature_alert_id !== null,
        icon: 'temperature',
      };
    case ReadingsEnum.billableTime:
      if (isNaN(event.billable_time) && !returnEmptyObjectIfNoReading) {
        return null;
      }
      return {
        title: Strings.time,
        value: !isNaN(event.billable_time) ? Math.round(event.billable_time / 60 + Number.EPSILON) : '-',
        unit: ` ${Strings.unitsEnum.minutes}`,
        valueWithUnit: !isNaN(event.billable_time)
          ? `${Math.round(event.billable_time / 60 + Number.EPSILON)} ${Strings.unitsEnum.minutes}`
          : '-',
        icon: 'billable_time',
      };
    case ReadingsEnum.bloodPressure:
      if (
        (isNaN(vitals?.PressureSystolic?.Value) || isNaN(vitals?.PressureDiastolic?.Value)) &&
        !returnEmptyObjectIfNoReading
      ) {
        return null;
      }
      return {
        title: Strings.bloodPressure,
        value:
          !isNaN(vitals?.PressureSystolic?.Value) && !isNaN(vitals?.PressureDiastolic?.Value)
            ? `${vitals?.PressureSystolic?.Value} / ${vitals?.PressureDiastolic?.Value}`
            : '- / -',
        valueWithUnit:
          !isNaN(vitals?.PressureSystolic?.Value) && !isNaN(vitals?.PressureDiastolic?.Value)
            ? `${vitals?.PressureSystolic?.Value} ${Strings.bpm_unit} / ${vitals?.PressureDiastolic?.Value} ${Strings.bpm_unit}`
            : '- / -',
        date: vitals?.PressureSystolic?.Timestamp
          ? moment(vitals?.PressureSystolic?.Timestamp)
          : vitals?.PressureDiastolic?.Timestamp
          ? moment(vitals?.PressureDiastolic?.Timestamp)
          : undefined,
        alert: alerts?.blood_pressure_alert_id !== null,
        unit: ` ${Strings.bpm_unit}`,
        icon: 'blood_pressure',
      };
    case ReadingsEnum.bloodPressure7avg:
      if (
        (isNaN(event?.blood_pressure?.systolic_pressure) || isNaN(event?.blood_pressure?.diastolic_pressure)) &&
        !returnEmptyObjectIfNoReading
      ) {
        return null;
      }
      return {
        title: Strings.avgBloodPressure,
        value:
          !isNaN(event?.blood_pressure?.systolic_pressure) && !isNaN(event?.blood_pressure?.diastolic_pressure)
            ? `${event?.blood_pressure?.systolic_pressure} / ${event?.blood_pressure?.diastolic_pressure}`
            : '- / -',
        valueWithUnit:
          !isNaN(event?.blood_pressure?.systolic_pressure) && !isNaN(event?.blood_pressure?.diastolic_pressure)
            ? `${event?.blood_pressure?.systolic_pressure} ${Strings.bpm_unit} / ${event?.blood_pressure?.diastolic_pressure} ${Strings.bpm_unit}`
            : '- / -',
        tooltip: `From 7 ${Strings.latestReadings}`,
        unit: ` ${Strings.bpm_unit}`,
        icon: 'blood_pressure',
      };
    case ReadingsEnum.weight:
      if (isNaN(vitals?.Weight?.Value) && !returnEmptyObjectIfNoReading) {
        return null;
      }
      return {
        title: Strings.weight,
        value: !isNaN(vitals?.Weight?.Value)
          ? roundToDecimal(WeightUnitConverter(vitals?.Weight?.Value, WEIGHT_UNITS.metric, WEIGHT_UNITS.lbs), 2)
          : '-',
        unit: ` ${Strings.unitsEnum.lbs}`,
        valueWithUnit: !isNaN(vitals?.Weight?.Value)
          ? `${roundToDecimal(WeightUnitConverter(vitals?.Weight?.Value, WEIGHT_UNITS.metric, WEIGHT_UNITS.lbs), 2)} ${
              Strings.unitsEnum.lbs
            }`
          : '-',
        date: vitals?.Weight?.Timestamp ? moment(vitals?.Weight?.Timestamp) : undefined,
        alert: alerts.weight_alert_id !== null,
        icon: 'weight',
      };
    case ReadingsEnum.pulseox:
      if (isNaN(vitals?.OxygenSaturation?.Value) && !returnEmptyObjectIfNoReading) {
        return null;
      }
      return {
        title: Strings.oxygenSaturation,
        value: !isNaN(vitals?.OxygenSaturation?.Value) ? vitals?.OxygenSaturation?.Value : '-',
        unit: '%',
        valueWithUnit: !isNaN(vitals?.OxygenSaturation?.Value) ? `${vitals?.OxygenSaturation?.Value}%` : '-',
        date: vitals?.OxygenSaturation?.Timestamp ? moment(vitals?.OxygenSaturation?.Timestamp) : undefined,
        alert: alerts.saturation_alert_id !== null,
        icon: 'pulseoximetry',
      };
    case ReadingsEnum.glucose:
      if (isNaN(vitals?.SugarLevel?.Value) && !returnEmptyObjectIfNoReading) {
        return null;
      }
      return {
        title: Strings.glucose,
        value: !isNaN(vitals?.SugarLevel?.Value) ? roundToDecimal(vitals?.SugarLevel?.Value, 2) : '-',
        unit: ` ${GLUCOSE_UNITS.mg_dl}`,
        valueWithUnit: !isNaN(vitals?.SugarLevel?.Value)
          ? `${roundToDecimal(vitals?.SugarLevel?.Value, 2)} ${GLUCOSE_UNITS.mg_dl}`
          : '-',
        date: vitals?.SugarLevel?.Timestamp ? moment(vitals?.SugarLevel?.Timestamp) : undefined,
        alert: alerts.glucose_alert_id !== null,
        icon: 'glucose',
      };
    case ReadingsEnum.spirometer:
      if (
        (isNaN(vitals?.ForcedVitalCapacity?.Value) || isNaN(vitals?.ForcedExpiratoryVolume1s?.Value)) &&
        !returnEmptyObjectIfNoReading
      ) {
        return null;
      }
      return {
        title: Strings.spirometry,
        value:
          !isNaN(vitals?.ForcedVitalCapacity?.Value) && !isNaN(vitals?.ForcedExpiratoryVolume1s?.Value)
            ? `${vitals?.ForcedVitalCapacity?.Value} | ${vitals?.ForcedExpiratoryVolume1s?.Value}`
            : '- | -',
        unit: ` FVC | FEV1`,
        valueWithUnit:
          !isNaN(vitals?.ForcedVitalCapacity?.Value) && !isNaN(vitals?.ForcedExpiratoryVolume1s?.Value)
            ? `${vitals?.ForcedVitalCapacity?.Value} FVC | ${vitals?.ForcedExpiratoryVolume1s?.Value} FEV1`
            : '- | -',
        date: vitals?.ForcedVitalCapacity?.Timestamp ? moment(vitals?.ForcedExpiratoryVolume1s?.Timestamp) : undefined,
        alert: alerts.spirometer_alert_id !== null,
        icon: 'spirometry',
      };
    case ReadingsEnum.steps:
      if (isNaN(vitals?.Steps?.Value) && !returnEmptyObjectIfNoReading) {
        return null;
      }
      const v = !isNaN(vitals?.Steps?.Value) ? vitals?.Steps?.Value : '-';
      return {
        title: Strings.steps,
        value: v,
        valueWithUnit: v,
        date: vitals?.Steps?.Timestamp ? moment(vitals?.Steps?.Timestamp) : undefined,
        icon: 'steps',
      };
    case ReadingsEnum.sleep:
      if (isNaN(vitals?.Sleep?.Value) && !returnEmptyObjectIfNoReading) {
        return null;
      }
      return {
        title: Strings.sleep,
        value: !isNaN(vitals?.Sleep?.Value) ? roundToDecimal(vitals?.Sleep?.Value / 60, 2) : '-',
        unit: ` ${Strings.unitsEnum.minutes}`,
        valueWithUnit: !isNaN(vitals?.Sleep?.Value)
          ? `${roundToDecimal(vitals?.Sleep?.Value / 60, 2)} ${Strings.unitsEnum.minutes}`
          : '-',
        date: vitals?.Sleep?.Timestamp ? moment(vitals?.Sleep?.Timestamp) : undefined,
        icon: 'sleep',
      };
    case ReadingsEnum.activity:
      if (
        (isNaN(vitals?.BurnCalories?.Value) || isNaN(vitals?.ExerciseDuration?.Value)) &&
        !returnEmptyObjectIfNoReading
      ) {
        return null;
      }
      return {
        title: Strings.activity,
        value:
          !isNaN(vitals?.BurnCalories?.Value) && !isNaN(vitals?.ExerciseDuration?.Value)
            ? `${vitals?.BurnCalories?.Value} | ${vitals?.ExerciseDuration?.Value}`
            : '- | -',
        unit: ` kcal | ${Strings.unitsEnum.minutes}`,
        valueWithUnit:
          !isNaN(vitals?.BurnCalories?.Value) && !isNaN(vitals?.ExerciseDuration?.Value)
            ? `${vitals?.BurnCalories?.Value} kcal | ${vitals?.ExerciseDuration?.Value} ${Strings.unitsEnum.minutes}`
            : '- | -',
        date: vitals?.BurnCalories?.Timestamp
          ? moment(vitals?.BurnCalories?.Timestamp)
          : vitals?.ExerciseDuration?.Timestamp
          ? moment(vitals?.ExerciseDuration?.Timestamp)
          : undefined,
        icon: 'burned_calories',
      };
    case ReadingsEnum.heartRate:
      if (isNaN(vitals?.HeartRate?.Value) && !returnEmptyObjectIfNoReading) {
        return null;
      }
      return {
        title: Strings.heartRate,
        value: !isNaN(vitals?.HeartRate?.Value) ? vitals?.HeartRate?.Value : '-',
        unit: ` ${Strings.bpm_unit}`,
        valueWithUnit: !isNaN(vitals?.HeartRate?.Value) ? `${vitals?.HeartRate?.Value} ${Strings.bpm_unit}` : '-',
        date: vitals?.HeartRate?.Timestamp ? moment(vitals?.HeartRate?.Timestamp) : undefined,
        alert: alerts.heart_rate_alert_id !== null,
        icon: 'heart_rate',
      };
    case ReadingsEnum.pm25:
      if (isNaN(vitals['PM2.5']?.Value) && !returnEmptyObjectIfNoReading) {
        return null;
      }
      return {
        title: Strings.pm25,
        value: !isNaN(vitals['PM2.5']?.Value) ? vitals['PM2.5']?.Value : '-',
        unit: ' µg/m³',
        valueWithUnit: !isNaN(vitals['PM2.5']?.Value) ? `${vitals['PM2.5']?.Value} µg/m³` : '-',
        date: vitals['PM2.5']?.Timestamp ? moment(vitals['PM2.5']?.Timestamp) : undefined,
        alert: alerts.air_quality_alert_id !== null,
        icon: 'air_quality',
      };
    case ReadingsEnum.coagulation:
      if (
        (isNaN(vitals.ProthrombinTime?.Value) || isNaN(vitals.InternationalNormalizedRatio?.Value)) &&
        !returnEmptyObjectIfNoReading
      ) {
        return null;
      }
      return {
        title: 'PT | INR',
        value:
          !isNaN(vitals.ProthrombinTime?.Value) && !isNaN(vitals.InternationalNormalizedRatio?.Value)
            ? `${vitals.ProthrombinTime?.Value} | ${vitals.InternationalNormalizedRatio?.Value}`
            : '- | -',
        unit: ' s | - ',
        valueWithUnit:
          !isNaN(vitals.ProthrombinTime?.Value) && !isNaN(vitals.InternationalNormalizedRatio?.Value)
            ? `${vitals.ProthrombinTime?.Value}s | ${vitals.InternationalNormalizedRatio?.Value}`
            : '- | -',
        date: vitals.ProthrombinTime?.Timestamp
          ? moment(vitals.ProthrombinTime?.Timestamp)
          : vitals.InternationalNormalizedRatio?.Timestamp
          ? moment(vitals.InternationalNormalizedRatio?.Timestamp)
          : undefined,
        alert: alerts.coagulation_alert_id !== null,
        icon: 'coagulation',
      };
    default:
      return null;
  }
};

export const getVitalsIcons = vitals => {
  const vitalsPresentation = [];
  Object.values(ReadingsEnum)
    .filter(r => r !== ReadingsEnum.billableTime && r !== ReadingsEnum.bloodPressure7avg)
    .forEach(reading => {
      const data = getReadingsData(reading, vitals);
      if (data) {
        vitalsPresentation.push(data);
      }
    });

  if (vitalsPresentation.length === 0) {
    vitalsPresentation.push({
      icon: 'none',
      value: Strings.na,
      valueWithUnit: Strings.na,
      alert: false,
    });
  }

  return (
    <div className="alert-container">
      {vitalsPresentation?.length > 0 &&
        vitalsPresentation.map(v => (
          <div
            key={`vital-${v.icon}`}
            className="icon-and-text-vertical"
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div>
                {v.title}
                <br /> {v.valueWithUnit}
                <br /> {v.date?.format(DATE_MONTH_DAY_YEAR_WITH_DASH)}
              </div>,
            )}
            data-tooltip-id={v.value !== Strings.na ? 'tooltip' : undefined}
          >
            <div className={`device-icon-container ${v.alert ? 'alerted' : ''}`}>
              <div className={`device-icon ${v.icon} ${v.alert ? 'alerted' : ''}`} />
            </div>
            <div className={`measurement ${v.alert ? 'alerted' : ''} ${v.value === Strings.na ? 'grey' : ''}`}>
              {v.value}
            </div>
          </div>
        ))}
    </div>
  );
};

export const getSchedulesIcons = (data, hasMedAlerts) => {
  const dataPresentation = [];
  data?.scheduleMapping?.forEach(schedule => {
    const missed = data.events?.filter(e => e.scheduleId === schedule.scheduleId && e.status === 'missed')?.length || 0;
    const taken = data.events?.filter(e => e.scheduleId === schedule.scheduleId && e.status === 'taken')?.length || 0;

    dataPresentation.push({
      type: schedule.deviceType === 'Hailie Inhaler' ? 'inhaler' : 'schedule',
      id: schedule.scheduleId,
      name: schedule.name,
      alert: hasMedAlerts,
      missed,
      taken,
      na: missed === 0 && taken === 0,
      text: missed > 0 ? `${Strings.miss} (${missed})` : taken > 0 ? Strings.taken : Strings.na,
    });
  });
  if (dataPresentation?.length === 0) {
    dataPresentation.push({
      type: 'none',
      na: true,
      text: Strings.na,
    });
  }

  return (
    <div className="alert-container">
      {dataPresentation?.length > 0 &&
        dataPresentation.map(v => (
          <React.Fragment>
            <div
              key={`schedule-${v.id}`}
              className="icon-and-text-vertical"
              data-tooltip-content={v.type !== 'none' ? v.name : undefined}
              data-tooltip-id={v.type !== 'none' ? 'tooltip' : undefined}
            >
              <div className={`device-icon-container ${v.alert ? 'alerted' : v.missed > 0 ? 'missed' : ''}`}>
                <div className={`device-icon ${v.type} ${v.alert ? 'alerted' : v.missed > 0 ? 'missed' : ''}`} />
              </div>
              <div
                className={`measurement ${v.alert ? 'alerted' : v.missed > 0 ? 'missed' : ''} ${v.na ? 'grey' : ''}`}
              >
                {v.text}
              </div>
            </div>
          </React.Fragment>
        ))}
    </div>
  );
};

const getVitalsText = (field, diff, unit, actual_value) => {
  const leadingPlusSign = diff > 0 ? '+' : '';
  switch (field) {
    case 'weight':
      return `${Strings.weight}: ${leadingPlusSign}${roundToDecimal(
        WeightUnitConverter(diff, unit, WEIGHT_UNITS.lbs),
        2,
      )} lbs`;
    case 'systolic_pressure':
      return `SYS: ${leadingPlusSign}${diff}`;
    case 'diastolic_pressure':
      return `DIA: ${leadingPlusSign}${diff}`;
    case 'glucose_concentration':
      return `${Strings.glucose}: ${leadingPlusSign}${roundToDecimal(
        GlucoseUnitConverter(diff, unit, 'mg/dL'),
        2,
      )} mg/dL`;
    case 'saturation':
      return `${Strings.saturation}: ${leadingPlusSign}${diff}%`;
    case 'heart_rate':
      return `HR: ${leadingPlusSign}${diff}`;
    case 'temperature':
      return `${Strings.temperature}: ${leadingPlusSign}${roundToDecimal(
        convertCelsiusToFahrenheit(actual_value + diff) - convertCelsiusToFahrenheit(actual_value),
        2,
      )}ºF`;
    case 'pm2_5':
      return `PM 2.5: ${leadingPlusSign}${diff} µg/m³`;
    case 'pt':
      return `PT: ${leadingPlusSign}${diff}s`;
    case 'inr':
      return `INR: ${leadingPlusSign}${diff}`;
    default:
      return `${field}: ${leadingPlusSign}${diff}`;
  }
};

export const showStatus = status => {
  let icon = '';
  switch (status) {
    case 'onhold':
      icon = 'hold';
      break;
    case 'pending':
    case 'invited':
      icon = 'pending';
      break;
    case 'submitted':
    case 'enabled':
    case 'active':
    case 'configured':
      icon = 'tick';
      break;
    case 'disabled':
    case 'inactive':
    case 'forbidden':
      icon = 'delete';
      break;
    default:
      break;
  }

  return (
    <div className="icon-and-text-container">
      <div className={`icon-container ${icon}`} />
      {Strings.statusEnum[status] || `Undefined (${status})`}
    </div>
  );
};
