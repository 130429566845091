import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import { Modal } from '../containers';
import Form, { Input, TextArea } from '../containers/Form';
import { closeModal } from '../actions/modal';
import { resetForm } from '../actions/forms';
import LoadingRenderer from '../components/LoadingRenderer';
import { STUDY_NAME_LENGTH } from '../constants';
import Strings from '../Strings';
import './editStudyModal.scss';
import { NEW_TRIAL, UPDATE_TRIAL_RESULT, NEW_TRIAL_RESULT } from '../actions/action-types';
import { trialsAction } from '../actions/trials';

class EditStudyModal extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      study: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),

      action: PropTypes.func.isRequired,
      actionType: PropTypes.string.isRequired,
    }).isRequired,
  };

  static getCaption() {
    return window.location.pathname.includes('overview') ? Strings.editStudy : Strings.createStudy;
  }

  constructor(props) {
    super(props);

    const newStudy = {
      id: 0,
      name: '',
      description: '',
    };

    this.state = {
      errors: [],
      isStudyNameLonger: false,
      study: this.props.data.study ? cloneDeep(this.props.data.study) : newStudy,
    };
    this.textAreaInput = React.createRef();
    this.onTextChange = this.onTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onTextChange(event) {
    const { study } = this.state;
    if (this.state.errors.length) {
      study.description = '';
      this.setState({
        study,
        errors: [],
      });
      return;
    }

    study[event.target.getAttribute('name')] = event.target.value;
    const isStudyNameLonger = study.name.length > STUDY_NAME_LENGTH;
    this.setState({
      study,
      isStudyNameLonger,
    });
  }

  onSubmit() {
    const { study } = this.state;
    const errorString = Strings.errors.requiredStudyNameAndDescription;
    let isInValid = false;

    if (!study.name.length) {
      isInValid = true;
    }

    if (!study.description.length) {
      isInValid = true;
    }

    if (isInValid) {
      this.setState({ errors: [errorString] });
      Strings.description = errorString;
      return;
    }

    const {
      data: { actionType },
    } = this.props;

    const dateRange = this.props.dateFilter
      ? this.props.dateFilter
      : {
          infimum: moment()
            .subtract(1, 'month')
            .toDate(),
          supremum: moment().toDate(),
        };

    this.pageRequest = {
      startDate: dateRange.infimum,
      endDate: dateRange.supremum,
      offset: 0,
      search: '',
    };

    this.props.onSubmit(study, this.pageRequest).then(response => {
      if (response.type === `${actionType}/error` && response.response.data) {
        const responseData = response.response.data;
        const errors = Object.keys(responseData).map(key => `${key}: ${responseData[key]}`);
        this.setState({ errors });
      }

      return response;
    });
  }

  render() {
    const { onOpen, onCancel, editStudyModalLoading, ...props } = this.props;

    const { study, isStudyNameLonger, errors } = this.state;
    return (
      <Modal
        name="new-trial"
        onOpen={onOpen}
        onClose={onCancel}
        caption={EditStudyModal.getCaption()}
        additionalClasses={['form-modal']}
        {...props}
      >
        <LoadingRenderer loading={!!editStudyModalLoading}>
          <Form onSubmit={this.onSubmit} onCancel={onCancel} id="new-trial" key="new-trial">
            <div className="input-wrapper required mb-15 " key="studyName">
              <Input
                label=""
                name="name"
                id="name"
                className="dialog-form"
                placeholder={Strings.studyName}
                type="text"
                defaultValue={study.name}
                onChange={this.onTextChange}
              />
            </div>
            <div key="study-hint" className={isStudyNameLonger ? 'study-hint' : ''}>
              {isStudyNameLonger ? Strings.longStudyName : ''}
            </div>

            <div key="study-description" className="input-wrapper required">
              <TextArea
                name="description"
                id="description"
                className={errors.length ? 'errors dialog-form' : 'rounded dialog-form'}
                label=""
                placeholder={Strings.description}
                maxLength={1024}
                defaultValue={study.description}
                onChange={this.onTextChange}
              />
            </div>

            {/* <div className="errors-block">{errors.join(" ")}</div> */}
            <div className="button-bar reverse" key="button-bar">
              <button
                className="brand-blue"
                key="submit"
                type="submit"
                // disabled={!isEnabled}
              >
                {Strings.submit}
              </button>
              <button className="white gray-text mr-15" key="cancel" type="cancel">
                {Strings.cancel}
              </button>
            </div>
          </Form>
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  editStudyModalLoading: !!state.entities.trials.trialLoading,
  dateFilter: state.filter.dateFilter,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCancel: () => dispatch(closeModal(NEW_TRIAL)),
    onSubmit: (trial, pageRequest) =>
      dispatch(trial.id ? ownProps.data.action(trial.id, trial) : ownProps.data.action(trial)).then(response => {
        if (response && response.type === NEW_TRIAL_RESULT) {
          dispatch(trialsAction.actionList(pageRequest, false));
        }
        if (response && response.type === UPDATE_TRIAL_RESULT) {
          dispatch(trialsAction.actionGetInfo(trial.id));
        }
        dispatch(closeModal(NEW_TRIAL));

        return response;
      }),
    onOpen: () => dispatch(resetForm(NEW_TRIAL, ownProps.data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStudyModal);
