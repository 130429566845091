import { closeModal } from 'actions/modal';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { WizardForm } from '../../components/WizardForm/WizardForm';
import { Modal } from '../../containers';
import {
  ESI_IS_PORTAL_ONBOARDING_TUTORIAL_VIEWED,
  ESI_PATIENT_LOGIN_CONFIG_RESULT,
} from '../../pages/ESI/PatientLogin/redux/constants';
import Strings from '../../Strings';
import '../editStudyModal.scss';
import { actions } from './redux/actions';

class OnBoardingTutorialModal extends PureComponent {
  static MODAL_NAME = 'on-boarding-tutorial';
  TITLES = [
    Strings.tutorialTexts.dashTitle,
    Strings.tutorialTexts.medicationsTitle,
    Strings.tutorialTexts.calendarTitle,
    Strings.tutorialTexts.historyTitle,
    Strings.tutorialTexts.settingsTitle,
    Strings.tutorialTexts.supportTitle,
  ];

  state = {
    errors: null,
    values: null,
  };

  onSubmit = () => {
    const tcConfig = {
      key: ESI_IS_PORTAL_ONBOARDING_TUTORIAL_VIEWED,
      value: true,
    };

    this.props.updateConfig(tcConfig).then(r => {
      if (r.type === ESI_PATIENT_LOGIN_CONFIG_RESULT) {
        this.props.onCancel();
        this.props.navigate('/user-dashboard');
        window.location.reload();
      }
    });
  };

  getInitialValues() {
    const { timezone } = this.props;

    return { timezone };
  }

  setValues = values => {
    this.setState({ values });
  };

  onCancel = () => {
    this.props.skipOnBoarding();
    this.props.onCancel();
  };

  currentPage = values => {
    switch (values) {
      case 0:
        this.props.navigate(`/patient/${this.props.id}/medications`);
        break;
      case 1:
        this.props.navigate('/calendar');
        break;
      case 2:
        this.props.navigate('/history');
        break;
      case 3:
        this.props.navigate('/esi-patient-settings');
        break;
      case 4:
        this.props.navigate('/support/contact-us');
        break;
      default:
        this.props.navigate('/user-dashboard');
        break;
    }
  };

  render() {
    const { onOpen, onCancel, ...props } = this.props;

    return (
      <Modal
        onOpen={onOpen}
        name={OnBoardingTutorialModal.MODAL_NAME}
        additionalClasses={['form-modal', 'user-invite']}
        withoutContainer
        {...props}
      >
        <WizardForm
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
          onNext={this.setValues}
          initialValues={this.getInitialValues()}
          titles={this.TITLES}
          submitButtonTitle={Strings.done}
          cancelButtonTitle={Strings.tutorialTexts.skipBtn}
          currentPage={this.currentPage}
          showCancelButton={false}
        >
          <WizardForm.Page>
            <div>{Strings.tutorialTexts.dashboardInfo}</div>
          </WizardForm.Page>
          <WizardForm.Page>
            <div>{Strings.tutorialTexts.medicationsInfo}</div>
          </WizardForm.Page>
          <WizardForm.Page>
            <div>{Strings.tutorialTexts.calendarInfo}</div>
          </WizardForm.Page>
          <WizardForm.Page>
            <div>{Strings.tutorialTexts.historyInfo}</div>
          </WizardForm.Page>
          <WizardForm.Page>
            <div>{Strings.tutorialTexts.settignsInfo}</div>
          </WizardForm.Page>
          <WizardForm.Page>
            <div>{Strings.tutorialTexts.supportInfo}</div>
          </WizardForm.Page>
        </WizardForm>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    id: state.auth?.profile?.id || 0,
    timezone: state.auth?.profile?.preferences?.timezone,
  };
};

const mapDispatchToProps = dispatch => ({
  onCancel: () => dispatch(closeModal(OnBoardingTutorialModal.MODAL_NAME)),
  navigate: path => dispatch(push(path)),
  updateConfig: config => dispatch(actions.updateConfig(config)),
  skipOnBoarding: () => dispatch(actions.skipOnBoarding()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnBoardingTutorialModal);
