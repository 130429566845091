import { select } from 'd3-selection';
import { axisBottom, axisLeft } from 'd3-axis';
import { min as d3min, max as d3max } from 'd3-array';
import { scaleLinear, scaleTime } from 'd3-scale';
import moment from 'moment-timezone';
import { timeFormat } from 'd3-time-format';

import _ from 'lodash';

class TimeDeltaPlot {
  static defaultValues = {
    radius: 5,
    barPadding: 0,
    rectPadding: 15,
    minLeftDistance: 180,
    minTopDistance: 20,
    minBottomDistance: 60,
  };

  getClassByTimeDelta = timedelta => {
    const delta = Math.abs(timedelta);
    if (delta <= 30) return 'good';
    if (delta <= 60) return 'bad';
    return 'ugly';
  };

  resizeHandler = () => {
    const { data, minDate, maxDate } = this.props;
    const viewBox = this.updateViewBox();

    const x = scaleTime()
      .range([0, viewBox.width])
      .domain([minDate, maxDate]);

    const borders = TimeDeltaPlot.getYBorders(data.data || []);
    const yTicksCount = borders[1] - borders[0];

    const y = scaleLinear()
      .range([viewBox.height, 0])
      .domain(borders)
      .nice();

    this.updateAxes(
      {
        x,
        y,
      },
      viewBox,
      yTicksCount,
    );
    this.renderNotifications(data.data, x, y, viewBox);
  };

  installHandlers = () => {
    window.addEventListener('resize', this.resizeHandler);
  };

  create = (node, props) => {
    this.props = props;
    const { data, minDate, maxDate } = props;
    this.container = select(node);

    this.svg = this.container.append('svg').attr('class', 'plot timedelta');

    this.gMain = this.svg.append('g').attr('class', 'main');
    const viewBox = this.updateViewBox();

    const x = scaleTime()
      .range([0, viewBox.width])
      .domain([minDate, maxDate]);

    const borders = TimeDeltaPlot.getYBorders(data.data || []);
    const yTicksCount = borders[1] - borders[0];

    const y = scaleLinear()
      .range([viewBox.height, 0])
      .domain(borders)
      .nice();

    this.gMain.append('g').attr('class', 'grid vertical');

    this.gMain
      .append('g')
      .attr('class', 'grid horizontal')
      .append('line')
      .attr('class', 'domain');

    this.gMain.append('g').attr('class', 'chart');

    this.gMain
      .append('g')
      .attr('class', 'axis middle')
      .attr('transform', `translate(0, ${y(0) + 200})`);

    this.gMain.append('g').attr('class', 'axis middle-vertical');

    this.renderNotifications(data.data || [], x, y, viewBox);
    this.updateAxes(
      {
        x,
        y,
      },
      viewBox,
      yTicksCount,
    );
    this.installHandlers();
  };

  static getYBorders(data) {
    const minPeriod = 1;
    let min = d3min(data, d => Math.min(d.notification.y, d.event.y) - 1) || 0;
    let max = d3max(data, d => Math.max(d.notification.y, d.event.y) + 1) || 24;
    const delta = Math.abs(max - min);
    if (delta < minPeriod) {
      max = Math.min(Math.ceil(max + (minPeriod - delta) / 2), 24);
      min = Math.max(Math.floor(min - (minPeriod - delta) / 2), 0);
    }

    return [min, max];
  }

  // eslint-disable-next-line class-methods-use-this

  updateAxes = (scales, viewBox, yTicksCount) => {
    const { yTickFormat } = this.props;
    const { x, y } = scales;

    const ticks = yTicksCount >= 12 ? 12 : yTicksCount;
    const axes = {
      x: axisBottom(x)
        .tickSize(4)
        .tickSizeOuter([0])
        .tickFormat(timeFormat('%b %d')),
      y: axisLeft(y)
        .ticks(ticks)
        // .tickSize(4)
        .tickPadding(10)
        .tickSizeOuter([0]),
    };

    if (yTickFormat) {
      axes.y.tickFormat(yTickFormat);
    }

    const { x: axisX, y: axisY } = axes;
    this.gMain
      .select('g.axis.middle')
      .attr('transform', `translate(0, ${viewBox.height})`)
      .call(axisX);

    this.gMain.select('g.axis.middle-vertical').call(axisY);

    this.gMain
      .select('g.grid.vertical')
      .call(
        axisBottom(x)
          .tickSize(-viewBox.height)
          .tickFormat(''),
      )
      .attr('transform', `translate(0,${viewBox.height})`)
      .select('.domain')
      .remove();

    const gridTicksCount = yTicksCount >= 12 ? yTicksCount : yTicksCount * 4;
    this.gMain
      .select('g.grid.horizontal')
      .call(
        axisLeft(y)
          .tickSize(-viewBox.width)
          .tickFormat('')
          .ticks(gridTicksCount),
      )
      .select('.domain')
      .remove();
  };

  static getBarWidth() {
    // const range = xScale.range();
    // const rangeWidth = range[1] - range[0];
    // const groupCount = xScale.ticks().length;
    return 16;
  }

  renderNotifications = (data, x, y, viewBox) => {
    const { minDate } = this.props;
    const barWidth = TimeDeltaPlot.getBarWidth(x);
    const barCenter = barWidth / 2 + TimeDeltaPlot.defaultValues.barPadding;
    const self = this;

    function setUpLine(lineSelection, lineType) {
      lineSelection
        .attr('class', d => `${self.getClassByTimeDelta(d.rawTimedelta)} ${lineType} id${d.id}`)
        .attr('x1', d => {
          if (lineType === 'connector') {
            return d.connector.x + barCenter;
          }
          return x(d.notification.x) + TimeDeltaPlot.defaultValues.barPadding;
        })
        .attr('x2', d => {
          if (lineType === 'connector') {
            return d.connector.x + barCenter;
          }
          return x(d.notification.x) + barWidth + TimeDeltaPlot.defaultValues.barPadding;
        })
        .attr('y1', d => {
          if (lineType === 'connector') {
            return d.connector.y1;
          }
          return y(d.notification.y);
        })
        .attr('y2', d => {
          if (lineType === 'connector') {
            return d.connector.y2;
          }
          return y(d.notification.y);
        });
    }

    function setUpEventCircle(circleSelection) {
      circleSelection
        .attr('class', dd => {
          return `${self.getClassByTimeDelta(dd.rawTimedelta)} time-marker circle id${dd.id}`;
        })
        .attr('data-tip', true)
        .attr('data-tooltip-id', dd => `id${dd.id}`)
        .attr('notificationId', dd => dd.id)
        .attr('cx', dd => x(dd.event.x) + barCenter)
        .attr('cy', dd => y(dd.event.y))
        .attr('r', TimeDeltaPlot.defaultValues.radius);
    }

    const chart = this.gMain.select('g.chart');

    // prepare data array for connectors
    // there can be more than one connector for one notification if dates of event and notification are different
    const connectorsArray = [];
    data.forEach(d => {
      if (d.notification.x === d.event.x) {
        connectorsArray.push({
          ...d,
          connector: {
            x: x(d.notification.x),
            y1: y(d.notification.y),
            y2: y(d.event.y),
          },
        });
      } else {
        const notificationDate = moment(d.notification.x);
        const eventDate = moment(d.event.x);

        const eventFirst = d.notification.x > d.event.x;
        const startDate = eventFirst ? moment(d.event.x) : moment(d.notification.x);
        const endDate = eventFirst ? moment(d.notification.x) : moment(d.event.x);
        for (let da = startDate; da.format('MM-DD') <= endDate.format('MM-DD'); da.add(1, 'day')) {
          if (da.valueOf() < minDate.getTime()) {
            // eslint-disable-next-line no-continue
            continue;
          }
          // iterate through all days between notification date and event timestamp
          const connector = {};
          if (da.format('MM-DD') === notificationDate.format('MM-DD')) {
            connector.x = x(d.notification.x);
            connector.y1 = eventFirst ? 0 : viewBox.height;
            connector.y2 = y(d.notification.y);
          } else if (da.format('MM-DD') === eventDate.format('MM-DD')) {
            connector.x = x(d.event.x);
            connector.y1 = eventFirst ? 0 : viewBox.height;
            connector.y2 = y(d.event.y);
          } else {
            connector.x = x(da.valueOf());
            connector.y1 = 0;
            connector.y2 = viewBox.height;
          }
          connectorsArray.push({
            ...d,
            connector,
          });
        }
      }
    });

    const connectors = chart.selectAll('line.connector').data(connectorsArray);

    // create
    setUpLine(connectors.enter().append('line'), 'connector');
    // update
    setUpLine(connectors.transition(), 'connector');
    // remove
    connectors.exit().remove();

    const circles = chart.selectAll('circle.time-marker').data(data);

    // create
    setUpEventCircle(circles.enter().append('circle'));

    // update
    setUpEventCircle(circles.transition());

    // remove
    circles.exit().remove();

    const tooltip = select('.Chart')
      .attr('id', 'time-delta-graph')
      .append('div')
      .attr('class', 'time-delta-custom-tooltip')
      .style('position', 'absolute')
      .style('z-index', '1000')
      .style('opacity', 0);

    this.gMain
      .selectAll('g.chart .time-marker, g.chart .connector')
      .on('mouseover', notificationData => {
        this.gMain.classed('go-grey', true);
        this.gMain.selectAll(`g.chart .id${notificationData.id}`).classed('active', true);

        const cx = document.getElementsByClassName(`time-marker circle id${notificationData.id}`)[0].cx.baseVal.value;
        const cy = document.getElementsByClassName(`time-marker circle id${notificationData.id}`)[0].cy.baseVal.value;
        tooltip
          .html(
            `${'<div class=' +
              'tooltip-day-1' +
              '><div class=' +
              'label1' +
              '> Taken Time: </div>' +
              '<div class=' +
              'value1' +
              '>'}${notificationData.actualEvent.time}</div></div></div>` +
              `<div class=` +
              `tooltip-day-1` +
              `><div class=` +
              `label1` +
              `> Scheduled Time: </div>` +
              `<div class=` +
              `value1` +
              `>${notificationData.notification.time}</div></div></div>` +
              `<div class=` +
              `tooltip-day-1` +
              `><div class=` +
              `label1` +
              `> Timedelta: </div>` +
              `<div class=` +
              `value1` +
              `>${notificationData.timedelta}</div></div></div>`,
          )
          .style('opacity', 0.9)
          .style('top', `${cy + 50}px`)
          .style('left', `${cx - 50}px`);
      })
      .on('mouseout', notificationData => {
        this.gMain.classed('go-grey', false);
        this.gMain.selectAll(`g.chart .id${notificationData.id}`).classed('active', false);

        tooltip.style('opacity', 0);
        select('.custom-tooltip')
          .style('stroke', 'none')
          .style('opacity', 0);
      });
  };

  updateViewBox = () => {
    const brc = this.container.node().getBoundingClientRect();
    const { margin } = this.props;
    const viewBox = {
      x: margin.left,
      y: margin.top,
      left: brc.left,
      top: brc.top,
      width: brc.width - margin.left - margin.right,
      height: brc.height - margin.top - margin.bottom,
    };

    this.svg.attr('viewBox', `0 0 ${brc.width} ${brc.height}`);
    this.gMain.attr('transform', `translate(${viewBox.x},${viewBox.y})`);
    return viewBox;
  };

  update = (node, props) => {
    this.props = props;
    const { data, minDate, maxDate } = props;
    this.container = select(node);

    this.svg = this.container.select('svg');
    this.gMain = this.svg.select('g.main');
    const viewBox = this.updateViewBox();

    const x = scaleTime()
      .range([0, viewBox.width])
      .domain([minDate, maxDate]);

    const borders = TimeDeltaPlot.getYBorders(data.data || []);
    const yTicksCount = borders[1] - borders[0];

    const y = scaleLinear()
      .range([viewBox.height, 0])
      .domain(borders)
      .nice();

    const chart = this.gMain.select('.chart');

    chart.selectAll('.notification_axis').remove();

    const yAxises = [];

    data.data?.forEach(d => {
      yAxises.push(d.notification.y);
    });

    _.uniq(yAxises).forEach(dy => {
      chart
        .append('line')
        .attr('class', 'notification_axis')
        .attr('stroke-dasharray', '5')
        .attr('id', `axis_${dy}`)
        .attr('x1', () => 0)
        .attr('x2', () => viewBox.width)
        .attr('y1', () => y(dy))
        .attr('y2', () => y(dy));
    });

    this.renderNotifications(data.data || [], x, y, viewBox);
    this.updateAxes(
      {
        x,
        y,
      },
      viewBox,
      yTicksCount,
    );
  };

  destroy = () => {
    window.removeEventListener('resize', this.resizeHandler);
  };
}

export default TimeDeltaPlot;
