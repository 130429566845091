import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';

import { openModalAction } from '../../../actions/modal';
import { PatientDashboardAction } from '../../../actions/patient-dashboard';
import { SELECT_TYPE_RIGHT } from '../../../components/Inputs/SelectInput';
import LoadingRenderer from '../../../components/LoadingRenderer';
import { PageHeader } from '../../../components/PageHeader';
import PatientAdherencePiePlot from '../../../components/Plots/patient-adherence-plot';
import Select, { SELECT_TYPE_LEFT } from '../../../components/Select/SelectOld';
import {
  ADHERENCE_LEVEL_AVERAGE,
  ADHERENCE_LEVEL_AVERAGE_C,
  ADHERENCE_LEVEL_GOOD,
  ADHERENCE_LEVEL_GOOD_C,
  ADHERENCE_LEVEL_POOR,
  ADHERENCE_LEVEL_POOR_C,
} from '../../../constants';
import TimezoneChangeModal from '../../../modals/TimezoneChangeModal/TimezoneChangeModal';
import OnBoardingTutorialModal from '../../../modals/OnBoardingTutorialModal/OnBoardingTutorialModal';
import Strings from '../../../Strings';
import { pluralize } from '../../../utils';
import { ActiveMeds } from './ActiveMeds';
import { Battery } from './Battery';
import { Panel } from './Panel';
import { Reminders } from './Reminders';
import { Separator } from './Separator';
import { Stat } from './Stat';
import { Stocks } from './Stocks';
import styles from './style.module.scss';
import { TopPanel } from './TopPanel';

const mapStateToProps = state => {
  const { patientDashboard, loading = true } = state.entities.patientDashboard;

  return {
    isLoading: loading !== false,
    patientId: state.auth.profile?.id,
    config: state.auth?.profile?.config,
    skipOnBoarding: state.auth?.esi_is_portal_onboarding_tutorial_viewed,
    adherence: patientDashboard?.adherence,
    stocks: patientDashboard?.stocks,
    reminders: patientDashboard?.reminders,
    userFullname: state.auth.profile ? `${state.auth.profile.first_name} ${state.auth.profile.last_name}` : '',
  };
};

const mapDispatchToProps = dispatch => ({
  loadDashboard: id => dispatch(PatientDashboardAction.getDashboard(id)),
  navigate: path => dispatch(push(path)),
  openTimezoneModal: () => dispatch(openModalAction(TimezoneChangeModal.MODAL_NAME)),
  onBoarding: () => dispatch(openModalAction(OnBoardingTutorialModal.MODAL_NAME)),
});

const types = [
  {
    value: 'previous_month',
    text: Strings.yourSuccessPreviousMonth,
  },
  {
    value: 'monthly',
    text: Strings.yourSuccessMonthly,
  },
  {
    value: 'weekly',
    text: Strings.yourSuccessWeekly,
  },
];

class PatientDashboard extends PureComponent {
  refSelectMedication = React.createRef();
  state = {
    selectedMedication: null,
    selectedType: 'monthly',
  };

  componentDidMount() {
    const { patientId, loadDashboard, openTimezoneModal, config, skipOnBoarding } = this.props;
    if (!config?.esi_is_portal_onboarding_tutorial_viewed) {
      if (!skipOnBoarding) {
        setTimeout(() => {
          this.props.onBoarding();
        }, 1000);
      }
    }

    if (patientId) {
      loadDashboard(patientId);
    }

    // Opening time zone modal if first login
    const firstLogin = sessionStorage.getItem('first_login');
    if (firstLogin === true || firstLogin === 'true') {
      openTimezoneModal();
    }
    sessionStorage.removeItem('first_login');
    // this.refSelectMedication && this.refSelectMedication.current.refSelect.current.focus();
  }

  navigateToPatientMedications = () => {
    const { navigate, patientId } = this.props;
    navigate(`/patient/${patientId}/medications`);
  };

  navigateToPatientMedication = id => {
    const { navigate, patientId } = this.props;
    navigate(`/patient/${patientId}/medications/${id}/details`);
  };

  navigateToCalendar = () => {
    const { navigate } = this.props;
    navigate('/calendar');
  };

  navigateToHistory = () => {
    const { navigate } = this.props;
    navigate('/history');
  };

  onMedFilterChange = value => {
    /* eslint-disable */
    if (value === Strings.patientDashboard.allMeds) {
      value = null;
    }
    /* eslint-enable */

    this.setState({ selectedMedication: value });
  };

  onTypeFilterChange = value => {
    this.setState({ selectedType: value });
  };

  getHeaderComponentsRight = () => {
    const { adherence } = this.props;
    const medData = [Strings.patientDashboard.allMeds];

    adherence?.medications.forEach(m => {
      medData.push(_.truncate(m.name, { length: 100 }));
    });

    return (
      <Select
        type={SELECT_TYPE_RIGHT}
        autoWidth
        classes={[Select.CLASS_BIG, Select.CLASS_DARK, Select.CLASS_NO_MARGIN_RIGHT]}
        data={_.uniq(medData)}
        onChange={this.onMedFilterChange}
        ref={this.refSelectMedication}
      />
    );
  };

  getMedicationData = () => {
    const { selectedMedication } = this.state;
    const { adherence } = this.props;
    /* eslint-disable */
    for (const k in adherence?.medications) {
      const m = adherence.medications[k];
      if (m.name === selectedMedication) return m;
    }
    /* eslint-enable */
    return adherence;
  };

  getSelectedAdherence = () => {
    const { selectedType } = this.state;
    const med = this.getMedicationData();
    if (med) {
      return med[selectedType];
    }
  };

  render() {
    const { isLoading, adherence, stocks, reminders, userFullname } = this.props;
    const { selectedType } = this.state;
    const adherenceByType = this.getSelectedAdherence();
    let label = '';
    switch (adherenceByType?.performance) {
      case ADHERENCE_LEVEL_POOR:
        label = ADHERENCE_LEVEL_POOR_C;
        break;
      case ADHERENCE_LEVEL_GOOD:
        label = ADHERENCE_LEVEL_GOOD_C;
        break;
      case ADHERENCE_LEVEL_AVERAGE:
        label = ADHERENCE_LEVEL_AVERAGE_C;
        break;
      default:
        label = '';
        break;
    }

    return (
      <React.Fragment>
        <PageHeader
          noLeftPadding
          isBlack
          left={`${Strings.welcome}, ${userFullname}`}
          // right={() => this.getHeaderComponentsRight()}
        />
        <LoadingRenderer loading={isLoading}>
          <TopPanel
            adherence={adherence?.total}
            performance={adherence?.performance}
            left={
              <React.Fragment>
                <Separator />
                {pluralize(adherence?.no_of_days, Strings.day)}
                {Strings.daysStreak}
              </React.Fragment>
            }
          >
            {Strings.battery.battery}:
            <Battery value={adherence?.battery_status} />
          </TopPanel>

          <div className={styles.panels}>
            <div className={styles.row}>
              <Panel
                title={() => (
                  <Select
                    type={SELECT_TYPE_LEFT}
                    autoWidth
                    classes={[
                      Select.CLASS_BIG,
                      Select.CLASS_DARK,
                      Select.CLASS_LEFT_OPTIONS,
                      Select.CLASS_PATIENT_DASHBOARD,
                    ]}
                    data={types}
                    value={selectedType}
                    onChange={this.onTypeFilterChange}
                  />
                )}
                buttonTitle={Strings.patientDashboard.viewHistory}
                onClick={this.navigateToHistory}
                ariaLabel="navigate to the history screen"
              >
                <div className={styles.chart_stat_panel}>
                  <div className={styles.chart}>
                    {adherenceByType && (
                      <PatientAdherencePiePlot
                        additionalClass={adherenceByType?.performance}
                        data={adherenceByType}
                        label={label}
                        isLoading={false}
                      />
                    )}
                  </div>
                  <div className={styles.stat}>
                    <Stat data={adherenceByType} />
                  </div>
                </div>
              </Panel>
              <Panel
                title={Strings.patientDashboard.upcomingReminders}
                buttonTitle={Strings.calendar}
                onClick={this.navigateToCalendar}
                ariaLabel="navigate to the calendar screen"
              >
                <Reminders data={reminders} />
              </Panel>
            </div>
            <div className={styles.row}>
              <Panel
                title={Strings.patientDashboard.activeMedications}
                buttonTitle={Strings.patientDashboard.seeAll}
                onClick={this.navigateToPatientMedications}
                ariaLabel="navigate to the medication list screen"
              >
                <ActiveMeds data={adherence?.medications} onClick={this.navigateToPatientMedication} />
              </Panel>
              <Panel
                title={Strings.patientDashboard.stockManagement}
                buttonTitle={Strings.patientDashboard.seeDetails}
                onClick={this.navigateToPatientMedications}
                ariaLabel={Strings.medicationScreenAL}
              >
                <Stocks data={stocks} />
              </Panel>
            </div>
          </div>
        </LoadingRenderer>
      </React.Fragment>
    );
  }
}

PatientDashboard.propTypes = {
  adherence: PropTypes.shape({
    battery_status: PropTypes.any,
    medications: PropTypes.shape({ forEach: PropTypes.func }),
    no_of_days: PropTypes.any,
    performance: PropTypes.any,
    total: PropTypes.any,
  }),
  config: PropTypes.shape({ esi_is_portal_onboarding_tutorial_viewed: PropTypes.any }),
  isLoading: PropTypes.any,
  loadDashboard: PropTypes.func,
  navigate: PropTypes.func,
  onBoarding: PropTypes.func,
  openTimezoneModal: PropTypes.func,
  patientId: PropTypes.any,
  reminders: PropTypes.any,
  skipOnBoarding: PropTypes.any,
  stocks: PropTypes.any,
  userFullname: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps)(PatientDashboard);
