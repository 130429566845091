import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { openModalAction } from '../../../actions/modal';
import { PageHeader } from '../../../components/PageHeader';
import { Button } from '../../../components/PageHeader/Button';
import { TextInput } from '../../../components/PageHeader/TextInput';
import { DATE_FORMAT_MONTH_NAME, HEADER_BUTTON_BLUE, PAGE_LIMIT } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { actions } from '../Organization/redux/actions';
import { ADD_ORGANIZATION_OUTBOUND_API, UPDATE_ORGANIZATION_OUTBOUND_API } from '../Organization/redux/constants';
import './OutboundAPI.scss';
import { cloneDeep } from 'lodash';

class OutboundAPI extends PureComponent {
  searchTime;
  componentDidMount() {
    this.pageRequest = {
      offset: 0,
      search: '',
      organizationId: decodeURIComponent(this.props.organizationId),
    };
    this.props.getOutboundAPIList(this.pageRequest);
  }

  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  onSearchQueryChange = query => {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    clearTimeout(this.searchTime);
    this.searchTime = setTimeout(() => {
      this.props.getOutboundAPIList(this.pageRequest);
    }, 1000);
  };

  onPrevClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset - PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getOutboundAPIList(this.pageRequest);
  };

  onNextClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset + PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getOutboundAPIList(this.pageRequest);
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.getOutboundAPIList(this.pageRequest);
  };

  onSortClick = ({ sortKey, direction }) => {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    this.props.getOutboundAPIList(this.pageRequest);
  };

  getHeaderComponents() {
    return (
      <React.Fragment>
        <TextInput class="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        <Button disabled={false} class={HEADER_BUTTON_BLUE} onClick={this.addNewOutBoundAPI} title={Strings.add} />
      </React.Fragment>
    );
  }

  showStatus = patient => {
    const { is_active } = patient;
    if (!is_active) {
      return (
        <div className="status-conatiner med-status">
          <div className="approval-status img-block  inactive-medicine"> </div>
          <span className="status-text admin-text">{Strings.patient_medications.inActive}</span>
        </div>
      );
    }

    return (
      <div className="status-conatiner med-status">
        <div className="approval-status img-block accepted" />
        <span className="status-text admin-text">{Strings.patient_medications.active}</span>
      </div>
    );
  };

  addNewOutBoundAPI = () => {
    this.pageRequest = {
      offset: 0,
      search: '',
      organizationId: decodeURIComponent(this.props.organizationId),
      version: '1.1',
    };
    this.props.onAddNewOutBoundAPI(this.pageRequest);
  };

  onOrgOutboundAPIEdit = id => {
    this.pageRequest.version = '1.1';
    const apiData = cloneDeep(this.props.organizationOutboundAPI[id]);
    apiData.paused = {
      paused: apiData.paused,
    };
    apiData.success_field_required = {
      success_field_required: apiData.success_field_required,
    };
    this.props.onEditOrganizationOutboundAPI(apiData, this.pageRequest);
  };

  onOrgOutboundAPIDelete = id => {
    const api = this.props.organizationOutboundAPI[id];
    const deleteData = { id: api.id };

    const data = {
      title: (
        <span>
          {Strings.deleteOrgOutboundAPIWarning} <b> {api.name}</b>?
        </span>
      ),

      onConfirmAction: actions.deleteOrganizationOutboundAPI(deleteData, [
        actions.getOutboundAPIList({
          ...this.request,
          limit: PAGE_LIMIT,
        }),
      ]),
      onCancelAction: null,
    };
    this.props.openConfirmModal(data);
  };

  render() {
    const { isLoading, organizationOutboundAPI, pagination } = this.props;

    const headerTitle =
      !isLoading && organizationOutboundAPI && pagination
        ? Strings.formatString(
            Strings.showingXOf,
            organizationOutboundAPI.length,
            pagination.totalRecords ? pagination.totalRecords : 0,
            Strings.outboundAPITab,
          )
        : Strings.showingWait;

    const buttons = [
      {
        icon: 'edit',
        onClick: this.onOrgOutboundAPIEdit,
        text: Strings.edit,
      },
      {
        icon: 'delete',
        onClick: this.onOrgOutboundAPIDelete,
        text: Strings.delete,
      },
    ];

    return (
      <div className="org-out-bound-api">
        <PageHeader left={headerTitle} right={this.getHeaderComponents()} />
        <Table
          isLoading={isLoading}
          name="organizations"
          data={organizationOutboundAPI}
          onPrevClick={this.onPrevClick}
          onSortClick={this.onSortClick}
          onNextClick={this.onNextClick}
          onCustomPage={this.onCustomPage}
          pagination={
            pagination || {
              offset: 0,
              total: 0,
            }
          }
          buttons={buttons}
        >
          <Column key="name" title={Strings.name} value={d => d.name} />
          <Column key="url" title={Strings.urls} value={d => d.url} />
          <Column key="username" title={Strings.key} value={d => d.auth_key} />
          <Column key="auth_key_header_parameter" title={Strings.authHeader} value={d => d.auth_key_header_parameter} />
          <Column
            key="paused"
            title={Strings.pauseAPI}
            value={d => (d.paused ? Strings.apiStatus.active : Strings.apiStatus.paused)}
          />
          <Column key="version" title={Strings.version} value={d => d.version} />
          <Column key="max_batch_size" title={Strings.maxBatchSize} value={d => d.max_batch_size} />
          <Column
            key="auth_key_created_at"
            title={Strings.dateOfCreation}
            value={e => moment.unix(e.auth_key_created_at).format(DATE_FORMAT_MONTH_NAME)}
          />
          <Column
            key="success_field_required"
            title={Strings.successFieldRequired}
            value={d => (d.success_field_required ? Strings.required : Strings.notRequired)}
          />
          <Column
            key="max_time_without_events"
            title={Strings.maxTimeWithoutEvents}
            value={d => d.max_time_without_events}
          />
          <Column key="status" title={Strings.status} value={this.showStatus} />
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { organizations } = state.superUser;
  return {
    role: state.auth.role,
    organizationOutboundAPI: organizations && organizations?.organizationOutboundAPI,
    isLoading: organizations && organizations?.isLoading,
    pagination: organizations && organizations?.pagination,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onNavigate: path => dispatch(push(path)),
  getOutboundAPIList: pageRequest => dispatch(actions.getOutboundAPIList(pageRequest)),
  onAddNewOutBoundAPI: pageRequest =>
    dispatch(
      openModalAction('organization-outbound-api', {
        action: actions.addOrganizationOutboundAPI,
        clearErrors: actions.clearErrors,
        actionType: ADD_ORGANIZATION_OUTBOUND_API,
        writePrivileges: false,
        organization_id: props.organizationId,
        nextAction: actions.getOutboundAPIList,
        pageRequest,
      }),
    ),

  onEditOrganizationOutboundAPI: (organization, pageRequest) =>
    dispatch(
      openModalAction('organization-outbound-api', {
        action: actions.updateOrganizationOutboundAPI,
        clearErrors: actions.clearErrors,
        actionType: UPDATE_ORGANIZATION_OUTBOUND_API,
        organization_id: props.organizationId,
        nextAction: actions.getOutboundAPIList,
        pageRequest,
        data: organization,
      }),
    ),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutboundAPI);
