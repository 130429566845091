import moment from 'moment-timezone';
import { normalize } from 'normalizr';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { NEW_TRIAL, POST_ADMIN_INVITE, SEND_INVITE } from '../../actions/action-types';
import adminsAction from '../../actions/admins';
import { changeFilter, clearEntitiesAction } from '../../actions/index';
import invitesAction from '../../actions/invites';
import { openModalAction } from '../../actions/modal';
import { patientAction } from '../../actions/patient';
import { trialsAction } from '../../actions/trials';
import AdherenceGraph from '../../components/Graphs/AdherenceGraph';
import CapActivations from '../../components/Graphs/CapActivations';
import InfoBox from '../../components/InfoBox';
import { PageHeader } from '../../components/PageHeader';
import SliderPicker from '../../components/SliderPicker';
import WithLoader from '../../components/_hocs/withLoader';
import { DATE_FORMAT_MONTH_NAME, LocationUSCenter } from '../../constants';
import { last7DaysAdherenceSchema } from '../../schemas';
import Strings from '../../Strings';
import { isEsi } from '../../utils/portalType';
import { isDoctor } from '../../utils/userRoles';
import './Dashboard.scss';
import { actions } from './redux/actions';
import DashboardLayout from '../Dashboards/DashboardLayout';
import DashboardFilter from '../Dashboards/DashboardFilter';
import { PERMISSIONS, PERMISSION_GROUPS, hasAnyPermissionInGroup } from '../../utils/userPermissions';
import DashboardEditModeButton from '../Dashboards/DashboardEditModeButton';
import EsiActivePillboxUsers, {
  getActiveMonthlyPillboxUsersMetadata,
} from '../Dashboards/Widgets/EsiActivePillboxUsers';
import EsiPatientStats, { getEsiPatientsStateMetadata } from '../Dashboards/Widgets/EsiPatientStats';
import { ColorPalette } from '../Dashboards/DashboardColorPalettes';

export const DASHBOARD_ID = 'Adherence';

const MissedDosesMetadata = {
  id: 'MissedDosesWidget',
  name: Strings.missedDoses,
  permission: PERMISSIONS.DASHBOARD_ADHERENCE_ADHERENCE_STATS,
  defProps: { i: 'MissedDosesWidget', w: 4, h: 2, minW: 4, minH: 2 },
};

const AdherenceStatsMetadata = {
  id: 'AdherenceStatsWidget',
  name: Strings.adminDashboard.adherenceStats,
  permission: PERMISSIONS.DASHBOARD_ADHERENCE_ADHERENCE_STATS,
  defProps: { i: 'AdherenceStatsWidget', w: 4, h: 3, minW: 4, minH: 3 },
};

const TotalAdherenceMetadata = {
  id: 'TotalAdherenceWidget',
  name: Strings.totalAdherence,
  permission: PERMISSIONS.DASHBOARD_ADHERENCE_ADHERENCE_CHART,
  defProps: { i: 'TotalAdherenceWidget', w: 4, h: 5, minW: 4, minH: 4 },
};

const EvolutionOfCapActivationMetadata = {
  id: 'EvolutionOfCapActivationWidget',
  name: Strings.adminDashboard.evolutionOfCapActivations,
  permission: PERMISSIONS.DASHBOARD_ADHERENCE_CAP_EVOLUTION,
  defProps: { i: 'EvolutionOfCapActivationWidget', w: 4, h: 5, minW: 4, minH: 4 },
};

export const WidgetMetadata = [
  MissedDosesMetadata,
  AdherenceStatsMetadata,
  TotalAdherenceMetadata,
  EvolutionOfCapActivationMetadata,
  getActiveMonthlyPillboxUsersMetadata(false, false),
  getEsiPatientsStateMetadata(false, false),
];

const l = [
  { ...MissedDosesMetadata.defProps, x: 0, y: 0, w: 4, h: 2 },
  { ...AdherenceStatsMetadata.defProps, x: 0, y: 2, w: 4, h: 3 },
  { ...TotalAdherenceMetadata.defProps, x: 4, y: 0, w: 4, h: 5 },
  { ...EvolutionOfCapActivationMetadata.defProps, x: 8, y: 0, w: 4, h: 5 },
  { ...getActiveMonthlyPillboxUsersMetadata(false, false).defProps, x: 0, y: 5, w: 4, h: 2 },
  { ...getEsiPatientsStateMetadata(false, false).defProps, x: 4, y: 5, w: 4, h: 4 },
];

const m = [
  { ...MissedDosesMetadata.defProps, x: 0, y: 0, w: 4, h: 2 },
  { ...AdherenceStatsMetadata.defProps, x: 0, y: 2, w: 4, h: 3 },
  { ...TotalAdherenceMetadata.defProps, x: 4, y: 0, w: 4, h: 5 },
  { ...EvolutionOfCapActivationMetadata.defProps, x: 0, y: 5, w: 8, h: 5 },
  { ...getActiveMonthlyPillboxUsersMetadata(false, false).defProps, x: 8, y: 0, w: 4, h: 2 },
  { ...getEsiPatientsStateMetadata(false, false).defProps, x: 8, y: 2, w: 4, h: 4 },
];

const s = [
  { ...MissedDosesMetadata.defProps, x: 0, y: 0, w: 12, h: 2 },
  { ...AdherenceStatsMetadata.defProps, x: 0, y: 2, w: 12, h: 3 },
  { ...TotalAdherenceMetadata.defProps, x: 0, y: 4, w: 12, h: 4 },
  { ...EvolutionOfCapActivationMetadata.defProps, x: 0, y: 6, w: 4, h: 4 },
  { ...getActiveMonthlyPillboxUsersMetadata(false, false).defProps, x: 4, y: 6, w: 4, h: 2 },
  { ...getEsiPatientsStateMetadata(false, false).defProps, x: 8, y: 6, w: 4, h: 4 },
];

export const DefaultLayouts = { l, m, s };

function Dashboard(props) {
  const timeout = useRef();
  const pageRequest = useRef({});

  useEffect(() => {
    props.patientsHaveNotifications();
    props.clearEntities();
  }, []);

  useEffect(() => {
    if (!props.timezone) {
      return;
    }
    if (props.dateFilter.infimum && props.dateFilter.supremum) {
      getData(props.dateRange);
    }
  }, [props.dateFilter.infimum, props.dateFilter.supremum, props.timezone]);

  const getData = dateRange => {
    pageRequest.current = {
      startDate: dateRange.infimum,
      endDate: dateRange.supremum,
    };
    props.onLoadOverView(pageRequest.current);
    props.getAdherenceOnESIAdmin(pageRequest.current);
    props.getCapEvolution(pageRequest.current);
  };

  const doctorHasData = () => {
    const { totalTrialCount, totalPatientCount, patientsHaveData } = props;

    return !(totalTrialCount === 0 || totalPatientCount === 0 || patientsHaveData !== true);
  };

  const filtterCapEvolutionData = aa => {
    for (let i = 0; i < aa.length; i += 1) {
      if (aa.length > 11) {
        aa.splice(i + 1, 1);
      }
    }
    if (aa.length > 11) {
      filtterCapEvolutionData(aa);
    }
  };

  const {
    devicesUsageData,
    adherence7days = {},
    totalTrialCount,
    totalPatientCount,
    notificationIsLoading,
    onNewTrial,
    onNewPatient,
    onNewAdmin,
    patientsHaveData,
    isLoading,
    adminDashboard,
    dateRange,
  } = props;
  const adherence = adminDashboard?.adherence;

  const adherenceData = {
    adherence: adherence?.total_adherence,
    complied: adherence?.medication_taken,
    id: 0,
    missed: adherence?.medication_missed,
    skipped: 0,
    total: 0,
  };

  const evolution = adminDashboard?.capEvolution?.data?.map(d => ({
    date: moment(d.date).format(DATE_FORMAT_MONTH_NAME),
    count: d.count,
  }));

  if (notificationIsLoading) {
    return <WithLoader isLoading />;
  }
  const pageHeader = (
    <PageHeader
      left={`${Strings.welcome}, ${props.userFullname}`}
      right={
        <React.Fragment>
          <DashboardEditModeButton dashboardId={DASHBOARD_ID} />
          <DashboardFilter dashboardId={DASHBOARD_ID} />
        </React.Fragment>
      }
    />
  );

  if (isDoctor() && isLoading === false && !doctorHasData() && !isEsi()) {
    const isNoTrials = totalTrialCount === 0;
    const isNoPatients = totalPatientCount === 0;

    return (
      <React.Fragment>
        {pageHeader}
        {isNoTrials === false && isNoPatients === false && patientsHaveData !== true && (
          <div className="no-data-yet">{Strings.dashboardNoDataYet}</div>
        )}
        <div className="dashboard-page-content">
          <InfoBox
            caption={Strings.createYourFirstStudy}
            buttonText={Strings.create}
            onClick={onNewTrial}
            isGlowing={isNoTrials}
          >
            {Strings.createYourFirstStudy_p1}
            <a onClick={onNewTrial}>{Strings.create}</a>
            {Strings.createYourFirstStudy_p2}
          </InfoBox>
          <InfoBox
            caption={Strings.addAPatient}
            buttonText={Strings.add}
            onClick={onNewPatient}
            isGlowing={!isNoTrials && isNoPatients}
          >
            {isNoTrials ? (
              <React.Fragment>
                {Strings.addAPatient_p1}
                <a onClick={onNewPatient}>{Strings.add}</a>
                {Strings.addAPatient_p2}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {Strings.addAPatient_2_p1}
                <a onClick={onNewPatient}>{Strings.patients}</a>
                {Strings.addAPatient_2_p2}
                <a onClick={onNewPatient}>{Strings.add}</a>
                {Strings.addAPatient_2_p3}
              </React.Fragment>
            )}
          </InfoBox>
          <InfoBox caption={Strings.inviteAnAdmin} buttonText={Strings.invite} onClick={onNewAdmin}>
            {Strings.inviteAnAdmin_p1}
            <a onClick={onNewAdmin}>{Strings.invite}</a>
            {Strings.inviteAnAdmin_p2}
          </InfoBox>
        </div>
      </React.Fragment>
    );
  }

  const sliderMinDate = moment()
    .subtract(2, 'months')
    .startOf('day')
    .toDate();
  const sliderMaxDate = moment()
    .endOf('day')
    .toDate();

  const MissedDosesWidget = {
    ...MissedDosesMetadata,
    render: !adminDashboard.loading ? (
      <div className="stats-grid missed-doses">
        <label>{Strings.adminDashboard.missed2ConsecutiveDoses}</label>
        <span className="labelValue red">{adherence?.missed_two_consecutive_dosage ?? 0}</span>
        <div className="horizontal-rule" />
        <div />
        <label>{Strings.adminDashboard.missed5ConsecutiveDoses}</label>
        <span className="labelValue red">{adherence?.missed_five_consecutive_dosage ?? 0}</span>
      </div>
    ) : (
      <></>
    ),
    noPadding: true,
    greyBackground: true,
  };
  const AdherenceStatsWidget = {
    ...AdherenceStatsMetadata,
    render: (
      <div className="stats-grid">
        <div className="sub-box">
          <label>{Strings.adminDashboard.numberOfParticipants}</label>
          <div className="labelValue blue">{adherence?.number_of_participants ?? 0}</div>
        </div>
        <div className="sub-box">
          <label>{Strings.adminDashboard.numberOfActivatedCaps}</label>
          <div className="labelValue blue">{adherence?.number_of_activated_caps ?? 0}</div>
        </div>
        <div className="sub-box">
          <label>{Strings.adminDashboard.medicationTaken}</label>
          <div className="labelValue green">{adherence?.medication_taken ?? 0}</div>
        </div>
        <div className="sub-box">
          <label>{Strings.adminDashboard.medicationMissed}</label>
          <div className="labelValue red">{adherence?.medication_missed ?? 0}</div>
        </div>
      </div>
    ),
    noPadding: true,
    greyBackground: true,
  };
  const TotalAdherenceWidget = {
    ...TotalAdherenceMetadata,
    render: (
      <React.Fragment>
        <div className="boxGraphWithTitle-title">{Strings.totalAdherence}</div>
        <AdherenceGraph graphData={adherenceData} />
      </React.Fragment>
    ),
    greyBackground: true,
  };

  const EvolutionOfCapActivationWidget = {
    ...EvolutionOfCapActivationMetadata,
    render: (
      <React.Fragment>
        <div className="boxGraphWithTitle-title">{Strings.adminDashboard.evolutionOfCapActivations}</div>
        <div className="graphDoctorDashboardContainer">
          <CapActivations graphData={evolution} />
        </div>
      </React.Fragment>
    ),
    greyBackground: true,
  };

  const ActiveMonthlyPillboxUsersMetadata = getActiveMonthlyPillboxUsersMetadata(false, false);
  const ActiveMonthlyPillboxUsersWidget = {
    ...ActiveMonthlyPillboxUsersMetadata,
    noPadding: true,
    noBorder: true,
    configuration: props.dashboardSettingsFeature ? ActiveMonthlyPillboxUsersMetadata.configuration : undefined,
    render: <EsiActivePillboxUsers />,
  };

  const EsiPatientsStateMetadata = getEsiPatientsStateMetadata(false, false);
  const EsiPatientsStateWidget = {
    ...EsiPatientsStateMetadata,
    noPadding: true,
    render: <EsiPatientStats colorPalette={props.colorPalette} />,
  };

  const widgets = [
    MissedDosesWidget,
    AdherenceStatsWidget,
    TotalAdherenceWidget,
    EvolutionOfCapActivationWidget,
    ActiveMonthlyPillboxUsersWidget,
    EsiPatientsStateWidget,
  ];

  return (
    <React.Fragment>
      {pageHeader}

      {hasAnyPermissionInGroup(PERMISSION_GROUPS.DASHBOARD_ADHERENCE) && isDoctor() && (
        <SliderPicker min={sliderMinDate} max={sliderMaxDate} />
      )}
      {adherenceData && adherence && evolution && evolution.length > 0 && (
        <DashboardLayout
          dashboardId={DASHBOARD_ID}
          widgets={widgets}
          defaultLayouts={DefaultLayouts}
          breakpoints={{ l: 1100, m: 800, s: 0 }}
          rowHeight={60}
        />
      )}
      {/* When we need these 2 plots, they shoud be rewritten using new PieChart and added as widgets */}
      {/* <div className="plot-row dashboard-plot-row">
        <div className="column">
          <HistogramAdherencePlot data={adherence7days} isLoading={props.isLoading} caption="Adherence Last 7 Days" />
        </div>
        <div className="column">
          <DevicesPlot data={devicesUsageData} isLoading={props.isLoading} />
        </div>
      </div> */}
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  const { patients, eventLocations, trials } = state.entities;
  const { dashboard } = state.esi;
  const allLocations = Object.values(eventLocations.objects || {});
  const userId = state.auth.id;
  const userFullname = state.auth.profile ? `${state.auth.profile.first_name} ${state.auth.profile.last_name}` : '';
  const defaultLocation = allLocations.reduce(
    (data, notification) => {
      data.lat += notification.location.lat / (allLocations.length || 1); // eslint-disable-line no-param-reassign
      data.lng += notification.location.lng / (allLocations.length || 1); // eslint-disable-line no-param-reassign

      return data;
    },
    {
      lat: 0,
      lng: 0,
    },
  );
  if (allLocations.length === 0) {
    defaultLocation.lat = LocationUSCenter.lat;
    defaultLocation.lng = LocationUSCenter.lng;
  }
  const { dateFilter } = state.filter;
  const { adherenceData, devicesUsageData, adherence7days, preparing, loading } = state.entities.trials.overview;
  const { hasNotifications } = state.entities.patientNotifications;

  const notificationIsLoading = state.entities.patientNotifications.isLoading;

  let totalPatientCount = state.entities.trials.overview.totalPatientCount;
  let totalTrialCount = trials.trials ? trials.trials.length : 0;

  if (isNaN(totalPatientCount)) totalPatientCount = 0; // eslint-disable-line no-restricted-globals

  if (isNaN(totalTrialCount)) totalTrialCount = 0; // eslint-disable-line no-restricted-globals

  const patientsHaveData = hasNotifications;

  totalTrialCount = Math.max(totalTrialCount, trials.overview.totalTrialCount);

  const dateRange = dateFilter || {
    infimum: moment()
      .subtract(6, 'day')
      .toDate(),
    supremum: moment().toDate(),
  };

  let normAdherence7days;
  if (adherence7days) {
    normAdherence7days = normalize(adherence7days, [last7DaysAdherenceSchema]);
  } else {
    normAdherence7days = { entities: { last7DayAdherence: {} } };
  }

  const labFeatures = state.labState.features;

  return {
    dateRange,
    adherenceData,
    devicesUsageData,
    totalTrialCount,
    totalPatientCount,
    adherence7days: normAdherence7days.entities.last7DayAdherence,
    isPreparing: preparing,
    isLoading: !(loading === false && notificationIsLoading === false),
    notificationIsLoading,
    doctorFullName: state.auth.profile ? state.auth.profile.full_name : undefined,
    trials,
    patients,
    eventLocations,
    defaultLocation,
    dateFilter: state.filter.dateFilter,
    userId,
    userFullname,
    patientsHaveData,
    adminDashboard: dashboard,
    timezone: state.auth?.profile?.preferences?.timezone,
    dashboardSettingsFeature: labFeatures.find(f => f.name === 'Dashboard configuration')?.selected,
    colorPalette:
      ColorPalette[state.dashboardState.configuration?.allDashboards?.colorPalette] || Object.values(ColorPalette)[0],
  };
};

const mapDispatchToProps = dispatch => ({
  getAdherenceOnESIAdmin: pageRequest => dispatch(actions.getAdherence(pageRequest)),
  getCapEvolution: pageRequest => dispatch(actions.getCapEvolution(pageRequest)),
  onNavigate: path => dispatch(push(path)),
  onLoadOverView: trialRequest => dispatch(trialsAction.actionOverView(trialRequest)),
  onLoadLocations: (start, end) => Promise.all([dispatch(patientAction.actionEventLocations(start, end))]),
  onChangeDateFilter: filterData => dispatch(changeFilter('dateFilter', filterData)),
  clearEntities: () =>
    dispatch(clearEntitiesAction(['patients', 'eventLocations', 'trials', 'memberships', 'doctors'])),
  downloadPatientsData: () => dispatch(patientAction.downloadPatientsData()),
  onNewTrial: () =>
    dispatch(
      openModalAction(NEW_TRIAL, {
        action: trialsAction.actionNew,
        actionType: NEW_TRIAL,
      }),
    ),
  onNewPatient: () =>
    dispatch(
      openModalAction(isEsi() ? 'add-esi-patient' : 'invite-patient', {
        action: invitesAction.sendInvite,
        actionType: SEND_INVITE,
      }),
    ),
  patientsHaveNotifications: () => dispatch(patientAction.actionNotificationsExists()),
  onNewAdmin: () =>
    dispatch(
      openModalAction('new-admin', {
        action: adminsAction.sendInvite,
        actionType: POST_ADMIN_INVITE,
      }),
    ),
});

Dashboard.defaultProps = {
  dateFilter: {
    infimum: moment()
      .subtract(6, 'day')
      .toDate(),
    supremum: moment().toDate(),
  },
};

Dashboard.propTypes = {
  adherence7days: PropTypes.object,
  adherenceData: PropTypes.object,
  adminDashboard: PropTypes.shape({
    adherence: PropTypes.shape({
      medication_missed: PropTypes.number,
      medication_taken: PropTypes.number,
      missed_five_consecutive_dosage: PropTypes.number,
      missed_two_consecutive_dosage: PropTypes.number,
      number_of_activated_caps: PropTypes.number,
      number_of_participants: PropTypes.number,
      total_adherence: PropTypes.any,
    }),
    capEvolution: PropTypes.shape({ data: PropTypes.shape({ map: PropTypes.func }) }),
    loading: PropTypes.any,
  }),
  clearEntities: PropTypes.func,
  dateFilter: PropTypes.object,
  dateRange: PropTypes.object,
  devicesUsageData: PropTypes.any,
  downloadPatientsData: PropTypes.func,
  getAdherenceOnESIAdmin: PropTypes.func,
  getCapEvolution: PropTypes.func,
  infimum: PropTypes.func,
  isLoading: PropTypes.bool,
  notificationIsLoading: PropTypes.bool,
  onLoadData: PropTypes.func,
  onLoadOverView: PropTypes.func,
  onNavigate: PropTypes.func,
  onNewAdmin: PropTypes.func,
  onNewPatient: PropTypes.func,
  onNewTrial: PropTypes.func,
  patientsHaveData: PropTypes.bool,
  patientsHaveNotifications: PropTypes.func,
  supremum: PropTypes.func,
  totalPatientCount: PropTypes.number,
  totalTrialCount: PropTypes.number,
  userFullname: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
