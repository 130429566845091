import {
  WIDGET_GET_PATIENTS_WITH_NEW_NOTES,
  WIDGET_GET_PATIENTS_WITH_NEW_NOTES_RESULT,
  WIDGET_GET_PATIENTS_WITH_NEW_NOTES_ERROR,
} from './constants';

export const widgetPatientsWithNewNotesReducer = (state = {}, action) => {
  switch (action.type) {
    case WIDGET_GET_PATIENTS_WITH_NEW_NOTES:
      return {
        ...state,
        isLoading: true,
        isError: false,
        patientsWithNewNotes: undefined,
      };
    case WIDGET_GET_PATIENTS_WITH_NEW_NOTES_RESULT:
      return {
        ...state,
        isLoading: false,
        isError: false,
        patientsWithNewNotes: action.response.unique_patients_with_notes,
      };

    case WIDGET_GET_PATIENTS_WITH_NEW_NOTES_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        patientsWithNewNotes: undefined,
      };
    }
    default:
      return state;
  }
};
