import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import downloadIos from '../../images/download-app-ios.png';
import { IosAppLink } from '../../constants';
import Strings from '../../Strings';

const DownloadAndroid = () => (
  <a id="android" href="https://play.google.com/store/apps/details?id=com.mydosesmart">
    <img
      alt="Get it on Google Play"
      src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
    />
  </a>
);

class UserActivatedPage extends PureComponent {
  static propTypes = {
    authenticated: PropTypes.bool,
    onNotAuthenticated: PropTypes.func,
  };

  UNSAFE_componentWillMount() {
    if (!this.props.authenticated) {
      this.props.onNotAuthenticated();
    }
  }

  UNSAFE_componentWillUpdate(newProps) {
    if (!newProps.authenticated) {
      this.props.onNotAuthenticated();
    }
  }

  render() {
    return (
      <div className={`activated-page ${isMobile ? 'mobile' : ''}`}>
        <div className="image" />
        <h1>Congratulations</h1>
        {!isMobile ? (
          <div className="caption">{Strings.yourDSAccountActivatedText}</div>
        ) : (
          <div className="caption">
            {Strings.yourDSAccActivatedText}
            <br />
            {Strings.checkYourInbox}
          </div>
        )}
        {isMobile ? (
          <div className="download-container">
            <a id="ios" href={IosAppLink}>
              <img src={downloadIos} alt="iOS" />
            </a>
            <DownloadAndroid />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { authenticated: state.auth.authenticated };
};

const mapDispatchToProps = dispatch => ({
  onNotAuthenticated: () => dispatch(push('/login')),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserActivatedPage);
