import { DEVICE_DETAILS, DEVICE_DETAILS_CLEAR, DEVICE_DETAILS_RESULT } from './constants';

export const deviceCapDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case DEVICE_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case DEVICE_DETAILS_RESULT:
      return {
        ...state,
        isLoading: false,
        data: action.response,
      };
    case DEVICE_DETAILS_CLEAR:
      return {
        ...state,
        isLoading: false,
        data: undefined,
      };
    default:
      return state;
  }
};
