import { API_CALL } from '../middleware/api';
import { LIST_INVITES, SEND_INVITE } from './action-types';

const invitesAction = {
  actionList: () => ({
    [API_CALL]: { endpoint: '/groups/analytics/patients/invites/' },

    type: LIST_INVITES,
  }),
  sendInvite: data => ({
    [API_CALL]: {
      endpoint: '/groups/patients/invite/',
      method: 'post',
      data: {
        patient: { email: data.email },
        inviteToDefaultGroup: true,
      },
    },
    type: SEND_INVITE,
  }),
};

export default invitesAction;
