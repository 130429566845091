import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Keyboard, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import './style.scss';

function Carousel(props) {
  const swiperRef = React.useRef();
  const [navigationButtonsNeeded, setNavigationButtonsNeeded] = React.useState(false);

  let activeIndex = 0;
  props.children.map((child, i) => {
    if (i !== 0 && child.key === props.activeTabName) {
      activeIndex = i - 1;
      if (!swiperRef.current) {
        return;
      }
      const slideOffset = Math.round(swiperRef.current?.visibleSlidesIndexes.length / 2) - 1;
      swiperRef.current?.slideTo(Math.max(0, i - slideOffset));
    }
    return child;
  });

  return (
    <Swiper
      onSwiper={swiper => {
        swiperRef.current = swiper;
        if (swiper.visibleSlides?.length < swiper.slides.length) {
          setNavigationButtonsNeeded(true);
        }
      }}
      keyboard={{
        enabled: true,
      }}
      navigation={true}
      modules={[Keyboard, Navigation]}
      spaceBetween={20}
      slidesPerView={'auto'}
      rewind
      watchSlidesProgress
      className={`${navigationButtonsNeeded ? 'with-navigation-buttons' : ''}`}
    >
      {props.children.map((c, i) => (
        <SwiperSlide
          key={i}
          onFocus={() => {
            if (!swiperRef.current) {
              return;
            }
            swiperRef.current?.slideTo(i);
          }}
        >
          {c}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Carousel;
