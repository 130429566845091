import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone/moment-timezone';

import TableWithLocalPagination, { Column } from '../../../../../../containers/Table/TableWithLocalPagination';
import { actions, PLOT_DATA_RESULT } from '../actions';
import Strings from '../../../../../../Strings';
import { DATE_MONTH_DAY_YEAR, TIME_FORMAT_12_UPPERCASE } from '../../../../../../constants';
import { formatTimeSpanHHMM } from '../../../../../../utils';
import { PageHeader } from '../../../../../../components/PageHeader';
import { Button } from '../../../../../../components/PageHeader/Button';
import { LengthUnitConverter, LENGTH_UNITS, roundToDecimal } from '../../../../../../utils/unitConverters';

export const movementColumns = [
  <Column key="date" title={Strings.date} value={e => moment.unix(e.timestamp).format(DATE_MONTH_DAY_YEAR)} />,
  <Column key="time" title={Strings.time} value={e => moment.unix(e.timestamp).format(TIME_FORMAT_12_UPPERCASE)} />,
  // let round value to two numbers after coma
  <Column
    key="calories"
    title={Strings.calories}
    value={e => `${Math.round((e.burn_calories + Number.EPSILON) * 100) / 100} kcal`}
  />,
  <Column
    key="distance"
    title={Strings.distance}
    value={e => `${roundToDecimal(LengthUnitConverter(e.distance, LENGTH_UNITS.m, LENGTH_UNITS.mi), 2)} MI`}
  />,
  <Column key="steps" title={Strings.steps} value={e => e.steps} />,
  <Column key="exercise_time" title={Strings.exerciseTime} value={e => formatTimeSpanHHMM(e.exercise_time)} />,
  <Column key="exercise_intensity" title={Strings.exerciseIntensity} value={e => e.exercise_intensity} />,
  <Column key="Rest HR" title={Strings.restHR} value={e => (e.rest_hr_available ? `${e.rest_heart_rate} bpm` : '-')} />,
];

class MovementReport extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };

    this.dataToShow = [];
    this.refreshEnable = true;
  }

  componentDidMount() {
    this.getPlotData();
  }

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 10000);
    this.getPlotData();
  };

  getPlotData = () => {
    this.setState({ isLoading: true });
    this.dataToShow = [];

    const { deviceId, startDate, endDate } = this.props;

    const request = {
      deviceId,
      dataType: 'movement_report',
      startDate,
      endDate,
      hubId: this.props.hubId,
      kitId: this.props.kitId,
      patientId: this.props.patientId,
    };

    this.props.getPlotData(request).then(resp => {
      if (resp.type === PLOT_DATA_RESULT) {
        const events = resp.response;
        this.dataToShow = events;
      }
      this.setState({ isLoading: false });
    });
  };

  componentDidUpdate(prevProps) {
    const { deviceId, startDate, endDate } = this.props;

    if (prevProps.deviceId !== deviceId || prevProps.startDate !== startDate || prevProps.endDate !== endDate)
      this.getPlotData();
  }

  getTable = () => {
    const { isLoading } = this.state;

    // data in table should be in reverse order than graph, last events on top
    return (
      <TableWithLocalPagination
        isLoading={isLoading}
        data={this.dataToShow.slice().reverse()}
        columns={movementColumns}
      />
    );
  };

  render() {
    return <React.Fragment>{this.getTable()}</React.Fragment>;
  }

  static propTypes = {
    eventType: PropTypes.string.isRequired,
    deviceId: PropTypes.string.isRequired,
    patientId: PropTypes.string,
    hubId: PropTypes.string,
    kitId: PropTypes.string,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    getPlotData: PropTypes.func.isRequired,
  };
}

const mapDispatchToProps = dispatch => ({
  getPlotData: pageRequest => dispatch(actions.getPlotData(pageRequest)),
});

export default connect(null, mapDispatchToProps)(MovementReport);
