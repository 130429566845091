import { API_CALL } from 'middleware/api';
import { ESI_PATIENT_VERIFY } from './constants';

const method = 'POST';
const endpoint = '/v2/field-verify/';

const functionVerify = 'verify';
const functionNew = 'new';
const functionResend = 'resend';

const flavorEmail = 'email';
const flavorPhone = 'sms';

const makeAction = (field, flavor, func) => ({
  [API_CALL]: {
    method,
    endpoint,
    data: {
      function: func,
      flavor,
      field,
    },
  },
  type: ESI_PATIENT_VERIFY + '-' + func + '-' + flavor,
});

export const actions = {
  phoneVerify: field => makeAction(field, flavorPhone, functionVerify),
  phoneNew: field => makeAction(field, flavorPhone, functionNew),
  phoneResend: field => makeAction(field, flavorPhone, functionResend),
  emailVerify: field => makeAction(field, flavorEmail, functionVerify),
  emailNew: field => makeAction(field, flavorEmail, functionNew),
  emailResend: field => makeAction(field, flavorEmail, functionResend),
};
