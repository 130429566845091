import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { scaleLinear } from 'd3-scale';
import { drag as d3drag } from 'd3-drag';
import { select, event as currentEvent } from 'd3-selection';
import moment from 'moment-timezone';

import { changeFilter } from '../../actions';

class SliderD3 {
  static propTypes = {
    dateFilter: PropTypes.object,
    onChangeDateFilter: PropTypes.func,
  };

  dragStartX = 0;

  create = (node, props, state) => {
    this.scale = scaleLinear().domain([props.min, props.max]);
    this.brc = node.getBoundingClientRect();
    this.props = props;
    this.state = state;

    const responsiveness = svg => {
      const self = this;
      window.addEventListener('resize', () => {
        this.brc = svg.node().getBoundingClientRect();
        svg.call(self.render);
      });
    };

    const s = select(node);

    s.selectAll('div.slider')
      .data([true])
      .enter()
      .append('div')
      .attr('class', 'slider')
      .append('div')
      .attr('class', 'background')
      .append('div')
      .attr('class', 'months');

    s.select('div.slider')
      .append('div')
      .attr('class', 'months');

    const months = s.select('div.months').selectAll('div.month');
    const { min: minDate, max: maxDate } = this.props;
    const { width } = this.brc;

    const monthsScale = scaleLinear().domain([minDate, maxDate]);
    monthsScale.rangeRound([0, width]).clamp(true);

    const monthsData = [];

    const monthsDifference =
      maxDate.getMonth() + maxDate.getFullYear() * 12 - (minDate.getMonth() + minDate.getFullYear() * 12);
    const monthsDistance = monthsDifference > 12 ? Math.ceil(monthsDifference / 12) : 1;

    const current = new Date(+minDate);

    for (current.setDate(1); current <= maxDate; current.setMonth(current.getMonth() + monthsDistance)) {
      const month = moment(current).format('MMM');

      const startOfMonth = new Date(+current);
      startOfMonth.setDate(1);

      const left = this.scale(startOfMonth);

      if (left >= 0) {
        monthsData.push({
          label: month,
          left,
        });
      }
    }

    months
      .data(monthsData)
      .enter()
      .append('div')
      .attr('class', 'month')
      .style('left', d => `calc(${d.left * 100}% - 16px)`)
      .html(d => d.label);

    const bkg = s.select('div.slider > .background');
    bkg.call(responsiveness);

    return bkg.call(this.render);
  };

  update = (node, props, state) => {
    this.scale = scaleLinear().domain([props.min, props.max]);
    this.brc = node.getBoundingClientRect();
    this.props = props;
    this.state = state;

    const s = select(node);
    const bkg = s.select('div.slider > .background');
    return bkg.call(this.render);
  };

  render = s => {
    const { width } = this.brc;
    const { infimum, supremum } = this.state;
    this.scale.rangeRound([0, width]).clamp(true);

    const [min, max] = this.scale.domain();

    const data = [
      {
        width: this.scale(infimum) - this.scale(min),
        color: 'gray',
      },
      {
        width: this.scale(supremum) - this.scale(infimum),
        color: 'green',
      },
      {
        width: this.scale(max) - this.scale(supremum),
        color: 'gray',
      },
    ];

    const lines = s.selectAll('div.line').data(data);
    lines
      .enter()
      .append('div')
      .attr('class', d => ['line', d.color].join(' '))
      .style('flex-basis', d => `${d.width}px`);

    lines.style('flex-basis', d => `${d.width}px`);

    const dataTicks = [
      {
        key: 'infimum',
        value: this.state.infimum,
      },
      {
        key: 'supremum',
        value: this.state.supremum,
      },
    ];

    const drag = d3drag()
      .on('start', this.handleDragStart)
      .on('drag', this.handleDrag)
      .on('end', this.handleDragEnd);

    const handleInfimum = s
      .selectAll('div.tick.infimum')
      .data([dataTicks[0]])
      .attr('data-tooltip-id', 'tooltip')
      .attr('data-tooltip-content', moment(dataTicks[0].value).format('MM/DD/YYYY'))
      .enter()
      .insert('div', 'div.line.green')
      .attr('class', d => ['tick', d.key].join(' '))
      .attr('data-tooltip-id', 'tooltip')
      .attr('data-tooltip-content', moment(dataTicks[0].value).format('MM/DD/YYYY'))
      .call(drag);
    handleInfimum.call(drag);

    const handleSupremum = s
      .selectAll('div.tick.supremum')
      .data([dataTicks[1]])
      .attr('data-tooltip-id', 'tooltip')
      .attr('data-tooltip-content', moment(dataTicks[1].value).format('MM/DD/YYYY'))
      .enter()
      .insert('div', 'div.line.green + *')
      .attr('class', d => ['tick', d.key].join(' '))
      .attr('data-tooltip-id', 'tooltip')
      .attr('data-tooltip-content', moment(dataTicks[1].value).format('MM/DD/YYYY'))
      .call(drag);
    handleSupremum.call(drag);
  };

  handlerDragEvent(d) {
    // We take current value, get it scale (i.e pixels) value
    // then we add pixel delta between current event.x and drag start x
    // and then convert it back to js time
    let value = this.scale.invert(this.scale(d.value) + currentEvent.x - this.dragStartX);

    let momentDate;
    if (d.key === 'infimum') {
      value = Math.min(this.state.supremum, value);
      momentDate = moment(value).startOf('day');
    } else {
      value = Math.max(this.state.infimum, value);
      momentDate = moment(value).endOf('day');
    }

    return momentDate;
  }

  handleDragStart = () => {
    if (this.props.readOnly) {
      return;
    }
    // currentEvent.dx in the drag event didn't work properly
    // so we need to remember start drag position manually
    this.dragStartX = currentEvent.x;
  };

  handleDrag = d => {
    if (this.props.readOnly) {
      return;
    }
    this.props.onDrag({ [d.key]: this.handlerDragEvent(d).toDate() });
  };

  handleDragEnd = d => {
    if (this.props.readOnly) {
      return;
    }
    this.props.onDragEnd({ [d.key]: this.handlerDragEvent(d).toDate() });
  };
}

class Slider extends PureComponent {
  static propTypes = {
    max: PropTypes.instanceOf(Date).isRequired,
    min: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func,
    onChangeEnd: PropTypes.func,
    minRangeDays: PropTypes.number,
    dateFilter: PropTypes.object,
    onChangeDateFilter: PropTypes.func,
    readOnly: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      infimum: this.props.dateFilter.infimum || this.props.min,
      supremum: this.props.dateFilter.supremum || this.props.max,
    };
    this.handleDrag = this.handleDrag.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
  }

  componentDidMount() {
    this.node = ReactDOM.findDOMNode(this); // eslint-disable-line react/no-find-dom-node
    this.slider = new SliderD3();
    this.slider.create(
      this.node,
      {
        ...this.props,
        onDrag: this.handleDrag,
        onDragEnd: this.handleEnd,
      },
      this.state,
    );

    if (this.props.dateFilter && this.checkMinRange(this.props.dateFilter)) {
      this.setMinRange(this.props.dateFilter);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.dateFilter &&
      nextProps.dateFilter.infimum &&
      nextProps.dateFilter.supremum &&
      (this.state.infimum.getTime() !== nextProps.dateFilter.infimum.getTime() ||
        this.state.supremum.getTime() !== nextProps.dateFilter.supremum.getTime())
    ) {
      if (this.checkMinRange(this.props.dateFilter)) {
        this.setMinRange(nextProps.dateFilter);
      } else {
        this.setState({
          infimum: nextProps.dateFilter.infimum,
          supremum: nextProps.dateFilter.supremum,
        });
      }
    }
  }

  UNSAFE_componentWillUpdate(newProps, nextState) {
    this.slider.update(
      this.node,
      {
        ...newProps,
        onDrag: this.handleDrag,
        onDragEnd: this.handleEnd,
      },
      nextState,
    );

    return true;
  }

  setMinRange = dateFilter => {
    const today = new Date();
    let { infimum, supremum } = dateFilter;
    if (today.getDate() - dateFilter.supremum.getDate() < this.props.minRangeDays) {
      const d = new Date(dateFilter.supremum);
      d.setDate(d.getDate() - this.props.minRangeDays);
      infimum = d;
    } else {
      const d = new Date(dateFilter.infimum);
      d.setDate(d.getDate() + this.props.minRangeDays);
      supremum = d;
    }

    this.props.onChangeDateFilter({
      infimum,
      supremum,
    });

    this.setState({
      infimum,
      supremum,
    });
  };

  checkMinRange = dateFilter => {
    return (
      this.props.minRangeDays &&
      Math.ceil(Math.abs(dateFilter.supremum.getTime() - dateFilter.infimum.getTime()) / (1000 * 3600 * 24)) <
        this.props.minRangeDays
    );
  };

  handleDrag(data) {
    this.setState(data);

    if (this.props.onChange) {
      this.props.onChange({
        ...this.props.dateFilter,
        ...data,
      });
    }
  }

  handleEnd(data) {
    this.setState(data);
    this.props.onChangeDateFilter(data);

    if (this.props.onChangeEnd) {
      this.props.onChangeEnd({
        ...this.props.dateFilter,
        ...data,
      });
    }
  }

  render() {
    return <div className={`slider-container ${this.props.readOnly ? 'disabled' : ''}`} />;
  }
}

const mapStateToProps = state => ({ dateFilter: state.filter.dateFilter });

const mapDispatchToProps = dispatch => ({
  onChangeDateFilter: filterData => dispatch(changeFilter('dateFilter', filterData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
