import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function SideList(props) {
  const [show, setShow] = useState(false);
  const [justOpen, setJustOpen] = useState(false);

  let hovered = false;

  useEffect(() => {
    // check if just opened list is "me" list, if not, close myself
    if (props.lastOpenedChild !== props.title) {
      setShow(false);
    }
  }, [props.lastOpenedChild]);

  const toggle = () => {
    if (!props.disabled && !justOpen) {
      if (!show) props.notifWhoOpened(props.title);
      setShow(!show);
    }
  };

  const showMe = () => {
    if (!props.disabled && hovered) {
      props.notifWhoOpened(props.title);
      setShow(true);
      setJustOpen(true);
      setTimeout(() => {
        setJustOpen(false);
      }, 300);
    }
  };

  const onHovered = () => {
    hovered = true;
    setTimeout(() => {
      showMe();
    }, 300);
  };

  const sidelist = <div className="side-list">{props.children}</div>;

  const header = (
    <div
      className="side-list-main-header"
      onClick={toggle}
      onMouseEnter={onHovered}
      onMouseLeave={() => {
        hovered = false;
      }}
    >
      <div className={!props.disabled ? 'enabled' : 'disabled'}>
        <div className={show ? 'active' : 'non-active'}>
          <div className="arrow" />
          <div className="title">{props.title}</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="side-list-container">
      {header}
      {show && sidelist}
    </div>
  );
}

SideList.propTypes = {
  lastOpenedChild: PropTypes.string.isRequired,
  notifWhoOpened: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  disabled: PropTypes.any,
};

export default SideList;
