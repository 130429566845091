export const GET_INBOX_MESSAGES = 'get-inbox-messages';
export const GET_INBOX_MESSAGES_RESULT = `${GET_INBOX_MESSAGES}/result`;
export const GET_INBOX_MESSAGES_ERROR = `${GET_INBOX_MESSAGES}/error`;

export const GET_INBOX_ARCHIVED = 'get-archived-messages';
export const GET_INBOX_ARCHIVED_RESULT = `${GET_INBOX_ARCHIVED}/result`;
export const GET_INBOX_ARCHIVED_ERROR = `${GET_INBOX_ARCHIVED}/error`;

export const GET_MESSAGE_DETAILS = 'get-message-details';
export const GET_MESSAGE_DETAILS_RESULT = `${GET_MESSAGE_DETAILS}/result`;
export const GET_MESSAGE_DETAILS_ERROR = `${GET_MESSAGE_DETAILS}/error`;

export const TOGGLE_ARCHIVED = 'toggle-archived-messages';
export const TOGGLE_ARCHIVED_RESULT = `${TOGGLE_ARCHIVED}/result`;
export const TOGGLE_ARCHIVED_ERROR = `${TOGGLE_ARCHIVED}/error`;

export const TOGGLE_READ = 'toggle-read-messages';
export const TOGGLE_READ_RESULT = `${TOGGLE_READ}/result`;
export const TOGGLE_READ_ERROR = `${TOGGLE_READ}/error`;

export const OUTBOUND = 'outbound';
export const INBOUND = 'inbound';

export const SEND_MESSAGE = 'send-message';
export const SEND_MESSAGE_RESULT = `${SEND_MESSAGE}/result`;
export const SEND_MESSAGE_ERROR = `${SEND_MESSAGE}/error`;

export const SEND_SMS = 'send-sms';
export const SEND_SMS_RESULT = `${SEND_SMS}/result`;
export const SEND_SMS_ERROR = `${SEND_SMS}/error`;

export const CLEAR_DATA = 'clear-data';
export const CLEAR_MESSAGES = 'clear-messages-data';

export const GET_PREFAB_REPLIES = 'get-prefab-replies';
export const GET_PREFAB_REPLIES_RESULT = `${GET_PREFAB_REPLIES}/result`;
export const GET_PREFAB_REPLIES_ERROR = `${GET_PREFAB_REPLIES}/error`;

export const ADD_PREFAB_REPLY = 'add-prefab-reply';
export const ADD_PREFAB_REPLY_RESULT = `${ADD_PREFAB_REPLY}/result`;
export const ADD_PREFAB_REPLY_ERROR = `${ADD_PREFAB_REPLY}/error`;

export const EDIT_PREFAB_REPLY = 'edit-prefab-reply';
export const EDIT_PREFAB_REPLY_RESULT = `${EDIT_PREFAB_REPLY}/result`;
export const EDIT_PREFAB_REPLY_ERROR = `${EDIT_PREFAB_REPLY}/error`;

export const DELETE_PREFAB_REPLY = 'delete-prefab-reply';
export const DELETE_PREFAB_REPLY_RESULT = `${DELETE_PREFAB_REPLY}/result`;
export const DELETE_PREFAB_REPLY_ERROR = `${DELETE_PREFAB_REPLY}/error`;
