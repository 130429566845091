import React, { PureComponent } from 'react';

import styles from '../Input.module.scss';
import { PhoneInput } from '../PhoneInput';
import { MODE_TWO_COLUMNS } from '../../../constants';

export class PhoneInputWithLabel extends PureComponent {
  render() {
    const { title, error, mode = MODE_TWO_COLUMNS, linkRef, ...props } = this.props;

    return (
      <div className={mode === MODE_TWO_COLUMNS ? styles.twoColumns : styles.oneColumn}>
        <div className={styles.labelTitle}>{title}</div>
        <div>
          <PhoneInput ref={linkRef} {...props} />
        </div>
        {error ? <div className={styles.error}>{error}</div> : null}
      </div>
    );
  }
}
