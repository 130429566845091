import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ButtonRow } from 'components/Login/ButtonRow/ButtonRow';
import { Button, BUTTON_TYPE_WHITE } from 'components/Buttons/Button';
import { Text } from 'components/Login/Text/Text';

import Strings from '../../../../Strings';
import { BUTTON_TYPE_DARK_BLUE } from '../../../../components/Buttons/Button';
import video from '../../../../video/howtouse.mp4';

export class Step6_Video extends PureComponent {
  refVideo = React.createRef();
  state = {
    isShow: false,
    watched: false,
  };

  onFormSubmit = event => {
    event.preventDefault();
    this.openVideoPopup();
  };

  openVideoPopup = () => {
    this.setState({
      isShow: true,
      watched: true,
    });
  };

  closeVideo = () => {
    this.setState({ isShow: false });
  };

  signIn = () => {
    const { onFormSubmit } = this.props;
    const { watched } = this.state;
    onFormSubmit({ video: watched });
  };

  render() {
    const { isShow, watched } = this.state;
    return (
      <form onSubmit={this.onFormSubmit}>
        <Text>{Strings.videoInst}</Text>
        {isShow && (
          <React.Fragment>
            <div className="close-btn" onClick={this.closeVideo} />
            <video
              className="video-modal"
              ref={this.refVideo}
              src={video}
              controls
              autoPlay
              onEnded={this.closeVideo}
            />
          </React.Fragment>
        )}
        <ButtonRow
          full={
            <Button buttonType={BUTTON_TYPE_DARK_BLUE} type="submit">
              <i className="play-video"></i>
              {Strings.watchNow}
            </Button>
          }
        />
        <ButtonRow
          full={
            <Button buttonType={BUTTON_TYPE_WHITE} type="button" onClick={this.signIn}>
              {watched ? Strings.done : Strings.later}
            </Button>
          }
        />
      </form>
    );
  }
}

Step6_Video.propTypes = { onFormSubmit: PropTypes.func };
