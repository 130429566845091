export const GET_COHORTS = 'get-cohorts';
export const GET_COHORTS_RESULT = `${GET_COHORTS}/result`;
export const GET_COHORTS_ERROR = `${GET_COHORTS}/error`;

export const EDIT_COHORT = 'edit-cohort';
export const EDIT_COHORT_RESULT = `${EDIT_COHORT}/result`;
export const EDIT_COHORT_ERROR = `${EDIT_COHORT}/error`;

export const CREATE_COHORT = 'create-cohort';
export const CREATE_COHORT_RESULT = `${CREATE_COHORT}/result`;
export const CREATE_COHORT_ERROR = `${CREATE_COHORT}/error`;

export const DELETE_COHORT = 'delete-cohort';
export const DELETE_COHORT_RESULT = `${DELETE_COHORT}/result`;
export const DELETE_COHORT_ERROR = `${DELETE_COHORT}/error`;

export const GET_COHORT_DETAILS = 'get-cohort-details';
export const GET_COHORT_DETAILS_RESULT = `${GET_COHORT_DETAILS}/result`;
export const GET_COHORT_DETAILS_ERROR = `${GET_COHORT_DETAILS}/error`;

export const ASSIGN_PATIENT_TO_COHORT = 'assign-patient-to-cohort';
export const ASSIGN_PATIENT_TO_COHORT_RESULT = `${ASSIGN_PATIENT_TO_COHORT}/result`;
export const ASSIGN_PATIENT_TO_COHORT_ERROR = `${ASSIGN_PATIENT_TO_COHORT}/error`;

export const UNASSIGN_PATIENT_FROM_COHORT = 'unassign-patient-from-cohort';
export const UNASSIGN_PATIENT_FROM_COHORT_RESULT = `${UNASSIGN_PATIENT_FROM_COHORT}/result`;
export const UNASSIGN_PATIENT_FROM_COHORT_ERROR = `${UNASSIGN_PATIENT_FROM_COHORT}/error`;

export const GET_CONDITION_LIST = 'get-condition-list';
export const GET_CONDITION_LIST_RESULT = `${GET_CONDITION_LIST}/result`;
export const GET_CONDITION_LIST_ERROR = `${GET_CONDITION_LIST}/error`;

export const DELETE_CONDITION = 'delete-condition';
export const DELETE_CONDITION_RESULT = `${DELETE_CONDITION}/result`;
export const DELETE_CONDITION_ERROR = `${DELETE_CONDITION}/error`;

export const CREATE_CONDITION = 'create-condition';
export const CREATE_CONDITION_RESULT = `${CREATE_CONDITION}/result`;
export const CREATE_CONDITION_ERROR = `${CREATE_CONDITION}/error`;

export const EDIT_CONDITION = 'edit-condition';
export const EDIT_CONDITION_RESULT = `${EDIT_CONDITION}/result`;
export const EDIT_CONDITION_ERROR = `${EDIT_CONDITION}/error`;

export const GET_FACILITIES = 'get-facilities';
export const GET_FACILITIES_RESULT = `${GET_FACILITIES}/result`;
export const GET_FACILITIES_ERROR = `${GET_FACILITIES}/error`;

export const DELETE_FACILITY = 'delete-facility';
export const DELETE_FACILITY_RESULT = `${DELETE_FACILITY}/result`;
export const DELETE_FACILITY_ERROR = `${DELETE_FACILITY}/error`;

export const CREATE_FACILITY = 'create-facility';
export const CREATE_FACILITY_RESULT = `${CREATE_FACILITY}/result`;
export const CREATE_FACILITY_ERROR = `${CREATE_FACILITY}/error`;

export const EDIT_FACILITY = 'edit-facility';
export const EDIT_FACILITY_RESULT = `${EDIT_FACILITY}/result`;
export const EDIT_FACILITY_ERROR = `${EDIT_FACILITY}/error`;

export const ASSIGN_PATIENT_TO_FACILITY = 'assign-patient-to-facility';
export const ASSIGN_PATIENT_TO_FACILITY_RESULT = `${ASSIGN_PATIENT_TO_FACILITY}/result`;
export const ASSIGN_PATIENT_TO_FACILITY_ERROR = `${ASSIGN_PATIENT_TO_FACILITY}/error`;

export const GET_IDC_CONDITION_LIST = 'get-idc-condition-list';
export const GET_IDC_CONDITION_LIST_RESULT = `${GET_IDC_CONDITION_LIST}/result`;
export const GET_IDC_CONDITION_LIST_ERROR = `${GET_IDC_CONDITION_LIST}/error`;
