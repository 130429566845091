import React from 'react';

const Box = ({ className, ...props }) => (
  <div {...props} className={`${className} box`}>
    {props.children}
  </div>
);

Box.propTypes = {};

export default Box;
