import React from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import Strings from '../Strings';
import './../pages/SuperUser/privacyandterms.scss';

const PrivacyPage = () => (
  <OverlayScrollbarsComponent
    defer
    className="page-container"
    options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
  >
    <div className="page-inner-container">
      <h2 align="center">RXCAP INC. PRIVACY POLICY</h2>

      <ul className="list-style-none">
        <li>
          <h2>1. {Strings.introduction}</h2>
        </li>
        <ul className="list-style-lower-alpha">
          <li>
            <p>
              Maintaining the privacy of your information is of paramount importance to us. Privacy helps foster
              confidence, goodwill and stronger relationships with you, our customers. If, at any time, you have
              questions or concerns about our privacy practices, please feel free contact us at{' '}
              <a href={`mailto:${Strings.supportEmail}?subject=${Strings.emailSubject}`}>{Strings.supportEmail}</a>
            </p>
          </li>

          <li>
            <p>
              This Privacy Policy applies to your use of the RxCap Inc. mobile device application (the “App”) and
              related website located at <a href="https://rxcap.com">https://rxcap.com </a>
              (including the mobile-optimized versions of such website, the “Site”) owned by RxCap Inc. (“we” or “us” or
              “RxCap”). The RX Cap platform, Smart Caps, and mobile apps allow a complete and end-to-end solution for
              patient adherence management (the “Service”). The App and the Site are also referred to together as the
              “Service”. ‘You’ refers to any user of the Service, including individuals who use the Service. The Service
              is offered to you conditioned on your acceptance of our Privacy Policy.
            </p>
          </li>

          <li>
            <p>
              The Privacy Policy goes hand-in-hand with our Terms of Use, which govern all use of the Service and can be
              found at: <a href="https://rxcap.com/privacy/">https://rxcap.com/privacy/</a> Please read them together.
            </p>
          </li>
        </ul>
        <li>
          <h2>2. PRIVACY STATEMENT</h2>
        </li>
        <p>
          1.1 This Privacy Policy explains how we collect, use, maintain and disclose your Information. This includes
          information that could be used to identify a specific User (“Personal Information”), and other information
          that does not constitute Personal Information (“Non-Personal Information”) that is collected from you while
          using our Service. We take the privacy of your Personal Information seriously. All members of RxCap’s
          workforce who have access to your Personal Information are required to follow this policy, as amended,
          effective August 15, 2020.
        </p>
        <li>
          <h3>2. CONECENT</h3>
        </li>
        <p>
          2.1. When you use our Service or allow someone to use our Service on your behalf, you consent to our
          collection, use, disclosure, transfer and storage/retention of any the Personal and Non-Personal Information
          or other information received by us as a result of your use, all in accordance with this Privacy Policy.
        </p>
        <li>
          <h2>3. GROUNDS FOR DATA COLLECTION AND DATA CONTROLLER</h2>
        </li>
        <p>
          3.1. Data protection laws and privacy laws in certain jurisdictions, like the European Economic Area (EEA),
          differentiate between "controllers" and "processors" of personal information. A controller decides why and how
          to process personal information.
        </p>
        <p>
          3.2. A processor processes personal information on behalf of a controller based on the controller's
          instructions. When RxCap processes your Personal Information, we act as a controller. Privacy Policy
        </p>
        <p>3.3. Broadly speaking, we use your Data to further our legitimate interests to:</p>
        <ul className="list-style-lower-alpha">
          <li>
            understand who our customers and potential customers are and their interests in our product and services,
          </li>
          <li>manage our relationship with you and other customers,</li>
          <li>
            carry out core business operations such as accounting, filing taxes, and fulfilling regulatory obligations
            and
          </li>
          <li>
            help detect, prevent, or investigate security incidents, fraud and other abuse and/or misuse of our products
            and services.
          </li>
        </ul>
        <h2>4. A NOTE ABOUT CHILDREN</h2>
        <p>
          4.1. Consistent with the federal Children’s Online Privacy Protection Act of 1998 (COPPA), we do not knowingly
          collect Personal Information from children under the age of 13. If you have reason to believe that a child
          under the age of 13 has used our Service and provided Personal Information to us, please contact us, and we
          will work to delete that information from our databases. If you are a parent or guardian and you believe we
          have collected information from your child in a manner not permitted by law, contact us at{' '}
          <a href={`mailto:${Strings.supportEmail}?subject=${Strings.emailSubject}`}>{Strings.supportEmail}</a> We are
          not liable for any damages that may result from a visitor’s misrepresentation of age.
        </p>
        <p>
          5.1. In the course of business it may be necessary for us to collect Personal Information. This information
          allows us to identify who an individual is for the purposes of our business, contact the individual in the
          ordinary course of business and transact with the individual. We require this information in order to verify
          the identity of our Users, to protect our customers, and to ensure the integrity of the Service. 5.2. We will
          collect Personal Information from you only if you, or an authorized individual, whom you have authorized to
          share data about you (an “Authorized Agent”) voluntarily submit such information to us. You and/or the
          Authorized Agent can refuse to supply Personal Information, except that it may prevent you from engaging in
          certain Site related activities or accessing parts of the Service.
        </p>
        <p>
          5.2. We will collect Personal Information from you only if you, or an authorized individual, whom you have
          authorized to share data about you (an “Authorized Agent”) voluntarily submit such information to us. You
          and/or the Authorized Agent can refuse to supply Personal Information, except that it may prevent you from
          engaging in certain Site related activities or accessing parts of the Service.
        </p>
        <p>5.3. Without limitation, the types of information we may collect are:</p>
        <p>
          (a) Aggregated Data. So that we can continually improve our Service, we often conduct research on user
          demographics, interests, and behavior. This is based on Personal and Non-Personal Information and other
          information that we have collected, and may be compiled and analyzed on an aggregate basis. Since this
          aggregate information does not identify you personally, it is considered and treated as Non-Personal
          Information under this Privacy Policy;
        </p>
        <p>
          (b) Communications, Video and Workflow. We may collect information about a User’s habits, activity and
          communications when the User uses the Service. This includes, without limitation, messages and communications
          within the platform between Users;
        </p>
        <p>
          (c) Contact Information. We may collect information like your email address, telephone, and other information
          that allows us to contact you;
        </p>
        <p>
          (a) Financial Information. We may collect financial information related to an individual such as any bank or
          credit card details used to transact with us and other information that allows us to transact with the
          individual and/or provide them with our Service;
        </p>
        <p>
          (d) Health Information. In order to use the Service, we will need to collect certain information about your
          medication and dosages. If you are a healthcare provider, you may be asked to provide information regarding
          your professional qualifications as well as additional Personal Information during registration;
        </p>
        <p>
          (e) Installation ID. When you download and install our App onto a mobile device, we assign a random number to
          your App installation. We do not use this number to identify you personally unless you choose to become a
          registered user of the App. We use this random number in a manner similar to our use of cookies as described
          in this Privacy Policy. Because the random number is assigned to your installation of the App itself, it
          cannot be removed through the settings of your App or your device. If you do not want us to use the random
          number for the purposes for which we use cookies, please do not install our App;
        </p>
        <p>
          (f) Personal Information. We may collect personal details such as your name, location, and other information
          defined as “Personal Information” that allows us to identify who you are. "Personal Information" includes (but
          is not limited to) the following categories of information: (1) contact data (such as your e-mail address and
          phone number); (2) demographic data (such as your gender, your date of birth and your zip code); (3) insurance
          data (such as your insurance carrier, insurance plan, member ID, group ID and payer ID); (4) any health
          information you choose to share with us; and (5) other identifying information that you voluntarily choose to
          provide to us, including without limitation unique identifiers such as passwords, and Personal Information in
          emails or letters that you send to us. We refer to both Personally Identifiable Information" and "Personal
          Information" as "Personal Information." We may utilize this information in order to adapt our Service to
          Users’ needs or to develop new tools for the community;
        </p>
        <p>
          (g) Social Media Information. We may collect twitter, Facebook or other social media Usernames if you connect
          to these social networks through the Service or for identity verification purposes;
        </p>
        <p>
          (h) Statistical Information. We may collect information about an individual’s online and offline preferences,
          habits, movements, trends, decisions, associations, memberships, finances, purchases and other information for
          statistical purposes;
        </p>
        <p>
          (i) Technical information. The App automatically collects certain information about you when you use the App,
          this includes, but not limited to: type of computing or mobile device you use, advertiser ID, your device’s
          operating system, the language of your operating system and the Internet browser you are using. The App might
          also access a list of installed apps on your device. This is done only to ensure proper quality of service, as
          some apps mightinterfere with the App functionality, such as blocking notifications, and in such cases we will
          notify you;
        </p>
        <p>
          (j) Other Information. We may collect other Personal Information about you, which we will maintain according
          to this Privacy Policy. We may also collect non-Personally Identifiable Information about you such as
          information about your network, device, or operating system. Finally, we may collect any personal
          correspondence that you send us, or that is sent to us by others about an individual’s activities.
        </p>
        <h2> 2. HOW INFORMATION IS COLLECTED</h2>
        <p>
          5.4. Most information is collected in association with your use of the Service. In particular, information is
          likely to be collected as follows:
        </p>
        <p>
          (a) Account. When you open an Account on the application and submit your personal details, or when you enter
          Personal Information details through another process in order to receive or access something.
        </p>
        <p>(b) Contact. When you contact us in any way.</p>
        <p>
          (c) Cookies & Similar Technologies. When you use the Application, we may use cookies and similar technologies
          like pixels, web beacons, and local storage to collect information about how you use our Service, and to
          provide features to you. We use cookies to make your use of our Service as convenient as possible. Cookies are
          useful to estimate our number of visitors and to determine overall traffic patterns through our website. If
          you do not wish to receive any cookies you may set your Mobile phone to refuse cookies. This may mean you will
          not be able to take full advantage of the Service.
        </p>
        <p>
          (b) Flash LSOs. When we post videos, third parties may use local shared objects, known as “Flash Cookies,” to
          store your preferences for volume control or to personalize certain video features. Flash Cookies are
          different from browser Cookies because of the amount and type of data and how the data is stored. Cookie
          management tools provided by your browser or mobile phone will not remove Flash Cookies.
        </p>
        <p>
          (c) Log files. If you access certain portions of our Service, we will gather certain information automatically
          and store it in log files. This information includes IP addresses, browser type, Internet service provider
          (“ISP”), referring/exit pages, operating system, date/time stamp, and clickstream data. We use this
          information to analyze trends, administer the Site, track users’ movements around the Site, gather demographic
          information about our user base as a whole, and better tailor our Service to our users’ needs. For example,
          some of the information may be collected so that when you visit the Site or the Service again, it will
          recognize you and the information could then be used to serve advertisements and other information appropriate
          to your interests. Except as noted in this Privacy Policy, we do not link this automatically-collected data to
          Personal Information.
        </p>
        <p>
          (d) Marketing and Web Surveys. From time to time, we may conduct online research surveys through email
          invitations, surveys and online focus groups. When participating in a survey, we may ask you to enter Personal
          Information. The Personal Information you submit in a survey may be used by us for research and measurement
          purposes, as described below, including to measure the effectiveness of content, advertising or programs. When
          our market research surveys collect Personal Information we will not knowingly accept participants who are
          under the age of 13.
        </p>
        <p>(e) Payment. When an individual submits their details to open a payment account or make a payment.</p>
        <p>
          (d) Public Forums. RxCap may feature public forums where users with similar issues, interests, or conditions
          can share information and support one another or where users can post questions for experts to answer. Our
          forums are open to the public and should not be considered private. Any information (including Personal
          Information) you share in any online forum is by design open to the public and is not private. You should
          think carefully before posting any Personal Information in any public forum. What you post can be seen,
          disclosed to or collected by third parties and may be used by others in ways we cannot control or predict,
          including to contact you for unauthorized purposes. As with any public forum on any site, the information you
          post may also show up in third-party search engines. If you mistakenly post Personal Information in our Public
          Forums and would like it removed, you can send us an email to request that we remove it by using the contact
          address provided in the first page of the Privacy Policy. In some cases, we may not be able to remove your
          Personal Information.
        </p>
        <p>
          (e) Uploads. When you upload or generate user content, such as photos, videos, text, comments, using our
          Service.
        </p>
        <p>
          (f) Social Media Features. Our Site may include social media features. These features may collect your IP
          address and which page you are visiting on our Site, and may set a cookie to enable the feature to function
          properly. Social media features are either hosted by a third party or hosted directly on our Site. Your
          interactions with these features are governed by the privacy policy of the company providing them.
        </p>
        <p>
          (f) Phishing. It has become increasingly common for unauthorized individuals to send e-mail messages to
          consumers, purporting to represent a legitimate company such as a bank or on-line merchant, requesting that
          the consumer provide personal, often sensitive information. Sometimes, the domain name of the e-mail address
          from which the e-mail appears to have been sent, and the domain name of the web site requesting such
          information, appears to be the domain name of a legitimate, trusted company. In reality, such sensitive
          information is received by an unauthorized individual to be used for purposes of identity theft. This illegal
          activity is known as “phishing”. If you receive an e-mail or other correspondence requesting that you provide
          any sensitive information (including your password or credit card information) via e-mail or to a Web site
          that does not seem to be affiliated with us, or that otherwise seems suspicious to you, please do not provide
          such information, and report such request to us at{' '}
          <a href="mailto:infosec@rxcap.com?subject=infosec@rxcap.com">infosec@rxcap.com</a>
        </p>
        <p>
          (g) Third Party Links. Our Services may contain links to third party websites. The fact that we link to a
          website is not an endorsement, authorization or representation of our affiliation with that third party. We do
          not exercise control over third party websites. These other websites may place their own cookies or other
          files on your computer, collect data or solicit Personal Information from you. If you submit Personal
          Information to any of those sites, your information is governed by their privacy policies. Other sites follow
          different rules regarding the use or disclosure of the Personal Information you submit to them. We encourage
          you to read the privacy policies or statements of the other websites you visit. This Privacy Policy does not
          apply to information collected on external websites that may be linked to or through the Site.
        </p>
        <p>
          5.5. We understand that there are many circumstances in which we may collect information, and we work hard to
          ensure that you are always aware when your Personal Information is being collected.
        </p>
        <h2>6. THE SAFETY & SECURITY OF PERSONAL INFORMATION</h2>
        <p>
          6.1. Data Hosting. We are committed to protecting the security of your Personal Information. We use a variety
          of state-of-the-art encryption technologies and procedures to help protect your Personal Information from
          unauthorized access, use, or disclosure. We also require you to enter a password to access your Account
          information. We do not have access to this password. Please do not disclose your Account password to
          unauthorized people. No method of transmission over the Internet, or method of electronic storage, is 100%
          secure, however. Therefore, while we take extraordinary efforts to protect your Personal Information, we
          cannot guarantee its absolute security.
        </p>
        <p>
          6.2. Third Party Use. We are not responsible for the privacy or security practices of any third party; this
          includes third parties to whom we are permitted to disclose your Personal Information in accordance with this
          policy or any applicable laws. The collection and use of your information by these third parties may be
          subject to separate privacy and security policies. We cannot control and are not responsible for the privacy
          and security of your Personal Information once it is provided to a third party by you or in accordance with
          your requests or directions.
        </p>
        <p>
          6.3. Unauthorized Access. If you suspect any misuse, loss of, or unauthorized access to your Personal
          Information, you should let us know immediately at{' '}
          <a href="mailto:infosec@rxcap.com?subject=infosec@rxcap.com">infosec@rxcap.com</a>
        </p>
        <p>
          6.4. Authorized Use. We are not liable for any loss, damage, or claim arising out of another person’s use of
          the Personal Information where we were authorized to provide that person with the Personal Information.
        </p>
        <h2>7. WHEN WE CONTACT USERS</h2>
        <p>
          7.1. Service Announcements. On rare occasions it is necessary to send out a strictly Service related
          announcement. For instance, if our Service is temporarily suspended for maintenance we might send users an
          email. Generally, users may not opt-out of these communications, though they can deactivate their Account.
          However, these communications are not promotional in nature.
        </p>
        <p>
          7.2. Customer Service. We communicate with users on a regular basis to provide requested Service and in
          regards to issues relating to their Account.
        </p>
        <h2>8. WHEN PERSONAL INFORMATION IS USED & DISCLOSED</h2>
        <p>
          We do not sell your Personal Information to anyone for direct marketing purposes without your consent. Period.
        </p>
        <p>
          8.1. Time Period. We may retain your data as long as you continue to use our Service. You may close your
          account by contacting us, but we may retain Personal or Non-Personal Information for an additional period as
          is permitted or required under applicable laws. Even after we delete your Personal Information, it may persist
          on backup or archival media for an additional period of time.
        </p>
        <p>
          8.2. Agents, Consultants, and Trusted Third Parties. Like many businesses, we sometimes have companies perform
          certain business-related functions for us. These companies include our marketing agencies, database service
          providers, backup and disaster recovery service providers, email service providers, and others. When we engage
          another company, we may provide them with information including Personal Information, so they can perform
          their designated functions. They are not permitted to use your Personal Information for other purposes.
        </p>
        <p>
          8.3. Business Operations. Your Information is used to operate our business. These business operations may
          include:
        </p>
        <ul className="list-style-none">
          <li>
            <p>(g) The provision of Service between you and us;</p>

            <p>(h) Verifying your identity;</p>

            <p>(i) Communicating with you about:</p>

            <p>i. Your relationship with us;</p>

            <p>ii. Our goods and services;</p>

            <p>iii. Our own marketing and promotions to users and prospects;</p>

            <p>iv. Competitions, surveys and questionnaires.</p>
          </li>
        </ul>
        <p>
          1.1. <b>Corporate Restructuring.</b> We may share some or all of your Personal Information in connection with
          or during negotiation of any merger, financing, acquisition or dissolution transaction or proceeding involving
          sale, transfer, divestiture, or disclosure of all or a portion of our business or assets. In the event of an
          insolvency, bankruptcy, or receivership, Personal Information may also be transferred as a business asset. If
          another company acquires our company, business, or assets, that company will possess the Personal Information
          collected by us and will assume the rights and obligations regarding your Personal Information as described in
          this Privacy Policy.
        </p>
        <p>
          1.2. <b>IP Address.</b> We use your IP address to help diagnose problems with our application or sever, and to
          administer our website. We do not link your IP address which accesses our website to any Personal Information.
          We use tracking information to determine which areas our App or site users visit based on traffic to those
          areas. RXCAP does not track what individual users read, but rather how often each page is visited. This helps
          us maintain a superior and informative website for you.
        </p>
        <p>
          1.3. <b>Improve Customer Service.</b> Information you provide helps us respond to your customer service
          requests and to support your needs more efficiently.
        </p>
        <p>
          1.4. <b>Medical professionals and Doctors.</b> You may use the Service in order to share your Personal
          Information or other information with your doctor or healthcare providers. Such sharing may be enabled by
          inviting your healthcare provider to monitor your Personal Information, by accepting an invitation from your
          healthcare provider to download and use the Service, or otherwise using the Service settings to share your
          information with your healthcare provider; in these cases you consent to our sending Personal Information
          about your medications and your compliance with your medication regime with your healthcare providers. Confirm
          with your healthcare provider that they have sent you an invitation to download the App. Such sharing of your
          information is at your discretion and is solely your responsibility.
        </p>
        <p>
          1.5. <b>Pharmacies and coupon companies.</b> You may choose to share your Personal Information with pharmacies
          with which we partner, and/or third party coupon companies, in order that we may send you coupons and/or
          provide you with reminders to get your prescription refilled. If you choose to share your Personal Information
          in this manner, please note that such information may be disclosed to the coupon companies and/or pharmacies
          and will be subject to their privacy practices.
        </p>
        <p>
          1.6.<b>Payments.</b> We may use the information Users provide about themselves when placing an order only to
          provide service to that order. We do not share this information with outside parties except to the extent
          necessary to provide the service.
        </p>
        <p>
          1.7. <b>Personalize Your Experience.</b> We may use information in the aggregate to understand how our Users
          as a group use the Service.
        </p>
        <p>
          1.8. <b>Research Partners.</b> We may share your Personal Information with third parties, such as research
          institutes, healthcare systems and healthcare providers, that they may associate with other information that
          they have about you, for research purposes and for improvement of our services.
        </p>
        <p>
          1.9. <b>Site Improvement.</b> We may use feedback you provide to improve our products and Service.
        </p>
        <p>
          8.4. <b>Social Networking Sites (SNSs).</b> Our Service may enable you to post content to SNSs. If you choose
          to do this, we will provide information to such SNSs in accordance with your elections. You acknowledge and
          agree that you are solely responsible for your use of those websites and that it is your responsibility to
          review the terms of use and privacy policy of the third party provider of such SNSs. We will not be
          responsible or liable for: (i) the availability or accuracy of such SNSs; (ii) the content, products or
          services on or availability of such SNSs; or (iii) your use of any such SNSs.
        </p>
        <p>
          8.5. <b>User Testimonials and Feedback.</b> We often receive testimonials and comments from users who have had
          positive experiences with our Service. We occasionally publish such content. We obtain the user’s consent
          prior to posting his or her name along with the testimonial. We may post user feedback on the Site from time
          to time. If we choose to post your first and last name along with your feedback, we will obtain your consent
          prior to posting you name with your feedback. If you make any comments on a blog or forum associated with your
          Site, you should be aware that any Personal Information you submit there can be read, collected, or used by
          other users of these forums, and could be used to send you unsolicited messages. We are not responsible for
          the Personal Information you choose to submit in these blogs and forums.
        </p>
        <p>
          8.6. <b>Disclosure.</b> There are a few circumstances where we must disclose an individual’s information:
        </p>
        <p>
          (j) Where we reasonably believe that an individual may be engaged in fraudulent, deceptive, or unlawful
          activity that a governmental authority should know about or to enforce our Terms of Use and investigate
          potential violations of the Terms of Use;
        </p>
        <p>
          (k) In response to lawful requests by public authorities, including to meet national security or law
          enforcement requirements;
        </p>
        <p>(l) To protect the rights, property, or personal safety of another user or any member of the public;</p>
        <p>(m) As required by any law;</p>
        (n) In the event we sell our business and may need to transfer Personal Information to a new owner; or (o) In
        special cases, such as in response to a physical threat to you or others. (p) RXCAP does not make your Personal
        Information available to third parties for their marketing purposes without your consent.
        <p>
          8.7. Non-Disclosure. We promise not to disclose or sell your Personal Information to unrelated third parties
          under any circumstances, ever. We do not sell, trade, or rent your Personal Information to others.
        </p>
        <h2>9. HOW YOU CAN CONTROL YOUR INFORMATION</h2>
        <p>
          We believe that choice in how and when you are contacted is key to customer satisfaction, so we offer several
          ways for you to update your contact information, change your preferences, or opt out of receiving future
          communications:
        </p>
        <p>
          9.1. Email Communications. We will periodically send you free newsletters and e-mails that directly promote
          the use of our Service. When you receive newsletters or promotional communications from us, you may indicate a
          preference to stop receiving further communications from us and you will have the opportunity to “opt-out” by
          following the unsubscribe instructions provided in the e-mail you receive or by contacting us directly (please
          see contact information below). Despite your indicated e-mail preferences, we may send you service related
          communications, including notices of any updates to our Terms of Use or Privacy Policy.
        </p>
        <p>
          9.2. Cookies. If you decide at any time that you no longer wish to accept Cookies from our Service for any of
          the purposes described above, then you can instruct your browser, by changing its settings, to stop accepting
          Cookies or to prompt you before accepting a Cookie from the websites you visit. Consult your browser’s
          technical information. If you do not accept Cookies, however, you may not be able to use all portions of the
          Service or all functionality of the Service. If you have any questions about how to disable or modify Cookies,
          please let us know at the contact information provided below.
        </p>
        <p>
          9.3. De-Linking SNS. If you decide at any time that you no longer wish to have your SNS account (e.g.,
          Facebook, Telegram) linked to your Account, then you may de-link the SNS account in the “preferences” section
          in your account settings. You may also manage the sharing of certain Personal Information with us when you
          connect with us through an SNS, such as through Facebook Connect. Please refer to the privacy settings of the
          SNS to determine how you may adjust our permissions and manage the interactivity between the Service and your
          social media account or mobile device.
        </p>
        <p>
          1.1. Do Not Track. Your browser settings may allow you to automatically transmit a "do not track" signal to
          online services you visit. Note, however, there is no industry consensus as to what site and app operators
          should do with regard to these signals. Accordingly, unless and until the law is interpreted to require us to
          do so, our systems do not recognize browser "do-not-track" requests. You may, however, disable certain
          tracking as discussed in this privacy policy (e.g., by disabling cookies, or using 'private' browsing modes).
        </p>
        <p>
          9.4. Changing or Deleting your Personal Information. You may change any of your Personal Information in your
          Account by editing your profile within your Account or by sending an e-mail to us at{' '}
          <a href={`mailto:${Strings.supportEmail}?subject=${Strings.emailSubject}`}>{Strings.supportEmail}</a> You may
          request deletion of your Personal Information by us, and we will use commercially reasonable efforts to honor
          your request, but please note that we may be required to keep such information and not delete it (or to keep
          this information for a certain time, in which case we will comply with your deletion request only after we
          have fulfilled such requirements). When we delete any information, it will be deleted from the active
          database, but may remain in our archives. We may also retain your information for fraud or similar purposes.
        </p>
        <p>
          1.2. EU Residents. In addition, if you are a resident of the European Union, you can object to processing of
          your Personal Information, ask us to restrict processing of your Personal Information, or request portability
          of your Personal Information. Again, you can exercise these rights by contacting us at{' '}
          <a href={`mailto:${Strings.supportEmail}?subject=${Strings.emailSubject}`}>{Strings.supportEmail}</a> Note
          that if you submit such a request, this may prohibit you from using the Site and/or Service.
        </p>
        <p>
          9.5. Withdrawing Consent. If we have collected and processed your information with your consent, then you can
          withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing
          we conducted prior to your withdrawal, nor will it affect processing of your information conducted in reliance
          on lawful processing grounds other than consent.
        </p>
        <p>
          9.6. Data Protection. You have the right to complain to a data protection authority about our collection and
          use of your information. For more information, please contact your local data protection authority. We respond
          to all requests we receive from individuals wishing to exercise their data protection rights in accordance
          with applicable data protection laws. Where we process your information solely on behalf of a customer, we may
          be legally required to forward your request directly to our customer and/or social media business partners for
          their review / handling.
        </p>
        <h2>10. CAN-SPAM COMPLIANCE NOTICE (U.S. USERS ONLY)</h2>
        <p>
          10.1. We may send periodic promotional or informational emails to you. You may opt-out of such communications
          by following the unsubscribe or opt-out instructions contained in the email. Please note that it may take up
          to 10 business days for us to process opt-out requests. If you opt-out of receiving emails about
          recommendations or other information we think may interest you, we may still send you emails about your
          account or any service you have requested or received from us.
        </p>
        <h2>11. COMPLAINTS AND DISPUTES</h2>
        <p>
          11.1. If you have a complaint about our handling of your Personal Information, address your complaint in
          writing to{' '}
          <a href={`mailto:${Strings.supportEmail}?subject=${Strings.emailSubject}`}>{Strings.supportEmail}</a>.
        </p>
        <p>
          11.2. If we have a dispute over handling of your Personal Information, we will first attempt to resolve the
          issue directly between us.
        </p>
        <p>
          11.3. If we become aware of any unauthorized access to your Personal Information we will inform you at the
          earliest practical opportunity, once we have established what was accessed and how it was accessed.
        </p>
        <h2>12. INTERNATIONAL PRIVACY LAWS</h2>
        <p>
          12.1. This site is intended for use only in the United States. If you are visiting the Service from outside
          the United States, please be aware that you may be sending information (including Personal Information) to the
          United States, where some of our servers are located. That information may then be transferred within the
          United States or back out of the United States, depending on the type of information and how it is stored by
          us.
        </p>
        <p>
          12.2. We hold and process your Personal Information in accordance with privacy laws in the United States and
          this Privacy Policy. Please note that privacy laws in the United States may not be the same as, and in some
          cases may be less protective than, the privacy laws in your country, and while in the United States Personal
          Information may be subject to lawful access requests by government agencies.
        </p>
        <p>
          12.3. We will ensure that any transfer of Personal Information from countries in the European Economic Area
          (EEA) to countries outside the EEA will be protected by appropriate safeguards, for example by using standard
          data protection clauses approved by the European Commission, or the use of binding corporate rules or other
          legally accepted means.
        </p>
        <h2>13. EUROPEAN USERS</h2>
        <p>
          13.1. Data protection law in Europe requires a "lawful basis" for collecting and retaining Personal
          Information from citizens or residents of the European Economic area. Our lawful bases include:
        </p>
        <p>
          (A) Performing the contract we have with you: In certain circumstances, we need your Personal Information to
          comply with our contractual obligation to deliver the services, enable creators to establish and display their
          projects, and enable backers to find and make pledges to them.
        </p>
        <p>
          (B) Legal compliance: Sometimes the law says we need to collect and use your data. For example, tax laws
          require us to retain records of pledges and payments made through our services.
        </p>
        <p>
          (C) Legitimate interests: This is a technical term in data protection law which essentially means we have a
          good and fair reason to use your data and we do so in ways which do not hurt your interests and rights. We
          sometimes require your data to pursue our legitimate interests in a way that might reasonably be expected as
          part of running our business and that does not materially impact your rights, freedom or interests. For
          example, we use identity, device, and location information to prevent fraud and abuse and to keep the services
          secure. We may also send you promotional communications about our services, subject to your right to control
          whether we do so.
        </p>
        <p>
          (A) We analyze how users interact with our site so we can understand better what elements of the design are
          working well and which are not working so well. this allows us to improve and develop the quality of the
          online experience we offer all our users.
        </p>
        <p>
          (B) Data Protection Authority. Subject to applicable law, if you are a citizen or resident of the European
          Economic Area, you also have the right to object to RxCap’s use of your Personal Information and to lodge a
          complaint with your local data protection authority.
        </p>
        <h2>2. ADDITIONS TO THIS POLICY</h2>
        <p>
          13.2. If we change this Privacy Policy, we will post updates on the Service or the App (the “Modifications”).
          Modifications are effective thirty (30) days following the "Updated” date, or the date communicated in any
          other notice to you. Please review this policy periodically for changes, and especially before you provide any
          Personal Information. By continuing to use our Service after the effective date of any Modifications to this
          Privacy Policy, you accept those Modifications. If any Modification to this Privacy Policy is not acceptable
          to you, you should cease accessing, browsing, and otherwise using the Service.
        </p>
        <h2>14. CONTACTING US</h2>
        <p>
          14.1. If you have any questions about this Privacy Policy or your dealings with the Application, please
          contact us at:{' '}
          <a href={`mailto:${Strings.supportEmail}?subject=${Strings.emailSubject}`}>{Strings.supportEmail}</a>
        </p>
        <h2>15. ADDITIONAL DISCLOSURES FOR CALIFORNIA RESIDENTS</h2>
        <p>
          These additional disclosures for California residents apply only to individuals who reside in California. The
          California Consumer Privacy Act of 2018 (“CCPA”) provides additional rights to know, delete and opt out, and
          requires businesses collecting or disclosing Personal Information to provide notices and means to exercise
          rights.
        </p>
        <b>Notice of Collection.</b>
        <p>
          In the past 12 months, we have collected the following categories of Personal Information enumerated in the
          CCPA:
        </p>
        <li>
          ● <b>Identifiers</b>, including name, email address, phone number account name, IP address, and an ID or
          number assigned to your account.
        </li>
        <li>
          ● <b>Customer records</b>, billing and shipping address, and credit or debit card information.
        </li>
        <li>
          ● <b>Commercial information</b>, including purchases and engagement with the Service.
        </li>
        <li>
          ● <b>Internet activity</b>, including your interactions with our Service.
        </li>
        <li>
          ● <b>Audio or visual data</b>, including pictures or videos you post on our Service.
        </li>
        <li>
          ● <b>Employment and education data</b>, including information you provide when you apply for a job with us.
        </li>
        <li>
          ● <b>Inferences</b>, including information about your interests, preferences and favorites.
        </li>
        <b>Right to Know and Delete.</b>
        Consumers who are California residents (and not representatives of businesses, whether those businesses are our
        customers or others) have the right to delete the Personal Information we have collected from you and the right
        know certain information about our data practices in the preceding 12 months. In particular, you have the right
        to request the following from us:
        <li>● The categories of Personal Information we have collected about you;</li>
        <li>● The categories of sources from which the Personal Information was collected;</li>
        <li>● The categories of Personal Information about you we disclosed for a business purpose or sold;</li>
        <li>
          ● The categories of third parties to whom the Personal Information was disclosed for a business purpose or
          sold;
        </li>
        <li>● The business or commercial purpose for collecting or selling the Personal Information; and</li>
        <li>● The specific pieces of Personal Information we have collected about you.</li>
        <p>
          To exercise any of these rights, please email us at{' '}
          <a href={`mailto:${Strings.supportEmail}?subject=${Strings.emailSubject}`}>{Strings.supportEmail}</a>. In the
          request, please specify which right you are seeking to exercise and the scope of the request. We will confirm
          receipt of your request within 10 days. We may require specific information from you to help us verify your
          identity and process your request. If we are unable to verify your identity, we may deny your requests to know
          or delete.
        </p>
        <p>
          If Personal Information about you has been processed by us as a service provider on behalf of a customer and
          you wish to exercise any rights you have with such Personal Information, please inquire with our customer
          directly. If you wish to make your request directly to us, please provide the name of our customer on whose
          behalf we processed your Personal Information. We will refer your request to that customer, and will support
          them to the extent required by applicable law in responding to your request.
        </p>
        <b>Right to Opt-Out.</b>
        <p>
          To the extent RxCap sells your Personal Information as the term “sell” is defined under the California
          Consumer Privacy Act, you have the right to opt-out of the sale of your Personal Information by us to third
          parties at any time. You may submit a request to opt-out by emailing us at{' '}
          <a href={`mailto:${Strings.supportEmail}?subject=${Strings.emailSubject}`}>{Strings.supportEmail}</a>.
        </p>
        <b>Authorized Agent.</b>
        <p>
          You can designate an authorized agent to submit requests on your behalf. However, we will require written
          proof of the agent’s permission to do so and verify your identity directly. Right to Non-Discrimination.
        </p>
        <p>
          You have the right not to receive non-discriminatory treatment by us for the exercise of any of your rights.
        </p>
        <b>Shine the Light.</b>
        <p>
          Pursuant to Section 1798.83 of the California Civil Code, residents of California can obtain certain
          information about the types of Personal Information that companies with whom they have an established business
          relationship have shared with third parties for direct marketing purposes during the preceding calendar year.
          In particular, the law provides that companies must inform consumers about the categories of Personal
          Information that have been shared with third parties, the names and addresses of those third parties, and
          examples of the types of services or products marketed by those third parties. To request a copy of the
          information disclosure provided by RxCap Social pursuant to Section 1798.83 of the California Civil Code,
          please contact us via email at{' '}
          <a href={`mailto:${Strings.supportEmail}?subject=${Strings.emailSubject}`}>{Strings.supportEmail}</a>
        </p>
      </ul>
    </div>
  </OverlayScrollbarsComponent>
);

export default PrivacyPage;
