import { WIDGET_GET_TOTAL_KITS, WIDGET_GET_TOTAL_KITS_RESULT, WIDGET_GET_TOTAL_KITS_ERROR } from './constants';

export const widgetTotalKitsReducer = (state = {}, action) => {
  switch (action.type) {
    case WIDGET_GET_TOTAL_KITS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        totalKits: undefined,
      };
    case WIDGET_GET_TOTAL_KITS_RESULT:
      return {
        ...state,
        isLoading: false,
        isError: false,
        totalKits: action.response.pagination.totalRecords,
      };

    case WIDGET_GET_TOTAL_KITS_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        totalKits: undefined,
      };
    }
    default:
      return state;
  }
};
