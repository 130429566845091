import PropTypes from 'prop-types';
import React from 'react';

import { PAGE_LIMIT } from '../../constants';
import TableWithPagination from './TableWithPagination';

export { Column } from './components/index';

class TableWithLocalPagination extends React.PureComponent {
  constructor(props) {
    super(props);
    this.pageLimit = props.pageLimit || PAGE_LIMIT;
    this.state = {
      pagination: {
        totalRecords: props.data.length,
        offset: 0,
      },
      dataToShow: [],
      data: [],
    };
  }

  componentDidMount() {
    this.onPaginationChange();
  }

  sortDataByNumericValue = (sortKey, direction) => {
    const { data } = this.props;

    if (direction == 'ascending') {
      data.sort((a, b) => {
        return a[sortKey] - b[sortKey];
      });
    } else {
      data.sort((a, b) => {
        return b[sortKey] - a[sortKey];
      });
    }

    this.setState({
      dataToShow: data,
    });
  };

  sortDataByStringValue = (sortKey, direction) => {
    const { data } = this.props;

    data.sort((a, b) => {
      const valueA = a[sortKey].toUpperCase(); // ignore upper and lowercase
      const valueB = b[sortKey].toUpperCase(); // ignore upper and lowercase
      let ret = 0; // assume that they are equal
      if (valueA < valueB) ret = -1;
      else if (valueA > valueB) ret = 1;

      if (direction != 'ascending') ret *= -1; // revert order of soring

      return ret;
    });

    this.setState({
      dataToShow: data,
    });
  };

  onSortClick = ({ sortKey, direction }) => {
    const { data } = this.props;
    if (data?.length > 0) {
      if (Object.prototype.toString.call(data[0][sortKey]) === '[object String]') {
        // sort strings
        this.sortDataByStringValue(sortKey, direction);
      } else if (Object.prototype.toString.call(data[0][sortKey]) === '[object Number]') {
        // sort numbers
        this.sortDataByNumericValue(sortKey, direction);
      }

      this.onCustomPage(1); // bring user to first page after sort
    }
  };

  onOffsetChange = offset => {
    const pagination = { ...this.state.pagination };
    pagination.offset = offset;
    this.setState({ pagination }, this.onPaginationChange);
  };

  onCustomPage = page => {
    const { pagination } = this.state;
    pagination.offset = (page - 1) * this.pageLimit;
    this.setState({ pagination });
    this.onPaginationChange();
  };

  onPaginationChange = () => {
    const { data } = this.props;
    if (data) {
      if (data.length > this.pageLimit) {
        this.setState({
          dataToShow: data.slice(this.state.pagination.offset, this.state.pagination.offset + this.pageLimit),
        });
      } else {
        this.setState({
          dataToShow: data,
        });
      }
    }
  };

  onRowClick = e => {
    const { onRowClick } = this.props;
    const { pagination } = this.state;
    if (!onRowClick) {
      return;
    }
    try {
      const id = parseInt(e);
      onRowClick(pagination.offset ? id + pagination.offset : id);
    } catch (err) {}
  };

  onRowSelection = e => {
    const { onRowSelection } = this.props;
    const { pagination } = this.state;
    if (!onRowSelection) {
      return;
    }
    try {
      const id = parseInt(e);
      onRowSelection(pagination.offset ? id + pagination.offset : id);
    } catch (err) {}
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.state.pagination = {
        totalRecords: this.props.data.length,
        offset: 0,
      };
      this.onPaginationChange();
    }
  }

  render() {
    const {
      isLoading,
      columns,
      onRowClick,
      onRowSelection,
      buttons,
      data: dataOld,
      infiniteScroll,
      ...otherProps
    } = this.props;
    const { pagination, dataToShow } = this.state;

    return (
      <TableWithPagination
        name="table-with-pagination"
        isLoading={isLoading}
        data={infiniteScroll ? dataOld : dataToShow}
        pagination={pagination}
        onOffsetChange={this.onOffsetChange}
        onSortClick={this.onSortClick}
        // eslint-disable-next-line no-unneeded-ternary
        onRowClick={this.onRowClick}
        onRowSelection={this.onRowSelection}
        buttons={buttons}
        infiniteScroll={infiniteScroll}
        {...otherProps}
      >
        {columns}
      </TableWithPagination>
    );
  }

  static propTypes = {
    isLoading: PropTypes.bool,
    columns: PropTypes.array,
    data: PropTypes.array,
    onRowClick: PropTypes.func,
    buttons: PropTypes.array,
  };
}

export default TableWithLocalPagination;
