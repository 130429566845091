import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import _ from 'lodash';
import { goBack, push } from 'react-router-redux';
import { withRouter } from 'react-router';

import { PageHeader } from '../../components/PageHeader';
import { PAGE_LIMIT } from '../../constants';
import Strings from '../../Strings';
import { actions } from './redux/actions';
import TableWithPagination, { Column } from '../../containers/Table/TableWithPagination';
import { TextInput } from '../../components/PageHeader/TextInput';
import {
  getCapChargeText,
  toolTipForTable,
  downloadFileOnly,
  signalRender,
  convertUnixEpochToHumanReadable,
} from '../../utils';
import Select from '../../components/Select';
import {
  isCapManager,
  isOrganizationManager,
  isShipper,
  isSuperUser,
  isBrownUniversity,
  isOmron,
  isDoctor,
} from '../../utils/userRoles';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../components/PageHeader/Button';
import { openModalAction } from '../../actions/modal';
import {
  FEATURE_CONFIGURATION_RESULT,
  DOWNLOAD_REPORT_DATA_ERROR,
  SHIPPER_CAPS_RESULT,
  ORGANIZATION_LIST_RESULT,
} from './redux/constants';
import { SEND_SECURITY_CODE_CAP } from '../../actions/action-types';
import {
  ActionWidget,
  ActionButton,
  ActionDropdownOnOffMenu,
  ActionDropdownSingleChoiceMenu,
  ItemSelectorHelper,
} from '../../components/ActionWidget';
import { notificationActions } from '../../components/Notification/redux/actions';
import AdvancedFilters, { Filter } from '../../components/AdvancedFilters/AdvancedFilters';
import AdvancedFiltersBar from '../../components/AdvancedFilters/AdvancedFiltersBar';
import { getFiltersForRequest, urlInjected } from '../../components/AdvancedFilters/helpers';
import { getFiltersDefinition } from '../../components/AdvancedFilters/FiltersDefinition';
import { DateFilters } from '../../utils/DateFilters';
import { DeviceTypes } from '../../utils/deviceTypes';

const mapStateToProps = state => ({
  filters: state.entities.advancedFilters.filters.items,
});

const mapDispatchToProps = dispatch => ({
  getCaps: (pageRequest, signal) => dispatch(actions.getCaps(pageRequest, signal)),
  cancelGetCaps: controller => dispatch(actions.cancelGetCaps(controller)),
  getOrgs: request => dispatch(actions.getOrgs(request)),
  onNavigate: (path, params) =>
    dispatch(
      push({
        pathname: path,
        state: { params },
      }),
    ),
  downloadEvents: params => dispatch(actions.downloadReport(params)),
  downloadDetails: params => dispatch(actions.downloadDetails(params)),
  onBack: () => dispatch(goBack()),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  onAttachCapToAdmin: postAction =>
    dispatch(
      openModalAction('attach-cap-to-org', {
        postAction,
        type: DeviceTypes.Datahub.id,
      }),
    ),
  openSendPinModal: data => dispatch(openModalAction('send-security-code', data)),
  featureConfiguration: request => dispatch(actions.featureConfiguration(request)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  openDateRangeSelectionModal: (actionOnDone, filters, orgName) =>
    dispatch(openModalAction('date-range-selection-for-events-reports-modal', { actionOnDone, filters, orgName })),
});

const all = 'all';
const no = 'no_org';

class Hub extends PureComponent {
  refreshEnable = true;
  state = {
    selectedOrg: all,
    openActionWidget: false,
    multiselectedItems: false,
    keyForItemCheckboxes: false,
    controller: undefined,
    hubs: [],
    pagination: undefined,
    isLoading: false,
    organizations: [],
  };

  itemSelectorHelper = new ItemSelectorHelper();

  static propTypes = {
    downloadEvents: PropTypes.func,
    downloadDetails: PropTypes.func,
    getCaps: PropTypes.func,
    getOrgs: PropTypes.func,
    history: PropTypes.shape({
      location: PropTypes.shape({ state: PropTypes.shape({ params: PropTypes.shape({ offset: PropTypes.any }) }) }),
    }),
    onAttachCapToAdmin: PropTypes.func,
    onNavigate: PropTypes.func,
    openConfirmModal: PropTypes.func,
    openSendPinModal: PropTypes.func,
    featureConfiguration: PropTypes.func,
    showNotification: PropTypes.func,
    cancelGetCaps: PropTypes.func,
    openDateRangeSelectionModal: PropTypes.func,
    filters: PropTypes.array.isRequired,
  };

  request = {
    limit: PAGE_LIMIT,
    offset: 0,
    search: '',
    filters: [],
  };

  componentDidMount() {
    if (this.props.history?.location?.state?.params?.offset) {
      this.request.offset = this.props.history?.location?.state?.params?.offset;
    }
    this.props.getOrgs().then(resp => {
      if (resp?.type === ORGANIZATION_LIST_RESULT) {
        this.setState({ organizations: resp.response?.data });
      }
    });
    if (!urlInjected(window.location.search)) {
      this.getCaps();
    }
  }

  componentWillUnmount() {
    const { controller } = this.state;
    controller && this.props.cancelGetCaps(controller);
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    if (filters !== prevProps.filters) {
      this.onFiltersChange(filters);
    }
  }

  resetActionWidget = () => {
    this.setState({
      keyForItemCheckboxes: !this.state.keyForItemCheckboxes,
      openActionWidget: false,
    });
    this.itemSelectorHelper.clearItems();
  };

  getCaps = () => {
    const { selectedOrg } = this.state;
    this.request.deviceType = DeviceTypes.Datahub.id;
    this.request.organizationId = '';
    if (selectedOrg !== all) {
      this.request.organizationId = this.state.selectedOrg;
    }

    const controller = new AbortController();
    this.setState({ controller, isLoading: true });
    this.props.getCaps(this.request, controller.signal).then(resp => {
      if (resp?.type === SHIPPER_CAPS_RESULT) {
        this.setState({
          hubs: resp.response?.data,
          pagination: resp.response?.pagination,
        });
        this.resetActionWidget();
      }
      this.setState({ isLoading: false });
    });
  };

  load = () => {
    this.getCaps();
    this.resetActionWidget();
  };

  onOrgChange = option => {
    let value = option.value;
    this.request.offset = 0;
    this.setState({ selectedOrg: value }, () => {
      this.load();
    });
  };

  onSortClick = ({ sortKey, direction }) => {
    this.request.offset = 0;
    this.request.sortColumn = sortKey;
    this.request.sortType = direction;
    this.load();
  };

  onRowSelection = id => {
    this.props.onNavigate(`/devices/hub/${this.state.hubs[id].cap_id}/devices`, { offset: this.request.offset });
  };

  onOffsetChange = offset => {
    this.request.offset = offset;
    this.load();
  };

  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  onSearchQueryChange = query => {
    return this.onSearchQueryChangeDebounced(query);
  };

  onFiltersChange = filters => {
    return this.filtersDebounced(filters);
  };

  filtersDebounced = _.debounce(() => {
    const newFiltersForRequest = getFiltersForRequest(this.props.filters);
    if (
      JSON.stringify(newFiltersForRequest) !== this.props.filters &&
      JSON.stringify(newFiltersForRequest) !== JSON.stringify(this.request.filters)
    ) {
      this.request.filters = newFiltersForRequest;
      this.request.offset = 0;
    }
    this.load();
  }, 1000);

  onSearchQueryChangeDebounced = _.debounce(query => {
    this.request.offset = 0;
    this.request.search = query;
    this.load();
  }, 1000);

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 10000);
    this.load();
  };

  onDownload = () => {
    const downloadHandler = params => {
      const paramsWithType = params;
      paramsWithType.deviceType = DeviceTypes.Datahub.id;

      switch (params.selectedOption) {
        case 'events':
          delete paramsWithType.selectedOption;
          this.props.downloadEvents(params).then(this.onDownloadBtnClickSuccessHandler);
          break;
        case 'details':
          delete paramsWithType.selectedOption;
          paramsWithType.limit = 100000;
          paramsWithType.offset = 0;
          this.props.downloadDetails(params).then(this.onDownloadBtnClickSuccessHandler);
          break;
        default:
          this.props.downloadEvents(params).then(this.onDownloadBtnClickSuccessHandler);
      }
      this.props.showNotification('Download request sent, please wait...');
    };
    const downloadRequest = { ...this.request };
    delete downloadRequest.offset;
    delete downloadRequest.limit;
    if (!downloadRequest.lastUpdatedStart) {
      downloadRequest.lastUpdatedStart = DateFilters.AllTime.dates.startDate();
    }
    if (!downloadRequest.lastUpdatedEnd) {
      downloadRequest.lastUpdatedEnd = DateFilters.AllTime.dates.endDate();
    }

    let orgName = '';
    if (downloadRequest.organizationId) {
      orgName = this.state.organizations?.find(o => o.masked_id === downloadRequest.organizationId)?.name;
      if (downloadRequest.organizationId === no) {
        orgName = Strings.patientDashboard.noOrg;
      }
    }
    this.props.openDateRangeSelectionModal(downloadHandler, downloadRequest, orgName);
  };

  onDownloadBtnClickSuccessHandler = req => {
    if (req?.type === DOWNLOAD_REPORT_DATA_ERROR) {
      this.props.showNotification(req?.error);
    } else {
      const receivedName = req.headers['content-disposition'].split('filename=')[1];
      const fileName = `${moment().toString() + receivedName}`;
      downloadFileOnly(req, fileName);
    }
  };

  onSleepModeChange = state => {
    let message = 'Sleep mode disabled';
    if (state === 1) {
      message = 'Sleep mode enabled';
    }

    const request = {
      devices: this.itemSelectorHelper.getItems(),
      features: [
        {
          name: 'sleep',
          int_value: state,
        },
      ],
    };
    this.props.showNotification('Processing, please wait...');
    this.props.featureConfiguration(request).then(resp => {
      if (resp.type === FEATURE_CONFIGURATION_RESULT) {
        this.props.showNotification(message);
        this.getCaps();
      } else {
        this.props.showNotification('Error');
      }
    });
  };

  onPairingModeChange = value => {
    const message = `Pairing mode set`;

    const request = {
      devices: this.itemSelectorHelper.getItems(),
      features: [
        {
          name: 'pairing',
          int_value: value,
        },
      ],
    };
    this.props.showNotification('Processing, please wait...');
    this.props.featureConfiguration(request).then(resp => {
      if (resp.type === FEATURE_CONFIGURATION_RESULT) {
        this.props.showNotification(message);
        this.getCaps();
      } else {
        this.props.showNotification('Error');
      }
    });
  };

  onResetBle = () => {
    const request = {
      devices: this.itemSelectorHelper.getItems(),
      features: [
        {
          name: 'cmd',
          string_value: 'bleTalker reset',
        },
      ],
    };

    const data = {
      title: <span>{Strings.resetBleWarning}</span>,
      onConfirmAction: actions.featureConfiguration(request),
      onCancelAction: null,
      confirmPostAction: this.getCaps,
      hideCaution: true,
    };
    this.props.openConfirmModal(data);
  };

  onDateRangeChanged = option => {
    if (option) {
      this.request.lastUpdatedStart = option.dates.startDate();
      this.request.lastUpdatedEnd = option.dates.endDate();
    } else {
      this.request.lastUpdatedStart = '';
      this.request.lastUpdatedEnd = '';
    }
    this.request.offset = 0;

    this.load();
  };

  getHeaderComponents = () => {
    if (isCapManager() || isOrganizationManager() || isDoctor()) {
      return (
        <React.Fragment>
          <Button class="refreshTop" disabled={!this.refreshEnable} onClick={this.onRefresh} />
          <TextInput className="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
          {isBrownUniversity() && (
            <Button
              class={HEADER_BUTTON_DARK_BLUE}
              disabled={!this.refreshEnable}
              onClick={this.onDownload}
              title={Strings.download}
            />
          )}
          <Select
            data={[
              DateFilters.AllTime,
              DateFilters.Last48Hours,
              DateFilters.ThisMonth,
              DateFilters.Last30Days,
              DateFilters.LastMonth,
              DateFilters.Last6Months,
              DateFilters.ThisYear,
              DateFilters.Last12Months,
            ]}
            onChange={this.onDateRangeChanged}
            defaultValue={DateFilters.AllTime.value}
          />
          <AdvancedFilters lastButton>
            <Filter definition={getFiltersDefinition().Status} />
            <Filter definition={getFiltersDefinition().Reminder} />
            <Filter definition={getFiltersDefinition().Signal} />
            <Filter definition={getFiltersDefinition().Battery} />
            <Filter definition={getFiltersDefinition().Faults} />
          </AdvancedFilters>
          {!isBrownUniversity() && <Button class="download" onClick={this.onDownload} />}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <TextInput className="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        <AdvancedFilters lastButton>
          <Filter definition={getFiltersDefinition().Status} />
          <Filter definition={getFiltersDefinition().Reminder} />
          <Filter definition={getFiltersDefinition().Signal} />
          <Filter definition={getFiltersDefinition().Battery} />
          <Filter definition={getFiltersDefinition().Faults} />
        </AdvancedFilters>
      </React.Fragment>
    );
  };

  getSuperUserHeaderComponents = () => {
    const { onAttachCapToAdmin } = this.props;
    const { selectedOrg, organizations } = this.state;

    const data = [
      {
        value: all,
        label: Strings.patientDashboard.all,
      },
      {
        value: no,
        label: Strings.patientDashboard.noOrg,
      },
    ];

    if (organizations) {
      Object.values(organizations).forEach(organization => {
        data.push({
          value: organization.masked_id,
          label: organization.name,
        });
      });
    }

    return (
      <React.Fragment>
        <Button class="refreshTop" disabled={!this.refreshEnable} onClick={this.onRefresh} />
        <TextInput className="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        <Select data={data} value={selectedOrg} onChange={this.onOrgChange} isSearchable />
        <Select
          data={[
            DateFilters.AllTime,
            DateFilters.Last48Hours,
            DateFilters.ThisMonth,
            DateFilters.Last30Days,
            DateFilters.LastMonth,
            DateFilters.Last6Months,
            DateFilters.ThisYear,
            DateFilters.Last12Months,
          ]}
          onChange={this.onDateRangeChanged}
          defaultValue={DateFilters.AllTime.value}
        />
        <Button
          class={HEADER_BUTTON_DARK_BLUE}
          onClick={() => onAttachCapToAdmin(this.getCaps)}
          title={Strings.attachCap}
        />
        <AdvancedFilters lastButton>
          <Filter definition={getFiltersDefinition().Status} />
          <Filter definition={getFiltersDefinition().Reminder} />
          <Filter definition={getFiltersDefinition().Signal} />
          <Filter definition={getFiltersDefinition().Battery} />
          <Filter definition={getFiltersDefinition().Faults} />
        </AdvancedFilters>
        <Button class="download" onClick={this.onDownload} />
      </React.Fragment>
    );
  };

  remindersRender(value) {
    if (value == true) {
      return (
        <div className="reminders-container no-border">
          <div className="reminders on" />
        </div>
      );
    }
    return (
      <div className="reminders-container">
        <div className="reminders off" />
      </div>
    );
  }

  sleepModeRender(value) {
    if (value == true) return Strings.on;
    return Strings.off;
  }

  pairingModeRender(value) {
    if (value == 1) return Strings.factory;
    else if (value == 2) return Strings.customer;
    return Strings.off;
  }

  cityDisplay(value) {
    const location = value.split('"');
    const index = location.findIndex(e => e == 'city');
    if (index != -1) {
      return location[index + 2];
    }
    return '-';
  }

  onCapsDelete = (event, cap_id) => {
    event.preventDefault();
    event.stopPropagation();

    const selected_device = this.state.hubs.filter(hub => hub.cap_id === cap_id)?.pop();

    const request = {
      organization_id: selected_device?.organization?.masked_id,
      device_id: cap_id,
    };

    if (isCapManager() || isOrganizationManager()) {
      const data = {
        title: (
          <span>
            {Strings.deleteCapWarning} <b>{cap_id}</b>?
          </span>
        ),
        onConfirmAction: actions.deleteCapManagerCaps(request),
        confirmPostAction: this.getCaps,
        onCancelAction: null,
      };
      this.props.openConfirmModal(data);
    } else {
      const data = {
        title: (
          <span>
            {Strings.deleteCapWarning} <b>{cap_id}</b> from organization <b>{selected_device?.organization?.name}</b>?
          </span>
        ),
        onConfirmAction: actions.deleteCaps(request),
        onCancelAction: null,
        confirmPostAction: this.getCaps,
      };
      this.props.openConfirmModal(data);
    }
  };

  itemChecked = ({ target }) => {
    if (target.checked === true) {
      this.itemSelectorHelper.addItem(target.id);
    } else {
      this.itemSelectorHelper.removeItem(target.id);
    }

    if (this.itemSelectorHelper.getItems().length !== 0) {
      this.setState({ openActionWidget: true });
    } else {
      this.setState({ openActionWidget: false });
    }

    this.setState({ multiselectedItems: this.itemSelectorHelper.isMultiselect() });
  };

  onCapsSendPin = (event, cap_id) => {
    event.preventDefault();
    event.stopPropagation();

    const data = {
      onConfirmAction: actions.sendCapsPin,
      actionType: SEND_SECURITY_CODE_CAP,
      onCancelAction: null,
      capId: cap_id,
      postAction: this.getCaps,
    };
    this.props.openSendPinModal(data);
  };

  onReminderChange = state => {
    let reminderTimeout = 0;
    let message = 'Reminder disabled';
    if (state === 1) {
      reminderTimeout = 24;
      message = 'Reminder enabled';
    }

    const request = {
      devices: this.itemSelectorHelper.getItems(),
      features: [
        {
          name: 'reminderTimeout',
          int_value: reminderTimeout,
        },
      ],
    };
    this.props.showNotification('Processing, please wait...');
    this.props.featureConfiguration(request).then(resp => {
      if (resp.type === FEATURE_CONFIGURATION_RESULT) {
        this.props.showNotification(message);
        this.getCaps();
      } else {
        this.props.showNotification('Error');
      }
    });
  };

  statusRender = (status, ble_reset) => {
    const content = (
      <div className="status-with-info">
        {status || '-'}
        {ble_reset ? <div className="signal-alert info" /> : <React.Fragment />}
      </div>
    );

    const tooltip = Strings.awaitingBleReset;
    return ble_reset ? toolTipForTable(content, tooltip) : content;
  };

  render() {
    const { pagination, isLoading, hubs } = this.state;
    const columns = [];
    columns.push(
      <Column
        key="capid"
        title={Strings.deviceId}
        value={e => (
          <React.Fragment>
            <div className="cell-with-select">
              <div className="selector">
                <input
                  type="checkbox"
                  className="item-checkbox"
                  id={e.cap_id}
                  onChange={this.itemChecked}
                  key={this.state.keyForItemCheckboxes}
                />
              </div>
              <div className="selector-label">{e.cap_id}</div>
            </div>
          </React.Fragment>
        )}
        sortKey="cap_id"
        className="fix"
        fixed
      />,
    );
    columns.push(<Column key="cap_name" title={Strings.deviceName} value={e => e.cap_name || '-'} />);
    columns.push(<Column key="imei" title={Strings.imei} value={e => e.imei || '-'} />);
    columns.push(<Column key="kit_id" title={Strings.kitId} value={e => e.kit_id || '-'} />);

    if (isShipper() || isSuperUser()) {
      columns.push(
        <Column
          key="organization"
          title={Strings.organizationName}
          value={e => e.organization?.name || '-'}
          sortKey="org_name"
        />,
      );
    }

    columns.push(<Column key="status" title={Strings.status} value={e => this.statusRender(e.status, e.ble_reset)} />);
    columns.push(<Column key="signalstrength" title={Strings.signal} value={e => signalRender(e.signal_strength)} />);
    columns.push(<Column key="sleepmode" title={Strings.sleepMode} value={e => this.sleepModeRender(e.sleep_mode)} />);
    columns.push(
      <Column key="pairingmode" title={Strings.pairingMode} value={e => this.pairingModeRender(e.pairing)} />,
    );
    columns.push(<Column key="reminders" title={Strings.reminders} value={e => this.remindersRender(e.reminder)} />);
    columns.push(
      <Column key="batterylevel" title={Strings.battery.battery} value={d => getCapChargeText(d.battery)} />,
    );
    columns.push(<Column key="fota" title={Strings.fota} value={e => e.firmware91 || null} />);
    columns.push(<Column key="pins" title={Strings.pins} value={e => e.pins || null} />);
    columns.push(
      <Column
        key="devices"
        title={Strings.devices}
        value={e => (e.devices ? toolTipForTable(e.devices, e.deviceIds) : null)}
      />,
    );
    columns.push(<Column key="readings" title={Strings.readings} value={e => e.readings} />);
    columns.push(
      <Column key="location" title={Strings.location} value={e => (e.location ? this.cityDisplay(e.location) : '-')} />,
    );
    columns.push(
      <Column
        key="last_updated_at"
        title={Strings.lastActivity}
        value={e => (e.last_updated_at ? convertUnixEpochToHumanReadable(e.last_updated_at) : '-')}
        sortKey="last_updated_at"
      />,
    );
    columns.push(<Column key="activitytype" title={Strings.activityType} value={e => e.last_activity_type || '-'} />);

    const actionBtns = (
      <React.Fragment>
        <ActionDropdownOnOffMenu
          img="action-sleepmode"
          tooltiptext={Strings.sleepMode}
          text={Strings.sleepMode}
          action={value => {
            this.onSleepModeChange(value);
          }}
        />
        <ActionDropdownSingleChoiceMenu
          img="action-pairingmode"
          tooltiptext={Strings.pairingMode}
          options={[
            {
              value: 0,
              text: Strings.off,
            },
            {
              value: 1,
              text: Strings.factoryMode,
            },
            {
              value: 2,
              text: Strings.customerMode,
            },
          ]}
          action={value => {
            this.onPairingModeChange(value);
          }}
        />
        <ActionDropdownOnOffMenu
          img="action-reminder"
          tooltiptext="Reminder"
          text="Reminder"
          action={value => {
            this.onReminderChange(value);
          }}
        />
        <ActionButton
          img="action-pinset"
          tooltiptext="Set PIN"
          text="Set PIN"
          action={e => {
            this.onCapsSendPin(e, this.itemSelectorHelper.getFirstItem());
          }}
          disabled={this.state.multiselectedItems}
        />
        <ActionButton
          img="action-reset"
          tooltiptext={Strings.resetBle}
          text={Strings.resetBle}
          action={this.onResetBle}
        />
        {!isOmron() && !isCapManager() ? (
          <ActionButton
            img="action-delete"
            tooltiptext="Delete"
            text="Delete"
            action={e => {
              this.onCapsDelete(e, this.itemSelectorHelper.getFirstItem());
            }}
            disabled={this.state.multiselectedItems}
          />
        ) : null}
      </React.Fragment>
    );
    const headerTitle =
      !isLoading && hubs && pagination
        ? `${Strings.showingXHubs.showing} ${hubs.length} ${Strings.showingXHubs.of} ${
            pagination.totalRecords ? pagination.totalRecords : 0
          } ${Strings.showingXHubs.hubs}`
        : Strings.showingWait;

    return (
      <div>
        <PageHeader
          isBlack
          left={headerTitle}
          right={isShipper() || isSuperUser() ? this.getSuperUserHeaderComponents() : this.getHeaderComponents()}
        />
        <AdvancedFiltersBar />
        <TableWithPagination
          isLoading={isLoading}
          name="hub"
          uuid="659582c8-4510-40dd-aaf7-d648ad6333b8"
          data={hubs}
          onRowClick={this.onRowSelection}
          onOffsetChange={this.onOffsetChange}
          pagination={pagination}
          onSortClick={this.onSortClick}
          excludeColumns={this.state.excludeColumns}
          key={this.state.keyForTable}
          freezeFirstColumn
          enableColumnFiltering
        >
          {columns}
        </TableWithPagination>
        <ActionWidget show={this.state.openActionWidget}>{actionBtns}</ActionWidget>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Hub));
