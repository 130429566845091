import React, { PureComponent } from 'react';
import cn from 'classnames';

import styles from '../Input.module.scss';

export class TextInput extends PureComponent {
  onKeyDown(e) {
    const key = e.key;

    if (key.length === 1 && /\D/.test(key)) {
      e.preventDefault();
    }
  }

  spaceKeyDown(e) {
    const key = e.key;

    if (key === ' ') {
      e.preventDefault();
    }
  }

  render() {
    const { type, align, error, refLink, onlyDigits, noSpaces, className, ...props } = this.props;

    return (
      <div>
        <input
          {...props}
          className={cn(styles.textInput, {
            [styles.alignCenter]: align === 'center',
            [styles.alignLeft]: align === 'left',
            [styles.inputError]: Boolean(error),
            [className]: className,
          })}
          type={type || 'text'}
          ref={refLink}
          onKeyDown={onlyDigits ? this.onKeyDown : null || noSpaces ? this.spaceKeyDown : null}
        />
        {error ? <div className={styles.error}>{error}</div> : null}
      </div>
    );
  }
}
