export const PROVISIONING = 'provisioning';
export const PROVISIONING_RESULT = `${PROVISIONING}/result`;
export const PROVISIONING_ERROR = `${PROVISIONING}/error`;

export const GET_PROVISIONING_DEVICES = 'get-provisioning/devices';
export const GET_PROVISIONING_DEVICES_RESULT = `${GET_PROVISIONING_DEVICES}/result`;
export const GET_PROVISIONING_DEVICES_ERROR = `${GET_PROVISIONING_DEVICES}/error`;

export const GET_IMEI_FROM_ID_MFG = 'get-imei-from-id-mfg';
export const GET_IMEI_FROM_ID_MFG_RESULT = `${GET_IMEI_FROM_ID_MFG}/result`;
export const GET_IMEI_FROM_ID_MFG_ERROR = `${GET_IMEI_FROM_ID_MFG}/error`;

export const POST_PROVISIONING_DEVICES = 'add-provisioning/devices';
export const POST_PROVISIONING_DEVICES_RESULT = `${POST_PROVISIONING_DEVICES}/result`;
export const POST_PROVISIONING_DEVICES_ERROR = `${POST_PROVISIONING_DEVICES}/error`;

export const PUT_PROVISIONING_DEVICES = 'update-provisioning/devices';
export const PUT_PROVISIONING_DEVICES_RESULT = `${PUT_PROVISIONING_DEVICES}/result`;
export const PUT_PROVISIONING_DEVICES_ERROR = `${PUT_PROVISIONING_DEVICES}/error`;

export const DELETE_PROVISIONING_DEVICES = 'delete-provisioning/devices';
export const DELETE_PROVISIONING_DEVICES_RESULT = `${DELETE_PROVISIONING_DEVICES}/result`;
export const DELETE_PROVISIONING_DEVICES_ERROR = `${DELETE_PROVISIONING_DEVICES}/error`;

export const SHIPPER_CAPS_DETAILS = 'shipper-caps-details';
export const CLEAR_DATA = 'clear-data';
