import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { notificationActions } from '../../../components/Notification/redux/actions';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../components/PageHeader/Button';
import Strings from '../../../Strings';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import { PageHeader } from '../../../components/PageHeader';
import { GET_EXTERNAL_API_LOGS_RESULT, actions } from './actions';
import { DATE_MONTH_DAY_YEAR, PAGE_LIMIT } from '../../../constants';
import moment from 'moment';
import Select from '../../../components/Select';
import { DateFilters } from '../../../utils/DateFilters';
import { SystemConfigurationTabs } from '../SystemConfiguration/SystemConfiguration';

function ExternalApiLogs(props) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [logs, setLogs] = React.useState([]);
  const [pagination, setPagination] = React.useState({});

  const pageRequest = useRef({
    offset: 0,
    limit: PAGE_LIMIT,
  });

  !props.master && (pageRequest.current.org_uuid = props.organizationId);

  const load = () => {
    setIsLoading(true);
    props.getLogs(pageRequest.current).then(resp => {
      if (resp.type === GET_EXTERNAL_API_LOGS_RESULT) {
        setLogs(resp.response.logs);
        setPagination(resp.response.pagination);
      } else {
        props.showNotification(Strings.errors.internalError, 5000);
        setLogs([]);
        setPagination({});
      }
      setIsLoading(false);
    });
  };

  React.useEffect(() => {
    load();
  }, []);

  const columns = [
    <Column title={Strings.date} key="date" value={e => moment(e.created_at).format(DATE_MONTH_DAY_YEAR)} />,
    <Column title={Strings.time} key="time" value={e => moment(e.created_at).format('h:mm a')} />,
  ];

  props.master &&
    columns.push(
      <Column title={Strings.organization} key="organization" value={e => (e.org_name ? e.org_name : '-')} />,
    );

  columns.push(<Column title={Strings.reason} key="reason" value={e => e.reason} />);
  columns.push(<Column title={Strings.url} key="url" value={e => e.url} />);
  columns.push(<Column title={Strings.ip} key="ip" value={e => e.ip} />);

  const onCustomPage = page => {
    pageRequest.current.offset = (page - 1) * PAGE_LIMIT;
    load();
  };

  const onPrevClick = () => {
    pageRequest.current.offset = pageRequest.current.offset - PAGE_LIMIT;
    load();
  };

  const onNextClick = () => {
    pageRequest.current.offset = pageRequest.current.offset + PAGE_LIMIT;
    load();
  };

  const navigateToMasterLog = (
    <Button
      class={HEADER_BUTTON_DARK_BLUE}
      onClick={() => props.navigate(SystemConfigurationTabs.ExternalApiLogs.path)}
      title={Strings.masterLog}
    />
  );

  const onDateRangeChange = dateRange => {
    if (dateRange) {
      pageRequest.current.startDate = dateRange.dates.startDate();
      pageRequest.current.endDate = dateRange.dates.endDate();
    } else {
      pageRequest.current.startDate = '';
      pageRequest.current.endDate = '';
    }
    pageRequest.current.offset = 0;
    load();
  };

  const rightPageHeader = () => {
    return (
      <>
        <Select
          data={[
            DateFilters.AllTime,
            DateFilters.Last48Hours,
            DateFilters.ThisMonth,
            DateFilters.Last30Days,
            DateFilters.LastMonth,
            DateFilters.Last6Months,
            DateFilters.ThisYear,
            DateFilters.Last12Months,
          ]}
          onChange={onDateRangeChange}
          defaultValue={DateFilters.AllTime.value}
        />
        {!props.master ? navigateToMasterLog : <></>}
      </>
    );
  };

  return (
    <>
      <PageHeader left={Strings.externalApiLogsTab} right={rightPageHeader()} />
      <div className="external-api-configuration-table-container">
        <Table
          name="external-api-logs"
          data={logs}
          pagination={pagination}
          isLoading={isLoading}
          onCustomPage={onCustomPage}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
        >
          {columns}
        </Table>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getLogs: params => dispatch(actions.getLogs(params)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  navigate: path => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(ExternalApiLogs);
