import { PageHeader } from 'components/PageHeader';
import { Button } from 'components/PageHeader/Button';

import Info from 'pages/patient/ESIPatient/Info';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// import Strings from "../../Strings";
import {
  PATIENT_PORTAL_UPDATE_PATIENT_NOTIFICATIONS,
  PATIENT_PORTAL_UPDATE_PATIENT_SETTINGS,
  POST_ADD_CAREGIVER,
  UPDATE_PATIENT_SETTINGS,
} from '../../actions/action-types';
import { openModalAction } from '../../actions/modal';
import { patientAction } from '../../actions/patient';
import { patientPortalActions } from '../../actions/patient-portal';
import { userSettingsAction } from '../../actions/user-settings';
import LoadingRenderer from '../../components/LoadingRenderer';
import PatientNotificationsForm from '../../components/PatientNotificationsForm';
import PatientProfileForm from '../../components/PatientProfileForm';
import TabView, { Tab } from '../../components/TabView/index';
import WithLoader from '../../components/_hocs/withLoader/WithPaginationLoader';
import { CAP_MANAGER, HEADER_BUTTON_BLUE, SUPER_ADMIN } from '../../constants';
import Strings from '../../Strings';
import { isESIPatient, isPatient } from '../../utils/userRoles';
import { sendRestPasswordMessage } from '../../actions/auth';

const mapStateToProps = state => {
  const user = state.user.settings;
  if (user) {
    user.dateOfBirth = user.date_of_birth;
    user.firstName = user.first_name;
    user.lastName = user.last_name;
  }

  return {
    user,
    isLoading: !state.user.settings || state.user.loading,
    changing: !!state.user.changing,
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  loadUserSettings: () => dispatch(userSettingsAction.actionGet()),
  updatePatientProfile: data => dispatch(patientPortalActions.actionUpdateProfile(data)),
  updatePatientNotifications: data =>
    dispatch(patientPortalActions.actionUpdateNotifications(data)).then(response => {
      dispatch(userSettingsAction.actionGet());
      return response;
    }),
  onAddCaregiver: patientId =>
    dispatch(
      openModalAction('add-caregiver', {
        action: patientAction.addCaregiver,
        actionType: POST_ADD_CAREGIVER,
        patientId,
      }),
    ),
  onEditProfile: () =>
    dispatch(
      openModalAction('edit-user-profile', {
        action: patientAction.actionUpdateProfile,
        actionType: UPDATE_PATIENT_SETTINGS,
      }),
    ),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
});

class PatientSettingsPage extends PureComponent {
  static defaultProps = { activeTab: 'Details' };

  static propTypes = {
    loadUserSettings: PropTypes.func.isRequired,
    onAddCaregiver: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    user: PropTypes.object,
    activeTab: PropTypes.string,
    updatePatientProfile: PropTypes.func.isRequired,
    updatePatientNotifications: PropTypes.func.isRequired,
    changing: PropTypes.bool,
    isLoading: PropTypes.bool,
    onEditProfile: PropTypes.func.isRequired,
    openConfirmModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onAddCaregiver = this.onAddCaregiver.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.loadUserSettings();
  }

  onAddCaregiver() {
    this.props.onAddCaregiver(this.props.auth.id);
  }

  changePassword = () => {
    const { user } = this.props;
    const data = {
      title: (
        <span>
          {Strings.changePasswordWarning} <b>{user.email}</b>?
        </span>
      ),
      hideCaution: true,
      onConfirmAction: sendRestPasswordMessage({
        method: 'email',
        email: user.email,
      }),
      onCancelAction: null,
      caption: Strings.changePassword,
    };

    this.props.openConfirmModal(data);
  };

  getHeaderComponents = () => {
    return (
      <React.Fragment>
        {!isESIPatient() && (
          <Button class={HEADER_BUTTON_BLUE} onClick={this.changePassword} title={Strings.changePassword} />
        )}
      </React.Fragment>
    );
  };

  render() {
    const { user = {}, activeTab, updatePatientProfile, updatePatientNotifications, auth, isLoading } = this.props;

    const isPermit = !(auth.role === SUPER_ADMIN || auth.role === CAP_MANAGER);
    if (!user) {
      return <LoadingRenderer loading />;
    }

    return (
      <div className="patient-settings content-block patient-page-content schedule-details shipper setting-page">
        <div>
          <TabView key="settingsTabs" activeTab={activeTab} routeMode>
            <Tab name="Details" path="/settings">
              <WithLoader isLoading={isLoading}>
                {!isPatient() ? (
                  <React.Fragment>
                    <PageHeader title={Strings.settings} getHeaderComponents={() => this.getHeaderComponents()} />
                    <PatientProfileForm
                      isPermit={isPermit}
                      patient={user}
                      readOnlyMode={false}
                      updateProfileFunc={updatePatientProfile}
                      actionType={PATIENT_PORTAL_UPDATE_PATIENT_SETTINGS}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <PageHeader title={Strings.settings} getHeaderComponents={() => this.getHeaderComponents()} />
                    <Info patient={user} isLoading={isLoading} isSettings />
                  </React.Fragment>
                )}
              </WithLoader>
            </Tab>
            {false && isPermit ? ( // eslint-disable-line no-constant-condition
              <Tab name="Notifications" path="/settings/notifications">
                <WithLoader isLoading={isLoading}>
                  {user ? (
                    <PatientNotificationsForm
                      patient={user}
                      readOnlyMode={false}
                      updateNotificationsFunc={updatePatientNotifications}
                      actionType={PATIENT_PORTAL_UPDATE_PATIENT_NOTIFICATIONS}
                    />
                  ) : (
                    ''
                  )}
                </WithLoader>
              </Tab>
            ) : (
              ''
            )}
          </TabView>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientSettingsPage);
