import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import GenericLineGraph from './GenericLineGraph';
import Strings from '../../Strings';

export default class YourKitsGraph extends PureComponent {
  render() {
    const dataSeries = [
      {
        name: 'Kits',
        color: '#0060AA',
        selector: item => item.kits,
      },
      {
        name: 'Low Signals',
        color: '#DA547D',
        selector: item => item.lowSignals,
      },
    ];

    return (
      <GenericLineGraph
        graphData={this.props.graphData}
        hasBorder={false}
        forceCondensed
        xAxisTopPosition={false}
        xAxisLabelsMinMaxOnly
        yAxisWidth={40}
        yAxisUnit=""
        tooltipTitle={Strings.yourKits}
        dataSeries={dataSeries}
        dateSelector={item => item.date}
        hasReferenceAreas={false}
      />
    );
  }
}

YourKitsGraph.propTypes = { graphData: PropTypes.array };
