import { trialsAction } from 'actions/trials';

import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { SEND_INVITE_ERROR, SEND_INVITE_RESULT } from '../actions/action-types';
import { resetForm } from '../actions/forms';
import { closeModal } from '../actions/modal';
import { Modal } from '../containers';
import Form, { Input } from '../containers/Form';
import Strings from '../Strings';
import './editStudyModal.scss';

class InvitePatientModal extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      patient: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),

      action: PropTypes.func.isRequired,
      actionType: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const newPatient = {
      id: 0,
      email: '',
    };

    this.state = {
      errors: [],
      patient: this.props.data.patient ? cloneDeep(this.props.data.patient) : cloneDeep(newPatient),
    };
  }

  onTextChange = event => {
    const patient = cloneDeep(this.state.patient);
    patient[event.target.getAttribute('name')] = event.target.value;
    this.setState({ patient });
  };

  onSubmit = () => {
    const { patient } = this.state;

    const validationErrors = [];

    if (!patient.email.length) {
      validationErrors.push(Strings.errors.emptyEmailAddress);
    }

    if (validationErrors.length) {
      this.setState({ errors: validationErrors });
      return;
    }

    this.props.onSubmit(patient).then(response => {
      if (response.type === SEND_INVITE_ERROR && response.response.data) {
        const responseData = response.response.data;
        const errors = Object.keys(responseData).map(key => `${key}: ${responseData[key]}`);
        this.setState({ errors });
      } else if (response && response.type === SEND_INVITE_RESULT) {
        this.props.onInvitedSuccess({
          startDate: moment()
            .subtract(6, 'day')
            .toDate(),
          endDate: moment().toDate(),
        });
      }
      return response;
    });
  };

  render() {
    const { onOpen, onCancel, ...props } = this.props;

    const { errors } = this.state;

    const patient = cloneDeep(this.state.patient);

    return (
      <Modal name="new-trial" onOpen={onOpen} additionalClasses={['form-modal', 'user-invite']} {...props}>
        <Form onSubmit={this.onSubmit} onCancel={onCancel} id="new-trial">
          <div className="flex-row">
            <div key="patient-email" className="row-item">
              <Input
                name="email"
                id="email"
                className="dialog-form"
                label=""
                placeholder={Strings.placeholder.emailAddress}
                type="email"
                defaultValue={patient.email}
                onChange={this.onTextChange}
              />
            </div>
          </div>
          <div key="errors-block" className="errors-block">
            {errors.length ? <div>{errors.join(' ')}</div> : null}
          </div>
          <div className="button-bar reverse" key="button-bar">
            <button className="brand-blue" key="submit" type="submit">
              {Strings.done}
            </button>

            <button className="white gray-text" key="cancel" type="cancel">
              {Strings.cancel}
            </button>
          </div>
        </Form>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('invite-patient')),
  onInvitedSuccess: trialRequest => dispatch(trialsAction.actionOverView(trialRequest)),
  onSubmit: patient =>
    dispatch(patient.id ? ownProps.data.action(patient.id, patient) : ownProps.data.action(patient)).then(response => {
      if (response && response.type === `${ownProps.data.actionType}/result`) {
        dispatch(closeModal('invite-patient'));
      }
      return response;
    }),
  onOpen: () => dispatch(resetForm('invite-patient', ownProps.data)),
});

export default connect(null, mapDispatchToProps)(InvitePatientModal);
