import {
  POST_CAP_MANAGER_INVITE,
  POST_CAP_MANAGER_INVITE_RESULT,
  ADD_NICK_NAME_RESULT,
  ADD_NICK_NAME,
  ADD_NICK_NAME_ERROR,
  DELETE_CAP_PATIENT_ERROR,
  DELETE_CAP_PATIENT_RESULT,
  DELETE_CAP_PATIENT,
  ADD_HARDWARE_ID_RESULT,
  ADD_HARDWARE_ID_ERROR,
  ADD_HARDWARE_ID,
} from '../actions/action-types';

const capsReducer = (
  state = {
    pagination: {},
    patients: [],
    errors: {},
  },
  action,
) => {
  const { response } = action;

  switch (action.type) {
    case ADD_NICK_NAME:
      return {
        ...state,
        loading: true,
        isPreparing: true,
      };

    case ADD_NICK_NAME_RESULT:
      return {
        ...state,
        capDetails: response,
        loading: false,
        isPreparing: false,
      };

    case ADD_NICK_NAME_ERROR:
      return {
        ...state,
        loading: false,
        isPreparing: false,
      };

    case POST_CAP_MANAGER_INVITE: {
      const manager = state.admin;
      const tempAdmin = manager.map(user => {
        const d = user;
        d.isLoading = true;
        return d;
      });
      return {
        ...state,
        admin: tempAdmin,
        loading: true,
        isPreparing: true,
      };
    }

    case POST_CAP_MANAGER_INVITE_RESULT:
      state.admin.splice(0, 0, response);
      return {
        ...state,
        loading: false,
        isPreparing: false,
      };

    case ADD_HARDWARE_ID:
      return {
        ...state,
        loading: true,
      };

    case ADD_HARDWARE_ID_RESULT:
      return {
        ...state,
        loading: false,
      };

    case ADD_HARDWARE_ID_ERROR:
      return {
        ...state,
        errors: action.response.data.error,
        loading: false,
      };

    case DELETE_CAP_PATIENT: {
      const patientId = action.data;
      const { patients } = state;
      const updatedPatients = patients.map(user => {
        const u = user;
        u.isLoading = user.user_id === patientId;
        return u;
      });
      return {
        ...state,
        patients: updatedPatients,
        loading: true,
        isPreparing: true,
      };
    }

    case DELETE_CAP_PATIENT_RESULT:
      if (action.response) {
        const patientId = action.data;
        const { patients } = state;

        const updatedPatients = patients.filter(user => {
          return user.user_id !== patientId;
        });
        return {
          ...state,
          patients: updatedPatients,
          loading: false,
        };
      }
      return state;

    case DELETE_CAP_PATIENT_ERROR: {
      const userId = action.data;
      const users = state.patients;
      const updatedUsers = users.map(user => {
        const p = user;
        p.isLoading = user.user_id === userId;
        return p;
      });
      return {
        ...state,
        patients: updatedUsers,
        loading: false,
        isPreparing: false,
      };
    }

    default:
      return state;
  }
};

export default capsReducer;
