import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import Strings from '../../Strings';
import { CustomizedXAxisTickNumeric, CustomizedYAxisTickNumeric, getNumericAxisParams } from './GraphAxes';
import { getColorsForColorTheme } from '../../utils/colorTheme';

class SpirometryFlowVolumeCurveGraph extends PureComponent {
  render() {
    const { fvc, inhalePoints, exhalePoints } = this.props;
    const points = [...inhalePoints, ...exhalePoints];

    const yAxisParams = getNumericAxisParams(points.map(p => p.y));
    const xAxisParams = getNumericAxisParams(
      points.map(p => p.x),
      { extraValues: [fvc] },
    );

    const graphReferenceLineColor = '#74b236';
    const graphLineColor = getColorsForColorTheme().mainColor;
    const graphLineWidth = 2;

    return (
      <div className="graphDivZ">
        <ResponsiveContainer width="100%" height={426}>
          <ComposedChart
            defaultShowTooltip={false}
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <XAxis
              type="number"
              dataKey="x"
              allowDataOverflow={false}
              height={30}
              interval={0}
              name="Volume (L)"
              orientation="bottom"
              tickLine
              tickSize={5}
              domain={xAxisParams.domain}
              ticks={xAxisParams.ticks}
              tick={
                <CustomizedXAxisTickNumeric
                  xMin={xAxisParams.minValue}
                  xMax={xAxisParams.maxValue}
                  height={30}
                  unit="L"
                />
              }
            />
            <YAxis
              type="number"
              dataKey="y"
              allowDataOverflow={false}
              name="Flow"
              unit=""
              tick={
                <CustomizedYAxisTickNumeric
                  yMin={yAxisParams.minValue}
                  yMax={yAxisParams.maxValue}
                  width={70}
                  unit="L/s"
                />
              }
              domain={yAxisParams.domain}
              ticks={yAxisParams.ticks}
              width={70}
            />

            <Line
              type="monotone"
              connectNulls
              activeDot={false}
              dot={false}
              stroke={graphLineColor}
              strokeWidth={graphLineWidth}
              data={inhalePoints}
              dataKey="y"
            />

            <CartesianGrid strokeDasharray="4 1 2" />

            <ReferenceLine y={0} label="" stroke="#666" />
            <ReferenceLine x={0} label="" stroke="#666" />
            <ReferenceLine x={fvc} label="FVC" stroke={graphReferenceLineColor} />

            <Line
              type="monotone"
              connectNulls
              activeDot={false}
              dot={false}
              stroke={graphLineColor}
              strokeWidth={graphLineWidth}
              strokeDasharray="3 3"
              data={exhalePoints}
              dataKey="y"
            />

            {/* <Tooltip content={<CustomTooltip />} dataKey="y" isAnimationActive={false} /> */}
          </ComposedChart>
        </ResponsiveContainer>
        <div style={{ textAlign: 'center', fontSize: '18px' }}>{Strings.flowVolumeCurve}</div>
      </div>
    );
  }
}

SpirometryFlowVolumeCurveGraph.propTypes = {
  fvc: PropTypes.number,
  inhalePoints: PropTypes.array,
  exhalePoints: PropTypes.array,
};

export default React.memo(SpirometryFlowVolumeCurveGraph);
