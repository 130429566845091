import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Rows } from './Rows';
import { Row } from './Row';
import { RowText } from './RowText';
import { RowLabel } from './RowLabel';
import Strings from '../../../Strings';
import { getMedIcon } from '../../../utils/medIcons';
import {
  MED_ICON_COLOR_FILTER_GREEN,
  MED_ICON_COLOR_FILTER_PINK,
  MED_ICON_COLOR_FILTER_ORANGE,
} from '../../../constants';
import { RowImage } from './RowImage';

export class Stocks extends PureComponent {
  render() {
    const { data } = this.props;

    const rows = [];

    data.forEach(e => {
      const type =
        e.stock_status === 'high'
          ? RowLabel.TYPE_GOOD
          : e.stock_status === 'medium'
          ? RowLabel.TYPE_AVERAGE
          : RowLabel.TYPE_POOR;

      const typeImage =
        e.stock_status === 'high'
          ? MED_ICON_COLOR_FILTER_GREEN
          : e.stock_status === 'medium'
          ? MED_ICON_COLOR_FILTER_ORANGE
          : MED_ICON_COLOR_FILTER_PINK;

      rows.push(
        <Row>
          <RowImage image={getMedIcon(e?.format?.route)} style={{ filter: typeImage }} />
          <RowText>{e.name}</RowText>
          <RowLabel title={`${Strings.patientDashboard.doses}:`} type={type}>
            {e.stock} {Strings.left}
          </RowLabel>
        </Row>,
      );
    });

    return <Rows rows={rows} />;
  }
}

Stocks.propTypes = { data: PropTypes.shape({ forEach: PropTypes.func }) };
