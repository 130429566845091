import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Input } from '../containers/Form';
import Strings from '../Strings';

class AutoComplete extends PureComponent {
  static propTypes = {
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        text: PropTypes.string.isRequired,
      }),
    ).isRequired,
    onSelect: PropTypes.func.isRequired,
    inputProps: PropTypes.shape(),
  };

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      // eslint-disable-next-line react/no-unused-state
      value: 0,
      choices: this.props.choices,
      opened: false,
    };

    this.onSelect = this.onSelect.bind(this);
    this.onAutocompleteChange = this.onAutocompleteChange.bind(this);
    this.showHideItems = this.showHideItems.bind(this);
  }

  onSelect(choice) {
    const { text, value } = choice;
    // eslint-disable-next-line react/no-unused-state
    this.setState({
      text,
      value,
      opened: false,
    });
    this.props.onSelect(choice);
  }

  onAutocompleteChange(event) {
    const text = event.target.value;
    const choices = this.props.choices.filter(choice => choice.text.toLowerCase().indexOf(text.toLowerCase()) !== -1);

    this.setState({
      text,
      choices,
    });
  }

  showHideItems(status) {
    this.setState({ opened: status });
  }

  render() {
    const { inputProps } = this.props;
    const { text, choices, opened } = this.state;

    return (
      <div className="autocomplete" key="autocomplete">
        <div
          className={`select ${opened ? 'opened' : ''}`}
          onMouseEnter={() => this.showHideItems(true)}
          onMouseLeave={() => this.showHideItems(false)}
          onKeyDown={() => this.showHideItems(true)}
        >
          <Input
            label=""
            type="text"
            onChange={this.onAutocompleteChange}
            value={text}
            autoComplete="off"
            {...inputProps}
          />

          <div className="line" key="line" />

          <div className="items-wrapper">
            <div className="items" key="items">
              {choices.length ? (
                choices.map(choice => (
                  <div className="item" key={`${choice.value} / ${choice.text}`} onClick={() => this.onSelect(choice)}>
                    {choice.text}
                  </div>
                ))
              ) : (
                <div className="item">{Strings.notFound}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AutoComplete;
