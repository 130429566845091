import { PageHeader } from 'components/PageHeader';
import { Button } from 'components/PageHeader/Button';

import merge from 'deepmerge';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { POST_USER_SETTINGS_ERROR, UPDATE_PATIENT_SETTINGS } from '../../actions/action-types';
import { getCountries } from '../../actions/countries';
import { openModalAction } from '../../actions/modal';
import { patientAction } from '../../actions/patient';
import { userSettingsAction } from '../../actions/user-settings';
import TabView, { Tab } from '../../components/TabView';
import { UsersCard } from '../../components/UsersCard/UsersInfoCard';
import WithLoader from '../../components/_hocs/withLoader/WithPaginationLoader';
import { EMAIL_PATTERN, HEADER_BUTTON_BLUE, MAX_DATE, MAX_MONTH } from '../../constants';
import Strings from '../../Strings';
import { getOptionsDays, getOptionsMonths, getOptionsYears, isValidBirthDate } from '../../utils';
import Workflows from './Workflows';
import { PERMISSIONS, hasPermission } from '../../utils/userPermissions';
import Lab from './Lab';
import { sendRestPasswordMessage } from '../../actions/auth';

const mapStateToProps = ({ countries, user, auth }) => ({
  countries: countries.countries,
  timezones: countries.timezones,
  user: user.settings,
  profile: auth.profile,
});

const mapDispatchToProps = dispatch => ({
  getCountries: () => dispatch(getCountries()),
  loadUserSettings: () => dispatch(userSettingsAction.actionGet()),
  postSettings: data => dispatch(userSettingsAction.actionPost(data)),
  onNavigate: path => dispatch(push(path)),
  onEditProfile: () =>
    dispatch(
      openModalAction('edit-user-profile', {
        action: patientAction.actionUpdateProfile,
        actionType: UPDATE_PATIENT_SETTINGS,
      }),
    ),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
});

class SettingsPage extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    loadUserSettings: PropTypes.func.isRequired,
    getCountries: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    postSettings: PropTypes.func.isRequired,
    onEditProfile: PropTypes.func.isRequired,
    openConfirmModal: PropTypes.func.isRequired,
  };

  state = {
    isLoading: true,
    first_name: '',
    last_name: '',
    username: '',
    date_of_birth: '',
    date_of_birth_year: '',
    date_of_birth_month: '',
    date_of_birth_day: '',
    mobile_phone: '',
    email: '',
    updateInProcess: false,
    status_class: 'success',
    error_msg: '',
    preferences: {
      timezone: '',
      country: '',
      city: '',
      sms_threshold: '',
      email_threshold: '',
      phone_threshold: '',
      sms_notice_enabled: false,
      email_notice_enabled: false,
      phone_notice_enabled: false,
    },
  };

  UNSAFE_componentWillMount() {
    if (!this.props.user) {
      this.props.loadUserSettings().then(() => this.setState({ isLoading: false }));
      this.props.getCountries();
    } else {
      this.setUser(this.props.user);
      this.setState({ isLoading: false });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.user) {
      this.setUser(newProps.user);
    }
  }

  onChangeComponent = obj => {
    this.setState(obj);
  };

  onChange = event => {
    const name = event.target.getAttribute('name');
    const value = event.target.value.trim();
    const currentYear = moment().format('YYYY');

    // eslint-disable-next-line
    if (name === 'date_of_birth_day' && (isNaN(value) || value > MAX_DATE)) {
      return;
    }
    // eslint-disable-next-line
    if (name === 'date_of_birth_month' && (isNaN(value) || value > MAX_MONTH)) {
      return;
    }
    if (
      name === 'date_of_birth_year' &&
      // eslint-disable-next-line
      (isNaN(value) || value.length > 4 || value > currentYear)
    ) {
      return;
    }
    this.setState({ [event.target.getAttribute('name')]: value });
  };

  onChangePreferences = event => {
    this.setState({
      preferences: {
        ...this.state.preferences,
        [event.target.getAttribute('name')]: event.target.value,
      },
    });
  };

  onSaveAllChanges = event => {
    event.preventDefault();
    const { countries } = this.props;

    this.setState({
      error_msg: '',
      status_class: 'success',
      updateInProcess: true,
    });
    let allSettings = Object.assign({}, this.state);
    const years = getOptionsYears();
    const months = getOptionsMonths();
    const days = getOptionsDays(+allSettings.date_of_birth_year, +allSettings.date_of_birth_month);
    const isValid = isValidBirthDate(
      allSettings.date_of_birth_day,
      allSettings.date_of_birth_month,
      allSettings.date_of_birth_year,
    );
    if (
      !(
        +allSettings.date_of_birth_year in years &&
        +allSettings.date_of_birth_month in months &&
        +allSettings.date_of_birth_day in days
      ) ||
      !isValid
    ) {
      this.setState({
        error_msg: Strings.errors.invalidDOB,
        status_class: 'errors',
        updateInProcess: false,
      });
      return;
    }
    if (!EMAIL_PATTERN.test(allSettings.email)) {
      this.setState({
        error_msg: Strings.errors.invalidEmail,
        status_class: 'errors',
        updateInProcess: false,
      });
      return;
    }

    if (!allSettings.preferences.country) {
      allSettings = merge(allSettings, { preferences: { country: countries[0] } });
    }

    allSettings.date_of_birth = `${allSettings.date_of_birth_year}-${allSettings.date_of_birth_month}-${allSettings.date_of_birth_day}`;
    delete allSettings.date_of_birth_year;
    delete allSettings.date_of_birth_month;
    delete allSettings.date_of_birth_day;

    this.props.postSettings(allSettings).then(response => {
      this.setState({ updateInProcess: false });
      if (response.type === POST_USER_SETTINGS_ERROR) {
        if (response.response && response.response.data) {
          const errors = response.response.data;
          if ('preferences' in errors) {
            this.setState({
              error_msg: Object.keys(errors.preferences).map(fieldName => `Please enter a valid ${fieldName}`),
            });
          } else {
            this.setState({ error_msg: Object.keys(errors).map(fieldName => `${fieldName}: ${errors[fieldName]}`) });
          }
        }
      }
    });
  };

  setUser(user) {
    const newPropsArr = [];

    // eslint-disable-next-line
    for (const i in user) {
      if (i === 'date_of_birth') {
        if (user[i]) {
          const dob = user[i].split('-');
          newPropsArr.date_of_birth_year = parseInt(dob[0], 10);
          newPropsArr.date_of_birth_month = parseInt(dob[1], 10);
          newPropsArr.date_of_birth_day = parseInt(dob[2], 10);
        }
      } else {
        newPropsArr[i] = user[i];
        if (typeof newPropsArr[i] === 'string') {
          newPropsArr[i] = newPropsArr[i].trim();
        }
      }
    }
    this.setState(newPropsArr);
  }

  changePassword = () => {
    const { email } = this.state;
    const data = {
      title: (
        <span>
          {Strings.changePasswordWarning} <b>{email}</b>?
        </span>
      ),
      hideCaution: true,
      onConfirmAction: sendRestPasswordMessage({
        method: 'email',
        email: email,
      }),
      onCancelAction: null,
      caption: Strings.changePassword,
    };

    this.props.openConfirmModal(data);
  };

  getHeaderComponents() {
    return (
      <React.Fragment>
        <Button class={HEADER_BUTTON_BLUE} onClick={this.changePassword} title={Strings.changePassword} />
      </React.Fragment>
    );
  }

  render() {
    const { first_name, last_name, email, mobile_phone, isLoading, company } = this.state;

    const data = [
      {
        title: Strings.firstName,
        value: first_name,
      },
      {
        title: Strings.lastName,
        value: last_name,
      },
      {
        title: Strings.email,
        value: email,
      },
      {
        title: Strings.company,
        value: company,
      },
      {
        title: Strings.phoneNumber,
        value: mobile_phone,
      },
    ];

    const userSettings = (
      <Tab name={Strings.settingPage.details} path="/user-settings">
        <div className="">
          <PageHeader left={`${first_name} ${last_name}`} right={() => this.getHeaderComponents()} />
          <WithLoader isLoading={isLoading}>
            <div className="settings user-profile">
              <div className="details-container-profile">
                <UsersCard data={data} isSettings />
              </div>
            </div>
          </WithLoader>
        </div>
      </Tab>
    );
    const workflows = (
      <Tab name="Workflows" path="/user-settings/workflows">
        <Workflows />
      </Tab>
    );
    const lab = (
      <Tab name="Lab" path="/user-settings/lab">
        <Lab />
      </Tab>
    );

    const tabs = [userSettings];
    if (hasPermission(PERMISSIONS.WORKFLOW_ENROLLMENT)) {
      tabs.push(workflows);
    }
    if (process.env.NODE_ENV === 'development') {
      tabs.push(lab);
    }

    return (
      <div className="schedule-details shipper setting-page">
        <TabView
          key="tabs"
          activeTab={this.props.activeTab || Strings.settingPage.details}
          routeMode
          className=""
          noSwiping
        >
          {tabs}
        </TabView>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
