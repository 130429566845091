import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';
import SimpleBar from 'simplebar-react';
import Highlighter from 'react-highlight-words';

import { TextInput } from '../../../components/PageHeader/TextInput';
import {
  DAY_DATE_FORMAT_MONTH_NAME,
  MAX_MESSAGE_LENGTH,
  PAGE_LIMIT,
  TIME_FORMAT_12_UPPERCASE,
} from '../../../constants';
import Strings from '../../../Strings';
import './messages.scss';
import PatientInfo from './PatientInfo';
import { actions } from './redux/actions';
import rxLogo from '../../../images/rx/rxCapicon.svg';
import { USPhoneNumberFormat, calculateInitials } from '../../../utils';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../components/PageHeader/Button';
import { PageHeader } from '../../../components/PageHeader';
import Select from '../../../components/Select';
import { DateFilters } from '../../../utils/DateFilters';
import { openModalAction } from '../../../actions/modal';
import { ADD_PREFAB_REPLY } from './redux/constants';
import { PERMISSIONS, hasPermission } from '../../../utils/userPermissions';
import PrefabWindow from './PrefabWindow';
import { TextAreaWithHashtags } from '../../../components/Inputs/TextAreaWithHashtags/TextAreaWithHashtags';
import { prefabType } from './PrefabReplies';

function MessageDetails(props) {
  const userId = props.patientId || props.match?.params?.userId;
  const showPrefabs = hasPermission(PERMISSIONS.MESSAGES_PREFABS);

  const pageRequest = useRef({
    offset: 0,
    userId,
    limit: PAGE_LIMIT,
  });
  const myRef = useRef();
  const detailsContainer = useRef();
  const timerScroll = useRef();
  const messagesEnd = useRef();
  const inputRef = useRef();

  const [message, setMessage] = useState('');
  const [systemSent, setSystemSent] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState('');
  const [searchText, setSearchText] = useState('');
  const [refreshEnable, setRefreshEnable] = useState(true);
  const [prefabWindowOpen, setPrefabWindowOpen] = useState(false);
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEnd.current) {
        messagesEnd.current.scrollIntoView({ block: 'end', inline: 'end', behavior: 'smooth' });
      }
    }, 500);
  };

  const scrollToTop = () => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollTo({ y: 100 });
    }
  };

  const loadMessages = () => {
    props.getMessageDetails(pageRequest.current, userId).then(() => {
      scrollToBottom();
    });
  };

  const onRefresh = () => {
    setRefreshEnable(false);
    setTimeout(() => {
      setRefreshEnable(true);
    }, 30000);
    pageRequest.current.offset = 0;
    loadMessages();
    props.getPrefabReplies();
  };

  useEffect(() => {
    pageRequest.current.search = '';
    pageRequest.current.systemSent = systemSent;
    loadMessages();
    props.toggleRead({ id: parseInt(userId, 10), value: true });
    props.getPrefabReplies();
    return () => {
      props.clearData();
    };
  }, []);

  const onSend = () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);

    const data = {
      phones: [props.patientInfo.mobileNumber],
      message,
    };
    pageRequest.current.offset = 0;
    props.onSendMessage(data).then(() => {
      setMessage('');
      loadMessages();
      setSaveBtnClicked(false);
    });
  };

  const scrollEvent = e => {
    const target = e.target;
    clearTimeout(timerScroll.current);
    timerScroll.current = setTimeout(() => {
      if (target.scrollTop < 10) {
        if (props?.pagination) {
          if (props?.pagination?.totalRecords > pageRequest.current.offset + props?.pagination?.limit) {
            pageRequest.current.offset += props?.pagination?.limit;
            const { messages, pagination } = props;
            if (messages.length < pagination.totalRecords) {
              props.getMessageDetails(pageRequest.current, userId).then(() => {
                scrollToTop();
              });
            }
          }
        }
      }
    }, 1000);
  };

  const onSystemSentChange = event => {
    const { checked } = event.target;
    pageRequest.current.offset = 0;
    pageRequest.current.systemSent = checked;

    props.clearMessages();
    loadMessages();
    setSystemSent(checked);
  };

  const isShowDateBox = (array, index, item) => {
    if (index === 0) {
      return true;
    }

    return (
      index > 0 &&
      moment(array[index - 1].createdAt).format('dddd, MMMM Do') !== moment(item.createdAt).format('dddd, MMMM Do')
    );
  };

  const handleScrollToElement = () => {
    const elementTop = myRef?.current?.offsetTop;

    if (elementTop) {
      detailsContainer.current.scrollTop = elementTop;
    }
  };

  const onTextInputChange = e => {
    const text = e.target.value;
    const { messages } = props;
    let filterTimeout;
    let scrollTimeout;
    if (text.length >= 3) {
      filterTimeout = setTimeout(() => {
        const data = messages.filter(element => {
          return element.message.toLowerCase().includes(text.toLowerCase());
        });
        setSelectedMessage(data);
        setSearchText(text);

        scrollTimeout = setTimeout(() => {
          handleScrollToElement();
        }, 1000);
      }, 500);
    } else {
      clearTimeout(filterTimeout);
      clearTimeout(scrollTimeout);
      setSelectedMessage([]);
      setSearchText('');
    }

    onRefresh();
  };

  const onDateRangeChanged = option => {
    props.clearMessages();
    if (option) {
      pageRequest.current.startDate = option.dates.startDate();
      pageRequest.current.endDate = option.dates.endDate();
    } else {
      pageRequest.current.startDate = '';
      pageRequest.current.endDate = '';
    }
    pageRequest.current.offset = 0;

    loadMessages();
  };

  const showDate = () => {
    if (selectedMessage.length) {
      return moment(selectedMessage[selectedMessage.length - 1].createdAt).format(DAY_DATE_FORMAT_MONTH_NAME);
    }
    return moment().format(DAY_DATE_FORMAT_MONTH_NAME);
  };

  const addToPrefabs = text => {
    props.addPrefabReply({ full_txt: text, type: prefabType.shared });
  };

  const onPrefabChosen = text => {
    const { selectionStart, selectionEnd, value } = inputRef.current;
    let textBeforeCursorPosition = value.substring(0, selectionStart);
    let textAfterCursorPosition = value.substring(selectionEnd, value.length);
    setMessage(`${textBeforeCursorPosition}${text}${textAfterCursorPosition}`);
    setPrefabWindowOpen(false);
    inputRef.current.selectionStart = selectionStart + value.length;
    inputRef.current.selectionEnd = selectionStart + value.length;
    inputRef.current.focus();
  };

  const { messages, patientInfo, isLoading } = props;
  const charsRemaining = MAX_MESSAGE_LENGTH - message.length;

  return (
    <div className={`message-container ${props.showHeader ? 'with-header' : ''}`}>
      {props.showHeader && (
        <React.Fragment>
          <div className="infoHeader">
            <div className="rowDirection">
              <button className="back" onClick={() => history.back()} tabIndex={0}>
                {Strings.back}
              </button>
              {patientInfo && (
                <div className="feildBox">
                  <div className="feildValue val">
                    <b>{USPhoneNumberFormat(patientInfo.mobileNumber)}:</b>{' '}
                    <span className="user-name">
                      {' '}
                      {patientInfo.firstName} {patientInfo.lastName}{' '}
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="rowDirection left">
              <button
                className="refresh-button2"
                style={{ marginLeft: '15px' }}
                disabled={!refreshEnable}
                onClick={onRefresh}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      <PageHeader
        right={
          <React.Fragment>
            {Strings.messages.displaySystemMessages}
            <input type="checkbox" checked={systemSent} onChange={onSystemSentChange} />
          </React.Fragment>
        }
        left={
          <React.Fragment>
            <TextInput class="search" placeholder={Strings.searchConversation} onChange={onTextInputChange} />
            <Select
              data={[
                DateFilters.AllTime,
                DateFilters.Last48Hours,
                DateFilters.ThisMonth,
                DateFilters.Last30Days,
                DateFilters.LastMonth,
                DateFilters.Last6Months,
                DateFilters.ThisYear,
                DateFilters.Last12Months,
              ]}
              onChange={onDateRangeChanged}
              defaultValue={DateFilters.AllTime.value}
            />
          </React.Fragment>
        }
      />

      <div className="message-patient-container">
        <div className="flex-left">
          {/*             <div className="date-container">
            <div className="date-box date-header auto-width">{showDate()}</div>
          </div> */}
          <div className="flex1">
            <SimpleBar ref={detailsContainer} className="details-container" onScroll={e => scrollEvent(e, props)}>
              <div className="messages">
                {isLoading && <div className="spinner" />}
                {!isLoading &&
                  messages?.map((item, index, array) => {
                    return (
                      <>
                        {isShowDateBox(array, index, item) && (
                          <div className="date-container">
                            <div className="date-box">{moment(item.createdAt).format(DAY_DATE_FORMAT_MONTH_NAME)}</div>
                            <div className="v-line"></div>
                          </div>
                        )}
                        <div className={`inbound chat-box ${item.messageType === 'outbound' ? 'reversed' : ''}`}>
                          <span className="message-time">
                            ({moment(item.createdAt).format(TIME_FORMAT_12_UPPERCASE)})
                          </span>
                          <div className="image-box">
                            {item.sentBy === 'System' ? (
                              <img className="user-image" src={rxLogo} alt="avatar" />
                            ) : (
                              <div className="avatar" data-tooltip-content={item.sentBy} data-tooltip-id="tooltip">
                                {calculateInitials(item.sentBy)}
                              </div>
                            )}
                          </div>
                          <div className="message-with-button">
                            <div className="message">
                              <Highlighter
                                searchWords={[searchText]}
                                textToHighlight={item.message.replace(/(?:\\n)/g, '\n').replace(/^"\\*(.*)"$/, '$1')}
                                autoEscape
                              />
                            </div>
                            {item.messageType === 'outbound' && showPrefabs && (
                              <button
                                className="add-prefab-button plus-button dark"
                                onClick={() => addToPrefabs(item.message)}
                                data-tooltip-content={Strings.messages.addToPrefabs}
                                data-tooltip-id="tooltip"
                              />
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
                <div
                  style={{
                    float: 'left',
                    clear: 'both',
                  }}
                  ref={messagesEnd}
                ></div>
              </div>
            </SimpleBar>
          </div>
          <div className="bottom-box">
            <TextAreaWithHashtags
              value={message}
              onChange={e => setMessage(e)}
              hashtags={
                props.prefabReplies?.map(r => {
                  return {
                    id: r.id,
                    display: r.full_txt,
                    shortcut: r.shortcut,
                    title: r.title,
                  };
                }) || []
              }
              tooltipForEntries="Add Reply"
              convertTagToPlainText
              ref={inputRef}
            />
            {charsRemaining >= 0 && (
              <div className={`chars-remaining ${charsRemaining < 50 ? 'low' : ''}`}>
                {Strings.formatString(Strings.maxAndRemainingChars, MAX_MESSAGE_LENGTH, charsRemaining)}
              </div>
            )}
            {charsRemaining < 0 && (
              <div className="chars-overflow">
                {Strings.formatString(Strings.maxAndOverflowChars, MAX_MESSAGE_LENGTH, -charsRemaining)}
              </div>
            )}
            {prefabWindowOpen && showPrefabs && (
              <PrefabWindow onBlur={() => setPrefabWindowOpen(false)} onPrefabChosen={onPrefabChosen} />
            )}
            {showPrefabs && (
              <Button
                class={`${prefabWindowOpen ? 'clicked' : ''} prefab-button`}
                onClick={() => setPrefabWindowOpen(!prefabWindowOpen)}
                title="#"
              />
            )}
            <Button
              class={`${HEADER_BUTTON_DARK_BLUE} send-button`}
              onClick={onSend}
              title={Strings.send}
              disabled={!message || message.length > MAX_MESSAGE_LENGTH}
            />
          </div>
        </div>
        {props.showPatientDetails && (
          <SimpleBar className="flex-right">
            <PatientInfo patientId={userId}></PatientInfo>
          </SimpleBar>
        )}
      </div>
    </div>
  );
}

MessageDetails.propTypes = {
  clearData: PropTypes.func,
  getMessageDetails: PropTypes.func,
  isLoading: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
      type: PropTypes.any,
      userId: PropTypes.any,
    }),
  }),
  messages: PropTypes.shape({
    length: PropTypes.any,
    map: PropTypes.func,
  }),
  onNavigate: PropTypes.func,
  onSendMessage: PropTypes.func,
  clearMessages: PropTypes.func,
  pagination: PropTypes.shape({
    totalRecords: PropTypes.any,
    limit: PropTypes.any,
  }),
  totalRecords: PropTypes.any,
  limit: PropTypes.any,
  patientInfo: PropTypes.shape({
    firstName: PropTypes.any,
    lastName: PropTypes.any,
    mobileNumber: PropTypes.any,
  }),
  showPatientDetails: PropTypes.bool,
  showHeader: PropTypes.bool,
  patientId: PropTypes.any,
  addPrefabReply: PropTypes.func,
  getPrefabReplies: PropTypes.func,
  prefabReplies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      full_txt: PropTypes.string,
      shortcut: PropTypes.string,
    }),
  ),
  toggleRead: PropTypes.func,
};

MessageDetails.defaultProps = { showPatientDetails: true, showHeader: true };

const mapStateToProps = state => {
  const { messages } = state.superUser;
  return {
    role: state.auth.role,
    messages: messages?.messageDetails,
    pagination: messages?.messagePagination,
    isLoading: messages?.isMessageDetailsLoading,
    patientInfo: messages?.patientInfo,
    prefabReplies: messages?.prefabReplies,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getMessageDetails: (pageRequest, userId) => dispatch(actions.getMessageDetails(pageRequest, userId)),
  clearData: () => dispatch(actions.clearData()),
  clearMessages: () => dispatch(actions.clearMessages()),
  onSendMessage: data => dispatch(actions.sendMessage(data)),
  addPrefabReply: data =>
    dispatch(
      openModalAction('edit-prefab-reply', {
        action: actions.addPrefabReply,
        actionType: ADD_PREFAB_REPLY,
        prefab: data,
        title: Strings.messages.addAsPrefab,
      }),
    ),
  getPrefabReplies: () => dispatch(actions.getPrefabReplies()),
  toggleRead: data => dispatch(actions.readToggle(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageDetails);
