import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';

import { TextInput } from '../../../components/PageHeader/TextInput';
import { DATE_MONTH_DAY_YEAR, PAGE_LIMIT, TIME_FORMAT_12_UPPERCASE } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { getWeight, getVitalsData, getVitalsDate } from '../../../utils';
import './patient.scss';
import { actions } from './redux/actions';
import { WEIGHT_UNITS } from '../../../utils/unitConverters';

class PatientEvent extends PureComponent {
  searchTime;

  componentDidMount() {
    this.pageRequest = {
      offset: 0,
      search: '',
    };
    const { patientId } = this.props;
    this.props.getPatientEvent(this.pageRequest, patientId);
  }

  onSearchQueryChange = query => {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    clearTimeout(this.searchTime);
    const { patientId } = this.props;
    this.searchTime = setTimeout(() => {
      this.props.getPatientEvent(this.pageRequest, patientId);
    }, 1000);
  };

  getHeaderComponents = () => {
    return (
      <React.Fragment>
        <TextInput
          class="search"
          placeholder={Strings.search}
          onChange={e => this.onSearchQueryChange(e.target.value)}
        />
      </React.Fragment>
    );
  };

  onPrevClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset - PAGE_LIMIT;
    this.pageRequest.offset = offset;
    const { patientId } = this.props;
    this.props.getPatientEvent(this.pageRequest, patientId);
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    const { patientId } = this.props;
    this.props.getPatientEvent(this.pageRequest, patientId);
  };

  onNextClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset + PAGE_LIMIT;
    this.pageRequest.offset = offset;
    const { patientId } = this.props;
    this.props.getPatientEvent(this.pageRequest, patientId);
  };

  onSortClick = ({ sortKey, direction }) => {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    const { patientId } = this.props;
    this.props.getPatientEvent(this.pageRequest, patientId);
  };

  onEditTask = (event, task) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onEditTask(this.pageRequest, task, this.props.taskList);
  };

  onDeletePatientEvent = (event, patientEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const { patientId } = this.props;

    const data = {
      title: (
        <span>
          {Strings.deleteTaksHistoryWarning} <b>{patientEvent.taskName}</b>?
        </span>
      ),

      onConfirmAction: actions.deletePatientEvent(patientId, patientEvent.id, [
        actions.getPatientEvent(this.pageRequest, patientId),
      ]),
      onCancelAction: null,
    };
    this.props.openConfirmModal(data);
  };

  getData = data => {
    if (data) {
      switch (data.event_type) {
        case 'blood_pressure': {
          const unit = data.flags.blood_pressure_unit;
          const bpData = data.values;

          return `${Strings.pulseRate}: ${bpData.pulse_rate} bpm, ${Strings.systolicPressure}: ${bpData.systolic_pressure} ${unit}, ${Strings.diastolicPressure}: ${bpData.diastolic_pressure} ${unit}, ${Strings.meanArterialPressure}: ${bpData.mean_arterial_pressure} ${unit}`;
        }
        case 'weight':
        case 'weight_details': {
          const weightData = getWeight(data?.values?.weight, data?.flags?.measurement_unit, WEIGHT_UNITS.lbs);
          return `${Strings.weight}: ${weightData}`;
        }
        case 'pulseox': {
          const hr = data.measurements[0]?.heart_rate;
          const saturation = data.measurements[0]?.saturation;
          return `${Strings.heartRate}: ${hr}, ${Strings.saturation}: ${saturation}%`;
        }
        case 'movement_report': {
          return `${Strings.steps}: ${data.steps}`;
        }
        default:
          return '-';
      }
    }
  };

  render() {
    const { isLoading, pagination, patientEvents, patientDetails } = this.props;

    return (
      <div className="patient-container topPadding">
        {/* <PageHeader getHeaderComponents={() => this.getHeaderComponents()} /> */}
        <Table
          className="patient-task-history-table"
          isLoading={isLoading}
          name="organizations"
          data={patientEvents || []}
          onRowSelection={() => {}}
          onPrevClick={this.onPrevClick}
          onSortClick={this.onSortClick}
          onNextClick={this.onNextClick}
          onCustomPage={this.onCustomPage}
          pagination={
            pagination || {
              offset: 0,
              total: 0,
            }
          }
        >
          <Column key="deviceId1" title={Strings.capPatient.deviceId} value={d => d.deviceId} />
          <Column key="deviceType" title={Strings.deviceType} value={d => d.deviceType} />
          <Column
            key="timestampDate"
            title={Strings.date}
            value={e => (!_.isEmpty(e.vitals) ? moment(getVitalsDate(e.vitals)).format(DATE_MONTH_DAY_YEAR) : '-')}
          />
          <Column
            key="timestamp"
            title={Strings.time}
            value={e => (!_.isEmpty(e.vitals) ? moment(getVitalsDate(e.vitals)).format(TIME_FORMAT_12_UPPERCASE) : '-')}
          />
          <Column
            key="Data"
            title={Strings.capPatient.data}
            value={e => (!_.isEmpty(e.vitals) ? getVitalsData(e.vitals) : '-')}
          />
        </Table>
      </div>
    );
  }
}

PatientEvent.propTypes = {
  getPatientEvent: PropTypes.func,
  isLoading: PropTypes.any,
  onAddSchedule: PropTypes.any,
  onEditTask: PropTypes.func,
  openConfirmModal: PropTypes.func,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
  patientDetails: PropTypes.any,
  patientEvents: PropTypes.array,
  patientId: PropTypes.any,
  taskList: PropTypes.any,
};

const mapStateToProps = state => {
  const { patients } = state.superUser;
  return {
    patientEvents: patients?.patientEvents?.data,
    pagination: patients?.patientEvents?.pagination,
    isLoading: patients?.isPatientEventsLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getPatientEvent: (pageRequest, patientId) => dispatch(actions.getPatientEvent(pageRequest, patientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientEvent);
