import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FileDrop } from 'react-file-drop';
import axios from 'axios';

import { notificationActions } from '../../components/Notification/redux/actions';
import { documentsActions, GET_DOWNLOAD_URL_RESULT, GET_LIST_DOCUMENTS_RESULT, GET_UPLOAD_URL_RESULT } from './actions';
import { PageHeader } from '../../components/PageHeader';
import { Button } from '../../components/PageHeader/Button';
import { HEADER_BUTTON_BLUE } from '../../constants';
import Strings from '../../Strings';
import CloudContentTable from './internals/CloudContentTable';
import FirstUploadDrop from './internals/FirstUpload';
import { hasPermission, PERMISSIONS } from '../../utils/userPermissions';

function CloudDocuments(props) {
  const patientId = props.patientId;
  const canEdit = hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_INFO_EDIT);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);

  const getDocuments = (action, args, resultActionType) => {
    setLoading(true);
    setItems([]);
    props.dispatchAction(action, args).then(resp => {
      if (resp.type === resultActionType) {
        setItems(resp.response.entries);
        setLoading(false);
      } else {
        props.showNotification(Strings.documentsGeneralProblem);
      }
    });
  };

  const onDownload = fileName => {
    const action = patientId ? documentsActions.getS3DownloadUrl : documentsActions.getS3DownloadPatientListUrl;
    const args = patientId ? [patientId, fileName] : [fileName];

    props.dispatchAction(action, args).then(resp => {
      if (resp.type === GET_DOWNLOAD_URL_RESULT) {
        const tempLink = document.createElement('a');
        tempLink.href = resp.response.url;
        tempLink.target = '_blank';
        tempLink.download = fileName;
        tempLink.rel = 'noopener noreferrer';
        tempLink.click();
      } else {
        props.showNotification(Strings.documentsGeneralProblem);
      }
    });
  };

  const onUpload = file => {
    const sizeInMb = file.size / 1048576;
    if (sizeInMb >= 50) {
      props.showNotification(`${Strings.fileExceedMaximumSizeOf} 50 MB`);
      return;
    }

    const action = patientId ? documentsActions.getS3UploadUrl : documentsActions.getS3UploadPatientListUrl;
    const args = patientId ? [patientId, file.name] : [file.name];

    props.dispatchAction(action, args).then(resp => {
      setUploading(true);
      if (resp.type === GET_UPLOAD_URL_RESULT) {
        axios
          .put(resp.response.url, file, {})
          .then(resp => {
            if (resp.status === 200) {
              props.showNotification(Strings.fileUploadedSuccessfully);
              setUploading(false);
              getData();
              return;
            } else {
              props.showNotification(Strings.fileUploadFail);
              setUploading(false);
              return;
            }
          })
          .catch(() => {
            props.showNotification(Strings.fileUploadFail);
            setUploading(false);
            return;
          });
      } else {
        props.showNotification(Strings.documentsGeneralProblem);
        setUploading(false);
      }
    });
  };

  const getData = () => {
    patientId
      ? getDocuments(documentsActions.getS3ListDocuments, [patientId], GET_LIST_DOCUMENTS_RESULT)
      : getDocuments(documentsActions.getS3ListPatientList, [], GET_LIST_DOCUMENTS_RESULT);
  };

  useEffect(() => {
    getData();
  }, []);

  const spinner = (
    <div className="cd-gray-border">
      <div className="cd-spinner" />
    </div>
  );

  const firstUpload = (
    <div className="cloud-documents">
      <FirstUploadDrop onUpload={onUpload} uploading={uploading} />
    </div>
  );

  const fileInput = React.createRef();

  const triggerInputFile = () => {
    if (fileInput.current != undefined && fileInput.current.click != undefined) fileInput.current.click();
  };

  const dropSpaceAndButton = (
    <React.Fragment>
      <FileDrop
        onDrop={(files, event) => {
          event.preventDefault();
          event.stopPropagation();
          onUpload(files[0]);
        }}
      >
        <input
          type="file"
          name="file"
          ref={fileInput}
          onChange={e => {
            onUpload(e.target.files[0]);
          }}
          onTargetClick={() => triggerInputFile()}
        />
        <div className={`cd-small-drop ${uploading ? 'progressing' : ''}`}>{Strings.dropOr}</div>
      </FileDrop>
      <Button class={HEADER_BUTTON_BLUE} title={Strings.chooseFile} onClick={() => triggerInputFile()} />
    </React.Fragment>
  );

  const fileTable = (
    <div className="cd-file-table">
      <PageHeader right={dropSpaceAndButton} />
      <CloudContentTable data={items} isLoading={loading} downloadHandler={onDownload} provider="s3" />
    </div>
  );

  return loading ? spinner : items.length === 0 && canEdit ? firstUpload : fileTable;
}

CloudDocuments.propTypes = {
  showNotification: PropTypes.func.isRequired,
  dispatchAction: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => ({
  dispatchAction: (action, data) => dispatch(action(...data)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
});

export default connect(null, mapDispatchToProps)(CloudDocuments);
