import React from 'react';
import { Field } from 'react-final-form';

function getFieldWithComponent(Component, type) {
  const result = props => {
    return <Field type={type} {...props} component={Component} />;
  };

  result.displayName = `FieldWithComponent(${Component.displayName || Component.name || 'Component'})`;
  return result;
}

export { getFieldWithComponent };
