import moment from 'moment-timezone';
import { connect } from 'react-redux';

import React, { PureComponent } from 'react';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';

import Strings from '../../../Strings';
import TimeStampPlot from '../../../components/Plots/timestamp-plot';
import { trialsAction } from '../../../actions/trials';
import {
  getWeeksArray,
  isActivePeriod,
  getActiveWeekNumber,
  getWeeksStartAndEndDate,
  enumerateDaysBetweenDates,
  getWeeksStartAndEndDateForAPI,
  enumerateDatesBetweenDates,
} from '../../../utils';
import { MONTHS_RANGE } from '../../../constants';

class TimeStamp extends PureComponent {
  state = {
    currentMonth: moment().month(),
    currentYear: moment().year(),
    selectedYear: moment().year(),
    selectedMonth: moment().month(),
    selectedWeek: getActiveWeekNumber(),
  };

  onNextYear = () => {
    const year = this.state.selectedYear;
    this.setState({ selectedYear: year + 1 }, () => {
      this.onWeekClick(this.state.selectedWeek);
    });
  };

  onPrevYear = () => {
    const year = this.state.selectedYear;
    this.setState({ selectedYear: year - 1 }, () => {
      this.onWeekClick(this.state.selectedWeek);
    });
  };

  onNextMonth = () => {
    const month = this.state.selectedMonth;

    const updatedMonth = month === 11 ? 0 : month + 1;

    if (month === 11) {
      const selectedYear = this.state.selectedYear;
      this.setState(
        {
          selectedMonth: updatedMonth,
          selectedYear: selectedYear + 1,
        },
        () => {
          this.onWeekClick(this.state.selectedWeek);
        },
      );
    } else {
      this.setState({ selectedMonth: updatedMonth }, () => {
        this.onWeekClick(this.state.selectedWeek);
      });
    }
  };

  onPrevMonth = () => {
    const selectedMonth = this.state.selectedMonth;
    const updatedMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
    if (selectedMonth === 0) {
      const selectedYear = this.state.selectedYear;
      this.setState(
        {
          selectedMonth: updatedMonth,
          selectedYear: selectedYear - 1,
        },
        () => {
          this.onWeekClick(this.state.selectedWeek);
        },
      );
    } else {
      this.setState({ selectedMonth: updatedMonth }, () => {
        this.onWeekClick(this.state.selectedWeek);
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.dateRange !== nextProps.dateRange || nextProps.update) {
      this.onWeekClick(this.state.selectedWeek);
    }
  }

  componentWillUnmount() {
    this.props.clearTimestamp();
  }

  componentDidMount() {
    this.onWeekClick(this.state.selectedWeek);
  }

  getTimestamps(dateRange, trialId, patientId) {
    this.pageRequest = {
      startDate: dateRange.infimum,
      endDate: dateRange.supremum,
      offset: 0,
      trialId,
      patientId,
      search: '',
    };
    this.props.loadTimestamps(this.pageRequest);
  }

  onWeekClick(week) {
    const { trialId, patientId, patientTimezone } = this.props;
    const { selectedYear, selectedMonth } = this.state;
    this.setState({ selectedWeek: week });
    const dateRange = getWeeksStartAndEndDateForAPI(week, selectedYear, selectedMonth);
    this.pageRequest = {
      startDate: moment
        .tz(dateRange[0], patientTimezone)
        .format('YYYY-MM-DD[T]HH:mm:ss[Z]')
        .toString(),
      endDate: moment
        .tz(dateRange[1], patientTimezone)
        .format('YYYY-MM-DD[T]HH:mm:ss[Z]')
        .toString(),
      offset: 0,
      trialId,
      patientId,
      search: '',
    };
    this.props.loadTimestamps(this.pageRequest);
  }

  render() {
    const { capData, isLoading } = this.props;
    const events = capData?.timestamps;
    const battery = capData?.battery;
    const { currentMonth, currentYear, selectedYear, selectedMonth, selectedWeek } = this.state;

    const weeksArray = getWeeksArray(selectedYear, selectedMonth);
    const disablePrevButton = false;
    const disableNextButton = selectedYear >= currentYear;
    const disableMonthPrevButton = false;
    const disableMonthNextButton = !!(selectedMonth >= currentMonth && selectedYear >= currentYear);

    const fakeEvent = [
      {
        eventTimestamp: '',
        eventType: 'E',
        id: '',
        patientId: '',
        patientTimezone: 'Asia/Calcutta',
        scheduleCreatedAt: '',
      },
    ];

    const noData = () => {
      if (events && events.length === 0 && !isLoading) {
        return (
          <div className="no-data-image-container">
            <div className="no-data-image">{Strings.noData}</div>
          </div>
        );
      }
      return null;
    };

    const showData = () => {
      return (
        <div className="plot-row timestamp-row">
          <div className="column full-width">
            <TimeStampPlot
              data={events}
              battery={battery}
              selectedWeek={enumerateDaysBetweenDates(
                getWeeksStartAndEndDate(selectedWeek, selectedYear, selectedMonth),
                selectedWeek,
              )}
              toolTipDate={enumerateDatesBetweenDates(
                getWeeksStartAndEndDate(selectedWeek, selectedYear, selectedMonth),
                selectedWeek,
              )}
              isLoading={isLoading}
            />
          </div>
        </div>
      );
    };

    const noDataUI = () => {
      return (
        <div className="plot-row timestamp-row no-data-view">
          <div className="column full-width">
            {noData()}
            <TimeStampPlot
              data={fakeEvent}
              selectedWeek={enumerateDaysBetweenDates(
                getWeeksStartAndEndDate(selectedWeek, selectedYear, selectedMonth),
                selectedWeek,
              )}
              toolTipDate={enumerateDatesBetweenDates(
                getWeeksStartAndEndDate(selectedWeek, selectedYear, selectedMonth),
                selectedWeek,
              )}
              isLoading={isLoading}
            />
          </div>
        </div>
      );
    };

    const uiNoDataAndData = () => {
      if (events && events.length === 0) {
        return noDataUI();
      }
      if (events === undefined && isLoading) {
        return noDataUI();
      }
      if (events && events.length > 0) {
        return showData();
      }
      return null;
    };

    return (
      <div className="relative-div">
        <div className="timestamp">
          <div className="year-navigation">
            <div className="months">
              {weeksArray.map(week => {
                const activeWeek = week === selectedWeek ? ' active-month' : '';

                const monthClass = isActivePeriod(week, selectedWeek, selectedYear, selectedMonth);

                return monthClass === 'active-period ' ? (
                  <div
                    className={`month ${monthClass}${activeWeek}`}
                    data-tooltip-content={`Week ${week}`}
                    data-tooltip-id="tooltip"
                    key={`tttt-${week}`}
                    onClick={() => this.onWeekClick(week)}
                  >
                    {' '}
                  </div>
                ) : (
                  <div
                    className={`month ${monthClass}${activeWeek}`}
                    data-tip
                    data-tooltip-id={`tt-${week}`}
                    key={`tttt-${week}`}
                  >
                    {' '}
                    {tip}
                  </div>
                );
              })}
            </div>
            <div className="navigation-buttons">
              <button
                onClick={this.onPrevMonth}
                className={`adherence-arrow left prev  ${disableMonthPrevButton ? 'disabled' : ''}`}
              />
              <div className="caption date">{MONTHS_RANGE[selectedMonth]}</div>
              <button
                onClick={this.onNextMonth}
                className={`adherence-arrow right next ${disableMonthNextButton ? 'disabled' : ''}`}
              />
            </div>

            <div className="navigation-buttons">
              <button
                onClick={this.onPrevYear}
                className={`adherence-arrow left prev ${disablePrevButton ? 'disabled' : ''}`}
              />
              <div className="caption date">{selectedYear}</div>
              <button
                onClick={this.onNextYear}
                className={`adherence-arrow right next ${disableNextButton ? 'disabled' : ''}`}
              />
            </div>
          </div>
        </div>
        {uiNoDataAndData()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { dateFilter } = state.filter;
  const { events, loading, update } = state.entities.trials;
  const patientTimezone = state.esi?.patientInfo?.patient?.timezone || null;

  const dateRange = dateFilter || {
    infimum: moment()
      .subtract(1, 'month')
      .toDate(),
    supremum: moment().toDate(),
  };

  return {
    dateRange,
    isLoading: loading,
    capData: events,
    update,
    patientTimezone,
  };
};

const mapDispatchToProps = dispatch => ({
  loadTimestamps: timestampRequest => dispatch(trialsAction.actionTimestamp(timestampRequest)),
  clearTimestamp: () => dispatch(trialsAction.clearTimestamp()),
  onNavigate: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeStamp);

TimeStamp.propTypes = {
  capData: PropTypes.shape({
    battery: PropTypes.any,
    timestamps: PropTypes.shape({ length: PropTypes.number }),
  }),
  clearTimestamp: PropTypes.func,
  dateRange: PropTypes.object,
  events: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isMounted: PropTypes.bool.isRequired,
  isPreparing: PropTypes.bool,
  loadTimestamps: PropTypes.func,
  patientId: PropTypes.any,
  patientTimezone: PropTypes.any,
  timezone: PropTypes.string,
  trialId: PropTypes.any,
  update: PropTypes.any,
};
