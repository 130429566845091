import {
  ESI_ADMIN_ADHERENCE,
  ESI_ADMIN_ADHERENCE_CLEAR,
  ESI_ADMIN_ADHERENCE_ERROR,
  ESI_ADMIN_ADHERENCE_RESULT,
  ESI_ADMIN_CAP_EVOLUTION,
  ESI_ADMIN_CAP_EVOLUTION_CLEAR,
  ESI_ADMIN_CAP_EVOLUTION_ERROR,
  ESI_ADMIN_CAP_EVOLUTION_RESULT,
} from './constants';

export const esiAdminDashboardAdherenceReducer = (state = {}, action) => {
  switch (action.type) {
    case ESI_ADMIN_ADHERENCE:
      return {
        ...state,
        isLoading: true,
        adherence: {},
      };
    case ESI_ADMIN_ADHERENCE_RESULT:
      return {
        ...state,
        isLoading: false,
        adherence: action.response,
      };
    case ESI_ADMIN_ADHERENCE_ERROR:
      return {
        ...state,
        isLoading: false,
        adherence: undefined,
      };
    case ESI_ADMIN_ADHERENCE_CLEAR:
      return {
        ...state,
        isLoading: false,
        adherence: undefined,
      };
    case ESI_ADMIN_CAP_EVOLUTION:
      return {
        ...state,
        isLoading: true,
        capEvolution: {},
      };
    case ESI_ADMIN_CAP_EVOLUTION_RESULT:
      return {
        ...state,
        isLoading: false,
        capEvolution: action.response,
      };
    case ESI_ADMIN_CAP_EVOLUTION_ERROR:
      return {
        ...state,
        isLoading: false,
        capEvolution: undefined,
      };
    case ESI_ADMIN_CAP_EVOLUTION_CLEAR:
      return {
        ...state,
        isLoading: false,
        capEvolution: undefined,
      };

    default:
      return state;
  }
};
