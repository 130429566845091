import {
  PATIENT_PORTAL_LIST_SCHEDULES,
  PATIENT_PORTAL_LIST_SCHEDULES_RESULT,
  PATIENT_PORTAL_SAVE_SCHEDULE,
  PATIENT_PORTAL_SAVE_SCHEDULE_RESULT,
  PATIENT_PORTAL_SAVE_SCHEDULE_ERROR,
  PATIENT_PORTAL_DELETE_SCHEDULE,
  PATIENT_PORTAL_DELETE_SCHEDULE_ERROR,
  PATIENT_PORTAL_DELETE_SCHEDULE_RESULT,
} from '../actions/action-types';

const patientPortalSchedulesReducer = (reducer, types, actionToKey) => {
  const nextReducer = reducer(types, actionToKey);

  return (state, action) => {
    const newState = { ...nextReducer(state, action) };
    switch (action.type) {
      case PATIENT_PORTAL_LIST_SCHEDULES:
        return {
          ...newState,
          loading: true,
        };
      case PATIENT_PORTAL_LIST_SCHEDULES_RESULT:
        return {
          ...newState,
          loading: false,
          preparing: false,
        };
      case PATIENT_PORTAL_SAVE_SCHEDULE:
        return {
          ...state,
          saveScheduleLoading: true,
        };
      case PATIENT_PORTAL_SAVE_SCHEDULE_RESULT:
        state.objects[action.response.id] = action.response; // eslint-disable-line no-param-reassign

        return {
          ...state,
          saveScheduleLoading: false,
        };
      case PATIENT_PORTAL_SAVE_SCHEDULE_ERROR:
        return {
          ...state,
          saveScheduleLoading: false,
        };
      case PATIENT_PORTAL_DELETE_SCHEDULE:
        return {
          ...newState,
          loading: true,
        };
      case PATIENT_PORTAL_DELETE_SCHEDULE_ERROR:
        return {
          ...newState,
          loading: false,
        };
      case PATIENT_PORTAL_DELETE_SCHEDULE_RESULT:
        if (action.response.id in state.objects) {
          delete state.objects[action.response.id]; // eslint-disable-line no-param-reassign
        }

        return {
          ...state,
          objects: state.objects,
          loading: false,
        };
      default:
        return newState;
    }
  };
};

export default patientPortalSchedulesReducer;
