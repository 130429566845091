import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { PAGE_LIMIT } from '../../constants';
import { serialize, deserialize } from 'react-serialize/lib';
import { getTotalPagesCount } from '../../utils';
import { initTable, selectRow, setOrder as setOrderAction, setColumns } from './actions';
import { DataRowTemplate, HeaderRow, NoDataRow, SpinnerRow, DataLoadingRow } from './components/index';
import { DropdownTableFilter, FiltersStateHelper } from '../../components/DropdownTableFilter';

export { Column } from './components/index';

const emptyArray = [];
/**
 * <reselect> selectors
 * @param s - state
 * @param p - props
 */
const idsSelector = p => p.ids || (p.data && Object.keys(p.data)) || emptyArray;

const mapStateToProps = (state, props) => {
  const { name } = props;
  const { columns, order, selected } = state.table[name] || {};
  const { data } = props;

  return {
    ids: idsSelector(props),
    columns,
    order,
    selected: selected ? selected : props.selectedByDefault,
    data,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { name } = props;
  return {
    setOrder: (key, direction) => {
      dispatch(
        setOrderAction(name, {
          key,
          direction,
        }),
      );
    },
    onSetColumns: columns => dispatch(setColumns(name, columns)),
    onInit: payload => dispatch(initTable(name, payload)),
    selectRow: id => dispatch(selectRow(name, id, props.deselect)),
    onRowSelection: (id, item) => {
      if (props.onRowSelection) {
        if (!props.onRowSelection(id, item)) {
          dispatch(selectRow(name, id, props.deselect));
        }
      } else {
        dispatch(selectRow(name, id, props.deselect));
      }
    },
    onRowClick: id => {
      if (props.onRowClick) {
        if (!props.onRowClick(id)) {
          dispatch(selectRow(name, id, props.deselect));
        }
      } else {
        dispatch(selectRow(name, id, props.deselect));
      }
    },
  };
};

/**
 * the root table container
 * renders a table according to the store settings and props
 *
 * example
 * <Table name="example" data={data}>
 *     <Column key="id" title="Id" value={ValueCell} sortKey="id"/>
 *     <Column key="name" title="Name" value={NameCell} sortKey="meta.name"/>
 * </Table>
 */
class Table extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.any,
    defaultOrder: PropTypes.shape({
      direction: PropTypes.oneOf(['ascending', 'descending']),
      key: PropTypes.string,
      sortKey: PropTypes.string,
    }),
    deselect: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    ids: PropTypes.arrayOf(PropTypes.string),
    isLoading: PropTypes.bool,
    name: PropTypes.string.isRequired,
    noReorder: PropTypes.bool,
    onCustomPage: PropTypes.func,
    onInit: PropTypes.func,
    onNextClick: PropTypes.func,
    onPrevClick: PropTypes.func,
    onRowClick: PropTypes.func,
    onRowSelection: PropTypes.func,
    onSortClick: PropTypes.func,
    onOffsetChange: PropTypes.func,
    order: PropTypes.shape({
      direction: PropTypes.oneOf(['ascending', 'descending']),
      key: PropTypes.string,
    }),
    pageLimit: PropTypes.number,
    pagination: PropTypes.object,
    rowClass: PropTypes.func,
    selected: PropTypes.string,
    selectedByDefault: PropTypes.string,
    setOrder: PropTypes.any,
    tabIndex: PropTypes.bool,
    tableName: PropTypes.string,
    toolTip: PropTypes.shape({
      dataFor: PropTypes.func.isRequired,
      idField: PropTypes.string.isRequired,
    }),
    freezeFirstColumn: PropTypes.bool,
    infiniteScroll: PropTypes.bool,
  };
  static defaultProps = {
    ids: [],
    pagination: {},
    freezeFirstColumn: true,
    pageLimit: PAGE_LIMIT,
    infiniteScroll: false,
  };

  static processColumns(columnElements) {
    try {
      return columnElements.map(c => ({
        key: c.key,
        title: c.props.title,
        sortKey: c.props.sortKey,
        value: Boolean(c.props.value),
        cell: c.props.cell,
        className: c.props.className,
      }));
    } catch (TypeError) {
      return [
        {
          key: columnElements.key,
          title: columnElements.props.title,
          sortKey: columnElements.props.sortKey,
          value: Boolean(columnElements.props.value),
          cell: columnElements.props.cell,
          className: columnElements && columnElements.length > 1 ? columnElements[0].props.className : '',
        },
      ];
    }
  }

  isFirstRender = true;
  scrollTop = 0;

  constructor(props) {
    super(props);
    const { children, data, onInit, defaultOrder } = props;

    this.state = {
      filters: [],
      excludedColumns: [],
      currentPage: '',
      scrollAtPage: '',
      pageLimit: props.infiniteScroll ? 0 : props.pageLimit,
    };

    if (props.uuid && props.enableColumnFiltering) {
      let tableFilterStateSerialized = undefined;
      try {
        tableFilterStateSerialized = localStorage.getItem(props.uuid);
      } catch (e) {}
      const nodePattern = new RegExp(/^{"type":".+","props":{.+}}/);

      if (tableFilterStateSerialized !== undefined) {
        try {
          const filtersDeserialized = JSON.parse(tableFilterStateSerialized);
          const filters = filtersDeserialized.map(f => {
            return {
              id: f.id,
              checked: f.checked,
              label: nodePattern.test(f.label) ? deserialize(f.label) : f.label,
            };
          });
          const excludedColumns = FiltersStateHelper.getExcludedItems(filters);
          this.state.filters = filters;
          this.state.excludedColumns = excludedColumns;
        } catch (error) {
          localStorage.removeItem(props.uuid);
        }
      }
    }

    const firstSortable = _.find(this.columnDefinitions, d => d.sortKey !== undefined);

    this.columnDefinitions = Table.processColumns(children);

    onInit({
      columns: this.columnDefinitions
        .filter(c => !this.state.excludedColumns.includes(c.key))
        .map(d => ({
          ...d,
          value: undefined,
        })),
      data,
      order:
        defaultOrder ||
        (firstSortable && {
          key: firstSortable.key,
          sortKey: firstSortable.sortKey,
        }),
    });

    this.onRowSelection = this.onRowSelection.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onNextPage = this.onNextPage.bind(this);
    this.onPrevPage = this.onPrevPage.bind(this);
    this.dataRow = DataRowTemplate(children);

    if (props.enableColumnFiltering) {
      if (this.state.filters.length === 0) {
        const filters = FiltersStateHelper.generateDefaultFilterStateFromColumns(children);
        this.state.filters = filters;
      }
    }

    if (props.onOffsetChange && (props.onNextClick || props.onPrevClick || props.onCustomPage)) {
      console.warn('Table: onOffsetChange is set, onNextClick, onPrevClick and onCustomPage will be ignored');
    }

    if (props.uuid === undefined && props.enableColumnFiltering) {
      console.warn('Table: uuid is not set, filters will not be persisted');
    }
  }

  tableHeaderRef = React.createRef();
  scrollRef = React.createRef();
  tableContainerRef = React.createRef();
  paginationInputRef = React.createRef();

  componentDidMount() {
    const onResize = entries => {
      if (this.props.onPageLimitChange || this.props.infiniteScroll) {
        const height = entries[0].target?.clientHeight;
        const headerHeight = this.tableHeaderRef.current.clientHeight;
        const footerNeeded = this.props.pagination.totalRecords
          ? this.props.pagination.totalRecords > (height - headerHeight) / ROW_HEIGHT
          : false;
        const footerHeight = footerNeeded ? TABLE_PAGINATION_HEIGHT : 0;
        const pageLimit = Math.floor((height - headerHeight - footerHeight) / ROW_HEIGHT);
        this.setState({ pageLimit });
        const viewport = this.scrollRef?.current?.osInstance()?.elements()?.viewport;
        if (viewport) {
          this.setState({
            scrollAtPage: this.calculatePageForScrollPosition(
              viewport?.scrollHeight,
              viewport?.clientHeight,
              viewport?.scrollTop,
              pageLimit,
            ),
          });
        }
        if (this.props.onPageLimitChange) {
          this.props.onPageLimitChange(pageLimit);
        }
      }
    };
    this.resizeObserver = new ResizeObserver(onResize);
    this.resizeObserver.observe(this.tableContainerRef.current);
    onResize([{ target: this.tableContainerRef.current }]);
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.order !== nextProps.order && nextProps.order.direction) {
      this.props.onSortClick(nextProps.order);
    }
  }

  onRowSelection(id, item) {
    const { onRowSelection } = this.props;
    if (onRowSelection) {
      onRowSelection(id, item);
    }
  }

  onRowClick(id) {
    const { onRowClick } = this.props;
    if (onRowClick) {
      onRowClick(id);
    }
  }

  onPrevPage(page) {
    const { pagination } = this.props;

    if (this.props.infiniteScroll && this.scrollRef) {
      const scroll = (page - 1) * (this.state.pageLimit * ROW_HEIGHT);
      const viewport = this.scrollRef.current.osInstance().elements().viewport;
      viewport.scrollTo({ top: scroll, behavior: 'smooth' });
      return;
    }

    if (this.props.onOffsetChange) {
      this.props.onOffsetChange(pagination.offset - this.state.pageLimit);
    } else {
      this.props.onPrevClick();
    }

    if (this.props.selected) {
      this.props.selectRow('0');
    }
  }

  onNextPage(page) {
    const { pagination } = this.props;
    if (this.props.infiniteScroll && this.scrollRef) {
      const scroll = (page - 1) * (this.state.pageLimit * ROW_HEIGHT);
      const viewport = this.scrollRef.current.osInstance().elements().viewport;
      viewport.scrollTo({ top: scroll, behavior: 'smooth' });
      return;
    }

    if (this.props.onOffsetChange) {
      this.props.onOffsetChange(pagination.offset + this.state.pageLimit);
    } else {
      this.props.onNextClick();
    }

    if (this.props.selected) {
      this.props.selectRow('0');
    }
  }

  onCustomPagination = e => {
    const { value } = e.target;
    if (!this.props.onCustomPage && !this.props.onOffsetChange) return;
    const totalPages = getTotalPagesCount(this.props.pagination.totalRecords, this.state.pageLimit);
    if (totalPages >= parseInt(value, 10)) {
      this.setState({ currentPage: value });
      this.onCustomPaginationDebounced(value);
      setTimeout(() => {
        this.setState({ currentPage: '' });
        if (this.props.infiniteScroll) {
          if (this.scrollRef) {
            const scroll = (value - 1) * (this.state.pageLimit * ROW_HEIGHT);
            const viewport = this.scrollRef.current.osInstance().elements().viewport;
            viewport.scrollTo({ top: scroll, behavior: 'smooth' });
            this.paginationInputRef.current.blur();
          }
        }
      }, 1500);
    } else {
      return;
    }
  };

  onCustomPaginationDebounced = _.debounce(str => {
    if (this.props.infiniteScroll) {
      return;
    }

    const { pagination } = this.props;
    const totalPages = getTotalPagesCount(pagination.totalRecords, this.props.pageSize);
    let parsed = parseInt(str, 10);
    if (isNaN(parsed)) return;

    if (parsed < 1) parsed = 1;
    if (parsed > totalPages) parsed = totalPages;

    if (this.props.onOffsetChange) {
      this.props.onOffsetChange((parsed - 1) * this.state.pageLimit);
    } else {
      this.props.onCustomPage(parsed);
    }

    if (this.props.selected) {
      this.props.selectRow('0');
    }
  }, 1000);

  rowsToFetch(scrollDirection, currentPage) {
    const { data } = this.props;
    const pageLimit = this.state.pageLimit;

    const pagesBefore = scrollDirection >= 0 ? Math.floor(PAGES_BATCH / 2) : Math.ceil(PAGES_BATCH / 2);
    const startRange = Math.max(currentPage - 1 - pagesBefore, 0) * pageLimit;
    const endRange = Math.min(startRange + pageLimit * PAGES_BATCH, data.length);

    const dataToCheck = data.slice(startRange, endRange);
    if (dataToCheck.some(d => d === null)) {
      return {
        offset: dataToCheck.findIndex(d => d === null) + startRange,
        limit: dataToCheck.filter(p => p === null)?.length,
      };
    }
    return { offset: null, limit: null };
  }

  calculatePageForScrollPosition(scrollHeight, clientHeight, scrollTop, pageLimit = this.state.pageLimit) {
    const { pagination } = this.props;
    const isBottomReached = scrollHeight - Math.round(scrollTop) === clientHeight;
    const currentPage = Math.round(scrollTop / (pageLimit * ROW_HEIGHT)) + 1;
    const lastPartialPageNeeded =
      pagination.totalRecords % pageLimit && pagination.totalRecords > currentPage * pageLimit;
    return isBottomReached && lastPartialPageNeeded ? currentPage + 1 : currentPage;
  }

  scrollEventDebounced = _.debounce((scrollTop, scrollAtPage) => {
    const { offset, limit } = this.rowsToFetch(scrollTop - this.scrollTop, scrollAtPage);
    this.scrollTop = scrollTop;
    if (offset) {
      this.props.onOffsetChange(offset, limit);
    }
  }, 1000);

  scrollEvent() {
    const viewport = this.scrollRef.current.osInstance().elements().viewport;
    const scrollAtPage = this.calculatePageForScrollPosition(
      viewport?.scrollHeight,
      viewport?.clientHeight,
      viewport?.scrollTop,
    );
    this.setState({ scrollAtPage });
    this.scrollEventDebounced(viewport?.scrollTop, scrollAtPage);
  }

  onSetColumns = (item, value) => {
    const filters = FiltersStateHelper.changeFilterState(this.state.filters, item, value);
    const excludedColumns = FiltersStateHelper.getExcludedItems(filters);

    if (this.props.uuid) {
      const filtersPreSerialized = filters.map(f => {
        return {
          id: f.id,
          checked: f.checked,
          label: typeof f.label === 'string' ? f.label : serialize(f.label),
        };
      });
      try {
        localStorage.setItem(this.props.uuid, JSON.stringify(filtersPreSerialized));
      } catch (e) {}
    }

    this.setState({ excludedColumns, filters });
    this.props.onSetColumns(
      this.columnDefinitions.filter(c => !excludedColumns.includes(c.key)),
      filters,
    );
  };

  resetColumnsFilters = () => {
    const filters = FiltersStateHelper.getDefaultOnFilters(this.state.filters);
    const excludedColumns = [];

    this.setState({
      filters,
      excludedColumns,
    });

    this.props.onSetColumns(this.columnDefinitions, filters);

    if (this.props.uuid) {
      try {
        localStorage.removeItem(this.props.uuid);
      } catch (e) {}
    }
  };

  render() {
    const {
      name,
      className = '',
      columns = [],
      ids,
      data,
      setOrder,
      order,
      selected,
      isLoading,
      toolTip = null,
      rowClass = null,
      noReorder,
      pagination,
      tableName,
      tabIndex,
      freezeFirstColumn,
      buttons,
      enableColumnFiltering,
      infiniteScroll,
    } = this.props;

    const offset = pagination.offset ? pagination.offset : 0;

    const page = this.state.scrollAtPage || offset / this.state.pageLimit + 1;
    const totalPages = getTotalPagesCount(pagination.totalRecords, this.state.pageLimit);
    const rowProps = {};

    if (toolTip) {
      rowProps.toolTip = toolTip;
    }

    if (rowClass) {
      rowProps.rowClass = rowClass;
    }
    if (tabIndex) {
      rowProps.tabIndex = true;
    }

    const Row = this.dataRow;

    let tbody;
    let tfoot = null;

    if (isLoading && (this.isFirstRender || ids.length === 0)) {
      tbody = <SpinnerRow colspan={columns.length} />;
    } else if (ids.length === 0 && !isLoading) {
      tbody = <NoDataRow colspan={columns.length} />;
    } else {
      this.isFirstRender = false;
      tbody = (
        <tbody className="table-body fade-in">
          {ids.map(k => {
            return data[k] ? (
              <Row
                key={k}
                rowId={k}
                data={data[k]}
                selected={k === selected}
                onClick={this.onRowSelection != undefined ? () => this.onRowSelection(k, data[k]) : undefined}
                onDoubleClick={this.onRowClick != undefined ? this.onRowClick : undefined}
                uiClass={tableName === 'patient-table' && !data[k].status ? 'disabled' : 'active'}
                buttons={buttons}
                excludedColumns={this.state.excludedColumns}
                {...rowProps}
              />
            ) : (
              <DataLoadingRow columns={columns} key={k} rowId={k} {...rowProps} />
            );
          })}
        </tbody>
      );

      // Pagination with spinner
      if (totalPages > 1) {
        const inner = isLoading ? (
          <div className="pagination-spinner-container">
            <div className="spinner" />
          </div>
        ) : (
          <input
            type="text"
            onChange={this.onCustomPagination}
            placeholder={`${page} / ${totalPages}`}
            tabIndex={-1}
            value={this.state.currentPage}
            data-testid="table-custom-page"
            ref={this.paginationInputRef}
          />
        );

        tfoot = (
          <div className="table-pagination">
            <div className={`footerButtonContainer ${page === 1 ? 'disabled' : ''}`}>
              <button
                className={`add-arrow left prev ${page === 1 ? 'disabled' : ''}`}
                onClick={() => this.onPrevPage(page - 1)}
                data-testid="table-prev-page"
              />
            </div>
            {inner}
            <div className={`footerButtonContainer ${page >= totalPages ? 'disabled' : ''}`}>
              <button
                className={`add-arrow right next ${page >= totalPages ? 'disabled' : ''}`}
                onClick={() => this.onNextPage(page + 1)}
                data-testid="table-next-page"
              />
            </div>
          </div>
        );
      }
    }

    return (
      <div
        className={`table-container ${tfoot || this.props.infiniteScroll ? '' : 'marginBottomTable'}`}
        ref={this.tableContainerRef}
      >
        {enableColumnFiltering && (
          <DropdownTableFilter
            filters={this.state.filters}
            handleChange={this.onSetColumns}
            resetToDefaults={this.resetColumnsFilters}
          />
        )}
        <OverlayScrollbarsComponent
          defer
          className={`simplebar-100percent-width${freezeFirstColumn ? ' freezeFirstColumn' : ''}${
            tfoot ? ' with-footer' : ''
          }`}
          options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
          events={{ scroll: infiniteScroll ? e => this.scrollEvent(e) : undefined }}
          ref={this.scrollRef}
        >
          <table className={`table ${name}${className !== '' ? ` ${className}` : ''}`}>
            <thead className="table-header" ref={this.tableHeaderRef}>
              <HeaderRow order={order} columns={columns} onClick={noReorder ? () => {} : setOrder} />
            </thead>
            {tbody}
          </table>
        </OverlayScrollbarsComponent>
        {tfoot}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Table);

const TABLE_PAGINATION_HEIGHT = 51;
const ROW_HEIGHT = 50;
export const PAGES_BATCH = 5;
