import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import 'react-datepicker/src/stylesheets/datepicker.scss';
import { Modal } from '../containers';
import Form from '../containers/Form';
import { closeModal } from '../actions/modal';
import { resetForm } from '../actions/forms';
import { patientAction } from '../actions/patient';
import Strings from '../Strings';
import { POST_ADD_MEDICINE_RESULT, PATIENT_DETAIL_EDIT_RESULT } from '../actions/action-types';
import './editStudyModal.scss';
import WithLoader from '../components/_hocs/withLoader';

class EditProfileModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      firstName: '',
      lastName: '',
      username: '',
      gender: '',
      dateOfBirth: moment(),
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.patient) {
      const { patient } = this.props;
      this.updateState(patient);
    }
  }

  componentDidMount() {
    if (this.props.patient) {
      const { patient } = this.props;
      this.updateState(patient);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.patient) {
      const { patient } = newProps;
      this.updateState(patient);
    }
  }

  onTextChange(event) {
    const value = event.target.value;
    this.setState({ [event.target.id]: value });
  }

  onSave() {
    const { firstName, lastName, dateOfBirth, gender } = this.state;

    const profileData = {
      first_name: firstName,
      last_name: lastName,
      gender,
      date_of_birth: dateOfBirth?.isValid() ? dateOfBirth.format('YYYY-MM-DD') : undefined,
    };

    const { patientId } = this.props.data;

    this.props.updatePatientProfile(patientId, profileData);
  }

  handleChange = date => {
    this.setState({ dateOfBirth: date });
  };

  updateState(patient) {
    if (!patient || Object.keys(patient).length === 0) {
      return;
    }

    this.setState({
      firstName: patient.firstName,
      lastName: patient.lastName,
      username: patient.username,
      gender: patient.gender,
      dateOfBirth: moment(patient.dateOfBirth),
    });
  }

  render() {
    const { onOpen, onCancel, isLoading, patient, ...props } = this.props;

    const { errors, firstName, lastName, username, gender, dateOfBirth } = this.state;

    const selectedDOB = moment(dateOfBirth);

    return (
      <Modal name="edit-profile" onOpen={onOpen} additionalClasses={['form-modal']} {...props}>
        <WithLoader isLoading={isLoading}>
          <div className={`step1 ${this.state.step1Show ? 'opened' : 'closed'}`}>
            {!isLoading ? (
              <Form onCancel={onCancel} className="edit-profile" key="edit-patient-profile">
                <div className="flex-row">
                  <div key="caregiver-first-name " className="mb-15 dialog-input">
                    <div className="input-container ">
                      <input
                        name="firstName"
                        id="firstName"
                        className="dialog-form"
                        placeholder={Strings.firstName}
                        type="text"
                        value={firstName}
                        onChange={this.onTextChange}
                      />
                    </div>
                  </div>
                  <div key="caregiver-last-name " className="mb-15 dialog-input">
                    <div className="input-container ">
                      <input
                        name="lastName"
                        id="lastName"
                        className="dialog-form"
                        placeholder={Strings.lastName}
                        type="text"
                        value={lastName}
                        onChange={this.onTextChange}
                      />
                    </div>
                  </div>
                  <div key="caregiver-gender " className="mb-15 dialog-input">
                    <div className="input-container ">
                      <select
                        name="gender"
                        id="gender"
                        className="dialog-form"
                        placeholder={Strings.gender}
                        value={gender}
                        onChange={this.onTextChange}
                      >
                        {Object.keys(Strings.genders).map(key => {
                          const g = Strings.genders[key];
                          return <option value={g}>{g}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                  <div key="caregiver-user-name " className="mb-15 dialog-input">
                    <div className="input-container ">
                      <input
                        type="text"
                        className="dialog-form"
                        name="username"
                        value={username}
                        placeholder={Strings.userName}
                        disabled
                      />
                    </div>
                  </div>

                  <div key="profile-dob" className="mb-15 dialog-input">
                    <div className="two-column-layout">
                      <DatePicker
                        selected={selectedDOB.isValid() ? selectedDOB : undefined}
                        onChange={this.handleChange}
                        maxDate={moment()}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="MM / DD / YYYY"
                      />
                    </div>
                  </div>
                </div>
                <div key="errors-block" className="errors-block">
                  {errors.map(error => (
                    <div key={error.replace(/ +/g, '')}>{error}</div>
                  ))}
                </div>
                <div className="button-bar reverse" key="button-bar">
                  <button className="brand-blue" key="next" onClick={this.onSave}>
                    {Strings.save}
                  </button>

                  <button className="white gray-text" key="cancel" type="cancel">
                    {Strings.cancel}
                  </button>
                </div>
              </Form>
            ) : (
              ''
            )}
          </div>
        </WithLoader>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { patientDetail } = state.entities;

  return {
    patient: patientDetail.patientProfile,
    isLoading: patientDetail.loading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('edit-profile')),
  onSaveMedicine: (patient, patientId, dateRange) =>
    dispatch(patientAction.addMedicine(patient)).then(response => {
      if (response && response.type === POST_ADD_MEDICINE_RESULT) {
        dispatch(patientAction.actionSchedulesList(patientId, dateRange.infimum, dateRange.supremum, false));
        dispatch(closeModal('edit-profile'));
      }
      return response;
    }),

  updatePatientProfile: (patientId, data) =>
    dispatch(patientAction.updatePatientProfileByAdmin(patientId, data)).then(response => {
      if (response && response.type === PATIENT_DETAIL_EDIT_RESULT) {
        dispatch(patientAction.actionPatientDetail(patientId)).then(() => {
          dispatch(closeModal('edit-profile'));
        });
      }
      return response;
    }),
  onOpen: () => dispatch(resetForm('edit-profile', ownProps.data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileModal);
