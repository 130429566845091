import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { actions } from './redux/actions';
import Strings from '../../../../Strings';
import YourKitsGraph from '../../../../components/Graphs/YourKitsGraph';
import '../Widgets.scss';

function YourKits(props) {
  useEffect(() => {
    props.getData(props.timezone);
  }, [props.refreshTimestamp]);

  const { kits, timezone } = props;
  const graphData =
    kits &&
    kits.map(k => {
      return {
        date: moment(k.date * 1000).tz(timezone),
        kits: k.kits,
        lowSignals: k.low_signal_kits,
      };
    });

  let kitsCount = graphData && graphData.length > 0 && graphData.at(-1).kits;
  if (isNaN(kitsCount)) kitsCount = 0;
  let lowSignalKitsCount = graphData && graphData.length > 0 && graphData.at(-1).lowSignalKitsCount;
  if (isNaN(lowSignalKitsCount)) lowSignalKitsCount = 0;

  return (
    <div className="boxStatsWithGraph">
      <div className="boxStatsWithGraph-title">{Strings.yourKits}</div>
      <div className="boxStatsWithGraph-hl" />
      <div className="boxStatsWithGraph-stats">
        <div className="boxStatsWithGraph-statsBoxOf2 mr">
          <div className="boxStatsWithGraph-statValue colorBlue">{kitsCount}</div>
          <div className="boxStatsWithGraph-statLabel">{Strings.kits}</div>
        </div>
        <div className="boxStatsWithGraph-statsVl" />
        <div className="boxStatsWithGraph-statsBoxOf2 ml">
          <div className="boxStatsWithGraph-statValue colorRed">{lowSignalKitsCount}</div>
          <div className="boxStatsWithGraph-statLabel">{Strings.lowSignal}</div>
        </div>
      </div>
      <div className="boxStatsWithGraph-hl" />
      <div className="boxStatsWithGraph-graph">
        {graphData && graphData.length > 0 && <YourKitsGraph graphData={graphData} unit="" />}
      </div>
    </div>
  );
}

YourKits.widgetId = 'YourKitsWidget';

YourKits.propTypes = {
  getData: PropTypes.func,
  kits: PropTypes.array,
  isLoading: PropTypes.any,
  timezone: PropTypes.string,
  refreshTimestamp: PropTypes.any.isRequired,
};

const mapStateToProps = state => {
  const { widgetYourKitsReducer } = state.dashboardWidgets;
  return {
    kits: widgetYourKitsReducer && widgetYourKitsReducer?.kits,
    isLoading: widgetYourKitsReducer && widgetYourKitsReducer?.isLoading,
    timezone: state.auth?.profile?.preferences?.timezone,
  };
};

const mapDispatchToProps = dispatch => ({
  getData: timezone => dispatch(actions.getYourKitsCount(timezone)),
});

export default connect(mapStateToProps, mapDispatchToProps)(YourKits);
