import { API_CALL } from '../middleware/api';
import { ADD_HARDWARE_ID, DELETE_CAP_PATIENT } from './action-types';

const qs = require('qs');

export const capsAction = {
  deleteCapPatient: (patientId, code, callAfterSuccess = []) => ({
    [API_CALL]: {
      endpoint: `/v1/admin/patient/delete/`,
      method: 'post',
      data: qs.stringify({
        patient: code,
      }),
      callAfterSuccess,
    },
    type: DELETE_CAP_PATIENT,
    data: patientId,
  }),

  addHardwareId: data => ({
    [API_CALL]: {
      endpoint: '/esi/v1/cap/events',
      method: 'post',
      data,
    },
    type: ADD_HARDWARE_ID,
    data,
  }),
};
