import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';

import { openModalAction } from '../../../../actions/modal';
import { PageHeader } from '../../../../components/PageHeader';
import { TextInput } from '../../../../components/PageHeader/TextInput';
import { PAGE_LIMIT } from '../../../../constants';
import Table, { Column } from '../../../../containers/Table/TableWithPagination';
import Strings from '../../../../Strings';
import { actions } from './redux/actions';
import { EDIT_COHORT } from './redux/constants';
import { ActionWidget, ActionButton, ItemSelectorHelper } from '../../../../components/ActionWidget';
import AdvancedFilters, { Filter } from '../../../../components/AdvancedFilters/AdvancedFilters';
import AdvancedFiltersBar from '../../../../components/AdvancedFilters/AdvancedFiltersBar';
import { getRelevantFilters } from '../../../../components/AdvancedFilters/helpers';

export function FacilityFilter(facilities) {
  return {
    facilityId: {
      group: 'facilityId',
      text: Strings.facility,
      values: [
        {
          text: Strings.all,
          value: 'all',
        },
        {
          text: Strings.none,
          value: '',
        },
      ].concat(
        facilities.map(f => {
          return { text: f.title, value: f.id };
        }),
      ),
    },
  };
}

export class Cohorts extends PureComponent {
  searchTime;
  refreshEnable = true;

  state = {
    excludeColumns: [],
    keyForTable: true,
    filters: [],
    openActionWidget: false,
    multiselectedItems: false,
    keyForItemCheckboxes: false,
    isListOpen: false,
  };

  itemSelectorHelper = new ItemSelectorHelper();

  pageRequest = {
    offset: 0,
    search: '',
    sortType: 'descending',
    limit: PAGE_LIMIT,
  };

  componentDidMount() {
    this.load();
    this.props.getFacilities();
  }

  onFiltersChange = _.debounce(() => {
    this.pageRequest.offset = 0;
    const facilities = getRelevantFilters(this.props.filters.filter(f => f.group === 'facilityId'));
    if (facilities?.length > 0) {
      this.pageRequest.facilityId = facilities.map(f => f.value).join(',');
    } else {
      delete this.pageRequest.facilityId;
    }
    this.pageRequest.offset = 0;
    this.load();
  }, 1000);

  componentDidUpdate(prevProps) {
    if (prevProps.refreshTimestamp !== this.props.refreshTimestamp) {
      this.load();
    }
    const { filters } = this.props;
    if (!_.isEqual(filters, prevProps.filters)) {
      this.onFiltersChange(filters);
    }
  }

  load() {
    const { getCohorts } = this.props;

    getCohorts(this.pageRequest);
  }

  onSearchQueryChange = query => {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    clearTimeout(this.searchTime);
    this.searchTime = setTimeout(() => {
      this.props.getCohorts(this.pageRequest);
    }, 1000);
  };

  getHeaderComponents = () => {
    return (
      <React.Fragment>
        <TextInput class="" placeholder={Strings.search} onChange={e => this.onSearchQueryChange(e.target.value)} />
        {this.props.facilities?.length > 0 && (
          <AdvancedFilters>
            <Filter definition={FacilityFilter(this.props.facilities).facilityId} />
          </AdvancedFilters>
        )}
      </React.Fragment>
    );
  };

  resetActionWidget = () => {
    this.setState({
      keyForItemCheckboxes: !this.state.keyForItemCheckboxes,
      openActionWidget: false,
    });
    this.itemSelectorHelper.clearItems();
  };

  itemChecked = ({ target }) => {
    if (target.checked === true) {
      this.itemSelectorHelper.addItem(target.id);
    } else {
      this.itemSelectorHelper.removeItem(target.id);
    }

    if (this.itemSelectorHelper.getItems().length !== 0) {
      this.setState({ openActionWidget: true });
    } else {
      this.setState({ openActionWidget: false });
    }

    this.setState({ multiselectedItems: this.itemSelectorHelper.isMultiselect() });
  };

  onPrevClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset - PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getCohorts(this.pageRequest);
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.getCohorts(this.pageRequest);
  };

  onNextClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset + PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getCohorts(this.pageRequest);
  };

  onSortClick = ({ sortKey, direction }) => {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    this.props.getCohorts(this.pageRequest);
  };

  onCohortSelected = id => {
    const { cohorts } = this.props;
    if (cohorts && cohorts[id].id) {
      this.props.onNavigate(`/cap-patients/cohorts/${cohorts[id].id}`);
    }
  };

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 10000);
    this.load();
  };

  resetActionWidget = () => {
    this.setState({
      keyForItemCheckboxes: !this.state.keyForItemCheckboxes,
      openActionWidget: false,
    });
    this.itemSelectorHelper.clearItems();
  };

  onEditCohort = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    const cohort = this.props.cohorts.filter(e => e.id == id)?.pop();
    this.props.onEditCohort(cohort, () => this.props.getCohorts(this.pageRequest));
    this.resetActionWidget();
  };

  onDeleteCohort = (event, id) => {
    event.preventDefault();
    event.stopPropagation();

    const cohort = this.props.cohorts.filter(e => e.id == id)?.pop();
    const data = {
      title: (
        <span>
          {Strings.deleteCohortWarning} <b>{cohort.title}</b>?
        </span>
      ),

      onConfirmAction: actions.deleteCohort(cohort.id),
      confirmPostAction: () => {
        this.resetActionWidget();
        this.load();
      },
    };
    this.props.openConfirmModal(data);
  };

  render() {
    const { isLoading, cohorts, pagination } = this.props;

    const columns = [];

    columns.push(
      <Column
        key="cohort"
        title={Strings.cohort}
        className="clickable"
        value={e => (
          <React.Fragment>
            <div className="cell-with-select">
              <div className="selector">
                <input
                  type="checkbox"
                  className="item-checkbox"
                  id={e.id}
                  onChange={this.itemChecked}
                  key={this.state.keyForItemCheckboxes}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                />
              </div>
              <div className="selector-label">{e.title}</div>
            </div>
          </React.Fragment>
        )}
      />,
    );
    columns.push(<Column key="activePatients" title={Strings.activePatients} value={e => e.active_patients} />);
    columns.push(<Column key="inactivePatients" title={Strings.inactivePatients} value={e => e.inactive_patients} />);
    columns.push(<Column key="manager" title={Strings.assignedTo} value={e => e.manager} />);
    columns.push(<Column key="facility" title={Strings.facility} value={e => e.facility || '-'} />);
    columns.push(<Column key="condition" title={Strings.condition} value={e => e.condition} />);

    const headerTitle =
      !isLoading && cohorts && pagination
        ? Strings.formatString(
            Strings.showingXCohorts,
            cohorts.length,
            pagination.totalRecords ? pagination.totalRecords : 0,
          )
        : Strings.showingWait;

    return (
      <React.Fragment>
        <div className="details-container">
          <PageHeader right={() => this.getHeaderComponents()} left={headerTitle} noLeftPadding />
          {this.props.facilities && <AdvancedFiltersBar customFilters={FacilityFilter(this.props.facilities)} />}
          <Table
            className="schedules-table"
            isLoading={isLoading}
            name="organizations"
            uuid="f3c4925d-b119-4b1f-a789-768fda02ce0f"
            data={cohorts || []}
            onRowSelection={this.onCohortSelected}
            onPrevClick={this.onPrevClick}
            onSortClick={this.onSortClick}
            onNextClick={this.onNextClick}
            onCustomPage={this.onCustomPage}
            pagination={
              pagination || {
                offset: 0,
                total: 0,
              }
            }
            enableColumnFiltering
          >
            {columns}
          </Table>
          <ActionWidget show={this.state.openActionWidget}>
            <ActionButton
              img="action-edit"
              tooltiptext="Edit"
              text="Edit"
              action={e => {
                this.onEditCohort(e, this.itemSelectorHelper.getFirstItem());
              }}
              disabled={this.state.multiselectedItems}
            />
            <ActionButton
              img="action-delete"
              tooltiptext="Delete"
              text="Delete"
              action={e => {
                this.onDeleteCohort(e, this.itemSelectorHelper.getFirstItem());
              }}
              disabled={this.state.multiselectedItems}
            />
          </ActionWidget>
        </div>
      </React.Fragment>
    );
  }
}

Cohorts.propTypes = {
  getCohorts: PropTypes.func,
  isLoading: PropTypes.any,
  onEditCohort: PropTypes.func,
  onNavigate: PropTypes.func,
  openConfirmModal: PropTypes.func,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
  cohorts: PropTypes.array,
  refreshTimestamp: PropTypes.any,
  facilities: PropTypes.array,
  filters: PropTypes.array,
};

const mapStateToProps = state => {
  const { cohorts } = state.superUser;
  return {
    cohorts: cohorts && cohorts?.cohorts,
    isLoading: cohorts && cohorts?.isLoading,
    pagination: cohorts && cohorts?.pagination,
    facilities: state.superUser.cohorts?.facilities,
    filters: state.entities.advancedFilters.filters.items,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getCohorts: pageRequest => dispatch(actions.getCohorts(pageRequest)),
  onEditCohort: (cohort, nextAction) =>
    dispatch(
      openModalAction('edit-cohort', {
        action: actions.editCohort,
        actionType: EDIT_COHORT,
        cohort,
        nextAction,
        caption: Strings.editCohort,
      }),
    ),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  getFacilities: () => dispatch(actions.getFacilities()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cohorts);
