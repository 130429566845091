const ESI_PATIENT_LOGIN = 'esi-patient-login';

export const ESI_PATIENT_LOGIN_SEND_CODE = ESI_PATIENT_LOGIN + '-send-phone';
export const ESI_PATIENT_LOGIN_SEND_CODE_RESULT = ESI_PATIENT_LOGIN_SEND_CODE + '/result';
export const ESI_PATIENT_LOGIN_SEND_CODE_ERROR = ESI_PATIENT_LOGIN_SEND_CODE + '/error';

export const ESI_PATIENT_LOGIN_RESEND_CODE = ESI_PATIENT_LOGIN + '-resend-code';
export const ESI_PATIENT_LOGIN_RESEND_CODE_RESULT = ESI_PATIENT_LOGIN_RESEND_CODE + '/result';
export const ESI_PATIENT_LOGIN_RESEND_CODE_ERROR = ESI_PATIENT_LOGIN_RESEND_CODE + '/error';

export const ESI_PATIENT_LOGIN_CHECK_CODE = ESI_PATIENT_LOGIN + '-check-code';
export const ESI_PATIENT_LOGIN_CHECK_CODE_RESULT = ESI_PATIENT_LOGIN_CHECK_CODE + '/result';
export const ESI_PATIENT_LOGIN_CHECK_CODE_ERROR = ESI_PATIENT_LOGIN_CHECK_CODE + '/error';

export const ESI_PATIENT_LOGIN_CONFIG = ESI_PATIENT_LOGIN + '-config';
export const ESI_PATIENT_LOGIN_CONFIG_RESULT = ESI_PATIENT_LOGIN_CONFIG + '/result';
export const ESI_PATIENT_LOGIN_CONFIG_ERROR = ESI_PATIENT_LOGIN_CONFIG + '/error';

export const ESI_IS_TC_AGREE = 'esi_is_tc_agree';
export const ESI_IS_CAP_ID_SET = 'esi_is_cap_id_set';
export const CAP_SET_BY_SMS = 'cap_set_by_sms';
export const ESI_IS_TIMEZONE_SET = 'esi_is_timezone_set';
export const ESI_IS_PORTAL_TUTORIAL_VIEWED = 'esi_is_portal_tutorial_viewed';
export const ESI_IS_PORTAL_ONBOARDING_TUTORIAL_VIEWED = 'esi_is_portal_onboarding_tutorial_viewed';
export const ESI_PATIENT_CAP_ID_UPDATE = 'esi_patient_update_hardware_cap_id';
export const ESI_PATIENT_CAP_ID_UPDATE_RESULT = 'esi_patient_update_hardware_cap_id/result';
export const ESI_PATIENT_CAP_ID_UPDATE_ERROR = 'esi_patient_update_hardware_cap_id/error';

export const SKIP_ON_BOARDING = 'skip-onboarding';
export const SKIP_ON_BOARDING_RESULT = 'skip-onboarding/result';
