import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import {
  GET_MEDICATION_DOCS_DETAILS_RESULT,
  LIST_MEDICATION_DETAILS_RESULT,
  LIST_PATIENT,
} from '../../actions/action-types';
import { openModalAction } from '../../actions/modal';
import { patientAction } from '../../actions/patient';
import { PageHeader } from '../../components/PageHeader';
import { Button } from '../../components/PageHeader/Button';
import { Switch2 } from '../../components/Switch2/Switch2';
import {
  ACTIVE_STATUS,
  DATE_FORMAT_MONTH_NAME,
  DATE_FORMAT_SPACES,
  HEADER_BUTTON_BLUE,
  LANGUAGE_EN,
  NDC,
  RXNORM,
  TIME_FORMAT_12_UPPERCASE,
} from '../../constants';
import hardwareDeviceImg from '../../images/rx/hardwareDevice.png';
import infoGraphicsImg from '../../images/rx/info-graphic.svg';
import Strings from '../../Strings';
import { dateToFromNowDaily, getBatterStatus, getMedsType } from '../../utils';
import { PORTAL_ESI } from '../../utils/portalType';
import './MedicationDetailsView.scss';

const mapStateToProps = state => {
  const userFullname = state.auth.profile ? `${state.auth.profile.first_name} ${state.auth.profile.last_name}` : '';
  const { schedules } = state.entities;
  return {
    medication: schedules.medicationDetails,
    isLoading: schedules.loading,
    userFullname,
    timezone: state.auth?.profile?.preferences?.timezone,
  };
};

const mapDispatchToProps = dispatch => ({
  updateMedicationStatus: data => dispatch(patientAction.updateMedicationStatus(data)),
  loadMedications: medicationId => dispatch(patientAction.actionMedicationDetails(medicationId)),
  editSchedule: schedule =>
    dispatch(
      openModalAction('edit-patient-schedule', {
        action: patientAction.actionList,
        actionType: LIST_PATIENT,
        data: schedule,
      }),
    ),
  editStock: schedule =>
    dispatch(
      openModalAction('edit-stock', {
        action: patientAction.actionList,
        actionType: LIST_PATIENT,
        data: schedule,
      }),
    ),
  updateDevice: () => dispatch(openModalAction('activate-your-device-modal', { isChangeCap: true })),
  getMedicineDocDetails: (code, language, codeType) =>
    dispatch(patientAction.getMedicineDocDetails(code, language, codeType)),
  onNavigate: path => dispatch(push(path)),
});

class MedicationDetailsView extends PureComponent {
  state = {
    url: '',
    checked: Boolean,
  };
  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.loadMedications(this.props.match.params.medicationId).then(r => {
      if (r.type === LIST_MEDICATION_DETAILS_RESULT) {
        if (r.response) {
          this.setState({ checked: this.props.medication.status === ACTIVE_STATUS });
          const ndc_code = r.response?.schedule?.format?.ndc_code;
          const rxnorm_code = r.response?.schedule?.format?.rxnorm_code;
          let code;
          let codeType;
          if (ndc_code) {
            code = ndc_code;
            codeType = NDC;
          } else if (rxnorm_code) {
            code = rxnorm_code;
            codeType = RXNORM;
          } else {
            code = '';
            codeType = '';
          }
          if (code && codeType) {
            this.learnMoreValues(code, LANGUAGE_EN, codeType);
          }
        }
      }
    });
  };

  onUpdateCapClick = () => {
    this.props.updateDevice();
  };

  learnMoreValues = (code, language, codeType) => {
    this.props.getMedicineDocDetails(code, language, codeType).then(r => {
      if (r.type === GET_MEDICATION_DOCS_DETAILS_RESULT) {
        const url = r.response.data.fdbPem;
        this.setState({ url });
      }
    });
  };

  onLearnMore = () => {
    const { url } = this.state;
    window.open(url, '_blank');
  };

  onEditSchedule = () => {
    const medication = this.props.medication;
    const data = {
      frequency: this.getType(),
      timesPerDay: this.getTimesPerDay(),
      time: this.getTime('valueOnly'),
      cap_id: medication.cap_info ? medication.cap_info.cap_id : '',
      cap_type: medication.cap_info ? medication.cap_info.cap_type : '',
      name: medication.schedule ? medication.schedule.name : '',
      taken_for: '',
      type: this.getType(),
      strength: medication.schedule.strength,
      patientId: this.props.match.params.id,
      id: medication.schedule ? medication.schedule.id : '',
      schedule_timezone: medication.schedule_timezone || this.props.timezone,
    };
    this.props.editSchedule(data);
  };

  onEditStock = () => {
    const medication = this.props.medication;
    const data = {
      patientId: this.props.match.params.id,
      id: medication.schedule ? medication.schedule.id : '',
      stock_last_till: medication.stock ? medication.stock.stock_last_till : '',
      stock_status: medication.stock ? medication.stock.stock_status : 'low',
      dosePerDay: this.getTimesPerDay(),
      enabled: medication.stock ? medication.stock.enabled : true,
      medicine_name: medication.stock ? medication.schedule.name : '',
      remind_before_days: medication.stock ? medication.stock.remind_before_days : '',
      reminder_at: medication.stock ? medication.stock.reminder_at : '',
      reminder_time: medication.stock ? medication.stock.reminder_time : '',
      stock: medication.stock ? medication.stock.stock : '0',
    };
    this.props.editStock(data);
  };

  getType = () => {
    const { medication } = this.props;

    if (medication.schedule.format && medication.schedule.format.type) {
      if (medication.schedule.format.type === 'DayOfWeek') {
        return 'Days Of Week';
      }
      return getMedsType(medication.schedule.format.type);
    }

    if (medication.schedule && medication.schedule.type) {
      return getMedsType(medication.schedule.type);
    }

    return '-';
  };

  getTimesPerDay = () => {
    const { medication } = this.props;
    if (medication.schedule.format && medication.schedule.format.offset) {
      return medication.schedule.format.offset.length;
    }

    if (medication.schedule.format && medication.schedule.format.days) {
      const day = Object.keys(medication.schedule.format.days)[0];
      return medication.schedule.format.days[day].offset.length;
    }

    return '-';
  };

  getEndDate = (end_dt, schedule_timezone) => {
    if (schedule_timezone) {
      return moment.tz(moment.unix(end_dt), schedule_timezone).format(DATE_FORMAT_SPACES);
    }
    return moment.unix(end_dt).format(DATE_FORMAT_SPACES);
  };

  getTime = value => {
    const { medication } = this.props;

    const format = medication?.schedule?.format;

    if (!format) return '-';

    if (format.offset) {
      const size = medication.schedule.format.offset.length - 1;
      return medication.schedule.format.offset.map((item, index) => {
        const timeText = moment()
          .startOf('day')
          .seconds(item)
          .format(TIME_FORMAT_12_UPPERCASE);
        if (value === 'valueOnly') {
          return timeText;
        }
        if (size == index) {
          return <div className="timeText">{timeText}</div>;
        }
        return <div className="timeText">{timeText},</div>;
      });
    }

    if (format.days) {
      const day = Object.keys(medication.schedule.format.days)[0];
      return medication.schedule.format.days[day].offset.map(item => {
        return moment()
          .startOf('day')
          .seconds(item)
          .format(TIME_FORMAT_12_UPPERCASE);
      });
    }

    return '-';
  };

  onMedicationReminderChange = (sw, checked) => {
    const data = {
      scheduleId: this.props.match.params.medicationId,
      status: checked ? 'active' : 'inactive',
    };
    if (
      this.props.medication.schedule.format.end_dt < Math.floor(Date.now() / 1000) ||
      !this.props.medication.schedule.format.end_dt
    ) {
      this.updateStatus(sw, data, checked);
    } else if (!checked) {
      this.updateStatus(sw, data, checked);
    }
  };

  updateStatus(sw, data, checked) {
    sw.setBusy(true);
    this.props.updateMedicationStatus(data).then(r => {
      if (r.response) {
        this.setState({ checked: data.status === ACTIVE_STATUS });
        sw.setBusy(false);
        sw.setChecked(checked);
        const medicationId = r.response?.schedule?.id;
        this.props.onNavigate(`/patient/${this.props.match.params.id}/medications/${medicationId}/details`);
        window.location.reload();
      } else {
        alert(Strings.errors.internalError); // eslint-disable-line no-alert
      }
    });
  }

  renderStocks() {
    const { isLoading, medication } = this.props;

    if (isLoading) return null;
    if (!medication) return null;
    if (this.props.medication?.schedule?.type == 'as_needed') return null;
    const dayDiff = willLastFor => moment.unix(willLastFor).diff(moment(), 'days');
    const btnRefill = (
      <Button
        class={`${HEADER_BUTTON_BLUE} bottom`}
        onClick={this.onEditStock}
        title={Strings.patient_medications.refill}
        ariaLabel="refill stock"
      />
    );

    return medication.stock ? (
      <div className="infoBox center">
        <h4 className="title">{Strings.patient_medications.stock}</h4>

        {/* {dayDiff(medication.stock.stock_last_till) > 0 ? ( */}
        <div className="data">
          <div className="rowContent">
            <label>{Strings.patient_medications.numberOfPill}</label>
            <span className="labelValue">{medication.stock.stock}</span>
          </div>

          <div className="rowContent">
            <label>{Strings.patient_medications.dosesInStock}</label>
            <span className="labelValue">{medication.stock.stock_status}</span>
          </div>

          <div className="rowContent">
            <label>{Strings.patient_medications.willLastFor}</label>
            <span className="labelValue">{`${dayDiff(medication.stock.stock_last_till)} days`}</span>
          </div>

          <div className="rowContent" style={{ marginBottom: 20 }}>
            <label>{Strings.patient_medications.expiresOn}</label>
            <span className="labelValue">
              {moment.unix(medication.stock.stock_last_till).format(DATE_FORMAT_MONTH_NAME)}
            </span>
          </div>
          <Button class={HEADER_BUTTON_BLUE} onClick={this.onEditStock} title={Strings.edit} ariaLabel="edit stock" />
        </div>
      </div>
    ) : (
      <div className="infoBox center">
        <h4 className="title">{Strings.patient_medications.stock}</h4>
        {btnRefill}
      </div>
    );
  }

  renderLoader() {
    const { isLoading } = this.props;
    if (!isLoading) return null;
    return (
      <div className="infoBox center spinner-container">
        <div className="inner-section">
          <div className="table-row-spinner">
            <div className="spinner-row" />
          </div>
        </div>
      </div>
    );
  }

  renderHardware() {
    const { isLoading, medication } = this.props;
    if (isLoading) return null;
    if (!medication) return null;
    if (!medication.cap_info) return null;

    return (
      <div className="infoBox center two-column">
        <img src={hardwareDeviceImg} className="hwImg" alt="hardware" />{' '}
        <div className="data">
          <div className="rowContent">
            <label>{Strings.patient_medications.hardwareId}</label>
            <span className="labelValue">{medication.cap_info.cap_id}</span>
          </div>

          <div className="rowContent">
            <label>{Strings.patient_medications.device}</label>
            <span className="labelValue">{medication.cap_info.qr_code ? medication.cap_info.qr_code : '-'}</span>
          </div>

          <div className="rowContent">
            <label>{Strings.patient_medications.battery}</label>
            <span className="labelValue">
              {medication.cap_info.battery_status ? getBatterStatus(medication.cap_info.battery_status) : '-'}
            </span>
          </div>

          <div className="rowContent">
            <label>{Strings.patient_medications.activationDate}</label>
            <span className="labelValue">
              {medication.cap_info.activation_date
                ? moment(medication.cap_info.activation_date).format(DATE_FORMAT_SPACES)
                : '-'}
            </span>
          </div>
          <div style={{ marginTop: 20 }}>
            <Button
              class={HEADER_BUTTON_BLUE}
              onClick={this.onUpdateCapClick}
              title={Strings.updateCap}
              ariaLabel="update device"
            />
          </div>
        </div>
      </div>
    );
  }

  renderMedicationInfo() {
    const { isLoading, medication } = this.props;
    const { url } = this.state;
    if (isLoading) return null;
    if (!medication) return null;

    return (
      <div className="infoBox two-column mt-0">
        <img src={infoGraphicsImg} style={{ width: 100 }} alt="info-graphic" />{' '}
        <div className="">
          <label className="info-label">
            {Strings.learnMoreAboutInfo}
            <span className="link">{Strings.medicationC}</span>
          </label>
          <Button
            class={HEADER_BUTTON_BLUE}
            onClick={this.onLearnMore}
            title={Strings.patient_medications.learnMore}
            disabled={!url}
          />
        </div>
      </div>
    );
  }

  renderSchedule() {
    const { isLoading, medication } = this.props;

    if (isLoading) return null;
    if (!medication) return null;

    return (
      <div className="infoBox center">
        <h4 className="title">{Strings.patient_medications.schedule}</h4>

        <div className="data">
          <div className="rowContent tooltipSchedule">
            <label style={{ paddingRight: '10px' }}>{Strings.patient_medications.name}</label>
            <span className="labelValue">{medication.schedule.name.substring(0, 85)}</span>
          </div>

          <div className="rowContent">
            <label>{Strings.patient_medications.frequency}</label>
            <span className="labelValue">{this.getType()}</span>
          </div>

          <div className="rowContent">
            <label>{Strings.patient_medications.timesPerDay}</label>
            <span className="labelValue">{this.getTimesPerDay()}</span>
          </div>

          <div className="rowContent">
            <label>{Strings.patient_medications.time}</label>
            <span className="timeLabelValue">{this.getTime()}</span>
          </div>

          <div className="rowContent">
            <label>{Strings.patient_medications.startDate}</label>
            <span className="labelValue">
              {medication.schedule.format.start_dt
                ? moment.unix(medication.schedule.format.start_dt).format(DATE_FORMAT_SPACES)
                : '-'}
            </span>
          </div>

          <div className="rowContent">
            <label>{Strings.patient_medications.endDate}</label>
            <span className="labelValue">
              {medication.schedule.format.end_dt
                ? this.getEndDate(medication.schedule.format.end_dt, medication.schedule?.schedule_timezone)
                : '-'}
            </span>
          </div>

          <div className="rowContent">
            <label>{Strings.patient_medications.lastTaken}</label>
            <span className="labelValue">
              {medication.schedule.last_taken
                ? `${dateToFromNowDaily(medication.schedule.last_taken)}, ${moment
                    .tz(moment.unix(medication.schedule.last_taken), medication.schedule.schedule_timezone)
                    .format(TIME_FORMAT_12_UPPERCASE)}`
                : '-'}
            </span>
          </div>

          <div className="rowContent">
            <label>{Strings.patient_medications.nextReminder}</label>
            <span className="labelValue">
              {medication.schedule.next_reminder
                ? `${dateToFromNowDaily(medication.schedule.next_reminder)}, ${moment
                    .tz(moment.unix(medication.schedule.next_reminder), medication.schedule.schedule_timezone)
                    .format(TIME_FORMAT_12_UPPERCASE)}`
                : '-'}
            </span>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Button
            class={HEADER_BUTTON_BLUE}
            onClick={this.onEditSchedule}
            title={Strings.patient_medications.edit}
            style={{ marginLeft: 0 }}
            first={!(medication && medication.schedule.version !== PORTAL_ESI)}
            ariaLabel="edit schedule"
          />

          {/* {!medication.cap_info && medication.schedule.version !== PORTAL_FDB && (
            <Button
              class={HEADER_BUTTON_BLUE}
              onClick={this.onUpdateCapClick}
              title={Strings.registerCap}
              style={{ marginRight: 0 }}
            />
          )} */}
        </div>
      </div>
    );
  }

  renderTopInfoBox() {
    const { medication } = this.props;

    const status = medication && medication.status === ACTIVE_STATUS;

    return (
      <div className="infoBox">
        <div className="total-bubble">{medication && medication.total_adherence}%</div>
        <div className="nickName">{Strings.totalAdherence}</div>
        <div className="vl" />
        {Strings.missed}: {medication && medication.missed}
        <div className="vl" />
        {Strings.taken}: {medication && medication.taken}
        {medication && medication.schedule.version !== PORTAL_ESI && (
          <div className="right-section">
            <div className="switch-text">
              {/* {status ? Strings.patient_medications.active : Strings.patient_medications.inActive} */}
              {this.state.checked ? Strings.patient_medications.active : Strings.patient_medications.inActive}
            </div>
            <Switch2 checked={status} onBeforeChange={this.onMedicationReminderChange} />
            {/* <Switch flag={status} name="switch-10" disabled={status} onChange={this.onChangeComponent} /> */}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { medication } = this.props;
    const loader = this.renderLoader();
    const stocks = this.renderStocks();
    const medicationInfo = this.renderMedicationInfo();
    const schedule = this.renderSchedule();
    const topInfoBox = this.renderTopInfoBox();
    const hardware = this.renderHardware();

    return (
      <div className="medication-details-view ">
        <PageHeader
          onBackClick={() => history.back()}
          title={medication ? medication.schedule.name.substring(0, 100) : ''}
        />
        {topInfoBox}
        {loader}
        <div className="infoContainer">
          {schedule}
          <div className="two-row">
            {hardware || stocks}
            {medicationInfo}
          </div>
        </div>

        <div className="infoContainer" style={{ width: '50%' }}>
          {hardware ? stocks : null}
        </div>
      </div>
    );
  }
}

MedicationDetailsView.propTypes = {
  editSchedule: PropTypes.func,
  editStock: PropTypes.func,
  getMedicineDocDetails: PropTypes.func,
  isLoading: PropTypes.any,
  loadMedications: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
      medicationId: PropTypes.any,
    }),
  }),
  medication: PropTypes.shape({
    cap_info: PropTypes.shape({
      activation_date: PropTypes.func,
      battery_status: PropTypes.any,
      cap_id: PropTypes.any,
      cap_type: PropTypes.any,
    }),
    missed: PropTypes.any,
    schedule: PropTypes.shape({
      format: PropTypes.shape({
        days: PropTypes.any,
        end_dt: PropTypes.func,
        offset: PropTypes.shape({
          length: PropTypes.number,
          map: PropTypes.func,
        }),
        start_dt: PropTypes.any,
        type: PropTypes.string,
      }),
      id: PropTypes.any,
      last_taken: PropTypes.any,
      name: PropTypes.shape({ substring: PropTypes.func }),
      next_reminder: PropTypes.any,
      schedule_timezone: PropTypes.any,
      strength: PropTypes.any,
      type: PropTypes.string,
      version: PropTypes.any,
    }),
    status: PropTypes.any,
    stock: PropTypes.shape({
      enabled: PropTypes.any,
      remind_before_days: PropTypes.any,
      reminder_at: PropTypes.any,
      reminder_time: PropTypes.any,
      stock: PropTypes.any,
      stock_last_till: PropTypes.any,
      stock_status: PropTypes.any,
    }),
    taken: PropTypes.any,
    total_adherence: PropTypes.any,
  }),
  onNavigate: PropTypes.func,
  schedules: PropTypes.any,
  updateCap: PropTypes.func,
  updateMedicationStatus: PropTypes.func,
  timezone: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicationDetailsView);
