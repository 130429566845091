import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button as MenuButton, Menu, MenuItem, Wrapper } from 'react-aria-menubutton';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { openModalAction } from '../../../actions/modal';
import AdvancedFiltersBar from '../../../components/AdvancedFilters/AdvancedFiltersBar';
import { Button } from '../../../components/PageHeader/Button';
import { TextInput } from '../../../components/PageHeader/TextInput';
import { DATE_FORMAT_MONTH_NAME_2, PAGE_LIMIT, TIME_FORMAT_12_UPPERCASE } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { downloadFileOnly } from '../../../utils';
import { actions as patientActions } from '../Patients/redux/actions';
import './Billings.scss';
import { actions } from './redux/actions';
class BillingDetails extends PureComponent {
  pageRequest;
  id;
  refreshEnable = true;

  constructor(props) {
    super(props);
    this.id = this.props.match.params.id;
    this.state = {
      isListOpen: false,
    };
  }

  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  onSearchQueryChange = query => {
    return this.onSearchQueryChangeDebounced(query);
  };

  onSearchQueryChangeDebounced = _.debounce(query => {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    this.props.getBilling(this.pageRequest);
  }, 1000);

  getSecondHeaderComponents() {
    return (
      <React.Fragment>
        <TextInput className="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
      </React.Fragment>
    );
  }
  componentDidMount() {
    this.pageRequest = {
      id: this.id,
    };
    this.onload();
  }

  onload() {
    this.props.getBilling(this.pageRequest);
    // this.props.getPatientInfo(this.patientId);
  }

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 10000);
    this.onload();
  };

  onPrevClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset - PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getBilling(this.pageRequest);
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.getBilling(this.pageRequest);
  };

  onNextClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset + PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getBilling(this.pageRequest);
  };

  onSortClick = ({ sortKey, direction }) => {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    this.props.getBilling(this.pageRequest);
  };

  getMedicalHistory = medicalHistory => {
    medicalHistory.map(history => {
      return (
        <div>
          <div>{history.primaryCondition}</div>
          <div>{history.secondaryCondition}</div>
        </div>
      );
    });
  };

  toggleList = () => {
    this.setState(prevState => ({
      isListOpen: !prevState.isListOpen,
    }));
  };

  blurHandler = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setState({ isListOpen: false });
    }
  };

  OnDownload = () => {
    this.props.downloadBillingTaskData(this.pageRequest).then(this.onDownloadBtnClickSuccessHandler);
  };

  onDownloadBtnClickSuccessHandler = req => {
    const fileName = req?.response && req?.response?.name ? req?.response?.name : moment().toString();
    downloadFileOnly(req, fileName);
  };

  onStatusUpdate = (event, task, status) => {
    event.preventDefault();
    event.stopPropagation();

    const newStatus = { taskStatus: status === 'approved' ? 'Approved' : 'OnHold' };

    const data = {
      title: (
        <span>
          {Strings.updateStatus} <b>{status}</b>?
        </span>
      ),

      onConfirmAction: actions.updateTaskStatus(newStatus, task.userId, task.taskId, [
        actions.getCareDxBillingDetails(this.pageRequest),
      ]),
      onCancelAction: null,
    };
    this.props.openConfirmModal(data);
  };

  onBackButton = () => {
    this.props.onNavigate(`/billings`);
  };

  getName = patientInfo => {
    return patientInfo?.firstName ? patientInfo?.firstName + ' ' + patientInfo?.lastName : '-';
  };

  handleSelection = (value, event) => {
    switch (value) {
      case 'download':
        this.OnDownload();
        break;
      default:
        break;
    }
  };

  render() {
    const { tasks, pagination, isLoading, patientDetails, patientInfo } = this.props;
    const { isListOpen } = this.state;

    const columns = [];

    columns.push(
      <Column key="taskName" title={Strings.capPatient.taskName} value={d => (d.taskName ? d.taskName : '-')} />,
    );

    columns.push(<Column key="notes" title={Strings.capPatient.taskNotes} value={d => (d.taskName ? d.notes : '-')} />);

    columns.push(
      <Column
        key="deviceName"
        title={Strings.capPatient.deviceName}
        value={d => (d.deviceName ? d.deviceName : '-')}
      />,
    );

    if (pagination?.cptCode && pagination?.cptCode !== 99454) {
      columns.push(
        <Column
          key="taskTime"
          title={Strings.capPatient.taskTime}
          value={d => (d.timeSpent ? moment.utc(d.timeSpent * 1000).format('HH:mm:ss') : '-')}
        />,
      );
    }

    columns.push(
      <Column
        key="startTime"
        title={Strings.capPatient.startTime}
        value={d => (d.startTime ? moment(d.startTime * 1000).format(TIME_FORMAT_12_UPPERCASE) : '-')}
      />,
    );

    columns.push(
      <Column
        key="endTime"
        title={Strings.capPatient.endTime}
        value={d => (d.endTime ? moment(d.endTime * 1000).format(TIME_FORMAT_12_UPPERCASE) : '-')}
      />,
    );

    columns.push(
      <Column
        key="date"
        title={Strings.capPatient.date}
        value={d => (d.endTime ? moment(d.endTime * 1000).format(DATE_FORMAT_MONTH_NAME_2) : '-')}
      />,
    );

    columns.push(<Column key="reading" title={Strings.reading} value={d => (d.reading ? d.reading : '-')} />);

    const actionsButton = (
      <Wrapper
        className="addMenu"
        onSelection={this.handleSelection}
        onMenuToggle={({ isListOpen }) => {
          this.setState({ isListOpen });
        }}
      >
        <MenuButton className={`addMenu-button ${isListOpen ? 'expanded' : ''}`}>{Strings.actions}</MenuButton>
        <Menu className="addMenu-menu">
          <MenuItem className="addMenu-menuItem" value="download" id="download">
            {Strings.downloadData}
          </MenuItem>
        </Menu>
      </Wrapper>
    );

    return (
      <div className="billing-container">
        <div>
          <div className="infoHeader">
            <div className="rowDirection">
              <button className="back" onClick={() => this.onBackButton()} tabIndex={0}>
                {Strings.back}
              </button>
              <div className="feildBox">
                <div className="content-flexbox header">
                  <span className="key">{Strings.capPatient.mrn}: </span>{' '}
                  <span className="value">{patientInfo?.externalID}</span>
                </div>

                <div className="content-flexbox header">
                  <span className="key">{Strings.name}: </span>{' '}
                  <span className="value"> {this.getName(patientInfo)}</span>
                </div>

                <div className="content-flexbox header">
                  <span className="key">{Strings.capPatient.cptCode}: </span>{' '}
                  <span className="value">{pagination?.cptCode ? pagination?.cptCode : '-'}</span>
                </div>
              </div>
              <div className="right">
                <Button class="refreshTop mr20" disabled={!this.refreshEnable} onClick={this.onRefresh} />
                {actionsButton}
              </div>
            </div>
          </div>
        </div>

        <div className="details-container">
          <AdvancedFiltersBar />
          {pagination?.cptCode && (
            <Table
              isLoading={isLoading}
              name="billing-details-table"
              data={tasks || []}
              onRowSelection={this.onOrganizationSelected}
              onPrevClick={this.onPrevClick}
              onSortClick={this.onSortClick}
              onNextClick={this.onNextClick}
              onCustomPage={this.onCustomPage}
              pagination={
                pagination || {
                  offset: 0,
                  total: 0,
                }
              }
            >
              {columns}
            </Table>
          )}
        </div>
      </div>
    );
  }
}

BillingDetails.propTypes = {
  getBilling: PropTypes.func,
  getPatientInfo: PropTypes.func,
  isLoading: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
      type: PropTypes.any,
    }),
  }),
  onNavigate: PropTypes.func,
  openConfirmModal: PropTypes.func,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
  patientDetails: PropTypes.shape({
    dateOfBirth: PropTypes.any,
    mrn: PropTypes.any,
    patientName: PropTypes.any,
  }),
  tasks: PropTypes.array,
};

const mapStateToProps = state => {
  const { careBilling, isLoading, patients } = state.superUser;
  return {
    role: state.auth.role,
    tasks: careBilling?.taskDetails,
    pagination: careBilling?.pagination,
    patientInfo: careBilling?.patientInfo,
    isLoading,
    patientDetails: patients?.patientDetails,
    userId: state.auth?.profile?.id,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getBilling: pageRequest => dispatch(actions.getCareDxBillingDetails(pageRequest)),
  getDeviceFilter: () => dispatch(actions.getDeviceFilter()),
  getPatientInfo: patientId => dispatch(patientActions.getPatientInfo(patientId)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  downloadBillingTaskData: pageRequest => dispatch(actions.downloadBillingDetailsData(pageRequest)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetails);
