/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from '../../../utils';
import Strings from '../../../Strings';

/**
 * defines a table column
 * @param rowId - filled by <Table>
 * @param className - an optional className
 * @param children - not used
 * @param data - row data
 * @param value - a string or a react component to render as value
 * @param sortBy - sort key for the column ex. 'project.meta.code'
 * @param onClick - onClick fired when the table data cell of that column is selected
 */

export const Column = ({ rowId, className = '', data, value, onClick }) => {
  const cls = ['column', typeof className === 'function' ? className(data) : className].join(' ');
  if (className === 'study-name') {
    return (
      <td>
        <div className={cls} onClick={onClick}>
          {value(data, rowId)}
        </div>
      </td>
    );
  }

  // remove from words clickable and fix
  const clsFiltered = cls
    .split(' ')
    .filter(item => item !== 'clickable' && item !== 'fix' && item !== 'column')
    .join(' ');

  return (
    <td className={cls} onClick={onClick} data-testid={`column[${clsFiltered}][${rowId}]`}>
      {value(data, rowId)}
    </td>
  );
};

Column.propTypes = {
  className: PropTypes.string,
  data: PropTypes.any,
  onClick: PropTypes.func,
  rowId: PropTypes.string,
  value: PropTypes.func,
};

/**
 * renders standard header
 */

export const HeaderCell = ({ label, className, order, onClick, sortKey }) => (
  <th onClick={sortKey && onClick} className={['column', className, order, sortKey ? 'sortable' : ''].join(' ')}>
    <span>
      <a>{label}</a>
    </span>
    {sortKey && <span className={`sort-icon ${order}`} />}
  </th>
);

HeaderCell.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  order: PropTypes.any,
  onClick: PropTypes.func,
  sortKey: PropTypes.string,
};

export const HeaderRow = ({ order, columns, onClick }) => {
  return (
    <tr className="table-row">
      {columns &&
        columns.map(c => (
          <HeaderCell
            key={c.key}
            className={c.key}
            sortKey={c.sortKey}
            order={c.key === order.key ? order.direction : undefined}
            label={c.title}
            onClick={() => onClick(c.key)}
          />
        ))}
    </tr>
  );
};

HeaderRow.propTypes = {
  order: PropTypes.any,
  columns: PropTypes.array,
  onClick: PropTypes.func,
};

/**
 * creates a table row component basing on child column definition components
 * @param columns <Table>'s <Column ...> elements
 */

export const DataRowTemplate = columns => ({
  rowId,
  data,
  selected,
  onClick,
  onDoubleClick,
  toolTip,
  rowClass,
  isLoading,
  uiClass,
  tabIndex,
  buttons,
  excludedColumns,
}) => {
  try {
    const trProps = {};
    if (toolTip && toolTip.idField in data) {
      trProps['data-tip'] = true;
      trProps['data-tooltip-id'] = toolTip.dataFor(data[toolTip.idField]);
    }

    if (tabIndex) {
      trProps.tabIndex = 0;
    }
    const classes = {
      'table-row': true,
      selected,
      unread: !data.read,
    };

    if (rowClass) {
      classes[rowClass(data)] = true;
    }

    return (
      <tr
        className={`${classNames(classes)} ${uiClass} ${buttons && buttons.length > 0 ? 'with-buttons' : ''}`}
        onClick={() => (onClick != undefined ? onClick(rowId) : undefined)}
        onDoubleClick={() => (onDoubleClick != undefined ? onDoubleClick(rowId) : undefined)}
        onKeyDown={key => {
          if (key.which === 13) {
            onClick(rowId);
          }
        }}
        {...trProps}
      >
        {columns &&
          columns
            .filter(e => !excludedColumns.includes(e.key))
            .map((c, index) => {
              let className;
              if (c.props.className) {
                if (typeof c.props.className === 'function') {
                  className = d => [c.key, c.props.className(d)].join(' ');
                } else {
                  className = [c.key, c.props.className].join(' ');
                }
              } else {
                className = c.key;
              }

              const ret = React.cloneElement(c, {
                rowId,
                data,
                className,
                key: index,
              });

              return ret;
            })}
        {buttons && buttons.length > 0 && (
          <td className="sticky-right">
            <div className="buttons-wrapper">
              <div className="buttons-background-gradient" />
              <div className="buttons-background">
                <div className="buttons">
                  {buttons.map(b => (
                    <div className="icon-container">
                      <div
                        data-tooltip-content={typeof b.text === 'function' ? b.text(rowId, data) : b.text}
                        data-tooltip-id="tooltip"
                        className={`icon ${typeof b.icon === 'function' ? b.icon(rowId, data) : b.icon} ${
                          (typeof b.disabled === 'function' && b.disabled(rowId, data)) ||
                          (typeof b.disabled == 'boolean' && b.disabled)
                            ? 'disabled'
                            : ''
                        }`}
                        onClick={e => {
                          e.stopPropagation();
                          b.onClick(rowId, data);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </td>
        )}
        {isLoading ? (
          <td>
            <div className="table-spinner-container">
              <div className="spinner" />
            </div>
          </td>
        ) : null}
      </tr>
    );
  } catch (TypeError) {
    let className;
    if (columns.props.className) {
      if (typeof columns.props.className === 'function') {
        className = d => [columns.key, columns.props.className(d)].join(' ');
      } else {
        className = [columns.key, columns.props.className].join(' ');
      }
    } else {
      className = columns.key;
    }
    return (
      <tr
        className={classNames({
          'table-row': true,
          selected,
        })}
        onClick={() => onClick(rowId)}
      >
        {columns &&
          React.cloneElement(columns, {
            rowId,
            data,
            className,
          })}
        {buttons && buttons.length > 0 && (
          <div className="buttons">
            {buttons.map(b => (
              <div className="icon-container">
                <div
                  data-tooltip-content={typeof b.text === 'function' ? b.text(rowId, data) : b.text}
                  data-tooltip-id="tooltip"
                  className={`icon ${b.icon} ${
                    (typeof b.disabled === 'function' && b.disabled(rowId, data)) ||
                    (typeof b.disabled == 'boolean' && b.disabled)
                      ? 'disabled'
                      : ''
                  }`}
                  onClick={e => {
                    e.stopPropagation();
                    b.onClick(rowId, data);
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </tr>
    );
  }
};

DataRowTemplate.propTypes = {
  rowId: PropTypes.string,
  data: PropTypes.any,
  selected: PropTypes.any,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  toolTip: PropTypes.any,
  rowClass: PropTypes.string,
  isLoding: PropTypes.bool,
};

export const EmptyRow = ({ colspan, children }) => {
  return (
    <tbody>
      <tr className="empty-row">
        <td colSpan={colspan}>
          <div className="empty-row-container">
            <div className="empty-row-inner" data-testid="no-data-available">
              {children}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

EmptyRow.propTypes = {
  children: PropTypes.any,
  colspan: PropTypes.number.isRequired,
};

export const NoDataRow = ({ colspan }) => {
  return <EmptyRow colspan={colspan}>{Strings.noDataAvailable}</EmptyRow>;
};

NoDataRow.propTypes = { colspan: PropTypes.number.isRequired };

export const SpinnerRow = ({ colspan }) => {
  return (
    <EmptyRow colspan={colspan}>
      <div className="spinner" />
    </EmptyRow>
  );
};

SpinnerRow.propTypes = { colspan: PropTypes.number.isRequired };

export const DataLoadingRow = ({ columns }) => (
  <tr className="table-row">
    {columns &&
      columns.map((c, i) => (
        <td key={`loading-td-${c.name}${i}`}>
          <div className="data-loading" />
        </td>
      ))}
  </tr>
);

DataLoadingRow.propTypes = { columns: PropTypes.array.isRequired };
