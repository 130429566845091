import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import { connect } from 'react-redux';

import { changeFilter } from '../../actions';
import { DATE_FORMAT, DATE_FORMAT_FOR_SLIDER } from '../../constants';
import Strings from '../../Strings';
import { ManualDatePicker } from '../ManualDatePicker';
import Slider from '../Slider/index';

function SliderPicker(props) {
  React.useEffect(() => {
    if (!props.timezone) {
      return;
    }
    if (!props.dateFilter.infimum || !props.dateFilter.supremum) {
      props.onChangeDateFilter({
        supremum: moment()
          .tz(props.timezone)
          .endOf('day')
          .toDate(),
        infimum: moment()
          .tz(props.timezone)
          .subtract(6, 'day')
          .startOf('day')
          .toDate(),
      });
    }
  }, [props.dateFilter.infimum, props.dateFilter.supremum, props.timezone]);

  const handleSliderChange = d => {
    props.onChange(d);
  };

  const handleSliderChangeEnd = d => {
    handleSliderChange(d);
    props.onChangeEnd(d);
  };

  const handleInfimumChange = m => {
    const infimum = m.startOf('day').toDate();
    const infimumMoment = moment(infimum);
    const supremumMoment = moment(props.dateFilter.supremum);
    const differanceInDays = supremumMoment.diff(infimumMoment, 'days');

    if (differanceInDays <= 0) {
      const supremum = m.endOf('day').toDate();
      props.onChangeDateFilter({
        infimum,
        supremum,
      });
      props.onChangeEnd({
        infimum,
        supremum,
      });
    } else {
      props.onChangeDateFilter({ infimum });
      props.onChangeEnd({
        infimum,
        supremum: props.dateFilter.supremum,
      });
    }
  };

  const handleSupremumChange = m => {
    const supremum = m.endOf('day').toDate();
    props.onChangeDateFilter({ supremum });
    props.onChangeEnd({
      infimum: props.dateFilter.infimum,
      supremum,
    });
  };

  const { min, max, dateFilter, minRangeDays, manualDatePicker } = props;

  const propsDatePickerInfimum = {
    minDate: min,
    maxDate: max,
    selected: moment(dateFilter.infimum, DATE_FORMAT),
    dateFormat: DATE_FORMAT_FOR_SLIDER,
    onChange: handleInfimumChange,
  };

  const propDatePickerSupermum = {
    minDate: moment(dateFilter.infimum, DATE_FORMAT),
    maxDate: max,
    selected: moment(dateFilter.supremum, DATE_FORMAT),
    dateFormat: DATE_FORMAT_FOR_SLIDER,
    onChange: handleSupremumChange,
  };

  return (
    <div className="slider-picker-wrapper">
      <div className="slider-wrapper">
        <Slider
          min={min}
          max={max}
          minRangeDays={minRangeDays}
          onChange={handleSliderChange}
          onChangeEnd={handleSliderChangeEnd}
          readOnly={props.readOnly}
        />
      </div>
      <div className={`datepickers-wrapper ${manualDatePicker ? 'manualDatePicker' : ''}`}>
        <div className="datepicker-wrapper start-date-picker">
          <span className="outside-label">{Strings.startDate}</span>
          {manualDatePicker ? (
            <ManualDatePicker {...propsDatePickerInfimum} />
          ) : (
            <DatePicker
              id="datepicker"
              role="dialog"
              aria-modal="true"
              ariaLabel="Choose start Date"
              disabled={props.readOnly}
              {...propsDatePickerInfimum}
            />
          )}
        </div>
        <div className="datepicker-wrapper end-date-picker">
          <span className="outside-label">{Strings.endDate}</span>
          {manualDatePicker ? (
            <ManualDatePicker {...propDatePickerSupermum} />
          ) : (
            <DatePicker
              id="end-datepicker"
              ariaLabel="Choose end Date"
              aria-modal="true"
              disabled={props.readOnly}
              {...propDatePickerSupermum}
            />
          )}
        </div>
      </div>
    </div>
  );
}

SliderPicker.propTypes = {
  max: PropTypes.instanceOf(Date).isRequired,
  min: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func,
  onChangeEnd: PropTypes.func,
  minRangeDays: PropTypes.number,
  dateFilter: PropTypes.object,
  onChangeDateFilter: PropTypes.func,
  manualDatePicker: PropTypes.bool,
  readOnly: PropTypes.bool,
};

SliderPicker.defaultProps = {
  onChange: () => {},
  onChangeEnd: () => {},
  manualDatePicker: false,
};

const mapStateToProps = state => ({
  dateFilter: state.filter.dateFilter,
  timezone: state.auth?.profile?.preferences?.timezone,
});

const mapDispatchToProps = dispatch => ({
  onChangeDateFilter: filterData => dispatch(changeFilter('dateFilter', filterData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SliderPicker);
