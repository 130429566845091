/* eslint-disable func-names */
/**
 * Created on 01/12/18.
 */
import { select } from 'd3-selection';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleBand, scaleLinear } from 'd3-scale';
import { stack as d3stack, stackOffsetExpand } from 'd3-shape';
import lodash from 'lodash';

const dayNames = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S'];
const tooltipLength = 150;

class HistogramPlot {
  keys = ['complied', 'skipped', 'missed', 'pending'];

  width = 260;
  height = 235;
  margin = {
    top: 7.5,
    bottom: 24.5,
    left: 37,
    right: -5,
  };

  create = (node, props) => {
    this.props = props;
    const container = select(node);

    this.svg = container.append('svg').attr('class', 'plot adherence-7days');

    this.gMain = this.svg
      .append('g')
      .attr('class', 'main')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    this.installHandlers();

    this.gMain.append('g').attr('class', 'axis bottom no-domain');

    this.gMain.append('g').attr('class', 'axis left no-domain');

    // Amount of groups
    lodash.range(this.keys.length + 1).forEach(() => {
      const series = this.gMain.append('g').attr('class', 'empty series');
      // Amount of days
      for (let i = 0; i < props.data.length; i += 1) {
        series.append('rect');
      }
    });

    this.renderData();
  };

  renderData = () => {
    const { data: dataSet } = this.props;
    this.updateViewBox();

    const x = scaleBand()
      .rangeRound([0, this.width - this.margin.left - this.margin.right])
      .domain(dataSet.map(d => d.date))
      .padding(0.35)
      .align(0.5);

    const y = scaleLinear().rangeRound([this.height - this.margin.top - this.margin.bottom, 0]);

    const stack = d3stack()
      .keys(this.keys)
      .value((d, key) => d[key.toLowerCase()]) // for Pending key
      .offset(stackOffsetExpand);

    this.gMain
      .select('g.axis.bottom')
      .attr('transform', `translate(0, ${y(0) + 8})`)
      .call(
        axisBottom(x)
          .tickSize(0)
          .tickFormat(t => dayNames[new Date(+t).getDay()]),
      )
      .selectAll('text')
      .attr('class', t => dayNames[new Date(+t).getDay()]);

    this.gMain.select('g.axis.left').call(
      axisLeft(y)
        .tickFormat(t => `${+t * 100}%`)
        .ticks(4),
    );

    const self = this;
    this.gMain
      .selectAll('.series')
      .data(stack(dataSet))
      .each(function(d) {
        // iterate through all fields (missed, skipped, etc.)
        const gSeries = select(this);
        gSeries
          .attr('class', `${d.key} series`)
          .selectAll('rect')
          .data(seriesData => seriesData)
          .attr('x', dayData => x(dayData.data.date))
          .attr('y', dayData => y(dayData[1]))
          .attr('height', dayData => {
            if (dayData[0] === 0) {
              return y(dayData[0]) - y(dayData[1]);
            }
            return Math.max(y(dayData[0]) - y(dayData[1]) - 6, 0);
          })
          .attr('width', x.bandwidth())
          .on('mouseover', function(dayData) {
            const rectEl = select(this);
            const availableSpaceRight = x(dayData.data.date) + x.bandwidth() + tooltipLength;
            const fitsRight = availableSpaceRight < self.width - self.margin.left - self.margin.right;
            rectEl.classed('active', true);
            self.gMain.classed('go-grey', true);
            self.gMain
              .append('text')
              .attr('class', 'plot-tooltip')
              .attr('x', fitsRight ? x(dayData.data.date) + x.bandwidth() + 10 : x(dayData.data.date) - 10)
              .attr('y', y((dayData[0] + dayData[1]) / 2))
              .attr('text-anchor', fitsRight ? 'start' : 'end')
              .text(d.key === 'empty' ? 'empty' : `${d.key}: ${dayData.data[d.key]}/${dayData.data.total}`);
          })
          .on('mouseout', function() {
            const rectEl = select(this);
            rectEl.classed('active', false);
            self.gMain.classed('go-grey', false);
            self.gMain.selectAll('text.plot-tooltip').remove();
          });
      });
  };

  update = (node, props) => {
    this.props = props;

    const container = select(node);
    this.svg = container.select('svg');
    this.gMain = this.svg.select('g.main');
    this.renderData();
  };

  updateViewBox = () => {
    const brc = this.svg.node().getBoundingClientRect();

    // this.width = Math.max(brc.width, this.minWidth);
    this.height = brc.height;
    this.viewBox = {
      x: this.margin.left,
      y: this.margin.top,
      // width: this.width - this.margin.left - this.margin.right,
      // height: this.height - this.margin.top - this.margin.bottom,
    };

    this.svg.attr('viewBox', `0 0 ${this.width} ${this.height}`);
    // this.gMain.attr('transform', `translate(${this.viewBox.x},${this.viewBox.y})`);
  };

  resizeHandler = () => {
    this.updateViewBox();
    this.renderData();
  };

  installHandlers = () => {
    window.addEventListener('resize', this.resizeHandler);
  };

  destroy = () => {
    window.removeEventListener('resize', this.resizeHandler);
  };
}

export default HistogramPlot;
