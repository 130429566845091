import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { now } from 'moment-timezone';

import Strings from '../../../../Strings';
import TableWidget from '../component/TableWidget';
import { openModalAction } from '../../../../actions/modal';
import { GET_CAREGIVERS_LIST_RESULT, caregiverActions } from '../redux/caregiverActions';
import { toolTipForTable } from '../../../../utils';
import { PERMISSIONS, hasPermission } from '../../../../utils/userPermissions';

function Caregivers(props) {
  const [caregivers, setCaregivers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshTimestamp, setRefreshTimestamp] = useState(now());
  const canEdit = hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_INFO_EDIT);

  useEffect(() => {
    if (!props.patientId) {
      return;
    }

    setIsLoading(true);
    props.getCaregivers().then(resp => {
      if (resp.type === GET_CAREGIVERS_LIST_RESULT) {
        setCaregivers(resp.response);
      }
      setIsLoading(false);
    });
  }, [props.patientId, refreshTimestamp, props.refreshTimestamp]);

  const deleteCaregiver = (id, name) => {
    const data = {
      caption: Strings.confirmDelete,
      title: <span>{Strings.formatString(Strings.deleteWarning, `${Strings.caregiver} ${name}`)}</span>,
      hideCaution: true,
      onConfirmAction: caregiverActions.deleteCaregiver(props.patientId, id),
      onCancelAction: null,
      confirmPostAction: () => setRefreshTimestamp(now()),
    };
    props.openConfirmModal(data);
  };

  const renderCaregiverName = cg => {
    const name = `${cg.first_name} ${cg.last_name}`;
    return (
      <div className="row-with-buttons">
        <div className="icon-and-text-container">
          {toolTipForTable(
            <div
              className={`icon-container ${
                cg.status === 'unsubscribed'
                  ? 'hold'
                  : cg.status === 'invited' || cg.status === 'created'
                  ? 'pending'
                  : 'tick'
              }`}
            />,
            `${Strings.status}: ${cg.status}`,
            'top',
          )}
          <div className="icon-and-text-content">{name}</div>
        </div>
        {canEdit && (
          <div className="row-buttons">
            <div key={`button_delete`} className="buttons-icon-container">
              <div className={`icon delete`} onClick={() => deleteCaregiver(cg.id, name)} />
            </div>
          </div>
        )}
      </div>
    );
  };

  const data = [];
  if (caregivers && Array.isArray(caregivers) && caregivers.length > 0) {
    caregivers.forEach(cg => {
      data.push([cg.phone_number, renderCaregiverName(cg)]);
    });
  }
  const editCaregivers = () => {
    props.onEditCaregivers(() => setRefreshTimestamp(now()));
  };

  return (
    <TableWidget
      title={Strings.caregivers}
      buttons={canEdit ? [{ icon: 'plus', onClick: editCaregivers }] : undefined}
      data={data?.length ? data : [[' ', ' ']]}
      isLoading={isLoading}
      firstColumnClassName="center-details-col"
    />
  );
}

Caregivers.propTypes = {
  patientId: PropTypes.any,
  getCaregivers: PropTypes.func,
  onEditCaregivers: PropTypes.func,
};

const mapDispatchToProps = (dispatch, props) => ({
  onEditCaregivers: onSuccess =>
    dispatch(
      openModalAction('edit-caregivers', {
        patientId: props.patientId,
        onSuccess,
      }),
    ),
  getCaregivers: () => dispatch(caregiverActions.getCaregivers(props.patientId)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
});

Caregivers.widgetId = 'CaregiversWidget';

export default connect(null, mapDispatchToProps)(Caregivers);
