import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { TIME_FORMAT_12_UPPERCASE, DATE_MONTH_DAY_YEAR, PAGE_LIMIT } from '../../../../../constants';
import Table, { Column } from '../../../../../containers/Table/TableWithPagination';
import { actions, PLOT_DATA_RESULT } from '../Graph/actions';
import Strings from '../../../../../Strings';
import { getCapChargeText } from '../../../../../utils';

class BatteryConnectionTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      keyForTable: true,
    };

    this.dataToShow = [];
    this.pagination = {};

    this.pageRequest = {
      offset: 0,
      filterBy: this.props.eventType,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      deviceId: this.props.deviceId,
      hubId: this.props.hubId,
      kitId: this.props.kitId,
      patientId: this.props.patientId,
      dataType: this.props.eventType,
      limit: PAGE_LIMIT,
    };
  }

  componentDidMount() {
    this.getEvents();
  }

  componentDidUpdate(prevProps) {
    const { deviceId, startDate, endDate, hubId, kitId, patientId, eventType } = this.props;

    if (
      prevProps.deviceId !== deviceId ||
      prevProps.startDate !== startDate ||
      prevProps.endDate !== endDate ||
      prevProps.hubId !== hubId ||
      prevProps.kitId !== kitId ||
      prevProps.patientId !== patientId ||
      prevProps.eventType !== eventType
    ) {
      this.pageRequest.startDate = startDate;
      this.pageRequest.endDate = endDate;
      this.pageRequest.filterBy = eventType;
      this.pageRequest.deviceId = deviceId;
      this.pageRequest.hubId = hubId;
      this.pageRequest.kitId = kitId;
      this.pageRequest.patientId = patientId;
      this.pageRequest.dataType = eventType;
      this.getEvents();
    }

    // eslint-disable-next-line react/no-did-update-set-state
    if (prevProps.eventType !== eventType) this.setState({ keyForTable: !this.state.keyForTable });
  }

  onPrevClick = () => {
    this.pageRequest.offset = this.pagination.offset - PAGE_LIMIT;
    this.getEvents();
  };

  onNextClick = () => {
    this.pageRequest.offset = this.pagination.offset + PAGE_LIMIT;
    this.getEvents();
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.getEvents();
  };

  getEvents = () => {
    this.dataToShow = [];
    this.setState({ isLoading: true });
    this.props.getEvents(this.pageRequest).then(resp => {
      if (resp.type === PLOT_DATA_RESULT) {
        this.dataToShow = resp.response.data;
        this.pagination = resp.response.pagination;
      }
      this.setState({ isLoading: false });
    });
  };

  getTable = () => {
    const { isLoading, keyForTable } = this.state;

    const columns = [
      <Column
        key="event_timestamp"
        title={Strings.date}
        value={e => {
          return e.event_timestamp ? moment(e.event_timestamp).format(DATE_MONTH_DAY_YEAR) : '-';
        }}
      />,
      <Column
        key="timestampTime"
        title={Strings.time}
        value={e => {
          return e.event_timestamp ? moment(e.event_timestamp).format(TIME_FORMAT_12_UPPERCASE) : '-';
        }}
      />,
    ];

    if (this.props.eventType === 'battery')
      columns.push(
        <Column key="charge" title={Strings.battery.battery} value={d => getCapChargeText(d.battery_percentage)} />,
      );

    return (
      <Table
        name="battery-connection-table"
        pagination={this.pagination}
        data={this.dataToShow}
        isLoading={isLoading}
        key={keyForTable}
        onCustomPage={this.onCustomPage}
        onNextClick={this.onNextClick}
        onPrevClick={this.onPrevClick}
      >
        {columns}
      </Table>
    );
  };
  render() {
    return <React.Fragment>{this.getTable()}</React.Fragment>;
  }
}

BatteryConnectionTable.propTypes = {
  eventType: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired,
  hubId: PropTypes.string,
  kitId: PropTypes.string,
  patientId: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  getEvents: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getEvents: pageRequest => dispatch(actions.getPlotData(pageRequest)),
});

export default connect(null, mapDispatchToProps)(BatteryConnectionTable);
