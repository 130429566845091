import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Input, PhoneInputField } from '../containers/Form';
import { closeModal } from '../actions/modal';
import Strings from '../Strings';
import './editStudyModal.scss';
import Wizard from '../containers/Modal/Wizard';
import { validateFirstName, validateLastName, validatePhoneNo } from '../utils/validators/rpmPatient';
import { notificationActions } from '../components/Notification/redux/actions';
import { ADD_CAREGIVER_ERROR, caregiverActions } from '../pages/SuperUser/Patients/redux/caregiverActions';

const EditCaregiversModal = props => {
  const [caregiver, setCaregiver] = useState({});
  const [validationErrors, setValidationErrors] = useState([]);
  const [highlightInvalidFields, setHighlightMissingFields] = useState(false);
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);

  useEffect(() => {
    let errors = validateFirstName(caregiver.first_name)
      .concat(validateLastName(caregiver.last_name))
      .concat(validatePhoneNo(caregiver.phone_number, 'phone_number', true));
    setValidationErrors(errors);
  }, [caregiver]);

  const editItem = event => {
    const field = event.target.name;
    const value = event.target.value;
    setCaregiver(c => {
      return {
        ...c,
        [field]: value,
      };
    });
  };

  async function onSubmit() {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);
    const resp = await props.onAddCaregiver(caregiver);
    if (resp.type === ADD_CAREGIVER_ERROR) {
      props.showNotification(resp.response?.data?.error?.message, 5000, true);
    } else {
      props.showNotification(Strings.formatString(Strings.itemSaved, Strings.caregiver));
      if (props.data.onSuccess) {
        props.data.onSuccess();
      }
      props.closeModal();
    }
    setSaveBtnClicked(false);
  }

  const pageContent = (
    <React.Fragment>
      <div className="horizontal-flex">
        <div className="vertical-flex">
          <Input
            label={Strings.firstName}
            name="first_name"
            id="first_name"
            type="text"
            value={caregiver.first_name}
            onChange={editItem}
            disabled={caregiver.id}
            isRequired
            highlightInvalid={highlightInvalidFields}
            errorsForTooltip={validationErrors.find(v => v.property === 'firstName')?.errors || []}
          />
          <Input
            label={Strings.lastName}
            name="last_name"
            id="last_name"
            type="text"
            value={caregiver.last_name}
            onChange={editItem}
            disabled={caregiver.id}
            isRequired
            highlightInvalid={highlightInvalidFields}
            errorsForTooltip={validationErrors.find(v => v.property === 'lastName')?.errors || []}
          />
          <PhoneInputField
            align="left"
            name="phone_number"
            id="phone_number"
            value={caregiver.phone_number}
            onChange={e => editItem({ ...e, target: { ...e.target, name: 'phone_number' } })}
            label={Strings.mobilePhone}
            disabled={caregiver.id}
            isRequired
            highlightInvalid={highlightInvalidFields}
            errorsForTooltip={validationErrors.find(v => v.property === 'phone_number')?.errors || []}
          />
        </div>
      </div>
    </React.Fragment>
  );

  const pages = [
    {
      id: 'caregiver-data',
      title: Strings.addCaregiver,
      content: pageContent,
      emptyFieldsCount: validationErrors.filter(v => v.missing).length,
      canGoNext: validationErrors.length === 0 && !saveBtnClicked,
    },
  ];

  return (
    <Wizard
      name="edit-caregivers"
      pages={pages}
      onNextButtonHover={e => setHighlightMissingFields(e)}
      onSubmit={onSubmit}
      showPagesFilter={false}
    />
  );
};

EditCaregiversModal.propTypes = {
  data: PropTypes.shape({
    patientId: PropTypes.any,
    onSuccess: PropTypes.func,
  }),
  closeModal: PropTypes.func,
  onAddCaregiver: PropTypes.func,
  onDeleteCaregiver: PropTypes.func,
  showNotification: PropTypes.func,
  getCaregivers: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => dispatch(closeModal('edit-caregivers')),
    onAddCaregiver: data => dispatch(caregiverActions.addCaregiver(ownProps.data.patientId, data)),
    onDeleteCaregiver: id => dispatch(caregiverActions.deleteCaregiver(ownProps.data.patientId, id)),
    showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
    getCaregivers: () => dispatch(caregiverActions.getCaregivers(ownProps.data.patientId)),
  };
};

export default connect(null, mapDispatchToProps)(EditCaregiversModal);
