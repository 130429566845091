import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import './style.scss';
import Strings from '../../Strings';

class MobileInput extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    phoneError: PropTypes.string,
    isHideError: PropTypes.any,
    isContainerClass: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.string,
    placeHolder: PropTypes.string,
    country: PropTypes.any,
    value: PropTypes.any,
  };

  constructor() {
    super();
    this.state = { error: '' };
    this.onChangeHandler.bind(this);
  }

  onChangeHandler = e => {
    if (!isValidPhoneNumber(e)) {
      if (!e) {
        this.setState({ error: Strings.errors.emptyPhoneNumber });
      } else {
        this.setState({ error: Strings.errors.invalidPhoneNumber });
      }
    } else {
      this.setState({ error: '' });
    }
    this.props.onChange(e);
  };

  render() {
    const { phoneError, isHideError } = this.props;
    return (
      <div className={`${this.props.isContainerClass ? 'input-container' : ''}`}>
        <PhoneInput
          name={this.props.name}
          key={this.props.id}
          placeholder={this.props.placeHolder}
          country={this.props.country}
          value={this.props.value}
          onChange={this.onChangeHandler}
        />
        {isHideError ? null : (
          <div key="error" className="phone-error">
            {this.state.error || phoneError}
          </div>
        )}
      </div>
    );
  }
}

export default MobileInput;
