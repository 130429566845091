import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ReactDOMServer from 'react-dom/server';

import { getOrgnization, getRole } from '../../utils/userRoles';

import './SideMenu.module.scss';

class DiagnosticInfo extends PureComponent {
  static propTypes = {
    profile: PropTypes.object,
    visible: PropTypes.bool,
  };

  render() {
    const { profile, visible } = this.props;

    let userName = '';
    if (profile) {
      userName = profile.first_name ? `${profile.first_name} ${profile.last_name}` : profile.username;
    }

    return (
      <>
        <div
          className={`left-bar-diagnostic-info ${visible ? '' : 'collapsed'}`}
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            <div>
              {' '}
              {getRole()}
              <br /> {getOrgnization()}
            </div>,
          )}
          data-tooltip-id="tooltip"
        >
          {userName}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
  };
};

export default connect(mapStateToProps)(DiagnosticInfo);
