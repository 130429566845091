import PropTypes from 'prop-types';
import React from 'react';

import styles from './EditStockModal.module.scss';

export function Row(props) {
  const { title, children } = props;

  return (
    <div className={styles.row}>
      <div className={styles.title}>{title}</div>
      <div className={styles.value}>{children}</div>
    </div>
  );
}

Row.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
};
