import cloneDeep from 'lodash/cloneDeep';
import {
  ADD_SCHEDULE,
  ADD_SCHEDULE_ERROR,
  ADD_SCHEDULE_RESULT,
  DELETE_CAP_FROM_SCHEDULE,
  DELETE_CAP_FROM_SCHEDULE_RESULT,
  DELETE_SCHEDULE,
  DELETE_SCHEDULE_RESULT,
  EDIT_SCHEDULE,
  EDIT_SCHEDULE_ERROR,
  EDIT_SCHEDULE_RESULT,
  SCHEDULE_DETAILS,
  SCHEDULE_DETAILS_RESULT,
  SCHEDULE_LIST,
  SCHEDULE_LIST_CLEAR,
  SCHEDULE_LIST_RESULT,
} from './constants';

export const CapSchedulesReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_SCHEDULE:
    case ADD_SCHEDULE:
    case SCHEDULE_LIST:
      return {
        ...state,
        isLoading: true,
      };

    case EDIT_SCHEDULE_ERROR:
    case ADD_SCHEDULE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case EDIT_SCHEDULE_RESULT:
    case ADD_SCHEDULE_RESULT:
      return {
        ...state,
        isLoading: false,
      };
    case SCHEDULE_LIST_RESULT:
      return {
        ...state,
        isLoading: false,
        data: action.response,
      };
    case SCHEDULE_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case SCHEDULE_DETAILS_RESULT:
      return {
        ...state,
        isLoading: false,
        scheduleDetails: action.response,
      };
    case SCHEDULE_LIST_CLEAR:
      return {
        ...state,
        isLoading: false,
        data: undefined,
      };

    case DELETE_CAP_FROM_SCHEDULE:
    case DELETE_SCHEDULE:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_SCHEDULE_RESULT: {
      if (action.id) {
        const { data } = state;

        let updatedSchedule = cloneDeep(data);
        updatedSchedule.data = data?.data.filter(cap => {
          return cap.id !== action.id;
        });

        return {
          ...state,
          data: updatedSchedule,
          isLoading: false,
        };
      }
    }

    case DELETE_CAP_FROM_SCHEDULE_RESULT: {
      if (action.capId) {
        const { scheduleDetails } = state;

        let updatedScheduleDetails = cloneDeep(scheduleDetails);
        updatedScheduleDetails.caps = scheduleDetails?.caps.filter(cap => {
          return cap.cap_id !== action.capId;
        });

        return {
          ...state,
          scheduleDetails: updatedScheduleDetails,
          isLoading: false,
        };
      }
    }

    default:
      return state;
  }
};
