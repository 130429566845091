/* eslint-disable no-useless-constructor */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actionsAdvancedFilters } from '../actions';

function Input(props) {
  const getMyStateFromProps = () => {
    const { filterDefinition, filters } = props;
    const { group } = filterDefinition;

    let myState;
    filters.forEach(e => {
      if (e.group === group) myState = e.state;
    });

    return myState;
  };

  useEffect(() => {
    // if state is not defined in filters from props, lets publish as cleared
    if (getMyStateFromProps() === undefined) {
      const { updateFilterState, filterDefinition } = props;
      const { group } = filterDefinition;
      updateFilterState({
        group,
        value: group,
        state: '',
      });
    }
  }, [props.group, props.filters]);

  const onChange = value => {
    const { filterDefinition, updateFilterState } = props;
    const { group } = filterDefinition;
    if (props.filterDefinition.max && parseInt(value, 10) > props.filterDefinition.max) {
      return;
    }
    const event = {
      group,
      value: group,
      state: value,
    };
    updateFilterState(event);
  };

  const value = getMyStateFromProps();

  return (
    <div className="list-item">
      <div className="label-selector">
        <input type={props.filterDefinition.type} value={value} onChange={e => onChange(e.target.value)} />
        <span>{props.filterDefinition.unit}</span>
      </div>
    </div>
  );
}

Input.propTypes = {
  group: PropTypes.string.isRequired,
  updateFilterState: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  return {
    filters: state.entities.advancedFilters.filters.items,
  };
};

const mapDispatchToProps = dispatch => ({
  updateFilterState: data => dispatch(actionsAdvancedFilters.updateFilterState(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Input);
