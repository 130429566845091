import {
  GET_COHORTS,
  GET_COHORTS_RESULT,
  GET_COHORTS_ERROR,
  GET_COHORT_DETAILS,
  GET_COHORT_DETAILS_RESULT,
  GET_COHORT_DETAILS_ERROR,
  GET_CONDITION_LIST,
  GET_CONDITION_LIST_ERROR,
  GET_CONDITION_LIST_RESULT,
  GET_FACILITIES,
  GET_FACILITIES_RESULT,
  GET_FACILITIES_ERROR,
} from './constants';

export const CohortsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_COHORTS:
      return {
        ...state,
        cohorts: [],
        isLoading: true,
      };

    case GET_COHORTS_RESULT:
      return {
        ...state,
        cohorts: action.response.data,
        pagination: action.response.pagination,
        isLoading: false,
      };

    case GET_COHORTS_ERROR:
      return {
        ...state,
        cohorts: [],
        isLoading: false,
      };

    case GET_COHORT_DETAILS:
      return {
        ...state,
        cohort: null,
        isLoading: true,
      };

    case GET_COHORT_DETAILS_RESULT:
      return {
        ...state,
        cohort: action.response,
        isLoading: false,
      };

    case GET_COHORT_DETAILS_ERROR:
      return {
        ...state,
        cohort: null,
        isLoading: false,
      };

    case GET_CONDITION_LIST:
      return {
        ...state,
        conditions: [],
        isLoading: true,
      };

    case GET_CONDITION_LIST_RESULT:
      return {
        ...state,
        conditions: action.response.data,
        isLoading: false,
      };

    case GET_CONDITION_LIST_ERROR:
      return {
        ...state,
        conditions: [],
        isLoading: false,
      };

    case GET_FACILITIES:
      return {
        ...state,
        facilities: [],
        isLoading: true,
      };

    case GET_FACILITIES_RESULT:
      return {
        ...state,
        facilities: action.response.data,
        isLoading: false,
      };

    case GET_FACILITIES_ERROR:
      return {
        ...state,
        facilities: [],
        isLoading: false,
      };

    default:
      return state;
  }
};
