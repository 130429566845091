import {
  WIDGET_GET_DEVICES_READINGS,
  WIDGET_GET_DEVICES_READINGS_RESULT,
  WIDGET_GET_DEVICES_READINGS_ERROR,
} from './constants';

export const widgetDevicesReadingsReducer = (state = {}, action) => {
  switch (action.type) {
    case WIDGET_GET_DEVICES_READINGS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        readings: [],
      };
    case WIDGET_GET_DEVICES_READINGS_RESULT:
      return {
        ...state,
        isLoading: false,
        isError: false,
        readings: action.response,
      };

    case WIDGET_GET_DEVICES_READINGS_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        readings: [],
      };
    }
    default:
      return state;
  }
};
