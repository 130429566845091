import { API_CALL } from '../middleware/api';

export const authActions = {
  login: 'auth/login',
  logout: 'auth/logout',
  sessionLogout: 'auth/session-logout',
  profile: 'auth/profile',
  clearLoginForm: 'auth/clear-login-form',
  oauth2Login: 'auth/fhir-auth',
  ssoLogin: 'auth/sso',
  sendResetPasswordValidationMessage: 'auth/send-reset-pass-valid-message',
  resetPassword: 'auth/reset-password',
  checkSmsCode: 'auth/check-sms-code',
  two_factor_auth: 'auth/2fa',
  two_factor_auth_resend: 'auth/2fa_resend',
  two_factor_auth_resend_result: 'auth/2fa_resend/result',
  clear_two_factor_key: 'auth/clear-2fa-key',
};

export const clear_two_factor_key = () => ({ type: authActions.clear_two_factor_key });

export const two_factor_auth = ({ code, two_factor_key }) => ({
  type: authActions.two_factor_auth,
  [API_CALL]: {
    endpoint: '/esi/v1/admins/verify',
    data: {
      code,
      two_factor_key,
    },
    method: 'post',
  },
});

export const two_factor_auth_resend = ({ two_factor_key }) => ({
  type: authActions.two_factor_auth_resend,
  [API_CALL]: {
    endpoint: '/esi/v1/admins/verify',
    data: {
      function: 'resend',
      two_factor_key,
    },
    method: 'post',
  },
});

export const login = ({ username, password }) => ({
  type: authActions.login,
  [API_CALL]: {
    endpoint: '/rails/api/users/sign_in/',
    data: {
      username,
      password,
    },
    method: 'post',
  },
});

export const oauth2Login = ({ sst }) => ({
  type: authActions.oauth2Login,
  [API_CALL]: {
    endpoint: '/fhir/login',
    data: { sst },
    method: 'post',
  },
});

export const ssoLogin = ({ token, context, user, source = '' }) => ({
  type: authActions.ssoLogin,
  [API_CALL]: {
    endpoint: '/sso/login',
    data: {
      token,
      context,
      user,
    },
    method: 'post',
    source,
  },
});

export const userProfile = () => ({
  type: authActions.profile,
  [API_CALL]: {
    endpoint: '/api/accounts/users/me/',
    data: null,
  },
});

export const logout = () => ({
  type: authActions.logout,
  [API_CALL]: {
    endpoint: '/rails/api/users/sign_out/',
    method: 'get',
  },
});

export const sessionLogout = () => ({
  type: authActions.sessionLogout,
  [API_CALL]: {
    endpoint: '/logout_session/',
    method: 'get',
  },
});

export const clearLoginForm = { type: authActions.clearLoginForm };

export const unSetError = () => ({ type: 'auth/status/unset' });

export const sendRestPasswordMessage = model => ({
  type: authActions.sendResetPasswordValidationMessage,
  [API_CALL]: {
    endpoint: '/accounts/user/password/change/',
    method: 'post',
    data: model,
  },
});

export const resetPassword = model => ({
  type: authActions.resetPassword,
  [API_CALL]: {
    endpoint: '/accounts/user/password/verify/',
    method: 'post',
    data: model,
  },
});

export const checkSmsCode = model => ({
  type: authActions.checkSmsCode,
  [API_CALL]: {
    endpoint: '/accounts/user/password/verify_sms/',
    method: 'post',
    data: model,
  },
});
