import {
  interpolateBrBG,
  interpolatePRGn,
  interpolatePiYG,
  interpolatePuOr,
  interpolateRdBu,
  interpolateRdGy,
  interpolateRdYlBu,
  interpolateRdYlGn,
  interpolateSpectral,
  interpolateBlues,
  interpolateGreens,
  interpolateGreys,
  interpolateOranges,
  interpolatePurples,
  interpolateReds,
  interpolateTurbo,
  interpolateViridis,
  interpolateInferno,
  interpolateMagma,
  interpolatePlasma,
  interpolateCividis,
  interpolateWarm,
  interpolateCool,
  interpolateCubehelixDefault,
  interpolateBuGn,
  interpolateBuPu,
  interpolateGnBu,
  interpolateOrRd,
  interpolatePuBuGn,
  interpolatePuBu,
  interpolatePuRd,
  interpolateRdPu,
  interpolateYlGnBu,
  interpolateYlGn,
  interpolateYlOrBr,
  interpolateYlOrRd,
  interpolateRainbow,
  interpolateSinebow,
} from 'd3-scale-chromatic';

export const ColorPalette = {
  //#region diverging
  BrBG: { name: 'BrBG', interpolate: interpolateBrBG },
  PRGn: { name: 'PRGn', interpolate: interpolatePRGn },
  PiYG: { name: 'PiYG', interpolate: interpolatePiYG },
  PuOr: { name: 'PuOr', interpolate: interpolatePuOr },
  RdBu: { name: 'RdBu', interpolate: interpolateRdBu },
  RdGy: { name: 'RdGy', interpolate: interpolateRdGy },
  RdYlBu: { name: 'RdYlBu', interpolate: interpolateRdYlBu },
  RdYlGn: { name: 'RdYlGn', interpolate: interpolateRdYlGn },
  Spectral: { name: 'Spectral', interpolate: interpolateSpectral },

  //#endregion
  //#region sqeuquential
  Blues: { name: 'Blues', interpolate: interpolateBlues },
  Greens: { name: 'Greens', interpolate: interpolateGreens },
  Greys: { name: 'Greys', interpolate: interpolateGreys },
  Oranges: { name: 'Oranges', interpolate: interpolateOranges },
  Purples: { name: 'Purples', interpolate: interpolatePurples },
  Reds: { name: 'Reds', interpolate: interpolateReds },
  Turbo: { name: 'Turbo', interpolate: interpolateTurbo },
  Viridis: { name: 'Viridis', interpolate: interpolateViridis },
  Inferno: { name: 'Inferno', interpolate: interpolateInferno },
  Magma: { name: 'Magma', interpolate: interpolateMagma },
  Plasma: { name: 'Plasma', interpolate: interpolatePlasma },
  Cividis: { name: 'Cividis', interpolate: interpolateCividis },
  Warm: { name: 'Warm', interpolate: interpolateWarm },
  Cool: { name: 'Cool', interpolate: interpolateCool },
  CubehelixDefault: { name: 'CubehelixDefault', interpolate: interpolateCubehelixDefault },
  BuGn: { name: 'BuGn', interpolate: interpolateBuGn },
  BuPu: { name: 'BuPu', interpolate: interpolateBuPu },
  GnBu: { name: 'GnBu', interpolate: interpolateGnBu },
  OrRd: { name: 'OrRd', interpolate: interpolateOrRd },
  PuBuGn: { name: 'PuBuGn', interpolate: interpolatePuBuGn },
  PuBu: { name: 'PuBu', interpolate: interpolatePuBu },
  PuRd: { name: 'PuRd', interpolate: interpolatePuRd },
  RdPu: { name: 'RdPu', interpolate: interpolateRdPu },
  YlGnBu: { name: 'YlGnBu', interpolate: interpolateYlGnBu },
  YlGn: { name: 'YlGn', interpolate: interpolateYlGn },
  YlOrBr: { name: 'YlOrBr', interpolate: interpolateYlOrBr },
  YlOrRd: { name: 'YlOrRd', interpolate: interpolateYlOrRd },
  //#region
  //#region cyclical
  Rainbow: { name: 'Rainbow', interpolate: interpolateRainbow },
  Sinebow: { name: 'Sinebow', interpolate: interpolateSinebow },
  //#endregion
};

export const defaultPalette = Object.keys(ColorPalette)[16];
