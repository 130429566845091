import React from 'react';
import PropTypes from 'prop-types';

function SpirometryMeasurements(props) {
  const { data } = props;

  return data ? (
    <table className="table spirometry-results">
      <colgroup>
        <col />
        <col />
        <col />
        <col />
      </colgroup>
      <thead className="table-header">
        <tr>
          <th>
            <a>GLI 2012</a>
          </th>
          <th>
            <a>Best</a>
          </th>
          <th>
            <a>Pred.</a>
          </th>
          <th>
            <a>%Pred.</a>
          </th>
        </tr>
      </thead>
      <tbody className="table-body fade-in">
        <tr className="table-row">
          <td>FVC (L)</td>
          <td>{data.fvc?.toFixed(2)}</td>
          <td>{data.fvcPred?.toFixed(2)}</td>
          <td>{Math.round(data.fvcPercPred * 100)}%</td>
        </tr>
        <tr className="table-row">
          <td>
            FEV<sub>1</sub> (L)
          </td>
          <td>{data.fev1?.toFixed(2)}</td>
          <td>{data.fev1Pred?.toFixed(2)}</td>
          <td>{Math.round(data.fev1PercPred * 100)}%</td>
        </tr>
        <tr className="table-row">
          <td>
            FEV<sub>1</sub>/FVC
          </td>
          <td>{data.fev1Fvc?.toFixed(2)}</td>
          <td>{data.fev1FvcPred?.toFixed(2)}</td>
          <td>{Math.round(data.fev1FvcPercPred * 100)}%</td>
        </tr>
        <tr className="table-row">
          <td>
            FEF<sub>2575</sub> (L/s)
          </td>
          <td>{data.fef2575?.toFixed(2)}</td>
          <td>{data.fef2575Pred?.toFixed(2)}</td>
          <td>{Math.round(data.fef2575PercPred * 100)}%</td>
        </tr>
        <tr className="table-row">
          <td>
            FEV<sub>6</sub> (L)
          </td>
          <td>{data.fev6?.toFixed(2)}</td>
          <td></td>
          <td></td>
        </tr>
        <tr className="table-row">
          <td>PEF (L/s)</td>
          <td>{data.pef?.toFixed(2)}</td>
          <td></td>
          <td></td>
        </tr>
        <tr className="table-row">
          <td>FET (s)</td>
          <td>{data.fet?.toFixed(2)}</td>
          <td></td>
          <td></td>
        </tr>
        <tr className="table-row">
          <td>FIVC (L)</td>
          <td>{data.fivc?.toFixed(2)}</td>
          <td></td>
          <td></td>
        </tr>
        <tr className="table-row">
          <td>PIF (L/s)</td>
          <td>{data.pif?.toFixed(2)}</td>
          <td></td>
          <td></td>
        </tr>
        <tr className="table-row">
          <td>BEV (L)</td>
          <td>{data.bev?.toFixed(2)}</td>
          <td></td>
          <td></td>
        </tr>
        {/* <tr className="table-row">
          <td>BEV (%)</td>
          <td>{((data.bev / data.fvc) * 100).toFixed(2)}%</td>
          <td></td>
          <td></td>
        </tr> */}
      </tbody>
    </table>
  ) : (
    <></>
  );
}

SpirometryMeasurements.widgetId = 'DevicesWidget';

SpirometryMeasurements.propTypes = {
  data: PropTypes.object,
};

export default SpirometryMeasurements;
