import React from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import { Box, PiePlotComponent } from './base';
import Strings from '../../Strings';
import { emptyPieChartSVG, emptyPieChartSVGNoData } from '../../utils';

const DevicesPlot = ({ data, isLoading }) => {
  let newData = cloneDeep(data);
  if (!data) {
    newData = {
      appOnly: 0,
      device: 0,
    };
  }
  const plotData = [
    {
      className: 'new-lightblue',
      value: newData.appOnly,
      label: Strings.appOnly,
    },
    {
      className: 'new-yellow',
      value: newData.device,
      label: Strings.smartCap,
    },
  ].filter(d => Boolean(d.value));

  const total = newData.appOnly + newData.device;

  function mainLabel() {
    return [
      {
        text: total ? `${((newData.device / total) * 100).toFixed(2)}%` : '',
        className: '',
      },
    ];
  }

  function itemLabel(d) {
    return [
      {
        text: d.data.label,
        className: 'bottom',
      },
      {
        text: d.data.value,
        className: 'top',
      },
    ];
  }

  if (!total) {
    return (
      <Box>
        <header>
          <div>{Strings.smartCapUsage}</div>
        </header>
        {isLoading || isLoading === undefined || !plotData ? emptyPieChartSVG() : emptyPieChartSVGNoData()}
      </Box>
    );
  }

  if (!total || (plotData && (isLoading || isLoading === undefined))) {
    return (
      <Box>
        <header>
          <div>{Strings.smartCapUsage}</div>
        </header>
        {isLoading || isLoading === undefined || !plotData ? emptyPieChartSVG() : emptyPieChartSVGNoData()}
      </Box>
    );
  }

  return (
    <Box caption={Strings.smartCapUsage} isLoading={isLoading}>
      <PiePlotComponent data={plotData} mainLabel={mainLabel} itemLabel={itemLabel} />
    </Box>
  );
};

DevicesPlot.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.bool,
};

export default DevicesPlot;
