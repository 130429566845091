import PropTypes from 'prop-types';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';

const renderSuggestion = suggestion => <div>{suggestion.rxName}</div>;

export class AutoSuggestionInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props?.value ? props.value : '',
      loader: false,
      suggestions: [],
      fetchData: [],
      code: '',
    };
  }

  componentDidMount() {
    axios.get('https://mobileapidev.meducation.com/medsearch?qs=aba').then(response => {
      this.setState({
        fetchData: response.data,
        suggestions: response.data,
      });
    });
    this.forceUpdate();
  }

  onChange = (event, { newValue }) => {
    if (this.props.handleNameValue) this.props.handleNameValue(newValue);
    this.setState({ value: newValue });
  };

  setSuggestion(value) {
    this.setState({ loader: true });
    axios.get(`https://mobileapidev.meducation.com/medsearch?qs=${value}`).then(response => {
      this.setState({ loader: false });
      this.setState({
        fetchData: response.data,
        suggestions: response.data,
      });
    });
    this.forceUpdate();
  }

  onSuggestionsFetchRequested = ({ value }) => {
    if (value.length) {
      this.setState({ loader: false });
      clearTimeout(this.keyPressTime);
      this.keyPressTime = setTimeout(() => {
        this.setSuggestion(value);
      }, 1000);
    }
  };

  onSuggestionsClearRequested = () => {
    // this.setState({
    //   suggestions: [],
    // });
  };

  getSuggestionValue = suggestion => {
    const a = cloneDeep(suggestion);
    this.props.handleValue(a);
    return suggestion.rxName;
  };

  render() {
    const { suggestions, loader, value } = this.state;
    // const { value } = this.props;
    const inputProps = {
      placeholder: 'Search',
      value,
      onChange: this.onChange,
    };

    return (
      <div className="auto-spinner-conatiner">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />

        {loader ? (
          <div className="table-row-spinner auto-spinner">
            <div className="spinner-row" />
          </div>
        ) : null}
      </div>
    );
  }
}

AutoSuggestionInput.propTypes = {
  handleNameValue: PropTypes.func,
  handleValue: PropTypes.func,
  value: PropTypes.any,
};
