import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';

import './style.scss';
import Strings from '../../Strings';

function Multiselect(props) {
  const { data, label, id, placeholder, value, className, checkboxFirst, menuPositionFixed } = props;

  const getLabel = option => (
    <React.Fragment key={`option_${option.value}_${value.includes(option.value)}`}>
      {option.label}
      <input type="checkbox" defaultChecked={value.includes(option.value)} disabled={option.isDisabled} />
    </React.Fragment>
  );

  const dataForValue =
    data?.length > 0
      ? data
          .filter(d => d.options)
          .map(d => d.options)
          .flat()
          .concat(data.filter(d => !d.options))
      : data;
  return (
    <>
      <label htmlFor={id} className="visuallyhidden">
        {label}
      </label>
      <ReactSelect
        id={id}
        className={`${className || ''} ${checkboxFirst ? 'checkboxFirst' : ''} react-select-container multi-select`}
        classNamePrefix="react-select"
        value={value.map(v => dataForValue.find(d => d.value === v))}
        onChange={props.onChange}
        options={data}
        unstyled
        isSearchable={false}
        placeholder={placeholder}
        menuPosition={menuPositionFixed ? 'fixed' : 'absolute'}
        isMulti
        controlShouldRenderValue={false}
        isClearable={false}
        hideSelectedOptions={false}
        closeMenuOnSelect={props.closeMenuOnSelect}
        getOptionLabel={getLabel}
        components={{ Group, GroupHeading }}
      />
    </>
  );
}

Multiselect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  data: PropTypes.array.isRequired,
  closeMenuOnSelect: PropTypes.bool,
  menuPositionFixed: PropTypes.bool,
};

Multiselect.defaultProps = { menuPositionFixed: true };

export default Multiselect;

export const SelectedOptionsType = {
  cloud: 'cloud',
  boxes: 'boxes',
};

export function SelectedOptions(props) {
  const { items, onRemove, onClear, itemsPerRow, type } = props;
  return (
    <div
      className={`multiselect-selected-options ${
        type === SelectedOptionsType.boxes ? `items-per-row-${itemsPerRow}` : ''
      }`}
    >
      {type === SelectedOptionsType.cloud && items && items.length > 0 && (
        <div className="round-button" onClick={onClear}>
          {Strings.clear}
        </div>
      )}
      {items.map(item => (
        <div
          key={`selectedOption_${item.value}`}
          className={`${type === SelectedOptionsType.boxes ? 'checkbox-container border' : 'option'}`}
        >
          <p>{item.label}</p>
          <button className="remove-button" onClick={() => onRemove(item)} />
        </div>
      ))}
    </div>
  );
}
SelectedOptions.propTypes = {
  items: PropTypes.array,
  onRemove: PropTypes.func,
  onClear: PropTypes.func,
  itemsPerRow: PropTypes.number,
  type: PropTypes.string,
};
SelectedOptions.defaultProps = { itemsPerRow: 2, type: SelectedOptionsType.boxes };

const Group = props => {
  const allOptionsSelected = !props.children.some(opt => !opt.props.isSelected);
  const value = props.getValue();
  let newValue = [];
  const isDisabled = props.options.every(o => o.isDisabled);

  const onClick = () => {
    if (allOptionsSelected) {
      newValue = value.filter(v => !props.options.find(o => o.value === v.value));
    } else {
      newValue = value.concat(props.options.filter(o => !value.find(v => v.value === o.value)));
    }
    props.selectProps.onChange(newValue);
  };
  return (
    <components.Group {...props} headingProps={{ ...props.headingProps, allOptionsSelected, onClick, isDisabled }} />
  );
};

const GroupHeading = ({ allOptionsSelected, ...props }) => {
  return (
    <div className="react-select__option header">
      <components.GroupHeading {...props} className={`${props.isDisabled ? 'disabled' : ''}`} />
      <input type="checkbox" checked={allOptionsSelected} onChange={props.onClick} disabled={props.isDisabled} />
    </div>
  );
};
