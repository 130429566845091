import moment from 'moment-timezone';

import { modalActionTypes } from '../actions/modal';

const modalsReducer = (state = {}, action) => {
  switch (action.type) {
    case modalActionTypes.open:
      return {
        ...state,
        [`${action.id}${action.modalId ? action.modalId : ''}`]: {
          open: true,
          data: action.data,
          modalId: action.modalId,
          timestamp: moment().unix(),
        },
      };

    case modalActionTypes.close:
      return {
        ...state,
        [`${action.id}${action.modalId ? action.modalId : ''}`]: {
          ...state[`${action.id}${action.modalId ? action.modalId : ''}`],
          open: false,
          data: action.data,
          timestamp: 0,
        },
      };

    case modalActionTypes.updateTimestamp:
      return {
        ...state,
        [`${action.id}${action.modalId ? action.modalId : ''}`]: {
          ...state[`${action.id}${action.modalId ? action.modalId : ''}`],
          timestamp: moment().unix(),
        },
      };

    default:
      return state;
  }
};

export default modalsReducer;
