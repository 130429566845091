import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
  ReferenceArea,
  ReferenceLine,
  Area,
  AreaChart,
  ComposedChart,
  LineChart,
} from 'recharts';

import {
  CustomizedXAxisTickIrregular,
  CustomizedXAxisTickIrregularMinMaxOnly,
  CustomizedYAxisTickNumeric,
} from './GraphAxes';
import { DATE_FORMAT_YEAR_MONTH_DAY, DATE_FORMAT_MONTH_YEAR, DATE_FORMAT_LLLL } from '../../constants';
import {
  CONDENSED_VIEW_THRESHOLD,
  FIRST_IN_MONTH_MARKER,
  FIRST_IN_DAY_MARKER,
  GRAPH_ROW_BK,
  GRAPH_ROW_BK_ALTERNATE,
  GRAPH_DOT_RADIUS,
  GRAPH_DOT_RADIUS_CONDENSED,
  GRAPH_ACTIVE_DOT_RADIUS,
  GRAPH_AXIS_X_HEIGHT,
  GRAPH_AXIS_X_HEIGHT_CONDENSED,
  GRAPH_AXIS_LINE_COLOR,
  GRAPH_AXIS_LINE_WIDTH,
  GRAPH_REF_LINE_COLOR,
  GRAPH_REF_LINE_DASH,
} from './GraphConstants';

import './graph.scss';
import _ from 'lodash';

function GetSeries_All(data) {
  const ret = [];
  data.forEach(day => {
    ret.push({
      x: day.x,
      y: day.values[0],
      all: day,
    });
  });

  return ret;
}

function GetSeries(data, index) {
  const ret = [];
  data.forEach(day => {
    ret.push({
      x: day.x,
      y: day.values[index],
    });
  });

  return ret;
}

export const chartTypeEnum = Object.freeze({ line: 'line', area: 'area' });

function GenericLineGraph(props) {
  const PrepareData = (graphData, dataSeries, dateSelector, yMin) => {
    const data = [];

    const constData = { yMin };

    let lastXday = '';
    let lastXmonth = '';

    graphData.forEach(item => {
      const date = dateSelector(item);
      const xDay = date.format(DATE_FORMAT_YEAR_MONTH_DAY);
      const isFirstDay = lastXday !== xDay;
      lastXday = xDay;

      const xMonth = date.format(DATE_FORMAT_MONTH_YEAR);
      const isFirstMonth = lastXmonth !== xMonth;
      lastXmonth = xMonth;

      const graphEntry = {
        x: date.format('LLL') + (isFirstMonth ? FIRST_IN_MONTH_MARKER : isFirstDay ? FIRST_IN_DAY_MARKER : ''),
        moment: date,
        values: [],
        ...constData,
      };

      dataSeries.forEach(ds => {
        graphEntry.values.push(ds.selector(item));
      });

      data.push(graphEntry);
    });

    return data;
  };

  const calcValMinMax2 = (graphData, dataSelector) => {
    const items = graphData.map(dataSelector);
    return {
      min: Math.min(...items),
      max: Math.max(...items),
    };
  };
  const calcValMinMax = (graphData, dataSeries) => {
    let result;
    dataSeries.forEach(ds => {
      const localMinMax = calcValMinMax2(graphData, ds.selector);
      if (result === undefined) {
        result = localMinMax;
      } else {
        if (result.min > localMinMax.min) result.min = localMinMax.min;
        if (result.max < localMinMax.max) result.max = localMinMax.max;
      }
    });

    return result;
  };

  const calcTickFromDelta = valDelta => {
    const maxTicks = 12;
    const tick =
      valDelta < maxTicks
        ? 1
        : valDelta < maxTicks * 2
        ? 2
        : valDelta < maxTicks * 5
        ? 5
        : valDelta < maxTicks * 10
        ? 10
        : valDelta < maxTicks * 20
        ? 20
        : valDelta < maxTicks * 50
        ? 50
        : valDelta < maxTicks * 100
        ? 100
        : valDelta < maxTicks * 200
        ? 200
        : valDelta < maxTicks * 500
        ? 500
        : 1000;

    return tick;
  };

  const {
    graphData,
    hasBorder,
    forceCondensed,
    xAxisTopPosition,
    xAxisLabelsMinMaxOnly,
    yAxisWidth,
    yAxisUnit,
    tooltipTitle,
    tooltipContentLines,
    tooltipDateFormat,
    dataSeries,
    referenceLines,
    dateSelector,
    hasReferenceAreas,
  } = props;

  const valMinMax = calcValMinMax(graphData, dataSeries);
  const valDelta = valMinMax.max - valMinMax.min;
  const tick = calcTickFromDelta(valDelta);

  const yMin = valMinMax.min - (valMinMax.min % tick);
  const yMax = valMinMax.max - (valMinMax.max % tick) + tick;
  const yTicksCount = (yMax - yMin) / tick + 1;
  const yTicks = Array.from({ length: yTicksCount }, (_, i) => yMin + i * tick);

  let chartData = PrepareData(graphData, dataSeries, dateSelector, yMin);
  const dateFormatForTooltip = tooltipDateFormat ? tooltipDateFormat : DATE_FORMAT_LLLL;
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
      if (payload[0].payload.all == null) return null; // exclude empty data
      if (payload[0].payload.all.values == null) return null; // only valid pp's
      return (
        <div className="customTooltip">
          <p className="customTooltipTitle">{tooltipTitle}</p>
          <div className="customTooltipDescr">{payload[0].payload.all.moment.format(dateFormatForTooltip)}</div>
          {tooltipContentLines &&
            tooltipContentLines(payload[0].payload.all).map(ttl => (
              <div className="customTooltipDescr" key={`customTT_${ttl}`}>
                {ttl}
              </div>
            ))}
          {!tooltipContentLines &&
            dataSeries.map((ds, index) => (
              <div className="customTooltipDescr" key={`customTT_${ds.name}`}>
                {ds.name}: {payload[0].payload.all.values[index] + (yAxisUnit ? ' ' + yAxisUnit : '')}
              </div>
            ))}
        </div>
      );
    }
    return null;
  };

  const xAxisFirstValue = chartData[0].x;
  const xAxisLastValue = chartData[chartData.length - 1].x;

  const drawXAxis = () => {
    return (
      <XAxis
        type="category"
        dataKey="x"
        allowDuplicatedCategory={false}
        height={
          chartData.length > CONDENSED_VIEW_THRESHOLD || forceCondensed || xAxisLabelsMinMaxOnly
            ? GRAPH_AXIS_X_HEIGHT_CONDENSED
            : GRAPH_AXIS_X_HEIGHT
        }
        interval={0}
        name="day"
        orientation={xAxisTopPosition ? 'top' : 'bottom'}
        tickLine={false}
        tickSize={2}
        tick={
          xAxisLabelsMinMaxOnly ? (
            <CustomizedXAxisTickIrregularMinMaxOnly
              valueFirst={xAxisFirstValue}
              valueLast={xAxisLastValue}
              isTop={xAxisTopPosition}
            />
          ) : (
            <CustomizedXAxisTickIrregular isCondensed={chartData.length > CONDENSED_VIEW_THRESHOLD || forceCondensed} />
          )
        }
        padding={{
          left: 10,
          right: 10,
        }}
        axisLine={{
          stroke: GRAPH_AXIS_LINE_COLOR,
          strokeWidth: GRAPH_AXIS_LINE_WIDTH,
        }}
      />
    );
  };

  const drawYAxis = () => {
    return (
      <YAxis
        type="number"
        dataKey="y"
        name="yAxis"
        tick={<CustomizedYAxisTickNumeric yMin={yMin} yMax={yMax} width={yAxisWidth} tick={tick} unit={yAxisUnit} />}
        domain={[yMin, yMax]}
        ticks={yTicks}
        interval={0}
        tickSize={0}
        width={yAxisWidth}
        axisLine={{
          stroke: GRAPH_AXIS_LINE_COLOR,
          strokeWidth: GRAPH_AXIS_LINE_WIDTH,
        }}
        padding={{
          top: 2,
          bottom: xAxisTopPosition ? 5 : 0,
        }}
      />
    );
  };

  const drawTooltip = () => {
    return <Tooltip content={<CustomTooltip />} dataKey="y" />;
  };

  /* reference areas to mimic alternate rows */
  const drawAlternateRows = () => {
    return Array.from({ length: yTicksCount }, (_, i) => i).map(i => (
      <ReferenceArea
        x1={graphData[0].x}
        x2={graphData[graphData.length - 1].x}
        y1={i === 0 ? yMin : yMin + tick / 2 + (i - 1) * tick}
        y2={yMin + tick / 2 + i * tick}
        stroke={i % 2 === 0 ? GRAPH_ROW_BK : GRAPH_ROW_BK_ALTERNATE}
        fill={i % 2 === 0 ? GRAPH_ROW_BK : GRAPH_ROW_BK_ALTERNATE}
        key={`refarea${i}`}
      />
    ));
  };

  const drawInvisibleLineForTooltip = () => {
    return <Line tooltipType="none" name="P0" data={GetSeries_All(chartData)} dataKey="y" visibility="hidden" />;
  };

  const drawLineSeries = () => {
    return props.dataSeries.map((ds, index) => (
      <Line
        key={`Line_${ds.name}`}
        type="linear"
        tooltipType="none"
        name={ds.name}
        stroke={ds.color}
        data={GetSeries(chartData, index)}
        dataKey="y"
        connectNulls
        dot={{
          r:
            chartData.length > CONDENSED_VIEW_THRESHOLD || forceCondensed
              ? GRAPH_DOT_RADIUS_CONDENSED
              : GRAPH_DOT_RADIUS,
        }}
        activeDot={{ r: GRAPH_ACTIVE_DOT_RADIUS }}
      />
    ));
  };

  const drawAreaSeries = () => {
    return props.dataSeries.map((ds, index) => (
      <Area
        key={`Area_${ds.name}`}
        type="monotone"
        tooltipType="none"
        name={ds.name}
        stroke={ds.color}
        strokeWidth={2}
        fill={ds.color}
        fillOpacity="10%"
        data={GetSeries(chartData, index)}
        dataKey="y"
        connectNulls
        dot={{
          r:
            chartData.length > CONDENSED_VIEW_THRESHOLD || forceCondensed
              ? GRAPH_DOT_RADIUS_CONDENSED
              : GRAPH_DOT_RADIUS,
          fill: 'white',
          fillOpacity: 1,
          stroke: ds.color,
        }}
        activeDot={{ r: GRAPH_ACTIVE_DOT_RADIUS }}
      />
    ));
  };

  const drawReferenceLines = () => {
    return referenceLines.map(rl => (
      <ReferenceLine key={rl} y={rl} label="" stroke={GRAPH_REF_LINE_COLOR} strokeDasharray={GRAPH_REF_LINE_DASH} />
    ));
  };

  const drawLineChart = () => {
    return (
      <LineChart
        data={chartData}
        defaultShowTooltip={false}
        margin={{
          top: 5,
          right: 10,
          bottom: 5,
          left: 10,
        }}
      >
        {drawXAxis()}
        {drawYAxis()}
        {drawTooltip()}
        {hasReferenceAreas && drawAlternateRows()}

        {/* line chart specific */}
        {drawInvisibleLineForTooltip()}
        {drawLineSeries()}

        {referenceLines && drawReferenceLines()}
      </LineChart>
    );
  };

  const drawAreaChart = () => {
    return (
      <ComposedChart
        data={chartData}
        defaultShowTooltip={false}
        margin={{
          top: 5,
          right: 10,
          bottom: 5,
          left: 10,
        }}
      >
        {drawXAxis()}
        {drawYAxis()}
        {drawTooltip()}
        {hasReferenceAreas && drawAlternateRows()}

        {/* line chart specific */}
        {drawInvisibleLineForTooltip()}
        {drawAreaSeries()}

        {referenceLines && drawReferenceLines()}
      </ComposedChart>
    );
  };

  if (props.graphType === chartTypeEnum.line) {
    return (
      <div className={hasBorder ? 'graphDiv' : 'graphDivNoBorder'}>
        <ResponsiveContainer width="100%" height="99%" debounce>
          {drawLineChart()}
        </ResponsiveContainer>
      </div>
    );
  }

  if (props.graphType === chartTypeEnum.area) {
    return (
      <div className={hasBorder ? 'graphDiv' : 'graphDivNoBorder'}>
        <ResponsiveContainer width="100%" height="99%" debounce>
          {drawAreaChart()}
        </ResponsiveContainer>
      </div>
    );
  }
}

export default React.memo(GenericLineGraph, (prevProps, nextProps) =>
  _.isEqual(prevProps.graphData, nextProps.graphData),
);

GenericLineGraph.propTypes = {
  graphData: PropTypes.array,
  hasBorder: PropTypes.bool,
  forceCondensed: PropTypes.bool,
  xAxisTopPosition: PropTypes.bool,
  xAxisLabelsMinMaxOnly: PropTypes.bool,
  yAxisWidth: PropTypes.number,
  yAxisUnit: PropTypes.string,
  tooltipTitle: PropTypes.string,
  tooltipContentLines: PropTypes.func,
  tooltipDateFormat: PropTypes.string,
  dataSeries: PropTypes.array,
  referenceLines: PropTypes.array,
  dateSelector: PropTypes.func,
  hasReferenceAreas: PropTypes.bool,
  graphType: PropTypes.string,
};

GenericLineGraph.defaultProps = { graphType: chartTypeEnum.line };
