import PropTypes from 'prop-types';

import moment from 'moment-timezone';
import React, { Component } from 'react';
import { cn } from 'react-id-swiper/lib/utils';

import { TIME_FORMAT_12 } from '../../../constants';
import Strings from '../../../Strings';
import styles from './Info.module.scss';

export function Row(props) {
  const { title, value } = props;

  return (
    <div className={styles.rowContent} style={value && value.style ? value.style : null}>
      <label>{title}</label>
      <span className={styles.labelValue}>{value && value.value ? value.value : value || '-'}</span>
    </div>
  );
}

Row.propTypes = {
  title: PropTypes.any,
  value: PropTypes.string,
};

export function Rows(props) {
  return Object.keys(props.data).map(key => {
    return <Row key={key} title={key} value={props.data[key]} />;
  });
}

Rows.propTypes = { data: PropTypes.any };

export function InfoEsiPatientOwn(props) {
  const { patient, isSettings } = props;

  return (
    <div className={`${styles.infoContainer} ${styles.own}`}>
      <div className={cn(styles.infoBox, { [styles.settings]: Boolean(isSettings) })}>
        <h4 className={styles.title}>{Strings.myDetails}</h4>
        <div className={styles.data}>
          <Rows
            data={{
              [Strings.firstName]: patient.first_name,
              [Strings.lastName]: patient.last_name,
              [Strings.phoneNumber]: patient.mobile_phone,
              [Strings.emailAddress]: patient.email,
              [Strings.timezone]: patient.preferences ? patient.preferences.timezone : '',
            }}
          />
          {/* <button className={styles.button} onClick={onDetailsEdit}>
            {Strings.edit}
          </button> */}
        </div>
      </div>

      <div className={cn(styles.infoBox, { [styles.settings]: Boolean(isSettings) })}>
        <h4 className={styles.title}>{Strings.myAddress}</h4>
        <div className={styles.data}>
          <Rows
            data={{
              [Strings.esiPatientInfo.street]: patient.address?.street_address,
              [Strings.esiPatientInfo.appartment]: patient.address?.address_extra,
              [Strings.esiPatientInfo.zip]: patient.address?.zip,
              [Strings.esiPatientInfo.city]: patient.address?.city,
              [Strings.esiPatientInfo.state]: patient.address?.state,
              [Strings.esiPatientInfo.country]: patient.address?.country,
            }}
          />
        </div>
      </div>
    </div>
  );
}

InfoEsiPatientOwn.propTypes = {
  isSettings: PropTypes.any,
  patient: PropTypes.shape({
    address: PropTypes.shape({
      address_extra: PropTypes.any,
      city: PropTypes.any,
      country: PropTypes.any,
      state: PropTypes.any,
      street_address: PropTypes.any,
      zip: PropTypes.any,
    }),
    email: PropTypes.any,
    first_name: PropTypes.any,
    last_name: PropTypes.any,
    mobile_phone: PropTypes.any,
    preferences: PropTypes.shape({ timezone: PropTypes.any }),
  }),
};

export default class Info extends Component {
  getCapType = cap_type => {
    switch (cap_type) {
      case '38_mm':
        return 'LS002';
      case '42_mm':
        return 'LS001';
      default:
        return '-';
    }
  };

  render() {
    const { patient, isLoading, onHardwareEdit, isSettings } = this.props;
    const patientTimeZone = patient?.timezone;
    const activeFrom = patient.medicine?.start_dt
      ? moment
          .unix(patient.medicine?.start_dt)
          .tz(patientTimeZone)
          .format('MM/DD/YYYY')
      : '-';

    return (
      <div className={styles.infoContainer}>
        <div className={cn(styles.infoBox, { [styles.settings]: Boolean(isSettings) })} style={{ marginTop: '35px' }}>
          <h4 className={styles.title}>{Strings.userDetails}</h4>
          {!isLoading && patient ? (
            <div className={styles.data}>
              <Rows
                data={{
                  [Strings.firstName]: patient.first_name,
                  [Strings.lastName]: patient.last_name,
                  [Strings.phoneNumber]: patient.phone,
                  [Strings.emailAddress]: patient.email,
                  [Strings.timezone]: patient.timezone
                    ? patient.timezone
                    : patient.preferences
                    ? patient.preferences.timezone
                    : '',
                }}
              />

              {patient.shipping ? (
                <Rows
                  data={{
                    [Strings.esiPatientInfo.street]: {
                      value: patient.shipping.street_address,
                      style: { marginTop: '35px' },
                    },
                    [Strings.esiPatientInfo.appartment]: patient.shipping.address_extra,
                    [Strings.esiPatientInfo.zip]: patient.shipping.zip,
                    [Strings.esiPatientInfo.city]: patient.shipping.city,
                    [Strings.esiPatientInfo.state]: patient.shipping.state,
                    [Strings.esiPatientInfo.country]: patient.shipping.country,
                  }}
                />
              ) : null}
            </div>
          ) : null}
        </div>

        <div className={styles.rightContainer}>
          {patient && patient.medicine ? (
            <div
              className={cn(styles.infoBox, { [styles.settings]: Boolean(isSettings) })}
              style={{ marginTop: '35px' }}
            >
              {!isLoading && patient ? (
                <div className={styles.data}>
                  <div className={styles.rowRightContent}>
                    <label className={styles.labelHW}>{Strings.deviceId}: </label>
                    <span className={styles.labelValue}>
                      {patient.medicine.cap_id ? patient.medicine.cap_id : Strings.notAvailable}
                    </span>
                  </div>
                  <button className={styles.button} onClick={onHardwareEdit}>
                    {patient.medicine.cap_id ? Strings.edit : Strings.add}
                  </button>
                </div>
              ) : null}
            </div>
          ) : null}

          {!isLoading && patient && patient.medicine ? (
            <div
              className={cn(styles.infoBox, { [styles.settings]: Boolean(isSettings) })}
              style={{ marginTop: '35px' }}
            >
              <h4 className={styles.title}>{Strings.esiPatientInfo.schedule}</h4>

              <div className={styles.data}>
                <Rows
                  data={{
                    [Strings.esiPatientInfo.device]: patient.medicine?.cap_id ?? '-',
                    [Strings.medication]: patient.medicine.name,
                    [Strings.frequencyText]: patient.medicine.type,
                    [Strings.esiPatientInfo.time]: patient.medicine.offset.map(e => {
                      return `${moment()
                        .startOf('day')
                        .seconds(parseInt(e, 10))
                        .format(TIME_FORMAT_12)} `;
                    }),
                    [Strings.esiPatientInfo.activeFrom]: activeFrom,
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

Info.propTypes = {
  isLoading: PropTypes.any,
  isSettings: PropTypes.any,
  onHardwareEdit: PropTypes.any,
  patient: PropTypes.shape({
    email: PropTypes.any,
    first_name: PropTypes.any,
    last_name: PropTypes.any,
    medicine: PropTypes.shape({
      cap_id: PropTypes.any,
      cap_type: PropTypes.any,
      name: PropTypes.any,
      offset: PropTypes.shape({ map: PropTypes.func }),
      start_dt: PropTypes.func,
      type: PropTypes.any,
    }),
    phone: PropTypes.any,
    preferences: PropTypes.shape({ timezone: PropTypes.any }),
    shipping: PropTypes.shape({
      address_extra: PropTypes.any,
      city: PropTypes.any,
      country: PropTypes.any,
      state: PropTypes.any,
      street_address: PropTypes.any,
      zip: PropTypes.any,
    }),
    timezone: PropTypes.any,
  }),
};
