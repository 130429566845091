export const CHANGE_PAGE_VISIBILITY = 'change-page-visibility';
export const CHANGE_MODAL_VISIBILITY = 'change-modal-visibility';
export const RESET_TO_DEFAULT = 'reset-modals-to-default';

export const actions = {
  changePageVisibility: (modalId, pageId) => ({
    type: CHANGE_PAGE_VISIBILITY,
    modalId,
    pageId,
  }),
  changeModalVisibility: (workflowId, modalId) => ({
    type: CHANGE_MODAL_VISIBILITY,
    workflowId,
    modalId,
  }),
  resetToDefault: modalId => ({
    type: RESET_TO_DEFAULT,
    modalId,
  }),
};
