import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'intl-tel-input/build/js/utils';
import React, { PureComponent } from 'react';

import Strings from '../../../Strings';
import { ComponentWithTooltip } from '../../../utils';
import { TextInput } from '../TextInput';
import './PhoneInput.scss';

export class PhoneInput extends PureComponent {
  refTextInput = React.createRef();
  refHidden = React.createRef();
  iti;
  state = { valuePhoneInput: this.props.value };

  getNumber = () => {
    return this.iti.getNumber();
  };

  isValidNumber = () => {
    if (!this.iti) return false;
    return this.iti.isValidNumber();
  };

  getValidationError = () => {
    if (!this.iti) return null;
    return this.iti.getValidationError();
  };

  getValidationErrorMessage = () => {
    /* eslint-disable */
    switch (this.getValidationError()) {
      case intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
        return Strings.errors.phone.invalidCountryCode;
      case intlTelInputUtils.validationError.NOT_A_NUMBER:
        return Strings.errors.phone.notANumber;
      case intlTelInputUtils.validationError.TOO_LONG:
        return Strings.errors.phone.tooLong;
      case intlTelInputUtils.validationError.TOO_SHORT:
        return Strings.errors.phone.tooShort;
      default:

      // return Strings.errors.phone.notValid; // // ONLY FOR DEV TODO UNCOMMENT ME LATER
      /* eslint-enable */
    }
  };

  initTelInput = () => {
    const { value } = this.props;

    const input = this.refTextInput.current;

    this.iti = intlTelInput(input, {
      onlyCountries: ['us'],
      preferredCountries: [],
      customContainer: 'PhoneInput',
      separateDialCode: true,
      allowDropdown: false,
    });

    /* eslint-disable */
    if (value && this.getValidationError() === intlTelInputUtils.validationError.INVALID_COUNTRY_CODE) {
      this.iti.setCountry('us');
    }
    /* eslint-enable */

    input.addEventListener('countrychange', () => this.onPhoneInputChange({ target: { value: this.getNumber() } }));
  };

  componentDidMount() {
    if (document.readyState !== 'complete') {
      window.addEventListener('load', this.initTelInput);
    } else {
      this.initTelInput();
    }
  }

  purifyNumber(value) {
    return value.replace(/[^0-9+]/g, '');
  }

  updateHiddenInput() {
    const phoneNumber = this.purifyNumber(this.getNumber());

    // Emulating react SyntheticEvent cause we can't fire react's event manually
    this.props.onChange({ target: { value: phoneNumber } });
  }

  onPhoneInputChange = e => {
    const selCountry = this.iti.getSelectedCountryData();
    const code = `+${selCountry.dialCode}`;

    let number = this.purifyNumber(e.target.value);

    if (number.startsWith(code)) {
      number = number.substring(code.length);
    }
    if (number.length <= 10) {
      this.setState({ valuePhoneInput: number });
      this.updateHiddenInput();
    }
  };

  handleOnPaste = () => {
    this.setState({ valuePhoneInput: '' });
  };

  render() {
    const { align, error, value, placeholder, noTooltip, className, ...props } = this.props;
    const { valuePhoneInput } = this.state;

    return (
      <React.Fragment>
        <input {...props} type="hidden" ref={this.refHidden} value={value} />
        {noTooltip ? (
          <TextInput
            error={error}
            placeholder={placeholder}
            id={props.name}
            refLink={this.refTextInput}
            onChange={this.onPhoneInputChange}
            value={valuePhoneInput}
            align={align}
            onPaste={this.handleOnPaste}
            className={className}
            disabled={props.disabled}
          />
        ) : (
          <ComponentWithTooltip
            componentType={TextInput}
            error={error}
            tt-text={props['tt-text']}
            placeholder={placeholder}
            tt-id={props.name}
            refLink={this.refTextInput}
            onChange={this.onPhoneInputChange}
            value={valuePhoneInput}
            align={align}
            onPaste={this.handleOnPaste}
            disabled={props.disabled}
          />
        )}
      </React.Fragment>
    );
  }
}
