import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { changeFilter } from '../../../actions';
import { GET_ENROLLMENT_DATE_RESULT } from '../../../actions/action-types';
import { openModalAction } from '../../../actions/modal';
import LoadingRenderer from '../../../components/LoadingRenderer';
import { PageHeader } from '../../../components/PageHeader';
import SliderPicker from '../../../components/SliderPicker';
import TabView, { Tab } from '../../../components/TabView/index';
import { actions } from '../../../modals/AddESIPatientModal/redux/actions';
import { patientAction } from '../../../actions/patient';
import Strings from '../../../Strings';
import HistoryTab from '../tabs/HistoryTab/HistoryTab';
import Medications from '../tabs/Medications';
import Info from './Info';
import AdherenceGraph from '../../SuperUser/Patients/AdherenceGraph';
import { getActiveTabNameFromUrl } from '../../../utils';
import styles from './Info.module.scss';

export const Tabs = {
  Medications: {
    DisplayName: Strings.medications,
    urlId: 'medications',
    path: 'medications',
  },
  History: {
    DisplayName: Strings.history,
    urlId: 'history',
    path: 'history',
  },
  TimeDelta: {
    DisplayName: Strings.timeDelta,
    urlId: 'timedelta',
    path: 'timedelta',
  },
  Info: {
    DisplayName: Strings.Info,
    urlId: 'info',
    path: 'info',
  },
};

export const getDefaultDateRange = () => {
  return {
    infimum: moment()
      .subtract(6, 'day')
      .startOf('day')
      .toDate(),
    supremum: moment()
      .endOf('day')
      .toDate(),
  };
};

const mapStateToProps = state => {
  const { patientInfo } = state.esi;
  const user = state.user.settings;
  let userFullname;
  if (state.auth.profile) {
    userFullname = `${state.auth.profile.first_name} ${state.auth.profile.last_name}`;
  }
  if (user) {
    user.dateOfBirth = user.date_of_birth;
    user.firstName = user.first_name;
    user.lastName = user.last_name;
  }

  let patient;
  let isLoading;
  if (patientInfo) {
    patient = patientInfo.patient;
    isLoading = patientInfo.isLoading;
  }

  return {
    user,
    isLoading,
    auth: state.auth,
    patient,
    userFullname,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getPatientData: id => dispatch(actions.getPatient(id)),
  clearPatient: () => dispatch(actions.clearPatient()),
  onHardwareEdit: (schedule_id, patient_id, cap_id) =>
    dispatch(
      openModalAction('add-edit-hardware-id', {
        action: '',
        actionType: '',
        schedule_id,
        patient_id,
        cap_id,
      }),
    ),
  getEnrollmentDate: params => dispatch(patientAction.getEnrollmentDate(params)),
});

class ESIPatientInfoPage extends PureComponent {
  static defaultProps = { activeTab: 'Info' };

  today = moment()
    .endOf('day')
    .toDate();
  yearAgo = moment()
    .subtract(1, 'year')
    .startOf('day')
    .toDate();

  static propTypes = {
    activeTab: PropTypes.string,
    auth: PropTypes.object.isRequired,
    clearPatient: PropTypes.func,
    getPatientData: PropTypes.func,
    isLoading: PropTypes.bool,
    match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.any }) }),
    onHardwareEdit: PropTypes.func,
    onNavigate: PropTypes.func,
    patient: PropTypes.shape({
      id: PropTypes.number,
      medicine: PropTypes.shape({
        cap_id: PropTypes.any,
        id: PropTypes.any,
      }),
    }),
    user: PropTypes.object,
    userFullname: PropTypes.any,
  };

  state = {
    minDate: this.yearAgo,
    maxDate: this.today,
  };

  UNSAFE_componentWillMount() {
    this.props.getPatientData(this.props.match.params.id);
  }

  componentDidMount() {
    this.props.getEnrollmentDate({ patientId: this.props.match.params.id }).then(response => {
      if (response.type == GET_ENROLLMENT_DATE_RESULT) {
        this.setState({
          minDate: moment(response.response.enrollmentDate)
            .startOf('day')
            .toDate(),
        });
      }
    });
  }

  componentWillUnmount() {
    this.props.clearPatient();
  }

  onBackClick = () => {
    this.props.onNavigate('/esi_patients/active');
  };

  onHardwareEdit = () => {
    const { medicine, id } = this.props.patient;
    this.props.onHardwareEdit(medicine?.id, id, medicine?.cap_id);
  };

  render() {
    const activeTab = this.props.match.params.tab;
    const { user = {}, isLoading, patient } = this.props;
    const { maxDate, minDate } = this.state;
    if (!user) {
      return <LoadingRenderer loading />;
    }

    const pageHeader = (
      <PageHeader
        onBackClick={this.onBackClick}
        title={`${Strings.welcome}${this.props.userFullname ? `, ${this.props.userFullname}` : ''}`}
      />
    );
    const sliderPicker = <SliderPicker min={minDate} max={maxDate} />;
    const patientId = patient?.id || 0;
    const isPatientLoading = isLoading || !patientId;

    return (
      <React.Fragment>
        {pageHeader}
        <TabView key="settingsTabs" activeTab={getActiveTabNameFromUrl(activeTab, Tabs)} routeMode>
          <Tab name={Tabs.Medications.DisplayName} path={Tabs.Medications.path}>
            <LoadingRenderer loading={isPatientLoading}>
              {sliderPicker}
              <Medications patientId={patientId} isLoading={isLoading} />
            </LoadingRenderer>
          </Tab>
          <Tab name={Tabs.History.DisplayName} path={Tabs.History.path}>
            <LoadingRenderer loading={isPatientLoading}>
              <HistoryTab patientId={patientId} minDate={minDate} maxDate={maxDate} />
            </LoadingRenderer>
          </Tab>
          <Tab name={Tabs.TimeDelta.DisplayName} path={Tabs.TimeDelta.path}>
            <LoadingRenderer loading={isPatientLoading}>
              <div className={styles.adherenceChart}>
                <AdherenceGraph patientId={patientId} includeSchedulesWithNoCap />
              </div>
            </LoadingRenderer>
          </Tab>
          <Tab name={Tabs.Info.DisplayName} path={Tabs.Info.path}>
            <LoadingRenderer loading={isPatientLoading}>
              <Info patient={patient} isLoading={isLoading} onHardwareEdit={this.onHardwareEdit} />
            </LoadingRenderer>
          </Tab>
        </TabView>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ESIPatientInfoPage);
