import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ComposedChart, ResponsiveContainer, Bar, Line, Tooltip, XAxis, YAxis } from 'recharts';

// import Strings from '../../Strings';
import { GRAPH_AXIS_LINE_COLOR, GRAPH_AXIS_LINE_WIDTH } from './GraphConstants';
import './graph.scss';
import Strings from '../../Strings';
import { CustomizedXAxisTickHourly, CustomizedYAxisTick, referenceAreas } from './GraphComponents';
import { DATE_FORMAT_YEAR_MONTH_DAY } from '../../constants';

const graphAxisPaddingLR = 10;
const graphAxisPaddingTB = 0;

const graphBarRadius = 10;
const graphMaxBarSize = 10;
const graphBarFill = '#74B236';
const graphBarFillBad = '#DA547D';

export default class StepsGraphHourly extends PureComponent {
  calcYAxisParams(rawData) {
    const dataMin = 0;
    const dataMax = Math.max(...rawData.map(x => x.steps));

    const axisTick = dataMax > 4500 ? 1000 : dataMax > 1800 ? 500 : dataMax > 1000 ? 200 : 100;
    const axisMin = 0;
    const axisMax = dataMax - (dataMax % axisTick) + axisTick;
    const axisDelta = axisMax - axisMin;

    return {
      dataMin,
      dataMax,
      axisMin,
      axisMax,
      axisTick,
      axisTicks: axisDelta / axisTick + 1,
    };
  }

  PrepareData(rawData) {
    const ret = [];
    let sum = 0;
    rawData.forEach(x => {
      ret.push({ ...x, stepsSum: sum, x: x.date.format(DATE_FORMAT_YEAR_MONTH_DAY + ' HH') + ':00:00' });
      sum += x.steps;
    });

    return { rawData: ret, finalSum: sum };
  }

  render() {
    const { graphData, date, goodDay } = this.props;

    const { rawData, finalSum } = this.PrepareData(graphData);
    const yAxisParams = this.calcYAxisParams(rawData);

    const CustomTooltip = ({ active, label }) => {
      if (active && rawData) {
        const payload = rawData;

        if (payload && payload.length > 0) {
          const p = payload.find(d => d.x === label);
          if (!p) {
            return null;
          }
          return (
            <div className="customTooltip">
              <div>
                <p className="customTooltipTitle">
                  {Strings.stepsBy}
                  <br />
                  {moment(p.date).format('lll')}
                </p>
                <div className="customTooltipDescr">{`${p.stepsSum} ${Strings.stepsLowerCase}`}</div>
              </div>
            </div>
          );
        }
      }
      return null;
    };

    return (
      <div className="graphDiv withBorder">
        <ResponsiveContainer width="100%" height={340}>
          <ComposedChart
            data={rawData}
            defaultShowTooltip={false}
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <Tooltip content={<CustomTooltip />} dataKey="y" isAnimationActive={false} />

            {/* reference areas to mimic alternate rows */}
            {referenceAreas(yAxisParams)}

            <XAxis
              type="category"
              dataKey="x"
              allowDuplicatedCategory={false}
              height={60}
              interval={0}
              name="day"
              orientation="top"
              tickLine={false}
              tickSize={0}
              tick={
                <CustomizedXAxisTickHourly
                  date={date}
                  axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
                />
              }
              axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
            />

            <YAxis
              type="number"
              dataKey="steps"
              tick={
                <CustomizedYAxisTick
                  axisMax={yAxisParams.axisMax}
                  day={date}
                  axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
                />
              }
              tickSize={0}
              width={80}
              axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
              padding={{ top: graphAxisPaddingTB, bottom: graphAxisPaddingTB }}
              domain={[yAxisParams.axisMin, yAxisParams.axisMax]}
              tickCount={yAxisParams.axisTicks}
            />

            <YAxis
              yAxisId="secondaryYAxis"
              // padding={{ top: graphAxisPaddingTB, bottom: graphAxisPaddingTB }}
              hide
              orientation="right"
            />

            <Bar
              dataKey="steps"
              tooltipType="none"
              radius={graphBarRadius}
              fillOpacity={1}
              maxBarSize={graphMaxBarSize}
              stroke={goodDay ? graphBarFill : graphBarFillBad}
              fill={goodDay ? graphBarFill : graphBarFillBad}
            />

            <Line
              dataKey="stepsSum"
              tooltipType="none"
              fillOpacity={0.4}
              stroke={goodDay ? graphBarFill : graphBarFillBad}
              fill={goodDay ? graphBarFill : graphBarFillBad}
              yAxisId="secondaryYAxis"
              strokeDasharray="2 2"
              dot={false}
              activeDot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

StepsGraphHourly.propTypes = {
  graphData: PropTypes.array,
  date: PropTypes.object,
  goodDay: PropTypes.bool,
};
