import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { actions } from './redux/actions';
import '../Widgets.scss';
import Strings from '../../../../Strings';

function Devices(props) {
  useEffect(() => {
    props.getData();
  }, [props.refreshTimestamp]);

  const { scales, bpMonitors } = props;
  return (
    <React.Fragment>
      <div className="box2colsWithVl">
        <div className="box2colsWithVl-box">
          <div className="box2colsWithVl-valueBox blue">{scales}</div>
          <div onClick={() => props.onClick('/devices/weight-scales')} className="box2colsWithVl-labelBox clickable">
            {Strings.scales}
          </div>
        </div>
        <div className="box2colsWithVl-box">
          <div className="box2colsWithVl-valueBox blue">{bpMonitors}</div>
          <div onClick={() => props.onClick('/devices/bpm')} className="box2colsWithVl-labelBox clickable">
            {Strings.bpm}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

Devices.widgetId = 'DevicesWidget';

Devices.propTypes = {
  getData: PropTypes.func,
  scales: PropTypes.any,
  bpMonitors: PropTypes.any,
  isLoading: PropTypes.any,
  onClick: PropTypes.func,
  refreshTimestamp: PropTypes.any.isRequired,
};

const mapStateToProps = state => {
  const { widgetDevicesReducer } = state.dashboardWidgets;
  return {
    scales: widgetDevicesReducer && widgetDevicesReducer?.scales,
    bpMonitors: widgetDevicesReducer && widgetDevicesReducer?.bpMonitors,
    isLoading: widgetDevicesReducer && widgetDevicesReducer?.isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  getData: () => dispatch(actions.getDevicesCount()),
  onClick: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
