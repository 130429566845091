import {
  CLEAR_ENTITIES,
  PATIENT_PORTAL_LIST_NOTIFICATIONS,
  PATIENT_PORTAL_LIST_NOTIFICATIONS_RESULT,
  PATIENT_PORTAL_NOTIFICATIONS_EXISTS,
  PATIENT_PORTAL_NOTIFICATIONS_EXISTS_RESULT,
} from 'actions/action-types';

const patientPortalNotificationsReducer = (reducer, types, actionToKey) => {
  const nextReducer = reducer(types, actionToKey);

  return (state, action) => {
    const newState = { ...nextReducer(state, action) };
    switch (action.type) {
      case PATIENT_PORTAL_LIST_NOTIFICATIONS:
        return {
          ...newState,
          loading: true,
        };
      case PATIENT_PORTAL_LIST_NOTIFICATIONS_RESULT:
        return {
          ...newState,
          loading: false,
          preparing: false,
        };
      case PATIENT_PORTAL_NOTIFICATIONS_EXISTS:
        return {
          ...state,
          isLoading: true,
        };
      case PATIENT_PORTAL_NOTIFICATIONS_EXISTS_RESULT:
        return {
          ...state,
          isLoading: false,
          hasNotifications: (action && action.response && action.response.result) || false,
        };
      case CLEAR_ENTITIES:
        if (action.entities && action.entities.indexOf('patientNotifications') !== -1) {
          return {
            ...newState,
            objects: {},
          };
        }
        return newState;
      default:
        return newState;
    }
  };
};

export default patientPortalNotificationsReducer;
