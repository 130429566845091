import { openModalAction } from 'actions/modal';
import { PageHeader } from 'components/PageHeader';
import { Switch2 } from 'components/Switch2/Switch2';

import NewConfirmationModal from 'modals/NewConfirmationModal/NewConfirmationModal';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import LoadingRenderer from '../../../components/LoadingRenderer';
import Strings from '../../../Strings';
import { InfoEsiPatientOwn } from '../../patient/ESIPatient/Info';
import { actions } from './redux/actions';
import styles from './style.module.scss';

const mapStateToProps = state => {
  return {
    profile: state.auth && state.auth.profile ? state.auth.profile : null,
    userFullname: state.auth.profile ? `${state.auth.profile.first_name} ${state.auth.profile.last_name}` : '',
  };
};

const mapDispatchToProps = dispatch => ({
  editAddress: user => dispatch(openModalAction('esi-patient-edit-address-modal', { user })),
  editDetails: user => dispatch(openModalAction('esi-patient-edit-details-modal', { user })),
  updateWeeklyReports: weekly_reports_enabled => dispatch(actions.updateWeeklyReports(weekly_reports_enabled)),
  updateScheduledReminders: scheduled_reminders => dispatch(actions.updateScheduledReminders(scheduled_reminders)),
  openConfirmModal: data => dispatch(openModalAction(NewConfirmationModal.MODAL_NAME, data)),
});
class EsiPatientSettingsPage extends PureComponent {
  onDetailsEdit = () => {
    const { profile } = this.props;

    this.props.editDetails(profile);
  };

  onAddressEdit = () => {
    const { profile } = this.props;

    this.props.editAddress(profile.address);
  };

  updateScheduledReminders(sw, checked) {
    sw.setBusy(true);
    this.props.updateScheduledReminders(checked).then(r => {
      if (r.response) {
        sw.setBusy(false);
        sw.setChecked(r.response.scheduled_reminders);
      } else {
        alert(Strings.errors.internalError); // eslint-disable-line no-alert
      }
    });
  }

  onMedicationReminderChange = (sw, checked) => {
    const { openConfirmModal } = this.props;

    openConfirmModal({
      text: checked ? Strings.scheduledRemindersAreTurningOn : Strings.scheduledRemindersAreTurningOff,
      onConfirm: () => {
        this.updateScheduledReminders(sw, checked);
      },
    });

    return false;
  };

  onWeeklyReportsChange = (sw, checked) => {
    sw.setBusy(true);

    this.props.updateWeeklyReports(checked).then(r => {
      if (r.response) {
        sw.setBusy(false);
        sw.setChecked(r.response.weekly_reports_enabled);
      } else {
        alert(Strings.errors.internalError); // eslint-disable-line no-alert
      }
    });

    return false;
  };

  render() {
    const { profile, userFullname } = this.props;

    if (!profile) {
      return <LoadingRenderer loading />;
    }

    return (
      <React.Fragment>
        <PageHeader noLeftPadding left={userFullname} />

        <div className={styles.switchContainer}>
          <div>
            <Switch2
              checked={profile.preferences.scheduled_reminders}
              onBeforeChange={this.onMedicationReminderChange}
            />
            <div className={styles.text}>{Strings.medicationReminders}</div>
          </div>

          <div></div>
        </div>

        <InfoEsiPatientOwn onDetailsEdit={this.onDetailsEdit} onAddressEdit={this.onAddressEdit} patient={profile} />
      </React.Fragment>
    );
  }
}

EsiPatientSettingsPage.propTypes = {
  editAddress: PropTypes.func,
  editDetails: PropTypes.func,
  openConfirmModal: PropTypes.func,
  profile: PropTypes.shape({
    address: PropTypes.any,
    preferences: PropTypes.shape({ scheduled_reminders: PropTypes.any }),
  }),
  updateScheduledReminders: PropTypes.func,
  updateWeeklyReports: PropTypes.func,
  userFullname: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(EsiPatientSettingsPage);
