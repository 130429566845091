import { API_CALL } from '../../../../middleware/api';

export const PLOT_DATA = 'plot-data';
export const PLOT_DATA_RESULT = `${PLOT_DATA}/result`;
export const PLOT_DATA_ERROR = `${PLOT_DATA}/error`;

export const ALERT_DEFINITION_PUT = 'alert-definition-put';
export const ALERT_DEFINITION_PUT_RESULT = `${ALERT_DEFINITION_PUT}/result`;
export const ALERT_DEFINITION_PUT_ERROR = `${ALERT_DEFINITION_PUT}/error`;

export const ALERT_DEFINITION_GET = 'alert-definition-get';
export const ALERT_DEFINITION_GET_RESULT = `${ALERT_DEFINITION_GET}/result`;
export const ALERT_DEFINITION_GET_ERROR = `${ALERT_DEFINITION_GET}/error`;

export const ALERTS_GET = 'alerts-get';
export const ALERTS_GET_RESULT = `${ALERTS_GET}/result`;
export const ALERTS_GET_ERROR = `${ALERTS_GET}/error`;

export const actions = {
  getPlotData: pageRequest => {
    const { patientId, dataType, startDate, endDate, resolution } = pageRequest;
    return {
      [API_CALL]: {
        endpoint: `/patients/${patientId}/data/${dataType}`,
        method: 'get',
        params: { startDate, endDate, resolution },
      },
      type: PLOT_DATA,
    };
  },
  setAlertDefinition: (patientId, alertDefinition) => {
    return {
      [API_CALL]: {
        endpoint: `/patients/${patientId}/alert-definition`,
        method: 'put',
        data: alertDefinition,
      },
      type: ALERT_DEFINITION_PUT,
    };
  },
  getAlertDefinition: (patientId, type) => {
    return {
      [API_CALL]: {
        endpoint: `/patients/${patientId}/alert-definition/${type}`,
        method: 'get',
      },
      type: ALERT_DEFINITION_GET,
    };
  },
  getAlerts: patientId => {
    return {
      [API_CALL]: {
        endpoint: `/patients/${patientId}/alerts`,
        method: 'get',
      },
      type: ALERTS_GET,
    };
  },
};
