import { PAGE_LIMIT } from '../../../../constants';
import { API_CALL } from '../../../../middleware/api';
import {
  PROVISIONING,
  GET_PROVISIONING_DEVICES,
  POST_PROVISIONING_DEVICES,
  PUT_PROVISIONING_DEVICES,
  DELETE_PROVISIONING_DEVICES,
  SHIPPER_CAPS_DETAILS,
  CLEAR_DATA,
  GET_IMEI_FROM_ID_MFG,
} from './constants';
const qs = require('qs');

export const actions = {
  getProvisioning: pageRequest => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: '/v1/provisioning',
        params: { ...pageRequest, limit: PAGE_LIMIT },
      },
      type: PROVISIONING,
    };
  },
  getDevices: pageRequest => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: '/provisioning/devices',
        params: { ...pageRequest, limit: PAGE_LIMIT },
      },
      type: GET_PROVISIONING_DEVICES,
    };
  },
  addDevice: deviceData => {
    return {
      [API_CALL]: {
        method: 'POST',
        endpoint: '/provisioning/devices',
        data: deviceData,
      },
      type: POST_PROVISIONING_DEVICES,
    };
  },
  updateDevice: deviceData => {
    return {
      [API_CALL]: {
        method: 'PUT',
        endpoint: '/provisioning/devices',
        data: deviceData,
      },
      type: PUT_PROVISIONING_DEVICES,
    };
  },
  deleteDevice: (deviceData, callAfterSuccess) => {
    return {
      [API_CALL]: {
        method: 'POST',
        endpoint: '/provisioning/devices/delete',
        data: deviceData,
        callAfterSuccess,
      },
      type: DELETE_PROVISIONING_DEVICES,
    };
  },
  getCapsDetails: (id, pageRequest) => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: '/esi/v1/caps/' + id,
        params: pageRequest,
      },
      type: SHIPPER_CAPS_DETAILS,
    };
  },
  clearData: () => ({
    type: CLEAR_DATA,
  }),
};
