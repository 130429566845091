import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import DashboardLayout from '../../../Dashboards/DashboardLayout';
import Strings from '../../../../Strings';
import ReadingsWidget, { WidgetTypes } from '../../../Dashboards/Widgets/ReadingsWidget';
import { TIME_FORMAT_12_LOWERCASE } from '../../../../constants';
import { isMobile } from 'react-device-detect';

export const MedicationStatsWidgetsMetadata = [
  {
    id: 'nextMed',
    name: Strings.nextMed,
    permission: '',
    group: Strings.adherence,
    defProps: { i: 'nextMed', w: 1, h: 1, minW: 1, minH: 1 },
  },
  {
    id: 'adherence',
    name: Strings.adherence,
    permission: '',
    group: Strings.adherence,
    defProps: { i: 'adherence', w: 1, h: 1, minW: 1, minH: 1 },
  },
  {
    id: 'taken',
    name: Strings.taken,
    permission: '',
    group: Strings.adherence,
    defProps: { i: 'taken', w: 1, h: 1, minW: 1, minH: 1 },
  },
  {
    id: 'missed',
    name: Strings.missed,
    permission: '',
    group: Strings.adherence,
    defProps: { i: 'missed', w: 1, h: 1, minW: 1, minH: 1 },
  },
  {
    id: 'avgTimeDelta',
    name: Strings.averageTimedelta,
    permission: '',
    group: Strings.adherence,
    defProps: { i: 'avgTimeDelta', w: 1, h: 1, minW: 1, minH: 1 },
  },
];

const L_WIDGET_COUNT = 5;
const M_WIDGET_COUNT = 3;
const S_WIDGET_COUNT = 2;

export const DefaultLayouts = {
  l: [
    { ...MedicationStatsWidgetsMetadata[0].defProps, x: 0, y: 0, w: 1, h: 1 },
    { ...MedicationStatsWidgetsMetadata[1].defProps, x: 1, y: 0, w: 1, h: 1 },
    { ...MedicationStatsWidgetsMetadata[2].defProps, x: 2, y: 0, w: 1, h: 1 },
    { ...MedicationStatsWidgetsMetadata[3].defProps, x: 3, y: 0, w: 1, h: 1 },
    { ...MedicationStatsWidgetsMetadata[4].defProps, x: 4, y: 0, w: 1, h: 1 },
  ],
  m: [
    { ...MedicationStatsWidgetsMetadata[0].defProps, x: 0, y: 0, w: 1, h: 1 },
    { ...MedicationStatsWidgetsMetadata[1].defProps, x: 1, y: 0, w: 1, h: 1 },
    { ...MedicationStatsWidgetsMetadata[2].defProps, x: 2, y: 0, w: 1, h: 1 },
    { ...MedicationStatsWidgetsMetadata[3].defProps, x: 0, y: 1, w: 1, h: 1 },
    { ...MedicationStatsWidgetsMetadata[4].defProps, x: 1, y: 1, w: 1, h: 1 },
  ],
  s: [
    { ...MedicationStatsWidgetsMetadata[0].defProps, x: 0, y: 0, w: 2, h: 1 },
    { ...MedicationStatsWidgetsMetadata[1].defProps, x: 0, y: 1, w: 1, h: 1 },
    { ...MedicationStatsWidgetsMetadata[2].defProps, x: 1, y: 1, w: 1, h: 1 },
    { ...MedicationStatsWidgetsMetadata[3].defProps, x: 0, y: 2, w: 1, h: 1 },
    { ...MedicationStatsWidgetsMetadata[4].defProps, x: 1, y: 2, w: 1, h: 1 },
  ],
};
export const DASHBOARD_ID = 'adherenceMiniDashboard';

function MedicationStats(props) {
  const { data, nextTake, medication } = props;

  const nextMedTime = () => {
    const scheduleEnded = medication.format?.end_dt && medication.format.end_dt < moment().unix();
    if (scheduleEnded) {
      return Strings.scheduleEnded;
    }

    if (medication?.scheduleType === 'as_needed') {
      return Strings.na;
    }

    if (!nextTake?.reminder_at) {
      return '?';
    }

    return moment(nextTake?.reminder_at).format(TIME_FORMAT_12_LOWERCASE);
  };
  const taken = data?.events?.filter(
    e =>
      (e.type === 'scheduled_take' && medication?.scheduleType === 'daily') ||
      (e.type === 'unscheduled_take' && medication?.scheduleType === 'as_needed'),
  )?.length;
  const missed = data?.events?.filter(e => e.type === 'missed_take')?.length;
  const adherenceWidgets = [
    {
      ...MedicationStatsWidgetsMetadata[0],
      render: (
        <ReadingsWidget
          icon="medication"
          color="orange"
          title="Next Med"
          value={nextMedTime()}
          type={WidgetTypes.colorfullWithIcon}
        />
      ),
      noPadding: true,
      noBorder: true,
    },
    {
      ...MedicationStatsWidgetsMetadata[1],
      render: (
        <ReadingsWidget
          icon="pie"
          color="orange"
          title={Strings.adherence}
          value={`${isNaN(data?.adherence) ? '-' : `${data?.adherence}%`}`}
          type={WidgetTypes.colorfullWithIcon}
        />
      ),
      noPadding: true,
      noBorder: true,
    },
    {
      ...MedicationStatsWidgetsMetadata[2],
      render: (
        <ReadingsWidget
          icon="tick"
          color="green"
          title={Strings.taken}
          value={isNaN(taken) ? '-' : taken}
          type={WidgetTypes.colorfullWithIcon}
        />
      ),
      noPadding: true,
      noBorder: true,
    },
    {
      ...MedicationStatsWidgetsMetadata[3],
      render: (
        <ReadingsWidget
          icon="alert"
          color="pink"
          title={Strings.missed}
          value={isNaN(missed) ? '-' : missed}
          type={WidgetTypes.colorfullWithIcon}
        />
      ),
      noPadding: true,
      noBorder: true,
    },
    {
      ...MedicationStatsWidgetsMetadata[4],
      render: (
        <ReadingsWidget
          icon="medication"
          color="orange"
          title={Strings.averageTimedelta}
          value={`${
            isNaN(data?.avg_time_delta) || data?.adherence === 0
              ? '-'
              : `${data?.avg_time_delta} ${Strings.unitsEnum.minutes}`
          }`}
          type={WidgetTypes.colorfullWithIcon}
        />
      ),
      noPadding: true,
      noBorder: true,
    },
  ];

  return data && medication ? (
    <DashboardLayout
      dashboardId={DASHBOARD_ID}
      widgets={adherenceWidgets}
      defaultLayouts={DefaultLayouts}
      rowHeight={83}
      breakpoints={{ l: 1350, m: 1150, s: 400 }}
      cols={{ l: L_WIDGET_COUNT, m: M_WIDGET_COUNT, s: S_WIDGET_COUNT }}
      isReadOnly
      innerMargin={isMobile ? 10 : undefined}
    />
  ) : (
    <React.Fragment />
  );
}

MedicationStats.propTypes = {
  patientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default MedicationStats;
