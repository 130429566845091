import PropTypes from 'prop-types';
import { Button } from 'components/Buttons/Button';
import { ButtonRow } from 'components/Login/ButtonRow/ButtonRow';
import { Errors } from 'components/Login/Errors/Errors';
import { Text } from 'components/Login/Text/Text';

import React, { PureComponent } from 'react';

import { BUTTON_TYPE_DARK_BLUE } from '../../../../components/Buttons/Button';
import { ESI_PHONE_NUMBER_TO_VERIFY_CAP_ID } from '../../../../constants';
import allDoneImg from '../../../../images/rx/alldone.svg';
import Strings from '../../../../Strings';
import './Step.scss';

export class Step4_ActivateYourDevice extends PureComponent {
  state = {
    errors: [],
    isCodeEmpty: true, // eslint-disable-line  react/no-unused-state
  };

  codeInput = React.createRef();

  onCodeChange = () => {
    const length = this.codeInput.current.getValue().length;
    this.setState({ isCodeEmpty: length !== 6 });
  };

  onFormSubmit = event => {
    event.preventDefault();
    window.location.reload();
  };

  setError(error) {
    this.setState(state => ({ errors: [...state.errors, error] }));
  }

  clearError() {
    this.setState({ errors: [] });
  }

  static getDerivedStateFromProps(props) {
    if (props.error) {
      return { errors: [props.error] };
    }

    return null;
  }

  render() {
    const { errors } = this.state;

    let txt;

    if (errors || errors.length !== 0) {
      txt = <Errors NoMT errors={errors} />;
    }

    return (
      <form onSubmit={this.onFormSubmit}>
        <div>{txt}</div>
        <Text>
          Activate your device by SMS. Your unique 6 digit activation code can be found printed on the label attached to
          your device. Text the code to:
        </Text>
        <b
          style={{
            color: '#0060a6',
            fontSize: '40px',
            fontWeight: '400',
          }}
        >
          {ESI_PHONE_NUMBER_TO_VERIFY_CAP_ID}
        </b>
        <img className="iconTick" src={allDoneImg} alt="all done" />
        <ButtonRow
          full={
            <Button buttonType={BUTTON_TYPE_DARK_BLUE} type="submit" style={{ margin: 0 }}>
              {Strings.next}
            </Button>
          }
        />
      </form>
    );
  }
}

Step4_ActivateYourDevice.propTypes = { onFormSubmit: PropTypes.any };
