import React from 'react';
import { PhoneInputWithLabel } from 'components/Inputs/PhoneInputWithLabel';

import { getFieldWithComponent } from './getFieldWithComponent';

const PhoneInputWithLabelField = props => {
  const { input, meta, ...rest } = props;
  const error = typeof rest.error === 'boolean' ? rest.error && meta.error : meta.touched && meta.error;

  return <PhoneInputWithLabel {...rest} error={error} {...input} />;
};

const Component = getFieldWithComponent(PhoneInputWithLabelField);

export { Component as PhoneInputWithLabelField };
