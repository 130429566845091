import { PAGE_LIMIT } from '../../../constants';
import { API_CALL } from '../../../middleware/api';

export const ALERTS_LIST = 'alert-list';
export const ALERTS_LIST_RESULT = `${ALERTS_LIST}/result`;

export const GET_ALERTS_DEFINITIONS = 'alert-definitions';
export const GET_ALERTS_DEFINITIONS_RESULT = `${GET_ALERTS_DEFINITIONS}/result`;

export const DELETE_ALERT_DEFINITION = 'delete-alert-definition';
export const DELETE_ALERT_DEFINITION_RESULT = `${DELETE_ALERT_DEFINITION}/result`;

export const DELETE_ALERT = 'delete-alert';
export const DELETE_ALERT_RESULT = `${DELETE_ALERT}/result`;

export const ALERTS_PRIORITIES_SUMMARY = 'alert-priorities-summary';
export const ALERTS_PRIORITIES_SUMMARY_RESULT = `${ALERTS_PRIORITIES_SUMMARY}/result`;

export const actions = {
  getAlerts: pageRequest => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/alerts',
      params: { ...pageRequest, limit: PAGE_LIMIT },
    },
    type: ALERTS_LIST,
  }),
  deleteAlert: alertId => ({
    [API_CALL]: {
      method: 'DELETE',
      endpoint: `/alerts/${alertId}`,
    },
    type: DELETE_ALERT,
  }),
  getAlertsDefinitions: pageRequest => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/alerts-definitions',
      params: { ...pageRequest, limit: PAGE_LIMIT },
    },
    type: GET_ALERTS_DEFINITIONS,
  }),
  deleteAlertDefinition: id => ({
    [API_CALL]: {
      method: 'DELETE',
      endpoint: `/alerts-definitions/${id}`,
    },
    type: DELETE_ALERT_DEFINITION,
  }),
  getAlertsPrioritiesSummary: () => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/alerts/priorities/summary',
    },
    type: ALERTS_PRIORITIES_SUMMARY,
  }),
};
