import merge from 'deepmerge';

export const objectsReducer = (types, actionToKey) => (state = {}, action) => {
  if (action.response && action.response.entities && action.response.entities[actionToKey(action)]) {
    const newState = merge(state, action.response.entities[actionToKey(action)]);

    Object.keys(action.response.entities[actionToKey(action)]).forEach(k => {
      const { [k]: value } = action.response.entities[actionToKey(action)];
      if (value.adherence !== undefined) {
        newState[k].adherence = { ...value.adherence };
      }
    });

    if (action.type === types.success && action.subType === 'get') {
      action.response.ids[actionToKey(action)].forEach(id => {
        newState[id].detailFetched = true;
      });
    }

    return newState;
  }
  return state;
};
