import { ESI_PATIENT_GET_CLEAR } from '../../../ESI/Patients/redux/constants';
import { ESI_PATIENT_GET, ESI_PATIENT_GET_ERROR, ESI_PATIENT_GET_RESULT } from './constants';

export const esiPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case ESI_PATIENT_GET:
      return {
        ...state,
        isLoading: true,
      };
    case ESI_PATIENT_GET_RESULT:
      return {
        ...state,
        isLoading: false,
        patient: action.response ? action.response : {},
      };

    case ESI_PATIENT_GET_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ESI_PATIENT_GET_CLEAR:
      return {
        ...state,
        patient: undefined,
      };
    default:
      return state;
  }
};
