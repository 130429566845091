import {
  ESI_VERIFY_ADMIN_CHECK_CODE,
  ESI_VERIFY_ADMIN_CHECK_CODE_ERROR,
  ESI_VERIFY_ADMIN_CHECK_CODE_RESULT,
  ESI_VERIFY_ADMIN_RESEND_CODE,
  ESI_VERIFY_ADMIN_RESEND_CODE_ERROR,
  ESI_VERIFY_ADMIN_RESEND_CODE_RESULT,
  ESI_VERIFY_ADMIN_SEND_CODE,
  ESI_VERIFY_ADMIN_SEND_CODE_ERROR,
  ESI_VERIFY_ADMIN_SEND_CODE_RESULT,
} from './constants';
import Strings from '../../../../Strings';
import { authSet } from '../../../../utils';

const ERROR_WRONG_NUMBER = 'Unrecognised Number';
const ERROR_WRONG_CODE = 'Invalid token';
const SUCCESS_MESSAGE = 'OK';

const defaultErrorMessage = {
  title: Strings.errors.internalError,
  text: '',
};

function isSuccess(response) {
  return response && response.success && response.success.code === 200 && response.success.message === SUCCESS_MESSAGE;
}

function getErrorMessage(response) {
  return response.data && response.data.error && response.data.error.message ? response.data.error.message : null;
}

export const reducer = (state = null, action) => {
  const actionType = action.type;
  let isLoading = false;
  let errorMessage;

  switch (action.type) {
    case ESI_VERIFY_ADMIN_SEND_CODE:
    case ESI_VERIFY_ADMIN_RESEND_CODE:
    case ESI_VERIFY_ADMIN_CHECK_CODE:
      isLoading = true;
      break;
    case ESI_VERIFY_ADMIN_SEND_CODE_RESULT:
    case ESI_VERIFY_ADMIN_RESEND_CODE_RESULT:
    case ESI_VERIFY_ADMIN_CHECK_CODE_RESULT:
      if (!isSuccess(action.response)) {
        errorMessage = defaultErrorMessage;
      }
      if (action.response.authentication_token) {
        if (action.response.version) {
          sessionStorage.setItem('version', action.response.version);
          sessionStorage.setItem('org', action.response?.organization?.name);
        }
        return {
          ...authSet(action.response),
          is_doctor: action.response.is_doctor,
          role: action.response.role,
          loginErrors: undefined,
          reset_password: action.response.reset_password,
          isLoading,
          errorMessage,
          actionType,
        };
      }
      if (action.response.two_factor_key) {
        return {
          ...state,
          two_factor_key: action.response.two_factor_key,
          verified: action.response.verified,
          first_name: action.response.first_name,
          isLoading,
          errorMessage,
          actionType,
        };
      }
      break;
    case ESI_VERIFY_ADMIN_SEND_CODE_ERROR:
    case ESI_VERIFY_ADMIN_RESEND_CODE_ERROR:
    case ESI_VERIFY_ADMIN_CHECK_CODE_ERROR:
      const apiErrorMessage = getErrorMessage(action.response);
      switch (apiErrorMessage) {
        case ERROR_WRONG_NUMBER:
          errorMessage = {
            title: Strings.errors.unrecognisedNumber,
            text: Strings.errors.unrecognisedNumberText,
          };
          break;
        case ERROR_WRONG_CODE:
          errorMessage = {
            title: Strings.errors.invalidCode,
            text: Strings.errors.invalidCodeText,
          };
          break;
        case null:
          errorMessage = defaultErrorMessage;
          break;
        default:
          errorMessage = {
            title: apiErrorMessage,
            text: '',
          };
          break;
      }
      break;
    default:
      return state;
  }

  return {
    ...state,
    isLoading,
    errorMessage,
    actionType,
  };
};
