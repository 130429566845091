import { API_CALL } from 'middleware/api';
import { ESI_PATIENTS_LIST, ESI_PATIENTS_LIST_CLEAR, ESI_PATIENT_GET_CLEAR } from './constants';

export const actions = {
  list: params => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/esi/v1/patients',
      params,
    },
    type: ESI_PATIENTS_LIST,
  }),
  clearList: () => ({
    type: ESI_PATIENTS_LIST_CLEAR,
  }),
  clearPatientInfo: () => ({
    type: ESI_PATIENT_GET_CLEAR,
  }),
};
