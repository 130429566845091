import { GENERAL_OVERVIEW, GENERAL_OVERVIEW_RESULT, GENERAL_OVERVIEW_ERROR } from '../actions/action-types';

const overViewsReducer = (
  state = {
    adherenceData: {},
    adherence7days: {},
    devicesUsageData: {},
    loading: true,
    preparing: true,
  },
  action,
) => {
  switch (action.type) {
    case GENERAL_OVERVIEW:
      return {
        ...state,
        loading: true,
        preparing: true,
      };

    case GENERAL_OVERVIEW_RESULT: {
      const response = action.response.entities.overview.objects;
      return {
        ...state,
        adherenceData: response.adherence,
        adherence7days: response.last7DayAdherence,
        devicesUsageData: response.smartCapData,
        totalTrialCount: response.totalTrialCount,
        totalPatientCount: response.totalPatientCount,
        loading: false,
        preparing: false,
      };
    }

    case GENERAL_OVERVIEW_ERROR: {
      return {
        ...state,
        loading: false,
        preparing: false,
      };
    }
    default:
      return state;
  }
};

export default overViewsReducer;
