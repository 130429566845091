// Enable this if needed
/* eslint-disable */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { render } from 'react-dom';

import { store, history } from './utils/store';
import Root from './containers/Root';
import styles from './style/main.scss';

/* eslint-enable */

// eslint-disable-next-line no-unused-vars
render(<Root store={store} history={history} />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}
