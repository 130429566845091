import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import PiePlot from './PiePlot';
import TimeDeltaPlot from './TimeDeltaPlot';
import TimeStampPlot from './TimeStampPlot/index';
import HistogramPlot from './HistogramPlot';
import AdherencePiePlot from './AdherencePiePlot';
import CapEvolutionPlot from './CapEvolutionPlot';

const PlotComponent = plot =>
  class extends PureComponent {
    componentDidMount() {
      // eslint-disable-next-line  react/no-find-dom-node
      this.node = ReactDOM.findDOMNode(this);
      plot.create(this.node, this.props);
      plot.update(this.node, this.props);
    }

    UNSAFE_componentWillUpdate(newProps, newState) {
      if (plot.update) {
        plot.update(this.node, newProps, newState);
      }
    }

    componentWillUnmount() {
      if (plot.destroy) {
        plot.destroy(this.node);
      }
    }

    render() {
      return <div className="Chart" />;
    }
  };

export const PiePlotComponent = PlotComponent(new PiePlot());
export const TimeDeltaPlotComponent = PlotComponent(new TimeDeltaPlot());
export const TimeStampPlotComponent = PlotComponent(new TimeStampPlot());
export const HistogramPlotComponent = PlotComponent(new HistogramPlot());
export const AdherencePiePlotComponent = PlotComponent(new AdherencePiePlot());
export const CapEvolutionPlotComponent = PlotComponent(new CapEvolutionPlot());
export const Box = ({ caption, isLoading, className = '', children }) => {
  return (
    <div className={`${className} box`}>
      {caption ? (
        <header>
          {caption === 'Temperature' ? <div>{caption} &#176;F</div> : <div className="title">{caption}</div>}
          {isLoading ? <div className="spinner" /> : null}
        </header>
      ) : null}
      {children}
    </div>
  );
};

Box.propTypes = {
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  caption: PropTypes.any,
  className: PropTypes.string,
};
