import React from 'react';
import PropTypes from 'prop-types';
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
  ReferenceArea,
  ReferenceLine,
  Area,
  ComposedChart,
  LineChart,
  BarChart,
  Bar,
  Pie,
  PieChart,
  Cell,
  Legend,
} from 'recharts';

import { CustomizedXAxisTickIrregularMinMaxOnly, CustomizedYAxisTickNumeric } from './GraphAxes';
import { DATE_FORMAT_LLLL } from '../../constants';
import {
  CONDENSED_VIEW_THRESHOLD,
  GRAPH_ROW_BK,
  GRAPH_ROW_BK_ALTERNATE,
  GRAPH_DOT_RADIUS,
  GRAPH_DOT_RADIUS_CONDENSED,
  GRAPH_ACTIVE_DOT_RADIUS,
  GRAPH_AXIS_X_HEIGHT,
  GRAPH_AXIS_X_HEIGHT_CONDENSED,
  GRAPH_AXIS_LINE_COLOR,
  GRAPH_AXIS_LINE_WIDTH,
  GRAPH_REF_LINE_COLOR,
  GRAPH_REF_LINE_DASH,
} from './GraphConstants';

import './graph.scss';
import _ from 'lodash';
import { CustomReferenceArea } from './GraphComponents';

const Y_AXIS_TOP_PADDING = 2;

function GetSeries_All(data) {
  const ret = [];
  data.forEach(category => {
    ret.push({
      x: category.x,
      y: category.values[0],
      all: category,
    });
  });

  return ret;
}

function GetSeries(data, index) {
  const ret = [];
  data.forEach(day => {
    ret.push({
      x: day.x,
      y: day.values[index],
    });
  });

  return ret;
}

export const chartTypeEnum = Object.freeze({
  line: 'line',
  area: 'area',
  stackedArea: 'stackedArea',
  bar: 'bar',
  stackedBar: 'stackedBar',
  pie: 'pie',
});

function GenericCategoryGraph(props) {
  const isStacked = props.graphType === chartTypeEnum.stackedBar || props.graphType === chartTypeEnum.stackedArea;
  const PrepareData = (graphData, dataSeries, categorySelector) => {
    const data = [];

    graphData.forEach(item => {
      const category = categorySelector(item);

      const graphEntry = {
        x: category,
        values: [],
      };

      dataSeries.forEach(ds => {
        graphEntry.values.push(ds.selector(item));
      });

      data.push(graphEntry);
    });

    return data;
  };

  const PrepareData_Stacked = (graphData, dataSeries, categorySelector) => {
    const data = [];

    graphData.forEach(item => {
      const category = categorySelector(item);

      const graphEntry = {
        x: category,
        values: [],
      };

      dataSeries.forEach(ds => {
        graphEntry.values.push(ds.selector(item));
      });

      dataSeries.forEach(ds => {
        graphEntry[ds.name] = ds.selector(item);
      });

      data.push(graphEntry);
    });

    return data;
  };

  const calcValMinMax2 = (graphData, dataSelector, useZeroAsMin) => {
    const items = graphData.map(dataSelector);
    if (useZeroAsMin) items.push(0);
    return {
      min: Math.min(...items),
      max: Math.max(...items),
    };
  };
  const calcValMinMax = (graphData, dataSeries, useZeroAsMin) => {
    let result;

    if (!isStacked) {
      dataSeries.forEach(ds => {
        const localMinMax = calcValMinMax2(graphData, ds.selector, useZeroAsMin);
        if (result === undefined) {
          result = localMinMax;
        } else {
          if (result.min > localMinMax.min) result.min = localMinMax.min;
          if (result.max < localMinMax.max) result.max = localMinMax.max;
        }
      });
    } else {
      let max = undefined;
      graphData.forEach(gd => {
        let sum = 0;
        dataSeries.forEach(ds => {
          sum += ds.selector(gd);
        });

        if (max === undefined) {
          max = sum;
        } else {
          if (max < sum) max = sum;
        }
        result = { min: 0, max };
      });
    }
    return result;
  };

  const calcTickFromDelta = valDelta => {
    const maxTicks = 12;
    const tick =
      valDelta < maxTicks
        ? 1
        : valDelta < maxTicks * 2
        ? 2
        : valDelta < maxTicks * 5
        ? 5
        : valDelta < maxTicks * 10
        ? 10
        : valDelta < maxTicks * 20
        ? 20
        : valDelta < maxTicks * 50
        ? 50
        : valDelta < maxTicks * 100
        ? 100
        : valDelta < maxTicks * 200
        ? 200
        : valDelta < maxTicks * 500
        ? 500
        : 1000;

    return tick;
  };

  const {
    graphData,
    graphType,
    hasBorder,
    forceCondensed,
    xAxisTopPosition,
    xAxisLabelsMinMaxOnly,
    yAxisWidth,
    yAxisUnit,
    tooltipTitle,
    tooltipContentLines,
    tooltipDateFormat,
    dataSeries,
    referenceLines,
    categorySelector,
    hasReferenceAreas,
  } = props;

  if (!graphData || graphData.length === 0) return <></>;

  const useZeroAsMin =
    graphType === chartTypeEnum.bar ||
    graphType === chartTypeEnum.stackedBar ||
    graphType === chartTypeEnum.area ||
    graphType === chartTypeEnum.stackedArea ||
    props.useZeroAsMin;

  const valMinMax = calcValMinMax(graphData, dataSeries, useZeroAsMin);
  const valDelta = valMinMax.max - valMinMax.min;
  const tick = calcTickFromDelta(valDelta);

  const yMin = valMinMax.min - (valMinMax.min % tick);
  const yMax = valMinMax.max - (valMinMax.max % tick) + tick;
  const yTicksCount = (yMax - yMin) / tick + 1;
  const yTicks = Array.from({ length: yTicksCount }, (_, i) => yMin + i * tick);

  let chartData = !isStacked
    ? PrepareData(graphData, dataSeries, categorySelector)
    : PrepareData_Stacked(graphData, dataSeries, categorySelector);
  const dateFormatForTooltip = tooltipDateFormat ? tooltipDateFormat : DATE_FORMAT_LLLL;
  const CustomTooltip = ({ active, payload }) => {
    //console.log(payload);
    if (active && payload && payload.length > 0) {
      if (payload.length === 1 && payload[0].payload.y !== undefined) {
        // only valid pp's
        return (
          <div className="customTooltip">
            <p className="customTooltipTitle">{tooltipTitle}</p>
            <div className="customTooltipDescr">{payload[0].payload.x}</div>
            <div className="customTooltipDescr">{payload[0].payload.y}</div>
          </div>
        );
      }

      if (payload.length > 1) {
        // only valid pp's
        return (
          <div className="customTooltip">
            <p className="customTooltipTitle">{tooltipTitle}</p>
            {payload.map(pp => (
              <div key={`customTT_${pp.name}`} className="customTooltipDescr">{`${pp.name}: ${pp.value}`}</div>
            ))}
          </div>
        );
      }

      if (payload[0].payload.values == null) return null; // only valid pp's
      // console.log(payload);
      // console.log(dataSeries);
      const sum = payload[0].payload.values.reduce((partialSum, a) => partialSum + a, 0);
      return (
        <div className="customTooltip">
          <p className="customTooltipTitle">{tooltipTitle}</p>
          <div className="customTooltipDescr">{payload[0].payload.x}</div>
          {dataSeries.map((ds, index) => (
            <div className="customTooltipDescr" key={`customTT_${ds.name}`}>
              {ds.name}: {payload[0].payload.values[index] + (yAxisUnit ? ' ' + yAxisUnit : '')}
            </div>
          ))}
          {dataSeries.length > 1 && <div className="customTooltipDescr">All: {sum}</div>}
        </div>
      );
    }
    return null;
  };

  // console.log('chartData', chartData);
  const xAxisFirstValue = chartData[0].x;
  const xAxisLastValue = chartData[chartData.length - 1].x;

  const drawXAxis = () => {
    return (
      <XAxis
        type="category"
        dataKey="x"
        allowDuplicatedCategory={false}
        height={
          chartData.length > CONDENSED_VIEW_THRESHOLD || forceCondensed || xAxisLabelsMinMaxOnly
            ? GRAPH_AXIS_X_HEIGHT_CONDENSED
            : GRAPH_AXIS_X_HEIGHT
        }
        interval={0}
        name="x"
        orientation={xAxisTopPosition ? 'top' : 'bottom'}
        tickLine={false}
        tickSize={2}
        tick={
          xAxisLabelsMinMaxOnly ? (
            <CustomizedXAxisTickIrregularMinMaxOnly
              valueFirst={xAxisFirstValue}
              valueLast={xAxisLastValue}
              isTop={xAxisTopPosition}
            />
          ) : (
            undefined
          )
        }
        padding={{
          left: 10,
          right: 10,
        }}
        axisLine={{
          stroke: GRAPH_AXIS_LINE_COLOR,
          strokeWidth: GRAPH_AXIS_LINE_WIDTH,
        }}
      />
    );
  };

  const drawYAxis = useZeroAsMin => {
    return (
      <YAxis
        type="number"
        allowDataOverflow
        //dataKey="y"
        name="yAxis"
        tick={<CustomizedYAxisTickNumeric yMin={yMin} yMax={yMax} width={yAxisWidth} tick={tick} unit={yAxisUnit} />}
        domain={[useZeroAsMin ? 0 : yMin, yMax]}
        ticks={yTicks}
        interval={0}
        tickSize={0}
        width={yAxisWidth}
        axisLine={{
          stroke: GRAPH_AXIS_LINE_COLOR,
          strokeWidth: GRAPH_AXIS_LINE_WIDTH,
        }}
        padding={{
          top: Y_AXIS_TOP_PADDING,
          bottom: xAxisTopPosition ? 5 : 2,
        }}
      />
    );
  };

  const drawTooltip = () => {
    return <Tooltip content={<CustomTooltip />} dataKey="values" />;
  };

  /* reference areas to mimic alternate rows */
  const drawAlternateRows = () => {
    return Array.from({ length: yTicksCount }, (_, i) => i).map(i => (
      <ReferenceArea
        x1={graphData[0].x}
        x2={graphData[graphData.length - 1].x}
        y1={i === 0 ? yMin : yMin + tick / 2 + (i - 1) * tick}
        y2={i !== yTicksCount - 1 ? yMin + tick / 2 + i * tick : yMin + i * tick}
        stroke={i % 2 === 0 ? GRAPH_ROW_BK : GRAPH_ROW_BK_ALTERNATE}
        fill={i % 2 === 0 ? GRAPH_ROW_BK : GRAPH_ROW_BK_ALTERNATE}
        key={`refarea${i}`}
        shape={
          <CustomReferenceArea
            dontExtend={false}
            //padding={X_AXIS_PADDING}
            padding={4}
          />
        }
      />
    ));
  };

  const drawInvisibleLineForTooltip = () => {
    const allData = GetSeries_All(chartData);
    //console.log(allData);
    return <Line tooltipType="none" name="P0" data={allData} dataKey="y" visibility="hidden" />;
  };

  const drawLineSeries = () => {
    return props.dataSeries.map((ds, index) => (
      <Line
        key={`Line_${ds.name}`}
        type="monotone"
        tooltipType="none"
        name={ds.name}
        stroke={ds.color}
        data={GetSeries(chartData, index)}
        dataKey="y"
        connectNulls
        dot={{
          r:
            chartData.length > CONDENSED_VIEW_THRESHOLD || forceCondensed
              ? GRAPH_DOT_RADIUS_CONDENSED
              : GRAPH_DOT_RADIUS,
        }}
        activeDot={{ r: GRAPH_ACTIVE_DOT_RADIUS, cursor: props.onCategoryClicked ? 'pointer' : 'default' }}
      />
    ));
  };
  const drawBarSeries = () => {
    return props.dataSeries.map((ds, index) => {
      const data = GetSeries(chartData, index);
      return (
        <Bar
          key={`Bar_${ds.name}`}
          tooltipType="none"
          name={ds.name}
          stroke={ds.color}
          fill={ds.color}
          data={data}
          dataKey="y"
          cursor={props.onCategoryClicked ? 'pointer' : 'default'}
          // dataKey={`values[${index}]`}
        >
          {data.map((entry, index) => {
            if (props.colorPalette) {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={props.colorPalette?.interpolate(index / data.length)}
                  stroke={props.colorPalette?.interpolate(index / data.length)}
                />
              );
            } else {
              return <Cell key={`cell-${index}`} />;
            }
          })}
        </Bar>
      );
    });
  };

  const drawStackedBarSeries = () => {
    return props.dataSeries.map((ds, index) => {
      return (
        <Bar
          key={`StackedBar_${ds.name}`}
          name={ds.name}
          stroke={ds.color}
          fill={ds.color}
          data={chartData}
          dataKey={ds.name}
          stackId="stackId"
          cursor={props.onCategoryClicked ? 'pointer' : 'default'}
        />
      );
    });
  };

  const drawAreaSeries = () => {
    return props.dataSeries.map((ds, index) => (
      <Area
        key={`Area_${ds.name}`}
        type="monotone"
        tooltipType="none"
        name={ds.name}
        stroke={ds.color}
        strokeWidth={2}
        fill={ds.color}
        fillOpacity="10%"
        data={GetSeries(chartData, index)}
        dataKey="y"
        connectNulls
        dot={{
          r:
            chartData.length > CONDENSED_VIEW_THRESHOLD || forceCondensed
              ? GRAPH_DOT_RADIUS_CONDENSED
              : GRAPH_DOT_RADIUS,
          fill: 'white',
          fillOpacity: 1,
          stroke: ds.color,
        }}
        activeDot={{ r: GRAPH_ACTIVE_DOT_RADIUS, cursor: props.onCategoryClicked ? 'pointer' : 'default' }}
      />
    ));
  };

  const drawStackedAreaSeries = () => {
    return props.dataSeries.map((ds, index) => {
      return (
        <Area
          key={`StackedArea_${ds.name}`}
          type="monotone"
          tooltipType="none"
          name={ds.name}
          stroke={ds.color}
          strokeWidth={2}
          fill={ds.color}
          fillOpacity="10%"
          // data={GetSeries(chartData, index)}
          // dataKey="y"
          connectNulls
          dot={{
            r:
              chartData.length > CONDENSED_VIEW_THRESHOLD || forceCondensed
                ? GRAPH_DOT_RADIUS_CONDENSED
                : GRAPH_DOT_RADIUS,
            fill: 'white',
            fillOpacity: 1,
            stroke: ds.color,
          }}
          activeDot={{ r: GRAPH_ACTIVE_DOT_RADIUS }}
          data={chartData}
          dataKey={ds.name}
          stackId="stackId"
        />
      );
    });
  };

  const drawPieSeries = () => {
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      const radius = outerRadius * 1.2;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text x={x} y={y} fill="red" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };

    const data = GetSeries(chartData, 0);
    return props.dataSeries.map((ds, i) => (
      <Pie
        key={`Pie_${ds.name}`}
        data={data}
        dataKey="y"
        fill={ds.color}
        startAngle={90}
        endAngle={-270}
        label
        onClick={nextState => {
          if (nextState?.x && props.onCategoryClicked) props.onCategoryClicked(nextState.x);
        }}
      >
        {data.map((_, index) => (
          <Cell
            key={`cell-${index}`}
            fill={props.colorPalette?.interpolate(index / data.length)}
            cursor={props.onCategoryClicked ? 'pointer' : 'default'}
          />
        ))}
      </Pie>
    ));
  };

  const drawReferenceLines = () => {
    return referenceLines.map(rl => (
      <ReferenceLine key={rl} y={rl} label="" stroke={GRAPH_REF_LINE_COLOR} strokeDasharray={GRAPH_REF_LINE_DASH} />
    ));
  };

  const drawLineChart = () => {
    // console.log('LINE CHART');
    // console.log('chartData', chartData);
    // console.log(valMinMax, valDelta, tick, yMin, yMax, yTicksCount, yTicks);

    return (
      <LineChart
        data={chartData}
        defaultShowTooltip={false}
        margin={{
          top: 5,
          right: 10,
          bottom: 5,
          left: 10,
        }}
        onClick={(nextState, _) => {
          if (nextState.activeLabel && props.onCategoryClicked) props.onCategoryClicked(nextState.activeLabel);
        }}
      >
        {drawXAxis()}
        {drawTooltip()}
        {hasReferenceAreas && drawAlternateRows()}
        {drawYAxis(useZeroAsMin)}

        {drawLineSeries()}

        {referenceLines && drawReferenceLines()}
      </LineChart>
    );
  };

  const drawBarChart = () => {
    // console.log('BAR CHART');
    // console.log('chartData', chartData);
    // console.log(valMinMax, valDelta, tick, yMin, yMax, yTicksCount, yTicks);

    return (
      <BarChart
        data={chartData}
        defaultShowTooltip={false}
        margin={{
          top: 5,
          right: 10,
          bottom: 5,
          left: 10,
        }}
        layout={graphType === chartTypeEnum.horizontalBar ? 'vertical' : 'horizontal'}
        onClick={(nextState, _) => {
          if (nextState.activeLabel && props.onCategoryClicked) props.onCategoryClicked(nextState.activeLabel);
        }}
      >
        {drawXAxis()}
        {hasReferenceAreas && drawAlternateRows()}
        {drawTooltip()}
        {drawYAxis(useZeroAsMin)}

        {drawBarSeries()}

        {referenceLines && drawReferenceLines()}
      </BarChart>
    );
  };

  const drawStackedBarChart = () => {
    // console.log('STACKED BAR CHART');
    // console.log('graphData', graphData);
    // console.log('chartData', chartData);

    return (
      <BarChart
        data={chartData}
        margin={{
          top: 5,
          right: 10,
          bottom: 5,
          left: 10,
        }}
        onClick={(nextState, _) => {
          if (nextState.activeLabel && props.onCategoryClicked) props.onCategoryClicked(nextState.activeLabel);
        }}
      >
        {hasReferenceAreas && drawAlternateRows()}

        {drawXAxis()}
        {drawYAxis(useZeroAsMin)}
        {drawTooltip()}
        {drawStackedBarSeries()}

        {referenceLines && drawReferenceLines()}
      </BarChart>
    );
  };

  const drawAreaChart = () => {
    return (
      <ComposedChart
        data={chartData}
        defaultShowTooltip={false}
        margin={{
          top: 5,
          right: 10,
          bottom: 5,
          left: 10,
        }}
        onClick={(nextState, _) => {
          if (nextState.activeLabel && props.onCategoryClicked) props.onCategoryClicked(nextState.activeLabel);
        }}
      >
        {drawXAxis()}
        {drawTooltip()}
        {hasReferenceAreas && drawAlternateRows()}
        {drawYAxis(useZeroAsMin)}

        {/* area chart specific */}
        {drawAreaSeries()}

        {referenceLines && drawReferenceLines()}
      </ComposedChart>
    );
  };

  const drawStackedAreaChart = () => {
    return (
      <ComposedChart
        data={chartData}
        defaultShowTooltip={false}
        margin={{
          top: 5,
          right: 10,
          bottom: 5,
          left: 10,
        }}
        onClick={(nextState, _) => {
          if (nextState.activeLabel && props.onCategoryClicked) props.onCategoryClicked(nextState.activeLabel);
        }}
      >
        {drawXAxis()}
        {drawTooltip()}
        {hasReferenceAreas && drawAlternateRows()}
        {drawYAxis(useZeroAsMin)}

        {drawStackedAreaSeries()}

        {referenceLines && drawReferenceLines()}
      </ComposedChart>
    );
  };

  const drawPieChart = () => {
    return (
      <PieChart
        data={chartData}
        defaultShowTooltip={false}
        margin={{
          top: 5,
          right: 10,
          bottom: 5,
          left: 10,
        }}
      >
        {drawTooltip()}
        {drawPieSeries()}

        <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
          formatter={(_, entry) => <span>{entry.payload.x}</span>}
        />
      </PieChart>
    );
  };

  if (graphType === chartTypeEnum.line) {
    return (
      <div className={hasBorder ? 'graphDiv' : 'graphDivNoBorder'}>
        <ResponsiveContainer width="100%" height="99%" debounce>
          {drawLineChart()}
        </ResponsiveContainer>
      </div>
    );
  }

  if (graphType === chartTypeEnum.bar) {
    return (
      <div className={hasBorder ? 'graphDiv' : 'graphDivNoBorder'}>
        <ResponsiveContainer width="100%" height="99%" debounce>
          {drawBarChart()}
        </ResponsiveContainer>
      </div>
    );
  }

  if (graphType === chartTypeEnum.stackedBar) {
    return (
      <div className={hasBorder ? 'graphDiv' : 'graphDivNoBorder'}>
        <ResponsiveContainer width="100%" height="99%" debounce>
          {drawStackedBarChart()}
        </ResponsiveContainer>
      </div>
    );
  }

  if (graphType === chartTypeEnum.area) {
    return (
      <div className={hasBorder ? 'graphDiv' : 'graphDivNoBorder'}>
        <ResponsiveContainer width="100%" height="99%" debounce>
          {drawAreaChart()}
        </ResponsiveContainer>
      </div>
    );
  }

  if (graphType === chartTypeEnum.stackedArea) {
    return (
      <div className={hasBorder ? 'graphDiv' : 'graphDivNoBorder'}>
        <ResponsiveContainer width="100%" height="99%" debounce>
          {drawStackedAreaChart()}
        </ResponsiveContainer>
      </div>
    );
  }

  if (graphType === chartTypeEnum.pie) {
    return (
      <div className={hasBorder ? 'graphDiv' : 'graphDivNoBorder'}>
        <ResponsiveContainer width="100%" height="99%" debounce>
          {drawPieChart()}
        </ResponsiveContainer>
      </div>
    );
  }
}

export default React.memo(
  GenericCategoryGraph,
  (prevProps, nextProps) =>
    _.isEqual(prevProps.graphData, nextProps.graphData) && _.isEqual(prevProps.dataSeries, nextProps.dataSeries),
);

GenericCategoryGraph.propTypes = {
  graphData: PropTypes.array,
  hasBorder: PropTypes.bool,
  forceCondensed: PropTypes.bool,
  xAxisTopPosition: PropTypes.bool,
  xAxisLabelsMinMaxOnly: PropTypes.bool,
  yAxisWidth: PropTypes.number,
  yAxisUnit: PropTypes.string,
  tooltipTitle: PropTypes.string,
  tooltipContentLines: PropTypes.func,
  tooltipDateFormat: PropTypes.string,
  dataSeries: PropTypes.array,
  referenceLines: PropTypes.array,
  categorySelector: PropTypes.func,
  hasReferenceAreas: PropTypes.bool,
  graphType: PropTypes.string,
  onCategoryClicked: PropTypes.func,
};

GenericCategoryGraph.defaultProps = { graphType: chartTypeEnum.line };
