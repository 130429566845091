import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';

import { push } from 'react-router-redux';

import { Modal } from '../../containers';
import Form from '../../containers/Form';
import { closeModal } from '../../actions/modal';
import { clearEntitiesAction } from '../../actions';
import styles from './EditStockModal.module.scss';
import { UPDATE_PATIENT_STOCK_ERROR, UPDATE_PATIENT_STOCK_RESULT } from '../../actions/action-types';
import { patientAction } from '../../actions/patient';
import { DATE_FORMAT_MONTH_NAME, REMINDER_DAYS, UPDATE_STOCK_REMINDER } from '../../constants';
import { Row } from './Row';
import { EditStockModalEditRow } from './EditStockModalEditRow';
import { Errors } from '../../components/Login/Errors/Errors';
import Strings from '../../Strings';
import cn from 'classnames';
import { getPillsColor } from '../../utils';

class EditStockModal extends PureComponent {
  saveBtnRef = React.createRef();
  state = {
    stock: cloneDeep(this.props.data.data),
    submitIsDisabled: true,
    errors: [],
    warning: '',
  };

  componentDidMount() {
    this.state.stock.stock = parseInt(this.state.stock.stock, 10);
    if (isNaN(this.state.stock.stock)) this.state.stock.stock = 0;
  }

  onCancel = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onCancel();
  };

  onAdd = value => {
    this.changeValue(value);
  };

  onRemove = value => {
    this.changeValue(-value);
  };

  changeValue = value => {
    const { data } = this.props;
    const { stock } = this.state;

    const maxStock =
      stock.dosePerDay *
      moment()
        .add(1, 'year')
        .diff(moment(), 'days');
    stock.stock += value;
    if (stock.stock > maxStock) {
      this.setState({ warning: Strings.patient_medications.tooMayPillsWaring + maxStock });
      stock.stock = maxStock;
    }

    if (stock.stock < 0) stock.stock = 0;

    let addDays = Math.floor(stock.stock / stock.dosePerDay);
    stock.stock_last_till = moment()
      .add(addDays, 'days')
      .unix();

    stock.remind_before_days = REMINDER_DAYS;
    const reminderLogicalDays = addDays - REMINDER_DAYS;
    // if (!stock?.reminder_at) {
    stock.reminder_at = moment()
      .add(reminderLogicalDays, 'days')
      .unix();
    // }
    if (!stock.reminder_time) {
      stock.reminder_time = UPDATE_STOCK_REMINDER;
    }

    this.setState(() => ({
      stock,
      submitIsDisabled: stock.stock === data.data.stock,
    }));
    this.forceUpdate();

    setTimeout(
      function() {
        this.saveBtnRef && this.saveBtnRef.current.focus();
      }.bind(this),
      1000,
    );
  };

  onSubmit = () => {
    const { onSubmit, onCancel, loadMedications, onNavigate } = this.props;

    const stock = cloneDeep(this.state.stock);
    const patientId = stock.patientId;
    const medicationId = stock.id;
    delete stock.dosePerDay;
    delete stock.patientId;
    delete stock.dosePerDay;
    delete stock.stock_status;
    delete stock.id;

    onSubmit(medicationId, stock).then(response => {
      if (response.type === UPDATE_PATIENT_STOCK_ERROR && response.response.data) {
        const responseData = response.response.data.error;
        const errors = [responseData.message];
        this.setState({ errors });
      }

      if (response && response.type === UPDATE_PATIENT_STOCK_RESULT) {
        onCancel();
        loadMedications(medicationId);
        onNavigate(`/patient/${patientId}/medications/${medicationId}/details`);
      }
    });
  };

  render() {
    const { onOpen, onCancel, data, ...props } = this.props;
    const { errors, stock, submitIsDisabled, warning } = this.state;
    return (
      <Modal name="edit-stock" {...props} additionalClasses={[styles.modal]} roundedCorner>
        <Form onSubmit={this.onSubmit} onCancel={onCancel}>
          <div className={styles.stock}>
            <Row title={Strings.current}>
              <input disabled value={data.data.stock} />
            </Row>

            <Row title={Strings.add}>
              <EditStockModalEditRow className={styles.plus} onApply={this.onAdd} pillsRef type="add" />
            </Row>

            <Row title={Strings.remove}>
              <EditStockModalEditRow className={styles.minus} onApply={this.onRemove} type="remove" />
            </Row>

            <div className={styles.result}>
              <div className={cn([styles.amount, styles[getPillsColor(stock.stock)]])}>{stock.stock}</div>
              <div className={styles.result_text}>
                <div className={styles.title}>{Strings.dosesRemaining}</div>
                <div className={styles.last_until}>
                  {Strings.thisWillLastUntil}
                  {stock.stock_last_till ? (
                    <span className={styles[getPillsColor(stock.stock)]}>
                      {moment.unix(stock.stock_last_till).format(DATE_FORMAT_MONTH_NAME)}
                    </span>
                  ) : (
                    <span className={styles[getPillsColor(stock.stock)]}>
                      {moment().format(DATE_FORMAT_MONTH_NAME)}
                    </span>
                  )}
                </div>
              </div>
            </div>
            {warning !== '' && <Errors errors={[warning]} />}
            {errors && <Errors errors={errors} />}

            <div className={styles.buttons}>
              <button onClick={this.onCancel} tabIndex={0} aria-label={Strings.cancel}>
                {Strings.cancel}
              </button>
              <button
                ref={this.saveBtnRef}
                className={styles.submit}
                onClick={this.onSubmit}
                disabled={submitIsDisabled}
                aria-label={Strings.save}
              >
                {Strings.save}
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onCancel: () => dispatch(closeModal('edit-stock')),
  onSubmit: (medicationId, data) =>
    dispatch(patientAction.actionUpdateStock(medicationId, data)).then(response => {
      if (response && response.type === UPDATE_PATIENT_STOCK_RESULT) {
        dispatch(closeModal('edit-stock'));
        dispatch(clearEntitiesAction(['patientNotifications']));
      }

      return response;
    }),
  onNavigate: path => dispatch(push(path)),
  loadMedications: medicationId => dispatch(patientAction.actionMedicationDetails(medicationId)),
});

export default connect(null, mapDispatchToProps)(EditStockModal);
