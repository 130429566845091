import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { openModalAction } from '../../../actions/modal';
import { ActionButton, ActionWidget, ItemSelectorHelper } from '../../../components/ActionWidget';
import { PageHeader } from '../../../components/PageHeader';
import { Button } from '../../../components/PageHeader/Button';
import { TextInput } from '../../../components/PageHeader/TextInput';
import { DATE_MONTH_DAY_YEAR, PAGE_LIMIT, TIME_FORMAT_12_UPPERCASE } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import './messages.scss';
import { actions } from './redux/actions';
class MessageArchived extends PureComponent {
  pageRequest;
  refreshEnable = true;

  state = {
    openActionWidget: false,
    keyForItemCheckboxes: false,
  };

  itemSelectorHelper = new ItemSelectorHelper();

  resetActionWidget = () => {
    this.setState({
      keyForItemCheckboxes: !this.state.keyForItemCheckboxes,
      openActionWidget: false,
    });
    this.itemSelectorHelper.clearItems();
  };

  itemChecked = ({ target }) => {
    if (target.checked === true) {
      this.itemSelectorHelper.addItem(target.id);
    } else {
      this.itemSelectorHelper.removeItem(target.id);
    }

    if (this.itemSelectorHelper.getItems().length !== 0) {
      this.setState({ openActionWidget: true });
    } else {
      this.setState({ openActionWidget: false });
    }
  };

  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  onSearchQueryChange = query => {
    return this.onSearchQueryChangeDebounced(query);
  };

  onSearchQueryChangeDebounced = _.debounce(query => {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    this.props.getArchived(this.pageRequest);
  }, 1000);

  getSecondHeaderComponents() {
    return (
      <React.Fragment>
        <TextInput className="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        <Button class="refresh" disabled={!this.refreshEnable} onClick={this.onRefresh} />
      </React.Fragment>
    );
  }

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 30000);
    this.props.getArchived(this.pageRequest);
    this.resetActionWidget();
  };

  componentDidMount() {
    this.pageRequest = {
      offset: 0,
      search: '',
      limit: PAGE_LIMIT,
    };
    this.props.getArchived(this.pageRequest);
  }

  onPrevClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset - PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getArchived(this.pageRequest);
    this.resetActionWidget();
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.getArchived(this.pageRequest);
    this.resetActionWidget();
  };

  onNextClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset + PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getArchived(this.pageRequest);
    this.resetActionWidget();
  };

  onSortClick = ({ sortKey, direction }) => {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    this.props.getArchived(this.pageRequest);
    this.resetActionWidget();
  };

  onArchiveToggle = (event, message_ids) => {
    event.preventDefault();
    event.stopPropagation();

    this.resetActionWidget();

    message_ids.forEach(e => {
      const data = { id: parseInt(e, 10), value: false };

      this.props.toggleArchieved(data).then(() => {
        this.props.getArchived(this.pageRequest);
        this.resetActionWidget();
      });
    });
  };

  onReadToggle = (event, users_ids) => {
    event.preventDefault();
    event.stopPropagation();

    this.resetActionWidget();
    users_ids.forEach(e => {
      const id = parseInt(e, 10);
      const message = this.props.messages.find(m => m.userId === id);
      if (message) {
        const readFlag = message.read;
        const data = { id, value: !readFlag };

        this.props.toggleRead(data).then(() => {
          this.props.getArchived(this.pageRequest);
          this.resetActionWidget();
        });
      }
    });
  };

  onRowSelected = id => {
    const { messages } = this.props;
    this.props.onNavigate(`/messages/inbox/${messages[id].userId}`);
  };

  render() {
    const { messages, pagination, isLoading } = this.props;

    const columns = [];

    columns.push(
      <Column
        key="name"
        title={Strings.messages.number}
        value={d => (
          <React.Fragment>
            <div className="cell-with-select">
              <div className="selector">
                <input
                  type="checkbox"
                  className="item-checkbox"
                  id={d.userId}
                  onChange={this.itemChecked}
                  key={this.state.keyForItemCheckboxes}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                />
              </div>
              <div className="selector-label">
                {d.mobileNumber ? d.mobileNumber : '-'} {!d.read ? <div className="unread-message"></div> : ''}
              </div>
            </div>
          </React.Fragment>
        )}
      />,
    );
    columns.push(<Column key="sendername" title={Strings.messages.name} value={d => `${d.firstName} ${d.lastName}`} />);
    columns.push(
      <Column
        key="conversation"
        title={Strings.messages.conversation}
        value={d => (d.lastMessage ? d.lastMessage : '-')}
      />,
    );

    columns.push(
      <Column
        key="date"
        title={Strings.messages.date}
        value={d => (d.lastMessageTime ? moment(d.lastMessageTime).format(DATE_MONTH_DAY_YEAR) : '-')}
      />,
    );

    columns.push(
      <Column
        key="time"
        title={Strings.messages.time}
        value={d => (d.lastMessageTime ? moment(d.lastMessageTime).format(TIME_FORMAT_12_UPPERCASE) : '-')}
      />,
    );

    return (
      <div className="billing-container">
        <PageHeader right={() => this.getSecondHeaderComponents()} />
        <Table
          isLoading={isLoading}
          name="task-table"
          uuid="b8a20150-4524-4b3d-b5af-0b2f3bfb2910"
          data={messages || []}
          onRowSelection={this.onRowSelected}
          onPrevClick={this.onPrevClick}
          onSortClick={this.onSortClick}
          onNextClick={this.onNextClick}
          onCustomPage={this.onCustomPage}
          pagination={
            pagination || {
              offset: 0,
              total: 0,
            }
          }
          enableColumnFiltering
        >
          {columns}
        </Table>
        <ActionWidget show={this.state.openActionWidget}>
          <ActionButton
            img="action-archive"
            tooltiptext={Strings.messages.unarchive}
            text={Strings.messages.unarchive}
            action={e => {
              this.onArchiveToggle(e, this.itemSelectorHelper.getItems());
            }}
          />
          <ActionButton
            img="action-unread"
            tooltiptext={Strings.messages.unread}
            text={Strings.messages.unread}
            action={e => {
              this.onReadToggle(e, this.itemSelectorHelper.getItems());
            }}
          />
        </ActionWidget>
      </div>
    );
  }
}

MessageArchived.propTypes = {
  getArchived: PropTypes.func,
  isLoading: PropTypes.any,
  messages: PropTypes.array,
  onNavigate: PropTypes.func,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
  toggleArchieved: PropTypes.func,
  toggleRead: PropTypes.func,
};

const mapStateToProps = state => {
  const { messages } = state.superUser;
  return {
    messages: messages?.messagesArchived,
    pagination: messages?.pagination,
    isLoading: messages?.isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getArchived: pageRequest => dispatch(actions.getArchivedMessages(pageRequest)),
  toggleArchieved: data => dispatch(actions.archiveToggle(data)),
  toggleRead: data => dispatch(actions.readToggle(data)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageArchived);
