import React, { PureComponent } from 'react';

import styles from './Text.module.scss';

export class Text extends PureComponent {
  render() {
    const { children, ...props } = this.props;

    return (
      <div {...props} className={styles.Text}>
        {children}
      </div>
    );
  }
}
