import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes, { string } from 'prop-types';
import moment from 'moment-timezone';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { truncate } from 'lodash';

import Strings from '../../../../Strings';
import { patientAction } from '../../../../actions/patient';
import { PageHeader } from '../../../../components/PageHeader';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../../components/PageHeader/Button';
import { Column } from '../../../../containers/Table/TableWithPagination';
import TableWithLocalPagination from '../../../../containers/Table/TableWithLocalPagination';
import { DATE_FORMAT_YEAR_MONTH_DAY, DATE_MONTH_DAY_YEAR_WITH_DASH, PAGE_LIMIT } from '../../../../constants';
import './HistoryTab.scss';
import { LIST_PATIENT, GET_PATIENT_MEDICATION_HISTORY_RESULT } from '../../../../actions/action-types';
import { openModalAction } from '../../../../actions/modal';
import Select from '../../../../components/Select/SelectOld';
import { SELECT_TYPE_RIGHT } from '../../../../components/Inputs/SelectInput';
import SliderPicker from '../../../../components/SliderPicker';
import { toolTipForTable } from '../../../../utils';
import { isOrgNameESI } from '../../../../utils/portalType';

const all = 'all';
const MAX_NAME_DISPLAY_LEN = 120;

const today = moment()
  .endOf('day')
  .toDate();
const yearAgo = moment()
  .subtract(1, 'year')
  .startOf('day')
  .toDate();

function HistoryTab(props) {
  const minDate = props.minDate || yearAgo;
  const maxDate = props.maxDate || today;

  const [refreshEnable, setRefreshEnable] = useState(true);
  const [selectedScheduleId, setSelectedScheduleId] = useState(all);
  const [selectedStatus, setSelectedStatus] = useState(all);
  const [events, setEvents] = useState([]);
  const [scheduleMapping, setScheduleMapping] = useState({});
  const [adherence, setAdherence] = useState('');
  const [missed, setMissed] = useState('-');
  const [taken, setTaken] = useState('-');
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [performance, setPerformance] = useState('poor');
  const patientId = useMemo(() => (props.isDoctor ? props.patientId : props.userId), [
    props.isDoctor,
    props.patientId,
    props.userId,
  ]);
  const pageRequest = useRef({
    offset: 0,
    limit: 10,
  });

  const setDefaultStatsValues = () => {
    setAdherence('');
    setTaken('-');
    setMissed('-');
  };

  const getData = () => {
    setIsLoading(true);
    setDefaultStatsValues();
    props.getMedicationsHistory(pageRequest.current, patientId).then(resp => {
      if (resp.type == GET_PATIENT_MEDICATION_HISTORY_RESULT) {
        const { response } = resp;
        const tscheduleMapping = [];
        response.scheduleMapping.forEach(e => {
          if (!tscheduleMapping.some(t => t.value === e.scheduleRootUuid)) {
            tscheduleMapping.push({
              value: e.scheduleRootUuid,
              text: truncate(e.name, MAX_NAME_DISPLAY_LEN),
            });
          }
        });
        setEvents(response.events || []);
        setScheduleMapping(tscheduleMapping);
        setAdherence(response.stats.adherence);
        setMissed(response.stats.missed);
        setTaken(response.stats.complied);
        setPerformance(response.stats.performance);
        setPagination(response.pagination);
        pageRequest.current.offset = response.pagination.offset;
      }
      setIsLoading(false);
    });
  };

  const clearPageRequest = () => {
    pageRequest.current = {
      pagination: {},
      offset: 0,
      search: '',
      status: all,
    };
  };

  useEffect(() => {
    clearPageRequest();
  }, [props.patientId, props.userId, props.isDoctor]);

  useEffect(() => {
    if (!props.dateFilter.infimum || !props.dateFilter.supremum) {
      return;
    }
    if (
      pageRequest.current.startDate !== props.dateFilter.infimum ||
      pageRequest.current.endDate !== props.dateFilter.supremum
    ) {
      pageRequest.current.startDate = props.dateFilter.infimum;
      pageRequest.current.endDate = props.dateFilter.supremum;
      getData();
    }
  }, [props.dateFilter]);

  const onRefresh = () => {
    getData();
    setRefreshEnable(false);
    setTimeout(() => {
      setRefreshEnable(true);
    }, 30000);
  };

  const onSelectHistoryFilter = filter => {
    pageRequest.current.offset = 0;
    pageRequest.current.status = filter;
    getData();
  };

  const onMedicineStatusChange = value => {
    setSelectedStatus(value);
    onSelectHistoryFilter(value);
  };

  const onScheduleChange = value => {
    setSelectedScheduleId(value);
    pageRequest.current.scheduleRootId = value;
    getData();
  };
  const onDownloadHistory = () => {
    const { isDoctor } = props;
    const filteredDate = {
      history: events.length,
      startDate: pageRequest.current.startDate,
      endDate: pageRequest.current.endDate,
      status: selectedStatus,
      scheduleId: selectedScheduleId,
    };
    if (isDoctor) filteredDate.patientId = props.patientId;
    props.download(filteredDate);
  };

  const { isDoctor } = props;

  const medicineStatusFilter = (
    <React.Fragment>
      Status -
      <Select
        data={[
          {
            value: all,
            text: Strings.patientDashboard.all,
          },
          {
            value: 'missed',
            text: Strings.missed,
          },
          {
            value: 'taken',
            text: Strings.taken,
          },
        ]}
        type={SELECT_TYPE_RIGHT}
        classes={[Select.CLASS_WHITE_BLUE, Select.CLASS_BIG]}
        value={selectedStatus}
        autoWidth
        onChange={onMedicineStatusChange}
        style={{
          marginLeft: 0,
        }}
        tabIndex={0}
        aria-label="Please select status"
      />
    </React.Fragment>
  );

  const capInfo = (
    <div className="infoBox history-view">
      <div className={`total-bubble ${performance}`}>{adherence} %</div>
      <div className="nickName">{Strings.accuracy}</div>
      <div className="vl" />
      {Strings.missed}: {missed}
      <div className="vl" />
      {Strings.taken}: {taken}
      {isLoading ? (
        <div className="table-row-spinner adherence">
          <div className="spinner-row " />
        </div>
      ) : (
        ''
      )}
      <div className="right-select ">{medicineStatusFilter}</div>
    </div>
  );

  const getTakeStatusWithTooltip = entry => {
    const { status } = entry;
    const divProps = {
      'data-tooltip-content': status,
      'data-tooltip-id': 'tooltip',
    };

    return <span className={status} {...divProps} />;
  };

  const takenDateTime = event => {
    const { status, scheduleId } = event;
    const divProps = {
      'data-tip': true,
      'data-tooltip-id': `tt-taken-time-${scheduleId}`,
    };

    return (
      <React.Fragment>
        <span className={status} {...divProps}>
          {event.takenTime &&
          moment(event.takenTime).format(DATE_FORMAT_YEAR_MONTH_DAY) !==
            moment(event.timestamp).format(DATE_FORMAT_YEAR_MONTH_DAY)
            ? moment(event.takenTime).format(DATE_MONTH_DAY_YEAR_WITH_DASH)
            : event.takenTime
            ? moment(event.takenTime).format('hh:mm A')
            : status == 'missed'
            ? Strings.missed
            : status == 'ready'
            ? 'Ready'
            : '-'}
        </span>
      </React.Fragment>
    );
  };

  const patientPageHeader = (
    <React.Fragment>
      <PageHeader
        noLeftPadding
        left={
          <React.Fragment>
            <Button
              class={HEADER_BUTTON_DARK_BLUE}
              onClick={onDownloadHistory}
              title={Strings.downloadHistory}
              first
              tabIndex={0}
            />
            <button
              className="refresh_button"
              style={{ marginLeft: '15px' }}
              disabled={!refreshEnable}
              onClick={onRefresh}
              aria-label="Refresh the data"
            />
          </React.Fragment>
        }
        right={
          <Select
            data={[{ value: '', text: Strings.patientDashboard.allMeds }].concat(scheduleMapping)}
            type={SELECT_TYPE_RIGHT}
            classes={[
              Select.CLASS_BIG,
              Select.CLASS_DARK,
              Select.CLASS_NO_MARGIN_RIGHT,
              Select.CLASS_NO_BORDER,
              Select.CLASS_MAX_WIDTH_600,
            ]}
            value={selectedScheduleId}
            autoWidth
            onChange={onScheduleChange}
            tabIndex={0}
            aria-label="select medicine from list"
            id="medicineSelect"
          />
        }
      />
      <SliderPicker min={minDate} max={maxDate} readOnly={isLoading} />
      {capInfo}
    </React.Fragment>
  );

  const doctorPageHeader = (
    <React.Fragment>
      <PageHeader
        right={
          <React.Fragment>
            <Button
              class={HEADER_BUTTON_DARK_BLUE}
              onClick={onDownloadHistory}
              title={Strings.downloadHistory}
              first
              tabIndex={0}
            />
            <Button class={isOrgNameESI() ? 'refreshTop' : 'refresh'} disabled={!refreshEnable} onClick={onRefresh} />
          </React.Fragment>
        }
      />
      <SliderPicker min={minDate} max={maxDate} readOnly={isLoading} />
      <div className="adherence-percent-patient doctor">
        {Strings.accuracy}:{' '}
        {!isLoading ? (
          <span>{adherence}%</span>
        ) : (
          <div className="table-row-spinner adherence">
            <div className="spinner-row " />
          </div>
        )}
      </div>
    </React.Fragment>
  );

  const table = (
    <TableWithLocalPagination
      name="patientMedicationHistoryTable"
      data={!isLoading ? events : []}
      pagination={pagination}
      isLoading={isLoading}
      freezeFirstColumn={false}
      infiniteScroll
      columns={[
        <Column
          key="timestamp"
          title={Strings.scheduledDate}
          value={d => toolTipForTable(moment(d.timestamp).format('MMM DD'), moment(d.timestamp).format('YYYY MMM DD'))}
          sortKey="timestamp"
        />,
        <Column key="status" title={Strings.status} value={getTakeStatusWithTooltip} sortKey="status" />,
        <Column
          key="time"
          title={Strings.timeDueText}
          className={d => `${d.status}-colour`}
          value={d => (d.scheduleType !== 'as_needed' ? moment(d.timestamp).format('hh:mm A') : Strings.asNeeded)}
        />,
        <Column key="takeEventTime" title={Strings.timeTaken} value={takenDateTime} />,
        <Column key="name" title={Strings.medicationNameText} value={d => d.name} sortKey="name" />,
      ]}
    />
  );

  return (
    <React.Fragment>
      {isDoctor ? doctorPageHeader : patientPageHeader}
      {table}
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  let userId = '';
  const isDoctor = state.auth.role === 'doctor';
  if (!isDoctor) {
    userId = state.auth.id;
  }
  if (state.auth.role === 'patient') {
    userId = state.auth.profile.id;
  }
  return {
    userId,
    isDoctor,
    username: state.auth?.profile.username,
    dateFilter: state.filter.dateFilter,
  };
};

const mapDispatchToProps = dispatch => ({
  getEnrollmentDate: params => dispatch(patientAction.getEnrollmentDate(params)),
  getMedicationsHistory: (pageRequest, patientId) =>
    dispatch(patientAction.getMedicationsHistory(pageRequest, patientId)),
  onNavigate: path => dispatch(push(path)),
  download: filteredDate =>
    dispatch(
      openModalAction('download-history', {
        action: patientAction.actionList,
        actionType: LIST_PATIENT,
        data: filteredDate,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTab);

HistoryTab.propTypes = {
  patientId: PropTypes.string,
  minDate: string,
  maxDate: string,
  username: PropTypes.string,
  isDoctor: PropTypes.bool.isRequired,
  getMedicationsHistory: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
  getEnrollmentDate: PropTypes.func.isRequired,
  timezone: PropTypes.string,
  userId: PropTypes.string,
};
