import moment from 'moment-timezone';

// implementation of
// Ramer-Douglas-Peucker simplification algorithm
//  It is an algorithm that decimates a curve composed of line segments to a similar curve with FEWER POINTS
//
// FROM: https://github.com/geonome/simplify2-js

function _appendIfNotSame(points, point) {
  var p = points[points.length - 1];
  if (p.x != point.x || p.y != point.y) {
    points.push(point);
  }
}

function _simplifyRadialDist(points, simplifiedPoints, tolerance2) {
  var length = points.length,
    i,
    pl = points[0],
    pi,
    dx,
    dy,
    r;

  simplifiedPoints.push(pl);
  for (i = 1; i < length; i++) {
    pi = points[i];

    dx = pl.x - pi.x;
    r = tolerance2 - dx * dx;
    if (r >= 0) {
      dy = pl.y - pi.y;
      if (r - dy * dy < 0) {
        simplifiedPoints.push(pi);
        pl = pi;
      }
    } else {
      simplifiedPoints.push(pi);
      pl = pi;
    }
  }

  _appendIfNotSame(simplifiedPoints, pi);

  return simplifiedPoints;
}

function _simplifyDouglasPeucker(points, simplifiedPoints, tolerance2, t0, t1) {
  var maxD = tolerance2,
    p0 = points[t0],
    p1 = points[t1],
    x0 = p0.x,
    y0 = p0.y,
    dx = p1.x - x0,
    dy = p1.y - y0,
    d2,
    id2,
    maxIdx,
    d,
    p,
    i,
    s;

  if (dx === 0 && dy === 0) {
    if (t1 > t0 + 1) {
      var m = (t0 + t1) >> 1;
      _simplifyDouglasPeucker(points, simplifiedPoints, tolerance2, t0, m);
      _appendIfNotSame(simplifiedPoints, points[m]);
      _simplifyDouglasPeucker(points, simplifiedPoints, tolerance2, m, t1);
    }
    return;
  }

  d2 = dx * dx + dy * dy;
  id2 = 1 / d2;
  for (i = t0; i <= t1; i++) {
    p = points[i];
    s = dx * (y0 - p.y) + dy * (p.x - x0);
    d = s * s * id2;

    if (d > maxD) {
      maxIdx = i;
      maxD = d;
    }
  }

  if (maxD > tolerance2) {
    _simplifyDouglasPeucker(points, simplifiedPoints, tolerance2, t0, maxIdx);
    simplifiedPoints.push(points[maxIdx]);
    _simplifyDouglasPeucker(points, simplifiedPoints, tolerance2, maxIdx, t1);
  }
}

/**
 * Simplifies the input points by only picking the ones separated by the specified tolerance distance.
 *
 * @param points the input points.
 * @param tolerance the tolerance distance.
 * @returns {[]} a new array holding the simplified points.
 */
export function radialDistance(points, tolerance) {
  return points && points.length > 1 && tolerance > 0 ? _simplifyRadialDist(points, [], tolerance * tolerance) : points;
}

/**
 * Simplifies the input points using the Ramer-Douglas-Peucker algorithm using the specified tolerance distance.
 *
 * @param points the input points.
 * @param tolerance the tolerance distance.
 * @returns {[]} a new array holding the simplified points.
 */
export function douglasPeucker(points, tolerance) {
  if (points && points.length > 1 && tolerance > 0) {
    var sPoints = [];
    sPoints.push(points[0]);
    _simplifyDouglasPeucker(points, sPoints, tolerance * tolerance, 0, points.length - 1);
    _appendIfNotSame(sPoints, points[points.length - 1]);
    return sPoints;
  }
  return points;
}

/**
 * Simplifies the input points first using a radial distance simplification followed by a
 * douglas-peucker simplification.
 *
 * @param points the input points.
 * @param tolerance the tolerance distance.
 * @returns {[]} a new array holding the simplified points.
 */
export function radialDistanceAndDouglasPeucker(points, tolerance) {
  return this.douglasPeucker(this.radialDistance(points, tolerance), tolerance);
}

export const prepareSimplifiedDataForDataProvider = data => {
  return prepareSimplifiedData(data, 1000);
};

export const prepareSimplifiedDataForChart = data => {
  return prepareSimplifiedData(data, 250);
};

const prepareSimplifiedData = (data, ELEMENTS_IDEAL) => {
  const ELEMENTS_IDEAL_MARGIN = ELEMENTS_IDEAL * 0.1;
  const MAX_TOLERANCE_DIVIDER = 1000;

  let count = data.length;

  const dataForDouglasPeucker = data.map(i => ({ x: i.dt.unix() / 300, y: i.value }));
  if (count <= ELEMENTS_IDEAL) return data;

  const min = Math.min(...dataForDouglasPeucker.map(i => i.y));
  const max = Math.max(...dataForDouglasPeucker.map(i => i.y));
  const delta = max - min;
  const minTick = delta / MAX_TOLERANCE_DIVIDER;

  let lower = minTick;
  let upper = delta;
  let prevCount = 0;

  // console.log(count, min, max, delta, minTick, lower, upper);

  let newData = [];
  let tolerance = lower + (upper - lower) / 2;
  do {
    newData = douglasPeucker(dataForDouglasPeucker, tolerance);

    // console.log('try tolerance: ', tolerance, ' result elements: ', newData.length);

    prevCount = count;
    count = newData.length;

    if (count <= ELEMENTS_IDEAL) {
      upper = upper - (upper - lower) / 2;
    } else {
      lower = lower + (upper - lower) / 2;
    }
    tolerance = lower + (upper - lower) / 2;
  } while (upper - lower >= minTick && Math.abs(count - ELEMENTS_IDEAL) > ELEMENTS_IDEAL_MARGIN && upper !== lower);

  // console.log('Data length:', newData.length, tolerance);

  return newData.filter(d => !isNaN(d.y)).map(i => ({ dt: moment(i.x * 300 * 1000), value: i.y }));
};

export const normalizePair = (min1, max1, val1_1, val1_2, min2, max2) => {
  let val2_1 = min2;
  let val2_2 = max2;

  if (val1_1 <= min1) val2_1 = min2;
  else if (val1_1 >= max1) val2_1 = max2;
  else {
    const ratio = (val1_1 - min1) / (max1 - min1);
    val2_1 = min2 + Math.round((max2 - min2) * ratio);
  }

  if (val1_2 <= min1) val2_2 = min2;
  else if (val1_2 >= max1) val2_2 = max2;
  else {
    const ratio = (val1_2 - min1) / (max1 - min1);
    val2_2 = min2 + Math.round((max2 - min2) * ratio);
  }

  return { v1: val2_1, v2: val2_2 };
};
