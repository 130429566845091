import _ from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { clearEntitiesAction } from '../actions';
import { SEND_INVITE, SEND_INVITE_RESULT } from '../actions/action-types';
import invitesAction from '../actions/invites';
import { closeModal, openModalAction } from '../actions/modal';
import { patientAction } from '../actions/patient';
import { PageHeader } from '../components/PageHeader';
import { Button } from '../components/PageHeader/Button';
import { TextInput } from '../components/PageHeader/TextInput';
import withSliderPicker from '../components/_hocs/withSlidePicker';
import { HEADER_BUTTON_BLUE, PAGE_LIMIT } from '../constants';
import Table, { Column } from '../containers/Table/TableWithPagination';
import Strings from '../Strings';
import { isESIDoctor } from '../utils/userRoles';

const getAdherencePercentage = patient => {
  if (patient.analytics && patient.analytics) {
    return `${patient.analytics.adherence}%`;
  }
  return 'N/A';
};
class MyPatients extends PureComponent {
  loaded = false;

  static defaultProps = {
    patients: {},
    trials: {},
    schedules: {},
    memberships: {},
  };

  constructor(props) {
    super(props);
    this.searchAPICallTimer = null;
    this.state = { email: '' };
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
  }

  componentDidMount() {
    const { readOnlyMode, onNewPatient } = this.props;

    const dateRange = this.getDateRange();
    this.pageRequest = {
      startDate: dateRange.infimum,
      endDate: dateRange.supremum,
      offset: 0,
      search: '',
    };
    this.props.clearEntities();
    this.props.loadPatients(this.pageRequest, true).then(r => {
      if (r.response.patients.length === 0 && !readOnlyMode) {
        onNewPatient();
      }
    });
    this.onRowSelected = this.onRowSelected.bind(this);
    this.onSearchQueryChange = _.debounce(this.onSearchQueryChange.bind(this));
    this.loaded = true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.dateFilter !== nextProps.dateFilter || this.props.isUpdate) {
      this.onDateRangeChanged(nextProps.dateFilter);
    }
  }

  onPatientDelete = (event, patient) => {
    event.preventDefault();
    event.stopPropagation();
    const data = {
      title: (
        <span>
          {Strings.deletePatientWarning} <b>{patient.fullName}</b>?
        </span>
      ),
      onConfirmAction: patientAction.actionDeletePatient(0, patient.code),
      onCancelAction: null,
    };
    this.props.openConfirmModal(data);
  };

  onSearchQueryChange(query) {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    clearTimeout(this.searchAPICallTimer);
    this.searchAPICallTimer = setTimeout(() => {
      this.props.loadPatients(this.pageRequest, true);
    }, 1000);
  }

  onRowSelected(id) {
    const { patients } = this.props;
    if (patients && patients[id].id && patients[id].status) {
      this.props.onNavigate(`/patient/${patients[id].id}/overview`);
    }
  }

  onPatientInvite = () => {
    const { email } = this.state;
    this.props.sendInvitation({ email }).then(response => {
      if (response.type === SEND_INVITE_RESULT) {
        this.props.loadPatients(this.pageRequest, true);
      }
      return response;
    });
  };

  onDateRangeChanged = range => {
    const offset = this.props.pagination.offset;
    const { infimum, supremum } = range;
    this.pageRequest.startDate = infimum;
    this.pageRequest.endDate = supremum;
    this.pageRequest.offset = offset;
    this.props.loadPatients(this.pageRequest, true);
  };

  onPrevClick() {
    const { pagination } = this.props;
    const offset = pagination.offset - PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.loadPatients(this.pageRequest, true);
  }

  onNextClick() {
    const { pagination } = this.props;
    const offset = pagination.offset + PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.loadPatients(this.pageRequest, true);
  }

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.loadPatients(this.pageRequest, true);
  };

  onSortClick({ sortKey, direction }) {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    this.props.loadPatients(this.pageRequest, false);
  }

  onChange = event => {
    this.setState({ [event.target.getAttribute('name')]: event.target.value });
  };

  getDateRange() {
    const dateRange = this.props.dateFilter
      ? this.props.dateFilter
      : {
          infimum: moment()
            .subtract(1, 'month')
            .toDate(),
          supremum: moment().toDate(),
        };
    return dateRange;
  }

  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  getHeaderComponents() {
    const { readOnlyMode, onNewPatient } = this.props;

    return (
      <React.Fragment>
        <TextInput class="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        {!readOnlyMode && (
          <Button
            class={HEADER_BUTTON_BLUE}
            onClick={onNewPatient}
            title={isESIDoctor() ? Strings.addPatient : Strings.invitePatient}
          />
        )}
      </React.Fragment>
    );
  }

  isPatientsExists() {
    return Array.isArray(this.props.patients) && this.props.patients && this.props.patients.length !== 0;
  }

  render() {
    const { patients = [], isLoading, isPreparing, pagination } = this.props;
    const defaultOrder = {
      key: 'username',
      sortKey: 'username',
      direction: 'ascending',
    };

    const getAdherenceLine = adherence => {
      const average = getAdherencePercentage(adherence);
      const perfectHundredStyle = average === '100%' ? 'perfect' : 'active';
      const averageWidthStyle = { width: average };
      return (
        <div>
          <div className="averageText">{average}</div>
          <div className="average">
            <div className={perfectHundredStyle} style={averageWidthStyle} />
          </div>
        </div>
      );
    };

    const isApprovedByPatient = patient => {
      const { status } = patient;
      const divProps = {
        'data-tooltip-content': !status ? Strings.pending : Strings.verified,
        'data-tooltip-id': 'tooltip',
      };
      return <div className="approval-status un-accepted" {...divProps} />;
    };

    return (
      <React.Fragment>
        <PageHeader getHeaderComponents={() => this.getHeaderComponents()} />
        <Table
          name="invited_patients"
          data={patients}
          onPrevClick={this.onPrevClick}
          onSortClick={this.onSortClick}
          onNextClick={this.onNextClick}
          onCustomPage={this.onCustomPage}
          order={defaultOrder}
          onRowSelection={this.onRowSelected}
          pagination={pagination}
          isLoading={isLoading || isPreparing}
          tableName="patient-table"
        >
          <Column key="username" title={Strings.userName} value={d => d.username} sortKey="username" />
          <Column key="email" title={Strings.email} value={d => d.email || '-'} sortKey="email" />
          <Column key="adherence" title={Strings.adherence} value={d => getAdherenceLine(d)} sortKey="adherence" />
          <Column key="study" title={Strings.study} value={d => d.studyName || '-'} sortKey="study" />
          <Column key="approved_by_patient" title={Strings.status} value={isApprovedByPatient} />
          <Column
            key="buttons"
            value={patient => (
              <button className="white delete" onClick={e => this.onPatientDelete(e, patient)}>
                <div className="delete-img" />
              </button>
            )}
          />
        </Table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { patients } = state.entities;
  return {
    readOnlyMode: state.auth.read_only,
    patients: patients.patients,
    pagination: patients.pagination,
    isLoading: patients.loading,
    isPreparing: patients.preparing,
    isUpdate: patients.isUpdate,
    dateFilter: state.filter.dateFilter,
  };
};

const mapDispatchToProps = dispatch => ({
  loadPatients: (pageRequest, isLoading) => dispatch(patientAction.actionList(pageRequest, isLoading)),
  startLoading: () => dispatch(patientAction.startLoading()),
  loadInvitations: () => dispatch(patientAction.actionInvitations()),
  onNavigate: path => dispatch(push(path)),
  clearEntities: () =>
    dispatch(clearEntitiesAction(['patients', 'trials', 'memberships', 'doctors', 'patientCaregivers'])),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  onNewPatient: () =>
    dispatch(
      openModalAction('new-patient', {
        action: invitesAction.sendInvite,
        actionType: SEND_INVITE,
      }),
    ),
  onCloseNewPatient: () => dispatch(closeModal('new-patient')),
  sendInvitation: patient => dispatch(invitesAction.sendInvite(patient)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSliderPicker(MyPatients));
