import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { TIME_FORMAT_12_UPPERCASE, DATE_MONTH_DAY_YEAR, ZEPHY_RX_DASHBOARD } from '../../../../../../constants';
import Strings from '../../../../../../Strings';
import { PageHeader } from '../../../../../../components/PageHeader';
import { actions, PLOT_DATA_RESULT } from '../actions';
import SpirometryDashboard from './SpirometryDashboard';
import { Button } from '../../../../../../components/PageHeader/Button';
import TableWithLocalPagination, { Column } from '../../../../../../containers/Table/TableWithLocalPagination';

function Spirometry(props) {
  const [spirometryData, setSpirometryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSeriesIndex, setSelectedSeriesIndex] = useState(0);
  const [refreshEnable, setRefreshEnable] = useState(true);

  useEffect(() => {
    getEvents();
  }, [props.deviceId, props.startDate, props.endDate]);

  const getEvents = () => {
    const { deviceId, patientId, hubId, kitId, startDate, endDate } = props;
    setIsLoading(true);
    if (deviceId && deviceId !== '' && startDate && startDate !== '' && endDate && endDate !== '') {
      const request = {
        deviceId,
        dataType: 'spirometer',
        startDate,
        endDate,
        hubId,
        kitId,
        patientId,
      };
      props.getEvents(request).then(response => {
        if (response.type === PLOT_DATA_RESULT) {
          const tests = response.response.reduce((a, b) => a.concat(b.serie), []);
          setSpirometryData(tests);
          setIsLoading(false);
          if (tests && tests.length > 0) setSelectedSeriesIndex(0);
        }
      });
    }
  };

  const onRefresh = () => {
    setRefreshEnable(false);
    setTimeout(() => {
      setRefreshEnable(true);
    }, 10000);
    getEvents();
  };

  const getTable = () => {
    const columns = [
      <Column key="date" title={Strings.date} value={e => moment(e.created).format(DATE_MONTH_DAY_YEAR)} />,
      <Column key="time" title={Strings.time} value={e => moment(e.created).format(TIME_FORMAT_12_UPPERCASE)} />,
      <Column key="fvc" title="FVC (L)" value={e => e.fvc} />,
      <Column
        key="fev1"
        title={
          <div>
            FEV<sub>1</sub> (L)
          </div>
        }
        value={e => e.fev1}
      />,
      <Column
        key="fev1Fvc"
        title={
          <div>
            FEV<sub>1</sub>/FVC
          </div>
        }
        value={e => e.fev1Fvc}
      />,
      <Column
        key="fef2575"
        title={
          <div>
            FEF<sub>2575</sub> (L/s)
          </div>
        }
        value={e => e.fef2575}
      />,
      <Column
        key="fev6"
        title={
          <div>
            FEV<sub>6</sub> (L)
          </div>
        }
        value={e => e.fev6}
      />,
      <Column key="pef" title="PEF (L/s)" value={e => e.pef} />,
      <Column key="fet" title="FET (s)" value={e => e.fet} />,
      <Column key="fivc" title="FIVC (L)" value={e => e.fivc} />,
      <Column key="pif" title="PIF (L/s)" value={e => e.pif} />,
      <Column key="bev" title="BEV (L)" value={e => e.bev} />,
    ];

    return (
      <TableWithLocalPagination
        name="spirometry-table"
        uuid="38bffe61-ec75-4ec5-9459-438dec5ea19d"
        data={spirometryData.sort((a, b) => moment(a.created) < moment(b.created))}
        onRowSelection={setSelectedSeriesIndex}
        selectedByDefault={selectedSeriesIndex.toString()}
        isLoading={isLoading}
        columns={columns}
        enableColumnFiltering
      />
    );
  };

  const getPageHeader = () => {
    return (
      <PageHeader
        right={
          <React.Fragment>
            <Button class="refreshTop" disabled={!refreshEnable} onClick={onRefresh} />
          </React.Fragment>
        }
        left={
          <a class="ml-20" href={ZEPHY_RX_DASHBOARD} target="_blank">
            {' '}
            {Strings.zephyRxDashboard}
          </a>
        }
      />
    );
  };

  const selectedSeries = spirometryData ? spirometryData[selectedSeriesIndex] : [];
  return (
    <React.Fragment>
      {getPageHeader()}
      {getTable()}
      <div className="top-padding">
        {selectedSeries && selectedSeries.flowVolumeCurve && <SpirometryDashboard data={selectedSeries} />}
      </div>
    </React.Fragment>
  );
}

Spirometry.propTypes = {
  deviceId: PropTypes.string.isRequired,
  patientId: PropTypes.string,
  hubId: PropTypes.string,
  kitId: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  getEvents: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getEvents: request => dispatch(actions.getPlotData(request)),
});

export default connect(null, mapDispatchToProps)(Spirometry);
