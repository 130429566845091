import PropTypes, { array } from 'prop-types';
import React, { PureComponent } from 'react';
import {
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import Strings from '../../Strings';
import { CustomizedXAxisTickNumeric, CustomizedYAxisTickNumeric, getNumericAxisParams } from './GraphAxes';
import { getColorsForColorTheme } from '../../utils/colorTheme';

class SpirometryTimeCurveGraph extends PureComponent {
  render() {
    const { fet, fvc, points } = this.props;

    const yAxisParams = getNumericAxisParams(
      points.map(p => p.y),
      { extraValues: [fvc] },
    );
    const xAxisParams = getNumericAxisParams(
      points.map(p => p.x),
      { minTick: 1, extraValues: [fet] },
    );

    const graphReferenceLineColor = '#74b236';
    const graphLineColor = getColorsForColorTheme().mainColor;
    const graphLineWidth = 2;

    return (
      <div className="graphDivZ">
        <ResponsiveContainer width="100%" height={426}>
          <ComposedChart
            defaultShowTooltip={false}
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <XAxis
              type="number"
              dataKey="x"
              allowDataOverflow={false}
              height={30}
              interval={0}
              name="Time"
              unit="s"
              orientation="bottom"
              tickLine
              tickSize={5}
              domain={xAxisParams.domain}
              ticks={xAxisParams.ticks}
              tick={
                <CustomizedXAxisTickNumeric
                  xMin={xAxisParams.minValue}
                  xMax={xAxisParams.maxValue}
                  height={30}
                  unit="s"
                />
              }
            />
            <YAxis
              type="number"
              dataKey="y"
              allowDataOverflow={false}
              name="Flow (l)"
              unit=""
              tickSize={5}
              width={70}
              domain={yAxisParams.domain}
              ticks={yAxisParams.ticks}
              tick={
                <CustomizedYAxisTickNumeric
                  yMin={yAxisParams.minValue}
                  yMax={yAxisParams.maxValue}
                  width={70}
                  unit="L"
                />
              }
            />

            <CartesianGrid strokeDasharray="4 1 2" />

            <ReferenceLine y={0} label="" stroke="#eeeeee" />
            <ReferenceLine x={fet} label="FET" stroke={graphReferenceLineColor} />
            <ReferenceLine y={fvc} label="FVC" stroke={graphReferenceLineColor} />

            <Line
              type="monotone"
              connectNulls
              activeDot={false}
              dot={false}
              stroke={graphLineColor}
              strokeWidth={graphLineWidth}
              data={points}
              dataKey="y"
            />

            {/* <Tooltip content={<CustomTooltip />} dataKey="y" isAnimationActive={false} /> */}
          </ComposedChart>
        </ResponsiveContainer>
        <div style={{ textAlign: 'center', fontSize: '18px' }}>{Strings.volumeTimeCurve}</div>
      </div>
    );
  }
}

export default React.memo(SpirometryTimeCurveGraph);

SpirometryTimeCurveGraph.propTypes = {
  fet: PropTypes.number,
  fvc: PropTypes.number,
  points: PropTypes.array,
};
