import {
  GET_FILTER_DATA,
  GET_FILTER_DATA_ERROR,
  GET_FILTER_DATA_RESULT,
  SHIPPER_CAPS_DETAILS,
  SHIPPER_CAPS_DETAILS_CLEAR,
  SHIPPER_CAPS_DETAILS_RESULT,
  SHIPPER_CAPS_SCHEDULE_DETAILS,
  SHIPPER_CAPS_SCHEDULE_DETAILS_ERROR,
  SHIPPER_CAPS_SCHEDULE_DETAILS_RESULT,
  SHIPPER_CAPS_PLOT_DATA,
  SHIPPER_CAPS_PLOT_DATA_ERROR,
  SHIPPER_CAPS_PLOT_DATA_RESULT,
} from './constants';

export const shipperCapsDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case SHIPPER_CAPS_DETAILS:
    case GET_FILTER_DATA:
      return {
        ...state,
        isLoading: true,
      };

    case SHIPPER_CAPS_PLOT_DATA:
      return {
        ...state,
        plotData: [],
        isLoading: true,
      };

    case SHIPPER_CAPS_PLOT_DATA_ERROR:
      return {
        ...state,
        plotData: [],
        isLoading: true,
      };

    case SHIPPER_CAPS_PLOT_DATA_RESULT:
      return {
        ...state,
        isLoading: false,
        plotData: action.response,
      };

    case GET_FILTER_DATA_RESULT:
      return {
        ...state,
        isLoading: false,
        filterData: action.response.data,
      };
    case GET_FILTER_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case SHIPPER_CAPS_DETAILS_RESULT:
      return {
        ...state,
        isLoading: false,
        data: action.response,
      };
    case SHIPPER_CAPS_DETAILS_CLEAR:
      return {
        ...state,
        isLoading: false,
        data: undefined,
        plotScheduleData: undefined,
        plotData: undefined,
      };
    case SHIPPER_CAPS_SCHEDULE_DETAILS_RESULT:
      return {
        ...state,
        isLoading: false,
        plotScheduleData: action.response,
      };
    case SHIPPER_CAPS_SCHEDULE_DETAILS:
    case SHIPPER_CAPS_SCHEDULE_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        plotScheduleData: [],
      };

    default:
      return state;
  }
};
