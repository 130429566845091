import Strings from '../Strings';

export const TEMPERATURE_UNITS = {
  f: '°F',
  c: '°C',
};

export function convertCelsiusToFahrenheit(temperature) {
  return Math.round(((temperature * 9) / 5 + 32 + Number.EPSILON) * 1000) / 1000;
}

export function convertFahrenheitToCelsius(temperature) {
  return Math.round((((temperature - 32) * 5) / 9 + Number.EPSILON) * 1000) / 1000;
}

/* Weight */

export const WEIGHT_UNITS = {
  kilogram: 'kilogram',
  kg: 'kg',
  lbs: 'pound',
  metric: 'kilogram, meter',
};

const KG_LBS_CONVERSION_RATIO = 2.20462262;
export function convertKilogramToPound(weight) {
  return WeightUnitConverter(weight, WEIGHT_UNITS.kg, WEIGHT_UNITS.lbs);
}

export function convertPoundToKilogram(weight) {
  return WeightUnitConverter(weight, WEIGHT_UNITS.lbs, WEIGHT_UNITS.kg);
}

export const WeightUnitConverter = (value, inUnit, outUnit) => {
  if (
    (inUnit === WEIGHT_UNITS.kg || inUnit === WEIGHT_UNITS.metric || inUnit === WEIGHT_UNITS.kilogram) &&
    outUnit === WEIGHT_UNITS.lbs
  )
    return value * KG_LBS_CONVERSION_RATIO;
  else if (
    inUnit === WEIGHT_UNITS.lbs &&
    (outUnit === WEIGHT_UNITS.kg || outUnit === WEIGHT_UNITS.metric || outUnit === WEIGHT_UNITS.kilogram)
  )
    return value / KG_LBS_CONVERSION_RATIO;
  return value;
};

/* Length */

export const LENGTH_UNITS = {
  m: 'metre',
  mi: 'mile',
  metric: 'kilogram, meter',
};

const M_MI_CONVERSION_RATIO = 0.0006213712;

export const LengthUnitConverter = (value, inUnit, outUnit) => {
  if ((inUnit === LENGTH_UNITS.m || inUnit === LENGTH_UNITS.metric) && outUnit === LENGTH_UNITS.mi)
    return value * M_MI_CONVERSION_RATIO;
  else if (inUnit === LENGTH_UNITS.mi && (outUnit === LENGTH_UNITS.m || outUnit === LENGTH_UNITS.metric))
    return value / M_MI_CONVERSION_RATIO;
  return value;
};

/* Glucose */

export const GLUCOSE_UNITS = {
  mol_l: 'mol/L',
  mmol_l: 'mmol/L',
  kg_l: 'kg/L',
  mg_dl: 'mg/dL',
};

export function GlucoseUnitConverter(value, inUnit, outUnit) {
  let ret = value;
  if (inUnit === GLUCOSE_UNITS.mol_l && outUnit === GLUCOSE_UNITS.mmol_l) ret = value * 1000;
  else if (inUnit === GLUCOSE_UNITS.kg_l && outUnit === GLUCOSE_UNITS.mmol_l) ret = value * 5550.7;
  else if (inUnit === GLUCOSE_UNITS.mol_l && outUnit === GLUCOSE_UNITS.mg_dl) ret = value * 1000 * 18.0156;
  else if (inUnit === GLUCOSE_UNITS.kg_l && outUnit === GLUCOSE_UNITS.mg_dl) ret = value * 100000;
  else if (inUnit === GLUCOSE_UNITS.mg_dl && outUnit === GLUCOSE_UNITS.kg_l) ret = value / 100000;
  return ret;
}

/* Helpers */

export const roundToDecimal = (value, places) => {
  if (isNaN(value)) return value;
  const precision = places || 0;
  typeof value === 'string' && (value = parseFloat(value));
  return Math.round((value + Number.EPSILON) * 10 ** precision) / 10 ** precision;
};

export const getUnitText = unit => {
  switch (unit) {
    case WEIGHT_UNITS.kg:
    case WEIGHT_UNITS.metric:
      return Strings.unitsEnum.kg;
    case WEIGHT_UNITS.lbs:
      return Strings.unitsEnum.lbs;
    default:
      return '';
  }
};
