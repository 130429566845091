import {
  GET_USER_SETTINGS,
  GET_USER_SETTINGS_RESULT,
  POST_USER_SETTINGS_ERROR,
  POST_USER_SETTINGS_RESULT,
  PATIENT_PORTAL_ADD_CAREGIVER,
  PATIENT_PORTAL_ADD_CAREGIVER_RESULT,
  PATIENT_PORTAL_ADD_CAREGIVER_ERROR,
  PATIENT_PORTAL_DELETE_CAREGIVER,
  PATIENT_PORTAL_DELETE_CAREGIVER_RESULT,
  PATIENT_PORTAL_DELETE_CAREGIVER_ERROR,
  PATIENT_PORTAL_UPDATE_PATIENT_SETTINGS_RESULT,
  PATIENT_PORTAL_UPDATE_PATIENT_NOTIFICATIONS_RESULT,
  POST_USER_SETTINGS,
} from '../actions/action-types';

const settingsReducer = (state = {}, action) => {
  const data = action.response;
  switch (action.type) {
    case GET_USER_SETTINGS:
      return {
        ...state,
        settings: {},
        loading: true,
      };

    case GET_USER_SETTINGS_RESULT:
      return {
        ...state,
        settings: data,
        loading: false,
      };

    case POST_USER_SETTINGS:
      return {
        ...state,
        loading: true,
      };
    case POST_USER_SETTINGS_ERROR:
      data.status_class = 'errors';
      return {
        ...state,
        settings: data,
        loading: false,
      };
    case POST_USER_SETTINGS_RESULT:
      data.status_class = 'success';
      data.error_msg = '';
      return {
        ...state,
        settings: data,
        loading: false,
      };
    case PATIENT_PORTAL_ADD_CAREGIVER:
      return {
        ...state,
        addCaregiverLoading: true,
        changing: true,
      };
    case PATIENT_PORTAL_ADD_CAREGIVER_RESULT:
      state.settings.caregivers.push(action.response);

      return {
        ...state,
        addCaregiverLoading: false,
        changing: false,
      };
    case PATIENT_PORTAL_ADD_CAREGIVER_ERROR:
      return {
        ...state,
        addCaregiverLoading: false,
        changing: false,
      };
    case PATIENT_PORTAL_DELETE_CAREGIVER:
      return {
        ...state,
        loading: true,
      };
    case PATIENT_PORTAL_DELETE_CAREGIVER_RESULT: // eslint-disable-line no-case-declarations
      const caregiverIndex = state.settings.caregivers.findIndex(element => +element.id === +action.response.id);

      if (caregiverIndex !== -1) {
        delete state.settings.caregivers[caregiverIndex]; // eslint-disable-line no-param-reassign
      }

      return {
        ...state,
        loading: false,
      };
    case PATIENT_PORTAL_DELETE_CAREGIVER_ERROR:
      return {
        ...state,
        loading: false,
      };
    case PATIENT_PORTAL_UPDATE_PATIENT_SETTINGS_RESULT:
    case PATIENT_PORTAL_UPDATE_PATIENT_NOTIFICATIONS_RESULT:
      return {
        ...state,
        // settings: data,
        loading: false,
      };
    default:
      return state;
  }
};

export default settingsReducer;
