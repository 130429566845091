import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import moment from 'moment-timezone';

import styles from './Timer.module.scss';

export class Timer extends PureComponent {
  static defaultProps = { text: '%s' };

  state = { str: '' };

  interval = null;

  componentDidMount() {
    this.prepareTimer();
  }

  componentDidUpdate() {
    this.prepareTimer();
  }

  prepareTimer() {
    if (this.interval) return;
    const { expiresAt } = this.props;
    if (!expiresAt) return;

    this.interval = setInterval(this.intervalHandler, 1000);
    this.intervalHandler();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  intervalHandler = () => {
    const { expiresAt, onExpired, text } = this.props;

    const now = moment().unix();

    if (now >= expiresAt) {
      clearInterval(this.interval);
      if (onExpired) {
        onExpired();
      } else {
        this.setState({ str: '' });
      }
      return;
    }

    const duration = moment.duration((expiresAt - now) * 1000);
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const str = text.replace('%s', `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);

    this.setState({ str });
  };

  render() {
    if (this.props.inlineMode) {
      return (
        <span className={styles.inline} role="timer" aria-live="off" aria-interval="30">
          {this.state.str}
        </span>
      );
    }

    return (
      <div className={styles.Timer} role="timer" aria-live="off" aria-interval="3w0">
        {this.state.str}
      </div>
    );
  }
}

Timer.propTypes = {
  expiresAt: PropTypes.any,
  inlineMode: PropTypes.any,
  onExpired: PropTypes.func,
  text: PropTypes.string,
};
