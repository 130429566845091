import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import GenericCategoryGraph, { chartTypeEnum } from './GenericCategoryGraph';
import Strings from '../../Strings';

export default class CapActivations extends PureComponent {
  render() {
    const dataSeries = [
      {
        name: Strings.caps,
        color: '#0460a9',
        selector: item => item.count,
      },
    ];

    return (
      <GenericCategoryGraph
        graphData={this.props.graphData}
        hasBorder={true}
        forceCondensed={false}
        xAxisTopPosition={false}
        xAxisLabelsMinMaxOnly
        useZeroAsMin
        yAxisWidth={40}
        yAxisUnit=""
        tooltipTitle={Strings.caps}
        dataSeries={dataSeries}
        categorySelector={item => item.date}
        hasReferenceAreas={false}
        graphType={chartTypeEnum.area}
      />
    );
  }
}

CapActivations.propTypes = {
  graphData: PropTypes.array,
};
