import React from 'react';
import FirstUploadDrop from '../../CloudDocuments/internals/FirstUpload';
import { notificationActions } from '../../../components/Notification/redux/actions';
import { actions } from './actions';

import { connect } from 'react-redux';
import { PageHeader } from '../../../components/PageHeader';
import { Button } from '../../../components/PageHeader/Button';
import { HEADER_BUTTON_BLUE } from '../../../constants';

import './AddDevices.scss';

function AddDevices(props) {
  const [uploading, setUploading] = React.useState(false);
  const [helpActive, setHelpActive] = React.useState(false);

  const getRequestFromFileContent = fileContent => {
    const lines = fileContent.split(/\r\n|\n/);

    if (lines.length === 0) {
      return [{}, 'Empty file'];
    }

    const deviceNamePattern = /^(HUB|LSC|LTE)[0-9A-Za-z]+$/i; // case insensitive match
    const parameterPattern = /^[a-zA-Z_]+=[a-zA-Z0-9.:/]+$/;

    const request = {
      device_ids: [],
      device_type: '',
      org_uuid: props.orgUuid,
    };

    for (let i = 0; i < lines.length; i++) {
      if (lines[i] === '') {
        continue;
      }
      if (deviceNamePattern.test(lines[i])) {
        // extract device type
        const deviceType = lines[i].substring(0, 3);
        if (request.device_type === '') {
          request.device_type = deviceType.toLowerCase();
        } else if (request.device_type !== deviceType.toLowerCase()) {
          return [{}, `Invalid device type: ${deviceType}`];
        }

        request.device_ids.push(lines[i]);
      } else if (parameterPattern.test(lines[i])) {
        const [key, value] = lines[i].split('=');
        if (
          key !== 'provisioning_devices' &&
          key !== 'provisioning_features' &&
          key !== 'fota_url' &&
          key !== 'provisioning_test_devices'
        ) {
          return [{}, `Invalid parameter: ${key}`];
        }
        if (key === 'provisioning_test_devices') {
          if (value !== 'true' && value !== 'false') {
            return [{}, `Invalid value for provisioning_test_devices: ${value}`];
          }

          if (value === 'true') {
            request.provisioning_test_devices = true;
          } else {
            request.provisioning_test_devices = false;
          }
          continue;
        }

        request[key] = value;
      } else {
        return [{}, `Invalid line: ${lines[i]}`];
      }
    }

    return [request, null];
  };

  const onUpload = file => {
    setUploading(true);
    const reader = new FileReader();
    reader.onload = e => {
      const data = e.target.result;

      const [request, error] = getRequestFromFileContent(data);
      if (error) {
        props.showNotification(error);
        setUploading(false);
        return;
      }

      props.addBulkDevices(request).then(response => {
        if (response.type === actions.ADD_BULK_DEVICES_RESULT) {
          props.showNotification('Devices added successfully');
          setUploading(false);
        } else {
          let error = 'Failed to add devices';
          if (response.response.data && response.response.data.error && response.response.data.error.message) {
            error = response.response.data.error.message;
          } else {
          }
          props.showNotification(error);
          setUploading(false);
        }
      });
    };

    reader.onabort = () => {
      props.showNotification('File reading was aborted');
      setUploading(false);
    };

    reader.onerror = () => {
      props.showNotification('File reading has failed');
      setUploading(false);
    };

    reader.readAsText(file);
  };

  const helpContent = (
    <>
      <textarea
        value="HUB140BD25EA82D7&#10;HUB140BD25EA392C&#10;HUB140BD25E99D7C&#10;HUB140BD25E9ADDB&#10;HUB140BD25EA24EE&#10;provisioning_test_devices=false&#10;provisioning_devices=newDevice&#10;provisioning_features=otherFeatures&#10;fota_url=rxcap.com"
      />
    </>
  );

  return (
    <>
      <PageHeader
        title={helpActive ? 'Example file' : 'Upload file'}
        right={
          <Button
            class={HEADER_BUTTON_BLUE}
            title={!helpActive ? 'Get Help' : 'Back'}
            onClick={() => setHelpActive(!helpActive)}
          />
        }
      />
      <div className="add-devices-bulk">
        {!helpActive && <FirstUploadDrop onUpload={onUpload} uploading={uploading} />}
        {helpActive && helpContent}
      </div>
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  showNotification: message => dispatch(notificationActions.show(message, 5000)),
  addBulkDevices: request => dispatch(actions.addBulkDevices(request)),
});

export default connect(null, mapDispatchToProps)(AddDevices);
