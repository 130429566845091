import {
  GET_DEVICES_UNASSIGNED_TO_KIT_RESULT,
  KIT_DEVICES_RESULT,
  CLEAR_DATA,
  UNASSIGN_DEVICE_FROM_KIT_RESULT,
  DELETE_KIT_RESULT,
  DELETE_KIT_ERROR,
  GET_PATIENT_KIT_INFO_RESULT,
  GET_PATIENT_KIT_INFO,
} from './constants';

export const kitReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DEVICES_UNASSIGNED_TO_KIT_RESULT:
      return {
        ...state,
        devices: action.response.devices,
        pagination: action.response.pagination,
        isLoading: false,
      };
    case KIT_DEVICES_RESULT:
      return {
        ...state,
        isLoading: false,
        kitDevices: action.response,
      };
    case CLEAR_DATA:
      return {
        ...state,
        isLoading: false,
        kitDevices: [],
        pagination: {},
      };
    case UNASSIGN_DEVICE_FROM_KIT_RESULT:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_KIT_RESULT:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_KIT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case GET_PATIENT_KIT_INFO:
      return {
        ...state,
        isLoading: true,
        patientKit: {},
      };
    case GET_PATIENT_KIT_INFO_RESULT:
      return {
        ...state,
        isLoading: false,
        patientKit: action.response.kit,
      };

    default:
      return state;
  }
};
