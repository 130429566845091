import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Strings from '../../Strings';
import { AsyncSelectField, SelectField, TextAreaWithHashtagsField } from '../../containers/Form';
import Wizard, { wizardTypes } from '../../containers/Modal/Wizard';
import { SelectedOptions, SelectedOptionsType } from '../../components/Select/Multiselect';
import { actions } from '../../pages/SuperUser/Messages/redux/actions';
import { Button } from '../../components/PageHeader/Button';
import { PERMISSIONS, hasPermission } from '../../utils/userPermissions';
import PrefabWindow from '../../pages/SuperUser/Messages/PrefabWindow';
import './SendMessageModal.scss';
import { SEND_MESSAGE_ERROR } from '../../pages/SuperUser/Messages/redux/constants';
import { notificationActions } from '../../components/Notification/redux/actions';
import { closeModal } from '../../actions/modal';
import { MAX_MESSAGE_LENGTH } from '../../constants';

const SendMessageModal = props => {
  const [highlightInvalidFields, setHighlightMissingFields] = useState(false);
  const [message, setMessage] = useState();
  const [recipients, setRecipients] = useState(
    props.data.patient
      ? [{ ...props.data.patient, value: props.data.patient.id, label: props.data.patient.patientName }]
      : [],
  );
  const [patients, setPatients] = useState([]);
  const [prefabWindowOpen, setPrefabWindowOpen] = useState(false);
  //set to false by default if patients api gets optimized
  const [asyncSelectNeeded, setAsyncSelectNeeded] = useState(true);

  const inputRef = useRef();
  const showPrefabs = hasPermission(PERMISSIONS.MESSAGES_PREFABS);
  const MAX_ITEMS_IN_SELECT = 500;

  useEffect(() => {
    props.getPrefabReplies();
    // restore if patients api gets optimized...
    // props.data.loadRecipients().then(resp => {
    //   if (resp.type === `${props.data.loadRecipientsActionType}/error`) {
    //     return [];
    //   }
    //   const people = resp.response.data.map(e => {
    //     return {
    //       ...e,
    //       value: e.id,
    //       label: `${e.patientName} (${e.mrn}, ${e.textPhoneNo})`,
    //     };
    //   });
    //   setPatients(people.filter(d => !recipients.some(p => p.id === d.id)));
    //   setAsyncSelectNeeded(resp.response.pagination.totalRecords > MAX_ITEMS_IN_SELECT);
    // });
  }, []);

  const loadOptions = _.debounce((value, callback) => {
    if (value?.length < 3) {
      return;
    }

    const pageRequest = {
      offset: 0,
      search: value,
    };
    props.data.loadRecipients(pageRequest).then(resp => {
      callback(() => {
        if (resp.type === `${props.data.loadRecipientsActionType}/error`) {
          return [];
        }
        const people = resp.response?.data
          ? resp.response.data.map(e => {
              return {
                ...e,
                value: e.id,
                label: `${e.patientName} (${e.mrn}, ${e.textPhoneNo})`,
              };
            })
          : [];
        return people.filter(d => !recipients.some(p => p.id === d.id));
      });
    });
  }, 1000);

  const onPrefabChosen = text => {
    const { selectionStart, selectionEnd, value } = inputRef.current;
    let textBeforeCursorPosition = value.substring(0, selectionStart);
    let textAfterCursorPosition = value.substring(selectionEnd, value.length);
    setMessage(`${textBeforeCursorPosition}${text}${textAfterCursorPosition}`);
    setPrefabWindowOpen(false);
    inputRef.current.selectionStart = selectionStart + value.length;
    inputRef.current.selectionEnd = selectionStart + value.length;
    inputRef.current.focus();
  };

  const onSend = () => {
    const data = {
      phones: recipients.map(r => r.textPhoneNo),
      message,
    };
    props.sendMessage(data).then(response => {
      if (response) {
        if (response.type === SEND_MESSAGE_ERROR) {
          const error = response.response?.data?.error?.message;
          props.showNotification(error, 5000, true);
        } else {
          props.showNotification(Strings.SMSSent);
          props.closeModal();
        }
      }
      return response;
    });
  };

  const messagePage = (
    <React.Fragment>
      {asyncSelectNeeded ? (
        <AsyncSelectField
          id="recipients"
          name="recipients"
          placeholder={Strings.patient_medications.enter3Chars}
          loadOptions={loadOptions}
          onChange={option => setRecipients(recipients.concat(option))}
          cacheOptions={false}
          clearValueAfterSelection
          defaultOptions={patients.filter(d => !recipients.some(p => p.id === d.id))}
          menuPositionFixed={false}
          isRequired
          highlightInvalid={highlightInvalidFields && recipients?.length < 1}
        />
      ) : (
        <SelectField
          placeholder={Strings.messages.recipients}
          data={patients.filter(d => !recipients.some(p => p.id === d.id))}
          isSearchable
          clearValueAfterSelection
          onChange={option => setRecipients(recipients.concat(option))}
          menuPositionFixed={false}
          isRequired
          highlightInvalid={highlightInvalidFields && recipients?.length < 1}
        />
      )}
      {recipients && recipients.length > 0 && (
        <SelectedOptions
          items={recipients}
          onRemove={option => setRecipients(recipients.filter(r => r.id !== option.id))}
          onClear={() => setRecipients([])}
          type={SelectedOptionsType.cloud}
        />
      )}
      <TextAreaWithHashtagsField
        value={message}
        onChange={e => setMessage(e)}
        hashtags={
          showPrefabs && props.prefabReplies && props.prefabReplies.length > 0
            ? props.prefabReplies.map(r => {
                return {
                  id: r.id,
                  display: r.full_txt,
                  shortcut: r.shortcut,
                  title: r.title,
                };
              })
            : []
        }
        tooltipForEntries="Add Reply"
        convertTagToPlainText
        inputRef={inputRef}
        isRequired
        errorsForTooltip={
          message?.length > MAX_MESSAGE_LENGTH
            ? [
                Strings.formatString(
                  Strings.maxAndOverflowChars,
                  MAX_MESSAGE_LENGTH,
                  message.length - MAX_MESSAGE_LENGTH,
                ),
              ]
            : undefined
        }
        highlightInvalid={highlightInvalidFields}
      />
      {prefabWindowOpen && showPrefabs && (
        <PrefabWindow
          prefabs={props.prefabReplies}
          onBlur={() => setPrefabWindowOpen(false)}
          onPrefabChosen={onPrefabChosen}
        />
      )}
      {showPrefabs && (
        <Button
          class={`${prefabWindowOpen ? 'clicked' : ''} prefab-button`}
          onClick={() => setPrefabWindowOpen(!prefabWindowOpen)}
          title="#"
        />
      )}
    </React.Fragment>
  );

  const pages = [
    {
      id: 'message',
      title: props.data.patient ? `${Strings.message} ${props.data.patient.patientName}` : Strings.messages.newMessage,
      content: messagePage,
      canGoNext: message && recipients?.length > 0,
      nextButton: { text: Strings.send },
    },
  ];

  return (
    <Wizard
      name="send-message"
      id={props.modalId}
      pages={pages}
      onNextButtonHover={e => setHighlightMissingFields(e)}
      isTopModal={props.isTopModal}
      type={wizardTypes.floatingModal}
      onSubmit={onSend}
    />
  );
};

SendMessageModal.propTypes = {
  onCancel: PropTypes.func,
  data: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.number,
      patientName: PropTypes.string,
      textPhoneNo: PropTypes.string,
    }),
    loadRecipients: PropTypes.func,
    loadRecipientsActionType: PropTypes.string,
  }),
  modalId: PropTypes.any,
  isTopModal: PropTypes.bool,
  sendMessage: PropTypes.func,
  prefabReplies: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      full_txt: PropTypes.string,
      shortcut: PropTypes.string,
    }),
  ),
  showNotification: PropTypes.func,
  closeModal: PropTypes.func,
};

const mapStateToProps = state => {
  const { messages } = state.superUser;
  return {
    prefabReplies: messages.prefabReplies,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPrefabReplies: () => dispatch(actions.getPrefabReplies()),
  sendMessage: data => dispatch(actions.sendMessage(data)),
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
  closeModal: () => dispatch(closeModal('send-message', {}, ownProps.modalId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendMessageModal);
