import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button, BUTTON_TYPE_BLUE } from './Buttons/Button';
import { Box } from './Plots/base';

class InfoBox extends PureComponent {
  static propTypes = {
    caption: PropTypes.string.isRequired,
    isGlowing: PropTypes.bool,
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
    children: PropTypes.any,
  };

  render() {
    const { caption, children, onClick, buttonText, isGlowing } = this.props;
    return (
      <Box caption={caption} className="info-box">
        <div>{children}</div>
        {onClick ? (
          <div className="footer">
            <Button isGlowing={isGlowing} buttonType={BUTTON_TYPE_BLUE} onClick={onClick}>
              {buttonText}
            </Button>
          </div>
        ) : null}
      </Box>
    );
  }
}

export default InfoBox;
