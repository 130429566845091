import {
  GET_ORAGANIZATION_DEVICES_BILLING,
  GET_ORAGANIZATION_DEVICES_BILLING_ERROR,
  GET_ORAGANIZATION_DEVICES_BILLING_RESULT,
} from './constants';

export const reducer = (state = null, action) => {
  switch (action.type) {
    case GET_ORAGANIZATION_DEVICES_BILLING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ORAGANIZATION_DEVICES_BILLING_ERROR:
      return {
        ...state,
        isLoading: false,
        billing: [],
      };
    case GET_ORAGANIZATION_DEVICES_BILLING_RESULT:
      return {
        ...state,
        devices: action.response.devices,
        summary: action.response.summary,
        pagination: action.response.pagination,
        isLoading: false,
      };

    default:
      return state;
  }
};
