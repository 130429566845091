export const tableActions = {
  init: 'table/INIT',
  setColumns: 'table/SET_COLUMNS',
  setOrder: 'table/SET_ORDER',
  setData: 'table/SET_DATA',
  setFilter: 'table/SET_FILTER',
  selectRow: 'table/SELECT_ROW',
  deselectRow: 'table/DESELECT_ROW',
  refreshTable: 'table/REFRESH_TABLE',
};

/**
 * initialise table state
 *
 * this is called most of the time by the <Table> itself after processing its children
 *
 * @param id - table id/name
 * @param columns - column definition objects
 * @param data - table data - not used, yet
 * @param order
 */
export const initTable = (id, { columns, data, order }) => ({
  type: tableActions.init,
  table: {
    id,
    columns,
    data,
    order,
  },
});

/**
 * set table columns
 * @param id - table name
 * @param columns - new column definition objects
 */
export const setColumns = (id, columns) => ({
  type: tableActions.setColumns,
  table: {
    id,
    columns,
  },
});

/**
 * set table order
 * @param id - table name
 * @param key - sorting column key
 * @param direction - sort direction
 */
export const setOrder = (id, { key, direction }) => {
  return {
    type: tableActions.setOrder,
    table: {
      id,
      order: {
        key,
        direction,
      },
    },
  };
};

export const selectRow = (id, rowId, othersToDeSelect) => ({
  type: tableActions.selectRow,
  table: {
    id,
    selected: rowId,
  },
  othersToDeSelect,
});

export const deSelectRow = id => ({
  type: tableActions.deselectRow,
  table: { id },
});

export const refreshTable = id => ({
  type: tableActions.refreshTable,
  table: { id },
});

export const isTableAction = action => action.table !== undefined;
