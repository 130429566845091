export const SCHEDULE_LIST = 'device-details';
export const SCHEDULE_LIST_RESULT = `${SCHEDULE_LIST}/result`;
export const SCHEDULE_LIST_CLEAR = `${SCHEDULE_LIST}/clear`;

export const SCHEDULE_DETAILS = 'schedule-details';
export const SCHEDULE_DETAILS_RESULT = `${SCHEDULE_DETAILS}/result`;
export const SCHEDULE_DETAILS_ERROR = `${SCHEDULE_DETAILS}/clear`;

export const GET_CAPS = 'schedule-details';
export const GET_CAPS_RESULT = `${GET_CAPS}/result`;
export const GET_CAPS_ERROR = `${GET_CAPS}/error`;

export const ASSIGN_CAPS_TO_THE_SCHEDULE = 'assign-caps-to-the-schedule';
export const ASSIGN_CAPS_TO_THE_SCHEDULE_RESULT = `${ASSIGN_CAPS_TO_THE_SCHEDULE}/result`;
export const ASSIGN_CAPS_TO_THE_SCHEDULE_ERROR = `${ASSIGN_CAPS_TO_THE_SCHEDULE}/error`;

export const DELETE_CAP_FROM_SCHEDULE = 'delete-cap-from-schedule';
export const DELETE_CAP_FROM_SCHEDULE_RESULT = `${DELETE_CAP_FROM_SCHEDULE}/result`;
export const DELETE_CAP_FROM_SCHEDULE_ERROR = `${DELETE_CAP_FROM_SCHEDULE}/error`;

export const ADD_SCHEDULE = 'add-schedule';
export const ADD_SCHEDULE_RESULT = `${ADD_SCHEDULE}/result`;
export const ADD_SCHEDULE_ERROR = `${ADD_SCHEDULE}/error`;

export const EDIT_SCHEDULE = 'add-schedule';
export const EDIT_SCHEDULE_RESULT = `${EDIT_SCHEDULE}/result`;
export const EDIT_SCHEDULE_ERROR = `${EDIT_SCHEDULE}/error`;

export const DELETE_SCHEDULE = 'delete-schedule';
export const DELETE_SCHEDULE_RESULT = `${DELETE_SCHEDULE}/result`;
export const DELETE_SCHEDULE_ERROR = `${DELETE_SCHEDULE}/error`;
