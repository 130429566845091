import PropTypes from 'prop-types';

import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { resetForm } from '../../actions/forms';
import { closeModal } from '../../actions/modal';
import LoadingRenderer from '../../components/LoadingRenderer';
import { ManualDatePicker } from '../../components/ManualDatePicker';
import { Switch2 } from '../../components/Switch2/Switch2';
import {
  DATE_FORMAT,
  DATE_FORMAT_FOR_SLIDER,
  DATE_FORMAT_MONTH_NAME,
  GetTimezoneTextMapping,
  NUMBER_PATTERN,
  TIME_FORMAT_12_UPPERCASE,
} from '../../constants';
import { Modal } from '../../containers';
import Form, { Input } from '../../containers/Form';
import { actions } from '../../pages/SuperUser/Schedules/redux/actions';
import {
  ADD_SCHEDULE_ERROR,
  ADD_SCHEDULE_RESULT,
  EDIT_SCHEDULE_ERROR,
  EDIT_SCHEDULE_RESULT,
} from '../../pages/SuperUser/Schedules/redux/constants';
import Strings from '../../Strings';
import './AddScheduleToTheCap.scss';
import { AutoSuggestionInput } from './AutoSuggestionInput';
import { getColorsForColorTheme } from '../../utils/colorTheme';

const today = moment()
  .endOf('day')
  .toDate();
const yearAhead = moment()
  .add(100, 'year')
  .startOf('day')
  .toDate();

const monthAhead = moment()
  .add(1, 'month')
  .startOf('day')
  .toDate();

class AddScheduleToTheCapModal extends PureComponent {
  scheduleFormRef = React.createRef();
  newSchedule = {
    frequency: 'Everyday',
    dose: '1',
    time: ['10:00 AM'],
    schedule_name: '',
    schedule_type: 'daily',
    offset: [],
    medication_name: '',
    start_dt: today,
    end_dt: monthAhead,
    rxnorm_code: '',
    schedule_timezone: '',
  };

  constructor(props) {
    super(props);
    let endDateFlag;
    let selectedSchedule;
    if (props.data?.schedule) {
      selectedSchedule = cloneDeep(props.data.schedule);
      endDateFlag = !!selectedSchedule?.end_dt;
      selectedSchedule.frequency = 'Everyday';
      selectedSchedule.time = this.getTime(selectedSchedule.offset);
      selectedSchedule.start_dt = moment.unix(selectedSchedule.start_dt).toDate();
      selectedSchedule.end_dt = selectedSchedule.end_dt ? moment.unix(selectedSchedule.end_dt).toDate() : monthAhead;
      selectedSchedule.dose = selectedSchedule.offset.length;
      selectedSchedule.masked_id = selectedSchedule?.masked_id;
      selectedSchedule.schedule_timezone = selectedSchedule?.schedule_timezone;
    }

    this.state = {
      errors: [],
      page: 1,
      enableEndDate: endDateFlag,
      titles: [
        Strings.schedules.title1,
        Strings.schedules.title2,
        Strings.schedules.title3,
        Strings.schedules.title4,
        Strings.schedules.title5,
      ],
      schedule: selectedSchedule ? cloneDeep(selectedSchedule) : this.newSchedule,
    };
  }

  getTime(value) {
    if (value) {
      return value.map(item => {
        const timeText = moment()
          .startOf('day')
          .seconds(item)
          .format(TIME_FORMAT_12_UPPERCASE);
        return timeText;
      });
    }
    return '-';
  }

  componentDidMount() {
    if (this.props.data?.schedule) {
      const timezones = GetTimezoneTextMapping();
      const selectedSchedule = cloneDeep(this.props.data.schedule);
      const index = selectedSchedule?.schedule_timezone;
      const tzone = timezones[index];
      const { schedule } = this.state;
      schedule.schedule_timezone_edit = tzone;
      this.setState({ schedule });
    }
    this.scheduleFormRef && this.scheduleFormRef?.current?.focus();
  }

  onSubmit = () => {
    const schedule = cloneDeep(this.state.schedule);
    const errors = [];
    this.setState({ errors });
    const { page } = this.state;
    if (page !== 5) {
      switch (page) {
        case 1:
          if (schedule.schedule_name) {
            this.next();
          } else {
            errors.push(Strings.schedules.errorScheduleName);
            this.setState({ errors });
          }
          break;

        case 2:
          if (schedule.medication_name) {
            this.next();
          } else {
            errors.push(Strings.schedules.errorMedicationName);
            this.setState({ errors });
          }
          break;

        case 3:
          if (schedule.time.length > 0 && schedule.schedule_timezone) {
            this.next();
          } else {
            if (schedule.schedule_timezone === '') {
              errors.push(Strings.schedules.errorSelectTimezone);
            } else {
              errors.push(Strings.schedules.errorSelectTime);
            }
            this.setState({ errors });
          }
          break;
        case 4:
          if (schedule.start_dt) {
            this.next();
          } else {
            errors.push(Strings.schedules.errorSelectStartDate);
            this.setState({ errors });
          }
          break;
        default:
          break;
      }
    } else {
      schedule.offset = schedule.time.map(offset => {
        return moment(offset, 'HH:mm A').diff(moment().startOf('day'), 'seconds');
      });

      schedule.start_dt = moment(schedule.start_dt)
        .startOf('day')
        .unix();
      schedule.end_dt = moment
        .utc(schedule.end_dt)
        .endOf('day')
        .unix();
      const scheduleData = {
        schedule_name: schedule.schedule_name,
        schedule_type: schedule.schedule_type,
        offset: schedule.offset,
        medication_name: schedule.medication_name,
        start_dt: schedule.start_dt,
        end_dt: schedule.end_dt,
        dose: `${schedule.dose}`,
        rxnorm_code: schedule?.rxnorm_code,
        schedule_timezone: schedule.schedule_timezone,
      };
      const { enableEndDate } = this.state;
      if (!enableEndDate) {
        delete scheduleData.end_dt;
      }
      delete schedule.dose;
      if (schedule.masked_id) {
        delete scheduleData.schedule_timezone;
        delete scheduleData.dose;
        scheduleData.masked_id = schedule.masked_id;
      }

      if (!this.props.isLoading) {
        this.props.onSubmit(scheduleData).then(response => {
          if (
            response.type === ADD_SCHEDULE_ERROR ||
            (response.type === EDIT_SCHEDULE_ERROR && response.response.data)
          ) {
            const responseData = response.response.data.error;
            const errorsData = [responseData.message];
            this.setState({ errors: errorsData });
          }
          return response;
        });
      }
    }
  };

  updateTimeByKey = (item, operation, index) => {
    this.updateTime(item, operation, index);
  };

  updateTime = (item, operation, index) => {
    const schedule = cloneDeep(this.state.schedule);
    if (item === 'HH' || item === 'PM' || item === 'AM') {
      if (operation === 'DOWN') {
        schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
          .subtract(1, 'hours')
          .format('hh:mm a');
      } else if (item === 'PM' || item === 'AM') {
        schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
          .add(12, 'hours')
          .format('hh:mm a');
      } else {
        schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
          .add(1, 'hours')
          .format('hh:mm a');
      }
    }
    if (item === 'MM') {
      if (operation === 'DOWN') {
        schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
          .subtract(5, 'minutes')
          .format('hh:mm a');
      } else {
        schedule.time[index] = moment(schedule.time[index], ['hh:mm A'])
          .add(5, 'minutes')
          .format('hh:mm a');
      }
    }
    this.setState({ schedule });
    this.forceUpdate();
  };

  updateDose = e => {
    const schedule = cloneDeep(this.state.schedule);
    if (e.target.value === '' || NUMBER_PATTERN.test(e.target.value)) {
      schedule.dose = e.target.value;
      let len = parseInt(schedule.dose, 10);
      if (len > 8) {
        schedule.dose = 8;
        len = 8;
        this.setState({ schedule });
      }
      schedule.time = [];
      /* eslint-disable */
      _.times(len, i => {
        schedule.time.push('10:00 AM');
      });
      /* eslint-enable */
      this.setState({ schedule });
    }
  };

  updateName = e => {
    const schedule = cloneDeep(this.state.schedule);
    if (e.target.value == ' ') {
      schedule.schedule_name = '';
      this.setState({ schedule });
    } else {
      schedule.schedule_name = e.target.value;
      this.setState({ schedule });
    }
  };

  previous = () => {
    this.setState(() => ({ errors: [] }));
    this.setState(state => ({ page: Math.max(state.page - 1, 0) }));
  };

  next = () => {
    this.setState(() => ({ errors: [] }));
    this.setState(state => ({ page: Math.min(state.page + 1) }));
  };

  handleValue = value => {
    if (value?.code) {
      const schedule = cloneDeep(this.state.schedule);
      schedule.medication_name = value.rxName;
      schedule.rxnorm_code = value.code;

      this.setState({ schedule });
    } else {
      const schedule = cloneDeep(this.state.schedule);
      schedule.medication_name = value;
      this.setState({ schedule });
    }
  };

  handleInfimumChange = m => {
    const schedule = cloneDeep(this.state.schedule);
    schedule.start_dt = m.startOf('day').toDate();
    this.setState({ schedule });
    this.forceUpdate();
  };

  handleSupremumChange = m => {
    const schedule = cloneDeep(this.state.schedule);
    schedule.end_dt = m.endOf('day').toDate();
    this.setState({ schedule });
  };

  onEndDateToggle = (sw, checked) => {
    sw.setBusy(true);
    this.setState({ enableEndDate: checked });
    sw.setBusy(false);
    sw.setChecked(checked);
  };

  renderStepsProgress() {
    const { page } = this.state;
    const colorTheme = getColorsForColorTheme();
    const progressStyles = {
      height: '8px',
      width: `${(page * 100) / 5}%`,
      //todo!!!
      backgroundColor: colorTheme.mainColor,
      borderRadius: '4px',
    };

    return (
      <div className="stepProgress">
        <div style={progressStyles} />
      </div>
    );
  }

  onTimezoneChange = option => {
    const schedule = cloneDeep(this.state.schedule);
    schedule.schedule_timezone = option.value;
    this.setState({ schedule });
  };

  render() {
    const { onOpen, onCancel, data, ...props } = this.props;
    const { errors, schedule, page, titles, enableEndDate } = this.state;

    const timezones = GetTimezoneTextMapping();

    const propsDatePickerInfimum = {
      minDate: today,
      maxDate: yearAhead,
      selected: moment(schedule.start_dt ?? today, DATE_FORMAT),
      dateFormat: DATE_FORMAT_FOR_SLIDER,
      onChange: this.handleInfimumChange,
    };

    const propDatePickerSupermum = {
      minDate: moment(schedule.start_dt, DATE_FORMAT),
      maxDate: yearAhead,
      selected: moment(schedule.end_dt ?? today, DATE_FORMAT),
      dateFormat: DATE_FORMAT_FOR_SLIDER,
      onChange: this.handleSupremumChange,
    };
    /* eslint-disable */
    return (
      <Modal
        name="add-schedule-to-the-cap"
        withoutContainer
        dditionalClasses={['form-modal', 'user-invite']}
        onOpen={onOpen}
        {...props}
      >
        <LoadingRenderer loading={false}>
          <div className="wizardWrapper wizardAdaptiveLayout add-schedule-to-the-cap">
            <header className="wizardHeader">
              {page != 1 && <div className="prevButton" onClick={this.previous} />}
              {titles[page - 1]}
            </header>
            {this.renderStepsProgress()}
            <div className="wizardContent">
              <Form onSubmit={this.onSubmit} onCancel={onCancel} id="add-schedule-to-the-cap">
                <div className="schedule-add content-block" key="schedule-form">
                  {page === 1 && (
                    <div>
                      <div className="row ">
                        <div>{Strings.schedules.addName}</div>
                        <span>
                          <Input
                            name="schedule_name"
                            id="addName"
                            label=""
                            placeholder={Strings.schedules.enterName}
                            type="text"
                            value={schedule.schedule_name}
                            onChange={this.updateName}
                          />
                        </span>
                      </div>
                    </div>
                  )}

                  {page === 2 && (
                    <div>
                      <div className="row" style={{ marginTop: '2px' }}>
                        {schedule.masked_id ? (
                          <Input
                            name="medication_name"
                            id="medication_name"
                            label=""
                            placeholder={Strings.schedules.title2}
                            type="text"
                            defaultValue={schedule.medication_name}
                            disabled
                          />
                        ) : (
                          <AutoSuggestionInput handleValue={this.handleValue} value={schedule.medication_name} />
                        )}
                      </div>
                    </div>
                  )}

                  {page === 3 && (
                    <div>
                      <div className="row title">
                        <span>{Strings.patient_medications.frequency}</span>
                        <span>
                          {schedule.frequency && (
                            <Input
                              name="frequency"
                              id="frequency"
                              label=""
                              placeholder={Strings.patient_medications.frequency}
                              type="text"
                              defaultValue={schedule.frequency}
                              disabled
                            />
                          )}
                        </span>
                      </div>

                      <div className="row title">
                        <span>{Strings.patient_medications.timesPerDay}</span>
                        <span>
                          <input
                            name="dose"
                            id="dose"
                            label=""
                            placeholder={Strings.patient_medications.dose}
                            type="text"
                            value={schedule.dose}
                            onChange={this.updateDose}
                          />
                        </span>
                      </div>

                      <div
                        style={{
                          maxHeight: '185px',
                          overflowY: 'auto',
                        }}
                      >
                        {schedule.time && Array.isArray(schedule.time)
                          ? schedule.time.map((schedule, index) => {
                              return (
                                <div className="row title" key={index}>
                                  <span>
                                    {Strings.patient_medications.time} {index + 1}
                                  </span>
                                  <div className="timePicker">
                                    <div className="img-input-container">
                                      <a className="step up" onClick={() => this.updateTime('HH', 'UP', index)} />

                                      <input
                                        className="timeStep"
                                        onChange={this.onChange}
                                        type="text"
                                        id="hh"
                                        name="hh"
                                        label=""
                                        value={
                                          parseInt(schedule.split(':')[0], 10) <= 9
                                            ? `0${parseInt(schedule.split(':')[0], 10)}`
                                            : parseInt(schedule.split(':')[0], 10)
                                        }
                                        placeholder={Strings.patient_medications.time}
                                        onKeyDown={() => this.updateTime('HH', 'UP', index)}
                                        ref={this.scheduleFormRef}
                                        tabIndex={0}
                                      />
                                      <a className="step down" onClick={() => this.updateTime('HH', 'DOWN', index)} />
                                    </div>
                                    <div className="divider">:</div>
                                    <div className="img-input-container">
                                      <div className="step up" onClick={() => this.updateTime('MM', 'UP', index)} />
                                      <input
                                        className="timeStep"
                                        onChange={this.onChange}
                                        type="text"
                                        id="mm"
                                        label=""
                                        value={
                                          parseInt(schedule.split(':')[1], 10) <= 9
                                            ? `0${parseInt(schedule.split(':')[1], 10)}`
                                            : parseInt(schedule.split(':')[1], 10)
                                        }
                                        placeholder={Strings.patient_medications.time}
                                        onKeyDown={() => this.updateTime('MM', 'UP', index)}
                                        tabIndex={0}
                                      />
                                      <div className="step down" onClick={() => this.updateTime('MM', 'DOWN', index)} />
                                    </div>
                                    <div className="ampmSwitch">
                                      <div
                                        className={`am block  ${
                                          schedule.split(' ')[1] === 'am' || schedule.split(' ')[1] === 'AM'
                                            ? 'active'
                                            : ''
                                        }`}
                                        onClick={() => this.updateTime('AM', 'UP', index)}
                                        tabIndex={0}
                                        onKeyDown={e => {
                                          const charCode = e.which ? e.which : e.keyCode;
                                          if (charCode === 13) {
                                            this.updateTime('AM', 'UP', index);
                                          }
                                        }}
                                      >
                                        {Strings.patient_medications.am}
                                      </div>
                                      <div
                                        className={`pm block ${
                                          schedule.split(' ')[1] === 'pm' || schedule.split(' ')[1] === 'PM'
                                            ? 'active'
                                            : ''
                                        }`}
                                        onClick={() => this.updateTime('PM', 'UP', index)}
                                        tabIndex={0}
                                        onKeyDown={e => {
                                          const charCode = e.which ? e.which : e.keyCode;
                                          if (charCode === 13) {
                                            this.updateTime('PM', 'UP', index);
                                          }
                                        }}
                                      >
                                        {Strings.patient_medications.pm}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : ''}
                      </div>
                      <div className="row title">
                        <span>{Strings.schedules.timezone}</span>

                        {schedule.masked_id ? (
                          <Input
                            name="filterByMedicine"
                            id="set_timezone"
                            label=""
                            placeholder={Strings.schedules.title2}
                            type="text"
                            defaultValue={schedule.schedule_timezone_edit}
                            disabled
                          />
                        ) : (
                          ''
                        )}

                        {!schedule.masked_id && (
                          <div className="select-timezone left-select">
                            <Select
                              name="filterByMedicine"
                              onChange={this.onTimezoneChange}
                              value={schedule.schedule_timezone}
                              className="right timezone"
                              data={Object.keys(timezones).map(key => {
                                return { label: timezones[key], value: key };
                              })}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {page === 4 && (
                    <div>
                      <div className="row title">
                        <span>{Strings.schedules.startDate}</span>
                        <span>{schedule.frequency && <ManualDatePicker {...propsDatePickerInfimum} />}</span>
                      </div>

                      <div className="row title">
                        <div className="switch-text">
                          {!enableEndDate ? Strings.schedules.enableEndDate : Strings.schedules.disableEndDate}
                        </div>
                        <Switch2 checked={enableEndDate} onBeforeChange={this.onEndDateToggle} />
                      </div>

                      {enableEndDate && (
                        <div className="row title">
                          <span>{Strings.schedules.endDate}</span>
                          <span>{schedule.frequency && <ManualDatePicker {...propDatePickerSupermum} />}</span>
                        </div>
                      )}
                    </div>
                  )}

                  {page === 5 && (
                    <div>
                      <div className="flex-container">
                        <div className="flex-left">
                          <span className="dark-text">{Strings.name}</span>
                        </div>
                        <div className="flex-right">{schedule.schedule_name}</div>
                      </div>

                      <div className="flex-container">
                        <div className="flex-left">
                          <span className="dark-text">{Strings.medication}</span>
                        </div>
                        <div className="flex-right">{schedule.medication_name}</div>
                      </div>

                      <div className="flex-container">
                        <div className="flex-left">
                          <span className="dark-text">{Strings.schedules.scheduleType}</span>
                        </div>
                        <div className="flex-right">{schedule.frequency}</div>
                      </div>

                      <div className="flex-container">
                        <div className="flex-left">
                          <span className="dark-text">{Strings.patient_medications.timesPerDay}</span>
                        </div>
                        <div className="flex-right">{schedule.dose}</div>
                      </div>

                      <div className="flex-container">
                        <div className="flex-left">
                          <span className="dark-text">{Strings.schedules.time}</span>
                        </div>
                        <div
                          className="flex-right"
                          style={{
                            maxHeight: '85px',
                            overflowY: 'auto',
                          }}
                        >
                          {schedule.time.map(t => {
                            return <div> {moment(t, ['hh:mm A']).format('hh:mm A')}</div>;
                          })}{' '}
                          {schedule.schedule_timezone ? `(${timezones[schedule.schedule_timezone]})` : ''}
                        </div>
                      </div>

                      <div className="flex-container">
                        <div className="flex-left">
                          <span className="dark-text">{Strings.startDate}</span>
                        </div>
                        <div className="flex-right">
                          {schedule.start_dt ? moment(schedule.start_dt).format(DATE_FORMAT_MONTH_NAME) : ''}
                        </div>
                      </div>

                      {enableEndDate ? (
                        <div className="flex-container">
                          <div className="flex-left">
                            <span className="dark-text">{Strings.endDate}</span>
                          </div>
                          <div className="flex-right">
                            {schedule.end_dt ? moment(schedule.end_dt).format(DATE_FORMAT_MONTH_NAME) : ''}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                  <div className="clear" />
                  {errors.length ? (
                    <div id="errors" className="errors-block">
                      {errors.join(' ')}
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="buttons" key="button-bar">
                    <button className="brand-white-gray" key="cancel" type="cancel">
                      {Strings.cancel}
                    </button>

                    <button className="brand-blue" key="submit" type="submit">
                      {page === 5 ? Strings.save : Strings.next}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </LoadingRenderer>
      </Modal>
    );
    /* eslint-enable */
  }
}

AddScheduleToTheCapModal.propTypes = {
  data: PropTypes.shape({ schedule: PropTypes.any }),
  isLoading: PropTypes.any,
  onCancel: PropTypes.any,
  onOpen: PropTypes.any,
  onSubmit: PropTypes.func,
};

const mapStateToProps = state => {
  const { schedules } = state.superUser;

  return {
    isLoading: schedules && schedules?.isLoading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('add-schedule-to-the-cap')),
  onSubmit: data => {
    if (data.masked_id) {
      return dispatch(actions.editSchedule(data)).then(response => {
        if (response && response.type === EDIT_SCHEDULE_RESULT) {
          dispatch(ownProps.data.action(ownProps.data.pageRequest));
          dispatch(closeModal('add-schedule-to-the-cap'));
        }

        return response;
      });
    }
    return dispatch(actions.addSchedule(data)).then(response => {
      if (response && response.type === ADD_SCHEDULE_RESULT) {
        dispatch(ownProps.data.action(ownProps.data.pageRequest));
        dispatch(closeModal('add-schedule-to-the-cap'));
      }

      return response;
    });
  },
  onNavigate: path => dispatch(push(path)),
  onOpen: () => dispatch(resetForm('add-schedule-to-the-cap', ownProps.data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddScheduleToTheCapModal);
