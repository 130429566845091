import { MOBILE_PHONE_VERIFY_RESULT, MOBILE_PHONE_VERIFY_ERROR } from '../actions/action-types';

const userMobilePhoneVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case MOBILE_PHONE_VERIFY_RESULT: // eslint-disable-line no-case-declarations
      const messages = action.response.success.messages; // eslint-disable-line prefer-destructuring

      let error = false;
      let codeError = false;

      if (messages.length) {
        if (messages.filter(message => message.code === 272).length) {
          // duplicate field error
          error = true;
        } else {
          codeError = true;
        }
      }

      return {
        ...state,
        error,
        codeError,
      };
    case MOBILE_PHONE_VERIFY_ERROR:
      return {
        ...state,
        error: true,
        codeError: false,
      };
    default:
      return state;
  }
};

export default userMobilePhoneVerifyReducer;
