import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { PASSWORD_PATTERN_EXP } from '../../constants';
import Strings from '../../Strings';

class PasswordBox extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
  };

  render() {
    const errorMsg = Strings.errors.invalidPassword;
    return (
      <React.Fragment>
        <input
          className={this.props.className}
          onChange={this.props.onChange}
          type={this.props.type}
          required
          pattern={PASSWORD_PATTERN_EXP}
          title={errorMsg}
          name={this.props.name}
          value={this.props.value}
          placeholder={this.props.placeholder}
        />
      </React.Fragment>
    );
  }
}

export default PasswordBox;
