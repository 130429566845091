import { Button } from 'components/Buttons/Button';
import { PhoneInput } from 'components/Inputs/PhoneInput';
import { ButtonRow } from 'components/Login/ButtonRow/ButtonRow';
import { Errors } from 'components/Login/Errors/Errors';
import { Text } from 'components/Login/Text/Text';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { BUTTON_TYPE_DARK_BLUE } from '../../../../components/Buttons/Button';
import Strings from '../../../../Strings';

class Step1_EnterPhoneNumber extends PureComponent {
  state = {
    errors: [],
    isPhoneEmpty: true,
  };

  refPhone = React.createRef();

  onPhoneChange = event => {
    this.setState({ isPhoneEmpty: event.target.value.trim() === '' });
  };

  setError(error) {
    this.setState(state => ({ errors: [...state.errors, error] }));
  }

  clearError() {
    this.setState({ errors: [] });
  }

  // Internal error is linked to outer error from props
  static getDerivedStateFromProps(props) {
    if (props.error) {
      return { errors: [props.error] };
    }

    return null;
  }

  onFormSubmit = event => {
    const { onFormSubmit } = this.props;

    event.preventDefault();
    this.clearError();

    const phoneInput = this.refPhone.current;

    const err = phoneInput.getValidationErrorMessage();

    if (err) {
      this.setError(err);
      return;
    }

    onFormSubmit({ phone: phoneInput.getNumber() });
  };

  render() {
    const { errors, isPhoneEmpty } = this.state;
    const { isLoading, text } = this.props;

    let txt;

    if (!errors || errors.length === 0) {
      txt = text ? (
        <Text>{text}</Text>
      ) : (
        <Text>
          To login to your <span style={{ color: '#0060a6' }}>Lid Sync</span> account, please enter your mobile phone
          number below.
        </Text>
      );
    } else {
      txt = <Errors NoMT errors={errors} />;
    }

    return (
      <form onSubmit={this.onFormSubmit}>
        <div>{txt}</div>

        <PhoneInput ref={this.refPhone} align="left" onChange={this.onPhoneChange} placeholder={Strings.phoneNumber} />

        <ButtonRow
          full={
            <Button
              buttonType={BUTTON_TYPE_DARK_BLUE}
              style={{ margin: '0px' }}
              type="submit"
              disabled={isPhoneEmpty || isLoading}
            >
              {Strings.next}
            </Button>
          }
        />
      </form>
    );
  }
}

Step1_EnterPhoneNumber.propTypes = {
  isLoading: PropTypes.any,
  onFormSubmit: PropTypes.func,
  text: PropTypes.any,
};

export default Step1_EnterPhoneNumber;
