import APIAction from '../ApiActions';
import { API_CALL } from '../middleware/api';
import { LIST_COUNTRIES } from './action-types';

export const getCountries = () => ({
  type: LIST_COUNTRIES,
  [API_CALL]: {
    endpoint: 'portal/country-time-zones',
    method: APIAction.GET,
  },
});
