import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import classNames from 'classnames';

import stylesTI from '../Input.module.scss';
import styles from './CodeInput.module.scss';
import Strings from '../../../Strings';

export class CodeInput extends PureComponent {
  inputs = [];
  inputsRefs = [];

  constructor(props) {
    super(props);
    const { num = 6 } = this.props;

    if (props.val) {
      for (let i = 0; i < num; i += 1) {
        const ref = React.createRef();
        this.inputsRefs.push(ref);
        this.inputs.push(
          <React.Fragment>
            <label htmlFor={`code${i + 1}`} class="visuallyhidden">
              {Strings.pleaseEnterCodeAL} {i + 1} {Strings.charachterAL}
            </label>
            <input
              ref={ref}
              key={i}
              value={props.val[i] ? props.val[i] : ''}
              type="text"
              inputmode="numeric"
              maxLength="1"
              id={`code${i + 1}`}
              className={stylesTI.TextInput}
              onChange={e => this.onChange(e, i)}
              onKeyDown={e => this.onKeyDown(e, i)}
              tabIndex={0}
              data-testid={`code[${i + 1}]`}
            />
          </React.Fragment>,
        );
      }
    } else {
      for (let i = 0; i < num; i += 1) {
        const ref = React.createRef();
        this.inputsRefs.push(ref);
        this.inputs.push(
          <React.Fragment>
            <label htmlFor={`code${i + 1}`} class="visuallyhidden">
              {Strings.pleaseEnterCodeAL} {i + 1} {Strings.charachterAL}
            </label>
            <input
              ref={ref}
              key={i}
              type="text"
              inputmode="numeric"
              maxLength="1"
              id={`code${i + 1}`}
              className={stylesTI.TextInput}
              onChange={e => this.onChange(e, i)}
              onKeyDown={e => this.onKeyDown(e, i)}
              tabIndex={0}
              data-testid={`code[${i + 1}]`}
            />
          </React.Fragment>,
        );
      }
    }
  }

  componentDidMount() {
    this.inputsRefs[0].current.focus();
  }

  onKeyDown = (e, key) => {
    const { isCharAllowed } = this.props;
    if (e.key !== 'Backspace') {
      if (!isCharAllowed) {
        if (e.key.length === 1 && /\D/.test(e.key)) {
          e.preventDefault();
        }
      }
      return;
    }

    if (e.target.value.trim() === '' && key > 0) {
      this.inputsRefs[key - 1].current.focus();
    }
  };

  onChange = (e, key) => {
    const { onChange } = this.props;

    const val = e.target.value.trim().substr(0, 1);

    e.target.value = val;
    if (val !== '') {
      if (key < this.inputsRefs.length - 1) {
        this.inputsRefs[key + 1].current.focus();
      }
    }

    if (onChange) onChange(e);
  };

  getValue() {
    return this.inputsRefs
      .map(ref => ref.current.value)
      .join('')
      .trim();
  }

  clear() {
    /* eslint-disable */
    this.inputsRefs.forEach(ref => {
      ref.current.value = '';
    });
    /* eslint-enable */
  }

  render() {
    const { isEsi } = this.props;
    return <div className={classNames(styles.CodeInput, isEsi && styles.isEsi)}>{this.inputs}</div>;
  }
}

CodeInput.propTypes = {
  isCharAllowed: PropTypes.any,
  isEsi: PropTypes.any,
  num: PropTypes.number,
  onChange: PropTypes.func,
  val: PropTypes.any,
};
