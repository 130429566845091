import PropTypes from 'prop-types';
import React from 'react';

import { Errors } from '../../components/Login/Errors/Errors';
import { WizardForm } from '../../components/WizardForm/WizardForm';
import { TextInputField } from '../../fields/TextInputField';
import Strings from '../../Strings';
import { composeValidators } from '../../utils/validators/composeValidators';
import { makeRequired } from '../../utils/validators/isRequired';
import { SwitchField } from '../../fields/SwitchField';
import { makeAboveZero } from '../../utils/validators/isAboveZero';
import SimpleBar from 'simplebar-react';
import styles from './OrgOutboundAPIForm.module.scss';

export class OrgOutboundAPIForm extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    admin: PropTypes.object,
    errors: PropTypes.any,
  };

  state = { values: null };

  prevValues = {};

  phoneRef = React.createRef();

  setValues = values => {
    this.setState({ values });
  };

  phoneValidate = () => {
    const phoneInput = this.phoneRef.current;
    if (!this.phoneRef || !phoneInput) {
      return undefined;
    }
    return phoneInput.isValidNumber() ? undefined : phoneInput.getValidationErrorMessage(); // ONLY FOR DEV TODO REMOVE ME LATER
  };

  checkForError(name, value) {
    const { errors = [] } = this.props;
    const errorMessage = errors[name];

    if (errorMessage) {
      if (typeof this.prevValues[name] === 'undefined') {
        this.prevValues[name] = value;
      }

      if (this.prevValues[name] === value) {
        return errorMessage.join('. ');
      }
    }

    return null;
  }

  render() {
    const { onSubmit, onCancel, isLoading, admin, errors = [] } = this.props;

    if (isLoading) {
      this.prevValues = {};
    }

    const title = admin ? Strings.editOutboundAPI : Strings.addOutboundAPI;
    const saveBtn = admin ? 'Save' : 'Add';

    return (
      <WizardForm
        initialValues={admin}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onNext={this.setValues}
        titles={[title]}
        submitButtonTitle={[saveBtn]}
        disableButtons={isLoading === true}
      >
        <WizardForm.Page>
          <TextInputField
            name="name"
            placeholder={Strings.name}
            align="left"
            validate={composeValidators(makeRequired(Strings.fieldIsRequired))}
          />
          <TextInputField
            name="host"
            placeholder={Strings.host}
            align="left"
            validate={composeValidators(makeRequired(Strings.fieldIsRequired))}
          />
          <TextInputField
            name="url"
            placeholder={Strings.url}
            align="left"
            validate={composeValidators(makeRequired(Strings.fieldIsRequired))}
          />
          <TextInputField
            name="auth_key"
            placeholder={Strings.secretKey}
            align="left"
            validate={composeValidators(makeRequired(Strings.fieldIsRequired))}
          />
          <TextInputField name="auth_key_header_parameter" placeholder={Strings.authenticationHeader} align="left" />
          <TextInputField
            name="version"
            placeholder={Strings.version}
            align="left"
            validate={composeValidators(makeRequired(Strings.fieldIsRequired))}
          />
          <SwitchField name="paused" align="left" value={false} text={Strings.pauseAPI} />
          <SwitchField name="success_field_required" align="left" value={false} text={Strings.successfieldRequired} />
          <TextInputField name="max_time_without_events" placeholder={Strings.maxTimeWithoutEvents} align="left" />
          <TextInputField
            name="max_batch_size"
            placeholder={Strings.maxBatchSize}
            align="left"
            validate={composeValidators(makeAboveZero(Strings.fieldIsRequired))}
          />
          {errors.length > 0 && <Errors errors={errors} />}
        </WizardForm.Page>
      </WizardForm>
    );
  }
}
