import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { BarChart, ReferenceArea, ReferenceLine, ResponsiveContainer, Bar, Tooltip, XAxis, YAxis } from 'recharts';

import { DATE_FORMAT_YEAR_MONTH_DAY, TIME_FORMAT_12_LOWERCASE } from '../../constants';
import {
  GRAPH_AXIS_LINE_COLOR,
  GRAPH_AXIS_LINE_WIDTH,
  MINIMUM_PIXELS_PER_DAY,
  OPTIMUM_PIXELS_PER_DAY_SLEEP,
  RANGE_PICKER_HEIGHT,
  X_VALUE_WEEK_PREFIX,
} from './GraphConstants';
import './graph.scss';
import { CustomizedXAxisTick, calcDays, referenceAreas } from './GraphComponents';
import { DEFAULT_DATE_RANGE_DAYS } from './CompositeGraph/Metrics';
import { RangePicker } from './CompositeGraph/RangePicker';

const graphAxisFontSize = 12;
const graphYAxisFontColor = '#4F4F4F';
const graphAxisFontColorWeekend = '#bbb';

const graphDotColorLightSleep = '#5EC4FC';
const graphDotColorDeepSleep = '#645EFC';
const graphDotColorRemSleep = '#FC5EDC';
const graphDotColorNoSleep = '#FCAC5E';
const graphMaxBarSize = 6;

const graphWarningFillOpacity = 0.1;
const graphWarningFill = '#F00';

const graphReferenceVerticalLineColor = '#eeeeee';

const CustomizedYAxisTick = props => {
  const { x, y, payload, axisMin, axisLine, sleepStartTick, sleepEndTick, dateMin, dateMax } = props; // stroke

  if (payload.value === axisMin && dateMin instanceof moment && dateMax instanceof moment) {
    return (
      <g transform={`translate(${x},${y})`}>
        <rect
          x={-x}
          y={0 - payload.offset - axisLine.strokeWidth / 2}
          width={x + 1}
          height={axisLine.strokeWidth}
          fill={axisLine.stroke}
        />
        <rect
          y={-y}
          x={0 - payload.offset - axisLine.strokeWidth / 2 + GRAPH_AXIS_LINE_WIDTH}
          height={y + 1}
          width={axisLine.strokeWidth}
          fill={axisLine.stroke}
        />
        <text
          className="span"
          fontSize={graphAxisFontSize}
          dy={-35}
          dx={-55}
          textAnchor="start"
          fill={graphAxisFontColorWeekend}
        >
          {dateMin.format('MMM D -').toUpperCase()}
        </text>
        <text
          className="span"
          fontSize={graphAxisFontSize}
          dy={-20}
          dx={-55}
          textAnchor="start"
          fill={graphAxisFontColorWeekend}
        >
          {dateMax.format('MMM D').toUpperCase()}
        </text>
      </g>
    );
  }

  if (payload.value === sleepStartTick || payload.value === sleepEndTick) {
    const leadingZero = payload.value >= 0 && payload.value < 10 ? '0' : '';
    const value = payload.value < 0 ? 24 + payload.value : payload.value;
    const postfix = payload.value < 0 ? ':00 pm' : ':00 am';

    if (payload.value === sleepStartTick) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            className="span"
            fontSize={graphAxisFontSize}
            dy={5}
            dx={-10}
            textAnchor="end"
            fill={graphYAxisFontColor}
          >
            &#9790;&nbsp;
            {leadingZero}
            {value}
            {postfix}
          </text>
        </g>
      );
    }

    return (
      <g transform={`translate(${x},${y})`}>
        <text className="span" fontSize={graphAxisFontSize} dy={5} dx={-10} textAnchor="end" fill={graphYAxisFontColor}>
          &#9728;&nbsp;
          {leadingZero}
          {value}
          {postfix}
        </text>
      </g>
    );
  }

  return null;
};
CustomizedYAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.any,
  payload: PropTypes.any,
  axisMin: PropTypes.number,
  sleepStartTick: PropTypes.number,
  sleepEndTick: PropTypes.number,
  dateMin: PropTypes.any,
  dateMax: PropTypes.any,
  axisLine: PropTypes.any,
};

export function WarningReferenceArea(props) {
  const { x, y, width, height, fill, fillOpacity } = props;
  const paddingW = 5;
  const paddingH = 8;

  return (
    <g>
      <rect
        x={x + paddingW}
        y={paddingH}
        width={width - 2 * paddingW}
        height={height + y - 2 * paddingH}
        fillOpacity={fillOpacity}
        fill={fill}
        rx={5}
        ry={5}
      />
    </g>
  );
}
WarningReferenceArea.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.any,
  fillOpacity: PropTypes.number,
  viewBox: PropTypes.any,
};

function formatSecondsAsHours(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor(seconds / 60) % 60;

  return `${h}h ${m}min`;
}

export default function SleepGraphDaily(props) {
  const [graphWidth, setGraphWidth] = useState();
  const [dateRange, setDateRange] = useState({
    start: moment()
      .subtract(DEFAULT_DATE_RANGE_DAYS, 'days')
      .startOf('day'),
    end: moment()
      .add(1, 'days')
      .startOf('day'),
  });
  const [maxRange, setMaxRange] = useState({
    start: props.minDate.clone().startOf('day'),
    end: props.maxDate
      .clone()
      .add(1, 'days')
      .startOf('day'),
  });
  const graphContainerRef = useRef();

  useEffect(() => {
    const width = graphContainerRef?.current?.clientWidth;
    setGraphWidth(width);
    setDateRange({
      start: moment()
        .subtract(width / OPTIMUM_PIXELS_PER_DAY_SLEEP, 'days')
        .startOf('day'),
      end: moment()
        .add(1, 'days')
        .startOf('day'),
    });
  }, [graphContainerRef.current]);

  const fixDays = (xyzData, days) => {
    const ret = [];

    let weekNo = 1;

    for (let index = 0; index <= days.length - 1; index += 1) {
      const thisDay = days[index].format(DATE_FORMAT_YEAR_MONTH_DAY);
      const daySleeps = xyzData.filter(x => x.x === thisDay);
      if (daySleeps.length > 0) ret.push({ date: days[index], x: thisDay, sleeps: daySleeps });
      else ret.push({ date: days[index], x: thisDay });
    }

    return ret;
  };

  const calcYAxisParams = (rawData, days) => {
    let dataMin = 24;
    days.forEach(day => {
      const thisDay = day.format(DATE_FORMAT_YEAR_MONTH_DAY);
      const daySleeps = rawData
        .filter(x => x.x === thisDay)
        .map(x =>
          x.sleepStart.format(DATE_FORMAT_YEAR_MONTH_DAY) === thisDay ? x.sleepStartHour : x.sleepStartHour - 24,
        );
      if (daySleeps.length > 0) {
        const min = Math.min(...daySleeps);
        if (min < dataMin) dataMin = min;
      }
    });
    const dataMax = Math.max(...rawData.map(x => x.sleepEndHour));

    const extraMargin = 0; // 2 or 0

    const axisTick = 2;
    const axisHalfTick = 1;
    const axisMin =
      (dataMin >= 0 ? dataMin - (dataMin % axisTick) - axisHalfTick : dataMin + (-dataMin % axisTick) - axisHalfTick) -
      1 -
      extraMargin;
    const axisMax = dataMax - (dataMax % axisTick) + axisHalfTick + 1 + extraMargin;
    const axisDelta = axisMax - axisMin;

    return {
      dataMin,
      dataMax,
      axisMin,
      axisMax,
      axisTick,
      axisTicks: axisDelta / axisTick + 1,
    };
  };

  const onClick = e => {
    if (props.onDayClick) {
      props.onDayClick(e.date);
    }
  };

  const calcParts = (fixedData, yAxis) => {
    return fixedData.map(d1 => {
      const y = yAxis.axisMax;
      const d2 = { date: d1.date, x: d1.x, y, data: d1, yAxis };
      return d2;
    });
  };

  const normalizePair = (min1, max1, val1_1, val1_2, min2, max2) => {
    let val2_1 = min2;
    let val2_2 = max2;

    if (val1_1 <= min1) val2_1 = min2;
    else if (val1_1 >= max1) val2_1 = max2;
    else {
      const ratio = (val1_1 - min1) / (max1 - min1);
      val2_1 = min2 + Math.round((max2 - min2) * ratio);
    }

    if (val1_2 <= min1) val2_2 = min2;
    else if (val1_2 >= max1) val2_2 = max2;
    else {
      const ratio = (val1_2 - min1) / (max1 - min1);
      val2_2 = min2 + Math.round((max2 - min2) * ratio);
    }

    return { v1: val2_1, v2: val2_2 };
  };

  const calcSubParts = (yAxis, yStart, yEnd, data) => {
    if (!data || !data.sleeps || data.sleeps.length === 0) return null;

    const parts = [];
    data.sleeps.forEach(sleep => {
      const yStartToday = data.x === sleep.sleepStart.format(DATE_FORMAT_YEAR_MONTH_DAY);

      const durationMinutesStart =
        sleep.sleepStart.hours() * 60 + sleep.sleepStart.minutes() - (yStartToday ? 0 : 24 * 60);
      let durationMinutesSum = 0;

      sleep.parts.forEach(part => {
        const partStart = (durationMinutesStart + durationMinutesSum) / 60;
        const partEnd = (durationMinutesStart + durationMinutesSum + part.duration) / 60;
        const type = part.depth[0];
        const p = normalizePair(yAxis.axisMin, yAxis.axisMax, partStart, partEnd, yStart, yEnd);
        parts.push({ y1: p.v1, y2: p.v2, type });
        durationMinutesSum += part.duration;
      });
    });

    return parts;
  };

  const { graphData, selectedPhase } = props;

  const rawData = graphData;
  const days = calcDays(dateRange.start, dateRange.end);
  const yAxisParams = calcYAxisParams(rawData, days);
  const fixedData = fixDays(rawData, days);
  const partsData = calcParts(fixedData, yAxisParams);

  const badDay = day => {
    if (day.date >= moment()) return false;
    if (!day.sleeps || day.sleeps.length === 0) return true;
    const sleepTime = day.sleeps.reduce((partialSum, a) => partialSum + a.sleepEnd.diff(a.sleepStart) / 1000 / 60, 0);

    return sleepTime < 7 * 60;
  };

  const warningDays = fixedData.filter(d => d.date && badDay(d)).map(d => d.date.format(DATE_FORMAT_YEAR_MONTH_DAY));

  const CustomTooltip = ({ active, label }) => {
    if (active && fixedData) {
      const payload = fixedData.filter(d => d.x === label && d.sleeps != null && d.sleeps.length > 0);

      if (payload && payload.length > 0) {
        return (
          <div className="customTooltip">
            <div>
              <p className="customTooltipTitle">
                Sleep
                <br />
                {moment(payload[0].date).format('ll')}
              </p>
              <div className="customTooltipDescr">
                {payload[0].sleeps.map((sleep, i) => (
                  <div key={`ttline_${i}`}>
                    {formatSecondsAsHours(sleep.sleepEnd.diff(sleep.sleepStart) / 1000)} of sleep starting at{' '}
                    {sleep.sleepStart.format(TIME_FORMAT_12_LOWERCASE)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      }
    }
    return null;
  };

  const DayBar = props => {
    const { x, width, payload, background } = props;

    if (payload.data && payload.data.sleeps && payload.data.sleeps.length > 0) {
      const yStart = background.y;
      const yEnd = background.y + background.height;

      const parts = calcSubParts(payload.yAxis, yStart, yEnd, payload.data);
      if (!parts) return <></>;

      const ret = (
        <g>
          {parts.map((i, index) => {
            const color =
              i.type === 'l'
                ? graphDotColorLightSleep
                : i.type === 'd'
                ? graphDotColorDeepSleep
                : i.type === 'r'
                ? graphDotColorRemSleep
                : graphDotColorNoSleep;

            const opacity = !selectedPhase ? '100%' : selectedPhase[0] === i.type ? '100%' : '10%';

            return (
              <rect
                key={`${x}_part_${index}`}
                x={background.x}
                y={i.y1}
                width={width}
                height={i.y2 - i.y1}
                fill={color}
                opacity={opacity}
              />
            );
          })}
        </g>
      );

      return ret;
    }

    return <></>;
  };

  const onBrushChange = (startTimestamp, endTimestamp) => {
    const start = moment(startTimestamp * 1000);
    const end = moment(endTimestamp * 1000);
    if (start.isValid() && end.isValid()) {
      const range = {
        start: start,
        end: end.add(1, 'day').startOf('day'),
      };
      setDateRange(range);
    }
  };

  const graphHeight = 395; // >37px row height to have all ticks!!!
  // console.log(yAxisParams);

  return (
    <div className={`graphDiv ${props.hasBorder ? 'withBorder' : ''}`}>
      <div
        style={{
          height: `calc(100% - ${RANGE_PICKER_HEIGHT}px)`,
          minHeight: 100,
        }}
        ref={graphContainerRef}
      >
        <ResponsiveContainer width="100%" height="99%" debounce>
          <BarChart
            data={partsData}
            defaultShowTooltip={false}
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <Tooltip content={<CustomTooltip />} dataKey="x" isAnimationActive={false} />

            {/* reference areas to mimic alternate rows */}
            {referenceAreas(yAxisParams)}

            {warningDays.map((warningDay, index) => (
              <ReferenceArea
                x1={warningDay}
                x2={warningDay}
                stroke={graphWarningFill}
                fill={graphWarningFill}
                fillOpacity={graphWarningFillOpacity}
                key={`refareaWarning${index}}`}
                shape={<WarningReferenceArea />}
              />
            ))}

            <XAxis
              type="category"
              dataKey="x"
              allowDuplicatedCategory={false}
              height={60}
              interval={0}
              name="day"
              orientation="top"
              tickLine={false}
              tickSize={0}
              tick={
                <CustomizedXAxisTick
                  axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
                  axisMin={fixedData[0].x}
                />
              }
              axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
            />
            <YAxis
              type="number"
              dataKey="y"
              name="hours"
              tick={
                <CustomizedYAxisTick
                  axisMin={yAxisParams.axisMin}
                  sleepStartTick={yAxisParams.axisMin + 2}
                  sleepEndTick={yAxisParams.axisMax - 2}
                  dateMin={dateRange.start}
                  dateMax={dateRange.end}
                  axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
                />
              }
              domain={[yAxisParams.axisMin, yAxisParams.axisMax]}
              tickCount={yAxisParams.axisTicks}
              tickSize={0}
              width={78}
              axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
              reversed
            />

            {/* weeks vertical lines */}
            {Array.from({ length: 10 }, (_, i) => i + 1).map(i => (
              <ReferenceLine
                x={`${X_VALUE_WEEK_PREFIX}${i}`}
                label=""
                stroke={graphReferenceVerticalLineColor}
                key={`vLine${i}`}
              />
            ))}

            <Bar
              key={`x`}
              tooltipType="none"
              dataKey={`y`}
              stackId="stack0"
              fillOpacity={1}
              maxBarSize={graphMaxBarSize}
              onClick={e => onClick(e)}
              cursor="pointer"
              shape={<DayBar />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <RangePicker
        startTimestamp={dateRange.start.unix()}
        endTimestamp={dateRange.end
          .clone()
          .subtract(1, 'days')
          .startOf('day')
          .unix()}
        dataStartTimestamp={maxRange.start.unix()}
        resolution="days"
        ticks={Math.round((maxRange.end.unix() - maxRange.start.unix()) / (24 * 60 * 60))}
        width="100%"
        onChange={onBrushChange}
        dateRange={maxRange}
        maxSelectionTicks={Math.floor(graphWidth / MINIMUM_PIXELS_PER_DAY)}
      />
    </div>
  );
}

SleepGraphDaily.propTypes = {
  graphData: PropTypes.array,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  selectedPhase: PropTypes.string,
  onDayClick: PropTypes.func,
  hasBorder: PropTypes.bool,
};

SleepGraphDaily.defaultProps = { hasBorder: true };
