import React, { PureComponent } from 'react';

import { TextInput } from '../TextInput';
import styles from '../Input.module.scss';

export class PasswordInput extends PureComponent {
  state = { showPassword: false };

  onPasswordSwitchClick = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const { showPassword } = this.state;

    return (
      <div className={styles.passwordContainer}>
        <TextInput {...this.props} type={showPassword ? 'text' : 'password'} />
        <div onClick={this.onPasswordSwitchClick} className={styles.passwordSwitch} />
      </div>
    );
  }
}
