import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { resetForm } from '../actions/forms';
import { closeModal } from '../actions/modal';
import LoadingRenderer from '../components/LoadingRenderer';
import { Switch2 } from '../components/Switch2/Switch2';
import { Modal } from '../containers';
import Form, { Input } from '../containers/Form';
import Strings from '../Strings';
import { validateName } from '../utils';
import { actions } from './AddOrganizationModal/redux/actions';
import { UPDATE_ORAGANIZATION_RESULT } from './AddOrganizationModal/redux/constants';
import './editStudyModal.scss';

class EditOrganization extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      action: PropTypes.func.isRequired,
      actionType: PropTypes.string.isRequired,
      admin: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),
      organization: PropTypes.any,
    }).isRequired,
    editAdminModalLoading: PropTypes.any,
    loadOrganization: PropTypes.func,
    onCancel: PropTypes.any,
    onOpen: PropTypes.any,
    onSubmit: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const newAdmin = {
      name: '',
      two_fa_enabled: true,
    };

    this.state = {
      errors: [],
      admin: this.props.data.organization ? cloneDeep(this.props.data.organization) : newAdmin,
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onTextChange(event) {
    const { admin } = this.state;

    admin[event.target.getAttribute('name')] = event.target.value;

    this.setState({ admin });
  }

  onSubmit() {
    const { admin } = this.state;

    const validationErrors = [];

    if (!admin.name.length) {
      validationErrors.push(Strings.errors.emptyOrgName);
      this.setState({ errors: validationErrors });
      return;
    }

    this.setState({ admin });

    const orgData = {
      name: admin.name,
      two_fa_enabled: admin.two_fa_enabled,
    };

    this.props.onSubmit(admin.masked_id, orgData).then(response => {
      if (response && response.type === UPDATE_ORAGANIZATION_RESULT) {
        const pageRequest = {
          offset: 0,
          search: '',
        };
        this.props.loadOrganization(pageRequest);
      }

      if (response && response.type === 'delete-organizations/error') {
        if (response.response && response.response.data) {
          if (response.response.data.error) {
            validationErrors.push(response.response.data.error.message);
            this.setState({ errors: validationErrors });
          }
        }
      }

      return response;
    });
  }

  on2FAChange = (sw, checked) => {
    const { admin } = this.state;
    admin.two_fa_enabled = checked;
    this.setState(() => ({ admin }));
    sw.setChecked(checked);
  };

  render() {
    const { onOpen, onCancel, editAdminModalLoading, ...props } = this.props;

    const { admin, errors } = this.state;
    return (
      <Modal
        name="new-admin-modal"
        onOpen={onOpen}
        additionalClasses={['form-modal', 'user-invite', 'invite-admin']}
        {...props}
      >
        <LoadingRenderer loading={!!editAdminModalLoading}>
          <Form key="add-edit-doctor-form" onSubmit={this.onSubmit} onCancel={onCancel} id="add-edit-doctor-form">
            <div key="doctor-firstName" className="dialog-input1">
              <label className="label-text"> {Strings.editOrganization}</label>
              <Input
                name="name"
                id="name"
                label=""
                className="dialog-form"
                placeholder={Strings.enterOrganizationName}
                type="text"
                defaultValue={admin.name}
                onChange={this.onTextChange}
              />
            </div>

            <div key="doctor-2fa" className="dialog-input1">
              <label className="label-text">2FA</label>
              <Switch2 checked={admin.two_fa_enabled} onBeforeChange={this.on2FAChange} />
            </div>

            <div key="errors-block" className="errors-block" style={{ marginTop: '20px' }}>
              {errors.length ? <div>{errors.join(' ')}</div> : null}
            </div>
            <div className="button-bar reverse" key="button-bar">
              <button className="brand-blue" key="submit" type="submit">
                {Strings.save}
              </button>
              <button className="white gray-text" key="cancel" type="cancel">
                {Strings.cancel}
              </button>
            </div>
          </Form>
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({ editAdminModalLoading: state.entities.admins.inviteLoading });

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('edit-organization')),
  loadOrganization: pageRequest => dispatch(actions.getOrganizations(pageRequest)),
  onSubmit: (id, data) =>
    dispatch(actions.updateOrganization(id, data)).then(response => {
      if (response && response.type === `${ownProps.data.actionType}/result`) {
        dispatch(closeModal('edit-organization'));
      }

      return response;
    }),
  onOpen: () => dispatch(resetForm('edit-organization', ownProps.data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditOrganization);
