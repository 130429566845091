import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import styles from './style.module.scss';

export class RowLabel extends PureComponent {
  static TYPE_GOOD = styles.good;
  static TYPE_AVERAGE = styles.average;
  static TYPE_POOR = styles.poor;

  render() {
    const { title, type, children } = this.props;

    return (
      <div className={`${styles.label} ${type ?? ''}`}>
        {title ? <div className={styles.title}>{title}</div> : null}
        <div className={styles.content}>{children}</div>
      </div>
    );
  }
}

RowLabel.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
  type: PropTypes.string,
};
