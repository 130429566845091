import styles from 'components/Inputs/Input.module.scss';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import Autosuggest from 'react-autosuggest';

import { MODE_TWO_COLUMNS } from '../../constants';
import { GoogleUtils } from '../../utils/googleUtils';
import './AutosuggestInput.scss';

export class AutosuggestWithLabelInput extends PureComponent {
  render() {
    const { title, error, mode = MODE_TWO_COLUMNS, ...props } = this.props;

    return (
      <div className={mode === MODE_TWO_COLUMNS ? styles.twoColumns : styles.oneColumn}>
        <div className={styles.labelTitle}>{title}</div>
        <div>
          <AutosuggestInput {...props} />
        </div>
        {error ? <div className="autosuggest-error">{error}</div> : null}
      </div>
    );
  }
}

export class AutosuggestInput extends PureComponent {
  static propTypes = {
    value: PropTypes.any,
    suggestions: PropTypes.array,
    placeholder: PropTypes.string,
    error: PropTypes.any,
    inputProps: PropTypes.object,
    className: PropTypes.string,
    onSuggestionsClear: PropTypes.func,
    onSuggestionsFetch: PropTypes.func,
    onAutosuggestSelected: PropTypes.func,
  };

  state = {
    value: this.props.inputProps?.value || '',
    suggestions: [],
  };

  renderSuggestion = suggestion =>
    suggestion.fullName ? <div>{`${suggestion.fullName} (${suggestion.name})`}</div> : <div>{suggestion.name} </div>;

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });
  };

  setSuggestions = suggestions => {
    this.setState({ suggestions });
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = (value, suggestions) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : suggestions.filter(x => x.name.toLowerCase().slice(0, inputLength) === inputValue);
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = suggestion => {
    const { onAutosuggestSelected } = this.props;

    if (onAutosuggestSelected) {
      GoogleUtils.getPlaceDetails(suggestion.place_id, onAutosuggestSelected);
    }

    return suggestion.name;
  };

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value }) => {
    if (this.props.onSuggestionsFetch) {
      this.props.onSuggestionsFetch(value, this.setSuggestions);
      return;
    }
    this.setState({ suggestions: this.getSuggestions(value, this.props.suggestions) });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  componentDidUpdate(prevProps) {
    const prevValue = prevProps?.inputProps?.value;
    const value = this.props?.inputProps?.value;
    /* eslint-disable */
    if (prevValue !== value) {
      this.setState({ value });
    }
    /* eslint-enable */
  }

  storeInputReference = autosuggest => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  onSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    this.props.inputProps?.onChange(suggestion.name, suggestion.fullName);
    this.setState({ value: '' });
  };

  render() {
    const { error } = this.props;
    const { value } = this.state;

    const suggestions = this.state.suggestions.filter(v => {
      return !this.props.usedDevices?.some(x => x.device_id === v.name);
    }, this);

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      ...this.props.inputProps,
      placeholder: this.props.placeholder,
      value,
      'data-id': this.props.dataId || '',
      id: this.props.id || '',
      onChange: this.onChange,
    };

    const divProps = {};

    const dataTip = this.props['data-tip'];
    const dataFor = this.props['data-tooltip-id'];

    if (dataFor && dataTip) {
      divProps['data-tip'] = dataTip;
      divProps['data-tooltip-id'] = dataFor;
    }

    return (
      <div className={`autosuggest ${this.props.className}`} {...divProps}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          shouldRenderSuggestions={() => true}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          ref={this.storeInputReference}
          onSuggestionSelected={this.onSuggestionSelected}
          alwaysRenderSuggestions={this.props.alwaysOn !== undefined}
          renderSuggestionsContainer={
            this.props.renderListProperly
              ? ({ containerProps, children }) => {
                  let toRender = null;
                  if (containerProps.className.indexOf('react-autosuggest__suggestions-container--open') >= 0) {
                    const inputCoords = this.input.getBoundingClientRect(),
                      style = {
                        position: 'fixed',
                        left: inputCoords.left + window.scrollX,
                        top: inputCoords.top + window.scrollY + 40,
                        width: inputCoords.width,
                        zIndex: 100,
                      };

                    toRender = (
                      <div {...containerProps} style={style}>
                        {children}
                      </div>
                    );
                    return ReactDOM.createPortal(toRender, document.body);
                  }

                  return null;
                }
              : undefined
          }
        />
        {error ? <div className="autosuggest-error">{error}</div> : null}
      </div>
    );
  }
}
