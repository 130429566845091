import { API_CALL } from 'middleware/api';
import { GET_SUPPORT_CONFIG } from './constants';

export const actions = {
  getConfig: organizationId => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: `/v1/organizations/${organizationId}/configs`,
    },
    type: GET_SUPPORT_CONFIG,
  }),
};
