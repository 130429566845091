import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FileDrop } from 'react-file-drop';

import BoxSdk from '../../libs/box-javascript-sdk';
import { notificationActions } from '../../components/Notification/redux/actions';
import { documentsActions, GET_ACCESS_TOKEN_RESULT } from './actions';
import { PageHeader } from '../../components/PageHeader';
import { Button } from '../../components/PageHeader/Button';
import { HEADER_BUTTON_BLUE } from '../../constants';
import Strings from '../../Strings';
import CloudContentTable from './internals/CloudContentTable';
import FirstUploadDrop from './internals/FirstUpload';
import { hasPermission, PERMISSIONS } from '../../utils/userPermissions';

function makeBoxClient(access_token) {
  const box = new BoxSdk();
  return new box.BasicBoxClient({ access_token });
}

function BoxDocuments(props) {
  const patientId = props.patientId;
  const canEdit = hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_INFO_EDIT);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);

  const errorHandler = () => {
    props.showNotification(Strings.documentsGeneralProblem);
    setLoading(false);
  };

  const getDownloadToken = () => {
    return new Promise((resolve, error) => {
      props.getDownloadToken(patientId).then(resp => {
        if (resp.type === GET_ACCESS_TOKEN_RESULT) {
          resolve(resp.response);
        } else {
          error();
        }
      });
    });
  };

  const getUploadToken = () => {
    return new Promise((resolve, error) => {
      props.getUploadToken(patientId).then(resp => {
        if (
          resp.type === GET_ACCESS_TOKEN_RESULT &&
          resp.response.access_token !== '' &&
          resp.response.folder_id !== ''
        ) {
          resolve(resp.response);
        } else {
          error();
        }
      });
    });
  };

  const getDocuments = () => {
    setLoading(true);
    setItems([]);
    getDownloadToken().then(resp => {
      if (resp.folder_id !== '') {
        const client = makeBoxClient(resp.access_token);
        client.folders
          .get({ id: resp.folder_id, params: { fields: 'name,item_collection,size,created_at' } })
          .then(boxResp => {
            setItems(boxResp.item_collection?.entries);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }, errorHandler);
  };

  const onPreview = fileId => {
    getDownloadToken().then(resp => {
      const client = makeBoxClient(resp.access_token);
      client.files
        .getEmbedLink(fileId)
        .then(linkResp => {
          const tempLink = document.createElement('a');
          tempLink.href = linkResp.expiring_embed_link.url;
          tempLink.target = '_blank';
          tempLink.click();
        })
        .catch(() => {
          errorHandler();
        });
    });
  };

  const onDownload = fileId => {
    getDownloadToken().then(resp => {
      const client = makeBoxClient(resp.access_token);
      client.files
        .getDownloadUrl(fileId)
        .then(downloadResp => {
          const tempLink = document.createElement('a');
          tempLink.href = downloadResp.download_url;
          tempLink.target = '_blank';
          tempLink.click();
        })
        .catch(() => {
          errorHandler();
        });
    }, errorHandler);
  };

  const onUpload = file => {
    const sizeInMb = file.size / 1048576;
    if (sizeInMb >= 50) {
      props.showNotification(`${Strings.fileExceedMaximumSizeOf} 50 MB`);
    } else {
      setUploading(true);
      getUploadToken().then(resp => {
        const client = makeBoxClient(resp.access_token);
        const body = new FormData();
        body.append(file.name, file);
        body.append('parent_id', resp.folder_id);

        client.files
          .upload({ body })
          .then(() => {
            props.showNotification(Strings.fileUploadedSuccessfully);
            setUploading(false);
            getDocuments();
          })
          .catch(err => {
            setUploading(false);
            props.showNotification(Strings.fileUploadFail);
          });
      }, errorHandler);
    }
  };

  useEffect(() => {
    getDocuments(patientId);
  }, []);

  const spinner = (
    <div className="cd-gray-border">
      <div className="cd-spinner" />
    </div>
  );

  const firstUpload = (
    <div className="cloud-documents">
      <FirstUploadDrop onUpload={onUpload} uploading={uploading} />
    </div>
  );

  const fileInput = React.createRef();

  const triggerInputFile = () => {
    if (fileInput.current != undefined && fileInput.current.click != undefined) fileInput.current.click();
  };
  const dropSpaceAndButton = (
    <React.Fragment>
      <FileDrop
        onDrop={(files, event) => {
          event.preventDefault();
          event.stopPropagation();
          onUpload(files[0]);
        }}
      >
        <input
          type="file"
          name="file"
          ref={fileInput}
          onChange={e => {
            onUpload(e.target.files[0]);
          }}
          onTargetClick={() => triggerInputFile()}
        />
        <div className={`cd-small-drop ${uploading ? 'progressing' : ''}`}>{Strings.dropOr}</div>
      </FileDrop>
      <Button class={HEADER_BUTTON_BLUE} title={Strings.chooseFile} onClick={() => triggerInputFile()} />
    </React.Fragment>
  );
  const fileTable = (
    <div className="cd-file-table">
      <PageHeader right={dropSpaceAndButton} />
      <CloudContentTable
        data={items}
        isLoading={loading}
        previewHandler={onPreview}
        downloadHandler={onDownload}
        provider="box"
      />
    </div>
  );

  return loading ? spinner : items.length === 0 && canEdit ? firstUpload : fileTable;
}

BoxDocuments.propTypes = {
  showNotification: PropTypes.func.isRequired,
  getDownloadToken: PropTypes.func.isRequired,
  getUploadToken: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => ({
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  getDownloadToken: patientId => dispatch(documentsActions.getBoxDownloadToken(patientId)),
  getUploadToken: patientId => dispatch(documentsActions.getBoxUploadToken(patientId)),
});

export default connect(null, mapDispatchToProps)(BoxDocuments);
