import { API_CALL } from 'middleware/api';
import { ESI_PATIENT_UPDATE_ADDRESS } from './constants';

export const actions = {
  updateAddress: address => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: `/rails/api/users/address`,
      data: address,
    },
    type: ESI_PATIENT_UPDATE_ADDRESS,
  }),
};
