import { WhiteBackground } from 'containers/WhiteBackground';
import React from 'react';

import Strings from '../../Strings';

const EsiPatientTerms = () => {
  return (
    <div>
      <div></div>
      <WhiteBackground noLines title={Strings.termsOfUse}>
        <div className="page">
          <p className="rx-p-normal">
            <span>EXPRESS-SCRIPTS.COM TERMS OF USE</span>
          </p>

          <p className="rx-p-normal">
            <span>
              These Terms of Use apply to all visitors who use the Express-Scripts.com website available through&nbsp;
            </span>
            <span>
              <a href="http://www.express-scripts.com/">
                <span>http://www.Express-Scripts.com</span>
              </a>
            </span>
            <span>
              &nbsp;(the &quot;Site&quot;) including Registered Users of the Registered User Website. The Site may be
              accessed via the World Wide Web, via a mobile application, or through a website or application hosted by a
              third party or one of our affiliated companies. The Site is provided to you as a service to provide
              information about our company and, as applicable, your pharmacy benefit, management of your User account
              and access to mail order pharmacy services and includes the Registered User Website. The &quot;Registered
              User Website&quot; (or simply &quot;Website&quot;) is a registered user-only account portal available
              through the Site, a third-party site, or via mobile access which enables Registered Users to access their
              personal account information and to use certain other services offered only to them.
            </span>
          </p>

          <p className="rx-p-normal">
            <span>
              &quot;Users&quot; are persons utilizing the Website and include Members. &quot;Registered Users&quot; are
              persons who have registered for use of the Registered User Website and include Registered Members.
              Individual members of an applicable prescription drug benefit plan administered by Express Scripts on
              behalf of a plan sponsor who have submitted a completed account registration form on the Site, or who have
              completed the appropriate registration process through their plan sponsor&apos;s website, or who have
              completed the registration process via telephone with a customer service representative, or are otherwise
              qualified as a beneficiary under the TRICARE program are all &quot;Registered Members&quot; (or
              &quot;Registered Beneficiaries&quot; in the case of the TRICARE program) who may access the Website after
              completing the registration process. These Terms of Use are between a user of any portion of the Site
              (&quot;you&quot; or &quot;your&quot;) and your plan sponsor&apos;s pharmacy benefit manager (or
              underwriting insurance company as applicable) or, if not applicable, Express Scripts Holding Company (in
              either case, &quot;Express Scripts&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot;).
            </span>
          </p>

          <p className="rx-p-normal">
            <span>
              If you are provided access to non-publicly available portions of the Site (other than the Registered User
              Website), any additional terms and conditions located on those portions of the Site will also apply and
              will control in the event of a conflict with these Terms of Use. If you agree to an updated version of
              these Terms of Use and the Privacy Policy for this Site (e.g., by using new Site features governed by such
              terms), these updated versions govern your use of the Site.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>REGISTRATION AND ASSENT</b>
          </p>

          <p className="rx-p-normal">
            <span>
              Access to and use of the Site is conditioned upon your assent to these Terms of Use. You are deemed to
              have assented to these Terms of Use when you use any available page of the Site. You are deemed to have
              assented to these Terms of Use as applicable to the Website, when you complete the online registration
              processes required for Registered Users as described above and indicate during the registration process
              that you accept these Terms of Use along with the Privacy Policy incorporated therein. You are deemed to
              have accepted these Terms of Use each time you access the Site and each time you use your login
              credentials to access the Registered User Website portion of the Site. You are deemed to have accepted
              these Terms of Use each time you use non-login Site functionality to manage aspects of your benefit or
              utilize the mail order pharmacy. By registering for or otherwise accessing or using the Site, you
              acknowledge that you have read, understand, and agree to be legally bound by these Terms of Use. These
              Terms of Use and the Privacy Policy are available during registration for the Website and on various pages
              of the Site.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>UPDATES</b>
          </p>

          <p className="rx-p-normal">
            <span>
              From time to time, we may, in our sole discretion, modify these Terms of Use, and the Privacy Policy,
              indicated by a new version number and revision date. The version number includes a major number, a decimal
              point, and a minor number. A change to the major number reflects a significant change to the policy, while
              a change to the minor number reflects a less significant change to the policy. Examples of significant
              changes include additional provisions that reflect new Site functionality, significant modifications to
              existing provisions, and more significant changes to Site functionality that cause provisions to be
              modified, added, or removed. Examples of less significant changes include additional provisions that
              clarify current Site functionality, minor modifications to existing provisions, and less significant
              changes to Site functionality that cause provisions to be modified, added, or removed.
            </span>
          </p>

          <p className="rx-p-normal">
            <span>
              We will provide an advance notice of a major change prior to your access of any portion of the Site for
              which registration is required. For example, we may (i) require that you reaccept the updated version of
              the web policies, (ii) send an electronic notification advising of the update to the web policies, (iii)
              include a notice on the Site viewable without login advising of the update to the web policies, and/or
              (iv) advise of the updated web policies during a phone call. We do not ordinarily provide advance notice
              of a minor change.
            </span>
          </p>

          <p className="rx-p-normal">
            <span>
              It is important that you check these Terms of Use every time you visit the Site. Your use of the Site
              and/or utilization of any Site benefits after the Terms of Use have been updated (and after advance notice
              of certain major changes), indicates your agreement and acceptance of the updated version of the Terms of
              Use and the Privacy Policy, including the modifications made as of the date of your use.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>USER OBLIGATIONS</b>
          </p>

          <p className="rx-p-normal">
            <span>
              You are required to comply with all applicable laws in connection with your use of the Site. You agree
              that you have appropriate procedures and safeguards with respect to your access and use of the Site and
              any Site Content obtained through the Site. As a condition of your use of the Site, you agree that you
              will not use the Site for any purpose that is unlawful or prohibited by these Terms of Use. You agree that
              you will only provide information during registration and in connection with any and all other uses of the
              Site that is true and accurate and is not false, misleading, or otherwise an impersonation of any person
              or entity. Certain portions of the Site may have additional terms and conditions. When these portions are
              used, you agree to be further bound by the associated additional terms and conditions.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>SITE CONTENT AND INTELLECTUAL PROPERTY RIGHTS</b>
          </p>

          <p className="rx-p-normal">
            <span>
              This Site may contain text, images, software (including images or files incorporated in or generated by
              the software or data accompanying such software), photographs, video, graphics, audio, features, data,
              designs, images, and other such similar content (collectively &quot;Site Content&quot;). Unless otherwise
              expressly identified, Site Content is owned by us or by our third-party licensors. The Site Content is
              protected by United States and international copyright, trademark, and other laws. You may browse the Site
              and download Site Content solely for your personal use, provided you keep intact all copyright and other
              proprietary notices. Except as expressly permitted, you may not modify, copy, reproduce, republish,
              upload, post, transmit, hyperlink to or from, or distribute in any way Site Content from this Site,
              including code and software underlying this Site, nor may you sell, transfer, or otherwise use the Site or
              the Site Content in commerce or for any public or commercial endeavor without our prior and express
              written consent. We may in our sole discretion make changes to the Site Content at any time without
              notice.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>ELECTRONIC TRANSACTIONS</b>
          </p>

          <p className="rx-p-normal">
            <span>
              Certain purchases may be electronically made through the Site using a bank account. You understand that
              because these transactions are electronic transactions, the associated funds may be withdrawn from your
              account as soon as today&apos;s date. If the payment date falls on a weekend or holiday, you understand
              that the payment may be executed on the next business day. You understand that if your account does not
              have sufficient funds when a payment is due, Express Scripts will not be responsible or liable for any
              penalties or charges assessed by any financial institution as a result of such insufficiency. You
              acknowledge that, in the event that Express Scripts&apos; additional attempts to collect the payment due
              via electronic funds transfer are unsuccessful, you must make the payment due by other means. You
              understand that Express Scripts will assess a non-sufficient fund fee of $15 as a result of your account
              having insufficient funds.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>SMS TEXT COMMUNICATIONS</b>
          </p>

          <p className="rx-p-normal">
            <span>
              We support SMS texting communications in accordance with the communication preferences that are available
              to you and for informational and/or marketing messages associated with certain other Express Scripts text
              message programs. If you opt-in to receive text messages for a particular category of communication
              preferences, you will receive text messages consistent with your communication preferences as further
              described in the Privacy Policy. If you opt-in to another text message program, you will receive
              informational and/or marketing text messages consistent with the program such as informational text
              messages about certain eligible prescriptions. The number of text messages per month varies.
            </span>
          </p>

          <p className="rx-p-normal">
            <span>
              To opt-in after providing us with a verbal consent to receive text messages, reply YES when you receive a
              text message from Express Scripts from any of the following numbers listed&nbsp;
            </span>
            <span>
              <a href="https://www.express-scripts.com/art/pdf/Text-Messaging-Capabilities.pdf">
                <span>here</span>
              </a>
            </span>
            <span>
              . If you have provided written confirmation for us to receive text messages, no confirmation may be
              necessary. To opt-out from a particular Express Scripts text message program, you can text STOP in
              response to associated program text messages from Express Scripts from your mobile phone and we will
              unsubscribe you from the associated Express Scripts text message program and send you one message to
              confirm your opt-out. You will not receive additional text messages from the associated program unless you
              opt back in. To get technical help or support, call us at 1-800-711-5672. From your mobile phone, you may
              request our contact information at any time by texting HELP in response to text messages from Express
              Scripts. Message and Data Rates May Apply. Carriers are not liable for delayed or undelivered messages.
              The Express Scripts text message program is subject to these Terms of Use and the Privacy Policy, as
              defined below, to the extent applicable.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>USER EXPERIENCE</b>
          </p>

          <p className="rx-p-normal">
            <span>
              We constantly make improvements to our Site by adding and modifying its features and functionality. To
              enhance your Site experience and improve Site effectiveness, we may monitor your Site usage, selectively
              provide or disable Site features and functionality, and contact you to solicit information that would
              assist us in improving the overall user experience through such features, functionality, or otherwise.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>FEEDBACK AND OTHER SUBMITTED CONTENT</b>
          </p>

          <p className="rx-p-normal">
            <span>
              Certain portions of the Site may provide mechanism(s) for you to communicate and share information and/or
              materials with us. By submitting us your feedback, comments, reviews, ideas, testimonials, opinions,
              photos, and/or other submitted content (any, a &quot;Submission&quot;) through the Site, electronic
              communications, or otherwise, you agree as follows:
            </span>
          </p>

          <ol start="1" type="1">
            <li className="rx-p-normal">
              <span>
                Your Submissions must: (i) be original and solely written or created by you, (ii) not feature any
                intellectual property unless owned by you or us, or anything illegal, obscene, threatening, defamatory
                or otherwise objectionable, in our sole discretion, (iii) not feature any identifiable person(s), other
                than you or those on whose behalf you are authorized to feature in your Submission; and (iv) be
                accurate, truthful, and not misleading.
              </span>
            </li>
          </ol>

          <ol start="2" type="1">
            <li className="rx-p-normal">
              <span>
                By sending us a Submission, to the fullest extent and for the maximum duration permitted by applicable
                law, you also grant to us a royalty-free, irrevocable, perpetual, non-exclusive license to use,
                reproduce, modify, publish, create derivative works from, and display your Submission, in whole or in
                part, including the right to use your name, image, likeness, and biographical information (as provided
                by you) in conjunction with the Submission, on a worldwide basis, in any form, media or technology now
                known or later developed, including without limitation for our promotional or marketing purposes, and
                you waive all moral and similar rights. If requested, you will agree to sign any documents to confirm
                the above.
              </span>
            </li>
          </ol>

          <ol start="3" type="1">
            <li className="rx-p-normal">
              <span>
                By sending a Submission, you also waive and release, and agree to hold us harmless, and our parents,
                subsidiaries, affiliates, advertising and promotion agencies, from and against any and all rights claims
                and causes of action whatsoever, including but not limited to claims relating to rights of privacy,
                publicity, libel or infringement, or otherwise relating to injury, loss or damage, whether direct,
                compensatory, incidental or consequential, arising in whole or in part from the Submission or our use of
                same. You warrant that your Submission is being provided voluntarily, does not incorporate or embody any
                confidential or proprietary information of third parties, and does not violate any applicable laws or
                regulations or infringe any third party&apos;s copyrights, patent rights, trademarks, or other
                intellectual property rights.
              </span>
            </li>
          </ol>

          <p className="rx-p-normal">
            <span>
              With certain submission mechanisms, we may post or otherwise contemporaneously provide you with terms that
              restrict our use of your Submission received through a particular submission mechanism and/or at a
              particular time. Some of the submission mechanisms available through the Site may provide you with options
              to enable or limit how we may use a Submission. When such terms are provided or options are available and
              specified, our use of such Submissions pursuant to this section is subject to such limitations.
            </span>
          </p>

          <p className="rx-p-normal">
            <span>
              We may contact you regarding your Submission through any known electronic communication channel. If you
              have questions about our usage of a particular Submission, please contact us at
              <a href="mailto:uspto@express-scripts.com"> uspto@express-scripts.com.</a>
            </span>
          </p>

          <p className="rx-p-normal">
            <b>SCHEDULED EVENTS</b>
          </p>

          <p className="rx-p-normal">
            <span>
              Portions of the Site may include a listing of events and times of events that we are scheduled to host or
              attend. You can contact us to confirm the date, time, location, and whether we are still scheduled to host
              or attend the listed event. However, we may ultimately not be able to host or attend the listed event due
              to a variety of reasons. We are not responsible for any errors or omissions in the listing of the events.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>SOCIAL MEDIA</b>
          </p>

          <p className="rx-p-normal">
            <span>
              The Site may include opportunities to view information and communicate with us through social media
              services such as LinkedIn®, Twitter®, Facebook®, Glassdoor®, and blogging. You should use common sense and
              good judgment in communicating with us through these services. We are not responsible for the terms of use
              and privacy policies that govern these third-party sites.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>NO PROFESSIONAL ADVICE</b>
          </p>

          <p className="rx-p-normal">
            <span>
              The information provided on the Site is for informational purposes or general guidance only, and does not
              constitute medical, legal, financial, accounting, tax, or other professional advice. We do not warrant or
              guarantee the accuracy, completeness, adequacy or currency of the provided information, nor do we endorse
              any views or opinions that may be included therein. The provided information does not constitute the
              rendering by us of any type of opinion, certification, or guarantee. The provided information is not a
              substitute for medical or other professional advice and it is important that no medical or other
              professional decisions should be made without first consulting a personal physician or other applicable
              professional. The receipt of any questions or feedback you submit to us does not create a professional
              relationship and does not create any privacy interests other than those described in our Privacy Policy.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>LINKING POLICY</b>
          </p>

          <p className="rx-p-normal">
            <span>
              Our Site may contain hyperlinks allowing our users to connect to other websites owned by us and our
              affiliated companies and websites owned by our third-party vendors, distributors, and providers
              (&quot;Linked Sites&quot;). You may also access our Site through a hyperlink embedded in a Linked Site. We
              provide hyperlinks to the Linked Sites to enable you to conveniently access websites that may be of
              interest to you. Please note that once you click on a hyperlink that transfers you from our Site to a
              Linked Site, you have left our Site, and this Terms of Use will immediately cease to apply to any
              subsequent activity on the Linked Site. We are under no obligation to notify you when you have left our
              Site and have accessed a Linked Site. Use of any Linked Site will be governed by the privacy policy, terms
              of use, and/or other policies (if any) on the Linked Site.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>USE RESTRICTIONS</b>
          </p>

          <p className="rx-p-normal">
            <span>
              You agree that you will not (and will not attempt to): (a) access or use the Site or any component thereof
              in any manner or for any purpose not expressly authorized by these Terms of Use; (b) distribute,
              sublicense, lease, rent, loan, or otherwise use or make any component of the Site available for use by
              third parties, including as part of a service bureau, outsourcing, or external consulting arrangement; (c)
              allow any other person or entity to use your login credentials to access, view, or use any component of
              the Site or for posting, copying, extracting, downloading, viewing, transmitting, or receiving data of any
              kind; (d) access any component available through the Site via remote access through interfaces or
              automated means not approved in writing by us; (e) collect, compile, or otherwise attempt to &quot;screen
              scrape,&quot; &quot;data mine&quot; or &quot;harvest&quot; any component of the Site, including through
              the creation of any duplicate or derivative data store; (f) deactivate, bypass, or circumvent any access
              controls or security measures for the Site; (g) gain unauthorized access to the Site or another site
              user&apos;s network, systems, or data; (h) engage in any activity or use any device, software, or routine,
              or introduce any virus, Trojan horse, spyware, adware, or other malicious code, that interferes with a
              user&apos;s access to the Site or the proper operation of this Site; (i) engage in any activity that
              disrupts or impairs the performance of the Site; (j) access or use the Site in violation of any applicable
              laws or regulations, the intellectual property or other rights of any third party, or any contractual or
              legal duty or obligation including in a manner prohibited by these Terms of Use; (k) reverse engineer,
              decompile, disassemble, or otherwise attempt to derive the source code or underlying programming, models,
              databases, or database structures for the Site; or (l) remove or obscure any copyright, trademark,
              confidentiality, or other Site notices, terms, or disclosures. We reserve the right to suspend or
              terminate Site access to address non-compliance with these Terms of Use.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>FORWARD-LOOKING STATEMENTS</b>
          </p>

          <p className="rx-p-normal">
            <span>
              This Site may contain &quot;forward-looking statements,&quot; as that term is defined in the Private
              Securities Litigation Reform Act of 1995, which involve risks and uncertainties. No forward-looking
              statement can be guaranteed, and actual results may differ materially from those projected. We undertake
              no obligation to publicly update any forward-looking statement, whether as a result of new information,
              future events, or otherwise. Forward-looking statements on this Site should be evaluated together with the
              disclosure regarding Express Scripts (along with those previously filed by our subsidiaries) in our
              registration statements and periodic reports filed with the Securities and Exchange Commission, including
              the risks and uncertainties facing our business described therein.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>OFF-LABEL USE</b>
          </p>

          <p className="rx-p-normal">
            <span>
              Certain uses of products discussed herein may not have been approved by the Food and Drug Administration.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>PRIVACY POLICY</b>
          </p>

          <p className="rx-p-normal">
            <span>
              You agree to the Internet privacy policy (&quot;Privacy Policy&quot;), which is incorporated by reference
              in these Terms of Use.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>DISCLAIMER OF WARRANTIES</b>
          </p>

          <p className="rx-p-normal">
            <span>
              THE SITE AND THE SITE CONTENT ARE PROVIDED ON AN &quot;AS IS&quot;, &quot;AS AVAILABLE&quot; AND
              &quot;WITH ALL FAULTS&quot; BASIS, AND ALL USE OF THE SITE AND THE SITE CONTENT IS AT YOUR OWN RISK. TO
              THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
              EXPRESS OR IMPLIED, REGARDING THE SITE, THE SITE CONTENT, OR YOUR ACCESS TO OR USE THEREOF. WE HEREBY
              DISCLAIM ANY AND ALL IMPLIED AND STATUTORY WARRANTIES OF ANY KIND, INCLUDING BUT NOT LIMITED TO ANY
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE, TITLE, QUIET ENJOYMENT,
              NON-INFRINGEMENT, CUSTOM, AND USAGE OF TRADE. WITHOUT LIMITING THE FOREGOING, WE DO NOT REPRESENT OR
              WARRANT THAT THE SITE OR THE SITE CONTENT WILL BE ACCURATE, COMPLETE, UP-TO-DATE, OR RELIABLE; THAT ACCESS
              TO OR USE OF THE SITE WILL BE UNINTERRUPTED, SECURE, OR ERROR-FREE; THAT THE SITE, THE SITE CONTENT, OR
              ANY PORTION THEREOF WILL NOT CHANGE OR BE DISCONTINUED; THAT ERRORS OR DEFECTS WILL BE CORRECTED; THAT THE
              SITE OR THE SITE CONTENT WILL BE FREE FROM COMPUTER VIRUSES OR OTHER HARMFUL COMPONENTS; OR THAT ACCESS TO
              OR USE OF THE SITE OR THE SITE CONTENT IS LAWFUL IN ANY PARTICULAR JURISDICTION. NO SUPPLIER TO US HAS ANY
              WARRANTY, SUPPORT, OR OTHER OBLIGATIONS TO YOU IN CONNECTION WITH YOUR USE OF THIS SITE. YOU ASSUME THE
              ENTIRE OBLIGATION AND COST OF ANY AND ALL NECESSARY COMPUTER, MOBILE DEVICE, AND NETWORK SERVICING,
              REPAIR, AND CORRECTION. IF YOU ARE DISSATISFIED WITH THE SITE OR THE SITE CONTENT, YOUR SOLE AND EXCLUSIVE
              REMEDY IS TO DISCONTINUE USING THE SITE. SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR
              CERTAIN OTHER WARRANTIES, SO THE ABOVE DISCLAIMERS MAY NOT APPLY TO YOU TO THE EXTENT SUCH A
              JURISDICTION&apos;S LAW APPLIES AND LIMITS SUCH DISCLAIMERS.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>LIMITATION OF LIABILITY</b>
          </p>

          <p className="rx-p-normal">
            <span>
              UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY,
              PUNITIVE, OR OTHER DAMAGES OR LOSSES OF ANY KIND ARISING OUT OF OR DIRECTLY OR INDIRECTLY RELATED TO THE
              SITE, THE SITE CONTENT, YOUR ACCESS TO OR USE THEREOF, OUR PERFORMANCE, NON-PERFORMANCE, ACTS, OR
              OMISSIONS IN CONNECTION THEREWITH, THE PRIVACY POLICY, OR THESE TERMS OF USE, EVEN IF FORESEEABLE, AND
              EVEN IF WE HAVE BEEN ADVISED OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. IN ANY EVENT, UNDER
              NO CIRCUMSTANCES SHALL OUR TOTAL, CUMULATIVE LIABILITY TO YOU FOR ANY AND ALL DAMAGES, LOSSES, CLAIMS, AND
              CAUSES OF ACTION EXCEED FIVE UNITED STATES DOLLARS ($5.00). IN NO WAY LIMITING THE FOREGOING, WE ASSUME NO
              OBLIGATION AND SHALL HAVE NO LIABILITY ARISING OUT OF OR DIRECTLY OR INDIRECTLY RELATED TO ANY
              COMMUNICATION ERRORS, SERVICE INTERRUPTIONS, OR SITE MALFUNCTIONS; THE CONDUCT OR CONTENT OF ANY SITE
              USER; COMPUTER VIRUSES OR OTHER HARMFUL COMPONENTS; LOSS OR CORRUPTION OF DATA; DAMAGE TO YOUR COMPUTER,
              MOBILE DEVICE, OR NETWORK; MALICIOUS ACTS OF THIRD PARTIES; FORCE MAJEURE EVENTS; BUSINESS INTERRUPTION OR
              DOWNTIME; LOSS OF BUSINESS, PROFITS, OR GOODWILL; OR ACTIONS TAKEN OR NOT TAKEN IN CONNECTION WITH
              INVESTIGATIONS, DEMANDS, OR CLAIMS BY US, INTELLECTUAL PROPERTY OWNERS, LAW ENFORCEMENT, GOVERNMENTAL
              AUTHORITIES, OR THIRD PARTIES. THE LIMITATIONS ON LIABILITY IN THIS SECTION SHALL APPLY TO THE FULLEST
              EXTENT PERMISSIBLE UNDER APPLICABLE LAW, REGARDLESS OF THE NUMBER OF INCIDENTS OR CLAIMS, AND REGARDLESS
              OF THE NATURE OF THE DAMAGE, LOSS, CLAIM, OR CAUSE OF ACTION (WHETHER IN CONTRACT, TORT, NEGLIGENCE,
              STRICT LIABILITY, INDEMNITY, OR OTHERWISE). THE LIMITATIONS ON LIABILITY IN THIS SECTION ARE AN ESSENTIAL
              PART OF THESE TERMS OF USE, AND SHALL BE VALID AND BINDING EVEN IF ANY REMEDY IS DEEMED TO FAIL OF ITS
              ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF INCIDENTAL, CONSEQUENTIAL, OR CERTAIN
              OTHER DAMAGES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU TO THE EXTENT SUCH A JURISDICTION&apos;S LAW
              APPLIES AND LIMITS SUCH EXCLUSIONS.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>NOTICE OF COPYRIGHT INFRINGEMENT</b>
          </p>

          <p className="rx-p-normal">
            <span>
              If you believe that your work has been copied and is accessible on this Site in a way that constitutes
              copyright infringement, please provide our designated Copyright Agent identified below with the following
              information:
            </span>
          </p>

          <p className="rx-p-normal">
            <span>a. identification of the copyrighted work claimed to have been infringed;</span>
          </p>

          <p className="rx-p-normal">
            <span>
              b. identification of the allegedly infringing material on the Site that is requested to be removed;
            </span>
          </p>

          <p className="rx-p-normal">
            <span>
              c. your name, address and daytime telephone number, and an e-mail address if available, so that we may
              contact you if necessary;
            </span>
          </p>

          <p className="rx-p-normal">
            <span>
              d. a statement that you have a good faith belief that the use of the copyrighted work is not authorized by
              the copyright owner, its agent, or the law;
            </span>
          </p>

          <p className="rx-p-normal">
            <span>
              e. a statement that the information in the notification is accurate, and under penalty of perjury, that
              the signatory is authorized to act on behalf of the owner of an exclusive copyright right that is
              allegedly infringed; and
            </span>
          </p>

          <p className="rx-p-normal">
            <span>
              f. a physical or electronic signature of the copyright owner or the person authorized on the owner&apos;s
              behalf to assert infringement of copyright and to submit the statement.
            </span>
          </p>

          <p className="rx-p-normal">
            <span>
              Contact Information for Our Copyright Agent: Legal Department, Intellectual Property Express Scripts
              Holding Company One Express Way, Hq1-2e03, St. Louis, MO 63121 e-mail: uspto@express-scripts.com
            </span>
          </p>

          <p className="rx-p-normal">
            <span>
              We will remove any content that infringes the copyright of any person under the laws of the United States
              upon receipt of information as set forth in Subsections (a) - (f) above, and may, in our sole discretion
              if we deem it appropriate, terminate access rights of any user who we reasonably believe violates any
              United States copyright laws.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>GOVERNING LAW</b>
          </p>

          <p className="rx-p-normal">
            <span>
              These Terms of Use shall be governed by and construed in accordance with the laws of the State of
              Missouri, excluding its conflict of law rules. The Site is intended for use by individuals residing in the
              United States of America. No laws of any foreign jurisdiction shall apply to these Terms of Use or be
              binding on us.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>JURISDICTION; VENUE</b>
          </p>

          <p className="rx-p-normal">
            <span>
              Any dispute, claim, action or proceeding arising out of or related to the Site, the Site Content, the
              Privacy Policy, or these Terms of Use, or the interpretation or enforcement hereof, whether at law or in
              equity, shall be brought only in the state courts located in St. Louis County, Missouri or, if proper and
              exclusive federal subject matter jurisdiction exists, the United States District Court for the Eastern
              District of Missouri. Each party hereby submits to the personal jurisdiction and consents to the exclusive
              venue of such courts, and waives any objections thereto, including based on forum non conveniens.
              Notwithstanding the foregoing, in connection with asserting or protecting our intellectual property or
              other legal rights or business interests, we retain the right to seek temporary or permanent injunctive or
              other non-monetary equitable relief against you in any court of competent jurisdiction.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>NO WAIVER</b>
          </p>

          <p className="rx-p-normal">
            <span>
              Any waiver by us must be express and in writing, must be directed specifically by us to you, and must be
              signed by our duly authorized representative. Our failure to enforce any provision of these Terms of Use
              or to respond to a breach by you or third parties shall not in any way limit or waive our right to do so,
              including without limitation our right to enforce subsequently any provision of these Terms of Use, or to
              assert our rights with respect to the same or similar breaches.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>ENTIRE AGREEMENT</b>
          </p>

          <p className="rx-p-normal">
            <span>
              Unless otherwise specified herein, these Terms of Use and the Privacy Policy incorporated herein
              constitute the entire agreement between you and us with respect to the subject matter hereof, and
              supersede all prior and contemporaneous agreements and understandings, whether written or oral, concerning
              the subject matter hereof. Any conflicting or supplementary terms proposed by you in any e-mail or other
              communication shall not be binding on us, and are hereby objected to and expressly rejected.
            </span>
          </p>

          <p className="rx-p-normal">
            <b>SEVERABILITY</b>
          </p>

          <p className="rx-p-normal">
            <span>
              If any portion of these Terms of Use or the Privacy Policy is held invalid or unenforceable under
              applicable law, that portion shall be construed in a manner consistent with applicable law to accomplish,
              as nearly as possible, the objective thereof, or severed from the document if and solely to the limited
              extent such construction is not possible, and the remaining portion of these Terms of Use and the Privacy
              Policy shall remain in full force and effect.
            </span>
          </p>
        </div>
      </WhiteBackground>
    </div>
  );
};

export default EsiPatientTerms;
