import cn from 'classnames';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Strings from '../../Strings';

import './Icon.scss';
import styles from './MenuItem.module.scss';
import { isBrowser, isMobile } from 'react-device-detect';

export class MenuItem extends PureComponent {
  static propTypes = {
    active: PropTypes.any,
    expandable: PropTypes.bool,
    expanded: PropTypes.bool,
    isChildNode: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    onExpand: PropTypes.func,
    ref: PropTypes.any,
    tag: PropTypes.string,
    to: PropTypes.any,
    visible: PropTypes.bool,
  };

  onItemClick = () => {
    const { to, onClick } = this.props;
    onClick(to);
  };

  onExpand = () => {
    const { onExpand, expanded, tag } = this.props;
    onExpand({
      isExpanding: !expanded,
      tag,
    });
  };

  render() {
    const { tag, label, active, ref, visible, expanded, expandable, isChildNode } = this.props;

    return (
      <React.Fragment>
        {/* buttons in this div are redundant and have tabindex="-1" to be ignored for tab order and aria labels removed */}
        {isBrowser && (
          <div
            className={cn(styles.menuItem, tag, 'item', styles.selected, {
              [styles.active]: !expanded,
              [styles.isChild]: isChildNode,
              [styles.collapsed]: !visible,
              [styles.selected2]: !expanded && active,
            })}
          >
            {expandable === true && (
              <button
                className={cn(styles.expandButton, styles.active, { [styles.isExpanded]: expanded })}
                ref={ref}
                onClick={this.onExpand}
                tabIndex={-1}
              />
            )}
            <button
              className={cn(styles.button, {
                [styles.collapsed]: !visible,
                [styles.expandable]: expandable,
                [styles.isChild]: isChildNode,
              })}
              onClick={this.onItemClick}
              ref={ref}
              tabIndex={-1}
            >
              <span
                className={cn(styles.title, {
                  [styles.collapsed]: !visible,
                  [styles.isChild]: isChildNode,
                  [styles.expandable]: expandable,
                })}
              >
                {label}
              </span>
              <span className={`icon menuIcons ${!expanded ? 'active' : ''} ${visible ? '' : 'collapsed'} `} />
            </button>
          </div>
        )}
        <div
          className={cn(styles.menuItem, tag, 'item', {
            [styles.isChild]: isChildNode,
            [styles.collapsed]: !visible,
            [styles.mobile]: isMobile,
          })}
        >
          {expandable === true && (
            <button
              className={cn(styles.expandButton, {
                [styles.isExpanded]: expanded,
                [styles.active]: active,
                [styles.mobile]: isMobile,
              })}
              ref={ref}
              onClick={this.onExpand}
            />
          )}
          <button
            aria-label={
              tag === 'logout'
                ? Strings.signOut
                : `${Strings.navigateToScreenAL.navigateTo} ${tag} ${Strings.navigateToScreenAL.screen}`
            }
            className={cn(styles.button, {
              [styles.collapsed]: !visible,
              [styles.expandable]: expandable,
              [styles.isChild]: isChildNode,
            })}
            onClick={this.onItemClick}
            ref={ref}
            tabIndex={0}
            data-testid={`menu-item[${label.toLowerCase()}]`}
          >
            <span
              className={cn(styles.title, {
                [styles.collapsed]: !visible,
                [styles.isChild]: isChildNode,
                [styles.expandable]: expandable,
              })}
            >
              {label}
            </span>
            <div
              className={cn(styles.iconContainer, {
                [styles.signout]: tag === 'logout',
                [styles.child]: isChildNode,
              })}
            >
              <span
                className={`icon menuIcons ${(!expanded && active) || isMobile ? 'active' : ''} ${
                  visible ? '' : 'collapsed'
                } ${isMobile ? 'mobile' : ''} ${isChildNode ? 'child' : ''}`}
              />
            </div>
          </button>
        </div>
      </React.Fragment>
    );
  }
}
