import { Button } from 'components/Buttons/Button';
import { ButtonRow } from 'components/Login/ButtonRow/ButtonRow';
import { Text } from 'components/Login/Text/Text';
import { LoginCheckbox } from 'components/LoginCheckbox';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { BUTTON_TYPE_DARK_BLUE } from '../../../../components/Buttons/Button';
import Strings from '../../../../Strings';

export class Step3_AcceptTerms extends PureComponent {
  state = {
    terms: false,
    privacy: false,
    license: false,
  };
  termsInput = React.createRef();
  privacyInput = React.createRef();
  licenseInput = React.createRef();

  onFormSubmit = event => {
    const { onFormSubmit } = this.props;
    event.preventDefault();
    onFormSubmit({ docs: true });
  };

  onCheckChange = event => {
    const name = event.target.name;
    const value = event.target.checked;
    this.setState(() => ({ [name]: value }));
  };

  render() {
    const { terms, privacy, license } = this.state;
    return (
      <form onSubmit={this.onFormSubmit}>
        <Text>{Strings.toProceedYouNeedToAcceptTheFollowing}</Text>

        <LoginCheckbox
          name="terms"
          ref={this.termsInput}
          link="/esi-patient-terms"
          text={Strings.termsOfUse}
          onCheckChange={this.onCheckChange}
        />
        <LoginCheckbox
          name="privacy"
          ref={this.privacyInput}
          link="/esi-patient-privacy"
          text={Strings.privacyPolicyTrust}
          onCheckChange={this.onCheckChange}
        />
        <LoginCheckbox
          name="license"
          ref={this.licenseInput}
          link="/esi-patient-license"
          text={Strings.endUserLicenseAgreement}
          onCheckChange={this.onCheckChange}
        />

        <ButtonRow
          full={
            <Button buttonType={BUTTON_TYPE_DARK_BLUE} type="submit" disabled={!(terms && privacy && license)}>
              {Strings.next}
            </Button>
          }
        />
      </form>
    );
  }
}

Step3_AcceptTerms.propTypes = { onFormSubmit: PropTypes.func };
