import { API_CALL } from '../../../../../middleware/api';
import { WIDGET_PATIENTS_TO_CALL } from './constants';

export const actions = {
  getPatientsToCall: params => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/patients/patients-to-call',
      params,
    },
    type: WIDGET_PATIENTS_TO_CALL,
  }),
};
