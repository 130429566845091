import React from 'react';
import PropTypes from 'prop-types';

import './graph.scss';
import GenericCategoryGraph from './GenericCategoryGraph';

export default function DeviceStatsGraph(props) {
  const { graphData, colorPalette } = props;
  const dataSeries = [
    {
      name: 'Devices',
      color: colorPalette?.interpolate(0.73),
      selector: item => item.count,
    },
  ];

  return (
    <GenericCategoryGraph
      graphData={graphData}
      hasBorder={true}
      forceCondensed
      xAxisTopPosition={false}
      yAxisWidth={40}
      yAxisUnit=""
      tooltipTitle="Number of Devices"
      dataSeries={dataSeries}
      categorySelector={item => item.state}
      hasReferenceAreas={true}
      graphType={props.graphType}
      colorPalette={colorPalette}
      onCategoryClicked={props.onCategoryClicked}
    />
  );
}

DeviceStatsGraph.propTypes = { graphData: PropTypes.array };
