import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { openModalAction } from '../../../../actions/modal';
import { PageHeader } from '../../../../components/PageHeader';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../../components/PageHeader/Button';
import { TextInput } from '../../../../components/PageHeader/TextInput';
import {
  DATE_MONTH_DAY_YEAR,
  GetTimezoneTextMapping,
  PAGE_LIMIT,
  TIME_FORMAT_12,
  TIME_FORMAT_12_UPPERCASE,
} from '../../../../constants';
import Table, { Column } from '../../../../containers/Table/TableWithPagination';
import Strings from '../../../../Strings';
import { getCapChargeText } from '../../../../utils';
import { actions } from '../redux/actions';
import { SCHEDULE_DETAILS } from '../redux/constants';
import './ScheduleDetails.scss';

class ScheduleDetails extends PureComponent {
  searchTime;

  pageRequest = {
    offset: 0,
    search: '',
    maskedId: decodeURIComponent(this.props.match.params.id),
    scheduleName: '',
  };

  componentDidMount() {
    this.props.getScheduleDetails(this.pageRequest).then(res => {
      this.pageRequest.scheduleName = res.response.schedule_name;
    });
  }

  onSearchQueryChange = query => {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    clearTimeout(this.searchTime);
    this.searchTime = setTimeout(() => {
      this.props.getScheduleDetails(this.pageRequest);
    }, 1000);
  };

  getHeaderComponents = () => {
    const { onAttachCapToSchedule } = this.props;
    return (
      <React.Fragment>
        <TextInput
          class="search"
          placeholder={Strings.search}
          onChange={e => this.onSearchQueryChange(e.target.value)}
        />
        <Button
          class={HEADER_BUTTON_DARK_BLUE}
          onClick={() => onAttachCapToSchedule(this.pageRequest)}
          title={Strings.schedules.addCap}
        />
      </React.Fragment>
    );
  };

  onPrevClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset - PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getScheduleDetails(this.pageRequest);
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.getScheduleDetails(this.pageRequest);
  };

  onNextClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset + PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getScheduleDetails(this.pageRequest);
  };

  onSortClick = ({ sortKey, direction }) => {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    this.props.getScheduleDetails(this.pageRequest);
  };

  onOrgEdit = (event, org) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.onEditOrganization(org);
  };

  onScheduleDelete = (event, schedules) => {
    event.preventDefault();
    event.stopPropagation();
    const deleteData = {
      masked_id: decodeURIComponent(this.props.match.params.id),
      device_id: schedules.cap_id,
    };

    const data = {
      title: (
        <span>
          {Strings.deleteScheduleCapWarning} <b>{schedules.cap_id}</b>?
        </span>
      ),

      onConfirmAction: actions.deleteScheduleCaps(deleteData),
      onCancelAction: null,
    };
    this.props.openConfirmModal(data);
  };

  onBackButton = () => {
    this.props.onNavigate(`/schedules`);
  };

  onRowSelection = id => {
    const scheduleId = this.props.match.params.id;
    const deviceId = this.props.schedules.caps[id].cap_id;
    const type = this.props.schedules.caps[id].version;

    if (type === '') {
      this.props.onNavigate(`/schedules/${scheduleId}/${deviceId}/default`);
    } else {
      this.props.onNavigate(`/schedules/${scheduleId}/${deviceId}/${type}`);
    }
  };

  getLeftTimeFor(timeFor) {
    const { schedules } = this.props;

    if (schedules?.start_dt) {
      const startDate = moment(schedules?.start_dt);
      const lastTake = moment(timeFor);
      if (startDate.diff(lastTake, 'days') < 0 || timeFor === 0) {
        return moment.unix(schedules?.start_dt).fromNow(true);
      }
    }

    if (timeFor) {
      return moment.unix(timeFor).fromNow(true);
    }
    return '-';
  }

  render() {
    const { isLoading, schedules, pagination } = this.props;
    const timezones = GetTimezoneTextMapping();
    return (
      <div className="schedule-details shipper">
        <div>
          <div className="infoHeader">
            <div className="rowDirection">
              <button className="back" onClick={() => this.onBackButton()} tabIndex={0}>
                {Strings.back}
              </button>
              <div className="feildBox">
                <div className="feildValue">{Strings.schedules.schedules}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="details-container">
          <div className="infoContainer">
            <div className="two-row">
              <div className="infoBox center first">
                <div className="data">
                  <div className="rowContent tooltipSchedule">
                    <label>{Strings.schedules.scheduleName}</label>
                    <span className="labelValue">{schedules?.schedule_name}</span>
                  </div>

                  <div className="rowContent">
                    <label>{Strings.schedules.medication}</label>
                    <span className="labelValue">{schedules?.medication_name}</span>
                  </div>

                  <div className="rowContent">
                    <label>{Strings.schedules.scheduleType}</label>
                    <span className="labelValue cap">{schedules?.schedule_type}</span>
                  </div>

                  <div className="rowContent">
                    <label>{Strings.schedules.time}</label>
                    <span className="labelValue">
                      {schedules?.offset?.map(e => {
                        return `${moment()
                          .startOf('day')
                          .seconds(parseInt(e, 10))
                          .format(TIME_FORMAT_12)} `;
                      })}
                      {schedules?.schedule_timezone
                        ? `(${
                            timezones[schedules.schedule_timezone]
                              ? timezones[schedules.schedule_timezone]
                              : schedules.schedule_timezone
                          })`
                        : ''}
                    </span>
                  </div>
                </div>
              </div>

              <div className="infoBox center second">
                <div className="data">
                  <div className="rowContent tooltipSchedule">
                    <label>{Strings.schedules.accuracy}</label>
                    <span className="labelValue">{schedules?.accuracy} %</span>
                  </div>

                  <div className="rowContent tooltipSchedule">
                    <label>{Strings.schedules.patient}</label>
                    <span className="labelValue">{schedules?.patient_count}</span>
                  </div>

                  <div className="rowContent">
                    <label>{Strings.schedules.startDate}</label>
                    <span className="labelValue">
                      {schedules?.start_dt ? moment.unix(schedules?.start_dt).format(DATE_MONTH_DAY_YEAR) : '-'}
                    </span>
                  </div>

                  <div className="rowContent">
                    <label>{Strings.schedules.endDate}</label>
                    <span className="labelValue">
                      {schedules?.end_dt ? moment.unix(schedules?.end_dt).format(DATE_MONTH_DAY_YEAR) : '-'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PageHeader getHeaderComponents={() => this.getHeaderComponents()} />
          <Table
            isLoading={isLoading}
            name="organizations"
            data={schedules?.caps || []}
            onPrevClick={this.onPrevClick}
            onSortClick={this.onSortClick}
            onRowSelection={this.onRowSelection}
            onNextClick={this.onNextClick}
            onCustomPage={this.onCustomPage}
            pagination={
              pagination || {
                offset: 0,
                total: 0,
              }
            }
          >
            <Column key="schedule_name" title={Strings.schedules.capId} value={d => d.cap_id} />
            <Column key="cap_name" title={Strings.schedules.capName} value={d => d.name} />
            <Column key="patient_count" title={Strings.schedules.accuracy} value={d => `${d.accuracy} %`} />
            <Column
              key="schedule_type"
              title={Strings.schedules.timeFor}
              value={d => this.getLeftTimeFor(d.take_time)}
            />
            <Column key="battery" title={Strings.schedules.battery} value={d => getCapChargeText(d.battery)} />
            <Column
              key="last_updated_at_date"
              title={Strings.schedules.lastActivityDate}
              value={d => {
                return d?.last_updated_at ? moment.unix(d.last_updated_at).format(DATE_MONTH_DAY_YEAR) : '-';
              }}
            />
            <Column
              key="last_updated_at"
              title={Strings.schedules.lastActivityTime}
              value={d => (d?.last_updated_at ? moment.unix(d?.last_updated_at).format(TIME_FORMAT_12_UPPERCASE) : '-')}
            />
            <Column
              key="edit"
              value={d => (
                <React.Fragment>
                  <div style={{ display: 'flex' }}>
                    <span className="trash-icon" onClick={e => this.onScheduleDelete(e, d)}></span>
                  </div>
                </React.Fragment>
              )}
            />
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { schedules } = state.superUser;
  return {
    schedules: schedules && schedules?.scheduleDetails,
    isLoading: schedules && schedules?.scheduleDetails?.isLoading,
    pagination: schedules && schedules?.scheduleDetails?.pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getScheduleDetails: pageRequest => dispatch(actions.getScheduleDetails(pageRequest)),
  onAttachCapToSchedule: req =>
    dispatch(
      openModalAction('attach-cap-to-schedule', {
        action: actions.getScheduleDetails,
        actionType: SCHEDULE_DETAILS,
        request: req, // props.match.params.id,
      }),
    ),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDetails);
