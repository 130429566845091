import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';

import { patientPortalActions } from '../../actions/patient-portal';
import LoadingRenderer from '../../components/LoadingRenderer';
import { openModalAction } from '../../actions/modal';
import { weekDays } from '../../utils';
import NoData from '../../components/NoData';
import Strings from '../../Strings';

const mapStateToProps = state => {
  const getDaysOfWeek = mask => {
    const days = {};

    Object.values(weekDays).map(weekDay => {
      days[weekDay.name] = !!(weekDay.mask & mask); // eslint-disable-line no-bitwise
      return null;
    });

    return days;
  };

  const schedules = Object.values(state.entities.patientSchedules.objects).map(row => {
    const schedule = row;

    schedule.days = getDaysOfWeek(row.days_of_week);
    schedule.daysCount = Object.values(schedule.days).filter(exists => exists).length;

    schedule.times = {};

    if (row.events && row.events.length) {
      row.events.split(',').map(event => {
        schedule.times[event] = {
          offset: event,
          time: moment.unix(event).tz('UTC'),
          label: moment
            .unix(event)
            .tz('UTC')
            .format('h:mma'),
        };

        return null;
      });
    }

    return schedule;
  });

  const schedulesByWeekDay = Object.values(schedules).reduce((data, schedule) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const weekDay in schedule.days) {
      if (schedule.days[weekDay]) {
        data[weekDay].schedules.push(schedule);
      }
    }

    return data;
  }, cloneDeep(weekDays));

  const maxSchedulesCount = Object.values(schedulesByWeekDay).reduce((length, weekDay) => {
    if (weekDay.schedules.length > length) {
      length = weekDay.schedules.length; // eslint-disable-line prefer-destructuring, no-param-reassign
    }

    return length;
  }, 0);

  return {
    schedules,
    schedulesByWeekDay,
    maxSchedulesCount,
    isLoading: !!state.entities.patientSchedules.loading,
    timezone: state.auth.profile ? state.auth.profile.timezone : null,
  };
};

const mapDispatchToProps = dispatch => ({
  loadSchedules: () => dispatch(patientPortalActions.getSchedules()),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  onEditSchedule: schedule => dispatch(openModalAction('edit-schedule', { schedule })),
});

class Medications extends PureComponent {
  static propTypes = {
    loadSchedules: PropTypes.func.isRequired,
    openConfirmModal: PropTypes.func.isRequired,
    schedules: PropTypes.array,
    schedulesByWeekDay: PropTypes.any,
    maxSchedulesCount: PropTypes.number,
    isLoading: PropTypes.bool,
  };

  UNSAFE_componentWillMount() {
    this.props.loadSchedules();
  }

  deleteSchedule(event, schedule) {
    event.preventDefault();

    const data = {
      title: (
        <span>
          {Strings.areYouSureText}
          <b>{schedule.name}</b>?
        </span>
      ),
      onConfirmAction: patientPortalActions.deleteSchedule(schedule.id),
      onCancelAction: null,
    };
    this.props.openConfirmModal(data);
  }

  render() {
    const { schedules, schedulesByWeekDay, maxSchedulesCount, isLoading } = this.props;

    const fillNumbers = () => {
      const items = [];

      for (let i = maxSchedulesCount; i > 0; i -= 1) {
        items.push(
          <div key={`number-${i}`} className="schedule-number">
            {i}
          </div>,
        );
      }

      return items;
    };

    const fillChart = schedulesCount => {
      const items = [];

      for (let i = schedulesCount; i < maxSchedulesCount; i += 1) {
        items.push(<div key={-i} className="schedule empty" />);
      }

      return items;
    };

    return (
      <div>
        <div className="content-header shifted">
          <div className="button-bar">
            {/* <button
              role="button"
              onClick={() => this.props.onEditSchedule(null)}
              className="add-medication brand-blue"
            >
              {Strings.addMedicationText}
            </button> */}
          </div>
        </div>
        <div className="medications">
          <LoadingRenderer loading={isLoading}>
            {schedules.length ? (
              <div>
                <div className="week-medications content-block">
                  <div className="numbers" key="numbers">
                    {fillNumbers()}
                  </div>
                  <div className="week-parts" key="week-parts">
                    {Object.values(schedulesByWeekDay).map(weekDay => (
                      <div className="week-part" key={weekDay.name}>
                        <div className="week-content">
                          {fillChart(weekDay.schedules.length)}
                          {weekDay.schedules
                            .sort((a, b) => a.id <= b.id)
                            .map(schedule => (
                              <div
                                key={schedule.id}
                                className="schedule"
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                  <div>
                                    <b>{schedule.name}</b>
                                    <div className="line" />
                                    {Strings.dosageText}
                                    <b>{schedule.dosage}</b>
                                    <br />
                                    {Strings.daysText}
                                    <b>{schedule.days.length}</b>
                                    <br />
                                    {Strings.timesText}{' '}
                                    <b>
                                      {Object.values(schedule.times)
                                        .map(time => time.label)
                                        .join(' ')}
                                    </b>
                                  </div>,
                                )}
                                data-tooltip-id="tooltip"
                              >
                                <div className="status-icon pending" />
                              </div>
                            ))}
                        </div>
                        <div
                          className={`week-footer ${weekDay.first ? 'first' : null} ${weekDay.last ? 'last' : null}`}
                        >
                          <div className="week-part-name">
                            <span className="long">{weekDay.name}</span>
                            <span className="short">{weekDay.shortName}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="schedules-list">
                  {Object.values(schedules).map(schedule => (
                    <div className="schedule content-block" key={schedule.id}>
                      <div className="row title">{schedule.name}</div>
                      <div className="row dosage">
                        <span className="label">{Strings.dosage}</span>
                        <span className="value">{schedule.dosage}</span>
                      </div>
                      <div className="row days-of-week">
                        <span className="label">{Strings.daysOfWeek}</span>
                        <span className="value">
                          {schedule.daysCount} {schedule.daysCount !== 1 ? 'Days' : 'Day'}
                        </span>
                        <div className="week-days">
                          {Object.values(weekDays).map(weekDay => (
                            <div
                              key={weekDay.name}
                              className={`week-day ${schedule.days[weekDay.name] ? 'active' : ''}`}
                            >
                              {weekDay.shortName}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="row times" style={{ border: 'none' }}>
                        <span className="label">{Strings.times}</span>
                        <div className="value">
                          {Object.values(schedule.times)
                            .map(time => time.label)
                            .join(' ')}
                        </div>
                      </div>
                      {/* <div className="buttons">
                        <button
                          role="button"
                          className="brand-white-gray"
                          onClick={e => this.deleteSchedule(e, schedule)}
                        >
                          {Strings.delete}
                        </button>
                        <button
                          role="button"
                          className="brand-blue"
                          onClick={() => this.props.onEditSchedule(schedule)}
                        >
                          {Strings.edit}
                        </button>
                      </div> */}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <NoData />
            )}
          </LoadingRenderer>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Medications);
