import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { actions } from '../../SuperUser/Report/redux/actions';
import { numberWithTitleWidget } from '../../SuperUser/Report/ReportDashboard';
import { PERMISSIONS } from '../../../utils/userPermissions';
import { customFormattingConfig } from './WidgetConfigs';
import { isSuperUser } from '../../../utils/userRoles';

export const getActiveMonthlyPillboxUsersMetadata = (skipPremissionCheck, allowConfig) => {
  return {
    id: 'ActiveMonthlyPillboxUsers',
    name: 'Active Monthly Pillbox Users',
    permission: skipPremissionCheck ? undefined : PERMISSIONS.PATIENTS_ADHERENCE_ACTIVE,
    defProps: { i: 'ActiveMonthlyPillboxUsers', w: 4, h: 2, minW: 2, minH: 2 },
    configuration: allowConfig ? customFormattingConfig : undefined,
  };
};

function EsiActivePillboxUsers(props) {
  const [data, setData] = useState();

  React.useEffect(() => {
    let request = {
      start_time: moment()
        .subtract(1, 'months')
        .startOf('month')
        .format(),
      end_time: moment()
        .subtract(1, 'months')
        .endOf('month')
        .format(),
    };
    props.getEsiPatientsStats(request).then(resp => {
      setData(resp.response);
    });
  }, []);

  const widget = numberWithTitleWidget(
    getActiveMonthlyPillboxUsersMetadata(isSuperUser()),
    data?.active_patients,
    '',
    props.dashboardSettingsFeature,
  );

  return widget?.render ? (
    React.cloneElement(widget.render, {
      ...props,
    })
  ) : (
    <></>
  );
}

EsiActivePillboxUsers.propTypes = {
  getEsiPatientsStats: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getEsiPatientsStats: request => dispatch(actions.getEsiPatientStats(request)),
});

export default connect(null, mapDispatchToProps)(EsiActivePillboxUsers);
