import React from 'react';

import '../editStudyModal.scss';
import '../AttachCapToSchedule/attachCap.scss';
import Strings from '../../Strings';
import { SelectedOptions } from '../../components/Select/Multiselect';
import AsyncSelect from '../../components/Select/AsyncSelect';
import { SelectField } from '../../containers/Form';

const DeviceList = props => {
  return (
    <React.Fragment>
      {props.asyncNeeded ? (
        <div className="row">
          <label>{Strings.attachDevicesToKit}</label>
          <AsyncSelect
            key={`device-list-${props.devices?.length || 0}`}
            placeholder={Strings.patient_medications.enter3Chars}
            loadOptions={props.loadOptions}
            defaultOptions={props.data}
            onChange={props.handleChange}
          />
        </div>
      ) : (
        <SelectField
          name="devices"
          id="devices"
          label={Strings.attachDevicesToKit}
          key={`device-list-${props.devices?.length || 0}`}
          placeholder={Strings.select}
          onChange={props.handleChange}
          data={props.data}
          isSearchable
        />
      )}
      <SelectedOptions items={props.devices} onRemove={props.delete} itemsPerRow={1} />
    </React.Fragment>
  );
};

export default DeviceList;
