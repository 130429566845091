import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import styles from './style.module.scss';

export class Row extends PureComponent {
  render() {
    const { children } = this.props;
    return <div className={styles.row}>{children}</div>;
  }
}

Row.propTypes = { children: PropTypes.any };
