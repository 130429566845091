import PropTypes from 'prop-types';

import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';

import { openModalAction } from '../../../actions/modal';
import { PageHeader } from '../../../components/PageHeader';
import { Button } from '../../../components/PageHeader/Button';
import { TextInput } from '../../../components/PageHeader/TextInput';
import { HEADER_BUTTON_BLUE, PAGE_LIMIT, TIME_FORMAT_12_UPPERCASE } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { getCapChargeText, toolTipWithValue } from '../../../utils';
import { actions } from '../Organization/redux/actions';
import { ORGANIZATION_DEVICES } from '../Organization/redux/constants';

class HubManage extends PureComponent {
  pageRequest = {
    offset: 0,
    search: '',
    filterBy: 'hub',
  };

  componentDidMount() {
    if (this.props.history?.location?.state?.params?.offset) {
      this.pageRequest.offset = this.props.history?.location?.state?.params?.offset;
      this.props.loadCaps(this.pageRequest);
    } else {
      this.props.loadCaps(this.pageRequest);
    }
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  onSearchQueryChange(query) {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    this.props.loadCaps(this.pageRequest);
  }

  onRowSelected = id => {
    this.props.onNavigate(`/org-devices/hub/${this.props.devices[id].cap_id}/devices`, {
      offset: this.pageRequest.offset,
    });
  };

  onDateRangeChanged = () => {
    this.pageRequest.offset = this.props.pagination.offset;
    this.props.loadCaps(this.pageRequest);
  };

  onPrevClick = () => {
    const { pagination } = this.props;
    this.pageRequest.offset = pagination.offset - PAGE_LIMIT;
    this.props.loadCaps(this.pageRequest);
  };

  onNextClick = () => {
    const { pagination } = this.props;
    this.pageRequest.offset = pagination.offset + PAGE_LIMIT;
    this.props.loadCaps(this.pageRequest);
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.loadCaps(this.pageRequest);
  };

  onChange = event => {
    this.setState({ [event.target.getAttribute('name')]: event.target.value });
  };

  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  getHeaderComponents() {
    const { onAttachCapToAdmin } = this.props;
    return (
      <React.Fragment>
        <TextInput class="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        <Button
          class={HEADER_BUTTON_BLUE}
          onClick={() => onAttachCapToAdmin(this.pageRequest)}
          title={Strings.attachCap}
        />
      </React.Fragment>
    );
  }

  render() {
    const { devices, isLoading, pagination } = this.props;
    const columns = [];

    columns.push(<Column key="cap_id" title={Strings.hubId} value={e => e.cap_id} />);
    columns.push(<Column key="imei" title={Strings.imei} value={e => e.imei} />);

    columns.push(
      <Column
        key="lastactivity"
        title={Strings.lastActivity}
        value={e =>
          moment(moment.utc(moment.unix(e.timestamp)))
            .local()
            .format(TIME_FORMAT_12_UPPERCASE)
        }
      />,
    );
    columns.push(
      <Column key="batterylevel" title={Strings.battery.battery} value={d => getCapChargeText(d.battery)} />,
    );
    // columns.push(<Column key="charge" title={Strings.battery.battery} value={d => getCapChargeText(d.charge)} />);
    columns.push(<Column key="signalstrength" title={Strings.signalStrength} value={e => e.signal_strength} />);
    columns.push(
      <Column key="location" title={Strings.location} value={e => toolTipWithValue(e.location ? e.location : '-')} />,
    );
    columns.push(<Column key="devices" title={Strings.devices} value={e => e.devices} />);
    columns.push(<Column key="status" title={Strings.status} value={e => e.status} />);
    return (
      <React.Fragment>
        <PageHeader
          title={Strings.devicesTab}
          onBackClick={this.onBackButton}
          getHeaderComponents={() => this.getHeaderComponents()}
        />
        {devices && (
          <Table
            name="cap-list"
            data={devices}
            onPrevClick={this.onPrevClick}
            onNextClick={this.onNextClick}
            onRowSelection={this.onRowSelected}
            onCustomPage={this.onCustomPage}
            pagination={
              pagination || {
                offset: 0,
                total: 0,
              }
            }
            isLoading={isLoading}
          >
            {columns}
          </Table>
        )}
      </React.Fragment>
    );
  }
}

HubManage.propTypes = {
  clearData: PropTypes.func,
  devices: PropTypes.any,
  history: PropTypes.shape({
    location: PropTypes.shape({ state: PropTypes.shape({ params: PropTypes.shape({ offset: PropTypes.any }) }) }),
  }),
  isLoading: PropTypes.any,
  loadCaps: PropTypes.func,
  onAttachCapToAdmin: PropTypes.func,
  onNavigate: PropTypes.func,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
};

const mapStateToProps = state => {
  const { organizations } = state.superUser;
  return {
    role: state.auth.role,
    devices: organizations && organizations?.devices,
    isLoading: organizations && organizations?.isLoading,
    pagination: organizations && organizations?.pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: (path, params) =>
    dispatch(
      push({
        pathname: path,
        state: { params },
      }),
    ),
  loadCaps: pageRequest => dispatch(actions.getDevices(pageRequest)),
  clearData: () => dispatch(actions.clearData()),
  onAttachCapToAdmin: req =>
    dispatch(
      openModalAction('attach-cap-to-org', {
        action: actions.getDevices,
        actionType: ORGANIZATION_DEVICES,
        type: 'hub',
        request: req,
      }),
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HubManage));
