import PropTypes from 'prop-types';

import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { openModalAction } from '../../../actions/modal';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../components/PageHeader/Button';
import { TextInput } from '../../../components/PageHeader/TextInput';
import { DATE_FORMAT_MONTH_NAME_2, PAGE_LIMIT, TIME_HOUR_MINUTE_SECONDS_FORMAT_12_UPPERCASE } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { toolTipForTable } from '../../../utils';
import './patient.scss';
import { actions } from './redux/actions';
import { UPDATE_TASKS } from './redux/constants';
import { getProgramDisplayName } from '../../../utils/cmsPrograms';

class TaskHistory extends PureComponent {
  searchTime;

  componentDidMount() {
    const { patientId } = this.props;
    this.pageRequest = {
      offset: 0,
      search: '',
      patientId,
    };
    this.props.getTaskHistory(this.pageRequest);
  }

  onSearchQueryChange = query => {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    clearTimeout(this.searchTime);
    this.searchTime = setTimeout(() => {
      this.props.getTaskHistory(this.pageRequest);
    }, 1000);
  };

  getHeaderComponents = () => {
    const { onAddSchedule } = this.props;
    return (
      <React.Fragment>
        <TextInput
          class="search"
          placeholder={Strings.search}
          onChange={e => this.onSearchQueryChange(e.target.value)}
        />
        <Button
          class={HEADER_BUTTON_DARK_BLUE}
          onClick={() => onAddSchedule(this.pageRequest)}
          title={Strings.addPatient}
        />
      </React.Fragment>
    );
  };

  onPrevClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset - PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getTaskHistory(this.pageRequest);
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.getTaskHistory(this.pageRequest);
  };

  onNextClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset + PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getTaskHistory(this.pageRequest);
  };

  onSortClick = ({ sortKey, direction }) => {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    this.props.getTaskHistory(this.pageRequest);
  };

  onEditTask = (id, task, readOnly = false) => {
    const { patientDetails, taskHistory } = this.props;
    const programs = [];
    if (patientDetails?.status === 'enrolled') {
      Object.entries(patientDetails.patientEnrollment.enrollments).forEach(([key, value]) => {
        if (value.kitAccepted === true && (value.verbalConsent === true || value.writtenConsent === true))
          programs.push({ enrollmentId: key, program: value.program, subprogram: value.subprogram });
      });
    }
    this.props.onEditTask(patientDetails.id, taskHistory[id], this.props.taskList, programs, readOnly, () => {
      this.props.getTaskHistory(this.pageRequest);
      this.props.getPatientBillableTime(patientDetails.id);
    });
  };

  onDeleteTaskHistory = id => {
    const { patientId, taskHistory } = this.props;

    const deleteRequest = {
      patientId,
      taskMetadataID: taskHistory.taskMetadataID,
      id: taskHistory[id].id,
    };
    const data = {
      title: (
        <span>
          {Strings.deleteTaksHistoryWarning} <b>{taskHistory[id].taskName}</b>?
        </span>
      ),

      onConfirmAction: actions.deleteTaskHistory(deleteRequest, [
        actions.getTaskHistory(this.pageRequest),
        actions.getPatientBillableTime(patientId),
      ]),
      onCancelAction: null,
    };
    this.props.openConfirmModal(data);
  };

  getNotes = d => {
    return (
      <div className="icon-and-text-container">
        <div className="icon-container notes" />
        {`${d.notes.length} ${Strings.characters}`}
      </div>
    );
  };

  getProgram = enrollmentId => {
    if (!enrollmentId || !this.props.patientDetails?.patientEnrollment?.enrollments) {
      return '-';
    }
    const enrollment = this.props.patientDetails.patientEnrollment.enrollments[enrollmentId];
    if (!enrollment) {
      return '-';
    }
    return getProgramDisplayName(enrollment.program, enrollment.subprogram);
  };

  render() {
    const { isLoading, taskHistory, pagination } = this.props;

    return (
      <div className="patient-container topPadding">
        <Table
          className="patient-task-history-table"
          isLoading={isLoading}
          name="organizations"
          data={taskHistory || []}
          onRowSelection={(id, task) => this.onEditTask(id, task, true)}
          onPrevClick={this.onPrevClick}
          onSortClick={this.onSortClick}
          onNextClick={this.onNextClick}
          onCustomPage={this.onCustomPage}
          pagination={
            pagination || {
              offset: 0,
              total: 0,
            }
          }
          buttons={[
            {
              icon: 'delete',
              onClick: this.onDeleteTaskHistory,
              disabled: () => {
                return false;
              },
              text: Strings.delete,
            },
            {
              icon: 'edit',
              onClick: this.onEditTask,
              disabled: () => {
                return false;
              },
              text: Strings.edit,
            },
          ]}
        >
          <Column key="taskName" title={Strings.capPatient.taskName} value={d => d.taskName} />
          <Column
            key="taskNotes"
            title={Strings.capPatient.taskNotes}
            value={d => (d.notes ? toolTipForTable(this.getNotes(d), d.notes) : '-')}
          />
          <Column key="program" title={Strings.program} value={d => this.getProgram(d.enrollmentID)} />
          <Column
            key="taskTime"
            title={Strings.capPatient.taskTime}
            value={d =>
              d.startTime && d.endTime
                ? moment.utc(moment(d.endTime * 1000).diff(moment(d.startTime * 1000))).format('HH:mm:ss')
                : '-'
            }
          />
          <Column
            key="startTime"
            title={Strings.capPatient.startTime}
            value={d =>
              d.startTime ? moment(d.startTime * 1000).format(TIME_HOUR_MINUTE_SECONDS_FORMAT_12_UPPERCASE) : '-'
            }
          />
          <Column
            key="endTime"
            title={Strings.capPatient.endTime}
            value={d =>
              d.endTime ? moment(d.endTime * 1000).format(TIME_HOUR_MINUTE_SECONDS_FORMAT_12_UPPERCASE) : '-'
            }
          />
          <Column
            key="date"
            title={Strings.capPatient.date}
            value={d => (d.endTime ? moment(d.endTime * 1000).format(DATE_FORMAT_MONTH_NAME_2) : '-')}
          />
        </Table>
      </div>
    );
  }
}

TaskHistory.propTypes = {
  getTaskHistory: PropTypes.func,
  isLoading: PropTypes.any,
  onAddSchedule: PropTypes.func,
  onEditTask: PropTypes.func,
  openConfirmModal: PropTypes.func,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
  patientDetails: PropTypes.any,
  patientId: PropTypes.any,
  taskHistory: PropTypes.array,
  taskList: PropTypes.any,
  getPatientBillableTime: PropTypes.func,
};

const mapStateToProps = state => {
  const { patients } = state.superUser;

  return {
    taskHistory: patients?.taskHistory?.data,
    isLoading: patients?.isTaskHistoryLoading,
    pagination: patients?.pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getTaskHistory: pageRequest => dispatch(actions.getTaskHistory(pageRequest)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  onEditTask: (patientId, task, taskList, programs, readOnly, successAction) =>
    dispatch(
      openModalAction('update-task', {
        action: actions.updateTask,
        successAction,
        actionType: UPDATE_TASKS,
        task,
        taskList,
        patientId,
        programs,
        readOnly,
      }),
    ),
  getPatientBillableTime: patientId => dispatch(actions.getPatientBillableTime(patientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskHistory);
