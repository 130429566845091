import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal } from '../containers';
import Form, { Input } from '../containers/Form';
import { closeModal } from '../actions/modal';
import { resetForm } from '../actions/forms';
import LoadingRenderer from '../components/LoadingRenderer';
import Strings from '../Strings';
import './editStudyModal.scss';
import { ADD_NICK_NAME_ERROR } from '../actions/action-types';
import { capsAction } from '../actions/caps';

class AddNickNameModal extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      admin: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),
      allowEmpty: PropTypes.bool,
      action: PropTypes.func.isRequired,
      actionType: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const capName = props.data?.capName;
    this.state = {
      errors: [],
      capNickName: capName || '',
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onTextChange(event) {
    this.setState({ capNickName: event.target.value });
  }

  onSubmit() {
    const { capNickName } = this.state;
    const validationErrors = [];

    if (!capNickName.length && !this.props.data.allowEmpty) {
      validationErrors.push(Strings.errors.emptyNickName);
    }

    if (validationErrors.length) {
      this.setState({ errors: validationErrors });
      return;
    }

    const caps = {
      cap_id: this.props.data.id,
      name: capNickName,
    };

    this.props.onSubmit(caps).then(response => {
      if (response.type === ADD_NICK_NAME_ERROR && response.response.data) {
        this.setState({ errors: [response.response.data.error?.message] });
      } else {
        this.props.onCancel();
      }
      return response;
    });
  }

  render() {
    const { onOpen, onCancel, AddNickNameModalLoading, ...props } = this.props;
    const { capNickName, errors } = this.state;

    return (
      <Modal
        name="add-nick-name-modal"
        onOpen={onOpen}
        additionalClasses={['form-modal', 'user-invite', 'invite-admin']}
        {...props}
      >
        <LoadingRenderer loading={!!AddNickNameModalLoading}>
          <Form key="add-nick-name-form" onSubmit={this.onSubmit} onCancel={onCancel} id="add-nick-name-form">
            <div key="admin-email" className="dialog-input1">
              <Input
                name="capNickName"
                id="capNickName"
                label=""
                className="dialog-form"
                placeholder="Enter name"
                type="text"
                defaultValue={capNickName}
                onChange={this.onTextChange}
                maxLength="25"
              />
            </div>
            <div>(Max length of 25 characters)</div>

            <div key="errors-block" className="errors-block">
              {errors.length ? <div>{errors.join(' ')}</div> : null}
            </div>
            <div className="button-bar reverse" key="button-bar">
              <button className="brand-blue" key="submit" type="submit">
                {Strings.save}
              </button>
              <button className="white gray-text" key="cancel" type="cancel">
                {Strings.cancel}
              </button>
            </div>
          </Form>
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({ AddNickNameModalLoading: state.entities.admins.inviteLoading });

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('add-nick-name')),
  onSubmit: admin =>
    dispatch(admin.id ? ownProps.data.action(admin.id, admin) : ownProps.data.action(admin)).then(response => {
      if (response && response.type === `${ownProps.data.actionType}/result`) {
        dispatch(ownProps.data.nextAction(ownProps.data.id, ownProps.data.pageRequest));
        dispatch(closeModal('add-nick-name'));
      }

      return response;
    }),
  onOpen: () => dispatch(resetForm('add-nick-name', ownProps.data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNickNameModal);
