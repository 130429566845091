import classNames from 'classnames';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.scss';
import { isDevelopment } from '../../utils/environment';
import Strings from '../../Strings';

export const SELECT_TYPE_LEFT = 0;
export const SELECT_TYPE_RIGHT = 1;

class Select extends PureComponent {
  static CLASS_BIG = 'big';
  static CLASS_DARK = 'dark';
  static CLASS_GRAY = 'gray';
  static CLASS_LEFT_OPTIONS = 'left_options';
  static CLASS_PATIENT_DASHBOARD = 'pattient_dashboard';
  static CLASS_WHITE_BLUE = 'white_blue';
  static CLASS_BLUE = 'blue';
  static CLASS_NO_MARGIN_RIGHT = 'no_margin_right';
  static CLASS_NO_MARGIN_LEFT = 'no_margin_left';
  static BLACK_BOX = 'blackBox';
  static CLASS_NO_BORDER = 'no_border';
  static CLASS_MAX_WIDTH_600 = 'max_width_600';

  static propTypes = {
    onChange: PropTypes.func,
    defaultValue: PropTypes.string, // deprecated
    value: PropTypes.string,
    type: PropTypes.number,
    data: PropTypes.array.isRequired,
    classes: PropTypes.any,
    autoWidth: PropTypes.bool,
  };

  static defaultProps = {
    type: SELECT_TYPE_RIGHT,
    autoWidth: false,
    classes: [],
  };

  state = { value: this.props.value || this.props.defaultValue };

  refSelect = React.createRef();

  componentDidMount() {
    if (isDevelopment()) {
      if (this.props.defaultValue) {
        console.warn('Prop `defaultValue` in Select is deprecated'); // eslint-disable-line no-console
      }
    }

    this.calculateNewWidth();
  }

  onChange = e => {
    const { onChange } = this.props;
    const value = e.target.value;

    this.setValue(value);

    if (onChange) {
      onChange(value);
    }
  };

  setValue(value) {
    this.setState({ value });
  }

  componentDidUpdate() {
    this.calculateNewWidth();
  }

  calculateNewWidth = () => {
    if (!this.props.autoWidth) return;
    const left_exists = Object.keys(this.props.classes).some(k => {
      return this.props.classes[k] === 'left_options';
    });

    const select = this.refSelect.current;
    if (select) {
      const text = select.options[select.selectedIndex].text;

      // Cloning this select with selected option only
      const tmpSelect = select.cloneNode();
      tmpSelect.style.width = 'auto';
      tmpSelect.style.position = 'absolute';
      const opt = document.createElement('option');
      opt.value = text;
      opt.innerHTML = text;
      tmpSelect.appendChild(opt);

      select.parentNode.appendChild(tmpSelect);

      // Getting clone width and applying it to original select
      const w = tmpSelect.clientWidth;
      if (left_exists) {
        const newW = w + 40;
        select.style.width = `${newW}px`;
      } else if (w > 0) {
        select.style.width = `${w}px`;
      } else {
        select.style.width = 'auto';
      }

      tmpSelect.remove();
    }
  };

  render() {
    const { value, data, type, big, gray, classes, tabIndex, label, id } = this.props;

    if (isDevelopment()) {
      if (big) console.warn('`big` prop in Select is deprecated'); // eslint-disable-line no-console
      if (gray) console.warn('`gray` prop in Select is deprecated'); // eslint-disable-line no-console
    }

    return (
      <>
        <label htmlFor={id} className="visuallyhidden">
          {label}
        </label>
        <select
          id={id}
          className={classNames(styles.select, {
            [styles.left]: type === SELECT_TYPE_LEFT,
            [styles.big]: big === true || classes.indexOf(Select.CLASS_BIG) >= 0,
            [styles.gray]: gray === true || classes.indexOf(Select.CLASS_GRAY) >= 0,
            [styles.pattient_dashboard]: classes.indexOf(Select.CLASS_PATIENT_DASHBOARD) >= 0,
            [styles.dark]: classes.indexOf(Select.CLASS_DARK) >= 0,
            [styles.left_options]: classes.indexOf(Select.CLASS_LEFT_OPTIONS) >= 0,
            [styles.white_blue]: classes.indexOf(Select.CLASS_WHITE_BLUE) >= 0,
            [styles.blue]: classes.indexOf(Select.CLASS_BLUE) >= 0,
            [styles.no_margin_right]: classes.indexOf(Select.CLASS_NO_MARGIN_RIGHT) >= 0,
            [styles.no_margin_left]: classes.indexOf(Select.CLASS_NO_MARGIN_LEFT) >= 0,
            [styles.blackBox]: classes.indexOf(Select.BLACK_BOX) >= 0,
            [styles.no_border]: classes.indexOf(Select.CLASS_NO_BORDER) >= 0,
            [styles.max_width_600]: classes.indexOf(Select.CLASS_MAX_WIDTH_600) >= 0,
          })}
          value={value}
          onChange={this.onChange}
          ref={this.refSelect}
          tabIndex={tabIndex || 0}
          role="listbox"
        >
          {data.map(e => {
            let optValue;
            let optText;

            if (typeof e === 'string') {
              optValue = e;
              optText = e;
            } else if (Array.isArray(e)) {
              optValue = e[0];
              optText = e[1];
            } else {
              optValue = e.value;
              optText = e.text;
            }

            return (
              <option key={optValue} value={optValue} role="option" aria-label={optValue}>
                {optText}
              </option>
            );
          })}
        </select>
      </>
    );
  }
}

export default Select;
