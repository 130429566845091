import { Button, BUTTON_TYPE_WHITE } from 'components/Buttons/Button';
import { CodeInput } from 'components/Inputs/CodeInput';
import { ButtonRow } from 'components/Login/ButtonRow/ButtonRow';
import { Errors } from 'components/Login/Errors/Errors';
import { Text } from 'components/Login/Text/Text';
import { Timer } from 'components/Timer';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { BUTTON_TYPE_DARK_BLUE } from '../../../../components/Buttons/Button';
import Strings from '../../../../Strings';

class Step2_EnterVerificationCode extends PureComponent {
  state = {
    errors: [],
    isCodeEmpty: true,
  };

  codeInput = React.createRef();

  onExpired = () => {
    window.location.reload();
  };

  onCodeChange = () => {
    const length = this.codeInput.current.getValue().length;
    this.setState({ isCodeEmpty: length !== 6 });
  };

  setError(error) {
    this.setState(state => ({ errors: [...state.errors, error] }));
  }

  clearError() {
    this.setState({ errors: [] });
  }

  // Internal error is linked to outer error from props
  static getDerivedStateFromProps(props) {
    if (props.error) {
      return { errors: [props.error] };
    }

    return null;
  }

  onFormSubmit = event => {
    const { onFormSubmit } = this.props;

    event.preventDefault();
    this.clearError();

    const code = this.codeInput.current.getValue();

    onFormSubmit({ code });
  };

  onResendCode = event => {
    const { onResendCode } = this.props;

    this.clearError();

    this.codeInput.current.clear();
    onResendCode(event);
  };

  render() {
    const { errors, isCodeEmpty } = this.state;
    const { isLoading, text, isEsi } = this.props;

    let txt;

    if (!errors || errors.length === 0) {
      txt = (
        <Text>
          {text}
          {this.props.codeExpiresAt ? (
            <Timer expiresAt={this.props.codeExpiresAt} onExpired={this.onExpired} inlineMode />
          ) : null}
        </Text>
      );
    } else {
      txt = <Errors NoMT errors={errors} />;
    }

    return (
      <form onSubmit={this.onFormSubmit}>
        <div tabIndex={0}>{txt}</div>

        <CodeInput ref={this.codeInput} isEsi={isEsi} onChange={this.onCodeChange} />

        <ButtonRow
          left={
            <Button type="button" buttonType={BUTTON_TYPE_WHITE} disabled={isLoading} onClick={this.onResendCode}>
              {Strings.resend}
            </Button>
          }
          right={
            <Button buttonType={BUTTON_TYPE_DARK_BLUE} type="submit" disabled={isCodeEmpty || isLoading}>
              {Strings.next}
            </Button>
          }
        />
      </form>
    );
  }
}

Step2_EnterVerificationCode.propTypes = {
  codeExpiresAt: PropTypes.any,
  isEsi: PropTypes.any,
  isLoading: PropTypes.any,
  onFormSubmit: PropTypes.func,
  onResendCode: PropTypes.func,
  text: PropTypes.any,
};

export default Step2_EnterVerificationCode;
