import { max as d3max, min as d3min } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleLinear, scaleTime } from 'd3-scale';
import { event, select } from 'd3-selection';
import { area as d3area, line as d3line } from 'd3-shape';
import moment from 'moment-timezone';
import { timeFormat } from 'd3-time-format';
import { DATE_MONTH_DAY_YEAR_WITH_DASH } from '../../../constants';
import './style.scss';
import { getColorsForColorTheme } from '../../../utils/colorTheme';

class CapEvolutionPlot {
  constructor() {
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.updateAxes = this.updateAxes.bind(this);
    this.updateViewBox = this.updateViewBox.bind(this);
  }

  create(node, props) {
    this.props = props;
    const { data, minDate, maxDate } = props;
    const s = select(node);
    s.selectAll('svg > *').remove();
    const svg = s.selectAll('svg').data([true]);
    svg
      .enter()
      .append('svg')
      .attr('class', 'plot')
      .append('g')
      .attr('class', 'main');

    const g = s.select('svg').select('g');
    const viewBox = this.updateViewBox(s.select('svg'));

    const x = scaleTime()
      .range([0, viewBox.width])
      .domain([minDate, maxDate]);

    let min = d3min(data, d => d.y);
    const max = d3max(data, d => d.y) + 5;
    if (min === 0 || min === max) {
      min = max - 5;
    }

    const y = scaleLinear()
      .range([viewBox.height, 0])
      .domain([min, max])
      .nice();

    // g.append('g').attr('class', 'grid vertical');

    // g.append('g').attr('class', 'grid horizontal');

    g.append('g').attr('class', 'axis middle');

    g.append('g').attr('class', 'axis middle-vertical');

    const tooltip = select('body')
      .attr('id', 'charge-graph')
      .append('div')
      .attr('class', 'charge-custom-tooltip')
      .style('position', 'absolute')
      .style('z-index', '1000')
      .style('opacity', 0.1);

    const axes = {
      x: axisBottom(x)
        .tickSize(4)
        .tickPadding(viewBox.height - y(0)),
      y: axisLeft(y)
        .ticks(1)
        .tickSize(1)
        .tickPadding(15),
    };
    if (props.yTickFormat) {
      axes.y.tickFormat(props.yTickFormat);
    }

    this.updateAxes(
      g,
      axes,
      {
        x,
        y,
      },
      viewBox,
    );

    g.selectAll('dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('r', 4)
      .style('fill', 'white')
      .style('stroke-width', '2px')
      .style('stroke', getColorsForColorTheme().mainColor)
      .attr('data-y', d => {
        return d.y;
      })
      .attr('data-unit', d => {
        return d.unit;
      })
      .attr('data-date', d => {
        return moment(d.x).format(DATE_MONTH_DAY_YEAR_WITH_DASH);
      })
      .attr('cx', function(d) {
        return x(d.x);
      })
      .attr('cy', function(d) {
        return y(d.y);
      })
      .on('mouseover', function() {
        tooltip.style('display', 'block');
        return tooltip.style('opacity', 0.9);
      })
      .on('mousemove', function() {
        /* eslint-disable no-useless-concat */
        tooltip.html(
          `${'<div class=' + 'charge' + '>'}${this.getAttribute('data-y')}</div>${this.getAttribute('data-date')}`,
        );
        return tooltip.style('left', `${event.pageX - 50}px`).style('top', `${event.pageY + 20}px`);
      })
      .on('mouseout', function() {
        tooltip.style('opacity', 0);
        tooltip.style('display', 'none');
        select('.charge-custom-tooltip')
          .style('stroke', 'none')
          .style('opacity', 0)
          .style('display', 'none');
      });
  }

  // eslint-disable-next-line class-methods-use-this
  updateAxes(g, axes, scales, viewBox) {
    const { x: axisX, y: axisY } = axes;
    const { x, y } = scales;

    g.select('g.axis.middle')
      .attr('transform', `translate(0, ${scales.y(0) + 15})`)
      .call(axisX);

    g.select('g.axis.middle-vertical').call(axisY);

    g.select('g.grid.vertical')
      .call(
        axisBottom(x)
          .tickSize(-viewBox.height)
          .tickFormat(''),
      )
      .attr('transform', `translate(0,${viewBox.height})`)
      .select('.domain')
      .remove();

    g.select('g.grid.horizontal')
      .call(
        axisLeft(y)
          .tickSize(-viewBox.width)
          .tickFormat(''),
      )
      .select('.domain')
      .remove();

    g.select('g .average').remove();
  }

  updateViewBox(svg) {
    const brc = svg.node().getBoundingClientRect();
    const { margin } = this.props;
    const viewBox = {
      x: margin.left,
      y: margin.top,
      width: brc.width - margin.left - margin.right,
      height: brc.height - margin.top - margin.bottom,
    };

    svg.attr('viewBox', `0 0 ${brc.width + 10} ${brc.height}`);
    svg.select('g.main').attr('transform', `translate(${viewBox.x + 35},${viewBox.y - 35})`);
    return viewBox;
  }

  update(node, props) {
    const { data, minDate, maxDate } = props;
    const s = select(node);
    const svg = s.select('svg');
    const g = svg.select('g.main');
    const viewBox = this.updateViewBox(svg);
    g.selectAll('g circle').remove();
    g.selectAll('g path').remove();
    g.selectAll('.charge-custom-tooltip').remove();

    const x = scaleTime()
      .range([0, viewBox.width - 100])
      .domain([minDate, maxDate]);

    const min = 0;
    const max = d3max(data, d => d.y) + 5;
    const y = scaleLinear()
      .range([viewBox.height, 0])
      .domain([min, max]);

    var a = moment(minDate);
    var b = moment(maxDate);
    const diff = b.diff(a, 'days');

    const axes = {
      x: axisBottom(x)
        .ticks(3)
        .tickPadding(viewBox.height - y(0))
        .tickFormat(diff <= 8 ? timeFormat('%a %d') : timeFormat('%b %d')),
      y: axisLeft(y)
        .ticks(4)
        .tickSize(6),
    };
    if (props.yTickFormat) {
      axes.y.tickFormat(props.yTickFormat);
    }
    axes.x.tickFormat(d => {
      return moment(d).format('ddd DD');
    });

    this.updateAxes(
      g,
      axes,
      {
        x,
        y,
      },
      viewBox,
    );

    const tooltip = select('body')
      .attr('id', 'charge-graph')
      .append('div')
      .attr('class', 'charge-custom-tooltip')
      .style('position', 'absolute')
      .style('z-index', '1000')
      .style('opacity', 0.1);

    const mouseleave = function() {
      tooltip.style('opacity', 0);
      tooltip.style('display', 'none');
      select('.charge-custom-tooltip')
        .style('display', 'none')
        .style('stroke', 'none')
        .style('opacity', 0);
    };

    g.append('text')
      .attr('transform', `translate(${viewBox.width / 2} ,${viewBox.height + 60})`)
      .style('text-anchor', 'middle')
      .text('Date')
      .style('fill', '#4F4F4F');

    g.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', -60)
      .attr('x', 0 - viewBox.height / 2)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .text('Scale')
      .style('fill', '#4F4F4F');

    g.append('path')
      .datum(data)
      // .attr('fill', 'rgba(36, 146, 207, 0.1)')
      .attr('fill', 'none')
      // .attr('fill-opacity', '0.4')
      .attr('stroke', 'none')
      .attr(
        'd',
        d3area()
          .x(function(d) {
            return x(d.x);
          })
          .y0(viewBox.height)
          .y1(function(d) {
            return y(d.y);
          }),
      );

    g.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', getColorsForColorTheme().mainColor)
      .attr('stroke-width', 2)
      .attr(
        'd',
        d3line()
          .x(function(d) {
            return x(d.x);
          })
          .y(function(d) {
            return y(d.y);
          }),
      );

    g.selectAll('dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('r', 4)
      .style('fill', 'white')
      .style('stroke-width', '2px')
      .style('stroke', getColorsForColorTheme().mainColor)
      .attr('data-y', d => {
        return d.y;
      })
      .attr('data-date', d => {
        return moment(d.x).format(DATE_MONTH_DAY_YEAR_WITH_DASH);
      })
      .attr('cx', function(d) {
        return x(d.x);
      })
      .attr('cy', function(d) {
        return y(d.y);
      })
      .on('mouseover', function() {
        tooltip.style('display', 'block');
        return tooltip.style('opacity', 0.9);
      })
      .on('mousemove', function() {
        /* eslint-disable no-useless-concat */
        tooltip.html(
          `${'<div class=' + 'charge' + '>'}${this.getAttribute('data-y')}</div>${this.getAttribute('data-date')}`,
        );
        return tooltip.style('left', `${event.pageX - 50}px`).style('top', `${event.pageY + 20}px`);
      })
      .on('mouseout', mouseleave);
  }
}

export default CapEvolutionPlot;
