import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../containers';
import Strings from '../../Strings';
import { closeModal } from '../../actions/modal';
import LoadingRenderer from '../../components/LoadingRenderer';
import Form from '../../containers/Form';
import './../editStudyModal.scss';
import { actions as kitActions } from '../../pages/Kits/redux/actions';
import { resetForm } from '../../actions/forms';
import {
  ATTACH_PATIENT_TO_KIT_ERROR,
  DELETE_KIT_RESULT,
  GET_KIT_DETAILS_RESULT,
} from '../../pages/Kits/redux/constants';
import Select from '../../components/Select';

const State = Object.freeze({
  ProvideData: Symbol('ProvideData'),
  Review: Symbol('Review'),
  Submited: Symbol('Submited'),
});

const Mode = Object.freeze({
  Create: Symbol('Create'),
  Edit: Symbol('Edit'),
});

class AttachKit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.data.caption,
      review: State.ProvideData,
      kit: {},
      isLoading: false,
      mode: Mode.Create,
      errors: [],
      kits: [],
    };
  }

  componentDidMount = () => {
    this.props.getKits({ havingPatients: false }).then(resp =>
      this.setState({
        kits: resp.response?.kits?.map(e => {
          return {
            name: e.id,
            fullName: e.name,
            value: e.id,
            label: `${e.id}${e.name ? ` ${e.name}` : ''}`,
          };
        }),
      }),
    );
  };

  attachAction = () => {
    const kitId = this.state.kit.id;
    const patientId = parseInt(this.props.data?.patientId, 10);

    this.props.attachKitToPatient(kitId, patientId).then(rep => {
      if (rep.type === ATTACH_PATIENT_TO_KIT_ERROR) {
        const errors = [rep.response?.data?.error?.message];
        this.setState({ errors });
      } else {
        this.props.getPatientKitInfo(patientId);
        this.props.closeModalWithNextAction();
      }
    });
  };

  onSave = () => {
    this.setState({ review: State.Submited });

    if (this.props.data?.kit) {
      this.props.deleteKit(this.props.data.kit.id).then(resp => {
        if (resp.type === DELETE_KIT_RESULT) {
          this.attachAction();
        } else {
          const errors = [resp.response?.data?.error?.message];
          this.setState({ errors });
        }
      });
    } else {
      this.attachAction();
    }
  };

  onReview = () => {
    this.setState({ errors: [] });

    const validationErrors = [];

    // check if kit name is different from original
    if (this.props.data.kit?.id === this.state.kit.id || this.state.kit.id === '') {
      validationErrors.push(Strings.errors.nothingToChange);
    }

    if (validationErrors.length > 0) {
      this.setState({ errors: validationErrors });
    } else {
      this.setState({ review: State.Review });
    }
  };

  onBack = () => {
    this.setState({ review: State.ProvideData });
  };

  handleChange = value => {
    this.getKitName(value);
    this.forceUpdate();
  };

  renderKitName = kit => (kit.name ? <div>{`${kit.name} (${kit.id})`}</div> : <div>{kit.id}</div>);

  // function that extracts string between parentheses from other string
  extractStringBetweenParentheses = str => str.match(/\(([^)]+)\)/)[1];

  // get kit name for a kit
  getKitName = kit => {
    this.props.getKitDetails(kit).then(resp => {
      if (resp.type === GET_KIT_DETAILS_RESULT) {
        this.setState({ kit: resp.response });
      }
    });
  };

  render() {
    const modalHeader = {
      caption: this.state.title,
      name: this.props.name,
    };
    const errorsStr = this.state.errors.map(e => <div style={{ margin: 0 }}>{e}</div>);
    return (
      <Modal
        name="attach-kit"
        onOpen={this.props.onOpen}
        additionalClasses={['form-modal', 'user-invite', 'create-kit-and-add-devices']}
        {...modalHeader}
      >
        <LoadingRenderer>
          {this.state.review === State.ProvideData ? (
            <Form onCancel={this.props.onCancel} id="attach-cap-to-org" className="add-kit">
              <div>
                <label className="header-label">{Strings.kitId}</label>
                <Select
                  placeholder={Strings.search}
                  data={this.state.kits}
                  onChange={e => {
                    this.handleChange(e.value);
                  }}
                  isSearchable
                />

                <label className="header-label">
                  {this.state.kit?.name ? `${Strings.kitName} : ${this.state.kit.name}` : ''}
                </label>
              </div>

              <div key="errors-block" className="errors-block">
                {errorsStr}
              </div>
              <div className="button-bar  reverse" key="button-bar" style={{ marginTop: '70px' }}>
                <button
                  className="brand-blue"
                  key="submit"
                  type="button"
                  onClick={() => this.onReview()}
                  disabled={this.state.isLoading || this.state.kit.id === undefined}
                >
                  {Strings.review}
                </button>
                <button className="brand-white-gray mr-15" key="cancel" type="cancel">
                  {Strings.cancel}
                </button>
              </div>
            </Form>
          ) : (
            <React.Fragment>
              <label className="label">
                {Strings.attach} {Strings.kitId} :
              </label>
              <label className="header-label">{this.renderKitName(this.state.kit)}</label>
              {this.props.data?.kit && (
                <React.Fragment>
                  <label className="label">
                    {Strings.delete} {Strings.kitId} :
                  </label>
                  <label className="header-label">{this.renderKitName(this.props.data.kit)}</label>
                </React.Fragment>
              )}
              <div key="errors-block" className="errors-block">
                {errorsStr}
              </div>
              <div className="button-bar  reverse" key="button-bar" style={{ marginTop: '70px' }}>
                {this.state.review === State.Submited ? null : (
                  <button className="brand-blue" key="save" type="button" onClick={this.onSave}>
                    {this.props.data.kit ? Strings.reassignKit : Strings.attachKit}
                  </button>
                )}
                {this.state.review === State.Submited && errorsStr.length === 0 ? null : (
                  <button className="white gray-text mr-15" key="back" type="button" onClick={this.onBack}>
                    {Strings.back}
                  </button>
                )}
              </div>
            </React.Fragment>
          )}
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('attach-kit')),
  getKits: pageRequest => dispatch(kitActions.getKits(pageRequest)),
  onOpen: () => dispatch(resetForm('attach-kit', ownProps.data)),
  deleteKit: kitId => dispatch(kitActions.deleteKit(kitId)),
  attachKitToPatient: (kitId, patientId) => dispatch(kitActions.attachKitToPatient(kitId, patientId)),
  getPatientKitInfo: patientId => dispatch(kitActions.getPatientKitInfo(patientId)),
  getKitDetails: kitId => dispatch(kitActions.getKitDetails(kitId)),
  closeModalWithNextAction: () => {
    if (ownProps.data.action) {
      dispatch(ownProps.data.action(ownProps.data.request, ownProps.data.patientId)).then(results => {
        if (results && results.type === `${ownProps.data.actionType}/result`) {
          dispatch(closeModal('attach-kit'));
        }
        return results;
      });
    }
  },
});

export default connect(null, mapDispatchToProps)(AttachKit);
