import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class TwoColumnsLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.object).isRequired,
    activeColumns: PropTypes.arrayOf(PropTypes.string),
    hideBasicLogo: PropTypes.bool,
  };

  render() {
    const { children } = this.props;
    const { activeColumns = ['left', 'right'] } = this.props;
    const { hideBasicLogo } = this.props;

    return (
      <div className="two-columns-layout">
        <div className={`left${activeColumns.includes('left') ? ' active' : ''}`}>
          <div className="vertical-center-block">{children[0]}</div>
        </div>
        <div className={`right${activeColumns.includes('right') ? ' active' : ''}`}>
          <div className="vertical-center-block">
            <div className={`right-logo ${hideBasicLogo ? 'hidden' : ''}`} />
            {children[1]}
            <div className="copyright" />
          </div>
        </div>
        <div className="clear" />
      </div>
    );
  }
}

export default TwoColumnsLayout;
