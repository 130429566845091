import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import { Modal } from '../containers';
import Form from '../containers/Form';
import { closeModal } from '../actions/modal';
import { resetForm } from '../actions/forms';
import { patientAction } from '../actions/patient';
import { trialsAction } from '../actions/trials';
import LoadingRenderer from '../components/LoadingRenderer';
import AutoComplete from '../components/AutoComplete';
import Strings from '../Strings';
import { TRIAL_NEW_PATIENT_ERROR, TRIAL_NEW_PATIENT_RESULT } from '../actions/action-types';

class NewTrialPatientModal extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({ trialId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      loading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.getPatientsChoices = this.getPatientsChoices.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.loadPatients();
  }

  onSubmit() {
    const { data: modalData } = this.props;
    const { selectedPatient } = this.state;

    if (!selectedPatient) {
      const errors = [Strings.errors.emptyPatient];
      this.setState({ errors }); // TODO - just make submit button gray if patient is not selected
      return;
    }

    this.setState({ loading: true });

    this.props.onSubmit(modalData.trialId, selectedPatient).then(action => {
      this.setState({ loading: false });
      if (action.type === TRIAL_NEW_PATIENT_ERROR) {
        const { data: responseData } = action.response;
        const errors = Object.keys(responseData).map(key => `${key}: ${responseData[key]}`);
        this.setState({ errors });
      }
      if (action.type === TRIAL_NEW_PATIENT_RESULT) {
        this.getPatients();
      }
      return action;
    });
  }

  onSelectChange(value) {
    this.setState({ selectedPatient: value });
  }

  getPatients() {
    const { dateRange } = this.props;
    const { data: modalData } = this.props;
    this.pageRequest = {
      startDate: dateRange.infimum,
      endDate: dateRange.supremum,
      offset: 0,
      trialId: modalData.trialId,
      search: '',
    };
    this.props.loadManagePatients(this.pageRequest, true);
  }

  getPatientsChoices() {
    const { patients = {} } = this.props;
    return Object.values(patients).map(patient => ({
      text: patient.username,
      value: patient.id,
    }));
  }

  render() {
    const { onOpen, onCancel, patientsLoading, ...props } = this.props;
    const { errors, loading } = this.state;

    const choices = this.getPatientsChoices();
    return (
      <Modal name="new-trial-patient" onOpen={onOpen} additionalClasses={['form-modal', 'user-invite']} {...props}>
        <LoadingRenderer loading={!!patientsLoading || loading} key="loading">
          <Form onSubmit={this.onSubmit} onCancel={onCancel} id="new-trial-patient">
            <div className="modal-logo" key="modal-logo" />

            <AutoComplete
              choices={choices}
              onSelect={item => this.onSelectChange(item.value)}
              key="patient-autocomplete"
              inputProps={{
                name: 'patient-name',
                id: 'patient-name',
                placeholder: Strings.patientUserName,
              }}
            />

            <div key="errors-block" className="errors-block">
              {errors.length ? <div>{errors.join(' ')}</div> : null}
            </div>
            <div className="button-bar" key="button-bar">
              <button className="white gray-text" key="cancel" type="cancel">
                {Strings.cancel}
              </button>
              <button className="brand-blue" key="submit" type="submit">
                {Strings.invite}
              </button>
            </div>
          </Form>
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { ownPatients } = state.entities;
  const { dateFilter } = state.filter;
  const dateRange = dateFilter || {
    infimum: moment()
      .subtract(1, 'month')
      .toDate(),
    supremum: moment().toDate(),
  };
  return {
    dateRange,
    data: state.modals['new-trial-patient'] && state.modals['new-trial-patient'].data,
    patients: ownPatients.patients || {},
    patientsLoading: ownPatients.loading,
    trials: state.entities.trials.objects,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('new-trial-patient')),
  onSubmit: (trialId, patientId) =>
    dispatch(trialsAction.actionNewPatient(trialId, patientId, [closeModal('new-trial-patient')])),
  onOpen: () => dispatch(resetForm('new-trial-patient', ownProps.data)),
  loadPatients: () => dispatch(patientAction.actionOwnPatientsList()),
  loadManagePatients: (patientRequest, isLoading) => dispatch(patientAction.actionList(patientRequest, isLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewTrialPatientModal);
