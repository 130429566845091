import cloneDeep from 'lodash/cloneDeep';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { clearEntitiesAction } from '../../actions';
import { UPDATE_PATIENT_STOCK_RESULT } from '../../actions/action-types';
import { resetForm } from '../../actions/forms';
import { closeModal } from '../../actions/modal';
import { patientAction } from '../../actions/patient';
import LoadingRenderer from '../../components/LoadingRenderer';
import { Modal } from '../../containers';
import Form from '../../containers/Form';
import Strings from '../../Strings';
import { downloadFiles, periodValue } from '../../utils';
import './DownloadMedicationModal.scss';

class DownloadMedicationModal extends PureComponent {
  state = {
    errors: [],
    allMeds: true,
    filteredMeds: false,
  };

  onSubmit = () => {
    const { allMeds } = cloneDeep(this.state);
    const { data } = cloneDeep(this.props.data);
    if (allMeds) {
      delete data.startDate;
      delete data.endDate;
      data.search = '';
      data.filterBy = 'all';
    }
    delete data.offset;
    delete data.medications;
    delete data.selectedPeriodText;
    this.props.downloadPatientData(data).then(this.onDownloadBtnClickSuccessHandler);
  };

  onDownloadBtnClickSuccessHandler = req => {
    const fileName = req.response && req.response.name ? req.response.name : 'doc.zip';
    downloadFiles(req, fileName);
  };

  onAllMeds = event => {
    if (event.target.checked) {
      this.setState({
        allMeds: event.target.checked,
        filteredMeds: false,
      });
      this.forceUpdate();
    }
  };

  onFilteredMeds = event => {
    if (event.target.checked) {
      this.setState({
        filteredMeds: event.target.checked,
        allMeds: false,
      });
      this.forceUpdate();
    }
  };

  render() {
    const { onOpen, onCancel, data, ...props } = this.props;
    const { errors, allMeds, filteredMeds } = this.state;
    return (
      <Modal name="download-medications" onOpen={onOpen} roundedCorner {...props}>
        <LoadingRenderer loading={false}>
          <Form onSubmit={this.onSubmit} onCancel={onCancel} id="download-medications">
            <div className="download-medications content-block" key="schedule-form">
              <div className="row title">
                <span>{Strings.patient_medications.downloadFullMedlist}</span>
                <div className="input-content">
                  <input onChange={this.onAllMeds} type="checkbox" id="confirmation-checkbox" checked={allMeds} />
                  <label></label>
                </div>
              </div>

              <div className="row title">
                <span>{Strings.patient_medications.downloadFilteredMedList}</span>
                <div className="input-content">
                  <input
                    onChange={this.onFilteredMeds}
                    type="checkbox"
                    id="filteredMedication"
                    checked={filteredMeds}
                  />
                  <label></label>
                </div>
              </div>

              <div className="dose-remain-box">
                <div>
                  <div className="big-label">
                    {data.data.search === '' || allMeds ? Strings.patientDashboard.allMeds : data.data.search} -{' '}
                    {data.data.selectedPeriodText}
                  </div>
                  {!allMeds ? (
                    <React.Fragment>
                      {Object.values(data.data.medications).map(med => {
                        return <div className="light-label">{med.medicationName}</div>;
                      })}
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="clear" />
              <div id="errors" className="errors-block">
                {errors.join(' ')}
              </div>

              <div className="buttons" key="button-bar">
                <button className="brand-white-gray" key="cancel" type="cancel">
                  {Strings.cancel}
                </button>
                <button className="brand-blue" key="submit" type="submit">
                  {Strings.download}
                </button>
              </div>
            </div>
          </Form>
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('download-medications')),
  onSubmit: (medicationId, data) =>
    dispatch(patientAction.actionUpdateStock(medicationId, data)).then(response => {
      if (response?.type === UPDATE_PATIENT_STOCK_RESULT) {
        dispatch(closeModal('download-medications'));
        dispatch(clearEntitiesAction(['patientNotifications']));
      }

      return response;
    }),
  onNavigate: path => dispatch(push(path)),
  onOpen: () => dispatch(resetForm('download-medications', ownProps.data)),
  downloadPatientData: data => dispatch(patientAction.downloadMedicineData(data)),
});

export default connect(null, mapDispatchToProps)(DownloadMedicationModal);
