import { API_CALL } from '../../../../../middleware/api';

export const PLOT_DATA = 'plot-data';
export const PLOT_DATA_RESULT = `${PLOT_DATA}/result`;
export const PLOT_DATA_ERROR = `${PLOT_DATA}/error`;

export const actions = {
  getPlotData: pageRequest => {
    const { deviceId, dataType, startDate, endDate, resolution, hubId, kitId, patientId, limit, offset } = pageRequest;
    return {
      [API_CALL]: {
        endpoint: `/devices/data/${dataType}`,
        method: 'get',
        params: {
          device_id: deviceId,
          startDate,
          endDate,
          resolution,
          hub_id: hubId,
          patientId,
          kit_id: kitId,
          limit,
          offset,
        },
      },
      type: PLOT_DATA,
    };
  },
};
