import { PhoneInput } from 'components/Inputs/PhoneInput';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Errors } from '../../../components/Login/Errors/Errors';
import Strings from '../../../Strings';
import './messages.scss';
import { actions } from './redux/actions';
import { SEND_SMS_ERROR, SEND_SMS_RESULT } from './redux/constants';

class MessageNew extends PureComponent {
  refSendToPhone = React.createRef();
  refFromPhone = React.createRef();
  state = {
    newMessage: {
      to: '',
      from: '',
      message: '',
    },
    successText: '',
    errorText: '',
    count: 160,
  };

  componentDidMount() {}

  onTextChange = (event, key) => {
    const newMessage = cloneDeep(this.state.newMessage);
    const { count } = this.state;
    newMessage[key] = event.target.value;
    if (key === 'message') {
      if (event.target.value.length <= 160) {
        this.setState({ count: 160 - event.target.value.length });
      }
      if (count >= 0) {
        this.setState({ newMessage });
      }
    } else {
      this.setState({ newMessage });
    }
  };

  onSend = () => {
    const { newMessage } = this.state;
    this.props.sendSMS(newMessage).then(response => {
      if (response.type === SEND_SMS_RESULT) {
        this.setState({ successText: Strings.SMSSent });
      }
      if (response.type === SEND_SMS_ERROR) {
        this.setState({ errorText: Strings.SMSFailed });
      }
      setTimeout(() => {
        this.setState({ successText: '', errorText: '' });
        window.location.reload();
      }, 5000);
    });
  };

  render() {
    const { to, from, message } = this.state.newMessage;
    const { successText, errorText, count } = this.state;
    const isValid = to && from && message ? true : false;

    const charsLeft =
      count > 1
        ? Strings.formatString(Strings.remainingChars, count)
        : Strings.formatString(Strings.remainingChar, count);
    return (
      <React.Fragment>
        <div class="draft-msg">
          <div className="row pb20">
            <div>{Strings.sendTo}</div>
            <div>
              <PhoneInput
                ref={this.refSendToPhone}
                align="left"
                name="to"
                value={to}
                onChange={e => this.onTextChange(e, 'to')}
              />
            </div>
          </div>

          <div className="row pb20">
            <div>{Strings.from}</div>
            <div>
              <PhoneInput
                ref={this.refFromPhone}
                align="left"
                name="from"
                value={from}
                onChange={e => this.onTextChange(e, 'from')}
              />
            </div>
          </div>

          <div className="row pb20">
            <div>{Strings.message}</div>
            <textarea
              className="message-text-input"
              rows="5"
              cols="60"
              value={message}
              onChange={e => this.onTextChange(e, 'message')}
              placeholder={Strings.message}
              maxlength="160"
            ></textarea>
            <p className="text-limit">{charsLeft}</p>
            {successText && <div className="success-msg">{Strings.SMSSent}</div>}
            {errorText && <Errors NoMT errors={[errorText]} />}
          </div>

          <button className="brand-blue right" onClick={e => this.onSend()} disabled={!isValid}>
            {Strings.sendSMS}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

MessageNew.propTypes = {
  sendSMS: PropTypes.func,
};

const mapStateToProps = state => {
  const { messages } = state.superUser;
  return {
    messages: messages?.messages,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  sendSMS: data => dispatch(actions.sendSMS(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageNew);
