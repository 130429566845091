/* eslint-disable func-names */
import { pie as d3pie, arc as d3arc } from 'd3-shape';
import { select } from 'd3-selection';
import 'd3-transition';

class PiePlot {
  mainLabel = () => {};
  itemLabel = () => {};

  create(node, props) {
    const { width = 245, height = 245, doughnutWidth = 14, margin = 2 } = props;

    this.mainLabel = props.mainLabel;
    this.itemLabel = props.itemLabel;
    const { data } = props;

    const outerRadius = Math.min(width, height) / 2 - margin;
    const innerRadius = outerRadius - doughnutWidth;

    const g = select(node)
      .append('svg')
      .attr('class', 'plot')
      .attr('viewBox', `${-width / 2} ${-height / 2} ${width} ${height}`)
      .append('g');

    const arc = d3arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .cornerRadius(10);
    const pie = d3pie()
      .value(d => d.value)
      .padAngle(0.03);
    const paths = g.selectAll('path').data(pie(data.filter(d => Boolean(d.value))));
    const self = this;
    g.call(this.updateLabels, this.mainLabel());

    paths
      .enter()
      .append('path')
      .attr('class', d => d.data.className)
      .attr('d', arc)
      .on('mouseover', function(d) {
        select(this)
          .transition()
          .duration(200)
          .attr('d', arc.innerRadius(innerRadius - 2).outerRadius(outerRadius + 2));
        g.call(self.updateLabels, props.itemLabel(d));
        // workaround for Safari (correct text position)
        const textElements = g.selectAll('text.doughnutCenterText.bottom');
        textElements.attr('transform', 'translate(0, 20)');
      })
      .on('mouseout', function() {
        select(this)
          .transition()
          .duration(200)
          .attr('d', arc.innerRadius(innerRadius).outerRadius(outerRadius));
        g.call(self.updateLabels, props.mainLabel());
        // workaround for Safari (correct text position)
        const textElements = g.selectAll('text.doughnutCenterText');
        textElements.attr('transform', null);
      });
  }

  update(node, props) {
    const { width = 235, height = 235, doughnutWidth = 14, margin = 1 } = props;

    this.mainLabel = props.mainLabel;
    this.itemLabel = props.itemLabel;
    const { data } = props;

    const outerRadius = Math.min(width, height) / 2 - margin;
    const innerRadius = outerRadius - doughnutWidth;

    const g = select(node).select('svg > g');

    const arc = d3arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .cornerRadius(10);
    const pie = d3pie()
      .sortValues(() => true)
      .value(d => d.value)
      .padAngle(0.03);
    const paths = g.selectAll('path').data(pie(data.filter(d => Boolean(d.value))));
    const self = this;
    g.call(this.updateLabels, this.mainLabel());

    paths
      .enter()
      .append('path')
      .attr('class', d => d.data.className)
      .attr('d', arc)
      .on('mouseover', function(d) {
        select(this)
          .transition()
          .duration(200)
          .attr('d', arc.innerRadius(innerRadius - 2).outerRadius(outerRadius + 2));
        g.call(self.updateLabels, props.itemLabel(d));
        // workaround for Safari (correct text position)
        const textElements = g.selectAll('text.doughnutCenterText.bottom');
        textElements.attr('transform', 'translate(0, 20)');
      })
      .on('mouseout', function() {
        select(this)
          .transition()
          .duration(200)
          .attr('d', arc.innerRadius(innerRadius).outerRadius(outerRadius));
        g.call(self.updateLabels, props.mainLabel());
        // workaround for Safari (correct text position)
        const textElements = g.selectAll('text.doughnutCenterText');
        textElements.attr('transform', null);
      });

    paths
      .attr('class', d => d.data.className)
      .attr('d', arc)
      .on('mouseover', function(d) {
        select(this)
          .transition()
          .duration(200)
          .attr('d', arc.innerRadius(innerRadius - 2).outerRadius(outerRadius + 2));
        g.call(self.updateLabels, props.itemLabel(d));
        // workaround for Safari (correct text position)
        const textElements = g.selectAll('text.doughnutCenterText.bottom');
        textElements.attr('transform', 'translate(0, 20)');
      })
      .on('mouseout', function() {
        select(this)
          .transition()
          .duration(200)
          .attr('d', arc.innerRadius(innerRadius).outerRadius(outerRadius));
        g.call(self.updateLabels, props.mainLabel());
        // workaround for Safari (correct text position)
        const textElements = g.selectAll('text.doughnutCenterText');
        textElements.attr('transform', null);
      });
  }

  // eslint-disable-next-line class-methods-use-this
  destroy() {}

  // eslint-disable-next-line class-methods-use-this
  updateLabels(s, labelData) {
    const labels = s.selectAll('.doughnutCenterText').data(labelData);
    labels
      .enter()
      .append('svg:text')
      .attr('class', d => `doughnutCenterText ${d.className}`)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .transition()
      .duration(200)
      .text(d => d.text);

    labels
      .attr('class', d => `doughnutCenterText ${d.className}`)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .transition()
      .duration(200)
      .text(d => d.text);

    labels.exit().remove();
  }
}

export default PiePlot;
