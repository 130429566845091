import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _, { groupBy } from 'lodash';
import moment from 'moment-timezone';

import { actions } from '../redux/actions';
import Strings from '../../../../Strings';
import { TextInput } from '../../../../components/PageHeader/TextInput';
import Select from '../../../../components/Select';
import AdvancedFilters, { Filter } from '../../../../components/AdvancedFilters/AdvancedFilters';
import { getRelevantFilters } from '../../../../components/AdvancedFilters/helpers';
import { getFiltersDefinition } from '../../../../components/AdvancedFilters/FiltersDefinition';
import PatientLogItem from './PatientLogItem';
import { DATE_FORMAT_YEAR_MONTH_DAY } from '../../../../constants';
import AdvancedFiltersBar from '../../../../components/AdvancedFilters/AdvancedFiltersBar';
import { DateFilters } from '../../../../utils/DateFilters';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

function PatientLog(props) {
  const { patientId } = props;

  const [searchString, setSearchString] = useState('');
  //const [dateRange, setDateRange] = useState({ min: 0, max: moment().unix() });
  const [selectedDateRange, setSelectedDateRange] = useState(DateFilters.AllTime);

  useEffect(() => {
    props.getNotes();
  }, [patientId, props.refreshTimestamp]);

  const onTextInputChange = e => {
    setSearchString(e.target.value);
  };

  const filterDataByAdvancedFilters = data => {
    const appliedFilters = getRelevantFilters(props.filters);
    let ret = data;
    if (!(appliedFilters.some(f => f.value === 'Note') && appliedFilters.some(f => f.value === 'Action'))) {
      if (appliedFilters.some(f => f.value === 'Note')) ret = ret.filter(n => n.action_completed === undefined);
      if (appliedFilters.some(f => f.value === 'Action')) ret = ret.filter(n => n.action_completed !== undefined);
    }
    if (!(appliedFilters.some(f => f.value === 'Completed') && appliedFilters.some(f => f.value === 'Open'))) {
      if (appliedFilters.some(f => f.value === 'Completed')) ret = ret.filter(n => n.action_completed === true);
      if (appliedFilters.some(f => f.value === 'Open')) ret = ret.filter(n => n.action_completed === false);
    }
    return ret;
  };

  const onDateRangeChanged = option => {
    if (option) setSelectedDateRange(option);
  };

  const getFilteredData = () => {
    let ret = [];
    if (!props.notes) return ret;
    ret = props.notes.filter(n => n.content.toLowerCase().includes(searchString.toLowerCase()));
    ret = ret.filter(
      n =>
        n.modification_time >= moment(selectedDateRange.dates.startDate()).unix() &&
        n.modification_time <= moment(selectedDateRange.dates.endDate()).unix(),
    );
    ret = filterDataByAdvancedFilters(ret);

    return ret;
  };

  const data = getFilteredData();
  const days = groupBy(data, x => moment(x.modification_time * 1000).format(DATE_FORMAT_YEAR_MONTH_DAY));

  return (
    <div className="patient-log-widget">
      <div className="section-header">
        <div>{Strings.patientLog}</div>
        <div className="patient-log-filters">
          <TextInput class="search" placeholder={Strings.searchLog} onChange={onTextInputChange} />
          <Select
            data={[DateFilters.AllTime, DateFilters.ThisMonth, DateFilters.Last30Days, DateFilters.LastMonth]}
            onChange={onDateRangeChanged}
            value={selectedDateRange.value}
            menuPositionFixed={false}
          />
          <AdvancedFilters>
            <Filter definition={getFiltersDefinition().LogItemType} />
            <Filter definition={getFiltersDefinition().ActionStatus} />
          </AdvancedFilters>
        </div>
      </div>
      <AdvancedFiltersBar />
      {days && (
        <OverlayScrollbarsComponent
          defer
          className="patient-log"
          options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
        >
          {Object.keys(days).length > 0 ? (
            Object.keys(days)
              .sort()
              .reverse()
              .map((day, i) => (
                <div className="patient-log" key={`patient-log-${day}`}>
                  <div className="horizontal-rule" />
                  <div className="patient-log-date">{moment(day).format('dddd, MMMM Do')}</div>
                  {days[day]
                    .sort((a, b) => a.modification_time - b.modification_time)
                    .reverse()
                    .map(d => (
                      <PatientLogItem logItem={d} patientId={patientId} key={`patient-note-${d.id}`} />
                    ))}
                </div>
              ))
          ) : (
            <div className="empty-row-container">
              <div className="empty-row-inner">{Strings.noDataAvailable}</div>
            </div>
          )}
        </OverlayScrollbarsComponent>
      )}
    </div>
  );
}

PatientLog.propTypes = {
  notes: PropTypes.array,
  patientId: PropTypes.string,
  getNotes: PropTypes.func,
  filters: PropTypes.array,
  refreshTimestamp: PropTypes.any,
};

const mapStateToProps = state => {
  const { patients } = state.superUser;
  return {
    notes: patients?.notes,
    filters: state.entities.advancedFilters.filters.items,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  getNotes: () => dispatch(actions.getNotes(props.patientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientLog);
