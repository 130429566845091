import { API_CALL } from '../middleware/api';
import {
  ACCEPT_EULA,
  GET_USER_SETTINGS,
  MOBILE_PHONE_VERIFY,
  POST_USER_SETTINGS,
  USER_ACTIVATE,
  USER_NAME_CHECK,
  VERIFY_SEND,
  RESET_PASSWORD,
} from './action-types';

export const userSettingsAction = {
  actionGet: () => ({
    [API_CALL]: { endpoint: '/api/accounts/users/me/' },
    type: GET_USER_SETTINGS,
  }),
  actionPost: data => ({
    [API_CALL]: {
      endpoint: '/rails/api/users/me/',
      method: 'post',
      data,
    },
    type: POST_USER_SETTINGS,
  }),
};

export const userActivateAction = {
  actionPost: data => ({
    [API_CALL]: {
      endpoint: '/rails/api/users/activate/',
      method: 'post',
      data,
    },
    type: USER_ACTIVATE,
  }),
};

export const userNameCheckAction = {
  actionPost: data => ({
    [API_CALL]: {
      endpoint: '/rails/api/users/name_check/',
      method: 'post',
      data,
    },
    type: USER_NAME_CHECK,
  }),
};

export const userMobilePhoneAction = {
  actionVerify: data => ({
    [API_CALL]: {
      endpoint: '/rails/api/users/field_verify/',
      method: 'post',
      data,
    },
    type: MOBILE_PHONE_VERIFY,
  }),
};

export const verificationAction = {
  verify: ({ user, token, method }) => ({
    [API_CALL]: {
      endpoint: `/accounts/verify/${method}/`,
      method: 'post',
      data: {
        user,
        token,
      },
    },
    type: VERIFY_SEND,
  }),
};

export const eulaActions = {
  acceptEulaAndTC: data => ({
    [API_CALL]: {
      endpoint: '/rails/api/users/accept_eula',
      method: 'post',
      data,
    },
    type: ACCEPT_EULA,
  }),
};

export const resetPassword = (token, password) => ({
  [API_CALL]: {
    endpoint: '/rails/api/users/reset_password/',
    method: 'post',
    data: {
      token,
      password,
    },
  },
  type: RESET_PASSWORD,
});
