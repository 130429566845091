class googleUtils {
  autocompleteService;
  placesService;

  getAutocompleteService = () => {
    if (!this.autocompleteService) {
      this.autocompleteService = new google.maps.places.AutocompleteService();
    }
    return this.autocompleteService;
  };

  getPlacesService = () => {
    if (!this.placesService) {
      this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
    }
    return this.placesService;
  };

  getPlacePredictions = async (value, setValue, setSuggestionError) => {
    if (value.length < 2) {
      return;
    }

    try {
      await this.getAutocompleteService().getPlacePredictions(
        {
          input: value,
          inputtype: 'textquery',
          language: 'en',
          componentRestrictions: {
            country: 'us',
          },
        },
        suggestsGoogle => {
          if (!suggestsGoogle) {
            setSuggestionError('The address was not found. It may be located outside the USA');
            return;
          }
          const suggestions = suggestsGoogle
            ? suggestsGoogle.map(x => ({
                name: x.description,
                place_id: x.place_id,
              }))
            : [];
          setValue(suggestions);
        },
      );
      setSuggestionError('');
    } catch (error) {
      setValue([]);
      setSuggestionError('Error request to Google Maps');
    }
  };

  getPlaceDetails = (placeId, onAutosuggestSelected) => {
    this.getPlacesService().getDetails({ placeId }, (place, status) => {
      onAutosuggestSelected({ place, status, addressDetails: this.getAddressDetails(place) });
    });
  };

  getAddressDetails = ({ address_components }) => {
    const result = {
      building: '',
      street: '',
      zip: '',
      city: '',
      state: '',
    };
    if (address_components) {
      address_components.forEach(e => {
        e.types.forEach(t => {
          switch (t) {
            case 'street_number':
              result.building = e.long_name;
              break;
            case 'route':
              result.street = e.long_name;
              break;
            case 'postal_code':
              result.zip = e.long_name;
              break;
            case 'administrative_area_level_1':
              result.state = e.long_name;
              break;
            case 'locality':
              result.city = e.long_name;
              break;
          }
        });
      });
    }
    return result;
  };
}

export const GoogleUtils = new googleUtils();
