import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Button, Menu, MenuItem } from 'react-aria-menubutton';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import Strings from '../../../../Strings';
import TableWidget from '../component/TableWidget';
import { actions } from '../redux/actions';
import { patientAction } from '../../../../actions/patient';
import { openModalAction } from '../../../../actions/modal';
import {
  getScheduleFromMed,
  getWindowOffsetsWithPrefix,
  remindersTypes,
} from '../../../../modals/EditRpmSchedule/EditRpmScheduleModal';
import { DATE_MONTH_DAY_YEAR, GetTimezoneTextMapping, TIME_FORMAT_12_LOWERCASE } from '../../../../constants';
import { getMedsType } from '../../../../utils';
import { UPDATE_PATIENT_SCHEDULE } from '../../../../actions/action-types';
import { EditRpmScheduleModalPages } from '../../../../modals/_ModalsMetadata/ModalsMetadata';
import { getProgramDisplayName } from '../../../../utils/cmsPrograms';

function MedicationWidget(props) {
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);
  const { devices, isLoading, isCollapsible, schedule, patient } = props;
  const timezones = GetTimezoneTextMapping();

  const onDelete = () => {
    const data = {
      title: (
        <span>
          {Strings.deleteMedicationWarning} <b>{schedule.medicationName}</b>?
        </span>
      ),

      onConfirmAction: actions.deleteMedication(patient.id, schedule.id, [actions.getMedications(patient.id)]),
      onCancelAction: null,
    };
    props.openConfirmModal(data);
  };

  const onCapClicked = value => {
    if (value === 'none') return;
    const scheduleToEdit = getScheduleFromMed(props.schedule);
    const data = {
      caption: Strings.confirmAssignment,
      title: <span>{Strings.formatString(Strings.assignCapToScheduleCaption, value, props.schedule.id)}</span>,
      hideCaution: true,
      onConfirmAction: patientAction.actionUpdateSchedule(
        {
          ...scheduleToEdit,
          cap_id: value,
        },
        props.patient.id,
      ),
      onCancelAction: null,
      confirmPostAction: actions.getMedications(props.patient.id),
    };
    props.openConfirmModal(data);
  };

  const onCapDelete = () => {
    const scheduleToEdit = getScheduleFromMed(props.schedule);
    const data = {
      caption: Strings.confirmDelete,
      title: (
        <span>
          {Strings.formatString(Strings.unassignCapToScheduleCaption, props.schedule?.capId, props.schedule.id)}
        </span>
      ),
      hideCaution: true,
      onConfirmAction: patientAction.actionUpdateSchedule(
        {
          ...scheduleToEdit,
          cap_id: null,
        },
        props.patient.id,
      ),
      onCancelAction: null,
      confirmPostAction: actions.getMedications(props.patient.id),
    };
    props.openConfirmModal(data);
  };

  const addCapButton = (
    <Wrapper
      className="addMenu"
      onSelection={onCapClicked}
      onMenuToggle={({ isOpen }) => {
        setIsActionMenuOpen(isOpen);
      }}
    >
      <div className="button-container">
        <Button className={`addMenu-button ${isActionMenuOpen ? 'expanded' : ''}`}> </Button>
      </div>
      <Menu className="addMenu-menu">
        {devices &&
          devices.map(device => (
            <MenuItem className="addMenu-menuItem" value={device} id={device} key={device}>
              {device}
            </MenuItem>
          ))}
        {(!devices || devices.length === 0) && (
          <MenuItem className="addMenu-menuItem" value="none">
            {Strings.noCapAvailable}
          </MenuItem>
        )}
      </Menu>
    </Wrapper>
  );

  const cap = (
    <div className="cap-and-delete">
      <div>{props.schedule?.capId}</div>
      {!props.hideActions && (
        <div className="buttons-icon-container">
          <div className="icon delete" onClick={onCapDelete} />
        </div>
      )}
    </div>
  );

  const getLocalDate = (dt, timezone) => {
    return dt
      ? moment
          .unix(dt)
          .tz(timezone)
          .format(DATE_MONTH_DAY_YEAR)
      : '-';
  };

  const times = (medication, withTimezone) =>
    medication.format.offset
      ? `${medication.format.offset
          .map(time =>
            moment
              .unix(time)
              .utc()
              .format(TIME_FORMAT_12_LOWERCASE),
          )
          .join(',')}${
          withTimezone && medication.scheduleTimezone ? ` (${timezones[medication.scheduleTimezone]})` : ''
        }`
      : '-';

  const reminders = medication =>
    medication.sms_reminder
      ? Object.entries(medication.sms_reminder)
          .map(
            r =>
              `${Strings.patient_medications.remindersTypes[r[0]]}${
                r[0] === remindersTypes.missedDoses
                  ? ` (${r[1].offset})`
                  : r[1]?.offset
                  ? ` (${r[0] === remindersTypes.early ? '-' : '+'}${r[1].offset / 60} ${Strings.unitsEnum.minutes})`
                  : ''
              }`,
          )
          .join(', ')
      : '-';

  const takeWindow = medication =>
    medication.takeWindow && medication.takeWindow.length === 2
      ? `${getWindowOffsetsWithPrefix('-').find(w => w.value === medication.takeWindow[0])?.label}, ${
          getWindowOffsetsWithPrefix('+').find(w => w.value === medication.takeWindow[1])?.label
        }`
      : Strings.allDay;

  const enrollmentData = props.patientEnrollments?.find(e => e.enrollmentId === props.schedule.enrollmentId);

  const data = [
    [
      Strings.patient_medications.assignedCap,
      schedule?.capId ? cap : props.devices && !props.hideActions ? addCapButton : '-',
    ],
    [Strings.patient_medications.schedule, getMedsType(schedule.format.type)],
    [Strings.patient_medications.times, times(schedule, !props.simplifiedVersion)],
    [Strings.patient_medications.startDate, getLocalDate(schedule.format.start_dt, schedule.scheduleTimezone)],
    [Strings.patient_medications.endDate, getLocalDate(schedule.format.end_dt, schedule.scheduleTimezone)],
  ];

  const dataExt = [
    [Strings.adherence, schedule.analytics?.adherence ? `${schedule.analytics?.adherence}%` : '-'],
    [Strings.reminders, reminders(schedule)],
    [Strings.administrationWindow, takeWindow(schedule)],
    [Strings.program, enrollmentData ? getProgramDisplayName(enrollmentData.name, enrollmentData.subprogram) : '-'],
  ];

  const buttons = [
    { icon: 'delete', onClick: onDelete },
    { icon: 'edit', onClick: () => props.onEditMedicine(props.patient, props.schedule) },
  ];

  const tableData = props.simplifiedVersion ? data : [...data, ...dataExt];
  return (
    <TableWidget
      title={schedule.medicationName}
      buttons={props.hideActions ? undefined : buttons}
      data={tableData}
      isLoading={isLoading}
      firstColumnClassName={`medication-details-col ${props.simplifiedVersion ? 'simplified' : ''}`}
      isCollapsible={isCollapsible}
    />
  );
}

MedicationWidget.propTypes = {
  isLoading: PropTypes.any,
  patient: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  medicationDetails: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    schedule: PropTypes.string,
    time: PropTypes.any,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    offset: PropTypes.array,
    adherence: PropTypes.number,
    scheduleTimezone: PropTypes.string,
    capId: PropTypes.string,
  }),
  schedule: PropTypes.any,
  patientEnrollments: PropTypes.arrayOf({
    enrollmentId: PropTypes.string,
    name: PropTypes.string,
    subprogram: PropTypes.string,
  }),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  devices: PropTypes.array,
  openConfirmModal: PropTypes.func,
  onEditMedicine: PropTypes.func,
  isCollapsible: PropTypes.bool,
  hideActions: PropTypes.bool,
  simplifiedVersion: PropTypes.bool,
};

MedicationWidget.defaultProps = { isCollapsible: false, hideActions: false, simplifiedVersion: false };

const mapDispatchToProps = dispatch => ({
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  onEditMedicine: (patient, schedule) =>
    dispatch(
      openModalAction('edit-rpm-schedule', {
        patient,
        schedule,
        action: patientAction.actionUpdateSchedule,
        actionType: UPDATE_PATIENT_SCHEDULE,
        onSuccess: () => dispatch(actions.getMedications(patient.id)),
        pages: Object.values(EditRpmScheduleModalPages).filter(p => p.id !== EditRpmScheduleModalPages.medication.id),
      }),
    ),
});

export default connect(null, mapDispatchToProps)(MedicationWidget);
