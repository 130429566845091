import PropTypes from 'prop-types';
import React from 'react';
import {
  calcNewDateRangeAfterMoveLeft,
  calcNewDateRangeAfterMoveRight,
  calcNewDateRangeAfterTypeChange,
} from './ZoomHelpers';
import { DATE_FORMAT_MONTH_YEAR, DATE_FORMAT_MONTH_NAME } from '../../../constants';
import { mobileDataRangeTypes } from './Metrics';
import Strings from '../../../Strings';
// import moment from 'moment-timezone';

export const MobileRangePicker = React.forwardRef((props, ref) => {
  const onChangeType = type => {
    const newRange = calcNewDateRangeAfterTypeChange(props.dateRange, type, props.maxRange);
    // console.log('NewDateRange', newRange);
    props.onChange(newRange);
  };

  const onLeft = () => {
    const newRange = calcNewDateRangeAfterMoveLeft(props.dateRange, props.maxRange);
    props.onChange(newRange);
  };

  const onRight = () => {
    const newRange = calcNewDateRangeAfterMoveRight(props.dateRange, props.maxRange);
    props.onChange(newRange);
  };

  let sRange = '';
  switch (props.dateRange.type) {
    case mobileDataRangeTypes.day:
      sRange = props.dateRange.start.format(DATE_FORMAT_MONTH_NAME);
      break;
    case mobileDataRangeTypes.week:
      sRange =
        props.dateRange.start.format(DATE_FORMAT_MONTH_NAME) +
        ' - ' +
        props.dateRange.end.format(DATE_FORMAT_MONTH_NAME);
      break;
    case mobileDataRangeTypes.twoWeeks:
      sRange =
        props.dateRange.start.format(DATE_FORMAT_MONTH_NAME) +
        ' - ' +
        props.dateRange.end.format(DATE_FORMAT_MONTH_NAME);
      break;
    case mobileDataRangeTypes.month:
      sRange =
        props.dateRange.start.date() === 1
          ? props.dateRange.start.format(DATE_FORMAT_MONTH_YEAR)
          : props.dateRange.start.format(DATE_FORMAT_MONTH_NAME) +
            ' - ' +
            props.dateRange.end.format(DATE_FORMAT_MONTH_NAME);
      break;
    case mobileDataRangeTypes.sixMonths:
      sRange =
        props.dateRange.start.date() === 1 && props.dateRange.start.month() % 6 === 0
          ? `H${props.dateRange.start.month() === 0 ? 1 : 2} ${props.dateRange.start.year()}`
          : props.dateRange.start.format(DATE_FORMAT_MONTH_NAME) +
            ' - ' +
            props.dateRange.end.format(DATE_FORMAT_MONTH_NAME);
      break;
    case mobileDataRangeTypes.year:
      sRange =
        props.dateRange.start.date() === 1 && props.dateRange.start.month() === 0
          ? `${props.dateRange.start.year()}`
          : props.dateRange.start.format(DATE_FORMAT_MONTH_NAME) +
            ' - ' +
            props.dateRange.end.format(DATE_FORMAT_MONTH_NAME);
      break;
    default:
      break;
  }

  // console.log(props.allowedRangeTypes, props.dateRange.type);
  return (
    <div className="mobile-range-picker" ref={ref}>
      <div className="range-types-picker">
        {props.allowedRangeTypes.map((type, i) => (
          <div
            key={type}
            className={`range-type ${type === props.dateRange.type ? 'active' : ''} ${
              props.allowedRangeTypes[i + 1] === props.dateRange.type ? 'next-active' : ''
            }`}
            onClick={ev => onChangeType(type)}
          >
            {Strings.dataRangeAberraviations[type]}
          </div>
        ))}
      </div>
      {props.metricsToggles && props.metricsToggles}
      <div className="dates-and-buttons">
        <button className="prev" onClick={ev => onLeft()} />
        <div className="dates-range">
          <span>{sRange}</span>
        </div>
        <button className="next" onClick={ev => onRight()} />
      </div>
    </div>
  );
});

MobileRangePicker.propTypes = {
  dateRange: PropTypes.shape({
    type: PropTypes.string,
    start: PropTypes.any,
    end: PropTypes.any,
  }),
  maxRange: PropTypes.shape({
    start: PropTypes.any,
    end: PropTypes.any,
  }),
  allowedRangeTypes: PropTypes.array,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
