import React from 'react';
import PropTypes from 'prop-types';

import Strings from '../../../../Strings';
import { makeValid } from '../../../../utils';
import TableWidget from '../component/TableWidget';
import { PERMISSIONS, hasPermission } from '../../../../utils/userPermissions';

function CenterDetails(props) {
  const { centerDetails, facility, cohort, isLoading } = props;
  const data = [];
  if (centerDetails && centerDetails.length > 0) {
    centerDetails.forEach(cd => {
      data.push([Strings.facility, makeValid(facility)]);
      data.push([Strings.cohort, makeValid(cohort)]);
      data.push([Strings.capPatient.orderingProvider, makeValid(cd.orderingProvider)]);
      data.push([Strings.capPatient.clinicContact, makeValid(cd.clinicContact)]);
    });
  }
  const canEdit = hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_INFO_EDIT);

  return (
    <TableWidget
      title={Strings.capPatient.centerDetails}
      buttons={props.onEdit && canEdit ? [{ icon: 'edit', onClick: props.onEdit }] : undefined}
      data={data}
      isLoading={isLoading}
      firstColumnClassName="center-details-col"
    />
  );
}

CenterDetails.propTypes = {
  isLoading: PropTypes.any,
  centerDetails: PropTypes.arrayOf(
    PropTypes.shape({
      centerName: PropTypes.string,
      provider: PropTypes.string,
      clinicContact: PropTypes.string,
    }),
  ),
  patientId: PropTypes.any,
  onEdit: PropTypes.func,
  facility: PropTypes.string,
  cohort: PropTypes.string,
};
CenterDetails.widgetId = 'CenterDetailsWidget';

export default CenterDetails;
