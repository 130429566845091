import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { NoDataRow, SpinnerRow } from '../../../../containers/Table/components';

function TableWidget(props) {
  const { data, isLoading, title, isCollapsible } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [stripesHeight, setStripesHeight] = useState(0);

  const containerRef = React.useRef(null);
  const tableRef = React.useRef(null);

  useEffect(() => {
    const onResize = () => {
      setStripesHeight(containerRef.current.clientHeight - tableRef.current.clientHeight);
    };
    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  let tbody;

  if (isLoading && data.length === 0) {
    tbody = <SpinnerRow colspan={2} />;
  } else if (data.length === 0 && !isLoading) {
    tbody = <NoDataRow colspan={2} />;
  } else {
    tbody = (
      <tbody className="table-body fade-in">
        {data.map((d, k) => {
          return (
            <tr className="table-row" key={`row-${title}-${k}`}>
              {d.map((t, i) => (
                <td key={`cell-${title}-${k}-${i}`}>{t}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    );
  }

  return (
    <OverlayScrollbarsComponent
      defer
      className={`widget-scrollable simplebar-100percent-width freezeFirstColumn ${collapsed ? 'collapsed' : ''}`}
      options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
    >
      <div className="table-and-stripes" ref={containerRef}>
        <table className="table patient-widget" ref={tableRef}>
          <colgroup>
            <col className={props.firstColumnClassName} />
            <col />
          </colgroup>
          <thead className={`table-header ${collapsed ? 'no-shadow' : ''}`}>
            <tr>
              <th colSpan={2}>
                <div className="row-with-buttons">
                  <a onClick={props.onClick}>{title}</a>
                  <div className="row-buttons">
                    {props.buttons &&
                      props.buttons.map(b => (
                        <div key={`button_${b.icon}`} className="buttons-icon-container">
                          <div className={`icon ${b.icon}`} onClick={e => b.onClick(e)} />
                        </div>
                      ))}
                    {isCollapsible && (
                      <div
                        className={`collapse-icon ${collapsed ? 'collapsed' : ''}`}
                        onClick={() => setCollapsed(!collapsed)}
                      />
                    )}
                  </div>
                </div>
              </th>
            </tr>
          </thead>

          {!collapsed && tbody}
        </table>
        {!collapsed && (
          <div
            className="stripes"
            style={
              containerRef.current && tableRef.current
                ? { height: containerRef.current.clientHeight - tableRef.current.clientHeight }
                : {}
            }
          >
            <div className={`border ${props.firstColumnClassName}`}></div>
          </div>
        )}
      </div>
    </OverlayScrollbarsComponent>
  );
}

TableWidget.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  firstColumnClassName: PropTypes.string,
  isCollapsible: PropTypes.bool,
  resizeTimestamp: PropTypes.any,
};

TableWidget.defaultProps = { isCollapsible: false };

export default TableWidget;
