import PropTypes from 'prop-types';

import _ from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import QRCode from 'react-qr-code';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { PageHeader } from '../../../components/PageHeader';
import { UsersCard } from '../../../components/UsersCard/UsersInfoCard';
import { DATE_MONTH_DAY_YEAR, PAGE_LIMIT, TIME_FORMAT_12_UPPERCASE } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { getFullLocation, getLocation } from '../../../utils';
import { isShipper } from '../../../utils/userRoles';
import { actions } from './redux/actions';
import AdvancedFiltersBar from '../../../components/AdvancedFilters/AdvancedFiltersBar';
import { getActivityTypeFilter } from '../../ShipperCapsDetails';
import AdvancedFilters, { Filter } from '../../../components/AdvancedFilters/AdvancedFilters';
import { getRelevantFilters } from '../../../components/AdvancedFilters/helpers';

const mapStateToProps = state => {
  const { deviceDetails } = state.superUser;

  return {
    isLoading: deviceDetails?.isLoading,
    details: deviceDetails?.data,
    pagination: deviceDetails?.data?.pagination,
    filters: state.entities.advancedFilters.filters.items,
  };
};

const mapDispatchToProps = dispatch => ({
  getFillterData: type => dispatch(actions.getFillterData(type)),
  getCapsDetails: (id, pageRequest) => dispatch(actions.getCapsDetails(id, pageRequest)),
  clearCapsDetails: () => dispatch(actions.clearCapsDetails()),
  navigate: (path, params) =>
    dispatch(
      push({
        pathname: path,
        state: { params },
      }),
    ),
});

const getFilterItems = filters => {
  const ret = [];
  /* eslint-disable */
  for (const i in filters) {
    ret.push({
      id: filters[i].type,
      value: filters[i].value,
    });
  }
  /* eslint-enable */
  return ret;
};

class DeviceDetails extends PureComponent {
  id = this.props.match.params.id;
  type = 'all';
  offset = 0;
  filterItems = [];

  componentDidMount() {
    const type = this.props.match.params.type;
    this.props.getFillterData(type).then(r => {
      this.filterItems = getFilterItems(r.response.data);
      this.load();
    });
  }

  componentWillUnmount() {
    this.props.clearCapsDetails();
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    if (filters !== prevProps.filters) {
      this.onFiltersChange(filters);
    }
  }

  load() {
    const id = this.id;
    const pageRequest = {
      offset: this.offset,
      search: '',
      activityType: this.type,
      limit: PAGE_LIMIT,
    };

    this.props.getCapsDetails(id, pageRequest);
  }

  onBackClick = () => {
    // const type = this.props.match.params.type;
    // const url = this.props.match.url;
    // const updateURL = url.replace('/' + this.id, '');
    // this.props.navigate(updateURL);

    const type = this.props.match.params.type;
    if (type === 'lte' || type === 'ble') {
      const id = this.props.match.params.id;
      const url = this.props.match.url.replace(`/${id}`, '');
      this.props.navigate(`${url}`, { offset: this.props.history.location.state.params?.offset });
    } else {
      const url = this.props.match.url;
      this.props.navigate(`${url}/devices`);
    }
  };

  onFiltersChange = _.debounce(() => {
    this.offset = 0;
    const type = getRelevantFilters(this.props.filters.filter(f => f.group === 'activityType'));
    if (type?.length > 0) {
      this.type = type.map(f => f.value).join(',');
    } else {
      this.type = 'all';
    }
    this.load();
  }, 1000);

  onPrevClick = () => {
    const { pagination } = this.props;
    this.offset = pagination.offset - PAGE_LIMIT;
    this.load();
  };

  onNextClick = () => {
    const { pagination } = this.props;
    this.offset = pagination.offset + PAGE_LIMIT;
    this.load();
  };

  getHWValue(e) {
    return isShipper() ? `${e.activity_value ?? ''} (${e.hw_code})` : e.activity_value;
  }

  render() {
    const { details, isLoading, pagination } = this.props;
    const { type } = this.props.match.params;
    const title = type === 'hub' ? Strings.hubActivityDetails : Strings.capActivityDetails;
    return (
      <React.Fragment>
        <PageHeader title={title} onBackClick={() => history.back()} />

        <div
          style={{
            display: 'flex',
            marginBottom: 20,
          }}
        >
          <div style={{ marginRight: 10 }}>
            <UsersCard
              data={[
                {
                  title: type === 'hub' ? Strings.hubId : Strings.capId,
                  value: details?.cap_id?.toString(),
                },
                {
                  title: Strings.capName,
                  value: details?.cap_name?.toString(),
                },
                {
                  title: Strings.imei,
                  value: details?.imei?.toString(),
                },
                {
                  noBottomBorder: true,
                  value: details?.qr_code?.toString() ? (
                    <div style={{ textAlign: 'center' }}>
                      <QRCode value={details.qr_code.toString()} size={96} />
                    </div>
                  ) : null,
                },
              ]}
              title=""
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {(details?.battery ||
              details?.battery === 0 ||
              details?.temperature ||
              details?.location ||
              details?.signal_strength) && (
              <UsersCard
                data={[
                  {
                    title: Strings.lastKnownBattery,
                    value: details?.battery?.toString(),
                  },
                  {
                    title: Strings.lastKnownTemperature,
                    value: details?.temperature?.toString(),
                  },
                  {
                    title: Strings.lastKnownLocation,
                    value: getLocation(details?.location) === '-' ? undefined : getFullLocation(details?.location),
                    rowValue: getFullLocation(details?.location),
                    specialKey: 'location',
                  },
                  {
                    title: Strings.lastKnownSignalStrength,
                    value: details?.signal_strength?.toString(),
                  },
                ]}
                title=""
              />
            )}
          </div>
        </div>
        <PageHeader
          left={
            this.filterItems?.length > 0 && (
              <AdvancedFilters expandToRight>
                <Filter definition={getActivityTypeFilter(this.filterItems).activityType} />
              </AdvancedFilters>
            )
          }
        />
        {this.filterItems?.length > 0 && <AdvancedFiltersBar customFilters={getActivityTypeFilter(this.filterItems)} />}

        <Table
          isLoading={isLoading}
          name="capsDetails"
          data={details?.activity || []}
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          pagination={pagination}
        >
          <Column
            key="timestamp"
            title={Strings.date}
            value={e => (e.timestamp ? moment.unix(e.timestamp).format(DATE_MONTH_DAY_YEAR) : '-')}
          />
          <Column
            key="timestampTime"
            title={Strings.time}
            value={e => (e.timestamp ? moment.unix(e.timestamp).format(TIME_FORMAT_12_UPPERCASE) : '-')}
          />
          <Column
            key={Strings.eventType}
            title={Strings.eventType}
            value={e => (e.activity_type ? e.activity_type : '-')}
          />
          <Column
            key={Strings.value}
            title={isShipper() ? Strings.valueHwCode : Strings.value}
            value={e => {
              return this.getHWValue(e);
            }}
          />
        </Table>
      </React.Fragment>
    );
  }
}

DeviceDetails.propTypes = {
  clearCapsDetails: PropTypes.func,
  details: PropTypes.shape({
    activity: PropTypes.array,
    battery: PropTypes.shape({ toString: PropTypes.func }),
    cap_id: PropTypes.shape({ toString: PropTypes.func }),
    cap_name: PropTypes.shape({ toString: PropTypes.func }),
    imei: PropTypes.shape({ toString: PropTypes.func }),
    location: PropTypes.any,
    qr_code: PropTypes.shape({ toString: PropTypes.func }),
    signal_strength: PropTypes.shape({ toString: PropTypes.func }),
    temperature: PropTypes.shape({ toString: PropTypes.func }),
  }),
  getCapsDetails: PropTypes.func,
  getFillterData: PropTypes.func,
  history: PropTypes.shape({
    location: PropTypes.shape({ state: PropTypes.shape({ params: PropTypes.shape({ offset: PropTypes.any }) }) }),
  }),
  isLoading: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
      type: PropTypes.string,
    }),
    url: PropTypes.shape({ replace: PropTypes.func }),
  }),
  navigate: PropTypes.func,
  pagination: PropTypes.shape({ offset: PropTypes.any }),
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetails);
