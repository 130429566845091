import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Strings from '../../../Strings';
import TabView, { Tab } from '../../../components/TabView';
import HubDeviceEvents from './HubDeviceEvents';
import DeviceStatusHistory from './DeviceStatusHistory';
import { actions as capsActions } from '../../ShipperCapsDetails/redux/actions';
import { isShipper, isSuperUser } from '../../../utils/userRoles';
import { openModalAction } from '../../../actions/modal';
import { ADD_NICK_NAME } from '../../../actions/action-types';

export const DEVICE_READINGS_TAB = Strings.deviceReadings;
export const DEVICE_STATUS_HISTORY_TAB = Strings.history;

function DeviceDetails(props) {
  const deviceType = props.match.params.deviceType;
  const selectedDevice = props.match.params.deviceId;
  const manufacturer = props.match.params.manufacturer;
  const model = props.match.params.model;
  const hubId = props.match.params.id;
  const prefix = manufacturer?.concat('.', model);
  let deviceId = selectedDevice.replace(prefix, '');
  if (deviceId.startsWith('.')) deviceId = deviceId.slice(1);
  const { details } = props;

  const capDetailsRequest = {
    offset: 0,
    search: '',
    activityType: 'all',
    limit: 1,
  };

  React.useEffect(() => {
    props.getCapsDetails(selectedDevice, capDetailsRequest);
    return () => {
      props.clearCapsDetails();
    };
  }, [selectedDevice]);

  const getInfoHeader = () => {
    return (
      <div className="infoHeader">
        <div className="rowDirection">
          <button className="back" onClick={() => history.back()} tabIndex={0}>
            {Strings.back}
          </button>
          {deviceType !== 'spirometer' && (
            <React.Fragment>
              <div className="feildBox">
                {Strings.deviceName}:{' '}
                <div className="feildValue val">
                  {details?.cap_name?.toString() ? details?.cap_name?.toString() : '-'}{' '}
                </div>
                {isShipper() ? (
                  ''
                ) : (
                  <div className="feildValue edit">
                    <div
                      className="editImg"
                      onClick={() => props.onAddNickName(capDetailsRequest, details?.cap_name, details?.cap_id)}
                    />
                  </div>
                )}
              </div>
              <div className="pathHr"></div>
            </React.Fragment>
          )}
          <div className="feildBox">
            {Strings.deviceId}:<div className="feildValue">{deviceId}</div>
          </div>
          {manufacturer && model && (
            <React.Fragment>
              <div className="pathHr"></div>
              <div className="feildBox">
                {Strings.deviceModel}:<div className="feildValue">{`${manufacturer} ${model}`}</div>
              </div>
            </React.Fragment>
          )}
          {props.hubId && (
            <React.Fragment>
              <div className="pathHr"></div>
              <div className="feildBox">
                {Strings.hubId}:<div className="feildValue">{props.hubId}</div>
              </div>
            </React.Fragment>
          )}
          {props.kitId && (
            <React.Fragment>
              <div className="pathHr"></div>
              <div className="feildBox">
                {Strings.kitId}:<div className="feildValue">{props.kitId}</div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  const rootPath = `/devices/${deviceType}/${hubId}/devices/${selectedDevice}/${manufacturer}/${model}`;
  const tabs = [
    <Tab name={DEVICE_READINGS_TAB} path={`${rootPath}/events`} key={DEVICE_READINGS_TAB}>
      <HubDeviceEvents {...props} />
    </Tab>,
  ];

  if (isSuperUser() || isShipper()) {
    tabs.push(
      <Tab name={DEVICE_STATUS_HISTORY_TAB} path={`${rootPath}/history`} key={DEVICE_STATUS_HISTORY_TAB}>
        <DeviceStatusHistory {...props} />
      </Tab>,
    );
  }

  return (
    <React.Fragment>
      {getInfoHeader()}
      <TabView activeTab={props.activeTab} routeMode noSwiping>
        {tabs}
      </TabView>
    </React.Fragment>
  );
}

DeviceDetails.propTypes = {
  activeTab: PropTypes.string,
};

const mapStateToProps = state => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    details: state.shipper.capsDetails.data,
    hubId: urlParams.get('hubId'),
    kitId: urlParams.get('kitId'),
  };
};

const mapDispatchToProps = dispatch => ({
  getCapsDetails: (id, pageRequest) => dispatch(capsActions.getCapsDetails(id, pageRequest)),
  clearCapsDetails: () => dispatch(capsActions.clearCapsDetails()),
  onAddNickName: (pageRequest, capName, id) =>
    dispatch(
      openModalAction('add-nick-name', {
        action: capsActions.addNickName,
        actionType: ADD_NICK_NAME,
        id,
        nextAction: capsActions.getCapsDetails,
        pageRequest,
        capName,
        allowEmpty: true,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetails);
