import Strings from '../../Strings';
import {
  convertKilogramToPound,
  GlucoseUnitConverter,
  GLUCOSE_UNITS,
  roundToDecimal,
  convertCelsiusToFahrenheit,
  TEMPERATURE_UNITS,
} from '../../utils/unitConverters';

export const typeRenderer = type => {
  switch (type) {
    case 'measurments':
      return Strings.measurments;
    case 'takes':
      return Strings.missed;
    case 'battery':
      return Strings.battery.battery;
    case 'schedule_missed_doses':
      return Strings.missedDoses;
    default:
      return 'Unknown';
  }
};

export const priorityRenderer = priority => {
  switch (priority) {
    case 0:
      return 'Low';
    case 1:
      return 'Medium';
    case 2:
      return 'High';
    default:
      return 'Unknown';
  }
};

export const idRenderer = e => {
  let prefix = '';
  switch (e.event_type) {
    case 'weight':
      prefix = 'SCALE';
      break;
    case 'blood_pressure':
      prefix = 'BPM';
      break;
    case 'glucose':
      prefix = 'GLU';
      break;
    case 'spirometer':
      prefix = 'SPI';
      break;
    case 'temperature':
      prefix = 'TEMP';
      break;
    case 'saturation':
      prefix = 'SAT';
      break;
    case 'heart_rate':
      prefix = 'HR';
      break;
    case 'air_quality':
      prefix = 'AIRQ';
      break;
    case 'coagulation':
      prefix = 'COAG';
      break;
    default:
      prefix = 'UNKNOWN';
  }
  if (e.general_type === 'schedule_missed_doses') {
    prefix = 'DOSES';
  }
  return `${prefix}${e.id}`;
};

export const unitRenderer = unit => {
  switch (unit) {
    case 'kilogram, meter':
      return 'kg';
    case 'punds, inch':
      return 'lbs';
    default:
      return 'Unknown';
  }
};

export const conditionRenderer = conditions => {
  const result = [];
  conditions.forEach(condition => {
    if (condition.field === 'weight') {
      const diff_in_lbs = roundToDecimal(convertKilogramToPound(condition.diff), 1);
      if (condition.actual_value > condition.max) {
        result.push(`+ ${diff_in_lbs} lbs`);
      } else if (condition.actual_value < condition.min) {
        result.push(`- ${diff_in_lbs} lbs`);
      }
    } else if (condition.field === 'systolic_pressure') {
      result.push(`SYS ${condition.actual_value}`);
    } else if (condition.field === 'diastolic_pressure') {
      result.push(`DIA ${condition.actual_value}`);
    } else if (condition.field === 'fev1') {
      result.push(`FEV1 ${condition.actual_value}`);
    } else if (condition.field === 'fvc') {
      result.push(`FVC ${condition.actual_value}`);
    } else if (condition.field === 'glucose_concentration') {
      const actual_value = roundToDecimal(
        GlucoseUnitConverter(condition.actual_value, condition.unit, GLUCOSE_UNITS.mg_dl),
        1,
      );
      result.push(`${actual_value} ${GLUCOSE_UNITS.mg_dl}`);
    } else if (condition.field === 'temperature') {
      const actual_value = roundToDecimal(convertCelsiusToFahrenheit(condition.actual_value), 1);
      result.push(`TEMP ${actual_value} ${TEMPERATURE_UNITS.f}`);
    } else if (condition.field === 'saturation') {
      result.push(`SAT ${condition.actual_value}%`);
    } else if (condition.field === 'heart_rate') {
      result.push(`HR ${condition.actual_value} ${Strings.bpm_unit}`);
    } else if (condition.field === 'missed_doses') {
      result.push(`-`);
    } else if (condition.field === 'pm2_5') {
      result.push(`PM 2.5 ${condition.actual_value} µg/m³`);
    } else if (condition.field === 'pt') {
      result.push(`PT ${condition.actual_value}s`);
    } else if (condition.field === 'inr') {
      result.push(`INR ${condition.actual_value}`);
    }
  });
  return result.join(' ');
};
