import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import TabView, { Tab } from '../../../components/TabView';
import Strings from '../../../Strings';
import Configuration from '../Configuration/Configuration';
import Devices from '../Devices/Devices';
import OutboundAPI from '../OutboundAPI/OutboundAPI';
import ExternalApiOauth from '../ExternalApi/ExternalApiOauth';
import ExternalApiAccessControl from '../ExternalApi/ExternalApiAccessControl';
import UserList from '../../Users/UserList';
import { DOCTOR, ORGANIZATION_MANAGER, CAP_MANAGER } from '../../../utils/userRoles';
import ExternalApiLogs from '../ExternalApi/ExternalApiLogs';

class ManageOrganization extends PureComponent {
  pageRequest = {
    offset: 0,
    search: '',
  };

  render() {
    const { id, type, name } = this.props.match.params;
    const { activeTab } = this.props;
    const tabArray = [];

    tabArray.push(
      <Tab name={Strings.organizationManagers} path={`/organization/${id}/${name}/orgnization-manager`}>
        <UserList organizationId={id} role={ORGANIZATION_MANAGER} />
      </Tab>,
    );

    tabArray.push(
      <Tab name={Strings.capManager} path={`/organization/${id}/${name}/cap-manager`}>
        <UserList organizationId={id} role={CAP_MANAGER} />
      </Tab>,
    );

    tabArray.push(
      <Tab name={Strings.adminTab} path={`/organization/${id}/${name}/admin`}>
        <UserList organizationId={id} role={DOCTOR} />
      </Tab>,
    );

    tabArray.push(
      <Tab name={Strings.devicesTab} path={`/organization/${id}/${name}/devices/ble`}>
        <Devices organizationId={id} type={type} organizationName={name} />
      </Tab>,
    );

    tabArray.push(
      <Tab name={Strings.outboundAPITab} path={`/organization/${id}/${name}/outbound-api`}>
        <OutboundAPI organizationId={id} />
      </Tab>,
    );

    tabArray.push(
      <Tab name={Strings.configuration} path={`/organization/${id}/${name}/config`}>
        <Configuration organizationId={id} />
      </Tab>,
    );

    tabArray.push(
      <Tab name={Strings.externalApiOauthTab} path={`/organization/${id}/${name}/external-api/oauth`}>
        <ExternalApiOauth organizationId={id} />
      </Tab>,
    );

    tabArray.push(
      <Tab name={Strings.externalApiAccessControlTab} path={`/organization/${id}/${name}/external-api/access-control`}>
        <ExternalApiAccessControl organizationId={id} />
      </Tab>,
    );

    tabArray.push(
      <Tab name={Strings.externalApiLogsTab} path={`/organization/${id}/${name}/external-api/logs`}>
        <ExternalApiLogs organizationId={id} />
      </Tab>,
    );

    return (
      <React.Fragment>
        <div className="infoHeader spaceBeteween white">
          <div className="rowDirection">
            <button className="back" onClick={() => history.back()} tabIndex={0}>
              {Strings.back}
            </button>
            <div className="feildBox">
              {Strings.organization}: <div className="feildValue">{name}</div>
            </div>
          </div>
        </div>
        <TabView key="tabs" activeTab={activeTab} routeMode className="">
          {tabArray}
        </TabView>
      </React.Fragment>
    );
  }
}

ManageOrganization.propTypes = {
  activeTab: PropTypes.any,
  caps: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.any,
      name: PropTypes.string,
      type: PropTypes.any,
    }),
  }),
  onNavigate: PropTypes.func,
  onSet: PropTypes.func,
};

const mapStateToProps = state => {
  return { role: state.auth.role };
};

const mapDispatchToProps = dispatch => ({ onNavigate: path => dispatch(push(path)) });

export default connect(mapStateToProps, mapDispatchToProps)(ManageOrganization);
