import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Modal } from '../containers';
import { closeModal } from '../actions/modal';
import Strings from '../Strings';
import { Errors } from '../components/Login/Errors/Errors';
import { notificationActions } from '../components/Notification/redux/actions';

const ConfirmationModal = ({ data, onCancel, ...props }) => {
  const [errors, setErrors] = useState([]);

  const onConfirm = () => {
    const result = props.onConfirm(data.onConfirmAction);
    if (result instanceof Promise) {
      result.then(response => {
        if (response && response.type === `${data.onConfirmAction.type}/error`) {
          setErrors([response.response?.data?.error?.message || response.error]);
        } else {
          props.closeModal();
          if (data.confirmPostAction) {
            props.confirmPostAction(data.confirmPostAction);
          }
          if (data.onSuccessNotification) {
            props.showNotification(data.onSuccessNotification);
          }
        }
        return response;
      });
    }
  };

  return (
    <Modal
      name="confirmation-modal"
      additionalClasses={['form-modal']}
      caption={data.caption !== undefined ? data.caption : Strings.confirmDelete}
      {...props}
    >
      <div>
        {data && data.title ? <div className="title">{data.title}</div> : null}
        <br />
        {<div className="caution">{!data.hideCaution ? Strings.warnigs.cantBeUndone : ''}</div>}
        {errors && errors.length > 0 && <Errors errors={errors} />}
        <div className="button-bar" key="button-bar">
          <button className="white gray-text" key="cancel" onClick={() => onCancel(data)}>
            {data?.noButtonText || Strings.no}
          </button>
          {!data?.hideYesButton && (
            <button className="brand-blue" key="submit" onClick={() => onConfirm(data)}>
              {data?.yesButtonText || Strings.yes}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  data: state.modals['confirmation-modal'] && state.modals['confirmation-modal'].data,
});

const mapDispatchToProps = dispatch => ({
  onCancel: data => {
    if (data.onCancelAction) {
      dispatch(data.onCancelAction);
    }
    dispatch(closeModal('confirmation-modal'));
  },
  onConfirm: action => dispatch(action),
  confirmPostAction: action => dispatch(action),
  closeModal: () => dispatch(closeModal('confirmation-modal')),
  showNotification: (message, timeout, error) => dispatch(notificationActions.show(message, timeout, error)),
});

ConfirmationModal.propTypes = {
  data: PropTypes.shape({
    onConfirmAction: PropTypes.shape({ type: PropTypes.string.isRequired }).isRequired,
    onCancelAction: PropTypes.shape(),
    confirmPostAction: PropTypes.func,
    onSuccessNotification: PropTypes.string,
    hideCaution: PropTypes.bool,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
