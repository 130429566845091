import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import React from 'react';

import { formatTimeFromSeconds, momentHourMinutesToSeconds } from '../../utils';
import style from './index.module.scss';
import { PillInfo } from './PillInfo';

function DayInfo(props) {
  const { events } = props;

  if (!events) return null;

  const data = {};

  const timeArray = [];
  const eventsCollection = {};

  events.forEach(event => {
    event.medicines.forEach(med => {
      const m = moment(med.time * 1000);
      const time = momentHourMinutesToSeconds(m);
      timeArray.push(time);
      if (!eventsCollection[time]) eventsCollection[time] = [];
      const itemFound = eventsCollection[time].find(x => x.name == med.name);
      if (!itemFound) {
        eventsCollection[time].push(med);
      }
    });
  });

  timeArray.sort((a, b) => a - b);

  timeArray.forEach(time => {
    data[formatTimeFromSeconds(time)] = eventsCollection[time];
  });

  return (
    <div className={style.info}>
      {Object.keys(data).map(key => (
        <PillInfo key={key} data={data[key]} time={key} />
      ))}
    </div>
  );
}

DayInfo.propTypes = {
  events: PropTypes.shape({ forEach: PropTypes.func }),
};

export default DayInfo;
