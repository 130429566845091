import { Background } from 'components/Login/Background/Background';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Strings from '../../../Strings';
import { getMobileOperatingSystem, QueryString } from '../../../utils';
import { actions } from './redux/actions';
import { ESI_VERIFY_ADMIN_CHECK_CODE_RESULT, ESI_VERIFY_ADMIN_SEND_CODE_RESULT } from './redux/constants';
import Step1_EnterPhoneNumber from './Steps/Step1_EnterPhoneNumber';
import Step2_EnterVerificationCode from './Steps/Step2_EnterVerificationCode';

const mapStateToProps = state => {
  return {
    data: state.esi.inviteAdmin,
    two_factor_key: state.auth.two_factor_key,
    role: state.auth.role || '',
    reset_password: state.auth.reset_password,
    first_name: state.auth.first_name,
  };
};

const mapDispatchToProps = dispatch => ({
  sendCode: (phone, two_factor_key) => dispatch(actions.sendCode(phone, two_factor_key)),
  resendCode: (phone, two_factor_key) => dispatch(actions.resendCode(phone, two_factor_key)),
  verifyCode: (phone, code, two_factor_key) => dispatch(actions.verifyCode(phone, code, two_factor_key)),
});

class EsiInviteDoctor extends PureComponent {
  steps = [
    {
      component: Step1_EnterPhoneNumber,
      title: () => {
        return `${Strings.hi} ${this.props.first_name} `;
      },
      props: { text: Strings.esiDoctorInviteText },
    },
    {
      component: Step2_EnterVerificationCode,
      title: () => {
        return Strings.verificationCode;
      },
      props: { text: Strings.esiDoctorCodeText },
    },
  ];

  state = {
    step: 0,
    error: null,
  };

  // User phone to send it in the step 2
  phone;

  resendCode() {
    this.props.resendCode(this.phone, this.props.two_factor_key);
  }

  verifyCode(code) {
    const { two_factor_key } = this.props;
    this.props.verifyCode(this.phone, code, two_factor_key);
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (data && data.isLoading === false && prevProps.data && prevProps.data.isLoading === true) {
      if (data.errorMessage) {
        this.setError(data.errorMessage.title, data.errorMessage.text);
        return;
      }

      if (data.actionType === ESI_VERIFY_ADMIN_CHECK_CODE_RESULT) {
        window.location = '/user-settings';
      }
    }
  }

  incStep() {
    this.setState(prevState => ({ step: prevState.step + 1 }));
  }

  onFormSubmit = data => {
    const { step } = this.state;
    const { two_factor_key } = this.props;
    this.clearError();

    switch (step) {
      case 0: {
        const { phone } = data;
        this.phone = phone;
        this.props.sendCode(phone, two_factor_key).then(r => {
          if (r.type === ESI_VERIFY_ADMIN_SEND_CODE_RESULT) {
            this.steps[1].props.codeExpiresAt = r.response.code_expires_at;
            this.incStep();
          }
        });
        break;
      }
      case 1: {
        const { code } = data;
        this.verifyCode(code);
        break;
      }
      case 2:
        window.location = '/';
        break;

      default:
        window.location = '/';
        break;
    }
  };

  onResendCode = event => {
    event.preventDefault();
    this.resendCode();
  };

  setError(title, text) {
    this.setState({
      error: text
        ? {
            title,
            text,
          }
        : title,
    });
  }

  clearError() {
    this.setState({ error: null });
  }

  render() {
    const { step } = this.state;
    const isLoading = this.props.data ? this.props.data.isLoading : false;

    const currentStep = this.steps[step];
    const StepComponent = currentStep.component;
    const progress = ((step + 1) / this.steps.length) * 100;
    const stepProps = currentStep.props ? currentStep.props : {};

    return (
      <Background title={currentStep.title()} progress={progress} noLines>
        {getMobileOperatingSystem() && <div className="toast-mobile show">{Strings.mobileVersionNotSupported}</div>}
        <StepComponent
          onFormSubmit={this.onFormSubmit}
          onResendCode={this.onResendCode}
          error={this.state.error}
          isLoading={isLoading}
          {...stepProps}
        />
      </Background>
    );
  }
}

EsiInviteDoctor.propTypes = {
  data: PropTypes.shape({
    actionType: PropTypes.any,
    errorMessage: PropTypes.shape({
      text: PropTypes.any,
      title: PropTypes.any,
    }),
    isLoading: PropTypes.bool,
  }),
  location: PropTypes.shape({ search: PropTypes.any }),
  resendCode: PropTypes.func,
  sendCode: PropTypes.func,
  two_factor_key: PropTypes.any,
  verifyCode: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(EsiInviteDoctor);
