import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import Strings from '../../Strings';
import { actionsAdvancedFilters } from './actions';
import BarItem from './internals/BarItem';
import { getRelevantFilters, getFiltersForRequest } from './helpers';
import { getFiltersDefinition } from './FiltersDefinition';

class AdvancedFiltersBar extends React.PureComponent {
  componentWillUnmount() {
    this.props.clearAll();
  }

  onClear = () => {
    this.props.clearFilters();
  };

  getStatusRepresentants = () => {
    const { filters, updateFilterState, customFilters } = this.props;

    return getRelevantFilters(filters).map((filter, i) => {
      return <BarItem updateFilterState={updateFilterState} key={i} filter={filter} customFilters={customFilters} />;
    });
  };

  componentDidUpdate(prevProps) {
    const { filters, isFiltersInitialized } = this.props;

    if (filters !== prevProps.filters && isFiltersInitialized) {
      // lets replace current entry in history with new one with filters state
      const filtersSerialized = JSON.stringify(getFiltersForRequest(filters));
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('filters', filtersSerialized);
      this.props.replacePath(`${window.location.pathname}?${urlParams}`);
    }
  }

  render() {
    const clearButton = (
      <div className="round-button" onClick={this.onClear}>
        {Strings.clear}
      </div>
    );

    const reps = this.getStatusRepresentants();

    if (reps.length !== 0) {
      return (
        <div className="advanced-filters">
          <div className="horizontal-line" />
          <div className="advanced-filters-bar">
            {clearButton}
            {reps}
          </div>
        </div>
      );
    }
    return <React.Fragment />;
  }

  static propTypes = {
    clearFilters: PropTypes.func.isRequired,
    clearAll: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    updateFilterState: PropTypes.func.isRequired,
    replacePath: PropTypes.func.isRequired,
    isFiltersInitialized: PropTypes.bool.isRequired,
  };
}

const mapStateToProps = state => {
  return {
    filters: state.entities.advancedFilters.filters.items,
    isFiltersInitialized: state.entities.advancedFilters.filters.isInitalized,
  };
};

const mapDispatchToProps = dispatch => ({
  clearFilters: () => dispatch(actionsAdvancedFilters.clearFilters()),
  clearAll: () => dispatch(actionsAdvancedFilters.clearAll()),
  updateFilterState: state => dispatch(actionsAdvancedFilters.updateFilterState(state)),
  replacePath: path => dispatch(replace(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedFiltersBar);
