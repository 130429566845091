import { API_CALL } from '../../../../../middleware/api';
import { WIDGET_GET_YOUR_KITS } from './constants';

export const actions = {
  getYourKitsCount: timezone => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/v1/hubs-lte-total-low-signal-per-day?timezone=' + timezone,
    },
    type: WIDGET_GET_YOUR_KITS,
  }),
};
