import { tableActions, isTableAction } from './actions';

const table = (
  state = {
    order: {},
    columns: [],
    ids: [],
  },
  action,
) => {
  const getIds = data => {
    if (typeof data === 'object' && data != undefined) {
      return Object.keys(data);
    }
    return data;
  };

  switch (action.type) {
    case tableActions.init:
      return {
        columns: action.table.columns,
        ids: getIds(action.table.data),
        order: { ...action.table.order },
      };
    case tableActions.setColumns:
      return {
        ...state,
        columns: action.table.columns,
      };
    case tableActions.setData:
      return {
        ...state,
        ids: getIds(action.table.data),
      };

    case tableActions.setOrder: {
      let { direction } = action.table;
      const { key } = action.table.order;
      const c = state.columns.find(d => d.key === key);

      if (direction === undefined) {
        if (state.order.key === key && state.order.direction === 'ascending') {
          direction = 'descending';
        } else {
          direction = 'ascending';
        }
      }

      if (!c || !c.sortKey) {
        return state;
      }

      return {
        ...state,
        order: {
          key: c.key,
          sortKey: c.sortKey,
          direction,
        },
      };
    }
    case tableActions.selectRow:
      return {
        ...state,
        selected: action.table.selected,
      };
    case tableActions.deselectRow:
      return {
        ...state,
        selected: undefined,
      };
    case tableActions.refreshTable:
      return {};
    default:
      return state;
  }
};

export default (state = {}, action) => {
  if (isTableAction(action)) {
    if (action.type === tableActions.selectRow && action.othersToDeSelect) {
      const others = action.othersToDeSelect === 'all' ? Object.keys(state) : action.othersToDeSelect;
      return {
        ...state,
        ...others.reduce((r, t) => {
          // eslint-disable-next-line no-param-reassign
          r[t] = table(state[t], t === action.table.id ? action : { type: tableActions.deselectRow });
          return r;
        }, {}),
      };
    }
    return {
      ...state,
      [action.table.id]: table(state[action.table.id], action),
    };
  }
  return state;
};
