export const UPDATE_LAYOUTS = 'update-layouts';
export const CHANGE_WIDGET_VISIBILITY = 'change-widget-visibility';
export const RESET_TO_DEFAULT = 'reset-dashboard-to-default';
export const CHANGE_EDIT_MODE = 'change-edit-mode';
export const CHANGE_WIGDET_CONFIGURATION = 'change-widget-configuration';
export const CHANGE_WIGDET_CONFIGURATION_FOR_DASHBOARD = 'change-widget-configuration-for-dashboard';
export const CHANGE_COLOR_PALETTE = 'change-color-palette';

export const actionsDashboard = {
  changeVisibility: (dashboardId, widgetId, visible, additionalData, permissions) => ({
    type: CHANGE_WIDGET_VISIBILITY,
    dashboardId,
    widgetId,
    visible,
    additionalData,
    permissions,
  }),
  resetToDefault: (dashboardId, additionalData, permissions) => ({
    type: RESET_TO_DEFAULT,
    dashboardId,
    additionalData,
    permissions,
  }),
  updateLayouts: (dashboardId, layouts) => ({
    type: UPDATE_LAYOUTS,
    dashboardId,
    layouts,
  }),
  changeEditMode: (dashboardId, editMode) => ({
    type: CHANGE_EDIT_MODE,
    dashboardId,
    editMode,
  }),
  changeWidgetConfiguration: (dashboardId, widgetId, configuration) => ({
    type: CHANGE_WIGDET_CONFIGURATION,
    dashboardId,
    widgetId,
    configuration,
  }),
  changeWidgetConfigurationForDashboard: (dashboardId, configuration) => ({
    type: CHANGE_WIGDET_CONFIGURATION_FOR_DASHBOARD,
    dashboardId,
    configuration,
  }),
  changeColorPalette: colorPalette => ({
    type: CHANGE_COLOR_PALETTE,
    colorPalette,
  }),
};
