import React from 'react';

import { TextInputWithLabel } from '../components/Inputs/TextInputWithLabel';
import { getFieldWithComponent } from './getFieldWithComponent';

function TextInputWithLabelField(props) {
  const { input, meta, refLink, ...rest } = props;
  const error = typeof rest.error === 'boolean' ? rest.error && meta.error : meta.touched && meta.error;

  return <TextInputWithLabel {...rest} error={error} {...input} />;
}

const Component = getFieldWithComponent(TextInputWithLabelField);

export { Component as TextInputWithLabelField };
