import { LIST_OWN_PATIENTS, LIST_OWN_PATIENTS_RESULT, LIST_OWN_PATIENTS_ERROR } from '../actions/action-types';

const ownPatientsReducer = (state = { objects: {} }, action) => {
  const data = action.response;
  switch (action.type) {
    case LIST_OWN_PATIENTS:
      return {
        ...state,
        loading: true,
      };

    case LIST_OWN_PATIENTS_RESULT:
      return {
        loading: false,
        patients: action.response,
        preparing: false,
      };

    case LIST_OWN_PATIENTS_ERROR:
      return {
        loading: false,
        patients: [],
        preparing: false,
      };
    default:
      return state;
  }
};

export default ownPatientsReducer;
