import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { push } from 'react-router-redux';

import { trialsAction } from '../../actions/trials';
import { openModalAction } from '../../actions/modal';
import Table, { Column } from '../../containers/Table/TableWithPagination';
import { clearEntitiesAction } from '../../actions';
import { PageHeader } from '../../components/PageHeader';
import { Button } from '../../components/PageHeader/Button';
import { TextInput } from '../../components/PageHeader/TextInput';
import { HEADER_BUTTON_BLUE, PAGE_LIMIT, STUDY_NAME_LENGTH } from '../../constants';
import Strings from '../../Strings';
import withSliderPicker from '../../components/_hocs/withSlidePicker';
import { NEW_TRIAL, NEW_TRIAL_ERROR } from '../../actions/action-types';
import styles from './MyStudies.module.scss';

const getAdherencePercentage = patient => {
  if (patient.analytics && patient.analytics) {
    return `${patient.analytics.adherence}%`;
  }
  return 'N/A';
};

const getAdherenceLine = adherence => {
  const average = getAdherencePercentage(adherence);
  const perfectHundredStyle = average === '100%' ? 'perfect' : 'active';
  const averageWidthStyle = { width: average };
  return (
    <div className={styles.adherenceWrapper}>
      <div className="averageText">{average}</div>
      <div className="average">
        <div className={perfectHundredStyle} style={averageWidthStyle} />
      </div>
    </div>
  );
};

class MyStudies extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      study: {
        name: '',
        description: '',
      },
    };
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
    this.onTrialSelected = this.onTrialSelected.bind(this);
  }

  componentDidMount() {
    const { onNewTrial, readOnlyMode } = this.props;

    const dateRange = this.getDateRange();
    this.pageRequest = {
      startDate: dateRange.infimum,
      endDate: dateRange.supremum,
      offset: 0,
      search: '',
    };
    this.props.clearEntities();
    this.props.onLoadData(this.pageRequest, true).then(r => {
      if (!readOnlyMode && r.response.studies.length === 0 && window.location.pathname === '/studies') {
        onNewTrial();
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.rangeDate !== nextProps.rangeDate) {
      this.onDateRangeChanged(nextProps.rangeDate);
    }
  }

  onDateRangeChanged = range => {
    const { offset } = this.props.pagination;
    const { infimum, supremum } = range;
    this.pageRequest.startDate = infimum;
    this.pageRequest.endDate = supremum;
    this.pageRequest.offset = offset;
    this.props.onLoadData(this.pageRequest);
  };

  onPrevClick() {
    const { pagination } = this.props;
    const offset = pagination.offset - PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.onLoadData(this.pageRequest, true);
  }

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.onLoadData(this.pageRequest, true);
  };

  onNextClick() {
    const { pagination } = this.props;
    const offset = pagination.offset + PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.onLoadData(this.pageRequest, true);
  }

  onSortClick({ sortKey, direction }) {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    this.props.onLoadData(this.pageRequest);
  }

  onSearchQueryChange = query => {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query?.toLowerCase();
    this.props.onLoadData(this.pageRequest, true);
  };

  onStudyDelete = (event, study) => {
    event.preventDefault();
    event.stopPropagation();
    const data = {
      title: (
        <span>
          Are you sure you want to {study.own ? 'delete' : 'leave'} study <b>{study.name}</b>?
        </span>
      ),
      onConfirmAction: trialsAction.actionDelete(study.id),
      studyId: study.id,
      onCancelAction: null,
    };
    this.props.openConfirmModal(data);
  };

  onTrialSelected = id => {
    const { trials } = this.props;
    if (trials && trials[id].id) {
      this.props.onNavigate(`/study/${trials[id].id}/overview`);
    }
  };

  onNewStudyTextChange = event => {
    const { study } = this.state;

    study[event.target.getAttribute('name')] = event.target.value;

    this.setState({ study });
  };

  onNewStudySubmit = () => {
    const { study } = this.state;

    const validationErrors = [];

    if (!study.name.length) {
      validationErrors.push(Strings.errors.emptyName);
    }

    if (validationErrors.length) {
      this.setState({ errors: validationErrors });
      return;
    }

    this.props.onFirstStudy(study).then(response => {
      if (response.type === NEW_TRIAL_ERROR && response.response.data) {
        const responseData = response.response.data;
        const errors = Object.keys(responseData).map(key => `${key}: ${responseData[key]}`);
        this.setState({ errors });
      } else {
        const dateRange = this.getDateRange();
        this.pageRequest = {
          startDate: dateRange.infimum,
          endDate: dateRange.supremum,
          offset: 0,
          search: '',
        };
        this.props.clearEntities();
        this.props.onLoadData(this.pageRequest, true);
      }
      return response;
    });
  };

  getDateRange() {
    const dateRange = this.props.dateFilter
      ? this.props.dateFilter
      : {
          infimum: moment()
            .subtract(1, 'month')
            .toDate(),
          supremum: moment().toDate(),
        };
    return dateRange;
  }

  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  getHeaderComponents() {
    const { onNewTrial, readOnlyMode } = this.props;
    return (
      <React.Fragment>
        <TextInput class="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        {!readOnlyMode && <Button class={HEADER_BUTTON_BLUE} onClick={onNewTrial} title={Strings.createStudy} />}
      </React.Fragment>
    );
  }

  render() {
    const { trials = {}, isLoading, readOnlyMode, pagination } = this.props;

    return (
      <div className="my-studies-page">
        <PageHeader getHeaderComponents={() => this.getHeaderComponents()} />
        <Table
          isLoading={isLoading}
          name="studies"
          data={trials}
          onRowSelection={this.onTrialSelected}
          onPrevClick={this.onPrevClick}
          onSortClick={this.onSortClick}
          onNextClick={this.onNextClick}
          onCustomPage={this.onCustomPage}
          pagination={pagination}
        >
          <Column key="study-name" title={Strings.name} value={d => d.studyName} sortKey="name" />
          <Column key="patient_count" title={Strings.patientCount} value={d => d.patientCount} sortKey="patientCount" />
          <Column key="adherence" title={Strings.adherence} value={d => getAdherenceLine(d)} sortKey="adherence" />
          <Column
            key="missed"
            title={Strings.missed}
            value={d => (d.analytics ? d.analytics.missed : 0)}
            sortKey="missed"
          />
          <Column
            key="buttons"
            title={Strings.action}
            value={d => (
              <React.Fragment>
                {!readOnlyMode ? (
                  <button className={`white ${d.isOwner ? 'delete' : null} `} onClick={e => this.onStudyDelete(e, d)}>
                    {d.isOwner ? <div className="delete-img" /> : 'Leave'}
                  </button>
                ) : null}
                {d.isLoading ? (
                  <div className="table-row-spinner">
                    <div className="spinner-row" />
                  </div>
                ) : null}
              </React.Fragment>
            )}
          />
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { trials } = state.entities;
  return {
    readOnlyMode: state.auth.read_only,
    trials: trials.trials,
    pagination: trials.pagination,
    isLoading: trials.loading,
    isPreparing: trials.preparing,
    dateFilter: state.filter.dateFilter,
  };
};

const mapDispatchToProps = dispatch => ({
  onLoadData: (pageRequest, isLoading) => dispatch(trialsAction.actionList(pageRequest, isLoading)),
  onNavigate: path => dispatch(push(path)),
  onNewTrial: () =>
    dispatch(
      openModalAction(NEW_TRIAL, {
        action: trialsAction.actionNew,
        actionType: NEW_TRIAL,
      }),
    ),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  clearEntities: () => dispatch(clearEntitiesAction(['patients', 'trials', 'memberships', 'doctors'])),
  onFirstStudy: study => dispatch(trialsAction.actionNew(study)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSliderPicker(MyStudies));
