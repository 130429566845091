import { GET_DEVICES_RESULT } from './constants';

export const ThirdPartyDevicesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DEVICES_RESULT:
      return {
        ...state,
        data: action.response.data,
        pagination: action.response.pagination,
      };

    default:
      return state;
  }
};
