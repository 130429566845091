import { ACCEPT_AS_CAREGIVER, ACCEPT_AS_CAREGIVER_ERROR, ACCEPT_AS_CAREGIVER_RESULT } from '../actions/action-types';

const caregiverReducer = (
  state = {
    caregiver: {},
    loading: false,
  },
  action,
) => {
  switch (action.type) {
    case ACCEPT_AS_CAREGIVER:
      return {
        ...state,
        loading: true,
        caregiver: {},
      };
    case ACCEPT_AS_CAREGIVER_RESULT:
      return {
        ...state,
        caregiver: action.response,
        loading: false,
      };
    case ACCEPT_AS_CAREGIVER_ERROR:
      return {
        ...state,
        error: action.response.data.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default caregiverReducer;
