import React from 'react';
import PropTypes from 'prop-types';

import './Dashboard.scss';
import { DATE_MONTH_DAY_YEAR_WITH_DASH } from '../../../../constants';
import ReadingsWidget from '../../../Dashboards/Widgets/ReadingsWidget';
import { getReadingsData } from '../../../../utils';

function PatientLastReading(props) {
  const reading = getReadingsData(props.reading, props.data, true);
  const { selected, onSelect, type, isCollapsible, expanded } = props;
  return (
    <ReadingsWidget
      title={`${reading?.title}${reading?.unit ? ` (${reading.unit.trim()})` : ''}`}
      value={reading?.value}
      selected={selected}
      alerted={reading?.alert}
      onClick={onSelect}
      valueTooltip={reading?.date ? reading.date.format(DATE_MONTH_DAY_YEAR_WITH_DASH) : reading?.tooltip}
      icon={reading?.icon}
      type={type}
      isCollapsible={isCollapsible}
      expanded={expanded}
    />
  );
}

PatientLastReading.propTypes = {
  onSelect: PropTypes.func,
  refreshTimestamp: PropTypes.any,
  reading: PropTypes.string,
  data: PropTypes.shape({
    date: PropTypes.string,
    title: PropTypes.string,
    alert: PropTypes.bool,
  }),
  selected: PropTypes.bool,
};

export default PatientLastReading;
