import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import TabView, { Tab } from '../../../components/TabView';
import './Devices.scss';
import ProvisioningTab from './ProvisioningTab';
import Strings from '../../../Strings';

import { getActiveTabNameFromUrl } from '../../../utils';
import { hasPermission, PERMISSIONS } from '../../../utils/userPermissions';

const mapStateToProps = state => {
  const { profile } = state.auth;
  const access = profile?.access;
  return { access };
};

const mapDispatchToProps = dispatch => ({ navigate: path => dispatch(push(path)) });

export const root = 'provisioning';

export const Tabs = {
  Hub: {
    DisplayName: Strings.hub,
    urlId: 'hub',
    url: `/${root}/hub`,
  },
  LTE: {
    DisplayName: Strings.lte,
    urlId: 'lte',
    url: `/${root}/lte`,
  },
  LSC: {
    DisplayName: Strings.lsc,
    urlId: 'lsc',
    url: `/${root}/lsc`,
  },
};

class Provisionings extends PureComponent {
  render() {
    const activeTab = this.props.match.params.tab;
    const tabArray = [];

    hasPermission(PERMISSIONS.PROVISIONING_HUB) &&
      tabArray.push(
        <Tab name={Tabs.Hub.DisplayName} path={Tabs.Hub.url}>
          <ProvisioningTab type="hub" />
        </Tab>,
      );

    hasPermission(PERMISSIONS.PROVISIONING_LTE) &&
      tabArray.push(
        <Tab name={Tabs.LTE.DisplayName} path={Tabs.LTE.url}>
          <ProvisioningTab type="lte" />
        </Tab>,
      );

    hasPermission(PERMISSIONS.PROVISIONING_LSC) &&
      tabArray.push(
        <Tab name={Tabs.LSC.DisplayName} path={Tabs.LSC.url}>
          <ProvisioningTab type="lsc" />
        </Tab>,
      );

    return (
      <TabView key="tabs" activeTab={getActiveTabNameFromUrl(activeTab, Tabs)} routeMode className="" noSwiping>
        {tabArray}
      </TabView>
    );
  }
}

Provisionings.propTypes = { activeTab: PropTypes.any };

export default connect(mapStateToProps, mapDispatchToProps)(Provisionings);
