import React from 'react';
import PropTypes from 'prop-types';

import './graph.scss';
import Strings from '../../Strings';
import PieGraph from './PieGraph';

export default function AdherenceGraph(props) {
  const { graphData } = props;
  const data = [
    {
      value: 0,
      label: Strings.adherence,
      fill: '#000',
      totalValue: graphData.adherence,
    },
    {
      value: graphData.missed,
      label: Strings.missed,
      fill: '#F1F1F1',
      stroke: '#F1F1F1',
    },
    {
      value: graphData.complied,
      label: Strings.complied,
      fill: graphData.adherence > 50 ? '#74B236' : '#e71d5b',
      cornerRadius: 15,
    },
    {
      value: graphData.skipped,
      label: Strings.skipped,
      fill: '#ffc000',
      stroke: '#ffc000',
    },
  ];

  return <PieGraph graphData={data} />;
}

AdherenceGraph.propTypes = {
  graphData: PropTypes.array,
  hasBorder: PropTypes.bool,
};
