import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';

import { QueryString } from '../../utils';
import { verificationAction } from '../../actions/user-settings';
import Strings from '../../Strings';

class VerificationCompletePage extends Component {
  static propTypes = {
    user: PropTypes.any,
    token: PropTypes.string,
    error: PropTypes.any,
    onError: PropTypes.func,
    onSendVerificationRequest: PropTypes.func,
    method: PropTypes.any,
  };

  UNSAFE_componentWillMount() {
    const { user, token, error } = this.props;
    if (error) {
      this.props.onError();
    }

    this.props.onSendVerificationRequest(user, token);
  }

  render() {
    const { method = 'email' } = this.props;
    return (
      <div className="activated-page">
        <div className="image" />
        <h1>{Strings.verificationCompleteText}</h1>
        <div className="caption">
          {Strings.yourText}
          {method}
          {Strings.verifiedText}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const query = new QueryString(ownProps.location.search);
  const { token = null, user = null } = query.params;
  const { method = null } = ownProps.match.params;
  const error = token === null || method === null || user === null;
  return {
    token,
    method,
    user,
    error,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { method = null } = ownProps.match.params;
  return {
    onError: () => dispatch(push('/')),
    onSendVerificationRequest: (user, token) => {
      dispatch(
        verificationAction.verify({
          user,
          token,
          method,
        }),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificationCompletePage);
