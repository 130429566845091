import React from 'react';

import { PhoneInput } from '../components/Inputs/PhoneInput';
import { getFieldWithComponent } from './getFieldWithComponent';
import Strings from '../Strings';

const PhoneField = props => {
  const { input, meta, linkRef, ...rest } = props;
  const error = typeof rest.error === 'boolean' ? rest.error && meta.error : meta.touched && meta.error;

  return <PhoneInput tt-text={Strings.phoneNumber} {...rest} error={error} ref={linkRef} {...input} />;
};

const Component = getFieldWithComponent(PhoneField);

export { Component as PhoneField };
