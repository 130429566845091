import React, { PureComponent } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

export class AdherenceTableScrollbarsHorizontal extends PureComponent {
  state = {
    shadowLeftOpacity: 0,
    shadowRightOpacity: 0,
  };

  eventsAreSet = false;

  renderTrackHorizontal = ({ style, ...props }) => {
    const finalStyle = {
      ...style,
      right: 2,
      bottom: 2,
      left: 2,
      borderRadius: 3,
    };

    return <div id="trackHorizontal" style={finalStyle} {...props} />;
  };

  renderThumbHorizontal = ({ style, ...props }) => {
    const finalStyle = {
      ...style,
      cursor: 'pointer',
      borderRadius: 'inherit',
      backgroundColor: 'rgba(0,0,0,.2)',
    };

    return <div id="thumbHorizontal" style={finalStyle} {...props} />;
  };

  handleUpdate = values => {
    const { scrollLeft, scrollWidth, clientWidth } = values;
    const shadowLeftOpacity = (1 / 20) * Math.min(scrollLeft, 20);
    const bottomScrollRight = scrollWidth - clientWidth;
    const shadowRightOpacity = (1 / 20) * (bottomScrollRight - Math.max(scrollLeft, bottomScrollRight - 20));
    this.setState({
      shadowLeftOpacity,
      shadowRightOpacity,
    });

    this.props.horizontalScrollUpdate(values, shadowLeftOpacity, shadowRightOpacity);

    // copying styles to outer scroll
    const thumbHorizontal = document.getElementById('thumbHorizontal');
    const trackHorizontal = document.getElementById('trackHorizontal');

    if (
      thumbHorizontal &&
      this.props.refHorizontalScroll.current &&
      trackHorizontal &&
      this.props.refHorizontalScrollThumb.current
    ) {
      window.eTrack = this.props.refHorizontalScroll.current;

      this.copyStyle(trackHorizontal, this.props.refHorizontalScroll.current, !this.eventsAreSet);
      this.copyStyle(thumbHorizontal, this.props.refHorizontalScrollThumb.current, !this.eventsAreSet);

      this.eventsAreSet = true;
    }
  };

  copyStyle(source, destination, setEvents) {
    destination.setAttribute('style', source.attributes.style.value);

    if (setEvents) {
      [
        'mousedown',
        'mouseup',
        'click',
        'mousemove',
        'mouseover',
        'mousewheel',
        'mouseout',
        'touchstart',
        'touchmove',
        'touchend',
        'touchcancel',
      ].map(event => {
        destination.addEventListener(event, e => {
          source.dispatchEvent(new e.constructor(e.type, e));
          e.preventDefault();
          return false;
        });
      });
    }
  }

  render() {
    const { shadowLeftOpacity, shadowRightOpacity } = this.state;

    return (
      <React.Fragment>
        <Scrollbars
          onUpdate={this.handleUpdate}
          renderTrackHorizontal={this.renderTrackHorizontal}
          renderThumbHorizontal={this.renderThumbHorizontal}
        >
          {this.props.children}
        </Scrollbars>
        {shadowLeftOpacity > 0 && <div className="shadow left" style={{ opacity: shadowLeftOpacity }} />}
        {shadowRightOpacity > 0 && <div className="shadow right" style={{ opacity: shadowRightOpacity }} />}
      </React.Fragment>
    );
  }
}
