import { createMigrate } from 'redux-persist';
import { GetDefaultModalsVisibilityForWorkflow, workflows } from '../../modals/_ModalsMetadata/ModalsMetadata';
import { storage } from '../persistStorage';

const migrations = {
  0: previousVersionState => ({
    modalsVisibility: previousVersionState.modalsVisibility,
    pagesVisibility: {
      ...previousVersionState.pagesVisibility,
      'enroll-patient': {
        ...previousVersionState.pagesVisibility['enroll-patient'],
        pages: previousVersionState.pagesVisibility['enroll-patient'].concat([
          { id: 'enroll-patient-welcome-message', visible: true },
        ]),
      },
    },
  }),
  1: previousVersionState => ({
    modalsVisibility: previousVersionState.modalsVisibility,
    pagesVisibility: {
      ...previousVersionState.pagesVisibility,
      'edit-rpm-schedule': {
        ...previousVersionState.pagesVisibility['edit-rpm-schedule'],
        pages: previousVersionState.pagesVisibility['edit-rpm-schedule'].concat([
          { id: 'schedule-link-program', visible: true },
        ]),
      },
    },
  }),
  2: previousVersionState => ({
    modalsVisibility:
      previousVersionState.modalsVisibility.concat[GetDefaultModalsVisibilityForWorkflow(workflows.setupRpm.id)],
    pagesVisibility: previousVersionState.pagesVisibility,
  }),
};

export const modalsVisibilityConfig = {
  key: 'modalsVisibilityStateReducer',
  version: 2,
  migrate: createMigrate(migrations, { debug: false }),
  storage,
};
