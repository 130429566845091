import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import './style.scss';

class WithLoader extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    children: PropTypes.any,
    disableSpinner: PropTypes.any,
  };

  render() {
    const { isLoading, children } = this.props;

    return (
      <div className="with-loader">
        <div className={`loader-container ${isLoading ? 'loading' : 'loaded'}`}>{children}</div>
        {isLoading && !this.props.disableSpinner ? (
          <div className="spinner-container">
            <div className="spinner" />
          </div>
        ) : null}
      </div>
    );
  }
}

export default WithLoader;
