import React from 'react';

import Strings from '../../../Strings';

export const twoFaEnabled = (two_fa_enabled, onClick) => {
  return (
    <div className="config-container" key="twoFaEnabled">
      <div className="head-item">{Strings.twoFAEnabled}</div>
      <div className="container">
        <div className="flex-container">
          <div className="flex-item">
            <input
              type="checkbox"
              onClick={onClick}
              name="two_fa_enabled"
              id="two_fa_enabled"
              value={two_fa_enabled}
              checked={two_fa_enabled}
            />
            <label key="two_fa_enabled" for="two_fa_enabled">
              {Strings.two_fa_enabled}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export const onPermissionRender = (permissions, onClick) => {
  return (
    permissions &&
    Object.entries(permissions).map(([name, options]) => {
      return (
        <div className="config-container" key={name}>
          <div className="head-item">{name}</div>
          {Object.keys(options).length === 0 && (
            <div>
              <input
                type="checkbox"
                onClick={onClick}
                name={name}
                value={name}
                checked={typeof permissions[name] === 'object' ? false : permissions[name]}
              />
            </div>
          )}
          <div className="container">
            {Object.entries(options).map(([oname, ooptions]) => {
              return (
                <div className="flex-container">
                  <div className="flex-item">
                    <input
                      type="checkbox"
                      onClick={onClick}
                      name={name}
                      id={oname + name}
                      value={oname}
                      checked={typeof permissions[name][oname] === 'object' ? false : permissions[name][oname]}
                    />
                    <label key={oname} for={oname + name}>
                      {oname}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    })
  );
};
