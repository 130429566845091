import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';

import Strings from '../../Strings';

export class Pill extends PureComponent {
  handleMouseEnter = () => {
    const { date, dayOnMouseEnter } = this.props;

    dayOnMouseEnter(date);
  };

  render() {
    const { date, dateDisplay, dayOfWeek, patientId, getDayColumnClass, dayOnMouseLeave, dayData } = this.props;

    let inner = null;

    if (dayData) {
      const width = Math.round((dayData.complied / dayData.total) * 100);

      inner = (
        <div
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            <div className=" adherence-tooltip">
              <div className={`total ${width >= 80 ? 'high' : ''}`}>{width}%</div>
              <div className="date">{dateDisplay}</div>
              <div className="content">
                {Strings.missed}: {dayData.total - dayData.complied}
                <br />
                {Strings.taken}: {dayData.complied}
              </div>
            </div>,
          )}
          data-tooltip-id="tooltip"
          className="pill"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={dayOnMouseLeave}
        >
          <div
            className={`green${width > 90 ? ' full' : ''}${width < 10 ? ' none' : ''}`}
            style={{ width: `${width}%` }}
          />
        </div>
      );
    }

    return <div className={getDayColumnClass(dayOfWeek)}>{inner}</div>;
  }
}

Pill.propTypes = {
  date: PropTypes.any,
  dateDisplay: PropTypes.any,
  dayData: PropTypes.shape({
    complied: PropTypes.any,
    total: PropTypes.any,
  }),
  dayOfWeek: PropTypes.any,
  dayOnMouseEnter: PropTypes.func,
  dayOnMouseLeave: PropTypes.any,
  getDayColumnClass: PropTypes.func,
  patientId: PropTypes.any,
};
