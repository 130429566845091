import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { resetForm } from '../../actions/forms';
import { closeModal } from '../../actions/modal';
import { CodeInput } from '../../components/Inputs/CodeInput';
import LoadingRenderer from '../../components/LoadingRenderer';
import { Modal } from '../../containers';
import Form from '../../containers/Form';
import { ESI_PATIENT_CAP_ID_UPDATE_ERROR } from '../../pages/ESI/PatientLogin/redux/constants';
import Strings from '../../Strings';
import { actions as esiPatientsActions } from '../AddESIPatientModal/redux/actions';
import { actions } from '../ESIPatientUpdateHardwareCapIdModal/redux/actions';
import './AddEditHardwareId.scss';

class AddEditHardwareId extends PureComponent {
  codeInput = React.createRef();
  constructor(props) {
    super(props);
    const cap = props.data?.cap_id;
    this.state = {
      errors: [],
      localCapId: cap || '',
    };
  }
  componentDidMount() {}

  onCodeChange = () => {
    const capValue = this.codeInput.current.getValue();
    this.codeInput.current.value = capValue;
    this.forceUpdate();
  };

  onSave = () => {
    const capId = this.codeInput.current.getValue();
    const { localCapId } = this.state;
    const validationErrors = [];
    const schedule_id = this.props.data?.schedule_id;

    if (capId || localCapId) {
      if (capId && localCapId) {
        validationErrors.push(Strings.errors.enterAnyOne);
        this.setState({ errors: validationErrors });
      } else if (capId && capId.length !== 6) {
        validationErrors.push(Strings.errors.invalidCapId);
        this.setState({ errors: validationErrors });
      } else if (localCapId && localCapId.length >= 32) {
        validationErrors.push(Strings.errors.invalidCapId);
        this.setState({ errors: validationErrors });
      } else {
        this.setState({ errors: [] });
        const cap_id = capId || localCapId;
        this.props.updateCapId(schedule_id, cap_id).then(r => {
          if (r.response && r.response.success && r.response.success.message === 'OK') {
            const patientId = this.props.data?.patient_id;
            this.props.getPatientData(patientId);
            this.props.onCancel();
            return;
          }

          if (r.type === ESI_PATIENT_CAP_ID_UPDATE_ERROR) {
            validationErrors.push(r.response.data.error.message);
            this.setState({ errors: validationErrors });
          }
        });
      }
    } else {
      validationErrors.push(Strings.errorEnterDeviceId);
      this.setState({ errors: validationErrors });
    }
  };

  setError(error) {
    this.setState(state => ({ errors: [...state.errors, error] }));
  }

  clearError() {
    this.setState({ errors: [] });
  }

  // Internal error is linked to outer error from props
  static getDerivedStateFromProps(props) {
    if (props.error) {
      return { errors: [props.error] };
    }

    return null;
  }

  onTextChange = event => {
    this.setState({ [event.target.getAttribute('name')]: event.target.value });
  };

  render() {
    const { onOpen, data, onCancel, AttachCaptoAdminModalLoading, ...props } = this.props;
    const { errors, localCapId } = this.state;
    const errorsStr = errors.map(e => <div style={{ margin: 0 }}>{e}</div>);
    return (
      <Modal name="add-edit-hardware-id" onOpen={onOpen} additionalClasses={['form-modal', 'user-invite']} {...props}>
        <LoadingRenderer>
          <Form onCancel={onCancel} id="add-edit-hardware-id" className="add-hardware-id">
            <div key="admin-email" className="row">
              <label className="t-lable">{Strings.enter6DigitCode}</label>
              <CodeInput ref={this.codeInput} isEsi isCharAllowed onChange={this.onCodeChange} />
            </div>

            <div key="admin-capId" className="row-box">
              <label className="t-lable-blue">{Strings.or}</label>
              <div key="admin-capId"></div>
            </div>
            <div key="admin-capId2" className="row-box1">
              <input
                className="hardware-id"
                onChange={this.onTextChange}
                type="text"
                name="localCapId"
                autoComplete="off"
                value={localCapId}
                placeholder={Strings.capId}
              />
            </div>
            <div key="errors-block" className="errors-block">
              {errorsStr}
            </div>
            <div className="button-bar  reverse" key="button-bar" style={{ marginTop: '70px' }}>
              <button className="brand-blue" key="submit" type="button" onClick={this.onSave}>
                {Strings.save}
              </button>
              <button className="brand-white-gray mr-15" key="cancel" type="cancel">
                {Strings.cancel}
              </button>
            </div>
          </Form>
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapStateToProps = () => ({ AttachCaptoAdminModalLoading: 'NotRequried now' });

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('add-edit-hardware-id')),
  onOpen: () => dispatch(resetForm('add-edit-hardware-id', ownProps.data)),
  updateCapId: (schedule_id, cap_id) => dispatch(actions.updateCapId(schedule_id, cap_id)),
  getPatientData: id => dispatch(esiPatientsActions.getPatient(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditHardwareId);
