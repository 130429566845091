import cloneDeep from 'lodash/cloneDeep';
import { DELETE_PATIENT_RESULT } from '../../../../actions/action-types';
import {
  ADD_PATIENT,
  ADD_PATIENT_ERROR,
  ADD_PATIENT_RESULT,
  ADD_SCHEDULE,
  ADD_SCHEDULE_ERROR,
  ADD_SCHEDULE_RESULT,
  CLEAR_DEVICE_DATA,
  DELETE_CAP,
  DELETE_CAP_ERROR,
  DELETE_CAP_FROM_SCHEDULE,
  DELETE_CAP_FROM_SCHEDULE_RESULT,
  DELETE_CAP_RESULT,
  DELETE_SCHEDULE,
  DELETE_SCHEDULE_RESULT,
  EDIT_SCHEDULE,
  EDIT_SCHEDULE_ERROR,
  EDIT_SCHEDULE_RESULT,
  END_TASK,
  END_TASK_ERROR,
  END_TASK_RESULT,
  EVENT_LIST,
  EVENT_LIST_ERROR,
  EVENT_LIST_RESULT,
  GET_CAP_DETAILS,
  GET_CAP_DETAILS_ERROR,
  GET_CAP_DETAILS_RESULT,
  GET_DEVICES,
  GET_DEVICES_DETAILS,
  GET_DEVICES_DETAILS_ERROR,
  GET_DEVICES_DETAILS_RESULT,
  GET_DEVICES_ERROR,
  GET_DEVICES_RESULT,
  GET_MEDICATIONS,
  GET_MEDICATIONS_ERROR,
  GET_MEDICATIONS_RESULT,
  GET_TASKS_LIST,
  GET_TASKS_LIST_ERROR,
  GET_TASKS_LIST_RESULT,
  PATIENT_DETAILS,
  PATIENT_DETAILS_RESULT,
  PATIENT_LIST,
  PATIENT_LIST_CLEAR,
  PATIENT_LIST_RESULT,
  START_TASK,
  START_TASK_ERROR,
  START_TASK_RESULT,
  TASK_HISTORY,
  TASK_HISTORY_ERROR,
  TASK_HISTORY_RESULT,
  GET_NOTES,
  GET_NOTES_RESULT,
  GET_NOTES_ERROR,
  PATIENT_BILLABLE_TIME_RESULT,
  PATIENT_LIST_FOR_SUGGESTION_RESULT,
  PATIENT_LIST_FOR_SUGGESTION_CLEAR,
  GET_CONDITION_LIST,
  GET_CONDITION_LIST_ERROR,
  GET_CONDITION_LIST_RESULT,
  GET_READINGS,
  GET_READINGS_RESULT,
  GET_READINGS_ERROR,
  PATIENT_DETAILS_CLEAR,
} from './constants';

export const CapPatientsReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_SCHEDULE:
    case ADD_SCHEDULE:
    case START_TASK:
    case GET_TASKS_LIST:
    case ADD_PATIENT:
    case DELETE_CAP:
    case GET_DEVICES_DETAILS:
    case GET_CAP_DETAILS:
      return {
        ...state,
        isLoading: true,
      };

    case END_TASK:
      return {
        ...state,
        isEndTaskLoading: true,
      };

    case GET_MEDICATIONS:
      return {
        ...state,
        patientMedications: [],
        isPatientMedicationsLoading: true,
      };

    case PATIENT_LIST:
      return {
        ...state,
        data: undefined,
        isLoading: true,
      };

    case EDIT_SCHEDULE_ERROR:
    case ADD_SCHEDULE_ERROR:
    case START_TASK_ERROR:
    case GET_TASKS_LIST_ERROR:
    case ADD_PATIENT_ERROR:
    case DELETE_CAP_ERROR:
    case GET_DEVICES_DETAILS_ERROR:
    case GET_CAP_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        isEndTaskLoading: false,
      };
    case GET_MEDICATIONS_ERROR:
      return {
        ...state,
        isPatientMedicationsLoading: false,
      };

    case EDIT_SCHEDULE_RESULT:
    case ADD_SCHEDULE_RESULT:
    case ADD_PATIENT_RESULT:
      return {
        ...state,
        isLoading: false,
      };
    case START_TASK_RESULT:
      return {
        ...state,
        isLoading: false,
      };
    case END_TASK_RESULT:
      return {
        ...state,
        isEndTaskLoading: false,
        endTaskData: action.response,
      };

    case END_TASK_ERROR:
      return {
        ...state,
        isEndTaskLoading: false,
      };

    case PATIENT_LIST_RESULT:
      return {
        ...state,
        isLoading: false,
        data: action.response,
      };

    case EVENT_LIST:
      return {
        ...state,
        isPatientEventsLoading: true,
      };

    case EVENT_LIST_ERROR:
      return {
        ...state,
        isPatientEventsLoading: false,
      };

    case EVENT_LIST_RESULT:
      return {
        ...state,
        isPatientEventsLoading: false,
        patientEvents: action.response,
        pagination: action.response.pagination,
      };

    case GET_DEVICES_DETAILS_RESULT:
      return {
        ...state,
        isLoading: false,
        patientDeviceDetails: action.response,
        pagination: action.response.pagination,
      };

    case GET_CAP_DETAILS_RESULT:
      return {
        ...state,
        isLoading: false,
        patientCapDetails: action.response,
        pagination: action.response.pagination,
      };

    case GET_TASKS_LIST_RESULT:
      return {
        ...state,
        isLoading: false,
        taskList: action.response,
      };
    case PATIENT_BILLABLE_TIME_RESULT:
      return {
        ...state,
        isLoading: false,
        taskStatus: {
          loggedTime: action.response.billable_time,
          goal: action.response.billable_time < 20 * 60 ? 20 : action.response < 40 * 60 ? 40 : 60,
        },
      };
    case PATIENT_DETAILS:
      return {
        ...state,
        isLoading: true,
      };
    case PATIENT_DETAILS_RESULT:
      return {
        ...state,
        isLoading: false,
        patientDetails: action.response,
      };
    case PATIENT_DETAILS_CLEAR:
      return {
        ...state,
        patientDetails: undefined,
      };
    case GET_MEDICATIONS_RESULT:
      return {
        ...state,
        isPatientMedicationsLoading: false,
        patientMedications: action.response,
      };

    case TASK_HISTORY:
      return {
        ...state,
        isTaskHistoryLoading: true,
      };

    case TASK_HISTORY_ERROR:
      return {
        ...state,
        isTaskHistoryLoading: false,
      };
    case TASK_HISTORY_RESULT:
      return {
        ...state,
        isTaskHistoryLoading: false,
        taskHistory: action.response,
        pagination: action.response.pagination,
      };

    case GET_DEVICES:
      return {
        ...state,
        isPatientDevicesLoading: true,
      };

    case GET_DEVICES_ERROR:
      return {
        ...state,
        isPatientDevicesLoading: false,
      };

    case GET_DEVICES_RESULT:
      return {
        ...state,
        isPatientDevicesLoading: false,
        patientDevices: action.response,
      };

    case PATIENT_LIST_CLEAR:
      return {
        ...state,
        isLoading: false,
        data: undefined,
      };
    case PATIENT_LIST_FOR_SUGGESTION_RESULT:
      return {
        ...state,
        patientsForSuggestion: action.response.data,
      };
    case PATIENT_LIST_FOR_SUGGESTION_CLEAR:
      return {
        ...state,
        patientsForSuggestion: [],
      };

    case DELETE_CAP_FROM_SCHEDULE:
    case DELETE_SCHEDULE:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_PATIENT_RESULT: {
      if (action.id) {
        const { data } = state;

        let updatedPatients = cloneDeep(data);
        updatedPatients.data = data?.data.filter(patient => {
          return patient.id !== action.id;
        });

        return {
          ...state,
          data: updatedPatients,
          isLoading: false,
        };
      }
    }
    case DELETE_SCHEDULE_RESULT: {
      if (action.id) {
        const { data } = state;

        let updatedSchedule = cloneDeep(data);
        updatedSchedule.data = data?.data.filter(cap => {
          return cap.id !== action.id;
        });

        return {
          ...state,
          data: updatedSchedule,
          isLoading: false,
        };
      }
    }

    case DELETE_CAP_RESULT: {
      if (action.id) {
        const { patientDevices } = state;

        let updateDevices = cloneDeep(patientDevices);
        updateDevices.data = patientDevices?.data.filter(cap => {
          return cap.cap_id !== action.id;
        });

        return {
          ...state,
          patientDevices: updateDevices,
          isLoading: false,
        };
      }
    }

    case DELETE_CAP_FROM_SCHEDULE_RESULT: {
      if (action.capId) {
        const { scheduleDetails } = state;

        let updatedScheduleDetails = cloneDeep(scheduleDetails);
        updatedScheduleDetails.caps = scheduleDetails?.caps.filter(cap => {
          return cap.cap_id !== action.capId;
        });

        return {
          ...state,
          scheduleDetails: updatedScheduleDetails,
          isLoading: false,
        };
      }
    }

    case CLEAR_DEVICE_DATA:
      return {
        ...state,
        isLoading: false,
        patientDevices: CLEAR_DEVICE_DATA,
      };

    case GET_NOTES:
      return {
        ...state,
        notes: [],
        isLoading: true,
      };

    case GET_NOTES_RESULT:
      return {
        ...state,
        notes: action.response.data,
        isLoading: false,
      };

    case GET_NOTES_ERROR:
      return {
        ...state,
        notes: [],
        isLoading: false,
      };

    case GET_CONDITION_LIST:
      return {
        ...state,
        conditions: [],
        isLoading: true,
      };

    case GET_CONDITION_LIST_RESULT:
      return {
        ...state,
        conditions: action.response.data,
        //pagination: action.response.pagination,
        isLoading: false,
      };

    case GET_CONDITION_LIST_ERROR:
      return {
        ...state,
        conditions: [],
        isLoading: false,
      };

    case GET_READINGS:
      return {
        ...state,
        readings: [],
        isLoading: true,
      };

    case GET_READINGS_RESULT:
      return {
        ...state,
        readings: action.response.data,
        pagination: action.response.pagination,
        isLoading: false,
      };

    case GET_READINGS_ERROR:
      return {
        ...state,
        readings: [],
        isLoading: false,
      };

    default:
      return state;
  }
};
