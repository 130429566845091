import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
  BarChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Bar,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { DATE_FORMAT_YEAR_MONTH_DAY } from '../../constants';
// import Strings from '../../Strings';
import { GRAPH_AXIS_LINE_COLOR, GRAPH_AXIS_LINE_WIDTH } from './GraphConstants';
import './graph.scss';
import { CustomReferenceArea, CustomizedXAxisTickHourly } from './GraphComponents';

const sAwake = 'Awake';
const sREM = 'REM';
const sLight = 'Light';
const sDeep = 'Deep';
const sLightSleep = 'Light Sleep';
const sDeepSleep = 'Deep Sleep';

const graphAlternateRowBackground = '#F4F8FC';
const graphRowBackground = '#fff';
const graphAxisFontSize = 12;
const graphAxisFontColor = '#000';
const graphYAxisFontColor = '#4F4F4F';
const graphAxisFontColorWeekend = '#bbb';
const graphAxisPaddingLR = 30;
const graphAxisPaddingTB = 30;

const graphDotColorLightSleep = '#5EC4FC';
const graphDotColorDeepSleep = '#645EFC';
const graphDotColorRemSleep = '#FC5EDC';
const graphDotColorNoSleep = '#FCAC5E';
const graphMaxBarSize = 10;
const graphBarRadius = 10;

const graphReferenceVerticalLineColor = '#eeeeee';

class CustomizedXAxisTick extends PureComponent {
  render() {
    const { x, y, payload, axisMin, date, axisLine } = this.props; // stroke
    const hour = payload.value >= 0 ? payload.value : payload.value + 24;

    return (
      <g transform={`translate(${x},${y})`}>
        {payload.value === axisMin && (
          <rect
            y={-y}
            x={0 - payload.offset - axisLine.strokeWidth / 2 - graphAxisPaddingLR}
            height={y + 2}
            width={axisLine.strokeWidth}
            fill={axisLine.stroke}
          />
        )}
        <text className="span" fontSize={graphAxisFontSize} dy={-30} textAnchor="middle" fill={graphAxisFontColor}>
          {hour}
        </text>

        {payload.value === 0 && (
          <text
            className="span"
            fontSize={graphAxisFontSize}
            dy={-50}
            dx={10}
            textAnchor="start"
            fill={graphAxisFontColorWeekend}
          >
            {date.format('MMM DD').toUpperCase()}
          </text>
        )}

        {payload.value === 0 && (
          <text
            className="span"
            fontSize={graphAxisFontSize}
            dy={-10}
            dx={10}
            textAnchor="start"
            fill={graphAxisFontColorWeekend}
          >
            AM
          </text>
        )}

        {(payload.value === 12 || payload.value === -12) && (
          <text
            className="span"
            fontSize={graphAxisFontSize}
            dy={-10}
            dx={10}
            textAnchor="start"
            fill={graphAxisFontColorWeekend}
          >
            PM
          </text>
        )}

        {payload.value === axisMin && axisMin < 0 && (
          <text
            className="span"
            fontSize={graphAxisFontSize}
            dy={-10}
            dx={10}
            textAnchor="start"
            fill={graphAxisFontColorWeekend}
          >
            PM
          </text>
        )}

        {payload.value === axisMin && (
          <text
            className="span"
            fontSize={graphAxisFontSize}
            dy={-37}
            dx={-graphAxisPaddingLR - 40}
            textAnchor="middle"
            fill={graphAxisFontColorWeekend}
          >
            {date.format('ddd,').toUpperCase()}
          </text>
        )}
        {payload.value === axisMin && (
          <text
            className="span"
            fontSize={graphAxisFontSize}
            dy={-23}
            dx={-graphAxisPaddingLR - 40}
            textAnchor="middle"
            fill={graphAxisFontColorWeekend}
          >
            {date.format('MMM DD').toUpperCase()}
          </text>
        )}
      </g>
    );
  }
}
CustomizedXAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.any,
  axisMin: PropTypes.number,
  date: PropTypes.object,
  axisLine: PropTypes.any,
};

class CustomizedYAxisTick extends PureComponent {
  render() {
    const { x, y, payload, axisMin, axisLine } = this.props; // stroke
    const value =
      payload.value === 'a'
        ? sAwake
        : payload.value === 'r'
        ? sREM
        : payload.value === 'l'
        ? sLight
        : payload.value === 'd'
        ? sDeep
        : '';

    return (
      <g transform={`translate(${x},${y})`}>
        {payload.value === axisMin && (
          <rect
            x={-x}
            y={0 - payload.offset - axisLine.strokeWidth / 2 - graphAxisPaddingTB}
            width={x + 2}
            height={axisLine.strokeWidth}
            fill={axisLine.stroke}
          />
        )}
        <text
          className="span"
          fontSize={graphAxisFontSize}
          dy={5}
          dx={-60}
          textAnchor="start"
          fill={graphYAxisFontColor}
        >
          {value}
        </text>
      </g>
    );
  }
}
CustomizedYAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.any,
  payload: PropTypes.any,
  axisLine: PropTypes.any,
  axisMin: PropTypes.string,
};

export default class SleepGraphHourly extends PureComponent {
  calcXAxisParams(rawData, date) {
    const thisDay = date.format(DATE_FORMAT_YEAR_MONTH_DAY);
    const sleepStartHours = rawData.map(x =>
      x.sleepStart.format(DATE_FORMAT_YEAR_MONTH_DAY) === thisDay ? x.sleepStartHour : x.sleepStartHour - 24,
    );
    const dataMin = Math.min(...sleepStartHours);
    const dataMax = Math.max(...rawData.map(x => x.sleepEndHour));

    const axisMin = dataMin >= 0 ? dataMin - (dataMin % 1) : dataMin + (-dataMin % 1) - 1;
    const axisMax = dataMax - (dataMax % 1) + 1;
    const axisDelta = axisMax - axisMin;

    return {
      dataMin,
      dataMax,
      axisMin,
      axisMax,
      axisTick: 1,
      axisTicks: axisDelta + 1,
    };
  }

  calcParts(rawData, date) {
    const parts = [];
    let prevSleepEnd = 0;

    rawData.forEach(sleep => {
      const startToday =
        date.format(DATE_FORMAT_YEAR_MONTH_DAY) === sleep.sleepStart.format(DATE_FORMAT_YEAR_MONTH_DAY);
      const start = sleep.sleepStart.hours() + sleep.sleepStart.minutes() / 60 - (startToday ? 0 : 24);
      const end = sleep.sleepEnd.hours() + sleep.sleepEnd.minutes() / 60;
      const diff = start - prevSleepEnd;
      prevSleepEnd = end;

      parts.push({ d: diff, t: ' ' });
      parts.push(
        ...sleep.parts.map(p => {
          return { d: p.duration / 60, t: p.depth[0] };
        }),
      );
    });

    return [
      { y: 'a', xs: parts },
      { y: 'r', xs: parts },
      { y: 'l', xs: parts },
      { y: 'd', xs: parts },
    ];
  }

  calcMaxParts(rawData) {
    let max = rawData.length - 1;
    rawData.forEach(sleep => {
      max += sleep.parts.length;
    });

    return max;
  }

  render() {
    const { graphData, date, selectedPhase } = this.props;

    const rawData = graphData;
    const xAxixParams = this.calcXAxisParams(rawData, date);
    const partsData = this.calcParts(rawData, date);
    const maxParts = partsData[0].xs.length;

    const CustomTooltip = ({ active, label }) => {
      if (active && rawData) {
        const payload = rawData;

        if (payload && payload.length > 0) {
          const labelFull =
            label === 'a'
              ? sAwake
              : label === 'r'
              ? sREM
              : label === 'l'
              ? sLightSleep
              : label === 'd'
              ? sDeepSleep
              : '?';

          return (
            <div className="customTooltip">
              <div>
                <p className="customTooltipTitle">
                  {labelFull}
                  <br />
                  {moment(payload[0].date).format('ll')}
                </p>
                <div className="customTooltipDescr">Some details... Maybe length of {labelFull}</div>
              </div>
            </div>
          );
        }
      }
      return null;
    };
    return (
      <div className="graphDiv withBorder">
        <ResponsiveContainer width="100%" height={340}>
          <BarChart
            layout="vertical"
            data={partsData}
            defaultShowTooltip={false}
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            {/* <Tooltip content={<CustomTooltip />} dataKey="y" isAnimationActive={false} /> */}

            {/* reference areas to mimic alternate rows */}

            <ReferenceArea
              y1="a"
              y2="a"
              stroke={graphAlternateRowBackground}
              fill={graphAlternateRowBackground}
              fillOpacity="1"
              key={`refarea_first}`}
              shape={<CustomReferenceArea isUpper />}
            />
            <ReferenceArea
              y1="a"
              y2="a"
              stroke={graphRowBackground}
              fill={graphRowBackground}
              fillOpacity="1"
              key={`refarea_a}`}
              shape={<CustomReferenceArea />}
            />
            <ReferenceArea
              y1="r"
              y2="r"
              stroke={graphAlternateRowBackground}
              fill={graphAlternateRowBackground}
              fillOpacity="1"
              key={`refarea_r}`}
              shape={<CustomReferenceArea />}
            />
            <ReferenceArea
              y1="l"
              y2="l"
              stroke={graphRowBackground}
              fill={graphRowBackground}
              fillOpacity="1"
              key={`refarea_l}`}
              shape={<CustomReferenceArea />}
            />
            <ReferenceArea
              y1="d"
              stroke={graphAlternateRowBackground}
              fill={graphAlternateRowBackground}
              fillOpacity="1"
              key={`refarea_d}`}
              shape={<CustomReferenceArea />}
            />

            <XAxis
              type="number"
              height={70}
              interval={0}
              name="hours"
              orientation="top"
              tickLine={false}
              domain={[xAxixParams.axisMin, xAxixParams.axisMax]}
              tickCount={xAxixParams.axisTicks}
              tickSize={0}
              tick={
                <CustomizedXAxisTickHourly
                  axisMin={xAxixParams.axisMin}
                  date={date}
                  axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
                />
              }
              axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
              padding={{ left: graphAxisPaddingLR, right: graphAxisPaddingLR }}
              allowDataOverflow
            />
            <YAxis
              type="category"
              allowDuplicatedCategory={false}
              dataKey="y"
              tick={
                <CustomizedYAxisTick
                  axisMin="a"
                  day={date}
                  axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
                />
              }
              tickSize={0}
              width={80}
              axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
              padding={{ top: graphAxisPaddingTB, bottom: graphAxisPaddingTB }}
            />

            {/* every 4 hour vertical lines */}
            {Array.from({ length: xAxixParams.axisMax - xAxixParams.axisMin }, (_, i) => i).map(
              i =>
                i % 4 === 0 && (
                  <ReferenceLine x={i} label="" stroke={graphReferenceVerticalLineColor} key={`vLine${i}`} />
                ),
            )}

            {Array.from({ length: maxParts }, (_, i) => i).map(i => (
              <Bar
                key={`bar_${i}`}
                tooltipType="none"
                dataKey={`xs[${i}].d`}
                stackId="stack0"
                radius={graphBarRadius}
                fillOpacity={1}
                maxBarSize={graphMaxBarSize}
                onClick={e => this.onClick(e)}
              >
                {partsData.map((entry, index) => {
                  const color =
                    entry.y === 'l'
                      ? graphDotColorLightSleep
                      : entry.y === 'd'
                      ? graphDotColorDeepSleep
                      : entry.y === 'r'
                      ? graphDotColorRemSleep
                      : graphDotColorNoSleep;
                  const opacity = !selectedPhase
                    ? '100%'
                    : selectedPhase === 'awake' && entry.y !== 'a'
                    ? '10%'
                    : selectedPhase === 'rem' && entry.y !== 'r'
                    ? '10%'
                    : selectedPhase === 'light' && entry.y !== 'l'
                    ? '10%'
                    : selectedPhase === 'deep' && entry.y !== 'd'
                    ? '10%'
                    : '100%';
                  const visibility = entry.y !== entry.xs[i].t ? 'hidden' : 'visible';
                  return (
                    <Cell key={`cell-${index}`} stroke={color} fill={color} visibility={visibility} opacity={opacity} />
                  );
                })}
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

SleepGraphHourly.propTypes = {
  graphData: PropTypes.array,
  date: PropTypes.object,
  selectedPhase: PropTypes.string,
};
