import { schema } from 'normalizr';
import _ from 'lodash';

export const GroupMembership = new schema.Entity('memberships');
export const Event = new schema.Entity('events');
export const Schedule = new schema.Entity('schedules');
export const Pagination = new schema.Entity('pagination', undefined, { idAttribute: () => 'pagination' });

export const Notification = new schema.Entity('notifications', { schedule: Schedule });
export const PatientSchema = new schema.Entity('patients', undefined, { idAttribute: () => 'objects' });

export const OverViewSchema = new schema.Entity('overview', undefined, { idAttribute: () => 'objects' });

export const last7DaysAdherenceSchema = new schema.Entity('last7DayAdherence', undefined, {
  idAttribute: value => value.date,
});

export const StudiesSchema = new schema.Entity('studies', undefined, { idAttribute: () => 'objects' });

export const Doctor = new schema.Entity('doctors');
export const Trial = new schema.Entity('trials', { studies: [StudiesSchema] });

export const Analytics = new schema.Entity('analytics');

export const PatientAnalytics = new schema.Entity('patients', undefined, { idAttribute: () => 'analytics' });

export const EventLocation = new schema.Entity('eventLocations');

export const PatientMedication = new schema.Entity('patientMedications');
export const PatientSchedule = new schema.Entity('patientSchedules');
export const PatientNotification = new schema.Entity('patientNotifications', { schedule: Schedule });

const parseNotification = notification => {
  return {
    ...notification,
    notification_at: new Date(Date.parse(notification.notification_at)),
  };
};

const parseEvent = event => {
  return {
    ...event,
    eventTimestamp: new Date(Date.parse(event.event_timestamp)),
  };
};

const parseObjects = parser => objects => {
  return _.mapValues(objects, parser);
};

export const EntityParsers = {
  notifications: parseObjects(parseNotification),
  events: parseObjects(parseEvent),
};
