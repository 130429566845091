import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import DeviceReadingsGraph from '../../../../components/Graphs/DeviceReadingsGraph';
import { actions } from './redux/actions';
import '../Widgets.scss';
import Strings from '../../../../Strings';

function DeviceReadings(props) {
  useEffect(() => {
    props.getData(props.timezone);
  }, [props.refreshTimestamp]);

  const { readings, timezone } = props;
  const graphData =
    readings &&
    readings.map(r => {
      return {
        date: moment(r.date * 1000).tz(timezone),
        hubs: r.hub_readings,
        scales: r.wm_readings,
        bpMonitors: r.bpm_readings,
      };
    });

  const hubsReadingsCount =
    graphData && graphData.length > 0 && graphData.map(item => item.hubs).reduce((prev, next) => prev + next);
  const wmReadingsCount =
    graphData && graphData.length > 0 && graphData.map(item => item.scales).reduce((prev, next) => prev + next);
  const bpmReadingsCount =
    graphData && graphData.length > 0 && graphData.map(item => item.bpMonitors).reduce((prev, next) => prev + next);

  return (
    <div className="boxStatsWithGraph">
      <div className="boxStatsWithGraph-title">{Strings.deviceReadings}</div>
      <div className="boxStatsWithGraph-hl" />
      <div className="boxStatsWithGraph-stats">
        <div className="boxStatsWithGraph-statsBoxOf3 mr">
          <div className="boxStatsWithGraph-statValue colorGreen">{hubsReadingsCount}</div>
          <div className="boxStatsWithGraph-statLabel">{Strings.devicesPlural.hub}</div>
        </div>
        <div className="boxStatsWithGraph-statsVl" />
        <div className="boxStatsWithGraph-statsBoxOf3 ml">
          <div className="boxStatsWithGraph-statValue colorBlue">{wmReadingsCount}</div>
          <div className="boxStatsWithGraph-statLabel">{Strings.scales}</div>
        </div>
        <div className="boxStatsWithGraph-statsVl" />
        <div className="boxStatsWithGraph-statsBoxOf3 ml">
          <div className="boxStatsWithGraph-statValue colorOrange">{bpmReadingsCount}</div>
          <div className="boxStatsWithGraph-statLabel">{Strings.bpMonitors}</div>
        </div>
      </div>
      <div className="boxStatsWithGraph-hl" />
      <div className="boxStatsWithGraph-graph">
        {graphData && graphData.length > 0 && <DeviceReadingsGraph graphData={graphData} />}
      </div>
    </div>
  );
}

DeviceReadings.widgetId = 'DeviceReadingsWidget';

DeviceReadings.propTypes = {
  getData: PropTypes.func,
  readings: PropTypes.array,
  isLoading: PropTypes.any,
  timezone: PropTypes.string,
  refreshTimestamp: PropTypes.any.isRequired,
};

const mapStateToProps = state => {
  const { widgetDevicesReadingsReducer } = state.dashboardWidgets;
  return {
    readings: widgetDevicesReadingsReducer && widgetDevicesReadingsReducer?.readings,
    isLoading: widgetDevicesReadingsReducer && widgetDevicesReadingsReducer?.isLoading,
    timezone: state.auth?.profile?.preferences?.timezone,
  };
};

const mapDispatchToProps = dispatch => ({
  getData: timezone => dispatch(actions.getDevicesReadingsCount(timezone)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceReadings);
