import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';

import Strings from '../../../Strings';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import { patientAction } from '../../../actions/patient';
import { DATE_FORMAT_MONTH_YEAR, PAGE_LIMIT } from '../../../constants';
import { clearEntitiesAction } from '../../../actions';
import { trialsAction } from '../../../actions/trials';
import { openModalAction } from '../../../actions/modal';

const getAdherencePercentage = patient => {
  if (patient.analytics && patient.analytics) {
    return `${patient.analytics.adherence}%`;
    // return getAdherenceColored(patient.analytics.adherence);
  }
  return 'N/A';
};

class StudyPatientsTab extends PureComponent {
  static propTypes = {
    dateRange: PropTypes.any,
    trialId: PropTypes.any,
    clearEntities: PropTypes.func,
    loadPatients: PropTypes.func,
    pagination: PropTypes.object,
    patients: PropTypes.any,
    onNavigate: PropTypes.func,
    openConfirmModal: PropTypes.func,
    isLoading: PropTypes.bool,
  };
  constructor() {
    super();
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.dateRange !== nextProps.dateRange) {
      const { dateRange, trialId } = nextProps;
      this.getPatients(dateRange, trialId, false);
    }
  }

  UNSAFE_componentWillMount() {
    this.props.clearEntities();
  }

  componentDidMount() {
    const { dateRange, trialId } = this.props;
    this.getPatients(dateRange, trialId, true);
  }

  getPatients(dateRange, trialId, isLoading) {
    this.pageRequest = {
      startDate: dateRange.infimum,
      endDate: dateRange.supremum,
      offset: 0,
      trialId,
      search: '',
    };
    this.props.loadPatients(this.pageRequest, isLoading);
  }

  onPrevClick() {
    const { pagination } = this.props;
    const offset = pagination.offset - PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.loadPatients(this.pageRequest, true);
  }

  onNextClick() {
    const { pagination } = this.props;
    const offset = pagination.offset + PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.loadPatients(this.pageRequest, true);
  }

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.loadPatients(this.pageRequest, true);
  };

  onSortClick({ sortKey, direction }) {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    this.props.loadPatients(this.pageRequest, false);
  }
  onRowSelected(id) {
    const { patients, trialId } = this.props;
    if (patients && patients[id].id && patients[id].status && trialId) {
      this.props.onNavigate(`/study/${trialId}/patient/${patients[id].id}/overview`);
    }
  }

  onPatientDelete = (event, patient) => {
    event.preventDefault();
    event.stopPropagation();
    const data = {
      title: (
        <span>
          {Strings.deletePatientWarning} <b>{patient.fullName}</b>?
        </span>
      ),
      onConfirmAction: trialsAction.actionDeletePatient(this.props.trialId, patient.code),
      onCancelAction: null,
    };
    this.props.openConfirmModal(data);
  };

  render() {
    const { isLoading, patients, pagination } = this.props;

    const getAdherenceLine = adherence => {
      const average = getAdherencePercentage(adherence);
      const perfectHundredStyle = average === '100%' ? 'perfect' : 'active';
      const averageWidthStyle = { width: average };
      return (
        <div>
          <div className="averageText">{average}</div>
          <div className="average">
            <div className={perfectHundredStyle} style={averageWidthStyle} />
          </div>
        </div>
      );
    };
    return (
      <Table
        name="study-patients"
        data={patients}
        onPrevClick={this.onPrevClick}
        onSortClick={this.onSortClick}
        onNextClick={this.onNextClick}
        onCustomPage={this.onCustomPage}
        onRowSelection={this.onRowSelected}
        pagination={pagination}
        isLoading={isLoading}
        tableName="patient-table"
      >
        <Column key="id" title="ID" value={d => d.id} sortKey="id" />
        <Column
          key="name"
          title={Strings.fullName}
          value={d => (d.fullName.trim() ? d.fullName : `Unnamed Patient ${d.id}`)}
          sortKey="fullName"
        />
        <Column
          key="dob"
          title={Strings.dateOfBirth}
          value={d => (d?.dateOfBirth ? moment(d.dateOfBirth).format(DATE_FORMAT_MONTH_YEAR) : '-')}
          sortKey="dateOfBirth"
        />
        <Column
          key="adherence"
          title={Strings.adherence}
          value={d => d.analytics && getAdherenceLine(d)}
          sortKey="adherence"
        />
        <Column key="missed" title={Strings.missed} value={d => d.analytics && d.analytics.missed} sortK ey="missed" />
        <Column
          key="buttons"
          value={patient => (
            <React.Fragment>
              <button className="white delete" onClick={e => this.onPatientDelete(e, patient)}>
                <div className="delete-img"></div>
              </button>
              {patient.isLoading ? (
                <div className="table-row-spinner">
                  <div className="spinner-row" />
                </div>
              ) : null}
            </React.Fragment>
          )}
        />
      </Table>
    );
  }
}

const mapStateToProps = state => {
  const { patients } = state.entities;
  const { dateFilter } = state.filter;
  const dateRange = dateFilter || {
    infimum: moment()
      .subtract(1, 'month')
      .toDate(),
    supremum: moment().toDate(),
  };
  return {
    dateRange,
    isLoading: patients.loading,
    patients: patients.patients,
    pagination: patients.pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  loadPatients: (patientRequest, isLoading) => dispatch(patientAction.actionList(patientRequest, isLoading)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  clearEntities: (entities = null) => {
    dispatch(clearEntitiesAction(entities || ['patients.patients']));
  },
  onNavigate: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudyPatientsTab);
