import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { isBrowser, isMobile } from 'react-device-detect';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { TextInput } from '../../../components/PageHeader/TextInput';
import { DATE_MONTH_DAY_YEAR_WITH_DASH, TIME_FORMAT_0_24 } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import './patient.scss';
import { actions } from './redux/actions';
import { actions as kitActions } from '../../Kits/redux/actions';
import { PageHeader } from '../../../components/PageHeader';
import ListOfTables from '../../../containers/ListOfTables';
import { calculateDeviceId, navigateToDevice } from './PatientDevices';
import { hasPermission, PERMISSIONS } from '../../../utils/userPermissions';

function PatientDevicesHistory(props) {
  const searchTime = React.useRef();
  const pageRequest = React.useRef({
    offset: 0,
    search: '',
    limit: isMobile ? 100 : undefined,
    status: 'deleted',
  });
  const patientId = props.patientId;

  useEffect(() => {
    props.getDevices(pageRequest.current, patientId);
    if (!props.kit?.id) {
      props.getPatientKitInfo(patientId);
    }
    return () => {
      props.clearDevice();
    };
  }, [patientId]);

  const onSearchQueryChange = query => {
    pageRequest.current.offset = 0;
    pageRequest.current.search = query;
    clearTimeout(searchTime.current);
    searchTime.current = setTimeout(() => {
      props.getDevices(pageRequest.current, patientId);
    }, 1000);
  };

  const onOffsetChange = (offset, limit) => {
    pageRequest.current.offset = offset;
    if (limit) {
      pageRequest.current.limit = limit;
    }
    props.getDevices(pageRequest.current, patientId);
  };

  const onSortClick = ({ sortKey, direction }) => {
    pageRequest.current.offset = 0;
    pageRequest.current.sortColumn = sortKey;
    pageRequest.current.sortType = direction;

    props.getDevices(pageRequest.current, patientId);
  };

  const getHeader = () => {
    const { isLoading, pagination, patientDevices } = props;
    const headerTitle =
      !isLoading && patientDevices && pagination
        ? Strings.formatString(
            Strings.showingXDevices,
            patientDevices.length,
            pagination.totalRecords ? pagination.totalRecords : 0,
          )
        : Strings.showingWait;
    return (
      <React.Fragment>
        <PageHeader
          isBlack
          left={headerTitle}
          right={
            <React.Fragment>
              <TextInput
                class="search"
                placeholder={Strings.search}
                onChange={e => onSearchQueryChange(e.target.value)}
              />
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  };

  const onRowSelected = id => {
    const device = props.patientDevices[id];
    navigateToDevice(device, `/cap-patients/${patientId}/devices-history`, patientId, props.onNavigate);
  };

  const renderDeviceId = device => {
    const id = device.deviceId ? calculateDeviceId(device) : device.cap_id;
    return id;
  };

  const columns = [
    <Column
      key="cap_id"
      title={Strings.deviceId}
      value={d => renderDeviceId(d)}
      sortKey="cap_id"
      className="clickable"
    />,
    <Column key="cap_name" title={Strings.deviceModel} value={d => `${d.manufacturer} ${d.model}`} />,
    <Column key="deviceType" title={Strings.deviceType} value={d => d.deviceType} />,
    <Column
      key="activationDate"
      title={Strings.assignedDate}
      value={d =>
        d.activationDate && d.activationDate !== 'unknown date'
          ? moment(d.activationDate * 1000).format(DATE_MONTH_DAY_YEAR_WITH_DASH)
          : '?'
      }
    />,
    <Column
      key="unassignedAt"
      title={Strings.unassignedDate}
      value={d =>
        d.unassignedAt && d.unassignedAt !== 'unknown date'
          ? moment(d.unassignedAt).format(DATE_MONTH_DAY_YEAR_WITH_DASH)
          : '?'
      }
    />,
  ];

  const { isLoading, pagination, patientDevices } = props;

  return (
    <OverlayScrollbarsComponent
      defer
      className="scrollbar-right-margin"
      options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
    >
      {getHeader()}
      {isBrowser && props.kit?.id && (
        <Table
          isLoading={isLoading}
          name="patient-deviceshistory"
          data={patientDevices || []}
          onRowSelection={hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_DEVICE_DETAILS) ? onRowSelected : undefined}
          onOffsetChange={onOffsetChange}
          onSortClick={onSortClick}
          pagination={
            pagination || {
              offset: 0,
              total: 0,
            }
          }
        >
          {columns}
        </Table>
      )}
      {isMobile && props.kit?.id && (
        <React.Fragment>
          <ListOfTables
            isLoading={isLoading}
            data={patientDevices || []}
            title={row => row.deviceType}
            rows={columns}
          />
        </React.Fragment>
      )}
    </OverlayScrollbarsComponent>
  );
}

PatientDevicesHistory.propTypes = {
  clearDevice: PropTypes.func,
  getDevices: PropTypes.func,
  isLoading: PropTypes.any,
  onNavigate: PropTypes.func,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
  patientDevices: PropTypes.array,
  patientId: PropTypes.any,
};

const mapStateToProps = state => {
  const { patients } = state.superUser;

  return {
    patientDevices: patients && patients?.patientDevices?.data,
    isLoading: patients && patients?.isPatientDevicesLoading,
    pagination: patients && patients?.patientDevices?.pagination,
    kit: state.entities.kits?.patientKit,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onNavigate: path => dispatch(push(path)),
  getDevices: (pageRequest, patientId) => dispatch(actions.getDevices(pageRequest, patientId)),
  clearDevice: () => dispatch(actions.clearDevice()),
  getPatientKitInfo: patientId => dispatch(kitActions.getPatientKitInfo(patientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientDevicesHistory);
