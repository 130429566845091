import React from 'react';
import PropTypes from 'prop-types';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

function HashtagsWindow(props) {
  return (
    <OverlayScrollbarsComponent
      defer
      className="scrollbar-right-margin"
      options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
    >
      <div className="suggestion-flex">
        {props.hashtags &&
          props.hashtags.map(p => (
            <div
              key={`prefab-${p}`}
              className="message-textbox__suggestions__item"
              onClick={() => props.onTagChosen(`[${p}]`)}
            >
              <div className="tag-suggestion" data-tooltip-content="Insert tag" data-tooltip-id="tooltip">
                {p}
              </div>
            </div>
          ))}
      </div>
    </OverlayScrollbarsComponent>
  );
}

HashtagsWindow.propTypes = {
  hashtags: PropTypes.arrayOf(PropTypes.string),
  onTagChosen: PropTypes.func,
  onBlur: PropTypes.func,
};

export default HashtagsWindow;
