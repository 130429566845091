import React from 'react';
import { PageHeader } from '../../../components/PageHeader';

import Strings from '../../../Strings';

function DeviceUnderConstruction() {
  return (
    <React.Fragment>
      <div className="infoHeader">
        <div className="rowDirection">
          <button className="back" onClick={() => history.back()} tabIndex={0}>
            {Strings.back}
          </button>
        </div>
      </div>

      <div className="details-container">
        <PageHeader left={Strings.unsupportedDevice} />
      </div>
    </React.Fragment>
  );
}

export default DeviceUnderConstruction;
