import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const HEADER_BUTTON_DARK_BLUE = 'dark_blue';

export class Button extends PureComponent {
  static propTypes = {
    class: PropTypes.string,
    disabled: PropTypes.bool,
    first: PropTypes.bool,
    isSpinner: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    style: PropTypes.any,
    tabIndex: PropTypes.number,
    title: PropTypes.string,
    testid: PropTypes.string,
  };
  buttonRef = React.createRef();

  componentDidMount() {
    this.buttonRef && this.buttonRef.current && this.buttonRef.current.focus();
  }

  render() {
    const { isSpinner, style, disabled, onClick, title, first = false, tabIndex, ariaLabel, testid } = this.props;

    let actualTestId = null;

    testid && (actualTestId = `button[${testid}]`);

    return (
      <React.Fragment>
        {isSpinner === true && <div className="spinner" />}
        <button
          role="button"
          disabled={disabled}
          tabIndex={tabIndex || 0}
          className={this.props.class}
          onClick={onClick}
          style={style}
          ref={first && this.buttonRef}
          aria-label={ariaLabel}
          data-testid={actualTestId}
        >
          {title}
        </button>
      </React.Fragment>
    );
  }
}
