import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import styles from './style.module.scss';

export class RowText extends PureComponent {
  render() {
    const { big, children } = this.props;

    return <div className={`${styles.text} ${big ? styles.big : ''}`}>{children}</div>;
  }
}

RowText.propTypes = {
  big: PropTypes.any,
  children: PropTypes.any,
};
