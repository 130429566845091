import {
  CLEAR_ENTITIES,
  CLEAR_HISTORY_DATA,
  GET_MEDICATION_DOCS_DETAILS,
  GET_MEDICATION_DOCS_DETAILS_ERROR,
  GET_MEDICATION_DOCS_DETAILS_RESULT,
  LIST_PATIENT_MEDICATION_HISTORY,
  LIST_PATIENT_MEDICATION_HISTORY_ERROR,
  LIST_PATIENT_MEDICATION_HISTORY_RESULT,
  UPDATE_MEDICATION_STATUS,
} from '../actions/action-types';

const patientMedicationHistoryReducer = (
  state = {
    patientMedicationHistory: {
      notifications: [],
      pagination: {},
    },
    loading: true,
    preparing: true,
  },
  action,
) => {
  switch (action.type) {
    case LIST_PATIENT_MEDICATION_HISTORY: {
      const patientMedicationHistory = state.patientMedicationHistory;
      if (patientMedicationHistory.notifications && patientMedicationHistory.notifications.length > 0) {
        const history = patientMedicationHistory.notifications.map(user => {
          const d = user;
          d.isLoading = true;
          return d;
        });

        return {
          ...state,
          patientMedicationHistory: {
            notifications: history,
            pagination: patientMedicationHistory.pagination,
          },
          loading: action.loading,
          preparing: true,
        };
      }
      return {
        ...state,
        loading: action.loading,
        preparing: true,
      };
    }
    case LIST_PATIENT_MEDICATION_HISTORY_RESULT:
      return {
        ...state,
        patientMedicationHistory: action.response,
        loading: false,
        preparing: false,
      };

    case LIST_PATIENT_MEDICATION_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
        preparing: false,
      };
    case CLEAR_HISTORY_DATA:
      return {
        ...state,
        patientMedicationHistory: {
          notifications: {},
          pagination: {},
        },
        loading: false,
        preparing: false,
      };
    case GET_MEDICATION_DOCS_DETAILS:
    case UPDATE_MEDICATION_STATUS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MEDICATION_DOCS_DETAILS_RESULT:
      return {
        ...state,
        isLoding: false,
        medicineDocDetails: action.response,
      };
    case GET_MEDICATION_DOCS_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case CLEAR_ENTITIES:
      if (action.entities && action.entities.indexOf('patientMedicationHistory') !== -1) {
        return {
          ...state,
          patientMedicationHistory: {},
          preparing: true,
          loading: true,
        };
      }
      return state;
    default:
      return state;
  }
};

export default patientMedicationHistoryReducer;
