import { closeModal } from 'actions/modal';
import { Button } from 'components/Buttons/Button';
import LoadingRenderer from 'components/LoadingRenderer';
import { ButtonRow } from 'components/Login/ButtonRow/ButtonRow';
import { Text } from 'components/Login/Text/Text';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { BUTTON_TYPE_DARK_BLUE, BUTTON_TYPE_WHITE } from '../../components/Buttons/Button';
import { ESI_PHONE_NUMBER_TO_VERIFY_CAP_ID } from '../../constants';
import { Modal } from '../../containers';
import Form from '../../containers/Form';
import allDoneImg from '../../images/rx/alldone.svg';
import Strings from '../../Strings';
import '../editStudyModal.scss';

const mapDispatchToProps = dispatch => ({
  onCancel: () => dispatch(closeModal('activate-your-device-modal')),
});

class ActivateYourDeviceModal extends PureComponent {
  onFormSubmit = () => {
    this.props.onCancel();
    window.location.reload();
  };

  render() {
    const { onOpen, onCancel, data, ...props } = this.props;

    return (
      <Modal
        onOpen={onOpen}
        name="activate-your-device-modal"
        roundedCorner
        caption={data?.isChangeCap ? Strings.changeYourDeviceTitle : Strings.activateYourDevice}
        {...props}
      >
        <LoadingRenderer>
          <Form onCancel={onCancel} id="attach-cap-to-org" className="add-medicine" onSubmit={this.onFormSubmit}>
            <Text>{data?.isChangeCap ? Strings.changeYourDevice : Strings.activateYourDeviceBySMSText}</Text>
            <b className="header-h2">{ESI_PHONE_NUMBER_TO_VERIFY_CAP_ID}</b>
            <img className="iconTick" src={allDoneImg} alt="all done" />
            <ButtonRow
              right={
                <Button buttonType={BUTTON_TYPE_DARK_BLUE} type="submit" style={{ marginTop: 30 }}>
                  {Strings.done}
                </Button>
              }
              left={
                <Button buttonType={BUTTON_TYPE_WHITE} type="cancel" style={{ marginTop: 30 }}>
                  {Strings.cancel}
                </Button>
              }
            />
          </Form>
        </LoadingRenderer>
      </Modal>
    );
  }
}

ActivateYourDeviceModal.propTypes = {
  onCancel: PropTypes.func,
  onOpen: PropTypes.any,
};

export default connect(null, mapDispatchToProps)(ActivateYourDeviceModal);
