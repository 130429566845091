import { ESI_PATIENT_CALENDAR_GET, ESI_PATIENT_CALENDAR_GET_ERROR, ESI_PATIENT_CALENDAR_GET_RESULT } from './constants';

export const calendarReducer = (state = {}, action) => {
  const { response } = action;

  switch (action.type) {
    case ESI_PATIENT_CALENDAR_GET:
      return {
        ...state,
        loading: true,
      };

    case ESI_PATIENT_CALENDAR_GET_RESULT:
      return {
        ...state,
        history: response,
        loading: false,
      };

    case ESI_PATIENT_CALENDAR_GET_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
