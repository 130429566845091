import React from 'react';
import moment from 'moment/moment';

import SliderPicker from '../SliderPicker';

const withSliderPicker = (Component, manualDatePicker = false) => {
  const today = moment().toDate();
  const weekAgo = moment()
    .subtract(1, 'week')
    .startOf('day')
    .toDate();
  const yearAgo = moment()
    .subtract(1, 'year')
    .startOf('day')
    .toDate();

  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { rangeDate: null };
    }

    onDateChanged = rangeDate => {
      this.setState({ rangeDate });
    };

    render() {
      const props = {
        ...this.props,
        weekAgo,
        yearAgo,
        today,
        rangeDate: this.state.rangeDate,
      };

      return (
        <React.Fragment>
          <SliderPicker
            min={yearAgo}
            max={today}
            onChangeEnd={this.onDateChanged}
            manualDatePicker={manualDatePicker}
            minRangeDays={6}
          />
          <Component {...props} />
        </React.Fragment>
      );
    }
  };
};

export default withSliderPicker;
