import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import styles from './style.module.scss';
import { ADHERENCE_LEVEL_AVERAGE, ADHERENCE_LEVEL_GOOD, ADHERENCE_LEVEL_POOR } from '../../../constants';
import Strings from '../../../Strings';

export class TopPanel extends PureComponent {
  render() {
    const { adherence, left, children, performance } = this.props;
    let className = styles.average;
    if (performance === ADHERENCE_LEVEL_GOOD) className = styles.good;
    if (performance === ADHERENCE_LEVEL_POOR) className = styles.poor;
    if (performance === ADHERENCE_LEVEL_AVERAGE) className = styles.average;

    return (
      <div className={styles.topPanel}>
        <div className={styles.adherenceContainer}>
          <div className={`${styles.value} ${className}`}>{adherence}%</div>
          {Strings.totalAdherence}
        </div>
        {left}
        <div className={styles.right}>{children}</div>
      </div>
    );
  }
}

TopPanel.propTypes = {
  adherence: PropTypes.any,
  children: PropTypes.any,
  left: PropTypes.any,
  performance: PropTypes.any,
};
