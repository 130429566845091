import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { closeModal } from '../../actions/modal';
import { Modal } from '../../containers';
import Strings from '../../Strings';
import '../editStudyModal.scss';
import styles from './TechnicalSupport.module.scss';

const mapDispatchToProps = dispatch => ({
  onCancel: () => {
    dispatch(closeModal('technical-support-modal'));
  },
  onAccept: () => {
    window.open('https://intercom.help/rxcap-inc/en/', '_blank');
    dispatch(closeModal('technical-support-modal'));
  },
});
class TechnicalSupportModal extends PureComponent {
  render() {
    const { onCancel, onAccept, ...props } = this.props;
    return (
      <Modal name="technical-support-modal" additionalClasses={['form-modal', 'user-invite']} {...props}>
        <div className={styles.wrapper}>
          <p>{Strings.supportTechnicalModal}</p>
          <div className="button-bar reverse" key="button-bar">
            <button className="brand-blue" onClick={onAccept}>
              {Strings.accept}
            </button>
            <button className="white gray-text" onClick={onCancel}>
              {Strings.cancel}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
export default connect(null, mapDispatchToProps)(TechnicalSupportModal);
