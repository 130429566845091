import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { ACCEPT_AS_CAREGIVER_RESULT } from '../actions/action-types';
import { caregiverAction } from '../actions/caregiver';
import Strings from '../Strings';
import { QueryString } from '../utils';
import './AddCareGiverUsingLink.scss';

class AddCareGiverUsingLink extends PureComponent {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phoneNumber: '',
      verificationCode: '',
      sent: 0,
      confirm: false,
    };
    this.textInput = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.onSendVerificationCode = this.onSendVerificationCode.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.myRef = React.createRef();
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    if (this.textInput) {
      this.textInput.current.focus();
    }
  }

  onSendVerificationCode() {
    let methodName;
    if (this.state.sent === 0) {
      methodName = 'new';
    } else {
      methodName = 'resend';
    }
    this.register(methodName);
  }

  register(methodName) {
    const query = new QueryString(this.props.location.search);
    const token = query.params.token;
    const code = this.state.sent ? this.state.verificationCode : '';
    const data = {
      token,
      function: methodName,
      code,
      caregiver_name: this.state.name,
      caregiver_phone: `+1${this.state.phoneNumber}`,
    };
    const sentValue = this.state.sent;
    this.props.verifyAndRegisterAsCareGiver(data).then(response => {
      if (response && response.type === ACCEPT_AS_CAREGIVER_RESULT) {
        this.setState({ sent: sentValue + 1 });
        if (methodName === 'verify') {
          // this.setState({ confirm: true });
          // this.myRef.current.scrollTo(0, 0);
          this.props.onNavigate(`/cgsuccess`);
        }
      }
    });
  }

  onConfirm() {
    this.register('verify');
  }

  onChange(event) {
    if (event.target.getAttribute('name') === 'phoneNumber') {
      const re = /^[0-9\b]+$/;
      if (event.target.value === '' || re.test(event.target.value)) {
        this.setState({ [event.target.getAttribute('name')]: event.target.value });
      }
    } else {
      this.setState({ [event.target.getAttribute('name')]: event.target.value });
    }
  }
  render() {
    const { name, verificationCode, phoneNumber, sent, confirm } = this.state;
    const { loading, error } = this.props;
    const query = new QueryString(this.props.location.search);
    const isInValidLink = !query.params.token;

    let width = 0;

    if (name.length) {
      width += 25;
    }
    if (phoneNumber.length === 10) {
      width += 25;
    }

    if (sent) {
      width += 25;
    }
    if (confirm) {
      width += 25;
    }

    const style = { width: `${width}%` };
    return (
      <div className="caregiver-container">
        <Helmet>
          <title>Caregiver invite</title>
        </Helmet>
        <div className="caregiver-header">
          <div className="caregiver-logo"></div>
          <div className="header-progress-bar mt-20">
            <div className={style !== '0%' ? 'active-progess' : ''} style={style}></div>
          </div>
        </div>
        <div className="body-content">
          <div className="content mt">
            <div className="text-container">
              <div className="heading">{Strings.careGiverHeading}</div>
              <div className="body mt">{Strings.careGiverSubHeading}</div>
            </div>
          </div>
          <div className="form-container mt">
            {isInValidLink ? <div className="cg-error">{Strings.careGiverInvalidLink}</div> : ''}
            {error ? <div className="cg-error">{error.message}</div> : ''}
            <form className="accept-as-caregiver">
              <div className="input-element">
                {!name ? <div className="triangle first"></div> : ''}
                <input
                  className="text"
                  onChange={this.onChange}
                  type="text"
                  name="name"
                  autoComplete="off"
                  value={name}
                  placeholder={Strings.name}
                  disabled={sent}
                  ref={this.textInput}
                />
                {name ? <div className="verify first"></div> : ''}
              </div>
              <div className="input-element mt">
                {name && phoneNumber.length !== 10 ? <div className="triangle"></div> : ''}
                <div className="cg-country-code">+1</div>
                <input
                  className="text phone-number"
                  onChange={this.onChange}
                  type="text"
                  name="phoneNumber"
                  autoComplete="off"
                  value={phoneNumber}
                  placeholder={Strings.placeholder.cgPhoneNumber}
                  disabled={sent}
                />
                {phoneNumber.length === 10 ? <div className="verify"></div> : ''}
              </div>

              {name && phoneNumber.length === 10 ? (
                <div className="input-element mt btn-verify">
                  {name && phoneNumber.length === 10 && !sent ? <div className="triangle "></div> : ''}
                  <button
                    className=""
                    type="button"
                    role="button"
                    id="login-button"
                    onClick={this.onSendVerificationCode}
                  >
                    {sent ? Strings.reSendVerificationCode : Strings.sendVerificationCode}
                  </button>
                  {sent ? <div className="verify btn-triangle"></div> : ''}
                  {loading ? <div className="caregiver-spinner"></div> : ''}
                </div>
              ) : (
                ''
              )}

              {sent ? (
                <div className="input-element mt">
                  {verificationCode.length !== 5 ? <div className="triangle "></div> : ''}
                  <input
                    className="text"
                    onChange={this.onChange}
                    type="text"
                    name="verificationCode"
                    autoComplete="off"
                    value={verificationCode}
                    placeholder={Strings.verificationCode}
                  />
                  {/* {verificationCode?<div className="verify"></div>:""} */}
                </div>
              ) : (
                ''
              )}

              {verificationCode.length === 5 && sent ? (
                <div className="input-element mt btn-verify">
                  {verificationCode && sent ? <div className="triangle "></div> : ''}
                  <button className="" type="button" role="button" id="confirm" onClick={this.onConfirm}>
                    {Strings.confirm}
                  </button>
                </div>
              ) : (
                ''
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.caregiver.loading,
    error: state.caregiver.error,
  };
};

const mapDispatchToProps = dispatch => ({
  verifyAndRegisterAsCareGiver: data => dispatch(caregiverAction.acceptRequest(data)),
  onNavigate: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCareGiverUsingLink);

AddCareGiverUsingLink.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  verifyAndRegisterAsCareGiver: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  onNavigate: PropTypes.func,
};
