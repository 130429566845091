import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Strings from '../../../../Strings';
import { Input } from '../../../../containers/Form';
import { actions, ALERTS_GET_RESULT, ALERT_DEFINITION_GET_RESULT, ALERT_DEFINITION_PUT_RESULT } from './actions';
import {
  convertKilogramToPound,
  convertPoundToKilogram,
  roundToDecimal,
  convertCelsiusToFahrenheit,
  convertFahrenheitToCelsius,
} from '../../../../utils/unitConverters';
import { notificationActions } from '../../../../components/Notification/redux/actions';
import { actions as alertActions, DELETE_ALERT_RESULT } from '../../../../pages/Alerts/redux/actions';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../../components/PageHeader/Button';
import { PERMISSIONS, hasPermission } from '../../../../utils/userPermissions';
import { patientAction } from '../../../../actions/patient';
import { UPDATE_PATIENT_SCHEDULE } from '../../../../actions/action-types';
import { EditRpmScheduleModalPages } from '../../../../modals/_ModalsMetadata/ModalsMetadata';
import { openModalAction } from '../../../../actions/modal';
import { ReadingsEnum } from '../../../../utils';

function LimitsSection(props) {
  const [highWeight, setHighWeight] = useState('');
  const [lowWeight, setLowWeight] = useState('');
  const [lowTemperature, setLowTemperature] = useState('');
  const [highTemperature, setHighTemperature] = useState('');
  const [highSystolic, setHighSystolic] = useState('');
  const [lowSystolic, setLowSystolic] = useState('');
  const [highDiastolic, setHighDiastolic] = useState('');
  const [lowDiastolic, setLowDiastolic] = useState('');
  const [lowGlucoseConcentration, setLowGlucoseConcentration] = useState('');
  const [highGlucoseConcentration, setHighGlucoseConcentration] = useState('');
  const [lowFev1, setLowFev1] = useState('');
  const [highFev1, setHighFev1] = useState('');
  const [lowFvc, setLowFvc] = useState('');
  const [highFvc, setHighFvc] = useState('');
  const [lowSaturation, setLowSaturation] = useState('');
  const [lowHR, setLowHR] = useState('');
  const [highHR, setHighHR] = useState('');
  const [highPm25, setHighPm25] = useState('');
  const [lowPt, setLowPt] = useState('');
  const [highPt, setHighPt] = useState('');
  const [lowInr, setLowInr] = useState('');
  const [highInr, setHighInr] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alertId, setAlertId] = useState();
  const [priority, setPriority] = useState(0);
  const { onAlertCleared } = props;

  const canEdit = hasPermission(
    PERMISSIONS.PATIENTS_RPM_PATIENT_MEDICATIONS_EDIT || PERMISSIONS.PATIENTS_RPM_PATIENT_DEVICES_EDIT,
  );

  const getLimits = () => {
    setIsLoading(true);
    setPriority(0);
    switch (props.selectedChart) {
      case ReadingsEnum.weight:
        props.getAlertDefinition(props.patientId, 'weight').then(resp => {
          if (resp.type === ALERT_DEFINITION_GET_RESULT) {
            const max = resp.response?.conditions[0].max;
            const min = resp.response?.conditions[0].min;
            max && setHighWeight(roundToDecimal(convertKilogramToPound(max), 2));
            min && setLowWeight(roundToDecimal(convertKilogramToPound(min), 2));
            setPriority(resp.response?.priority);
          }
          setIsLoading(false);
        });
        break;
      case ReadingsEnum.bloodPressure:
        props.getAlertDefinition(props.patientId, 'blood_pressure').then(resp => {
          if (resp.type === ALERT_DEFINITION_GET_RESULT) {
            resp.response.conditions?.forEach(condition => {
              if (condition.field === 'systolic_pressure') {
                condition.max && setHighSystolic(condition.max);
                condition.min && setLowSystolic(condition.min);
              } else if (condition.field === 'diastolic_pressure') {
                condition.max && setHighDiastolic(condition.max);
                condition.min && setLowDiastolic(condition.min);
              }
            });
            setPriority(resp.response?.priority);
          }
          setIsLoading(false);
        });
        break;
      case ReadingsEnum.glucose:
        props.getAlertDefinition(props.patientId, 'glucose').then(resp => {
          if (resp.type === ALERT_DEFINITION_GET_RESULT) {
            const max = resp.response?.conditions[0].max;
            const min = resp.response?.conditions[0].min;
            max && setHighGlucoseConcentration(max);
            min && setLowGlucoseConcentration(min);
            setPriority(resp.response?.priority);
          }
          setIsLoading(false);
        });
        break;
      case ReadingsEnum.temperature:
        props.getAlertDefinition(props.patientId, 'temperature').then(resp => {
          if (resp.type === ALERT_DEFINITION_GET_RESULT) {
            const max = resp.response?.conditions[0].max;
            const min = resp.response?.conditions[0].min;
            max && setHighTemperature(convertCelsiusToFahrenheit(max));
            min && setLowTemperature(convertCelsiusToFahrenheit(min));
            setPriority(resp.response?.priority);
          }
          setIsLoading(false);
        });
        break;
      case ReadingsEnum.spirometer:
        props.getAlertDefinition(props.patientId, 'spirometer').then(resp => {
          if (resp.type === ALERT_DEFINITION_GET_RESULT) {
            resp.response.conditions?.forEach(condition => {
              if (condition.field === 'fev1') {
                condition.max && setHighFev1(condition.max);
                condition.min && setLowFev1(condition.min);
              } else if (condition.field === 'fvc') {
                condition.max && setHighFvc(condition.max);
                condition.min && setLowFvc(condition.min);
              }
            });
            setPriority(resp.response?.priority);
          }
          setIsLoading(false);
        });
        break;
      case ReadingsEnum.coagulation:
        props.getAlertDefinition(props.patientId, 'coagulation').then(resp => {
          if (resp.type === ALERT_DEFINITION_GET_RESULT) {
            resp.response.conditions?.forEach(condition => {
              if (condition.field === 'pt') {
                condition.max && setHighPt(condition.max);
                condition.min && setLowPt(condition.min);
              } else if (condition.field === 'inr') {
                condition.max && setHighInr(condition.max);
                condition.min && setLowInr(condition.min);
              }
            });
            setPriority(resp.response?.priority);
          }
          setIsLoading(false);
        });
        break;
      case ReadingsEnum.pulseox:
        props.getAlertDefinition(props.patientId, 'saturation').then(resp => {
          if (resp.type === ALERT_DEFINITION_GET_RESULT) {
            const min = resp.response?.conditions[0].min;
            min && setLowSaturation(min);
            setPriority(resp.response?.priority);
          }
          setIsLoading(false);
        });
        break;

      case ReadingsEnum.heartRate:
        props.getAlertDefinition(props.patientId, 'heart_rate').then(resp => {
          if (resp.type === ALERT_DEFINITION_GET_RESULT) {
            const max = resp.response?.conditions[0].max;
            const min = resp.response?.conditions[0].min;
            max && setHighHR(max);
            min && setLowHR(min);
            setPriority(resp.response?.priority);
          }
          setIsLoading(false);
        });
        break;

      case ReadingsEnum.pm25:
        props.getAlertDefinition(props.patientId, 'air_quality').then(resp => {
          if (resp.type === ALERT_DEFINITION_GET_RESULT) {
            const max = resp.response?.conditions[0].max;
            max && setHighPm25(max);
            setPriority(resp.response?.priority);
          }
          setIsLoading(false);
        });
        break;

      default:
        break;
    }
  };

  const setLimits = prior => {
    switch (props.selectedChart) {
      case ReadingsEnum.weight:
        {
          const lowWeightPresent = lowWeight !== '';
          const highWeightPresent = highWeight !== '';

          if (lowWeightPresent && highWeightPresent) {
            if (lowWeight > highWeight) {
              return;
            }
          }

          if (lowWeightPresent || highWeightPresent) {
            const condition = {
              field: 'weight',
            };

            lowWeightPresent && (condition.min = convertPoundToKilogram(lowWeight));
            highWeightPresent && (condition.max = convertPoundToKilogram(highWeight));

            if (condition.min || condition.max) {
              const alertDefinition = {
                type: 'weight',
                conditions: [condition],
                priority: prior,
              };

              props.setAlertDefinition(props.patientId, alertDefinition).then(resp => {
                if (resp.type !== ALERT_DEFINITION_PUT_RESULT) {
                  props.showNotification(Strings.errors.settingLimitsError);
                }
              });
            }
          }
        }
        break;
      case ReadingsEnum.bloodPressure:
        {
          const lowSystolicPresent = lowSystolic !== '' && !isNaN(lowSystolic);
          const highSystolicPresent = highSystolic !== '' && !isNaN(highSystolic);
          const lowDiastolicPresent = lowDiastolic !== '' && !isNaN(lowDiastolic);
          const highDiastolicPresent = highDiastolic !== '' && !isNaN(highDiastolic);

          if ((!lowSystolicPresent && !highSystolicPresent) || (!lowDiastolicPresent && !highDiastolicPresent)) {
            return;
          }

          if (lowSystolicPresent && highSystolicPresent) {
            if (lowSystolic > highSystolic) {
              return;
            }
          }

          if (lowDiastolicPresent && highDiastolicPresent) {
            if (lowDiastolic > highDiastolic) {
              return;
            }
          }

          const conditions = [];

          if (lowSystolicPresent || highSystolicPresent) {
            const condition = {
              field: 'systolic_pressure',
            };

            lowSystolicPresent && (condition.min = parseInt(lowSystolic, 10));
            highSystolicPresent && (condition.max = parseInt(highSystolic, 10));
            conditions.push(condition);
          }

          if (lowDiastolicPresent || highDiastolicPresent) {
            const condition = {
              field: 'diastolic_pressure',
            };

            lowDiastolicPresent && (condition.min = parseInt(lowDiastolic, 10));
            highDiastolicPresent && (condition.max = parseInt(highDiastolic, 10));
            conditions.push(condition);
          }

          if (conditions.length > 0) {
            const alertDefinition = {
              type: 'blood_pressure',
              conditions,
              priority: prior,
            };

            props.setAlertDefinition(props.patientId, alertDefinition).then(resp => {
              if (resp.type !== ALERT_DEFINITION_PUT_RESULT) {
                props.showNotification(Strings.errors.settingLimitsError);
              }
            });
          }
        }
        break;
      case ReadingsEnum.glucose:
        {
          const lowGlucoseConcentrationPresent = lowGlucoseConcentration !== '';
          const highGlucoseConcentrationPresent = highGlucoseConcentration !== '';

          if (lowGlucoseConcentrationPresent && highGlucoseConcentrationPresent) {
            if (lowGlucoseConcentration > highGlucoseConcentration) {
              return;
            }
          }

          if (lowGlucoseConcentrationPresent || highGlucoseConcentrationPresent) {
            const condition = {
              field: 'glucose_concentration',
            };

            lowGlucoseConcentrationPresent && (condition.min = lowGlucoseConcentration);
            highGlucoseConcentrationPresent && (condition.max = highGlucoseConcentration);

            if (condition.min || condition.max) {
              const alertDefinition = {
                type: 'glucose',
                conditions: [condition],
                priority: prior,
              };

              props.setAlertDefinition(props.patientId, alertDefinition).then(resp => {
                if (resp.type !== ALERT_DEFINITION_PUT_RESULT) {
                  props.showNotification(Strings.errors.settingLimitsError);
                }
              });
            }
          }
        }
        break;
      case ReadingsEnum.spirometer:
        {
          const lowFev1Present = lowFev1 !== '' && !isNaN(lowFev1);
          const highFev1Present = highFev1 !== '' && !isNaN(highFev1);
          const lowFvcPresent = lowFvc !== '' && !isNaN(lowFvc);
          const highFvcPresent = highFvc !== '' && !isNaN(highFvc);

          if (lowFev1Present && highFev1Present) {
            if (lowFev1 > highFev1) {
              return;
            }
          }

          if (lowFvcPresent && highFvcPresent) {
            if (lowFvc > highFvc) {
              return;
            }
          }

          const conditions = [];

          if (lowFev1Present || highFev1Present) {
            const condition = {
              field: 'fev1',
            };

            lowFev1Present && (condition.min = parseFloat(lowFev1));
            highFev1Present && (condition.max = parseFloat(highFev1));
            conditions.push(condition);
          }

          if (lowFvcPresent || highFvcPresent) {
            const condition = {
              field: 'fvc',
            };

            lowFvcPresent && (condition.min = parseFloat(lowFvc));
            highFvcPresent && (condition.max = parseFloat(highFvc));
            conditions.push(condition);
          }

          if (conditions.length > 0) {
            const alertDefinition = {
              type: 'spirometer',
              conditions,
              priority: prior,
            };

            props.setAlertDefinition(props.patientId, alertDefinition).then(resp => {
              if (resp.type !== ALERT_DEFINITION_PUT_RESULT) {
                props.showNotification(Strings.errors.settingLimitsError);
              }
            });
          }
        }
        break;
      case ReadingsEnum.temperature:
        {
          const lowTemperaturePresent = lowTemperature !== '';
          const highTemperaturePresent = highTemperature !== '';

          if (lowTemperaturePresent && highTemperaturePresent) {
            if (lowTemperaturePresent > highTemperaturePresent) {
              return;
            }
          }

          if (lowTemperaturePresent || highTemperaturePresent) {
            const condition = {
              field: 'temperature',
            };

            lowTemperaturePresent && (condition.min = convertFahrenheitToCelsius(parseFloat(lowTemperature)));
            highTemperaturePresent && (condition.max = convertFahrenheitToCelsius(parseFloat(highTemperature)));

            if (condition.min || condition.max) {
              const alertDefinition = {
                type: 'temperature',
                conditions: [condition],
                priority: prior,
              };

              props.setAlertDefinition(props.patientId, alertDefinition).then(resp => {
                if (resp.type !== ALERT_DEFINITION_PUT_RESULT) {
                  props.showNotification(Strings.errors.settingLimitsError);
                }
              });
            }
          }
        }
        break;

      case ReadingsEnum.pulseox:
        {
          const lowSaturationPresent = lowSaturation !== '';

          if (lowSaturationPresent) {
            const condition = {
              field: 'saturation',
            };

            lowSaturationPresent && (condition.min = lowSaturation);

            if (condition.min >= 0) {
              const alertDefinition = {
                type: 'saturation',
                conditions: [condition],
                priority: prior,
              };

              props.setAlertDefinition(props.patientId, alertDefinition).then(resp => {
                if (resp.type !== ALERT_DEFINITION_PUT_RESULT) {
                  props.showNotification(Strings.errors.settingLimitsError);
                }
              });
            }
          }
        }
        break;
      case ReadingsEnum.coagulation:
        {
          const lowPtPresent = lowPt !== '' && !isNaN(lowPt);
          const highPtPresent = highPt !== '' && !isNaN(highPt);
          const lowInrPresent = lowInr !== '' && !isNaN(lowInr);
          const highInrPresent = highInr !== '' && !isNaN(highInr);

          if (lowPtPresent && highPtPresent) {
            if (lowPt > highPt) {
              return;
            }
          }

          if (lowInrPresent && highInrPresent) {
            if (lowInr > highInr) {
              return;
            }
          }

          const conditions = [];

          if (lowPtPresent || highPtPresent) {
            const condition = {
              field: 'pt',
            };

            lowPtPresent && (condition.min = parseFloat(lowPt, 10));
            highPtPresent && (condition.max = parseFloat(highPt, 10));
            conditions.push(condition);
          }

          if (lowInrPresent || highInrPresent) {
            const condition = {
              field: 'inr',
            };

            lowInrPresent && (condition.min = parseFloat(lowInr, 10));
            highInrPresent && (condition.max = parseFloat(highInr, 10));
            conditions.push(condition);
          }

          if (conditions.length > 0) {
            const alertDefinition = {
              type: 'coagulation',
              conditions,
              priority: prior,
            };

            props.setAlertDefinition(props.patientId, alertDefinition).then(resp => {
              if (resp.type !== ALERT_DEFINITION_PUT_RESULT) {
                props.showNotification(Strings.errors.settingLimitsError);
              }
            });
          }
        }
        break;
      case ReadingsEnum.heartRate:
        {
          const lowHRPresent = lowHR !== '';
          const highHRPresent = highHR !== '';

          if (lowHRPresent && highHRPresent) {
            if (lowHRPresent > highHRPresent) {
              return;
            }
          }

          if (lowHRPresent || highHRPresent) {
            const condition = {
              field: 'heart_rate',
            };

            lowHRPresent && (condition.min = lowHR);
            highHRPresent && (condition.max = highHR);

            if (condition.min || condition.max) {
              const alertDefinition = {
                type: 'heart_rate',
                conditions: [condition],
                priority: prior,
              };

              props.setAlertDefinition(props.patientId, alertDefinition).then(resp => {
                if (resp.type !== ALERT_DEFINITION_PUT_RESULT) {
                  props.showNotification(Strings.errors.settingLimitsError);
                }
              });
            }
          }
        }
        break;
      case ReadingsEnum.pm25:
        {
          const highPm25Present = highPm25 !== '';

          if (highPm25Present) {
            const condition = {
              field: 'pm2_5',
            };

            highPm25Present && (condition.max = highPm25);

            if (condition.max >= 0) {
              const alertDefinition = {
                type: 'air_quality',
                conditions: [condition],
                priority: prior,
              };

              props.setAlertDefinition(props.patientId, alertDefinition).then(resp => {
                if (resp.type !== ALERT_DEFINITION_PUT_RESULT) {
                  props.showNotification(Strings.errors.settingLimitsError);
                }
              });
            }
          }
        }
        break;
      default:
        break;
    }
  };

  const setAlertPriority = p => {
    setPriority(p);
    setLimits(p);
  };

  const getAlert = () => {
    props.getAlerts(props.patientId).then(resp => {
      if (resp.type === ALERTS_GET_RESULT) {
        if (props.selectedMed && props.medInfo) {
          setAlertId(
            resp.response.schedule_missed_doses_alerts?.find(a => a.schedule_id === props.medInfo.id)?.alert_id,
          );
        }
        switch (props.selectedChart) {
          case ReadingsEnum.weight:
            setAlertId(resp.response.weight_alert_id);
            break;
          case ReadingsEnum.bloodPressure:
            setAlertId(resp.response.blood_pressure_alert_id);
            break;
          case ReadingsEnum.glucose:
            setAlertId(resp.response.glucose_alert_id);
            break;
          case ReadingsEnum.spirometer:
            setAlertId(resp.response.spirometer_alert_id);
            break;
          case ReadingsEnum.temperature:
            setAlertId(resp.response.temperature_alert_id);
            break;
          case ReadingsEnum.pulseox:
            setAlertId(resp.response.saturation_alert_id);
            break;
          case ReadingsEnum.heartRate:
            setAlertId(resp.response.heart_rate_alert_id);
            break;
          case ReadingsEnum.pm25:
            setAlertId(resp.response.air_quality_alert_id);
            break;
          case ReadingsEnum.coagulation:
            setAlertId(resp.response.coagulation_alert_id);
          default:
            break;
        }
      }
    });
  };

  useEffect(() => {
    getLimits();
    getAlert();
  }, [props.selectedChart, props.patientId, props.selectedMed]);

  const deleteAlert = () => {
    props.deleteAlert(alertId).then(resp => {
      if (resp.type === DELETE_ALERT_RESULT) {
        onAlertCleared();
        setAlertId(null);
      } else {
        props.showNotification(Strings.clearAlertError, 5000, true);
      }
    });
  };

  const isAnyTresholdSet = () => {
    return (
      lowWeight ||
      highWeight ||
      lowSystolic ||
      highSystolic ||
      lowDiastolic ||
      highDiastolic ||
      lowGlucoseConcentration ||
      highGlucoseConcentration ||
      lowFev1 ||
      highFev1 ||
      lowFvc ||
      highFvc ||
      lowTemperature ||
      highTemperature ||
      lowSaturation ||
      lowHR ||
      highHR ||
      highPm25 ||
      lowPt ||
      highPt ||
      lowInr ||
      highInr
    );
  };

  const renderAlertInputs = () => {
    if (props.selectedMed && props.medInfo && props.medInfo.scheduleType !== 'as_needed') {
      return (
        <React.Fragment>
          {props.medInfo.sms_reminder?.missed_doses?.offset && (
            <Input
              name="missedDoses"
              id="missedDoses"
              label={Strings.missedDoses}
              type="number"
              value={props.medInfo.sms_reminder?.missed_doses?.offset}
              disabled
            />
          )}
          {canEdit && !alertId && (
            <Button
              class={HEADER_BUTTON_DARK_BLUE}
              onClick={() => props.editReminders(props.patientId, props.medInfo)}
              title={props.medInfo.sms_reminder?.missed_doses?.offset ? Strings.change : Strings.setReminder}
            />
          )}
        </React.Fragment>
      );
    }

    switch (props.selectedChart) {
      case ReadingsEnum.weight: {
        return (
          <React.Fragment>
            <Input
              name="lowWeight"
              id="lowWeight"
              label={Strings.lowWeight}
              type="number"
              value={lowWeight}
              onChange={e => setLowWeight(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
            <Input
              name="highWeight"
              id="highWeight"
              label={Strings.highWeight}
              type="number"
              value={highWeight}
              onChange={e => setHighWeight(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
          </React.Fragment>
        );
      }

      case ReadingsEnum.bloodPressure: {
        return (
          <React.Fragment>
            <Input
              name="lowSystolic"
              id="lowSystolic"
              label={Strings.lowSystolic}
              type="number"
              value={lowSystolic}
              onChange={e => setLowSystolic(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
            <Input
              name="highSystolic"
              id="highSystolic"
              label={Strings.highSystolic}
              type="number"
              value={highSystolic}
              onChange={e => setHighSystolic(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
            <Input
              name="lowDiastolic"
              id="lowDiastolic"
              label={Strings.lowDiastolic}
              type="number"
              value={lowDiastolic}
              onChange={e => setLowDiastolic(e.target.value)}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
            <Input
              name="highDiastolic"
              id="highDiastolic"
              label={Strings.highDiastolic}
              type="number"
              value={highDiastolic}
              onChange={e => setHighDiastolic(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
          </React.Fragment>
        );
      }

      case ReadingsEnum.glucose: {
        return (
          <React.Fragment>
            <Input
              name="lowGlucose"
              id="lowGlucose"
              label={Strings.lowGlucose}
              type="number"
              value={lowGlucoseConcentration != null ? roundToDecimal(lowGlucoseConcentration, 2) : ''}
              onChange={e => setLowGlucoseConcentration(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
            <Input
              name="highGlucose"
              id="highGlucose"
              label={Strings.highGlucose}
              type="number"
              value={highGlucoseConcentration != null ? roundToDecimal(highGlucoseConcentration, 2) : ''}
              onChange={e => setHighGlucoseConcentration(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
          </React.Fragment>
        );
      }

      case ReadingsEnum.temperature: {
        return (
          <React.Fragment>
            <Input
              name="lowTemperature"
              id="lowTemperature"
              label={Strings.lowTemperature}
              type="number"
              value={lowTemperature != null ? roundToDecimal(lowTemperature, 2) : ''}
              onChange={e => setLowTemperature(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
            <Input
              name="highTemperature"
              id="highTemperature"
              label={Strings.highTemperature}
              type="number"
              value={highTemperature != null ? roundToDecimal(highTemperature, 2) : ''}
              onChange={e => setHighTemperature(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
          </React.Fragment>
        );
      }

      case ReadingsEnum.spirometer: {
        return (
          <React.Fragment>
            <Input
              name="lowFev1"
              id="lowFev1"
              label={Strings.lowFev1}
              type="number"
              value={lowFev1 != null ? roundToDecimal(lowFev1, 2) : ''}
              onChange={e => setLowFev1(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
            <Input
              name="highFev1"
              id="highFev1"
              label={Strings.highFev1}
              type="number"
              value={highFev1 != null ? roundToDecimal(highFev1, 2) : ''}
              onChange={e => setHighFev1(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
            <Input
              name="lowFvc"
              id="lowFvc"
              label={Strings.lowFvc}
              type="number"
              value={lowFvc != null ? roundToDecimal(lowFvc, 2) : ''}
              onChange={e => setLowFvc(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
            <Input
              name="highFvc"
              id="highFvc"
              label={Strings.highFvc}
              type="number"
              value={highFvc != null ? roundToDecimal(highFvc, 2) : ''}
              onChange={e => setHighFvc(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
          </React.Fragment>
        );
      }

      case ReadingsEnum.pulseox: {
        return (
          <Input
            name="lowSaturation"
            id="lowSaturation"
            label={Strings.lowSaturation}
            type="number"
            value={lowSaturation}
            onChange={e => setLowSaturation(parseFloat(e.target.value))}
            onBlur={() => setLimits(priority)}
            disabled={isLoading || !canEdit || alertId}
          />
        );
      }

      case ReadingsEnum.heartRate: {
        return (
          <React.Fragment>
            <Input
              name="lowHR"
              id="lowHR"
              label={Strings.lowHR}
              type="number"
              value={lowHR}
              onChange={e => setLowHR(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
            <Input
              name="highHR"
              id="highHR"
              label={Strings.highHR}
              type="number"
              value={highHR}
              onChange={e => setHighHR(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
          </React.Fragment>
        );
      }

      case ReadingsEnum.pm25: {
        return (
          <Input
            name="highPm25"
            id="highPm25"
            label={Strings.highPm25}
            type="number"
            value={highPm25}
            onChange={e => setHighPm25(parseFloat(e.target.value))}
            onBlur={() => setLimits(priority)}
            disabled={isLoading || !canEdit || alertId}
          />
        );
      }

      case ReadingsEnum.coagulation: {
        return (
          <React.Fragment>
            <Input
              name="lowPt"
              id="lowPt"
              label={Strings.lowPt}
              type="number"
              value={lowPt}
              onChange={e => setLowPt(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
            <Input
              name="highPt"
              id="highPt"
              label={Strings.highPt}
              type="number"
              value={highPt}
              onChange={e => setHighPt(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
            <Input
              name="lowInr"
              id="lowInr"
              label={Strings.lowInr}
              type="number"
              value={lowInr}
              onChange={e => setLowInr(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
            <Input
              name="highInr"
              id="highInr"
              label={Strings.highInr}
              type="number"
              value={highInr}
              onChange={e => setHighInr(parseFloat(e.target.value))}
              onBlur={() => setLimits(priority)}
              disabled={isLoading || !canEdit || alertId}
            />
          </React.Fragment>
        );
      }

      default: {
        return null;
      }
    }
  };

  return [
    ReadingsEnum.heartRate,
    ReadingsEnum.pulseox,
    ReadingsEnum.spirometer,
    ReadingsEnum.temperature,
    ReadingsEnum.glucose,
    ReadingsEnum.bloodPressure,
    ReadingsEnum.weight,
    ReadingsEnum.adherence,
    ReadingsEnum.pm25,
    ReadingsEnum.coagulation,
  ].includes(props.selectedChart) || props.selectedMed ? (
    <div className="section-header">
      {props.selectedMed && (
        <div className="alert-title">{`${props.medInfo?.medicationName} ${
          props.medInfo?.scheduleType !== 'as_needed' ? Strings.alerts : ''
        }`}</div>
      )}
      <div>{Strings.alertsEnum[props.selectedChart]}</div>
      <div className="section-header-inputs">
        {renderAlertInputs()}
        {props.selectedChart && canEdit && (
          <Input
            name="priority"
            id="priority"
            label="Notification"
            type="checkbox"
            checked={priority === 2}
            onChange={e => setAlertPriority(e.target.checked ? 2 : undefined)}
            disabled={isLoading || !isAnyTresholdSet() || alertId}
          />
        )}
        {alertId && canEdit && (
          <Button class={HEADER_BUTTON_DARK_BLUE} onClick={deleteAlert} title={Strings.clearAlert} />
        )}
      </div>
    </div>
  ) : (
    <div className="section-header">
      <div>{Strings.alerts}</div>
      <div className="section-header-inputs">-</div>
    </div>
  );
}

LimitsSection.propTypes = {
  selectedChart: PropTypes.string.isRequired,
  deleteAlert: PropTypes.func.isRequired,
  setAlertDefinition: PropTypes.func.isRequired,
  getAlertDefinition: PropTypes.func.isRequired,
  getAlerts: PropTypes.func.isRequired,
  onAlertCleared: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, props) => ({
  setAlertDefinition: (patientId, alertDefinition) => dispatch(actions.setAlertDefinition(patientId, alertDefinition)),
  getAlertDefinition: (patientId, type) => dispatch(actions.getAlertDefinition(patientId, type)),
  showNotification: (message, timeout, error) => dispatch(notificationActions.show(message, timeout, error)),
  getAlerts: patientId => dispatch(actions.getAlerts(patientId)),
  deleteAlert: id => dispatch(alertActions.deleteAlert(id)),
  editReminders: (patientId, schedule) =>
    dispatch(
      openModalAction('edit-rpm-schedule', {
        patient: { id: patientId },
        schedule,
        action: patientAction.actionUpdateSchedule,
        actionType: UPDATE_PATIENT_SCHEDULE,
        onSuccess: props.onAlertCleared,
        pages: [EditRpmScheduleModalPages.reminders],
        ignoreFilters: true,
      }),
    ),
});

export default connect(null, mapDispatchToProps)(LimitsSection);
