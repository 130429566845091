import React, { PureComponent } from 'react';
import momentTimezone from 'moment-timezone';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';

import { Box, TimeStampPlotComponent } from './base';
import Strings from '../../Strings';
import styles from './timeStampPlot.module.scss';

let weekDays = [];
class TimeStampPlot extends PureComponent {
  static propTypes = {
    battery: PropTypes.shape({ battery_status: PropTypes.any }),
    caption: PropTypes.string,
    data: PropTypes.shape({ length: PropTypes.number }),
    isLoading: PropTypes.bool,
    selectedWeek: PropTypes.shape({ map: PropTypes.func }),
    toolTipDate: PropTypes.shape({ map: PropTypes.func }),
  };

  static xTickFormat(d) {
    if (d === 1440) {
      return '24:00';
    }

    return momentTimezone
      .unix(d * 60)
      .tz('UTC')
      .format('HH:ss');
  }

  static yTickFormat(d) {
    return `${weekDays[d]}`;
  }

  render() {
    const { data, caption, selectedWeek, toolTipDate, battery } = this.props;
    weekDays = selectedWeek;
    if (!data || data.length === 0) {
      return <Box className="adherence-time-plot">{Strings.noData}</Box>;
    }
    let timeStampData = Object.values(data);
    timeStampData = timeStampData.map(item => {
      const timeStamp = momentTimezone.tz(item.eventTimestamp, item.patientTimezone);
      let formattedTimeStamp = (+timeStamp.format('m') + +timeStamp.format('H') * 60) % 1440;
      const reminder = formattedTimeStamp % 10;
      formattedTimeStamp -= reminder; // round by 10 mins
      const weekDay = +timeStamp.format('d');
      return {
        weekDay: weekDay === 0 ? 6 : weekDay - 1, // index from Monday
        time: formattedTimeStamp,
        formattedTime: `${momentTimezone.tz(item.eventTimestamp, item.patientTimezone).format('hh:mm A')}`, // rounded by 10 mins, display middle (X:X5)
      };
    });

    timeStampData = timeStampData.reduce((timestamps, timeStamp) => {
      const key = `${timeStamp.weekDay}-${timeStamp.time}`;

      if (!timestamps[key]) {
        timestamps[key] = timeStamp; // eslint-disable-line no-param-reassign
        timestamps[key].taken = 0; // eslint-disable-line no-param-reassign
        timestamps[key].key = key; // eslint-disable-line no-param-reassign
      }

      timestamps[key].taken += 1; // eslint-disable-line no-param-reassign

      return timestamps;
    }, {});

    const maxTaken = Object.values(timeStampData).reduce((maxTakenCurrent, timeStamp) => {
      if (timeStamp.taken > maxTakenCurrent) {
        maxTakenCurrent = timeStamp.taken; // eslint-disable-line no-param-reassign
      }

      return maxTakenCurrent;
    }, 0);

    const timeStampArray = Object.values(timeStampData).sort((a, b) => a.taken - b.taken);

    return (
      <Box className="adherence-time-plot" caption={caption}>
        <div className={styles.legend}>
          {battery?.battery_status ? (
            <div className={styles[battery?.battery_status]}>
              {Strings.battery.battery}: {battery?.battery_status}
            </div>
          ) : (
            <span>{Strings.battery.battery} : -</span>
          )}
        </div>
        <div className="day-box">
          <div className="y-axis-label">
            {selectedWeek.map((day, index) => {
              if (index < 5) {
                return (
                  /* eslint-disable */
                  <div
                    className="y-axis-days"
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                      <div className="timestamp-date-value">
                        <div className="tooltip-day">{value.format('dddd')}</div>
                        <div className="tooltip-day-1">{value.format('Do MMM')}</div>
                      </div>,
                    )}
                    data-tooltip-id="tooltip"
                    key={`tttt-key-${index}`}
                  >
                    {day}
                  </div>
                  /* eslint-enable */
                );
              }
              return '';
            })}
          </div>
          <div className="y-axis-label-week-end">
            {selectedWeek.map((day, index) => {
              if (index === 5 || index === 6) {
                return (
                  /* eslint-disable */
                  <div
                    className="y-axis-days"
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                      <div className="timestamp-date-value">
                        <div className="tooltip-day">{value.format('dddd')}</div>
                        <div className="tooltip-day-1">{value.format('Do MMM')}</div>
                      </div>,
                    )}
                    data-tooltip-id="tooltip"
                    key={`tttt-key-${index}`}
                  >
                    {day}
                  </div>
                  /* eslint-enable */
                );
              }
              return '';
            })}
          </div>
        </div>
        {this.props.isLoading ? <div className="spinner" /> : null}
        <TimeStampPlotComponent
          timeStamps={timeStampArray}
          maxTaken={maxTaken}
          margin={{
            top: 20,
            bottom: 40,
            left: 40,
            right: 20,
          }}
          xTickFormat={TimeStampPlot.xTickFormat}
          yTickFormat={TimeStampPlot.yTickFormat}
        />
      </Box>
    );
  }
}

export default TimeStampPlot;
