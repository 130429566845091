import { API_CALL } from 'middleware/api';
import {
  ESI_PATIENT_UPDATE_PREFERENCES_WEEKLY_REPORTS,
  ESI_PATIENT_UPDATE_PREFERENCES_SCHEDULED_REMINDERS,
} from './constants';

export const actions = {
  updateWeeklyReports: weekly_reports_enabled => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: '/rails/api/users/preference',
      data: {
        weekly_reports_enabled,
      },
    },
    type: ESI_PATIENT_UPDATE_PREFERENCES_WEEKLY_REPORTS,
  }),
  updateScheduledReminders: scheduled_reminders => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: '/rails/api/users/preference',
      data: {
        scheduled_reminders,
      },
    },
    type: ESI_PATIENT_UPDATE_PREFERENCES_SCHEDULED_REMINDERS,
  }),
};
