import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class WithLoader extends PureComponent {
  static propTypes = {
    isMounted: PropTypes.bool,
    isLoading: PropTypes.bool,
    children: PropTypes.any,
    disableSpinner: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = { isInitialLoading: !this.props.isMounted };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoading && this.props.isLoading !== nextProps.isLoading) {
      this.setState({ isInitialLoading: false });
    }
  }

  render() {
    const { isLoading, children } = this.props;

    return (
      <div className="with-loader">
        <div className={`loader-container ${this.state.isInitialLoading && isLoading ? 'loading' : 'loaded'}`}>
          {children}
        </div>
        {isLoading && this.state.isInitialLoading && !this.props.disableSpinner ? (
          <div className="spinner-container">
            <div className="spinner" />
          </div>
        ) : null}
      </div>
    );
  }
}

export default WithLoader;
