import { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class Changeable extends PureComponent {
  static propTypes = { children: PropTypes.any };

  state = { content: this.props.children };

  render() {
    return this.state.content;
  }
}
