import { PAGE_LIMIT } from '../constants';
import { API_CALL } from '../middleware/api';
import { EventLocation } from '../schemas';
import {
  CLEAR_HISTORY_DATA,
  CLEAR_SCHEDULES,
  DELETE_CAREGIVER,
  DELETE_PATIENT,
  DOWNLOAD_PATIENTS_DATA,
  DOWNLOAD_PATIENT_DATA,
  EVENT_LOCATIONS,
  GET_MEDICATION_DOCS_DETAILS,
  GET_PATIENT_MEDICATION_HISTORY,
  LIST_ALL_ACTIVITY_EVENTS,
  LIST_CAREGIVERS,
  LIST_INVITED_PATIENT,
  LIST_MEDICATION,
  LIST_MEDICATION_DETAILS,
  LIST_OWN_PATIENTS,
  LIST_PATIENT,
  LIST_PATIENT_MEDICATION_HISTORY,
  LIST_PATIENT_RESULT,
  LIST_SHEDULES,
  PATIENT_DETAIL,
  PATIENT_DETAIL_EDIT,
  PATIENT_PORTAL_NOTIFICATIONS_EXISTS,
  POST_ADD_CAREGIVER,
  POST_ADD_MEDICINE,
  START_LOADING,
  TRIAL_TIMESTAMP_RELOAD,
  UPDATE_MEDICATION_STATUS,
  UPDATE_PATIENT_NOTIFICATIONS,
  UPDATE_PATIENT_SCHEDULE,
  UPDATE_PATIENT_SETTINGS,
  UPDATE_PATIENT_STOCK,
  GET_ENROLLMENT_DATE,
} from './action-types';

const qs = require('qs');

export const patientAction = {
  actionList: (pageRequest, isLoading) => ({
    [API_CALL]: {
      endpoint: '/portal/patients/patientList',
      method: 'post',
      data: qs.stringify({
        ...pageRequest,
        limit: PAGE_LIMIT,
      }),
    },
    isLoading,
    type: LIST_PATIENT,
  }),
  patientsLoadComplete: () => ({ type: LIST_PATIENT_RESULT }),

  actionNotificationsExists: () => ({
    [API_CALL]: {
      endpoint: '/portal/patients/notifications/exists',
      method: 'get',
    },
    type: PATIENT_PORTAL_NOTIFICATIONS_EXISTS,
  }),

  actionDeletePatient: (trialId, patientId, callAfterSuccess = []) => ({
    [API_CALL]: {
      endpoint: `/groups/trials/${trialId}/patients/delete`,
      method: 'post',
      data: { patient: patientId },
      callAfterSuccess,
    },
    type: DELETE_PATIENT,
  }),

  startLoading: () => ({ type: START_LOADING }),

  actionPatientDetail: patientId => ({
    [API_CALL]: { endpoint: `/portal/patients/${patientId}/notification/settings` },
    type: PATIENT_DETAIL,
  }),
  actionInvitations: () => ({
    [API_CALL]: { endpoint: '/groups/analytics/patients/invited/' },
    type: LIST_INVITED_PATIENT,
  }),

  actionCaregivers: (patientId, isLoading) => ({
    [API_CALL]: { endpoint: `/portal/patients/${patientId}/caregivers` },
    isLoading,
    type: LIST_CAREGIVERS,
  }),

  actionOwnPatientsList: () => ({
    [API_CALL]: {
      endpoint: '/groups/patients/',
      params: { scope: 'own' },
    },
    type: LIST_OWN_PATIENTS,
  }),

  actionSchedulesList: (id, startDate, endDate, filterDateRangeOfMedsSchedules) => ({
    [API_CALL]: {
      endpoint: `/portal/patients/${id}/medications`,
      params: {
        startDate,
        endDate,
        filterDateRangeOfMedsSchedules,
      },
    },
    type: LIST_SHEDULES,
  }),
  clearMedicine: () => ({
    type: CLEAR_SCHEDULES,
  }),
  actionMedicationList: (id, params) => {
    return {
      [API_CALL]: {
        endpoint: `/portal/patients/${id}/medications`,
        method: 'get',
        params,
      },
      type: LIST_MEDICATION,
    };
  },
  actionMedicationDetails: id => ({
    [API_CALL]: { endpoint: `/portal/medications/${id}` },
    type: LIST_MEDICATION_DETAILS,
  }),

  actionUpdateSchedule: (data, patientId) => ({
    [API_CALL]: {
      endpoint: `/patients/patient/${patientId}/schedules`,
      method: 'put',
      data,
    },
    type: UPDATE_PATIENT_SCHEDULE,
  }),

  actionUpdateStock: (medicationId, data) => ({
    [API_CALL]: {
      endpoint: `/portal/medications/${medicationId}/stock`,
      method: 'put',
      data,
    },
    type: UPDATE_PATIENT_STOCK,
  }),

  //Unused. Fix scheme before using
  actionAllActivityEventsList: (start, end, patients) => ({
    [API_CALL]: {
      endpoint: '/groups/patients/activity/',
      params: {
        start,
        end,
        patients,
      },
      schema: [Patient],
    },
    type: LIST_ALL_ACTIVITY_EVENTS,
  }),

  //Unused. Fix scheme before using
  actionUpdateProfile: (patientId, data) => ({
    [API_CALL]: {
      method: 'put',
      endpoint: `/groups/patients/${patientId}/profile/`,
      data,
      schema: Patient,
    },
    type: UPDATE_PATIENT_SETTINGS,
  }),

  updatePatientProfileByAdmin: (patientId, data) => ({
    [API_CALL]: {
      method: 'put',
      endpoint: `/groups/patients/${patientId}/profile/`,
      data,
    },
    type: PATIENT_DETAIL_EDIT,
  }),

  //Unused. Fix scheme before using
  actionUpdateNotifications: (patientId, data) => ({
    [API_CALL]: {
      method: 'put',
      endpoint: `/groups/patients/${patientId}/notifications/`,
      data,
      schema: Patient,
    },
    type: UPDATE_PATIENT_NOTIFICATIONS,
  }),
  actionEventLocations: (start, end, studyId) => ({
    [API_CALL]: {
      endpoint: '/groups/patients/locations/',
      params:
        start && end
          ? {
              start,
              end,
              studyId,
            }
          : { studyId },
      schema: [EventLocation],
    },
    type: EVENT_LOCATIONS,
  }),

  addMedicine: data => ({
    [API_CALL]: {
      endpoint: `/portal/schedules`,
      method: 'post',
      data,
    },
    type: POST_ADD_MEDICINE,
  }),
  addCaregiver: (patientId, data) => ({
    [API_CALL]: {
      endpoint: `/groups/patients/${patientId}/caregivers/add/`,
      method: 'post',
      data,
    },
    type: POST_ADD_CAREGIVER,
  }),
  deleteCaregiver: (patientId, data) => ({
    [API_CALL]: {
      endpoint: `/groups/patients/${patientId}/caregivers/delete/`,
      method: 'delete',
      data,
    },
    caregiverId: data.id,
    type: DELETE_CAREGIVER,
  }),
  downloadPatientsData: params => ({
    [API_CALL]: {
      endpoint: `/data/patient-export-all-related/`,
      method: 'get',
      // responseType: "blob",
      params,
    },
    type: DOWNLOAD_PATIENTS_DATA,
  }),
  downloadPatientData: data => ({
    [API_CALL]: {
      endpoint: `/data/patient-export/`,
      method: 'get',
      params: {
        search: data.search,
        startDate: data.startDate,
        endDate: data.endDate,
        filterBy: data.filterBy,
        patientId: data.patientId,
        scheduleId: data.scheduleId,
      },
    },
    type: DOWNLOAD_PATIENT_DATA,
  }),

  downloadMedicineData: data => ({
    [API_CALL]: {
      endpoint: `/data/patient-export/medicines`,
      method: 'get',
      params: { search: data.search, startDate: data.startDate, endDate: data.endDate, filterBy: data.filterBy },
    },
    type: DOWNLOAD_PATIENT_DATA,
  }),
  clearHistory: () => ({
    type: CLEAR_HISTORY_DATA,
  }),
  actionPatientTimeDelta: (pageRequest, patientId, loader) => ({
    [API_CALL]: {
      endpoint: `portal/patients/${patientId}/medicationsHistory`,
      method: 'get',
      params: { ...pageRequest },
      loading: true,
    },
    type: LIST_PATIENT_MEDICATION_HISTORY,
    loading: loader,
  }),
  getMedicationsHistory: (pageRequest, patientId) => ({
    [API_CALL]: {
      endpoint: `portal/patients/${patientId}/medicationsHistory`,
      method: 'get',
      params: { ...pageRequest },
    },
    type: GET_PATIENT_MEDICATION_HISTORY,
  }),

  getMedicineDocDetails: (code, language, codeType) => ({
    [API_CALL]: {
      endpoint: `/v2/medicine/details/`,
      method: 'get',
      params: { code, language, codeType },
    },
    type: GET_MEDICATION_DOCS_DETAILS,
  }),

  updateMedicationStatus: data => ({
    [API_CALL]: {
      endpoint: `/portal/medications/status`,
      method: 'post',
      data,
    },
    type: UPDATE_MEDICATION_STATUS,
  }),

  getEnrollmentDate: params => ({
    [API_CALL]: {
      endpoint: `/patients/enrollmentdate`,
      method: 'get',
      params,
    },
    type: GET_ENROLLMENT_DATE,
  }),

  reloadTimeStampGraph: () => ({ type: TRIAL_TIMESTAMP_RELOAD }),
};
