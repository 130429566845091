import {
  ChangeModalVisibility,
  GetDefaultModalsVisibility,
  ChangePageVisibility,
  GetDefaultPagesVisibility,
  SetDefaultPagesVisibility,
} from '../ModalsMetadata';
import { CHANGE_MODAL_VISIBILITY, CHANGE_PAGE_VISIBILITY, RESET_TO_DEFAULT } from './actions';

export const ModalsVisibilityStateReducer = (
  state = {
    pagesVisibility: GetDefaultPagesVisibility(),
    modalsVisibility: GetDefaultModalsVisibility(),
  },
  action,
) => {
  switch (action.type) {
    case CHANGE_PAGE_VISIBILITY:
      return {
        ...state,
        pagesVisibility: ChangePageVisibility(state.pagesVisibility, action.modalId, action.pageId),
      };

    case RESET_TO_DEFAULT:
      return {
        ...state,
        pagesVisibility: SetDefaultPagesVisibility(state.pagesVisibility, action.modalId),
      };

    case CHANGE_MODAL_VISIBILITY:
      return {
        ...state,
        modalsVisibility: ChangeModalVisibility(state.modalsVisibility, action.workflowId, action.modalId),
      };

    default:
      return state;
  }
};
