import { resetForm } from 'actions/forms';
import { closeModal } from 'actions/modal';
import { notificationActions } from 'components/Notification/redux/actions';
import { actions as esiPatientsActions } from 'pages/ESI/Patients/redux/actions';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DEFAULT_TIMEZONE, PAGE_LIMIT, GetTimezoneTextMapping } from '../../constants';
import { Modal } from '../../containers';
import { AddPatientForm } from '../../features';
import Strings from '../../Strings';
import '../editStudyModal.scss';
import { actions } from './redux/actions';
import {
  ESI_INVITE_PATIENT_GET_CAPS_TYPES_RESULT,
  ESI_INVITE_PATIENT_GET_SCHEDULE_DETAILS_RESULT,
  ESI_PATIENTS_EDIT,
  ESI_PATIENTS_EDIT_MEDICATION_RESULT,
  ESI_PATIENTS_EDIT_RESULT,
  ESI_PATIENT_GET_RESULT,
} from './redux/constants';

class AddESIPatientModal extends PureComponent {
  static propTypes = {
    capsTypes: PropTypes.array,
    editPatientModalLoading: PropTypes.bool,
    invitePatient: PropTypes.func,
    showNotification: PropTypes.func,
    onOpen: PropTypes.func,
    onCancel: PropTypes.func,
    getListOfCapsTypes: PropTypes.func,
    onInvitedSuccess: PropTypes.func,
    clearList: PropTypes.func,
  };

  state = {
    isBusy: false,
    patient: null,
    errors: [],
  };

  error(msg = '') {
    alert(`${msg ? `${msg}\n\n` : ''}Refreshing the page`); // eslint-disable-line no-alert
    window.location.reload();
  }

  constructor(props) {
    super(props);

    const { data } = props;

    this.state.isBusy = true;
    props.getListOfCapsTypes().then(r => {
      if (r.type !== ESI_INVITE_PATIENT_GET_CAPS_TYPES_RESULT) {
        this.error();
        return;
      }

      if (data.actionType === ESI_PATIENTS_EDIT && data.id) {
        this.loadPatientDataForEditing(data);
        return;
      }

      this.setState({ isBusy: false });
    });
  }

  getTimezone(tz) {
    const timezones = GetTimezoneTextMapping();
    const allowed = [tz];
    const filtered = Object.keys(timezones).filter(key => allowed.includes(key));
    return filtered.length ? filtered[0] : DEFAULT_TIMEZONE;
  }

  loadPatientDataForEditing(data) {
    const { getPatientData, getScheduleDetails } = this.props;

    getPatientData(data.id).then(r => {
      if (r.type !== ESI_PATIENT_GET_RESULT) {
        this.error("Can't get patient details");
        return;
      }

      if (r.response.medicine) {
        getScheduleDetails(r.response.medicine.cap_type).then(r1 => {
          if (r1.type !== ESI_INVITE_PATIENT_GET_SCHEDULE_DETAILS_RESULT) {
            this.error(`Can't get data for ${r.response.medicine.cap_type}`);
            return;
          }

          this.setState({
            isBusy: false,
            patient: {
              id: data.id,
              data: r.response,
            },
          });
        });
      } else {
        this.setState({
          isBusy: false,
          patient: {
            id: data.id,
            data: r.response,
          },
        });
      }
    });
  }

  patientToInitialValues(data) {
    const { capsTypes } = this.props;

    const cap = data.medicine ? capsTypes.find(x => x.name.toString() === data.medicine.cap_type) : null;

    return {
      patient_firstName: data.first_name,
      patient_lastName: data.last_name,
      email: data.email,
      patient_phone: data.phone,
      timezone: this.getTimezone(data.timezone),
      schedule_capSize: cap ? cap.id : undefined,
      schedule_medicine: data.medicine ? data.medicine.name : undefined,
      schedule_hwid: data.medicine ? data.medicine.cap_id.trim() : undefined,
      schedule_period: data.medicine ? data.medicine.type : undefined,
      schedule_time: data.medicine ? data.medicine.offset[0] : undefined,
      schedule_strength: data.medicine ? data.medicine.strength : undefined,
      shipping_streetAddress: data.shipping ? data.shipping.street_address : undefined,
      shipping_zip: data.shipping ? data.shipping.zip : undefined,
      shipping_state: data.shipping ? data.shipping.state : undefined,
      shipping_city: data.shipping ? data.shipping.city : undefined,
      shipping_country: data.shipping ? data.shipping.country : undefined,
      shipping_apartment: data.shipping ? data.shipping.address_extra : undefined,
    };
  }

  onSubmit = values => {
    this.setState({ errors: [] });
    const {
      capsTypes,
      invitePatient,
      editPatient,
      addPatientMedicine,
      updatePatientMedicine,
      showNotification,
      clearList,
      onInvitedSuccess,
      onCancel,
    } = this.props;

    const { patient } = this.state;

    const cap = values.schedule_capSize
      ? capsTypes.find(x => x.id.toString() === values.schedule_capSize.toString())
      : undefined;

    const requestParams = {
      user: {
        masked_id: this.isEdit() ? this.state.patient.data.masked_id : undefined,
        first_name: values.patient_firstName,
        last_name: values.patient_lastName,
        phone: values.patient_phone,
        email: values.email,
        timezone: this.isEdit() ? this.state.patient.data.timezone : values.timezone,
      },
      medicine: cap
        ? {
            cap_type: cap.name,
            name: values.schedule_medicine,
            type: values.schedule_period,
            strength: values.schedule_strength,
            offset: [parseInt(values.schedule_time, 10)],
            schedule_timezone: this.isEdit() ? this.state.patient.data.timezone : values.timezone,
          }
        : undefined,
      [this.isEdit() ? 'address' : 'shipping']: values.shipping_streetAddress
        ? {
            street_address: values.shipping_streetAddress,
            address_extra: values.shipping_apartment || '',
            zip: values.shipping_zip,
            state: values.shipping_state,
            city: values.shipping_city,
            country: values.shipping_country,
          }
        : undefined,
    };

    if (this.isEdit()) {
      const medicine = requestParams.medicine;
      requestParams.medicine = undefined;

      const promises = [editPatient(this.props.data.id, requestParams)];

      if (medicine) {
        medicine.cap_id = values.schedule_hwid.trim();
        const oldMedicine = patient.data.medicine;

        if (oldMedicine) {
          // Send medicine request only if it was changed
          let isEqual = true;
          Object.keys(medicine).forEach(key => {
            const isOffset = key === 'offset';
            const oldValue = isOffset ? oldMedicine[key][0] : oldMedicine[key];
            const newValue = isOffset ? medicine[key][0] : medicine[key];

            if (oldValue !== newValue) {
              isEqual = false;
            }
          });

          if (!isEqual) {
            promises.push(updatePatientMedicine(this.state.patient.data.medicine.id, medicine));
          }
        } else {
          promises.push(addPatientMedicine(this.props.data.id, medicine));
        }
      }

      Promise.all(promises).then(result => {
        const errors = [];
        if (result[0].type !== ESI_PATIENTS_EDIT_RESULT) {
          if (
            result[0].response &&
            result[0].response.data &&
            result[0].response.data.error &&
            result[0].response.data.error.message
          ) {
            errors.push(result[0].response.data.error.message);
          } else {
            errors.push(Strings.errorSavingPatient);
          }
        }
        if (result[1] && result[1].type !== ESI_PATIENTS_EDIT_MEDICATION_RESULT) {
          if (
            result[1].response &&
            result[1].response.data &&
            result[1].response.data.error &&
            result[1].response.data.error.message
          ) {
            errors.push(result[1].response.data.error.message);
          } else {
            errors.push(Strings.errorPaitentMedication);
          }
        }

        if (errors.length > 0) {
          this.setState({ errors });
          return;
        }

        clearList();
        onInvitedSuccess();
        onCancel();
      });
    } else {
      invitePatient(requestParams).then(response => {
        if (response.error && response.response) {
          if (response.response.data.phone) {
            this.setState({ errors: response.response.data.phone });
          }

          if (response.response.data.email) {
            this.setState({ errors: response.response.data.email });
          }
          return;
        }
        showNotification(Strings.patientAddedSuccessfully);
        clearList();
        onInvitedSuccess();
        onCancel();
      });
    }
  };

  isEdit() {
    const { data } = this.props;
    return Boolean(data.id);
  }

  render() {
    const { onOpen, onCancel, ...props } = this.props;
    const { patient } = this.state;

    return (
      <Modal
        onOpen={onOpen}
        name="new-trial"
        additionalClasses={['form-modal', 'user-invite']}
        withoutContainer
        {...props}
      >
        <AddPatientForm
          errors={this.state.errors}
          isEdit={this.isEdit()}
          onSubmit={this.onSubmit}
          onCancel={onCancel}
          initialValues={patient ? this.patientToInitialValues(patient.data) : undefined}
        />
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  capsTypes: state.esi.invitePatient ? state.esi.invitePatient.capsTypes.data : [],
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getListOfCapsTypes: () => dispatch(actions.getListOfCapsTypes()),
  onCancel: () => dispatch(closeModal('add-esi-patient')),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  invitePatient: patient => dispatch(actions.invitePatient(patient)),
  editPatient: (id, patient) => dispatch(actions.editPatient(id, patient)),
  addPatientMedicine: (id, data) => dispatch(actions.addPatientMedicine(id, data)),
  updatePatientMedicine: (id, data) => dispatch(actions.updatePatientMedicine(id, data)),
  onOpen: () => dispatch(resetForm('add-esi-patient', ownProps.data)),
  clearList: () => dispatch(esiPatientsActions.clearList()),
  onInvitedSuccess: () =>
    dispatch(
      esiPatientsActions.list({
        offset: 0,
        limit: PAGE_LIMIT,
      }),
    ),
  getPatientData: id => dispatch(actions.getPatient(id)),
  getScheduleDetails: capName => dispatch(actions.getScheduleDetails(capName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddESIPatientModal);
