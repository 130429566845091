import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closeModal } from 'actions/modal';

import { Modal } from '../../containers';
import Form from '../../containers/Form';
import { Errors } from '../../components/Login/Errors/Errors';
import Strings from '../../Strings';
import { actions } from '../../pages/SuperUser/Patients/redux/actions';

const EditNoteModal = props => {
  const [note, setNote] = useState(props.data?.note || '');
  const [errors, setErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onTextChange = event => {
    if (event.target.value != ' ') {
      setNote(event.target.value);
    }
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    props.onSubmit({ content: note }).then(response => {
      if (response && response.type === `${props.data.actionType}/result`) props.getNotes();
      if (response && response.type === `${props.data.actionType}/error`) {
        if (response.response && response.response.data) {
          if (response.response.data.error.message) {
            setErrors([response.response.data.error.message]);
          }
        }
      }
      setIsSubmitting(false);
      return response;
    });
  };

  return (
    <Modal
      name="edit-note"
      additionalClasses={['form-modal', 'rounded-borders']}
      caption={props.data.caption !== undefined ? props.data.caption : Strings.addNote}
      {...props}
    >
      <Form onCancel={props.onCancel} id="edit-note">
        <div className="edit-note-form">
          <div>{Strings.text}</div>
          <textarea name="text" id="text" label="" type="text" value={note} onChange={onTextChange} />
          {errors.length > 0 && <Errors errors={errors} />}
        </div>
        <div className="button-bar" key="button-bar">
          <button className="brand-white-gray" key="cancel" type="cancel">
            {Strings.cancel}
          </button>
          <button
            className="brand-blue"
            key="submit"
            type="button"
            onClick={onSubmit}
            disabled={!note || note === props.data?.note || isSubmitting}
          >
            {Strings.save}
          </button>
        </div>
      </Form>
    </Modal>
  );
};

EditNoteModal.propTypes = {
  onOpen: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.shape({
    patientId: PropTypes.any.isRequired,
    noteId: PropTypes.any,
    note: PropTypes.string,
    action: PropTypes.func,
  }),
  getNotes: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('edit-note')),
  onSubmit: note =>
    dispatch(ownProps.data.action(ownProps.data.noteId ? ownProps.data.noteId : ownProps.data.patientId, note)).then(
      response => {
        if (response && response.type === `${ownProps.data.actionType}/result`) {
          dispatch(closeModal('edit-note'));
        }
        return response;
      },
    ),
  getNotes: () => dispatch(actions.getNotes(ownProps.data.patientId)),
});

export default connect(null, mapDispatchToProps)(EditNoteModal);
