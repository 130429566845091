import React from 'react';
import PropTypes from 'prop-types';

import Alerts from './Alerts';
import AlertsConfigurations from './AlertsConfigurations';
import TabView, { Tab } from '../../components/TabView';
import { PageHeader } from '../../components/PageHeader';
import { Button } from '../../components/PageHeader/Button';
import Strings from '../../Strings';

export const ALERTS_TAB = Strings.alerts;
export const CONFIGURATIONS_TAB = Strings.configuration;

function AlertsTabs(props) {
  const [refreshTimestamp, setRefreshTimestamp] = React.useState(0);

  const pageHeaderRightPart = (
    <React.Fragment>
      <Button class="refresh" onClick={() => setRefreshTimestamp(Date.now())} />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <PageHeader left={Strings.myAlerts} right={pageHeaderRightPart} />
      <TabView activeTab={props.activeTab} routeMode noSwiping>
        <Tab name={ALERTS_TAB} path="/alerts/current" key={ALERTS_TAB}>
          <Alerts refreshTimestamp={refreshTimestamp} />
        </Tab>
        <Tab name={CONFIGURATIONS_TAB} path="/alerts/configuration" key={CONFIGURATIONS_TAB}>
          <AlertsConfigurations refreshTimestamp={refreshTimestamp} />
        </Tab>
      </TabView>
    </React.Fragment>
  );
}

AlertsTabs.propTypes = {
  activeTab: PropTypes.string,
};

export default AlertsTabs;
