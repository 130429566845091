import {
  PATIENT_DETAIL,
  PATIENT_DETAIL_RESULT,
  PATIENT_DETAIL_EDIT_RESULT,
  PATIENT_DETAIL_EDIT_ERROR,
  PATIENT_DETAIL_EDIT,
} from '../actions/action-types';

const patientDetailReducer = (state = { patientProfile: {} }, action) => {
  switch (action.type) {
    case PATIENT_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case PATIENT_DETAIL_RESULT:
      return {
        ...state,
        patientProfile: action.response,
        loading: false,
      };

    case PATIENT_DETAIL_EDIT:
      return {
        ...state,
        loading: true,
      };

    case PATIENT_DETAIL_EDIT_RESULT:
      return {
        ...state,
        loading: false,
      };

    case PATIENT_DETAIL_EDIT_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default patientDetailReducer;
