import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Wrapper, Button as MenuButton, Menu, MenuItem } from 'react-aria-menubutton';

import { openModalAction } from '../../../actions/modal';
import AdvancedFilters, { Filter } from '../../../components/AdvancedFilters/AdvancedFilters';
import AdvancedFiltersBar from '../../../components/AdvancedFilters/AdvancedFiltersBar';
import { getFiltersDefinition } from '../../../components/AdvancedFilters/FiltersDefinition';
import { getFiltersForRequest } from '../../../components/AdvancedFilters/helpers';
import { PageHeader } from '../../../components/PageHeader';
import { TextInput } from '../../../components/PageHeader/TextInput';
import Select from '../../../components/Select';
import { DATE_FORMAT_YEAR_MONTH_DAY, DATE_MONTH_DAY_YEAR, PAGE_LIMIT } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { downloadFileOnly } from '../../../utils';
import { DateFilters } from '../../../utils/DateFilters';
import './Billings.scss';
import { actions } from './redux/actions';
import { CUSTOM, GET_TASK_BILLING_RESULT } from './redux/constants';
import { dateRangeModes } from '../../../modals/DateRangeSelectionModal';

class Billings extends PureComponent {
  pageRequest;

  constructor(props) {
    super(props);
    this.state = {
      keyForItemCheckboxes: false,
      isListOpen: false,
    };
  }

  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  onSearchQueryChange = query => {
    return this.onSearchQueryChangeDebounced(query);
  };

  onSearchQueryChangeDebounced = _.debounce(query => {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    this.props.getBilling(this.pageRequest);
  }, 1000);

  handleSelection = (value, event) => {
    switch (value) {
      case 'download_events':
        this.OnDownload();
        break;
      case 'download_summary':
        this.OnDownloadSummary();
        break;
      default:
        break;
    }
  };

  getSecondHeaderComponents() {
    const { isListOpen } = this.state;
    const actionsButton = (
      <Wrapper
        className="addMenu"
        onSelection={this.handleSelection}
        onMenuToggle={({ isListOpen }) => {
          this.setState({ isListOpen });
        }}
      >
        <MenuButton className={`addMenu-button ${isListOpen ? 'expanded' : ''}`}>{Strings.actions}</MenuButton>
        <Menu className="addMenu-menu">
          <MenuItem className="addMenu-menuItem" value="download_events" id="download_e">
            {Strings.downloadEvents}
          </MenuItem>
          <MenuItem className="addMenu-menuItem" value="download_summary" id="download_s">
            {Strings.downloadSummary}
          </MenuItem>
        </Menu>
      </Wrapper>
    );

    return (
      <React.Fragment>
        <TextInput className="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        <Select
          data={[
            DateFilters.Custom,
            DateFilters.ThisMonth,
            DateFilters.Last30Days,
            DateFilters.LastMonth,
            DateFilters.Last6Months,
            DateFilters.ThisYear,
            DateFilters.Last12Months,
            DateFilters.AllTime,
          ]}
          onChange={this.onDateRangeChanged}
          defaultValue={DateFilters.AllTime.value}
        />
        <AdvancedFilters>
          <Filter definition={getFiltersDefinition().billingStatus} />
        </AdvancedFilters>
        {actionsButton}
      </React.Fragment>
    );
  }

  OnDownload = () => {
    const onDateSubmited = dates => {
      const request = {
        startDate: moment(dates.startDate * 1000).format(DATE_FORMAT_YEAR_MONTH_DAY),
        endDate: moment(dates.endDate * 1000).format(DATE_FORMAT_YEAR_MONTH_DAY),
      };
      this.props
        .downloadBillingTaskData(request)
        .then(resp =>
          this.onDownloadBtnClickSuccessHandler(resp, `BillEvents_${request.startDate}_${request.endDate}`),
        );
    };
    this.props.openDateRangeSelectionModal({
      actionOnDone: onDateSubmited,
      mode: dateRangeModes.months,
      title: Strings.downloadEvents,
      submitButtonText: Strings.download,
    });
  };

  OnDownloadSummary = () => {
    const onDateSubmited = dates => {
      const request = {
        startCalendar: moment(dates.startDate * 1000).format(DATE_FORMAT_YEAR_MONTH_DAY),
        endCalendar: moment(dates.endDate * 1000).format(DATE_FORMAT_YEAR_MONTH_DAY),
      };
      this.props
        .downloadBillingSummaryData(request)
        .then(resp =>
          this.onDownloadBtnClickSuccessHandler(resp, `BillSummaryAll_${request.startCalendar}_${request.endCalendar}`),
        );
    };
    this.props.openDateRangeSelectionModal({
      actionOnDone: onDateSubmited,
      mode: dateRangeModes.months,
      title: Strings.downloadSummary,
      submitButtonText: Strings.download,
    });
  };

  onDownloadBtnClickSuccessHandler = (req, filename) => {
    const fileName =
      req?.response && req?.response?.name ? req?.response?.name : filename ? filename : moment().toString();
    downloadFileOnly(req, fileName);
  };

  componentDidMount() {
    this.pageRequest = {
      offset: 0,
      search: '',
      limit: PAGE_LIMIT,
      startDate: '',
      endDate: '',
      filters: [{ status: 'All' }],
    };
    this.props.getBilling(this.pageRequest);
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    if (filters !== prevProps.filters) {
      this.onFiltersChange(filters);
    }
  }

  onFiltersChange = filters => {
    return this.filtersDebounced(filters);
  };

  filtersDebounced = _.debounce(() => {
    const newFiltersForRequest = getFiltersForRequest(this.props.filters);
    if (JSON.stringify(newFiltersForRequest) !== this.props.filters) {
      this.pageRequest.filters = newFiltersForRequest;
      this.pageRequest.offset = 0;
      this.props.getBilling(this.pageRequest);
    }
  }, 1000);

  onPrevClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset - PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getBilling(this.pageRequest);
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.getBilling(this.pageRequest);
  };

  onNextClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset + PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getBilling(this.pageRequest);
  };

  onSortClick = ({ sortKey, direction }) => {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    this.props.getBilling(this.pageRequest);
  };

  onRowSelected = id => {
    const { tasks } = this.props;
    if (tasks && tasks[id].id) {
      this.props.onNavigate(`/billings/${tasks[id].id}`);
    }
  };

  onStatusChange = value => {
    this.pageRequest.status = value;
    this.pageRequest.offset = 0;
    this.props.getBilling(this.pageRequest);
  };

  onDateRangeChanged = dateRange => {
    if (dateRange.value === CUSTOM) {
      const onDateSubmited = dates => {
        this.pageRequest.startDate = moment(dates.startDate * 1000).format(DATE_FORMAT_YEAR_MONTH_DAY);
        this.pageRequest.endDate = moment(dates.endDate * 1000).format(DATE_FORMAT_YEAR_MONTH_DAY);
        this.props.getBilling(this.pageRequest).then(resp => {
          if (resp.type === GET_TASK_BILLING_RESULT && resp.response?.data?.length === 0) {
            this.props.openDateRangeSelectionModal({
              actionOnDone: onDateSubmited,
              text: Strings.noDataInGivenPeriod,
              startDate: moment(dates.startDate * 1000),
              endDate: moment(dates.endDate * 1000),
            });
          }
        });
      };
      this.props.openDateRangeSelectionModal({ actionOnDone: onDateSubmited });
    } else {
      if (dateRange) {
        this.pageRequest.startDate = moment(dateRange.dates.startDate()).format(DATE_FORMAT_YEAR_MONTH_DAY);
        this.pageRequest.endDate = moment(dateRange.dates.endDate()).format(DATE_FORMAT_YEAR_MONTH_DAY);
      } else {
        this.pageRequest.startDate = '';
        this.pageRequest.endDate = '';
      }
      this.pageRequest.offset = 0;
      this.props.getBilling(this.pageRequest);
    }
  };

  onStatusUpdate = (event, task, status) => {
    event.preventDefault();
    event.stopPropagation();

    const newStatus = { taskStatus: status === 'Submitted' ? 'submitted' : 'onhold' };

    const data = {
      status: newStatus.taskStatus,
      id: task.id,
    };

    this.props.updateStatus(data).then(() => {
      this.props.getBilling(this.pageRequest);
    });
  };

  getStatus = d => {
    switch (d.status) {
      case 'onhold':
        return (
          <div className="icon-and-text-container" onClick={e => this.onStatusUpdate(e, d, 'Submitted')}>
            <div className="icon-container hold" />
            {Strings.billingStatus.onhold}
          </div>
        );
      case 'pending':
        return (
          <div className="icon-and-text-container" onClick={e => this.onStatusUpdate(e, d, 'Submitted')}>
            <div className="icon-container pending" /> {Strings.billingStatus.pending}
          </div>
        );
      case 'submitted':
        return (
          <div className="icon-and-text-container" onClick={e => this.onStatusUpdate(e, d, 'onhold')}>
            <div className="icon-container tick" />
            {Strings.billingStatus.submitted}
          </div>
        );
      default:
        return `Undefined(${d.status})`;
    }
  };

  itemChecked = ({ target }) => {
    if (target.checked === true) {
      this.itemSelectorHelper.addItem(target.id);
    } else {
      this.itemSelectorHelper.removeItem(target.id);
    }

    if (this.itemSelectorHelper.getItems().length !== 0) {
      this.setState({ openActionWidget: true });
    } else {
      this.setState({ openActionWidget: false });
    }

    this.setState({ multiselectedItems: this.itemSelectorHelper.isMultiselect() });
  };

  render() {
    const { tasks, pagination, isLoading } = this.props;

    const columns = [];
    columns.push(
      <Column
        key="mrn"
        title={Strings.capPatient.mrnNumber}
        value={d => (
          <React.Fragment>
            <div className="cell-with-select">
              <div className="selector">
                <input
                  type="checkbox"
                  className="item-checkbox"
                  id={d.patientExtID}
                  onChange={this.itemChecked}
                  key={this.state.keyForItemCheckboxes}
                />
              </div>
              <div className="selector-label">{d.patientExtID}</div>
            </div>
          </React.Fragment>
        )}
        sortKey="patientExtID"
        className="clickable"
        fixed
      />,
    );
    columns.push(
      <Column
        key="patientName"
        title={Strings.capPatient.patientName}
        value={d => (
          <div>
            <div className="light-lable">{d.patientName}</div>
          </div>
        )}
      />,
    );

    columns.push(
      <Column
        key="patientDOB"
        title={Strings.capPatient.dob}
        value={d => (d.patientDOB ? moment(d.patientDOB).format(DATE_MONTH_DAY_YEAR) : '-')}
      />,
    );
    columns.push(
      <Column
        key="startDate"
        title={Strings.capPatient.startDate}
        value={d => (d.startDate ? moment(d.startDate).format(DATE_MONTH_DAY_YEAR) : '-')}
      />,
    );

    columns.push(
      <Column
        key="endDate"
        title={Strings.capPatient.endDate}
        value={d => (d.endDate ? moment(d.endDate).format(DATE_MONTH_DAY_YEAR) : '-')}
        sortKey="endDate"
      />,
    );
    columns.push(
      <Column key="cptCode" title={Strings.capPatient.cptCode} value={d => (d.cptCode ? d.cptCode : '-')} />,
    );

    columns.push(
      <Column key="status" title={Strings.capPatient.status} value={d => (d.status ? this.getStatus(d) : '-')} />,
    );

    const headerTitle =
      !isLoading && tasks && pagination
        ? `${Strings.showingXHubs.showing} ${tasks.length} ${Strings.showingXHubs.of} ${
            pagination.totalRecords ? pagination.totalRecords : 0
          }`
        : Strings.showingWait;

    return (
      <div className="billing-container">
        <PageHeader left={headerTitle} right={() => this.getSecondHeaderComponents()} />
        <AdvancedFiltersBar />
        <Table
          name="task-table"
          isLoading={isLoading}
          data={tasks || []}
          onRowSelection={this.onRowSelected}
          onPrevClick={this.onPrevClick}
          onSortClick={this.onSortClick}
          onNextClick={this.onNextClick}
          onCustomPage={this.onCustomPage}
          pagination={
            pagination || {
              offset: 0,
              total: 0,
            }
          }
          enableColumnFiltering
          uuid="81d8a3fb-4542-434f-ba68-92bb13438759"
        >
          {columns}
        </Table>
      </div>
    );
  }
}

Billings.propTypes = {
  downloadBillingTaskData: PropTypes.func,
  downloadBillingSummaryData: PropTypes.func,
  getBilling: PropTypes.func,
  isLoading: PropTypes.any,
  onNavigate: PropTypes.func,
  pagination: PropTypes.shape({
    offset: PropTypes.number,
    total: PropTypes.number,
  }),
  tasks: PropTypes.array,
  updateStatus: PropTypes.func,
  openDateRangeSelectionModal: PropTypes.func,
};

const mapStateToProps = state => {
  const { careBilling } = state.superUser;
  return {
    role: state.auth.role,
    tasks: careBilling?.tasks,
    pagination: careBilling?.pagination,
    isLoading: careBilling?.isLoading,
    filters: state.entities.advancedFilters.filters.items,
  };
};

const mapDispatchToProps = dispatch => ({
  updateStatus: data => dispatch(actions.updateTaskStatus(data)),
  onNavigate: path => dispatch(push(path)),
  getBilling: pageRequest => dispatch(actions.getCareDxBillingTaskList(pageRequest)),
  getDeviceFilter: () => dispatch(actions.getDeviceFilter()),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  downloadBillingTaskData: request => dispatch(actions.downloadBillingTaskData(request)),
  downloadBillingSummaryData: request => dispatch(actions.downloadBillingSummaryData(request)),
  openDateRangeSelectionModal: data => dispatch(openModalAction('data-range-selection-modalion', data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Billings);
