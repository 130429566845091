import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions } from './redux/actions';

const Lab = props => {
  return (
    <React.Fragment>
      <h3>Experimental features</h3>
      {props.labFeatures?.map(f => (
        <div key={`feature_${f.name}`} className="modals-checkboxes">
          <input
            type="checkbox"
            className="filter-label"
            checked={f.selected}
            onChange={() => props.changeLabOption(f.name)}
          />
          {f.name}
        </div>
      ))}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    labFeatures: state.labState.features,
  };
};

Lab.propTypes = {
  changeLabOption: PropTypes.func,
  labFeatures: PropTypes.any,
};

const mapDispatchToProps = dispatch => ({
  changeLabOption: feature => dispatch(actions.changeLabOption(feature)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Lab);
