import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { resetForm } from '../../actions/forms';
import { closeModal } from '../../actions/modal';
import LoadingRenderer from '../../components/LoadingRenderer';
import { Switch2 } from '../../components/Switch2/Switch2';
import { Modal } from '../../containers';
import Form from '../../containers/Form';
import { actions } from '../../pages/SuperUser/Provisioning/redux/actions';
import {
  GET_IMEI_FROM_ID_MFG_RESULT,
  POST_PROVISIONING_DEVICES_RESULT,
  PUT_PROVISIONING_DEVICES_RESULT,
} from '../../pages/SuperUser/Provisioning/redux/constants';
import Strings from '../../Strings';

class AddEditProvisioning extends PureComponent {
  constructor(props) {
    super(props);

    if (props.data.request.DeviceSN) {
      const data = props.data.request;
      this.state = {
        DeviceSN: data.DeviceSN,
        DeviceImei: data.DeviceImei,
        CustomerID: data.CustomerID,
        TestDevice: data.TestDevice,
        ApiUrl: data.ApiUrl,
        FotaUrl: data.FotaUrl,
        Features: data.Features,
        Devices: data.Devices,
        update: true,
        errors: [],
        type: props.data.type,
      };

      if (data.ApiUrl != undefined) {
        this.state.apiURLCheck = true;
      }

      if (data.FotaUrl != undefined) {
        this.state.fotaURLCheck = true;
      }
    } else {
      this.state = {
        DeviceSN: '',
        DeviceImei: '',
        CustomerID: 0,
        TestDevice: true,
        Features: '',
        Devices: '',
        apiURLCheck: true,
        fotaURLCheck: true,
        errors: [],
        type: props.data.type,
      };
    }
  }

  componentDidMount() {}

  onSave = () => {
    const body = {
      DeviceSN: this.state.DeviceSN,
      DeviceImei: this.state.DeviceImei,
      CustomerID: 0,
      TestDevice: this.state.TestDevice,
      ApiUrl: this.state.ApiUrl,
      FotaUrl: this.state.FotaUrl,
      Features: this.state.Features,
      Devices: this.state.Devices,
    };

    this.props.addDevice(body).then(res => {
      if (res.type === POST_PROVISIONING_DEVICES_RESULT) {
        if (this.props.data.action) {
          this.props.postAction();
        }
        this.props.onCancel();
      } else {
        this.setState({ errors: [res.response.data.error.message] });
      }
    });
  };

  onUpdate = () => {
    const body = {
      DeviceSN: this.state.DeviceSN,
      DeviceImei: this.state.DeviceImei,
      CustomerID: this.state.CustomerID,
      TestDevice: this.state.TestDevice,
      ApiUrl: this.state.ApiUrl,
      FotaUrl: this.state.FotaUrl,
      Features: this.state.Features,
      Devices: this.state.Devices,
    };

    this.props.updateDevice(body).then(res => {
      if (res.type === PUT_PROVISIONING_DEVICES_RESULT) {
        if (this.props.data.action) {
          this.props.postAction();
        }
        this.props.onCancel();
      } else {
        this.setState({ errors: [res.response.data.error.message] });
      }
    });
  };

  onTestDevice = (sw, event) => {
    sw.setChecked(event);
    this.setState({ TestDevice: event });
  };

  onApiUrlCheck = (sw, event) => {
    sw.setChecked(event);
    this.setState({ apiURLCheck: event });
  };

  onFotaUrlCheck = (sw, event) => {
    sw.setChecked(event);
    this.setState({ fotaURLCheck: event });
  };

  onApiUrlChange = event => {
    this.setState({ ApiUrl: event.target.value });
  };

  onFotaUrlChange = event => {
    this.setState({ FotaUrl: event.target.value });
  };

  onFeaturesChange = event => {
    this.setState({ Features: event.target.value });
  };

  onDevicesChange = event => {
    this.setState({ Devices: event.target.value });
  };

  render() {
    const { onOpen, onCancel, AttachCaptoAdminModalLoading, ...props } = this.props;

    const {
      DeviceSN,
      DeviceImei,
      TestDevice,
      ApiUrl,
      FotaUrl,
      Features,
      Devices,
      apiURLCheck,
      fotaURLCheck,
      update,
      errors,
    } = this.state;

    const errorsStr = errors.map(e => <div style={{ margin: 0 }}>{e}</div>);

    return (
      <Modal name="add-edit-provisioning" onOpen={onOpen} additionalClasses={['form-modal', 'user-invite']} {...props}>
        <LoadingRenderer>
          <Form onCancel={onCancel} id="add-edit-provisioning" className="add-medicine">
            <div className="row">
              <label>Enter device ID</label>
              <input
                type="text"
                placeholder="Enter ID"
                value={DeviceSN}
                onChange={e => {
                  this.setState({ DeviceSN: e.target.value });
                }}
              />
            </div>
            <div className="row" style={{ marginBottom: '25px' }}>
              <label>IMEI: {DeviceImei}</label>
            </div>
            <div className="row" style={{ marginBottom: '15px' }}>
              <label>Test Device</label>
              <Switch2 checked={TestDevice} onChange={this.onTestDevice} />
            </div>
            <div>
              <label>API URL</label>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '17.5px',
                }}
              >
                <Switch2 checked={apiURLCheck} onChange={this.onApiUrlCheck} />
                {apiURLCheck ? (
                  <input
                    type="text"
                    placeholder="Enter API URL"
                    style={{
                      marginLeft: '15px',
                      marginBottom: '0px',
                    }}
                    value={ApiUrl}
                    onChange={this.onApiUrlChange}
                  />
                ) : (
                  <div style={{ height: '40.25px' }}></div>
                )}
              </div>
            </div>
            <div>
              <label>FOTA URL</label>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '17.5px',
                }}
              >
                <Switch2 checked={fotaURLCheck} onChange={this.onFotaUrlCheck} />
                {fotaURLCheck ? (
                  <input
                    type="text"
                    placeholder="Enter FOTA URL"
                    style={{
                      marginLeft: '15px',
                      marginBottom: '0px',
                    }}
                    value={FotaUrl}
                    onChange={this.onFotaUrlChange}
                  />
                ) : (
                  <div style={{ height: '40.25px' }}></div>
                )}
              </div>
            </div>
            <div>
              <label>Features</label>
              <input type="text" placeholder="Enter Features" value={Features} onChange={this.onFeaturesChange} />
            </div>
            {this.state.type === 'lte' ? (
              <div></div>
            ) : (
              <div>
                <label>Devices</label>
                <input type="text" placeholder="Enter Device ID" value={Devices} onChange={this.onDevicesChange} />
              </div>
            )}
            <div key="errors-block" className="errors-block">
              {errorsStr}
            </div>
            <div className="button-bar  reverse" key="button-bar" style={{ marginTop: '25px' }}>
              {!update ? (
                <button className="brand-blue" key="submit" type="button" onClick={this.onSave}>
                  {Strings.save}
                </button>
              ) : (
                <button className="brand-blue" key="submit" type="button" onClick={this.onUpdate}>
                  {Strings.update}
                </button>
              )}
              <button className="white gray-text mr-15" key="cancel" type="cancel">
                {Strings.cancel}
              </button>
            </div>
          </Form>
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  AttachCaptoAdminModalLoading: state.entities.caps.loading,
  organizations: state.superUser?.organizations?.organizationAll,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  addDevice: deviceData =>
    dispatch(actions.addDevice(deviceData)).then(response => {
      return response;
    }),
  updateDevice: deviceData =>
    dispatch(actions.updateDevice(deviceData)).then(response => {
      return response;
    }),
  onCancel: () => dispatch(closeModal('add-edit-provisioning')),
  onOpen: () => dispatch(resetForm('add-edit-provisioning', ownProps.data)),
  postAction: () => dispatch(ownProps.data.action(ownProps.data.pageRequest)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditProvisioning);
