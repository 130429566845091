import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Strings from '../../Strings';
import { TextInput } from '../../components/Inputs/TextInput';
import { PasswordInput } from '../../components/Inputs/PasswordInput';
import { Button, BUTTON_TYPE_BLUE } from '../../components/Buttons/Button';
import { Errors } from '../../components/Login/Errors/Errors';
import { ButtonRow } from '../../components/Login/ButtonRow/ButtonRow';

export class LoginForm extends PureComponent {
  static propTypes = {
    onForgotClick: PropTypes.func,
    state: PropTypes.any,
    errors: PropTypes.any,
    onLogin: PropTypes.func,
    isEnabled: PropTypes.any,
    isLoading: PropTypes.any,
    textInput: PropTypes.any,
    onChange: PropTypes.func,
  };

  textInput = React.createRef();

  componentDidMount() {
    if (this.textInput && this.textInput.current) {
      this.textInput.current.focus();
    }
  }

  render() {
    const { state, errors, onLogin, onChange, onForgotClick, isEnabled, isLoading } = this.props;

    return (
      <form onSubmit={onLogin}>
        <TextInput
          onChange={onChange}
          name="username"
          autoComplete="off"
          placeholder={Strings.userNameEmail}
          value={state.username}
          refLink={this.textInput}
          align="left"
          data-testid="username"
        />

        <PasswordInput
          onChange={onChange}
          name="password"
          autoComplete="off"
          align="left"
          value={state.password}
          placeholder={Strings.password}
          data-testid="password"
        />

        <Errors errors={errors} />

        <ButtonRow
          left={
            <Button type="button" onClick={onForgotClick} disabled={isLoading}>
              {Strings.forgotPassword}
            </Button>
          }
          right={
            <Button buttonType={BUTTON_TYPE_BLUE} disabled={!isEnabled || isLoading} type="submit" data-testid="submit">
              {Strings.login}
            </Button>
          }
        />
      </form>
    );
  }
}
