import React from 'react';
import PropTypes from 'prop-types';

import { AdherencePiePlotComponent, Box } from './base';
import Strings from '../../Strings';
import { emptyPieChartSVG, emptyPieChartSVGNoData } from '../../utils';

const PatientAdherencePiePlot = ({ data, isLoading, label, additionalClass }) => {
  const plotData = [
    {
      className: `new-green ${additionalClass}`,
      value: data.adherence,
      label: Strings.complied,
    },
    {
      className: 'new-lightblue',
      value: 100 - data.adherence,
      label: Strings.skipped,
    },
  ];

  function mainLabel() {
    return [
      {
        text: data ? `${data.adherence}%` : '',
        className: '',
      },
    ];
  }

  if (plotData && (isLoading || isLoading === undefined)) {
    return <Box>{isLoading || isLoading === undefined ? emptyPieChartSVG() : emptyPieChartSVGNoData()}</Box>;
  }
  return (
    <Box isLoading={isLoading}>
      <AdherencePiePlotComponent data={plotData} mainLabel={mainLabel} label={label} />
    </Box>
  );
};

PatientAdherencePiePlot.propTypes = {
  additionalClass: PropTypes.any,
  data: PropTypes.shape({ adherence: PropTypes.any }),
  isLoading: PropTypes.bool,
  label: PropTypes.any,
};

export default PatientAdherencePiePlot;
