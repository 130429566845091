import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import TableWithLocalPagination, { Column } from '../../../containers/Table/TableWithLocalPagination';
import Strings from '../../../Strings';
import { DATE_FORMAT_MONTH_NAME } from '../../../constants';
import '../CloudDocuments.scss';

function CloudContentTable(props) {
  const sizeConverter = size => {
    if (size < 1000000) return `${Math.round((size / 1000 + Number.EPSILON) * 100) / 100} KB`;
    return `${Math.round((size / 1000000 + Number.EPSILON) * 100) / 100} MB`;
  };

  const columns = [
    <Column key="name" title={Strings.name} value={e => e.name} />,
    <Column
      key="created_at"
      title={Strings.created}
      value={e => moment(e.created_at).format(DATE_FORMAT_MONTH_NAME)}
    />,
    <Column key="size" title={Strings.size} value={e => sizeConverter(e.size)} />,
  ];

  const download = (id, rowData) => {
    if (props.provider === 'box') {
      props.downloadHandler(rowData.id);
    } else {
      props.downloadHandler(rowData.name);
    }
  };

  const preview = (id, rowData) => {
    props.previewHandler(rowData.id);
  };

  const buttons = [
    {
      icon: 'download',
      onClick: download,
      disabled: () => {
        return false;
      },
      text: Strings.download,
    },
  ];

  if (props.provider === 'box') {
    buttons.push({
      icon: 'preview',
      onClick: preview,
      disabled: () => {
        return false;
      },
      text: Strings.preview,
    });
  }

  return <TableWithLocalPagination isLoading={props.isLoading} data={props.data} columns={columns} buttons={buttons} />;
}

CloudContentTable.propTypes = {
  downloadHandler: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  provider: PropTypes.string.isRequired,
};

export default CloudContentTable;
