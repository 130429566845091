import { USER_ACTIVATE_RESULT, USER_ACTIVATE_ERROR } from '../actions/action-types';

const userActivateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACTIVATE_RESULT:
      return {
        ...state,
        error: false,
      };
    case USER_ACTIVATE_ERROR:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default userActivateReducer;
