const LS_SELECTED_BRAND = 'selected-brand';
const DEFAULT_BRAND = 'rxcap';

export const supportedBrands = {
  esi: {
    name: 'esi',
  },
  caredx: {
    name: 'AlloHome',
  },
  rxcap: {
    name: 'RxCap',
  },
  gxcap: {
    name: 'Gx-Cap',
  },
};

export function setBrandFromLS() {
  let brand = DEFAULT_BRAND;
  try {
    brand = localStorage.getItem(LS_SELECTED_BRAND) || DEFAULT_BRAND;
  } catch (e) {}
  document.documentElement.className = brand;
}

export function setBrand(brand) {
  const brandToSet = supportedBrands[brand] ? brand : DEFAULT_BRAND;
  if (localStorage) {
    try {
      localStorage.setItem(LS_SELECTED_BRAND, brandToSet);
    } catch (e) {}
  }
  document.documentElement.className = brandToSet;
}

export function getVariablesForBrand() {
  const brand = document.documentElement.className;
  return supportedBrands[brand] || supportedBrands[DEFAULT_BRAND];
}
