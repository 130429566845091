import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Strings from '../../Strings';
import Wizard from '../../containers/Modal/Wizard';
import { notificationActions } from '../../components/Notification/redux/actions';
import './EulaModal.scss';
import { eulaActions } from '../../actions/user-settings';
import { ACCEPT_EULA_ERROR } from '../../actions/action-types';
import { closeModal } from '../../actions/modal';

const SCROLLBAR_BOTTOM_REACHED_MARGIN = 5;
const EulaModal = props => {
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);
  const [pageScrolled, setPageScrolled] = useState(false);
  const ref = useRef();

  function onSubmit() {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);

    props.onAcceptEulaAndTC().then(result => {
      if (result.type === ACCEPT_EULA_ERROR) {
        props.showNotification(result.response?.data?.error?.message, 5000, true);
        setPageScrolled(false);
        setSaveBtnClicked(false);
        return false;
      } else {
        props.closeModal();
        return true;
      }
    });
    return false;
  }

  useEffect(() => {
    scrollEvent({ target: ref.current });
  }, [ref.current]);

  const scrollEvent = e => {
    const { target } = e;
    const { scrollHeight, scrollTop, clientHeight } = target;
    const isBottomReached = scrollHeight - Math.round(scrollTop) - SCROLLBAR_BOTTOM_REACHED_MARGIN <= clientHeight;
    if (isBottomReached) {
      setPageScrolled(true);
    }
  };

  const pageContentEula = <React.Fragment>{props.data.eula_content}</React.Fragment>;
  const pageContentTC = <React.Fragment>{props.data.tc_content}</React.Fragment>;
  const pages = [
    {
      id: 'eula',
      title: Strings.endUserLicenseAgreement,
      content: pageContentEula,
      emptyFieldsCount: 0,
      canGoNext: !saveBtnClicked && pageScrolled,
      nextButton: { text: Strings.accept },
      onNext: () => {
        setPageScrolled(false);
        setTimeout(() => {
          scrollEvent({ target: ref.current });
        }, 100);
        return true;
      },
    },
    {
      id: 'terms-and-conditions',
      title: Strings.termsAndConditions,
      content: pageContentTC,
      emptyFieldsCount: 0,
      canGoNext: !saveBtnClicked && pageScrolled,
      nextButton: { text: Strings.accept },
    },
  ];

  return (
    <Wizard name="eula" pages={pages} showPagesFilter={false} onScroll={scrollEvent} ref={ref} onSubmit={onSubmit} />
  );
};

EulaModal.propTypes = {
  data: PropTypes.shape({
    eula: PropTypes.shape({
      eula_content: PropTypes.string,
      tc_content: PropTypes.string,
      version: PropTypes.string,
    }),
  }),
  closeModal: PropTypes.func,
  onAcceptEulaAndTC: PropTypes.func,
  showNotification: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAcceptEulaAndTC: () => dispatch(eulaActions.acceptEulaAndTC({ version: ownProps.data.version })),
    showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
    closeModal: () => dispatch(closeModal('eula')),
  };
};

export default connect(null, mapDispatchToProps)(EulaModal);
