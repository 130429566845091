export const SHIPPER_CAPS = 'shipper-caps';
export const SHIPPER_CAPS_RESULT = `${SHIPPER_CAPS}/result`;
export const SHIPPER_CAPS_CANCEL = `${SHIPPER_CAPS}/cancel`;
export const SHIPPER_CAPS_CANCELED = `${SHIPPER_CAPS}/canceled`;

export const ORGANIZATION_LIST = 'organization-list';
export const ORGANIZATION_LIST_RESULT = `${ORGANIZATION_LIST}/result`;
export const ORGANIZATION_LIST_ERROR = `${ORGANIZATION_LIST}/ERROR`;

export const DOWNLOAD_REPORT_DATA = 'download-report-data';
export const DOWNLOAD_REPORT_DATA_RESULT = `${DOWNLOAD_REPORT_DATA}/result`;
export const DOWNLOAD_REPORT_DATA_ERROR = `${DOWNLOAD_REPORT_DATA}/error`;

export const DELETE_CAPS = 'delete-caps';
export const DELETE_CAPS_RESULT = `${DELETE_CAPS}/result`;
export const DELETE_CAPS_ERROR = `${DELETE_CAPS}/error`;

export const SEND_SECURITY_CODE_CAP = 'send-security-code';
export const SEND_SECURITY_CODE_CAP_RESULT = 'send-security-code/result';
export const SEND_SECURITY_CODE_CAP_ERROR = 'send-security-code/error';

export const FEATURE_CONFIGURATION = 'feature-configuration';
export const FEATURE_CONFIGURATION_RESULT = 'feature-configuration/result';
export const FEATURE_CONFIGURATION_ERROR = 'feature-configuration/error';

export const DELETE_USER_CAPS = 'delete-user-caps';
export const DELETE_USER_CAPS_RESULT = `${DELETE_USER_CAPS}/result`;
export const DELETE_USER_CAPS_ERROR = `${DELETE_USER_CAPS}/ERROR`;

export const GET_DEVICE_STATUS_HISTORY = 'get-device-status-history';
export const GET_DEVICE_STATUS_HISTORY_RESULT = `${GET_DEVICE_STATUS_HISTORY}/result`;
export const GET_DEVICE_STATUS_HISTORY_ERROR = `${GET_DEVICE_STATUS_HISTORY}/error`;

export const CHANGE_DEVICE_STATUS_HISTORY = 'change-device-status-history';
export const CHANGE_DEVICE_STATUS_HISTORY_RESULT = `${CHANGE_DEVICE_STATUS_HISTORY}/result`;
export const CHANGE_DEVICE_STATUS_HISTORY_ERROR = `${CHANGE_DEVICE_STATUS_HISTORY}/error`;
