import React from 'react';
import { FileDrop } from 'react-file-drop';
import PropTypes from 'prop-types';

import Strings from '../../../Strings';

export default function FirstUploadDrop(props) {
  const fileInput = React.createRef();

  const triggerInputFile = () => {
    if (fileInput.current != undefined && fileInput.current.click != undefined) fileInput.current.click();
  };

  return (
    <FileDrop
      onDrop={(files, event) => {
        props.onUpload(files[0]);
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <input
        type="file"
        name="file"
        ref={fileInput}
        onChange={e => {
          props.onUpload(e.target.files[0]);
        }}
      />
      <div className="cd-gray-border">
        <div className="cd-first-upload">
          <div className="progress-border" onDragEnter={e => e.preventDefault()} onDragOver={e => e.preventDefault()}>
            <div className="progress-container">
              <div className={`progress ${props.uploading ? 'rotating' : ''}`}>
                <div className={`upload-icon ${props.uploading ? 'rotating' : ''}`} />
              </div>
            </div>
          </div>
          <div className="cd-first-upload-text">{Strings.dropOr}</div>
          <button className="brand-blue cd-first-upload-button" onClick={() => triggerInputFile()}>
            {Strings.chooseFile}
          </button>
        </div>
      </div>
    </FileDrop>
  );
}

FirstUploadDrop.propTypes = {
  onUpload: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
};
