import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, PieChart, Pie, Sector } from 'recharts';

import './graph.scss';
import Strings from '../../Strings';

const renderActiveShape = props => {
  const { cx, cy, innerRadius, outerRadius, cornerRadius, startAngle, endAngle, fill, payload } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={18} textAnchor="middle" className="doughnutCenterText">
        {Object.keys(payload).includes('totalValue') ? `${payload.totalValue}%` : ''}
      </text>
      <text x={cx} y={cy} dy={18} textAnchor="middle" className="doughnutCenterText top">
        {!Object.keys(payload).includes('totalValue') ? payload.value : ''}
      </text>
      <text x={cx} y={cy} dy={18} textAnchor="middle" className="doughnutCenterText bottom">
        {!Object.keys(payload).includes('totalValue') ? payload.label : ''}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        cornerRadius={cornerRadius}
      />
    </g>
  );
};

export default function PieGraph(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const { graphData, hasBorder } = props;

  return (
    <div className="graphDoctorDashboardContainer">
      <div className={`graphDiv withPadding  ${hasBorder ? 'withBorder' : ''}`}>
        <ResponsiveContainer width="100%" height="99%" debounce>
          <PieChart width={400} height={400}>
            <Pie
              data={[{ value: 100 }]}
              dataKey="value"
              innerRadius="86%"
              outerRadius="100%"
              fill="#F1F1F1"
              stroke="F1F1F1"
              isAnimationActive={false}
            />
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={graphData}
              innerRadius="86%"
              outerRadius="100%"
              dataKey="value"
              startAngle={90}
              endAngle={450}
              minAngle={5}
              onMouseEnter={(data, index) => setActiveIndex(index)}
              onMouseLeave={() => setActiveIndex(0)}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

PieGraph.propTypes = {
  graphData: PropTypes.array,
  hasBorder: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  tooltipContentLines: PropTypes.func,
  tooltipDateFormat: PropTypes.string,
};
