import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { notificationActions } from 'components/Notification/redux/actions';

import { actionsTZ } from '../../components/TZ/redux/actions';
import { SelectField } from '../../containers/Form';
import Strings from '../../Strings';
import '../editStudyModal.scss';
import { GetTimezoneTextMapping } from '../../constants';
import { TZ_UPDATE_USER_TIMEZONE_RESULT } from '../../components/TZ/redux/const';
import Wizard from '../../containers/Modal/Wizard';
import './TimezoneChangeModal.scss';

const mapStateToProps = state => {
  return {
    timezone: state.auth?.profile?.preferences?.timezone,
  };
};

const mapDispatchToProps = dispatch => ({
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  updateUserTimezone: timezone => dispatch(actionsTZ.updateUserTimezone(timezone)),
});

class TimezoneChangeModal extends PureComponent {
  static MODAL_NAME = 'timezone-change-modal';
  state = {
    timezone: this.props.timezone,
  };

  onTimezoneChange = option => {
    this.setState({ timezone: option.value });
  };

  onSubmit = () => {
    const { timezone } = this.state;

    this.props.updateUserTimezone(timezone).then(resp => {
      if (resp.type === TZ_UPDATE_USER_TIMEZONE_RESULT) {
        window.location.reload();
      } else {
        this.props.showNotification('Error while updating timezone settings', 5000);
      }
    });
  };

  render() {
    const { timezone } = this.state;
    const timezones = GetTimezoneTextMapping();

    const page = (
      <SelectField
        name="timezone"
        id="timezone"
        data={Object.keys(timezones).map(e => ({
          label: timezones[e],
          value: e,
        }))}
        onChange={this.onTimezoneChange}
        defaultValue={timezone}
        placeholder={timezone}
      />
    );

    return (
      <Wizard
        name={TimezoneChangeModal.MODAL_NAME}
        pages={[
          {
            id: TimezoneChangeModal.MODAL_NAME,
            title: Strings.selectTimezone,
            content: page,
            canGoNext: true,
          },
        ]}
        onSubmit={this.onSubmit}
        showPagesFilter={false}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimezoneChangeModal);
