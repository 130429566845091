import moment from 'moment-timezone';
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { PageHeader } from '../../components/PageHeader';
import TableWithPagination, { Column } from '../../containers/Table/TableWithPagination';
import Strings from '../../Strings';
import { DATE_MONTH_DAY_YEAR, PAGE_LIMIT } from '../../constants';
import { TextInput } from '../../components/PageHeader/TextInput';
import Select from '../../components/Select';
import AdvancedFilters from '../../components/AdvancedFilters/AdvancedFilters';
import { idRenderer, conditionRenderer, priorityRenderer, typeRenderer } from './helpers';
import { AlertSummaryWidget } from './AlertWidget';
import { actions, ALERTS_LIST_RESULT, ALERTS_PRIORITIES_SUMMARY_RESULT, DELETE_ALERT_RESULT } from './redux/actions';
import './alerts.scss';
import { ActionWidget, ActionButton, ItemSelectorHelper } from '../../components/ActionWidget';
import { notificationActions } from '../../components/Notification/redux/actions';
import { DateFilters } from '../../utils/DateFilters';

function Alerts(props) {
  const [currentShowingCount, setCurrentShowingCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [highPriotyAlertsCount, setHighPriotyAlertsCount] = useState(0);
  const [mediumPriotyAlertsCount, setMediumPriotyAlertsCount] = useState(0);
  const [lowPriotyAlertsCount, setLowPriotyAlertsCount] = useState(0);
  const [alerts, setAlerts] = useState([]);
  const [pagination, setPagination] = useState({});
  const [showActionWidget, setShowActionWidget] = useState(false);

  const itemSelectorHelper = useRef(new ItemSelectorHelper());

  const itemChecked = ({ target }) => {
    if (target.checked) {
      itemSelectorHelper.current.addItem(target.id);
    } else {
      itemSelectorHelper.current.removeItem(target.id);
    }

    if (itemSelectorHelper.current.getItems().length !== 0) {
      setShowActionWidget(true);
    } else {
      setShowActionWidget(false);
    }
  };

  const pageRequest = useRef({
    offset: 0,
    limit: PAGE_LIMIT,
  });

  const setDefaults = () => {
    setHighPriotyAlertsCount(0);
    setMediumPriotyAlertsCount(0);
    setLowPriotyAlertsCount(0);
    setAlerts([]);
    setPagination({});
  };

  const parseResponse = response => {
    setCurrentShowingCount(response.alerts.length);
    setAlerts(response.alerts);
    setPagination(response.pagination);
    setIsLoading(false);
  };

  const getAlerts = () => {
    setIsLoading(true);
    setDefaults();

    props.getAlerts(pageRequest.current).then(resp => {
      if (resp.type === ALERTS_LIST_RESULT) {
        parseResponse(resp.response);
      } else {
        setIsLoading(false);
      }
    });

    props.getAlertsPrioritiesSummary().then(resp => {
      if (resp.type === ALERTS_PRIORITIES_SUMMARY_RESULT) {
        setLowPriotyAlertsCount(resp.response.find(r => r.priority === 0)?.count || 0);
        setMediumPriotyAlertsCount(resp.response.find(r => r.priority === 1)?.count || 0);
        setHighPriotyAlertsCount(resp.response.find(r => r.priority === 2)?.count || 0);
      }
    });
  };

  useEffect(() => {
    getAlerts();
  }, [props.refreshTimestamp]);

  const pageHeaderLeftPart = (
    <React.Fragment>
      {isLoading ? Strings.showingWait : Strings.formatString(Strings.showingXPendingAlerts, currentShowingCount)}
    </React.Fragment>
  );

  const searchQueryDebounced = _.debounce(query => {
    pageRequest.current.offset = 0;
    pageRequest.current.search = query;
    getAlerts();
  }, 1000);

  const searchQueryChange = ({ target }) => {
    return searchQueryDebounced(target.value);
  };

  const dateChange = option => {
    if (option.value == DateFilters.AllTime.value) {
      delete pageRequest.current.startDate;
      delete pageRequest.current.endDate;
    } else {
      pageRequest.current.startDate = option.dates.startDate();
      pageRequest.current.endDate = option.dates.endDate();
    }
    getAlerts();
  };

  const pageHeaderRightPart = (
    <React.Fragment>
      <TextInput className="search" placeholder={Strings.search} onChange={searchQueryChange} />
      <Select
        data={[DateFilters.AllTime, DateFilters.Last7Days, DateFilters.Last30Days]}
        onChange={dateChange}
        defaultValue={DateFilters.AllTime.value}
      />
      {/* <Select data={[{ value: 'all', label: 'New alerts' }]} />
      <AdvancedFilters /> */}
    </React.Fragment>
  );

  const pageHeader = <PageHeader left={pageHeaderLeftPart} right={pageHeaderRightPart} />;

  const summary = (
    <div className="alerts-priority-widget-container">
      <AlertSummaryWidget isLoading={isLoading} count={highPriotyAlertsCount} priority={2} />
      <AlertSummaryWidget isLoading={isLoading} count={mediumPriotyAlertsCount} priority={1} />
      <AlertSummaryWidget isLoading={isLoading} count={lowPriotyAlertsCount} priority={0} />
    </div>
  );

  const onPrevClick = () => {
    pageRequest.current.offset = pagination.offset - PAGE_LIMIT;
    getAlerts();
  };

  const onNextClick = () => {
    pageRequest.current.offset = pagination.offset + PAGE_LIMIT;
    getAlerts();
  };

  const onCustomPage = page => {
    pageRequest.current.offset = (page - 1) * PAGE_LIMIT;
    getAlerts();
  };

  const deleteAlert = () => {
    const alerts_ids = itemSelectorHelper.current.getItems();
    alerts_ids.forEach(alert_id => {
      props.deleteAlert(alert_id).then(resp => {
        if (resp.type !== DELETE_ALERT_RESULT) {
          props.showNotification('Failed to delete alert definition');
        }
        setShowActionWidget(false);
        itemSelectorHelper.current.clearItems();
        getAlerts();
      });
    });
  };

  const onSortClick = ({ sortKey, direction }) => {
    pageRequest.current.sortColumn = sortKey;
    pageRequest.current.sortType = direction;
    pageRequest.current.offset = 0;
    getAlerts();
  };

  const onRowClick = id => {
    if (alerts[id]?.patient_id) {
      const patient = alerts[id];
      const maskedId = encodeURIComponent(patient.patient_id);
      props.onNavigate(`/cap-patients/${maskedId}`);
    }
  };

  const table = (
    <TableWithPagination
      isLoading={isLoading}
      name="alerts"
      data={alerts}
      pagination={pagination}
      onCustomPage={onCustomPage}
      onNextClick={onNextClick}
      onPrevClick={onPrevClick}
      onSortClick={onSortClick}
      onRowSelection={onRowClick}
    >
      <Column
        key="id"
        sortKey="id"
        title={Strings.id}
        value={e => (
          <React.Fragment>
            {/* // TODO: Define selector behavior */}
            <div className="cell-with-select">
              <div className="selector">
                <input type="checkbox" className="item-checkbox" id={e.id} onChange={itemChecked} />
              </div>
              <div className="selector-label">{idRenderer(e)}</div>
            </div>
          </React.Fragment>
        )}
      />
      <Column key="name" sortKey="name" title={Strings.name} value={e => e.patient_name} />
      <Column key="priority" title={Strings.priority} value={e => priorityRenderer(e.priority)} />
      <Column
        key="date"
        sortKey="date"
        title={Strings.date}
        value={e => moment(e.created_at).format(DATE_MONTH_DAY_YEAR)}
      />
      <Column key="time" title={Strings.time} value={e => moment(e.created_at).format('h:mm a')} />
      <Column key="type" sortKey="type" title={Strings.type} value={e => typeRenderer(e.general_type)} />
      <Column key="value" title={Strings.value} value={e => conditionRenderer(e.conditions)} />
    </TableWithPagination>
  );

  const actionWidget = (
    <ActionWidget show={showActionWidget}>
      <ActionButton img="action-delete" tooltiptext={Strings.delete} text={Strings.delete} action={deleteAlert} />
    </ActionWidget>
  );

  return (
    <div className="alerts">
      {pageHeader}
      {summary}
      {table}
      {actionWidget}
    </div>
  );
}

Alerts.propTypes = {
  getAlerts: PropTypes.func.isRequired,
  deleteAlert: PropTypes.func.isRequired,
  refreshTimestamp: PropTypes.any.isRequired,
  showNotification: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getAlerts: pageRequest => dispatch(actions.getAlerts(pageRequest)),
  deleteAlert: id => dispatch(actions.deleteAlert(id)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  getAlertsPrioritiesSummary: () => dispatch(actions.getAlertsPrioritiesSummary()),
  onNavigate: path => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(Alerts);
