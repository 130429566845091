import React, { PureComponent } from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';

export const BUTTON_TYPE_BLUE = 0;
export const BUTTON_TYPE_WHITE = 1;
export const BUTTON_TYPE_DARK_BLUE = 2;

export class Button extends PureComponent {
  btnRef = React.createRef();
  componentDidMount() {
    this.btnRef && this.btnRef?.current?.focus();
  }
  render() {
    const { buttonType, isGlowing, className, refLink, first, ...props } = this.props;

    return (
      <button
        ref={first && this.btnRef}
        {...props}
        className={classNames(styles.Button, className, {
          [styles.blue]: buttonType === BUTTON_TYPE_BLUE,
          [styles.white]: buttonType === BUTTON_TYPE_WHITE,
          [styles.dark_blue]: buttonType === BUTTON_TYPE_DARK_BLUE,
          [styles.glowing]: isGlowing === true,
        })}
        tabIndex={0}
      />
    );
  }
}
