import { userProfile } from 'actions/auth';
import { closeModal } from 'actions/modal';
import { notificationActions } from 'components/Notification/redux/actions';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../containers';
import { ESIPatientEditAddressForm } from '../../features/ESIPatientEditAddressForm/ESIPatientEditAddressForm';
import Strings from '../../Strings';
import '../editStudyModal.scss';
import { actions } from './redux/actions';

const mapDispatchToProps = dispatch => ({
  onCancel: () => dispatch(closeModal('esi-patient-edit-address-modal')),
  updateAddress: address => dispatch(actions.updateAddress(address)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  onUpdatedSuccess: () => dispatch(userProfile()),
});
class ESIPatientEditAddressModal extends PureComponent {
  static MODAL_NAME = 'esi-patient-edit-address-modal';

  state = { errors: [] };

  onSubmit = values => {
    const { updateAddress, showNotification, onUpdatedSuccess, onCancel } = this.props;

    this.setState({ errors: [] });

    updateAddress(values).then(response => {
      if (response.error) {
        this.setState({ errors: Strings.errors.internalError });
        return;
      }

      showNotification(Strings.addressUpdatedSuccessfully);
      onUpdatedSuccess();
      onCancel();
    });
  };

  render() {
    const {
      onOpen,
      onCancel,
      data: { user },
      ...props
    } = this.props;

    return (
      <Modal
        onOpen={onOpen}
        errors={this.state.errors}
        name={ESIPatientEditAddressModal.MODAL_NAME}
        additionalClasses={['form-modal', 'user-invite']}
        withoutContainer
        {...props}
      >
        {
          <ESIPatientEditAddressForm
            errors={this.state.errors}
            onSubmit={this.onSubmit}
            onCancel={onCancel}
            initialValues={user}
          />
        }
      </Modal>
    );
  }
}

export default connect(null, mapDispatchToProps)(ESIPatientEditAddressModal);
