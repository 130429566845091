import { API_CALL } from '../../../../middleware/api';

export const GET_REPORT_REQ = 'get-report';
export const GET_REPORT_REQ_RESULT = 'get-report/result';
export const GET_REPORT_REQ_ERROR = 'get-report/error';

export const GET_PROGRAM_STATISTICS = 'get-program-statistics';
export const GET_PROGRAM_STATISTICS_RESULT = `${GET_PROGRAM_STATISTICS}/result`;
export const GET_PROGRAM_STATISTICS_ERROR = `${GET_PROGRAM_STATISTICS}/error`;

export const GET_ESI_PATIENTS_STATS = 'get-esi-patients-stats';
export const GET_ESI_PATIENTS_STATS_RESULT = `${GET_ESI_PATIENTS_STATS}/result`;
export const GET_ESI_PATIENTS_STATS_ERROR = `${GET_ESI_PATIENTS_STATS}/error`;

export const actions = {
  getReport: endDate => ({
    [API_CALL]: {
      endpoint: '/report/overview',
      method: 'get',
      params: {
        endDate,
      },
    },
    type: GET_REPORT_REQ,
  }),

  getProgramStatistics: request => ({
    [API_CALL]: {
      endpoint: '/report/program_statistics',
      method: 'get',
      params: request,
    },
    type: GET_PROGRAM_STATISTICS,
  }),
  getEsiPatientStats: request => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/report/esi_patients',
      params: request,
    },
    type: GET_ESI_PATIENTS_STATS,
  }),
};
