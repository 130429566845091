import { objectsReducer } from './objects';

const assetsReducer = (types, actionToKey) => (state = {}, action) => {
  const newState = {
    ...state,
    objects: objectsReducer(types, actionToKey)(state.objects, action),
  };

  if (action.response && action.response.entities) {
    // newState.paging = paged(types, actionToKey)(state.paging, action);
    return newState;
  }

  return newState;
};

export default assetsReducer;
