import { cloneDeep, omitBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Select from '../../../components/Select';
import Strings from '../../../Strings';
import { actions } from '../Organization/redux/actions';
import './Configuration.scss';
import { onPermissionRender, twoFaEnabled } from './helpers';

class OrgRolePermissions extends PureComponent {
  state = { permissions: {} };

  componentDidMount() {
    this.pageRequest = {
      organizationId: decodeURIComponent(this.props.organizationId),
      roleUuid: this.state.role,
    };

    this.props.getOrgRole(this.pageRequest);
  }

  handleCheckbox = e => {
    const { name, value, checked } = e.target;
    const preState = this.state.permissions || {};
    const previousState = this.state.permissions[name] || {};
    this.setState({
      permissions: {
        ...preState,
        [name]: { ...previousState, [value]: checked },
      },
    });
  };

  handleTwoFa = e => {
    const { name, value, checked } = e.target;
    this.setState({ two_fa_enabled: checked });
  };

  handleSupportCheckbox = e => {
    const { name, value, checked } = e.target;
    const preState = this.state.support || {};
    const previousState = this.state.support[name] || {};
    this.setState({
      support: {
        ...preState,
        [name]: { ...previousState, [value]: checked },
      },
    });
  };

  handleSections = (e, oname, sname) => {
    const support = this.state.support;
    support.sections[oname][sname] = e.target.value;
    this.setState({ ...support });
    this.forceUpdate();
  };

  onRoleChange = value => {
    this.setState({ role: value });
    this.pageRequest.roleUuid = value.value;
    this.props.getOrgRoleConfig(this.pageRequest).then(() => {
      this.props.getOrgConfig(this.pageRequest).then(() => {
        const { orgRoleConfig } = this.props;
        const orgConfig = this.props.orgConfig?.permissions;
        const support = this.props.orgConfig?.support;
        const two_fa_enabled = this.props.orgConfig?.two_fa_enabled;
        let permissions = {};

        orgConfig &&
          Object.entries(orgConfig).map(([name, options]) => {
            if (orgConfig[name]) {
              if (Object.keys(orgConfig[name]).length === 0) {
                orgConfig[name][name] = false;
              }
            }
            Object.entries(orgConfig[name]).map(([oname, ooptions]) => {
              if (orgConfig[name][oname]) {
                orgConfig[name][oname] = false;
              }
            });
          });

        if (orgRoleConfig) {
          Object.entries(orgRoleConfig).map(([name, options]) => {
            if (orgRoleConfig[name]) {
              if (Object.keys(orgRoleConfig[name]).length === 0) {
                orgRoleConfig[name][name] = true;
              }
              Object.entries(orgRoleConfig[name]).map(([oname, ooptions]) => {
                if (orgRoleConfig[name][oname]) {
                  orgRoleConfig[name][oname] = true;
                }
              });
            }
          });
        }

        permissions = _.merge(orgConfig, orgRoleConfig);
        this.setState({
          permissions,
          support,
          two_fa_enabled,
        });
      });
    });
  };

  updatePermission = () => {
    const { permissions, support, two_fa_enabled } = cloneDeep(this.state);

    Object.entries(permissions).map(([name, options]) => {
      if (permissions[name]) {
        Object.entries(permissions[name]).map(([oname, ooptions]) => {
          if (oname === name) {
            if (permissions[name][oname] === true) {
              permissions[name] = {};
            } else {
              delete permissions[name];
              return;
            }
          } else {
            if (permissions[name][oname] === true) {
              permissions[name][oname] = {};
            } else {
              delete permissions[name][oname];
              if (Object.keys(permissions[name]).length === 0) {
                delete permissions[name];
                return;
              }
              return;
            }
          }
        });
      }
    });

    const data = {
      permissions,
      support: support,
      two_fa_enabled,
    };

    this.props.updateOrgRoleConfig(data, this.pageRequest).then(() => {
      this.setState({ isSuccess: true });
      setTimeout(() => {
        this.setState({ isSuccess: false });
      }, 3000);
    });
  };

  render() {
    const { roles, isLoading } = this.props;
    const { role, isSuccess } = this.state;
    return (
      <React.Fragment>
        <div>
          {isLoading && <div className="spinner" />}
          <div className="dd-container" style={{ margin: '20px 0' }}>
            {roles && (
              <div className="dd-ele">
                <div className="dd-title">{Strings.role}</div>
                <Select
                  value={role}
                  autoWidth
                  onChange={this.onRoleChange}
                  data={[{ value: '', label: Strings.role }].concat(
                    Object.keys(roles).map(key => {
                      return { value: roles[key].role_uuid, label: roles[key].archetype };
                    }),
                  )}
                />
              </div>
            )}
          </div>

          {role && onPermissionRender(this.state.permissions, this.handleCheckbox)}
          {twoFaEnabled(this.state.two_fa_enabled, this.handleTwoFa)}
        </div>
        <button className="brand-blue" key="submit" onClick={this.updatePermission} disabled={!this.state.role}>
          {Strings.submit}
        </button>
        {isSuccess && <div className="success-text">{Strings.permissionsUpdated} </div>}
      </React.Fragment>
    );
  }
}

OrgRolePermissions.propTypes = {
  getOrgConfig: PropTypes.func,
  getOrgRole: PropTypes.func,
  getOrgRoleConfig: PropTypes.func,
  isLoading: PropTypes.any,
  orgConfig: PropTypes.shape({
    permissions: PropTypes.any,
  }),
  orgRoleConfig: PropTypes.any,
  organizationId: PropTypes.any,
  roles: PropTypes.any,
  updateOrgRoleConfig: PropTypes.func,
};

const mapStateToProps = state => {
  const { organizations } = state.superUser;
  return {
    roles: organizations?.roles,
    orgRoleConfig: organizations?.orgRoleConfig,
    isLoading: organizations?.isLoading,
    orgConfig: organizations?.orgConfig,
  };
};

const mapDispatchToProps = dispatch => ({
  getOrgRole: pageRequest => dispatch(actions.getOrgRole(pageRequest)),
  getOrgRoleConfig: pageRequest => dispatch(actions.getOrgRoleConfig(pageRequest)),
  updateOrgRoleConfig: (data, pageRequest) => dispatch(actions.updateOrgRoleConfig(data, pageRequest)),
  getOrgConfig: pageRequest => dispatch(actions.getOrgConfig(pageRequest)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgRolePermissions);
