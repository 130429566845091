import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { Button } from '../../../components/PageHeader/Button';
import TabView, { Tab } from '../../../components/TabView';
import { HEADER_BUTTON_BLUE } from '../../../constants';
import Strings from '../../../Strings';
import { downloadFiles } from '../../../utils';
import ActivePatients from './ActivePatients';
import PendingPatients from './PendingPatients';
import TermedPatients from './TermedPatients';

import { getActiveTabNameFromUrl } from '../../../utils';

export const Tabs = {
  Active: {
    DisplayName: Strings.esi.active,
    urlId: 'active',
    url: '/esi_patients/active',
  },
  Pending: {
    DisplayName: Strings.esi.pending,
    urlId: 'pending',
    url: '/esi_patients/pending',
  },
  Termed: {
    DisplayName: Strings.esi.termed,
    urlId: 'termed',
    url: '/esi_patients/termed',
  },
};

class ESIPatients extends PureComponent {
  searchTime;
  componentDidMount() {}

  getHeaderComponents() {
    return (
      <React.Fragment>
        <Button disabled={false} class={HEADER_BUTTON_BLUE} onClick={this.onDownloadReport} title={Strings.donwload} />
      </React.Fragment>
    );
  }

  onDownloadReport = () => {
    this.props.downloadReport().then(this.onDownloadBtnClickSuccessHandler);
  };

  onDownloadBtnClickSuccessHandler = req => {
    const fileName = req.response && req.response.name ? req.response.name : 'doc.zip';
    downloadFiles(req, fileName);
  };

  render() {
    const activeTab = this.props.match.params.tab;
    const tabArray = [];
    const patientActive = (
      <Tab name={Tabs.Active.DisplayName} path={Tabs.Active.url}>
        <ActivePatients />
      </Tab>
    );

    const patientPending = (
      <Tab name={Tabs.Pending.DisplayName} path={Tabs.Pending.url}>
        <PendingPatients />
      </Tab>
    );

    const patientTermed = (
      <Tab name={Tabs.Termed.DisplayName} path={Tabs.Termed.url}>
        <TermedPatients />
      </Tab>
    );

    tabArray.push(patientActive);
    tabArray.push(patientPending);
    tabArray.push(patientTermed);
    return (
      <TabView key="tabs" activeTab={getActiveTabNameFromUrl(activeTab, Tabs)} routeMode className="" noSwiping>
        {tabArray}
      </TabView>
    );
  }
}

ESIPatients.propTypes = {
  activeTab: PropTypes.any,
  downloadReport: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string,
    }),
  }),
};

const mapStateToProps = state => {
  return { role: state.auth.role };
};

const mapDispatchToProps = dispatch => ({ onNavigate: path => dispatch(push(path)) });

export default connect(mapStateToProps, mapDispatchToProps)(ESIPatients);
