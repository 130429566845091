import { API_CALL } from '../../../../../middleware/api';
import { WIDGET_LOCATION_OF_YOUR_KITS } from './constants';

export const actions = {
  getHubs: params => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/v1/devices-locations',
    },
    type: WIDGET_LOCATION_OF_YOUR_KITS,
  }),
};
