import { notificationActions } from 'components/Notification/redux/actions';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { POST_ADMIN_INVITE_ERROR, POST_ADMIN_INVITE_RESULT, POST_CAP_MANAGER_INVITE } from '../actions/action-types';
import adminsAction from '../actions/admins';
import { resetForm } from '../actions/forms';
import { closeModal } from '../actions/modal';
import LoadingRenderer from '../components/LoadingRenderer';
import { Modal } from '../containers';
import Form, { Input } from '../containers/Form';
import Strings from '../Strings';
import { validateName } from '../utils';
import './editStudyModal.scss';

class EditAdminModal extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      admin: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),

      action: PropTypes.func.isRequired,
      actionType: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    const newAdmin = {
      id: 0,
      email: '',
      first_name: '',
      last_name: '',
      organization: '',
    };

    this.state = {
      errors: [],
      admin: this.props.data.admin ? cloneDeep(this.props.data.admin) : newAdmin,
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onTextChange(event) {
    const { admin } = this.state;

    admin[event.target.getAttribute('name')] = event.target.value;

    this.setState({ admin });
  }

  onSubmit() {
    const { admin } = this.state;

    const validationErrors = [];

    if (!admin.email.length) {
      validationErrors.push(Strings.errors.emptyEmailAddress);
    }

    if (admin.first_name && !validateName(admin.first_name)) {
      validationErrors.push(Strings.errors.invalidFirstName);
    }

    if (admin.last_name && !validateName(admin.last_name)) {
      validationErrors.push(Strings.errors.invalidLastName);
    }

    if (validationErrors.length) {
      this.setState({ errors: validationErrors });
      return;
    }

    this.setState({ admin });

    this.props.onSubmit(admin).then(response => {
      if (response.type === POST_ADMIN_INVITE_ERROR && response.response.data) {
        const responseData = response.response.data;
        const errors = Object.keys(responseData).map(key => `${key}: ${responseData[key]}`);
        this.setState({ errors });
      } else if (response && response.type === POST_ADMIN_INVITE_RESULT) {
        this.props.showNotification(Strings.adminInviteSent);
        this.props.loadAdmins();
      }

      return response;
    });
  }

  render() {
    const { onOpen, onCancel, editAdminModalLoading, ...props } = this.props;

    const { admin, errors } = this.state;
    const showOrg = this.props.data.actionType === POST_CAP_MANAGER_INVITE;
    return (
      <Modal
        name="new-admin-modal"
        onOpen={onOpen}
        additionalClasses={['form-modal', 'user-invite', 'invite-admin']}
        {...props}
      >
        <LoadingRenderer loading={!!editAdminModalLoading}>
          <Form key="add-edit-admin-form" onSubmit={this.onSubmit} onCancel={onCancel} id="add-edit-admin-form">
            <div key="admin-firstName" className="dialog-input1">
              <Input
                name="first_name"
                id="firstName"
                label=""
                className="dialog-form"
                placeholder="Firstname"
                type="text"
                defaultValue={admin.first_name}
                onChange={this.onTextChange}
              />
            </div>

            <div key="admin-lastName" className="dialog-input1">
              <Input
                name="last_name"
                id="lastName"
                label=""
                className="dialog-form"
                placeholder="Lastname"
                type="text"
                defaultValue={admin.last_name}
                onChange={this.onTextChange}
              />
            </div>

            <div key="admin-email" className="dialog-input1">
              <Input
                name="email"
                id="email"
                label=""
                className="dialog-form"
                placeholder="Enter Email Address"
                type="email"
                defaultValue={admin.email}
                onChange={this.onTextChange}
              />
            </div>

            {showOrg === true ? (
              <div key="admin-organization" className="dialog-input1">
                <Input
                  name="organization"
                  id="organization"
                  label=""
                  className="dialog-form"
                  placeholder={Strings.organizationName}
                  type="text"
                  defaultValue={admin.organization}
                  onChange={this.onTextChange}
                />
              </div>
            ) : (
              <div></div>
            )}
            <div key="errors-block" className="errors-block">
              {errors.length ? <div>{errors.join(' ')}</div> : null}
            </div>
            <div className="button-bar reverse" key="button-bar">
              <button className="brand-blue" key="submit" type="submit">
                {Strings.save}
              </button>
              <button className="white gray-text" key="cancel" type="cancel">
                {Strings.cancel}
              </button>
            </div>
          </Form>
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({ editAdminModalLoading: state.entities.admins.inviteLoading });

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('new-admin')),
  loadAdmins: () => dispatch(adminsAction.actionListAdmins()),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  onSubmit: admin =>
    dispatch(admin.id ? ownProps.data.action(admin.id, admin) : ownProps.data.action(admin)).then(response => {
      if (response && response.type === `${ownProps.data.actionType}/result`) {
        dispatch(closeModal('new-admin'));
      }

      return response;
    }),
  onOpen: () => dispatch(resetForm('new-admin', ownProps.data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAdminModal);
