import React from 'react';
import { Bar, Cell, Line, ReferenceLine } from 'recharts';
import { graphMetrics } from './Metrics';
import { getMetricDataForDateRange } from './DataHelpers';
import { getMetricDefaultFormat } from './Formatting';
import { drawDotShape } from './Components';
import { BAD_METRIC_DOT_COLOR } from './Constants';
import { manipulateColor } from './ColorTools';
import { normalizePair, prepareSimplifiedDataForChart } from './simplifyData';

const CustomizedDot = props => {
  const { value, badData, format } = props;
  const bad = badData && badData(value);
  const shape = format.dotShape;

  const stroke = bad ? BAD_METRIC_DOT_COLOR : format.stroke;
  const fill = bad ? BAD_METRIC_DOT_COLOR : manipulateColor(0.4, stroke);

  // console.log(props);
  // console.log(bad, shape);

  return drawDotShape(shape, {
    ...props,
    r: props.r * 4,
    strokeDasharray: '',
    fill,
    stroke,
  });
};

export function drawLineSeries(selectedMetrics, yAxisGroups, data, dateRange, simplifiedVariant = false) {
  const getMetricData = (data, dateRange, metricExt) => {
    const metricData = getMetricDataForDateRange(data, dateRange, metricExt.id);
    if (!metricExt.dontSimplify) {
      metricData.data = prepareSimplifiedDataForChart(metricData.data);
    }
    return metricData;
  };
  const getSeriesData = metricData => {
    return metricData.data.map(i => {
      return { x: i.dt.unix(), y: i.value, statistics: i.statistics, distribution: i.distribution };
    });
  };

  let index = 0;
  const selectedMetricsExt = selectedMetrics.map(metric => graphMetrics.find(gm => gm.id === metric));
  return selectedMetricsExt.map(metricExt => {
    if (!data[metricExt.id] || !data[metricExt.id].data || data[metricExt.id].data.length === 0) return null;

    const metricData = getMetricData(data, dateRange, metricExt);
    const seriesData = getSeriesData(metricData);

    const format = getMetricDefaultFormat(index);
    const metricFormat = metricExt.format || {};
    Object.assign(format, metricFormat);

    index++;
    // console.log(`series ${metricExt.id}: ${seriesData.length} elements`);

    const yAxisGroup = yAxisGroups.find(yAG => yAG.metricsExt.some(m => m.id === metricExt.id));
    const yAxisId = yAxisGroup.axisId;
    const yAxisIdOK = yAxisGroup.axisId === 'yAxis2' || yAxisGroup.axisId === 'yAxis1';
    const yAxisIsRight = yAxisId === 'yAxis2';

    if (!yAxisIdOK) return null;

    const isBar = metricExt.format.chartType === 'bar';
    const isDistribution = metricExt.format.chartType === 'distribution';
    const isCategoryDistribution = metricExt.format.chartType === 'categoryDistribution';

    // console.log('metricData', metricData);
    // console.log('seriesData', seriesData);

    const SimpleBar = props => {
      const { yAxis, payload, background, color } = props;

      // console.log('SimpleBar', props);

      if (payload.statistics) {
        const yStart = background.y;
        const yEnd = background.y + background.height;
        const yAxisStart = yAxis.yMinReal;
        const yAxisEnd = yAxis.yMaxReal;

        const yRange = normalizePair(
          yAxisStart,
          yAxisEnd,
          payload.statistics.minValue,
          payload.statistics.maxValue,
          yStart,
          yEnd,
        );
        if (yRange.v1 === yRange.v2) {
          yRange.v1--;
          yRange.v2++;
        }

        const width = 7;

        return (
          <rect
            x={background.x + 1 - width / 2}
            y={background.height - yRange.v2}
            width={width}
            height={yRange.v2 - yRange.v1}
            fill={color}
          />
        );
      }

      if (payload.y) {
        const yStart = background.y;
        const yEnd = background.y + background.height;
        const yAxisStart = yAxis.yMinReal;
        const yAxisEnd = yAxis.yMaxReal;

        const yRange = normalizePair(yAxisStart, yAxisEnd, 0, payload.y, yStart, yEnd);
        if (yRange.v1 === yRange.v2) {
          yRange.v1--;
          yRange.v2++;
        }

        const width = 7;

        return (
          <rect
            x={background.x + 1 - width / 2}
            y={background.height - yRange.v2}
            width={width}
            height={yRange.v2 - yRange.v1}
            fill={color}
          />
        );
      }

      return null;
    };

    const DistributionBar = props => {
      const { yAxis, payload, background, color, resolution } = props;

      // console.log('DistributionBar', props);

      if (payload.statistics) {
        const yStart = background.y;
        const yEnd = background.y + background.height;
        const yAxisStart = yAxis.yMinReal;
        const yAxisEnd = yAxis.yMaxReal;

        const ret = (
          <g>
            {payload.statistics.values.map((i, index) => {
              const opacity = i.count >= 10 ? '0%' : `${i.count * 10}%`;
              const manipulateRatio = i.count >= 10 ? 0 : 1 - i.count / 10;
              const fillColor = manipulateColor(manipulateRatio, color, '#fff');
              const strokeColor = manipulateColor(manipulateRatio * 0.7, color, '#fff');

              const yRange = normalizePair(
                yAxisStart,
                yAxisEnd,
                i.bucket * resolution,
                i.bucket * resolution + resolution,
                yStart,
                yEnd,
              );
              const width = 7;

              // console.log(i, yAxisStart, yAxisEnd, i.value, i.value + resolution, yStart, yEnd);
              // console.log('yRange', yRange);

              return (
                <rect
                  key={`Distribution_${metricExt.id}_part_${index}`}
                  style={{ strokeWidth: 1, stroke: `${strokeColor}` }}
                  x={background.x + 1 - width / 2}
                  y={background.height - yRange.v2}
                  width={width}
                  height={yRange.v2 - yRange.v1}
                  fill={fillColor}
                  //opacity={opacity}
                  rx={5}
                  ry={2}
                />
              );
            })}
          </g>
        );

        return ret;
      }

      return null;
    };

    const CategoryDistributionBar = props => {
      const { yAxis, payload, background, colors } = props;

      // console.log('CategoryDistributionBar', props);

      if (payload.distribution) {
        const yStart = background.y;
        const yEnd = background.y + background.height;
        const yAxisStart = yAxis.yMinReal;
        const yAxisEnd = yAxis.yMaxReal;

        const keys = Object.keys(payload.distribution);

        let sum = 0;
        const ret = (
          <g>
            {keys.map((key, index) => {
              const value = payload.distribution[key];
              const fillColor = colors[key];
              const strokeColor = colors[key];

              const yRange = normalizePair(yAxisStart, yAxisEnd, sum, sum + value, yStart, yEnd);
              const width = 7;

              // console.log(key, yAxisStart, yAxisEnd, sum, sum + value, yStart, yEnd);
              // console.log('yRange', yRange);

              sum += value;

              return (
                <rect
                  key={`Distribution_${metricExt.id}_part_${index}`}
                  style={{ strokeWidth: 1, stroke: `${strokeColor}` }}
                  x={background.x + 1 - width / 2}
                  y={background.height - yRange.v2}
                  width={width}
                  height={yRange.v2 - yRange.v1}
                  fill={fillColor}
                  //opacity={opacity}
                  rx={5}
                  ry={2}
                />
              );
            })}
          </g>
        );

        return ret;
      }

      return null;
    };

    return (
      <React.Fragment key={metricExt.id}>
        {data[metricExt.id].thresholdMin &&
          !simplifiedVariant &&
          (metricData.inRangeCount > 0 || (metricData.hasPrev && metricData.hasNext)) && (
            <ReferenceLine
              key={`ThresholdRefLineMin_${metricExt.id}`}
              yAxisId={yAxisId}
              y={data[metricExt.id].thresholdMin}
              strokeDasharray="3 3"
              stroke={format.stroke}
            />
          )}
        {data[metricExt.id].thresholdMax &&
          !simplifiedVariant &&
          (metricData.inRangeCount > 0 || (metricData.hasPrev && metricData.hasNext)) && (
            <ReferenceLine
              key={`ThresholdRefLineMax_${metricExt.id}`}
              yAxisId={yAxisId}
              y={data[metricExt.id].thresholdMax}
              strokeDasharray="3 3"
              stroke={format.stroke}
            />
          )}

        {!isBar && !isDistribution && !isCategoryDistribution && (
          <Line
            key={`Line_${metricExt.id}`}
            type={format.type}
            tooltipType="none"
            name={metricExt.label + (yAxisIsRight ? ' (R)' : '')}
            stroke={manipulateColor(0.5, format.stroke, '#fff')}
            strokeWidth={format.strokeWidth}
            data={seriesData}
            dataKey="y"
            connectNulls
            dot={simplifiedVariant ? false : <CustomizedDot format={format} badData={data[metricExt.id].badData} />}
            activeDot={false}
            strokeDasharray={format.strokeDasharray} // {!yAxisIsRight ? format.strokeDasharray : '2 2'}
            isAnimationActive={false}
            yAxisId={yAxisId}
          />
        )}

        {isBar && (
          <Bar
            key={`Bar_${metricExt.id}`}
            name={metricExt.label + (yAxisIsRight ? ' (R)' : '')}
            fill={manipulateColor(0.5, format.stroke, '#fff')}
            data={seriesData}
            dataKey="y"
            isAnimationActive={false}
            yAxisId={yAxisId}
            shape={<SimpleBar yAxis={yAxisGroup} color={metricExt.format.color} />}
            barSize={0}
          />
        )}
        {isDistribution && (
          <Bar
            key={`Distribution_${metricExt.id}`}
            name={metricExt.label + (yAxisIsRight ? ' (R)' : '')}
            fill={manipulateColor(0.5, format.stroke, '#fff')}
            data={seriesData}
            dataKey="y"
            isAnimationActive={false}
            yAxisId={yAxisId}
            shape={
              <DistributionBar
                yAxis={yAxisGroup}
                color={metricExt.format.color}
                resolution={metricExt.format.resolution}
              />
            }
            barSize={0}
          />
        )}
        {isCategoryDistribution && (
          <Bar
            key={`Distribution_stackId_${metricExt.format.stackId}`}
            name={metricExt.label + (yAxisIsRight ? ' (R)' : '')}
            fill={manipulateColor(0.5, format.stroke, '#fff')}
            data={seriesData}
            dataKey="y"
            isAnimationActive={false}
            yAxisId={yAxisId}
            shape={<CategoryDistributionBar yAxis={yAxisGroup} colors={metricExt.format.colors} />}
            barSize={0}
          />
        )}
      </React.Fragment>
    );
  });
}
