import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import { actions } from '../redux/actions';
import Strings from '../../../../Strings';
import { TIME_FORMAT_12_UPPERCASE } from '../../../../constants';
import { Button } from '../../../../components/PageHeader/Button';
import { EDIT_NOTE } from '../redux/constants';
import { openModalAction } from '../../../../actions/modal';

function PatientLogItem(props) {
  const { logItem } = props;
  const status = logItem.action_completed === true ? 'completed' : logItem.action_completed === false ? 'open' : 'note';

  const chageActionState = completed => {
    props.chageActionState({ action_completed: completed, content: logItem.content }).then(() => {
      props.getNotes();
    });
  };

  const editNote = () => {
    // props.editNote(logItem.content).then(() => {
    //   props.getNotes();
    // });
    if (status === 'note') props.editNoteContent(logItem.content);
    else props.editActionContent(logItem.content);
  };

  return (
    <div className="patient-log-logitem">
      <div className="icon-container">
        <div className={`icon ${status}`} />
      </div>
      <div className="item">
        <div className="title">
          {status === 'note' ? Strings.note : status === 'completed' ? Strings.completedAction : Strings.openAction}
          <span className="date">
            {status === 'note'
              ? `(${moment(logItem.modification_time * 1000).format(TIME_FORMAT_12_UPPERCASE)})`
              : `(${Strings.created} ${moment(logItem.modification_time * 1000).format(TIME_FORMAT_12_UPPERCASE)})`}
          </span>
        </div>
        <div className="content">{logItem.content}</div>
        {status === 'open' && <Button onClick={() => chageActionState(true)} title={Strings.complete} />}
        {status === 'completed' && <Button onClick={() => chageActionState(false)} title={Strings.reOpen} />}
      </div>
      <div
        role="button"
        onClick={() => editNote()}
        className="edit-button"
        data-tooltip-content={status === 'note' ? Strings.editNote : Strings.editAction}
        data-tooltip-id="tooltip"
      >
        <div className="icon" />
      </div>
    </div>
  );
}

PatientLogItem.propTypes = {
  logItem: PropTypes.shape({
    content: PropTypes.string,
    modification_time: PropTypes.any,
    id: PropTypes.any,
  }),
  patientId: PropTypes.any,
  chageActionState: PropTypes.func,
  editContent: PropTypes.func,
};

const mapDispatchToProps = (dispatch, props) => ({
  chageActionState: state => dispatch(actions.editNote(props.logItem.id, state)),
  editNoteContent: content =>
    dispatch(
      openModalAction('edit-note', {
        action: actions.editNote,
        actionType: EDIT_NOTE,
        noteId: props.logItem.id,
        patientId: props.patientId,
        note: content,
        caption: Strings.editNote,
      }),
    ),
  editActionContent: content =>
    dispatch(
      openModalAction('edit-action', {
        action: actions.editNote,
        actionType: EDIT_NOTE,
        noteId: props.logItem.id,
        patientId: props.patientId,
        note: content,
        action_completed: props.logItem.action_completed,
        caption: Strings.editAction,
      }),
    ),
  getNotes: () => dispatch(actions.getNotes(props.patientId)),
});

export default connect(null, mapDispatchToProps)(PatientLogItem);
