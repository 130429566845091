import { graphDataProvidersEnum } from './DataProviders';
import { graphMetricEnum, graphMetrics, mobileDataRangeTypes } from './Metrics';

export const graphTypeEnum = Object.freeze({
  weight: 'weight',
  bp: 'bp',
  hr: 'hr',
  airQuality: 'airQuality',
  pulseOx: 'pulseOx',
  glucose: 'glucose',
  temperature: 'temperature',
  coagulation: 'coagulation',
  composite: 'composite',
});

export const graphTypes = Object.freeze([
  {
    id: graphTypeEnum.composite,
    allowedMetrics: graphMetrics,
    metrics: [],
    editAllowed: true,
    withOverlay: true,
    withLegend: true,
    tableViewEnabled: false,
    defaultMobileDateRangeType: mobileDataRangeTypes.week,
    allowedMobileDateRangeTypes: [
      mobileDataRangeTypes.day,
      mobileDataRangeTypes.week,
      mobileDataRangeTypes.twoWeeks,
      mobileDataRangeTypes.month,
      mobileDataRangeTypes.sixMonths,
      mobileDataRangeTypes.year,
    ],
  },
  {
    id: graphTypeEnum.weight,
    allowedMetrics: graphMetrics.filter(m => m.id === graphMetricEnum.weight),
    metrics: [graphMetricEnum.weight],
    editAllowed: false,
    withOverlay: false,
    withLegend: true,
    tableViewEnabled: true,
    defaultMobileDateRangeType: mobileDataRangeTypes.month,
    allowedMobileDateRangeTypes: [
      mobileDataRangeTypes.week,
      mobileDataRangeTypes.twoWeeks,
      mobileDataRangeTypes.month,
      mobileDataRangeTypes.year,
    ],
  },
  {
    id: graphTypeEnum.bp,
    allowedMetrics: graphMetrics.filter(m => m.dataProvider === graphDataProvidersEnum.bp),
    metrics: [graphMetricEnum.bpSP, graphMetricEnum.bpDP, graphMetricEnum.bpPP, graphMetricEnum.bpPR],
    editAllowed: true,
    withOverlay: false,
    withLegend: true,
    tableViewEnabled: true,
    defaultMobileDateRangeType: mobileDataRangeTypes.week,
    allowedMobileDateRangeTypes: [mobileDataRangeTypes.week, mobileDataRangeTypes.month],
  },
  {
    id: graphTypeEnum.hr,
    allowedMetrics: graphMetrics.filter(m => m.dataProvider === graphDataProvidersEnum.hr),
    metrics: [graphMetricEnum.hr],
    editAllowed: true,
    withOverlay: false,
    withLegend: true,
    tableViewEnabled: true,
  },
  {
    id: graphTypeEnum.airQuality,
    allowedMetrics: graphMetrics.filter(m => m.dataProvider === graphDataProvidersEnum.airQualityMonitor),
    metrics: [graphMetricEnum.pm25, graphMetricEnum.pm1],
    defaultDateRangeDays: 7,
    editAllowed: true,
    withOverlay: false,
    withLegend: true,
    tableViewEnabled: true,
  },
  {
    id: graphTypeEnum.pulseOx,
    allowedMetrics: graphMetrics.filter(m => m.dataProvider === graphDataProvidersEnum.pulseox),
    metrics: [graphMetricEnum.saturation, graphMetricEnum.pi],
    editAllowed: true,
    withOverlay: false,
    withLegend: true,
    tableViewEnabled: true,
  },
  {
    id: graphTypeEnum.glucose,
    allowedMetrics: graphMetrics.filter(m => m.id === graphMetricEnum.glucose),
    metrics: [graphMetricEnum.glucose],
    editAllowed: false,
    withOverlay: false,
    withLegend: true,
    tableViewEnabled: true,
  },
  {
    id: graphTypeEnum.temperature,
    allowedMetrics: graphMetrics.filter(m => m.id === graphMetricEnum.temperature),
    metrics: [graphMetricEnum.temperature],
    editAllowed: false,
    withOverlay: false,
    withLegend: true,
    tableViewEnabled: true,
  },
  {
    id: graphTypeEnum.coagulation,
    allowedMetrics: graphMetrics.filter(m => m.dataProvider === graphDataProvidersEnum.ptInr),
    metrics: [graphMetricEnum.pt, graphMetricEnum.inr],
    editAllowed: false,
    withOverlay: false,
    withLegend: true,
    tableViewEnabled: true,
  },
]);
