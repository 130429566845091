import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Strings from '../../../Strings';
import { actions } from '../../Kits/redux/actions';
import { GET_KIT_DETAILS_RESULT } from '../../Kits/redux/constants';
import './Devices.scss';
import TabView, { Tab } from '../../../components/TabView';
import KitDevices from './KitDevices';
import { isShipper, isSuperUser } from '../../../utils/userRoles';
import KitStatusHistory from './KitStatusHistory';

export const KIT_STATUS_HISTORY_TAB = Strings.history;
export const KIT_DEVICES_TAB = Strings.devices;

function KitDetails(props) {
  const kitId = encodeURIComponent(props.match.params.id);
  const [kitName, setKitName] = React.useState('');

  useEffect(() => {
    props.getKitDetails(kitId).then(resp => {
      if (resp.type === GET_KIT_DETAILS_RESULT) {
        setKitName(resp.response?.name);
      }
    });
    return () => props.clearData();
  }, []);

  const rootPath = `/devices/kits/${kitId}`;
  const tabs = [];
  tabs.push(
    <Tab name={KIT_DEVICES_TAB} path={`${rootPath}/devices`} key={KIT_DEVICES_TAB}>
      <KitDevices {...props} />
    </Tab>,
  );

  if (isSuperUser() || isShipper()) {
    tabs.push(
      <Tab name={KIT_STATUS_HISTORY_TAB} path={`${rootPath}/history`} key={KIT_STATUS_HISTORY_TAB}>
        <KitStatusHistory {...props} />
      </Tab>,
    );
  }

  return (
    <React.Fragment>
      <div className="infoHeader">
        <div className="rowDirection">
          <button className="back" onClick={() => history.back()} tabIndex={0}>
            {Strings.back}
          </button>
          <div className="feildBox">
            {Strings.kitId}: <div className="feildValue">{kitId}</div>
          </div>
          <div className="pathHr"></div>
          <div className="feildBox">
            {Strings.kitName}: <div className="feildValue">{kitName || Strings.na}</div>
          </div>
        </div>
      </div>
      <TabView activeTab={props.activeTab} routeMode>
        {tabs}
      </TabView>
    </React.Fragment>
  );
}

KitDetails.propTypes = {
  clearData: PropTypes.func,
  history: PropTypes.shape({
    location: PropTypes.shape({ state: PropTypes.shape({ params: PropTypes.shape({ offset: PropTypes.any }) }) }),
  }),
  getKitDetails: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.any }),
    url: PropTypes.shape({ replace: PropTypes.func }),
  }),
  activeTab: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  getKitDetails: (kitId, pageRequest) => dispatch(actions.getKitDetails(kitId, pageRequest)),
  clearData: () => dispatch(actions.clearData()),
});

export default connect(null, mapDispatchToProps)(KitDetails);
