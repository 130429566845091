import { notificationActions } from 'components/Notification/redux/actions';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { SEND_SECURITY_CODE_CAP_ERROR, SEND_SECURITY_CODE_CAP_RESULT } from '../actions/action-types';
import { resetForm } from '../actions/forms';
import { closeModal } from '../actions/modal';
import { CodeInput } from '../components/Inputs/CodeInput';
import LoadingRenderer from '../components/LoadingRenderer';
import { Modal } from '../containers';
import Form from '../containers/Form';
import Strings from '../Strings';
import './editStudyModal.scss';

class SendSecurityCodeModal extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      action: PropTypes.func.isRequired,
      postAction: PropTypes.func.isRequired,
      actionType: PropTypes.string.isRequired,
      admin: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),
      capId: PropTypes.any,
    }).isRequired,
    editPinModalLoading: PropTypes.any,
    onCancel: PropTypes.any,
    onOpen: PropTypes.any,
    onSubmit: PropTypes.func,
    showNotification: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      isCodeEmpty: true,
    };

    this.onCodeChange = this.onCodeChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  codeInput = React.createRef();

  onCodeChange() {
    const length = this.codeInput.current.getValue().length;
    this.setState({ isCodeEmpty: length !== 6 });
  }

  setError(error) {
    this.setState(state => ({ errors: [...state.errors, error] }));
  }

  clearError() {
    this.setState({ errors: [] });
  }

  onSubmit() {
    this.clearError();

    const code = this.codeInput.current.getValue();

    this.props.onSubmit(code).then(response => {
      if (response.type === SEND_SECURITY_CODE_CAP_ERROR && response.response.data) {
        this.setState({ errors: [response.error] });
      } else if (response && response.type === SEND_SECURITY_CODE_CAP_RESULT) {
        this.props.showNotification(Strings.capSendSecurityCodeSent);
      }
      return response;
    });
  }

  render() {
    const { onOpen, onCancel, editPinModalLoading, data, ...props } = this.props;

    const { isCodeEmpty, errors } = this.state;
    return (
      <Modal
        name="send-security-code"
        onOpen={onOpen}
        // additionalClasses={['form-modal', 'user-invite', 'invite-admin']}
        additionalClasses={['form-modal', 'rounded-borders']}
        {...props}
      >
        <LoadingRenderer loading={!!editPinModalLoading}>
          <Form key="send-security-code" onSubmit={this.onSubmit} onCancel={onCancel} id="send-security-code">
            <div key="pin" className="display-grid">
              {Strings.deviceId}
              <br />
              <span className="cap-id">{data.capId}</span>
              <br />
              {<div className="caution">Security Code</div>}
              <CodeInput ref={this.codeInput} onChange={this.onCodeChange} className="code-input" type="code" />
            </div>

            <div key="errors-block" className="errors-block">
              {errors.length ? <div>{errors.join(' ')}</div> : null}
            </div>
            <div className="button-bar reverse" key="button-bar">
              <button className="brand-blue" key="submit" type="submit" disabled={isCodeEmpty}>
                {Strings.save}
              </button>
              <button className="white gray-text" key="cancel" type="cancel">
                {Strings.cancel}
              </button>
            </div>
          </Form>
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({ editPinModalLoading: state.entities.admins.inviteLoading });

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('send-security-code')),
  onSubmit: code =>
    dispatch(ownProps.data.onConfirmAction(ownProps.data.capId, [code])).then(response => {
      if (response && response.type === `${ownProps.data.actionType}/result`) {
        ownProps.data.postAction();
        dispatch(closeModal('send-security-code'));
      }

      return response;
    }),
  onOpen: () => dispatch(resetForm('send-security-code', ownProps.data)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendSecurityCodeModal);
