import PropTypes from 'prop-types';
import moment from 'moment-timezone/moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Select from '../../../components/Select';
import { actions } from '../Organization/redux/actions';
import { HUB_DEVICES_TYPES_RESULT } from '../Organization/redux/constants';
import {
  BLOOD_PRESSURE,
  WEIGHT,
  CONNECTION,
  BATTERY,
  SLEEP_REPORT,
  HEART_RATE,
  GLUCOMETER,
  MOVEMENT_REPORT,
  STEPS_REPORT,
  SPIROMETER,
  THERMOMETER,
  PULSEOX,
  AIR_QUALITY_MONITOR,
  BLOOD_PRESSURE_MONITOR,
  WEIGHING_MACHINE,
  WRIST_BAND,
  GLUCOMETER_DEVICE_TYPE,
  INHALER,
  SPIROMETER_DEVICE_TYPE,
  THERMOMETER_DEVICE_TYPE,
  PXO,
  AIR_QUALITY_MONITOR_DEVICE_TYPE,
  BLUETOOTH_CAP,
  HUB,
  LSC,
  LTE,
  HAILIE_INHALER_DEVICE_TYPE,
  HAILIE_INHALER,
  PTINR_MONITOR_DEVICE_TYPE,
  PTINR_MONITOR,
} from './constant';
import './HubDeviceEvents/HubDeviceEvents.scss';
import SleepReport from './HubDeviceEvents/Graph/SleepReport/SleepReport';
import StepsReport from './HubDeviceEvents/Graph/StepsReport/StepsReport';
import MovementReport from './HubDeviceEvents/Graph/MovementReport/MovementReport';
import BatteryConnectionTable from './HubDeviceEvents/Tables/BatteryConnectionTable';
import Spirometry from './HubDeviceEvents/Graph/Spirometry/Spirometry';
import HailieInhalerTable from './HubDeviceEvents/Tables/HailieInhalerTable';
import CompositeGraph from '../../../components/Graphs/CompositeGraph/CompositeGraph';
import { PageHeader } from '../../../components/PageHeader';
import { graphTypeEnum } from '../../../components/Graphs/CompositeGraph/GraphTypes';

class HubDeviceEvents extends PureComponent {
  constructor(props) {
    super(props);

    this.deviceType = props.match.params.deviceType;
    this.deviceId = { deviceId: props.match.params.deviceId };
    let selectedEventType = '';
    if (this.deviceType === 'bpm') selectedEventType = BLOOD_PRESSURE;
    else if (this.deviceType === 'weight-scales') selectedEventType = WEIGHT;
    else if (this.deviceType === 'wb') selectedEventType = HEART_RATE;
    else if (this.deviceType === 'glucometer') selectedEventType = GLUCOMETER;
    else if (this.deviceType === 'spirometer') selectedEventType = SPIROMETER;
    else if (this.deviceType === 'thermometer') selectedEventType = THERMOMETER;
    else if (this.deviceType === 'po') selectedEventType = PULSEOX;
    else if (this.deviceType === 'air-quality-monitor') selectedEventType = AIR_QUALITY_MONITOR;
    else if (this.deviceType === 'hailie-inhaler') selectedEventType = HAILIE_INHALER;
    else if (this.deviceType === 'pt_inr') selectedEventType = PTINR_MONITOR;

    this.state = {
      selectedEventType,
      plotStartDate: moment()
        .subtract(4, 'months')
        .startOf('month')
        .startOf('day')
        .format(),
      plotEndDate: moment()
        .add(1, 'days')
        .startOf('day')
        .format(),
    };
  }

  componentDidMount() {
    if (this.state.selectedEventType !== SPIROMETER && this.state.selectedEventType !== HAILIE_INHALER)
      this.props.loadDeviceTypes(this.deviceId).then(response => {
        if (response.type === HUB_DEVICES_TYPES_RESULT) {
          this.state.selectedEventType = response.response.data[0].type;
        }
      });
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  onEventTypeChange = option => {
    this.setState({ selectedEventType: option.value });
  };

  getContent = () => {
    const { selectedEventType, plotStartDate, plotEndDate } = this.state;
    switch (selectedEventType) {
      case SLEEP_REPORT:
        return (
          <SleepReport
            patientId={this.props.patientId}
            deviceId={this.deviceId.deviceId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            startDate={plotStartDate}
            endDate={plotEndDate}
          />
        );
      case HEART_RATE:
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.hr}
            hasBorder
          />
        );
      case BLOOD_PRESSURE:
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.bp}
            hasBorder
          />
        );
      case WEIGHT:
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.weight}
            hasBorder
          />
        );
      case 'glucometer':
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.glucose}
            hasBorder
          />
        );
      case 'thermometer':
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.temperature}
            hasBorder
          />
        );
      case STEPS_REPORT:
        return (
          <StepsReport
            patientId={this.props.patientId}
            deviceId={this.deviceId.deviceId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            startDate={plotStartDate}
            endDate={plotEndDate}
          />
        );
      case MOVEMENT_REPORT:
        return (
          <MovementReport
            patientId={this.props.patientId}
            deviceId={this.deviceId.deviceId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            startDate={plotStartDate}
            endDate={plotEndDate}
          />
        );
      case SPIROMETER:
        return (
          <Spirometry
            patientId={this.props.patientId}
            deviceId={this.deviceId.deviceId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            startDate={plotStartDate}
            endDate={plotEndDate}
          />
        );
      case PULSEOX:
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.pulseOx}
            hasBorder
          />
        );
      case CONNECTION:
      case BATTERY:
        return (
          <React.Fragment>
            <BatteryConnectionTable
              patientId={this.props.patientId}
              deviceId={this.deviceId.deviceId}
              hubId={this.props.hubId}
              kitId={this.props.kitId}
              startDate={plotStartDate}
              endDate={plotEndDate}
              eventType={selectedEventType}
            />
          </React.Fragment>
        );
      case AIR_QUALITY_MONITOR:
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.airQuality}
            hasBorder
          />
        );
      case HAILIE_INHALER:
        return (
          <HailieInhalerTable
            patientId={this.props.patientId}
            deviceId={this.deviceId.deviceId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            startDate={plotStartDate}
            endDate={plotEndDate}
          />
        );
      case PTINR_MONITOR:
        return (
          <CompositeGraph
            patientId={this.props.patientId}
            hubId={this.props.hubId}
            kitId={this.props.kitId}
            deviceId={this.deviceId.deviceId}
            graphType={graphTypeEnum.coagulation}
            hasBorder
          />
        );
      default:
        return <p>Not supported</p>;
    }
  };

  render() {
    return (
      <React.Fragment>
        <PageHeader
          right={
            <React.Fragment>
              {this.state.selectedEventType !== SPIROMETER && (
                <Select
                  data={this.props.eventTypes}
                  onChange={this.onEventTypeChange}
                  value={this.state.selectedEventType}
                />
              )}
            </React.Fragment>
          }
        />
        <div className="details-container">{this.getContent()}</div>
      </React.Fragment>
    );
  }
}

HubDeviceEvents.propTypes = {
  clearData: PropTypes.func,
  eventTypes: PropTypes.array,
  loadDeviceTypes: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      deviceId: PropTypes.string,
      deviceType: PropTypes.any,
      id: PropTypes.any,
      model: PropTypes.string,
      manufacturer: PropTypes.string,
    }),
    url: PropTypes.string,
  }),
  details: PropTypes.any,
  showBackButton: PropTypes.bool,
};

HubDeviceEvents.defaultProps = { showBackButton: true };

const mapStateToProps = state => {
  const { organizations } = state.superUser;

  const eventTypes = [];
  if (organizations && organizations?.deviceTypes) {
    organizations.deviceTypes.forEach(et => {
      eventTypes.push({
        value: et.type,
        label: et.value,
      });
    });
  }

  const urlParams = new URLSearchParams(window.location.search);

  return {
    eventTypes,
    details: state.shipper.capsDetails.data,
    lastActivity: urlParams.get('lastActivity'),
    patientId: urlParams.get('patientId'),
    hubId: urlParams.get('hubId'),
    kitId: urlParams.get('kitId'),
  };
};

const mapDispatchToProps = dispatch => ({
  loadDeviceTypes: pageRequest => dispatch(actions.getDeviceTypes(pageRequest)),
  clearData: () => dispatch(actions.clearData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HubDeviceEvents);

export const getUrlForDevice = (device, deviceClass) => {
  const hubId = device.hub_id ? device.hub_id : device.hubs?.length > 0 ? device.hubs[0] : '-';
  const deviceId = device.deviceId || device.device_id;
  let manufacturer = device.manufacturer;
  let model = device.model;
  if (model == '') {
    if (manufacturer.startsWith('gg-rpm')) {
      model = manufacturer.replace('gg-rpm ', '');
      manufacturer = 'gg-rpm';
    }
  }

  let deviceClassUrl = '';
  if (deviceClass === BLOOD_PRESSURE_MONITOR) deviceClassUrl = 'bpm';
  else if (deviceClass === WEIGHING_MACHINE) deviceClassUrl = 'weight-scales';
  else if (deviceClass === WRIST_BAND) deviceClassUrl = 'wb';
  else if (deviceClass === GLUCOMETER_DEVICE_TYPE) deviceClassUrl = 'glucometer';
  else if (deviceClass === INHALER) deviceClassUrl = 'inhaler';
  else if (deviceClass === SPIROMETER_DEVICE_TYPE) deviceClassUrl = 'spirometer';
  else if (deviceClass === THERMOMETER_DEVICE_TYPE) deviceClassUrl = 'thermometer';
  else if (deviceClass === PXO) deviceClassUrl = 'po';
  else if (deviceClass === AIR_QUALITY_MONITOR_DEVICE_TYPE) deviceClassUrl = 'air-quality-monitor';
  else if (deviceClass === HAILIE_INHALER_DEVICE_TYPE) deviceClassUrl = 'hailie-inhaler';
  else if (deviceClass === PTINR_MONITOR_DEVICE_TYPE) deviceClassUrl = 'pt_inr';
  else if (deviceClass === BLUETOOTH_CAP) {
    return `/devices/ble/${deviceId}`;
  } else if (deviceClass === HUB) {
    return `/devices/hub/${deviceId}`;
  } else if (deviceClass === LSC || deviceClass == LTE) {
    return `/devices/lte/${deviceId}`;
  } else {
    return `/device-under-construction`;
  }

  return `/devices/${deviceClassUrl}/${hubId}/devices/${deviceId}/${manufacturer}/${model}/events`;
};
