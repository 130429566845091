import PropTypes from 'prop-types';

import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { DATE_MONTH_DAY_YEAR, LOCAL_TIMEZONE, PAGE_LIMIT, TIME_FORMAT_12_UPPERCASE } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { getHWValue } from '../../../utils';
import './patient.scss';
import { actions } from './redux/actions';

class PatientCapDetails extends PureComponent {
  searchTime;
  componentDidMount() {
    const capId = this.props.match.params.capId;
    this.pageRequest = {
      offset: 0,
      search: '',
      limit: PAGE_LIMIT,
      activityType: 'all',
    };
    this.props.getDevices(this.pageRequest, capId);
  }

  onSearchQueryChange = query => {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    clearTimeout(this.searchTime);
    this.searchTime = setTimeout(() => {
      this.props.getDevices(this.pageRequest);
    }, 1000);
  };

  onPrevClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset - PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getDevices(this.pageRequest);
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.getDevices(this.pageRequest);
  };

  onNextClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset + PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getDevices(this.pageRequest);
  };

  onSortClick = ({ sortKey, direction }) => {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    this.props.getDevices(this.pageRequest);
  };

  onBackButton = () => {
    const patientId = this.props.match.params.id;
    this.props.onNavigate(`/cap-patients/${patientId}/devices`);
  };

  render() {
    const { isLoading, patientCapDetails, pagination, patientDetails } = this.props;

    return (
      <div className="patient-container">
        <div>
          <div className="infoHeader">
            <div className="rowDirection">
              <button className="back" onClick={() => this.onBackButton()} tabIndex={0}>
                {Strings.back}
              </button>
              <div className="feildBox">{/* {headerLable}: <div className="feildValue">{selectedDevice}</div> */}</div>
            </div>
          </div>
        </div>

        <div className="details-container">
          <Table
            isLoading={isLoading}
            name="capsDetails"
            data={patientCapDetails ?? []}
            onPrevClick={this.onPrevClick}
            onNextClick={this.onNextClick}
            onCustomPage={this.onCustomPage}
            pagination={pagination}
          >
            <Column
              key="timestamp"
              title={Strings.date}
              value={e => {
                if (e.tzinfo === LOCAL_TIMEZONE) {
                  return e.timestamp ? moment.unix(e.timestamp).format(DATE_MONTH_DAY_YEAR) : '-';
                }
                return e.timestamp ? moment.utc(moment.unix(e.timestamp)).format(DATE_MONTH_DAY_YEAR) : '-';
              }}
            />
            <Column
              key="timestampTime"
              title={Strings.time}
              value={e => {
                if (e.tzinfo === LOCAL_TIMEZONE) {
                  return e.timestamp ? moment.unix(e.timestamp).format(TIME_FORMAT_12_UPPERCASE) : '-';
                }
                return e.timestamp ? moment.utc(moment.unix(e.timestamp)).format(TIME_FORMAT_12_UPPERCASE) : '-';
              }}
            />
            <Column
              key={Strings.eventType}
              title={Strings.eventType}
              value={e => (e.activity_type ? e.activity_type : '-')}
            />
            <Column
              key={Strings.value}
              title={Strings.value}
              value={e => {
                return getHWValue(e);
              }}
            />
          </Table>
        </div>
      </div>
    );
  }
}

PatientCapDetails.propTypes = {
  getDevices: PropTypes.func,
  isLoading: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.shape({
      capId: PropTypes.any,
      id: PropTypes.any,
      type: PropTypes.any,
    }),
  }),
  onNavigate: PropTypes.func,
  pagination: PropTypes.shape({ offset: PropTypes.any }),
  patientCapDetails: PropTypes.array,
  patientDetails: PropTypes.any,
  patientId: PropTypes.any,
};

const mapStateToProps = state => {
  const { patients } = state.superUser;

  return {
    patientCapDetails: patients && patients?.patientCapDetails?.activity,
    isLoading: patients && patients?.isLoading,
    pagination: patients && patients?.patientCapDetails?.pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getDevices: (pageRequest, capId) => dispatch(actions.getCapDetails(capId, pageRequest)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientCapDetails);
