import PropTypes from 'prop-types';

import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { actions } from '../Patients/redux/actions';
import { TIME_FORMAT_12_UPPERCASE } from '../../../constants';
import PatientDetails from '../Patients/PatientInfo/PatientDetails';
import MedicationWidget from '../Patients/PatientMedications/MedicationWidget';

class PatientInfo extends PureComponent {
  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  onSearchQueryChange = query => {
    return this.onSearchQueryChangeDebounced(query);
  };

  componentDidMount() {
    const { patientId } = this.props;
    this.props.getPatientInfo(patientId);
    this.props.getMedications(patientId);
  }

  componentWillUnmount() {
    this.props.clearPatientInfo();
  }

  getTime = medication => {
    const offset = medication?.offset;

    if (!offset) return '-';

    if (medication?.offset) {
      const size = medication?.offset.length - 1;
      return medication.offset.map((item, index) => {
        const timeText = moment()
          .startOf('day')
          .seconds(item)
          .format(TIME_FORMAT_12_UPPERCASE);
        if (size == index) {
          return <div className="timeText">{timeText}</div>;
        }
        return <div className="timeText">{timeText},</div>;
      });
    }
  };

  render() {
    const { patientDetails, patientMedications } = this.props;
    return (
      <div className="patient-container">
        <div className="widgetContainer widgetContainerNoPadding">
          <PatientDetails patientDetails={patientDetails} isCollapsible />
        </div>
        {patientMedications &&
          patientMedications.length > 0 &&
          patientMedications.map(medication => (
            <div className="widgetContainer widgetContainerNoPadding">
              <MedicationWidget schedule={medication} patient={patientDetails} isCollapsible hideActions />
            </div>
          ))}
      </div>
    );
  }
}

PatientInfo.propTypes = {
  getPatientInfo: PropTypes.func,
  getPatientData: PropTypes.func,
  clearPatientInfo: PropTypes.func,
  getMedications: PropTypes.func,
  isLoading: PropTypes.any,
  patientDetails: PropTypes.shape({
    centerDetails: PropTypes.shape({ map: PropTypes.func }),
    dateOfBirth: PropTypes.any,
    email: PropTypes.any,
    homeAddress: PropTypes.any,
    insurance: PropTypes.shape({ map: PropTypes.func }),
    medicalHistory: PropTypes.shape({ map: PropTypes.func }),
    mrn: PropTypes.any,
    patientName: PropTypes.any,
    primaryPhoneNo: PropTypes.any,
    textPhoneNo: PropTypes.any,
    transplantDetails: PropTypes.shape({ map: PropTypes.func }),
  }),
  patientId: PropTypes.any,
  patientMedications: PropTypes.array,
};

const mapStateToProps = state => {
  const { patients } = state.superUser;
  return {
    patientDetails: patients?.patientDetails,
    isLoading: patients?.isLoading,
    patientMedications: patients?.patientMedications?.medications,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getPatientInfo: patientId => dispatch(actions.getPatientInfo(patientId)),
  clearPatientInfo: () => dispatch(actions.clearPatientInfo()),
  getMedications: patientId => dispatch(actions.getMedications(patientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo);
