import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { closeModal } from '../../actions/modal';
import Form, { Input, SelectField } from '../../containers/Form';
import Strings from '../../Strings';
import { notificationActions } from '../../components/Notification/redux/actions';
import './WidgetConfigurationModal.scss';
import { Modal } from '../../containers';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { actionsDashboard } from '../../pages/Dashboards/redux/actions';
import { removeNonErrors } from '../../utils/validators/rpmPatient';
import { GetDefaultConfiguration } from '../../pages/Dashboards/DashboardsMetadata';

function WidgetConfigurationModal(props) {
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);
  const [saveButtonHovered, setSaveButtonHovered] = useState(false);
  const [config, setConfig] = useState({ ...props.data.configuration });

  const onChange = (property, value) => {
    setConfig(c => ({
      ...c,
      [property]: { ...c[property], value },
    }));
  };

  const validateField = field => {
    const ret = { property: field.name, errors: [], missing: false };
    if (field.isRequired && !field.value) {
      ret.missing = true;
    }
    if ((field.max && field.value > field.max) || (field.min && field.value < field.min)) {
      ret.errors.push(Strings.formatString(Strings.allowedRangeWarning, field.min, field.max));
    }
    return ret;
  };

  const validateForm = () => {
    let errors = [];
    Object.entries(config).forEach(([key, c]) => {
      errors = errors.concat(validateField(c));
    });
    return removeNonErrors(errors);
  };

  const onSave = () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);
    props.editWidgetConfiguration(props.data.widget.id, config);
    props.showNotification(Strings.formatString(Strings.itemSaved, Strings.configuration));
    props.closeModal();
    setSaveBtnClicked(false);
  };

  const validationErrors = validateForm();

  return (
    <Modal name="widget-configuration" withoutContainer {...props}>
      <div className="config-modal">
        <h2>{props.data.widget?.name}</h2>
        <Form onCancel={props.closeModal} id="widget-configuration">
          <OverlayScrollbarsComponent
            defer
            options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
            key="scrollbar"
          >
            <div className="content-block">
              {config &&
                Object.entries(config)
                  .filter(([_, c]) => c.type !== 'select')
                  .map(([key, c]) => (
                    <div key={c.name} className="config-modal-row">
                      <Input
                        id={c.name}
                        onChange={e => onChange(key, e.target.value)}
                        isRequired={c.isRequired}
                        highlightInvalid={saveButtonHovered}
                        errorsForTooltip={validationErrors.find(v => v.property === c.name)?.errors || []}
                        {...c}
                        defaultValue={c.value ? undefined : c.defaultValue}
                      />
                    </div>
                  ))}
              {config &&
                Object.entries(config)
                  .filter(([_, c]) => c.type === 'select')
                  .map(([key, c]) => (
                    <div key={c.name} className="config-modal-row">
                      <SelectField
                        id={c.name}
                        onChange={option => onChange(key, option.value)}
                        isRequired={c.isRequired}
                        highlightInvalid={saveButtonHovered}
                        errorsForTooltip={validationErrors.find(v => v.property === c.name)?.errors || []}
                        data={c.options}
                        {...c}
                        defaultValue={c.value ? undefined : c.defaultValue}
                      />
                    </div>
                  ))}
            </div>
          </OverlayScrollbarsComponent>
          <div key="content">
            <div className="requiredFieldsError">
              {saveButtonHovered && validationErrors.filter(e => e.missing === true)?.length > 0
                ? `${validationErrors.filter(e => e.missing === true)?.length} *${Strings.fieldsRequired}`
                : '\xa0'}
            </div>
            <div className="buttons" key="button-bar">
              <div className="bottom-button-container">
                <button className="brand-white-gray" key="cancel" type="cancel">
                  {Strings.cancel}
                </button>
              </div>
              <div className="bottom-button-container">
                <button
                  className="brand-white-gray"
                  key="restoreDefaults"
                  onClick={() =>
                    setConfig(
                      GetDefaultConfiguration(props.data.dashboardId, props.data.additionalData)[props.data.widget.id],
                    )
                  }
                >
                  {Strings.setDefault}
                </button>
              </div>
              <div
                className="bottom-button-container"
                onMouseEnter={() => setSaveButtonHovered(true)}
                onMouseLeave={() => setSaveButtonHovered(false)}
              >
                <button
                  className="brand-blue"
                  key="submit"
                  type="button"
                  onClick={onSave}
                  disabled={validationErrors.length > 0 || saveBtnClicked}
                >
                  {Strings.save}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

WidgetConfigurationModal.propTypes = {
  data: PropTypes.shape({
    configuration: PropTypes.object,
    widget: PropTypes.object,
    dashboardId: PropTypes.string,
    additionalData: PropTypes.any,
  }),
  closeModal: PropTypes.func,
  showNotification: PropTypes.func,
  editWidgetConfiguration: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeModal: () => dispatch(closeModal('widget-configuration')),
  editWidgetConfiguration: (widgetId, configuration) =>
    dispatch(actionsDashboard.changeWidgetConfiguration(ownProps.data.dashboardId, widgetId, configuration)),
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
});

export default connect(null, mapDispatchToProps)(WidgetConfigurationModal);
