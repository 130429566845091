import PropTypes from 'prop-types';
import React from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Mention, MentionsInput } from 'react-mentions';

import './TextAreaWithHashtags.scss';

export const TextAreaWithHashtags = React.forwardRef((props, ref) => {
  const customSuggestionsContainer = children => {
    return (
      <OverlayScrollbarsComponent
        defer
        className="suggestions-scrolled-container"
        options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
      >
        <div className="suggestion-flex">{children}</div>
      </OverlayScrollbarsComponent>
    );
  };

  const renderSuggestion = (entry, search, highlightedDisplay, index, focused) => {
    return (
      <React.Fragment>
        <div
          className="tag-suggestion"
          data-tooltip-content={props.tooltipForEntries}
          data-tooltip-id="tooltip"
          data-tooltip-hidden={!props.tooltipForEntries || !focused}
        >
          <div className="tag-suggestion-header">
            {!props.simpleMenu && (
              <React.Fragment>
                <div className="tag-suggestion-header-title">{entry.title}</div>
                <div className="tag-suggestion-header-shortcut">
                  {props.trigger}
                  {entry.shortcut}
                </div>
              </React.Fragment>
            )}
          </div>
          {entry.display}
        </div>
      </React.Fragment>
    );
  };

  const onMessageChange = e => {
    let text = e?.target?.value;
    if (text && props.convertTagToPlainText) {
      const mentionPattern = /@\[(.*)\](.*)/;
      const newText = text.replace(mentionPattern, '$1');
      props.onChange(newText);
    } else {
      props.onChange(text);
    }
  };

  const rtl_rx = /[\u0591-\u07FF]/;
  const isRtl = rtl_rx.test(props.value);

  return (
    <MentionsInput
      value={props.value || ''}
      onChange={onMessageChange}
      className={`message-textbox ${props.className} ${isRtl ? 'rtl' : ''}`}
      forceSuggestionsAboveCursor={props.forceSuggestionsAboveCursor}
      customSuggestionsContainer={customSuggestionsContainer}
      inputRef={ref}
      dir={isRtl ? 'rtl' : undefined}
    >
      <Mention
        className="mentions__mention"
        trigger={props.trigger}
        markup={props.markup || undefined}
        displayTransform={props.displayTransform || undefined}
        regex={props.regex || undefined}
        data={(search, callback) => callback(props.hashtags.filter(h => h.shortcut.startsWith(search)))}
        renderSuggestion={renderSuggestion}
      />
    </MentionsInput>
  );
});

TextAreaWithHashtags.propTypes = {
  onChange: PropTypes.func,
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]).isRequired,
  markup: PropTypes.string,
  displayTransform: PropTypes.func,
  hashtags: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string,
      shortcut: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  tooltipForEntries: PropTypes.string,
  convertTagToPlainText: PropTypes.bool,
  className: PropTypes.string,
  forceSuggestionsAboveCursor: PropTypes.bool,
};

TextAreaWithHashtags.defaultProps = { trigger: '#', convertTagToPlainText: false, forceSuggestionsAboveCursor: true };
