import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './ActionWidget.scss';

function RadioButton(props) {
  const { selected, onChange, text, value } = props;

  let cssCheckedStatus = 'unchecked';
  if (value === selected) cssCheckedStatus = 'checked';

  return (
    <div
      className="action-dropdown-menu-label-radio"
      onClick={() => {
        onChange(value);
      }}
    >
      <div className="action-dropdown-menu-label">{text}</div>
      <div className={`action-dropdown-menu-checkbox-${cssCheckedStatus}`}></div>
    </div>
  );
}

RadioButton.propTypes = {
  selected: PropTypes.any,
  onChange: PropTypes.func,
  text: PropTypes.string,
  value: PropTypes.any,
};

function ActionDropdownOnOffMenu(props) {
  const [open, setOpen] = useState(0);
  const [selected, setSelected] = useState(0);

  let css_styles = props.img.concat(' action-button tooltip');
  if (props.disabled === true) {
    css_styles += ' disabled';
  }

  const { text } = props;

  const onChange = value => {
    if (value !== selected) {
      setSelected(value);
      props.action(value);
    }
  };

  const toggle = () => {
    setOpen(!open);
    setSelected(null);
  };

  const onOffButtons = (
    <React.Fragment>
      <RadioButton
        value={0}
        selected={selected}
        text={`${text} (OFF)`}
        onChange={value => {
          onChange(value);
        }}
      />
      <RadioButton
        value={1}
        selected={selected}
        text={`${text} (ON)`}
        onChange={value => {
          onChange(value);
        }}
      />
    </React.Fragment>
  );

  const menu = (
    <div className="action-dropdown-on-off-menu" onClick={e => e.stopPropagation()}>
      {onOffButtons}
    </div>
  );

  return (
    <div className="action-dropdown-icon-container">
      <span
        onClick={toggle}
        className={css_styles}
        tabIndex={1}
        onBlur={() => {
          setOpen(false);
        }}
      >
        {!open ? <span className="tooltiptext">{props.tooltiptext}</span> : ''}
        {open ? menu : ''}
      </span>
    </div>
  );
}

ActionDropdownOnOffMenu.propTypes = {
  text: PropTypes.string,
  img: PropTypes.string,
  disabled: PropTypes.bool,
  tooltiptext: PropTypes.string,
  action: PropTypes.func,
};

function ActionDropdownSingleChoiceMenu(props) {
  let css_styles = props.img.concat(' action-button tooltip');
  if (props.disabled === true) {
    css_styles += ' disabled';
  }

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const onChange = value => {
    if (value !== selected) {
      setSelected(value);
      props.action(value);
    }
  };

  const toggle = () => {
    setOpen(!open);
    setSelected(null);
  };

  const radioButtons = props.options.map(e => (
    <RadioButton
      value={e.value}
      selected={selected}
      text={e.text}
      onChange={value => {
        onChange(value);
      }}
    />
  ));

  const menu = (
    <div className="action-dropdown-on-off-menu" onClick={e => e.stopPropagation()}>
      {radioButtons}
    </div>
  );

  return (
    <div className="action-dropdown-icon-container">
      <span onClick={toggle} className={css_styles} tabIndex={1} onBlur={() => setOpen(false)}>
        {!open ? <span className="tooltiptext">{props.tooltiptext}</span> : ''}
        {open ? menu : ''}
      </span>
    </div>
  );
}

ActionDropdownSingleChoiceMenu.propTypes = {
  text: PropTypes.string,
  img: PropTypes.string,
  disabled: PropTypes.bool,
  tooltiptext: PropTypes.string,
  action: PropTypes.func,
  options: PropTypes.array,
};

function ActionButton(props) {
  let css_styles = `${props.img} action-button tooltip`;
  let action = props.action;
  if (props.disabled === true) {
    css_styles += ' disabled';
    action = () => {};
  }
  return (
    <div className="action-dropdown-icon-container">
      <span onClick={action} className={css_styles}>
        <span className="tooltiptext">{props.tooltiptext}</span>
      </span>
    </div>
  );
}

ActionButton.propTypes = {
  action: PropTypes.func,
  disabled: PropTypes.bool,
  img: PropTypes.string,
  text: PropTypes.string,
  tooltiptext: PropTypes.any,
};

function ActionWidget(props) {
  const widget = <div className="action-widget">{props.children}</div>;
  return <React.Fragment>{props.show && widget}</React.Fragment>;
}

ActionWidget.propTypes = {
  children: PropTypes.any,
  show: PropTypes.any,
};

class ItemSelectorHelper {
  constructor() {
    this.selectedItems = [];

    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.getItems = this.getItems.bind(this);
    this.getFirstItem = this.getFirstItem.bind(this);
    this.isMultiselect = this.isMultiselect.bind(this);
    this.isSelected = this.isSelected.bind(this);
  }

  addItem(id) {
    this.selectedItems.push(id);
  }

  removeItem(id) {
    this.selectedItems = this.selectedItems.filter(item => item !== id);
  }

  getItems() {
    return this.selectedItems;
  }

  getFirstItem() {
    return this.selectedItems[0];
  }

  isMultiselect() {
    return this.selectedItems.length > 1;
  }

  clearItems() {
    this.selectedItems = [];
  }

  isSelected(id) {
    return this.selectedItems.includes(id);
  }
}

export { ActionButton, ActionWidget, ActionDropdownOnOffMenu, ActionDropdownSingleChoiceMenu, ItemSelectorHelper };
