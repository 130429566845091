import React from 'react';
import { Cell, Customized, Line, Rectangle, Scatter } from 'recharts';
import {
  NOTIFICATIONS_COLORS,
  graphMissedTakeDotColor,
  graphUnscheduledTakeDotColor,
  graphValidTakeBarColor,
  graphValidTakeDotColor,
} from './Constants';
import { drawDotShape } from './Components';
import { scatterDotShapeEnum, symbolDotSizeEnum } from './Formatting';

const CustomizedTake = props => {
  const { formattedGraphicalItems } = props;

  //console.log(props);

  // get first and second series in chart
  const firstSeries = formattedGraphicalItems[0];
  const secondSeries = formattedGraphicalItems[1];

  // render custom content using points from the graph
  return firstSeries?.props?.points.map((firstSeriesPoint, index) => {
    const secondSeriesPoint = secondSeries?.props?.points[index];
    const yDifference = secondSeriesPoint.y - firstSeriesPoint.y;

    if (firstSeriesPoint.x > firstSeries?.props?.width + firstSeries?.props?.left) return null;

    const width = 3;

    return (
      <Rectangle
        key={`CT_${index}`}
        width={width}
        height={yDifference + 5}
        x={firstSeriesPoint.x - width / 2}
        y={firstSeriesPoint.y}
        stroke={graphValidTakeBarColor}
        fill={graphValidTakeBarColor}
        radius={5}
      />
    );
  });
};

const drawTakes_Bars = takesData => {
  return <Customized component={CustomizedTake} />;
};

const drawTakes_Delta = takesData => {
  const filteredData = takesData.filter(i => i.type === 'scheduled_take');
  const seriesData = filteredData.map(i => {
    return { x: i.dt.unix(), y: i.value };
  });

  return (
    <Line
      type="linear"
      tooltipType="none"
      name="takes0"
      data={seriesData}
      dataKey="y"
      connectNulls
      yAxisId="yAxis1"
      visibility="hidden"
      activeDot={false}
    />
  );
};

const drawTakes_Valid = takesData => {
  const filteredData = takesData.filter(i => i.type === 'scheduled_take');
  const seriesData = filteredData.map(i => {
    return { x: i.dt.unix(), y: 0 };
  });

  //console.log(`series ValidTakes: ${seriesData.length} elements`);

  return (
    <Scatter
      tooltipType="none"
      name="takesValid"
      stroke={graphValidTakeDotColor}
      fill={graphValidTakeDotColor}
      data={seriesData}
      dataKey="y"
      dot={{ r: 2, strokeWidth: 2 * 2 }}
      activeDot={false}
      isAnimationActive={false}
      yAxisId="yAxis1"
    />
  );
};

const drawTakes_Missed = takesData => {
  const filteredData = takesData.filter(i => i.type === 'missed_take');
  const seriesData = filteredData.map(i => {
    return { x: i.dt.unix(), y: 0 };
  });

  return (
    <Scatter
      tooltipType="none"
      name="takesMissed"
      stroke={graphMissedTakeDotColor}
      fill={graphMissedTakeDotColor}
      data={seriesData}
      dataKey="y"
      dot={{ r: 2, strokeWidth: 2 * 2 }}
      activeDot={false}
      isAnimationActive={false}
      yAxisId="yAxis1"
    />
  );
};

const drawTakes_Unscheduled = takesData => {
  const filteredData = takesData.filter(i => i.type === 'unscheduled_take');
  const seriesData = filteredData.map(i => {
    return { x: i.dt.unix(), y: 0 };
  });

  return (
    <Scatter
      tooltipType="none"
      name="takesUnscheduled"
      stroke={graphUnscheduledTakeDotColor}
      fill={graphUnscheduledTakeDotColor}
      data={seriesData}
      dataKey="y"
      dot={{ r: 2, strokeWidth: 2 * 2 }}
      activeDot={false}
      isAnimationActive={false}
      yAxisId="yAxis1"
    />
  );
};

const drawNotifications = (takesData, tick) => {
  const filteredData = takesData.filter(i =>
    [
      'SCHEDULE_MISSED_DOSE_SMS',
      'SCHEDULE_ON_TIME_REMINDER_SMS',
      'SCHEDULE_LATE_REMINDER_SMS',
      'SCHEDULE_EARLY_REMINDER_SMS',
    ].includes(i.type),
  );
  const seriesData = filteredData.map(i => {
    return { x: i.dt.unix(), y: tick, color: NOTIFICATIONS_COLORS[i.type] };
  });

  return (
    <Scatter
      tooltipType="none"
      name="takesMissed"
      data={seriesData}
      dataKey="y"
      dot={{ r: 2, strokeWidth: 2 * 2 }}
      activeDot={false}
      isAnimationActive={false}
      yAxisId="yAxis1"
      shape={e =>
        drawDotShape(scatterDotShapeEnum.cross, {
          ...e,
          format: {
            dotSize: symbolDotSizeEnum.small,
          },
        })
      }
    >
      {seriesData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={entry.color} />
      ))}
    </Scatter>
  );
};

export function drawTakes(takesData, tick, showReminders) {
  return (
    <React.Fragment>
      {drawTakes_Bars(takesData)}
      {drawTakes_Delta(takesData)}
      {drawTakes_Valid(takesData)}
      {showReminders && drawNotifications(takesData, tick)}
      {drawTakes_Missed(takesData)}
      {drawTakes_Unscheduled(takesData)}
    </React.Fragment>
  );
}
