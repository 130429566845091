import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { capsAction } from '../../../actions/caps';
import { openModalAction } from '../../../actions/modal';
import { PageHeader } from '../../../components/PageHeader';
import { Button } from '../../../components/PageHeader/Button';
import { TextInput } from '../../../components/PageHeader/TextInput';
import { HEADER_BUTTON_BLUE, PAGE_LIMIT } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import { ADD_ORAGANIZATION, UPDATE_ORAGANIZATION } from '../../../modals/AddOrganizationModal/redux/constants';
import Strings from '../../../Strings';
import { actions } from './redux/actions';
import { showStatus } from '../../../utils';

class Organization extends PureComponent {
  searchTime;

  componentDidMount() {
    this.pageRequest = {
      offset: 0,
      search: '',
    };
    this.props.getOrganizations(this.pageRequest);
  }

  onSearchQueryChange = query => {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    clearTimeout(this.searchTime);
    this.searchTime = setTimeout(() => {
      this.props.getOrganizations(this.pageRequest);
    }, 1000);
  };

  getHeaderComponents = () => {
    const { onAddOrganization } = this.props;
    return (
      <React.Fragment>
        <TextInput
          class="search"
          placeholder={Strings.search}
          onChange={e => this.onSearchQueryChange(e.target.value)}
        />
        <Button class={HEADER_BUTTON_BLUE} onClick={onAddOrganization} title={Strings.addOrganization} />
      </React.Fragment>
    );
  };

  onPrevClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset - PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getOrganizations(this.pageRequest);
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.getOrganizations(this.pageRequest);
  };

  onNextClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset + PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getOrganizations(this.pageRequest);
  };

  onSortClick = ({ sortKey, direction }) => {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    this.props.getOrganizations(this.pageRequest);
  };

  onOrganizationSelected = id => {
    const { organizations } = this.props;
    if (organizations && organizations[id].masked_id) {
      const data = organizations[id];
      const maskedId = encodeURIComponent(data.masked_id);
      this.props.onNavigate(`/organization/${maskedId}/${organizations[id].name}/orgnization-manager`);
    }
  };

  onOrgEdit = id => {
    this.props.onEditOrganization(this.props.organizations[id]);
  };

  onOrgDelete = id => {
    const organization = this.props.organizations[id];
    const deleteData = {
      masked_id: organization.masked_id,
      deleted: true,
    };

    const data = {
      title: (
        <span>
          {Strings.deleteOrgWarning} <b>{organization.name}</b>?
        </span>
      ),

      onConfirmAction: actions.deleteOrg(deleteData, [
        // actions.clearList(),
        actions.getOrganizations({
          ...this.request,
          limit: PAGE_LIMIT,
        }),
      ]),
      onCancelAction: null,
    };
    this.props.openConfirmModal(data);
  };

  render() {
    const { isLoading, organizations, pagination } = this.props;

    const buttons = [
      {
        icon: 'edit',
        onClick: this.onOrgEdit,
        disabled: () => {
          return false;
        },
        text: Strings.edit,
      },
      {
        icon: 'delete',
        onClick: this.onOrgDelete,
        disabled: () => {
          return false;
        },
        text: Strings.delete,
      },
    ];

    return (
      <div>
        <PageHeader right={this.getHeaderComponents()} />
        {/* {organizations && ( */}
        <Table
          isLoading={isLoading}
          name="organizations"
          data={organizations || []}
          onRowSelection={this.onOrganizationSelected}
          onPrevClick={this.onPrevClick}
          onSortClick={this.onSortClick}
          onNextClick={this.onNextClick}
          onCustomPage={this.onCustomPage}
          pagination={
            pagination || {
              offset: 0,
              total: 0,
            }
          }
          buttons={buttons}
        >
          <Column key="name" title={Strings.organizations} value={d => d.name} />
          <Column
            key="organization_managers"
            title={Strings.organizationManagers}
            value={d => d.organization_managers}
          />
          <Column key="cap_managers" title={Strings.capManagers} value={d => d.cap_managers} />
          <Column key="admins" title={Strings.admin} value={d => d.admins} />
          <Column key="patients" title={Strings.patients} value={d => d.patients} />
          <Column
            key="two_fa_enabled"
            title={Strings.twoFAEnabled}
            value={d => showStatus(d.two_fa_enabled ? 'enabled' : 'disabled')}
          />
        </Table>
        {/* )} */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { organizations } = state.superUser;

  return {
    organizations: organizations && organizations?.organization,
    isLoading: organizations && organizations?.isLoading,
    pagination: organizations && organizations?.pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getOrganizations: pageRequest => dispatch(actions.getOrganizations(pageRequest)),
  onAddOrganization: () =>
    dispatch(
      openModalAction('add-organization', {
        actionType: ADD_ORAGANIZATION,
      }),
    ),
  onEditOrganization: organization =>
    dispatch(
      openModalAction('edit-organization', {
        actionType: UPDATE_ORAGANIZATION,
        organization,
      }),
    ),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
