import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';
import Draggable from 'react-draggable';

import { closeModal, updateTimestamp } from '../../actions/modal';
import { Modal } from '../../containers';
import Form from '../../containers/Form';
import Strings from '../../Strings';
import './Wizard.scss';
import './FloatingModal.scss';
import ModalsVisibilityMenu from '../../modals/_ModalsMetadata/ModalsVisibilityMenu';
import { isMobile } from 'react-device-detect';

export const wizardTypes = {
  wizard: 'wizard',
  floatingModal: 'floatingModal',
};

const Wizard = React.forwardRef((props, scrollbarRef) => {
  const { pages, bringOnTop, isTopModal } = props;
  const [nextButtonHovered, setNextButtonHovered] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [collapsed, setCollapsed] = useState(props.openMinimized);

  const scrollableNodeRef = useRef();

  useEffect(() => {
    if (props.setScrollbarRef) {
      props.setScrollbarRef(scrollableNodeRef);
    }
  }, [scrollableNodeRef?.current]);

  useEffect(() => {
    if (currentPage > pages.length - 1) {
      setCurrentPage(p => p - 1);
    }
  }, [pages]);

  const previousPageNo = number => {
    return number === 0 ? null : number - 1;
  };

  const nextPageNo = number => {
    return number === pages.length - 1 ? null : number + 1;
  };

  const onNext = func => {
    if (nextPageNo(currentPage) === null && props.onSubmit) {
      props.onSubmit();
      return;
    }

    let canGoNext = true;
    const nextPage = nextPageNo(currentPage);
    if (func) {
      canGoNext = func();
    }
    if (canGoNext === false) {
      return;
    }
    if (canGoNext === true && nextPage) {
      setCurrentPage(nextPage);
    }
    if (canGoNext !== true && canGoNext !== false) {
      Promise.resolve(canGoNext).then(value => {
        if (value && nextPage) {
          setCurrentPage(nextPage);
        }
      });
    }
    if (canGoNext && nextPage) setCurrentPage(nextPage);
  };

  useEffect(() => {
    if (props.forceNextPage > 0) {
      onNext();
    }
  }, [props.forceNextPage]);

  const onPrevious = () => {
    const prevPage = previousPageNo(currentPage);
    if (prevPage !== null) setCurrentPage(prevPage);
  };

  const getNextButtonText = () => {
    return nextPageNo(currentPage) === null ? Strings.save : Strings.next;
  };

  const showBackButton = () => {
    if (previousPageNo(currentPage) !== null) {
      return (
        <div>
          <button className="brand-white-gray back-button" onClick={onPrevious}>
            {Strings.back}
          </button>
        </div>
      );
    }
    return <div>{'\xa0'}</div>;
  };

  const onNextButtonHover = value => {
    if (props.onNextButtonHover) {
      props.onNextButtonHover(value);
    }
    setNextButtonHovered(value);
  };

  const onCancel = () => {
    if (props.onCancelAction) {
      props.onCancelAction();
    }
    props.onCancel();
  };

  const floatingModalRightButtons = (
    <React.Fragment>
      <div className="buttonContainer">
        <button className="close-button" key="close" type="button" onClick={props.onCancel} />
      </div>
      <div className="buttonContainer">
        <button
          className={`minimize-button ${collapsed ? 'collapsed' : ''}`}
          key="minimize"
          type="button"
          onClick={() => setCollapsed(!collapsed)}
        />
      </div>
    </React.Fragment>
  );

  const floatingModal = (
    <Draggable cancel=".modalContent, .modalTabs" onStart={bringOnTop}>
      {!collapsed ? (
        <div
          className={`modalWrapper floating-modal ${isTopModal ? 'top-modal' : ''} ${isMobile ? 'mobile' : ''} ${
            props.name
          }`}
          onClick={bringOnTop}
        >
          <header className="modalHeader">
            {pages[currentPage]?.title}
            {pages.length > 1 ? (
              <div className="steps">
                {Array.from({ length: pages.length }, (_, i) => i).map(i => (
                  <div
                    key={`step-${i}`}
                    className={`step ${i < currentPage || pages[i].canGoNext ? 'completed' : ''}`}
                  />
                ))}
              </div>
            ) : (
              <></>
            )}
          </header>
          {pages.length > 1 ? (
            <div className="modalTabs">
              {Array.from({ length: pages.length }, (_, i) => i).map(i => (
                <div
                  className={`
                  tab ${i === currentPage ? 'active' : ''}
                  ${i < currentPage ? 'clickable' : ''}
                `}
                  onClick={() => {
                    setCurrentPage(i);
                  }}
                  key={`tab-${i}`}
                >
                  {pages[i].title}
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
          {pages[currentPage] && (
            <div className="modalContent">
              {pages[currentPage].content}
              <div className="button-bar" key="button-bar">
                {props.customButtons ? (
                  props.customButtons
                ) : (
                  <div
                    className="bottom-button-container"
                    onMouseEnter={() => onNextButtonHover(true)}
                    onMouseLeave={() => onNextButtonHover(false)}
                    data-tooltip-content={pages[currentPage].nextButton?.tooltip}
                    data-tooltip-id={pages[currentPage].nextButton?.tooltip ? 'tooltip' : undefined}
                  >
                    <button
                      className="brand-blue"
                      key="submit"
                      type="button"
                      onClick={() => onNext(pages[currentPage].onNext)}
                      disabled={!pages[currentPage].canGoNext}
                    >
                      {pages[currentPage].nextButton?.text || getNextButtonText()}
                    </button>
                  </div>
                )}
                <div className="right">{floatingModalRightButtons}</div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className={`modalWrapper floating-modal minimized ${isMobile ? 'mobile' : ''} ${
            isTopModal ? 'top-modal' : ''
          } ${props.name}`}
          onClick={bringOnTop}
        >
          <div className="modalContentMinimized">
            <div className="button-bar" key="button-bar">
              {pages[currentPage].titleMinimized ? pages[currentPage].titleMinimized : pages[currentPage].title}
              <div className="right">{floatingModalRightButtons}</div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );

  const wizard = (
    <Modal name={props.name} withoutContainer {...props}>
      {pages[currentPage] && (
        <div className={`wizard ${props.name}`}>
          <header className="wizardHeader">
            <div className="horizontal-flex center header-content">
              {showBackButton()}
              {pages[currentPage].title}
              <div>
                {props.showPagesFilter && props.pagesVisibility?.length > 1 && (
                  <ModalsVisibilityMenu modalId={props.name} />
                )}
              </div>
            </div>
            <div className="requiredFieldsError">
              {nextButtonHovered && pages[currentPage].emptyFieldsCount > 0
                ? `${pages[currentPage].emptyFieldsCount} *${Strings.fieldsRequired}`
                : '\xa0'}
            </div>
          </header>
          <div className="wizardContent">
            <Form onCancel={onCancel} id={props.name} key={pages[currentPage].id}>
              {pages[currentPage].noScrollbar ? (
                <div className="content-block">{pages[currentPage].content}</div>
              ) : (
                <SimpleBar
                  className="scroll-content"
                  key="form"
                  scrollableNodeProps={{ ref: scrollbarRef }}
                  onScroll={props.onScroll ? props.onScroll : () => {}}
                >
                  <div className="content-block">{pages[currentPage].content}</div>
                </SimpleBar>
              )}
              {props.customButtons ? (
                props.customButtons
              ) : (
                <div className="buttons" key="button-bar">
                  <div className="bottom-button-container">
                    <button className="brand-white-gray" key="cancel" type="cancel">
                      {pages[currentPage].closeButton?.text || Strings.close}
                    </button>
                  </div>
                  <div
                    className="bottom-button-container"
                    onMouseEnter={() => onNextButtonHover(true)}
                    onMouseLeave={() => onNextButtonHover(false)}
                    data-tooltip-content={pages[currentPage.nextButton?.tooltip]}
                    data-tooltip-id={pages[currentPage].nextButton?.tooltip ? 'tooltip' : undefined}
                  >
                    <button
                      className="brand-blue"
                      key="submit"
                      type="button"
                      onClick={() => onNext(pages[currentPage].onNext)}
                      disabled={!pages[currentPage].canGoNext}
                    >
                      {pages[currentPage].nextButton?.text || getNextButtonText()}
                    </button>
                  </div>
                </div>
              )}
            </Form>
          </div>
        </div>
      )}
    </Modal>
  );

  return props.type === wizardTypes.wizard ? wizard : floatingModal;
});

Wizard.propTypes = {
  name: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      titleMinimized: PropTypes.string,
      content: PropTypes.any.isRequired,
      emptyFieldsCount: PropTypes.number,
      onNext: PropTypes.func,
      canGoNext: PropTypes.bool,
      closeButton: PropTypes.shape({
        text: PropTypes.string,
      }),
      nextButton: PropTypes.shape({
        text: PropTypes.string,
        tooltip: PropTypes.string,
      }),
    }),
  ).isRequired,
  onNextButtonHover: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  forceNextPage: PropTypes.number,
  customButtons: PropTypes.any,
  type: PropTypes.string,
  bringOnTop: PropTypes.func,
  isTopModal: PropTypes.bool,
  showPagesFilter: PropTypes.bool,
  pagesVisibility: PropTypes.array,
  onScroll: PropTypes.bool,
  openMinimized: PropTypes.bool,
};

Wizard.defaultProps = { type: wizardTypes.wizard, showPagesFilter: true, openMinimized: false };

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal(ownProps.name, {}, ownProps.id)),
  bringOnTop: () => dispatch(updateTimestamp(ownProps.name, ownProps.id)),
});

const mapStateToProps = (state, ownProps) => {
  return {
    pagesVisibility: state.modalsVisibility.pagesVisibility.find(m => m.id === ownProps.name)?.pages,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Wizard);
