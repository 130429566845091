import createDecorator from 'final-form-calculate';
import { capitalize } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Errors } from '../../components/Login/Errors/Errors';
import { WizardForm } from '../../components/WizardForm/WizardForm';
import { states, GetTimezoneTextMapping } from '../../constants';
import { AutosuggestField } from '../../fields/AutosuggestField';
import { PhoneField } from '../../fields/PhoneField';
import { SelectField } from '../../fields/SelectField';
import { TextInputField } from '../../fields/TextInputField';
import { actions } from '../../modals/AddESIPatientModal/redux/actions';
import Strings from '../../Strings';
import { getCapType } from '../../utils';
import { GoogleUtils } from '../../utils/googleUtils';
import { composeValidators } from '../../utils/validators/composeValidators';
import { makeName } from '../../utils/validators/isName';
import { makeRequired } from '../../utils/validators/isRequired';
import { makeEmailValidation } from '../../utils/validators/isValidEmail';
import styles from './AddPatientForm.module.scss';

const mapStateToProps = state => {
  const { invitePatient } = state.esi;
  return {
    capsTypes:
      invitePatient && invitePatient.capsTypes && invitePatient.capsTypes.data ? invitePatient.capsTypes.data : [],
    schedule: invitePatient && invitePatient.schedule && invitePatient.schedule.data ? invitePatient.schedule.data : [],
    isScheduleLoading: invitePatient && invitePatient.schedule && invitePatient.schedule.isLoading === true,
  };
};

const mapDispatchToProps = dispatch => ({
  getScheduleDetails: capName => dispatch(actions.getScheduleDetails(capName)),
});
export class AddPatientForm extends React.PureComponent {
  TITLES = [this.props.isEdit ? 'Edit patient' : 'Add patient', 'Create schedule', 'Shipping to', 'Confirmation'];

  requireCheck = {
    firstName: false,
    lastName: false,
    email: false,
    timeZone: false,
    capType: false,
    streetAddress: false,
    zip: false,
    state: false,
    city: false,
  };

  static propTypes = {
    capsTypes: PropTypes.array,
    errors: PropTypes.array,
    getScheduleDetails: PropTypes.func,
    initialValues: PropTypes.shape({ shipping_country: PropTypes.string }),
    isEdit: PropTypes.any,
    isScheduleLoading: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    schedule: PropTypes.array,
  };

  state = {
    isComplete: true,
    values: null,
    addressSuggestionsError: '',
    selectMedicineData: [],
    selectStrengthData: [],
    selectTypeData: [],
  };

  phoneRef = React.createRef();
  refWizardForm = React.createRef();

  selectTimeOptions = [];

  componentDidMount() {
    // Seconds from 01:00 to 00:00 step 1 hour
    for (let i = 3600; i <= 86400; i += 3600) {
      this.selectTimeOptions.push({
        text: moment()
          .startOf('day')
          .seconds(i)
          .format('h:mm A'),
        value: i,
      });
    }
  }

  setValues = values => {
    this.setState({ isComplete: false });
    this.setState({ values });
  };

  phoneValidate = () => {
    const phoneInput = this.phoneRef.current;
    if (!this.phoneRef || !phoneInput) {
      return undefined;
    }
    return phoneInput.isValidNumber() ? undefined : phoneInput.getValidationErrorMessage();
  };

  findAddressFromQuery = async (value, setValue) => {
    return GoogleUtils.getPlacePredictions(value, setValue, this.setSuggestionError);
  };

  setSuggestionError = value => {
    this.setState({ addressSuggestionsError: value });
  };

  onAutosuggestSelected = place => {
    if (place.status !== 'OK') return;
    this.refWizardForm.current.setValue('shipping_state', place.addressDetails.state);
    this.refWizardForm.current.setValue('shipping_city', place.addressDetails.city);
    this.refWizardForm.current.setValue('shipping_zip', place.addressDetails.zip);
  };

  checkComplete = () => {
    if (
      this.requireCheck.firstName &&
      this.requireCheck.lastName &&
      this.requireCheck.email &&
      this.requireCheck.timeZone
    ) {
      this.setState({ isComplete: false });
    } else {
      this.setState({ isComplete: true });
    }
  };

  checkCompleteTwo = () => {
    if (this.requireCheck.capType) {
      this.setState({ isComplete: false });
    } else {
      this.setState({ isComplete: true });
    }
  };

  checkCompleteThree = () => {
    if (this.requireCheck.streetAddress && this.requireCheck.zip && this.requireCheck.state && this.requireCheck.city) {
      this.setState({ isComplete: false });
    } else {
      this.setState({ isComplete: true });
    }
  };

  firstNameValidate = value => {
    if (value == undefined) {
      this.requireCheck.firstName = false;
      this.checkComplete();
      return makeRequired(Strings.fieldIsRequired)(value);
    }
    this.requireCheck.firstName = true;
    this.checkComplete();
    return makeName(Strings.errors.invalidFirstName)(value);
  };

  lastNameValidate = value => {
    if (value == undefined) {
      this.requireCheck.lastName = false;
      this.checkComplete();
      return makeRequired(Strings.fieldIsRequired)(value);
    }
    if (makeName(Strings.errors.invalidLastName)(value)) {
      this.requireCheck.lastName = false;
      this.checkComplete();
    } else {
      this.requireCheck.lastName = true;
      this.checkComplete();
    }
    return makeName(Strings.errors.invalidLastName)(value);
  };

  emailValidate = value => {
    if (value == undefined) {
      this.requireCheck.email = false;
      this.checkComplete();
      return makeRequired(Strings.fieldIsRequired)(value);
    }
    this.requireCheck.email = true;
    this.checkComplete();
    return makeEmailValidation(Strings.errors.invalidEmail)(value);
  };

  timeZoneValidate = value => {
    if (value == undefined) {
      this.requireCheck.timeZone = false;
      this.checkComplete();
      return makeRequired(Strings.fieldIsRequired)(value);
    }
    this.requireCheck.timeZone = true;
    this.checkComplete();
    return undefined;
  };

  capTypeValidate = value => {
    if (value == undefined) {
      this.requireCheck.capType = false;
      this.checkCompleteTwo();
      return makeRequired(Strings.fieldIsRequired)(value);
    }
    this.requireCheck.capType = true;
    this.checkCompleteTwo();
    return undefined;
  };

  streetAddressValidate = value => {
    if (value == undefined) {
      this.requireCheck.streetAddress = false;
      this.checkCompleteThree();
      return makeRequired(Strings.fieldIsRequired)(value);
    }
    this.requireCheck.streetAddress = true;
    this.checkCompleteThree();
    return undefined;
  };

  zipValidate = value => {
    if (value == undefined) {
      this.requireCheck.zip = false;
      this.checkCompleteThree();
      return makeRequired(Strings.fieldIsRequired)(value);
    }
    this.requireCheck.zip = true;
    this.checkCompleteThree();
    return undefined;
  };

  stateValidate = value => {
    if (value == undefined) {
      this.requireCheck.state = false;
      this.checkCompleteThree();
      return makeRequired(Strings.fieldIsRequired)(value);
    }
    this.requireCheck.state = true;
    this.checkCompleteThree();
    return undefined;
  };

  cityValidate = value => {
    if (value == undefined) {
      this.requireCheck.city = false;
      this.checkCompleteThree();
      return makeRequired(Strings.fieldIsRequired)(value);
    }
    this.requireCheck.city = true;
    this.checkCompleteThree();
    return undefined;
  };

  calculateScheduleFields = createDecorator(
    {
      field: 'schedule_capSize',
      updates: {
        schedule_medicine: value => {
          const cap = this.props.capsTypes.find(e => e.id.toString() === value.toString());
          const text = cap.name;
          return new Promise(resolve => {
            this.props.getScheduleDetails(text).then(() => {
              const selectMedicineData = this.props.schedule.map(e => e.name);
              this.setState({ selectMedicineData });
              return resolve(selectMedicineData[0]);
            });
          });
        },
      },
    },
    {
      field: 'schedule_medicine',
      updates: {
        schedule_strength: (medicine, allValues, prevValues) => {
          const element = this.props.schedule.find(e => e.name === medicine);
          if (!element) return null;
          const selectStrengthData = [element.strength ? element.strength : '-'];
          this.setState({ selectStrengthData });
          if (Object.keys(prevValues).length === 0) return allValues.schedule_strength;
          return selectStrengthData[0];
        },
        schedule_period: (medicine, allValues, prevValues) => {
          const element = this.props.schedule.find(e => e.name === medicine);
          if (!element) return null;
          const selectTypeData = [element.type];
          this.setState({ selectTypeData });
          if (Object.keys(prevValues).length === 0) return allValues.schedule_period;
          return selectTypeData[0];
        },
        schedule_time: (medicine, allValues, prevValues) => {
          if (Object.keys(prevValues).length === 0) return allValues.schedule_time;
          const element = this.props.schedule.find(e => e.name === medicine);
          if (!element) return null;
          return element.offset[0];
        },
      },
    },
  );

  render() {
    const { initialValues = { shipping_country: 'United States' }, errors = [], isEdit } = this.props;

    const timezones = GetTimezoneTextMapping();

    const capSizeData =
      this.state.values &&
      this.state.values.schedule_capSize &&
      this.props.capsTypes.find(x => x.id.toString() === this.state.values.schedule_capSize);

    return (
      <WizardForm
        onSubmit={this.props.onSubmit}
        onCancel={this.props.onCancel}
        onNext={this.setValues}
        decorators={[this.calculateScheduleFields]}
        initialValues={initialValues}
        titles={this.TITLES}
        disableNext={this.state.isComplete}
        ref={this.refWizardForm}
      >
        <WizardForm.Page>
          <TextInputField
            name="patient_firstName"
            placeholder="* First name"
            align="left"
            validate={composeValidators(this.firstNameValidate)}
          />
          <TextInputField
            name="patient_lastName"
            placeholder="* Last name"
            align="left"
            validate={composeValidators(this.lastNameValidate)}
          />
          <TextInputField
            name="email"
            type="email"
            placeholder="* Email address"
            align="left"
            validate={composeValidators(this.emailValidate)}
          />
          <PhoneField name="patient_phone" align="left" linkRef={this.phoneRef} validate={this.phoneValidate} />

          <div>
            {timezones && (
              <SelectField
                name="timezone"
                placeholder="* Select Timezone"
                data={Object.keys(timezones).map(key => ({
                  text: timezones[key],
                  value: [key],
                }))}
                validate={composeValidators(this.timeZoneValidate)}
                disabled={isEdit}
              />
            )}
          </div>
        </WizardForm.Page>
        <WizardForm.Page>
          {this.props.isEdit ? (
            <TextInputField name="schedule_hwid" placeholder="Cap Hardware ID" align="left" />
          ) : (
            undefined
          )}
          <SelectField
            name="schedule_capSize"
            placeholder="* Cap Type"
            data={this.props.capsTypes?.map(e => ({
              text: e.title,
              value: e.id,
            }))}
            validate={composeValidators(this.capTypeValidate)}
          />
          <SelectField name="schedule_medicine" placeholder="Medicine" data={this.state.selectMedicineData} />
          <SelectField
            name="schedule_strength"
            placeholder="Strength"
            data={this.state.selectStrengthData}
            disabled={this.props.isScheduleLoading}
          />
          <SelectField
            name="schedule_period"
            placeholder="Period"
            data={this.state.selectTypeData}
            disabled={this.props.isScheduleLoading}
          />
          <SelectField
            name="schedule_time"
            placeholder="Time"
            data={this.selectTimeOptions}
            disabled={this.props.isScheduleLoading}
          />
        </WizardForm.Page>
        <WizardForm.Page>
          <AutosuggestField
            name="shipping_streetAddress"
            placeholder="* Street address"
            onSuggestionsFetch={this.findAddressFromQuery}
            // error={this.state.addressSuggestionsError}
            onAutosuggestSelected={this.onAutosuggestSelected}
            validate={composeValidators(this.streetAddressValidate)}
          />
          <TextInputField name="shipping_apartment" placeholder="Apartment / Suite (optional)" align="left" />
          <div className={styles.twoColumnsField}>
            <TextInputField
              name="shipping_zip"
              placeholder="* ZIP"
              align="left"
              onlyDigits
              validate={composeValidators(this.zipValidate)}
            />
            <AutosuggestField
              name="shipping_state"
              placeholder="* State"
              suggestions={states.map(x => ({
                ...x,
                name: capitalize(x.name),
              }))}
              className={styles.marginLeftFromState}
              validate={composeValidators(this.stateValidate)}
            />
          </div>
          <TextInputField
            name="shipping_city"
            placeholder="* City"
            align="left"
            validate={composeValidators(this.cityValidate)}
          />
          <TextInputField name="shipping_country" placeholder="Country" align="left" disabled />
        </WizardForm.Page>
        <WizardForm.Page>
          <div className={styles.title}>Schedule:</div>
          {this.state.values && (
            <React.Fragment>
              <div className={styles.summary}>{getCapType(capSizeData?.name)}</div>
              <div className={styles.summary}>{this.state.values.schedule_medicine}</div>
              {this.state.values.schedule_strength !== '-' && (
                <div className={styles.summary}>{this.state.values.schedule_strength}</div>
              )}
              <div className={styles.summary}>{this.state.values.schedule_period}</div>
              <div className={styles.summary}>
                {this.state.values &&
                  this.state.values.schedule_time &&
                  moment()
                    .startOf('day')
                    .seconds(this.state.values.schedule_time)
                    .format('h:mm A')}
              </div>
            </React.Fragment>
          )}
          <div className={styles.divider} />
          <div className={styles.title}>Ship to:</div>
          {this.state.values ? (
            <React.Fragment>
              <div className={styles.summary}>
                {this.state.values.patient_firstName} {this.state.values.patient_lastName}
              </div>
              <div className={styles.summary}>{this.state.values.shipping_streetAddress}</div>
              <div className={styles.summary}>{this.state.values.shipping_apartment}</div>
              <div className={styles.summary}>
                {this.state.values.shipping_state} {this.state.values.shipping_zip}
              </div>
            </React.Fragment>
          ) : null}

          {errors.length > 0 ? <Errors NoMT errors={errors} /> : null}
        </WizardForm.Page>
      </WizardForm>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPatientForm);
