import { API_CALL } from '../../../middleware/api';
import { ESI_PATIENT_CALENDAR_GET } from './constants';

export const calendarActions = {
  getAdherence: selectedMonth => ({
    [API_CALL]: {
      endpoint: `/v2/calendar/adherence/?start=${selectedMonth}`,
    },
    type: ESI_PATIENT_CALENDAR_GET,
  }),
};
