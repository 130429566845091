import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment-timezone';

import { MAX_DATE, MAX_MONTH } from '../constants';
import Strings from '../Strings';
import '../style/patientProfileForm.scss';
import { UsersCard } from './UsersCard/UsersInfoCard';

class PatientProfileForm extends PureComponent {
  static propTypes = {
    isPermit: PropTypes.bool,
    patient: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      username: PropTypes.string,
      dateOfBirth: PropTypes.string,
    }).isRequired,
    updateProfileFunc: PropTypes.func.isRequired,
  };

  state = {
    firstName: '',
    lastName: '',
    username: '',
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    email: '',
  };

  UNSAFE_componentWillMount() {
    if (this.props.patient) {
      const { patient } = this.props;
      this.updateState(patient);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.patient) {
      const { patient } = newProps;
      this.updateState(patient);
    }
  }

  onChange = (event, isSwitchEvent) => {
    if (isSwitchEvent === true) {
      this.setState(event);
    } else {
      const currentYear = moment().format('YYYY');
      const name = event.target.getAttribute('name');
      const value = event.target.value.trim();
      if (name === 'birthDay' && (Number.isNaN(value) || value.length > 2 || value > MAX_DATE)) {
        return;
      }
      if (name === 'birthMonth' && (Number.isNaN(value) || value.length > 2 || value > MAX_MONTH)) {
        return;
      }
      if (name === 'birthYear' && (Number.isNaN(value) || value.length > 4 || value > currentYear)) {
        return;
      }
      this.setState({ [event.target.name]: value });
    }
  };

  onSaveAllChanges = () => {
    const profileData = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      date_of_birth: `${this.state.birthYear}-${this.state.birthMonth}-${this.state.birthDay}`,
    };

    this.props.updateProfileFunc(profileData);
  };

  updateState = patient => {
    if (!patient || Object.keys(patient).length === 0) {
      return;
    }
    if (patient.dateOfBirth) {
      const dateParts = patient.dateOfBirth.split('-');
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      this.setState({
        birthYear: parseInt(dateParts[0], 10),
        birthMonth: monthNames[parseInt(dateParts[1], 10) - 1],
        birthDay: parseInt(dateParts[2], 10),
      });
    }
    this.setState({
      firstName: patient.firstName,
      lastName: patient.lastName,
      username: patient.username,
      email: patient.email,
      gender: patient.gender,
    });
  };

  render() {
    const { firstName, lastName, birthYear, birthMonth, birthDay, email, username, gender } = this.state;

    let dob = `${birthMonth}-${birthDay}-${birthYear}`;
    if (dob === '//') dob = null;

    const data = [
      {
        title: Strings.firstName,
        value: firstName,
      },
      {
        title: Strings.lastName,
        value: lastName,
      },
      {
        title: Strings.email,
        value: email,
      },
      {
        title: Strings.gender,
        value: gender,
      },
      {
        title: Strings.userName,
        value: username,
      },
      {
        title: Strings.dob,
        value: dob,
      },
    ];

    return (
      <div className="settings user-profile">
        <div className="details-container-profile">
          <UsersCard data={data} isSettings />
        </div>
      </div>
    );
  }
}

export default PatientProfileForm;
