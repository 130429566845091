import { WIDGET_GET_DEVICES_COUNT, WIDGET_GET_DEVICES_COUNT_RESULT, WIDGET_GET_DEVICES_COUNT_ERROR } from './constants';

export const widgetDevicesReducer = (state = {}, action) => {
  switch (action.type) {
    case WIDGET_GET_DEVICES_COUNT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        scales: undefined,
        bpMonitors: undefined,
      };
    case WIDGET_GET_DEVICES_COUNT_RESULT:
      return {
        ...state,
        isLoading: false,
        isError: false,
        scales: getDeviceCountFromResponse(action.response, 'Weighing Machine'),
        bpMonitors: getDeviceCountFromResponse(action.response, 'Blood Pressure Monitor'),
      };

    case WIDGET_GET_DEVICES_COUNT_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        scales: undefined,
        bpMonitors: undefined,
      };
    }
    default:
      return state;
  }
};

const getDeviceCountFromResponse = (response, deviceType) => {
  if (!response || !response.devices_count || response.devices_count.length < 1) return 0;
  const respDevType = response.devices_count.find(d => d.device_type === deviceType);
  if (!respDevType) return 0;
  return respDevType.count;
};
