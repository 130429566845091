import english from './language/en';
import spanish from './language/es';
import LocalizedStrings from 'react-localization';

let Strings = new LocalizedStrings({
  en: english,
  es: spanish,
});

export default Strings;
