import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';

import { QueryString } from '../../utils';
import { groupInvitationConfirm } from '../../actions/api';
import { ssoLogin } from '../../actions/auth';
import Strings from '../../Strings';

class GroupInvitationConfirmPage extends Component {
  static propTypes = {
    user: PropTypes.any,
    token: PropTypes.any,
    error: PropTypes.any,
    onError: PropTypes.func,
    onSendInvitationConfirm: PropTypes.func,
  };

  UNSAFE_componentWillMount() {
    const { user, token, error } = this.props;
    if (error) {
      this.props.onError();
    }
    this.props.onSendInvitationConfirm(user, token);
  }

  render() {
    return (
      <div className="activated-page">
        <div className="image" />
        <h1>{Strings.verificationCompleteText}</h1>
        <div className="caption">{Strings.invitationConfirmedSuccessfully}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const query = new QueryString(ownProps.location.search);
  const { membership = null, token = null, user = null } = query.params;
  const error = token === null || user === null || token === null;
  return {
    token,
    user,
    membership,
    error,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const query = new QueryString(ownProps.location.search);
  const { membership = null, token = null, user = null } = query.params;

  return {
    onError: () => dispatch(push('/')),
    onSendInvitationConfirm: () => {
      dispatch(
        ssoLogin({
          user,
          token,
          context: 'GROUP_INVITATION',
        }),
      ).then(() => {
        dispatch(
          groupInvitationConfirm({
            membership,
            response: true,
          }),
        );
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupInvitationConfirmPage);
