import { createBrowserHistory } from 'history';
import { authCheck } from '../utils';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import rootReducer from '../reducers/index';
import api from '../middleware/api';
import { BASE_NAME } from '../constants';
import persistStore from 'redux-persist/es/persistStore';

const configureStore = history => preloadedState =>
  createStore(
    rootReducer,
    preloadedState,
    compose(
      applyMiddleware(thunk, api /* , createLogger() */),
      applyMiddleware(routerMiddleware(history)),
      window.devToolsExtension ? window.devToolsExtension() : f => f,
    ),
  );

const history = createBrowserHistory({
  basename: BASE_NAME,
});
const auth = authCheck();
const store = configureStore(history)(auth);
const persistor = persistStore(store);

export { history, auth, store, persistor };
