import { createMigrate } from 'redux-persist';
import { storage } from '../persistStorage';
import { defaultPalette } from '../../pages/Dashboards/DashboardColorPalettes';
import { DASHBOARD_ID as ADHERENDE_DASHBOARD_ID } from '../../pages/Dashboard/Dashboard';

const removeIsResizableTrue = layout => {
  return layout.map(({ isResizable, ...widgetProps }) => {
    if (isResizable === false) {
      return { ...widgetProps, isResizable: false };
    }
    return widgetProps;
  });
};

const migrations = {
  0: previousVersionState => ({
    dashboardFilters: previousVersionState.dashboardFilters,
    dashboardLayouts: Object.entries(previousVersionState.dashboardLayouts).map(([dashboardId, layouts]) => {
      return {
        [dashboardId]: {
          l: removeIsResizableTrue(layouts.l),
          m: removeIsResizableTrue(layouts.m),
          s: removeIsResizableTrue(layouts.s),
        },
      };
    }),
  }),
  1: previousVersionState => ({
    dashboardFilters: previousVersionState.dashboardFilters,
    dashboardLayouts: previousVersionState.dashboardLayouts,
    configuration: { allDashboards: { colorPalette: defaultPalette } },
  }),
  2: previousVersionState => ({
    dashboardFilters: {
      ...previousVersionState.dashboardFilters,
      [ADHERENDE_DASHBOARD_ID]: previousVersionState.dashboardFilters[ADHERENDE_DASHBOARD_ID].map(filter => {
        if (filter.id === 'PatientsState' || filter.id === 'ActiveMonthlyPillboxUsers') {
          return { ...filter, checked: false };
        }
        return filter;
      }),
    },
    dashboardLayouts: previousVersionState.dashboardLayouts,
    configuration: previousVersionState.configuration,
  }),
};

export const dashboardStateConfig = {
  key: 'dashboardState',
  version: 2,
  migrate: createMigrate(migrations, { debug: false }),
  storage,
  blacklist: ['editMode'],
};
