import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions } from './redux/actions';
import { PageHeader } from '../../../components/PageHeader';
import { Column } from '../../../containers/Table/TableWithPagination';
import TableWithLocalPagination from '../../../containers/Table/TableWithLocalPagination';
import Strings from '../../../Strings';
import { TextInput } from '../../../components/PageHeader/TextInput';
import AdvancedFilters, { Filter } from '../../../components/AdvancedFilters/AdvancedFilters';
import AdvancedFiltersBar from '../../../components/AdvancedFilters/AdvancedFiltersBar';
import { PAGE_LIMIT } from '../../../constants';
import { getFiltersDefinition } from '../../../components/AdvancedFilters/FiltersDefinition';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../components/PageHeader/Button';
import { openModalAction } from '../../../actions/modal';
import { ADD_PREFAB_REPLY, EDIT_PREFAB_REPLY } from './redux/constants';
import { isMe } from '../../../utils/userRoles';
import { getRelevantFilters } from '../../../components/AdvancedFilters/helpers';

export const prefabType = {
  private: 'private',
  shared: 'shared_with_org',
};

function PrefabReplies(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [searchString, setSearchString] = useState();
  const [refreshEnable, setRefreshEnable] = useState(true);

  const getPrefabReplies = () => {
    setIsLoading(true);
    props.getPrefabReplies().then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getPrefabReplies();
  }, [props.refreshTimestamp]);

  const onRefresh = () => {
    setRefreshEnable(false);
    setTimeout(() => {
      setRefreshEnable(true);
    }, 30000);
    getPrefabReplies();
  };

  const pageHeaderLeftPart = (
    <React.Fragment>
      {isLoading
        ? Strings.showingWait
        : Strings.formatString(
            Strings.showingXOf,
            props.prefabReplies.length < PAGE_LIMIT ? props.prefabReplies.length : PAGE_LIMIT,
            props.prefabReplies.length,
            Strings.messages.prefabReplies,
          )}
    </React.Fragment>
  );

  const pageHeaderRightPart = (
    <React.Fragment>
      <TextInput className="search" placeholder={Strings.search} onChange={e => setSearchString(e.target.value)} />
      <AdvancedFilters>
        <Filter definition={getFiltersDefinition().PrefabMessageType} />
      </AdvancedFilters>
      <Button
        class={HEADER_BUTTON_DARK_BLUE}
        onClick={() => props.addPrefabReply(() => onRefresh())}
        title={Strings.new}
      />
    </React.Fragment>
  );

  const pageHeader = <PageHeader left={pageHeaderLeftPart} right={pageHeaderRightPart} />;

  const deletePrefabReply = prefab => {
    const data = {
      title: (
        <span>
          {Strings.deletePrefabReply} <b>{prefab.title}</b>?
        </span>
      ),

      onConfirmAction: actions.deletePrefabReply(prefab.id),
      onCancelAction: null,
      confirmPostAction: () => onRefresh(),
    };
    props.openConfirmModal(data);
  };
  const buttons = [
    {
      icon: 'delete',
      onClick: (id, data) => deletePrefabReply(data),
      disabled: (id, data) => !isMe(data?.created_by),
      text: Strings.delete,
    },
    {
      icon: 'edit',
      onClick: (id, data) => props.editPrefabReply(data, () => onRefresh()),
      disabled: (id, data) => !isMe(data?.created_by),
      text: Strings.edit,
    },
  ];

  const getFilteredData = () => {
    let ret = props.prefabReplies || [];

    if (searchString) {
      ret = props.prefabReplies.filter(
        p =>
          p.full_txt.toLowerCase().includes(searchString.toLowerCase()) ||
          p.shortcut.toLowerCase().includes(searchString.toLowerCase()) ||
          p.title.toLowerCase().includes(searchString.toLowerCase()) ||
          p.creator_display_name.toLowerCase().includes(searchString.toLowerCase()),
      );
    }

    const appliedFilters = getRelevantFilters(props.filters);
    if (appliedFilters?.length === 0) {
      return ret;
    }
    return ret.filter(p => appliedFilters.find(f => f.value === p.type));
  };

  const table = (
    <TableWithLocalPagination
      isLoading={isLoading}
      name="PrefabReplies"
      data={getFilteredData()}
      columns={[
        <Column key="title" title={Strings.title} value={e => e.title} />,
        <Column key="full_txt" title={Strings.message} value={e => e.full_txt} />,
        <Column key="shortcut" title={Strings.messages.shortcut} value={e => `#${e.shortcut}`} />,
        <Column key="author" title={Strings.messages.author} value={e => e.creator_display_name} />,
        <Column
          key="type"
          title={Strings.type}
          value={e => (e.type === prefabType.private ? Strings.messages.private : Strings.messages.shared)}
        />,
      ]}
      buttons={buttons}
    />
  );

  return (
    <div className="PrefabReplies">
      {pageHeader}
      <AdvancedFiltersBar />
      {props.prefabReplies?.length > 0 && table}
    </div>
  );
}

PrefabReplies.propTypes = {
  getPrefabReplies: PropTypes.func.isRequired,
  editPrefabReply: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  openConfirmModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  getPrefabReplies: () => dispatch(actions.getPrefabReplies()),
  addPrefabReply: () =>
    dispatch(
      openModalAction('edit-prefab-reply', {
        action: actions.addPrefabReply,
        actionType: ADD_PREFAB_REPLY,
      }),
    ),
  editPrefabReply: data =>
    dispatch(
      openModalAction('edit-prefab-reply', {
        action: actions.editPrefabReply,
        actionType: EDIT_PREFAB_REPLY,
        prefab: data,
      }),
    ),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
});

const mapStateToProps = state => ({
  filters: state.entities.advancedFilters.filters.items,
  prefabReplies: state.superUser.messages.prefabReplies,
});

export default connect(mapStateToProps, mapDispatchToProps)(PrefabReplies);
