import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Strings from '../../Strings';
import { isDevelopment } from '../../utils/environment';
import { returnOrCall } from '../../utils';
import { isMobile } from 'react-device-detect';

export class PageHeader extends PureComponent {
  static propTypes = {
    onBackClick: PropTypes.func,
    title: PropTypes.object,
    getHeaderComponents: PropTypes.func,
    centered: PropTypes.bool,
    left: PropTypes.any,
    right: PropTypes.any,
    growRight: PropTypes.bool,
    isBlack: PropTypes.bool,
    noLeftPadding: PropTypes.bool,
  };

  intervalRefresh = null;
  refPageHeader = React.createRef();
  refBackBtn = React.createRef();

  componentDidMount() {
    const { noLeftPadding = false } = this.props;
    // this.refBackBtn && this.refBackBtn.current?.focus();
    if (noLeftPadding) {
      this.intervalRefresh = setInterval(this.intervalRefreshHandler, 1000);
      this.intervalRefreshHandler();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalRefresh);
  }

  // Checking for scrollbars on windows browser to align right part of header
  intervalRefreshHandler = () => {
    const elContent = document.querySelectorAll('.app-content')[0];
    if (!elContent) return;

    let delta = elContent.offsetWidth - elContent.clientWidth;

    if (delta < 0) delta = 0;

    if (this.refPageHeader.current) {
      this.refPageHeader.current.style.paddingRight = `${delta}px`;
    }
  };

  render() {
    const {
      centered,
      left,
      right,
      onBackClick,
      title,
      getHeaderComponents,
      growRight = false,
      isBlack = true,
      noLeftPadding = false,
    } = this.props;

    if (isDevelopment()) {
      if (title) {
        console.warn('PageHeader.title is deprecated'); // eslint-disable-line no-console
      }
      if (getHeaderComponents) {
        console.warn('PageHeader.getHeaderComponents is deprecated'); // eslint-disable-line no-console
      }
    }

    return (
      <div
        ref={this.refPageHeader}
        className={classNames(
          'page-header',
          { centered },
          growRight ? 'grow-right' : '',
          isBlack ? 'black' : '',
          noLeftPadding ? 'no-left-padding' : '',
        )}
      >
        <div className="left" data-testid="page-header-left">
          {onBackClick && (
            <button className="back" onClick={onBackClick} tabIndex={0} aria-label="Navigates to the previous screen">
              {isMobile ? '' : Strings.back}
            </button>
          )}
          {left ? returnOrCall(left) : title?.substring(0, 100)}
        </div>
        {(right || getHeaderComponents) && (
          <div className="right" data-testid="page-header-right">
            {right ? returnOrCall(right) : getHeaderComponents()}
          </div>
        )}
      </div>
    );
  }
}
