import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';

import { capsAction } from '../../actions/caps';
import { resetForm } from '../../actions/forms';
import { closeModal } from '../../actions/modal';
import LoadingRenderer from '../../components/LoadingRenderer';
import Select from '../../components/Select';
import { SelectedOptions } from '../../components/Select/Multiselect';
import { Modal } from '../../containers';
import Form from '../../containers/Form';
import { actions } from '../../pages/SuperUser/Organization/redux/actions';
import {
  ASSIGN_CAPS_TO_THE_ORGANIZATION_ERROR,
  ASSIGN_CAPS_TO_THE_ORGANIZATION_RESULT,
} from '../../pages/SuperUser/Organization/redux/constants';
import Strings from '../../Strings';
import './../editStudyModal.scss';

class AttachCapToOrgnization extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      errors: [],
      selectedOrg: '',
      selectedOrgName: '',
      devices: [],
      review: false,
      unassignedDevices: [],
    };

    this.onTextChange = this.onTextChange.bind(this);
  }
  componentDidMount() {
    this.state.title = this.props.caption;
    if (!this.props.organizations) {
      this.props.getOrgs();
    }
    this.props.loadCaps({ type: this.props.data?.type }).then(resp => {
      const devices = resp.response.data.map(e => {
        return {
          ...e,
          value: e.device_id,
          label: `${e.device_id}${e.device_name ? ` (${e.device_name})` : ''}`,
        };
      });
      this.setState({
        unassignedDevices: devices.filter(d => !this.state.devices.some(dv => dv.device_id === d.device_id)),
      });
    });
  }

  onTextChange(event) {
    this.setState({ [event.target.getAttribute('name')]: event.target.value });
  }

  onBack = () => {
    this.setState({ title: this.props.caption });
    this.setState({ review: false });
  };

  onSave = () => {
    const { devices, selectedOrg } = this.state;

    const devicesData = devices.map(device => ({
      device_id: device.device_id,
      type: device.type,
    }));

    const data = {
      organization_id: selectedOrg,
      devices: devicesData,
    };

    this.props.assignCaps(data).then(response => {
      if (response?.type === ASSIGN_CAPS_TO_THE_ORGANIZATION_ERROR) {
        const validationErrors = [response?.response?.data?.error?.message];
        this.setState({ errors: validationErrors });
      }
    });
  };

  onReview = () => {
    this.setState({ errors: [] });
    this.setState({ title: Strings.devicesToAttach });
    let { devices } = this.state;
    const { selectedOrg } = this.state;

    devices = devices.filter(function(e) {
      return e.device_id != '';
    });

    const errorDevices = devices.filter(function(e) {
      return e.error;
    });

    const validationErrors = [];

    if (!selectedOrg.length) {
      validationErrors.push(Strings.errors.emptyOrgId);
    }

    if (!devices.length) {
      validationErrors.push(Strings.errors.emptyCapId);
    }

    if (validationErrors.length || errorDevices.length) {
      this.setState({ errors: validationErrors });
    } else {
      this.setState({ review: true });
    }
  };

  onOrgChange = option => {
    this.setState({ selectedOrg: option.value });
    this.setState({ selectedOrgName: option.label });
    this.setState({ errors: [] });
  };

  clickOnDelete = record => {
    this.setState({ devices: this.state.devices.filter(r => r !== record) });
  };

  onDeviceSelected = option => {
    const devices = [...this.state.devices];
    devices.push(option);

    if (option.value) {
      if (option.value.length <= 100) {
        devices[devices.length - 1].error = false;
      } else {
        devices[devices.length - 1].error = true;
      }
    }

    this.setState({ devices });

    this.forceUpdate();
  };

  scrollToMyRef = () => {
    const _this = this;
    window.requestAnimationFrame(function() {
      const scroll = _this.listContainer.current.scrollHeight - _this.listContainer.current.clientHeight;
      _this.listContainer.current.scrollTo(0, scroll);
    });
  };

  render() {
    const { onOpen, onCancel } = this.props;

    const { errors, selectedOrg, title, selectedOrgName } = this.state;

    const { organizations } = this.props;

    const data = [
      {
        value: '',
        label: Strings.patientDashboard.selectOrg,
      },
    ];

    const { devices, review } = this.state;
    if (organizations) {
      Object.values(organizations).forEach(organization => {
        data.push({
          value: organization.masked_id,
          label: organization.name,
        });
      });
    }

    const modalHeader = {
      caption: title ? title : Strings.attachDevice,
      name: this.props.name,
    };
    const errorsStr = errors.map(e => <div style={{ margin: 0 }}>{e}</div>);
    return (
      <Modal
        name="attach-cap-to-org"
        onOpen={onOpen}
        additionalClasses={['form-modal', 'user-invite']}
        {...modalHeader}
      >
        <LoadingRenderer>
          {!review ? (
            <Form onCancel={onCancel} id="attach-cap-to-org" className="add-medicine">
              <div key="admin-email" className="row select-org">
                <label>{Strings.addOrganization}</label>
                <Select
                  name="organization"
                  value={selectedOrg}
                  onChange={this.onOrgChange}
                  placeholder={Strings.selectOrganization}
                  data={data}
                  isSearchable
                  searchByLabelOnly
                />

                {data.length === 1 && (
                  <div className="spinner-container">
                    <div className="spinner" />
                  </div>
                )}
              </div>

              <div>
                <Select
                  key={`devices-select-${devices.length}`}
                  data={this.state.unassignedDevices}
                  placeholder={Strings.select}
                  onChange={this.onDeviceSelected}
                  isSearchable
                />
              </div>
              <SimpleBar
                className="devices-list-container"
                ref={el => {
                  this.listContainer = el;
                }}
              >
                <SelectedOptions items={devices} onRemove={this.clickOnDelete} itemsPerRow={1} />
              </SimpleBar>
              <div key="errors-block" className="errors-block">
                {errorsStr}
              </div>
              <div className="button-bar  reverse" key="button-bar" style={{ marginTop: '70px' }}>
                <button className="brand-blue" key="submit" type="button" onClick={this.onReview}>
                  {Strings.review}
                </button>
                <button className="brand-white-gray mr-15" key="cancel" type="cancel">
                  {Strings.cancel}
                </button>
              </div>
            </Form>
          ) : (
            <React.Fragment>
              <label className="header-label">
                {Strings.organization} : {selectedOrgName}
              </label>
              <label className="header-label">{Strings.deviceId}</label>
              {devices.map(device => {
                return <label className="mb-20">{device.device_id} </label>;
              })}
              <div key="errors-block" className="errors-block">
                {errorsStr}
              </div>
              <div className="button-bar  reverse" key="button-bar" style={{ marginTop: '70px' }}>
                <button className="brand-blue" key="back" type="button" onClick={this.onSave}>
                  {Strings.save}
                </button>
                <button className="white gray-text mr-15" key="save" type="button" onClick={this.onBack}>
                  {Strings.back}
                </button>
              </div>
            </React.Fragment>
          )}
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  AttachCaptoAdminModalLoading: state.entities.caps.loading,
  organizations: state.superUser?.organizations?.organizationAll,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('attach-cap-to-org')),
  getOrgs: request => dispatch(actions.getOrgs(request)),
  assignCaps: data =>
    dispatch(actions.assignCaps(data)).then(response => {
      if (response.type === ASSIGN_CAPS_TO_THE_ORGANIZATION_RESULT) {
        if (ownProps.data.postAction) {
          ownProps.data.postAction();
        }
        dispatch(closeModal('attach-cap-to-org'));
      }
    }),
  loadCaps: pageRequest => dispatch(actions.getDevices(pageRequest)),
  onOpen: () => dispatch(resetForm('attach-cap-to-org', ownProps.data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachCapToOrgnization);
