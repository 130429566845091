import { GET_TOTAL_TRIAL_ADHERENCE } from 'actions/action-types';
import { PAGE_LIMIT } from '../constants';
import { API_CALL } from '../middleware/api';
import { ESI_PATIENTS_TIMESTAMP_CLEAR } from '../pages/ESI/Patients/redux/constants';
import { OverViewSchema, Trial } from '../schemas';
import {
  DELETE_TRIAL,
  GET_TRIAL,
  GET_TRIAL_ADHERENCE,
  LIST_TRIALS,
  NEW_TRIAL,
  TRIAL_DELETE_PATIENT,
  TRIAL_INFO,
  TRIAL_NEW_PATIENT,
  TRIAL_OVERVIEW,
  TRIAL_TIMESTAMP,
  TRIAL_TIMESTAMP_RELOAD,
  UPDATE_TRIAL,
} from './action-types';

const qs = require('qs');

export const trialsAction = {
  actionList: (pageRequest, isLoading) => ({
    [API_CALL]: {
      endpoint: '/portal/trials',
      method: 'post',
      data: qs.stringify({ ...pageRequest, limit: PAGE_LIMIT }),
    },
    isLoading,
    type: LIST_TRIALS,
  }),

  actionGetInfo: trialId => ({
    [API_CALL]: {
      endpoint: `portal/trials/${trialId}/info`,
    },
    type: TRIAL_INFO,
    trialId,
  }),

  actionGet: ({ id, start, end }) => ({
    [API_CALL]: {
      endpoint: `/groups/trials/${id}/`,
      params:
        start && end
          ? {
              analytics: true,
              start,
              end,
            }
          : {},
      schema: Trial,
    },
    type: GET_TRIAL,
    id,
  }),
  actionNew: ({ name, description, startDate, endDate }) => ({
    [API_CALL]: {
      endpoint: '/groups/trials/',
      schema: Trial,
      method: 'post',
      data: {
        name,
        description,
        startDate,
        endDate,
      },
    },
    type: NEW_TRIAL,
  }),
  actionUpdate: (trialId, { name, description, startDate, endDate }) => ({
    [API_CALL]: {
      endpoint: `/groups/trials/${trialId}/`,
      schema: Trial,
      method: 'put',
      data: {
        name,
        description,
        startDate,
        endDate,
      },
    },
    type: UPDATE_TRIAL,
  }),

  actionDelete: trialId => ({
    [API_CALL]: {
      endpoint: `/groups/trials/${trialId}/`,
      method: 'delete',
    },
    type: DELETE_TRIAL,
    trialId,
  }),

  actionNewPatient: (trialId, patient, callAfterSuccess = []) => ({
    [API_CALL]: {
      endpoint: `/groups/trials/${trialId}/patients/`,
      method: 'post',
      data: {
        patient,
      },
      callAfterSuccess,
    },
    type: TRIAL_NEW_PATIENT,
  }),
  actionDeletePatient: (trialId, patientId, callAfterSuccess = []) => ({
    [API_CALL]: {
      endpoint: `/groups/trials/${trialId}/patients/delete`,
      method: 'post',
      data: {
        patient: patientId,
      },
      callAfterSuccess,
    },
    type: TRIAL_DELETE_PATIENT,
    patientId,
  }),
  adherence: (pageRequest, id) => ({
    [API_CALL]: {
      endpoint: `/portal/trials/${id}/adherence`,
      params: { ...pageRequest },
    },
    type: GET_TRIAL_ADHERENCE,
  }),

  adherenceTotal: id => ({
    [API_CALL]: {
      endpoint: `/portal/trials/${id}/adherence/total`,
    },
    type: GET_TOTAL_TRIAL_ADHERENCE,
  }),

  actionOverView: pageRequest => ({
    [API_CALL]: {
      endpoint: `/portal/overview`,
      data: null,
      params: { ...pageRequest },
      schema: OverViewSchema,
    },
    type: TRIAL_OVERVIEW,
  }),

  actionTimestamp: pageRequest => ({
    [API_CALL]: {
      endpoint: `/portal/timestamps`,
      params: { ...pageRequest },
    },
    type: TRIAL_TIMESTAMP,
  }),
  clearTimestamp: () => ({
    type: ESI_PATIENTS_TIMESTAMP_CLEAR,
  }),
  reloadTimeStampGraph: () => ({
    type: TRIAL_TIMESTAMP_RELOAD,
  }),
};
