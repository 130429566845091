import { CLEAR_ENTITIES } from '../actions/action-types';

const patientDeviceReducer = (state = { patientDevice: {} }, action) => {
  switch (action.type) {
    case CLEAR_ENTITIES:
      if (action.entities && action.entities.indexOf('patientDevice') !== -1) {
        return {
          ...state,
          patientDevice: {},
          preparing: true,
          loading: true,
        };
      }
      return state;
    default:
      return state;
  }
};

export default patientDeviceReducer;
