import React from 'react';

import { CardWrapper } from '../../components/CardWrapper/CardWrapper';
import Strings from '../../Strings';
import styles from './support.module.scss';

function Support() {
  return (
    <CardWrapper title={Strings.supportHeader}>
      <React.Fragment>
        <div className={styles.title}>{Strings.supportSubHeader}</div>
        <div className={styles.text}>
          {Strings.supportText}
          <a href={`mailto:${Strings.supportEmail}?subject=${Strings.emailSubject}`}>{Strings.supportEmail}</a>
        </div>
        <a href={`mailto:${Strings.supportEmail}?subject=${Strings.emailSubject}`} className={styles.button}>
          {Strings.supportButton}
        </a>
      </React.Fragment>
    </CardWrapper>
  );
}

export default Support;
