import { TZ_UPDATE_USER_TIMEZONE } from './const';
import { API_CALL } from '../../../middleware/api';

export const actionsTZ = {
  updateUserTimezone: timezone => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: '/rails/api/users/update_my_timezone',
      data: {
        timezone,
      },
    },
    type: TZ_UPDATE_USER_TIMEZONE,
  }),
};
