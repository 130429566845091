import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import { push } from 'react-router-redux';

import { actions } from './redux/actions';
import { actions as capsActions } from '../ShipperCaps/redux/actions';
import { GET_DEVICES, GET_DEVICES_RESULT } from './redux/constants';
import {
  CHANGE_DEVICE_STATUS_HISTORY,
  DOWNLOAD_REPORT_DATA_ERROR,
  ORGANIZATION_LIST_RESULT,
} from '../ShipperCaps/redux/constants';
import { PageHeader } from '../../components/PageHeader';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../components/PageHeader/Button';
import Table, { Column } from '../../containers/Table/TableWithPagination';
import { TextInput } from '../../components/PageHeader/TextInput';
import Strings from '../../Strings';
import {
  getCapChargeText,
  toolTipForTable,
  getVitalsData,
  downloadFileOnly,
  convertUnixEpochToHumanReadable,
} from '../../utils';
import { PAGE_LIMIT } from '../../constants';
import Select from '../../components/Select';
import AdvancedFilters, { Filter } from '../../components/AdvancedFilters/AdvancedFilters';
import AdvancedFiltersBar from '../../components/AdvancedFilters/AdvancedFiltersBar';
import { getFiltersForRequest, urlInjected } from '../../components/AdvancedFilters/helpers';
import { getFiltersDefinition } from '../../components/AdvancedFilters/FiltersDefinition';
import { isCapManager, isDoctor, isOrganizationManager, isShipper, isSuperUser } from '../../utils/userRoles';
import { openModalAction } from '../../actions/modal';
import { notificationActions } from '../../components/Notification/redux/actions';
import { ActionWidget, ActionButton, ItemSelectorHelper } from '../../components/ActionWidget';
import { DateFilters } from '../../utils/DateFilters';
import { getUrlForDevice } from '../SuperUser/DeviceManagement/HubDeviceEvents';
import { DeviceTypes } from '../../utils/deviceTypes';

const mapStateToPropsDeviceView = state => {
  return {
    devices: state.shipper.devices.data,
    pagination: state.shipper.devices.pagination,
    filters: state.entities.advancedFilters.filters.items,
  };
};

const mapDispatchToPropsDeviceView = dispatch => ({
  onGetDevices: pageRequest => dispatch(actions.getDevices(pageRequest)),
  onNavigate: path => dispatch(push(path)),
  onAttachDevice: (type, postAction) =>
    dispatch(
      openModalAction('attach-cap-to-org', {
        action: actions.getDevices,
        actionType: GET_DEVICES,
        type,
        postAction,
      }),
    ),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  downloadEvents: params => dispatch(capsActions.downloadReport(params)),
  downloadDetails: params => dispatch(capsActions.downloadDetails(params)),
  openDateRangeSelectionModal: (actionOnDone, downloadRequest, orgName) =>
    dispatch(
      openModalAction('date-range-selection-for-events-reports-modal', {
        actionOnDone,
        simplified: true,
        filters: downloadRequest,
        orgName,
      }),
    ),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  getOrgs: request => dispatch(capsActions.getOrgs(request)),
  changeDeviceStatus: data => dispatch(openModalAction('change-device-status', data)),
});

const all = 'all';
const no = 'no_org';

class DeviceView extends React.PureComponent {
  state = {
    isLoading: true,
    openActionWidget: false,
    keyForItemCheckboxes: false,
    organizations: [],
    selectedOrg: all,
  };

  request = {
    deviceType: this.props.deviceClass,
    limit: PAGE_LIMIT,
    offset: 0,
    search: '',
    filters: [],
  };
  refreshEnable = true;

  itemSelectorHelper = new ItemSelectorHelper();

  componentDidMount() {
    if (this.props.filtersDisabled) this.getDevices();
    this.props.getOrgs().then(resp => {
      if (resp?.type === ORGANIZATION_LIST_RESULT) {
        this.setState({ organizations: resp.response?.data });
      }
    });
    if (!urlInjected(window.location.search)) {
      this.getDevices();
    }
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    if (filters !== prevProps.filters) {
      this.onFiltersChange(filters);
    }
  }

  onFiltersChange = filters => {
    return this.filtersDebounced(filters);
  };

  filtersDebounced = _.debounce(() => {
    const newFiltersForRequest = getFiltersForRequest(this.props.filters);
    if (JSON.stringify(newFiltersForRequest) !== JSON.stringify(this.request.filters)) {
      this.request.offset = 0;
      this.request.filters = newFiltersForRequest;
    }
    this.getDevices();
  }, 1000);

  onDateRangeChanged = dateRange => {
    if (dateRange) {
      this.request.lastUpdatedStart = dateRange.dates.startDate();
      this.request.lastUpdatedEnd = dateRange.dates.endDate();
    } else {
      this.request.lastUpdatedStart = '';
      this.request.lastUpdatedEnd = '';
    }
    this.request.offset = 0;

    this.getDevices();
  };

  getDevices = () => {
    this.setState({ isLoading: true });

    const { selectedOrg } = this.state;
    if (selectedOrg !== all) {
      this.request.organizationId = this.state.selectedOrg;
    } else {
      delete this.request.organizationId;
    }

    this.props.onGetDevices(this.request).then(resp => {
      this.resetActionWidget();
      if (resp.type == GET_DEVICES_RESULT) this.setState({ isLoading: false });
    });
  };

  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  onSearchQueryChange = query => {
    return this.onSearchQueryChangeDebounced(query);
  };

  onSearchQueryChangeDebounced = _.debounce(query => {
    this.request.search = query;
    this.request.offset = 0;
    this.getDevices();
  }, 1000);

  onOffsetChange = offset => {
    this.request.offset = offset;
    this.getDevices();
  };

  onRowSelection = id => {
    const device = this.props.devices[id];
    const url = getUrlForDevice(device, this.props.deviceClass);
    this.props.onNavigate(`${url}?lastActivity=${device.lastActivity}`);
  };

  onOrgChange = option => {
    let value = option.value;
    this.request.offset = 0;
    this.setState({ selectedOrg: value }, () => {
      this.getDevices();
    });
  };

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 10000);
    this.getDevices();
  };

  onDownload = () => {
    const downloadHandler = params => {
      const paramsWithType = params;
      paramsWithType.deviceType = this.props.deviceClass;

      switch (params.selectedOption) {
        case 'events':
          delete paramsWithType.selectedOption;
          this.props.downloadEvents(params).then(this.onDownloadBtnClickSuccessHandler);
          break;
        case 'details':
          delete paramsWithType.selectedOption;
          paramsWithType.limit = 100000;
          paramsWithType.offset = 0;
          this.props.downloadDetails(params).then(this.onDownloadBtnClickSuccessHandler);
          break;
        case 'vitals':
          delete paramsWithType.selectedOption;
          paramsWithType.simplified = true;
          this.props.downloadEvents(params).then(this.onDownloadBtnClickSuccessHandler);
          break;
        default:
          this.props.downloadEvents(params).then(this.onDownloadBtnClickSuccessHandler);
      }
      this.props.showNotification('Download request sent, please wait...');
    };
    const downloadRequest = { ...this.request };
    delete downloadRequest.offset;
    delete downloadRequest.limit;
    if (!downloadRequest.lastUpdatedStart) {
      downloadRequest.lastUpdatedStart = DateFilters.AllTime.dates.startDate();
    }
    if (!downloadRequest.lastUpdatedEnd) {
      downloadRequest.lastUpdatedEnd = DateFilters.AllTime.dates.endDate();
    }

    let orgName = '';
    if (downloadRequest.organizationId) {
      orgName = this.state.organizations?.find(o => o.masked_id === downloadRequest.organizationId)?.name;
      if (downloadRequest.organizationId === no) {
        orgName = Strings.patientDashboard.noOrg;
      }
    }
    this.props.openDateRangeSelectionModal(downloadHandler, downloadRequest, orgName);
  };

  onDownloadBtnClickSuccessHandler = req => {
    if (req?.type === DOWNLOAD_REPORT_DATA_ERROR) {
      this.props.showNotification(req?.error);
    } else {
      const receivedName = req.headers['content-disposition'].split('filename=')[1];
      const fileName = `${moment().toString() + receivedName}`;
      downloadFileOnly(req, fileName);
    }
  };

  getHeaderComponents() {
    const { selectedOrg, organizations } = this.state;
    const hasOrgSelect = isShipper() || isSuperUser();
    const orgData = [
      {
        value: all,
        label: Strings.patientDashboard.all,
      },
      {
        value: no,
        label: Strings.patientDashboard.noOrg,
      },
    ];

    if (organizations) {
      Object.values(organizations).forEach(organization => {
        orgData.push({
          value: organization.masked_id,
          label: organization.name,
        });
      });
    }

    return (
      <React.Fragment>
        <Button class="refreshTop" disabled={!this.refreshEnable} onClick={this.onRefresh} />
        <TextInput className="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        {hasOrgSelect && <Select data={orgData} value={selectedOrg} onChange={this.onOrgChange} isSearchable />}
        <Select
          data={[
            DateFilters.AllTime,
            DateFilters.Last48Hours,
            DateFilters.ThisMonth,
            DateFilters.Last30Days,
            DateFilters.LastMonth,
            DateFilters.Last6Months,
            DateFilters.ThisYear,
            DateFilters.Last12Months,
          ]}
          onChange={this.onDateRangeChanged}
          defaultValue={DateFilters.AllTime.value}
        />
        {(isSuperUser() || isShipper()) && (
          <Button
            class={HEADER_BUTTON_DARK_BLUE}
            onClick={() => this.props.onAttachDevice(this.props.deviceClass, this.onRefresh)}
            title={Strings.attachCap}
          />
        )}
        {this.props.deviceClass !== DeviceTypes.Spirometer.id &&
          this.props.deviceClass !== DeviceTypes['Hailie Inhaler'].id && (
            <AdvancedFilters>
              <Filter definition={getFiltersDefinition().Battery} />
            </AdvancedFilters>
          )}
        {(isCapManager() || isOrganizationManager() || isSuperUser() || isDoctor()) && (
          <Button class="download" onClick={this.onDownload} />
        )}
      </React.Fragment>
    );
  }

  resetActionWidget = () => {
    this.setState({
      keyForItemCheckboxes: !this.state.keyForItemCheckboxes,
      openActionWidget: false,
    });
    this.itemSelectorHelper.clearItems();
  };

  onUnassignFromOrg = (event, cap) => {
    event.preventDefault();
    event.stopPropagation();

    const request = {
      device_id: cap,
    };

    const data = {
      title: (
        <span>
          {Strings.deleteCapWarning} <b>{cap}</b> from organization <b>{cap.organization}</b>?
        </span>
      ),
      onConfirmAction: capsActions.deleteCaps(request),
      confirmPostAction: this.onRefresh,
      onCancelAction: null,
    };
    this.props.openConfirmModal(data);
  };

  onStatusChange = (event, cap) => {
    event.preventDefault();
    event.stopPropagation();

    const data = {
      deviceId: cap,
      action: capsActions.changeDeviceStatus,
      actionType: CHANGE_DEVICE_STATUS_HISTORY,
      onSuccess: this.onRefresh,
    };
    this.props.changeDeviceStatus(data);
  };

  itemChecked = ({ target }) => {
    const data = {
      deviceId: target.id,
      organization: target.dataset.org,
    };
    if (target.checked === true) {
      this.itemSelectorHelper.addItem(data.deviceId);
    } else {
      this.itemSelectorHelper.removeItem(data.deviceId);
    }

    if (this.itemSelectorHelper.getItems().length !== 0) {
      this.setState({ openActionWidget: true });
    } else {
      this.setState({ openActionWidget: false });
    }

    this.setState({ multiselectedItems: this.itemSelectorHelper.isMultiselect() });
  };

  render() {
    const { pagination, devices } = this.props;
    const { isLoading } = this.state;

    const headerTitle =
      !isLoading && pagination && devices
        ? Strings.formatString(
            Strings.showingXDevices,
            devices.length,
            pagination.totalRecords ? pagination.totalRecords : 0,
          )
        : Strings.showingWait;

    const columns = [];

    columns.push(
      <Column
        key="deviceId"
        title={Strings.deviceId}
        className="clickable"
        value={e => {
          const prefix = e.manufacturer.concat('.', e.model);
          let trueDeviceId = e.deviceId.replace(prefix, '');
          if (trueDeviceId.startsWith('.')) trueDeviceId = trueDeviceId.slice(1);
          if (isShipper() || isSuperUser()) {
            return (
              <React.Fragment>
                <div className="cell-with-select">
                  <div className="selector">
                    <input
                      type="checkbox"
                      className="item-checkbox"
                      id={e.deviceId}
                      data-org={e.organization}
                      onChange={this.itemChecked}
                      key={this.state.keyForItemCheckboxes}
                    />
                  </div>
                  <div className="selector-label">{trueDeviceId}</div>
                </div>
              </React.Fragment>
            );
          }
          return trueDeviceId;
        }}
      />,
    );

    if (this.props.deviceClass !== DeviceTypes.Spirometer.id) {
      columns.push(<Column key="deviceName" title={Strings.deviceName} value={e => (e.name ? e.name : '-')} />);
    }
    columns.push(
      <Column key="deviceModel" title={Strings.deviceModel} value={e => e.manufacturer.concat(' ', e.model)} />,
    );
    if (isShipper() || isSuperUser()) {
      columns.push(<Column key="organization" title={Strings.organizationName} value={e => e.organization} />);
    }
    if (
      this.props.deviceClass !== DeviceTypes.Spirometer.id &&
      this.props.deviceClass !== DeviceTypes['Hailie Inhaler'].id
    ) {
      columns.push(
        <Column
          key="hubs"
          title={Strings.devicesPlural.hub}
          value={e => (e.hubs?.length > 1 ? toolTipForTable(`${e.hubs[0]}...`, e.hubs.join(', ')) : e.hubs)}
        />,
      );
    }
    columns.push(<Column key="kitId" title={Strings.kitId} value={e => e.kitId} />);

    if (
      this.props.deviceClass !== DeviceTypes.Spirometer.id &&
      this.props.deviceClass !== DeviceTypes['Hailie Inhaler'].id
    ) {
      columns.push(
        <Column
          key="batteryPercentage"
          title={Strings.battery.battery}
          value={e => getCapChargeText(e.batteryPercentage)}
        />,
      );
    }

    columns.push(
      <Column
        key="lastActivity"
        title={Strings.lastActivity}
        value={e => (e.lastActivity ? convertUnixEpochToHumanReadable(e.lastActivity) : '-')}
      />,
    );

    columns.push(
      <Column
        key="Reading"
        title={Strings.lastReading}
        value={e => (!_.isEmpty(e.lastReading) ? getVitalsData(e.lastReading) : '-')}
      />,
    );

    return (
      <div>
        <PageHeader left={headerTitle} right={() => this.getHeaderComponents()} />
        <AdvancedFiltersBar />
        <Table
          name="devices"
          uuid={DeviceTypes[this.props.deviceClass].tableUuid}
          isLoading={isLoading}
          onRowClick={this.onRowSelection}
          onSortClick={this.onSortClick}
          onOffsetChange={this.onOffsetChange}
          pagination={
            pagination || {
              offset: 0,
              total: 0,
            }
          }
          data={devices || []}
          enableColumnFiltering
        >
          {columns}
        </Table>
        {(isShipper() || isSuperUser()) && (
          <ActionWidget show={this.state.openActionWidget}>
            <ActionButton
              img="action-delete"
              tooltiptext="Delete"
              text="Delete"
              action={e => {
                this.onUnassignFromOrg(e, this.itemSelectorHelper.getFirstItem());
              }}
              disabled={this.state.multiselectedItems}
            />
            <ActionButton
              img="action-reset"
              tooltiptext="Change State"
              text="Change State"
              action={e => {
                this.onStatusChange(e, this.itemSelectorHelper.getFirstItem());
              }}
            />
          </ActionWidget>
        )}
      </div>
    );
  }

  static propTypes = {
    deviceClass: PropTypes.string,
    onGetDevices: PropTypes.func,
    pagination: PropTypes.object,
    devices: PropTypes.array,
    isLoading: PropTypes.bool,
    onNavigate: PropTypes.func,
    filters: PropTypes.array,
    onAttachDevice: PropTypes.func,
    openConfirmModal: PropTypes.func,
    downloadEvents: PropTypes.func,
    downloadDetails: PropTypes.func,
    openDateRangeSelectionModal: PropTypes.func,
    showNotification: PropTypes.func,
    filtersDisabled: PropTypes.bool,
    getOrgs: PropTypes.func,
    changeDeviceStatus: PropTypes.func,
  };
}

export default connect(mapStateToPropsDeviceView, mapDispatchToPropsDeviceView)(DeviceView);
