import { API_CALL } from '../middleware/api';
import { PatientAnalytics } from '../schemas';

class APIAction {
  constructor(name, base = '/data/', actions) {
    this.endpoints = {
      list: `${base + name}/search`,
      get: `${base + name}/`,
      upload: base + name,
      remove: `${base + name}/`,
    };
    this.name = name;
    this.types = APIAction.createTypes(actions || ['list', 'get', 'upload', 'remove'], name);
    this.get = this.get.bind(this);
    this.list = this.list.bind(this);
  }

  static createTypes(actions, name) {
    return {
      send: [name, 'request'].join('-'),
      success: [name, 'success'].join('-'),
      fail: [name, 'fail'].join('-'),
      sort: [name, 'sort'].join('-'),
    };
  }

  list(token, filter) {
    return dispatch => {
      dispatch(this.actionList(token, filter));
    };
  }

  get(token, id) {
    return (dispatch, getState) => {
      const {
        assets: {
          [this.name]: {
            objects: {
              [id]: { detailFetched = false },
            },
          },
        },
      } = getState();
      if (!detailFetched) {
        dispatch(this.actionGet(token, id));
      }
    };
  }

  upload(data) {
    return dispatch => dispatch(this.actionUpload(data));
  }

  remove(id) {
    return dispatch => {
      dispatch(this.actionRemove(id));
    };
  }

  actionList(filter) {
    return {
      [API_CALL]: {
        endpoint: this.endpoints.list,
        types: this.types,
        params: { filter },
      },
      subType: 'list',
    };
  }

  actionGet(token, id) {
    return {
      [API_CALL]: {
        endpoint: this.endpoints.get,
        types: this.types,
        params: {
          token,
          id,
        },
      },
      subType: 'get',
    };
  }

  actionUpload(data) {
    return {
      [API_CALL]: {
        endpoint: this.endpoints.upload,
        types: this.types,
        method: 'post',
        data,
      },
      subType: 'upload',
    };
  }

  actionRemove(id) {
    return {
      [API_CALL]: {
        endpoint: this.endpoints.delete + id,
        types: this.types,
        method: 'delete',
      },
      subType: 'remove',
    };
  }
}

export const pageAction = new APIAction('page');

export const analyticsAction = {
  actionList: (ids, start, end) => ({
    [API_CALL]: {
      endpoint: '/groups/analytics/patients/',
      params: {
        ids: ids.length > 1 ? ids.join(',') : ids[0],
        start,
        end,
      },
      schema: PatientAnalytics,
    },
    type: 'get-patient-analytics',
  }),
};

export const userAction = new APIAction('user');

export const groupInvitationConfirm = ({ membership, response }) => ({
  [API_CALL]: {
    endpoint: `/groups/patient-memberships/${membership}/invitation-respond/`,
    method: 'post',
    data: { response },
  },
  type: 'group-invitation-respond',
});
