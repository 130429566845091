import _ from 'lodash';
import {
  CLEAR_DATA,
  CLEAR_MESSAGES,
  GET_INBOX_ARCHIVED,
  GET_INBOX_ARCHIVED_ERROR,
  GET_INBOX_ARCHIVED_RESULT,
  GET_INBOX_MESSAGES,
  GET_INBOX_MESSAGES_ERROR,
  GET_INBOX_MESSAGES_RESULT,
  GET_MESSAGE_DETAILS,
  GET_MESSAGE_DETAILS_ERROR,
  GET_MESSAGE_DETAILS_RESULT,
  GET_PREFAB_REPLIES,
  GET_PREFAB_REPLIES_RESULT,
} from './constants';

export const reducer = (state = { messageDetails: [], prefabReplies: [] }, action) => {
  switch (action.type) {
    case GET_INBOX_MESSAGES:
    case GET_INBOX_ARCHIVED:
      return {
        ...state,
        isLoading: true,
      };

    case GET_MESSAGE_DETAILS:
      return {
        ...state,
        isMessageDetailsLoading: true,
      };
    case GET_INBOX_MESSAGES_ERROR:
    case GET_INBOX_ARCHIVED_ERROR:
      return {
        ...state,
        isLoading: false,
        messages: [],
        messageDetails: [],
        messagePagination: {},
      };

    case GET_MESSAGE_DETAILS_ERROR:
      return {
        ...state,
        isMessageDetailsLoading: false,
      };
    case GET_INBOX_MESSAGES_RESULT:
      return {
        ...state,
        messages: action.response.data,
        pagination: action.response.pagination,
        isLoading: false,
      };
    case GET_INBOX_ARCHIVED_RESULT:
      return {
        ...state,
        messagesArchived: action.response.data,
        pagination: action.response.pagination,
        isLoading: false,
      };
    case GET_MESSAGE_DETAILS_RESULT: {
      let data = [...action.response.data, ...state.messageDetails];
      data = _.sortBy(data, [
        function(o) {
          return o.createdAt;
        },
      ]);
      return {
        ...state,
        messageDetails: _.uniqBy(data, function(e) {
          return [e.createdAt, e.message, e.sentBy].join();
        }),
        messagePagination: action.response.pagination,
        patientInfo: action.response.patientInfo,
        isMessageDetailsLoading: false,
      };
    }
    case CLEAR_MESSAGES: {
      return {
        ...state,
        messageDetails: [],
        pagination: {},
      };
    }
    case CLEAR_DATA:
      return {
        ...state,
        isLoading: false,
        messageDetails: [],
        messagesArchived: [],
        messages: [],
        pagination: {},
        prefabReplies: [],
      };

    case GET_PREFAB_REPLIES:
      return {
        ...state,
        prefabReplies: [],
      };
    case GET_PREFAB_REPLIES_RESULT:
      return {
        ...state,
        prefabReplies: action.response,
      };

    default:
      return state;
  }
};
