import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import cloneDeep from 'lodash/cloneDeep';

import { Box, TimeDeltaPlotComponent } from './base';
import Strings from '../../Strings';
import WithLoader from '../_hocs/withLoader';
import { diffDays } from '../../utils';

const medicationReducer = (schedules, timezone) => (ret, d) => {
  if (ret[d.scheduleId] === undefined) {
    // eslint-disable-next-line no-param-reassign
    ret[d.scheduleId] = {
      id: schedules[d.scheduleId].scheduleId,
      name: schedules[d.scheduleId].name,
      data: [],
    };
  }
  if (d.takenTime !== undefined) {
    const notificationAtDate = moment.tz(d.notificationTime, timezone);
    const actualTimeStampDate = moment.tz(Date.parse(d.takenTime), timezone);
    let timestampDate = moment.tz(Date.parse(d.takenTime), timezone);

    if (
      moment(notificationAtDate).date() > moment(timestampDate).date() ||
      diffDays(notificationAtDate, timestampDate) > 0 ||
      diffDays(notificationAtDate, timestampDate) < 0
    ) {
      timestampDate = moment(notificationAtDate).startOf('day');
    }

    const rawTimedelta = Math.abs(Math.floor((actualTimeStampDate.valueOf() - notificationAtDate.valueOf()) / 60000));
    const notificationActualAddTime = moment(notificationAtDate).add(60, 'minutes');
    const notificationActualSubtractTime = moment(notificationAtDate).subtract(60, 'minutes');
    ret[d.scheduleId].data.push({
      id: d.id,
      timedelta: `${rawTimedelta} min`,
      rawTimedelta,
      notification: {
        time: notificationAtDate.format('DD MMM, hh:mm a'),
        scheduleHour: notificationAtDate.format('hh:mm a'),
        y: notificationAtDate.hour() + notificationAtDate.minute() / 60,
        x: notificationAtDate.startOf('day').valueOf(),
      },
      expirtyTime: {
        time: notificationActualAddTime.format('hh:mm a'),
        y: notificationActualAddTime.hour() + notificationActualAddTime.minute() / 60,
        x: notificationAtDate.startOf('day').valueOf(),

        y1: notificationActualSubtractTime.hour() + notificationActualSubtractTime.minute() / 60,
        x1: notificationAtDate.startOf('day').valueOf(),
      },
      event: {
        time: timestampDate.format('hh:mm a'),
        y: timestampDate.hour() + timestampDate.minute() / 60,
        x: timestampDate.startOf('day').valueOf(),
      },
      actualEvent: { time: actualTimeStampDate.format('DD MMM, hh:mm a') },
    });
  }
  return ret;
};

const reduceData = (data, schedules, timezone) => {
  const dataSets = Object.values(data)
    .filter(d => d.status === 'taken' || d.status === 'missed')
    .filter(d => d.takenTime)
    .filter(d => d.name)
    .reduce(medicationReducer(schedules, timezone), {});
  return Object.values(dataSets);
};

class TimeDeltaPlot extends Component {
  static propTypes = {
    data: PropTypes.any,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    isLoading: PropTypes.bool,
    isPreparing: PropTypes.bool,
    patientInfo: PropTypes.object,
  };

  static yTickFormat(d) {
    if (d > 24 || d < 0) {
      return '';
    }
    return `${d}:00`;
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedSchedule: 0,
      selectedTime: 0,
    };
    this.onScheduleSelect = this.onScheduleSelect.bind(this);
    this.onScheduleTimeSelect = this.onScheduleTimeSelect.bind(this);
    this.checkContainsScheduleId = this.checkContainsScheduleId.bind(this);
  }

  onScheduleSelect(event) {
    const { value } = event.target;
    this.setState({ selectedSchedule: value });
  }

  onScheduleTimeSelect(event) {
    const { value } = event.target;
    this.setState({ selectedTime: +value });
  }

  /*eslint-disable */
  checkContainsScheduleId(schedules, scheduleId) {
    for (let i = 0; i < schedules.length; i += 1) {
      const notification = schedules[i];
      if (notification.scheduleId === scheduleId) {
        return true;
      }
    }
    return false;
  }
  /* eslint-enable */

  render() {
    const { selectedSchedule, selectedTime } = this.state;
    const { data, minDate, maxDate, isLoading, isPreparing, timezone } = this.props;

    const schedules = [];
    const schedulesData = {};

    if (data && data.length > 0) {
      /*eslint-disable */
      data.map(notification => {
        if (!this.checkContainsScheduleId(schedules, notification.scheduleId)) {
          const { scheduleId } = notification;
          schedules.push(notification);
          schedulesData[scheduleId] = notification;
        }
      });
      /* eslint-enable */
    }

    const filteredData = _.pickBy(data, item => {
      const not_at = new Date(Date.parse(item.notificationTime));
      const event_at = new Date(Date.parse(item.takenTime));
      // (item.complied && not_at <= maxDate && not_at >= minDate) || (event_at <= maxDate && event_at >= minDate)
      return (
        (item.status === 'taken' && not_at <= maxDate && not_at >= minDate) ||
        (event_at <= maxDate && event_at >= minDate)
      );
    });

    const plotData = reduceData(filteredData, schedulesData, timezone);
    const scheduleData = plotData[Math.min(selectedSchedule, plotData.length)] || {};

    let averageTimedelta;
    if (scheduleData.data) {
      const sumTimedelta = scheduleData.data.reduce((result, item) => result + item.rawTimedelta, 0);
      averageTimedelta = Math.round(sumTimedelta / scheduleData.data.length);
    }

    const filteredTime = cloneDeep(scheduleData.data);
    let filterTimeData = [];
    if (filteredTime) {
      filterTimeData = _.uniqBy(filteredTime, 'notification.scheduleHour');
      if (plotData && plotData[0].data.length > 0) {
        scheduleData.data = scheduleData.data.filter(
          item => item.notification.scheduleHour === filterTimeData[selectedTime].notification.scheduleHour,
        );
      }
    }

    if (isLoading) {
      return (
        <WithLoader isLoading={isLoading} isMounted={this.isLoading}>
          <div></div>
        </WithLoader>
      );
    }

    return (
      <Box
        isLoading={isPreparing}
        caption={
          <div>
            <div className="average-time-delta">
              {Strings.averageTimedelta}:{' '}
              {averageTimedelta || averageTimedelta === 0 ? `${averageTimedelta} min` : 'NA'}
            </div>

            {plotData.length > 0 ? (
              <div className="select-box-time-delta">
                <select onChange={this.onScheduleSelect} value={selectedSchedule}>
                  {plotData.map((item, itemIndex) => (
                    <option key={item.id} value={itemIndex}>
                      {_.truncate(item.name, { length: 150 })}
                    </option>
                  ))}
                </select>
                <div className="timeDelta-tool-tips">
                  <ReactTooltip
                    place="bottom"
                    type="light"
                    id={`id${selectedSchedule}`}
                    key={`id${selectedSchedule}`}
                    className="timestamp-date-value"
                    offset={{
                      bottom: 12,
                      left: 30,
                    }}
                  >
                    <div>
                      <div className="tooltip-day">
                        <div className="">{plotData[selectedSchedule].name}</div>
                      </div>
                    </div>
                  </ReactTooltip>
                </div>
              </div>
            ) : (
              <span>{Strings.noMedicationsTaken}</span>
            )}

            {filterTimeData.length > 0 ? (
              <div className="select-box-time-delta">
                <select onChange={this.onScheduleTimeSelect} value={selectedTime}>
                  {filterTimeData.map((item, itemIndex) => (
                    <option key={item.id} value={itemIndex}>
                      {item.notification.scheduleHour}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              ''
            )}
          </div>
        }
      >
        {Object.keys(scheduleData).length === 0 && !isPreparing ? (
          <div className="no-data-image-container">
            <div className="no-data-image">{Strings.noData}</div>
          </div>
        ) : (
          ''
        )}

        <TimeDeltaPlotComponent
          data={scheduleData}
          minDate={minDate}
          maxDate={maxDate}
          margin={{
            top: 27,
            bottom: 40,
            left: 50,
            right: 27,
          }}
          isLoading
          yTickFormat={TimeDeltaPlot.yTickFormat}
        />
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  timezone: state.auth?.profile?.preferences?.timezone,
});

export default connect(mapStateToProps, null)(TimeDeltaPlot);
