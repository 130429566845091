import { API_CALL } from '../middleware/api';
import { PatientMedication, PatientNotification, PatientSchedule } from '../schemas';
import {
  PATIENT_PORTAL_ADD_CAREGIVER,
  PATIENT_PORTAL_DELETE_CAREGIVER,
  PATIENT_PORTAL_DELETE_SCHEDULE,
  PATIENT_PORTAL_GET_MEDICATIONS,
  PATIENT_PORTAL_LIST_NOTIFICATIONS,
  PATIENT_PORTAL_LIST_SCHEDULES,
  PATIENT_PORTAL_SAVE_SCHEDULE,
  PATIENT_PORTAL_TAKE_MEDICATIONS,
  PATIENT_PORTAL_UPDATE_PATIENT_NOTIFICATIONS,
  PATIENT_PORTAL_UPDATE_PATIENT_SETTINGS,
  PATIENT_UPDATE_NOTIFICATIONS,
} from './action-types';

export const patientPortalActions = {
  getMedications: () => ({
    [API_CALL]: {
      endpoint: '/groups/patient/medications/',
      schema: [PatientMedication],
    },
    type: PATIENT_PORTAL_GET_MEDICATIONS,
  }),
  takeMedication: id => ({
    [API_CALL]: {
      endpoint: `/groups/patient/medications/${id}/take/`,
      method: 'post',
    },
    type: PATIENT_PORTAL_TAKE_MEDICATIONS,
  }),
  getSchedules: () => ({
    [API_CALL]: {
      endpoint: '/groups/patient/schedules/',
      params: {},
      schema: [PatientSchedule],
    },
    type: PATIENT_PORTAL_LIST_SCHEDULES,
  }),
  deleteSchedule: id => ({
    [API_CALL]: {
      endpoint: `/groups/patient/schedules/${id}/`,
      method: 'delete',
    },
    type: PATIENT_PORTAL_DELETE_SCHEDULE,
  }),
  saveSchedule: data => ({
    [API_CALL]: {
      endpoint: '/groups/patient/schedules/',
      method: 'post',
      data,
    },
    type: PATIENT_PORTAL_SAVE_SCHEDULE,
  }),
  getNotifications: (start, end) => ({
    [API_CALL]: {
      endpoint: '/groups/patient/notifications/',
      params:
        start && end
          ? {
              start,
              end,
            }
          : {},
      schema: [PatientNotification],
    },
    type: PATIENT_PORTAL_LIST_NOTIFICATIONS,
  }),
  updateNotificationStatus: (userId, model) => ({
    [API_CALL]: {
      endpoint: `/groups/patients/${userId}/notifications/`,
      method: 'put',
      data: model,
    },
    type: PATIENT_UPDATE_NOTIFICATIONS,
  }),
  actionUpdateProfile: data => ({
    [API_CALL]: {
      method: 'put',
      endpoint: '/groups/patient/settings/profile/',
      data,
    },
    type: PATIENT_PORTAL_UPDATE_PATIENT_SETTINGS,
  }),
  actionUpdateNotifications: data => ({
    [API_CALL]: {
      method: 'put',
      endpoint: '/groups/patient/settings/notifications/',
      data,
    },
    type: PATIENT_PORTAL_UPDATE_PATIENT_NOTIFICATIONS,
  }),
  addCaregiver: (patientId, data) => ({
    [API_CALL]: {
      endpoint: '/groups/patient/caregivers/',
      method: 'post',
      data,
    },
    type: PATIENT_PORTAL_ADD_CAREGIVER,
  }),
  deleteCaregiver: (patientId, data) => ({
    [API_CALL]: {
      endpoint: `/groups/patient/caregivers/${data.id}/`,
      method: 'delete',
    },
    type: PATIENT_PORTAL_DELETE_CAREGIVER,
  }),
};
