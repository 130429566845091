export const ESI_INVITE_PATIENT_GET_SCHEDULE_DETAILS = 'esi-invite-patient-get-schedule';
export const ESI_INVITE_PATIENT_GET_SCHEDULE_DETAILS_RESULT = `${ESI_INVITE_PATIENT_GET_SCHEDULE_DETAILS}/result`;
export const ESI_INVITE_PATIENT_GET_SCHEDULE_DETAILS_ERROR = `${ESI_INVITE_PATIENT_GET_SCHEDULE_DETAILS}/error`;

export const ESI_INVITE_PATIENT_GET_CAPS_TYPES = 'esi-invite-patient-get-caps';
export const ESI_INVITE_PATIENT_GET_CAPS_TYPES_RESULT = `${ESI_INVITE_PATIENT_GET_CAPS_TYPES}/result`;
export const ESI_INVITE_PATIENT_GET_CAPS_TYPES_ERROR = `${ESI_INVITE_PATIENT_GET_CAPS_TYPES}/error`;

export const ESI_INVITE_PATIENT = 'esi-invite-patient';

export const ESI_PATIENT_GET = 'esi-patient-get';
export const ESI_PATIENT_GET_RESULT = ESI_PATIENT_GET + '/result';

export const ESI_PATIENTS_EDIT = 'esi-patient-edit';
export const ESI_PATIENTS_EDIT_RESULT = ESI_PATIENTS_EDIT + '/result';
export const ESI_PATIENTS_EDIT_ERROR = ESI_PATIENTS_EDIT + '/error';

export const ESI_PATIENTS_EDIT_MEDICATION = 'esi-patient-edit-medication';
export const ESI_PATIENTS_EDIT_MEDICATION_RESULT = ESI_PATIENTS_EDIT_MEDICATION + '/result';
export const ESI_PATIENTS_EDIT_MEDICATION_ERROR = ESI_PATIENTS_EDIT_MEDICATION + '/error';

export const CLEAR_PATIENT = 'clear-patient';
