import React from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const TermsPage = () => (
  <OverlayScrollbarsComponent
    defer
    className="page-container"
    options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
  >
    <div className="page-inner-container">
      <h2 align="center">Website Terms</h2>

      <ul>
        <li className="list-style-decimal">
          <h2>ALL PERSONS ACCESSING AND/OR USING THIS SITE AGREE TO THE FOLLOWING TERMS AND CONDITIONS</h2>
          <ol>
            <li>
              <p>
                <strong>Ownership and Usage Restrictions</strong>. All rights, including copyrights, to this Site and
                its contents are owned by RxCap Inc. and its affiliates (hereinafter referred to as “
                <strong>RxCap</strong>”) or third parties who have licensed such rights to RxCap. The contents of this
                Site may not be copied, republished, distributed, altered or used for any purpose except as explicitly
                set forth in this Site or approved in writing by RxCap.
              </p>
            </li>
            <li>
              <p>
                The RX Cap platform, Smart Caps, and mobile apps allow a complete and end-to-end solution for patient
                adherence management <strong>(the “Service”).</strong> The RxCap website (the “<strong>Site</strong>")
                is comprised of various web pages operated by us. The Site is offered to you conditioned on your
                acceptance of our Privacy Policy.
              </p>
            </li>
            <li>
              <p>
                <strong>Informational Purposes Only.</strong> The materials available on this Site have been prepared by
                RxCap and are intended for informational purposes ONLY. &nbsp;The information provided on this Site is
                provided only as general information and does not create a business or professional services
                relationship between you and RxCap. &nbsp;
              </p>
            </li>
            <li>
              <p>
                <strong>Submitted Information</strong>. The RxCap Site is free to use and exploit in any legal manner
                and for any legal purpose any information sent to this Site by any person accessing and/or using this
                Site. Please refer to the Privacy Policy section of this Site for further details on the collection and
                use of Site user information.
              </p>
            </li>
          </ol>
        </li>
      </ul>
      <ol start="2">
        <li>
          <h2>THIRD PARTY LINKS</h2>
          <ol>
            <li>
              <p>
                We may include links to related internet sites maintained by third parties. &nbsp;Neither RxCap nor its
                affiliates or subsidiaries operate or control, in any respect, any information, products or services on
                such linked sites. &nbsp;In addition, RxCap does not guarantee the timeliness, sequence, accuracy,
                completeness, reliability, or content of such information.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>CONDITIONS OF USE</h2>
          <ol>
            <li>
              <p>
                You agree that it shall only use the Site for legal purposes. You are solely responsible for complying
                with the laws of the jurisdiction from which you are accessing this Site and you agree that you will not
                access or use the information on this Site in violation of such law. &nbsp;You represent that you have
                the lawful right to submit such information and you agree that you will not submit any information
                through the use of this Site unless you are legally entitled to do so. &nbsp;Again, we recommend that
                you do not submit information you consider confidential. &nbsp;Without limiting the foregoing and by way
                of example only, you may not and shall NOT:
              </p>
              <p>
                a. engage in any conduct that is unlawful, immoral, threatening, abusive or in a way that is deemed
                unreasonable by RxCap in its discretion.
              </p>
              <p>
                b. infringe our intellectual property rights or those of any third party in relation to your use of the
                Service;
              </p>
              <p>c. transmit any material that is confidential or proprietary;</p>
              <p>
                d. use the Service in a way that could damage, disable, overburden, impair or compromise our systems or
                security or interfere with other users;
              </p>
              <p>
                e. collect or harvest any information or data from the Service or attempt to decipher any transmissions
                to or from the servers running any Service;
              </p>
              <p>
                f. access the Service in order to build a similar or competitive product or service or copy any ideas,
                features, functions, or graphics of the Service;
              </p>
              <p>g. use the Service in any manner that may harm minors or that interacts with or targets</p>
              <p>h. send unsolicited communications, promotions or advertisements, or spam;</p>
              <p>
                i. send altered, deceptive or false source-identifying information, including “spoofing” or “phishing”;
              </p>
              <p>j. sublicense, resell, time share or similarly exploit the Services;</p>
              <p>k. authorize, permit, enable, induce or encourage any third party to do any of the above.</p>
            </li>
            <li>
              <p>
                You agree that you will not engage in any activity that interferes with or disrupts the Site. You
                further agree that you will not crawl, scrape, reproduce, duplicate, copy, sell, trade or resell any
                part of the Site for any purpose.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>INTELLECTUAL PROPERTY</h2>
          <ol>
            <li>
              <p>
                User acknowledges that RxCap retains ownership of all Intellectual Property of RxCap incorporated in the
                Site and Service (including all improvements, enhancements, updates and corrections) and any
                Intellectual Property generated by RxCap in the process of providing the Service.
              </p>
            </li>
            <li>
              <p>
                The User agrees and accepts that any Intellectual Property generated by the user in connection with the
                user’s use of the Site or Service is owned absolutely by RxCap and vests in RxCap immediately,
                including:
              </p>
              <p>a. RxCap name, trade marks, logo and design; and</p>
              <p>
                b. any text, images, graphics, source code, usage data, ideas, enhancements, feature requests,
                suggestions or other information provided by the User or any other party with respect to the Service.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>ADVERTISING</h2>
          <ol>
            <li>
              <p>
                Some of our Services may be supported by advertising revenue and may display advertisements and
                promotions. You agree that we may place such advertising and promotions on the Site or Service, or on,
                about, or in conjunction with any content provided by the User. The manner, mode, and extent of such
                advertising and promotions are subject to change without specific notice to you.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>USE OUTSIDE THE UNITED STATES</h2>
          <ol>
            <li>
              <p>
                RxCap makes no representation or warranty that the content of RxCap is appropriate, lawful, or available
                for use in countries other than the United States. If you use RxCap, including without limitation, the
                Site, you are responsible for compliance with all applicable laws.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>DISCLAIMER</h2>
          <ol>
            <li>
              <p>
                All content and information on this Site is subject to change without notice. This Site may contain
                links to other websites. RxCap makes no representation or warranty and disclaims any responsibility or
                liability with respect to such third-party websites and their content.
              </p>
            </li>
            <li>
              <p>
                ALL CONTENT AND INFORMATION ON THIS SITE IS PROVIDED “AS IS,” WITH NO WARRANTIES WHATSOEVER, EITHER
                EXPRESS OR IMPLIED. RXCAP AND ITS AFFILIATES, AND ITS AND THEIR THIRD-PARTY LICENSORS, EXPRESSLY
                DISCLAIM TO THE FULLEST EXTENT PERMITTED BY LAW ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR
                OTHERWISE, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS. RXCAP AND ITS AFFILIATES, AND ITS AND THEIR
                THIRD-PARTY LICENSORS, DISCLAIM ANY WARRANTIES REGARDING THE SECURITY, RELIABILITY, TIMELINESS, AND
                PERFORMANCE OF THE SERVICE, AND ALL MATERIALS, INFORMATION, ADVICE, JOB LISTINGS, USER CONTENT, PRODUCTS
                AND SERVICES AVAILABLE ON OR THROUGH THE SERVICE. RXCAP AND ITS AFFILIATES, AND ITS AND THEIR
                THIRD-PARTY LICENSORS, DISCLAIM ANY WARRANTIES FOR SERVICES OR GOODS RECEIVED THROUGH OR ADVERTISED ON
                THE SITE OR SERVICE OR RECEIVED THROUGH ANY LINKS MADE AVAILABLE BY RXCAP.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h2>INFRINGEMENT CLAIMS</h2>
          <ol>
            <li>
              <p>
                U.S. Copyright Infringement. The Digital Millennium Copyright Act of 1998 (the “DMCA“) provides recourse
                for copyright owners who believe that material appearing on the Internet infringes their rights under
                U.S. copyright law. If you believe in good faith that materials hosted by this Site infringe your
                copyright, you (or your agent) may send us a notice requesting that the material be removed, or access
                to it blocked. The notice must include the following information as required by 17 USC. § 512(c)(3)(A):
                (a) a physical or electronic signature of a person authorized to act on behalf of the owner of an
                exclusive right that is allegedly infringed; (b) identification of the copyrighted work claimed to have
                been infringed (or if multiple copyrighted works located on the Site or Service are covered by a single
                notification, a representative list of such works); (c) identification of the material that is claimed
                to be infringing or the subject of infringing activity, and information reasonably sufficient to allow
                RxCap to locate the material on the Site; (d) the name, address, telephone number, and email address (if
                available) of the complaining party; (e) a statement that the complaining party has a good faith belief
                that use of the material in the manner complained of is not authorized by the copyright owner, its
                agent, or the law; and (f) a statement that the information in the notification is accurate, and under
                penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an
                exclusive right that is allegedly infringed. If you believe in good faith that a notice of copyright
                infringement has been wrongly filed against you, the DMCA permits you to send RxCap a counter-notice.
                Notices and counter-notices must meet the then-current statutory requirements imposed by the DMCA; see
                <a href="http://www.loc.gov/copyright/"> http://www.loc.gov/copyright/ </a> for details. We suggest that
                you consult your legal advisor before filing a notice or counter-notice. Also, be aware that there are
                penalties for false claims under the DMCA.
              </p>
              <ol start="2"></ol>
            </li>
          </ol>
        </li>
        <li>
          <h2>Notices</h2>
          <ol>
            <li>
              <p>
                The User can direct notices, enquiries, complaints and so forth to RxCap at this address:
                <a href="mailto:contact@RxCap.com"> contact@rxcap.com</a>
              </p>
              <p>
                A consent, notice or communication under this Agreement is effective if it is sent as an electronic
                communication unless required to be physically delivered under law.
              </p>
            </li>
            <li>
              <h2></h2>
              <p>
                <strong>Modifications</strong>
              </p>
            </li>
            <li>
              <p>
                As our business evolves, we may change these Terms or the Privacy Policy. If we make any material
                changes to the Terms or the Privacy Policy, we will provide you with reasonable notice prior to the
                change taking effect either by emailing the email address associated with your account or by posting a
                notice on our Site. You can review the most current version of the Terms at any time by visiting this
                page. Any material revisions to these Terms will become effective on the date set forth in our notice,
                and all other changes will become effective on the date we publish the change. If you use the Site or
                service after the effective date of any changes, that use will constitute your acceptance of the revised
                terms and conditions.
              </p>
            </li>
            <li>
              <p>
                <strong>CHOICE OF LAW / JURISDICTION</strong>
              </p>
            </li>
            <li>
              <p>
                Owner maintains this site in Delaware, U.S.A. and you agree that these terms of use and any legal action
                or proceeding relating to this Site shall be governed by the laws of the State of Delaware without
                reference to its choice of law rules. &nbsp;As you have agreed by using this site to choose the laws of
                the State of Delaware to govern any such proceedings, Owner may elect to defend any such action in
                Delaware, without regard to where in the world you are located, or from where in the world you visited
                this Site.
              </p>
            </li>
            <li>
              <p>
                <strong>General</strong>
              </p>
            </li>
            <li>
              <p>
                No failure or delay by either party in exercising any right under the Terms, will constitute a waiver of
                that right. No waiver under the Terms will be effective unless made in writing and signed by an
                authorized representative of the party being deemed to have granted the waiver. Each party acknowledges
                that it has not relied on any representation, warranty or statement made by any other party, other than
                as set out in this Agreement. The relationship of the parties to this Agreement does not form a joint
                venture or partnership. No clause of this Agreement will be deemed waived and no breach excused unless
                such waiver or consent is provided in writing. Each party must do anything necessary (including
                executing agreements and documents) to give full effect to this Agreement and the transaction
                facilitated by it. Any clause of this Agreement, which is invalid or unenforceable is ineffective to the
                extent of the invalidity or unenforceability without affecting the remaining clauses of this Agreement.
                The Terms, including any terms incorporated by reference into the Terms, constitute the entire agreement
                between you and us and supersede all prior and contemporaneous agreements, proposals or representations,
                written or oral, concerning its subject matter. To the extent of any conflict or inconsistency between
                the provisions in these Terms and any pages referenced in these Terms, these Terms will prevail.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p>
        <strong>These Terms were last updated on January 15, 2021</strong>
      </p>
    </div>
  </OverlayScrollbarsComponent>
);

export default TermsPage;
