import React from 'react';

import { SelectInput } from '../components/Inputs/SelectInput';
import { getFieldWithComponent } from './getFieldWithComponent';
import { ComponentWithTooltip } from '../utils';

function SelectField(props) {
  const { input, meta, onChange, ...rest } = props;
  const onCustomChange = (e, value, text) => {
    input.onChange(e);
    if (onChange) {
      onChange(value, text);
    }
  };
  const error = typeof rest.error === 'boolean' ? rest.error && meta.error : meta.touched && meta.error;

  return (
    <ComponentWithTooltip componentType={SelectInput} {...rest} error={error} {...input} onChange={onCustomChange} />
  );
}

const Component = getFieldWithComponent(SelectField);

export { Component as SelectField };
