import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import Strings from '../../Strings';
import { Box, HistogramPlotComponent } from './base';
import { emptyPieChartSVG, emptyPieChartSVGNoData } from '../../utils';

const preparePlotData = data => {
  // Calculate time borders
  const now = new Date();
  const weekAgo = new Date(now);
  weekAgo.setDate(now.getDate() - 6);
  weekAgo.setHours(0, 0, 0, 0);

  // Convert object to array, init days without data, add additional fields
  const plotData = [];

  for (let x = new Date(weekAgo); x <= now; x.setDate(x.getDate() + 1)) {
    const ts = Number(x);
    const dateStr = moment(ts).format('YYYY-MM-DD');
    const dayData = data[dateStr] || {
      complied: 0,
      missed: 0,
      skipped: 0,
      pending: 0,
      total: 0,
    };
    plotData.push({
      ...dayData,
      date: ts,
      empty: !data.total,
    });
  }
  return plotData;
};

preparePlotData.propTypes = { data: PropTypes.any };

const HistogramAdherencePlot = ({ data, caption, isLoading }) => {
  const plotData = preparePlotData(data);
  if (Object.keys(data).length === 0 || (plotData && (isLoading || isLoading === undefined))) {
    return (
      <Box>
        <header>
          <div>{Strings.last7DaysAdherence}</div>
        </header>
        {isLoading || isLoading === undefined ? emptyPieChartSVG() : emptyPieChartSVGNoData()}
      </Box>
    );
  }

  return (
    <Box caption={caption} isLoading={isLoading}>
      <HistogramPlotComponent data={plotData} />
    </Box>
  );
};

HistogramAdherencePlot.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.bool,
  caption: PropTypes.string,
};

export default HistogramAdherencePlot;
