import {
  ESI_PATIENTS_LIST,
  ESI_PATIENTS_LIST_CLEAR,
  ESI_PATIENTS_LIST_ERROR,
  ESI_PATIENTS_LIST_RESULT,
} from './constants';

export const listReducer = (state = {}, action) => {
  switch (action.type) {
    case ESI_PATIENTS_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case ESI_PATIENTS_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.response,
      };
    case ESI_PATIENTS_LIST_RESULT:
      return {
        ...state,
        isLoading: false,
        data: action.response,
      };
    case ESI_PATIENTS_LIST_CLEAR:
      return {
        ...state,
        isLoading: undefined,
        data: undefined,
      };
    default:
      return state;
  }
};
