import { WIDGET_PATIENTS_TO_CALL, WIDGET_PATIENTS_TO_CALL_RESULT, WIDGET_PATIENTS_TO_CALL_ERROR } from './constants';

export const widgetPatientsToCallReducer = (state = {}, action) => {
  switch (action.type) {
    case WIDGET_PATIENTS_TO_CALL:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: [],
        pagination: {},
      };
    case WIDGET_PATIENTS_TO_CALL_RESULT:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.response.patients,
        pagination: action.response.pagination,
      };

    case WIDGET_PATIENTS_TO_CALL_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: [],
        pagination: {},
      };
    }
    default:
      return state;
  }
};
