import {
  ESI_PATIENT_CAP_ID_UPDATE,
  ESI_PATIENT_CAP_ID_UPDATE_ERROR,
  ESI_PATIENT_LOGIN_CHECK_CODE,
  ESI_PATIENT_LOGIN_CHECK_CODE_ERROR,
  ESI_PATIENT_LOGIN_CHECK_CODE_RESULT,
  ESI_PATIENT_LOGIN_RESEND_CODE,
  ESI_PATIENT_LOGIN_RESEND_CODE_ERROR,
  ESI_PATIENT_LOGIN_RESEND_CODE_RESULT,
  ESI_PATIENT_LOGIN_SEND_CODE,
  ESI_PATIENT_LOGIN_SEND_CODE_ERROR,
  ESI_PATIENT_LOGIN_SEND_CODE_RESULT,
} from './constants';
import Strings from '../../../../Strings';
import React from 'react';
import { authSet } from '../../../../utils';

const ERROR_WRONG_NUMBER = 'Unregistered Number';
const ERROR_WRONG_CODE = 'Invalid token';
const SUCCESS_MESSAGE = 'OK';

const defaultErrorMessage = {
  title: Strings.errors.internalError,
  text: '',
};

function isSuccess(response) {
  return response && response.success && response.success.code === 200 && response.success.message === SUCCESS_MESSAGE;
}

function getErrorMessage(response) {
  return response.data && response.data.error && response.data.error.message ? response.data.error.message : null;
}

export const reducer = (state = null, action) => {
  const actionType = action.type;
  let isLoading = false;
  let errorMessage;

  switch (action.type) {
    case ESI_PATIENT_LOGIN_SEND_CODE:
    case ESI_PATIENT_LOGIN_RESEND_CODE:
    case ESI_PATIENT_LOGIN_CHECK_CODE:
    case ESI_PATIENT_CAP_ID_UPDATE:
      isLoading = true;
      break;
    case ESI_PATIENT_LOGIN_SEND_CODE_RESULT:
    case ESI_PATIENT_LOGIN_RESEND_CODE_RESULT:
    case ESI_PATIENT_LOGIN_CHECK_CODE_RESULT:
      if (!isSuccess(action.response)) {
        errorMessage = defaultErrorMessage;
      }
      if (action.response.authentication_token) {
        return {
          ...authSet(action.response),
          isLoading: false,
          is_doctor: action.response.is_doctor,
          role: action.response.role,
          loginErrors: undefined,
          reset_password: action.response.reset_password,
          config: action.response.config,
        };
      }
      break;
    case ESI_PATIENT_LOGIN_SEND_CODE_ERROR:
    case ESI_PATIENT_LOGIN_RESEND_CODE_ERROR: {
      const apiErrorMessage = getErrorMessage(action.response);
      errorMessage = {
        title: Strings.errors.unrecognisedNumber,
        text: Strings.errors.unrecognisedNumberEsiPatientText(),
      };
      break;
    }
    case ESI_PATIENT_CAP_ID_UPDATE:
      return {
        ...state,
        isLoading: false,
      };
    case ESI_PATIENT_CAP_ID_UPDATE_ERROR: {
      const apiErrorMessage = getErrorMessage(action.response);
      errorMessage = {
        title: Strings.errors.invalidCapId,
        text: '',
      };
      break;
    }
    case ESI_PATIENT_LOGIN_CHECK_CODE_ERROR:
      const apiErrorMessage = getErrorMessage(action.response);
      switch (apiErrorMessage) {
        case ERROR_WRONG_NUMBER:
          errorMessage = {
            title: Strings.errors.unrecognisedNumber,
            text: Strings.errors.unrecognisedNumberEsiPatientText(),
          };
          break;
        case ERROR_WRONG_CODE:
          errorMessage = {
            title: Strings.errors.invalidCode,
            text: Strings.errors.invalidCodeEsiPatientText(),
          };
          break;
        case null:
          errorMessage = defaultErrorMessage;
          break;
        default:
          errorMessage = {
            title: apiErrorMessage,
          };
          break;
      }
      break;
    default:
      return state;
  }

  return {
    ...state,
    isLoading,
    errorMessage,
    actionType,
  };
};
