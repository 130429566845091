import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { LIST_PATIENT } from '../../actions/action-types';
import { openModalAction } from '../../actions/modal';
import { patientAction } from '../../actions/patient';
import { SELECT_TYPE_RIGHT } from '../../components/Inputs/SelectInput';
import { PageHeader } from '../../components/PageHeader';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../components/PageHeader/Button';
import Select from '../../components/Select/SelectOld';
import { MED_ICON_COLOR_FILTER, MED_ICON_COLOR_FILTER_INACTIVE } from '../../constants';
import Table, { Column } from '../../containers/Table/TableWithPagination';
import Strings from '../../Strings';
import { getMedIcon } from '../../utils/medIcons';
import { RowImage } from '../ESI/PatientDashboard/RowImage';
import { Separator } from '../ESI/PatientDashboard/Separator';
import { TopPanel } from '../ESI/PatientDashboard/TopPanel';
import { DateFilters } from '../../utils/DateFilters';
import './MedicationsView.scss';
import { showStatus, toolTipForTable } from '../../utils';

const getAdherencePercentage = patient => {
  if (patient.analytics && patient.analytics) {
    return `${patient.analytics.adherence}%`;
  }
  return 'N/A';
};

const getAdherenceLine = adherence => {
  const average = getAdherencePercentage(adherence);
  const disabled = adherence.status === 'active' ? '' : ' disabled';
  const perfectHundredStyle = average === '100%' ? 'perfect' : 'active';
  const averageWidthStyle = { width: average };
  return (
    <div className="medication_adherence">
      <div className={`average${disabled}`}>
        <div className={perfectHundredStyle} style={averageWidthStyle} />
      </div>
      <div className="averageText">{average}</div>
    </div>
  );
};

class MedicationsView extends Component {
  refreshEnable = true;
  datePeriods = [
    {
      text: Strings.patient_medications.last2Months,
      value: 'Last2Months',
    },
    {
      text: Strings.thisMonth,
      value: 'ThisMonth',
    },
    {
      text: Strings.last7days,
      value: 'Last7Days',
    },
  ];
  state = {
    selectedPeriodValue: DateFilters.Last2Months.value,
    selectedMedicine: 'all',
    isDownload: false,
    active: true,
    inActive: true,
  };

  request = {
    startDate: DateFilters.Last2Months.dates.startDate(),
    filterBy: 'all',
    filterDateRangeOfMedsSchedules: true,
  };

  componentDidMount() {
    this.loadMedications();
  }

  onSortClick = ({ sortKey, direction }) => {
    this.request.offset = 0;
    this.request.sortColumn = sortKey;
    this.request.sortType = direction;
    this.loadMedications();
  };

  onPrevClick = () => {
    const { pagination } = this.props;
    this.request.offset = pagination.offset - PAGE_LIMIT;
    this.loadMedications();
  };

  onNextClick = () => {
    const { pagination } = this.props;
    this.request.offset = pagination.offset + PAGE_LIMIT;
    this.loadMedications();
  };

  onCustomPage = page => {
    this.request.offset = (page - 1) * PAGE_LIMIT;
    this.loadMedications();
  };

  onMedicineChange = value => {
    this.setState({ selectedMedicine: value }, () => {
      this.setMedicationsFilter();
    });
  };

  onPeriodChange = value => {
    this.setState({ selectedPeriodValue: value });

    this.request.startDate = DateFilters[value].dates.startDate();
    this.loadMedications();
    this.setMedicationsFilter();
  };

  getMedsWithNameFilter = () => {
    const { medications } = this.props;
    const { selectedMedicine } = this.state;
    if (medications && selectedMedicine === 'all') {
      return medications;
    }
    if (medications) {
      return Object.values(medications).filter(x => x.medicationName === selectedMedicine);
    }
  };

  setMedicationsFilter = () => {
    return this.byStatusFilter(this.getMedsWithNameFilter());
  };

  byStatusFilter(medications) {
    const { active, inActive } = this.state;
    const medicine = Object.values(medications).filter(x => {
      if (active && inActive) {
        return x;
      }
      if (active) {
        return x.status === 'active';
      }
      if (inActive) {
        return x.status === 'inactive';
      }
    });
    return medicine;
  }

  onActive = event => {
    this.setState({ active: event.target.checked });
  };

  onInActive = event => {
    this.setState({ inActive: event.target.checked });
  };

  onRowSelected = id => {
    const medicine = this.setMedicationsFilter();
    this.props.onNavigate(`/patient/${this.props.match.params.id}/medications/${medicine[id].id}/details`);
  };

  onDownloadBtnClick = () => {
    const { medications } = this.props;
    const { active, inActive, selectedMedicine, selectedPeriodValue } = this.state;
    const filterBy = active && inActive ? 'all' : active ? 'active' : 'inactive';
    const medicine = Object.values(medications).filter(x => {
      if (selectedMedicine !== 'all') {
        if (active) {
          return x.medicationName === selectedMedicine && x.status === 'active';
        } else if (inActive) {
          return x.medicationName === selectedMedicine && x.status === 'inactive';
        }
        return x.medicationName === selectedMedicine;
      }
    });

    const filteredData = {
      startDate: DateFilters[selectedPeriodValue].dates.startDate(),
      filterBy,
      search: selectedMedicine == 'all' ? '' : selectedMedicine,
      medications: medicine,
    };

    this.props.download(filteredData);
  };

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 30000);
    this.loadMedications();
  };

  loadMedications() {
    const patientId = this.props.match.params.id;
    this.props.loadMedications(patientId, this.request);
  }

  getHeaderComponentsLeft = () => {
    const { isDownload } = this.state;

    return (
      <React.Fragment>
        <Button
          class={HEADER_BUTTON_DARK_BLUE}
          isSpinner={isDownload}
          onClick={this.onDownloadBtnClick}
          title={Strings.downloadAllMeds}
          first
        />
        <button
          className="refresh_button"
          style={{ marginLeft: '15px' }}
          disabled={!this.refreshEnable}
          onClick={this.onRefresh}
          aria-label={Strings.refreshAL}
        ></button>
      </React.Fragment>
    );
  };

  getHeaderComponentsRight = () => {
    const { medications } = this.props;

    const medData = [
      {
        value: 'all',
        text: Strings.patientDashboard.allMeds,
      },
    ];

    const medNames = [];
    if (medications) {
      Object.values(medications).forEach(medication => {
        medNames.push(medication.medicationName);
      });
    }

    _.uniq(medNames).forEach(e => {
      medData.push({ value: e, text: _.truncate(e, { length: 120 }) });
    });

    return (
      <Select
        type={SELECT_TYPE_RIGHT}
        autoWidth
        classes={[
          Select.CLASS_BIG,
          Select.CLASS_DARK,
          Select.CLASS_NO_MARGIN_RIGHT,
          Select.CLASS_NO_BORDER,
          Select.CLASS_MAX_WIDTH_600,
        ]}
        data={medData}
        onChange={this.onMedicineChange}
        aria-haspopup="listbox"
        role="option"
        aria-label="select medicine from list"
        id="medicineSelect"
      />
    );
  };

  render() {
    const { active, inActive } = this.state;
    const { medications, adherence, performance, isLoading } = this.props;

    const isStatusActive = d => {
      return d.status === 'active';
    };

    const medsByName = this.getMedsWithNameFilter();
    let activeMeds = 0;
    let inactiveMeds = 0;

    if (medsByName) {
      Object.values(medsByName).forEach(m => {
        if (m.status === 'active') {
          activeMeds += 1;
        } else {
          inactiveMeds += 1;
        }
      });
    }

    return (
      <div className="medications-view">
        <PageHeader
          noLeftPadding
          left={() => this.getHeaderComponentsLeft()}
          right={() => this.getHeaderComponentsRight()}
          growRight
        />

        <TopPanel adherence={adherence} performance={performance}>
          <div className="checkbox_container">
            <div className="input-content">
              <input
                onChange={this.onActive}
                type="checkbox"
                id="confirmation-checkbox-active"
                checked={active}
                aria-checked={active}
                tabIndex="0"
                aria-labelledby="chkAct-label"
              />
            </div>
            <span id="chkAct-label">
              {Strings.patient_medications.active} ({activeMeds})
            </span>
          </div>

          <div className="checkbox_container">
            <div className="input-content">
              <input
                onChange={this.onInActive}
                type="checkbox"
                id="confirmation-checkbox-inactive"
                checked={inActive}
                aria-checked={active}
                tabIndex="0"
                aria-labelledby="chkInAct-label"
              />
            </div>
            <span id="chkInAct-label">
              {Strings.patient_medications.inActive} ({inactiveMeds})
            </span>
          </div>

          <Separator />

          <Select
            type={SELECT_TYPE_RIGHT}
            autoWidth
            classes={[Select.CLASS_BIG, Select.CLASS_WHITE_BLUE]}
            data={this.datePeriods}
            onChange={this.onPeriodChange}
            aria-label="select period from list"
            id="periodSelect"
          />
        </TopPanel>

        <Table
          name="Medications"
          data={medications ? this.setMedicationsFilter() : []}
          isLoading={isLoading}
          onRowSelection={this.onRowSelected}
          onSortClick={this.onSortClick}
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          onCustomPage={this.onCustomPage}
          tabIndex
          freezeFirstColumn={false}
        >
          <Column
            key="name"
            title={Strings.patient_medications.medication}
            value={d =>
              toolTipForTable(
                <div className="medication-name-with-icon">
                  <RowImage
                    image={getMedIcon(d.format?.route)}
                    style={{ filter: isStatusActive(d) ? MED_ICON_COLOR_FILTER : MED_ICON_COLOR_FILTER_INACTIVE }}
                    list="row-icon"
                  />
                  <div className={`medication-name ${isStatusActive(d) ? 'name_active' : 'name_inactive'}`}>
                    {d.medicationName}
                  </div>
                </div>,
                d.medicationName,
              )
            }
            sortKey="name"
          />
          <Column
            key="dose"
            title={Strings.patient_medications.stockDoses}
            value={d => {
              const amount = d.stock?.stock || 0;
              const status = d.stock?.stock_status || 'low';

              return <div className={`stock ${status} ${isStatusActive(d) ? '' : 'inactive'}`}>{amount}</div>;
            }}
            sortKey="stock"
          />
          <Column
            key="adherence"
            title={Strings.adherence}
            value={d => (d.analytics && d.scheduleType !== 'as_needed' ? getAdherenceLine(d) : 'N/A')}
          />
          <Column key="taken" title={Strings.taken} value={d => d.analytics.complied} />
          <Column
            key="missed"
            title={Strings.missed}
            value={d => (d.scheduleType !== 'as_needed' ? d.analytics.missed : 'N/A')}
          />
          <Column key="status" title={Strings.status} value={d => showStatus(d.status)} />
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const userFullname = state.auth.profile ? `${state.auth.profile.first_name} ${state.auth.profile.last_name}` : '';
  const { schedules } = state.entities;
  return {
    medications: schedules.medications,
    isLoading: schedules.loading,
    dateFilter: state.filter.dateFilter,
    userFullname,
    adherence: schedules.adherence,
    performance: schedules.performance,
    active_medications: schedules.active_medications,
    pagination: schedules.pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  loadMedications: (patientId, params) => dispatch(patientAction.actionMedicationList(patientId, params)),
  onNavigate: path => dispatch(push(path)),
  download: data =>
    dispatch(
      openModalAction('download-medications', {
        action: patientAction.actionList,
        actionType: LIST_PATIENT,
        data,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MedicationsView);
