import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/Buttons/Button';
import { ButtonRow } from 'components/Login/ButtonRow/ButtonRow';
import { Text } from 'components/Login/Text/Text';

import { BUTTON_TYPE_DARK_BLUE } from '../../../../components/Buttons/Button';
import dropdownImage from '../../../../images/rx/rectangle.svg';
import Strings from '../../../../Strings';
import './Step.scss';
import { GetTimezoneTextMapping } from '../../../../constants';

class Step5_Timezone extends PureComponent {
  state = { selectedTimezone: 'Select Timezone' };

  codeInput = React.createRef();

  onTimezoneChange = event => {
    this.setState({ selectedTimezone: event.target.value });
  };

  onFormSubmit = event => {
    const { onFormSubmit } = this.props;
    event.preventDefault();
    const { selectedTimezone } = this.state;
    onFormSubmit({ timezone: selectedTimezone });
  };

  render() {
    const { selectedPeriod, selectedTimezone } = this.state;
    const timezones = GetTimezoneTextMapping();

    return (
      <form onSubmit={this.onFormSubmit}>
        <Text>{Strings.setTimezoneInst}</Text>

        {timezones && (
          <div className="select-timezone left-select">
            <img className="select-image" src={dropdownImage} alt="select" />

            <select name="filterByMedicine" onChange={this.onTimezoneChange} value={selectedPeriod} className="right">
              <option key="lastmonth1" value={selectedTimezone}>
                Please select
              </option>
              {timezones &&
                Object.keys(timezones).map(key => {
                  return (
                    <option key={[key]} value={[key]}>
                      {timezones[key]}
                    </option>
                  );
                })}
            </select>
          </div>
        )}
        {!timezones && <div className="spinner" />}
        <ButtonRow
          full={
            <Button buttonType={BUTTON_TYPE_DARK_BLUE} type="submit" disabled={selectedTimezone === 'Select Timezone'}>
              {Strings.next}
            </Button>
          }
        />
      </form>
    );
  }
}

Step5_Timezone.propTypes = {
  onFormSubmit: PropTypes.func,
  timezones: PropTypes.func,
};

export default Step5_Timezone;
