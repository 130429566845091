import { API_CALL } from '../../../middleware/api';

export const ADD_BULK_DEVICES = 'ADD_BULK_DEVICES';
export const ADD_BULK_DEVICES_RESULT = `${ADD_BULK_DEVICES}/result`;

export const actions = {
  addBulkDevices: data => {
    return {
      [API_CALL]: {
        method: 'POST',
        endpoint: `/v1/organizations/devices/add`,
        data,
      },
      type: ADD_BULK_DEVICES,
    };
  },
};
