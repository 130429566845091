import { API_CALL } from '../middleware/api';
import { GET_PATIENT_DASHBOARD } from './action-types';

export const PatientDashboardAction = {
  getDashboard: id => ({
    [API_CALL]: {
      method: 'get',
      endpoint: `/portal/patients/${id}/overview`,
    },
    type: GET_PATIENT_DASHBOARD,
  }),
};
