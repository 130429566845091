import { API_CALL } from '../middleware/api';
import { ACCEPT_AS_CAREGIVER } from './action-types';

export const caregiverAction = {
  acceptRequest: data => ({
    [API_CALL]: {
      endpoint: '/v2/caregiver/register/',
      method: 'post',
      data,
    },
    type: ACCEPT_AS_CAREGIVER,
  }),
};
