import { columnActionType } from '../actions/columns';

export const columnsReducer = (state = { visible: ['left', 'right'] }, action) => {
  switch (action.type) {
    case columnActionType.show:
      return {
        ...state,
        visible: action.columns,
      };
    default:
      return state;
  }
};
