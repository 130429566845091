import React, { PureComponent } from 'react';
import Switch from 'components/Switch';

import styles from '../Input.module.scss';

export class SwitchInput extends PureComponent {
  render() {
    const { error, text, checked, name, ...props } = this.props;

    return (
      <div>
        <div className={styles.switchContainer}>
          <div className={styles.text}>{text}</div>
          <Switch name={name} flag={checked} {...props} />
        </div>
        {error ? <div className={styles.error}>{error}</div> : null}
      </div>
    );
  }
}
