import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isValidPhoneNumber } from 'react-phone-number-input';
import cloneDeep from 'lodash/cloneDeep';

import { Modal } from '../containers';
import Form, { Input } from '../containers/Form';
import { closeModal } from '../actions/modal';
import { resetForm } from '../actions/forms';
import LoadingRenderer from '../components/LoadingRenderer';
import { patientAction } from '../actions/patient';
import Strings from '../Strings';
import './editStudyModal.scss';
import CustomSelect from '../components/CustomSelect';

class EditCaregiverModalOld extends PureComponent {
  constructor(props) {
    super(props);

    const newCaregiver = {
      id: 0,
      first_name: '',
      last_name: '',
      mobilePhone: '',
      phoneError: '',
      mobile_phone: '',
      patient_name: '',
      selectedOption: {
        name: 'United States',
        value: '+1',
      },
    };

    this.state = {
      errors: [],
      listOpen: false,
      caregiver: this.props.data.caregiver ? cloneDeep(this.props.data.caregiver) : newCaregiver,
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onMobileNumberChange = this.onMobileNumberChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.contryCodeChange = this.contryCodeChange.bind(this);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onTextChange(event) {
    const { caregiver } = this.state;
    caregiver[event.target.getAttribute('name')] = event.target.value.trim();
    this.setState({ caregiver });
  }

  onMobileNumberChange(event) {
    const caregiver = cloneDeep(this.state.caregiver);
    caregiver.mobilePhone = event.target.value;
    this.setState({ caregiver });
  }

  onSubmit() {
    const { caregiver } = this.state;

    const validationErrors = [];
    caregiver.mobile_phone = caregiver.selectedOption.value + caregiver.mobilePhone;
    this.setState(caregiver);
    if (!caregiver.patient_name.length) {
      const name = this.props.isDoctor ? Strings.patientNameText : Strings.yourName;
      const error = `${name} ${Strings.errors.emptyIsRequired}`;
      validationErrors.push(error);
    } else if (!caregiver.first_name.length) {
      validationErrors.push(Strings.errors.emptyFirstName);
    } else if (!caregiver.last_name.length) {
      validationErrors.push(Strings.errors.emptyLastName);
    } else if (!caregiver.mobilePhone || !caregiver.mobilePhone.length) {
      validationErrors.push(Strings.errors.emptyPhoneNumber);
    } else if (!isValidPhoneNumber(caregiver.mobile_phone)) {
      validationErrors.push(Strings.errors.invalidPhoneNumber);
    }

    if (validationErrors.length) {
      this.setState({ errors: validationErrors });
      return;
    }

    const cloneCaregiver = cloneDeep(caregiver);
    delete cloneCaregiver.mobilePhone;
    delete cloneCaregiver.selectedOption;
    delete cloneCaregiver.phoneError;

    const {
      data: { actionType },
    } = this.props;

    if (this.props.role === 'patient') {
      this.props.onSubmit(cloneCaregiver, this.props.patientId).then(response => {
        if (response.type === `${actionType}/error` && response.response.data) {
          const responseData = response.response.data;
          const errors = Object.keys(responseData).map(key => `${key}: ${responseData[key]}`);
          this.setState({ errors });
        } else if (response && response.type === `${actionType}/result`) {
          this.props.loadPatients(response.response.user_id, false);
          this.props.onClose();
        }
        return response;
      });
    } else {
      this.props.onSubmit(cloneCaregiver).then(response => {
        if (response.type === `${actionType}/error` && response.response.data) {
          const responseData = response.response.data;
          const errors = Object.keys(responseData).map(key => `${key}: ${responseData[key]}`);
          this.setState({ errors });
        } else if (response && response.type === `${actionType}/result`) {
          this.props.loadPatients(response.response.user_id, false);
          this.props.onClose();
        }
        return response;
      });
    }
  }

  contryCodeChange(event) {
    const caregiver = cloneDeep(this.state.caregiver);
    caregiver.selectedOption = event;
    this.setState({
      caregiver,
      listOpen: false,
    });
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ listOpen: false });
    }
  }

  toggleVisibility() {
    this.setState(prevState => ({ listOpen: !prevState.listOpen }));
  }

  render() {
    const { onOpen, onCancel, addCaregiverLoading, isDoctor, ...props } = this.props;

    const { errors, caregiver, listOpen } = this.state;
    return (
      <Modal name="new-trial" onOpen={onOpen} additionalClasses={['form-modal']} {...props}>
        <LoadingRenderer loading={!!addCaregiverLoading}>
          <Form onSubmit={this.onSubmit} onCancel={onCancel} id="new-caregiver">
            {/* <div className="modal-logo" key="modal-logo" /> */}
            <div key="caregiver-user-name " className="mb-15 dialog-input">
              <Input
                label=""
                name="patient_name"
                id="firspatient_your_namet_name"
                className="dialog-form"
                placeholder={isDoctor ? Strings.patientNameText : Strings.yourName}
                type="text"
                defaultValue={caregiver.patient_name}
                onChange={this.onTextChange}
              />
            </div>
            <div key="caregiver-first-name " className="mb-15 dialog-input">
              <Input
                label=""
                name="first_name"
                id="first_name"
                className="dialog-form"
                placeholder={Strings.firstName}
                type="text"
                defaultValue={caregiver.first_name}
                onChange={this.onTextChange}
              />
            </div>
            <div key="caregiver-last-name" className="mb-15 dialog-input">
              <Input
                label=""
                name="last_name"
                id="last_name"
                className="dialog-form"
                placeholder={Strings.lastName}
                type="text"
                defaultValue={caregiver.last_name}
                onChange={this.onTextChange}
              />
            </div>
            <div ref={this.wrapperRef} key="caregiver-mobile-phone-wrapper">
              <div key="caregiver-mobile-phone " style={{ height: '60px' }}>
                <CustomSelect
                  countryCode=""
                  isCountryListOpen=""
                  selectedOption={caregiver.selectedOption}
                  onClick={this.contryCodeChange}
                  isDialog
                  toggleDrawer={this.toggleVisibility}
                  listOpen={listOpen}
                ></CustomSelect>
              </div>
            </div>
            <div key="caregiver-number " className="dialog-input">
              <input
                label=""
                onChange={this.onMobileNumberChange}
                type="text"
                className="dialog-form"
                name="mobile"
                value={caregiver.mobilePhone}
                placeholder={Strings.mobileNumber}
              />
            </div>

            <div key="errors-block" className="errors-block">
              {errors.length ? <div>{errors.join(' ')}</div> : null}
            </div>
            <div className="button-bar" key="button-bar">
              <button className="white gray-text" key="cancel" type="cancel">
                {Strings.cancel}
              </button>
              <button className="brand-blue " key="submit" type="submit">
                {Strings.submit}
              </button>
            </div>
          </Form>
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const isDoctor = state.auth.role === 'doctor';
  return {
    dateFilter: state.filter.dateFilter,
    isDoctor,
    patientId: state.auth.profile.id,
    role: state.auth.role,
    addCaregiverLoading: state.entities.patients.addCaregiverLoading || state.user.addCaregiverLoading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCancel: () => dispatch(closeModal('add-caregiver')),
    loadPatients: (patientId, isLoading) => dispatch(patientAction.actionCaregivers(patientId, isLoading)),
    onSubmit: (caregiver, patientId) =>
      dispatch(
        caregiver.id
          ? ownProps.data.action(ownProps.data.patientId || null, caregiver.id, caregiver)
          : ownProps.data.action(ownProps.patientId || patientId, caregiver),
      ).then(response => {
        if (response && response.type === `${ownProps.data.actionType}/result`) {
          dispatch(closeModal('add-caregiver'));
        }

        return response;
      }),
    onClose: () => dispatch(closeModal('add-caregiver')),
    onOpen: () => dispatch(resetForm('add-caregiver', ownProps.data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCaregiverModalOld);
