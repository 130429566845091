import axios from 'axios';
import axiosRetry, { isNetworkError } from 'axios-retry';
import { normalize } from 'normalizr';

import { BASE_URL } from '../constants';
import { EntityParsers } from '../schemas';
import { authUnset } from '../utils';
import { isEsi } from '../utils/portalType';
import { isPatient } from '../utils/userRoles';

export const API_CALL = Symbol('api call');
export const API_CANCEL = Symbol('api cancel');

const qs = require('qs');

const axiosInstance = axios.create();

// Workaround for how axios-retry handles CORS errors...
// Based on https://github.com/softonic/axios-retry/issues/138#issuecomment-721632626
const isCorsNetworkError = error =>
  error && !error.response && error.code !== 'ECONNABORTED' && error.message === 'Network Error';

axiosRetry(axiosInstance, {
  retries: 3,
  retryCondition: error => {
    var res = isNetworkError(error);
    if (!res) {
      res = isCorsNetworkError(error);
    }
    // Added also 500, because seems when Google Cloud itself (not only our Cloud Function panic) has issues it returns 500... https://stackoverflow.com/q/65303743/950131
    if (!res) {
      res =
        error.response &&
        (error.response.status === 503 ||
          error.response.status === 429 ||
          error.response.status === 500 ||
          error.response.status === 502);
    }
    return res;
  },
  retryDelay: (retryCount, _) => {
    return retryCount === 1 ? 500 : retryCount === 2 ? 2000 : 5000;
  },
});

const callAPI = (endpoint, method, params, data, headers, responseType, signal) => {
  const req = axiosInstance.request({
    method,
    baseURL: BASE_URL,
    url: endpoint,
    responseType: responseType || undefined,
    params,
    data,
    headers,
    paramsSerializer: parameters => qs.stringify(parameters, { arrayFormat: 'repeat' }),
    signal,
  });

  return req.then(response => response);
};

export default store => next => action => {
  const apiCancel = action[API_CANCEL];
  if (typeof apiCancel !== 'undefined') {
    const { controller } = apiCancel;
    controller.abort();
  }

  const apiCall = action[API_CALL];
  if (typeof apiCall === 'undefined') {
    return next(action);
  }

  const { auth } = store.getState();

  const { type } = action;
  let { endpoint } = apiCall;
  const {
    method = 'get',
    params,
    data = null,
    schema,
    callBefore = [],
    callAfterSuccess = [],
    callAfterError = [],
    responseType = undefined,
    signal,
  } = apiCall;

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }

  const actionWith = actionData => {
    const finalAction = {
      ...action,
      ...actionData,
    };
    delete finalAction[apiCall];
    return finalAction;
  };

  next(actionWith({ type }));

  const headers = auth.authenticated
    ? {
        Authorization: `Token ${auth.token}`,
        'X-Client-Type': 'web',
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    : {
        'X-Client-Type': 'web',
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      };

  if (callBefore && Array.isArray(callBefore)) {
    callBefore.forEach(item => store.dispatch(item));
  }

  let respHeaders;

  return callAPI(endpoint, method, params, data, headers, responseType, signal)
    .then(response => {
      respHeaders = response.headers;
      return (schema && normalize(response.data, schema)) || response.data;
    })
    .then(response => {
      let newResponse = response;
      if (response.entities) {
        const entities = Object.keys(response.entities).reduce((result, key) => {
          const newResult = result;
          const value = response.entities[key];
          newResult[key] = (EntityParsers[key] && EntityParsers[key](value)) || value;
          return newResult;
        }, {});
        newResponse = {
          ...response,
          entities: {
            ...response.entities,
            ...entities,
          },
        };
      }

      return newResponse;
    })
    .then(response => {
      if (callAfterSuccess && Array.isArray(callAfterSuccess)) {
        callAfterSuccess.forEach(item => store.dispatch(item));
      }
      return next(
        actionWith({
          type: [type, 'result'].join('/'),
          headers: respHeaders,
          response,
        }),
      );
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        const isUserPatient = isPatient();
        const isUserEsi = isEsi();

        authUnset();

        if (window.location.pathname.indexOf('/login') !== 0) {
          window.location.href = isUserPatient && isUserEsi ? '/login-esi-patient' : '/login';
        }
      }

      if (error.response && error.response.status === 403) {
        return next(
          actionWith({
            type: ['auth/status', 'error'].join('/'),
            error: error.message || 'Something bad happened',
            response: error.response,
          }),
        );
      }

      if (callAfterError && Array.isArray(callAfterError)) {
        callAfterError.forEach(item => store.dispatch(item));
      }
      if (error.message === 'canceled') {
        return next(
          actionWith({
            type: [type, 'canceled'].join('/'),
          }),
        );
      }
      return next(
        actionWith({
          type: [type, 'error'].join('/'),
          error: error.message || 'Something bad happened',
          response: error.response,
        }),
      );
    });
};
