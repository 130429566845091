import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import Strings from '../../../../Strings';
import { makeValid } from '../../../../utils';
import TableWidget from '../component/TableWidget';
import { DATE_FORMAT_MONTH_NAME } from '../../../../constants';

function CohortDetails(props) {
  const { cohort, cohortId, isLoading } = props;
  const data = cohort
    ? [
        [Strings.cohortId, makeValid(cohortId)],
        [Strings.cohortName, makeValid(cohort.title)],
        [Strings.created, cohort.created ? moment(cohort.created).format(DATE_FORMAT_MONTH_NAME) : '-'],
        [Strings.edited, cohort.updated ? moment(cohort.updated).format(DATE_FORMAT_MONTH_NAME) : '-'],
        [Strings.capPatient.primaryCondition, makeValid(cohort.condition)],
        [Strings.facility, cohort.facility || '-'],
      ]
    : [];
  return (
    <TableWidget title={Strings.widgets.details} data={data} isLoading={isLoading} firstColumnClassName="cohort-col" />
  );
}

CohortDetails.propTypes = {
  isLoading: PropTypes.any,
  cohort: PropTypes.shape({
    title: PropTypes.string,
    created: PropTypes.string,
    edited: PropTypes.string,
    condition: PropTypes.string,
  }),
  cohortId: PropTypes.any,
  onEdit: PropTypes.func,
};
CohortDetails.widgetId = 'CohortDetailsWidget';

export default CohortDetails;
