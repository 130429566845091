import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TabView, { Tab } from '../../../components/TabView';
import Strings from '../../../Strings';
import MessageArchived from './MessageArchived';
import MessageInbox from './MessageInbox';
import MessageNew from './MessageNew';
import { hasPermission, PERMISSIONS } from '../../../utils/userPermissions';
import PrefabReplies from './PrefabReplies';

class Messages extends PureComponent {
  searchTime;

  static propTypes = { activeTab: PropTypes.string.isRequired };

  render() {
    const { activeTab } = this.props;
    const tabArray = [];
    const messageInbox = (
      <Tab key="inbox" name={Strings.messages.inbox} path="/messages/inbox">
        <MessageInbox />
      </Tab>
    );

    const archived = (
      <Tab key="archived" name={Strings.messages.archived} path="/messages/archived">
        <MessageArchived></MessageArchived>
      </Tab>
    );

    const newMessage = (
      <Tab key="new" name={Strings.messages.new} path="/messages/new">
        <MessageNew></MessageNew>
      </Tab>
    );

    const prefabReplies = (
      <Tab key="new" name={Strings.messages.prefabReplies} path="/messages/prefabs">
        <PrefabReplies />
      </Tab>
    );

    if (hasPermission(PERMISSIONS.MESSAGES_INBOX)) {
      tabArray.push(messageInbox);
    }
    if (hasPermission(PERMISSIONS.MESSAGES_ARCHIVED)) {
      tabArray.push(archived);
    }
    if (hasPermission(PERMISSIONS.MESSAGES_SEND_MESSAGES)) {
      tabArray.push(newMessage);
    }
    if (hasPermission(PERMISSIONS.MESSAGES_PREFABS)) {
      tabArray.push(prefabReplies);
    }

    return (
      <TabView key="tabs" activeTab={activeTab} routeMode className="" noSwiping>
        {tabArray}
      </TabView>
    );
  }
}

export default Messages;
