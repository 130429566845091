import React, { PureComponent } from 'react';

import { TextInput } from '../TextInput';
import styles from '../Input.module.scss';
import { MODE_TWO_COLUMNS } from '../../../constants';

export class TextInputWithLabel extends PureComponent {
  render() {
    const { title, error, mode = MODE_TWO_COLUMNS, ...props } = this.props;

    return (
      <div className={mode === MODE_TWO_COLUMNS ? styles.twoColumns : styles.oneColumn}>
        <div className={styles.labelTitle}>{title}</div>
        <div>
          <TextInput {...props} />
        </div>
        {error ? <div className={styles.error}>{error}</div> : null}
      </div>
    );
  }
}
