import { API_CALL } from 'middleware/api';
import {
  ESI_PATIENT_CAP_ID_UPDATE,
  ESI_PATIENT_LOGIN_CHECK_CODE,
  ESI_PATIENT_LOGIN_CONFIG,
  ESI_PATIENT_LOGIN_RESEND_CODE,
  ESI_PATIENT_LOGIN_SEND_CODE,
} from './constants';

function getDeviceInfo() {
  return {
    deviceIp: '',
    deviceName: '',
    deviceType: 'web',
    deviceToken: '',
  };
}

export const actions = {
  sendCode: phone => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: '/esi/v1/patients/verify',
      data: {
        context: 'ESI_PATIENT_VERIFICATION',
        function: 'new',
        field: phone,
        flavor: 'sms',
        deviceInfo: getDeviceInfo(),
      },
    },
    type: ESI_PATIENT_LOGIN_SEND_CODE,
  }),

  resendCode: phone => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: '/esi/v1/patients/verify',
      data: {
        context: 'ESI_PATIENT_VERIFICATION',
        function: 'new',
        field: phone,
        flavor: 'sms',
        deviceInfo: getDeviceInfo(),
      },
    },
    type: ESI_PATIENT_LOGIN_RESEND_CODE,
  }),

  verifyCode: (phone, code) => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: '/esi/v1/patients/verify',
      data: {
        context: 'ESI_PATIENT_VERIFICATION',
        function: 'verify',
        field: phone,
        token: code,
        flavor: 'sms',
        deviceInfo: getDeviceInfo(),
      },
    },
    type: ESI_PATIENT_LOGIN_CHECK_CODE,
  }),

  updateConfig: data => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: '/esi/user/config/',
      data,
    },
    type: ESI_PATIENT_LOGIN_CONFIG,
  }),

  updateCapId: cap_id => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: `/esi/v1/cap/assign`,
      data: { cap_id },
    },
    type: ESI_PATIENT_CAP_ID_UPDATE,
  }),
};
