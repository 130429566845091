import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import { COUNTRIES_TEL_CODE } from '../../constants';

class CustomSelect extends PureComponent {
  static propTypes = {
    toggleDrawer: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    selectedOption: PropTypes.object.isRequired,
    isDialog: PropTypes.bool,
    listOpen: PropTypes.bool,
  };

  constructor() {
    super();
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  toggleVisibility() {
    this.props.toggleDrawer();
  }

  selectItem(item) {
    this.props.onClick(item);
  }

  render() {
    const { selectedOption, isDialog, listOpen } = this.props;
    return (
      <div>
        <div
          className={listOpen ? 'container list-open' : 'container '}
          style={{ padding: isDialog ? '0 10px' : '12px 12px 0 12px' }}
        >
          <div className={`selectedValue ${listOpen ? 'hide' : 'show'}`}>
            {selectedOption ? (
              <div>
                <span className="code">{selectedOption.value}</span>
                <span className="code-name">{selectedOption.name}</span>
                <span className="toggle" onClick={this.toggleVisibility} />
              </div>
            ) : (
              <div>
                <span>tel-code</span> <span>tel-country</span>
                <span className="toggle" onClick={this.toggleVisibility} />
              </div>
            )}
          </div>
        </div>
        {listOpen && (
          <div className={listOpen ? 'open-container' : ''}>
            <div className="mt-20">
              {selectedOption && listOpen ? (
                <div className="selectedValue">
                  <span className="code">{selectedOption.value}</span>
                  <span className="code-name">{selectedOption.name}</span>
                  <span className="toggle expand" onClick={this.toggleVisibility} />
                </div>
              ) : listOpen ? (
                <div className="selectedValue">
                  <span>tel-code</span> <span>tel-country</span>
                  <span className="toggle expand" onClick={this.toggleVisibility} />
                </div>
              ) : null}
              <div className={listOpen ? 'option-box open' : 'option-box close'}>
                {COUNTRIES_TEL_CODE.map((item, i) => {
                  const isActive = item.name === selectedOption.name;
                  return (
                    <div
                      key={`${i}-custom-option`}
                      className={isActive ? 'option selected' : 'option '}
                      onClick={() => this.selectItem(item)}
                    >
                      <span className="code"> {item.value}</span>{' '}
                      <span className={isActive ? 'label code' : 'label'}>{item.name}</span>
                      <span className={isActive ? 'selected' : ''} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CustomSelect;
