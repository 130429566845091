import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getValueText } from './internals/BarItem';
import { getRelevantFilters } from './helpers';
import { getFiltersDefinition } from './FiltersDefinition';

function AdvancedFiltersInfo(props) {
  const { filters, customFilters } = props;

  const groupBy = function(array, key) {
    const keys = _.uniq(array.map(i => i[key]));
    const ret = keys.map(k => {
      const filters = getFiltersDefinition(customFilters);
      const filterDefinition = customFilters ? customFilters[k] : filters[k];

      return {
        group: filterDefinition,
        items: array.filter(i => i[key] === k),
      };
    });
    return ret;
  };

  const getStatusRepresentants = () => {
    const filtersGrouped = groupBy(getRelevantFilters(filters), 'group');

    return filtersGrouped.map(g => (
      <div className="bar-item">
        <div className="item-label">
          <div>{g.group.text}:</div>
          {g.group.itemRender
            ? g.items.map(i => g.group.itemRender(i))
            : g.items.map(i => getValueText(i.value, g.group.group)).join(', ')}
        </div>
      </div>
    ));
  };
  const reps = getStatusRepresentants();

  return reps.length !== 0 ? reps : <React.Fragment />;
}

const mapStateToProps = state => {
  return {
    filters: state.entities.advancedFilters.filters.items,
    isFiltersInitialized: state.entities.advancedFilters.filters.isInitalized,
  };
};

export default connect(mapStateToProps, null)(AdvancedFiltersInfo);
