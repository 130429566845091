export const X_AXIS_HEIGHT = 60;
export const X_AXIS_CHART_HEIGHT = X_AXIS_HEIGHT + 3;

export const X_AXIS_HEIGHT_TINY = 22;
export const X_AXIS_CHART_HEIGHT_TINY = X_AXIS_HEIGHT_TINY + 3;

export const X_AXIS_PADDING = 6;
export const Y_AXIS_LEFT_WIDTH = 80;
export const Y_AXIS_RIGHT_WIDTH = 80;

export const Y_AXIS_WIDTH_TINY = 37;

export const OVERLAY_CHART_HEIGHT = 100;
export const BAD_METRIC_DOT_COLOR = '#DA547D';
// const GRAPH_SLICES_BK = "#fff";

export const graphValidTakeDotColor = '#75b333';
export const graphUnscheduledTakeDotColor = '#f9a452';
export const graphMissedTakeDotColor = '#db537e';
export const graphValidTakeBarColor = '#d5e8c2';

export const NOTIFICATIONS_COLORS = {
  SCHEDULE_MISSED_DOSE_SMS: '#db537e',
  SCHEDULE_LATE_REMINDER_SMS: '#B4DE2C',
  SCHEDULE_ON_TIME_REMINDER_SMS: '#35B779',
  SCHEDULE_EARLY_REMINDER_SMS: '#25828E',
};

export const HR_DISTRIBUTON_RESOLUTION = 5;
