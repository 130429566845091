import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import styles from './ButtonRow.module.scss';

export class ButtonRow extends PureComponent {
  render() {
    const { full, left, right } = this.props;

    let inner;

    if (full) {
      inner = full;
    } else {
      inner = (
        <React.Fragment>
          <div className={styles.left}>{left}</div>
          <div className={styles.right}>{right}</div>
        </React.Fragment>
      );
    }

    return <div className={styles.ButtonRow}>{inner}</div>;
  }
}

ButtonRow.propTypes = {
  full: PropTypes.any,
  left: PropTypes.any,
  right: PropTypes.any,
};
