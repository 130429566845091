import moment from 'moment-timezone';

import { EMAIL_PATTERN } from '../../constants';
import Strings from '../../Strings';
import { EMAIL_MAX_LENGTH, FIRST_NAME_MAX_LENGTH, LAST_NAME_MAX_LENGTH } from './fieldConstraints';
import { supportedLanguages } from '../language';

const allTimeZones = moment.tz.names();

export const validateRpmPatient = (patient, conditions, facilities, strict = true) => {
  const errors = validateFirstName(patient.firstName)
    .concat(validateLastName(patient.lastName))
    .concat(validateMRN(patient.mrn))
    .concat(validatePhoneNo(patient.primaryPhoneNo, 'primaryPhoneNo', strict))
    .concat(validatePhoneNo(patient.textPhoneNo, 'textPhoneNo', strict))
    .concat(validateEmail(patient.email, strict))
    .concat(validateAddress(patient.homeAddress))
    .concat(validateDOB(patient.dateOfBirth))
    .concat(validateGender(patient.gender))
    .concat(validateLanguage(patient.language))
    .concat(validateTimezone(patient.timezone))
    .concat(validateTransplantDetails(patient.transplantDetails))
    .concat(validateInsurance(patient.insurance))
    .concat(validateConditions(patient.conditions, conditions, !strict))
    .concat(validateFacility(patient.facilityId, facilities, !strict))
    .concat(validateCenterDetails(patient.centerDetails));

  return errors;
};

export function removeNonErrors(errors) {
  return errors.filter(e => e.missing || e.errors?.length > 0);
}

export function validateFirstName(firstName) {
  const ret = { property: 'firstName', errors: [], missing: false };

  if (!firstName) {
    ret.missing = true;
  } else if (firstName.length > FIRST_NAME_MAX_LENGTH)
    ret.errors.push(Strings.formatString(Strings.capPatient.wrongFieldLengthFormat, FIRST_NAME_MAX_LENGTH));

  return removeNonErrors([ret]);
}

export function validateLastName(lastName) {
  const ret = { property: 'lastName', errors: [], missing: false };

  if (!lastName) {
    ret.missing = true;
  } else if (lastName.length > LAST_NAME_MAX_LENGTH)
    ret.errors.push(Strings.formatString(Strings.capPatient.wrongFieldLengthFormat, LAST_NAME_MAX_LENGTH));

  return removeNonErrors([ret]);
}

export function validateMRN(mrn) {
  const ret = { property: 'mrn', errors: [], missing: false };
  if (!mrn) {
    ret.missing = true;
  }
  return removeNonErrors([ret]);
}

const phoneNoPattern = new RegExp(/^(\+\d+)?\d\d\d\d\d\d\d\d\d\d/);
export function validatePhoneNo(number, field, isRequired) {
  const ret = { property: field, errors: [], missing: false };

  if (!number) {
    ret.missing = isRequired;
  } else if (!phoneNoPattern.test(number))
    ret.errors.push(Strings.formatString(Strings.capPatient.incorrectFieldFormat));

  return removeNonErrors([ret]);
}

export function validateEmail(email, isRequired) {
  const ret = { property: 'email', errors: [], missing: false };

  if (!email) {
    ret.missing = isRequired;
  } else {
    if (!EMAIL_PATTERN.test(email)) ret.errors.push(Strings.formatString(Strings.capPatient.incorrectFieldFormat));
    if (email?.length > EMAIL_MAX_LENGTH)
      ret.errors.push(Strings.formatString(Strings.capPatient.wrongFieldLengthFormat, EMAIL_MAX_LENGTH));
  }

  return removeNonErrors([ret]);
}

export function validateAddress(address) {
  const ret = { property: 'homeAddress', errors: [], missing: false };
  if (!address) {
    ret.missing = true;
  }
  return removeNonErrors([ret]);
}

export function validateDOB(dob) {
  const ret = { property: 'dateOfBirth', errors: [], missing: false };
  if (!dob) {
    ret.missing = true;
  } else {
    if (!moment(dob).isValid()) {
      ret.errors.push(Strings.formatString(Strings.capPatient.incorrectFieldFormat));
    }
    if (moment(dob).unix() > moment().unix()) {
      ret.errors.push(Strings.formatString(Strings.errors.dateInTheFuture));
    }
    if (moment(dob).year() < 1000) {
      ret.errors.push(Strings.formatString(Strings.capPatient.incorrectFieldFormat));
    }
  }

  return removeNonErrors([ret]);
}

export function validateTimezone(timezone) {
  const ret = { property: 'timezone', errors: [], missing: false };
  if (timezone && !allTimeZones.includes(timezone))
    ret.errors.push(Strings.formatString(Strings.capPatient.incorrectFieldValue, timezone));
  return removeNonErrors([ret]);
}

export function validateGender(gender) {
  const ret = { property: 'gender', errors: [], missing: false };

  if (!gender) {
    ret.missing = true;
  } else if (!Object.values(Strings.genders).includes(gender)) {
    ret.errors.push(Strings.formatString(Strings.capPatient.incorrectFieldValue, gender));
  }

  return removeNonErrors([ret]);
}

export function validateLanguage(language) {
  const ret = { property: 'language', errors: [], missing: false };

  if (language) {
    if (!supportedLanguages.map(lg => lg.id).includes(language.toLowerCase())) {
      ret.errors.push(Strings.formatString(Strings.capPatient.incorrectFieldValue, language));
    }
  }

  return removeNonErrors([ret]);
}

export function validateTransplantDetails(transplantDetails) {
  const ret = [];

  if (!transplantDetails) {
    return ret;
  }

  for (let i = 0; i < transplantDetails.length; i += 1) {
    if (transplantDetails[i].transplantDate && !moment(transplantDetails[i].transplantDate).isValid()) {
      ret.push({
        property: 'transplantDetails',
        index: i,
        field: 'transplantDate',
        errors: [Strings.formatString(Strings.capPatient.incorrectFieldFormat)],
        missing: false,
      });
    } else if (moment(transplantDetails[i].transplantDate).unix() > moment().unix()) {
      ret.push({
        property: 'transplantDetails',
        index: i,
        field: 'transplantDate',
        errors: [Strings.formatString(Strings.errors.dateInTheFuture)],
        missing: false,
      });
    }
  }
  return removeNonErrors(ret);
}

export function validateInsurance(insurance) {
  const ret = [];
  if (!insurance) {
    return ret;
  }

  for (let i = 0; i < insurance.length; i += 1) {
    if (insurance[i].groupNumber && !/^\d+$/.test(insurance[i].groupNumber)) {
      ret.push({
        property: 'insurance',
        index: i,
        field: 'groupNumber',
        errors: [Strings.formatString(Strings.capPatient.incorrectFieldFormat)],
        missing: false,
      });
    }
  }
  return removeNonErrors(ret);
}

export function validateConditions(patientConditions, orgConditions, skipValidation) {
  if (skipValidation) {
    return [];
  }
  if (!patientConditions || patientConditions.length === 0 || !orgConditions || orgConditions.length === 0) {
    return [
      {
        property: 'conditions',
        index: 0,
        errors: [Strings.capPatient.missingMedicalHistory],
        missing: true,
      },
    ];
  }

  const ret = [];
  patientConditions.forEach(c => {
    ret.push({
      property: 'conditions',
      index: 0,
      errors: validateConditionId(c, orgConditions),
      missing: false,
    });
  });
  return removeNonErrors(ret);
}

export function validateConditionId(condition, conditions) {
  const errors = [];
  if (condition && !conditions.some(d => d.id === condition || d.code?.toLowerCase() === condition?.toLowerCase()))
    errors.push(Strings.formatString(Strings.capPatient.incorrectFieldValue, condition));
  return errors;
}

export function validateFacility(facilityId, facilities, skipValidation) {
  const ret = { property: 'facility', errors: [], missing: false };
  if (skipValidation) {
    return removeNonErrors([ret]);
  }

  if (!facilityId || !facilities || facilities.length === 0) {
    ret.missing = true;
  }
  if (facilityId && !facilities.some(d => d.id === facilityId)) {
    ret.errors.push(Strings.formatString(Strings.capPatient.incorrectFieldValue, facilityId));
  }
  return removeNonErrors([ret]);
}

export function validateCenterDetails(centerDetails) {
  const ret = [];

  if (!centerDetails) {
    return ret;
  }

  for (let i = 0; i < centerDetails.length; i += 1) {
    if (centerDetails[i].clinicContact && !phoneNoPattern.test(centerDetails[i].clinicContact)) {
      ret.push({
        property: 'centerDetails',
        index: i,
        field: 'clinicContact',
        errors: [Strings.formatString(Strings.capPatient.incorrectFieldFormat)],
        missing: false,
      });
    }
  }
  return removeNonErrors(ret);
}
