import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import GenericLineGraph from './GenericLineGraph';
import Strings from '../../Strings';

export default class DeviceReadingsGraph extends PureComponent {
  render() {
    const dataSeries = [
      {
        name: 'Hubs',
        color: '#4EB067',
        selector: item => item.hubs,
      },
      {
        name: 'Scales',
        color: '#0060AA',
        selector: item => item.scales,
      },
      {
        name: 'BP Monitors',
        color: '#F9A452',
        selector: item => item.bpMonitors,
      },
    ];

    return (
      <GenericLineGraph
        graphData={this.props.graphData}
        hasBorder={false}
        forceCondensed
        xAxisTopPosition={false}
        xAxisLabelsMinMaxOnly
        yAxisWidth={40}
        yAxisUnit=""
        tooltipTitle={Strings.deviceReadings}
        tooltipDateFormat="LL"
        dataSeries={dataSeries}
        dateSelector={item => item.date}
        hasReferenceAreas={false}
      />
    );
  }
}

DeviceReadingsGraph.propTypes = { graphData: PropTypes.array };
