import {
  WIDGET_GET_PENDING_CALLS_MESSAGES,
  WIDGET_GET_PENDING_CALLS_MESSAGES_RESULT,
  WIDGET_GET_PENDING_CALLS_MESSAGES_ERROR,
} from './constants';

export const widgetPendingCallsMessagesReducer = (state = {}, action) => {
  switch (action.type) {
    case WIDGET_GET_PENDING_CALLS_MESSAGES:
      return {
        ...state,
        isLoading: true,
        isError: false,
        pendingCalls: 0,
        pendingMessages: 0,
      };
    case WIDGET_GET_PENDING_CALLS_MESSAGES_RESULT:
      return {
        ...state,
        isLoading: false,
        isError: false,
        pendingCalls: action.response?.pendingCalls,
        pendingMessages: action.response?.pendingMessages,
      };

    case WIDGET_GET_PENDING_CALLS_MESSAGES_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        pendingCalls: undefined,
        pendingMessages: undefined,
      };
    }
    default:
      return state;
  }
};
