import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { DivWithTooltip } from '../../utils';

class Switch extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const switchName = props.name;

    this.state = { [switchName]: props.flag };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const switchName = newProps.name;
    const newState = { [switchName]: newProps.flag };

    this.setState(newState);
  }

  onChange = event => {
    const switchName = event.target.getAttribute('name');
    const active = event.target.checked;

    const result = this.props.onChange({ [switchName]: active });

    if (result === false) {
      event.preventDefault();
    } else {
      this.setState({ [switchName]: active });
    }
  };

  getTooltip = () => {
    const { name, tooltip } = this.props;
    if (typeof tooltip === 'string') return tooltip;
    if (tooltip.length === 1) return tooltip[0];
    const value = this.state[name];
    return tooltip[value ? 1 : 0];
  };

  render() {
    const { tooltip, name, refLink, disabled = false } = this.props;

    const divProps = { className: 'checkboxes-and-radios' };

    const input = (
      <React.Fragment>
        <input
          type="checkbox"
          name={name}
          id={name}
          value={name}
          checked={this.state[name]}
          onChange={this.onChange}
          disabled={disabled}
          ref={refLink}
        />
        <label htmlFor={name} />
      </React.Fragment>
    );

    if (!tooltip) {
      return <div {...divProps}>{input}</div>;
    }

    return (
      <DivWithTooltip tt-id={`tt-${name}`} tt-text={this.getTooltip} {...divProps}>
        {input}
      </DivWithTooltip>
    );
  }
}

export default Switch;
