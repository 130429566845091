import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { openModalAction } from '../../../../actions/modal';
import Strings from '../../../../Strings';
import DashboardLayout from '../../../Dashboards/DashboardLayout';
import { actions } from '../redux/actions';
import { patientAction } from '../../../../actions/patient';
import { GET_CAPS_WITHOUT_SCHEDULE_RESULT } from '../redux/constants';
import MedicationWidget from './MedicationWidget';
import AdherenceGraph from '../AdherenceGraph';
import { UPDATE_PATIENT_SCHEDULE } from '../../../../actions/action-types';
import { getProgramsFromPatient } from '../../../../utils/cmsPrograms';
import { PERMISSIONS, hasPermission } from '../../../../utils/userPermissions';

export const EMPTY_DASHBOARD_ID = 'dashboardPatientMedicationsEmpty';
const addMedicationMetadata = {
  id: 'MedicationWidget_addMed',
  name: Strings.addMedicationText,
  permission: PERMISSIONS.PATIENTS_RPM_PATIENT_MEDICATIONS_EDIT,
  defProps: { i: 'MedicationWidget_addMed', w: 1, h: 1, minW: 1, minH: 1 },
};
export const EmptyDashboadWigetMetadata = [addMedicationMetadata];
export const EmptyDashboadDefaultLayouts = {
  l: [{ ...addMedicationMetadata.defProps, x: 0, y: 0, w: 1, h: 1 }],
  m: [{ ...addMedicationMetadata.defProps, x: 0, y: 0, w: 1, h: 1 }],
  s: [{ ...addMedicationMetadata.defProps, x: 0, y: 0, w: 1, h: 1 }],
};

export function getWidgetsMetadata(meds) {
  const ret = [addMedicationMetadata].concat(
    meds && meds.length > 0
      ? meds.map(medication => {
          return getWidgetMetadataForMed(medication);
        })
      : [],
  );
  return ret;
}

function getWidgetMetadataForMed(medication) {
  return {
    id: `MedicationWidget_${medication.rootId}`,
    name: medication.medicationName,
    permission: '',
    defProps: {
      i: `MedicationWidget_${medication.rootId}`,
      w: 1,
      h: 1,
      minW: 1,
      minH: 1,
      maxW: 3,
      maxH: 1,
    },
  };
}

const L_WIDGET_COUNT = 3;
const M_WIDGET_COUNT = 2;
const S_WIDGET_COUNT = 1;

export function getDefaultLayouts(meds, permissions) {
  const widgets = getWidgetsMetadata(meds);
  const l = [];
  const m = [];
  const s = [];

  widgets
    .filter(w => !w.permission || permissions?.includes(w.permission))
    .forEach((w, i) => {
      l.push({ ...w.defProps, x: i % L_WIDGET_COUNT, y: Math.floor(i / L_WIDGET_COUNT), w: 1, h: 1 });
      m.push({ ...w.defProps, x: i % M_WIDGET_COUNT, y: Math.floor(i / M_WIDGET_COUNT), w: 1, h: 1 });
      s.push({ ...w.defProps, x: i % S_WIDGET_COUNT, y: Math.floor(i / S_WIDGET_COUNT), w: 1, h: 1 });
    });
  return { l, m, s };
}

export const PATIENT_MEDICATION_DASHBOARD_ID_PREFIX = 'dashboardPatientMedications1_';

function PatientMedications(props) {
  const { patientMedications, isLoading } = props;
  const patientId = props.patientId || props.userId;

  const DASHBOARD_ID = `${PATIENT_MEDICATION_DASHBOARD_ID_PREFIX}${patientId}`;
  const canEdit = hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_MEDICATIONS_EDIT);

  const [medications, setMedications] = useState();
  const [capsWithoutSchedule, setCapsWithoutSchedule] = useState([]);
  const scrollbarRef = React.useRef();

  useEffect(() => {
    props.getMedications(patientId);
    if (!props.patientDetails) {
      props.getPatientInfo(patientId);
    }
    if (!props.patientVersion) {
      getCaps();
    }
  }, [patientId, props.refreshTimestamp]);

  useEffect(() => {
    if (_.isEqual(patientMedications, medications)) {
      return;
    }

    setMedications(patientMedications);
    if (patientMedications?.length > 0 && !props.patientVersion) {
      getCaps();
    }
  }, [patientMedications]);

  const getCaps = () => {
    props.getCaps(patientId).then(r => {
      if (r.type === GET_CAPS_WITHOUT_SCHEDULE_RESULT) {
        const caps = r.response;
        if (caps) {
          setCapsWithoutSchedule(caps);
        }
      }
    });
  };

  const widgets =
    patientMedications && patientMedications.length > 0
      ? patientMedications
          .sort(m => m.rootId)
          .map(medication => {
            const widgetMetadata = getWidgetMetadataForMed(medication);
            const widget = {
              ...widgetMetadata,
              render: (
                <MedicationWidget
                  schedule={medication}
                  devices={capsWithoutSchedule}
                  patient={props.patientDetails}
                  patientEnrollments={getProgramsFromPatient(props.patientDetails, true)}
                  hideActions={!canEdit}
                  simplifiedVersion={props.patientVersion}
                />
              ),
              noPadding: true,
            };

            return widget;
          })
      : [];

  if (canEdit) {
    widgets.push({
      ...addMedicationMetadata,
      render: (
        <div className="widget-scrollable no-stripes">
          <table className="table patient-widget">
            <thead className="table-header">
              <tr>
                <th>
                  <a>{Strings.addMedicationText}</a>
                </th>
              </tr>
            </thead>
          </table>
          <div className="plus-box">
            <div className="plus-border">
              <div className="plus" onClick={() => props.onAddMedicine(props.patientDetails)} />
            </div>
          </div>
        </div>
      ),
      noPadding: true,
    });
  }

  const breakpoints = {
    l: 1400,
    m: 1151,
    s: 0,
  };
  const columns = {
    l: L_WIDGET_COUNT,
    m: M_WIDGET_COUNT,
    s: S_WIDGET_COUNT,
  };

  return (
    <OverlayScrollbarsComponent
      defer
      className="scrollbar-right-margin"
      style={{ paddingTop: props.patientVersion ? 0 : 20, height: '100%' }}
      options={{ scrollbars: { autoHide: 'leave', autoHideDelay: '100' } }}
      ref={scrollbarRef}
    >
      <div className="medication-details-page">
        {props.patientVersion && <h2>{Strings.yourMedications}</h2>}
        {widgets.length > 0 && !isLoading && (
          <DashboardLayout
            dashboardId={widgets.length > 1 || !canEdit ? DASHBOARD_ID : EMPTY_DASHBOARD_ID}
            widgets={widgets}
            defaultLayouts={
              widgets.length > 1 || !canEdit
                ? getDefaultLayouts(patientMedications, props.permissions)
                : EmptyDashboadDefaultLayouts
            }
            breakpoints={breakpoints}
            rowHeight={props.patientVersion ? 312 : 512}
            cols={columns}
            additionalData={patientMedications}
            isReadOnly={props.patientVersion}
            scrollbarRef={scrollbarRef}
          />
        )}
        <div className="patient-medications-chart">
          {!props.patientVersion && <AdherenceGraph includeSchedulesWithNoCap patientId={patientId} />}
        </div>
      </div>
    </OverlayScrollbarsComponent>
  );
}

PatientMedications.propTypes = {
  getMedications: PropTypes.func,
  isLoading: PropTypes.any,
  onAddMedicine: PropTypes.func,
  getCaps: PropTypes.func,
  patientDetails: PropTypes.any,
  patientId: PropTypes.any,
  patientMedications: PropTypes.array,
  patientVersion: PropTypes.bool,
  refreshTimestamp: PropTypes.any,
};

PatientMedications.defaultProps = { patientVersion: false };

const mapStateToProps = state => {
  const { patients } = state.superUser;
  return {
    patientMedications: patients?.patientMedications?.medications,
    pagination: patients?.patientMedications?.pagination,
    isLoading: patients?.isPatientMedicationsLoading,
    patientDetails: patients?.patientDetails,
    userId: state.auth.profile?.id,
    permissions: state.auth?.permissions,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getMedications: patientId => dispatch(actions.getMedications(patientId)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  onAddMedicine: patient =>
    dispatch(
      openModalAction('edit-rpm-schedule', {
        patient,
        action: patientAction.actionUpdateSchedule,
        actionType: UPDATE_PATIENT_SCHEDULE,
        onSuccess: () => dispatch(actions.getMedications(patient.id)),
      }),
    ),
  getCaps: patientId => dispatch(actions.getCapsWithoutSchedule(patientId)),
  getPatientInfo: patientId => dispatch(actions.getPatientInfo(patientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientMedications);
