import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { MED_ICON_COLOR_FILTER } from '../../constants';
import { getMedIcon } from '../../utils/medIcons';
import { RowImage } from '../ESI/PatientDashboard/RowImage';
import style from './index.module.scss';

export function Pill({ med }) {
  let format = med?.format;

  if (typeof format === 'string') {
    format = JSON.parse(format);
  }

  return (
    <div className={style.pill}>
      <RowImage image={getMedIcon(format?.route)} style={{ filter: MED_ICON_COLOR_FILTER }} />
      <div className={style.title}>{med.name}</div>
      <div
        className={cn(
          style.status,
          med.status === 'taken' ? style.taken : null,
          med.status === 'upcoming' ? style.upcoming : null,
          med.status === 'missed' ? style.missed : null,
        )}
      >
        {med.status}
      </div>
    </div>
  );
}

Pill.propTypes = {
  med: PropTypes.shape({
    format: PropTypes.shape({ route: PropTypes.any }),
    name: PropTypes.any,
    status: PropTypes.string,
  }),
};
