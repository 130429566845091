import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '../containers';
import { closeModal } from '../actions/modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Strings from '../Strings';
import { notificationActions } from '../components/Notification/redux/actions';

import '../pages/SuperUser/ExternalApi/ExternalApi.scss';

function ExternalApiModal(props) {
  return (
    <Modal caption="Credentials" {...props} additionalClasses={['form-modal', 'user-invite']}>
      <div className="external-api-modal-text-container">
        <div className="client-value-label">Client Id: {props.data.credentials.clientId}</div>
        <div className="client-value-label">Client Secret: {props.data.credentials.clientSecret}</div>
        <div className="notice">{Strings.noticeSaveCredentials}</div>
      </div>
      <div className="button-bar reverse extend-button" key="button-bar">
        <button className="brand-blue" key="button" type="button" onClick={props.onCancel}>
          {Strings.close}
        </button>
        <CopyToClipboard text={JSON.stringify(props.data.credentials, null, 2)}>
          <button
            className="brand-blue"
            key="button"
            type="button"
            onClick={() => props.showNotification(Strings.credentialsCopiedToClipboard)}
          >
            {Strings.copyToClipboard}
          </button>
        </CopyToClipboard>
      </div>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal(ownProps.name)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
});

export default connect(null, mapDispatchToProps)(ExternalApiModal);
