import { PAGE_LIMIT } from '../../../../../constants';
import { API_CALL } from '../../../../../middleware/api';
import {
  GET_COHORTS,
  EDIT_COHORT,
  CREATE_COHORT,
  GET_COHORT_DETAILS,
  DELETE_COHORT,
  ASSIGN_PATIENT_TO_COHORT,
  UNASSIGN_PATIENT_FROM_COHORT,
  GET_CONDITION_LIST,
  DELETE_CONDITION,
  CREATE_CONDITION,
  EDIT_CONDITION,
  GET_FACILITIES,
  DELETE_FACILITY,
  CREATE_FACILITY,
  EDIT_FACILITY,
  ASSIGN_PATIENT_TO_FACILITY,
  GET_IDC_CONDITION_LIST,
} from './constants';

export const actions = {
  getCohorts: pageRequest => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/summary`,
      method: 'get',
      params: { ...pageRequest },
    },
    type: GET_COHORTS,
  }),
  createCohort: data => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/create`,
      method: 'post',
      data,
    },
    type: CREATE_COHORT,
  }),
  editCohort: (cohortId, data) => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/cohort/${cohortId}/edit`,
      method: 'put',
      data,
    },
    type: EDIT_COHORT,
  }),
  deleteCohort: cohortId => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/cohort/${cohortId}/delete`,
      method: 'delete',
    },
    type: DELETE_COHORT,
  }),
  getCohortDetails: cohortId => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/cohort/${cohortId}/details`,
      method: 'get',
    },
    type: GET_COHORT_DETAILS,
  }),
  assignPatientToCohort: (patientId, cohortId) => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/cohort/${cohortId}/assign`,
      data: { userId: patientId },
      method: 'put',
    },
    type: ASSIGN_PATIENT_TO_COHORT,
  }),
  unassignPatientFromCohort: (patientId, cohortId) => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/cohort/${cohortId}/unassign`,
      data: { userId: patientId },
      method: 'put',
    },
    type: UNASSIGN_PATIENT_FROM_COHORT,
  }),
  getConditions: () => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/conditions/summary`,
      method: 'get',
      params: { limit: 1000000 },
    },
    type: GET_CONDITION_LIST,
  }),
  deleteCondition: id => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/conditions/condition/${id}/delete`,
      method: 'delete',
    },
    type: DELETE_CONDITION,
  }),
  createCondition: data => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/conditions/create`,
      method: 'post',
      data,
    },
    type: CREATE_CONDITION,
  }),
  editCondition: (id, data) => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/conditions/condition/${id}/edit`,
      method: 'put',
      data,
    },
    type: EDIT_CONDITION,
  }),
  getFacilities: () => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/facilities/summary`,
      method: 'get',
      params: { limit: 1000000 },
    },
    type: GET_FACILITIES,
  }),
  deleteFacility: id => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/facilities/facility/${id}/delete`,
      method: 'delete',
    },
    type: DELETE_FACILITY,
  }),
  createFacility: data => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/facilities/create`,
      method: 'post',
      data,
    },
    type: CREATE_FACILITY,
  }),
  editFacility: (id, data) => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/facilities/facility/${id}/edit`,
      method: 'put',
      data,
    },
    type: EDIT_FACILITY,
  }),
  assignPatientToFacility: (patientId, facilityId) => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/facilities/facility/${facilityId}/assign`,
      data: { patientId },
      method: 'put',
    },
    type: ASSIGN_PATIENT_TO_FACILITY,
  }),
  getIdcConditions: pageRequest => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/conditions/icd10cm`,
      method: 'get',
      params: { ...pageRequest, limit: 100 },
    },
    type: GET_IDC_CONDITION_LIST,
  }),
};
