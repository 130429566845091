import {
  LIST_PATIENT,
  LIST_PATIENT_RESULT,
  POST_ADD_CAREGIVER,
  POST_ADD_CAREGIVER_RESULT,
  POST_ADD_CAREGIVER_ERROR,
  CLEAR_ENTITIES,
  START_LOADING,
  TRIAL_DELETE_PATIENT_RESULT,
  SEND_INVITE,
  SEND_INVITE_RESULT,
  SEND_INVITE_ERROR,
  DELETE_PATIENT_RESULT,
  POST_ADD_MEDICINE_RESULT,
  POST_ADD_MEDICINE_ERROR,
  POST_ADD_MEDICINE,
  LIST_PATIENT_ERROR,
  TRIAL_DELETE_PATIENT,
  TRIAL_DELETE_PATIENT_ERROR,
} from '../actions/action-types';

const patientsReducer = (newState = { pagination: {} }, action) => {
  const { response } = action;

  switch (action.type) {
    case LIST_PATIENT: {
      const tempPatients = newState.patients;
      if (tempPatients.length > 0) {
        const patients = tempPatients.map(user => {
          const d = user;
          d.isLoading = true;
          return d;
        });
        return {
          ...newState,
          patients,
          loading: action.isLoading,
          preparing: true,
          isUpdate: false,
        };
      }
      return {
        ...newState,
        loading: action.isLoading,
        preparing: true,
        isUpdate: false,
      };
    }
    case START_LOADING:
      return {
        ...newState,
        loading: true,
      };
    case LIST_PATIENT_RESULT:
      return {
        ...newState,
        patients: response.patients,
        patient: response.patients.reduce((res, item) => {
          res[item.id] = item;
          return res;
        }, {}),
        pagination: response.pagination,
        loading: false,
        preparing: false,
        isUpdate: false,
      };

    case LIST_PATIENT_ERROR:
      return {
        ...newState,
        loading: false,
        error: false,
      };

    case DELETE_PATIENT_RESULT:
      if (action.response && action.response.patient) {
        const { patient } = action.response;
        const { patients } = newState;

        const updatedPatients = patients.filter(user => {
          return user.id !== patient;
        });
        return {
          ...newState,
          patients: updatedPatients,
          loading: false,
        };
      }
      return newState;

    case TRIAL_DELETE_PATIENT: {
      const tempPatients = newState.patients;
      if (tempPatients.length > 0) {
        const { patientId } = action;
        const patients = tempPatients.map(user => {
          const d = user;
          if (user.code === patientId) {
            d.isLoading = true;
          }
          return d;
        });
        return {
          ...newState,
          patients,
          loading: false,
        };
      }
      return {
        ...newState,
        loading: false,
      };
    }

    case TRIAL_DELETE_PATIENT_RESULT:
      if (action.response && action.response.trial) {
        const { patient } = action.response;
        const { patients } = newState;

        const updatedPatients = patients.filter(user => {
          return user.id !== patient;
        });

        const tempPatients = updatedPatients.map(user => {
          const d = user;
          d.isLoading = false;
          return d;
        });

        return {
          ...newState,
          patients: tempPatients,
          loading: false,
        };
      }
      return newState;

    case TRIAL_DELETE_PATIENT_ERROR: {
      const tempPatients = newState.patients;
      if (tempPatients.length > 0) {
        const patients = tempPatients.map(user => {
          const d = user;
          d.isLoading = false;
          return d;
        });
        return {
          ...newState,
          patients,
          loading: false,
        };
      }
      return {
        ...newState,
        loading: false,
      };
    }

    case CLEAR_ENTITIES:
      if (action.entities && action.entities.indexOf('patients') !== -1) {
        return {
          ...newState,
          patients: {},
          preparing: true,
          loading: true,
        };
      }
      return newState;

    case POST_ADD_MEDICINE:
      return {
        ...newState,
        loading: true,
        preparing: true,
        isUpdate: false,
      };

    case POST_ADD_MEDICINE_RESULT:
      return {
        ...newState,
        loading: false,
        preparing: false,
        isUpdate: false,
      };

    case POST_ADD_MEDICINE_ERROR:
      return {
        ...newState,
        AddMedicineModalLoading: false,
      };

    case POST_ADD_CAREGIVER:
      return {
        ...newState,
        addCaregiverLoading: true,
      };

    case POST_ADD_CAREGIVER_RESULT:
      // newState.objects[action.response.patient_id].caregivers.push(
      //   action.response
      // );

      return {
        ...newState,
        addCaregiverLoading: false,
      };
    case POST_ADD_CAREGIVER_ERROR:
      return {
        ...newState,
        addCaregiverLoading: false,
      };

    case SEND_INVITE:
      return {
        ...newState,
        loading: true,
      };
    case SEND_INVITE_RESULT:
      return {
        ...newState,
        ...action.response,
        result: 'success',
        isUpdate: true,
        loading: false,
      };
    case SEND_INVITE_ERROR: {
      return {
        ...newState,
        result: 'error',
        message: action.response.data,
        loading: false,
      };
    }

    default:
      return newState;
  }
};

export default patientsReducer;
