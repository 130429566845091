import { DELETE_PATIENT } from '../../../../actions/action-types';
import { PAGE_LIMIT } from '../../../../constants';
import { API_CALL } from '../../../../middleware/api';
import {
  ADD_MEDICINE,
  ADD_PATIENT,
  ADD_SCHEDULE,
  ASSIGN_CAPS_TO_THE_SCHEDULE,
  CLEAR_DEVICE_DATA,
  DELETE_CAP,
  DELETE_CAP_FROM_SCHEDULE,
  DELETE_MEDICATION,
  DELETE_SCHEDULE,
  DELETE_TASK_HISTORY,
  EDIT_PATIENT,
  EDIT_SCHEDULE,
  END_TASK,
  EVENT_LIST,
  GET_CAPS,
  GET_CAP_DETAILS,
  GET_DEVICES,
  GET_DEVICES_DETAILS,
  GET_MEDICATIONS,
  GET_TASKS_LIST,
  PATIENT_DETAILS,
  PATIENT_DASHBOARD_INFO,
  PATIENT_LIST,
  SCHEDULE_LIST_CLEAR,
  START_TASK,
  TASK_HISTORY,
  UPDATE_TASKS,
  ADD_NOTE,
  GET_NOTES,
  EDIT_NOTE,
  PATIENT_BILLABLE_TIME,
  PATIENT_LIST_FOR_SUGGESTION,
  PATIENT_LIST_FOR_SUGGESTION_CLEAR,
  PATIENT_LIST_CLEAR,
  GET_CONDITION_LIST,
  ENROLL_PATIENT,
  GET_READINGS,
  ARCHIVE_PATIENT,
  GET_SCHEDULE,
  GET_CAPS_WITHOUT_SCHEDULE,
  GET_CAP_PLOT_DATA,
  lOAD_PATIENTS,
  GET_REMINDERS_DEFAULT_MESSAGES,
  CONTINUE_TASK,
  SEND_WELCOME_MESSAGE,
  PATIENT_DETAILS_CLEAR,
  DOWNLOAD_NOTES_AND_ACTIONS,
  GET_SCHEDULE_NOTIFICATIONS,
  DOWNLOAD_KIT_DATA,
  GET_AVERAGE_READINGS,
  END_ENROLLMENT,
  INVITE_PATIENT_TO_PORTAL,
  GET_PATIENT_DEVICES,
  GET_PATIENT_ADHERENCE,
  GET_UNBILLED_EVIDENCES,
  DOWNLOAD_PATIENTS_EVENTS,
} from './constants';

export const actions = {
  updateTask: data => ({
    [API_CALL]: {
      endpoint: '/billing/work_log/edit',
      method: 'PUT',
      data,
    },
    type: UPDATE_TASKS,
  }),

  getPatients: pageRequest => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/patients/list',
      params: { ...pageRequest, limit: PAGE_LIMIT },
    },
    type: PATIENT_LIST,
  }),
  loadPatients: pageRequest => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/patients/list',
      params: { ...pageRequest, limit: PAGE_LIMIT },
    },
    type: lOAD_PATIENTS,
  }),
  getPatientsForSuggestion: pageRequest => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/patients/list',
      params: { ...pageRequest, limit: 500 },
    },
    type: PATIENT_LIST_FOR_SUGGESTION,
  }),
  clearPatients: () => ({ type: PATIENT_LIST_CLEAR }),
  clearPatientsForSuggestion: () => ({ type: PATIENT_LIST_FOR_SUGGESTION_CLEAR }),

  getPatientEvent: (pageRequest, patientId) => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: `/patients/patient/${patientId}/events`,
      params: { ...pageRequest, limit: PAGE_LIMIT },
    },
    type: EVENT_LIST,
  }),
  getPatientInfo: patientId => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: `/patients/patient/${patientId}/`,
    },
    type: PATIENT_DETAILS,
  }),
  clearPatientInfo: () => ({ type: PATIENT_DETAILS_CLEAR }),
  getPatientDahsboardInfo: patientId => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: `/patients/${patientId}/patient-info`,
    },
    type: PATIENT_DASHBOARD_INFO,
  }),
  getPatientLastReadings: patientId => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: `/patients/${patientId}/last-readings`,
    },
    type: PATIENT_DASHBOARD_INFO,
  }),
  getPatientBillableTime: patientId => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: `/patients/patient/${patientId}/billable-time`,
    },
    type: PATIENT_BILLABLE_TIME,
  }),
  getMedications: patientId => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: `/portal/patients/${patientId}/medications`,
    },
    type: GET_MEDICATIONS,
  }),
  addMedicine: data => ({
    [API_CALL]: {
      endpoint: `/portal/schedules`,
      method: 'post',
      data,
    },
    type: ADD_MEDICINE,
  }),

  addPatient: data => ({
    [API_CALL]: {
      endpoint: `/patients/add`,
      method: 'post',
      data,
    },
    type: ADD_PATIENT,
  }),

  editPatient: (data, patientId) => ({
    [API_CALL]: {
      endpoint: `/patients/patient/${patientId}/`,
      method: 'PUT',
      data,
    },
    type: EDIT_PATIENT,
  }),

  getTaskHistory: pageRequest => ({
    [API_CALL]: {
      endpoint: `/billing/work_log`,
      method: 'get',
      params: { ...pageRequest, limit: PAGE_LIMIT },
    },
    type: TASK_HISTORY,
  }),

  getDevices: (pageRequest, patientId) => ({
    [API_CALL]: {
      endpoint: `/patients/devices/${patientId}/list`,
      method: 'get',
      params: { ...pageRequest, limit: pageRequest.limit || PAGE_LIMIT },
    },
    type: GET_DEVICES,
  }),

  getPatientDevices: (pageRequest, patientId) => ({
    [API_CALL]: {
      endpoint: `/patients/devices/${patientId}/list`,
      method: 'get',
      params: { ...pageRequest, limit: pageRequest.limit || PAGE_LIMIT },
    },
    type: GET_PATIENT_DEVICES,
  }),

  getDeviceDetails: (pageRequest, patientId, capId, deviceType, deviceId) => ({
    [API_CALL]: {
      endpoint: `/v1/hubs/${capId}/devices/${deviceId}/events`,
      method: 'get',
      params: { ...pageRequest, limit: PAGE_LIMIT },
    },
    type: GET_DEVICES_DETAILS,
  }),

  getCapDetails: (id, pageRequest) => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/esi/v1/caps/' + id,
      params: pageRequest,
    },
    type: GET_CAP_DETAILS,
  }),

  deletetPatient: (deleteData, id) => ({
    [API_CALL]: {
      endpoint: `/patients/patient/delete/`,
      method: 'POST',
      data: deleteData,
    },
    type: DELETE_PATIENT,
    id: id,
  }),

  deleteMedication: (patientId, medicationId, callAfterSuccess = []) => ({
    [API_CALL]: {
      endpoint: `/patients/patient/${patientId}/schedule/${medicationId}`,
      method: 'DELETE',
      callAfterSuccess,
    },
    type: DELETE_MEDICATION,
    id: medicationId,
  }),

  deleteTaskHistory: (data, callAfterSuccess = []) => ({
    [API_CALL]: {
      endpoint: '/billing/work_log/delete',
      method: 'PUT',
      data,
      callAfterSuccess,
    },
    type: DELETE_TASK_HISTORY,
  }),

  deleteSchedule: (deleteData, id) => ({
    [API_CALL]: {
      endpoint: `/v1/grouped/schedule/delete`,
      method: 'POST',
      data: deleteData,
    },
    type: DELETE_SCHEDULE,
    id,
  }),

  // this endpoint should not be used as it is removed from backend
  deleteCap: (deleteData, callAfterSuccess = []) => ({
    [API_CALL]: {
      endpoint: `/patients/devices/detach`,
      method: 'PUT',
      data: deleteData,
      callAfterSuccess,
    },
    type: DELETE_CAP,
    id: deleteData.capId,
  }),

  deleteScheduleCaps: deleteData => ({
    [API_CALL]: {
      endpoint: `/v1/grouped/schedule/caps/delete`,
      method: 'POST',
      data: deleteData,
    },
    type: DELETE_CAP_FROM_SCHEDULE,
    capId: deleteData.device_id,
  }),
  getCaps: request => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/devices',
      params: request,
    },
    type: GET_CAPS,
  }),

  assignCaps: data => ({
    [API_CALL]: {
      endpoint: `/v1/grouped/schedule/caps/`,
      method: 'PUT',
      data,
    },
    type: ASSIGN_CAPS_TO_THE_SCHEDULE,
  }),

  addSchedule: data => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: '/v1/grouped/schedule/',
      data,
    },
    type: ADD_SCHEDULE,
  }),

  startTask: data => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: `/billing/work_log/new`,
      data,
    },
    type: START_TASK,
  }),

  continueTask: data => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: `/billing/work_log/continue`,
      data,
    },
    type: CONTINUE_TASK,
  }),

  endTask: data => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: `/billing/work_log/stop`,
      data,
    },
    type: END_TASK,
  }),

  editSchedule: data => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: '/v1/grouped/schedule/',
      data,
    },
    type: EDIT_SCHEDULE,
  }),
  getTasks: () => ({
    [API_CALL]: {
      endpoint: `/billing/work_log/work_types`,
      method: 'get',
    },
    type: GET_TASKS_LIST,
  }),

  clearCapsDetails: () => ({
    type: SCHEDULE_LIST_CLEAR,
  }),

  clearDevice: () => ({
    type: CLEAR_DEVICE_DATA,
  }),

  getNotes: patientId => ({
    [API_CALL]: {
      endpoint: `/patients/patient/${patientId}/notes`,
      method: 'get',
    },
    type: GET_NOTES,
  }),
  addNote: (patientId, data) => ({
    [API_CALL]: {
      endpoint: `/patients/patient/${patientId}/note`,
      method: 'post',
      data,
    },
    type: ADD_NOTE,
  }),
  editNote: (noteId, data) => ({
    [API_CALL]: {
      endpoint: `/patients/note/${noteId}/edit`,
      method: 'put',
      data,
    },
    type: EDIT_NOTE,
  }),
  getConditions: () => ({
    [API_CALL]: {
      endpoint: `/patients/cohorts/conditions/summary`,
      method: 'get',
      params: { limit: 1000000 },
    },
    type: GET_CONDITION_LIST,
  }),
  enrollPatient: data => ({
    [API_CALL]: {
      endpoint: '/billing/care_management/enrollment',
      method: 'PUT',
      data,
    },
    type: ENROLL_PATIENT,
  }),
  endEnrollment: data => ({
    [API_CALL]: {
      endpoint: '/billing/care_management/leave',
      method: 'PUT',
      data,
    },
    type: END_ENROLLMENT,
  }),
  getReadings: pageRequest => ({
    [API_CALL]: {
      endpoint: `/patients/multiple/last-readings`,
      method: 'get',
      params: { ...pageRequest, limit: PAGE_LIMIT },
    },
    type: GET_READINGS,
  }),
  archiveToggle: data => ({
    [API_CALL]: {
      endpoint: '/patients/multiple/last-readings-archive',
      method: 'PUT',
      data,
    },
    type: ARCHIVE_PATIENT,
  }),
  getSchedule: (patientId, scheduleId) => ({
    [API_CALL]: {
      endpoint: `/patients/patient/${patientId}/schedules/${scheduleId}/schedule_details`,
      method: 'get',
    },
    type: GET_SCHEDULE,
  }),
  getCapPlotData: (patientId, scheduleId, request) => ({
    [API_CALL]: {
      endpoint: `/patients/patient/${patientId}/schedules/${scheduleId}/plot`,
      method: 'get',
      params: { ...request },
    },
    type: GET_CAP_PLOT_DATA,
  }),
  getScheduleNotifications: (scheduleId, request) => ({
    [API_CALL]: {
      endpoint: `/reminders/schedules/${scheduleId}/history`,
      method: 'get',
      params: { ...request },
    },
    type: GET_SCHEDULE_NOTIFICATIONS,
  }),
  getCapsWithoutSchedule: patientId => ({
    [API_CALL]: {
      endpoint: `/patients/patient/${patientId}/caps_without_schedule`,
      method: 'get',
    },
    type: GET_CAPS_WITHOUT_SCHEDULE,
  }),
  getRemindersDefaultMessages: params => ({
    [API_CALL]: {
      endpoint: '/reminders/default_messages',
      method: 'get',
      params,
    },
    type: GET_REMINDERS_DEFAULT_MESSAGES,
  }),
  sendWelcomeMessage: data => ({
    [API_CALL]: {
      endpoint: '/reminders/welcome_message',
      method: 'post',
      data,
    },
    type: SEND_WELCOME_MESSAGE,
  }),
  downloadNotesAndActions: request => ({
    [API_CALL]: {
      endpoint: '/patients/notes/download',
      method: 'get',
      params: request,
    },
    type: DOWNLOAD_NOTES_AND_ACTIONS,
  }),
  downloadPatientsEvents: request => ({
    [API_CALL]: {
      endpoint: '/patients/events/csv',
      method: 'get',
      params: request,
    },
    type: DOWNLOAD_PATIENTS_EVENTS,
  }),
  downloadKitData: (patientId, request) => ({
    [API_CALL]: {
      endpoint: `/patients/patient/${patientId}/events/csv`,
      method: 'get',
      params: request,
    },
    type: DOWNLOAD_KIT_DATA,
  }),
  getAverageReadings: (patientId, dataType, request) => ({
    [API_CALL]: {
      endpoint: `/patients/${patientId}/average/${dataType}`,
      method: 'get',
      params: request,
    },
    type: GET_AVERAGE_READINGS,
  }),
  invitePatientToPortal: (patient_id, inviteMethod) => ({
    [API_CALL]: {
      endpoint: `/patients/patient/${patient_id}/portal/invite?${inviteMethod}`,
      method: 'post',
    },
    type: INVITE_PATIENT_TO_PORTAL,
  }),
  getPatientAdherence: patientId => ({
    [API_CALL]: {
      endpoint: `/portal/patients/${patientId}/daily-adherence`,
      method: 'get',
    },
    type: GET_PATIENT_ADHERENCE,
  }),
  getUnbilledEvidences: patientId => {
    return {
      [API_CALL]: {
        endpoint: `/billing/monitoring/report/rtm/unbilled-evidences`,
        method: 'get',
        params: { patientId },
      },
      type: GET_UNBILLED_EVIDENCES,
    };
  },
};
