import React from 'react';
import PropTypes from 'prop-types';

import TabView, { Tab } from '../../../components/TabView';
import Strings from '../../../Strings';
import ExternalApiAccessControl from '../ExternalApi/ExternalApiAccessControl';
import ExternalApiLogs from '../ExternalApi/ExternalApiLogs';

export const SystemConfigurationTabs = {
  ExternalApiLogs: {
    name: Strings.externalApiLogsTab,
    path: '/system-configuration/external-api-logs',
  },
  ExternalApiAccessControl: {
    name: Strings.externalApiAccessControlTab,
    path: '/system-configuration/external-api-access-control',
  },
};

function SystemConfiguration(props) {
  return (
    <React.Fragment>
      <TabView routeMode activeTab={props.activeTab}>
        <Tab
          name={SystemConfigurationTabs.ExternalApiLogs.name}
          path={SystemConfigurationTabs.ExternalApiLogs.path}
          key={SystemConfigurationTabs.ExternalApiLogs.name}
        >
          <ExternalApiLogs master />
        </Tab>
        <Tab
          name={SystemConfigurationTabs.ExternalApiAccessControl.name}
          path={SystemConfigurationTabs.ExternalApiAccessControl.path}
          key={SystemConfigurationTabs.ExternalApiAccessControl.name}
        >
          <ExternalApiAccessControl masterEditor />
        </Tab>
      </TabView>
    </React.Fragment>
  );
}

SystemConfiguration.propTypes = {
  activeTab: PropTypes.string,
};

export default SystemConfiguration;
