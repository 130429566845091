import React from 'react';
import { SwitchInput } from 'components/Inputs/SwitchInput';

import { getFieldWithComponent } from './getFieldWithComponent';

function SwitchField(props) {
  const { input, meta, ...rest } = props;
  const error = typeof rest.error === 'boolean' ? rest.error && meta.error : meta.touched && meta.error;

  input.checked = !!input.value[input.name];

  return <SwitchInput {...rest} error={error} {...input} />;
}

const Component = getFieldWithComponent(SwitchField);

export { Component as SwitchField };
