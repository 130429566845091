import { API_CALL } from '../../../middleware/api';
import {
  ESI_INVITE_PATIENT_GET_CAPS_TYPES,
  ESI_INVITE_PATIENT_GET_SCHEDULE_DETAILS,
  ESI_INVITE_PATIENT,
  ESI_PATIENTS_EDIT_MEDICATION,
  CLEAR_PATIENT,
} from './constants';
import { ESI_PATIENT_GET, ESI_PATIENTS_EDIT } from 'modals/AddESIPatientModal/redux/constants';
import { ESI_PATIENT_GET_CLEAR } from '../../../pages/ESI/Patients/redux/constants';

export const actions = {
  getListOfCapsTypes: () => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/esi/v1/cap-types',
    },
    type: ESI_INVITE_PATIENT_GET_CAPS_TYPES,
  }),
  getScheduleDetails: capName => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: `/esi/v1/medicines?cap-type=${capName}`,
    },
    type: ESI_INVITE_PATIENT_GET_SCHEDULE_DETAILS,
  }),
  invitePatient: patient => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: `/esi/v1/patients`,
      data: patient,
    },
    type: ESI_INVITE_PATIENT,
  }),
  getPatient: id => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: `/esi/v1/patients/${id}`,
    },
    type: ESI_PATIENT_GET,
  }),
  clearPatient: () => ({
    type: ESI_PATIENT_GET_CLEAR,
  }),
  editPatient: (id, patient) => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: `/esi/v1/patients/${id}`,
      data: patient,
    },
    type: ESI_PATIENTS_EDIT,
  }),
  updatePatientMedicine: (id, data) => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: `/esi/v1/schedules/${id}`,
      data,
    },
    type: ESI_PATIENTS_EDIT_MEDICATION,
  }),
  addPatientMedicine: (id, data) => ({
    [API_CALL]: {
      method: 'PUT',
      endpoint: `/patients/patient/${id}/schedules`,
      data,
    },
    type: ESI_PATIENTS_EDIT_MEDICATION,
  }),
};
