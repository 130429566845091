import { API_CALL } from '../../../../middleware/api';
import { ESI_VERIFY_ADMIN_CHECK_CODE, ESI_VERIFY_ADMIN_RESEND_CODE, ESI_VERIFY_ADMIN_SEND_CODE } from './constants';

export const actions = {
  sendCode: (phone, two_factor_key) => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: '/v1/organizations/users/invite-verify',
      data: {
        function: 'new',
        field: phone,
        flavor: 'sms',
        two_factor_key,
      },
    },
    type: ESI_VERIFY_ADMIN_SEND_CODE,
  }),

  resendCode: (phone, two_factor_key) => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: '/v1/organizations/users/invite-verify',
      data: {
        function: 'resend',
        field: phone,
        flavor: 'sms',
        two_factor_key,
      },
    },
    type: ESI_VERIFY_ADMIN_RESEND_CODE,
  }),

  verifyCode: (phone, code, two_factor_key) => ({
    [API_CALL]: {
      method: 'POST',
      endpoint: '/v1/organizations/users/invite-verify',
      data: {
        function: 'verify',
        field: phone,
        token: code,
        flavor: 'sms',
        two_factor_key,
      },
    },
    type: ESI_VERIFY_ADMIN_CHECK_CODE,
  }),
};
