import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { MED_ICON_COLOR_FILTER } from '../../../constants';
import { getMedIcon } from '../../../utils/medIcons';
import { Row } from './Row';
import { RowButton } from './RowButton';
import { RowImage } from './RowImage';
import { Rows } from './Rows';
import { RowText } from './RowText';

export class ActiveMeds extends PureComponent {
  render() {
    const { data, onClick } = this.props;

    const rows = [];

    data?.slice(0, 4).forEach((e, index) => {
      rows.push(
        <Row>
          <RowImage image={getMedIcon(e?.format?.route)} style={{ filter: MED_ICON_COLOR_FILTER }} />
          <RowText>{e.name}</RowText>
          <RowButton onClick={() => onClick(e.id)} index={index} />
        </Row>,
      );
    });

    return <Rows rows={rows} />;
  }
}

ActiveMeds.propTypes = {
  data: PropTypes.shape({ slice: PropTypes.func }),
  onClick: PropTypes.func,
};
