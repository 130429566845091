import { API_CALL } from '../middleware/api';

export const formActionTypes = {
  update: 'form-update',
  reset: 'form-reset',
  submit: 'form-submit',
  cancel: 'form-cancel',
};

const formAction = (formId, data, type, apiCall) => {
  if (apiCall === undefined) {
    return {
      type,
      data,
      formId,
    };
  }
  return {
    type,
    data,
    formId,
    [API_CALL]: {
      endpoint: `/comments/${type}`,
      method: 'post',
    },
  };
};

export const formUpdateAction = (formId, data) => formAction(formId, data, formActionTypes.update);
export const formResetAction = (formId, data) => formAction(formId, data, formActionTypes.reset);
export const formSubmitAction = (formId, data) => formAction(formId, data, formActionTypes.submit);
export const formCancelAction = (formId, data) => formAction(formId, data, formActionTypes.cancel);

export const updateForm = (formId, data) => dispatch => dispatch(formUpdateAction(formId, data));
export const resetForm = (formId, data) => dispatch => dispatch(formResetAction(formId, data));
export const submitForm = (formId, data) => dispatch => dispatch(formSubmitAction(formId, data));
export const cancelForm = (formId, data) => dispatch => dispatch(formCancelAction(formId, data));
