import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { push } from 'react-router-redux';
import QRCode from 'react-qr-code';
import _ from 'lodash';

import { PageHeader } from '../../components/PageHeader';
import { DATE_MONTH_DAY_YEAR, LOCAL_TIMEZONE, PAGE_LIMIT, TIME_FORMAT_12_UPPERCASE } from '../../constants';
import Strings from '../../Strings';
import { actions } from './redux/actions';
import Table, { Column } from '../../containers/Table/TableWithPagination';
import { UsersCard } from '../../components/UsersCard/UsersInfoCard';
import styles from './style.module.scss';
import { isShipper } from '../../utils/userRoles';
import { getFullLocation, getLocation } from '../../utils';
import { Button } from '../../components/PageHeader/Button';
import CapTakesPlot from './CapTakesPlot';
import AdvancedFilters, { Filter } from '../../components/AdvancedFilters/AdvancedFilters';
import { getActivityTypeFilter } from '.';
import AdvancedFiltersBar from '../../components/AdvancedFilters/AdvancedFiltersBar';
import { getRelevantFilters } from '../../components/AdvancedFilters/helpers';

const mapStateToProps = state => {
  const { profile } = state.auth;
  const access = profile?.access;
  return {
    plotData: state.shipper.capsDetails.plotData,
    plotScheduleData: state.shipper.capsDetails.plotScheduleData,
    isLoading: state.shipper.capsDetails.isLoading,
    details: state.shipper.capsDetails.data,
    pagination: state.shipper.capsDetails.data?.pagination,
    access,
    timezone: state.auth?.profile?.preferences?.timezone,
    filters: state.entities.advancedFilters.filters.items,
  };
};

const mapDispatchToProps = dispatch => ({
  getFillterData: type => dispatch(actions.getFillterData(type)),
  getCapsDetails: (id, pageRequest) => dispatch(actions.getCapsDetails(id, pageRequest)),
  clearCapsDetails: () => dispatch(actions.clearCapsDetails()),
  navigate: path => dispatch(push(path)),
  getPlotScheduleDetail: id => dispatch(actions.getPlotScheduleDetail(id)),
  getPlotData: (id, startDate, endDate) => dispatch(actions.getPlotData(id, startDate, endDate)),
});

const getFilterItems = filters => {
  const ret = [];
  /* eslint-disable */
  for (const i in filters) {
    ret.push({
      id: filters[i].type,
      value: filters[i].value,
    });
  }
  /* eslint-enable */
  return ret;
};

class BleCapDetails extends PureComponent {
  id = this.props.match.params.id;
  deviceId = this.props.match.params.deviceId;
  type = 'all';
  offset = 0;
  filterItems = [];
  refreshEnable = true;

  componentDidMount() {
    this.loadAllData();
  }

  componentWillUnmount() {
    this.props.clearCapsDetails();
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    if (filters !== prevProps.filters) {
      this.onFiltersChange(filters);
    }
  }

  loadAllData() {
    let id = this.id;
    if (this.props.match.params?.deviceId) {
      id = this.props.match.params?.deviceId;
    }

    this.props.getPlotScheduleDetail(id).then(r => {
      this.startDate = r.response.start_date
        ? moment(r.response.start_date * 1000).startOf('day')
        : moment()
            .subtract(1, 'month')
            .startOf('day');
      this.endDate =
        !r.response.end_date || moment(r.response.end_date) > moment()
          ? moment().endOf('day')
          : moment(r.response.end_date * 1000).endOf('day');
      this.setState({ graphMinDate: this.startDate, graphMaxDate: this.endDate });

      this.loadPlot();
    });
    this.props.getFillterData('ble').then(r => {
      this.filterItems = getFilterItems(r.response.data);
      this.load();
    });
  }

  load() {
    const id = this.deviceId;
    const pageRequest = {
      offset: this.offset,
      search: '',
      activityType: this.type,
      limit: PAGE_LIMIT,
    };

    this.props.getCapsDetails(id, pageRequest);
  }

  loadPlot() {
    let id = this.id;
    if (this.props.match.params?.deviceId) {
      id = this.props.match.params?.deviceId;
    }
    if (!this.startDate || !this.endDate) return;
    this.props.getPlotData(id, this.startDate, this.endDate);
  }

  onBackClick = () => {
    const url = this.props.match.url;
    const updateURL = url.replace(`/${this.deviceId}/events/ble`, '');
    this.props.navigate(updateURL);
  };

  onFiltersChange = _.debounce(() => {
    this.offset = 0;
    const type = getRelevantFilters(this.props.filters.filter(f => f.group === 'activityType'));
    if (type?.length > 0) {
      this.type = type.map(f => f.value).join(',');
    } else {
      this.type = 'all';
    }
    this.load();
  }, 1000);

  onPrevClick = () => {
    const { pagination } = this.props;
    this.offset = pagination.offset - PAGE_LIMIT;
    this.load();
  };

  onNextClick = () => {
    const { pagination } = this.props;
    this.offset = pagination.offset + PAGE_LIMIT;
    this.load();
  };

  onCustomPage = page => {
    this.offset = (page - 1) * PAGE_LIMIT;
    this.load();
  };

  getHWValue(e) {
    return isShipper() ? `${e.activity_value ?? ''} (${e.hw_code})` : e.activity_value;
  }

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 10000);
    this.loadAllData();
  };

  render() {
    const { details, isLoading, pagination, plotData, plotScheduleData, timezone } = this.props;
    const { type } = this.props.match.params;
    const title = type === 'hub' ? Strings.hubActivityDetails : Strings.capActivityDetails;

    const graphMinDate = this.selectedPlotDateRange
      ? moment(this.selectedPlotDateRange.split(' ')[0]).tz(timezone)
      : moment()
          .tz(timezone)
          .subtract(29, 'day')
          .startOf('day');
    const graphMaxDate = this.selectedPlotDateRange
      ? moment(this.selectedPlotDateRange.split(' ')[1]).tz(timezone)
      : moment()
          .tz(timezone)
          .endOf('day');

    return (
      <React.Fragment>
        <PageHeader
          left={<b>{title}</b>}
          onBackClick={this.onBackClick}
          noLeftPadding
          right={<Button class="refresh" disabled={!this.refreshEnable} onClick={this.onRefresh} />}
        />

        <div
          style={{
            display: 'flex',
            //marginBottom: 20,
          }}
        >
          <div style={{ marginRight: 10 }}>
            <UsersCard
              data={[
                {
                  title: Strings.capId,
                  value: this.deviceId,
                },
                {
                  title: type === 'hub' ? Strings.hubId : Strings.capId,
                  value: this.id,
                },
                {
                  title: Strings.capName,
                  value: details?.cap_name?.toString(),
                },
                {
                  noBottomBorder: true,
                  value: details?.qr_code?.toString() ? (
                    <div style={{ textAlign: 'center' }}>
                      <QRCode value={details.qr_code.toString()} size={96} />
                    </div>
                  ) : null,
                },
              ]}
              title=""
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {(details?.battery ||
              details?.battery === 0 ||
              details?.temperature ||
              details?.location ||
              details?.signal_strength) && (
              <UsersCard
                data={[
                  {
                    title: Strings.lastKnownBattery,
                    value: details?.battery?.toString(),
                  },
                  {
                    title: Strings.lastKnownTemperature,
                    value: details?.temperature?.toString(),
                  },
                  {
                    title: Strings.lastKnownLocation,
                    value: getLocation(details?.location),
                    rowValue: getFullLocation(details?.location),
                    specialKey: 'location',
                  },
                  {
                    title: Strings.lastKnownSignalStrength,
                    value: details?.signal_strength?.toString(),
                  },
                ]}
                title=""
              />
            )}
          </div>
        </div>

        {plotData && (
          <div className={styles.chart}>
            <CapTakesPlot
              plotData={plotData}
              plotScheduleData={plotScheduleData}
              graphMinDate={graphMinDate}
              graphMaxDate={graphMaxDate}
            />
          </div>
        )}

        <div className={styles.newHeader}>
          {this.filterItems?.length > 0 && (
            <AdvancedFilters expandToRight>
              <Filter definition={getActivityTypeFilter(this.filterItems).activityType} />
            </AdvancedFilters>
          )}
        </div>

        {this.filterItems?.length > 0 && <AdvancedFiltersBar customFilters={getActivityTypeFilter(this.filterItems)} />}
        <Table
          isLoading={isLoading}
          name="capsDetails"
          data={details?.activity ?? []}
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          onCustomPage={this.onCustomPage}
          pagination={pagination}
        >
          <Column
            key="timestamp"
            title={Strings.date}
            value={e => {
              if (e.tzinfo === LOCAL_TIMEZONE) {
                return e.timestamp ? moment.unix(e.timestamp).format(DATE_MONTH_DAY_YEAR) : '-';
              }
              return e.timestamp ? moment.utc(moment.unix(e.timestamp)).format(DATE_MONTH_DAY_YEAR) : '-';
            }}
          />
          <Column
            key="timestampTime"
            title={Strings.time}
            value={e => {
              if (e.tzinfo === LOCAL_TIMEZONE) {
                return e.timestamp ? moment.unix(e.timestamp).format(TIME_FORMAT_12_UPPERCASE) : '-';
              }
              return e.timestamp ? moment.utc(moment.unix(e.timestamp)).format(TIME_FORMAT_12_UPPERCASE) : '-';
            }}
          />
          <Column
            key={Strings.eventType}
            title={Strings.eventType}
            value={e => (e.activity_type ? e.activity_type : '-')}
          />
          <Column
            key={Strings.value}
            title={isShipper() ? Strings.valueHwCode : Strings.value}
            value={e => {
              return this.getHWValue(e);
            }}
          />
        </Table>
      </React.Fragment>
    );
  }
}

BleCapDetails.propTypes = {
  clearCapsDetails: PropTypes.func,
  details: PropTypes.shape({
    activity: PropTypes.array,
    battery: PropTypes.shape({ toString: PropTypes.func }),
    cap_name: PropTypes.shape({ toString: PropTypes.func }),
    location: PropTypes.any,
    qr_code: PropTypes.shape({ toString: PropTypes.func }),
    signal_strength: PropTypes.shape({ toString: PropTypes.func }),
    temperature: PropTypes.shape({ toString: PropTypes.func }),
  }),
  getCapsDetails: PropTypes.func,
  getFillterData: PropTypes.func,
  isLoading: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.shape({
      deviceId: PropTypes.any,
      id: PropTypes.any,
      type: PropTypes.string,
    }),
    url: PropTypes.shape({ replace: PropTypes.func }),
  }),
  navigate: PropTypes.func,
  pagination: PropTypes.shape({ offset: PropTypes.any }),
};

export default connect(mapStateToProps, mapDispatchToProps)(BleCapDetails);
