import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { push } from 'react-router-redux';
import { normalize } from 'normalizr';
import { connect } from 'react-redux';
import { AdherencePlot, DevicesPlot } from '../../../components/Plots';
import HistogramAdherencePlot from '../../../components/Plots/histogram-plot';
import { last7DaysAdherenceSchema } from '../../../schemas';
import { overViewAction } from '../../../actions/overView';

class StudyOverView extends PureComponent {
  static propTypes = {
    dateRange: PropTypes.any,
    onLoadOverView: PropTypes.func,
    adherence7days: PropTypes.any,
    adherenceData: PropTypes.any,
    devicesUsageData: PropTypes.any,
    overviewLoading: PropTypes.any,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.dateRange !== nextProps.dateRange) {
      const { dateRange, trialId } = nextProps;
      this.getData(dateRange, trialId);
    }
  }

  componentDidMount() {
    const { dateRange, trialId } = this.props;
    this.getData(dateRange, trialId);
  }

  getData(dateRange, trialId) {
    this.pageRequest = {
      startDate: dateRange.infimum,
      endDate: dateRange.supremum,
      offset: 0,
      trialId,
      search: '',
    };
    this.props.onLoadOverView(this.pageRequest);
  }

  render() {
    const { adherence7days = {}, adherenceData, devicesUsageData, overviewLoading } = this.props;
    return (
      <div className="plot-row dashboard-plot-row">
        <div className="column">
          <AdherencePlot data={adherenceData} isLoading={overviewLoading} />
        </div>
        <div className="column">
          <HistogramAdherencePlot data={adherence7days} isLoading={overviewLoading} caption="Adherence Last 7 Days" />
        </div>
        <div className="column">
          <DevicesPlot data={devicesUsageData} isLoading={overviewLoading} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { dateFilter } = state.filter;
  const { adherenceData, devicesUsageData, adherence7days, loading } = state.entities.overView;
  const dateRange = dateFilter || {
    infimum: moment()
      .subtract(1, 'month')
      .toDate(),
    supremum: moment().toDate(),
  };

  const normAdherence7days = normalize(adherence7days, [last7DaysAdherenceSchema]);

  return {
    dateRange,
    adherenceData,
    devicesUsageData,
    adherence7days: normAdherence7days.entities.last7DayAdherence,
    overviewLoading: loading,
  };
};

const mapDispatchToProps = dispatch => ({
  onLoadOverView: trialRequest => dispatch(overViewAction.actionOverView(trialRequest)),
  onNavigate: path => dispatch(push(path)),
});

StudyOverView.propTypes = {
  trialId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyOverView);
