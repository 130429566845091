import React from 'react';
import moment from 'moment-timezone';
import { DATE_FORMAT_LLLL } from '../../../constants';
import { Tooltip } from 'recharts';
import { graphMetrics } from './Metrics';

const CustomTooltip = props => {
  const { active, payload, label, data, dateRange, selectedMetrics } = props;
  if (active && payload && payload.length > 0 && data && dateRange && selectedMetrics) {
    // console.log(props);

    if (+label < dateRange.start.unix()) return null;
    if (+label > dateRange.end.unix()) return null;

    const includeNotSelectedMetrics = false;

    let dateFormat = DATE_FORMAT_LLLL;
    const values = [];
    Object.keys(data).forEach(m => {
      if (includeNotSelectedMetrics || selectedMetrics.includes(m)) {
        const metricExt = graphMetrics.find(gm => gm.id === m);
        const unit = metricExt.unit;
        const metricData = data[m].data
          .filter(i => i.dt.unix() === +label)
          .map(i => ({
            metric: metricExt.label,
            value: i.value,
            statistics: i.statistics,
            distribution: i.distribution,
            unit: unit,
          }));
        values.push(...metricData);
        if (metricExt.format.preferredTooltipDateFormat) dateFormat = metricExt.format.preferredTooltipDateFormat;
      }
    });

    if (values.length > 0) {
      //console.log(values);

      return (
        <div className="customTooltip">
          <p className="customTooltipTitle" style={{ paddingLeft: 10, paddingRight: 10 }}>
            {moment(label * 1000).format(dateFormat)}
          </p>
          <div className="customTooltipDescrHeader left-align"></div>
          <div className="customTooltipDescr left-align">
            {values.map((p, index) => (
              <div className="customTooltipDescr left-align" key={`customTT_1b_${index}`}>
                {p.metric}: {p.statistics ? ` ${p.statistics.minValue}...${p.statistics.maxValue} ` : p.value}
                {p.unit ? p.unit : ''}
                {p.distribution &&
                  Object.keys(p.distribution).map(key => (
                    <div className="customTooltipDescr left-align" key={`customTT_1b_${index}`}>
                      {key}: {p.distribution[key]}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
      );
    }
  }
  return null;
};

const CustomTakesTooltip = props => {
  const { active, payload, label, medId, takes } = props;
  if (active && payload && payload.length > 0 && takes) {
    //console.log(props);

    const takesData = takes[medId];
    if (!takesData) return null;

    const takesDataFiltered = takesData.filter(i => i.dt.unix() === +label);
    //console.log(takesDataFiltered);
    if (takesDataFiltered.length < 1) return null;

    const dt = moment(label * 1000).format(DATE_FORMAT_LLLL);
    // if (takesDataFiltered.length > 1) console.log(`More than 1 take for ${dt}!`);

    const takeData = takesDataFiltered[0];

    const type =
      takeData.type === 'scheduled_take'
        ? 'scheduled take, '
        : takeData.type === 'unscheduled_take'
        ? 'unscheduled take'
        : takeData.type === 'missed_take'
        ? 'missed take'
        : takeData.type === 'SCHEDULE_MISSED_DOSE_SMS'
        ? 'missed doses reminer'
        : takeData.type === 'SCHEDULE_ON_TIME_REMINDER_SMS'
        ? 'on time reminder'
        : takeData.type === 'SCHEDULE_LATE_REMINDER_SMS'
        ? 'late reminder'
        : takeData.type === 'SCHEDULE_EARLY_REMINDER_SMS'
        ? 'early reminder'
        : '';

    const absValue = Math.abs(takeData.value);
    const hours = Math.floor(absValue);
    const minutes = Math.round((absValue - hours) * 60);
    const delta = `${hours > 0 ? hours + 'h' : ''}${minutes > 0 ? ' ' + minutes + 'min' : ''}`.trim();

    const descr =
      takeData.type === 'scheduled_take' && takeData.value === 0
        ? 'just on time'
        : takeData.type === 'scheduled_take' && takeData.value < 0
        ? `${delta} early`
        : takeData.type === 'scheduled_take' && takeData.value > 0
        ? `${delta} late`
        : '';

    return (
      <div className="customTooltip">
        <p className="customTooltipTitle" style={{ paddingLeft: 10, paddingRight: 10 }}>
          {dt}
        </p>
        <div className="customTooltipDescrHeader left-align">{type + descr}</div>
      </div>
    );
  }
  return null;
};

export function drawTooltip(data, dateRange, selectedMetrics) {
  return (
    <Tooltip
      content={<CustomTooltip data={data} dateRange={dateRange} selectedMetrics={selectedMetrics} />}
      dataKey="y"
      allowEscapeViewBox={{ x: false, y: false }}
    />
  );
}

export function drawTakesTooltip(takes, medId) {
  return (
    <Tooltip
      content={<CustomTakesTooltip takes={takes} medId={medId} />}
      dataKey="y"
      allowEscapeViewBox={{ x: false, y: false }}
    />
  );
}
