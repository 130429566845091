import { isBoolean } from 'lodash';

export const getRelevantFilters = filters => {
  const relevantFilters = [];
  filters.forEach(filter => {
    if (filter.value !== 'all' && filter.state)
      relevantFilters.push({
        group: filter.group,
        value: filter.value,
        state: filter.state,
      });
  });

  return relevantFilters;
};

export const getFiltersForRequest = filters => {
  const groupedFilters = {};
  getRelevantFilters(filters).forEach(filter => {
    if (!isBoolean(filter.state) && filter.state !== '') {
      groupedFilters[filter.group] = filter.state;
    } else {
      if (!{}.hasOwnProperty.call(groupedFilters, filter.group)) groupedFilters[filter.group] = filter.value;
      else groupedFilters[filter.group] = groupedFilters[filter.group].concat(',', filter.value);
    }
  });

  const ret = [];
  Object.entries(groupedFilters).forEach(([key, value]) => {
    ret.push(''.concat(key, ':', value));
  });

  return ret;
};

export const urlInjected = search => {
  const urlParams = new URLSearchParams(search);
  const filtersSerialized = JSON.parse(urlParams.get('filters'));
  if (!filtersSerialized || filtersSerialized.length === 0) return false;
  return true;
};
