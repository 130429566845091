import { formActionTypes } from '../actions/forms';

export const formReducer = (state = {}, action) => {
  const { type, formId, data = {} } = action;
  const { [formId]: formState = {} } = state;

  if (formId === undefined) {
    return state;
  }

  switch (type) {
    case formActionTypes.update:
      return {
        ...state,
        [formId]: {
          ...formState,
          data: {
            ...formState.data,
            ...data,
          },
          submitted: false,
        },
      };
    case formActionTypes.reset:
      return {
        ...state,
        [formId]: {
          data: { ...data },
          submitted: false,
          cancelled: false,
        },
      };
    case formActionTypes.submit:
      return {
        ...state,
        [formId]: {
          ...formState,
          submitted: true,
          data: undefined,
        },
      };
    case formActionTypes.cancel:
      return {
        ...state,
        [formId]: {
          ...formState,
          cancelled: true,
          data: undefined,
        },
      };
    default:
      return state;
  }
};
