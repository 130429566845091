import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import imgStatusMissed from '../../../images/statusMissed.svg';
import imgStatusTaken from '../../../images/statusTaken.svg';
import Strings from '../../../Strings';
import { Row } from './Row';
import { RowImage } from './RowImage';
import { RowLabel } from './RowLabel';
import { Rows } from './Rows';
import { RowText } from './RowText';

export class Stat extends PureComponent {
  render() {
    const { data } = this.props;

    const rows = [];

    const taken = data.total - data.missed - data.skipped;

    rows.push(
      <Row>
        <RowImage image={imgStatusTaken} />
        <RowText big>{Strings.taken}</RowText>
        <RowLabel>{taken}</RowLabel>
      </Row>,
    );
    rows.push(
      <Row>
        <RowImage image={imgStatusMissed} />
        <RowText big>{Strings.missed}</RowText>
        <RowLabel>{data.missed}</RowLabel>
      </Row>,
    );

    return <Rows noGrow rows={rows} />;
  }
}

Stat.propTypes = {
  data: PropTypes.shape({
    missed: PropTypes.any,
    skipped: PropTypes.any,
    total: PropTypes.any,
  }),
};
