import { API_CALL } from '../../../middleware/api';

export const GET_ACTIVE_DEVICES = 'get-active-devices';
export const GET_ACTIVE_HUBS = 'get-active-hubs';
export const GET_ACTIVATED_HUBS = 'get-activated-hubs';
export const GET_ACTIVITY_RATIO = 'get-activity-ratio';
export const GET_HUBS_ONLINE_RATIO = 'get-hubs-online-ratio';

export const actions = {
  getActivityStatistics: request => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/report/activity_statistics',
      params: request,
    },
    type: GET_ACTIVITY_RATIO,
  }),
};
