export const ESI_VERIFY_ADMIN_SEND_CODE = 'esi-verify-admin-send-code';
export const ESI_VERIFY_ADMIN_SEND_CODE_RESULT = 'esi-verify-admin-send-code/result';
export const ESI_VERIFY_ADMIN_SEND_CODE_ERROR = 'esi-verify-admin-send-code/error';

export const ESI_VERIFY_ADMIN_RESEND_CODE = 'esi-verify-admin-resend-code';
export const ESI_VERIFY_ADMIN_RESEND_CODE_RESULT = 'esi-verify-admin-resend-code/result';
export const ESI_VERIFY_ADMIN_RESEND_CODE_ERROR = 'esi-verify-admin-resend-code/error';

export const ESI_VERIFY_ADMIN_CHECK_CODE = 'esi-verify-admin-check-code';
export const ESI_VERIFY_ADMIN_CHECK_CODE_RESULT = 'esi-verify-admin-check-code/result';
export const ESI_VERIFY_ADMIN_CHECK_CODE_ERROR = 'esi-verify-admin-check-code/error';
