import React, { Component } from 'react';

import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Modal.module.scss';
import './ModalFormStyles.scss';

export class Modal extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    name: PropTypes.string,
    children: PropTypes.any,
    caption: PropTypes.string,
    additionalClasses: PropTypes.array,
    withoutContainer: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.setModalRef = element => {
      this.modalBox = element;
    };

    this.focusModalBox = () => {
      if (this.modalBox) this.modalBox.focus();
    };
  }

  componentDidMount() {
    this.focusModalBox();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.open && !this.props.open) {
      window.addEventListener('keydown', this.onKeyDown);
      if (nextProps.onOpen !== undefined) {
        nextProps.onOpen();
      }
    } else if (!nextProps.open && this.props.open) {
      window.removeEventListener('keydown', this.onKeyDown);
    }
  }

  onKeyDown = ({ key }) => {
    if (key === 'Escape') {
      this.props.onClose(this.props.name);
    }
  };

  render() {
    const {
      children,
      caption,
      name,
      additionalClasses = [],
      withoutContainer = false,
      roundedCorner = false,
    } = this.props;
    return withoutContainer ? (
      <div ref={this.setModalRef}>{children}</div>
    ) : (
      <div
        className={cn([styles.modalWrapper, roundedCorner && styles.roundedCorner, name, additionalClasses.join(' ')])}
      >
        <header className={styles.modalHeader}>{caption}</header>
        <div
          ref={this.setModalRef}
          className={cn([styles.modalContent, roundedCorner && styles.roundedCorner, 'modal-content'])}
        >
          {children}
        </div>
      </div>
    );
  }
}
