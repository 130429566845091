import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { reducer as esiInviteAdminReducer } from 'pages/ESI/InviteDoctor/redux/reducers';
import { reducer as notificationReducer } from 'components/Notification/redux/reducers';
import { reducer as esiInvitePatientReducer } from 'modals/AddESIPatientModal/redux/reducer';
import { reducer as AddOrganizationReducer } from 'modals/AddOrganizationModal/redux/reducer';
import { reducer as OrganizationReducer } from 'pages/SuperUser/Organization/redux/reducer';
import { reducer as ProvisioningReducer } from 'pages/SuperUser/Provisioning/redux/reducer';
import { listReducer } from 'pages/ESI/Patients/redux/reducers';
import { esiPatientReducer } from 'pages/patient/ESIPatient/redux/reducers';
import { reducer as esiPatientLoginReducer } from 'pages/ESI/PatientLogin/redux/reducers';

import { reducer as messagesReducer } from '../pages/SuperUser/Messages/redux/reducer';
import { reducer as careBillingReducer } from '../pages/SuperUser/CareBilling/redux/reducer';
import { reducer as billingReducer } from '../pages/SuperUser/Billing/redux/reducer';
import modalsReducer from './modal';
import { formReducer } from './forms';
import { pageAction } from '../actions/api';
import { auth } from './auth';
import table from '../containers/Table/reducers';
import { columnsReducer } from './columns';
import settingsReducer from './settings';
import ownPatientsReducer from './ownPatients';
import { doctorsReducer } from './doctors';
import schedulesReducer from './schedules';
import trialsReducer from './trials';
import notificationsReducer from './notifications';
import { eventsReducer } from './events';
import { membershipsReducer } from './memberships';
import { adminsReducer } from './admins';
import { eventLocationsReducer } from './eventLocations';
import userNameCheckReducer from './userNameCheck';
import userMobilePhoneVerifyReducer from './userMobilePhoneVerify';
import patientPortalReducer from './patientPortal';
import patientPortalSchedulesReducer from './patientPortalSchedules';
import patientPortalNotificationsReducer from './patientPortalNotifications';
import { filterReducer } from './filter';
import assetsReducer from './assets';
import countriesReducer from './countries';
import patientsReducer from './patients';
import userActivateReducer from './userActivate';
import patientDetailReducer from './patientDetail';
import patientCaregiversReducer from './patientCaregivers';
import patientMedicationHistoryReducer from './patientMedicationHistory';
import patientDeviceReducer from './patientDevice';
import capsReducer from './caps';
import overViewsReducer from './overView';
import caregiverReducer from './caregiver';
import patientDashboardReducer from './patientDashboard';
import { calendarReducer } from '../pages/Calendar/redux/reducers';
import { shipperCapsDetailsReducer } from '../pages/ShipperCapsDetails/redux/reducers';
import { esiAdminDashboardAdherenceReducer } from '../pages/Dashboard/redux/reducers';
import { deviceCapDetailsReducer } from '../pages/SuperUser/DeviceDetails/redux/reducers';
import { CapSchedulesReducer } from '../pages/SuperUser/Schedules/redux/reducers';
import { supportReducer } from '../pages/ESI/Support/redux/reducers';
import { CapPatientsReducer } from '../pages/SuperUser/Patients/redux/reducers';
import { ThirdPartyDevicesReducer } from '../pages/ThirdPartyDevices/redux/reducers';
import { widgetDevicesReducer } from '../pages/Dashboards/Widgets/Devices/redux/reducers';
import { widgetDevicesReadingsReducer } from '../pages/Dashboards/Widgets/DeviceReadings/redux/reducers';
import { widgetHubsWithoutReadingsReducer } from '../pages/Dashboards/Widgets/HubsWithoutReadings/redux/reducers';
import { widgetLocationOfYourKitsReducer } from '../pages/Dashboards/Widgets/LocationOfYourKits/redux/reducers';
import { widgetYourKitsReducer } from '../pages/Dashboards/Widgets/YourKits/redux/reducers';
import { widgetPendingCallsMessagesReducer } from '../pages/Dashboards/Widgets/PendingCallsMessages/redux/reducers';
import { widgetPatientsEnrollmentReducer } from '../pages/Dashboards/Widgets/PatientsEnrollment/redux/reducers';
import { widgetTotalKitsReducer } from '../pages/Dashboards/Widgets/TotalKits/redux/reducers';
import { widgetPatientsToCallReducer } from '../pages/Dashboards/Widgets/PatientsToCall/redux/reducers';
import { AdvancedFiltersReducer } from '../components/AdvancedFilters/reducer';
import { kitReducer } from '../pages/Kits/redux/reducer';
import { DashboardStateReducer } from '../pages/Dashboards/redux/reducer';
import { CohortsReducer } from '../pages/SuperUser/Patients/Cohorts/redux/reducers';
import { ModalsVisibilityStateReducer } from '../modals/_ModalsMetadata/redux/reducer';
import { modalsVisibilityConfig } from './migrations/modalsVisibility';
import { LabReducer } from '../pages/Settings/redux/reducer';
import { labFeaturesConfig } from './migrations/labFeatures';
import { dashboardStateConfig } from './migrations/dashboardState';
import { widgetPatientsWithNoReadingsReducer } from '../pages/Dashboards/Widgets/PatientsWithNoReadings/redux/reducers';
import { widgetPatientsWithNewNotesReducer } from '../pages/Dashboards/Widgets/PatientsWithNewNotes/redux/reducers';

const entities = combineReducers({
  kits: kitReducer,
  caps: capsReducer,
  history: calendarReducer,
  patientDashboard: patientDashboardReducer,
  patients: patientsReducer,
  patientDetail: patientDetailReducer,
  patientCaregivers: patientCaregiversReducer,
  patientMedicationHistory: patientMedicationHistoryReducer,
  patientDevice: patientDeviceReducer,
  eventLocations: eventLocationsReducer(assetsReducer, pageAction.types, () => 'eventLocations'),
  ownPatients: ownPatientsReducer,
  doctors: doctorsReducer(assetsReducer, pageAction.types, () => 'doctors'),
  admins: adminsReducer,
  trials: trialsReducer,
  overView: overViewsReducer,
  notifications: notificationsReducer(assetsReducer, pageAction.types, () => 'notifications'),
  events: eventsReducer(assetsReducer, pageAction.types, () => 'events'),
  analytics: assetsReducer(pageAction.types, () => 'notifications'),
  memberships: membershipsReducer(assetsReducer, pageAction.types, () => 'memberships'),
  schedules: schedulesReducer,
  // invites: combineReducers({
  //   analytics: invitationsAnalyticsReducer,
  //   invitationResult: invitationsReducer,
  //   objects: invitationsListReducer
  // }),
  userActivate: userActivateReducer,
  userNameCheck: userNameCheckReducer,
  userMobilePhoneVerify: userMobilePhoneVerifyReducer,
  // verification: verificationReducer,
  patientMedications: patientPortalReducer(assetsReducer, pageAction.types, () => 'patientMedications'),
  patientSchedules: patientPortalSchedulesReducer(assetsReducer, pageAction.types, () => 'patientSchedules'),
  patientNotifications: patientPortalNotificationsReducer(
    assetsReducer,
    pageAction.types,
    () => 'patientNotifications',
  ),
  advancedFilters: AdvancedFiltersReducer,
});

const rootReducer = combineReducers({
  auth,
  entities,
  caregiver: caregiverReducer,
  countries: countriesReducer,
  routing: routerReducer,
  filter: filterReducer,
  modals: modalsReducer,
  forms: formReducer,
  user: settingsReducer,
  table,
  columns: columnsReducer,
  esi: combineReducers({
    inviteAdmin: esiInviteAdminReducer,
    invitePatient: esiInvitePatientReducer,
    patientInfo: esiPatientReducer,
    dashboard: esiAdminDashboardAdherenceReducer,
    patient: combineReducers({
      list: listReducer,
      login: esiPatientLoginReducer,
    }),
  }),
  shipper: combineReducers({
    capsDetails: shipperCapsDetailsReducer,
    devices: ThirdPartyDevicesReducer,
  }),
  superUser: combineReducers({
    organization: AddOrganizationReducer,
    organizations: OrganizationReducer,
    provisioning: ProvisioningReducer,
    deviceDetails: deviceCapDetailsReducer,
    schedules: CapSchedulesReducer,
    patients: CapPatientsReducer,
    cohorts: CohortsReducer,
    billing: billingReducer,
    careBilling: careBillingReducer,
    messages: messagesReducer,
  }),
  support: supportReducer,
  notification: notificationReducer,
  dashboardWidgets: combineReducers({
    widgetDevicesReducer,
    widgetDevicesReadingsReducer,
    widgetHubsWithoutReadingsReducer,
    widgetLocationOfYourKitsReducer,
    widgetYourKitsReducer,
    widgetPendingCallsMessagesReducer,
    widgetPatientsEnrollmentReducer,
    widgetTotalKitsReducer,
    widgetPatientsToCallReducer,
    widgetPatientsWithNoReadingsReducer,
    widgetPatientsWithNewNotesReducer,
  }),
  modalsVisibility: persistReducer(modalsVisibilityConfig, ModalsVisibilityStateReducer),
  dashboardState: persistReducer(dashboardStateConfig, DashboardStateReducer),
  labState: persistReducer(labFeaturesConfig, LabReducer),
});

export default rootReducer;
