import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ToastMessage extends PureComponent {
  static propTypes = {
    message: PropTypes.string.isRequired,
    isShow: PropTypes.bool,
  };

  constructor() {
    super();
    this.state = { className: '' };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.isShow !== this.props.isShow) {
      if (newProps.isShow) {
        this.setState({ className: 'show' });
      } else {
        this.setState({ className: 'hide' });
      }

      if (newProps.timer === true) {
        clearInterval(this.intervalId);
        this.clearInterval = setTimeout(() => {
          this.setState({ className: 'hide' });
          clearInterval(this.intervalId);
        }, 8000);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }
  timer() {
    clearInterval(this.intervalId);
  }

  render() {
    const { message } = this.props;
    const { className } = this.state;
    return <div className={`toast ${className}`}> {message}</div>;
  }
}
export default ToastMessage;
