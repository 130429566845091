import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { PropTypes } from 'prop-types';
import { trialsAction } from 'actions/trials';
import { Pill } from 'components/AdherenceTable/Pill';

import { AdherenceTableScrollbarsVertical } from 'components/AdherenceTable/AdherenceTableScrollbarsVertical';
import { AdherenceTableScrollbarsHorizontal } from 'components/AdherenceTable/AdherenceTableScrollbarsHorizontal';
import SliderPicker from 'components/SliderPicker';
import Select, { SELECT_TYPE_LEFT } from 'components/Select/SelectOld';
import classNames from 'classnames';
import _ from 'lodash';

import { DATE_FORMAT_SPACES, DATE_FORMAT_YEAR_MONTH_DAY } from '../../constants';
import Strings from '../../Strings';

const CLASS_GOOD = 'good';
const CLASS_NORMAL = 'normal';
const CLASS_BAD = 'bad';
const VALUE_ALL = Strings.all;

class AdherenceTable extends PureComponent {
  static propTypes = {
    adherence: PropTypes.object.isRequired,
    dateFilter: PropTypes.shape({
      infimum: PropTypes.any,
      supremum: PropTypes.any,
    }),
    isLoading: PropTypes.bool,
    isPreparing: PropTypes.bool.isRequired,
    loadAdherenceTotal: PropTypes.func.isRequired,
    maxDate: PropTypes.any,
    minDate: PropTypes.any,
    onLoadAdherence: PropTypes.func.isRequired,
    trialId: PropTypes.string.isRequired,
  };

  state = {
    hoverDay: null,
    hoverPatient: null,

    scrollLeft: 0,
    shadowLeftOpacity: 0,
    shadowRightOpacity: 0,
  };

  meds = [];
  refHorizontalScroll = React.createRef();
  refHorizontalScrollThumb = React.createRef();
  refSelectMed = React.createRef();
  refHeaderData = React.createRef();
  refBodyData = React.createRef();

  UNSAFE_componentWillMount() {
    const { trialId } = this.props;
    this.props.loadAdherenceTotal(trialId);
    this.loadDataFromDateFilter();
  }

  componentDidUpdate() {
    const divHeaderData = this.refHeaderData.current;
    const divBodyData = this.refBodyData.current;

    // Updating body data div width to match header
    if (divHeaderData && divBodyData) {
      divBodyData.style.width = `${divHeaderData.clientWidth}px`;
    }
  }

  loadDataFromDateFilter(med = null) {
    const { dateFilter } = this.props;
    this.loadDataFromRange(dateFilter, med);
  }

  loadDataFromRange(range, med = null) {
    const { infimum, supremum } = range;
    const momentStart = `${moment(infimum).format(DATE_FORMAT_YEAR_MONTH_DAY)}T00:00:00Z`;
    const momentEnd = `${moment(supremum).format(DATE_FORMAT_YEAR_MONTH_DAY)}T23:59:59Z`;
    this.loadData(momentStart, momentEnd, med);
  }

  loadData(start, end, med) {
    const { trialId } = this.props;
    const pageRequest = {
      startDate: start,
      endDate: end,
    };

    if (med) pageRequest.search = med;

    this.props.onLoadAdherence(pageRequest, trialId);
  }

  /*
        "weekend" for sun & sat
        "start" for mon & sun (also :first-child)
        "end" for fri & sat (also :last-child)
         */
  getDayColumnClass(day) {
    return classNames('day', {
      weekend: day === 6 || day === 0,
      start: day === 1 || day === 6,
      end: day === 5 || day === 0,
    });
  }

  getDayColumnClassWithHover(e) {
    const { hoverDay } = this.state;
    return (
      this.getDayColumnClass(e.day()) + (hoverDay && hoverDay === e.format(DATE_FORMAT_YEAR_MONTH_DAY) ? ' hover' : '')
    );
  }

  onDateChangeEnd = range => {
    if (this.refSelectMed.current) {
      this.refSelectMed.current.setValue(VALUE_ALL);
    }

    this.loadDataFromRange(range);
  };

  dayOnMouseEnter = day => {
    this.setState({ hoverDay: day });
  };

  dayOnMouseLeave = () => {
    this.setState({ hoverDay: null });
  };

  patientOnMouseEnter = patientId => {
    this.setState({ hoverPatient: patientId });
  };

  patientOnMouseLeave = () => {
    this.setState({ hoverPatient: null });
  };

  renderPatientsAdherence(patients, days) {
    const { hoverPatient } = this.state;

    return patients.map(({ patientId, notifications }) => {
      return (
        <div
          key={`data-${patientId}`}
          onMouseEnter={() => this.patientOnMouseEnter(patientId)}
          onMouseLeave={this.patientOnMouseLeave}
          className={`row${hoverPatient === patientId ? ' hover' : ''}`}
        >
          {days.map(day => {
            const data = notifications.find(n => n.date === day.format(DATE_FORMAT_YEAR_MONTH_DAY));

            return (
              <Pill
                key={`data-${patientId}-${day.format(DATE_FORMAT_YEAR_MONTH_DAY)}`}
                date={day.format(DATE_FORMAT_YEAR_MONTH_DAY)}
                dateDisplay={day.format(DATE_FORMAT_SPACES)}
                patientId={patientId}
                getDayColumnClass={this.getDayColumnClass}
                dayOnMouseEnter={this.dayOnMouseEnter}
                dayOnMouseLeave={this.dayOnMouseLeave}
                dayData={data}
                dayOfWeek={day.day()}
              />
            );
          })}
        </div>
      );
    });
  }

  horizontalScrollUpdate = ({ scrollLeft }, shadowLeftOpacity, shadowRightOpacity) => {
    this.setState({
      scrollLeft,
      shadowLeftOpacity,
      shadowRightOpacity,
    });
  };

  renderTable(days, patients) {
    const { hoverPatient, scrollLeft, shadowLeftOpacity, shadowRightOpacity } = this.state;
    /* eslint-disable */
    return (
      <div className="data-table">
        <div className="head">
          <div className="col-name">{Strings.name}</div>
          <div className="col-data" ref={this.refHeaderData}>
            <div className="days-container">
              {days.map((e, i) => {
                const day = e.format('D');
                const style = {};
                if (i === 0) {
                  style.marginLeft = `${-scrollLeft}px`;
                }
                return (
                  <div
                    style={style}
                    key={e.format(DATE_FORMAT_YEAR_MONTH_DAY)}
                    className={this.getDayColumnClassWithHover(e)}
                  >
                    {day}
                  </div>
                );
              })}
            </div>

            {shadowLeftOpacity > 0 && <div className="shadow left" style={{ opacity: shadowLeftOpacity }} />}
            {shadowRightOpacity > 0 && <div className="shadow right" style={{ opacity: shadowRightOpacity }} />}
          </div>
          <div className="col-total">{Strings.total}</div>
        </div>
        <AdherenceTableScrollbarsVertical>
          <div className="body">
            <div className="col-name">
              {patients.map(({ patientFullname, patientId }) => {
                const name = patientFullname.trim() ? patientFullname : `Unnamed Patient ${patientId}`;
                return (
                  <div
                    key={`name-${patientId}`}
                    onMouseEnter={() => this.patientOnMouseEnter(patientId)}
                    onMouseLeave={this.patientOnMouseLeave}
                    className={`row${hoverPatient === patientId ? ' hover' : ''}`}
                  >
                    {name}
                  </div>
                );
              })}
            </div>
            <div className="col-data" ref={this.refBodyData}>
              <AdherenceTableScrollbarsHorizontal
                horizontalScrollUpdate={this.horizontalScrollUpdate}
                onHorizontalScrollRender={this.onHorizontalScrollRender}
                refHorizontalScroll={this.refHorizontalScroll}
                refHorizontalScrollThumb={this.refHorizontalScrollThumb}
              >
                <div className="col-data-inner">{this.renderPatientsAdherence(patients, days)}</div>
              </AdherenceTableScrollbarsHorizontal>
            </div>

            <div className="col-total">
              {patients.map(({ notifications, patientId }) => {
                const totalPatientAdherence = notifications.reduce(
                  (data, dailyAdherence) => {
                    data.complied += dailyAdherence.complied;
                    data.total += dailyAdherence.total;
                    return data;
                  },
                  {
                    complied: 0,
                    total: 0,
                  },
                );
                const percent = Math.round((totalPatientAdherence.complied / totalPatientAdherence.total) * 100);

                return (
                  <div
                    key={`total-${patientId}`}
                    onMouseEnter={() => this.patientOnMouseEnter(patientId)}
                    onMouseLeave={this.patientOnMouseLeave}
                    className={`row${hoverPatient === patientId ? ' hover' : ''}`}
                  >
                    {percent}%
                  </div>
                );
              })}
            </div>
          </div>
        </AdherenceTableScrollbarsVertical>

        <div className="HorizontalScrollContainer">
          <div ref={this.refHorizontalScroll}>
            <div ref={this.refHorizontalScrollThumb} />
          </div>
        </div>
      </div>
    );
    /* eslint-enable */
  }

  onMedChange = val => {
    this.loadDataFromDateFilter(val !== VALUE_ALL ? val : null);
  };

  getAdherenceClassByValue(value) {
    if (value >= 90) return CLASS_GOOD;
    if (value <= 70) return CLASS_BAD;
    return CLASS_NORMAL;
  }

  render() {
    const { isLoading, adherence, isPreparing, maxDate, minDate, dateFilter } = this.props;

    const momentStart = moment(dateFilter.infimum);
    const momentEnd = moment(dateFilter.supremum);

    const days = [];
    let inner = null;

    // To prevent infinite cycle
    if (momentStart.diff(momentEnd) > 0) {
      return 'Bad range';
    }

    const m = momentStart;
    const flag = true;
    while (flag) {
      days.push(moment(m));
      const diff = m.diff(momentEnd, 'days');
      if (diff === 0) break;
      m.add(1, 'days');
    }

    let adherenceText = null;

    if (isLoading || isPreparing) {
      inner = (
        <div className="spinner-container">
          <div className="spinner" />
        </div>
      );
    } else if (!adherence.result && !isPreparing) {
      inner = (
        <div className="no-data-image-container-adherence">
          <div className="no-data-image">{Strings.noData}</div>
        </div>
      );
    } else {
      adherence.result.forEach(patient => {
        if (patient.meds !== '') {
          const meds = patient.meds.split(',');
          this.meds = _.union(this.meds, meds).sort();
        }
      });

      // We don't display patients without data
      const patients = adherence.result.filter(e => e.notifications);

      if (patients.length == 0) {
        inner = (
          <div className="no-data-image-container-adherence">
            <div className="no-data-image">{Strings.noData}</div>
          </div>
        );
      } else {
        // Trim start & end of period
        let startDate = null;
        let endDate = null;

        patients.forEach(patient => {
          patient.notifications.forEach(notification => {
            const date = moment(notification.date);

            if (startDate === null || startDate > date) {
              startDate = date.clone();
            }
            if (endDate === null || endDate < date) {
              endDate = date.clone();
            }
          });
        });

        adherenceText = (
          <div className="adherence">
            Adherence:{' '}
            <span className={this.getAdherenceClassByValue(adherence.totalAdherence)}>
              {adherence.totalAdherence} %
            </span>
          </div>
        );

        inner = this.renderTable(
          days.filter(date => date >= startDate && date <= endDate),
          patients,
        );
      }
    }

    return (
      <React.Fragment>
        <SliderPicker min={minDate} max={maxDate} onChangeEnd={this.onDateChangeEnd} />
        <div className="adherence-new-container">
          <div className="header">
            <div className="filter">
              {this.meds.length > 0 && (
                <Select
                  onChange={this.onMedChange}
                  type={SELECT_TYPE_LEFT}
                  data={_.union([VALUE_ALL], this.meds)}
                  ref={this.refSelectMed}
                />
              )}
              {adherenceText}
            </div>
            <div className="legend">
              <div className="taken">{Strings.taken}</div>
              <div className="skipped">{Strings.skipped}</div>
              <div className="missed">{Strings.missed}</div>
            </div>
          </div>
          {inner}
        </div>
      </React.Fragment>
    );
  }
}

const today = moment()
  .endOf('day')
  .toDate();
const yearAgo = moment()
  .subtract(1, 'year')
  .startOf('day')
  .toDate();

const mapStateToProps = state => {
  const { adherence } = state.entities.trials;
  const maxDate = today;
  const minDate = yearAgo;

  return {
    isLoading: adherence.loading || false,
    isPreparing: adherence.preparing || false,
    adherence: adherence.adherence || {},
    maxDate,
    minDate,
    dateFilter: state.filter.dateFilter,
  };
};

const mapDispatchToProps = dispatch => ({
  onLoadAdherence: (pageRequest, trialId) => dispatch(trialsAction.adherence(pageRequest, trialId)),
  loadAdherenceTotal: trialId => dispatch(trialsAction.adherenceTotal(trialId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdherenceTable);
