import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { closeModal } from '../../actions/modal';
import Strings from '../../Strings';
import Wizard from '../../containers/Modal/Wizard';
import './OnboardingModal.scss';

function SetupRpmModal(props) {
  const youAreDonePage = (
    <React.Fragment>
      <div className="page-info-header">{Strings.formatString(Strings.finishedRpmSetup)}</div>
      <div>
        <div onClick={() => props.onOptionClicked('edit-cohort')} className="checkbox-container border navigate">
          Add another cohort
        </div>
      </div>
      <div>
        <div onClick={() => props.onOptionClicked('edit-rpm-patient')} className="checkbox-container border navigate">
          Add patient
        </div>
      </div>
      <div>
        <div onClick={() => props.onOptionClicked('bulk-add-patients')} className="checkbox-container border navigate">
          Upload a CSV of patient details
        </div>
      </div>
    </React.Fragment>
  );

  const pages = [
    {
      id: 'youAreDonePage',
      title: Strings.youAreAllDone,
      content: youAreDonePage,
      emptyFieldsCount: 0,
      canGoNext: true,
    },
  ];

  const buttons = (
    <div className="buttons center" key="button-bar">
      <div className="bottom-button-container">
        <button className="brand-blue" key="submit" type="button" onClick={props.onCancel}>
          {Strings.done}
        </button>
      </div>
    </div>
  );

  return <Wizard name="done-rpm-setup" pages={pages} onSubmit={props.onCancel} customButtons={buttons} />;
}

SetupRpmModal.propTypes = {
  data: PropTypes.shape({
    onSuccess: PropTypes.func,
  }),
  onCancel: PropTypes.any,
  onOptionClicked: PropTypes.func,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('done-rpm-setup')),
  onOptionClicked: option => {
    dispatch(closeModal('done-rpm-setup'));
    if (ownProps.data.onOptionClicked) {
      ownProps.data.onOptionClicked(option);
    }
  },
});

export default connect(null, mapDispatchToProps)(SetupRpmModal);
