import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../components/PageHeader/Button';
import { GET_EXTERNAL_API_CONFIGURATION_RESULT, SET_EXTERNAL_API_CONFIGURATION_RESULT, actions } from './actions';

import './ExternalApi.scss';
import { notificationActions } from '../../../components/Notification/redux/actions';
import Strings from '../../../Strings';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import { Switch2 } from '../../../components/Switch2/Switch2';
import { PageHeader } from '../../../components/PageHeader';
import { SystemConfigurationTabs } from '../SystemConfiguration/SystemConfiguration';

function ExternalApiAccessControl(props) {
  const [config, setConfig] = React.useState({});
  const [newConfig, setNewConfig] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [enableSave, setEnableSave] = React.useState(false);
  const configRef = React.useRef(config);
  configRef.current = config;
  const newConfigRef = React.useRef(newConfig);
  newConfigRef.current = newConfig;

  const onSave = () => {
    props.setConfig(newConfig).then(resp => {
      if (resp.type != SET_EXTERNAL_API_CONFIGURATION_RESULT) {
        if (resp.response.status === 400 || resp.response.status === 500) {
          props.showNotification(Strings.errors.internalError, 5000);
        } else {
          props.showNotification(Strings.errors.unknownError, 5000);
        }
      } else {
        props.showNotification(Strings.success.dataSavedSuccessfully, 5000);
      }
      setEnableSave(false);
      load();
    });
  };

  const load = () => {
    setIsLoading(true);
    props.getConfig().then(resp => {
      if (resp.type === GET_EXTERNAL_API_CONFIGURATION_RESULT) {
        setConfig(_.cloneDeep(resp.response));
        setNewConfig(_.cloneDeep(resp.response));
      } else {
        props.showNotification(Strings.errors.internalError, 5000);
      }
      setIsLoading(false);
    });
  };

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    load();
  }, [props.masterEditor, props.organizationId]);

  React.useEffect(() => {
    const isChanged = !_.isEqual(config, newConfig);
    setEnableSave(isChanged);
  }, [newConfig]);

  const displayBool = value => {
    return value === true ? 'True' : 'False';
  };

  const onChange = (sw, enabled) => {
    const id = sw.props.name.split('_')[0];
    const field = sw.props.name.split('_')[1];
    const newConfig = _.cloneDeep(newConfigRef.current);

    for (var route in newConfig['routes']) {
      if (newConfig['routes'][route].id === id) {
        newConfig['routes'][route][field] = enabled;
        break;
      }
    }

    setNewConfig(newConfig);
  };

  const columns = [
    <Column title={Strings.description} key="description" value={e => e.description} />,
    <Column title={Strings.url} key="url" value={e => e.url} />,
  ];

  if (props.masterEditor) {
    columns.push.apply(columns, [
      <Column
        title={Strings.defaultEnabled}
        key="defaultEnabled"
        value={e => <Switch2 checked={e.defaultEnabled} name={`${e.id}_defaultEnabled`} onChange={onChange} />}
      />,
      <Column
        title={Strings.blacklisted}
        key="blacklisted"
        value={e => <Switch2 checked={e.blacklisted} name={`${e.id}_blacklisted`} onChange={onChange} />}
      />,
    ]);
  } else {
    columns.push.apply(columns, [
      <Column
        title={Strings.enabled}
        key="enabled"
        value={e => <Switch2 checked={e.enabled} name={`${e.id}_enabled`} onChange={onChange} />}
      />,
      <Column title={Strings.defaultEnabled} key="defaultEnabled" value={e => displayBool(e.defaultEnabled)} />,
      <Column title={Strings.blacklisted} key="blacklisted" value={e => displayBool(e.blacklisted)} />,
    ]);
  }

  columns.push(<Column title={Strings.id} key="id" value={e => e.id} />);

  const onNavigateToMasterEditor = () => {
    props.navigate(SystemConfigurationTabs.ExternalApiAccessControl.path);
  };

  const navigateToMasterEditor = (
    <Button
      class={HEADER_BUTTON_DARK_BLUE}
      onClick={() => onNavigateToMasterEditor()}
      title={Strings.masterConfiguration}
    />
  );

  return (
    <>
      <PageHeader
        title={props.masterEditor ? Strings.masterConfiguration : Strings.organizationConfiguration}
        right={!props.masterEditor ? navigateToMasterEditor : <></>}
      />
      <div className="external-api-configuration-table-container">
        <Table name="external-api-configuration" data={config['routes']} isLoading={isLoading}>
          {columns}
        </Table>
        <div className="buttons">
          <Button
            class={HEADER_BUTTON_DARK_BLUE}
            onClick={() => onSave()}
            title={Strings.save}
            disabled={!enableSave}
          />
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getConfig: () =>
    dispatch(
      ownProps.masterEditor ? actions.getMasterConfiguration() : actions.getOrgConfiguration(ownProps.organizationId),
    ),
  setConfig: configuration =>
    dispatch(
      ownProps.masterEditor
        ? actions.setMasterConfiguration(configuration)
        : actions.setOrgConfiguration(ownProps.organizationId, configuration),
    ),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
  navigate: path => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(ExternalApiAccessControl);
