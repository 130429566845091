import { createMigrate } from 'redux-persist';
import { allFeatures } from '../../pages/Settings/redux/reducer';
import { storage } from '../persistStorage';

const updateFeatures = previousVersionState => {
  return allFeatures.map(f => {
    return { name: f, selected: previousVersionState.features.find(f2 => f2.name === f)?.selected || false };
  });
};

const migrations = {
  0: previousVersionState => ({
    features: updateFeatures(previousVersionState),
  }),
  1: previousVersionState => ({
    features: updateFeatures(previousVersionState),
  }),
  2: previousVersionState => ({
    features: updateFeatures(previousVersionState),
  }),
};

export const labFeaturesConfig = {
  key: 'labState',
  version: 2,
  migrate: createMigrate(migrations, { debug: false }),
  storage,
};
