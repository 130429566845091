import React from 'react';
import PropTypes from 'prop-types';

import { Box, PiePlotComponent } from './base';
import Strings from '../../Strings';
import { emptyPieChartSVG, emptyPieChartSVGNoData, emptyPieChartSVGWithOutSpinner } from '../../utils';

const AdherencePlot = ({ data, isLoading }) => {
  const plotData = [
    {
      className: 'new-transparent',
      value: data.missed,
      label: Strings.missed,
    },
    {
      className: 'new-green',
      value: data.complied,
      label: Strings.complied,
    },
    {
      className: 'skipped',
      value: data.skipped,
      label: Strings.skipped,
    },
  ].filter(d => Boolean(d.value));
  const total = plotData.reduce((ret, it) => {
    let react = ret;
    react += it.value;
    return react;
  }, 0);

  // const totalAdherence = total ? (data.complied / total) * 100 : 0;
  const totalAdherence = data.adherence;

  if (totalAdherence < 50) {
    plotData[1] ? (plotData[1].className = 'new-red') : null;
  }

  function mainLabel() {
    return [
      {
        text: total ? `${Math.round(totalAdherence)}%` : '',
        className: '',
      },
    ];
  }

  function itemLabel(d) {
    return [
      {
        text: d.data.label,
        className: 'bottom',
      },
      {
        text: d.data.value,
        className: 'top',
      },
    ];
  }

  if (!total || (plotData && (isLoading || isLoading === undefined))) {
    return (
      <Box>
        <header>
          <div className="title">{Strings.totalAdherence}</div>
        </header>
        {isLoading || isLoading === undefined ? emptyPieChartSVG() : emptyPieChartSVGNoData()}
      </Box>
    );
  }

  return (
    <Box caption={Strings.totalAdherence} isLoading={isLoading}>
      <div style={{ position: 'relative' }}>
        {emptyPieChartSVGWithOutSpinner()}
        <div
          style={{
            position: 'absolute',
            top: '-20px',
            right: 0,
            left: 0,
          }}
        >
          <PiePlotComponent data={plotData} mainLabel={mainLabel} itemLabel={itemLabel} />
        </div>
      </div>
    </Box>
  );
};

AdherencePlot.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.bool,
};

export default AdherencePlot;
