import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment-timezone';

import { openModalAction } from '../../../actions/modal';
import { PageHeader } from '../../../components/PageHeader';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../components/PageHeader/Button';
import { TextInput } from '../../../components/PageHeader/TextInput';
import { DATE_MONTH_DAY_YEAR_WITH_DASH, PAGE_LIMIT } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { actions } from '../../Kits/redux/actions';
import { GET_KIT_DETAILS_RESULT, UPDATE_KIT } from '../../Kits/redux/constants';
import './Devices.scss';
import { convertUnixEpochToHumanReadable, getVitalsData } from '../../../utils';
import { getUrlForDevice } from './HubDeviceEvents';
import AdvancedFilters, { Filter } from '../../../components/AdvancedFilters/AdvancedFilters';
import { getFiltersDefinition } from '../../../components/AdvancedFilters/FiltersDefinition';
import AdvancedFiltersBar from '../../../components/AdvancedFilters/AdvancedFiltersBar';
import { getFiltersForRequest } from '../../../components/AdvancedFilters/helpers';
import { PERMISSIONS, hasPermission } from '../../../utils/userPermissions';

function KitDevices(props) {
  const kitId = encodeURIComponent(props.match.params.id);
  const defaultFilter = 'status:active';
  const pageRequest = React.useRef({ filterBy: defaultFilter });
  const refreshEnable = React.useRef(true);

  const [kitName, setKitName] = React.useState('');

  useEffect(() => {
    return () => props.clearData();
  }, []);

  const getDevices = () => {
    props.getKitDevices(kitId, pageRequest.current);
    updateKitName(kitId);
  };

  useEffect(() => {
    onFiltersChange(props.filters);
  }, [props.filters]);

  const onFiltersChange = filters => {
    return filtersDebounced(filters);
  };

  const filtersDebounced = _.debounce(() => {
    const newFiltersForRequest = getFiltersForRequest(props.filters).map(f => f.replace('DeviceStatus', 'status'));
    if (JSON.stringify(newFiltersForRequest) !== JSON.stringify(pageRequest.current.filterBy)) {
      if (newFiltersForRequest.length === 0) {
        pageRequest.current.filterBy = defaultFilter;
      } else {
        pageRequest.current.filterBy = newFiltersForRequest;
      }
      pageRequest.current.offset = 0;
      getDevices();
    }
  }, 1000);

  const onSearchQueryChange = query => {
    pageRequest.current.offset = 0;
    pageRequest.current.search = query;
    getDevices();
  };

  const onCustomPage = page => {
    pageRequest.current.offset = (page - 1) * PAGE_LIMIT;
    getDevices();
  };

  const onTextInputChange = e => {
    onSearchQueryChange(e.target.value);
  };

  const onBackButton = () => {
    const url = props.match.url;
    const updateURL = url.replace(`/${kitId}/details`, '');
    if (props.history.location.state?.params?.offset) {
      props.onNavigate(updateURL, { offset: props.history.location.state.params?.offset });
    } else {
      props.onNavigate(updateURL, { offset: 0 });
    }
  };

  const onRowSelected = id => {
    const device = props.kitDevices[id];
    const url = getUrlForDevice(device, device.device_type);
    props.onNavigate(url, null, `?lastActivity=${device.timestamp}&kitId=${kitId}`);
  };

  const onRefresh = () => {
    refreshEnable.current = false;
    setTimeout(() => {
      refreshEnable.current = true;
    }, 10000);
    getDevices();
    updateKitName(kitId);
  };

  const getHeaderComponents = () => {
    return (
      <React.Fragment>
        <TextInput class="search" placeholder={Strings.search} onChange={onTextInputChange} />
        {hasPermission(PERMISSIONS.DEVICE_MANAGEMENT_SHOW_DELETED) && (
          <AdvancedFilters>
            <Filter definition={getFiltersDefinition().DeviceStatus} />
          </AdvancedFilters>
        )}
        <Button onClick={onKitEdit} title={Strings.edit} />
        <Button class={HEADER_BUTTON_DARK_BLUE} onClick={onKitDelete} title={Strings.delete} />
        <Button class="refresh" disabled={!refreshEnable.current} onClick={onRefresh} />
      </React.Fragment>
    );
  };

  const onKitEdit = () => {
    const data = {
      kitId: kitId,
      kitName: kitName,
      onRefresh,
      action: actions.updateKit,
      actionType: UPDATE_KIT,
    };

    props.openEditModal(data);
  };

  const updateKitName = kitId => {
    props.getKitDetails(kitId).then(resp => {
      if (resp.type === GET_KIT_DETAILS_RESULT) {
        setKitName(resp.response?.name);
      }
    });
  };

  const onKitDelete = event => {
    event.preventDefault();
    event.stopPropagation();

    const data = {
      title: (
        <span>
          {Strings.deleteKitWarning} <b>{kitId}</b>?
        </span>
      ),

      onConfirmAction: actions.deleteKit(kitId, [onBackButton]),
      onCancelAction: null,
    };
    props.openConfirmModal(data);
  };

  const onCapsDelete = id => {
    const cap = props.kitDevices[id];
    const deviceId = cap.device_id;
    const data = {
      title: (
        <span>
          {Strings.deleteHubDeviceWarning}{' '}
          <b>{cap.device_id.split('.').length >= 2 ? cap.device_id.split('.')[1] : cap.device_id}</b>?
        </span>
      ),

      onConfirmAction: actions.unassignDeviceFromKit(kitId, deviceId, [
        actions.getKitDevices(kitId, pageRequest.current),
      ]),
      onCancelAction: null,
    };
    props.openConfirmModal(data);
  };

  const renderDeviceId = device => {
    let id = device.device_id;
    if (typeof device.manufacturer !== 'undefined') {
      const prefix = device.manufacturer.concat('.', device.model);
      id = device.device_id.replace(prefix, '');
      if (id.startsWith('.')) id = id.slice(1);
    }
    return id;
  };

  const getColumns = () => {
    const showingDeleted = pageRequest.current.filterBy.includes('status:deleted');
    const columns = [];
    columns.push(
      <Column key="device_id" title={Strings.deviceId} value={e => renderDeviceId(e)} className="clickable" />,
    );

    columns.push(
      <Column key="deviceName" title={Strings.deviceName} value={e => (e.device_name ? e.device_name : '-')} />,
    );

    columns.push(
      <Column
        key="deviceModel"
        title={Strings.deviceModel}
        value={e => (e.manufacturer ? e.manufacturer.concat(' ', e.model) : '-')}
      />,
    );

    if (!showingDeleted) {
      columns.push(
        <Column
          key="timestamp"
          title={Strings.lastActivity}
          value={e => (e.timestamp ? convertUnixEpochToHumanReadable(e.timestamp) : '-')}
        />,
      );
    }
    columns.push(
      <Column key={Strings.deviceType} title={Strings.deviceType} value={e => (e.device_type ? e.device_type : '-')} />,
    );

    if (!showingDeleted) {
      columns.push(
        <Column
          key="last_reading"
          title={Strings.lastReading}
          value={e => (!_.isEmpty(e.last_reading) ? getVitalsData(e.last_reading) : '-')}
        />,
      );
    }
    if (showingDeleted) {
      columns.push(
        <Column
          key="assigned_at"
          title={Strings.assignedDate}
          value={e => moment(e.assigned_at).format(DATE_MONTH_DAY_YEAR_WITH_DASH)}
        />,
      );
    }
    if (showingDeleted) {
      columns.push(
        <Column
          key="unassigned_at"
          title={Strings.unassignedDate}
          value={e => moment(e.unassigned_at).format(DATE_MONTH_DAY_YEAR_WITH_DASH)}
        />,
      );
    }
    return columns;
  };

  const buttons = [
    {
      icon: 'delete',
      onClick: onCapsDelete,
      disabled: () => {
        return false;
      },
      text: Strings.delete,
    },
  ];

  const columns = getColumns();
  const showingDeleted = pageRequest.current.filterBy.includes('status:deleted');

  return (
    <div className="details-container">
      <PageHeader
        right={() => getHeaderComponents()}
        left={Strings.formatString(
          Strings.showingX,
          props.kitDevices?.length || '-',
          showingDeleted ? Strings.deletedDevices : Strings.devices,
        )}
      />
      <AdvancedFiltersBar />
      {props.kitDevices && (
        <React.Fragment>
          <Table
            isLoading={props.isLoading}
            key={`kit-devices-${showingDeleted ? 'deleted' : 'active'}`}
            name={`kit-devices-${showingDeleted ? 'deleted' : 'active'}`}
            uuid={showingDeleted ? '08e4a154-31f2-4663-96ba-6501ff2e21fb' : 'bb431ae0-3cbc-41c9-bece-77bd3895a65d'}
            data={props.kitDevices}
            onRowClick={onRowSelected}
            onCustomPage={onCustomPage}
            buttons={showingDeleted ? undefined : buttons}
            enableColumnFiltering
          >
            {columns}
          </Table>
        </React.Fragment>
      )}
    </div>
  );
}

KitDevices.propTypes = {
  clearData: PropTypes.func,
  kitDevices: PropTypes.array,
  history: PropTypes.shape({
    location: PropTypes.shape({ state: PropTypes.shape({ params: PropTypes.shape({ offset: PropTypes.any }) }) }),
  }),
  isLoading: PropTypes.any,
  getKitDevices: PropTypes.func,
  getKitDetails: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.any }),
    url: PropTypes.shape({ replace: PropTypes.func }),
  }),
  onNavigate: PropTypes.func,
  openConfirmModal: PropTypes.func,
  openEditModal: PropTypes.func,
  filters: PropTypes.array,
};

const mapStateToProps = state => {
  return {
    role: state.auth.role,
    kitDevices: state.entities.kits?.kitDevices?.devices,
    isLoading: state.entities.kits?.isLoading,
    filters: state.entities.advancedFilters.filters.items,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: (path, params, search) =>
    dispatch(
      push({
        pathname: path,
        state: { params },
        search,
      }),
    ),
  getKitDevices: (kitId, pageRequest) => dispatch(actions.getKitDevices(kitId, pageRequest)),
  clearData: () => dispatch(actions.clearData()),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  openEditModal: data => dispatch(openModalAction('create-kit-and-add-devices', data)),
  getKitDetails: kitId => dispatch(actions.getKitDetails(kitId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KitDevices);
