import { API_CALL } from '../../../../../middleware/api';
import { WIDGET_GET_TOTAL_KITS } from './constants';

export const actions = {
  getTotalKits: () => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/kits/list',
      params: { limit: 1 },
    },
    type: WIDGET_GET_TOTAL_KITS,
  }),
};
