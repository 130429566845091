import { defaultPalette } from '../DashboardColorPalettes';
import {
  HandleFilterChange,
  GetDefaultFilters,
  GetDefaultLayouts,
  addWidgetToLayouts,
  GetLayouts,
  GetDefaultConfiguration,
} from '../DashboardsMetadata';
import {
  CHANGE_WIDGET_VISIBILITY,
  UPDATE_LAYOUTS,
  RESET_TO_DEFAULT,
  CHANGE_EDIT_MODE,
  CHANGE_WIGDET_CONFIGURATION,
  CHANGE_COLOR_PALETTE,
  CHANGE_WIGDET_CONFIGURATION_FOR_DASHBOARD,
} from './actions';

export const DashboardStateReducer = (
  state = {
    dashboardFilters: {},
    dashboardLayouts: {},
    editMode: {},
    configuration: { allDashboards: { colorPalette: defaultPalette } },
  },
  action,
) => {
  switch (action.type) {
    case CHANGE_WIDGET_VISIBILITY: {
      const newFilters = HandleFilterChange(
        action.dashboardId,
        state.dashboardFilters[action.dashboardId],
        action.widgetId,
        action.visible,
        action.additionalData,
        action.permissions,
      );
      return {
        ...state,
        dashboardFilters: {
          ...state.dashboardFilters,
          [action.dashboardId]: newFilters,
        },
        dashboardLayouts: {
          ...state.dashboardLayouts,
          [action.dashboardId]: action.visible
            ? addWidgetToLayouts(
                action.dashboardId,
                state.dashboardLayouts[action.dashboardId],
                action.widgetId,
                action.additionalData,
                action.permissions,
              )
            : GetLayouts(
                action.dashboardId,
                newFilters,
                state.dashboardLayouts[action.dashboardId],
                action.additionalData,
                action.permissions,
              ),
        },
      };
    }
    case RESET_TO_DEFAULT:
      return {
        ...state,
        dashboardFilters: {
          ...state.dashboardFilters,
          [action.dashboardId]: GetDefaultFilters(action.dashboardId, action.additionalData, action.permissions),
        },
        dashboardLayouts: {
          ...state.dashboardLayouts,
          [action.dashboardId]: GetDefaultLayouts(action.dashboardId, action.additionalData, action.permissions),
        },
        configuration: {
          allDashboards: { colorPalette: defaultPalette },
        },
        editMode: {},
      };
    case CHANGE_EDIT_MODE:
      return {
        ...state,
        editMode: {
          ...state.editMode,
          [action.dashboardId]: action.editMode,
        },
      };
    case UPDATE_LAYOUTS:
      return {
        ...state,
        dashboardLayouts: {
          ...state.dashboardLayouts,
          [action.dashboardId]: { ...action.layouts },
        },
      };
    case CHANGE_WIGDET_CONFIGURATION:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          [action.dashboardId]: {
            ...state.configuration[action.dashboardId],
            [action.widgetId]: { ...action.configuration },
          },
        },
      };
    case CHANGE_WIGDET_CONFIGURATION_FOR_DASHBOARD:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          [action.dashboardId]: {
            ...state.configuration[action.dashboardId],
            WidgetConfigForDashboard: action.configuration,
          },
        },
      };
    case CHANGE_COLOR_PALETTE:
      return {
        ...state,
        configuration: {
          ...state.configuration,
          allDashboards: {
            ...state.configuration.allDashboards,
            colorPalette: action.colorPalette,
          },
        },
      };
    default:
      return state;
  }
};
