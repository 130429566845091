import { API_CALL } from '../middleware/api';
import {
  DELETE_ADMIN_MEMBERSHIP,
  LIST_ADMINS,
  LIST_ADMINS_MEMBERSHIPS,
  POST_ADMIN_INVITE,
  SAVE_ADMIN_PERMISSION,
  RESET_ADMIN_PASSWORD,
} from './action-types';

const adminsAction = {
  actionList: (excludeMe = true) => ({
    [API_CALL]: {
      endpoint: '/groups/admins/memberships/',
      data: null,
      params: { excludeMe },
    },
    type: LIST_ADMINS_MEMBERSHIPS,
  }),
  actionListAdmins: (excludeMe = true) => ({
    [API_CALL]: {
      endpoint: '/groups/admins/list/',
      data: null,
      params: { excludeMe },
    },
    type: LIST_ADMINS,
  }),
  sendInvite: data => ({
    [API_CALL]: {
      endpoint: '/groups/doctors/invite/new/',
      method: 'post',
      data,
    },
    type: POST_ADMIN_INVITE,
  }),
  actionDelete: adminId => ({
    [API_CALL]: {
      endpoint: '/groups/admins/delete/{adminId}/',
      method: 'delete',
    },
    type: DELETE_ADMIN_MEMBERSHIP,
    adminId,
  }),
  actionSavePermission: (adminId, adminAppPrivileges, type = SAVE_ADMIN_PERMISSION) => ({
    [API_CALL]: {
      endpoint: '/groups/admins/save-permission/',
      method: 'post',
      data: {
        doctor: adminId,
        admin_app_privileges: adminAppPrivileges,
      },
    },
    type,
  }),
  actionResetPassword: adminEmail => ({
    [API_CALL]: {
      endpoint: 'accounts/user/password/change/',
      method: 'post',
      data: {
        method: 'email',
        email: adminEmail,
      },
    },
    type: RESET_ADMIN_PASSWORD,
  }),
};

export default adminsAction;
