import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { PageHeader } from '../components/PageHeader';
import TabView, { Tab } from '../components/TabView/index';
import { Button } from '../components/PageHeader/Button';
import SliderPicker from '../components/SliderPicker/index';
import { patientAction } from '../actions/patient';
import { changeFilter, clearEntitiesAction } from '../actions/index';
import { downloadFileOnly, downloadFiles } from '../utils';
import PatientProfileForm from '../components/PatientProfileForm';
import LoadingRenderer from '../components/LoadingRenderer';
import { openModalAction } from '../actions/modal';
import WithLoader from '../components/_hocs/withLoader/index';
import Medications from './patient/tabs/Medications';
import HistoryTab from './patient/tabs/HistoryTab/HistoryTab';
import TimeStamp from './study/tabs/Timestamp';
import TimeDelta from './patient/tabs/TimeDelta';
import { UPDATE_PATIENT_SETTINGS, POST_ADD_CAREGIVER, POST_ADD_MEDICINE } from '../actions/action-types';
import { trialsAction } from '../actions/trials';
import Strings from '../Strings';
import { HEADER_BUTTON_BLUE, HEADER_BUTTON_WHITE } from '../constants';

const today = moment()
  .endOf('day')
  .toDate();
const weekAgo = moment()
  .subtract(8, 'day')
  .startOf('day')
  .toDate();
const yearAgo = moment()
  .subtract(1, 'year')
  .startOf('day')
  .toDate();
const getDateFilter = state => state.filter.dateFilter;

const mapStateToProps = (state, props) => {
  const { patientDetail } = state.entities;
  const date = Date.parse('');
  const maxDate = new Date(Math.max(date || today, Number(today)));
  const minDate = new Date(Math.min(date || yearAgo, Number(yearAgo)));

  const isDoctor = state.auth.role === 'doctor';
  const { events } = state.entities.trials;

  return {
    patient: patientDetail.patientProfile,
    trials: state.entities.trials.trial,
    selectedPatient: state.entities.patients.patient,
    isPatientProfileLoading: patientDetail.loading,
    readOnlyMode: state.auth.read_only,
    isLoading: false,
    maxDate,
    minDate,
    dateFilter: getDateFilter(state, props),
    timedeltaPeriod: {},
    isDoctor,
    events,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  loadPatientDetail: () => dispatch(patientAction.actionPatientDetail(props.match.params.id)),
  onNavigate: path => dispatch(push(path)),
  onChangeDateFilter: payload =>
    dispatch(
      changeFilter('dateFilter', {
        ...payload,
        isChanging: payload.isChanging || false,
      }),
    ),
  onChangeTimedeltaFilter: payload => dispatch(changeFilter('timedeltaPeriod', { ...payload })),
  onLoadDeviceData: (start, end) =>
    dispatch(
      patientAction.actionGetDeviceData({
        id: props.match.params.id,
        start_date: start,
        end_date: end,
      }),
    ),
  clearEntities: (entities = null) => {
    dispatch(clearEntitiesAction(entities || ['patients', 'memberships', 'doctors', 'patientCaregivers']));
  },
  updatePatientProfile: data => dispatch(patientAction.actionUpdateProfile(props.match.params.id, data)),
  updatePatientNotifications: data => dispatch(patientAction.actionUpdateNotifications(props.match.params.id, data)),
  onAddCaregiver: () =>
    dispatch(
      openModalAction('add-caregiver', {
        patient: props.match.params.id,
        action: patientAction.addCaregiver,
        actionType: POST_ADD_CAREGIVER,
      }),
    ),

  downloadPatientData: () => dispatch(patientAction.downloadPatientData()),
  reloadTimestamp: () => dispatch(trialsAction.reloadTimeStampGraph()),
  onAddMedicine: () =>
    dispatch(
      openModalAction('edit-rpm-schedule', {
        patient: props.patientDetail,
        action: patientAction.addMedicine,
        actionType: POST_ADD_MEDICINE,
      }),
    ),

  onEditProfile: () =>
    dispatch(
      openModalAction('edit-profile', {
        patientId: props.match.params.id,
        action: patientAction.actionUpdateProfile,
        actionType: UPDATE_PATIENT_SETTINGS,
      }),
    ),
});

class Patient extends PureComponent {
  static defaultProps = {
    activeTab: 'Medications',
    dateFilter: {
      supremum: moment()
        .endOf('day')
        .toDate(),
      infimum: moment()
        .subtract(1, 'month')
        .startOf('day')
        .toDate(),
    },
    timedeltaPeriod: {
      start: weekAgo,
      end: today,
    },
  };

  static propTypes = {
    loadPatientDetail: PropTypes.any,
    downloadPatientData: PropTypes.func,
    match: PropTypes.object,
    loadSchedules: PropTypes.bool,
    reloadTimestamp: PropTypes.func,
    onNavigate: PropTypes.any,
    onChangeDateFilter: PropTypes.func,
    patient: PropTypes.object,
    isPatientProfileLoading: PropTypes.bool,
    maxDate: PropTypes.instanceOf(Date).isRequired,
    minDate: PropTypes.instanceOf(Date).isRequired,
    activeTab: PropTypes.string,
    readOnlyMode: PropTypes.bool,
    updatePatientProfile: PropTypes.func,
    isLoading: PropTypes.bool,
    onAddCaregiver: PropTypes.func,
    isDoctor: PropTypes.bool,
    trials: PropTypes.array,
    selectedPatient: PropTypes.object,
    onAddMedicine: PropTypes.func,
    onEditProfile: PropTypes.func,
    dateFilter: PropTypes.any,
    timedeltaPeriod: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.onTrialSelected = this.onTrialSelected.bind(this);
    this.onTimeStampReload = this.onTimeStampReload.bind(this);
    this.state = { isMounted: false };
  }

  componentDidMount() {
    this.props.loadPatientDetail();
  }

  onDownloadBtnClickSuccessHandler = req => {
    const fileName = req.response && req.response.name ? req.response.name : 'doc.zip';
    downloadFileOnly(req, fileName);
  };

  onDownloadBtnClick = () => {
    this.props.downloadPatientData().then(this.onDownloadBtnClickSuccessHandler);
  };

  onTimeStampReload() {
    this.props.reloadTimestamp();
  }

  onTrialSelected(trialId) {
    this.props.onNavigate(`/study/${trialId}/overview`);
  }

  setDateFilterSettings(props) {
    const { maxDate, minDate } = props;
    const infimum = moment(props.dateFilter.infimum);
    const supremum = moment(props.dateFilter.supremum);

    const newRange = {};
    if (infimum > maxDate) {
      newRange.infimum = moment(maxDate)
        .subtract(1, 'month')
        .toDate();
    }

    if (infimum < minDate) {
      newRange.infimum = minDate;
    }
    if (supremum > maxDate) {
      newRange.supremum = maxDate;
    }

    if (Object.keys(newRange).length > 0) {
      this.props.onChangeDateFilter(newRange);
    }
  }

  getHeaderComponents() {
    const { activeTab, onEditProfile } = this.props;

    // We have download button everywhere except Info tab
    const btnDownloadPatientData = (
      <Button class={HEADER_BUTTON_BLUE} onClick={this.onDownloadBtnClick} title={Strings.downloadPatientData} />
    );

    switch (activeTab) {
      case 'Cap data':
        return (
          <React.Fragment>
            <Button onClick={this.onTimeStampReload} title={Strings.reloadGraph} class={HEADER_BUTTON_WHITE} />
            {btnDownloadPatientData}
          </React.Fragment>
        );
      case 'Info':
      case 'Profile':
        return <Button class={HEADER_BUTTON_BLUE} onClick={onEditProfile} title={Strings.editPatientInfo} />;
      default:
        return btnDownloadPatientData;
    }
  }

  onBackButtonPatients = () => {
    this.props.onNavigate('/patients');
  };

  onBackButtonStudy = () => {
    const trialId = this.props.match.params.trialId;
    this.props.onNavigate(`/study/${trialId}/patients`);
  };

  makeBackButton() {
    const { isDoctor } = this.props;
    const trialId = this.props.match.params.trialId;
    if (!isDoctor) return null;
    return trialId ? this.onBackButtonStudy : this.onBackButtonPatients;
  }

  render() {
    const {
      patient = {},
      isPatientProfileLoading,
      maxDate,
      minDate,
      activeTab,
      readOnlyMode,
      updatePatientProfile,
      isLoading,
      isDoctor,
      trials,
      selectedPatient,
    } = this.props;

    const patientId = this.props.match.params.id;
    const trialId = this.props.match.params.trialId;
    const pathString = trialId ? `/study/${trialId}` : '';
    let studyName = '';
    if (trialId) {
      studyName = trials ? trials[trialId].studyName : '';
    } else if (selectedPatient) {
      studyName = selectedPatient[patientId].studyName;
    }

    const { isMounted } = this.state;

    if (!patient) {
      return <LoadingRenderer loading />;
    }

    const patients = {};
    patients[patient.id] = patient;

    const sliderPicker = <SliderPicker min={minDate} max={maxDate} />;
    return (
      <div className="patient-page-content">
        <PageHeader
          onBackClick={this.makeBackButton()}
          title={patient.fullName ? patient.fullName + (studyName ? ` [${studyName.trim()}]` : '') : ''}
          getHeaderComponents={() => this.getHeaderComponents()}
        />

        <TabView key="tabs" activeTab={activeTab} routeMode className="patients-tab">
          <Tab name="Medications" path={`${pathString}/patient/${patientId}/overview`}>
            {sliderPicker}
            <Medications patientId={patientId} isLoading={isLoading} isMounted={isMounted} />
          </Tab>

          {isDoctor ? (
            <React.Fragment>
              <Tab name="History" path={`${pathString}/patient/${patientId}/overview/history`}>
                <HistoryTab patientId={patientId} />
              </Tab>
              <Tab name="Cap data" path={`${pathString}/patient/${patientId}/overview/smart-cap`}>
                {patient.preference ? <TimeStamp patientId={patientId} timezone={patient.preference.timezone} /> : null}
              </Tab>
              <Tab name="Time Delta" path={`${pathString}/patient/${patientId}/overview/timedelta`}>
                <TimeDelta patientId={patientId} />
              </Tab>
              <Tab name="Info" path={`${pathString}/patient/${patientId}/overview/info`}>
                <WithLoader isLoading={isPatientProfileLoading} isMounted={this.state.isMounted}>
                  {!isPatientProfileLoading ? (
                    <PatientProfileForm
                      isPermit
                      patient={patient}
                      readOnlyMode={readOnlyMode}
                      updateProfileFunc={updatePatientProfile}
                      actionType={UPDATE_PATIENT_SETTINGS}
                    />
                  ) : null}
                </WithLoader>
              </Tab>
            </React.Fragment>
          ) : (
            ''
          )}
        </TabView>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Patient);
