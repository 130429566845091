import { WhiteBackground } from 'containers/WhiteBackground';
import React from 'react';

import Strings from '../../Strings';

const EsiPatientPrivacy = () => {
  return (
    <WhiteBackground noLines title={Strings.privacyPolicy}>
      <div className="article-container">
        <div className="article-style">
          <p className="normal-text">
            <span>
              This Privacy Policy is provided by your plan sponsor’s pharmacy benefit manager (or underwriting insurance
              company as applicable) or, if not applicable, Express Scripts Holding Company (in either case, “
              <b>Express Scripts</b>” “<b>we</b>”, “<b>us</b>” or “<b>our</b>”) to all visitors (“<b>you</b>” or “
              <b>your</b>”) who use the Express-Scripts.com site available through
            </span>
            <span>
              <a href="http://www.Express-Scripts.com">
                <span>http://www.Express-Scripts.com</span>
              </a>
            </span>
            <span className="MsoHyperlink">
              <span> </span>
            </span>
            <span>
              (the “<b>Site</b>”) including Registered Users of the Registered User Website. The Site may be accessed
              via the World Wide Web, via a mobile application, or through a website or application hosted by a third
              party or one of our affiliated companies. The Site is provided to you as a service to provide information
              about our company and, as applicable, your pharmacy benefit management of your User account and access to
              mail order pharmacy services and includes the Registered User Website. The “<b>Registered User Website</b>
              ” (or simply “<b>Website</b>”) is a registered user-only account portal available through the Site which
              enables Registered Users to access their personal account information and to use certain other services
              offered only to them.
            </span>
          </p>

          <p className="normal-text">
            <span>&nbsp;</span>
          </p>

          <p className="normal-text">
            <span>
              &quot;<b>Users</b>&quot; are persons utilizing the Website and include Members. &quot;<b>Members</b>
              &quot; (or &quot;
              <b>Beneficiaries</b>&quot; in the case of the TRICARE program) are individual participants in a
              prescription drug benefit plan. &quot;<b>Registered Users</b>&quot; are persons who have registered for
              use of the Website and include Registered Members. Individual Members of an applicable prescription drug
              plan administered by Express Scripts on behalf of a plan sponsor who have successfully submitted a
              completed account registration form on the Site, or have completed the appropriate registration process
              from their plan sponsor’s website, or have registered via telephone with a customer service
              representative, or are otherwise qualified as a beneficiary under the TRICARE program and registered
              through an available mechanism are all &quot;<b>Registered Members</b>&quot; (or “
              <b>Registered Beneficiaries</b>&quot; in the case of the TRICARE program)
            </span>
            <b>
              <span> </span>
            </b>
            <span>
              who will be provided access to the Website after completing the registration process. <b>Cardholders</b>
              &quot; (or &quot;<b>Sponsors</b>&quot; in the case of the TRICARE program) are Members of a prescription
              drug benefit plan who carry the membership under their name for themselves and their Covered Household
              Members. &quot;<b>Covered Household Members</b>&quot; mean individuals who are covered through the
              prescription drug benefit plan under the Member’s account. &quot;<b>Spouse</b>&quot; means a Covered
              Household Member who is designated as a spouse in accordance with the terms of the prescription drug
              benefit plan. &quot;
              <b>Adult Dependent</b>&quot; means a Covered Household Member who is eighteen years of age or older.
              &quot;<b>Minor Dependent</b>&quot; means a Covered Household Member who is under the age of eighteen.
            </span>
          </p>

          <p className="normal-text">
            <span>&nbsp;</span>
          </p>

          <p className="normal-text">
            <span>
              We are firmly committed to protecting the confidentiality and security of your Personal Information. The
              term &quot;<b>Personal Information</b>&quot; means any information which can be used to identify a person
              including by way of example, but not limitation, name, date of birth, mailing address, social media and
              other third party platform account identifiers, home phone number, mobile phone number, e-mail address,
              credit card information, and/or Social Security number. The term <b>“Health Information&quot;</b> means
              any information, in any form, related to the past, present, or future health or medical status, condition,
              or treatment of a person, including, by way of example, but not limitation, names of doctors, health
              conditions, medicines, and/or prescription information and history.
            </span>
          </p>

          <p className="normal-text">
            <span>&nbsp;</span>
          </p>

          <p className="statement">
            <span>
              In addition to this Privacy Policy, the “<b>Notice of Privacy Practices</b>” describes how we may use and
              disclose Health Information, and your rights to access and update your Health Information and how to
              request restrictions on our use and disclosure of your Health Information. To the extent any terms in this
              Privacy Policy conflict with any terms in the Notice of Privacy Practices, the conflicting terms in the
              Notice of Privacy Practices will control and override the corresponding terms in this Privacy Policy.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>
                COLLECTION, USE, AND DISCLOSURE OF PERSONAL INFORMATION AND HEALTH-RELATED PERSONAL INFORMATION
              </span>
            </b>
          </p>

          <p className="normal-text">
            <b>
              <span>How We Collect Your Personal Information </span>
            </b>
          </p>

          <p className="normal-text">
            <b>
              <span>Site Registration</span>
            </b>
          </p>

          <p className="normal-text">
            <span>Registration is optional; however, Registered Users</span>
            <b>
              <span> </span>
            </b>
            <span>
              are provided access to the Registered User Website and to information and online services not provided on
              the public website, as well as the ability to login to the Website when revisiting the Site. The Personal
              Information and Health Information you disclose to us during registration and in connection with the
              Website are provided strictly on a voluntary basis. We may also collect Non-Personal Information during
              the registration process as described below. Registered Members or Registered Beneficiaries may be asked
              to provide us with the Personal Information and/or Health Information of one or more of their Covered
              Household Members. In some instances, you may provide Personal Information and/or Health Information about
              your family members to enable utilization of certain Site functionality on their behalf.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Website Role</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              When you are qualified, you may register for access to the Registered User Website using your Personal
              Information. Depending on the role assigned to you during Website registration and thereafter, differing
              Website functionality may be made available to you. For example, the Website functionality available to a
              Registered Member and a Registered User who is not a Registered Member may differ. In some instances, you
              may be able to register for access to the Website (i) before you are a plan member so that we can utilize
              your Personal Information during an open enrollment, (ii) to enable you to take advantage of Website
              functionality when your membership becomes active, or (iii) otherwise. If you have multiple accounts or
              roles with us (e.g., based on current membership in a first plan and past membership in a second plan or,
              as a member and as a caregiver), you may be able to switch between the different accounts/roles while on
              the Website.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Utilization of Third Party Platform Login Credentials</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              Users may register for the Website by creating login credentials used for the site (“
              <b>Express Scripts credentials</b>”), or by using existing login credentials associated with an approved
              third party platform (“<b>third party credentials</b>”). Both types of login credentials once associated
              with the account of the Registered User may be interchangeably used to access the Website. You may also be
              able to register for access to the Website through multiple third party platforms. Regardless of the login
              credentials used, your account associated with the Website is the same.
            </span>
          </p>

          <p className="normal-text">
            <span>
              If you reset your third party credentials directly with the third party platform, the resetting should not
              affect your access to the Website. By using third party credentials to access the Site, certain Personal
              Information and Non-Personal Information may be provided to us by the third party platform or otherwise.
              We may use any received Personal Information and Non-Personal Information in a manner consistent with this
              Privacy Policy.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Communication Functionality</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              Certain portions of the Site may be available to you that include communication functionality. The
              communication functionality enables real-time communication sessions with Express Scripts personnel or
              other persons on behalf of or in conjunction with Express Scripts (“<b>Authorized Persons</b>”). When
              used, certain Personal Information, Health Information, and/or Non-Personal Information may be shared with
              or collected by the Authorized Persons depending upon the nature of the communication session. In certain
              instances, you may be able to selectively grant permission(s) to an Authorized Person to participate with
              you in interacting with the Site. Certain communication functionality may be provided on the Site for
              limited purposes, and the Authorized Persons will be unable to provide assistance beyond such purposes.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Purchases</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              In addition to providing Personal Information during the registration process, you may provide us with
              Personal Information or Health Information on the checkout page of the Site when ordering and paying for
              products and/or if you choose to purchase products or services using our “e-check” electronic funds
              transfer program or through an automatic refill option (if available to you).
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Guest Authentication and Functionality</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              Certain functionality on the Site requires login to the Registered User Website. Other functionality may
              be available without the use of login credentials. However, depending on the nature of the non-login
              functionality (“guest functionality”), you may be required to authenticate yourself (“guest
              authentication”). For example, Site features such as requesting a refill, checking order status, and
              paying a bill may be performed by logging into the Website or by use of guest functionality. If you have
              not previously registered for access to the Website, we will not use Personal Information captured during
              guest authentication to register you for the Website unless you have requested that we do so.
            </span>
          </p>

          <p className="normal-text">
            <span>
              In general, we will not use the Personal Information collected during guest authentication to update your
              profile unless otherwise indicated. However, we may utilize the captured Personal Information to provide
              the associated guest functionality. For example, an e-mail address provided while requesting a refill
              using guest functionality may be used to confirm that your refill has been shipped. In certain instances
              when using guest functionality, we may communicate with you using your communication preferences and/or
              Personal Information contained in your profile or otherwise available to us.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Claim Processing</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              As applicable, we use your Personal Information and Health Information to process your prescription drug
              claims in accordance with membership in your Express Scripts drug benefit plan. When available, you may
              submit certain claims for previously filled prescription drugs through the Website instead of mailing them
              to us. We may request additional Personal Information and/or Health Information to process your claims.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Designated Caregiver</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              When available, you may be able to assign a designated caregiver (“<b>Caregiver</b>”) in the ”Profile”
              section of the Registered User Website to act on your behalf with various Express Scripts functions. We
              will request that you provide certain Personal Information about this individual. The Personal Information
              may include the individual&apos;s full name and date of birth which will be used by us to properly
              identify your Caregiver when he or she contacts us on your behalf. In certain instances, the Caregiver
              when so designated may receive Site and other notifications that would otherwise be sent to you. Other
              features and functionality may be available to the Caregiver based on your designation.
            </span>
          </p>

          <p className="normal-text">
            <span>
              The mechanism that you use to designate the Caregiver may affect the Caregiver functionality available to
              you and/or the Caregiver, and the actions on your behalf that the Caregiver may make. For example,
              designating a Caregiver via the Website may enable a lesser number of actions the Caregiver can make on
              your behalf while compared to designating a Caregiver via legal designation. In some implementations, a
              Caregiver designated via letter can only be revoked by calling us, while a Caregiver designated via the
              Website can be revoked via the Website or by calling us.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Contacting Us</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              After login, you may contact us by selecting the Contact Us portion of the Website. In this portion of the
              Website, you may be able to communicate more directly regarding specific issues to designated personnel at
              Express Scripts. Your use of this feature is in accordance with any additional posted terms and
              restrictions including, but not limited to, turnaround time for our response. Do not use this feature if
              you are experiencing an emergency or are out of medication; contact your doctor, other healthcare
              provider, or us as appropriate.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Communications</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              (A) <u>Introduction to Communications and Preference Setting</u> We constantly seek to improve our ability
              to communicate with you in more effective ways. Our communications to you may provide, as applicable, (i)
              information associated with your prescription drug benefit plan, (ii) information associated with the
              order and/or delivery of prescription drugs and/or other products from our pharmacies and/or other
              providers, and (iii) other information that we have been given permission to send to you. We strive to
              send these different types of information in accordance with available communication channels, formats,
              and choices that you have expressed, in each case in compliance with applicable law. While we generally
              communicate with you in accordance with these preferences, we may sometimes use other channels and formats
              to best provide you with available services. Not all types of information and communication channels,
              formats, and choices may be available to you or honored at a particular time. For example, the
              communication preferences available to you through the Site may differ from those available to others
              depending upon your particular prescription drug benefit plan. An expressed communication preference may
              not be immediately honored for all communications associated with the preference. However, the preference
              may be honored for future communications when possible. We may also communicate with you through one or
              more communication channels to enable us to more effectively provide our services to you and, as
              applicable, on behalf of your plan sponsor.
            </span>
          </p>

          <p className="normal-text">
            <span>
              (B) <u>Additional Preference Setting</u> Certain communications may include additional ways for you to
              express preferences. When your preference is expressed in this manner, such preference may be limited to a
              certain type of information, communication channel, and/or communication format as applicable. For
              example, if you select an opt-out option available in a particular e-mail, you may only have opted-out of
              a certain type of e-mail (e.g., promotional e-mails). We encourage you to review your communication
              preferences page(s) from time to time to review your communication options and expressed preferences.
            </span>
          </p>

          <p className="normal-text">
            <span>
              (C) <u>Reviewing and Adjusting Communication Preferences</u> You may review and adjust your communication
              preferences by logging into the Website, or in certain instances, otherwise authenticating yourself. We
              may adjust the categories of communication preferences available to you through the Website from time to
              time such as at the beginning of a new plan year, in accordance with new Site features and organization,
              or otherwise. These adjustments may, in certain instances, affect which communications you receive and the
              communication channels by which you receive them. As such, we may notify you of such adjustments and
              periodically remind you to review your communication preferences.
            </span>
          </p>

          <p className="normal-text">
            <span>
              (D) <u>Service Provider Fees</u> The sending and receipt of communications in certain communication
              channels may cause you to incur messaging, data usage, or other fees from your services provider. By
              selecting such communication channels, you agree that you are solely responsible for these fees.
            </span>
          </p>

          <p className="normal-text">
            <span>
              (E) <u>Initial Preference Selections</u> By registering for use of the Website, we will preselect initial
              communication preferences. These initial preferences include various communication categories for
              communicating with you through your provided e-mail address and, as applicable, through our secure message
              center and/or encrypted messages. In addition, without registering for or logging into the Website, when
              available you can authenticate yourself, provide us with your e-mail address, and agree to this Privacy
              Policy and the Terms of Use for the Site to also enable the aforementioned electronic communications.
              These initial communication preferences will provide you with certain communications through e-mail that
              were previously provided by automated outbound telephone calls or mail, and some additional communications
              that you would not have otherwise received. If you have previously expressed preferences to receive
              communications through a different communication channel, your preferences will be retained. You may
              modify your communication preferences on the communication preferences page of the Website. For example,
              if you do not wish to receive e-mails or certain types of e-mails from us, you may so designate on the
              communication preferences page. In general, once you have authorized us to utilize a particular manner of
              communicating with you, we may cease communicating with you through other communication channels, except
              as otherwise provided for in this Privacy Policy or as required by law. We may modify the communication
              categories associated with your initial communication preferences to better service you. If you modify
              your initial communication preferences, you may need to login to the Website or otherwise authenticate
              yourself to modify your communication preferences associated with the communication categories.
            </span>
          </p>

          <p className="normal-text">
            <span>
              (F) <u>Plan/program preferences</u>. While many Registered Users have a single plan or program with us,
              some Registered Users may have more than one. When you have multiple plans/programs, availability of
              certain communication types may differ based on the applicable plan/program. We will then send
              communications with the applicable plan/program under which the communication would fall and your
              communication preferences in accordance with applicable law.
            </span>
          </p>

          <p className="normal-text">
            <span>
              (G) <u>Secure Message Center</u> Communications may be provided to you through our secure message center.
              You may be notified of these communications through another communication channel that you have
              designated. In some instances, you may be able to select secure message center communications as part of
              your communication preferences. When secure message center communications is selected, we may provide
              certain information associated with your prescription drug benefit plan and information associated with
              the order and/or delivery of prescription drugs and/or other products from our pharmacies and/or other
              providers that would otherwise be provided to you through mail. You will be alerted of the availability of
              such information so that you can conveniently and securely access it. For example, we may send you an
              e-mail to notify you that a communication including prescription drug information for a prescription drug
              that has been shipped to you is available in the secure message center.
            </span>
          </p>

          <p className="normal-text">
            <span>
              (H) <u>Encrypted Communications</u> Depending upon program/plan preference, you may be able to receive an
              email containing an encrypted message. If you agree to receive such encrypted communications, you may
              receive certain encrypted messages electronically instead of through other channels. You may opt out of
              receiving further encrypted communications by clicking an “opt-out” link included with the encrypted
              message or, when available, via modifying your communication preferences.
            </span>
          </p>

          <p className="normal-text">
            <span>
              (I) <u>In-App Communications</u> When our mobile application (“app”) is installed and you login to the
              Website, we may automatically provide you with certain in-app communications. These in-app or “push”
              communications may continue to be provided while the app is installed on your mobile device. For example,
              we may send you an in-app communication to ask about your experiences with the app. By uninstalling the
              app from your mobile device, such in-app communications will automatically terminate. In some instances,
              you may be able to select mobile application communications as part of your communication preferences to
              receive other communications (e.g., communications including information associated with your prescription
              drug benefit plan) through your app. If you uninstall the app but have not modified your communication
              preferences, we will send these communications to you through another available communication channel.
            </span>
          </p>

          <p className="normal-text">
            <span>
              (J) <u>Non-Preference Communications</u> We seek to provide your communications in an efficient and
              effective manner. We may selectively utilize known communication channels to communicate with you even
              when the channels are not designated communication preferences. We may also contact you regarding a
              particular issue or through a particular communication channel despite an otherwise stated communication
              preference. In certain instances, we may communicate with you through a different communication channel
              than an expressed communication preference, or we may utilize multiple communication channels to reach
              you. Examples of when we may not follow your communication preferences include when required by law, when
              a communication channel is or becomes unavailable, when we are unable to reach you by your preferred
              communication channel, when you have reached out to us by a particular communication channel and we
              respond by communicating with you through the same communication channel, when your communication
              preferences have not been designated, or in the case of an emergency or other extenuating circumstance.
              You may continue to use all communication channels available to you to reach us regardless of your
              communication preferences.
            </span>
          </p>

          <p className="normal-text">
            <span>
              (K) <u>Preference Setting for Others</u> Depending on the functionality available to you through the Site,
              you may be able to set communications preferences (i) at an individual level so that they are only
              applicable to you, (ii) at a partner level so that they are applicable both for you and your designated
              partner, or (iii) at a family level when you are the Cardholder (or Sponsor) so that the communication
              preferences are applicable to your Covered Household Members.
            </span>
          </p>

          <p className="normal-text">
            <span>
              (L) <u>Communications to be received on behalf of others</u> Certain Users may designate others to receive
              all or certain communications on their behalf. In such situations, the User generally may then cease to
              receive such communications. When available, the designation may be made by selecting a Caregiver,
              granting certain access to a Cardholder and/or a Covered Household Member, or otherwise. For example, if a
              User has a prescription and another person is authorized by the user to request fulfillment of the
              prescription drug on behalf of the User, the person making the order may receive the notification instead
              of the User.
            </span>
          </p>

          <p className="normal-text">
            <span>
              (M) <u>Communications through third party systems</u> We may work with certain third parties to provide
              your communications more efficiently. When available and you so select, we may send at least a portion of
              your communications through such channels as permitted by your plan/sponsor/program and in accordance with
              applicable law.
            </span>
          </p>

          <p className="normal-text">
            <span>
              <br />
              (N) <u>Communications including Health Information</u> You may also be able to designate certain uses of
              your Health Information with our communications. For example, you may be able to designate whether certain
              Health Information can be included in communications to you (or your Caregiver) regarding your
              prescription drug benefit plan and order and/or delivery of prescription drugs. You may also be able to
              authorize use of your Health Information to send you promotional communications with information and
              opportunities that may be of interest to you. For example, such promotional communications could include
              the opportunity to purchase additional products, engage in promotional surveys, obtain additional health
              and wellness information, and the like.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Web Profile Maintenance and Termination</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              Your web profile that enables access to the Website will generally be active after registration. If your
              coverage with Express Scripts is termed (e.g., you are no longer a Member), you may continue to utilize
              your web profile to obtain account access for a certain period of time. After such period of time elapses,
              your web profile is subject to deletion. If you later regain coverage (e.g., you again become a Member),
              you may be required to create a new web profile to regain access to the Website. Depending on the length
              of time between losing and regaining coverage, you may be able to access your prior history for a period
              of time. If you choose to manually delete your web profile, your online access to the Website will
              terminate. However, we will continue to communicate with you in accordance with your previously
              established communication preferences. You can modify such preferences by re-registering for access to the
              Website, following instructions contained within an e-mail, or otherwise.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Social Media</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              When you communicate with us through social media, or provide a comment directed at us through social
              media, we may use social media to communicate with you. We may also directly communicate with you through
              social media in accordance with any expressed social media preferences in your communication preferences.
              We may also promote content of interest to you through social media. You may opt out or configure your
              social media account settings to limit promotion of such content.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Analytics</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              We may use certain in-house or third-party functionality to log and analyze your communications with us
              and interactions with the Site. This functionality enables us to communicate with you about our services,
              and to monitor the services provided to you, so that we can improve your Site experience and address
              certain Site or benefit related issues. These third parties will be required to protect your Personal
              Information and Health Information in a manner consistent with this Privacy Policy. Other analytics
              capabilities are reflected in the description of Non-Personal Information.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Other Uses and Disclosure of Your Personal Information </span>
            </b>
            <span>
              We will not use or disclose your Personal Information or your Health Information in a manner inconsistent
              with applicable law, this Privacy Policy or the Notice of Privacy Practices. Examples of our uses and
              disclosures include:
            </span>
          </p>

          <p className="normal-text">
            •
            <span>
              As applicable, we will manage your prescription benefits and process your prescription drug claims. This
              process may involve sharing certain Personal Information and/or Health Information with you and/or your
              doctor, pharmacist, health plan or plan administrator. These disclosures are made in accordance with the
              terms of your health plan or prescription benefit plan.
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              We may use your Personal Information and Health Information to process your requests for prescription drug
              pricing estimates.
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              We will use your Personal Information to process and send orders that have been placed through our
              pharmacy.
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              If you choose to use a third party payment solution, we may utilize your Personal Information to
              communicate with the third party payment provider to facilitate the transaction.
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              We may share your Personal Information with selected service providers or consultants acting on our
              behalf. Those third parties will be required to protect your Personal Information in a manner consistent
              with this Privacy Policy.
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              In accordance with the terms of your health plan or federal regulations, we may share information to
              facilitate utilization of potentially applicable patient assistant programs such as those that are offered
              or may be offered by manufacturers, pharma, state health plans, and charities to process secondary claims
              on your behalf and potentially lower your cost share.
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              We may utilize selected service providers to make targeted non-personal communications to an aggregated
              audience regarding our offerings and other potentially relevant benefit information of interest to you.
              These communications will not be based on your Health Information.
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              We may share your Personal Information with other Express Scripts companies (i.e., entities which are
              controlling, controlled by, or under common control with Express Scripts) to provide you with more
              personalized and enhanced services.
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              We generally manage your Personal Information separately from the Personal Information held by your plan
              sponsor. However, from time to time and at our discretion, we may communicate with your plan sponsor or
              payor to enable verification and/or correction of your Personal Information for benefit communication
              purposes.
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              We may disclose your Personal Information to relevant third parties such as state and federal regulatory
              agencies, auditors, lawyers, or other professional advisors.
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              We may act on behalf of or otherwise cooperate with your health plan or plan sponsor to enable sending of
              health or plan sponsor information to you using your Personal Information.
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              If you choose to use the feature, we process received login credentials stored within a third party device
              (e.g., Apple Touch ID) to facilitate login to the Website.
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              We may use your Personal Information and Non-Personal Information in communicating with you via e-mail,
              facsimile, letter, text message, mobile application, through the Site via a pop-up message, or otherwise.
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              We may use third-party agents for purposes of communicating with you and/or collecting information from
              you.
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              Statements here and elsewhere on the Site concerning the treatment of your Personal Information and Health
              Information may not apply with respect to information already in our possession.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Compelled and Necessary Disclosures</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              In certain circumstances, we may be legally compelled to release your or your Covered Household Member’s
              Personal Information in response to a court order, subpoena, search warrant, or law or regulation, or the
              terms of the Notice of Privacy Practices. In addition, we may disclose your Personal Information and
              Health Information as reasonably necessary in accordance with law to protect the rights or property of us,
              our affiliates, and our users, or to enforce the terms and conditions associated with the Site including
              this Privacy Policy and the Terms of Use.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Covered Household Members Personal Information and Health Information</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              Our Website may include features through which Registered Members may view their own Health Information
              (such as their prescription history) and their Personal Information. In some cases, Cardholders may use
              the Website to view Health Information and Personal Information or manage the benefit on behalf of any
              Covered Household Members. Spouses and Adult Dependents may similarly register with the Website to access
              their own Health Information and Personal Information. The Cardholder, Spouse, or Adult Dependent may
              grant access to another person to view the granting person’s Health Information.
            </span>
            <span>
              Covered Household Members who are either Minor Dependents or flagged are not provided with access to the
              Website, but may continue to call the number on the back of their benefit card to transact business with
              Express Scripts.
            </span>
          </p>

          <p className="normal-text">
            <span>
              The availability of the Health Information and Personal Information of the Cardholder and other Covered
              Household Members may depend on Cardholder preferences, plan/program preferences, available Website
              functionality, and applicable law. For example:
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              the Cardholder may view the Health Information, or otherwise utilize certain benefits on behalf of, all
              Minor Dependents;
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              the Cardholder may grant access to a Spouse and/or Adult Dependents to view the Health Information and
              Personal Information or otherwise utilize certain benefits on the Cardholder’s behalf; and
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              the Cardholder may grant access to a Spouse to selectively view the Health Information and Personal
              Information of only designated Minor Dependents and utilize certain benefits on their behalf while
              preventing the viewing of the Health Information and Personal Information of other Minor Dependents and
              utilizing certain benefits on the others behalf;
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              the Adult Dependents may grant access to the Cardholder, the Spouse, and/or the other Adult Dependents to
              view their Health Information and Personal Information;
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              the Adult Dependents or Spouse may request permission from the Cardholder to view the Health Information
              and Personal Information and utilize the benefits on behalf of a Minor Dependent.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>How You Can Correct/Update Your Personal Information and/or Health Information</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              You can correct or update your Personal Information or Health Information at any time using the following
              options:
            </span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>Login to the Website and update your Personal Information.</span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>Call the Customer Service number on your Member ID card (as applicable).</span>
          </p>

          <p className="normal-text">
            •<span>&nbsp; </span>
            <span>
              Write to:
              <br />
              Express Scripts
              <br />
              Attention: KANA Team
              <br />
              One Express Way, St. Louis, MO 63121
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>COLLECTION, USE, AND DISCLOSURE OF NON-PERSONAL INFORMATION</span>
            </b>
          </p>

          <p className="normal-text">
            <b>
              <span>Collection of Non-Personal Information </span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              When you visit the Site, and during your interactions with the Site, we may collect Non-Personal
              Information from you. “<b>Non-Personal Information</b>” means a data element or collection of data
              elements that by itself cannot ordinarily be associated with a specific individual. Non-Personal
              Information includes by way of example but not limitation, the Internet browser, or operating system you
              are using, your navigation of the Site including the pages or displays of the Site that you access, the
              amount of time spent on various portions of the Site, the length and dates of your visits to the Site, and
              certain Site data captured through your interactions with the Site and other sites. Non-Personal
              Information may include information provided by you through the Site or otherwise (e.g., through a
              third-party site) that is not Personal Information or Health Information. Certain Non-Personal Information
              may be collected on an aggregated, anonymous basis through web server logs, cookies, ad servers, tracking
              pixels, web beacons, and similar Internet tracking devices (collectively “<b>Tracking Mechanisms</b>”).
              Web servers automatically collect Non-Personal Information, with your IP address, when you request pages
              or displays of the Site or other sites. Based on certain interactions with the Site, third-party sites,
              mailings, other communications with us, and/or our system configurations, certain Non-Personal Information
              may be associated with your Personal Information such that your Non-Personal Information is identifiable
              with you.
            </span>
          </p>

          <p className="normal-text">
            <span>
              You may be able to opt-out of certain third-party associations by following third party customization
              and/or opt-out options. Google®, Twitter®, and LinkedIn® may provide customization and/or opt-out of
              certain Tracking Mechanisms through their respective sites. For example, Google&apos;s Ads Settings,
              DoubleClick opt-out page, Twitter’s promoted content settings, LinkedIn account settings, and Network
              Advertising Initiative opt-out page may limit the collection and usage of certain third-party Tracking
              Mechanisms.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Use of Non-Personal Information</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              The collected Non-Personal Information may be used by us and our affiliated companies for a variety of
              analytic and developmental purposes including to improve and enhance the Site and our products and
              services, to create new products and services, to customize your experience on the Site and other sites
              that you visit on the Internet, to identify and/or offer products, services and website functionality that
              may be of interest to you, and other legitimate business purposes.
            </span>
          </p>

          <p className="normal-text">
            <span>
              We may use different kinds of cookies including session ID cookies and persistent cookies. Session ID
              cookies are used to personalize your user experience, to determine ways to improve the Site, Site content,
              and the services offered through the Site. These cookies are deleted from your hard drive when you close
              your browser session. Persistent cookies are used to collect non-personally identifiable information such
              as Internet Protocol (IP) addresses, browser type, Internet Service Provider (ISP), referring/exit pages,
              platform type, date/time stamp and number of clicks.
            </span>
          </p>

          <p className="normal-text">
            <span>
              You may set your browser to accept cookies, warn you when a cookie is sent, or turn off all cookies
              (except Flash cookies). Check your web browser’s help menu or your mobile device settings to find out how.
              Some mobile devices store cookies not only in areas connected to the web browsers but also in an
              app-specific area, so you may have to check your app settings options to determine how to manage or delete
              cookies stored in these other areas. If you do not accept cookies, some features, services, or activities
              available through the Site may not function correctly and you may be unable to access certain content.
            </span>
          </p>

          <p className="normal-text">
            <span>
              We may embed tracking pixels within various pages of the Site to enable use of site analytics. The site
              analytics enable us to determine the usage frequency of various areas of the Site and identify areas of
              the Site for enhancement. While you are visiting and after you leave the Site, we may use web beacons to
              notify you of areas of the Site and other aspects of our organization and its affiliated companies in
              which you may be interested. Certain tracking pixels and web beacons may be cleared or reset through
              configuration of your web browser such as by clearing your cache. We may use ad servers to provide you
              with offers of possible interest.
            </span>
          </p>

          <p className="normal-text">
            <span>
              We use your IP address so that we can send data (such as the pages you request) to you and collect
              Non-Personal Information during the process. We aggregate this Non-Personal Information with similar
              Non-Personal Information collected from other users to track overall visitor traffic patterns and help us
              understand Site usage and preferred and most frequently used pages, products and services, to provide you
              with better service, to improve Site use and functionality, and to provide you with information on other
              products and services that may be of interest to you.
            </span>
          </p>

          <p className="normal-text">
            <span>
              When using the Site through a mobile application, we may use different kinds of software and hardware
              identifiers to personalize your user experience, to determine ways to improve the Site, Site content, and
              the services offered through the Site. These identifiers may be deleted or rendered otherwise inaccessible
              when you close your browsing session. Certain identifiers may be used to collect non-personally
              identifiable information such as IP addresses, device type and other device details, Internet Service
              Provider (ISP), operating system and other platform details, date/time stamp and number of clicks. We may
              embed certain identifiers within various displays of the Site to enable use of Site analytics. Site
              analytics enable us to determine the usage frequency of various portions of the Site and identify portions
              of the Site for enhancement.
            </span>
          </p>

          <p className="normal-text">
            <span>
              We may analyze Non-Personal Information in the aggregate to study outcomes, costs, and provider profiles,
              and to suggest benefit designs for employers or health plans. These studies may generate Aggregate Data
              (described below) which we may utilize for a variety of purposes.
            </span>
          </p>

          <p className="normal-text">
            <span>
              We may perform statistical analyses of the traffic patterns, Site usage, and behaviors associated with the
              Site. We may use these analyses to generate Aggregate Data from the original Non-Personal Information. We
              may combine, separate, aggregate, or otherwise parse and process Non-Personal Information. The parsing and
              processing of such information may generate Aggregate Data. “<b>Aggregate Data</b>” is summary level data,
              such as the number of web visitors in a specific geographic area. Aggregate Data does not contain
              information that can be used to identify or contact you, such as your name, address, telephone number or
              e-mail address, and does not reflect the original form of the Non-Personal Information collected from you.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Disclosure of Non-Personal Information</span>
            </b>
          </p>

          <p className="normal-text">
            <span>We may disclose Non-Personal Information as follows:</span>
          </p>

          <p className="normal-text">
            <span>
              • We may share Non-Personal Information with our affiliated companies, third parties who provide services
              to us, and other parties that you have authorized.
            </span>
          </p>

          <p className="normal-text">
            <span>
              • We may disclose Aggregate Data to other companies or organizations for any legitimate business purpose.
            </span>
          </p>

          <p className="normal-text">
            <span>
              • We may disclose products and services developed using the Non-Personal Information, including products
              and services that disclose anonymous and/or deidentified Site data for any legitimate business purpose.
            </span>
          </p>

          <p className="normal-text">
            <span>• We will not sell your Non-Personal Information to other companies or organizations.</span>
          </p>

          <p className="normal-text">
            <b>
              <span>“Do Not Track” Signals and Similar Mechanisms </span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              Our Site does not respond to web browser “do not track” signals and similar mechanisms. However, you may
              control certain Tracking Mechanisms as described above.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Transfer of Personal Information, Health Information and Non-Personal Information</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              All Personal Information, Health Information, and Non-Personal Information obtained through our Site are
              owned by us. Accordingly, if we are acquired, merge with another entity, or we divest one or more of our
              businesses, affiliates or subsidiary companies, the Sites, and any Personal Information, Health
              Information, and Non-Personal Information obtained through them, may be transferred to an applicable
              entity for the purposes of continuation of services, in accordance with applicable law and the Notice of
              Privacy Practices.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>RETENTION AND DESTRUCTION OF PERSONAL INFORMATION</span>
            </b>
          </p>

          <p className="label-text">
            <span>
              Subject to any applicable business, legal, or regulatory requirements, we securely destroy Personal
              Information when it is no longer required to fulfill our services and commitments to you or to enforce our
              rights or meet our obligations.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Third-Party Usage </span>
            </b>
          </p>

          <p className="label-text">
            <span>
              We may use third parties to: (a) operate and maintain the server(s) on which the Site operates, (b) enable
              login to the Website utilizing third party platform login credentials, (c) provide communication
              functionality, (d) encrypt message, (e) provide Tracking Mechanism(s) that we embed in or use with the
              Site, (f) provide advertisements and other information to you about the Site, products, and services
              through a third-party site based on a prior visit to the Site, (g) analyze communication with us and
              interactions with the Site, (h) de-identify data, and (i) collect Non-Personal Information from you (e.g.,
              on your interactions and/or experience with the Site and/or us). The third party may then share the
              Personal Information, Non-Personal Information, Aggregate Data, and/or other data with us.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Usage by Children AND ON BEHALF OF CHILDREN</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              Our Site is neither intended for nor designed to attract users who are under the age of 18. If you are
              under the age of 18, or we are not otherwise able to offer Site functionality to you because you are
              deemed a minor, do not use the Site. However, depending upon the Site functionality available to you, a
              partner, guardian, or similar legally authorized person (“<b>Authorized Person</b>”) may register for
              access to the Website and use it on your behalf. Upon turning 18, we will cease providing Website access
              to the Authorized Person. Depending on the Site functionality available to you, we may (i) permanently
              disable the Authorized Person’s account, (ii) require you to register for desired access to the Website,
              (iii) provide a notification of your options associated with the Website, (iv) request that you indicate
              whether the Authorized Person may continue to act on your behalf, (v) seek confirmation that you have
              taken over the account for access to the Website on behalf of the Authorized person, and/or (vi) otherwise
              communicate with you and/or the Authorized Person in accordance with applicable law, your communication
              preferences, your health plan’s preferences, or otherwise.
            </span>
          </p>

          <p className="normal-text">
            <span>
              We are committed to preventing the unintentional collection of Personal Information and Health Information
              from children under the age of 13. Any Personal Information and Health Information of a child under 13
              that is provided to us must be provided by a parent or legal guardian, and not by a child under the age of
              13 who is using the Site.
            </span>
          </p>

          <p className="normal-text">
            <span>
              If you are the parent or legal guardian of a child under the age of 13 whom you have reason to believe has
              provided his or her own Personal Information or Health Information to us, you have the right to review and
              request the removal of that child&apos;s Personal Information and/or Health Information from our database.
              In order to request such removal, please send an e-mail to{' '}
            </span>
            <span>
              <a href="mailto:privacy@express-scripts.com">
                <span>privacy@express-scripts.com</span>
              </a>
            </span>
            <span>
              . You will be required to verify your identity as the child’s parent or legal guardian in order to view
              their Personal Information or Health Information or have it removed.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Linking Policy</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              Our Site may contain hyperlinks allowing our users to connect to other websites owned by us and our
              affiliated companies and websites owned by our third-party vendors, distributors, and providers (“
              <b>Linked Sites</b>”). You may also access our Site through a hyperlink embedded in a Linked Site. We
              provide hyperlinks to the Linked Sites to enable you to conveniently access websites that may be of
              interest to you. Please note that once you click on a hyperlink that transfers you from our Site to a
              Linked Site, you have left our Site, and this Privacy Policy will immediately cease to apply to any
              subsequent activity on the Linked Site. We are under no obligation to notify you when you have left our
              Site and have accessed a Linked Site. Use of any Linked Site will be governed by the privacy policy, terms
              of use, and/or other policies (if any) on the Linked Site. You may, at your option, participate in surveys
              or provide other information to our affiliates that control a Linked Site, and that information may be
              shared with us or with others subject to the privacy policy terms set forth on that Linked Site. Certain
              Non-Personal Information that you choose to provide through a Linked Site (such as comments) that is
              subsequently provided to us by the Linked Site provider in connection with a service engagement may be
              identifiable to us as your Personal Information.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>SECURITY STATEMENT</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              We are committed to protecting the privacy and security of this Site. We take reasonable technical and
              procedural precautions to protect the information received by us. Our Internet infrastructure is protected
              using industry recognized commercial security products, including current encryption technology, and best
              practice procedures for maintenance of the website. In addition, our infrastructure is monitored 24 hours
              a day, seven days a week.
            </span>
          </p>

          <p className="normal-text">
            <span>
              No method of transmission over the Internet or storage of data on an Internet server is 100% secure.
              Although we use commercially acceptable and reasonable precautions to protect your information, we do not
              guarantee its absolute security.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Your Acceptance of this Privacy Policy</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              You are deemed to have assented to the terms and conditions contained in this Privacy Policy when you use
              the Site and/or when you have indicated in your online registration that you accept the Terms of Use into
              which this Privacy Policy is incorporated. You are deemed to have read and accepted this Privacy Policy
              each time you access the Site and/or the Website after initial registration by using your login
              credentials. If you do not agree to the terms of this Privacy Policy, please do not use the Site. The
              terms and conditions contained in this Privacy Policy are subject to and may be superseded by applicable
              Federal and State laws.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>RELATIONSHIP TO THE TERMS OF USE</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              This Privacy Policy, and your and our performance in connection herewith, is further governed by and
              subject to the Terms of Use for the Site, including but not limited to the disclaimer, limitation of
              liability, governing law, jurisdiction, and venue provisions set forth therein.
            </span>
          </p>

          <p className="normal-text">
            <b>
              <span>Changes in Our Privacy Policy</span>
            </b>
          </p>

          <p className="normal-text">
            <span>
              We use Personal Information, Health Information, and Non-Personal Information collected from you pursuant
              to the Site only within the scope of use described in this Privacy Policy. However, we reserve the right,
              from time to time in our sole and absolute discretion, to change, to modify, or to add terms or remove
              terms from this Privacy Policy. Changes to this Privacy Policy will be reflected when we post a new
              version number and updated revision date. The version number includes a major number, a decimal point, and
              a minor number. A change to the major number reflects a significant change to the policy, while a change
              to the minor number reflects a less significant change to the policy. Examples of significant changes
              include additional provisions that reflect new Site functionality, significant modifications to existing
              provisions, and more significant changes to Site functionality that cause provisions to be modified,
              added, or removed. Examples of less significant changes include additional provisions that clarify current
              Site functionality, minor modifications to existing provisions, and less significant changes to Site
              functionality that cause provisions to be modified, added, or removed.
            </span>
          </p>

          <p className="normal-text">
            <span>
              We will provide an advance notice of a major change prior to your access of any portion of the Site for
              which registration is required.&nbsp; For example, we may (i) require that you reaccept the updated
              version of the web policies, (ii) send an electronic notification advising of the update to the web
              policies, (iii) include a notice on the Site viewable without login advising of the update to the web
              policies, and/or (iv) advise you of the updated web policies during a phone call.&nbsp; We do not
              ordinarily provide advance notice of a minor change.
            </span>
          </p>

          <p className="normal-text">
            <span>
              We recommend that you check the version number and revision date prior to using the Site, and that you
              review this Privacy Policy on a frequent basis. Your continued use of the Site and/or utilization of any
              Site benefits after this Privacy Policy has been updated (and after advance notice for a major change)
              indicates your agreement and acceptance of the updated version of the Privacy Policy.
            </span>
          </p>

          <p className="normal-text">
            <span className="h1Text">
              <span>POLICY COMMENTS AND ASSISTANCE</span>
            </span>
            <b>
              <span> </span>
            </b>
          </p>

          <p className="normal-text">
            <span>We welcome your comments on this Privacy Policy. If you have general feedback, please </span>
            <span>
              <a href="mailto:privacy@express-scripts.com">
                <span>e-mail us</span>
              </a>
            </span>
            <span>. Specific questions regarding the enforcement of this Privacy Policy should be directed to </span>
            <span>
              <a href="mailto:privacy@express-scripts.com">
                <span>privacy@express-scripts.com</span>
              </a>
            </span>
            <span>
              . For technical assistance with the Site, please contact our technical support by calling 1-800-711-5672.
            </span>
          </p>
        </div>
      </div>
    </WhiteBackground>
  );
};

export default EsiPatientPrivacy;
