import { API_CALL } from '../../../../../middleware/api';
import { WIDGET_GET_DEVICES_COUNT } from './constants';

export const actions = {
  getDevicesCount: request => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/v1/hubs-devices-count',
      data: request,
    },
    type: WIDGET_GET_DEVICES_COUNT,
    request,
  }),
};
