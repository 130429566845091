import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import TabView, { Tab } from '../../../components/TabView';
import BLEManage from './BLEManage';
import './Devices.scss';
import HubManage from './HubManage';
import LTEManage from './LTEManage';

const mapStateToProps = state => {
  const { profile } = state.auth;
  const access = profile?.access;
  return { access };
};

const mapDispatchToProps = dispatch => ({ navigate: path => dispatch(push(path)) });

class Devices extends PureComponent {
  render() {
    const { activeTab } = this.props;
    const tabArray = [];
    const bleTab = (
      <Tab name="Bluetooth" path="/org-devices/ble">
        <BLEManage />
      </Tab>
    );

    const lteTab = (
      <Tab name="LTE" path="/org-devices/lte">
        <LTEManage />
      </Tab>
    );

    const hubTab = (
      <Tab name="Hub" path="/org-devices/hub">
        <HubManage />
      </Tab>
    );

    tabArray.push(bleTab);
    tabArray.push(lteTab);
    tabArray.push(hubTab);

    return (
      <TabView key="tabs" activeTab={activeTab} routeMode className="" noSwiping>
        {tabArray}
      </TabView>
    );
  }
}

Devices.propTypes = { activeTab: PropTypes.any };
export default connect(mapStateToProps, mapDispatchToProps)(Devices);
