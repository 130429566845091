import React from 'react';
import WithLoader from '../../../components/_hocs/withLoader/index';
import Box from '../../../components/Box';
import PropTypes from 'prop-types';
import Strings from '../../../Strings';

const StudyInfo = ({ isLoading, trial, isMounted }) => {
  return (
    <WithLoader isLoading={isLoading} isMounted={isMounted}>
      <div className="edit-study">
        <p className="title">{trial.name}</p>
        <hr></hr>

        {trial.description ? (
          <p className="description">{trial.description}</p>
        ) : (
          <div className="no-description">{Strings.noDescription}</div>
        )}
      </div>
    </WithLoader>
  );
};

export default StudyInfo;

StudyInfo.propTypes = {
  trial: PropTypes.object.isRequired,
};
