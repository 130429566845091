import merge from 'deepmerge';
import omit from 'lodash/omit';

import {
  SAVE_ADMIN_PERMISSION,
  SAVE_ADMIN_PERMISSION_RESULT,
  LIST_ADMINS,
  LIST_ADMINS_RESULT,
  LIST_ADMINS_MEMBERSHIPS,
  LIST_ADMINS_MEMBERSHIPS_RESULT,
  POST_ADMIN_INVITE,
  POST_ADMIN_INVITE_RESULT,
  POST_ADMIN_INVITE_ERROR,
  DELETE_ADMIN_MEMBERSHIP_RESULT,
  DELETE_ADMIN_MEMBERSHIP_ERROR,
  DELETE_ADMIN_MEMBERSHIP,
  RESET_ADMIN_PASSWORD,
} from '../actions/action-types';

export const adminsReducer = (state = { memberships: {} }, action) => {
  switch (action.type) {
    case LIST_ADMINS_MEMBERSHIPS:
      return {
        ...state,
        loading: true,
      };
    case LIST_ADMINS:
      return {
        ...state,
        loading: true,
      };
    case LIST_ADMINS_RESULT:
      return {
        ...state,
        objects: action.response.reduce((res, item) => {
          res[item.id] = item;
          return res;
        }, {}),
        loading: false,
      };

    case LIST_ADMINS_MEMBERSHIPS_RESULT:
      return {
        ...state,
        memberships: action.response.reduce((res, item) => {
          res[item.id] = item;
          return res;
        }, {}),
        loading: false,
      };

    case DELETE_ADMIN_MEMBERSHIP: {
      const tempAdmins = state.objects;
      if (tempAdmins) {
        const { adminId } = action;
        tempAdmins[adminId].isLoading = true;
        return {
          ...state,
          objects: tempAdmins,
        };
      }

      return {
        ...state,
        loading: true,
      };
    }

    case DELETE_ADMIN_MEMBERSHIP_RESULT: {
      if (action.response && action.response.admin) {
        const { admin } = action.response;
        const { objects } = state;
        const adminObj = omit(objects, admin);
        return {
          ...state,
          objects: adminObj,
          loading: false,
        };
      }
      return state;
    }

    case DELETE_ADMIN_MEMBERSHIP_ERROR: {
      const tempAdmins = state.objects;
      if (tempAdmins) {
        const { adminId } = action;
        tempAdmins[adminId].isLoading = false;
        return {
          ...state,
          objects: tempAdmins,
        };
      }

      return {
        ...state,
        loading: false,
      };
    }

    case POST_ADMIN_INVITE:
      return {
        ...state,
        inviteLoading: true,
      };
    case POST_ADMIN_INVITE_RESULT:
      if (action.response && action.response.id) {
        return merge(state, {
          objects: { [action.response.id]: action.response },
          inviteLoading: false,
        });
      }
      return {
        ...state,
        inviteLoading: false,
      };
    case POST_ADMIN_INVITE_ERROR:
      return {
        ...state,
        inviteLoading: false,
      };
    // case 'delete-admin-membership/result':
    //   if (action.response && action.response.id) {
    //     const membershipId = action.response.id.toString();
    //     const { [membershipId]: removedAdmin, ...otherAdmins } = state.memberships;
    //     return {
    //       ...state,
    //       memberships: otherAdmins,
    //     };
    //   }
    //   return state;
    case SAVE_ADMIN_PERMISSION:
      return {
        ...state,
        loading: true,
      };
    case SAVE_ADMIN_PERMISSION_RESULT: // eslint-disable-line no-case-declarations
      const adminId = action.response.id;
      const readOnly = action.response.read_only;

      if (adminId in state.objects) {
        state.objects[adminId].read_only = readOnly; // eslint-disable-line no-param-reassign
      }

      return {
        ...state,
        objects: state.objects,
        loading: false,
      };

    case RESET_ADMIN_PASSWORD:
      return {
        ...state,
        inviteLoading: false,
      };

    default:
      return state;
  }
};
