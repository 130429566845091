import React from 'react';
import PropTypes from 'prop-types';

import { isMobile } from 'react-device-detect';
import { PageHeader } from '../../../../components/PageHeader';
import { connect } from 'react-redux';
import PatientChart from './PatientChart';
import Strings from '../../../../Strings';

function ReadingDetails(props) {
  const [medName, setMedName] = React.useState('');
  const patientId = props.userId;

  const selectedChart = props.reading;
  const selectedMed = props.medId;

  return (
    <div className="reading-details-page">
      {isMobile && (
        <PageHeader
          onBackClick={() => history.back()}
          left={<div className="text-with-ellipsis">{selectedChart ? Strings[selectedChart] : medName}</div>}
        />
      )}
      <div className="widgetContainerExtendMargins reading-details-page-graph">
        <PatientChart
          patientId={patientId}
          selectedChart={selectedChart}
          selectedMed={selectedMed}
          patientVersion={props.patientVersion}
          setMedName={setMedName}
        />
      </div>
    </div>
  );
}

ReadingDetails.propTypes = {
  patientId: PropTypes.any,
  selectedChart: PropTypes.string,
  selectedMed: PropTypes.number,
  patientVersion: PropTypes.number,
};

const mapStateToProps = state => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    userId: state.auth.profile?.id,
    medId: parseInt(urlParams.get('medId'), 10),
    reading: urlParams.get('reading'),
  };
};

const mapDispatchToProps = dispatch => ({
  onClick: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReadingDetails);
