import { HIDE_MESSAGE, SHOW_MESSAGE } from 'actions/action-types';

export const notificationActions = {
  show: (message, timeout, isError) => ({
    type: SHOW_MESSAGE,
    payload: {
      message,
      timeout,
      isError,
    },
  }),
  hide: () => ({
    type: HIDE_MESSAGE,
  }),
};
