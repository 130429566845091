import PropTypes from 'prop-types';

import _ from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { PageHeader } from '../../../components/PageHeader';
import CapEvolution from '../../../components/Plots/cap-evolution';
import Select from '../../../components/Select';
import { DATE_FORMAT_MONTH_SPACE_YEAR } from '../../../constants';
import Strings from '../../../Strings';
import { actions } from '../Organization/redux/actions';

class HubDevicesGraph extends PureComponent {
  hubId = encodeURIComponent(this.props.match.params.id);
  deviceId = this.props.match.params.deviceId;
  pageRequest = {
    deviceId: this.deviceId,
    startDate: moment()
      .subtract(0, 'month')
      .startOf('month')
      .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    endDate: moment()
      .subtract(0, 'month')
      .endOf('month')
      .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
  };

  state = { selectedDateRange: `${this.pageRequest.startDate} ${this.pageRequest.endDate}` };

  componentDidMount() {
    this.props.loadGraph(this.pageRequest);
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  onBackButton = () => {
    const url = this.props.match.url;
    const updateURL = url.replace(`/${this.deviceId}/graph`, '');
    this.props.onNavigate(updateURL);
  };

  onDeviceChange = value => {
    this.setState({ selectedDeviceType: value }, () => {
      // this.setDeviceFilter();
    });
  };

  getHeaderComponents = () => {
    const dateRange = [];

    _.times(5, i => {
      dateRange.push({
        text: moment()
          .subtract(i, 'month')
          .format(DATE_FORMAT_MONTH_SPACE_YEAR),
        value: `${moment()
          .subtract(i, 'month')
          .startOf('month')
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]')} ${moment()
          .subtract(i, 'month')
          .endOf('month')
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]')}`,
      });
    });

    return (
      <React.Fragment>
        {dateRange.length && (
          <Select
            type={1}
            autoWidth
            classes={[Select.CLASS_BIG, Select.CLASS_DARK, Select.CLASS_NO_MARGIN_RIGHT]}
            data={dateRange || []}
            onChange={this.onDateRangeChanged}
          />
        )}
      </React.Fragment>
    );
  };

  onDateRangeChanged = value => {
    this.setState({ selectedDateRange: value }, () => {
      this.pageRequest.startDate = value.split(' ')[0];
      this.pageRequest.endDate = value.split(' ')[1];
      this.props.loadGraph(this.pageRequest);
    });
  };

  render() {
    const { data, isLoading } = this.props;
    const { selectedDateRange } = this.state;
    const infimum = moment(selectedDateRange.split(' ')[0]).toDate();
    const supremum = moment(selectedDateRange.split(' ')[1]).toDate();
    const deviceId = this.props.match.params.deviceId;

    const weightData = [];
    if (data) {
      data.map(d =>
        weightData.push({
          x: d.event_timestamp,
          y: parseFloat(d.weight),
          unit: d.unit,
        }),
      );
    }
    weightData.sort(function compare(a, b) {
      const dateA = new Date(a.x);
      const dateB = new Date(b.x);
      return dateA - dateB;
    });

    return (
      <div className="event-graph">
        <div>
          <div className="infoHeader">
            <div className="rowDirection">
              <button className="back" onClick={() => this.onBackButton()} tabIndex={0}>
                {Strings.back}
              </button>
              <div className="feildBox">
                {Strings.scale}: <div className="feildValue">{deviceId}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="details-container">
          <PageHeader getHeaderComponents={() => this.getHeaderComponents()} />
          <div className="title-graph">
            {Strings.weight} {weightData && weightData[0]?.unit && <span>({weightData[0]?.unit})</span>}{' '}
          </div>
          <div className="column full-width graph-box">
            {weightData.length > 0 && (
              <CapEvolution
                caption=""
                data={
                  weightData &&
                  weightData.map(d => ({
                    ...d,
                    x: new Date(d.x),
                    min: 0,
                    max: 200,
                    unit: d.unit,
                  }))
                }
                minDate={infimum}
                maxDate={supremum}
                isLoading={isLoading}
              />
            )}
            {isLoading ? <div className="spinner" /> : null}
            {weightData.length === 0 && (
              <CapEvolution
                caption=""
                data={
                  weightData &&
                  weightData.map(d => ({
                    ...d,
                    x: new Date(d.x),
                    min: 0,
                    max: 200,
                    unit: d.unit,
                  }))
                }
                minDate={infimum}
                maxDate={supremum}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

HubDevicesGraph.propTypes = {
  clearData: PropTypes.func,
  data: PropTypes.shape({ map: PropTypes.func }),
  isLoading: PropTypes.any,
  loadGraph: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      deviceId: PropTypes.any,
      hubId: PropTypes.any,
      id: PropTypes.any,
    }),
    url: PropTypes.shape({ replace: PropTypes.func }),
  }),
  onNavigate: PropTypes.func,
};

const mapStateToProps = state => {
  const { organizations } = state.superUser;
  return {
    role: state.auth.role,
    data: organizations && organizations?.graphData,
    isLoading: organizations && organizations?.isLoading,
    pagination: organizations && organizations?.pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  loadCaps: (hubId, pageRequest) => dispatch(actions.getHubDevices(hubId, pageRequest)),
  loadGraph: pageRequest => dispatch(actions.loadGraph(pageRequest)),
  clearData: () => dispatch(actions.clearData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HubDevicesGraph);
