import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Box, CapEvolutionPlotComponent } from './base';
import Strings from '../../Strings';

const identity = d => d;

class CapEvolution extends PureComponent {
  static defaultSplitDataOnChunks(data) {
    const chunks = [];
    let chunk = [];
    for (let i = 0; i < data.length - 1; i += 1) {
      chunk.push(data[i]);
      const date = data[i].x;
      const nextDate = data[i + 1].x;
      if (Math.abs(nextDate - date) >= 24 * 3600 * 1000) {
        chunks.push(chunk);
        chunk = [];
      }
    }
    if (chunk.length > 0) {
      chunks.push(chunk);
    }
    return chunks;
  }

  static propTypes = {
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    caption: PropTypes.string,
    yTickFormat: PropTypes.any,
    chunkSplitterFn: PropTypes.func,
    yAvg: PropTypes.any,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
  };

  render() {
    const {
      data,
      caption,
      minDate,
      maxDate,
      yTickFormat = identity,
      chunkSplitterFn = CapEvolution.defaultSplitDataOnChunks,
      yAvg,
      isLoading,
    } = this.props;

    if (!data || data.length === 0 || isLoading) {
      const fakeData = [
        {
          max: 100,
          min: 0,
          x: 0,
          y: 0,
        },
      ];

      return (
        <Box caption={caption} isLoading={this.props.isLoading} className="charge-plot">
          {isLoading === false ? (
            <div className="no-data-image-container">
              <div className="no-data-image">{Strings.noData}</div>
            </div>
          ) : null}
          <CapEvolutionPlotComponent
            data={fakeData}
            minDate={minDate}
            maxDate={maxDate}
            chunkSplitterFn={chunkSplitterFn}
            margin={{
              top: 27,
              bottom: 40,
              left: 0,
              right: 0,
            }}
            yAvg={yAvg}
            yTickFormat={yTickFormat}
          />
        </Box>
      );
    }

    const sortedData = data
      .sort((dateA, dateB) => dateA.x.getTime() - dateB.x.getTime())
      .map(item => ({
        ...item,
        max: Math.max(item.min, item.max), // max value should be >= min value
      }));
    const dataMinDate = new Date(sortedData[0].x);
    const dataMaxDate = new Date(sortedData[sortedData.length - 1].x);
    dataMinDate.setDate(sortedData[0].x.getDate() - 1);
    dataMaxDate.setDate(sortedData[sortedData.length - 1].x.getDate() + 1);

    return (
      <Box caption={caption} isLoading={this.props.isLoading} className="charge-time-plot">
        <CapEvolutionPlotComponent
          data={sortedData}
          minDate={sortedData[0] ? dataMinDate : minDate}
          maxDate={sortedData[0] ? dataMaxDate : maxDate}
          chunkSplitterFn={chunkSplitterFn}
          margin={{
            top: 40,
            bottom: 40,
            left: 25,
            right: 20,
          }}
          yAvg={yAvg}
          yTickFormat={yTickFormat}
        />
      </Box>
    );
  }
}

export default CapEvolution;
