/* eslint-disable no-useless-constructor */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actionsAdvancedFilters } from '../actions';

function Checkbox(props) {
  const getMyStateFromProps = () => {
    const { group, filters, value } = props;

    let myState;
    filters.forEach(e => {
      if (e.group === group && e.value === value) myState = e.state;
    });

    return myState;
  };

  useEffect(() => {
    // if state is not defined in filters from props, lets publish as cleared
    if (getMyStateFromProps() === undefined) {
      const { updateFilterState, group, value } = props;
      updateFilterState({
        group,
        value,
        state: false,
      });
    }
  }, [props.group, props.value, props.filters]);

  const onClick = () => {
    const { group, value, updateFilterState } = props;
    const checked = getMyStateFromProps();

    const event = {
      group,
      value,
      state: !checked,
    };
    updateFilterState(event);
  };

  const { text, value } = props;
  const checked = getMyStateFromProps();

  const checkboxStyle = checked ? 'checkbox-checked' : 'checkbox-unchecked';

  return (
    <div className="list-item" onClick={onClick}>
      <div className="label-selector">
        {props.children ? <div className="label-list-item">{props.children}</div> : null}
        {text.length !== 0 ? <div className="label-list-item">{text}</div> : null}
        <div className={checkboxStyle}></div>
      </div>
    </div>
  );
}

Checkbox.propTypes = {
  group: PropTypes.string.isRequired,
  updateFilterState: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    filters: state.entities.advancedFilters.filters.items,
  };
};

const mapDispatchToProps = dispatch => ({
  updateFilterState: data => dispatch(actionsAdvancedFilters.updateFilterState(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkbox);
