import React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

function Location(data) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDe6hkLc5YlewU5_tZZoZ9USpOmsFol-pM',
  });

  return isLoaded ? (
    <GoogleMap
      center={data.center}
      zoom={14}
      options={{
        disableDefaultUI: true,
        keyboardShortcuts: false,
      }}
    ></GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(Location);
