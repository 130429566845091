import { API_CALL } from '../../../../../middleware/api';
import { WIDGET_GET_HUBS_WITHOUT_READINGS_COUNT } from './constants';

export const actions = {
  getHubsWithoutReadings: hours => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/v1/hubs-without-readings?last_hours=' + hours,
    },
    type: WIDGET_GET_HUBS_WITHOUT_READINGS_COUNT,
  }),
};
