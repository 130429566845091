import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Carousel from '../../components/carousel';

export const Tab = ({ active, children }) => <div className={`tab-content ${active ? 'active' : ''}`}>{children}</div>;

Tab.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.any,
};
class TabView extends PureComponent {
  static propTypes = {
    active: PropTypes.any,
    activeTab: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.string,
    isBack: PropTypes.any,
    rightMargin: PropTypes.any,
    onNavigate: PropTypes.func,
    routeMode: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = { active: props.active };
    this.onTabChange = this.onTabChange.bind(this);
  }

  onBackButton = () => {
    this.props.onNavigate('/cap-patients');
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.activeTab && this.state.active !== nextProps.activeTab) {
      this.onTabChange(nextProps.activeTab);
    }
  }

  onTabChange(name) {
    this.setState({ active: name });
  }

  renderHeader(children, activeTabName, routeMode, isBack) {
    if (routeMode) {
      return (
        <div className="tabs">
          {isBack && (
            <div className="tab-back">
              {' '}
              <button className="back nav-back" onClick={() => this.onBackButton()} tabIndex={0}>
                Back
              </button>
            </div>
          )}
          <Carousel type="navMenu" activeTabName={activeTabName}>
            {children
              .filter(c => !!c)
              .map(c => (
                <Link
                  key={c.props.name}
                  data-testid={`tab[${c.props.name.toLowerCase()}]`}
                  to={c.props.path}
                  className={activeTabName === c.props.name ? 'active' : ''}
                >
                  {c.props.name}
                </Link>
              ))}
          </Carousel>
          {this.props.rightMargin && <div style={{ marginRight: this.props.rightMargin }} />}
        </div>
      );
    }
    return (
      <ul className="tabs">
        {children
          .filter(c => !!c)
          .map(c => (
            <li
              className={activeTabName === c.props.name ? 'active' : ''}
              onClick={() => this.onTabChange(c.props.name)}
              key={c.props.name}
            >
              {c.props.name}
            </li>
          ))}
      </ul>
    );
  }

  render() {
    const { children, routeMode, activeTab, isBack } = this.props;
    const className = `tab-view ${this.props.className}`;
    const childrenArray = Array.isArray(children) ? children : [children];
    let { active } = this.state;

    if (!active) {
      active = activeTab;
    }

    return (
      <div className={className}>
        {childrenArray.length > 1 && this.renderHeader(childrenArray, active, routeMode, isBack)}
        {childrenArray &&
          childrenArray
            .filter(c => !!c)
            .map(c => {
              if (active === c.props.name) {
                return React.cloneElement(c, {
                  key: c.props.name,
                  active: active === c.props.name,
                });
              }
              return <div key={c.props.name} />;
            })}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({ onNavigate: path => dispatch(push(path)) });

export default connect(null, mapDispatchToProps)(TabView);
