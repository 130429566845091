import {
  ESI_INVITE_PATIENT_GET_CAPS_TYPES,
  ESI_INVITE_PATIENT_GET_CAPS_TYPES_ERROR,
  ESI_INVITE_PATIENT_GET_CAPS_TYPES_RESULT,
  ESI_INVITE_PATIENT_GET_SCHEDULE_DETAILS,
  ESI_INVITE_PATIENT_GET_SCHEDULE_DETAILS_ERROR,
  ESI_INVITE_PATIENT_GET_SCHEDULE_DETAILS_RESULT,
} from './constants';

export const reducer = (state = null, action) => {
  switch (action.type) {
    case ESI_INVITE_PATIENT_GET_CAPS_TYPES:
      return {
        ...state,
        capsTypes: {
          isLoading: true,
        },
      };
    case ESI_INVITE_PATIENT_GET_CAPS_TYPES_ERROR:
      return {
        ...state,
        capsTypes: {
          isLoading: false,
          data: [],
        },
      };
    case ESI_INVITE_PATIENT_GET_CAPS_TYPES_RESULT:
      return {
        ...state,
        capsTypes: {
          isLoading: false,
          data: action.response.data ? action.response.data : [],
        },
      };
    case ESI_INVITE_PATIENT_GET_SCHEDULE_DETAILS:
      return {
        ...state,
        schedule: {
          isLoading: true,
        },
      };
    case ESI_INVITE_PATIENT_GET_SCHEDULE_DETAILS_ERROR:
      return {
        ...state,
        schedule: {
          isLoading: false,
          data: null,
        },
      };
    case ESI_INVITE_PATIENT_GET_SCHEDULE_DETAILS_RESULT:
      return {
        ...state,
        schedule: {
          isLoading: false,
          data: action.response.data ? action.response.data : [],
        },
      };
    default:
      return state;
  }
};
