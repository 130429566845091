import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ToastMessage from '../components/ToastMessage';
import Strings from '../Strings';
import { unSetError } from '../actions/auth';

class ConnectionCheck extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    onUnset: PropTypes.func,
    commonError: PropTypes.object,
  };

  state = { isDisconnected: false };

  componentDidMount() {
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentWillUnmount() {
    clearTimeout(this.messageInterval);
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  UNSAFE_componentWillUpdate(newProps) {
    if (newProps.commonError) {
      clearTimeout(this.messageInterval);
      this.messageInterval = setTimeout(() => {
        this.props.onUnset();
        clearTimeout(this.messageInterval);
      }, 8000);
    }
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      this.setState({ isDisconnected: false });
    } else {
      this.setState({ isDisconnected: true });
    }
  };

  render() {
    const { children, commonError } = this.props;
    const { isDisconnected } = this.state;

    let showToastMessage = false;
    let toastMessage = '';
    if (commonError) {
      if (commonError.error) {
        showToastMessage = !!commonError.error;
        toastMessage = commonError.error.message;
      } else {
        showToastMessage = false;
      }
    }

    return (
      <div className="full-height">
        <ToastMessage isShow={showToastMessage} message={toastMessage} timer />
        <ToastMessage isShow={isDisconnected} message={Strings.errors.connectionError} />
        {children}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { commonError: state.auth.errors };
};

const mapDispatchToProps = dispatch => ({ onUnset: () => dispatch(unSetError()) });
export default connect(mapStateToProps, mapDispatchToProps)(ConnectionCheck);
