import React from 'react';
import PropTypes from 'prop-types';

import Strings from '../Strings';

const NoData = ({ showImage }) => (
  <div className="no-data">
    <div className="content">
      {Strings.noDataAvailable}
      {showImage === false ? null : <div className="image" />}
    </div>
  </div>
);

NoData.propTypes = { showImage: PropTypes.bool };

export default NoData;
