import React from 'react';
import PropTypes from 'prop-types';

import { PageHeader } from '../PageHeader';
import styles from './CardWrapper.module.scss';
import { Container } from '../../containers/Container';

export function CardWrapper({ title, children, style = {} }) {
  return (
    <React.Fragment>
      {title && <PageHeader title={title} />}
      <Container className={styles.container} style={style}>
        {children}
      </Container>
    </React.Fragment>
  );
}

CardWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  style: PropTypes.object,
  title: PropTypes.string,
};
