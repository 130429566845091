import { WhiteBackground } from 'containers/WhiteBackground';
import React from 'react';

import Strings from '../../Strings';

const EsiPatientLicense = () => {
  return (
    <WhiteBackground noLines title={Strings.endUserLicenseAgreement}>
      <div className="article-container">
        <div className="article-style">
          <p>
            <strong>By accessing the Licensed Solutions, you (the</strong>&quot;
            <strong>End User&quot;) agree as follows:</strong>
          </p>
          <h4 id="medical-disclaimers">Medical Disclaimers</h4>
          <p>
            The Meducation medication information and any related content provided through Lid Sync Adhere (the
            &quot;application&quot;) by First Databank, Inc. (&quot;FDB&quot;, &quot;we&quot;, &quot;us&quot; or
            &quot;our&quot;) to Lid Sync, Inc. pursuant to a license agreement for use in the application is designed to
            offer you general health information for patient awareness, education and empowerment purposes only. The
            health information accessible by you through this application is not intended to be professional advice and
            is not intended to replace personal consultation with a qualified physician, pharmacist or other healthcare
            professional. You must always seek the advice of a professional for questions related to your disease or
            condition, including any symptoms and appropriate therapeutic treatments. If you have or suspect that you
            have a medical problem or condition, please contact a qualified healthcare provider immediately. You should
            never disregard medical advice or delay in seeking it because of something you have read on this
            application.
          </p>
          <p>
            FDB does not make any warranty that the content on this application satisfies government regulations
            requiring disclosure of information on prescription drug products. The content was developed for use in the
            United States, and neither FDB nor our content providers make any representation concerning the content when
            used in any other country. While information on this application has been obtained from sources believed to
            be reliable, neither we nor our content providers warrant the accuracy of any other data contained on this
            application.
          </p>
          <p>
            FDB does not give medical advice, nor do we provide medical or diagnostic services. Medical information
            changes rapidly. Neither we nor our content providers guarantee that the content covers all possible uses,
            directions, precautions, drug interactions, or adverse effects that may be associated with any therapeutic
            treatments.
          </p>
          <p>
            Your reliance upon information and content obtained by you at or through this application is solely at your
            own risk. Neither we nor our content providers assume any liability or responsibility for damage or injury
            (including death) to you, other persons or property arising from any use of any product, information, idea
            or instruction contained in the content or services provided to you.
          </p>
          <h4 id="liability-disclaimers">Liability Disclaimers</h4>
          <p>
            Our content providers have utilized reasonable care in collecting and reporting the information accessible
            via this application and have obtained such information from sources believed to be reliable. However, the
            content providers do not warrant the accuracy of the information in the application. The clinical
            information contained in the information is intended as a supplement to, and not a substitute for, the
            knowledge, expertise, skill, and judgment of physicians, pharmacists, or other healthcare professionals in
            patient care. The absence of a warning for a given drug or drug combination should not be construed to
            indicate that the drug or drug combination is safe, appropriate or effective in any given patient.
          </p>
          <p>
            <strong>
              THE CONTENT PROVIDERS MAKE NO WARRANTY OR REPRESENTATION, EXPRESS OR IMPLIED, AS TO THE ACCURACY OF THE
              DATA FROM WHICH THE INFORMATION IS COMPILED OR THE ACCURACY OF THE INFORMATION ITSELF, NOR THE
              COMPATIBILITY OF THE INFORMATION WITH ANY HARDWARE AND SYSTEMS, AND SPECIFICALLY DISCLAIMS THE IMPLIED
              WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
            </strong>
          </p>
          <p>
            <strong>
              YOU USE THIS APPLICATION AND THE MATERIAL AND INFORMATION AVAILABLE THROUGH THE APPLICATION AT YOUR OWN
              RISK. OUR CONTENT PROVIDERS SHALL NOT BE LIABLE FOR ANY DAMAGES ALLEGEDLY SUSTAINED ARISING OUT OF USE OF
              THE APPLICATION, AND INCLUDING ANY CONSEQUENTIAL, SPECIAL, OR SIMILAR DAMAGES, EVEN IF ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES.
            </strong>
          </p>
          <p>
            <strong>
              IF, NOTWITHSTANDING THE OTHER TERMS OF THIS AGREEMENT (OR ANY OTHER AGREEMENT BETWEEN YOU AND US), WE (OR
              OUR CONTENT PROVIDERS, PARTNERS OR SPONSOR/ADVERTISERS) SHOULD HAVE ANY LIABILITY FOR ANY LOSS, HARM OR
              DAMAGE, YOU AND WE (ON BEHALF OF OURSELVES AND OUR CONTENT PROVIDERS , PARTNERS AND SPONSOR/ADVERTISERS)
              AGREE THAT SUCH LIABILITY SHALL UNDER NO CIRCUMSTANCES EXCEED $1,000. YOU AND WE (ON BEHALF OF OURSELVES
              AND OUR CONTENT PROVIDERS, PARTNERS AND SPONSOR/ADVERTISERS) AGREE THAT THE FOREGOING LIMITATION OF
              LIABILITY IS AN AGREED ALLOCATION OF RISK BETWEEN YOU AND US (AND OUR CONTENT PROVIDERS, PARTNERS AND
              SPONSOR/ADVERTISERS) AND REFLECTS THE FEES, IF ANY, WE CHARGE YOU TO USE THIS APPLICATION, THE SERVICES
              AND THE CONTENT. YOU ACKNOWLEDGE THAT ABSENT YOUR AGREEMENT TO THIS LIMITATION OF LIABILITY, WE WOULD NOT
              PROVIDE THE INFORMATION TO BE ACCESSIBLE VIA THE APPLICATION.
            </strong>
          </p>
          <h4 id="restrictions">Restrictions</h4>
          <p>
            No FDB content made available to you through this application may be copied, reproduced, republished,
            uploaded, posted, transmitted, commercialized or distributed in any way. Modification of the materials or
            use of the materials for any other purpose is a violation of our content provider&apos;s copyright and other
            proprietary rights. The use of any such material on any other application, site or networked computer
            environment is prohibited. All rights, title and interest in and to the materials made available to you
            through this application (including but not limited to all copyrights, trademarks, service marks, trade
            names and all derivative works) are owned or controlled by and shall remain at all time vested in FDB, our
            partners or our content providers, in each case as applicable
          </p>
        </div>
      </div>
    </WhiteBackground>
  );
};

export default EsiPatientLicense;
