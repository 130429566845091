import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { openModalAction } from '../../../actions/modal';
import { PageHeader } from '../../../components/PageHeader';
import { Button, HEADER_BUTTON_DARK_BLUE } from '../../../components/PageHeader/Button';
import { TextInput } from '../../../components/PageHeader/TextInput';
import { DATE_FORMAT_MONTH_NAME_2, PAGE_LIMIT, TIME_FORMAT_12_UPPERCASE } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { actions } from './redux/actions';
import { SCHEDULE_LIST } from './redux/constants';
import './Schedules.scss';

class Schedules extends PureComponent {
  searchTime;

  componentDidMount() {
    this.pageRequest = {
      offset: 0,
      search: '',
    };
    this.props.getSchedules(this.pageRequest);
  }

  onSearchQueryChange = query => {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    clearTimeout(this.searchTime);
    this.searchTime = setTimeout(() => {
      this.props.getSchedules(this.pageRequest);
    }, 1000);
  };

  getHeaderComponents = () => {
    const { onAddSchedule } = this.props;
    return (
      <React.Fragment>
        <TextInput
          class="search"
          placeholder={Strings.search}
          onChange={e => this.onSearchQueryChange(e.target.value)}
        />
        <Button
          class={HEADER_BUTTON_DARK_BLUE}
          onClick={() => onAddSchedule(this.pageRequest)}
          title={Strings.schedules.addSchedule}
        />
      </React.Fragment>
    );
  };

  onPrevClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset - PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getSchedules(this.pageRequest);
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.props.getSchedules(this.pageRequest);
  };

  onNextClick = () => {
    const { pagination } = this.props;
    const offset = pagination.offset + PAGE_LIMIT;
    this.pageRequest.offset = offset;
    this.props.getSchedules(this.pageRequest);
  };

  onSortClick = ({ sortKey, direction }) => {
    this.pageRequest.offset = 0;
    this.pageRequest.sortColumn = sortKey;
    this.pageRequest.sortType = direction;
    this.props.getSchedules(this.pageRequest);
  };

  onScheduleSelected = id => {
    const { schedules } = this.props;
    if (schedules && schedules[id].id) {
      const data = schedules[id];
      const maskedId = encodeURIComponent(data.masked_id);
      this.props.onNavigate(`/schedules/${maskedId}`);
    }
  };

  onEditSchedule = (event, org) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onEditSchedule(this.pageRequest, org);
  };

  onScheduleDelete = (event, schedules) => {
    event.preventDefault();
    event.stopPropagation();

    const deleteData = { masked_id: schedules.masked_id };
    const data = {
      title: (
        <span>
          {Strings.deleteScheduleWarning} <b>{schedules.schedule_name}</b>?
        </span>
      ),

      onConfirmAction: actions.deleteSchedule(deleteData, schedules.id),
      onCancelAction: null,
    };
    this.props.openConfirmModal(data);
  };

  render() {
    const { isLoading, schedules, pagination } = this.props;

    const getTimeOffset = d => {
      let offset = '';
      d.offset.map(e => {
        offset += `${moment()
          .startOf('day')
          .seconds(parseInt(e, 10))
          .format(TIME_FORMAT_12_UPPERCASE)} `;
      });

      const timezone = d?.schedule_timezone ? d?.schedule_timezone : '';
      return offset + timezone;
    };

    return (
      <React.Fragment>
        <div>
          <div className="infoHeader">
            <div className="rowDirection">
              <div className="feildBox">
                <div className="feildValue">{Strings.schedules.schedules}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="details-container">
          <PageHeader getHeaderComponents={() => this.getHeaderComponents()} />
          {/* {organizations && ( */}
          <Table
            className="schedules-table"
            isLoading={isLoading}
            name="organizations"
            data={schedules || []}
            onRowSelection={this.onScheduleSelected}
            onPrevClick={this.onPrevClick}
            onSortClick={this.onSortClick}
            onNextClick={this.onNextClick}
            onCustomPage={this.onCustomPage}
            pagination={
              pagination || {
                offset: 0,
                total: 0,
              }
            }
          >
            <Column key="schedule_name" title={Strings.schedules.scheduleName} value={d => d.schedule_name} />
            <Column key="patient_count" title={Strings.schedules.patient} value={d => d.patient_count} />
            <Column key="schedule_type" title={Strings.schedules.scheduleType} value={d => d.schedule_type} />
            <Column key="offset" title={Strings.schedules.time} value={d => getTimeOffset(d)} />
            <Column key="accuracy" title={Strings.schedules.accuracy} value={d => `${d.accuracy}%`} />
            <Column key="medication_name" title={Strings.schedules.medication} value={d => d.medication_name} />
            <Column
              key="start_dt"
              title={Strings.schedules.startDate}
              value={d => (d.start_dt ? moment.unix(d.start_dt).format(DATE_FORMAT_MONTH_NAME_2) : '-')}
            />
            <Column
              key="end_dt"
              title={Strings.schedules.endDate}
              value={d => (d.end_dt ? moment.unix(d.end_dt).format(DATE_FORMAT_MONTH_NAME_2) : '-')}
            />
            <Column
              key="edit"
              value={d => (
                <React.Fragment>
                  <div style={{ display: 'flex' }}>
                    <span className="edit-icon" onClick={e => this.onEditSchedule(e, d)}></span>
                    <span className="trash-icon2" onClick={e => this.onScheduleDelete(e, d)}></span>
                  </div>
                </React.Fragment>
              )}
            />
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { schedules } = state.superUser;

  return {
    schedules: schedules && schedules?.data?.data,
    isLoading: schedules && schedules?.isLoading,
    pagination: schedules && schedules?.data?.pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getSchedules: pageRequest => dispatch(actions.getSchedules(pageRequest)),
  onAddSchedule: schedule =>
    dispatch(
      openModalAction('add-schedule-to-the-cap', {
        action: actions.getSchedules,
        actionType: SCHEDULE_LIST,
        pageRequest: schedule,
      }),
    ),
  onEditSchedule: (pageRequest, schedule) =>
    dispatch(
      openModalAction('add-schedule-to-the-cap', {
        action: actions.getSchedules,
        actionType: SCHEDULE_LIST,
        pageRequest,
        schedule,
      }),
    ),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Schedules);
