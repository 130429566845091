import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import ReactDatePicker from 'react-datepicker';
import { connect } from 'react-redux';

import { resetForm } from '../../actions/forms';
import { closeModal } from '../../actions/modal';
import LoadingRenderer from '../../components/LoadingRenderer';
import Select from '../../components/Select';
import { CHAR_LIMIT } from '../../constants';
import { Modal } from '../../containers';
import Form from '../../containers/Form';
import { UPDATE_TASKS_ERROR } from '../../pages/SuperUser/Patients/redux/constants';
import Strings from '../../Strings';
import { getProgramDisplayName } from '../../utils/cmsPrograms';
import './UpdateTask.scss';

class UpdateTask extends PureComponent {
  constructor(props) {
    super(props);
    const task = props.data?.task;
    this.state = {
      errors: [],
      isCodeEmpty: true,
      taskMetadataID: task.taskMetadataID,
      note: task.notes,
      notes_char_left: CHAR_LIMIT,
      schedule: {
        startTime: moment.unix(task.startTime).format('hh:mm:ss A'),
        endTime: moment.unix(task.endTime).format('hh:mm:ss A'),
        startDate: moment.unix(task.startTime),
        endDate: moment.unix(task.endTime),
      },
      enrollmentId: task.enrollmentID,
      readOnly: props.data?.readOnly,
    };
  }
  componentDidMount() {
    this.fixNotesCharLeft();
  }

  fixNotesCharLeft = () => {
    const { notes_char_left, note } = this.state;
    this.setState({ notes_char_left: notes_char_left - note.length });
  };

  onSave = () => {
    const { startTime, endTime, startDate, endDate } = this.state.schedule;
    const { taskMetadataID, note, enrollmentId } = this.state;

    const sTime = moment(`${moment(startDate).format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD HH:mm:ss A').unix();
    const eTime = moment(`${moment(endDate).format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD HH:mm:ss A').unix();

    const taskTime = eTime - sTime;
    const id = this.props.data.task.id;
    const patientId = this.props.data.patientId;

    const data = {
      id,
      patientId,
      taskMetadataID: parseInt(taskMetadataID, 10),
      notes: note,
      startTime: sTime,
      endTime: eTime,
      enrollmentID: enrollmentId,
    };

    const validationErrors = [];

    if (taskTime <= 0) {
      validationErrors.push(Strings.errors.endTime);
    }
    if (sTime > moment().unix() || eTime > moment().unix()) {
      validationErrors.push(Strings.errors.timeInTheFuture);
    }
    if (validationErrors.length === 0) {
      this.props.update(data).then(r => {
        if (r.type === UPDATE_TASKS_ERROR) {
          validationErrors.push(r.response.data.error.message);
          this.setState({ errors: validationErrors });
        }
      });
    } else {
      this.setState({ errors: validationErrors });
    }
  };

  setError(error) {
    this.setState(state => ({ errors: [...state.errors, error] }));
  }

  clearError() {
    this.setState({ errors: [] });
  }

  updateTime = (item, operation, index, key) => {
    const schedule = cloneDeep(this.state.schedule);
    if (key) {
      const isNumber = /^[0-9]$/i.test(key.key);
      if (isNumber) {
        if (item === 'HH') {
          if (this.state.lastKey == 1) {
            if (key.key == 0) {
              this.setState({ lastKey: 10 });
              schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
                .set('hour', 10)
                .format('hh:mm:ss A');
            } else if (key.key == 1) {
              this.setState({ lastKey: 11 });
              schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
                .set('hour', 11)
                .format('hh:mm:ss A');
            } else if (key.key == 2) {
              this.setState({ lastKey: 12 });
              schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
                .set('hour', 12)
                .format('hh:mm:ss A');
            } else {
              this.setState({ lastKey: key.key });
              schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
                .set('hour', key.key)
                .format('hh:mm:ss A');
            }
          } else {
            this.setState({ lastKey: key.key });
            schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
              .set('hour', key.key)
              .format('hh:mm:ss A');
          }
        } else if (item === 'MM') {
          if (
            this.state.lastKey == 1 ||
            this.state.lastKey == 2 ||
            this.state.lastKey == 3 ||
            this.state.lastKey == 4 ||
            this.state.lastKey == 5
          ) {
            const minutes = this.state.lastKey.concat(key.key);
            this.setState({ lastKey: minutes });
            schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
              .set('minutes', minutes)
              .format('hh:mm:ss A');
          } else {
            this.setState({ lastKey: key.key });
            schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
              .set('minutes', key.key)
              .format('hh:mm:ss A');
          }
        } else if (item === 'SS') {
          if (
            this.state.lastKey == 1 ||
            this.state.lastKey == 2 ||
            this.state.lastKey == 3 ||
            this.state.lastKey == 4 ||
            this.state.lastKey == 5
          ) {
            const seconds = this.state.lastKey.concat(key.key);
            this.setState({ lastKey: seconds });
            schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
              .set('seconds', seconds)
              .format('hh:mm:ss A');
          } else {
            this.setState({ lastKey: key.key });
            schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
              .set('seconds', key.key)
              .format('hh:mm:ss A');
          }
        }
      } else if (item === 'HH') {
        if (key.key == 'ArrowUp') {
          this.setState({ lastKey: 0 });
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .add(1, 'hours')
            .format('hh:mm:ss A');
        } else if (key.key == 'ArrowDown') {
          this.setState({ lastKey: 0 });
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .subtract(1, 'hours')
            .format('hh:mm:ss A');
        } else if (key.key == 'Backspace') {
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .set('hour', 1)
            .format('hh:mm:ss A');
        }
      } else if (item === 'MM') {
        if (key.key == 'ArrowUp') {
          this.setState({ lastKey: 0 });
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .add(1, 'minutes')
            .format('hh:mm:ss A');
        } else if (key.key == 'ArrowDown') {
          this.setState({ lastKey: 0 });
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .subtract(1, 'minutes')
            .format('hh:mm:ss A');
        } else if (key.key == 'Backspace') {
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .set('minutes', 0)
            .format('hh:mm:ss A');
        }
      } else if (item === 'SS') {
        if (key.key == 'ArrowUp') {
          this.setState({ lastKey: 0 });
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .add(1, 'seconds')
            .format('hh:mm:ss A');
        } else if (key.key == 'ArrowDown') {
          this.setState({ lastKey: 0 });
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .subtract(1, 'seconds')
            .format('hh:mm:ss A');
        } else if (key.key == 'Backspace') {
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .set('seconds', 0)
            .format('hh:mm:ss A');
        }
      }
    } else {
      if (item === 'HH' || item === 'PM' || item === 'AM') {
        if (operation === 'DOWN') {
          this.setState({ lastKey: 0 });
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .subtract(1, 'hours')
            .format('hh:mm:ss A');
        } else if (item === 'PM' || item === 'AM') {
          this.setState({ lastKey: 0 });
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .add(12, 'hours')
            .format('hh:mm:ss A');
        } else {
          this.setState({ lastKey: 0 });
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .add(1, 'hours')
            .format('hh:mm:ss A');
        }
      }
      if (item === 'MM') {
        if (operation === 'DOWN') {
          this.setState({ lastKey: 0 });
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .subtract(5, 'minutes')
            .format('hh:mm:ss A');
        } else {
          this.setState({ lastKey: 0 });
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .add(5, 'minutes')
            .format('hh:mm:ss A');
        }
      }
      if (item === 'SS') {
        if (operation === 'DOWN') {
          this.setState({ lastKey: 0 });
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .subtract(5, 'seconds')
            .format('hh:mm:ss A');
        } else {
          this.setState({ lastKey: 0 });
          schedule[index] = moment(schedule[index], ['hh:mm:ss A'])
            .add(5, 'seconds')
            .format('hh:mm:ss A');
        }
      }
    }
    this.setState({ schedule });
    this.forceUpdate();
  };

  onProgramChange = option => {
    this.setState({ enrollmentId: option.value });
  };

  onTaskChange = option => {
    this.setState({ taskMetadataID: option.value });
  };

  handleNotesArea = e => {
    const notes_char_count = e.target.value.length;
    if (notes_char_count <= CHAR_LIMIT) {
      this.setState({ note: e.target.value });
      this.setState({ notes_char_left: CHAR_LIMIT - notes_char_count });
    }
  };

  onDateChange = (selectedDate, date) => {
    const schedule = cloneDeep(this.state.schedule);
    schedule[date] = selectedDate;
    this.setState({ schedule });
  };

  render() {
    const { onOpen, data, onCancel, AttachCaptoAdminModalLoading, ...props } = this.props;
    const { errors, schedule, taskMetadataID, note, enrollmentId, readOnly } = this.state;
    const errorsStr = errors.map(e => <div style={{ margin: 0 }}>{e}</div>);
    const { taskList } = data;
    const renameKeys = (keysMap, obj) =>
      Object.keys(obj).reduce(
        (acc, key) => ({
          ...acc,
          ...{ [keysMap[key] || key]: obj[key] },
        }),
        {},
      );

    const keysMap = {
      id: 'value',
      taskName: 'label',
    };
    let renamedTaskList = [];
    if (taskList) {
      renamedTaskList = taskList.map(item => {
        return renameKeys(keysMap, item);
      });
    }

    return (
      <Modal name="update-task" onOpen={onOpen} additionalClasses={['form-modal', 'user-invite']} {...props}>
        <LoadingRenderer>
          <Form onCancel={onCancel} id="update-task" className="update-task">
            {!!this.props.data?.readOnly ? (
              <div className={`edit-task-button-container ${readOnly ? '' : 'edit-mode-on'}`}>
                <button
                  className={`edit-task-button ${readOnly ? '' : 'edit-mode-on'}`}
                  onClick={() => this.setState({ readOnly: !readOnly })}
                />
              </div>
            ) : (
              <React.Fragment />
            )}
            <div className="row taskName">
              {data.programs && data.programs.length > 1 && (
                <React.Fragment>
                  <div className="feild-lable">{Strings.program}</div>
                  <Select
                    value={enrollmentId}
                    data={data.programs.map(p => {
                      return { label: getProgramDisplayName(p.program, p.subprogram), value: p.enrollmentId };
                    })}
                    onChange={this.onProgramChange}
                    disabled={readOnly}
                  />
                </React.Fragment>
              )}
            </div>
            <div className="row taskName">
              <div className="feild-lable">{Strings.capPatient.taskName}</div>
              <Select value={taskMetadataID} data={renamedTaskList} onChange={this.onTaskChange} disabled={readOnly} />
            </div>

            <div className="taskTimerBox full-width">
              <textarea
                className="taskNoteArea"
                value={note}
                onChange={this.handleNotesArea}
                placeholder={Strings.placeholder.notesArea}
                disabled={readOnly}
              ></textarea>
              <div className="limitShowDiv">
                <p>
                  {this.state.notes_char_left !== 1
                    ? `${this.state.notes_char_left} characters left`
                    : `${this.state.notes_char_left} character left`}
                </p>
              </div>
            </div>

            <div className="row title" key="start-time">
              <div className="feild-lable">{Strings.capPatient.startTime}</div>
              <ReactDatePicker
                selected={schedule.startDate}
                onChange={e => this.onDateChange(e, 'startDate')}
                maxDate={moment()}
                placeholderText="Please select date"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormat="MM / DD / YYYY"
                disabled={readOnly}
                popperPlacement="top-start"
              />
              <div className="timePicker">
                <div className={`img-input-container ${readOnly ? 'disabled' : ''}`}>
                  <a className="step up" onClick={() => (!readOnly ? this.updateTime('HH', 'UP', 'startTime') : {})} />

                  <input
                    className="timeStep"
                    onChange={this.onChange}
                    type="text"
                    id="hh"
                    name="hh"
                    label=""
                    value={
                      parseInt(schedule.startTime.split(':')[0], 10) <= 9
                        ? `0${parseInt(schedule.startTime.split(':')[0], 10)}`
                        : parseInt(schedule.startTime.split(':')[0], 10)
                    }
                    placeholder={Strings.patient_medications.time}
                    onKeyDown={e => this.updateTime('HH', 'UP', 'startTime', e)}
                    ref={this.scheduleFormRef}
                    tabIndex={0}
                    disabled={readOnly}
                  />
                  <a
                    className="step down"
                    onClick={() => (!readOnly ? this.updateTime('HH', 'DOWN', 'startTime') : {})}
                  />
                </div>
                <div className="divider">:</div>
                <div className={`img-input-container ${readOnly ? 'disabled' : ''}`}>
                  <div
                    className="step up"
                    onClick={() => (!readOnly ? this.updateTime('MM', 'UP', 'startTime') : {})}
                  />
                  <input
                    className="timeStep"
                    onChange={this.onChange}
                    type="text"
                    id="mm"
                    label=""
                    value={
                      parseInt(schedule.startTime.split(':')[1], 10) <= 9
                        ? `0${parseInt(schedule.startTime.split(':')[1], 10)}`
                        : parseInt(schedule.startTime.split(':')[1], 10)
                    }
                    placeholder={Strings.patient_medications.time}
                    onKeyDown={e => this.updateTime('MM', 'UP', 'startTime', e)}
                    tabIndex={0}
                    disabled={readOnly}
                  />
                  <div
                    className="step down"
                    onClick={() => (!readOnly ? this.updateTime('MM', 'DOWN', 'startTime') : {})}
                  />
                </div>
                <div className="divider">:</div>
                <div className={`img-input-container ${readOnly ? 'disabled' : ''}`}>
                  <div
                    className="step up"
                    onClick={() => (!readOnly ? this.updateTime('SS', 'UP', 'startTime') : {})}
                  />
                  <input
                    className="timeStep"
                    onChange={this.onChange}
                    type="text"
                    id="ss"
                    label=""
                    value={
                      parseInt(schedule.startTime.split(':')[2], 10) <= 9
                        ? `0${parseInt(schedule.startTime.split(':')[2], 10)}`
                        : parseInt(schedule.startTime.split(':')[2], 10)
                    }
                    placeholder={Strings.patient_medications.time}
                    onKeyDown={e => this.updateTime('SS', 'UP', 'startTime', e)}
                    tabIndex={0}
                    disabled={readOnly}
                  />
                  <div
                    className="step down"
                    onClick={() => (!readOnly ? this.updateTime('SS', 'DOWN', 'startTime') : {})}
                  />
                </div>
                <div className={`ampmSwitch ${readOnly ? 'disabled' : ''}`}>
                  <div
                    className={`am block  ${
                      schedule.startTime.split(' ')[1] === 'am' || schedule.startTime.split(' ')[1] === 'AM'
                        ? 'active'
                        : ''
                    } ${readOnly ? ' disabled' : ''}`}
                    onClick={() => (!readOnly ? this.updateTime('AM', 'UP', 'startTime') : {})}
                    tabIndex={0}
                    onKeyDown={e => {
                      const charCode = e.which ? e.which : e.keyCode;
                      if (charCode === 13) {
                        this.updateTime('AM', 'UP', 'startTime');
                      }
                    }}
                  >
                    {Strings.patient_medications.am}
                  </div>
                  <div
                    className={`pm block ${
                      schedule.startTime.split(' ')[1] === 'pm' || schedule.startTime.split(' ')[1] === 'PM'
                        ? 'active'
                        : ''
                    } ${readOnly ? ' disabled' : ''}`}
                    onClick={() => (!readOnly ? this.updateTime('PM', 'UP', 'startTime') : {})}
                    tabIndex={0}
                    onKeyDown={e => {
                      const charCode = e.which ? e.which : e.keyCode;
                      if (charCode === 13) {
                        this.updateTime('PM', 'UP', 'startTime');
                      }
                    }}
                  >
                    {Strings.patient_medications.pm}
                  </div>
                </div>
              </div>
            </div>

            <div className="row title" key="end-time">
              <div className="feild-lable">{Strings.capPatient.endTime}</div>
              <ReactDatePicker
                selected={schedule.endDate}
                onChange={e => this.onDateChange(e, 'endDate')}
                maxDate={moment()}
                placeholderText="Please select date"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormat="MM / DD / YYYY"
                disabled={readOnly}
                popperPlacement="top-start"
              />
              <div className="timePicker">
                <div className={`img-input-container ${readOnly ? 'disabled' : ''}`}>
                  <a className="step up" onClick={() => (!readOnly ? this.updateTime('HH', 'UP', 'endTime') : {})} />

                  <input
                    className="timeStep"
                    onChange={this.onChange}
                    type="text"
                    id="hh"
                    name="hh"
                    label=""
                    value={
                      parseInt(schedule.endTime.split(':')[0], 10) <= 9
                        ? `0${parseInt(schedule.endTime.split(':')[0], 10)}`
                        : parseInt(schedule.endTime.split(':')[0], 10)
                    }
                    placeholder={Strings.patient_medications.time}
                    onKeyDown={e => this.updateTime('HH', 'UP', 'endTime', e)}
                    ref={this.scheduleFormRef}
                    tabIndex={0}
                    disabled={readOnly}
                  />
                  <a
                    className="step down"
                    onClick={() => (!readOnly ? this.updateTime('HH', 'DOWN', 'endTime') : {})}
                  />
                </div>
                <div className="divider">:</div>
                <div className={`img-input-container ${readOnly ? 'disabled' : ''}`}>
                  <div className="step up" onClick={() => (!readOnly ? this.updateTime('MM', 'UP', 'endTime') : {})} />
                  <input
                    className="timeStep"
                    onChange={this.onChange}
                    type="text"
                    id="mm"
                    label=""
                    value={
                      parseInt(schedule.endTime.split(':')[1], 10) <= 9
                        ? `0${parseInt(schedule.endTime.split(':')[1], 10)}`
                        : parseInt(schedule.endTime.split(':')[1], 10)
                    }
                    placeholder={Strings.patient_medications.time}
                    onKeyDown={e => this.updateTime('MM', 'UP', 'endTime', e)}
                    tabIndex={0}
                    disabled={readOnly}
                  />
                  <div
                    className="step down"
                    onClick={() => (!readOnly ? this.updateTime('MM', 'DOWN', 'endTime') : {})}
                  />
                </div>
                <div className="divider">:</div>
                <div className={`img-input-container ${readOnly ? 'disabled' : ''}`}>
                  <div className="step up" onClick={() => (!readOnly ? this.updateTime('SS', 'UP', 'endTime') : {})} />
                  <input
                    className="timeStep"
                    onChange={this.onChange}
                    type="text"
                    id="ss"
                    label=""
                    value={
                      parseInt(schedule.endTime.split(':')[2], 10) <= 9
                        ? `0${parseInt(schedule.endTime.split(':')[2], 10)}`
                        : parseInt(schedule.endTime.split(':')[2], 10)
                    }
                    placeholder={Strings.patient_medications.time}
                    onKeyDown={e => this.updateTime('SS', 'UP', 'endTime', e)}
                    tabIndex={0}
                    disabled={readOnly}
                  />
                  <div
                    className="step down"
                    onClick={() => (!readOnly ? this.updateTime('SS', 'DOWN', 'endTime') : {})}
                  />
                </div>
                <div className={`ampmSwitch ${readOnly ? 'disabled' : ''}`}>
                  <div
                    className={`am block  ${
                      schedule.endTime.split(' ')[1] === 'am' || schedule.endTime.split(' ')[1] === 'AM' ? 'active' : ''
                    } ${readOnly ? ' disabled' : ''}`}
                    onClick={() => (!readOnly ? this.updateTime('AM', 'UP', 'endTime') : {})}
                    tabIndex={0}
                    onKeyDown={e => {
                      const charCode = e.which ? e.which : e.keyCode;
                      if (charCode === 13) {
                        this.updateTime('AM', 'UP', 'endTime');
                      }
                    }}
                  >
                    {Strings.patient_medications.am}
                  </div>
                  <div
                    className={`pm block ${
                      schedule.endTime.split(' ')[1] === 'pm' || schedule.endTime.split(' ')[1] === 'PM' ? 'active' : ''
                    } ${readOnly ? ' disabled' : ''}`}
                    onClick={() => (!readOnly ? this.updateTime('PM', 'UP', 'endTime') : {})}
                    tabIndex={0}
                    onKeyDown={e => {
                      const charCode = e.which ? e.which : e.keyCode;
                      if (charCode === 13) {
                        this.updateTime('PM', 'UP', 'endTime');
                      }
                    }}
                  >
                    {Strings.patient_medications.pm}
                  </div>
                </div>
              </div>
            </div>

            <div></div>

            <div key="errors-block" className="errors-block">
              {errorsStr}
            </div>
            <div className="button-bar  reverse" key="button-bar" style={{ marginTop: '70px' }}>
              <button className="brand-blue" key="submit" type="button" onClick={this.onSave} disabled={readOnly}>
                {Strings.save}
              </button>
              <button className="brand-white-gray mr-15" key="cancel" type="cancel">
                {Strings.cancel}
              </button>
            </div>
          </Form>
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapStateToProps = () => ({ AttachCaptoAdminModalLoading: 'NotRequried now' });

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('update-task')),
  onOpen: () => dispatch(resetForm('update-task', ownProps.data)),
  update: data =>
    dispatch(ownProps.data.action(data)).then(response => {
      if (response && response.type === `${ownProps.data.actionType}/result`) {
        if (ownProps.data.successAction) {
          ownProps.data.successAction();
        }
        dispatch(closeModal('update-task'));
      }

      return response;
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTask);
