import merge from 'deepmerge';

import {
  CLEAR_ENTITIES,
  DELETE_TRIAL,
  DELETE_TRIAL_RESULT,
  GET_TOTAL_TRIAL_ADHERENCE,
  GET_TOTAL_TRIAL_ADHERENCE_RESULT,
  GET_TRIAL,
  GET_TRIAL_ADHERENCE,
  GET_TRIAL_ADHERENCE_RESULT,
  GET_TRIAL_RESULT,
  LIST_TRIALS,
  LIST_TRIALS_RESULT,
  NEW_TRIAL,
  NEW_TRIAL_ERROR,
  NEW_TRIAL_RESULT,
  POST_ADMIN_INVITE_RESULT,
  TRIAL_INFO_RESULT,
  TRIAL_NEW_PATIENT_ERROR,
  TRIAL_NEW_PATIENT_RESULT,
  TRIAL_OVERVIEW,
  TRIAL_OVERVIEW_RESULT,
  TRIAL_TIMESTAMP,
  TRIAL_TIMESTAMP_RELOAD,
  TRIAL_TIMESTAMP_RESULT,
  UPDATE_TRIAL,
  UPDATE_TRIAL_ERROR,
  UPDATE_TRIAL_RESULT,
} from '../actions/action-types';
import { ESI_PATIENTS_TIMESTAMP_CLEAR } from '../pages/ESI/Patients/redux/constants';

const initState = {
  pagination: {},
  trials: [],
  trialInfo: {},
  overview: {
    adherenceData: {},
    adherence7days: {},
    devicesUsageData: {},
    loading: true,
    preparing: true,
  },
  loading: true,
  preparing: true,
};

const trialsReducer = (newState = initState, action) => {
  switch (action.type) {
    case GET_TRIAL:
      return {
        ...newState,
        loading: true,
      };

    case GET_TRIAL_RESULT:
      return {
        ...newState,
        loading: false,
        preparing: false,
      };

    case LIST_TRIALS:
      return {
        ...newState,
        loading: action.isLoading,
        preparing: true,
      };

    case LIST_TRIALS_RESULT: {
      const { studies, pagination } = action.response;
      return {
        ...newState,
        trials: studies,
        trial: studies.reduce((res, item) => {
          res[item.id] = item;
          return res;
        }, {}),
        pagination,
        loading: false,
        preparing: false,
      };
    }

    case TRIAL_INFO_RESULT: {
      return {
        ...newState,
        trialInfo: action.response,
        loading: false,
        preparing: false,
      };
    }

    case TRIAL_OVERVIEW:
      return {
        ...newState,
        loading: true,
        preparing: true,
        overview: {
          loading: true,
          preparing: true,
        },
      };

    case TRIAL_OVERVIEW_RESULT: {
      const response = action.response.entities.overview.objects;
      return {
        ...newState,
        overview: {
          adherenceData: response.adherence,
          adherence7days: response.last7DayAdherence,
          devicesUsageData: response.smartCapData,
          totalTrialCount: response.totalTrialCount,
          totalPatientCount: response.totalPatientCount,
          loading: false,
          preparing: false,
        },
      };
    }

    case TRIAL_TIMESTAMP:
      return {
        ...newState,
        loading: true,
        preparing: true,
        update: false,
      };

    case TRIAL_TIMESTAMP_RELOAD: {
      return {
        ...newState,
        loading: true,
        preparing: true,
        update: true,
      };
    }

    case TRIAL_TIMESTAMP_RESULT:
      return {
        ...newState,
        loading: false,
        preparing: false,
        update: false,
        events: action.response,
      };

    case ESI_PATIENTS_TIMESTAMP_CLEAR:
      return {
        ...newState,
        loading: false,
        preparing: false,
        update: false,
        events: [],
      };

    case GET_TRIAL_ADHERENCE:
      return {
        ...newState,
        adherence: {
          loading: true,
          preparing: true,
        },
      };
    case GET_TRIAL_ADHERENCE_RESULT:
      return {
        ...newState,
        adherence: {
          loading: false,
          preparing: false,
          adherence: action.response,
        },
      };

    case GET_TOTAL_TRIAL_ADHERENCE:
      return {
        ...newState,
        adherenceTotal: { value: null },
      };

    case GET_TOTAL_TRIAL_ADHERENCE_RESULT:
      return {
        ...newState,
        adherenceTotal: { value: action.response.totalAdherence },
      };

    case DELETE_TRIAL: {
      const tempTrials = newState.trials;
      if (tempTrials) {
        const { trialId } = action;
        const trials = tempTrials.map(trial => {
          const d = trial;
          if (d.id === trialId) {
            d.isLoading = true;
          }
          return d;
        });
        return {
          ...newState,
          trials,
          loading: false,
        };
      }

      return {
        ...newState,
        loading: true,
      };
    }

    case DELETE_TRIAL_RESULT: {
      const { trials } = newState;
      if (action.response && action.response.id && trials !== undefined) {
        const tempTrials = trials.filter(trial => trial.id !== parseInt(action.response.id, 10));
        return {
          ...newState,
          trials: tempTrials,
          pagination: {
            startDate: '',
            endDate: '',
            offset: 0,
            search: '',
          },
          loading: false,
        };
      }
      return newState;
    }

    case CLEAR_ENTITIES:
      if (action.entities && action.entities.indexOf('trials') !== -1) {
        return {
          ...newState,
          overview: {
            adherenceData: {},
            adherence7days: {},
            devicesUsageData: {},
          },
          trials: {},
          adherence: { result: [] },
          trialInfo: {},
          preparing: true,
        };
      }
      return newState;

    case TRIAL_NEW_PATIENT_RESULT: {
      const membership = Object.values(action.response.entities.memberships)[0];
      return merge(newState, { objects: { [membership.group_id]: { patients: [membership.patient_id] } } });
    }

    case TRIAL_NEW_PATIENT_ERROR:
      return {
        ...newState,
        newPatientErrors: action.response.data || {},
      };

    case POST_ADMIN_INVITE_RESULT:
      if (action.response && action.response.study) {
        return merge(newState, { objects: { [action.response.study]: { doctors: [action.response.doctor.id] } } });
      }
      return newState;

    case NEW_TRIAL:
      return {
        ...newState,
        trialLoading: true,
      };

    case NEW_TRIAL_RESULT:
      return {
        ...newState,
        trialLoading: false,
      };
    case NEW_TRIAL_ERROR:
      return {
        ...newState,
        trialLoading: false,
      };

    case UPDATE_TRIAL:
      return {
        ...newState,
        trialLoading: true,
      };

    case UPDATE_TRIAL_RESULT:
      return {
        ...newState,
        trialLoading: false,
      };

    case UPDATE_TRIAL_ERROR:
      return {
        ...newState,
        trialLoading: false,
      };

    default:
      return newState;
  }
};

export default trialsReducer;
