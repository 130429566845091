import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import zxcvbn from 'zxcvbn';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ssoLogin } from '../actions/auth';
import { QueryString } from '../utils';
import { userActivateAction, userNameCheckAction } from '../actions/user-settings';
import { CurrentLandingLayout } from '../layouts';
import { USER_ACTIVATE_RESULT } from '../actions/action-types';
import Strings from '../Strings';

class UserActivatePage extends PureComponent {
  /* eslint react/prop-types: 0 */
  static propTypes = {
    onNameCheck: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    onSSOLogin: PropTypes.func.isRequired,
    onActivate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    userActivate: PropTypes.object,
    userNameCheck: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onActivate = this.onActivate.bind(this);
    this.state = {
      username: '',
      password: '',
      confirm_password: '',
      type: 'password',
      type1: 'password',
      onlineErrors: {},
      passwordScore: null,
    };

    this.togglePassword = this.togglePassword.bind(this);
    this.togglePasswordType = this.togglePasswordType.bind(this);
    this.onNameCheckDebounced = _.debounce(this.props.onNameCheck, 500);
  }

  UNSAFE_componentWillMount() {
    const queryParams = new QueryString(this.props.location.search);
    const { token, user } = queryParams.params;
    this.props.onSSOLogin(user, token);
  }

  onChange(event) {
    const name = event.target.getAttribute('name');
    const {
      target: { value },
    } = event;

    this.setState({ [name]: value });

    const { password, confirm_password, onlineErrors } = this.state;

    if (name === 'username') {
      this.onNameCheckDebounced(value);
    } else {
      onlineErrors[name] = '';
    }

    if (name === 'password' && confirm_password !== '') {
      const { score } = zxcvbn(password);
      this.setState({ passwordScore: score });
      if (value !== confirm_password) {
        onlineErrors.confirm_password = Strings.errors.misMatchPassword;
      } else {
        onlineErrors.confirm_password = '';
      }
    }

    if (name === 'confirm_password') {
      if (value !== password) {
        onlineErrors[name] = Strings.errors.misMatchPassword;
      }
    }

    this.setState({ onlineErrors });
  }

  onActivate(e) {
    e.preventDefault();
    const { password, confirm_password } = this.state;
    if (password === confirm_password) {
      const query = new QueryString(this.props.location.search);
      const payload = {
        code: query.params.code,
        source: query.params.source,
        ...this.state,
      };

      this.props.onActivate(payload);
    }
  }

  togglePasswordType() {
    const { type1 } = this.state;
    const isTextOrHide = type1 === 'password';
    const newState = isTextOrHide ? 'text' : 'password';
    this.setState({ type1: newState });
  }

  togglePassword() {
    const { type } = this.state;
    const isTextOrHide = type === 'password';
    const newState = isTextOrHide ? 'text' : 'password';
    this.setState({ type: newState });
  }

  render() {
    const {
      onCancel,
      userActivate: { error },
    } = this.props;
    const queryParams = new QueryString(this.props.location.search);

    const loginErrors = [];

    if (error) {
      loginErrors.push(Strings.errors.invalidActivationLink);
    }

    const { onlineErrors } = this.state;

    if ('error' in this.props.userNameCheck) {
      onlineErrors.username = this.props.userNameCheck.error ? Strings.errors.isAlreadyTaken : '';
    } else {
      delete onlineErrors.username;
    }

    return queryParams.params.origin !== 'fhir' ? (
      <UserActivateForm
        state={this.state}
        onlineErrors={onlineErrors}
        errors={loginErrors}
        onActivate={this.onActivate}
        onChange={this.onChange}
        onCancel={onCancel}
        togglePassword={this.togglePassword}
        togglePasswordType={this.togglePasswordType}
      />
    ) : (
      <div>
        <h1>Loading FHIR session...</h1>
      </div>
    );
  }
}

const UserActivateForm = ({
  state,
  onlineErrors,
  errors,
  onActivate,
  onChange,
  togglePassword,
  togglePasswordType,
}) => {
  const statusClasses = name => {
    const classes = [];

    if (name in onlineErrors) {
      if (onlineErrors[name] === '') {
        return 'success';
      }
      return 'has-error';
    }

    return classes.join(' ');
  };

  return (
    <form className="login-page user-activate" onSubmit={onActivate}>
      <div className="h1-wrapper ">
        <h1 className="center">{Strings.setPasswordToRxCap}</h1>
      </div>
      <div className="form-container">
        <input
          className={`text ${statusClasses('username')}`}
          onChange={onChange}
          type="text"
          name="username"
          required
          value={state.username}
          placeholder={Strings.placeholder.code}
        />
        {/* <p>{Strings.errors.invalidPassword}</p> */}

        <div className="password-wrapper">
          <input
            className="text"
            onChange={onChange}
            type={state.type}
            name="password"
            autoComplete="off"
            value={state.password}
            placeholder={Strings.placeholder.password}
          />

          <span className={`toggle-${state.type}`} onClick={togglePassword} />
        </div>

        <div className="password-wrapper">
          <input
            className="text"
            onChange={onChange}
            type={state.type1}
            name="confirm_password"
            autoComplete="off"
            value={state.confirm_password}
            placeholder={Strings.placeholder.confirmPassword}
          />

          <span className={`toggle-${state.type1}`} onClick={togglePasswordType} />
        </div>

        <div key="password_error" className="error">
          {onlineErrors.confirm_password}
        </div>

        {errors.length ? (
          <div key="error" className="error">
            {errors.join(' ')}
          </div>
        ) : null}
      </div>

      <div className="buttons row dotted-line">
        <input
          className="brand-blue full-width"
          type="submit"
          role="button"
          id="create-account-button"
          value="Submit"
        />
      </div>
    </form>
  );
};

export default connect(
  state => ({
    userNameCheck: state.entities.userNameCheck,
    userActivate: state.entities.userActivate,
  }),
  dispatch => ({
    onSSOLogin: (user, token) =>
      dispatch(
        ssoLogin({
          token,
          user,
          context: 'ACTIVATE',
          source: 'email',
        }),
      ),
    onActivate: payload =>
      dispatch(userActivateAction.actionPost(payload)).then(action => {
        if (action.type === USER_ACTIVATE_RESULT) {
          return dispatch(push('/verify-mobile-phone'));
        }

        return null;
      }),
    onNameCheck: username => dispatch(userNameCheckAction.actionPost({ username })),
  }),
)(
  CurrentLandingLayout({
    title: Strings.verifyAccountText,
    caption: Strings.youHaveBeenInvitedText + Strings.trackOfYourMedicineText + Strings.toVerifyYourAccountText,
  })(UserActivatePage),
);
