import React from 'react';
import PropTypes from 'prop-types';
import { XAxis, YAxis } from 'recharts';
import { X_AXIS_HEIGHT, X_AXIS_PADDING, Y_AXIS_LEFT_WIDTH, Y_AXIS_RIGHT_WIDTH } from './Constants';
import {
  GRAPH_AXIS_FONT_SIZE,
  GRAPH_AXIS_LINE_COLOR,
  GRAPH_AXIS_LINE_WIDTH,
  GRAPH_ROW_BK_ALTERNATE,
  GRAPH_X_AXIS_FONT_COLOR,
  GRAPH_X_AXIS_FONT_COLOR_REPEATED,
} from '../GraphConstants';
import { CustomizedYAxisTickHours, CustomizedYAxisTickNumeric } from '../GraphAxes';
import { PureComponent } from 'react';
import moment from 'moment-timezone';
import { DATE_FORMAT_MONTH_NAME } from '../../../constants';
import { isDesktop, isMobile } from 'react-device-detect';
import { mobileDataRangeTypes } from './Metrics';

export function calcXAxisParams_TimeMobile(dateRange) {
  let unit;
  let dateMin;
  let dateMax;
  let diff;
  let diffMulti = 1;
  let firstTickDay;
  let halfTickHours = 0;
  let tickMarginLeft = 0;
  let tickMarginRight = 0;

  let drawFirstValue = false;
  let drawLastValue = false;

  switch (dateRange.type) {
    case mobileDataRangeTypes.year: {
      dateMin = dateRange.start.clone();
      dateMax = dateRange.end.clone();
      firstTickDay = dateMin;
      diff = 12;
      unit = 'month';
      halfTickHours = 15 * 24;
      break;
    }
    case mobileDataRangeTypes.sixMonths: {
      dateMin = dateRange.start.clone();
      dateMax = dateRange.end.clone();
      firstTickDay = dateMin;
      diff = 6;
      unit = 'month';
      halfTickHours = 15 * 24;
      break;
    }
    case mobileDataRangeTypes.month: {
      dateMin = dateRange.start.clone();
      dateMax = dateRange.end.clone();
      firstTickDay = dateMin
        .clone()
        .add(6, 'day')
        .startOf('week')
        .startOf('day');
      unit = 'day';
      diffMulti = 7;
      diff = 5;
      halfTickHours = 0;
      break;
    }
    case mobileDataRangeTypes.twoWeeks: {
      dateMin = dateRange.start.clone();
      dateMax = dateRange.end.clone();
      firstTickDay = dateMin.clone().startOf('day');
      diff = 14;
      unit = 'day';
      halfTickHours = 12;
      tickMarginLeft = 23 * 60 * 60;
      break;
    }
    case mobileDataRangeTypes.day: {
      dateMin = dateRange.start.clone();
      dateMax = dateRange.start.clone().add(1, 'day');
      firstTickDay = dateMin
        .clone()
        .startOf('day')
        .add(Math.round(dateRange.start.hours() / 4) * 4, 'hours');
      diff = 24;
      unit = 'hour';
      diffMulti = 4;
      halfTickHours = 0;
      drawFirstValue = true;
      drawLastValue = true;
      break;
    }
    case mobileDataRangeTypes.week:
    default: {
      dateMin = dateRange.start.clone();
      dateMax = dateRange.end.clone();
      firstTickDay = dateMin.clone().startOf('day');
      diff = 8;
      unit = 'day';
      halfTickHours = 12;
      tickMarginLeft = 23 * 60 * 60;
      break;
    }
  }

  const units = unit + 's';

  const firstTickDayDeltaUnix = firstTickDay.unix() - dateMin.unix();

  // console.log(dateMinDay, dateMaxDay, diffMulti, units);
  const ticks = Array.from({ length: diff + 1 }, (_, i) => i)
    .map(i => firstTickDay.clone().add(i * diffMulti, units))
    .filter(t => t.unix() >= dateMin.unix() - tickMarginLeft && t.unix() < dateMax.unix() + tickMarginRight);
  //if (ticks[0].unix() < dateMin.unix()) ticks = ticks.slice(1, undefined);
  //if (ticks[ticks.length - 1].unix() >= dateMax.unix()) ticks = ticks.slice(0, ticks.length - 1);

  const refLines = ticks.filter(t => t.unix() > dateMin.unix() && t.unix() < dateMax.unix()).map(t => t.clone());

  // move ticks right by half tick
  if (halfTickHours > 0) {
    ticks.forEach(t => {
      t.add(halfTickHours, 'hours');
    });
  }

  const ret = {
    unit,
    drawFirstValue,
    drawLastValue,
    min: dateMin.unix(),
    max: dateMax.unix(),
    ticks: ticks.map(x => x.unix()),
    refLines,
    diffMulti,
  };

  //console.log(ret);

  return ret;
}

export function calcXAxisParams_Time(
  dateMinDay,
  dateMaxDay,
  xAxisLabelsMinMaxOnly,
  graphWidth,
  preferredTickWidth = 40,
  allowedUnits = ['h', 'd', 'm', 'y'],
  allowedMultiples = ['2h', '4h', '7d', '3m'],
) {
  if (xAxisLabelsMinMaxOnly) {
    return {
      unit: 'day',
      drawFirstValue: true,
      drawLastValue: true,
      min: dateMinDay.unix(),
      max: dateMaxDay.unix(),
      ticks: [dateMinDay.unix(), dateMaxDay.unix()],
    };
  }

  if (!graphWidth) {
    return {
      unit: 'day',
      drawFirstValue: true,
      drawLastValue: true,
      min: dateMinDay.unix(),
      max: dateMaxDay.unix(),
      ticks: [dateMinDay.unix(), dateMaxDay.unix()],
    };
  }

  const preferredTicks = Math.floor(graphWidth / preferredTickWidth) + 1; // 30px per tick
  const diffDays = dateMaxDay.diff(dateMinDay, 'days');
  const tickHours = (diffDays * 24) / preferredTicks;

  let unit = 'hour';
  let diffMulti = 1;

  if (allowedUnits.includes('h') && tickHours <= 1) {
    unit = 'hour';
    diffMulti = 1;
  } else if (allowedUnits.includes('h') && allowedMultiples.includes('2h') && tickHours <= 2) {
    unit = 'hour';
    diffMulti = 2;
  } else if (allowedUnits.includes('h') && allowedMultiples.includes('4h') && tickHours <= 4) {
    unit = 'hour';
    diffMulti = 4;
  } else if (allowedUnits.includes('d') && tickHours <= 1 * 24) {
    unit = 'day';
    diffMulti = 1;
  } else if (allowedUnits.includes('d') && allowedMultiples.includes('7d') && tickHours <= 7 * 24) {
    unit = 'day';
    diffMulti = 7;
  } else if (allowedUnits.includes('m') && tickHours <= 1 * 31 * 24) {
    unit = 'month';
    diffMulti = 1;
  } else if (allowedUnits.includes('m') && allowedMultiples.includes('3m') && tickHours <= 3 * 31 * 24) {
    unit = 'month';
    diffMulti = 3;
  } else if (allowedUnits.includes('y') && tickHours <= 12 * 31 * 24) {
    unit = 'year';
    diffMulti = 1;
  } else {
    unit = 'year';
    diffMulti = 1;
  }

  const units = `${unit}s`;

  let dateMin;
  let dateMax;
  let diff;

  let drawFirstValue = false;
  let firstValue;
  let drawLastValue = false;
  let lastValue;

  switch (unit) {
    case 'year': {
      dateMin = dateMinDay.clone().startOf('year');
      dateMax = dateMaxDay
        .clone()
        .endOf('year')
        .add(1, 'day')
        .startOf('year');
      diff = dateMax.diff(dateMin, 'years');

      break;
    }
    case 'month': {
      dateMin = dateMinDay
        .clone()
        .startOf('month')
        .add(-(dateMinDay.month() % diffMulti), 'months')
        .startOf('month');
      const dateMaxStartOfLastTick = dateMaxDay
        .clone()
        .startOf('month')
        .add(-(dateMinDay.month() % diffMulti), 'months')
        .startOf('month');
      dateMax = dateMaxStartOfLastTick.add(diffMulti, 'months').startOf('month');
      diff = dateMax.diff(dateMin, 'months') / diffMulti;
      break;
    }
    case 'hour': {
      dateMin = dateMinDay;
      dateMax = dateMaxDay;
      diff = (diffDays * 24) / diffMulti;
      break;
    }
    case 'day':
    default: {
      dateMin =
        diffMulti === 7 ? dateMinDay.clone().add(-((dateMinDay.day() - 1 + 7) % 7), 'days') : dateMinDay.clone();
      dateMax =
        diffMulti === 7
          ? dateMaxDay
              .clone()
              .add(-((dateMaxDay.day() - 1 + 7) % 7), 'days')
              .add(7, 'days')
          : dateMaxDay.clone();
      diff = dateMax.diff(dateMin, 'days') / diffMulti;
      break;
    }
  }

  // console.log(dateMinDay, dateMaxDay, diffMulti, units);
  let ticks = Array.from({ length: diff + 1 }, (_, i) => i).map(i => dateMin.clone().add(i * diffMulti, units));
  if (ticks[0].unix() < dateMinDay.unix()) ticks = ticks.slice(1, undefined);
  if (ticks[ticks.length - 1].unix() > dateMaxDay.unix()) ticks = ticks.slice(0, ticks.length - 1);

  // console.log(ticks);
  if (drawFirstValue) ticks.splice(0, 0, firstValue);
  if (drawLastValue) ticks.push(lastValue);

  const ret = {
    unit,
    drawFirstValue,
    drawLastValue,
    min: dateMinDay.unix(),
    max: dateMaxDay.unix(),
    ticks: ticks.map(x => x.unix()),
    diffMulti,
  };

  // console.log(ret);

  return ret;
}

export function calcXAxisParams_Hours(
  dateMinDay,
  dateMaxDay,
  graphWidth,
  preferredTickWidth = 40,
  allowedMultiples = ['2h', '4h', '12h'],
) {
  const errorRet = {
    unit: 'hour',
    drawFirstValue: true,
    drawLastValue: true,
    min: dateMinDay.unix(),
    max: dateMaxDay.unix(),
    ticks: [dateMinDay.unix(), dateMaxDay.unix()],
  };

  if (!graphWidth) {
    return errorRet;
  }

  const preferredTicks = Math.floor(graphWidth / preferredTickWidth) + 1; // 30px per tick
  const diffHours = dateMaxDay.diff(dateMinDay, 'hours');
  const tickHours = diffHours / preferredTicks;

  let unit = 'hour';
  let diffMulti = 1;

  if (tickHours <= 1) {
    diffMulti = 1;
  } else if (allowedMultiples.includes('2h') && tickHours <= 2) {
    diffMulti = 2;
  } else if (allowedMultiples.includes('4h') && tickHours <= 4) {
    diffMulti = 4;
  } else {
    return errorRet;
  }

  let dateMin = dateMinDay.clone();
  let dateMax = dateMaxDay.clone();
  if (diffMulti > 1 && dateMin.hours() % diffMulti !== 0) {
    dateMin.add(-(dateMin.hours() % diffMulti), 'hours');
  }
  if (diffMulti > 1 && dateMax.hours() % diffMulti !== 0) {
    dateMax.add(diffMulti - (dateMax.hours() % diffMulti), 'hours');
  }

  let diff = dateMax.diff(dateMin, 'hours') / diffMulti;
  const ticks = Array.from({ length: diff + 1 }, (_, i) => i).map(i => dateMin.clone().add(i * diffMulti, 'hours'));

  const ret = {
    unit,
    drawFirstValue: false,
    drawLastValue: false,
    min: dateMin.unix(),
    max: dateMax.unix(),
    ticks: ticks.map(x => x.unix()),
    diffMulti,
  };

  // console.log(ret);

  return ret;
}

export function drawXAxis(xAxisParams, height, orientation, leftCornerTexts, noAxisLine = false, oneLine = false) {
  // console.log(xAxisParams);
  const visible = height > 5;
  const validHeight = height < GRAPH_AXIS_LINE_WIDTH ? GRAPH_AXIS_LINE_WIDTH : height;

  return (
    <XAxis
      type="number"
      dataKey="x"
      allowDataOverflow
      height={validHeight}
      domain={[xAxisParams.min, xAxisParams.max]}
      ticks={visible ? xAxisParams.ticks : []}
      interval={0}
      name="day"
      orientation={orientation}
      tickSize={0}
      tick={
        visible ? (
          <CustomizedXAxisProportional
            xAxisParams={xAxisParams}
            xAxisLabelsMinMaxOnly={false}
            leftCornerTexts={leftCornerTexts}
            oneLine={oneLine}
          />
        ) : (
          false
        )
      }
      padding={{
        left: X_AXIS_PADDING,
        right: X_AXIS_PADDING,
      }}
      axisLine={{
        stroke: GRAPH_AXIS_LINE_COLOR,
        strokeWidth: noAxisLine ? 0 : GRAPH_AXIS_LINE_WIDTH,
      }}
    />
  );
}

export function drawYAxis(yAxisGroup, noAxisLine = false, forceWidth = 0) {
  //console.log(yAxisGroup)
  const isRight = yAxisGroup.axisId === 'yAxis2';
  const width = forceWidth ? forceWidth : isRight ? Y_AXIS_RIGHT_WIDTH : Y_AXIS_LEFT_WIDTH;
  return (
    <YAxis
      yAxisId={yAxisGroup.axisId}
      orientation={isRight ? 'right' : 'left'}
      type="number"
      dataKey="y"
      name={`yAxis_${yAxisGroup.axisId}`}
      allowDataOverflow
      tick={
        <CustomizedYAxisTickNumeric
          yMin={yAxisGroup.yMin}
          yMax={yAxisGroup.yMax}
          width={width}
          tick={yAxisGroup.tick}
          unit=""
          isRight={isRight}
        />
      }
      domain={
        yAxisGroup.hasData ? [yAxisGroup.yMin - yAxisGroup.tick / 2, yAxisGroup.yMax + yAxisGroup.tick / 2] : [0, 0]
      }
      ticks={yAxisGroup.hasData ? yAxisGroup.yTicks : [0]}
      interval={0}
      tickSize={isRight ? 2 : 0}
      width={width}
      axisLine={{
        stroke: GRAPH_AXIS_LINE_COLOR,
        strokeWidth: noAxisLine ? 0 : GRAPH_AXIS_LINE_WIDTH,
      }}
      padding={{
        top: 0,
        bottom: 0, // xAxisTopPosition ? 5 : 1,
      }}
    />
  );
}

export function drawYAxisTakes(yAxisTakes, isLeft, noAxisLine, forceWidth = 0) {
  const width = forceWidth ? forceWidth : Y_AXIS_LEFT_WIDTH;
  return (
    <YAxis
      yAxisId={isLeft ? 'yAxis1' : 'yAxis2'}
      orientation={isLeft ? 'left' : 'right'}
      type="number"
      dataKey="y"
      reversed
      name={isLeft ? 'yAxisTakesLeft' : 'yAxisTakesRight'}
      allowDataOverflow
      tick={
        isLeft ? (
          <CustomizedYAxisTickHours
            yMin={yAxisTakes.yMin}
            yMax={yAxisTakes.yMax}
            width={width}
            tick={yAxisTakes.tick}
            isRight={false}
          />
        ) : (
          false
        )
      }
      domain={[yAxisTakes.yMin, yAxisTakes.yMax]}
      ticks={isLeft ? yAxisTakes.yTicks : []}
      interval={0}
      tickSize={noAxisLine ? 0 : 4}
      width={width}
      axisLine={{
        stroke: GRAPH_AXIS_LINE_COLOR,
        strokeWidth: noAxisLine ? 0 : GRAPH_AXIS_LINE_WIDTH,
      }}
      padding={{
        top: 2,
        bottom: 5, // xAxisTopPosition ? 5 : 1,
      }}
    />
  );
}

const IsValidMoment = date => {
  return date != null && date instanceof moment && date.isValid();
};

export class CustomizedXAxisProportional extends PureComponent {
  render() {
    const {
      x,
      y,
      height,
      verticalAnchor,
      payload,
      xAxisParams,
      xAxisLabelsMinMaxOnly,
      leftCornerTexts,
      labelsOnTick,
      drawFirstAndLastTick,
      hourFormat,
      oneLine,
      oneMoreVisibleTick,
    } = this.props; // stroke

    const isTop = verticalAnchor === 'end';

    const day = moment(payload.value * 1000);
    const dayOfMonth = IsValidMoment(day) ? day.date() : -1;
    const isWeekend = IsValidMoment(day) && (day.day() === 6 || day.day() === 0);
    const dayOfWeekLetter = IsValidMoment(day) ? ['S', 'M', 'T', 'W', 'T', 'F', 'S'][day.day()] : '';
    const hour = IsValidMoment(day) ? day.hours() : -1;
    const monthOfYear = IsValidMoment(day) ? day.month() : -1;
    const month = IsValidMoment(day) ? day.format('MMM') : '';
    const year = IsValidMoment(day) ? day.format('YYYY') : '';

    const isFirstMonthOfYear = monthOfYear === 1;
    const isFirstDayOfMonth = dayOfMonth === 1;

    const isFirstTick = payload.value <= xAxisParams.ticks[0];
    const isLastTick = payload.value >= xAxisParams.ticks[xAxisParams.ticks.length - 1];
    const isLastTickEqualLastValue = isLastTick && payload.value === xAxisParams.max;

    const isMaxValue = payload.value === xAxisParams.max;
    const isMinValue = payload.value === xAxisParams.min;

    const isMax = payload.value === xAxisParams.max;

    const isExtraFirstTick = xAxisParams.drawFirstValue && isFirstTick;
    const isExtraLastTick = xAxisParams.drawLastValue && isLastTick;

    const unit = xAxisParams.unit;
    const unitTick = xAxisParams.diffMulti;

    if (xAxisLabelsMinMaxOnly) {
      if (isFirstTick || isLastTick) {
        const value = day.format(DATE_FORMAT_MONTH_NAME);
        return (
          <g transform={`translate(${x},${y})`}>
            <text
              className="span"
              fontSize={GRAPH_AXIS_FONT_SIZE}
              dy={isTop ? -10 : 15}
              textAnchor={isFirstTick ? 'start' : 'end'}
              fill={GRAPH_X_AXIS_FONT_COLOR}
            >
              {value}
            </text>
          </g>
        );
      }
      return null;
    }
    let value0;
    let value1;
    let value2;
    let fill0;
    let fill1;
    let fill2;
    let dx0;
    let dx1;
    let dx2;
    let anchor0;
    let anchor1;
    let anchor2;

    if (isLastTick && isLastTickEqualLastValue) return null;

    const tickWidth = this.props.width / (this.props.visibleTicksCount - 1 + (oneMoreVisibleTick ? 1 : 0));

    switch (unit) {
      case 'hour':
        value1 = hour === 0 && !isLastTick ? `${month} ${dayOfMonth}` : '';
        value2 =
          drawFirstAndLastTick || !(isFirstTick || isLastTick) || (isMobile && !(isMinValue || isMaxValue))
            ? day.format(hourFormat)
            : '';
        value0 = value2;
        fill1 = unit === 'hour' && !isWeekend ? GRAPH_X_AXIS_FONT_COLOR : GRAPH_X_AXIS_FONT_COLOR_REPEATED;
        fill2 = isWeekend ? GRAPH_X_AXIS_FONT_COLOR_REPEATED : GRAPH_X_AXIS_FONT_COLOR;
        fill0 = fill2;
        dx1 = (tickWidth * 24) / 2 / unitTick;
        dx2 = 0;
        dx0 = dx2;
        anchor1 = 'middle';
        anchor2 = 'middle';
        anchor0 = anchor2;
        break;
      case 'day':
        value1 = isFirstDayOfMonth || isFirstTick || xAxisParams.diffMulti !== 1 ? month : '';
        value2 = dayOfMonth;
        value0 = xAxisParams.diffMulti === 1 ? dayOfWeekLetter : isDesktop ? `${month} ${dayOfMonth}` : `${dayOfMonth}`;
        fill1 = GRAPH_X_AXIS_FONT_COLOR;
        fill2 = isWeekend ? GRAPH_X_AXIS_FONT_COLOR_REPEATED : GRAPH_X_AXIS_FONT_COLOR;
        fill0 = fill2;
        dx1 = isMobile ? 0 : labelsOnTick ? 0 : xAxisParams.diffMulti === 1 ? tickWidth / 2 : 10;
        dx2 = isMobile ? 0 : labelsOnTick ? 0 : xAxisParams.diffMulti === 1 ? tickWidth / 2 : 10;
        dx0 = dx2;
        anchor1 = isMobile || labelsOnTick || xAxisParams.diffMulti === 1 ? 'middle' : 'start';
        anchor2 = isMobile || labelsOnTick || xAxisParams.diffMulti === 1 ? 'middle' : 'start';
        anchor0 = anchor2;
        break;
      case 'month':
        value1 = month;
        value2 = year;
        value0 = month;
        fill1 = GRAPH_X_AXIS_FONT_COLOR;
        fill2 = GRAPH_X_AXIS_FONT_COLOR;
        fill0 = fill2;
        dx1 = isMobile ? 0 : 10;
        dx2 = isMobile ? 0 : 10;
        dx0 = dx2;
        anchor1 = isMobile ? 'middle' : 'start';
        anchor2 = isMobile ? 'middle' : 'start';
        anchor0 = anchor2;
        break;
      case 'year':
        value1 = isMax ? '' : !isExtraFirstTick ? year : `${month} ${year}`;
        value2 = '';
        fill1 = GRAPH_X_AXIS_FONT_COLOR;
        fill2 = GRAPH_X_AXIS_FONT_COLOR;
        dx1 = isMobile ? 0 : tickWidth / 2;
        dx2 = isMobile ? 0 : tickWidth / 2;
        anchor1 = 'middle';
        anchor2 = isMobile || isExtraFirstTick || isExtraLastTick ? 'middle' : 'start';
        break;
      default:
        break;
    }

    const rectHeight = 36;
    const rectWidth = 60;
    // console.log('drawXTick', value1, value2, value0, this.props);

    return (
      <g transform={`translate(${x},${y})`}>
        {isFirstTick && leftCornerTexts && leftCornerTexts.length > 0 && (
          <g transform={`translate(${-x},${0})`}>
            <rect
              y={isTop ? -(height / 2 + rectHeight / 2) : 15}
              x={Y_AXIS_LEFT_WIDTH / 2 - rectWidth / 2}
              width={rectWidth}
              height={rectHeight}
              fill={GRAPH_ROW_BK_ALTERNATE}
              stroke={GRAPH_ROW_BK_ALTERNATE}
              strokeWidth="10px"
              rx="10px"
              ry="10px"
              strokeLinejoin="round"
            />

            {leftCornerTexts.map((t, i) => (
              <text
                key={`leftCornerText_${i}`}
                className="span"
                fontSize={GRAPH_AXIS_FONT_SIZE}
                dy={(isTop ? -height / 2 : 15) + i * 16}
                dx={Y_AXIS_LEFT_WIDTH / 2}
                textAnchor="middle"
                fill={GRAPH_X_AXIS_FONT_COLOR}
              >
                {t}
              </text>
            ))}
          </g>
        )}

        {!oneLine && (
          <text
            className="span"
            fontSize={GRAPH_AXIS_FONT_SIZE}
            dy={isTop ? -height / 2 - 3 : 15}
            dx={dx1}
            textAnchor={anchor1}
            fill={fill1}
          >
            {value1}
          </text>
        )}

        {!oneLine && (
          <text
            className="span"
            fontSize={GRAPH_AXIS_FONT_SIZE}
            dy={isTop ? -height / 2 + 17 : 35}
            dx={dx2}
            textAnchor={anchor2}
            fill={fill2}
          >
            {value2}
          </text>
        )}

        {oneLine && (
          <text
            className="span"
            fontSize={GRAPH_AXIS_FONT_SIZE}
            dy={isTop ? -(height - GRAPH_AXIS_FONT_SIZE) / 2 : 15}
            dx={dx0}
            textAnchor={anchor0}
            fill={fill0}
          >
            {value0}
          </text>
        )}
      </g>
    );
  }
}
CustomizedXAxisProportional.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  verticalAnchor: PropTypes.string,
  payload: PropTypes.any,
  xAxisParams: PropTypes.object,
  extraFirstValue: PropTypes.number,
  extraLastValue: PropTypes.number,
  xAxisLabelsMinMaxOnly: PropTypes.bool,
  leftCornerTexts: PropTypes.array,
  labelsOnTick: PropTypes.bool,
  drawFirstAndLastTick: PropTypes.bool,
  hourFormat: PropTypes.string,
  oneMoreVisibleTick: PropTypes.bool,
};
CustomizedXAxisProportional.defaultProps = { hourFormat: 'h a' };
