import React from 'react';
import PropTypes from 'prop-types';

import Strings from '../../../../Strings';
import { makeValid } from '../../../../utils';
import TableWidget from '../component/TableWidget';

function CohortPeople(props) {
  const { cohort, isLoading } = props;
  const data = cohort
    ? [
        [Strings.createdBy, makeValid(cohort.created_by)],
        [Strings.primaryManager, makeValid(cohort.manager)],
        [Strings.secondaryManager, ''],
        [Strings.numberOfPatients, makeValid(cohort.patients_count)],
      ]
    : [];
  return (
    <TableWidget title={Strings.widgets.people} data={data} isLoading={isLoading} firstColumnClassName="cohort-col" />
  );
}

CohortPeople.propTypes = {
  isLoading: PropTypes.any,
  cohort: PropTypes.shape({
    created_by: PropTypes.string,
    manager: PropTypes.string,
    patients_count: PropTypes.number,
  }),
  onEdit: PropTypes.func,
};
CohortPeople.widgetId = 'CohortPeopleWidget';

export default CohortPeople;
