import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { Modal } from '../containers';
import { openModalAction, closeModal } from '../actions/modal';
import Strings from '../Strings';

class EditScheduleModal extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
  };

  static getTimeByOffset(offset) {
    const momentTime = moment.unix(offset).tz('UTC');
    const amOrPm = momentTime.format('a');

    return {
      am: amOrPm === 'am',
      pm: amOrPm === 'pm',
      hours: momentTime.format('hh'),
      minutes: momentTime.format('mm'),
    };
  }

  static getOffsetByTime(time) {
    let hours = +time.hours;

    if (+time.hours === 12) {
      if (time.am) {
        hours = 0;
      }
    } else if (time.pm) {
      hours += 12;
    }

    return (60 * hours + +time.minutes) * 60;
  }

  constructor(props) {
    super(props);

    this.state = {
      timeOffset: 0,
      time: EditScheduleModal.getTimeByOffset(0),
      step: 'hours',
    };

    this.onAddTime = this.onAddTime.bind(this);
    this.backToHours = this.backToHours.bind(this);
  }

  onAddTime() {
    const { timeOffset } = this.state;
    const { schedule } = this.props.data;

    schedule.times[timeOffset] = {
      offset: timeOffset,
      time: moment.unix(timeOffset).tz('UTC'),
      label: moment
        .unix(timeOffset)
        .tz('UTC')
        .format('h:mma'),
    };

    schedule.events = Object.values(schedule.times)
      .map(time => time.offset)
      .sort()
      .join(',');

    return this.props.onSubmit();
  }

  onAmPmSwitch(am, pm) {
    const { time } = this.state;

    time.am = am;
    time.pm = pm;

    this.setState({
      time,
      timeOffset: EditScheduleModal.getOffsetByTime(time),
    });
    this.forceUpdate();
  }

  onClockArrowClick(value) {
    const { time } = this.state;
    let { step } = this.state;

    if (step === 'hours') {
      time.hours = value < 10 ? `0${value}` : value;

      step = 'minutes';
    } else {
      time.minutes = value < 10 ? `0${value}` : value;
    }

    this.setState({
      time,
      step,
      timeOffset: EditScheduleModal.getOffsetByTime(time),
    });
    this.forceUpdate();
  }

  backToHours() {
    this.setState({ step: 'hours' });
    this.forceUpdate();
  }

  render() {
    const { time, step } = this.state;

    const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const minutes = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 0];

    const clockWidth = 180;
    const clockHeight = 180;

    const position = index => {
      const rad = -2 * Math.PI * (((index + 7) % 12) / 12);

      return {
        left: clockWidth / 2 + (clockWidth * Math.sin(rad)) / 2 - 18,
        top: clockHeight / 2 + (clockHeight * Math.cos(rad)) / 2 - 18,
      };
    };

    const rotate = () => {
      let index;

      if (step === 'hours') {
        index = hours.indexOf(+time.hours);
      } else {
        index = minutes.indexOf(+time.minutes);
      }

      const deg = 360 * (((index + 7) % 12) / 12);

      return `rotate(${deg}deg)`;
    };

    return (
      <Modal name="schedule-time-picker">
        <div className="picker content-block">
          <div className="top">
            <div className={`am-pm ${time.am ? 'active' : ''}`} onClick={() => this.onAmPmSwitch(true, false)}>
              AM
            </div>
            <div className="time">
              <div className={`hours ${step === 'hours' ? 'active' : ''}`}>{time.hours}</div>
              <div className="separator">:</div>
              <div className={`minutes ${step === 'minutes' ? 'active' : ''}`}>{time.minutes}</div>
            </div>
            <div className={`am-pm ${time.pm ? 'active' : ''}`} onClick={() => this.onAmPmSwitch(false, true)}>
              PM
            </div>
          </div>
          <div className="clock-wrapper">
            <div
              className="clock"
              style={{
                width: clockWidth + 44,
                height: clockHeight + 44,
              }}
            >
              <div
                className="numbers"
                style={{
                  width: clockWidth,
                  height: clockHeight,
                }}
              >
                {(step === 'hours' ? hours : minutes).map((value, index) => (
                  <div
                    key={value}
                    onClick={() => this.onClockArrowClick(value)}
                    style={position(index)}
                    className={`value ${+value === +(step === 'hours' ? time.hours : time.minutes) ? 'active' : ''}`}
                  >
                    {value}
                  </div>
                ))}
                <div className="arrow" style={{ transform: rotate() }} />
              </div>
            </div>
          </div>
          <div className="buttons" key="button-bar">
            {step === 'hours' ? (
              <button className="brand-white-gray" key="cancel" onClick={this.props.onCancel}>
                {Strings.cancel}
              </button>
            ) : (
              <button className="brand-white-gray" key="back" onClick={this.backToHours}>
                {Strings.backToHours}
              </button>
            )}
            <button className="brand-white-gray" key="submit" onClick={this.onAddTime}>
              {Strings.add}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => {
    dispatch(closeModal('schedule-time-picker'));
    dispatch(openModalAction('edit-schedule', { schedule: ownProps.data.schedule }));
  },
  onSubmit: () => {
    dispatch(closeModal('schedule-time-picker'));
    dispatch(openModalAction('edit-schedule', { schedule: ownProps.data.schedule }));
  },
});

export default connect(null, mapDispatchToProps)(EditScheduleModal);
