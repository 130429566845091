import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { actions } from './redux/actions';
import '../Widgets.scss';
import Strings from '../../../../Strings';

class PatientsEnrollment extends PureComponent {
  componentDidMount() {
    this.props.getData();
  }

  render() {
    const { isLoading, enrolledPatients, pendingEnrollments } = this.props;
    return (
      <React.Fragment>
        {!isLoading && (
          <div className="box2colsWithVl">
            <div className="box2colsWithVl-box">
              <div className="box2colsWithVl-valueBox black">{enrolledPatients}</div>
              <div className="box2colsWithVl-labelBox clickable">{Strings.enrolledPatients}</div>
            </div>
            <div className="box2colsWithVl-box">
              <div className="box2colsWithVl-valueBox black">{pendingEnrollments}</div>
              <div className="box2colsWithVl-labelBox clickable">{Strings.pendingEnrollment}</div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

PatientsEnrollment.widgetId = 'PatientsEnrollmentWidget';

PatientsEnrollment.propTypes = {
  getData: PropTypes.func,
  enrolledPatients: PropTypes.any,
  pendingEnrollments: PropTypes.any,
  isLoading: PropTypes.any,
  onClick: PropTypes.func,
};

const mapStateToProps = state => {
  const { widgetPatientsEnrollmentReducer } = state.dashboardWidgets;
  return {
    enrolledPatients: widgetPatientsEnrollmentReducer && widgetPatientsEnrollmentReducer?.enrolledPatients,
    pendingEnrollments: widgetPatientsEnrollmentReducer && widgetPatientsEnrollmentReducer?.pendingEnrollments,
    isLoading: widgetPatientsEnrollmentReducer && widgetPatientsEnrollmentReducer?.isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  getData: () => dispatch(actions.getPatientsEnrollment()),
  onClick: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientsEnrollment);
