import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './index.module.scss';

export function Container({ children, style, className }) {
  return (
    <div className={cn(styles.container, className)} style={style ?? undefined}>
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.element.isRequired,
  style: PropTypes.object,
  className: PropTypes.any,
};
