import { API_CALL } from '../../../../../middleware/api';
import { WIDGET_GET_DEVICES_READINGS } from './constants';

export const actions = {
  getDevicesReadingsCount: timezone => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: `/v1/devices-readings-per-day?timezone=${timezone}`,
    },
    type: WIDGET_GET_DEVICES_READINGS,
  }),
};
