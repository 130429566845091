import React from 'react';
import PropTypes from 'prop-types';

import { Button, HEADER_BUTTON_DARK_BLUE } from '../../components/PageHeader/Button';
import Strings from '../../Strings';
import './index.scss';

function DropdownItems(props) {
  const handleChange = (item, value) => {
    props.handleChange(item, value);
  };
  const handleGroupChange = (group, value) => {
    props.items
      .filter(i => i.group === group)
      .forEach(item => {
        props.handleChange(item.id, value);
      });
  };

  const { topLabelText } = props;

  // Group items by "group" property
  const groupedItems = {};
  props.items.forEach(item => {
    const group = item.group || 'none';
    if (!groupedItems[group]) {
      groupedItems[group] = [];
    }
    groupedItems[group].push(item);
  });

  return (
    <div className="dropdown">
      <div className="top-label">
        <label className="top-label">{topLabelText ? topLabelText : Strings.showSelectedItems}</label>
      </div>
      {Object.entries(groupedItems).map(([group, items]) => (
        <React.Fragment key={group}>
          {group !== 'none' && (
            <Item
              key={`group_${group}`}
              id={group}
              label={group}
              checked={!items.some(i => !i.checked)}
              handleChange={handleGroupChange}
              disabled={!items.some(i => !i.disabled)}
              grouped={false}
            />
          )}
          {items.map((item, i) => (
            <Item
              key={item.id}
              id={item.id}
              label={item.label}
              checked={item.checked}
              handleChange={handleChange}
              disabled={item.disabled || (i === 0 && !props.dontDisableFirstEntry)}
              grouped={group !== 'none'}
            />
          ))}
        </React.Fragment>
      ))}
      <div className="reset-button">
        <Button class={HEADER_BUTTON_DARK_BLUE} onClick={props.resetToDefaults} title={Strings.resetToDefaults} />
      </div>
    </div>
  );
}

DropdownItems.propTypes = {
  states: PropTypes.object, // states of switches
  items: PropTypes.array, // switches
  handleChange: PropTypes.func,
  resetToDefaults: PropTypes.func,
  topLabelText: PropTypes.string,
  dontDisableFirstEntry: PropTypes.bool,
};

class Item extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ target }) {
    this.props.handleChange(this.props.id, target.checked);
  }

  render() {
    return (
      <div align="left" className={`filter ${this.props.grouped ? 'grouped' : ''}`}>
        <label className="form-control" htmlFor={this.props.id}>
          <input
            type="checkbox"
            className="filter-label"
            id={this.props.id}
            onClick={this.handleChange}
            checked={this.props.checked}
            disabled={this.props.disabled}
          />
          <div className="filter-text">{this.props.label}</div>
        </label>
      </div>
    );
  }
  static propTypes = {
    id: PropTypes.string,
    handleChange: PropTypes.func,
    label: PropTypes.any,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    grouped: PropTypes.bool,
  };
}

class DropdownTableFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { open: false };

    this.resetToDefaults = this.resetToDefaults.bind(this);
  }

  setOpen(value) {
    this.setState({ open: value });
  }

  resetToDefaults() {
    this.setOpen(false);
    this.props.resetToDefaults();
  }

  onBlur = event => {
    // if Click is outside of this list components, lets close it, mean that we lost focus
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setOpen(false);
    }
  };

  render() {
    const { open } = this.state;
    const { topLabelText } = this.props;

    const dropdownMenu = (
      <React.Fragment>
        <DropdownItems
          items={this.props.filters}
          handleChange={this.props.handleChange}
          resetToDefaults={this.resetToDefaults}
          topLabelText={topLabelText}
          dontDisableFirstEntry={this.props.dontDisableFirstEntry || undefined}
        />
      </React.Fragment>
    );

    const iconStyle = open ? 'filtervisibility-icon-on' : 'filtervisibility-icon';

    return (
      <div className="dropdown-container" tabIndex={1} onBlur={e => this.onBlur(e)}>
        <div className="filtervisibility-container">
          <div className={iconStyle} onClick={() => this.setOpen(!this.state.open)}></div>
        </div>
        {open && dropdownMenu}
      </div>
    );
  }

  static propTypes = {
    filters: PropTypes.array,
    handleChange: PropTypes.func,
    resetToDefaults: PropTypes.func,
    topLabelText: PropTypes.string,
    dontDisableFirstEntry: PropTypes.bool,
  };
}

class FiltersStateHelper {
  static changeFilterState = (filters, filter_id, value) => {
    const to_update = filters.find(e => e.id === filter_id);
    to_update.checked = value;
    return filters.map(filter => (filter.filter_id !== filter_id ? filter : to_update));
  };

  static getExcludedItems = filters_state => {
    const excluded_items = filters_state.map(e => {
      if (e.checked === false) return e.id;
      return null;
    });

    return excluded_items.filter(e => e !== null);
  };

  static generateDefaultFilterStateFromColumns = columns => {
    return columns.map(e => {
      return {
        id: e.key,
        label: e.props.title,
        checked: true,
      };
    });
  };

  static getDefaultOnFilters = filters => {
    /* eslint-disable */
    filters.forEach((filter, index, filters_array) => {
      filters_array[index].checked = true;
    });
    /* eslint-enable */
    return filters;
  };
}

export { FiltersStateHelper, DropdownTableFilter };
