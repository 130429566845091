import {
  CLEAR_DATA,
  GET_PROVISIONING_DEVICES,
  GET_PROVISIONING_DEVICES_RESULT,
  GET_PROVISIONING_DEVICES_ERROR,
  POST_PROVISIONING_DEVICES,
  POST_PROVISIONING_DEVICES_RESULT,
  POST_PROVISIONING_DEVICES_ERROR,
  PUT_PROVISIONING_DEVICES,
  PUT_PROVISIONING_DEVICES_RESULT,
  PUT_PROVISIONING_DEVICES_ERROR,
  DELETE_PROVISIONING_DEVICES,
  DELETE_PROVISIONING_DEVICES_RESULT,
  DELETE_PROVISIONING_DEVICES_ERROR,
  GET_IMEI_FROM_ID_MFG,
  GET_IMEI_FROM_ID_MFG_RESULT,
} from './constants';

function getErrorMessage(response) {
  return response.data && response.data.error && response.data.error.message ? response.data.error.message : null;
}

export const reducer = (state = null, action) => {
  switch (action.type) {
    case GET_PROVISIONING_DEVICES:
      return {
        ...state,
        isLoading: true,
      };

    case GET_PROVISIONING_DEVICES_ERROR:
      return {
        ...state,
        isLoading: false,
        devices: [],
      };
    case GET_PROVISIONING_DEVICES_RESULT:
      return {
        ...state,
        devices: action.response.data,
        pagination: action.response.pagination,
        isLoading: false,
      };
    case POST_PROVISIONING_DEVICES:
      return {
        ...state,
        isLoading: true,
      };

    case POST_PROVISIONING_DEVICES_ERROR:
      return {
        ...state,
        isLoading: false,
        devices: [],
      };
    case POST_PROVISIONING_DEVICES_RESULT:
      return {
        ...state,
        devices: action.response.data,
        pagination: action.response.pagination,
        isLoading: false,
      };
    case PUT_PROVISIONING_DEVICES:
      return {
        ...state,
        isLoading: true,
      };

    case PUT_PROVISIONING_DEVICES_ERROR:
      return {
        ...state,
        isLoading: false,
        devices: [],
      };
    case PUT_PROVISIONING_DEVICES_RESULT:
      return {
        ...state,
        devices: action.response.data,
        pagination: action.response.pagination,
        isLoading: false,
      };
    case DELETE_PROVISIONING_DEVICES:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_PROVISIONING_DEVICES_ERROR:
      return {
        ...state,
        isLoading: false,
        devices: [],
      };
    case DELETE_PROVISIONING_DEVICES_RESULT:
      return {
        ...state,
        devices: action.response.data,
        pagination: action.response.pagination,
        isLoading: false,
      };

    case GET_IMEI_FROM_ID_MFG:
      return {
        ...state,
        isLoading: false,
      };

    case GET_IMEI_FROM_ID_MFG_RESULT:
      return {
        ...state,
        isLoading: false,
        imei: action.response.data,
      };

    case CLEAR_DATA:
      return {
        ...state,
        devices: [],
        organizationDevices: [],
        deviceTypes: [],
      };

    default:
      return state;
  }
};
