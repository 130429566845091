import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { actions } from './redux/actions';
import MapGraph, { calculateSignalColor } from '../../../../components/Graphs/MapGraph';
import { getFullLocationObject } from '../../../../utils';
import '../Widgets.scss';
import Strings from '../../../../Strings';
import infoIcon from '../../../../images/rx/infoIcon.svg';

const MAX_ZOOM_LEVEL = 15;
function LocationOfYourKits(props) {
  useEffect(() => {
    props.getData();
  }, [props.refreshTimestamp]);

  const handleMarkerClick = hub => {
    props.navigate(`/devices/hub/${hub.properties.id}`);
  };

  const { data, isLoading } = props;

  const hubs =
    data && data.length > 0
      ? data.map(d => {
          const location = getFullLocationObject(d.location);
          if (!location || !location?.lat || !location?.lng) {
            return null;
          }
          // objects for map needs to be in GeoJSON Feature format:
          // https://datatracker.ietf.org/doc/html/rfc7946#section-3.2
          return {
            type: 'Feature',
            properties: {
              cluster: false,
              id: d.id,
              signalStrength: d.signal_strength,
              color: calculateSignalColor(d.signal_strength),
              location,
            },
            geometry: {
              type: 'Point',
              coordinates: [location.lng, location.lat],
            },
          };
        })
      : [];

  const hubsWithLocation = hubs ? hubs.filter(h => h?.properties?.location) : [];

  return (
    <div style={{ height: `100%` }}>
      <MapLegend />
      <div className="heightMap clickable">
        {hubsWithLocation && !isLoading && window.location.host !== 'localhost:3000' && (
          <MapGraph
            onClick={marker => handleMarkerClick(marker)}
            markers={hubsWithLocation}
            maxZoomLevel={MAX_ZOOM_LEVEL}
          />
        )}
      </div>
    </div>
  );
}

LocationOfYourKits.widgetId = 'LocationOfYourKitsWidget';

LocationOfYourKits.propTypes = {
  getData: PropTypes.func,
  data: PropTypes.array,
  isLoading: PropTypes.any,
  navigate: PropTypes.func,
  refreshTimestamp: PropTypes.any.isRequired,
};

const mapStateToProps = state => {
  const { widgetLocationOfYourKitsReducer } = state.dashboardWidgets;
  return {
    data: widgetLocationOfYourKitsReducer && widgetLocationOfYourKitsReducer?.data,
    isLoading: widgetLocationOfYourKitsReducer && widgetLocationOfYourKitsReducer?.isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  getData: () => dispatch(actions.getHubs()),
  navigate: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationOfYourKits);

const MapLegend = () => {
  return (
    <div className="mapGraphHeader">
      <div className="mapGraphLabelWithIcon">
        <div
          data-tooltip-content={Strings.mapTooltipText}
          data-tooltip-id={undefined}
          className="mapGraphLabelWithIcon-IconContainer"
        >
          <img src={infoIcon} alt="info icon" className="mapGraphLabelWithIcon-Icon" />
        </div>
        <div className="mapGraphLabel">{Strings.locationOfDevices}</div>
      </div>
      <div className="legendContainer">
        <div className="dot low"></div>
        <div className="label">{Strings.lowSignal}</div>
        <div className="dot medium"></div>
        <div className="label">{Strings.moderateSignal}</div>
        <div className="dot high"></div>
        <div className="label">{Strings.highSignal}</div>
      </div>
    </div>
  );
};
