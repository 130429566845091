import React from 'react';

import { AutosuggestInput } from '../components/AutosuggestInput/AutosuggestInput';
import { getFieldWithComponent } from './getFieldWithComponent';
import { ComponentWithTooltip } from '../utils';

function AutosuggestField(props) {
  const { input, meta, ...rest } = props;
  const error = typeof rest.error === 'boolean' ? rest.error && meta.error : meta.touched && meta.error;

  return <ComponentWithTooltip componentType={AutosuggestInput} {...rest} error={error} inputProps={input} />;
}

const Component = getFieldWithComponent(AutosuggestField);

export { Component as AutosuggestField };
