import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './AppHeader.module.scss';

export const MobileHeader = ({ onMenuClick, onLogoClick, leftMenuVisible, leftInteractionEnabled = true }) => (
  <header className={styles.mobile}>
    <div onClick={leftInteractionEnabled ? onLogoClick : () => {}} className={cn(styles.headerLeft, {})}>
      <div
        className={cn(styles.headerLogo, {
          [styles.cursorArrow]: leftInteractionEnabled,
        })}
        onClick={onMenuClick}
      />

      <button
        onClick={onMenuClick}
        className={cn(styles.hamburger, { [styles.menuOpened]: leftMenuVisible })}
        tabIndex={0}
        aria-label="menu"
      ></button>
    </div>
  </header>
);

MobileHeader.propTypes = {
  onMenuClick: PropTypes.func,
  onLogoClick: PropTypes.func,
  leftInteractionEnabled: PropTypes.bool,
  leftMenuVisible: PropTypes.bool,
};
