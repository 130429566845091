import { LIST_COUNTRIES_RESULT, LIST_COUNTRIES_ERROR } from '../actions/action-types';

const countriesReducer = (
  state = {
    countries: [],
    timezones: [],
  },
  action,
) => {
  switch (action.type) {
    case LIST_COUNTRIES_RESULT:
      return {
        ...state,
        countries: action.response.countries,
        timezones: action.response.timezones,
      };
    case LIST_COUNTRIES_ERROR:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
export default countriesReducer;
