import { API_CALL } from '../middleware/api';
import { OverViewSchema } from '../schemas';
import { GENERAL_OVERVIEW } from './action-types';

export const overViewAction = {
  actionOverView: pageRequest => ({
    [API_CALL]: {
      endpoint: `/portal/overview`,
      data: null,
      params: { ...pageRequest },
      schema: OverViewSchema,
    },
    type: GENERAL_OVERVIEW,
  }),
};
