import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Line } from 'components/Login/Background/Line';
import cn from 'classnames';

import allDoneImg from '../../../images/rx/alldone.svg';
import styles from './Background.module.scss';
import Copyright from '../../copyright';
export class Background extends PureComponent {
  renderFooter() {
    return <Copyright></Copyright>;
  }

  render() {
    const {
      title,
      icon,
      isEsi,
      isError,
      progress,
      children,
      isWhite = false,
      noLines = false,
      footer = this.renderFooter(),
    } = this.props;
    const lines = [];
    if (!noLines) {
      for (let i = 0; i < 8; i += 1) lines.push(<Line key={i} />);
    }
    return (
      <div className={cn(styles.background, isWhite ? styles.white : null)}>
        <div className={styles.animationContainer}>{lines}</div>
        <div className={styles.container}>
          {title && (
            <div className={styles.title}>
              {icon && <img className={styles.icon} src={allDoneImg} alt="all done" />} {title}
              {typeof progress !== 'undefined' && (
                <div
                  className={styles.progressBar}
                  role="progressbar"
                  aria-valuenow={Math.round(progress)}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    style={{ width: `${progress}%` }}
                    className={`${progress === 100 ? styles.complete : ''} ${isError ? styles.error : ''}`}
                  />
                </div>
              )}
            </div>
          )}
          {typeof children !== 'undefined' && (
            <div className={cn(styles.content, isEsi && styles.isEsi)}>{children}</div>
          )}
        </div>

        <div className={cn(styles.footer, isEsi && styles.isEsi)}>{footer}</div>
      </div>
    );
  }
}

Background.propTypes = {
  children: PropTypes.any,
  footer: PropTypes.func,
  icon: PropTypes.any,
  isError: PropTypes.any,
  isEsi: PropTypes.any,
  isWhite: PropTypes.bool,
  noLines: PropTypes.bool,
  progress: PropTypes.number,
  title: PropTypes.any,
};
