export const BLOOD_PRESSURE = `blood_pressure`;
export const SLEEP_REPORT = 'sleep_report';
export const GLUCOMETER = 'glucometer';
export const HEART_RATE = 'heart_rate';
export const BATTERY = `battery`;
export const CONNECTION = `connection`;
export const WEIGHT = 'weight';
export const BLUETOOTH = 'bluetooth';
export const BLUETOOTH_CAP = 'Bluetooth cap';
export const WEIGHING_MACHINE = 'Weighing Machine';
export const BLOOD_PRESSURE_MONITOR = 'Blood Pressure Monitor';
export const GLUCOMETER_DEVICE_TYPE = 'Glucometer';
export const WRIST_BAND = 'Wrist Band';
export const MOVEMENT_REPORT = 'movement_report';
export const STEPS_REPORT = 'steps_report';
export const SPIROMETER = 'spirometer';
export const SPIROMETER_DEVICE_TYPE = 'Spirometer';
export const HAILIE_INHALER_DEVICE_TYPE = 'Hailie Inhaler';
export const HAILIE_INHALER = 'hailie_inhaler';
export const PTINR_MONITOR_DEVICE_TYPE = 'PT/INR Monitor';
export const PTINR_MONITOR = 'pt_inr';
export const HUB = 'hub';
export const LTE = 'lte';
export const LSC = 'lsc';
export const PXO = 'Pulse Oximeter';
export const INHALER = 'Inhaler';
export const THERMOMETER = 'thermometer';
export const THERMOMETER_DEVICE_TYPE = 'Thermometer';
export const PULSE_OXIMETER_DEVICE_TYPE = 'Pulse Ox';
export const PULSEOX = 'pulseox';
export const AIR_QUALITY_MONITOR_DEVICE_TYPE = 'Air Quality Monitor';
export const AIR_QUALITY_MONITOR = 'atmocube';
export const INJECTABLE_TRACKER = 'Injectable Medication Tracker';
export const MDP = 'mdp';

export const DEVICE_TYPES = {
  BLOOD_PRESSURE_MONITOR,
  GLUCOMETER_DEVICE_TYPE,
  WRIST_BAND,
  SPIROMETER_DEVICE_TYPE,
  HAILIE_INHALER_DEVICE_TYPE,
  PULSE_OXIMETER_DEVICE_TYPE,
  THERMOMETER_DEVICE_TYPE,
  AIR_QUALITY_MONITOR_DEVICE_TYPE,
  WEIGHING_MACHINE,
};
