import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Errors } from '../../components/Login/Errors/Errors';
import { ButtonRow } from '../../components/Login/ButtonRow/ButtonRow';
import { Button, BUTTON_TYPE_BLUE, BUTTON_TYPE_WHITE } from '../../components/Buttons/Button';
import { Text } from '../../components/Login/Text/Text';
import { CodeInput } from '../../components/Inputs/CodeInput';
import { Timer } from '../../components/Timer';
import Strings from '../../Strings';

export class TwoFAForm extends PureComponent {
  state = {
    errors: [],
    isCodeEmpty: true,
  };

  codeInput = React.createRef();

  onCodeChange = () => {
    const length = this.codeInput.current.getValue().length;
    this.setState({ isCodeEmpty: length !== 6 });
  };

  setError(error) {
    this.setState(state => ({ errors: [...state.errors, error] }));
  }

  clearError() {
    this.setState({ errors: [] });
  }

  // Internal error is linked to outer error from props
  static getDerivedStateFromProps(props) {
    if (props.error) {
      return { errors: [props.error] };
    }

    return null;
  }

  onFormSubmit = event => {
    const { onFormSubmit } = this.props;

    event.preventDefault();
    this.clearError();

    const code = this.codeInput.current.getValue();

    onFormSubmit({ code });
  };

  onExpired = () => {
    const { onBack } = this.props;
    onBack();
  };

  render() {
    const { errors, isCodeEmpty } = this.state;
    const { isLoading, onResend, expiresAt } = this.props;

    let text;

    if (!errors || errors.length === 0) {
      text = (
        <Text>
          {Strings.twoFAFormCodeText}
          {expiresAt ? <Timer expiresAt={expiresAt} onExpired={this.onExpired} inlineMode /> : null}
        </Text>
      );
    } else {
      text = <Errors NoMT errors={errors} />;
    }

    return (
      <form onSubmit={this.onFormSubmit}>
        <div>{text}</div>

        <CodeInput ref={this.codeInput} onChange={this.onCodeChange} />

        <ButtonRow
          left={
            <Button type="button" buttonType={BUTTON_TYPE_WHITE} disabled={isLoading} onClick={onResend}>
              {Strings.resend}
            </Button>
          }
          right={
            <Button
              buttonType={BUTTON_TYPE_BLUE}
              type="submit"
              data-testid="submit"
              disabled={isCodeEmpty || isLoading}
            >
              {Strings.next}
            </Button>
          }
        />
      </form>
    );
  }
}

TwoFAForm.propTypes = {
  expiresAt: PropTypes.any,
  isLoading: PropTypes.any,
  onBack: PropTypes.func,
  onFormSubmit: PropTypes.func,
  onResend: PropTypes.any,
};
