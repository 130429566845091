import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { TimeDeltaPlot } from '../../../components/Plots/index';
import { patientAction } from '../../../actions/patient';
import WithLoader from '../../../components/_hocs/withLoader/index';
import { Button } from '../../../components/PageHeader/Button';
import './style.scss';

const pageRequest = {
  startDate: null,
  endDate: null,
  status: 'taken',
  offset: 0,
  limit: 100,
};

function TimeDelta(props) {
  const [refreshEnable, setRefreshEnable] = useState(true);

  const load = () => {
    const { dateFilter, patientId } = props;

    pageRequest.startDate = moment(dateFilter.infimum)
      .startOf('day')
      .toDate();

    pageRequest.endDate = moment(dateFilter.supremum)
      .endOf('day')
      .toDate();

    props.onLoadSchedules(pageRequest, patientId, true);
  };

  useEffect(() => {
    load();
  }, [props.dateFilter]);

  const onRefresh = () => {
    setRefreshEnable(false);
    setTimeout(() => {
      setRefreshEnable(true);
    }, 10000);
    load();
  };

  return (
    <WithLoader isLoading={props.isLoading} isMounted={props.isLoading}>
      <Button class="refreshTimeDelta" disabled={!refreshEnable} onClick={onRefresh} />
      <div className="plot-row">
        <div className="column full-width">
          <TimeDeltaPlot
            data={props.notifications}
            minDate={props.dateFilter.infimum}
            maxDate={props.dateFilter.supremum}
            isPreparing={props.isPreparing}
            isLoading={props.isLoading}
          />
        </div>
      </div>
    </WithLoader>
  );
}

const mapStateToProps = state => {
  const { patientMedicationHistory, loading, preparing } = state.entities.patientMedicationHistory;

  return {
    isPreparing: preparing,
    isLoading: loading,
    notifications: patientMedicationHistory.events,
    dateFilter: state.filter.dateFilter,
  };
};

const mapDispatchToProps = dispatch => ({
  onLoadSchedules: (request, patientId, loader) =>
    dispatch(patientAction.actionPatientTimeDelta(request, patientId, loader)),
});

TimeDelta.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isPreparing: PropTypes.bool,
  dateFilter: PropTypes.object,
  notifications: PropTypes.any,
  patientId: PropTypes.any,
  onLoadSchedules: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeDelta);
