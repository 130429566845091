import EsiPatientSettingsPage from 'pages/ESI/PatientSettingsPage/PatientSettingsPage';
import MedicationDetailsView from 'pages/MedicationDetails/MedicationDetailsView';
import React, { Suspense } from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import LoadingRenderer from './components/LoadingRenderer';
import App from './containers/App';
import AddCareGiverUsingLink from './pages/AddCareGiverUsingLink';
import AppRedirection from './pages/AppRedirection/AppRedirection';
import Calendar from './pages/Calendar';
import CareGiverSuccessPage from './pages/CareGiverSuccessPage';
import Dashboard from './pages/Dashboard/Dashboard';
import DevicesDashboard from './pages/Dashboards/DevicesDashboard';
import EsiPatientLicense from './pages/documents/EsiPatientLicense';
import EsiPatientPrivacy from './pages/documents/EsiPatientPrivacy';
import EsiPatientTerms from './pages/documents/EsiPatientTerms';
import esiInviteDoctor from './pages/ESI/InviteDoctor';
import PatientDashboard from './pages/ESI/PatientDashboard';
import ESIPatientLogin from './pages/ESI/PatientLogin';
import ESIPatients from './pages/ESI/Patients';
import ESISupport from './pages/ESI/Support/Support';
import GroupInvitationConfirmPage from './pages/invitations/GroupInvitationConfirm';
import UserActivatePage from './pages/invitations/UserActivate';
import UserActivatedPage from './pages/invitations/UserActivatedPage';
import UserVerifyPhonePage from './pages/invitations/UserVerifyPhonePage';
import VerificationComplete from './pages/invitations/VerificationComplete';
import Login from './pages/login/Login';
import MedicationsView from './pages/Medications/MedicationsView';
import MyPatients from './pages/MyPatients';
import Patient from './pages/Patient';
import ESIPatientInfoPage from './pages/patient/ESIPatient/ESIPatientInfoPage';
import Medications from './pages/patient/Medications';
import PatientSettingsPage from './pages/patient/PatientSettingsPage';
import HistoryTab from './pages/patient/tabs/HistoryTab/HistoryTab';
import Privacy from './pages/PrivacyPage';
import Terms from './pages/TermsPage';
import ResetByLink from './pages/ResetByLink';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import SettingsPage from './pages/Settings/Settings';

import ShipperCapsDetails from './pages/ShipperCapsDetails';
import BleCapDetails from './pages/ShipperCapsDetails/BleCapDetails';
import MyStudies from './pages/studies/MyStudies';
import Study from './pages/Study';
import BillingDetails from './pages/SuperUser/CareBilling/BillingDetails';
import OrgDeviceDetails from './pages/SuperUser/DeviceDetails';
import Devices from './pages/SuperUser/DeviceManagement/Devices';
import HubDeviceEvents from './pages/SuperUser/DeviceManagement/HubDeviceEvents';
import HubDevices from './pages/SuperUser/DeviceManagement/HubDevices';
import HubDevicesGraph from './pages/SuperUser/DeviceManagement/HubDevicesGraph';
import ManageOrganization from './pages/SuperUser/ManageOrganization/ManageOrganization';
import Messages from './pages/SuperUser/Messages';
import MessageDetails from './pages/SuperUser/Messages/MessageDetails';
import Organization from './pages/SuperUser/Organization/Organization';
import Patients from './pages/SuperUser/Patients';
import PatientCapDetails from './pages/SuperUser/Patients/PatientCapDetails';
import PatientDetails from './pages/SuperUser/Patients/PatientDetails';
import Provisionings from './pages/SuperUser/Provisioning/Provisionings';
import Report from './pages/SuperUser/Report/Report';
import Schedules from './pages/SuperUser/Schedules';
import ScheduleDetails from './pages/SuperUser/Schedules/ScheduleDetails';
import Support from './pages/support/support';
import TakeSimulationLink from './pages/TakeSimulationLink';
import Strings from './Strings';
import DashboardRPM from './pages/Dashboards/DashboardRPM';
import DeviceUnderConstruction from './pages/SuperUser/DeviceManagement/DeviceUnderConstruction';
import Billings from './pages/SuperUser/CareBilling/Billings';
import KitDevices from './pages/SuperUser/DeviceManagement/KitDevices';
import AlertsTabs, { ALERTS_TAB, CONFIGURATIONS_TAB } from './pages/Alerts/AlertsTabs';
import Cohort from './pages/SuperUser/Patients/Cohorts/Cohort';
import ShipperCaps, { root as DevicesRoot } from './pages/ShipperCaps';
import PatientMedications from './pages/SuperUser/Patients/PatientMedications/PatientMedications';
import RpmPatientDashboard from './pages/SuperUser/Patients/Dashboard/PatientDashboard';
import PatientDevices from './pages/SuperUser/Patients/PatientDevices';
import ReadingDetails from './pages/SuperUser/Patients/Dashboard/ReadingDetails';
import UserList from './pages/Users/UserList';
import { CAP_MANAGER, DOCTOR } from './utils/userRoles';
import SystemConfiguration, {
  SystemConfigurationTabs,
} from './pages/SuperUser/SystemConfiguration/SystemConfiguration';
import DeviceDetails, {
  DEVICE_READINGS_TAB,
  DEVICE_STATUS_HISTORY_TAB,
} from './pages/SuperUser/DeviceManagement/DeviceDetails';
import CapDetails, {
  CAP_EVENTS_TAB,
  CAP_STATUS_HISTORY_TAB,
  HUB_DEVICES_TAB,
} from './pages/SuperUser/DeviceManagement/CapDetails';
import NewPassword from './pages/NewPassword/NewPassword';
import KitDetails, { KIT_DEVICES_TAB, KIT_STATUS_HISTORY_TAB } from './pages/SuperUser/DeviceManagement/KitDetails';

const StudyRoutes = [
  <Route key={1} exact path="/study/:id/overview" render={props => <Study activeTab="Overview" {...props} />} />,
  <Route key={1} path="/study/:id/patients" render={props => <Study activeTab="Patients" {...props} />} />,
  <Route
    key={1}
    exact
    path="/study/:trialId/patient/:id/overview"
    render={props => <Patient activeTab="Medications" {...props} />}
  />,
  <Route
    key={1}
    path="/study/:trialId/patient/:id/overview/history"
    render={props => <Patient activeTab="History" {...props} />}
  />,
  <Route
    key={1}
    path="/study/:trialId/patient/:id/overview/smart-cap"
    render={props => <Patient activeTab="Cap data" {...props} />}
  />,
  <Route
    key={1}
    path="/study/:trialId/patient/:id/overview/timestamp"
    render={props => <Patient activeTab="Timestamp" {...props} />}
  />,
  <Route
    key={1}
    path="/study/:trialId/patient/:id/overview/timedelta"
    render={props => <Patient activeTab="Time Delta" {...props} />}
  />,
  <Route key={1} path="/study/:id/overview/adherence" render={props => <Study activeTab="Adherence" {...props} />} />,
  <Route key={1} path="/study/:id/overview/info" render={props => <Study activeTab="Info" {...props} />} />,
  <Route
    key={1}
    path="/study/:id/overview/manage-patients"
    render={props => <Study activeTab="Manage Patients" {...props} />}
  />,
  <Route key={1} path="/study/:id/overview/timestamp" render={props => <Study activeTab="Timestamp" {...props} />} />,
  <Route
    key={1}
    path="/study/:id/overview/medications-map"
    render={props => <Study activeTab="Medications Map" {...props} />}
  />,
];

const PatientRoutes = [
  <Route key={1} exact path="/patient/:id/overview" render={props => <Patient activeTab="Medications" {...props} />} />,
  <Route key={1} path="/patient/:id/overview/history" render={props => <Patient activeTab="History" {...props} />} />,
  <Route
    key={1}
    path="/patient/:id/overview/smart-cap"
    render={props => <Patient activeTab="Cap data" {...props} />}
  />,
  <Route
    key={1}
    path="/patient/:id/overview/timestamp"
    render={props => <Patient activeTab="Timestamp" {...props} />}
  />,
  <Route
    key={1}
    path="/patient/:id/overview/timedelta"
    render={props => <Patient activeTab="Time Delta" {...props} />}
  />,
  <Route key={1} path="/patient/:id/overview/info" render={props => <Patient activeTab="Info" {...props} />} />,
  <Route key={1} path="/patient/:id/medications" exact component={MedicationsView} />,
  <Route key={1} path="/patient/:id/medications/:medicationId/details" exact component={MedicationDetailsView} />,
];

const PatientDetailsRoutes = [
  <Route key={1} exact path="/cap-patients/:id" render={props => <PatientDetails {...props} />} />,
  <Route
    key={1}
    exact
    path="/cap-patients/:id/info"
    render={props => <PatientDetails activeTab="Info" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/cap-patients/:id/dashboard"
    render={props => <PatientDetails activeTab="Dashboard" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/cap-patients/:id/medications"
    render={props => <PatientDetails activeTab="Medications" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/cap-patients/:id/readings"
    render={props => <PatientDetails activeTab="Readings" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/cap-patients/:id/history"
    render={props => <PatientDetails activeTab="Task History" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/cap-patients/:id/devices"
    render={props => <PatientDetails activeTab="Devices" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/cap-patients/:id/devices/:type/:deviceId/:manufacturer/:model/:deviceType/details"
    render={props => <PatientDetails activeTab="Devices" type="3rdParty" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/cap-patients/:id/devices/:deviceType/:deviceId"
    render={props => <PatientDetails activeTab="Devices" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/cap-patients/:id/devices-history"
    render={props => <PatientDetails activeTab="Devices History" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/cap-patients/:id/devices-history/:type/:deviceId/:manufacturer/:model/:deviceType/details"
    render={props => <PatientDetails activeTab="Devices History" type="3rdParty" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/cap-patients/:id/devices-history/:type/:deviceId"
    render={props => <PatientDetails activeTab="Devices History" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/cap-patients/:id/messages"
    render={props => <PatientDetails activeTab="Messages" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/cap-patients/:id/documents"
    render={props => <PatientDetails activeTab={Strings.documents} {...props} />}
  />,
  <Route key={1} exact path="/rpm-patient/info" render={props => <PatientDetails activeTab="Info" {...props} />} />,
  <Route
    key={1}
    exact
    path="/rpm-patient/dashboard"
    render={props => <PatientDetails activeTab="Dashboard" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/rpm-patient/medications"
    render={props => <PatientDetails activeTab="Medications" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/rpm-patient/readings"
    render={props => <PatientDetails activeTab="Readings" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/rpm-patient/devices"
    render={props => <PatientDetails activeTab="Devices" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/rpm-patient/devices/:type/:capId/:deviceId/:manufacturer/:model/:deviceType/details"
    render={props => <PatientDetails activeTab="Devices" type="3rdParty" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/rpm-patient/devices/:type/:deviceId"
    render={props => <PatientDetails activeTab="Devices" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/rpm-patient/messages"
    render={props => <PatientDetails activeTab="Messages" {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/rpm-patient/documents"
    render={props => <PatientDetails activeTab="Documents" {...props} />}
  />,
];

const DevicesRoutes = [
  <Route
    key={1}
    exact
    path="/devices/kits/:id/devices"
    render={props => <KitDetails activeTab={KIT_DEVICES_TAB} {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/devices/kits/:id/history"
    render={props => <KitDetails activeTab={KIT_STATUS_HISTORY_TAB} {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/devices/:type/:id/devices"
    render={props => <CapDetails {...props} activeTab={HUB_DEVICES_TAB} />}
  />,
  <Route
    key={1}
    exact
    path="/devices/:type/:id/events"
    render={props => <CapDetails {...props} activeTab={CAP_EVENTS_TAB} />}
  />,
  <Route
    key={1}
    exact
    path="/devices/:type/:id/history"
    render={props => <CapDetails {...props} activeTab={CAP_STATUS_HISTORY_TAB} />}
  />,
  <Route key={1} exact path="/devices/:type/:id" component={ShipperCapsDetails} />,
  <Route
    key={1}
    exact
    path="/devices/:deviceType/:id/devices/:deviceId/:manufacturer/:model/events"
    render={props => <DeviceDetails activeTab={DEVICE_READINGS_TAB} {...props} />}
  />,
  <Route
    key={1}
    exact
    path="/devices/:deviceType/:id/devices/:deviceId/:manufacturer/:model/history"
    render={props => <DeviceDetails activeTab={DEVICE_STATUS_HISTORY_TAB} {...props} />}
  />,
];

const OrgDevicesRoutes = [
  <Route key={1} exact path="/org-devices/ble" render={props => <Devices activeTab="Bluetooth" {...props} />} />,
  <Route key={1} exact path="/org-devices/hub" render={props => <Devices activeTab="Hub" {...props} />} />,
  <Route key={1} exact path="/org-devices/lte" render={props => <Devices activeTab="LTE" {...props} />} />,
  <Route key={1} exact path="/org-devices/:type/:id" component={OrgDeviceDetails} />,
  <Route key={1} exact path="/org-devices/:type/:id/devices" component={HubDevices} />,
  <Route key={1} exact path="/org-devices/:type/:hubId/devices/:deviceId/graph" component={HubDevicesGraph} />,
  <Route
    key={1}
    exact
    path="/org-devices/:type/:id/devices/:deviceId/:manufacturer/:model/events/:deviceType/hubdevice"
    component={HubDeviceEvents}
  />,
  <Route key={1} exact path="/org-devices/:type/:id/devices/:deviceId/events/ble" component={BleCapDetails} />,
];

export default ({ history, store }) => {
  return (
    <Router history={history}>
      <Suspense fallback={<div />}>
        <Switch>
          <Route key={1} path="/login" component={Login} />
          <Route key={1} path="/login-esi-patient" component={ESIPatientLogin} />
          <Route key={1} path="/app-download" component={AppRedirection} />
          <Route key={1} path="/take-simulation" component={TakeSimulationLink} />
          <Route key={1} path="/caregiver" component={AddCareGiverUsingLink} />
          <Route key={1} path="/cgsuccess" component={CareGiverSuccessPage} />
          <Route key={1} path="/activate" component={UserActivatePage} />
          <Route key={1} path="/verify-mobile-phone" component={UserVerifyPhonePage} />
          <Route key={1} path="/activated" component={UserActivatedPage} />
          <Route key={1} path="/verify/:method/" component={VerificationComplete} />
          <Route key={1} path="/privacy" component={Privacy} />
          <Route key={1} path="/terms" component={Terms} />
          <Route key={1} path="/invite-doctor" component={esiInviteDoctor} />
          <Route key={1} path="/esi-patient-terms" component={EsiPatientTerms} />
          <Route key={1} path="/esi-patient-privacy" component={EsiPatientPrivacy} />
          <Route key={1} path="/esi-patient-license" component={EsiPatientLicense} />
          <Route key={1} path="/group/invitation/confirm" component={GroupInvitationConfirmPage} />
          <Route key={1} path="/resetpassword/" component={ResetPassword} />
          <Route key={1} path="/setnewpassword" component={NewPassword} />
          <Route key={1} path="/reset" component={ResetByLink} />
          <App>
            <Suspense fallback={<LoadingRenderer loading />}>
              <Switch>
                <Route key={1} path="/dashboard-devices" component={DevicesDashboard} />
                <Route key={1} path="/dashboard" component={Dashboard} />
                <Route key={1} path="/dashboard-rpm" component={DashboardRPM} />
                <Route key={1} path="/organization" exact component={Organization} />
                <Route
                  key={1}
                  exact
                  path="/organization/:id/:name/orgnization-manager"
                  render={props => <ManageOrganization activeTab="Organization Managers" {...props} />}
                />
                <Route
                  key={1}
                  exact
                  path="/organization/:id/:name/cap-manager"
                  render={props => <ManageOrganization activeTab="Cap Manager" {...props} />}
                />
                <Route
                  key={1}
                  exact
                  path="/organization/:id/:name/admin"
                  render={props => <ManageOrganization activeTab="Admin" {...props} />}
                />
                <Route
                  key={1}
                  exact
                  path="/organization/:id/:name/devices/:type"
                  render={props => <ManageOrganization activeTab="Devices" {...props} />}
                />
                <Route key={1} exact path="/organization/:id/:name/devices/:type/:id/devices" component={HubDevices} />
                <Route
                  key={1}
                  exact
                  path="/organization/:id/devices/:type/:hubId/devices/:deviceId/graph"
                  component={HubDevicesGraph}
                />
                <Route key={1} exact path="/organization/:id/devices/:type/:id" component={OrgDeviceDetails} />
                <Route
                  key={1}
                  exact
                  path="/organization/:orgId/devices/:type/:id/devices/:deviceId/:manufacturer/:model/events/:deviceType/hubdevice"
                  component={HubDeviceEvents}
                />
                <Route
                  key={1}
                  exact
                  path="/organization/:orgId/devices/:type/:id/devices/:deviceId/events/ble"
                  component={BleCapDetails}
                />
                <Route
                  key={1}
                  exact
                  path="/organization/:id/:name/outbound-api"
                  render={props => <ManageOrganization activeTab="Outbound API" {...props} />}
                />
                <Route
                  key={1}
                  exact
                  path="/organization/:id/:name/config"
                  render={props => <ManageOrganization activeTab="Configuration" {...props} />}
                />
                <Route
                  key={1}
                  exact
                  path="/organization/:id/:name/external-api/oauth"
                  render={props => <ManageOrganization activeTab={Strings.externalApiOauthTab} {...props} />}
                />
                <Route
                  key={1}
                  exact
                  path="/organization/:id/:name/external-api/access-control"
                  render={props => <ManageOrganization activeTab={Strings.externalApiAccessControlTab} {...props} />}
                />
                <Route
                  key={1}
                  exact
                  path="/organization/:id/:name/external-api/logs"
                  render={props => <ManageOrganization activeTab={Strings.externalApiLogsTab} {...props} />}
                />
                <Route
                  key={1}
                  exact
                  path={SystemConfigurationTabs.ExternalApiLogs.path}
                  render={props => (
                    <SystemConfiguration activeTab={SystemConfigurationTabs.ExternalApiLogs.name} {...props} />
                  )}
                />
                <Route
                  key={1}
                  exact
                  path={SystemConfigurationTabs.ExternalApiAccessControl.path}
                  render={props => (
                    <SystemConfiguration activeTab={SystemConfigurationTabs.ExternalApiAccessControl.name} {...props} />
                  )}
                />
                <Route key={1} path="/user-dashboard" component={PatientDashboard} />
                <Route key={1} path="/studies" component={MyStudies} />
                <Route key={1} path="/patients" component={MyPatients} />
                <Route key={1} path="/admins" render={props => <UserList role={DOCTOR} {...props} />} />
                <Route key={1} path="/cap-managers" render={props => <UserList role={CAP_MANAGER} {...props} />} />
                <Route key={1} path="/calendar" component={Calendar} />
                <Route key={1} path="/esi-patient-settings" component={EsiPatientSettingsPage} />

                <Route key={1} exact path={`/${DevicesRoot}/:type`} render={props => <ShipperCaps {...props} />} />

                {StudyRoutes}
                {PatientRoutes}

                <Route
                  key={1}
                  path="/esi_patients/:id/:tab"
                  exact
                  render={props => <ESIPatientInfoPage {...props} />}
                />

                {DevicesRoutes}
                {OrgDevicesRoutes}

                <Route
                  key={1}
                  path="/settings"
                  exact
                  render={props => <PatientSettingsPage activeTab="Details" {...props} />}
                />

                <Route key={1} path="/user-settings" exact render={() => <SettingsPage />} />
                <Route
                  key={1}
                  path="/user-settings/workflows"
                  exact
                  render={props => <SettingsPage activeTab="Workflows" {...props} />}
                />
                <Route
                  key={1}
                  path="/user-settings/lab"
                  exact
                  render={props => <SettingsPage activeTab="Lab" {...props} />}
                />
                {store.getState().auth.is_doctor ? null : (
                  <Route
                    key={1}
                    path="/settings/notifications"
                    render={props => <PatientSettingsPage activeTab="Notifications" {...props} />}
                  />
                )}
                <Route key={1} path="/history" render={props => <HistoryTab {...props} />} />
                <Route key={1} path="/medications" render={props => <Medications {...props} />} />
                <Route key={1} exact path="/alerts/current" render={() => <AlertsTabs activeTab={ALERTS_TAB} />} />
                <Route
                  key={1}
                  exact
                  path="/alerts/configuration"
                  render={() => <AlertsTabs activeTab={CONFIGURATIONS_TAB} />}
                />

                <Route key={1} exact path="/device-under-construction" component={DeviceUnderConstruction} />
                <Route key={1} exact path="/schedules" component={Schedules} />
                <Route key={1} exact path="/schedules/:id" component={ScheduleDetails} />
                <Route key={1} exact path="/schedules/:maskedId/:id/:type" component={ShipperCapsDetails} />
                <Route
                  key={1}
                  exact
                  path="/support/contact-us"
                  render={props => <ESISupport activeTab="Contact Us" {...props} />}
                />

                <Route
                  key={1}
                  exact
                  path="/support/videos"
                  render={props => <ESISupport activeTab="Videos" {...props} />}
                />
                <Route
                  key={1}
                  exact
                  path="/support/eula"
                  render={props => <ESISupport activeTab={Strings.endUserLicenseAgreement} {...props} />}
                />
                <Route
                  key={1}
                  exact
                  path="/support/terms"
                  render={props => <ESISupport activeTab={Strings.termsAndConditions} {...props} />}
                />

                <Route
                  key={1}
                  exact
                  path="/support/documents"
                  render={props => <ESISupport activeTab="Documents" {...props} />}
                />
                <Route key={1} exact path="/support" component={ESISupport} />
                <Route key={1} exact path="/report" render={props => <Report {...props} />} />
                <Route key={1} exact path="/billings" component={Billings} />
                <Route key={1} exact path="/billings/:id" component={BillingDetails} />
                <Route
                  key={1}
                  exact
                  path="/cap-patients/list"
                  render={props => <Patients activeTab="Patients" {...props} />}
                />
                <Route
                  key={1}
                  exact
                  path="/cap-patients/readings"
                  render={props => <Patients activeTab="Monitoring" {...props} />}
                />
                <Route
                  key={1}
                  exact
                  path="/cap-patients/cohorts"
                  render={props => <Patients activeTab="Cohorts" {...props} />}
                />
                <Route key={1} exact path="/cap-patients/cohorts/:id" component={Cohort} />
                <Route
                  key={1}
                  exact
                  path="/cap-patients/patient-list-files"
                  render={props => <Patients activeTab={Strings.patientListFiles} {...props} />}
                />

                <Route
                  key={1}
                  exact
                  path="/cap-patients/:id/devices/:capId/:type/details"
                  component={PatientCapDetails}
                />
                {PatientDetailsRoutes}
                <Route key={1} path="/esi_patients/:tab" render={props => <ESIPatients {...props} />} />
                <Route
                  key={1}
                  exact
                  path="/messages/inbox"
                  render={props => <Messages activeTab="Inbox" {...props} />}
                />
                <Route
                  key={1}
                  exact
                  path="/messages/archived"
                  render={props => <Messages activeTab="Archived" {...props} />}
                />
                <Route key={1} exact path="/messages/new" render={props => <Messages activeTab="New" {...props} />} />
                <Route
                  key={1}
                  exact
                  path="/messages/prefabs"
                  render={props => <Messages activeTab="Prefab Responses" {...props} />}
                />
                <Route key={1} exact path="/messages/:type/:userId" component={MessageDetails} />
                <Route key={1} exact path="/provisioning/:tab" render={props => <Provisionings {...props} />} />

                <Route
                  key={1}
                  exact
                  path="/health-center"
                  render={props => <RpmPatientDashboard patientVersion {...props} />}
                />
                <Route
                  key={1}
                  exact
                  path="/health-center/details"
                  render={props => <ReadingDetails patientVersion {...props} />}
                />
                <Route
                  key={1}
                  path="/patient-medications"
                  render={props => <PatientMedications patientVersion {...props} />}
                />
                <Route key={1} path="/patient-devices" render={props => <PatientDevices patientVersion {...props} />} />
              </Switch>
            </Suspense>
          </App>
        </Switch>
      </Suspense>
    </Router>
  );
};
