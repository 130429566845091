import React from 'react';
import PropTypes from 'prop-types';
import { ComposedChart, ResponsiveContainer } from 'recharts';

import { drawXAxis, drawYAxis } from './Axes';
import { drawWeekRefLines } from './Components';
import {
  X_AXIS_CHART_HEIGHT,
  X_AXIS_CHART_HEIGHT_TINY,
  X_AXIS_HEIGHT,
  X_AXIS_HEIGHT_TINY,
  Y_AXIS_WIDTH_TINY,
} from './Constants';

function CompositeGraphXAxis(props) {
  const rangeTexts = [];
  //console.log(props.dateRange);
  if (!props.useTinyAxes && props.dateRange && props.dateRange.start && props.dateRange.end) {
    rangeTexts.push(props.dateRange.start.format('MMM DD') + ' -');
    rangeTexts.push(
      props.dateRange.end
        .clone()
        .subtract(1, 'day')
        .format('MMM DD'),
    );
  }

  const forceYAxisWidth = props.useTinyAxes ? Y_AXIS_WIDTH_TINY : 0;

  return (
    <ResponsiveContainer width="100%" height={props.useTinyAxes ? X_AXIS_CHART_HEIGHT_TINY : X_AXIS_CHART_HEIGHT}>
      <ComposedChart
        defaultShowTooltip={false}
        margin={{
          top: 2,
          right: 0,
          bottom: 1,
          left: 0,
        }}
      >
        {drawXAxis(
          props.xAxisParams,
          props.useTinyAxes ? X_AXIS_HEIGHT_TINY : X_AXIS_HEIGHT,
          'top',
          rangeTexts,
          true,
          props.useTinyAxes,
        )}
        {drawYAxis({ axisId: 'yAxis1', yMin: 0, yMax: 0, tick: 0, hasData: false, yTicks: [] }, false, forceYAxisWidth)}
        {(props.withRightAxis || props.useTinyAxes) &&
          drawYAxis(
            { axisId: 'yAxis2', yMin: 0, yMax: 0, tick: 0, hasData: false, yTicks: [] },
            false,
            forceYAxisWidth,
          )}
        {!props.useTinyAxes && drawWeekRefLines(props.xAxisParams, props.dateRange, 'yAxis1')}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default CompositeGraphXAxis;

CompositeGraphXAxis.propTypes = {
  dateRange: PropTypes.object,
  maxRange: PropTypes.object,
  xAxisParams: PropTypes.object,
  withRightAxis: PropTypes.bool,
  useTinyAxes: PropTypes.bool,
};
