import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { PageHeader } from '../../../components/PageHeader';
import { TextInput } from '../../../components/PageHeader/TextInput';
import { PAGE_LIMIT } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { getCapChargeText, signalRender, convertUnixEpochToHumanReadable, ucFirst, cityDisplay } from '../../../utils';
import { Button } from '../../../components/PageHeader/Button';
import { actions } from '../../ShipperCaps/redux/actions';
import { SHIPPER_CAPS_RESULT } from '../../ShipperCaps/redux/constants';

class LTEManage extends PureComponent {
  pageRequest = {
    limit: PAGE_LIMIT,
    offset: 0,
    search: '',
    deviceType: 'lte',
  };
  refreshEnable = true;
  state = {
    controller: undefined,
    caps: [],
    pagination: undefined,
    isLoading: false,
  };

  componentDidMount() {
    this.pageRequest.organizationId = decodeURIComponent(this.props.organizationId);
    this.loadCaps();
  }

  loadCaps() {
    const controller = new AbortController();
    this.setState({ controller, isLoading: true });
    this.props.loadCaps(this.pageRequest, controller.signal).then(resp => {
      if (resp?.type === SHIPPER_CAPS_RESULT) {
        this.setState({
          caps: resp.response?.data,
          pagination: resp.response?.pagination,
        });
      }
      this.setState({ isLoading: false });
    });
  }

  componentWillUnmount() {
    const { controller } = this.state;
    controller && this.props.cancelGetCaps(controller);
  }

  onSearchQueryChange(query) {
    this.pageRequest.offset = 0;
    this.pageRequest.search = query;
    this.loadCaps();
  }

  onRowSelected = id => {
    this.props.onNavigate(`/organization/${this.props.organizationId}/devices/lte/${this.state.caps[id].cap_id}`);
  };

  onDateRangeChanged = () => {
    this.pageRequest.offset = this.state.pagination.offset;
    this.loadCaps();
  };

  onPrevClick = () => {
    const { pagination } = this.state;
    this.pageRequest.offset = pagination.offset - PAGE_LIMIT;
    this.loadCaps();
  };

  onNextClick = () => {
    const { pagination } = this.state;
    this.pageRequest.offset = pagination.offset + PAGE_LIMIT;
    this.loadCaps();
  };

  onCustomPage = page => {
    this.pageRequest.offset = (page - 1) * PAGE_LIMIT;
    this.loadCaps();
  };

  onChange = event => {
    this.setState({ [event.target.getAttribute('name')]: event.target.value });
  };

  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 10000);
    this.loadCaps();
  };

  getHeaderComponents() {
    return (
      <React.Fragment>
        <TextInput class="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        <Button class="refresh" disabled={!this.refreshEnable} onClick={this.onRefresh} />
      </React.Fragment>
    );
  }

  render() {
    const { caps, isLoading, pagination } = this.state;
    const columns = [];

    columns.push(<Column key="capid" title={Strings.deviceId} value={e => e.cap_id} />);
    columns.push(<Column key="cap_name" title={Strings.deviceName} value={e => e.cap_name || '-'} />);
    columns.push(<Column key="imei" title={Strings.imei} value={e => e.imei || '-'} />);

    columns.push(<Column key="kit_id" title={Strings.kitId} value={e => e.kit_id || '-'} />);

    columns.push(
      <Column
        key="last_updated_at"
        title={Strings.lastActivity}
        value={e => (e.last_updated_at ? convertUnixEpochToHumanReadable(e.last_updated_at) : '-')}
        sortKey="last_updated_at"
      />,
    );
    columns.push(
      <Column
        key="eventTime"
        title={Strings.lastEventTime}
        value={e => (e.last_vital_at ? convertUnixEpochToHumanReadable(e.last_vital_at) : '-')}
      />,
    );
    columns.push(
      <Column key="batterylevel" title={Strings.battery.battery} value={d => getCapChargeText(d.battery)} />,
    );
    columns.push(<Column key="fota" title={Strings.fota} value={e => e.firmware91 || '-'} />);
    columns.push(
      <Column key="signalstrength" title={Strings.signalStrength} value={e => signalRender(e.signal_strength)} />,
    );
    columns.push(
      <Column key="location" title={Strings.location} value={e => (e.location ? cityDisplay(e.location) : '-')} />,
    );
    columns.push(<Column key="attached" title={Strings.patient} value={e => (e.attached ? 'Yes' : 'No')} />);
    columns.push(<Column key="status" title={Strings.status} value={e => (e.status ? ucFirst(e.status) : '-')} />);

    const headerTitle =
      !isLoading && caps && pagination
        ? Strings.formatString(
            Strings.showingXOf,
            caps.length,
            pagination.totalRecords ? pagination.totalRecords : 0,
            Strings.devices,
          )
        : Strings.showingWait;

    return (
      <React.Fragment>
        <PageHeader left={headerTitle} right={this.getHeaderComponents()} />
        <Table
          name="cap-list"
          data={caps}
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          onRowSelection={this.onRowSelected}
          onCustomPage={this.onCustomPage}
          pagination={pagination}
          isLoading={isLoading}
        >
          {columns}
        </Table>
      </React.Fragment>
    );
  }
}

LTEManage.propTypes = {
  loadCaps: PropTypes.func,
  onNavigate: PropTypes.func,
  organizationId: PropTypes.any,
  cancelGetCaps: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    role: state.auth.role,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  loadCaps: (pageRequest, signal) => dispatch(actions.getCaps(pageRequest, signal)),
  cancelGetCaps: controller => dispatch(actions.cancelGetCaps(controller)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LTEManage);
