import {
  WIDGET_GET_PATIENTS_WITH_NO_READINGS,
  WIDGET_GET_PATIENTS_WITH_NO_READINGS_RESULT,
  WIDGET_GET_PATIENTS_WITH_NO_READINGS_ERROR,
} from './constants';

export const widgetPatientsWithNoReadingsReducer = (state = {}, action) => {
  switch (action.type) {
    case WIDGET_GET_PATIENTS_WITH_NO_READINGS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        patientsWithNoReadings: undefined,
      };
    case WIDGET_GET_PATIENTS_WITH_NO_READINGS_RESULT:
      return {
        ...state,
        isLoading: false,
        isError: false,
        patientsWithNoReadings: action.response.pagination.totalRecords,
      };

    case WIDGET_GET_PATIENTS_WITH_NO_READINGS_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        patientsWithNoReadings: undefined,
      };
    }
    default:
      return state;
  }
};
