import { GET_SUPPORT_CONFIG, GET_SUPPORT_CONFIG_ERROR, GET_SUPPORT_CONFIG_RESULT } from './constants';

export const supportReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUPPORT_CONFIG:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SUPPORT_CONFIG_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.response.data,
      };
    case GET_SUPPORT_CONFIG_RESULT:
      return {
        ...state,
        isLoading: false,
        data: action.response,
      };
    default:
      return state;
  }
};
