export function makeAboveZero(errorMsg) {
  return value => {
    if (value) {
      if (+value <= 0) {
        return errorMsg;
      }
    }
    return undefined;
  };
}
