import React from 'react';

import { AutosuggestWithLabelInput } from '../components/AutosuggestInput/AutosuggestInput';
import { getFieldWithComponent } from './getFieldWithComponent';
import { ComponentWithTooltip } from '../utils';

function AutosuggestWithLabelField(props) {
  const { input, meta, error, ...rest } = props;

  return <ComponentWithTooltip componentType={AutosuggestWithLabelInput} {...rest} error={error} inputProps={input} />;
}

const Component = getFieldWithComponent(AutosuggestWithLabelField);

export { Component as AutosuggestWithLabelField };
