import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import styles from './Errors.module.scss';

export class Errors extends PureComponent {
  render() {
    const { errors } = this.props;
    if (!errors || errors.length === 0) return null;

    return (
      <div style={this.props.NoMT ? { marginTop: 0 } : { marginBottom: 0 }} className={styles.Errors}>
        {errors.map(function(e) {
          if (typeof e === 'string') {
            return <div className={styles.title}>{e}</div>;
          }
          return (
            <React.Fragment>
              <div className={styles.title}>
                {e.title}
                {e.text && ':'}
              </div>
              {e.text}
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

Errors.propTypes = {
  NoMT: PropTypes.any,
  errors: PropTypes.array,
};
