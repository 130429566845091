import React from 'react';

export const graphContainer = (graph, title) => (
  <div className="boxStatsWithGraph">
    <div className="boxStatsWithGraph-title">{title}</div>
    <div className="boxStatsWithGraph-graph noStats">{graph}</div>
  </div>
);

export const graphContainerWithLegend = (graph, title, series) => (
  <div className="boxStatsWithGraph">
    <div className="boxStatsWithGraph-title">
      <div>{title}</div>
      {GraphLegend(series)}
    </div>

    <div className="boxStatsWithGraph-graph noStats">{graph}</div>
  </div>
);

export const GraphLegend = series => {
  return (
    <div className="legendContainer">
      {series?.length > 0 &&
        series.map(s => (
          <React.Fragment key={s.name}>
            <div className="dot" style={{ backgroundColor: s.color }}></div>
            <div className="label">{s.name}</div>
          </React.Fragment>
        ))}
    </div>
  );
};
