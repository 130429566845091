import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';

import { QueryString, getASCIIValueOfChar, getFormattedPhoneNumber } from '../../utils';
import { userMobilePhoneAction } from '../../actions/user-settings';
import { CurrentLandingLayout } from '../../layouts';
import MobileInput from '../../components/MobileInput';
import Strings from '../../Strings';
import { MOBILE_PHONE_VERIFY_RESULT } from '../../actions/action-types';

class UserVerifyPhonePage extends PureComponent {
  static propTypes = {
    view: PropTypes.oneOf(['phone', 'code']),
    userMobilePhoneVerify: PropTypes.object,
    onChange: PropTypes.func,
    onVerify: PropTypes.func,
    onChangeMobileNumber: PropTypes.func,
    onChangeCountry: PropTypes.func,
    onVerifyCode: PropTypes.func,
    onResendCode: PropTypes.func,
    onChangeCode: PropTypes.func,
    authenticated: PropTypes.bool,
    onNotAuthenticated: PropTypes.func,
    location: PropTypes.object,
    onSkip: PropTypes.func,
    state: PropTypes.func,
  };

  static defaultProps = { view: 'phone' };

  static codeDigits = 6;

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onVerify = this.onVerify.bind(this);
    this.onChangeMobileNumber = this.onChangeMobileNumber.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onVerifyCode = this.onVerifyCode.bind(this);
    this.onResendCode = this.onResendCode.bind(this);
    this.onChangeCode = this.onChangeCode.bind(this);

    this.state = {
      mobile_code: '',
      mobile_number: '',
      verification_code: [],
    };

    for (let i = 0; i < UserVerifyPhonePage.codeDigits; i += 1) {
      this.state.verification_code[i] = '';
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.authenticated) {
      this.props.onNotAuthenticated();
    }
  }

  UNSAFE_componentWillUpdate(newProps) {
    if (!newProps.authenticated) {
      this.props.onNotAuthenticated();
    }
  }

  onChange(event) {
    if (event) {
      const name = event.target.getAttribute('name');
      const {
        target: { value },
      } = event;

      this.setState({
        [name]: value,
        mobile_code: '+1',
      });
    }
  }

  onChangeMobileNumber(event) {
    if (event) {
      this.setState({ mobile_number: event });
    }
  }

  onChangeCode(index, event) {
    const { verification_code } = this.state;
    const {
      target: { value },
    } = event;
    if (value > 9) {
      verification_code[index] = value % 10;
    } else {
      verification_code[index] = parseInt(value, 10).toString();
    }
    this.setState({ verification_code });
    this.forceUpdate();
  }

  onChangeCountry(value) {
    this.setState({ mobile_code: value });
  }

  onVerify() {
    const { mobile_code, mobile_number } = this.state;

    const data = {
      flavor: 'sms',
      function: 'new',
      field: `${mobile_code}${mobile_number}`,
    };

    this.props.onVerify(data);
  }

  onVerifyCode() {
    const { verification_code } = this.state;
    const combinedCode = verification_code.filter(d => d || d === 0).join('');
    const data = {
      flavor: 'sms',
      function: 'verify',
      field: combinedCode,
      origin: 'site',
    };
    if (combinedCode.length === UserVerifyPhonePage.codeDigits) {
      this.props.onVerify(data);
    }
  }

  onResendCode() {
    const { mobile_code, mobile_number } = this.state;

    const data = {
      flavor: 'sms',
      function: 'resend',
      field: `${mobile_code}${mobile_number}`,
    };

    this.props.onVerify(data);
  }

  renderPhone() {
    const { userMobilePhoneVerify } = this.props;
    const queryParams = new QueryString(this.props.location.search);

    const verifyErrors = [];

    if (userMobilePhoneVerify.error) {
      verifyErrors.push('Please enter valid mobile number.');
    }

    return queryParams.params.origin !== 'fhir' ? (
      <UserVerifyPhoneForm
        state={this.state}
        errors={verifyErrors}
        onVerify={this.onVerify}
        onChange={this.onChangeMobileNumber}
        onSkip={this.props.onSkip}
        onChangeCountry={this.onChangeCountry}
      />
    ) : (
      <div>
        <h1>{Strings.loadingFHIRSession}</h1>
      </div>
    );
  }

  renderCode() {
    const { userMobilePhoneVerify } = this.props;

    const queryParams = new QueryString(this.props.location.search);

    const verifyErrors = [];

    if (userMobilePhoneVerify.codeError) {
      verifyErrors.push(Strings.errors.invalidActivationCode);
    }

    return queryParams.params.origin !== 'fhir' ? (
      <UserVerifyCodeForm
        state={this.state}
        errors={verifyErrors}
        onVerifyCode={this.onVerifyCode}
        onResendCode={this.onResendCode}
        onChangeCode={this.onChangeCode}
      />
    ) : (
      <div>
        <h1>{Strings.loadingFHIRSession}</h1>
      </div>
    );
  }

  render() {
    const { view } = this.props;

    switch (view) {
      case 'code':
        return this.renderCode();
      case 'phone':
      default:
        return this.renderPhone();
    }
  }
}

const UserVerifyPhoneForm = ({ state, errors, onVerify, onSkip, onChange }) => {
  const filledCorrectly = state.mobile_number !== '' && state.mobile_code !== '';

  return (
    <form className="login-page user-mobile-phone-verify" onSubmit={onVerify}>
      <div className="">
        <p>{Strings.enterPhoneNumberThatCanBeUsedToVerifyYourIdentity}</p>

        <div className="input-wrapper">
          <MobileInput
            isContainerClass
            country="US"
            placeHolder={Strings.placeholder.phoneNumber}
            value={state.mobile_number}
            onChange={onChange}
          />
        </div>

        {errors.length ? (
          <div key="error" className="error">
            {errors.join(' ')}
          </div>
        ) : null}
      </div>

      <div className="buttons row">
        <input
          className={`brand-blue full-width ${filledCorrectly ? '' : 'inactive'}`}
          type="button"
          role="button"
          id="create-account-button"
          value="Next"
          onClick={onVerify}
        />
      </div>
      <div className="buttons row">
        <span className="left-text">{Strings.noSMSReminderText}</span>
        <input
          className={`white ${filledCorrectly ? '' : 'inactive'}`}
          type="button"
          role="button"
          id="create-account-button"
          value="Skip"
          onClick={onSkip}
        />
      </div>
    </form>
  );
};

UserVerifyPhoneForm.propTypes = {
  state: PropTypes.any,
  errors: PropTypes.array,
  onVerify: PropTypes.func,
  onSkip: PropTypes.func,
  onChange: PropTypes.func,
};

class UserVerifyCodeForm extends PureComponent {
  static propTypes = {
    onChangeCode: PropTypes.func,
    isValidNumber: PropTypes.func,
    onVerifyCode: PropTypes.func,
    onResendCode: PropTypes.func,
    errors: PropTypes.array,
    state: PropTypes.object,
  };

  // eslint-disable-line react/no-multi-comp
  constructor(props) {
    super(props);

    this.state = this.props.state;

    this.onChangeCode = this.onChangeCode.bind(this);
    this.isValidNumber = this.isValidNumber.bind(this);
  }

  onChangeCode(index, event) {
    const { onChangeCode } = this.props;
    const {
      target,
      target: { value },
    } = event;

    const targetElement = this.refs[target.name]; // eslint-disable-line react/no-string-refs

    if (value.length) {
      if (targetElement.nextSibling) {
        targetElement.nextSibling.focus();
      } else {
        targetElement.blur();
      }
    }

    return onChangeCode(index, event);
  }

  onNumberKeyPress(evt) {
    let evtTemp = evt;
    evtTemp = evt || window.event;
    const charCode = evtTemp.which ? evtTemp.which : evtTemp.keyCode;
    return this.isValidNumber(charCode) ? true : evtTemp.preventDefault();
  }

  isValidNumber = charCode => {
    const zeroASCIICode = getASCIIValueOfChar('0');
    const nineASCIICode = getASCIIValueOfChar('9');
    return !!(charCode >= zeroASCIICode && charCode <= nineASCIICode);
  };

  render() {
    const { mobile_number, verification_code } = this.state;
    const { errors, onVerifyCode, onResendCode } = this.props;
    const codeInputs = [];

    for (let i = 0; i < UserVerifyPhonePage.codeDigits; i += 1) {
      codeInputs.push(
        <input
          key={i}
          className="text verification-code"
          onChange={e => this.onChangeCode(i, e)}
          type="number"
          min={0}
          max={9}
          name={`verification_code_${i}`}
          ref={`verification_code_${i}`}
          value={verification_code[i]}
          onKeyPress={e => this.onNumberKeyPress(e)}
        />,
      );
    }

    const filledCorrectly = verification_code.filter(d => d || d === 0).length >= UserVerifyPhonePage.codeDigits;

    return (
      <form className="login-page user-mobile-phone-verify code-form" onSubmit={onVerifyCode}>
        <div className="fixed-height">
          <p>
            {Strings.weHaveSentCodeText}
            <br />
            <span className="red">{getFormattedPhoneNumber(mobile_number)}</span>
            {Strings.enterTheCodeText}{' '}
            <span className="blue">
              {Strings.didnText}
              {"'"}
              {Strings.tReceiveACode}
            </span>
          </p>

          <div className="input-wrapper">
            <label className="code-label">{Strings.digitVerificationCode}</label>

            <div className="verification-code-wrapper">{codeInputs}</div>
          </div>

          {errors.length ? (
            <div key="error" className="error">
              {errors.join(' ')}
            </div>
          ) : null}
        </div>

        <div className="buttons row dotted-line">
          <input className="resend" type="button" role="button" value="Resend Code" onClick={onResendCode} />
          <input
            className={`brand-blue ${filledCorrectly ? '' : 'inactive'}`}
            type="button"
            role="button"
            value="Verify"
            onClick={onVerifyCode}
          />
        </div>
      </form>
    );
  }
}

export default connect(
  state => ({
    authenticated: state.auth.authenticated,
    userMobilePhoneVerify: state.entities.userMobilePhoneVerify,
    view: state.entities.userMobilePhoneVerify.error === false ? 'code' : 'phone',
  }),
  dispatch => ({
    onNotAuthenticated: () => {} /* dispatch(push('/login')) */,
    onSkip: () => dispatch(push('/activated')),
    onVerify: data =>
      dispatch(userMobilePhoneAction.actionVerify(data)).then(action => {
        if (data.function === 'verify' && action.type === MOBILE_PHONE_VERIFY_RESULT) {
          return dispatch(push('/activated'));
        }

        return null;
      }),
  }),
)(
  CurrentLandingLayout({
    title: Strings.verifyAccountText,
    caption: Strings.youHaveBeenInvitedText + Strings.trackOfYourMedicineText + Strings.toVerifyYourAccountText,
  })(UserVerifyPhonePage),
);
