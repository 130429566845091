import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import Strings from '../../../../Strings';
import { lastActivityDisplay, makeValid } from '../../../../utils';
import { DATE_MONTH_DAY_YEAR } from '../../../../constants';
import TableWidget from '../component/TableWidget';
import { PERMISSIONS, hasPermission } from '../../../../utils/userPermissions';

function PatientDetails(props) {
  const { patientDetails, isLoading, isCollapsible } = props;
  const data = [
    [Strings.capPatient.mrn, patientDetails?.mrn],
    [Strings.patientNameText, patientDetails?.patientName],
    [Strings.capPatient.dob, moment(patientDetails?.dateOfBirth).format(DATE_MONTH_DAY_YEAR)],
    [Strings.gender, makeValid(patientDetails?.gender)],
    [
      Strings.language,
      patientDetails?.language && Strings.languagesEnum[patientDetails?.language]
        ? Strings.languagesEnum[patientDetails?.language]
        : '',
    ],
    [Strings.capPatient.textNumber, makeValid(patientDetails?.textPhoneNo)],
    [Strings.capPatient.phoneNumber, makeValid(patientDetails?.primaryPhoneNo)],
    [Strings.capPatient.email, makeValid(patientDetails?.email)],
    [Strings.capPatient.address, makeValid(patientDetails?.homeAddress)],
  ];
  if (hasPermission(PERMISSIONS.GRANT_PATIENT_PORTAL_ACCESS)) {
    data.push([Strings.lastLogin, lastActivityDisplay(patientDetails?.last_login)]);
  }
  const canEdit = hasPermission(PERMISSIONS.PATIENTS_RPM_PATIENT_INFO_EDIT);

  return (
    <TableWidget
      title={Strings.capPatient.patientDetails}
      buttons={props.onEdit && canEdit ? [{ icon: 'edit', onClick: props.onEdit }] : []}
      data={data}
      isLoading={isLoading}
      firstColumnClassName="patient-details-col"
      isCollapsible={isCollapsible}
    />
  );
}

PatientDetails.propTypes = {
  isLoading: PropTypes.any,
  patientDetails: PropTypes.shape({
    dateOfBirth: PropTypes.any,
    email: PropTypes.any,
    homeAddress: PropTypes.any,
    mrn: PropTypes.any,
    patientName: PropTypes.any,
    primaryPhoneNo: PropTypes.any,
    textPhoneNo: PropTypes.any,
  }),
  onEdit: PropTypes.func,
  isCollapsible: PropTypes.bool,
};
PatientDetails.defaultProps = { isCollapsible: false };

PatientDetails.widgetId = 'PatientDetailsWidget';

export default PatientDetails;
