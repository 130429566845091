import React from 'react';
import PropTypes from 'prop-types';
import { WizardForm } from 'components/WizardForm/WizardForm';
import { NotVerifiedLabel } from 'components/NotVerifiedLabel/NotVerifiedLabel';
import { Button, BUTTON_TYPE_BLUE, BUTTON_TYPE_WHITE } from 'components/Buttons/Button';

import { TextInputWithLabelField } from '../../fields/TextInputWithLabelField';
import Strings from '../../Strings';
import { composeValidators } from '../../utils/validators/composeValidators';
import { makeRequired } from '../../utils/validators/isRequired';
import { MODE_ONE_COLUMN } from '../../constants';
import { PhoneInputWithLabelField } from '../../fields/PhoneInputWithLabelField';
import styles from '../forms.module.scss';
import { makeEmailValidation } from '../../utils/validators/isValidEmail';

function SendCodeButtons(props) {
  const { isChanged, isSendButtonDisabled = false, onCancelClick, onSendCodeClick } = props;
  if (!isChanged) return null;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Button buttonType={BUTTON_TYPE_WHITE} onClick={onCancelClick} className={styles.btn}>
        {Strings.cancel}
      </Button>
      &nbsp;
      <Button
        buttonType={BUTTON_TYPE_BLUE}
        onClick={onSendCodeClick}
        className={styles.btn}
        disabled={isSendButtonDisabled}
      >
        {Strings.sendVerificationCode}
      </Button>
    </div>
  );
}

SendCodeButtons.propTypes = {
  isChanged: PropTypes.any,
  isSendButtonDisabled: PropTypes.bool,
  onCancelClick: PropTypes.any,
  onSendCodeClick: PropTypes.any,
};

export class ESIPatientEditDetailsForm extends React.PureComponent {
  static propTypes = {
    initialValues: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onEmailSendCode: PropTypes.func.isRequired,
    onPhoneSendCode: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    phoneWasChanged: false,
    emailWasChanged: false,
    phoneIsEmpty: false,
    emailIsEmpty: false,
    phoneValue: null,
    emailValue: null,
  };

  phoneRef = React.createRef();
  refForm = React.createRef();

  phoneValidate = () => {
    const { initialValues = {} } = this.props;

    const phoneInput = this.phoneRef.current;
    if (!this.phoneRef || !phoneInput) {
      return undefined;
    }

    this.setState({
      phoneWasChanged: phoneInput.getNumber() !== initialValues.mobile_phone,
      phoneIsEmpty: !!phoneInput.getValidationErrorMessage(), // Can't use isValidNumber here because of mocked numbers
    });

    return phoneInput.isValidNumber() ? undefined : phoneInput.getValidationErrorMessage();
  };

  emailValidate = value => {
    const { initialValues = {} } = this.props;

    this.setState({
      emailWasChanged: value !== initialValues.email,
      emailIsEmpty: !value || !value.trim() || makeEmailValidation(false)(value) === false,
    });
    return undefined;
  };

  onPhoneChangeCancel = () => {
    this.refForm.current.resetField('mobile_phone');
    this.setState({ phoneWasChanged: false });
  };

  onEmailChangeCancel = () => {
    this.refForm.current.resetField('email');
    this.setState({ emailWasChanged: false });
  };

  callSendCodeHandler(event, handler) {
    event.preventDefault();
    handler(this.refForm.current.getValues());
  }

  onPhoneSendCode = event => {
    const { onPhoneSendCode } = this.props;
    this.callSendCodeHandler(event, onPhoneSendCode);
  };

  onEmailSendCode = event => {
    const { onEmailSendCode } = this.props;
    this.callSendCodeHandler(event, onEmailSendCode);
  };

  render() {
    const { onCancel, onSubmit, initialValues = {} } = this.props;
    const { phoneWasChanged, emailWasChanged, phoneIsEmpty, emailIsEmpty } = this.state;

    return (
      <WizardForm
        ref={this.refForm}
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={initialValues}
        titles={[Strings.editDetails]}
        submitButtonTitle={Strings.save}
        disableSubmit={phoneWasChanged || emailWasChanged}
      >
        <WizardForm.Page>
          <TextInputWithLabelField
            mode={MODE_ONE_COLUMN}
            name="first_name"
            title={Strings.firstName}
            style={{ textAlign: 'left' }}
            validate={composeValidators(makeRequired(Strings.fieldIsRequired))}
          />

          <TextInputWithLabelField
            mode={MODE_ONE_COLUMN}
            name="last_name"
            title={Strings.lastName}
            style={{ textAlign: 'left' }}
            validate={composeValidators(makeRequired(Strings.fieldIsRequired))}
          />

          {phoneWasChanged ? <NotVerifiedLabel /> : null}
          <PhoneInputWithLabelField
            mode={MODE_ONE_COLUMN}
            name="mobile_phone"
            title={Strings.phoneNumber}
            align="left"
            linkRef={this.phoneRef}
            validate={this.phoneValidate}
          />

          <SendCodeButtons
            isChanged={phoneWasChanged}
            onCancelClick={this.onPhoneChangeCancel}
            onSendCodeClick={this.onPhoneSendCode}
            isSendButtonDisabled={phoneIsEmpty}
          />

          {emailWasChanged ? <NotVerifiedLabel /> : null}

          <TextInputWithLabelField
            mode={MODE_ONE_COLUMN}
            name="email"
            title={Strings.email}
            style={{ textAlign: 'left' }}
            validate={composeValidators(this.emailValidate)}
          />

          <SendCodeButtons
            isChanged={emailWasChanged}
            onCancelClick={this.onEmailChangeCancel}
            onSendCodeClick={this.onEmailSendCode}
            isSendButtonDisabled={emailIsEmpty}
          />
        </WizardForm.Page>
      </WizardForm>
    );
  }
}
