import IMAGE_DROPPER from '../images/meds/dropper.svg';
import IMAGE_HARD_CAPSULE from '../images/meds/hard-capsule.svg';
import IMAGE_INHALER from '../images/meds/inhaler.svg';
import IMAGE_INJECTOR from '../images/meds/injector.svg';
import IMAGE_LIQUID_CAPSULE from '../images/meds/liquid-capsule.svg';
import IMAGE_LIQUID from '../images/meds/liquid.svg';
import IMAGE_ORAL_OTHER from '../images/meds/oral-other.svg';
import IMAGE_PATCH from '../images/meds/patch.svg';
import IMAGE_POWDER from '../images/meds/powder.svg';
import IMAGE_TOPICAL_OINTMENT from '../images/meds/topical-ointment.svg';
import IMAGE_ORAL_PILL from '../images/meds/oral-pill.svg';

const TYPE_ORAL_PILL = 'Route Oral Pill';
const TYPE_ORAL_LIQUID = 'Route Oral Liquid';
const TYPE_INJECTABLE = 'Route Injectable';
const TYPE_INHALANT = 'Route Inhalant';
const TYPE_TOPICAL_PATCH = 'Route Topical Patch';
const TYPE_ORAL_OTHER = 'Route Oral Other';
const TYPE_TOPICAL = 'Route Topical';
const TYPE_ORAL_DROPPER = 'Route Oral Dropper';
const TYPE_ORAL_POWDER = 'Route Oral Powder';
const TYPE_ORAL_CAPSULE = 'Route Oral Capsule';

const images = {
  [TYPE_ORAL_PILL]: IMAGE_ORAL_PILL,
  [TYPE_ORAL_LIQUID]: IMAGE_LIQUID,
  [TYPE_INJECTABLE]: IMAGE_INJECTOR,
  [TYPE_INHALANT]: IMAGE_INHALER,
  [TYPE_TOPICAL_PATCH]: IMAGE_PATCH,
  [TYPE_ORAL_OTHER]: IMAGE_ORAL_OTHER,
  [TYPE_TOPICAL]: IMAGE_TOPICAL_OINTMENT,
  [TYPE_ORAL_DROPPER]: IMAGE_DROPPER,
  [TYPE_ORAL_POWDER]: IMAGE_POWDER,
  [TYPE_ORAL_CAPSULE]: IMAGE_LIQUID_CAPSULE,
};

export function getMedIcon(route) {
  const result = images[route];
  if (result) return result;
  return IMAGE_ORAL_PILL;
}
