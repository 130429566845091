import { HIDE_MESSAGE, SHOW_MESSAGE } from 'actions/action-types';

export const reducer = (state = { notification: null }, action) => {
  switch (action.type) {
    case HIDE_MESSAGE:
      return {
        ...state,
        notification: null,
      };
    case SHOW_MESSAGE:
      return {
        ...state,
        notification: action.payload,
      };
    default:
      return state;
  }
};
