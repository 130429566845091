import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { closeModal } from 'actions/modal';
import moment from 'moment-timezone';

import { DateInput, MultiSelectField, SelectField, TextArea, TimeInput } from '../../containers/Form';
import Strings from '../../Strings';
import { actions } from '../../pages/SuperUser/Patients/redux/actions';
import { actions as cohortActions } from '../../pages/SuperUser/Patients/Cohorts/redux/actions';
import { notificationActions } from '../../components/Notification/redux/actions';
import './EnrollPatientModal.scss';
import {
  ADD_NOTE_ERROR,
  EDIT_PATIENT_ERROR,
  ENROLL_PATIENT_ERROR,
  GET_REMINDERS_DEFAULT_MESSAGES_RESULT,
  SEND_WELCOME_MESSAGE_ERROR,
} from '../../pages/SuperUser/Patients/redux/constants';
import { validateEmail, validateFirstName, validateLastName, validatePhoneNo } from '../../utils/validators/rpmPatient';
import {
  ATTACH_PATIENT_TO_KIT_ERROR,
  DELETE_KIT_ERROR,
  DELETE_KIT_RESULT,
  GET_PATIENT_KIT_INFO_RESULT,
} from '../../pages/Kits/redux/constants';
import { actions as kitActions } from '../../pages/Kits/redux/actions';
import { RPM_PATIENT_STATUS } from '../../constants';
import { flatProgramsSubprogramsList, getSubprogramForApi, programs, RTM, subprograms } from '../../utils/cmsPrograms';
import Wizard, { wizardTypes } from '../../containers/Modal/Wizard';
import kitIdImg from '../../images/kitId.png';
import { ASSIGN_PATIENT_TO_COHORT_ERROR } from '../../pages/SuperUser/Patients/Cohorts/redux/constants';
import { EnrollPatientModalPages } from '../_ModalsMetadata/ModalsMetadata';
import { UPDATE_PATIENT_SCHEDULE } from '../../actions/action-types';
import { patientAction } from '../../actions/patient';
import { openModalAction } from '../../actions/modal';
import { stages } from '../EditRpmPatient/EditRpmPatientModal';

const EnrollPatientModal = props => {
  const enrollments = _.isEmpty(props.data?.patient?.patientEnrollment?.enrollments)
    ? []
    : Object.values(props.data.patient.patientEnrollment.enrollments).map(v => {
        return {
          ...v,
          name: programs[v.program],
          subprogram: subprograms[v.subprogram],
          isDefault: v.program === props.data.patient.patientEnrollment.defaultProgram,
          id: flatProgramsSubprogramsList.find(
            l => l.name === programs[v.program] && l.subprogram === subprograms[v.subprogram],
          )?.id,
        };
      });
  // data
  const [patient, setPatient] = useState(
    props.data.patient
      ? {
          ...props.data.patient,
          medicalHistory: null,
        }
      : {},
  );
  const [kit, setKit] = useState({});
  const [kits, setKits] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState(enrollments);
  const [selectedCondition, setSelectedCondition] = useState(() => calculateConditions(props.data.patient));
  const [welcomeMessage, setWelcomeMessage] = useState();
  const [welcomeMessageDate, setWelcomeMessageDate] = useState();
  const [welcomeMessageDateSet, setWelcomeMessageDateSet] = useState(false);
  const [welcomeMessageTime, setWelcomeMessageTime] = useState('09:00');
  const [welcomeMessageTimeAmPm, setWelcomeMessageTimeAmPm] = useState('AM');
  const [defaultWelcomeMessage, setDefaultWelcomeMessage] = useState();
  const [startDate, setStartDate] = useState(
    calculateStartDate(props.data.patient) || props.data?.stage === stages.activating
      ? new Date(
          moment
            .tz(props.orgTimezone)
            .add(1, 'days')
            .startOf('day')
            .format(),
        )
      : null,
  );
  const [endDate, setEndDate] = useState(calculateEndDate(props.data.patient));

  // checkboxes
  const [writtenConsentConfirm, setWrittenConsentConfirm] = useState(
    enrollments.length > 0 ? enrollments[0].writtenConsent : false,
  );
  const [verbalConsentConfirm, setVerbalConsentConfirm] = useState(
    enrollments.length > 0 ? enrollments[0].verbalConsent : false,
  );
  const [welcomeMessageChecked, setWelcomeMessageChecked] = useState(props.data?.stage === stages.activating);

  // wizard
  const [highlightInvalidFields, setHighlightMissingFields] = useState(false);
  const [saveBtnClicked, setSaveBtnClicked] = useState(false);
  // const [validationErrors, setValidationErrors] = useState([]);

  // other
  const [onlyAssigingKit, setOnlyAssigingKit] = useState(false);
  const [showKitPicture, setShowKitPicture] = useState(false);
  const [previousNotes, setPreviousNotes] = useState('');
  const [note, setNote] = useState('');

  useEffect(() => {
    // get patient kit
    props.getPatientKitInfo(patient.id).then(resp => {
      if (
        resp.type === GET_PATIENT_KIT_INFO_RESULT &&
        resp.response.kit?.id &&
        props.data.stage === stages.activating
      ) {
        setKit(resp.response.kit);
      }
    });

    props.getKits({ havingPatients: false }).then(resp =>
      setKits(
        resp.response.kits.map(d => {
          return { label: `${d.id}${d.name ? ` (${d.name})` : ''}`, value: d.id, name: d.name };
        }),
      ),
    );

    props.getNotes(patient.id).then(resp => {
      if (resp.response?.data?.length > 0) {
        setPreviousNotes(resp.response.data.map(n => n.content).join('\n'));
      }
    });

    props.getRemindersDefaultMessages({ language: patient.language }).then(resp => {
      if (resp.type === GET_REMINDERS_DEFAULT_MESSAGES_RESULT) {
        const defaultMessage = resp.response?.data?.find(m => m.type === 'welcome_msg_sms')?.message;
        setDefaultWelcomeMessage(defaultMessage);
        setWelcomeMessage(defaultMessage);
      }
    });

    props.getConditions();

    if (patient.status === RPM_PATIENT_STATUS.enrolled) {
      setOnlyAssigingKit(true);
    }
  }, []);

  useEffect(() => {
    const request = { limit: 1000 };
    if (patient.facilityId) {
      request.facilityId = patient.facilityId;
    }
    if (patient.conditions) {
      request.conditionId = Object.keys(patient.conditions).join(',');
    }

    props.getCohorts(request);
  }, [patient.conditions]);

  useEffect(() => {
    if (startDate && !welcomeMessageDateSet) {
      setWelcomeMessageDate(new Date(startDate));
    }
  }, [startDate]);

  function calculateEndDate(p) {
    let ret = '';
    if (!p?.patientEnrollment || !p?.patientEnrollment.enrollments) {
      return ret;
    }
    Object.values(p.patientEnrollment.enrollments).forEach(v => {
      if (v.enrollmentEnd && ret != v.enrollmentEnd) {
        ret = new Date(v.enrollmentEnd);
      }
    });
    return ret;
  }

  function calculateStartDate(p) {
    let ret = '';
    if (!p?.patientEnrollment || !p?.patientEnrollment.enrollments) {
      return ret;
    }
    Object.values(p.patientEnrollment.enrollments).forEach(v => {
      if (v.enrollmentStart && ret != v.enrollmentStart) {
        ret = new Date(v.enrollmentStart);
      }
    });
    return ret;
  }

  function calculateConditions(p) {
    if (enrollments.length > 0) {
      return enrollments[0].conditionId;
    }
    return p?.conditions && Object.keys(p.conditions).length === 1 ? Object.keys(p.conditions)[0] : null;
  }

  const onNoteChange = event => {
    if (event.target.value != ' ') {
      setNote(event.target.value);
    }
  };

  const assignToCohort = async () => {
    let ret = false;
    if (patient.cohortId && patient.cohortId !== props.data.patient?.cohortId) {
      await props.assignPatientToCohort(parseInt(patient.id, 10), patient.cohortId).then(response => {
        if (response) {
          if (response.type === ASSIGN_PATIENT_TO_COHORT_ERROR) {
            const error = response.response?.data?.error?.message;
            props.showNotification(error, 5000, true);
            ret = false;
          }
          ret = true;
        }
      });
    } else {
      return true;
    }
    return ret;
  };

  const getValidationErrors = () => {
    const errors = validateFirstName(patient.firstName)
      .concat(validateLastName(patient.lastName))
      .concat(validatePhoneNo(patient.primaryPhoneNo, 'primaryPhoneNo', true))
      .concat(validatePhoneNo(patient.textPhoneNo, 'textPhoneNo', true))
      .concat(validateEmail(patient.email));

    if (!selectedCondition) {
      errors.push({ property: 'selectedCondition', errors: [], missing: true });
    }
    if (!verbalConsentConfirm && !writtenConsentConfirm) {
      errors.push({ property: 'consents', errors: [], missing: true });
    }
    if (!selectedPrograms || selectedPrograms.length === 0) {
      errors.push({ property: 'programs', errors: [], missing: true });
    }
    if (!selectedPrograms?.some(p => p.isDefault)) {
      errors.push({ property: 'defaultProgram', errors: [], missing: true });
    }
    if (
      endDate &&
      moment(endDate).unix() <
        moment()
          .tz(props.orgTimezone)
          .endOf('day')
          .unix()
    ) {
      errors.push({ property: 'endDate', errors: [Strings.errors.dateInThePast], missing: false });
    }
    if (
      startDate &&
      moment(startDate).unix() <
        moment
          .tz(props.orgTimezone)
          .endOf('day')
          .unix()
    ) {
      errors.push({ property: 'startDate', errors: [Strings.errors.dateInThePast], missing: false });
    }
    if (startDate && endDate && moment(startDate).isAfter(endDate)) {
      errors.push({
        property: 'endDate',
        errors: [Strings.errors.endDateMustBeAfterStartDate],
      });
    }
    if (!kit?.id) {
      errors.push({ property: 'kit', errors: [], missing: true });
    }
    const welcomeMessageDateTime = calcWelcomeMessageDateTime(welcomeMessageTime, welcomeMessageTimeAmPm);
    if (welcomeMessageDateTime && moment(welcomeMessageDateTime).unix() < moment().unix()) {
      errors.push({ property: 'welcomeMessageDate', errors: [Strings.errors.dateInThePast], missing: false });
    }
    if (welcomeMessageChecked && welcomeMessageDate && !welcomeMessageTime) {
      errors.push({ property: 'welcomeMessageTime', errors: [], missing: true });
    }
    return errors;
  };

  const calcWelcomeMessageDateTime = (time, ampm) => {
    const date = welcomeMessageDate || new Date();
    if (date && time && ampm) {
      const t = moment(time, 'hh:mm').format('HH:mm');
      const d = moment.tz(date, props.data?.patient?.timezone).format('YYYY-MM-DD');
      return moment.tz(`${d} ${t} ${ampm}`, 'YYYY-MM-DD HH:mm A', props.data?.patient?.timezone).format();
    }
    return null;
  };

  const onKitSelected = option => {
    setKit({ id: option.value, name: option.name });
  };

  const onAssignKit = async () => {
    if (!kit?.id) {
      return true;
    }
    // if patient kit remains unchanged
    if (props.kit && props.kit.id === kit.id) {
      return true;
    }

    // otherwise if patient kit is reassigned, delete the one he has
    let deleteKitSuccessful = true;
    if (props.kit) {
      await props.deleteKit(props.kit.id).then(resp => {
        if (resp.type === DELETE_KIT_RESULT) {
          deleteKitSuccessful = true;
        } else if (resp.type === DELETE_KIT_ERROR) {
          const error = resp.response?.data?.error?.message;
          props.showNotification(error, 5000, true);
          deleteKitSuccessful = false;
        }
      });
    }
    if (!deleteKitSuccessful) {
      return false;
    }

    // assign new kit
    let ret = false;
    await props.attachKitToPatient(kit.id, patient.id).then(resp => {
      if (resp.type === ATTACH_PATIENT_TO_KIT_ERROR) {
        const error = resp.response?.data?.error?.message;
        props.showNotification(error, 5000, true);
        ret = false;
      } else {
        props.showNotification(Strings.formatString(Strings.kitAssignedInfo, kit.id, patient.patientName));
        ret = true;
      }
    });
    return ret;
  };

  const enrollPatient = async () => {
    let ret = false;
    const data = { patient_id: patient.id };

    data.data = selectedPrograms.map(program => {
      const enrollmentData = { condition_id: selectedCondition };

      if (program.subprogram) {
        enrollmentData.subprogram = getSubprogramForApi(program.subprogram);
      }

      if (!onlyAssigingKit) {
        enrollmentData.default_program = program.isDefault;
        enrollmentData.verbal_consent = verbalConsentConfirm;
        enrollmentData.written_consent = writtenConsentConfirm;
      }

      if (kit?.id) {
        enrollmentData.kit_id = kit.id;
      }

      if (startDate) {
        enrollmentData.start_date = moment
          .tz(startDate, props.orgTimezone)
          .startOf('day')
          .format();
      }

      if (endDate) {
        enrollmentData.end_date = moment
          .tz(endDate, props.orgTimezone)
          .endOf('day')
          .format();
      } else {
        // from backend documentaion: To unset end_date set it to 2300-01-01T00:00:00Z
        const magicDateToClearEndDate = '2300-01-01T00:00:00Z';
        enrollmentData.end_date = magicDateToClearEndDate;
      }

      return enrollmentData;
    });

    await props.enrollPatient(data).then(resp => {
      if (resp.type === ENROLL_PATIENT_ERROR) {
        const error = resp.response?.data?.error?.message;
        props.showNotification(error, 5000, true);
        ret = false;
      } else {
        props.showNotification(Strings.formatString(Strings.patientEnrolledInfo, patient.patientName));
        ret = true;
      }
      return resp;
    });
    return ret;
  };

  const sendWelcomeSms = async () => {
    let ret = false;
    if (welcomeMessageChecked) {
      const request = { user_id: patient.id, kit_id: kit.id };
      if (welcomeMessage !== defaultWelcomeMessage) {
        request.message = welcomeMessage;
      }
      const welcomeMessageDateTime = calcWelcomeMessageDateTime(welcomeMessageTime, welcomeMessageTimeAmPm);
      if (welcomeMessageDateTime) {
        request.schedule_at = welcomeMessageDateTime;
      }
      await props.sendWelcomeMessage(request).then(resp => {
        if (resp.type === SEND_WELCOME_MESSAGE_ERROR) {
          const error = resp.response?.data?.error?.message;
          props.showNotification(error, 5000, true);
          ret = false;
        } else {
          props.showNotification(Strings.formatString(Strings.welcomeSmsSent, patient.patientName));
          ret = true;
        }
        return resp;
      });
    } else {
      ret = true;
    }
    return ret;
  };

  const saveNote = async () => {
    if (note !== '') {
      await props.addNote(patient.id, { content: note }).then(resp => {
        if (resp.type === ADD_NOTE_ERROR) {
          const error = resp.response?.data?.error?.message;
          props.showNotification(error, 5000, true);
        }
      });
    }
  };

  const addCondition = async () => {
    let ret = false;
    if (Object.keys(patient.conditions).some(c => c === selectedCondition)) {
      return true;
    }
    await props
      .editPatient(
        { conditions: Object.keys(patient.conditions).concat([selectedCondition]) },
        parseInt(patient.id, 10),
      )
      .then(response => {
        if (response) {
          if (response.type === EDIT_PATIENT_ERROR) {
            const error = response.response?.data?.error?.message;
            props.showNotification(error, 5000, true);
            ret = false;
          }
          ret = true;
        }
      });
    return ret;
  };

  const onSave = async () => {
    if (saveBtnClicked) {
      return;
    }
    setSaveBtnClicked(true);

    const addConditionSucessful = await addCondition();
    if (addConditionSucessful) {
      const assignSuccessful = await assignToCohort();
      if (assignSuccessful) {
        const kitSuccessful = await onAssignKit();
        if (kitSuccessful) {
          const enrollSuccessful = await enrollPatient();
          if (enrollSuccessful) {
            const welcomeSmsSuccessful = await sendWelcomeSms();
            if (welcomeSmsSuccessful) {
              await saveNote();
              const shouldStartAddingMed =
                props.data?.stage === stages.activating && selectedPrograms.some(p => p.name === RTM);
              props.closeModalWithNextAction(shouldStartAddingMed);
            }
          }
        }
      }
    }
    setSaveBtnClicked(false);
  };

  const validationErrors = getValidationErrors();

  const onMultiselectChange = checkedPrograms => {
    if (!checkedPrograms || checkedPrograms == []) {
      setSelectedPrograms([]);
    } else if (checkedPrograms.length === 1) {
      setSelectedPrograms([{ ...checkedPrograms[0], isDefault: true }]);
    } else {
      const def = selectedPrograms.find(s => s.isDefault);
      setSelectedPrograms(
        checkedPrograms.map(p => {
          return {
            ...p,
            isDefault: def?.name === p.name && def?.subprogram === p.subprogram,
          };
        }),
      );
    }
  };
  const onProgramRemove = option => {
    onMultiselectChange(selectedPrograms.filter(p => p.id !== option.value));
  };

  const onDefaultProgramChange = option => {
    if (option.value !== ' ')
      setSelectedPrograms(
        selectedPrograms.map(p => {
          return {
            ...p,
            isDefault: p.id === option.value,
          };
        }),
      );
  };

  const onProgramSelected = option => {
    if (option.value !== ' ') setSelectedPrograms([{ ...option, isDefault: true }]);
  };

  const onSelectedOption = (option, field) => {
    if (option.value) {
      setPatient(p => ({
        ...p,
        [field]: option.value,
      }));
    }
  };

  const onConsentChange = option => {
    if (option.value === 'verbal') {
      setVerbalConsentConfirm(true);
      setWrittenConsentConfirm(false);
    } else if (option.value === 'written') {
      setVerbalConsentConfirm(false);
      setWrittenConsentConfirm(true);
    }
  };

  const onWelcomeMessageTimeChange = value => {
    setWelcomeMessageTime(value);
    const ampm = welcomeMessageTimeAmPm || value.split(':')[0] > 8 ? 'AM' : 'PM';
    if (!welcomeMessageTimeAmPm) {
      setWelcomeMessageTimeAmPm(ampm);
    }
    if (!welcomeMessageDate) {
      if (moment().unix() > moment(calcWelcomeMessageDateTime(value, ampm)).unix()) {
        setWelcomeMessageDate(
          new Date(
            moment()
              .add(1, 'days')
              .format(),
          ),
        );
      } else {
        setWelcomeMessageDate(new Date());
      }
    }
  };

  const programPage = (
    <React.Fragment>
      {props.conditions && props.conditions.length > 0 && (
        <SelectField
          name="enrollment_condition"
          id="enrollment_condition"
          placeholder={Strings.select}
          label={Strings.condition}
          value={selectedCondition || ''}
          onChange={option => setSelectedCondition(option.value)}
          data={props.conditions.map(c => {
            return { value: c.id, label: c.title };
          })}
          disabled={props.data?.stage !== stages.enrolling}
          isRequired
          highlightInvalid={highlightInvalidFields}
        />
      )}
      <SelectField
        value={selectedPrograms.map(p => p.id)?.[0]}
        data={flatProgramsSubprogramsList.map(l => ({ ...l, value: l.id, label: l.displayText }))}
        onChange={onProgramSelected}
        placeholder={Strings.select}
        label={Strings.selectProgram}
        isRequired
        highlightInvalid={highlightInvalidFields}
        disabled={props.data?.stage !== stages.enrolling}
      />
      {selectedPrograms && selectedPrograms.length > 0 && (
        <SelectField
          name="cohort"
          id="cohort"
          label={Strings.capPatient.assignCohort}
          value={patient.cohortId}
          onChange={option => onSelectedOption(option, 'cohortId')}
          placeholder={Strings.select}
          data={
            props.cohorts && props.cohorts.length > 0
              ? props.cohorts.map(c => {
                  return { value: c.id, label: c.title };
                })
              : []
          }
        />
      )}
      <SelectField
        name="consent"
        id="consent"
        label={Strings.consent}
        value={verbalConsentConfirm ? 'verbal' : writtenConsentConfirm ? 'written' : undefined}
        onChange={onConsentChange}
        placeholder={Strings.select}
        data={[
          { value: 'verbal', label: Strings.verbalConsentGiven },
          { value: 'written', label: Strings.writtenConsentGiven },
        ]}
        isRequired
        highlightInvalid={highlightInvalidFields}
      />
      <TextArea
        name="previousNotes"
        id="previousNotes"
        rows={4}
        value={previousNotes}
        label={Strings.placeholder.notesArea}
        disabled
        rowClassName="double-width-left"
      />
      <TextArea
        name="enrollmentNote"
        id="enrollmentNote"
        rows={4}
        value={note}
        label={Strings.addNote}
        onChange={onNoteChange}
        rowClassName="double-width-right"
      />
    </React.Fragment>
  );

  const kitsList = kit?.id
    ? kits.concat([{ label: `${kit.id}${kit.name ? ` (${kit.name})` : ''}`, value: kit.id }])
    : kits;
  const kitPage = (
    <React.Fragment>
      <div className="control-with-top-right-text double-width-left">
        <SelectField
          id="kit"
          name="kit"
          label={Strings.searchKit}
          placeholder={Strings.select}
          disabled={selectedPrograms.length === 0}
          value={kit?.id}
          data={kitsList}
          onChange={onKitSelected}
          isSearchable
          isRequired
          highlightInvalid={highlightInvalidFields}
          rowClassName="double-width-left"
        />
        <div className="right-top-text">
          <span className="label">{Strings.capPatient.whereIsKitId}</span>
          <span
            onMouseEnter={() => setShowKitPicture(true)}
            onMouseLeave={() => setShowKitPicture(false)}
            className="icon"
          >
            ?
          </span>
        </div>
      </div>

      {showKitPicture && (
        <div className="kit-location">
          <img src={kitIdImg} className="hwImg" alt="hardware" />
          {Strings.wheresKitIdText}
        </div>
      )}
    </React.Fragment>
  );

  const datesPage = (
    <React.Fragment>
      {selectedPrograms && selectedPrograms.length > 0 && props.data?.stage !== stages.settingEndDate && (
        <DateInput
          name="start_date"
          id="start_date"
          label={Strings.startDate}
          placeholder="MM / DD / YYYY"
          value={startDate || ''}
          timezone={props.orgTimezone}
          format="MM / dd / yyyy"
          onChange={setStartDate}
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'startDate')?.errors || []}
        />
      )}
      {selectedPrograms && selectedPrograms.length > 0 && (
        <DateInput
          name="end_date"
          id="end_date"
          label={Strings.endDate}
          placeholder="MM / DD / YYYY"
          value={endDate || ''}
          timezone={props.orgTimezone}
          format="MM / dd / yyyy"
          onChange={setEndDate}
          highlightInvalid={highlightInvalidFields}
          errorsForTooltip={validationErrors.find(v => v.property === 'endDate')?.errors || []}
        />
      )}
    </React.Fragment>
  );
  const welcomeSmsPage = (
    <React.Fragment>
      <SelectField
        name="welcome_sms"
        id="welcome_sms"
        label={Strings.capPatient.enableMessage}
        data={[
          { value: true, label: Strings.enabled },
          { value: false, label: Strings.disabled },
        ]}
        value={welcomeMessageChecked}
        onChange={option => setWelcomeMessageChecked(option.value)}
        rowClassName="double-width-left"
      />
      {welcomeMessageChecked && (
        <React.Fragment>
          <div className="reminder-text double-width-right" style={{ gridRowStart: 9, gridRowEnd: 13 }}>
            <TextArea
              name="welcome_sms_text"
              id="welcome_sms_text"
              label={Strings.capPatient.welcomeMessage}
              value={welcomeMessage}
              timezone={patient?.timezone}
              rows={8}
              onChange={e => setWelcomeMessage(e.target.value)}
              isRequired
              highlightInvalid={highlightInvalidFields}
            />
            {welcomeMessage !== defaultWelcomeMessage && (
              <button
                onClick={() => setWelcomeMessage(defaultWelcomeMessage)}
                className="reset-reminder-text brand-white-gray"
              >
                {Strings.setDefault}
              </button>
            )}
          </div>
          <DateInput
            name="welcome_sms_date"
            id="welcome_sms_date"
            label={Strings.scheduledDate}
            placeholder="MM / DD / YYYY hh:mm a"
            value={welcomeMessageDate || ''}
            timezone={patient?.timezone}
            format="MM / dd / yyyy"
            onChange={date => {
              setWelcomeMessageDate(date);
              setWelcomeMessageDateSet(true);
            }}
            highlightInvalid={highlightInvalidFields}
            errorsForTooltip={validationErrors.find(v => v.property === 'welcomeMessageDate')?.errors || []}
            rowClassName="double-width-left"
          />
          <div className="horizontal-flex double-width-left">
            <div className="horizontal-flex">
              <TimeInput
                id="welcome_sms_time"
                name="welcome_sms_time"
                label={Strings.time}
                format="hh:mm"
                value={welcomeMessageTime}
                onChange={e => onWelcomeMessageTimeChange(e)}
                highlightInvalid={highlightInvalidFields}
                isRequired={welcomeMessageDate || welcomeMessageTimeAmPm}
              />
              <SelectField
                name="welcome_sms_time_am_pm"
                id="welcome_sms_time_am_pm"
                label=" "
                value={welcomeMessageTimeAmPm}
                onChange={option => setWelcomeMessageTimeAmPm(option.value)}
                data={[
                  { value: 'AM', label: Strings.patient_medications.am },
                  { value: 'PM', label: Strings.patient_medications.pm },
                ]}
                highlightInvalid={highlightInvalidFields}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );

  const mergePages = (pages, title, nextButtonText, fieldsForValidation) => {
    return {
      id: 'enroll-page',
      title,
      content: (
        <React.Fragment>
          {pages.map(p => {
            return (
              <React.Fragment key={p.id}>
                <div className="page-info-header">{p.title}</div>
                {p.content}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      ),
      emptyFieldsCount: validationErrors.filter(v => fieldsForValidation.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => fieldsForValidation.includes(v.property)).length === 0,
      nextButton: { text: nextButtonText },
    };
  };

  // const programFields = ['programs', 'defaultProgram', 'endDate'];
  const programFields = ['programs', 'consents', 'selectedCondition'];
  const datesFields = ['startDate', 'endDate'];
  const kitFields = ['kit'];
  const welcomeMessageFields = ['welcomeMessage', 'welcomeMessageDate', 'welcomeMessageTime'];

  // definitions of pages for modal
  // onNext should return bool indicating if action was successfull and wizzard can switch to next page
  const pages = [
    {
      id: EnrollPatientModalPages.programs.id,
      title: EnrollPatientModalPages.programs.name,
      content: programPage,
      emptyFieldsCount: validationErrors.filter(v => programFields.includes(v.property) && v.missing).length,
      canGoNext: validationErrors.filter(v => programFields.includes(v.property)).length === 0,
      nextButton: { text: Strings.enroll },
    },
    {
      id: EnrollPatientModalPages.kit.id,
      title: EnrollPatientModalPages.kit.name,
      content: kitPage,
      nextButton: {
        text: onlyAssigingKit || kit?.id ? null : Strings.saveWithNoKit,
      },
      emptyFieldsCount: validationErrors.filter(v => kitFields.includes(v.property) && v.missing).length,
      canGoNext: (!onlyAssigingKit || kit?.id) && !saveBtnClicked,
    },
    {
      id: EnrollPatientModalPages.dates.id,
      title: EnrollPatientModalPages.dates.name,
      content: datesPage,
    },
    {
      id: EnrollPatientModalPages.welcomeMessage.id,
      title: EnrollPatientModalPages.welcomeMessage.name,
      content: welcomeSmsPage,
      emptyFieldsCount: validationErrors.filter(v => welcomeMessageFields.includes(v.property) && v.missing).length,
      canGoNext:
        validationErrors.filter(v => welcomeMessageFields.includes(v.property)).length === 0 && !saveBtnClicked,
    },
  ];

  const getpagesToShow = () => {
    let pagesToMerge, title, buttonText, fields;
    switch (props.data.stage) {
      case stages.enrolling: {
        pagesToMerge = [pages[0]];
        title = `${Strings.enroll} ${patient?.patientName}`;
        buttonText = Strings.enroll;
        fields = [...programFields];
        break;
      }
      case stages.activating: {
        pagesToMerge = [pages[0], pages[1], pages[2], pages[3]];
        title = `${Strings.activate} ${patient?.patientName}`;
        buttonText = Strings.save;
        fields = [...programFields, ...datesFields, ...kitFields, ...welcomeMessageFields];
        break;
      }
      case stages.settingEndDate: {
        pagesToMerge = [pages[2]];
        title = Strings.setEnrollmentEndDate;
        buttonText = Strings.save;
        fields = ['endDate'];
        break;
      }
    }

    return [mergePages(pagesToMerge, title, buttonText, fields)];
  };

  return (
    <Wizard
      name="enroll-patient"
      pages={getpagesToShow()}
      onNextButtonHover={e => setHighlightMissingFields(e)}
      onSubmit={onSave}
      showPagesFilter={!props.data.ignoreFilters}
      type={wizardTypes.horizontal}
    />
  );
};

EnrollPatientModal.propTypes = {
  onCancel: PropTypes.func,
  data: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      primaryPhoneNo: PropTypes.string,
      textPhoneNo: PropTypes.string,
      conditions: PropTypes.any,
      cohortId: PropTypes.string,
    }),
    action: PropTypes.func,
    nextAction: PropTypes.func,
    workflow: PropTypes.any,
  }),
  kit: PropTypes.shape({ id: PropTypes.string }),
  conditions: PropTypes.array,
  cohorts: PropTypes.array,
  getConditions: PropTypes.func,
  getCohorts: PropTypes.func,
  assignPatientToCohort: PropTypes.func,
  getKits: PropTypes.func,
  getKitDetails: PropTypes.func,
  getPatientKitInfo: PropTypes.func,
  deleteKit: PropTypes.func,
  attachKitToPatient: PropTypes.func,
  enrollPatient: PropTypes.func,
  showNotification: PropTypes.func,
  closeModalWithNextAction: PropTypes.func,
  getRemindersDefaultMessages: PropTypes.func,
  sendWelcomeMessage: PropTypes.func,
  addNote: PropTypes.func,
  getNotes: PropTypes.func,
  editPatient: PropTypes.func,
  orgTimezone: PropTypes.string,
};

EnrollPatientModal.defaultProps = { data: { ignoreFilters: false } };

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('enroll-patient', {}, ownProps.modalId)),
  closeModalWithNextAction: startAddingMed => {
    dispatch(closeModal('enroll-patient'));
    if (ownProps.data.onSuccess) {
      ownProps.data.onSuccess(ownProps.data.patient);
    }
    if (startAddingMed) {
      dispatch(
        openModalAction('edit-rpm-schedule', {
          patient: ownProps.data?.patient,
          action: patientAction.actionUpdateSchedule,
          actionType: UPDATE_PATIENT_SCHEDULE,
        }),
      );
    }
  },
  getConditions: () => dispatch(actions.getConditions()),
  getCohorts: request => dispatch(cohortActions.getCohorts(request)),
  getKits: pageRequest => dispatch(kitActions.getKits(pageRequest)),
  getKitDetails: kitId => dispatch(kitActions.getKitDetails(kitId)),
  getPatientKitInfo: patientId => dispatch(kitActions.getPatientKitInfo(patientId)),
  deleteKit: kitId => dispatch(kitActions.deleteKit(kitId)),
  attachKitToPatient: (kitId, patientId) => dispatch(kitActions.attachKitToPatient(kitId, patientId)),
  enrollPatient: data => dispatch(actions.enrollPatient(data)),
  assignPatientToCohort: (patientId, cohortId) => dispatch(cohortActions.assignPatientToCohort(patientId, cohortId)),
  showNotification: (message, timeout, isError) => dispatch(notificationActions.show(message, timeout, isError)),
  getRemindersDefaultMessages: request => dispatch(actions.getRemindersDefaultMessages(request)),
  sendWelcomeMessage: data => dispatch(actions.sendWelcomeMessage(data)),
  addNote: (patientId, data) => dispatch(actions.addNote(patientId, data)),
  getNotes: patientId => dispatch(actions.getNotes(patientId)),
  editPatient: (data, patientId) => dispatch(actions.editPatient(data, patientId)),
});

const mapStateToProps = state => {
  return {
    conditions: state.superUser.patients?.conditions,
    cohorts: state.superUser.cohorts?.cohorts,
    kit: state.entities.kits?.patientKit,
    timezone: state.auth?.profile?.preferences?.timezone,
    orgTimezone: state.auth?.ui_config?.timezone,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnrollPatientModal);
