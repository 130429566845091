import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class TextInput extends PureComponent {
  static propTypes = {
    class: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
  };

  render() {
    return <input onChange={this.props.onChange} className={this.props.class} placeholder={this.props.placeholder} />;
  }
}
