import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactDatePicker from 'react-datepicker';

import { updateForm, submitForm, cancelForm, resetForm } from '../actions/forms';
import { PhoneInput } from '../components/Inputs/PhoneInput';
import Select from '../components/Select';
import Multiselect from '../components/Select/Multiselect';
import { AutosuggestInput } from '../components/AutosuggestInput/AutosuggestInput';
import AsyncSelect from '../components/Select/AsyncSelect';
import DatePicker from '../components/Inputs/DatePicker';
import TimePicker from '../components/Inputs/TimePicker';
import { TextAreaWithHashtags } from '../components/Inputs/TextAreaWithHashtags/TextAreaWithHashtags';

class Form extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    updateForm: PropTypes.func,
    submitForm: PropTypes.func,
    resetForm: PropTypes.func,
    cancelForm: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    children: PropTypes.any,
  };

  onChange(event) {
    const { target } = event;
    this.props.updateForm({ [target.id]: target.value });
  }

  onSubmit(type) {
    // event.preventDefault();
    if (type === 'cancel') {
      this.props.cancelForm();

      if (this.props.onCancel) {
        this.props.onCancel();
      }
    } else if (type === 'reset') {
      this.props.resetForm();
    } else {
      this.props.submitForm({});

      if (this.props.onSubmit) {
        this.props.onSubmit(this.props.data);
      }
    }
  }

  onClick(event) {
    if (event.target.tagName === 'BUTTON') {
      event.preventDefault();
      event.stopPropagation();

      const { target } = event;
      this.onSubmit(target.getAttribute('type'));
    }
  }

  render() {
    const { data } = this.props;
    let { children } = this.props;

    if (!Array.isArray(children)) {
      children = [children];
    }

    return (
      <form onClick={e => this.onClick(e)}>
        {children.map(item =>
          React.cloneElement(item, {
            value: (data && data[item.props.id]) || '',
            key: item.props.id,
            onChange: event => this.onChange(event),
          }),
        )}
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  data: state.forms && state.forms[ownProps.id] && state.forms[ownProps.id].data,
  cancelled: (state.forms && state.forms[ownProps.id] && state.forms[ownProps.id].cancelled) || false,
  submitted: (state.forms && state.forms[ownProps.id] && state.forms[ownProps.id].submitted) || false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateForm: data => updateForm(ownProps.id, data)(dispatch),
  submitForm: data => submitForm(ownProps.id, data)(dispatch),
  resetForm: () => resetForm(ownProps.id, ownProps.initData)(dispatch),
  cancelForm: () => cancelForm(ownProps.id)(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);

const FieldWrapper = ({
  label,
  id,
  errors,
  errorsForTooltip,
  Field,
  onChange,
  className,
  isRequired,
  highlightInvalid,
  value,
  rowClassName,
  inputRef,
  ...otherProps
}) => (
  <span className={`row ${rowClassName || ''}`}>
    {label && (
      <label htmlFor={id} className={isRequired ? 'required-asterisk' : ''}>
        {label}
      </label>
    )}
    {errorsForTooltip &&
      errorsForTooltip.map((item, i) => (
        <span
          className={`input-error${!label ? ' no-label' : ''}`}
          data-tooltip-content={item}
          data-tooltip-id="tooltip"
        />
      ))}
    {errors &&
      errors.map((item, i) => (
        <span key={`error-${i}`} className="error">
          {item}
        </span>
      ))}
    <Field
      className={`${className || ''} ${
        highlightInvalid &&
        isRequired &&
        (Object.prototype.hasOwnProperty.call(otherProps, 'inputProps')
          ? isValueEmpty(otherProps.inputProps.value)
          : isValueEmpty(value))
          ? 'invalid-control-background'
          : ''
      } ${highlightInvalid && errorsForTooltip?.length > 0 ? 'invalid-control-background' : ''}`}
      onChange={onChange}
      id={id}
      value={value}
      ref={inputRef}
      {...otherProps}
    />
  </span>
);

const isValueEmpty = value => {
  return value === undefined || value === null || value === '' || (Array.isArray(value) && value.length === 0);
};

export const Input = ({ label, id, errors, className, onChange, ...otherProps }) => (
  <FieldWrapper
    label={label}
    id={id}
    className={className}
    errors={errors}
    onChange={onChange}
    {...otherProps}
    Field="input"
  />
);

export const TextArea = ({ label, id, errors, onChange, ...otherProps }) => (
  <FieldWrapper label={label} id={id} errors={errors} onChange={onChange} {...otherProps} Field="textarea" />
);

export const PhoneInputField = ({ label, id, errors, onChange, ...otherProps }) => (
  <FieldWrapper
    label={label}
    id={id}
    errors={errors}
    onChange={onChange}
    {...otherProps}
    noTooltip
    Field={PhoneInput}
  />
);

export const SelectField = ({ label, id, errors, onChange, ...otherProps }) => (
  <FieldWrapper label={label} id={id} errors={errors} onChange={onChange} {...otherProps} Field={Select} />
);

export const MultiSelectField = ({ label, id, errors, onChange, ...otherProps }) => (
  <FieldWrapper label={label} id={id} errors={errors} onChange={onChange} {...otherProps} Field={Multiselect} />
);

export const AutosuggestInputField = ({ label, id, errors, onChange, ...otherProps }) => (
  <FieldWrapper label={label} id={id} errors={errors} {...otherProps} Field={AutosuggestInput} />
);

export const AsyncSelectField = ({ label, id, errors, onChange, ...otherProps }) => (
  <FieldWrapper label={label} id={id} errors={errors} onChange={onChange} {...otherProps} Field={AsyncSelect} />
);

export const DateInput = ({ label, id, errors, onChange, ...otherProps }) => (
  <FieldWrapper label={label} id={id} errors={errors} onChange={onChange} {...otherProps} Field={DatePicker} />
);

export const TimeInput = ({ label, id, errors, onChange, ...otherProps }) => (
  <FieldWrapper
    label={label}
    id={id}
    errors={errors}
    onChange={onChange}
    disableCalendar
    disableClock
    clearIcon={null}
    {...otherProps}
    Field={TimePicker}
  />
);

export const RadioButton = props =>
  props.label ? (
    <label htmlFor={props.id} className="row for-radiobutton">
      {props.label}
      <input type="radio" {...props} />
      <div className="radio-button" />
    </label>
  ) : (
    <input type="radio" {...props} />
  );

export const TextAreaWithHashtagsField = ({ label, id, errors, onChange, ...otherProps }) => (
  <FieldWrapper
    label={label}
    id={id}
    errors={errors}
    onChange={onChange}
    {...otherProps}
    Field={TextAreaWithHashtags}
  />
);

export const DatePickerField = ({ label, id, errors, onChange, ...otherProps }) => (
  <FieldWrapper label={label} id={id} errors={errors} onChange={onChange} {...otherProps} Field={ReactDatePicker} />
);

Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string.isRequired,
};

export const Columns = ({ children }) => <div className={`columns-${children.length}`}>{children}</div>;

Columns.propTypes = { children: PropTypes.any };
