import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actionsDashboard as actions } from './redux/actions';

const mapStateToProps = (state, ownProps) => {
  const { editMode } = state.dashboardState;
  return {
    editMode: editMode[ownProps.dashboardId],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeEditMode: editMode => dispatch(actions.changeEditMode(ownProps.dashboardId, editMode)),
});

function DashboardEditModeButton(props) {
  return (
    <div className={`edit-mode-button ${props.editMode ? 'on' : ``}`}>
      <div
        onClick={() => props.changeEditMode(!props.editMode)}
        className={props.editMode ? 'edit-mode-icon-on' : `edit-mode-icon`}
      />
    </div>
  );
}

DashboardEditModeButton.propTypes = {
  editMode: PropTypes.bool,
  dashboardId: PropTypes.string,
  changeEditMode: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardEditModeButton);
