import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { BATTERY_LEVEL_GOOD, BATTERY_LEVEL_POOR } from '../../../constants';
import Strings from '../../../Strings';
import styles from './style.module.scss';

export class Battery extends PureComponent {
  render() {
    let { value } = this.props;
    switch (value) {
      case 'High':
        value = 4;
        break;
      case 'MedHigh':
        value = 3;
        break;
      case 'MedLow':
        value = 2;
        break;
      case 'Poor':
        value = 1;
        break;
      default:
        value = 0;
        break;
    }

    if (!value && value !== 0) {
      return <div className={styles.not_available}>{Strings.notAvailable}</div>;
    }

    let batteryLevel = styles.average;

    if (value <= BATTERY_LEVEL_POOR) batteryLevel = styles.poor;
    if (value >= BATTERY_LEVEL_GOOD) batteryLevel = styles.good;

    return (
      <div className={`${styles.battery} ${batteryLevel}`}>
        <div className={value >= 1 ? styles.active : ''} />
        <div className={value >= 2 ? styles.active : ''} />
        <div className={value >= 3 ? styles.active : ''} />
        <div className={value >= 4 ? styles.active : ''} />
      </div>
    );
  }
}

Battery.propTypes = { value: PropTypes.number };
