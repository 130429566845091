import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { ADD_HARDWARE_ID_RESULT } from '../actions/action-types';
import { capsAction } from '../actions/caps';
import Strings from '../Strings';
import './AddCareGiverUsingLink.scss';
import './CareGiverSuccessPage.scss';

class TakeSimulationLink extends PureComponent {
  state = {
    hardware_id: '',
    isNotSubmited: true,
  };
  myRef = React.createRef();

  componentDidMount() {
    if (this.textInput) {
      this.textInput.current.focus();
    }
  }

  register = () => {
    const data = {
      hardware_id: this.state.hardware_id,
      timestamp: new Date().getTime(),
    };
    this.props.takeSimulation(data).then(response => {
      if (response && response.type === ADD_HARDWARE_ID_RESULT) {
        this.setState({
          hardware_id: '',
          isNotSubmited: false,
        });
      }
    });
  };

  onChange = event => {
    this.setState({ [event.target.getAttribute('name')]: event.target.value });
  };

  render() {
    const { hardware_id, isNotSubmited } = this.state;
    const { errors } = this.props;

    let width = 0;

    if (hardware_id.length) {
      width = 100;
    }

    const style = { width: `${width}%` };
    return (
      <div>
        {isNotSubmited ? (
          <div className="caregiver-container">
            <div className="caregiver-header">
              <div className="caregiver-logo"></div>
              <div className="header-progress-bar mt-20">
                <div className={style !== '0%' ? 'active-progess' : ''} style={style}></div>
              </div>
            </div>
            <div className="body-content">
              <div className="content mt">
                <div className="text-container">
                  <div className="heading">{Strings.takeSimulation}</div>
                </div>
              </div>
              <div className="form-container mt">
                {errors ? <div className="cg-error">{errors.message}</div> : ''}
                <form className="accept-as-caregiver" onSubmit={e => e.preventDefault()}>
                  <div className="input-element">
                    {!hardware_id ? <div className="triangle first"></div> : ''}
                    <input
                      className="text"
                      onChange={this.onChange}
                      type="text"
                      name="hardware_id"
                      autoComplete="off"
                      value={hardware_id}
                      placeholder={Strings.deviceId}
                      ref={this.textInput}
                    />
                    {hardware_id ? <div className="verify first"></div> : ''}
                  </div>

                  {hardware_id.length ? (
                    <div className="input-element mt btn-verify">
                      <div className="triangle "></div>

                      <button className="" type="button" role="button" id="confirm" onClick={this.register}>
                        {Strings.confirm}
                      </button>
                    </div>
                  ) : (
                    ''
                  )}
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className="full-height2">
            <div className="caregiver-container2" ref={this.myRef}>
              <div className="caregiver-header2">
                <div className="success-logo"></div>
                <div className="header-progress-bar mt-20">
                  <div className="active-progess" style={style}></div>
                </div>
              </div>

              <div className="body-content2" style={{ height: '100vh' }}>
                <div className="content mt">
                  <div className="text-container">
                    <div className="heading">
                      {Strings.great}
                      <br />
                      {Strings.youAreAllDone}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.caregiver.loading,
    errors: state.entities.caps.errors || {},
  };
};

const mapDispatchToProps = dispatch => ({
  takeSimulation: data => dispatch(capsAction.addHardwareId(data)),
  onNavigate: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TakeSimulationLink);

TakeSimulationLink.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.object,
  takeSimulation: PropTypes.func.isRequired,
  onNavigate: PropTypes.func,
};
