/* eslint-disable react/no-string-refs */
/* eslint-disable class-methods-use-this */
import React, { PureComponent } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

export class AdherenceTableScrollbarsVertical extends PureComponent {
  state = {
    shadowTopOpacity: 0,
    shadowBottomOpacity: 0,
  };

  renderTrackHorizontal({ style, ...props }) {
    const finalStyle = { display: 'none !important' };
    return <div style={finalStyle} {...props} />;
  }

  handleUpdate = values => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const shadowTopOpacity = (1 / 20) * Math.min(scrollTop, 20);
    const bottomScrollTop = scrollHeight - clientHeight;
    const shadowBottomOpacity = (1 / 20) * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20));
    this.setState({
      shadowTopOpacity,
      shadowBottomOpacity,
    });
  };

  render() {
    const { shadowTopOpacity, shadowBottomOpacity } = this.state;

    return (
      <React.Fragment>
        <Scrollbars onUpdate={this.handleUpdate} renderTrackHorizontal={this.renderTrackHorizontal} {...this.props} />
        {shadowTopOpacity > 0 && <div className="shadow top" style={{ opacity: this.state.shadowTopOpacity }} />}
        {shadowBottomOpacity > 0 && (
          <div className="shadow bottom" style={{ opacity: this.state.shadowBottomOpacity }} />
        )}
      </React.Fragment>
    );
  }
}
