import { API_CALL } from '../../../../middleware/api';
import {
  DOWNLOAD_TASK_DATA,
  GET_DEVICES_FILTER,
  GET_DEVICE_EVENTS,
  GET_ORAGANIZATION_DEVICES_BILLING,
  GET_TASK_BILLING,
  GET_TASK_DETAILS,
  DOWNLOAD_BILLING_DETAILS_DATA,
  DOWNLOAD_TASK_AGGREGATED_DATA,
} from './constants';

const qs = require('qs');

export const actions = {
  getOrgnizationBilling: pageRequest => ({
    [API_CALL]: {
      endpoint: `/v1/organization/billing`,
      method: 'get',
      params: pageRequest,
    },
    type: GET_ORAGANIZATION_DEVICES_BILLING,
  }),

  getCareDxBillingTaskList: pageRequest => ({
    [API_CALL]: {
      endpoint: `/billing/codes/summary`,
      method: 'get',
      params: pageRequest,
    },
    type: GET_TASK_BILLING,
  }),

  getCareDxBillingDetails: pageRequest => ({
    [API_CALL]: {
      endpoint: `/billing/codes/detail`,
      method: 'get',
      params: pageRequest,
    },
    type: GET_TASK_DETAILS,
  }),

  getCareDxTaskDetails: pageRequest => ({
    [API_CALL]: {
      endpoint: `/billing/work_log`,
      method: 'get',
      params: pageRequest,
    },
    type: GET_TASK_DETAILS,
  }),

  getDeviceFilter: () => ({
    [API_CALL]: {
      endpoint: `/v1/organization/billing/filters`,
      method: 'get',
      params: pageRequest,
    },
    type: GET_DEVICES_FILTER,
  }),

  // This endpoint does not exist in backend
  getDeviceEvents: pageRequest => ({
    [API_CALL]: {
      endpoint: `/v1/caredx/device-events`,
      method: 'get',
      params: pageRequest,
    },
    type: GET_DEVICE_EVENTS,
  }),

  updateTaskStatus: data => ({
    [API_CALL]: {
      endpoint: `/billing/codes/edit`,
      method: 'put',
      data,
    },
    type: GET_DEVICES_FILTER,
    id: data.id,
  }),

  downloadBillingTaskData: request => ({
    [API_CALL]: {
      endpoint: `/billing/codes/report/summary`,
      method: 'get',
      params: request,
    },
    type: DOWNLOAD_TASK_DATA,
  }),

  downloadBillingSummaryData: request => ({
    [API_CALL]: {
      endpoint: '/billing/codes/report/aggregated',
      method: 'get',
      params: request,
    },
    type: DOWNLOAD_TASK_AGGREGATED_DATA,
  }),

  downloadBillingDetailsData: params => ({
    [API_CALL]: {
      endpoint: `billing/codes/report/detail`,
      method: 'get',
      params,
    },
    type: DOWNLOAD_BILLING_DETAILS_DATA,
  }),
};
