export const modalActionTypes = {
  open: 'modal-open',
  close: 'modal-close',
  updateTimestamp: 'modal-update-timestamp',
};

export const openModalAction = (id, data, modalId = null) => ({
  type: modalActionTypes.open,
  id,
  data,
  modalId,
});

export const openModal = (id, data = {}, modalId = null) => dispatch => dispatch(openModalAction(id, data, modalId));

const closeModalAction = (id, data, modalId) => ({
  type: modalActionTypes.close,
  id,
  data,
  modalId,
});

export const closeModal = (id, data = {}, modalId = null) => dispatch => dispatch(closeModalAction(id, data, modalId));

export const updateTimestamp = (id, modalId = null) => ({
  type: modalActionTypes.updateTimestamp,
  id,
  modalId,
});
