import { closeModal } from 'actions/modal';
import { Button, BUTTON_TYPE_BLUE, BUTTON_TYPE_WHITE } from 'components/Buttons/Button';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../containers';
import Strings from '../../Strings';
import styles from './style.module.scss';

class NewConfirmationModal extends PureComponent {
  static MODAL_NAME = 'new-confirmation-modal';

  static propTypes = {
    onOpen: PropTypes.func,
    onCancel: PropTypes.func,
    caption: PropTypes.string,
    data: PropTypes.shape({
      title: PropTypes.string,
      cancelText: PropTypes.string,
      confirmText: PropTypes.string,
      onCancel: PropTypes.func,
      onConfirm: PropTypes.func,
      noCloseAfterConfirm: PropTypes.bool,
      afterClose: PropTypes.func,
      afterOpen: PropTypes.func,
      isSubmitButtonDisabled: PropTypes.bool,
      submitButtonType: PropTypes.number,
    }),
  };

  state = {
    isSubmitButtonDisabled:
      typeof this.props.data.isSubmitButtonDisabled !== 'undefined' ? this.props.data.isSubmitButtonDisabled : false,
  };

  onConfirm = () => {
    const { data } = this.props;

    if (data.onConfirm) {
      data.onConfirm();
    }
    if (!data.noCloseAfterConfirm) {
      this.getCancelFunction()();
    }
  };

  componentDidMount() {
    const { data } = this.props;
    if (typeof data.afterOpen === 'function') {
      data.afterOpen(this);
    }
  }

  getCancelFunction = () => {
    const { onCancel, data } = this.props;
    return data.onCancel ?? onCancel;
  };

  render() {
    const { onOpen, caption, data, children, ...props } = this.props;
    const { isSubmitButtonDisabled } = this.state;

    const onCancel = this.getCancelFunction();

    return (
      <Modal onOpen={onOpen} name={NewConfirmationModal.MODAL_NAME} withoutContainer {...props}>
        <div className={styles.newModal}>
          <header>{data.title ? data.title : caption}</header>
          <section>
            <div className={styles.text}>{children ?? data.text}</div>

            <div className={styles.buttons}>
              <Button buttonType={BUTTON_TYPE_WHITE} onClick={onCancel}>
                {data.cancelText ? data.cancelText : Strings.cancel}
              </Button>
              <Button
                buttonType={data.submitButtonType ?? BUTTON_TYPE_BLUE}
                onClick={this.onConfirm}
                disabled={isSubmitButtonDisabled}
              >
                {data.confirmText ? data.confirmText : Strings.confirm}
              </Button>
            </div>
          </section>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({ onCancel: () => dispatch(closeModal(NewConfirmationModal.MODAL_NAME)) });

export default connect(null, mapDispatchToProps)(NewConfirmationModal);
