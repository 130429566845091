import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import Strings from '../Strings';
import './editStudyModal.scss';
import './dateRangeSelectionModal.scss';
import AdvancedFiltersInfo from '../components/AdvancedFilters/AdvancedFiltersInfo';
import Wizard from '../containers/Modal/Wizard';
import { DatePickerField, Input, RadioButton } from '../containers/Form';
import { getDateRangeForDownload } from './DateRangeSelectionModal';

function DateRangeSelectionForEventsReportsModal(props) {
  const { filters } = props.data;
  const defaultDateRange = getDateRangeForDownload(filters?.lastUpdatedStart, filters?.lastUpdatedEnd);

  const [startDate, setStartDate] = React.useState(defaultDateRange.startDate);
  const [endDate, setEndDate] = React.useState(defaultDateRange.endDate);
  const [selectedOption, setSelectedOption] = React.useState('events');
  const [validationErrors, setValidationErrors] = React.useState([]);
  const [highlightInvalidFields, setHighlightMissingFields] = React.useState(false);
  const [applyFilters, setApplyFilters] = React.useState(true);
  const [dateRangeAdjusted, setDateRangeAdjusted] = React.useState(defaultDateRange.startDateAdjusted);

  React.useEffect(() => {
    const errors = [];
    if (endDate.clone().endOf('day') < startDate) {
      errors.push({ property: 'startDate', errors: [Strings.startDateShouldBeBeforeEndDate], missing: false });
      errors.push({ property: 'endDate', errors: [Strings.startDateShouldBeBeforeEndDate], missing: false });
    } else if (endDate > startDate.clone().add(6, 'month')) {
      errors.push({ property: 'startDate', errors: [Strings.allowedDataRangeIs6Months], missing: false });
      errors.push({ property: 'endDate', errors: [Strings.allowedDataRangeIs6Months], missing: false });
    }
    setValidationErrors(errors);
  }, [startDate, endDate]);

  const onSubmit = () => {
    const request = {
      selectedOption,
    };
    if (selectedOption === 'details') {
      request.lastUpdatedStart = startDate.startOf('day').format();
      request.lastUpdatedEnd = endDate.endOf('day').format();
    } else {
      request.startDate = startDate.startOf('day').format();
      request.endDate = endDate.endOf('day').format();
    }

    if (applyFilters) {
      if (filters.search) {
        request.search = filters.search;
      }
      if (filters.filters) {
        request.filters = filters.filters;
      }
      if (filters.organizationId) {
        request.organizationId = filters.organizationId;
      }
    }
    props.data.actionOnDone(request);
  };

  const onValueChange = event => {
    setSelectedOption(event.target.value);
  };

  const WrappedDataPicker = ({ selected, onChange, propertyForError, label, calendarPlacement }) => (
    <DatePickerField
      className="drs-datepicker"
      selected={selected}
      onChange={onChange}
      maxDate={moment()}
      minDate={moment().subtract(12, 'years')}
      readOnly
      placeholderText="Please select date"
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      // this was causing the date picker to blur on select
      // dropdownMode="select"
      dateFormat="MM / DD / YYYY"
      label={label}
      popperPlacement={calendarPlacement}
      highlightInvalid={highlightInvalidFields}
      errorsForTooltip={validationErrors.find(v => v.property === propertyForError)?.errors || []}
    />
  );

  const DataTypeSelector = () => (
    <div className="grid-with-options">
      <div className="checkbox-container border">
        <RadioButton
          type="radio"
          name="Events"
          id="events"
          value="events"
          label="Raw events"
          containerClass="for-radio"
          checked={selectedOption === 'events'}
          onChange={onValueChange}
        />
      </div>
      <div className="checkbox-container border">
        <RadioButton
          type="radio"
          name="Devices details"
          id="details"
          value="details"
          label="Devices details"
          containerClass="for-radio"
          checked={selectedOption === 'details'}
          onChange={onValueChange}
        />
      </div>
      {props.data.simplified ? (
        <div className="checkbox-container border">
          <RadioButton
            type="radio"
            name="Human-readable vital readings"
            id="vitals"
            value="vitals"
            label="Readings"
            containerClass="for-radio"
            checked={selectedOption === 'vitals'}
            onChange={onValueChange}
          />
        </div>
      ) : null}
    </div>
  );

  const pages = [
    {
      id: 'dataDownloadFilterPage',
      title: Strings.downloadData,
      content: (
        <React.Fragment>
          <div className="horizontal-flex">
            <div className="start-date">
              <WrappedDataPicker
                selected={startDate}
                onChange={date => {
                  setStartDate(date.endOf('day'));
                  setDateRangeAdjusted(false);
                }}
                propertyForError="startDate"
                label={Strings.startDate}
                calendarPlacement="bottom-start"
              />
              {dateRangeAdjusted && (
                <React.Fragment>
                  <div
                    className="signal-alert"
                    data-tooltip-content={Strings.dateRangeAdjustedWarning}
                    data-tooltip-id="tooltip"
                  />
                </React.Fragment>
              )}
            </div>
            <WrappedDataPicker
              selected={endDate}
              onChange={date => {
                setEndDate(date.startOf('day'));
                setDateRangeAdjusted(false);
              }}
              propertyForError="endDate"
              label={Strings.endDate}
              calendarPlacement="bottom-end"
            />
          </div>
          {(filters?.search || filters?.organizationId || filters?.filters?.length > 0) && (
            <React.Fragment>
              <Input
                name="filters"
                id="filters"
                label={Strings.applyFilters}
                type="checkbox"
                checked={applyFilters}
                onChange={() => setApplyFilters(!applyFilters)}
                rowClassName="apply-filters-checkbox"
              />
              <div className="horizontal-rule" />
              {applyFilters && (
                <div className="filters-grid">
                  {filters?.search && <div className="bar-item">{`${Strings.search}: ${filters.search}`}</div>}
                  {filters?.organizationId && props.data?.orgName && (
                    <div className="bar-item">{`${Strings.organization}: ${props.data?.orgName}`}</div>
                  )}
                  {filters?.filters && <AdvancedFiltersInfo skipClearingOnExit />}
                </div>
              )}
            </React.Fragment>
          )}
          <DataTypeSelector />
          {!filters?.search && !filters?.filters?.length > 0 && !filters?.organizationId && (
            <div className="empty-space-for-calendar" />
          )}
        </React.Fragment>
      ),
      nextButton: { text: Strings.download },
      emptyFieldsCount: 0,
      canGoNext: validationErrors.length === 0,
    },
  ];

  return (
    <Wizard
      name="date-range-selection-for-events-reports-modal"
      pages={pages}
      onSubmit={onSubmit}
      onNextButtonHover={e => setHighlightMissingFields(e)}
    />
  );
}

export default DateRangeSelectionForEventsReportsModal;

DateRangeSelectionForEventsReportsModal.propTypes = {
  data: PropTypes.shape({
    filters: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      search: PropTypes.string,
      filters: PropTypes.array,
      organizationId: PropTypes.string,
    }),
    actionOnDone: PropTypes.func.isRequired,
    simplified: PropTypes.bool,
    orgName: PropTypes.string,
  }),
};
