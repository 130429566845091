import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import { Modal } from '../containers';
import Form, { Input } from '../containers/Form';
import { openModalAction, closeModal } from '../actions/modal';
import { resetForm } from '../actions/forms';
import { patientPortalActions } from '../actions/patient-portal';
import LoadingRenderer from '../components/LoadingRenderer';
import { weekDays } from '../utils';
import { clearEntitiesAction } from '../actions';
import Strings from '../Strings';
import { PATIENT_PORTAL_SAVE_SCHEDULE_ERROR, PATIENT_PORTAL_SAVE_SCHEDULE_RESULT } from '../actions/action-types';

class EditScheduleModal extends PureComponent {
  constructor(props) {
    super(props);

    const newSchedule = {
      name: '',
      dosage: '',
      days: {},
      daysCount: 0,
      days_of_week: 0b10000000,
      events: '',
      times: {},
    };

    this.state = {
      errors: [],
      schedule: this.props.data.schedule ? cloneDeep(this.props.data.schedule) : newSchedule,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onSwitchWeekDay = this.onSwitchWeekDay.bind(this);
    this.onDeleteTime = this.onDeleteTime.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
  }

  onSubmit() {
    const { schedule } = this.state;

    const validationErrors = [];

    if (!schedule.name.length) {
      validationErrors.push(Strings.errors.emptyName);
    }

    if (!schedule.dosage.length) {
      validationErrors.push(Strings.errors.emptyDosage);
    }

    if (!schedule.daysCount) {
      validationErrors.push(Strings.errors.selectOneDay);
    }

    if (!Object.values(schedule.times).length) {
      validationErrors.push(Strings.errors.addMedicationTime);
    }

    if (validationErrors.length) {
      this.setState({ errors: validationErrors });
      return;
    }

    this.props.onSubmit(schedule).then(response => {
      if (response.type === PATIENT_PORTAL_SAVE_SCHEDULE_ERROR && response.response.data) {
        const responseData = response.response.data;
        const errors = Object.keys(responseData).map(key => `${key}: ${responseData[key]}`);
        this.setState({ errors });
      }
      return response;
    });
  }

  onSwitchWeekDay(weekDay) {
    const { schedule } = this.state;

    schedule.days[weekDay.name] = !schedule.days[weekDay.name];
    schedule.daysCount = Object.values(schedule.days).filter(exists => exists).length;

    if (schedule.days[weekDay.name]) {
      schedule.days_of_week |= weekDay.mask; // eslint-disable-line no-bitwise
    } else {
      schedule.days_of_week &= ~weekDay.mask; // eslint-disable-line no-bitwise
    }

    this.setState({ schedule });
    this.forceUpdate();
  }

  onDeleteTime(medicationTime) {
    const { schedule } = this.state;

    if (medicationTime.offset in schedule.times) {
      delete schedule.times[medicationTime.offset];
    }

    schedule.events = Object.values(schedule.times)
      .map(time => time.offset)
      .sort()
      .join(',');

    this.setState({ schedule });
    this.forceUpdate();
  }

  onTextChange(event) {
    const { schedule } = this.state;

    schedule[event.target.getAttribute('name')] = event.target.value;

    this.setState({ schedule });
  }

  render() {
    const { onOpen, onCancel, saveScheduleLoading, data, ...props } = this.props;

    const { errors, schedule } = this.state;

    return (
      <Modal name="edit-schedule" onOpen={onOpen} {...props}>
        <LoadingRenderer loading={!!saveScheduleLoading}>
          <Form onSubmit={this.onSubmit} onCancel={onCancel} id="edit-schedule">
            <div className="schedule content-block" key="schedule-form">
              <div className="row title">
                <Input
                  name="name"
                  id="schedule-name"
                  label=""
                  placeholder={Strings.placeholder.medicationName}
                  type="text"
                  defaultValue={schedule.name}
                  onChange={this.onTextChange}
                />
              </div>
              <div className="row dosage">
                <Input
                  name="dosage"
                  id="schedule-dosage"
                  label=""
                  placeholder={Strings.placeholder.dosageName}
                  type="text"
                  defaultValue={schedule.dosage}
                  onChange={this.onTextChange}
                />
              </div>
              <div className="row days-of-week">
                <span className="label">{Strings.daysOfWeek}</span>
                <span className="value">
                  {schedule.daysCount} {schedule.daysCount !== 1 ? 'Days' : 'Day'}
                </span>
                <div className="week-days">
                  {Object.values(weekDays).map(weekDay => (
                    <div
                      key={weekDay.name}
                      className={`week-day ${schedule.days[weekDay.name] ? 'active' : ''}`}
                      onClick={() => this.onSwitchWeekDay(weekDay)}
                    >
                      {weekDay.shortName}
                    </div>
                  ))}
                </div>
              </div>
              <div className="row">
                <Input
                  name="add-new-time"
                  id="add-new-time"
                  label=""
                  placeholder={Strings.placeholder.newTimeZone}
                  type="text"
                  readOnly
                  onClick={() => this.props.onAddTime(schedule)}
                />
              </div>
              {Object.values(schedule.times).length ? (
                <div className="times">
                  {Object.values(schedule.times).map(time => (
                    <div className="time" key={time.offset}>
                      <div className="delete" onClick={() => this.onDeleteTime(time)} />
                      {time.label}
                    </div>
                  ))}
                </div>
              ) : null}
              <div className="clear" />
              <div id="errors" className="errors-block">
                {errors.join(' ')}
              </div>
              <div className="buttons" key="button-bar">
                <button className="brand-white-gray" key="cancel" type="cancel">
                  {Strings.cancel}
                </button>
                <button className="brand-blue" key="submit" type="submit">
                  {Strings.confirm}
                </button>
              </div>
            </div>
          </Form>
        </LoadingRenderer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({ saveScheduleLoading: state.entities.patientSchedules.saveScheduleLoading });

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('edit-schedule')),
  onSubmit: data =>
    dispatch(patientPortalActions.saveSchedule(data)).then(response => {
      if (response && response.type === PATIENT_PORTAL_SAVE_SCHEDULE_RESULT) {
        dispatch(closeModal('edit-schedule'));
        dispatch(clearEntitiesAction(['patientNotifications']));
      }

      return response;
    }),
  onOpen: () => dispatch(resetForm('edit-schedule', ownProps.data)),
  onAddTime: schedule => {
    dispatch(closeModal('edit-schedule'));
    dispatch(openModalAction('schedule-time-picker', { schedule }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditScheduleModal);
