import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { patientAction } from '../../../actions/patient';

const getAdherencePercentage = patient => {
  if (patient.analytics && patient.analytics) {
    return `${patient.analytics.adherence}%`;
  }
  return 'N/A';
};

const getAdherenceLine = adherence => {
  const average = getAdherencePercentage(adherence);
  const perfectHundredStyle = average === '100%' ? 'perfect' : 'active';
  const averageWidthStyle = {
    width: average,
  };
  return (
    <div>
      <div className="averageText">{average}</div>
      <div className="average">
        <div className={perfectHundredStyle} style={averageWidthStyle} />
      </div>
    </div>
  );
};

function Medications(props) {
  React.useEffect(() => {
    if (!props.dateFilter.infimum || !props.dateFilter.supremum) {
      return;
    }
    props.loadMedications(props.patientId, props.dateFilter.infimum, props.dateFilter.supremum);
    return () => props.clearMedicine();
  }, [props.dateFilter]);

  const { isLoading, medications } = props;

  return (
    <div>
      <Table
        name="Medications"
        data={medications ? (medications.medications ? medications.medications : medications) : []}
        isLoading={isLoading}
      >
        <Column key="name" title="Name" value={d => d.medicationName} />
        <Column
          key="adherence"
          title={Strings.adherence}
          value={d => (d.analytics && d.scheduleType !== 'as_needed' ? getAdherenceLine(d) : 'N/A')}
        />
        <Column
          key="missed"
          title={Strings.missed}
          value={d => (d.scheduleType !== 'as_needed' ? d.analytics.missed : 'N/A')}
        />
        <Column key="total" title={Strings.total} value={d => (isNaN(d.analytics.total) ? '-' : d.analytics.total)} />
      </Table>
    </div>
  );
}

const mapStateToProps = state => {
  const { schedules } = state.entities;
  return {
    readOnlyMode: state.auth.read_only,
    medications: schedules.schedules,
    isLoading: schedules.loading,
    isPreparing: schedules.preparing,
    dateFilter: state.filter.dateFilter,
  };
};

const mapDispatchToProps = dispatch => ({
  loadMedications: (patientId, startDate, endDate) =>
    dispatch(patientAction.actionSchedulesList(patientId, startDate, endDate, true)),
  clearMedicine: () => dispatch(patientAction.clearMedicine()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Medications);

Medications.propTypes = {
  isLoading: PropTypes.bool,
  isMounted: PropTypes.bool,
  medications: PropTypes.array,
  patientId: PropTypes.any,
  loadMedications: PropTypes.func.isRequired,
  dateFilter: PropTypes.object,
  clearMedicine: PropTypes.func.isRequired,
};
