import {
  GET_PATIENT_DASHBOARD,
  GET_PATIENT_DASHBOARD_ERROR,
  GET_PATIENT_DASHBOARD_RESULT,
} from '../actions/action-types';

const patientDashboardReducer = (state = {}, action) => {
  const { response } = action;

  switch (action.type) {
    case GET_PATIENT_DASHBOARD:
      return {
        ...state,
        patientDashboard: undefined,
        loading: true,
      };

    case GET_PATIENT_DASHBOARD_RESULT:
      return {
        ...state,
        patientDashboard: response,
        loading: false,
      };

    case GET_PATIENT_DASHBOARD_ERROR:
      return {
        ...state,
        patientDashboard: undefined,
        loading: false,
      };

    default:
      return state;
  }
};

export default patientDashboardReducer;
