import { openModalAction } from '../../actions/modal';
import { store } from '../../utils/store';
import { workflows } from '../_ModalsMetadata/ModalsMetadata';
import { actions } from '../../pages/SuperUser/Patients/redux/actions';
import { patientAction } from '../../actions/patient';
import { ADD_PATIENT, ENROLL_PATIENT } from '../../pages/SuperUser/Patients/redux/constants';
import { UPDATE_PATIENT_SCHEDULE } from '../../actions/action-types';

export function executePatientOnboarding(nextAction) {
  const modals = store?.getState().modalsVisibility.modalsVisibility.find(w => w.id === workflows.patientOnboarding.id)
    .modals;
  const modalsFiltered = modals?.filter(m => m.visible);

  const getNextModal = modalId => {
    const currentModalId = modalsFiltered.findIndex(m => m.id === modalId);
    if (currentModalId < modalsFiltered.length - 1) {
      const ret = { ...modalsFiltered[currentModalId + 1] };
      return ret.id;
    }
    return null;
  };

  const startAddPatient = callNext => {
    store?.dispatch(
      openModalAction('edit-rpm-patient', {
        action: actions.addPatient,
        actionType: ADD_PATIENT,
        workflow: 'patientOnboarding',
        onSuccess: patient => callNext('edit-rpm-patient', patient),
      }),
    );
  };

  const startAddSchedule = (patient, callNext) => {
    store?.dispatch(
      openModalAction('edit-rpm-schedule', {
        action: patientAction.actionUpdateSchedule,
        actionType: UPDATE_PATIENT_SCHEDULE,
        patient,
        workflow: 'patientOnboarding',
        onSuccess: () => callNext('edit-rpm-schedule', patient),
      }),
    );
  };

  const startEnrollment = (patient, callNext) => {
    store?.dispatch(
      openModalAction('enroll-patient', {
        action: actions.enrollPatient,
        actionType: ENROLL_PATIENT,
        patient,
        workflow: 'patientOnboarding',
        onSuccess: () => callNext('enroll-patient', patient),
      }),
    );
  };

  const startNew = (id, patient, callNext) => {
    switch (id) {
      case 'edit-rpm-patient':
        startAddPatient(callNext);
        break;
      case 'edit-rpm-schedule':
        startAddSchedule(patient, callNext);
        break;
      case 'bulk-add-patients':
        store?.dispatch(openModalAction('bulk-add-patients'));
        break;
      default:
        break;
    }
  };

  const showSummary = (patient, callNext) => {
    store?.dispatch(
      openModalAction('you-are-all-done', {
        patient,
        workflow: 'patientOnboarding',
        onOptionClicked: modal => startNew(modal, patient, callNext),
      }),
    );
  };

  const onSuccess = (modalId, patient) => {
    if (nextAction) {
      store?.dispatch(nextAction);
    }
    const modalToOpen = getNextModal(modalId);
    if (modalToOpen) {
      switch (modalToOpen) {
        case 'edit-rpm-patient':
          startAddPatient(onSuccess);
          break;
        case 'enroll-patient':
          startEnrollment(patient, onSuccess);
          break;
        case 'edit-rpm-schedule':
          startAddSchedule(patient, onSuccess);
          break;
        case 'you-are-all-done':
          showSummary(patient, onSuccess);
          break;
        default:
          break;
      }
    }
  };

  startAddPatient(onSuccess);
}
