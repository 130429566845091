import PropTypes from 'prop-types';
import { openModalAction } from 'actions/modal';
import { patientAction } from 'actions/patient';
import { PageHeader } from 'components/PageHeader';
import { TextInput } from 'components/PageHeader/TextInput';

import _ from 'lodash';
import { actions as invitesAction } from 'modals/AddESIPatientModal/redux/actions';
import { ESI_INVITE_PATIENT, ESI_PATIENTS_EDIT } from 'modals/AddESIPatientModal/redux/constants';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { capsAction } from '../../../actions/caps';
import { DATE_MONTH_DAY_YEAR, PAGE_LIMIT } from '../../../constants';
import Table, { Column } from '../../../containers/Table/TableWithPagination';
import Strings from '../../../Strings';
import { getAdherencePercentage } from '../../../utils';
import { actions } from './redux/actions';
import { Button } from '../../../components/PageHeader/Button';

const mapStateToProps = state => {
  const { list } = state.esi.patient;

  const pagination = list?.data?.pagination ?? {};
  if (!pagination.offset) pagination.offset = 0;

  return {
    data: list?.data?.data ?? {},
    needRefresh: list?.needRefresh === true,
    pagination,
    isLoading: !(list?.isLoading === false),
    readOnlyMode: state.auth.profile ? state.auth.profile.read_only : true,
    organization: state.auth.profile?.organization,
  };
};

const mapDispatchToProps = dispatch => ({
  loadList: request => dispatch(actions.list(request)),
  patientsLoadComplete: () => dispatch(patientAction.patientsLoadComplete()),
  clearMedicine: () => dispatch(patientAction.clearMedicine()),
  clearList: () => dispatch(actions.clearList()),
  clearHistory: () => dispatch(patientAction.clearHistory()),
  clearPatientInfo: () => dispatch(actions.clearPatientInfo()),
  onNavigate: path => dispatch(push(path)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  EditPatient: id =>
    dispatch(
      openModalAction('add-esi-patient', {
        action: actions.editPatient,
        actionType: ESI_PATIENTS_EDIT,
        id,
      }),
    ),
  onNewPatient: () =>
    dispatch(
      openModalAction('add-esi-patient', {
        action: invitesAction.invitePatient,
        actionType: ESI_INVITE_PATIENT,
      }),
    ),
});

class ActivePatients extends PureComponent {
  request = {
    offset: 0,
    limit: PAGE_LIMIT,
    search: null,
    type: 'active',
  };
  refreshEnable = true;

  state = {
    filters: [],
    keyForTable: true,
    excludedColumns: [],
  };

  loadData() {
    this.props.clearMedicine();
    return this.props.loadList(this.request);
  }

  componentDidMount() {
    this.loadData();
    this.props.clearMedicine();
  }

  componentWillUnmount() {
    this.props.clearList();
    this.props.clearPatientInfo();
    this.props.clearHistory();
  }

  onPatientDelete = (event, patient) => {
    event.preventDefault();
    event.stopPropagation();
    const data = {
      title: (
        <span>
          {Strings.deletePatientWarning} <b>{patient.fullName}</b>?
        </span>
      ),

      onConfirmAction: capsAction.deleteCapPatient(patient.id, patient.masked_id, [
        actions.clearList(),
        actions.list({
          ...this.request,
          limit: PAGE_LIMIT,
        }),
      ]),
      onCancelAction: null,
    };
    this.props.openConfirmModal(data);
  };

  onPatientEdit = (event, patient) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.EditPatient(patient.id);
  };

  onSearchQueryChange = query => {
    return this.onSearchQueryChangeDebounced(query);
  };

  onSearchQueryChangeDebounced = _.debounce(query => {
    this.props.clearList();
    this.request.offset = 0;
    this.request.search = query;
    this.loadData();
  }, 1000);

  onRowSelected = id => {
    const { data } = this.props;
    if (data && data[id].id) {
      this.props.onNavigate(`/esi_patients/${data[id].id}/info`);
    }
  };

  onPrevClick = () => {
    const { pagination } = this.props;
    this.request.offset = pagination.offset - PAGE_LIMIT;
    this.loadData();
  };

  onNextClick = () => {
    const { pagination } = this.props;
    this.request.offset = pagination.offset + PAGE_LIMIT;
    this.loadData();
  };

  onCustomPage = page => {
    this.request.offset = (page - 1) * PAGE_LIMIT;
    this.loadData();
  };

  onSortClick = ({ sortKey, direction }) => {
    this.request.offset = 0;
    this.request.sortColumn = sortKey;
    this.request.sortType = direction;
    this.loadData();
  };

  enrollmentDate = date => {
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return date ? `${month[date.month - 1]}-${date.date}-${date.year}` : '-';
  };

  onTextInputChange = e => {
    this.onSearchQueryChange(e.target.value);
  };

  onRefresh = () => {
    this.refreshEnable = false;
    this.turnOffTimeout = setTimeout(() => {
      this.refreshEnable = true;
      this.forceUpdate();
    }, 10000);
    this.loadData();
  };

  getHeaderComponents() {
    return (
      <React.Fragment>
        <TextInput class="search" placeholder={Strings.search} onChange={this.onTextInputChange} />
        <Button class="refresh" disabled={!this.refreshEnable} onClick={this.onRefresh} />
      </React.Fragment>
    );
  }

  render() {
    const { data, isLoading, pagination } = this.props;

    const getAdherenceLine = adherence => {
      const average = getAdherencePercentage(adherence);
      const perfectHundredStyle = average === '100%' ? 'perfect' : 'active';
      const averageWidthStyle = { width: average };
      return (
        <div>
          <div className="averageText">{adherence}%</div>
          <div className="average">
            <div className={perfectHundredStyle} style={averageWidthStyle} />
          </div>
        </div>
      );
    };

    const columns = [];
    columns.push(<Column key="name" title={Strings.name} value={d => (d.name ? d.name.trim() : d.username)} />);
    columns.push(<Column key="agn" title={Strings.AGN} value={d => (d.agn ? d.agn.trim() : '-')} />);
    columns.push(
      <Column key="phone" sortKey="phone" title={Strings.phoneNumber} value={d => (d.phone ? d.phone.trim() : '-')} />,
    );
    columns.push(<Column key="hardwareId" sortKey="cap_id" title={Strings.deviceId} value={d => d.cap_id} />);
    columns.push(<Column key="qrcode" title={Strings.qrCode} value={d => d.qrcode || '-'} />);
    columns.push(
      <Column
        key="adherence"
        sortKey="adherence"
        title={Strings.adherence}
        value={d => getAdherenceLine(d.adherence)}
      />,
    );
    columns.push(
      <Column
        key="enrollment_date"
        title={Strings.enrollmentDate}
        value={d => this.enrollmentDate(d.enrollment_date)}
      />,
    );

    columns.push(
      <Column
        key="registered_date"
        sortKey="registered_date"
        title={Strings.registeredDate}
        value={d => (d.registered_date ? moment(d.registered_date).format(DATE_MONTH_DAY_YEAR) : '-')}
      />,
    );

    columns.push(
      <Column
        key="activation_date"
        sortKey="activation_date"
        title={Strings.esi.activatedDate}
        value={d => (d.activation_date ? moment(d.activation_date).format(DATE_MONTH_DAY_YEAR) : '-')}
      />,
    );

    return (
      <div>
        <PageHeader right={() => this.getHeaderComponents()} />
        <Table
          name="esi-patient-table"
          uuid="e73c1778-254e-4c44-abf3-69da3790bc8b"
          data={data}
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          onCustomPage={this.onCustomPage}
          onRowSelection={this.onRowSelected}
          onSortClick={this.onSortClick}
          pagination={pagination}
          isLoading={isLoading}
          enableColumnFiltering
        >
          {columns}
        </Table>
      </div>
    );
  }
}

ActivePatients.propTypes = {
  EditPatient: PropTypes.func,
  clearHistory: PropTypes.func,
  clearList: PropTypes.func,
  clearMedicine: PropTypes.func,
  clearPatientInfo: PropTypes.func,
  data: PropTypes.any,
  isLoading: PropTypes.any,
  loadList: PropTypes.func,
  onNavigate: PropTypes.func,
  onNewPatient: PropTypes.func,
  openConfirmModal: PropTypes.func,
  organization: PropTypes.shape({ name: PropTypes.string }),
  pagination: PropTypes.shape({ offset: PropTypes.any }),
  readOnlyMode: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivePatients);
