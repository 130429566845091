import { API_CALL } from '../../../../middleware/api';

export const GET_CAREGIVERS_LIST = 'get-caregivers-list';
export const GET_CAREGIVERS_LIST_RESULT = `${GET_CAREGIVERS_LIST}/result`;
export const GET_CAREGIVERS_LIST_ERROR = `${GET_CAREGIVERS_LIST}/error`;

export const ADD_CAREGIVER = 'add-caregiver-rpm';
export const ADD_CAREGIVER_RESULT = `${ADD_CAREGIVER}/result`;
export const ADD_CAREGIVER_ERROR = `${ADD_CAREGIVER}/error`;

export const DELETE_CAREGIVER = 'delete-caregiver-rpm';
export const DELETE_CAREGIVER_RESULT = `${DELETE_CAREGIVER}/result`;
export const DELETE_CAREGIVER_ERROR = `${DELETE_CAREGIVER}/error`;

export const caregiverActions = {
  getCaregivers: patientId => ({
    [API_CALL]: {
      endpoint: `/patients/patient/${patientId}/caregivers`,
      method: 'GET',
    },
    type: GET_CAREGIVERS_LIST,
  }),

  addCaregiver: (patientId, data) => ({
    [API_CALL]: {
      endpoint: `/patients/patient/${patientId}/caregivers`,
      method: 'POST',
      data,
    },
    type: ADD_CAREGIVER,
  }),

  deleteCaregiver: (patientId, caregiverId) => ({
    [API_CALL]: {
      endpoint: `/patients/patient/${patientId}/caregivers/${caregiverId}/`,
      method: 'DELETE',
    },
    type: DELETE_CAREGIVER,
  }),
};
