import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import Strings from '../Strings';
import './editStudyModal.scss';
import './dateRangeSelectionModal.scss';
import Wizard from '../containers/Modal/Wizard';
import { DatePickerField, SelectField } from '../containers/Form';
import { MONTHS_RANGE } from '../constants';
import { closeModal } from '../actions/modal';

export const dateRangeModes = {
  customDates: 'customDates',
  months: 'months',
};

export const getDateRangeForDownload = (startDate, endDate, mode = dateRangeModes.customDates) => {
  if (mode === dateRangeModes.months) {
    return {
      startDate: moment()
        .subtract(1, 'months')
        .startOf('month'),
      endDate: moment()
        .subtract(1, 'months')
        .endOf('month'),
    };
  }
  if (!startDate || !endDate) {
    return {
      startDate: moment().subtract(6, 'months'),
      endDate: moment(),
    };
  }
  const start = moment(startDate);
  const end = moment(endDate);
  if (end <= start.clone().add(6, 'month')) {
    return {
      startDate: start.startOf('day'),
      endDate: end.endOf('day'),
    };
  }
  return {
    startDate: end.clone().subtract(6, 'months'),
    endDate: end,
    startDateAdjusted: true,
  };
};

function DateRangeSelectionModal(props) {
  const defaultDateRange = getDateRangeForDownload(props.data?.startDate, props.data?.endDate, props.data?.mode);
  const [startDate, setStartDate] = React.useState(defaultDateRange.startDate);
  const [endDate, setEndDate] = React.useState(defaultDateRange.endDate);
  const [validationErrors, setValidationErrors] = React.useState([]);
  const [highlightInvalidFields, setHighlightMissingFields] = React.useState(false);
  const [dateRangeAdjusted, setDateRangeAdjusted] = React.useState(defaultDateRange.startDateAdjusted);

  React.useEffect(() => {
    const errors = [];
    if (endDate.clone().endOf('day') < startDate) {
      errors.push({ property: 'startDate', errors: [Strings.startDateShouldBeBeforeEndDate], missing: false });
      errors.push({ property: 'endDate', errors: [Strings.startDateShouldBeBeforeEndDate], missing: false });
    } else if (endDate > startDate.clone().add(6, 'month')) {
      errors.push({ property: 'startDate', errors: [Strings.allowedDataRangeIs6Months], missing: false });
      errors.push({ property: 'endDate', errors: [Strings.allowedDataRangeIs6Months], missing: false });
    }
    setValidationErrors(errors);
  }, [startDate, endDate]);

  const onDateChange = (month, year) => {
    setStartDate(moment({ year, month, day: 1 }).startOf('month'));
    setEndDate(moment({ year, month, day: 1 }).endOf('month'));
  };

  const onSubmit = () => {
    const request = {
      startDate: startDate.startOf('day').unix(),
      endDate: endDate.endOf('day').unix(),
    };
    props.data.actionOnDone(request);
    props.onClose();
  };

  const WrappedDataPicker = ({ selected, onChange, propertyForError, label, calendarPlacement }) => (
    <DatePickerField
      className="drs-datepicker"
      selected={selected}
      onChange={onChange}
      maxDate={moment()}
      minDate={moment().subtract(12, 'years')}
      readOnly
      placeholderText="Please select date"
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      // this was causing the date picker to blur on select
      // dropdownMode="select"
      dateFormat="MM / DD / YYYY"
      label={label}
      popperPlacement={calendarPlacement}
      highlightInvalid={highlightInvalidFields}
      errorsForTooltip={validationErrors.find(v => v.property === propertyForError)?.errors || []}
    />
  );

  const pages = [
    {
      id: 'dataDownloadDateRangePage',
      title: props.data.title || Strings.dateRange,
      content:
        props.data.mode === dateRangeModes.months ? (
          <div className="drs-input-group center">
            <SelectField
              name="month"
              id="month"
              label={Strings.capPatient.month}
              placeholder={Strings.capPatient.month}
              value={startDate.months()}
              onChange={e => onDateChange(e.value, startDate.year())}
              data={Array.from({ length: 12 }, (_, i) => i).map(i => {
                return {
                  value: i,
                  label: MONTHS_RANGE[i],
                  isDisabled: moment().year() === startDate.year() && i > moment().month(),
                };
              })}
              isRequired
              highlightInvalid={highlightInvalidFields}
            />
            <SelectField
              name="year"
              id="year"
              label={Strings.year}
              placeholder={Strings.year}
              value={startDate.year()}
              onChange={e => onDateChange(startDate.months(), e.value)}
              data={Array.from({ length: 12 }, (_, i) => i).map(i => {
                return {
                  value: moment().year() - i,
                  label: moment().year() - i,
                  isDisabled: startDate.month() > moment().month() && moment().year() - i >= moment().year(),
                };
              })}
              isRequired
              highlightInvalid={highlightInvalidFields}
            />
          </div>
        ) : (
          <div className="horizontal-flex">
            <div className="start-date">
              <WrappedDataPicker
                selected={startDate}
                onChange={date => {
                  setStartDate(date.endOf('day'));
                  setDateRangeAdjusted(false);
                }}
                propertyForError="startDate"
                label={Strings.startDate}
                calendarPlacement="bottom-start"
              />
              {dateRangeAdjusted && (
                <React.Fragment>
                  <div
                    className="signal-alert"
                    data-tooltip-content={Strings.dateRangeAdjustedWarning}
                    data-tooltip-id="tooltip"
                  />
                </React.Fragment>
              )}
            </div>
            <WrappedDataPicker
              selected={endDate}
              onChange={date => {
                setEndDate(date.startOf('day'));
                setDateRangeAdjusted(false);
              }}
              propertyForError="endDate"
              label={Strings.endDate}
              calendarPlacement="bottom-end"
            />
          </div>
        ),
      nextButton: { text: props.data.submitButtonText || Strings.submit },
      emptyFieldsCount: 0,
      canGoNext: validationErrors.length === 0,
    },
  ];

  return (
    <Wizard
      name="data-range-selection-modalion"
      pages={pages}
      onSubmit={onSubmit}
      onNextButtonHover={e => setHighlightMissingFields(e)}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeModal('data-range-selection-modalion')),
});

export default connect(null, mapDispatchToProps)(DateRangeSelectionModal);

DateRangeSelectionModal.propTypes = {
  data: PropTypes.shape({
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    actionOnDone: PropTypes.func.isRequired,
    title: PropTypes.string,
    submitButtonText: PropTypes.string,
  }).isRequired,
};

DateRangeSelectionModal.defaultProps = {
  data: {
    mode: dateRangeModes.customDates,
  },
};
