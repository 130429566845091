import PropTypes from 'prop-types';
import { Background } from 'components/Login/Background/Background';

import Step1_EnterPhoneNumber from 'pages/ESI/InviteDoctor/Steps/Step1_EnterPhoneNumber';
import Step2_EnterVerificationCode from 'pages/ESI/InviteDoctor/Steps/Step2_EnterVerificationCode';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Strings from 'Strings';
import { getMobileOperatingSystem } from 'utils';

import { BASE_NAME } from '../../../constants';
import './PatientLogin.scss';
import { actions } from './redux/actions';
import {
  CAP_SET_BY_SMS,
  ESI_IS_CAP_ID_SET,
  ESI_IS_PORTAL_TUTORIAL_VIEWED,
  ESI_IS_TC_AGREE,
  ESI_IS_TIMEZONE_SET,
  ESI_PATIENT_LOGIN_CHECK_CODE_RESULT,
  ESI_PATIENT_LOGIN_CONFIG_RESULT,
  ESI_PATIENT_LOGIN_RESEND_CODE_RESULT,
  ESI_PATIENT_LOGIN_SEND_CODE_RESULT,
} from './redux/constants';
import { Step3_AcceptTerms } from './Steps/Step3_AcceptTerms';
import { Step4_ActivateYourDevice } from './Steps/Step4_ActivateYourDevice';
import Step5_Timezone from './Steps/Step5_timezone';
import { Step6_Video } from './Steps/Step6_Video';
import { LS_LANGUAGE } from '../../../utils/language';
import { setTheme } from '../../../utils/colorTheme';
import { setBrand } from '../../../utils/brand';

const mapStateToProps = state => {
  return {
    data: state.esi.patient.login,
    authConfig: state.auth.config,
  };
};

const mapDispatchToProps = dispatch => ({
  sendCode: phone => dispatch(actions.sendCode(phone)),
  resendCode: phone => dispatch(actions.resendCode(phone)),
  verifyCode: (phone, code) => dispatch(actions.verifyCode(phone, code)),
  updateConfig: config => dispatch(actions.updateConfig(config)),
  updateCapId: cap_id => dispatch(actions.updateCapId(cap_id)),
});

class ESIPatientLogin extends PureComponent {
  getLanguageFromLS() {
    let language = Strings.getLanguage();
    try {
      languageFromLocalStorage = localStorage.getItem(LS_LANGUAGE);
      if (languageFromLocalStorage) language = languageFromLocalStorage;
    } catch (e) {}
    return language;
  }
  language = this.getLanguageFromLS();
  steps = [
    {
      component: Step1_EnterPhoneNumber,
      title() {
        return (
          <React.Fragment>
            <div style={{ display: 'inline' }}>{Strings.login}</div>
            {/* <div style={{ color: '#14568d', display: 'inline' }}>{Strings.lidSync}</div> */}
          </React.Fragment>
        );
      },
    },
    {
      component: Step2_EnterVerificationCode,
      title() {
        return Strings.verificationCode;
      },
      props: {
        text: Strings.esiPatientLoginStep2,
        isEsi: true,
      },
    },
    {
      component: Step3_AcceptTerms,
      title() {
        return Strings.yourTrustIsImportantToUs;
      },
    },
    {
      component: Step4_ActivateYourDevice,
      title() {
        return Strings.activateYourDeviceTitle;
      },
    },
    {
      component: Step5_Timezone,
      title() {
        return Strings.setYourTimezone;
      },
    },
    {
      component: Step6_Video,
      title() {
        return Strings.yourAllDone;
      },
      props: { icon: true },
    },
  ];

  state = {
    step: 0,
    stepProps: {},
  };

  componentDidMount() {
    setTheme('white');
    setBrand('esi');
    const favicon = document.getElementById('favicon');
    favicon.href = 'faviconLidSync.ico';
    this.updateStepProps();
    Strings.setLanguage(this.language);
    if (localStorage) {
      try {
        localStorage.setItem(LS_LANGUAGE, this.language);
      } catch (e) {}
    }
  }

  updateStepProps() {
    this.setState({ stepProps: this.steps[this.state.step].props || {} });
    this.forceUpdate(); // Forcing render to update timer after resend action
  }

  // User phone to send it in the step 2
  phone;

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (data && data.isLoading === false && prevProps.data && prevProps.data.isLoading === true) {
      if (data.errorMessage) {
        this.setError(data.errorMessage.title, data.errorMessage.text);
      }
    }
  }

  incStep(count) {
    this.setState(prevState => ({ step: prevState.step + count }));
    this.updateStepProps();
  }

  setStep(count) {
    this.setState(() => ({ step: count }));
    this.updateStepProps();
  }

  resendCode() {
    return this.props.resendCode(this.phone);
  }

  verifyCode(code) {
    return this.props.verifyCode(this.phone, code);
  }

  login() {
    sessionStorage.setItem('authentication_token', this.token);
    sessionStorage.setItem('version', this.version);
    window.location = `${BASE_NAME}/user-dashboard`;
  }

  setTerms(authConfig) {
    if (!authConfig || (Object.keys(authConfig).length === 0 && authConfig.constructor === Object)) {
      this.incStep(1);
    } else if (authConfig[ESI_IS_TC_AGREE]) {
      if (!authConfig[ESI_IS_TC_AGREE]) {
        this.setStep(2);
      } else if (!authConfig[ESI_IS_CAP_ID_SET] && !authConfig[CAP_SET_BY_SMS]) {
        this.setStep(3);
      } else if (!authConfig[ESI_IS_TIMEZONE_SET]) {
        this.setStep(4);
      } else {
        this.login();
      }
    } else {
      this.login();
    }
  }

  onFormSubmit = data => {
    const { step } = this.state;

    this.clearError();

    switch (step) {
      case 0: {
        const { phone } = data;
        this.phone = phone;
        this.props.sendCode(phone).then(r => {
          if (r.type === ESI_PATIENT_LOGIN_SEND_CODE_RESULT) {
            this.firstLogin = r.response.first_login;
            sessionStorage.setItem('first_login', this.firstLogin);
            this.steps[1].props.codeExpiresAt = r.response.code_expires_at;
            this.incStep(1);
          }
        });
        break;
      }
      case 1: {
        const { code } = data;
        this.verifyCode(code).then(r => {
          if (r.type === ESI_PATIENT_LOGIN_CHECK_CODE_RESULT) {
            const token = r.response.authentication_token;

            if (token) {
              this.token = token;
              this.version = r.response.version;
              sessionStorage.setItem('authentication_token', this.token);
              sessionStorage.setItem('version', this.version);
              sessionStorage.setItem('org', r.response?.organization?.name);
              const { authConfig } = this.props;
              this.setTerms(authConfig);
            }
          }
        });
        break;
      }
      case 2: {
        const { docs } = data;
        const tcConfig = {
          key: ESI_IS_TC_AGREE,
          value: docs,
        };

        this.props.updateConfig(tcConfig).then(r => {
          if (r.type === ESI_PATIENT_LOGIN_CONFIG_RESULT) {
            const { authConfig } = this.props;
            this.setTerms(authConfig);
          }
        });
        break;
      }
      case 3: {
        // const { capId } = data;
        // const capConfig = {
        //   key: ESI_IS_CAP_ID_SET,
        //   value: capId,
        // };
        // this.props.updateCapId(capId).then(r => {
        //   if (r.type === ESI_PATIENT_CAP_ID_UPDATE_RESULT) {
        //     this.props.updateConfig(capConfig).then(r => {
        //       if (r.type === ESI_PATIENT_LOGIN_CONFIG_RESULT) {
        //         const { authConfig } = this.props;
        //         this.setTerms(authConfig);
        //       }
        //     });
        //   }
        // });
        break;
      }

      case 4: {
        const { timezone } = data;
        const timezonConfig = {
          key: ESI_IS_TIMEZONE_SET,
          value: timezone,
        };
        this.props.updateConfig(timezonConfig).then(r => {
          if (r.type === ESI_PATIENT_LOGIN_CONFIG_RESULT) {
            const { authConfig } = this.props;
            this.setTerms(authConfig);
          }
        });
        break;
      }
      case 5: {
        const { video } = data;
        const videoConfig = {
          key: ESI_IS_PORTAL_TUTORIAL_VIEWED,
          value: video,
        };
        this.props.updateConfig(videoConfig).then(r => {
          if (r.type === ESI_PATIENT_LOGIN_CONFIG_RESULT) {
            this.login();
          }
        });
        break;
      }

      default: {
        break;
      }
    }
  };

  onResendCode = event => {
    event.preventDefault();
    this.resendCode().then(r => {
      if (r.type === ESI_PATIENT_LOGIN_RESEND_CODE_RESULT) {
        this.steps[1].props.codeExpiresAt = r.response.code_expires_at;
        this.updateStepProps();
      }
    });
  };

  setError(title, text) {
    this.setState({
      error: text
        ? {
            title,
            text,
          }
        : title,
    });
  }

  clearError() {
    this.setState({ error: null });
  }

  render() {
    const { step, error } = this.state;
    const isLoading = this.props.data ? this.props.data.isLoading : false;

    const currentStep = this.steps[step];
    const StepComponent = currentStep.component;
    const progress = ((step + 1) / this.steps.length) * 100;

    const stepProps = this.state.stepProps;

    let prefix = '/portal';
    if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'stage' || process.env.NODE_ENV == 'int')
      prefix = '';

    const footer = (
      <React.Fragment>
        <span style={{ color: '#14568d' }}>{Strings.lidSync}&#8480;</span> {new Date().getFullYear()} - All copyrights
        reserved -{' '}
        <a target="_self" href={`${prefix}/esi-patient-privacy`} className="blueLink">
          Privacy Policy
        </a>{' '}
        -{' '}
        <a target="_self" href={`${prefix}/esi-patient-terms`} className="blueLink">
          Terms & Conditions
        </a>
      </React.Fragment>
    );

    return (
      <Background
        footer={footer}
        isWhite
        title={currentStep.title()}
        progress={progress}
        isError={error}
        isEsi
        icon={currentStep.props?.icon}
        noLines
      >
        {getMobileOperatingSystem() && <div className="toast-mobile show">{Strings.mobileVersionNotSupported}</div>}
        <StepComponent
          onFormSubmit={this.onFormSubmit}
          onResendCode={this.onResendCode}
          error={this.state.error}
          isLoading={isLoading}
          {...stepProps}
        />
      </Background>
    );
  }
}

ESIPatientLogin.propTypes = {
  authConfig: PropTypes.any,
  data: PropTypes.shape({
    errorMessage: PropTypes.shape({
      text: PropTypes.any,
      title: PropTypes.any,
    }),
    isLoading: PropTypes.bool,
  }),
  getCountries: PropTypes.func,
  resendCode: PropTypes.func,
  sendCode: PropTypes.func,
  updateConfig: PropTypes.func,
  verifyCode: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ESIPatientLogin);
