import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment-timezone';

import { actions } from './redux/actions';
import '../Widgets.scss';
import Strings from '../../../../Strings';

function PatientsWithNoReadings(props) {
  const days = props.config?.days?.value || props.config?.days?.defaultValue;
  React.useEffect(() => {
    props.getData(
      moment()
        .subtract(days, 'day')
        .startOf('day')
        .format(),
    );
  }, [props.config?.days]);

  const { isLoading, patientsWithNoReadings } = props;
  return (
    <React.Fragment>
      {!isLoading && (
        <div className="boxWithVl-box">
          <div className="boxWithVl-valueBox black">{patientsWithNoReadings}</div>
          <div
            onClick={() => props.onClick(`/cap-patients/list?filters=["NoReadingsSince:${days}"]`)}
            className="boxWithVl-labelBox clickable"
          >
            {Strings.widgets.patientsWithNoReadings}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

PatientsWithNoReadings.widgetId = 'PatientsWithNoReadingsWidget';

PatientsWithNoReadings.propTypes = {
  getData: PropTypes.func,
  patientsWithNoReadings: PropTypes.any,
  isLoading: PropTypes.any,
  onClick: PropTypes.func,
  dashboardId: PropTypes.string,
  config: PropTypes.object,
};

const mapStateToProps = state => {
  const { widgetPatientsWithNoReadingsReducer } = state.dashboardWidgets;

  return {
    patientsWithNoReadings:
      widgetPatientsWithNoReadingsReducer && widgetPatientsWithNoReadingsReducer?.patientsWithNoReadings,
    isLoading: widgetPatientsWithNoReadingsReducer && widgetPatientsWithNoReadingsReducer?.isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  getData: days => dispatch(actions.getPatientsWithNoReadings(days)),
  onClick: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientsWithNoReadings);
