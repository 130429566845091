export const GET_ORAGANIZATION = 'get-organizations';
export const GET_ORAGANIZATION_RESULT = `${GET_ORAGANIZATION}/result`;
export const GET_ORAGANIZATION_ERROR = `${GET_ORAGANIZATION}/error`;

export const GET_ORAGANIZATION_REPORT = 'get-organizations-report';
export const GET_ORAGANIZATION_REPORT_RESULT = `${GET_ORAGANIZATION_REPORT}/result`;
export const GET_ORAGANIZATION_REPORT_ERROR = `${GET_ORAGANIZATION_REPORT}/error`;

export const DELETE_ORGANIZATION = 'delete-organizations';
export const DELETE_ORGANIZATION_RESULT = `${DELETE_ORGANIZATION}/result`;
export const DELETE_ORGANIZATION_ERROR = `${DELETE_ORGANIZATION}/error`;

export const GET_ORAGANIZATION_OUTBOUND_API = 'get-organizations-outbound-api';
export const GET_ORAGANIZATION_OUTBOUND_API_RESULT = `${GET_ORAGANIZATION_OUTBOUND_API}/result`;
export const GET_ORAGANIZATION_OUTBOUND_API_ERROR = `${GET_ORAGANIZATION_OUTBOUND_API}/error`;

export const GET_ALL_PERMISSION = 'get-permission';
export const GET_ALL_PERMISSION_RESULT = `${GET_ALL_PERMISSION}/result`;
export const GET_ALL_PERMISSION_ERROR = `${GET_ALL_PERMISSION}/error`;

export const GET_ROLES_DETAIL = 'get-config-roles';
export const GET_ROLES_DETAIL_RESULT = `${GET_ROLES_DETAIL}/result`;
export const GET_ROLES_DETAIL_ERROR = `${GET_ROLES_DETAIL}/error`;

export const GET_ORG_ROLES_DETAIL = 'get-config-org-role-permission';
export const GET_ORG_ROLES_DETAIL_RESULT = `${GET_ORG_ROLES_DETAIL}/result`;
export const GET_ORG_ROLES_DETAIL_ERROR = `${GET_ORG_ROLES_DETAIL}/error`;

export const UPDATE_ORG_ROLES_DETAIL = 'update-config-org-role-permission';
export const UPDATE_ORG_ROLES_DETAIL_RESULT = `${UPDATE_ORG_ROLES_DETAIL}/result`;
export const UPDATE_ORG_ROLES_DETAIL_ERROR = `${UPDATE_ORG_ROLES_DETAIL}/error`;

export const UPDATE_ORG = 'update-org';
export const UPDATE_ORG_RESULT = `${UPDATE_ORG}/result`;
export const UPDATE_ORG_ERROR = `${UPDATE_ORG}/error`;

export const GET_ORG_CONFIG = 'get-org-config';
export const GET_ORG_CONFIG_RESULT = `${GET_ORG_CONFIG}/result`;
export const GET_ORG_CONFIG_ERROR = `${GET_ORG_CONFIG}/error`;

export const ADD_ORGANIZATION_OUTBOUND_API = 'add-organization-outbound-api';
export const ADD_ORGANIZATION_OUTBOUND_API_RESULT = `${ADD_ORGANIZATION_OUTBOUND_API}/result`;
export const ADD_ORGANIZATION_OUTBOUND_API_ERROR = `${ADD_ORGANIZATION_OUTBOUND_API}/error`;

export const UPDATE_ORGANIZATION_OUTBOUND_API = 'update-organization-outbound-api';
export const UPDATE_ORGANIZATION_OUTBOUND_API_RESULT = `${UPDATE_ORGANIZATION_OUTBOUND_API}/result`;
export const UPDATE_ORGANIZATION_OUTBOUND_API_ERROR = `${UPDATE_ORGANIZATION_OUTBOUND_API}/error`;

export const DELETE_ORGANIZATION_OUTBOUND_API = 'delete-organizations-outbound-apdi';
export const DELETE_ORGANIZATION_OUTBOUND_API_RESULT = `${DELETE_ORGANIZATION_OUTBOUND_API}/result`;
export const DELETE_ORGANIZATION_OUTBOUND_API_ERROR = `${DELETE_ORGANIZATION_OUTBOUND_API}/error`;

export const DELETE_HUB_DEVICE = 'delete-hub-device';
export const DELETE_HUB_DEVICE_RESULT = `${DELETE_HUB_DEVICE}/result`;
export const DELETE_HUB_DEVICE_ERROR = `${DELETE_HUB_DEVICE}/error`;

export const ORGANIZATION_DEVICES = 'organization-device';
export const ORGANIZATION_DEVICES_RESULT = `${ORGANIZATION_DEVICES}/result`;
export const ORGANIZATION_DEVICES_ERROR = `${ORGANIZATION_DEVICES}/error`;

export const SUGGEST_ORGANIZATION_DEVICES = 'suggest-organization-device';
export const SUGGEST_ORGANIZATION_DEVICES_RESULT = `${SUGGEST_ORGANIZATION_DEVICES}/result`;
export const SUGGEST_ORGANIZATION_DEVICES_ERROR = `${SUGGEST_ORGANIZATION_DEVICES}/error`;

export const ORGANIZATION_LIST = 'organization-list';
export const ORGANIZATION_LIST_RESULT = `${ORGANIZATION_LIST}/result`;
export const ORGANIZATION_LIST_ERROR = `${ORGANIZATION_LIST}/error`;

export const ASSIGN_CAPS_TO_THE_ORGANIZATION = 'assign-caps-to-the-organization';
export const ASSIGN_CAPS_TO_THE_ORGANIZATION_RESULT = `${ASSIGN_CAPS_TO_THE_ORGANIZATION}/result`;
export const ASSIGN_CAPS_TO_THE_ORGANIZATION_ERROR = `${ASSIGN_CAPS_TO_THE_ORGANIZATION}/error`;

export const HUB_DEVICES = 'hub-device';
export const HUB_DEVICES_RESULT = `${HUB_DEVICES}/result`;
export const HUB_DEVICES_ERROR = `${HUB_DEVICES}/error`;

export const HUB_DEVICES_TYPES = 'hub-device-types';
export const HUB_DEVICES_TYPES_RESULT = `${HUB_DEVICES_TYPES}/result`;
export const HUB_DEVICES_TYPES_ERROR = `${HUB_DEVICES_TYPES}/error`;

export const HUB_DEVICES_SCALE_GRAPH = 'hub-device-scale-graph';
export const HUB_DEVICES_SCALE_GRAPH_RESULT = `${HUB_DEVICES_SCALE_GRAPH}/result`;
export const HUB_DEVICES_SCALE_GRAPH_ERROR = `${HUB_DEVICES_SCALE_GRAPH}/error`;

export const HUB_DEVICES_BPMONITOR_GRAPH = 'hub-device-bpmonitor-graph';
export const HUB_DEVICES_BPMONITOR_GRAPH_RESULT = `${HUB_DEVICES_BPMONITOR_GRAPH}/result`;
export const HUB_DEVICES_BPMONITOR_GRAPH_ERROR = `${HUB_DEVICES_BPMONITOR_GRAPH}/error`;

export const CLEAR_ORGANIZATION = 'clear-organization';
export const CLEAR_DATA = 'clear-data';
export const CLEAR_SUGGESTED_DATA = 'clear-suggested-data';
export const CLEAR_ERRORS = 'clear-errors';
