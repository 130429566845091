import {
  WIDGET_GET_PATIENT_ENROLMENT,
  WIDGET_GET_PATIENT_ENROLMENT_RESULT,
  WIDGET_GET_PATIENT_ENROLMENT_ERROR,
} from './constants';

export const widgetPatientsEnrollmentReducer = (state = {}, action) => {
  switch (action.type) {
    case WIDGET_GET_PATIENT_ENROLMENT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        enrolledPatients: 0,
        pendingEnrollments: 0,
      };
    case WIDGET_GET_PATIENT_ENROLMENT_RESULT:
      return {
        ...state,
        isLoading: false,
        isError: false,
        enrolledPatients: action.response?.enrolledPatients,
        pendingEnrollments: action.response?.pendingEnrolments,
      };

    case WIDGET_GET_PATIENT_ENROLMENT_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        enrolledPatients: undefined,
        pendingEnrollments: undefined,
      };
    }
    default:
      return state;
  }
};
