import moment from 'moment-timezone';

import { FILTER_RESET, FILTER_CHANGE } from '../actions/action-types';

const getInitialFilterState = () => {
  return {
    dateFilter: {
      supremum: null,
      infimum: null,
    },
  };
};

export const filterReducer = (state = getInitialFilterState(), action) => {
  switch (action.type) {
    case FILTER_RESET:
      return {
        ...state,
        [action.filter]: { ...action.payload },
      };

    case FILTER_CHANGE:
      return {
        ...state,
        [action.filter]: {
          ...state[action.filter],
          infimum: action.payload.infimum
            ? moment(action.payload.infimum.getTime())
                .startOf('day')
                .toDate()
            : state.dateFilter?.infimum,
          supremum: action.payload.supremum
            ? moment(action.payload.supremum.getTime())
                .endOf('day')
                .toDate()
            : state.dateFilter?.supremum,
        },
      };
    default:
      return state;
  }
};
