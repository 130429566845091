import { chartTypeEnum } from '../../../components/Graphs/GenericCategoryGraph';

export const customFormattingConfig = {
  backgroundColor: {
    type: 'color',
    name: 'backgroundColor',
    label: 'Background color',
    defaultValue: '#f7f7f7',
    canBeSetByDashboard: true,
  },
  valueColor: {
    type: 'color',
    name: 'value',
    label: 'Value color',
    defaultValue: '#000000',
    canBeSetByDashboard: true,
  },
  titleColor: {
    type: 'color',
    name: 'titleColor',
    label: 'Title color',
    defaultValue: '#000000',
    canBeSetByDashboard: true,
  },
};
export const stackedChartTypeConfig = {
  stackedChartType: {
    type: 'select',
    name: 'stackedChartType',
    label: 'Stacked Chart Type',
    defaultValue: chartTypeEnum.stackedBar,
    options: [
      { label: 'Stacked Bar chart', value: chartTypeEnum.stackedBar },
      { label: 'Stacked Area chart', value: chartTypeEnum.stackedArea },
      { label: 'Line chart', value: chartTypeEnum.line },
    ],
    canBeSetByDashboard: false,
  },
};
