import { merge } from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import { connect } from 'react-redux';

import { PATIENT_DETAIL_EDIT_RESULT, POST_USER_SETTINGS_ERROR } from '../actions/action-types';
import { userProfile } from '../actions/auth';
import { resetForm } from '../actions/forms';
import { closeModal } from '../actions/modal';
import { patientAction } from '../actions/patient';
import { userSettingsAction } from '../actions/user-settings';
import WithLoader from '../components/_hocs/withLoader';
import { CAP_MANAGER, EMAIL_PATTERN, SUPER_ADMIN } from '../constants';
import { Modal } from '../containers';
import Form from '../containers/Form';
import Strings from '../Strings';
import { DivWithTooltip, filterTimezones, InputWithTooltip, SelectWithTooltip } from '../utils';
import { isCapManager, isDoctor } from '../utils/userRoles';
import './editStudyModal.scss';

class EditUserProfileModal extends PureComponent {
  state = {
    loading: false,
    isLoading: true,
    first_name: '',
    last_name: '',
    username: '',
    date_of_birth: '',
    mobile_phone: '',
    email: '',
    company: '',
    updateInProcess: false,
    status_class: 'success',
    error_msg: '',
    preferences: {
      timezone: '',
      country: '',
      city: '',
      sms_threshold: '',
      email_threshold: '',
      phone_threshold: '',
      sms_notice_enabled: false,
      email_notice_enabled: false,
      phone_notice_enabled: false,
    },
  };

  UNSAFE_componentWillMount() {
    if (this.props.patient) {
      const { patient } = this.props;
      this.updateState(patient);
    }
  }

  componentDidMount() {
    if (this.props.patient) {
      const { patient } = this.props;
      this.updateState(patient);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.patient) {
      const { patient } = newProps;
      this.updateState(patient);
    }
  }

  onChangePreferences = event => {
    this.setState({
      preferences: {
        ...this.state.preferences,
        [event.target.getAttribute('name')]: event.target.value,
      },
    });
  };

  onTextChange = event => {
    const value = event.target.value;
    this.setState({ [event.target.id]: value });
  };

  onSave = event => {
    event.preventDefault();
    const { countries } = this.props;

    this.setState({
      error_msg: '',
      status_class: 'success',
      updateInProcess: true,
    });
    let allSettings = Object.assign({}, this.state);

    if (this.props.role !== SUPER_ADMIN) {
      if (!EMAIL_PATTERN.test(allSettings.email)) {
        this.setState({
          error_msg: Strings.errors.invalidEmail,
          status_class: 'errors',
          updateInProcess: false,
        });
        return;
      }
    }

    if (this.props.role !== CAP_MANAGER && this.props.role !== SUPER_ADMIN) {
      if (!allSettings.preferences.country) {
        allSettings = merge(allSettings, { preferences: { country: countries[0] } });
      }
    }

    allSettings.date_of_birth = allSettings.date_of_birth.format('YYYY-MM-DD');

    delete allSettings.status_class;
    delete allSettings.error_msg;
    delete allSettings.role;

    if (allSettings.date_of_birth === 'Invalid date') {
      delete allSettings.date_of_birth;
    }

    this.props.postSettings(allSettings).then(response => {
      this.setState({ updateInProcess: false });
      if (response.type === POST_USER_SETTINGS_ERROR) {
        if (response.response && response.response.data) {
          const errors = response.response.data;
          if ('preferences' in errors) {
            this.setState({
              error_msg: Object.keys(errors.preferences).map(fieldName => `Please enter a valid ${fieldName}`),
            });
          } else {
            this.setState({ error_msg: Object.keys(errors).map(fieldName => `${fieldName}: ${errors[fieldName]}`) });
          }
        }
      }
    });
  };

  handleChange = date => {
    this.setState({ date_of_birth: date });
  };

  updateState(patient) {
    if (!patient || Object.keys(patient).length === 0) {
      return;
    }

    const newPropsArr = [];
    // eslint-disable-next-line
    for (const i in patient) {
      if (i === 'date_of_birth') {
        newPropsArr.date_of_birth = moment(patient.date_of_birth);
      } else {
        newPropsArr[i] = patient[i];
        if (typeof newPropsArr[i] === 'string') {
          newPropsArr[i] = newPropsArr[i].trim();
        }
      }
    }
    this.setState(newPropsArr);
  }

  render() {
    const { onOpen, onCancel, isLoading, patient, ...props } = this.props;

    const {
      error_msg,
      first_name,
      last_name,
      username,
      date_of_birth,
      email,
      company,
      preferences = {},
      status_class,
    } = this.state;
    const { countries, timezones, role } = this.props;
    const { timezone, country, city } = preferences;

    const filteredTimeZones = Object.values(timezones).filter(filterTimezones);
    return (
      <Modal name="edit-user-profile" onOpen={onOpen} additionalClasses={['form-modal', 'rounded-borders']} {...props}>
        <WithLoader isLoading={isLoading}>
          <div className={`step1 ${this.state.step1Show ? 'opened' : 'closed'}`}>
            {!isLoading ? (
              <Form onCancel={onCancel} key="edit-user-profile" className="edit-user-profile">
                <div className="flex-row" key="edit-user-profile-child">
                  <div key="profile-name" className="mb-15 dialog-input">
                    <div className="two-column-layout">
                      <div key="caregiver-first-name" className="mb-15 dialog-input">
                        <InputWithTooltip
                          name="first_name"
                          id="first_name"
                          className="dialog-form first-name"
                          placeholder={Strings.firstName}
                          type="text"
                          value={first_name}
                          onChange={this.onTextChange}
                        />
                      </div>
                      <div key="user-last-name" className="mb-15 dialog-input">
                        <div className="input-container">
                          <InputWithTooltip
                            name="last_name"
                            id="last_name"
                            className="dialog-form"
                            placeholder={Strings.lastName}
                            type="text"
                            value={last_name}
                            onChange={this.onTextChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {role !== CAP_MANAGER && role !== SUPER_ADMIN ? (
                    <div key="edit-user-email " className="mb-15 dialog-input">
                      <div className="input-container ">
                        <InputWithTooltip
                          type="text"
                          className="dialog-form"
                          name="email"
                          id="email"
                          value={email}
                          placeholder={Strings.email}
                          onChange={this.onTextChange}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  <div key="edit-user-name " className="mb-15 dialog-input">
                    <DivWithTooltip className="input-container" tt-id="username" tt-text={Strings.userName}>
                      <input
                        type="text"
                        className="dialog-form disabled"
                        name="username"
                        value={username}
                        placeholder={Strings.userName}
                        disabled
                      />
                    </DivWithTooltip>
                  </div>

                  {(isDoctor() || isCapManager()) && (
                    <div key="edit-user-company" className="mb-15 dialog-input">
                      <div className="input-container">
                        <InputWithTooltip
                          type="text"
                          className="dialog-form"
                          name="company"
                          id="company"
                          value={company}
                          placeholder={Strings.organizations}
                          onChange={this.onTextChange}
                        />
                      </div>
                    </div>
                  )}

                  <div key="edit-user-profile-dob" className="mb-15 dialog-input">
                    <div className="two-column-layout">
                      {role !== CAP_MANAGER && role !== SUPER_ADMIN ? (
                        <DivWithTooltip tt-id="dob" tt-text={Strings.dateOfBirth}>
                          <DatePicker
                            selected={moment(date_of_birth).isValid() ? moment(date_of_birth) : moment()}
                            onChange={this.handleChange}
                            maxDate={moment()}
                            readOnly
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="MM / DD / YYYY"
                          />
                        </DivWithTooltip>
                      ) : (
                        ''
                      )}
                      {role !== 'patient' && role !== CAP_MANAGER && role !== SUPER_ADMIN ? (
                        <SelectWithTooltip
                          componentType="select"
                          name="country"
                          value={country}
                          className="dialog-form country-select"
                          onChange={this.onChangePreferences}
                          placeholder={Strings.country}
                        >
                          {countries.map(c => (
                            <option value={c} key={c}>
                              {c}
                            </option>
                          ))}
                        </SelectWithTooltip>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {role !== 'patient' && role !== CAP_MANAGER && role !== SUPER_ADMIN ? (
                    <div key="edit-user-profile-timezone" className="mb-15 dialog-input">
                      <div className="two-column-layout">
                        <SelectWithTooltip
                          componentType="select"
                          name="timezone"
                          className="dialog-form user-timezone "
                          value={timezone}
                          onChange={this.onChangePreferences}
                          placeholder={Strings.timezone}
                        >
                          {filteredTimeZones.map(tz => (
                            <option key={tz} value={tz}>
                              {tz}
                            </option>
                          ))}
                        </SelectWithTooltip>
                        <InputWithTooltip
                          componentType="input"
                          onChange={this.onChangePreferences}
                          type="text"
                          className="dialog-form"
                          required
                          name="city"
                          value={city}
                          placeholder={Strings.city}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div key="errors-block" className="errors-block">
                  {error_msg ? <div className={`message ${status_class}`}>{error_msg}</div> : null}
                </div>
                <div className="button-bar reverse" key="button-bar">
                  <button className="brand-blue" key="edit-user-profile-next" onClick={this.onSave}>
                    {Strings.save}
                  </button>

                  <button className="white gray-text" key="edit-user-profile-cancel" type="cancel">
                    {Strings.cancel}
                  </button>
                </div>
              </Form>
            ) : (
              ''
            )}
          </div>
        </WithLoader>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { settings, loading } = state.user;
  const { countries } = state;
  return {
    patient: settings,
    isLoading: loading,
    countries: countries.countries,
    timezones: countries.timezones,
    role: state.auth.role,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: () => dispatch(closeModal('edit-user-profile')),
  updatePatientProfile: (patientId, data) =>
    dispatch(patientAction.updatePatientProfileByAdmin(patientId, data)).then(response => {
      if (response && response.type === PATIENT_DETAIL_EDIT_RESULT) {
        dispatch(patientAction.actionPatientDetail(patientId)).then(() => {
          dispatch(closeModal('edit-user-profile'));
        });
      }
      return response;
    }),

  postSettings: data =>
    dispatch(userSettingsAction.actionPost(data)).then(response => {
      if (response) {
        dispatch(userProfile());
        dispatch(closeModal('edit-user-profile'));
      }
      return response;
    }),
  onOpen: () => dispatch(resetForm('edit-user-profile', ownProps.data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUserProfileModal);
