import React from 'react';
import PropTypes from 'prop-types';

export const WidgetTypes = {
  plain: 'plain',
  withIcon: 'withIcon',
  colorfullWithIcon: 'colorfullWithIcon',
};

const ReadingsWidget = props => {
  const {
    icon,
    title,
    value,
    color,
    alerted,
    selected,
    type,
    onClick,
    valueTooltip,
    isCollapsible,
    expanded,
    config,
  } = props;
  const titleRef = React.useRef(null);
  const showTooltip = titleRef.current?.scrollWidth > titleRef.current?.clientWidth;
  return (
    <React.Fragment>
      {type === WidgetTypes.plain && (
        <div
          className={`grid-readings-cell
          ${selected ? 'selected' : ''}
          ${alerted ? ' alerted' : ''}
          ${onClick ? '' : ' notClickable'}`}
          style={config?.backgroundColor?.value ? { backgroundColor: config.backgroundColor?.value } : {}}
          onClick={onClick}
        >
          <div
            data-tooltip-content={title}
            data-tooltip-id={showTooltip ? 'tooltip' : undefined}
            className="cell-el title"
            style={config?.titleColor?.value ? { color: config.titleColor?.value } : {}}
            ref={titleRef}
          >
            {title}
          </div>
          <div
            className="cell-el value"
            style={config?.valueColor?.value ? { color: config.valueColor?.value } : {}}
            data-tooltip-content={valueTooltip}
            data-tooltip-id={valueTooltip ? 'tooltip' : undefined}
            data-tooltip-place="bottom"
          >
            {value}
          </div>
        </div>
      )}
      {type === WidgetTypes.withIcon && (
        <div
          className={`widget-with-icon grey
          ${(!isCollapsible && selected) || (isCollapsible && expanded) ? 'selected' : ''}
        ${alerted ? ' alerted' : ''}`}
          onClick={onClick}
        >
          <div className={`icon-container`}>
            <div className={`widget-icon ${icon}`} />
          </div>
          <div className="data">
            <div
              data-tooltip-content={title}
              data-tooltip-id={showTooltip ? 'tooltip' : undefined}
              className="cell-el title"
              ref={titleRef}
            >
              {title}
            </div>
            <div
              className="cell-el value"
              data-tooltip-content={valueTooltip}
              data-tooltip-id={valueTooltip ? 'tooltip' : undefined}
              data-tooltip-place="bottom"
            >
              {value}
            </div>
          </div>
          {isCollapsible && <div className={`collapse-icon ${expanded ? '' : 'collapsed'}`} />}
        </div>
      )}
      {type === WidgetTypes.colorfullWithIcon && (
        <div
          className={`widget-with-icon ${
            (!isCollapsible && selected) || (isCollapsible && expanded) ? 'selected' : ''
          }`}
          onClick={onClick}
        >
          <div className={`icon-container ${color || (alerted ? 'pink' : 'green')}`}>
            <div className={`widget-icon ${icon}`} />
          </div>
          <div className="data">
            <div
              data-tooltip-content={title}
              data-tooltip-id={showTooltip ? 'tooltip' : undefined}
              className="cell-el title"
              ref={titleRef}
            >
              {title}
            </div>
            <div
              className="cell-el value"
              data-tooltip-content={valueTooltip}
              data-tooltip-id={valueTooltip ? 'tooltip' : undefined}
              data-tooltip-place="bottom"
            >
              {value}
            </div>
          </div>
          {isCollapsible && <div className={`collapse-icon ${expanded ? '' : 'collapsed'}`} />}
        </div>
      )}
    </React.Fragment>
  );
};

ReadingsWidget.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  type: PropTypes.oneOf(Object.values(WidgetTypes)),
  alerted: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  valueTooltip: PropTypes.string,
  isCollapsible: PropTypes.bool,
  expanded: PropTypes.bool,
  config: PropTypes.object,
};

export default ReadingsWidget;
