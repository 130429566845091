import PropTypes from 'prop-types';
import React from 'react';

import Strings from '../../Strings';

export function AlertSummaryWidget(props) {
  let priorityText;
  let priorityIcon;
  switch (props.priority) {
    case 0:
      priorityText = Strings.low;
      priorityIcon = 'low';
      break;
    case 1:
      priorityText = Strings.medium;
      priorityIcon = 'medium';
      break;
    case 2:
      priorityText = Strings.high;
      priorityIcon = 'high';
      break;
    default:
      priorityText = 'Unknown';
  }
  priorityText += ` ${Strings.priority}`;

  return (
    <div className="alerts-priority-widget">
      <div className="icon-container">
        <div className={`${priorityIcon}-priority-alerts-icon-widget`}></div>
      </div>
      <div>
        {props.count} - {priorityText}
      </div>
    </div>
  );
}

AlertSummaryWidget.propTypes = {
  count: PropTypes.number.isRequired,
  priority: PropTypes.number.isRequired,
};
