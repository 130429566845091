import cn from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';

import Strings from '../../Strings';
import { getColorsForColorTheme } from '../../utils/colorTheme';
import { isEsi } from '../../utils/portalType';
import { isPatient } from '../../utils/userRoles';
import { Button, BUTTON_TYPE_BLUE, BUTTON_TYPE_DARK_BLUE, BUTTON_TYPE_WHITE } from '../Buttons/Button';
import styles from './WizardForm.module.scss';
import SimpleBar from 'simplebar-react';

export class WizardForm extends React.Component {
  static propTypes = {
    adaptiveLayout: PropTypes.bool,
    cancelButtonTitle: PropTypes.any,
    children: PropTypes.element.isRequired,
    currentPage: PropTypes.func,
    decorators: PropTypes.array,
    disableButtons: PropTypes.bool,
    disableNext: PropTypes.bool,
    disableSubmit: PropTypes.bool,
    initialValues: PropTypes.object,
    onCancel: PropTypes.func,
    onNext: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    showCancelButton: PropTypes.bool,
    submitButtonTitle: PropTypes.string,
    titles: PropTypes.array,
  };
  static Page = ({ children }) => children;

  static defaultProps = {
    submitButtonTitle: Strings.submit,
    disableButtons: false,
    disableNext: false,
    disableSubmit: false,
    showCancelButton: true,
    adaptiveLayout: false,
  };

  state = {
    page: 0,
    values: this.props.initialValues || {},
  };

  next = values => {
    this.props.onNext(values);
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values,
    }));
    if (isEsi() && isPatient()) {
      this.props.currentPage(this.state.page);
    }
  };

  previous = () => {
    this.setState(state => ({ page: Math.max(state.page - 1, 0) }));
  };

  handleSubmit = values => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values);
    }
    return this.next(values);
  };

  resetField(name) {
    const { initialValues = {} } = this.props;
    const form = this.refForm.current.form;
    const currentValues = form.getState().values;
    currentValues[name] = initialValues[name];
    form.reset(currentValues);
    form.resetFieldState(name);
  }

  setValue(name, value) {
    const form = this.refForm.current.form;
    const currentValues = form.getState().values;
    currentValues[name] = value;
    form.reset(currentValues);
    form.resetFieldState(name);
  }

  getValues() {
    return this.refForm.current.form.getState().values;
  }

  renderStepsProgress() {
    const { children } = this.props;
    const { page } = this.state;
    const count = React.Children.count(children);
    if (count === 1) return null;
    const isEsiPatient = isEsi() && isPatient();
    const colorTheme = getColorsForColorTheme();
    const progressStyles = {
      height: '8px',
      width: `${((page + 1) * 100) / count}%`,
      backgroundColor: isEsiPatient ? '#14568d' : colorTheme.mainColor,
      borderRadius: '4px',
    };

    return (
      <div className={styles.stepProgress}>
        <div style={progressStyles} />
      </div>
    );
  }

  refForm = React.createRef();

  render() {
    const {
      children,
      titles,
      submitButtonTitle,
      cancelButtonTitle,
      disableButtons,
      disableNext,
      disableSubmit,
      onValidate,
    } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    const isEsiPatient = isEsi() && isPatient();
    return (
      <div
        className={cn([
          styles.wizardWrapper,
          isEsiPatient && styles.isEsi,
          this.props.adaptiveLayout ? styles.wizardAdaptiveLayout : null,
        ])}
      >
        {titles[page] && (
          <header className={styles.wizardHeader}>
            {page > 0 && !isEsiPatient && <div className={styles.prevButton} onClick={this.previous} />}
            {titles[page]}
          </header>
        )}
        <div className={cn([styles.wizardContent, isEsiPatient && styles.wizardContentEsi])}>
          <Form
            ref={this.refForm}
            initialValues={values}
            validate={onValidate}
            onSubmit={this.handleSubmit}
            decorators={this.props.decorators}
          >
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit} className={styles.wizardForm}>
                {this.renderStepsProgress()}

                <SimpleBar className={styles.simplebarHeight} key="form">
                  {activePage}
                </SimpleBar>
                <div className={styles.buttonsBar}>
                  {!isLastPage && (
                    <React.Fragment>
                      <Button
                        buttonType={BUTTON_TYPE_WHITE}
                        onClick={this.props.onCancel}
                        disabled={submitting || disableButtons}
                        className={styles.button}
                      >
                        {cancelButtonTitle || Strings.cancel}
                      </Button>
                      <Button
                        type="submit"
                        buttonType={isEsiPatient ? BUTTON_TYPE_DARK_BLUE : BUTTON_TYPE_BLUE}
                        disabled={submitting || disableButtons || disableNext}
                        className={styles.button}
                      >
                        {Strings.next}
                      </Button>
                    </React.Fragment>
                  )}
                  {isLastPage && (
                    <React.Fragment>
                      {this.props.showCancelButton && (
                        <Button
                          buttonType={BUTTON_TYPE_WHITE}
                          onClick={this.props.onCancel}
                          disabled={submitting || disableButtons}
                          className={styles.button}
                        >
                          {Strings.cancel}
                        </Button>
                      )}
                      <Button
                        type="submit"
                        buttonType={isEsiPatient ? BUTTON_TYPE_DARK_BLUE : BUTTON_TYPE_BLUE}
                        disabled={submitting || disableButtons || disableSubmit}
                        className={styles.button}
                      >
                        {submitButtonTitle}
                      </Button>
                    </React.Fragment>
                  )}
                </div>
              </form>
            )}
          </Form>
        </div>
      </div>
    );
  }
}
