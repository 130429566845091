import PropTypes from 'prop-types';
import cn from 'classnames';
import React, { PureComponent } from 'react';

import styles from './style.module.scss';

export class RowButton extends PureComponent {
  render() {
    const { onClick } = this.props;

    return (
      <div className={styles.button_container}>
        <button
          className={cn([styles.button, styles.ibutton])}
          onClick={onClick}
          tabIndex={0}
          aria-label="navigate to the medications screen"
        ></button>
      </div>
    );
  }
}

RowButton.propTypes = { onClick: PropTypes.any };
