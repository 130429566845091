import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Strings from '../../Strings';
import styles from './UsersCard.module.scss';

export class UsersCard extends PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired,
    isSettings: PropTypes.bool,
    title: PropTypes.any,
  };

  render() {
    const { data, isSettings, title = Strings.userDetails } = this.props;

    return (
      <div className={cn(styles.infoBox, { [styles.settings]: Boolean(isSettings) })}>
        {title && <h4 className={styles.title}>{title}</h4>}
        <div className={styles.data}>
          {isSettings && <div className="vborder"></div>}
          {data.map((e, i) => {
            const { title: otherTitle, specialKey, value, rowValue, noBottomBorder = false } = e;
            if (!value) return null;

            const className = cn(styles.rowContent, noBottomBorder ? styles.noBottomBorder : null);

            if (React.isValidElement(value)) {
              return (
                <div className={className} key={`data_${i}`}>
                  {value}
                </div>
              );
            }

            if (value.trim().length === 0) return null;

            if (specialKey === 'location') {
              return (
                <div className={className} key={`data_${i}`}>
                  <label className={styles.label}>{title}</label>
                  <div className={styles.labelValue}>
                    <span className={styles.labelValue}>
                      <span>
                        <a target="_blank" href={`https://maps.google.com/?q=${rowValue}`} rel="noopener noreferrer">
                          {value}
                        </a>
                      </span>
                    </span>
                  </div>
                </div>
              );
            }
            return (
              <div className={className} key={`data_${i}`}>
                <label className={cn(styles.blueLable, styles.label)}>{otherTitle}</label>
                <div className={styles.labelValue}>{value}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
