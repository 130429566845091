import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { actions } from './redux/actions';
import '../Widgets.scss';
import Strings from '../../../../Strings';

const defaultOption = 24;

function HubsWithoutReadings(props) {
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const onSelectOption = option => {
    props.getData(option);
    setSelectedOption(option);
  };

  useEffect(() => {
    props.getData(selectedOption);
  }, [props.refreshTimestamp]);

  const { count } = props;
  return (
    <div className="boxWithOptions">
      <div className="boxWithOptions-optionsCol">
        <div className="boxWithOptions-optionsLabel">{Strings.inLast}</div>
        <div className="boxWithOptions-options">
          <div
            className={`boxWithOptions-option${selectedOption === 12 ? ' selected' : ''}`}
            onClick={() => onSelectOption(12)}
          >
            12 hr
          </div>
          <div
            className={`boxWithOptions-option${selectedOption === 24 ? ' selected' : ''}`}
            onClick={() => onSelectOption(24)}
          >
            24 hr
          </div>
          <div
            className={`boxWithOptions-option${selectedOption === 48 ? ' selected' : ''}`}
            onClick={() => onSelectOption(48)}
          >
            48 hr
          </div>
        </div>
      </div>
      <div className="boxWithOptions-valueCol">
        <div className="boxWithOptions-valueBox">{count}</div>
        <div className="boxWithOptions-labelBox">{Strings.noReadings}</div>
      </div>
    </div>
  );
}

HubsWithoutReadings.widgetId = 'HubsWithoutReadingsWidget';

HubsWithoutReadings.propTypes = {
  getData: PropTypes.func,
  count: PropTypes.any,
  isLoading: PropTypes.any,
  onClick: PropTypes.func,
  refreshTimestamp: PropTypes.any.isRequired,
};

const mapStateToProps = state => {
  const { widgetHubsWithoutReadingsReducer } = state.dashboardWidgets;
  return {
    count: widgetHubsWithoutReadingsReducer && widgetHubsWithoutReadingsReducer?.count,
    isLoading: widgetHubsWithoutReadingsReducer && widgetHubsWithoutReadingsReducer?.isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  getData: hours => dispatch(actions.getHubsWithoutReadings(hours)),
  onClick: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HubsWithoutReadings);
