import React from 'react';
import PropTypes from 'prop-types';

import { Box, PiePlotComponent } from './base';
import Strings from '../../Strings';

const InvitesPlot = ({ data }) => {
  const plotData = [
    {
      className: 'invite-accepted',
      value: data.accepted,
      label: Strings.accepted,
    },
    {
      className: 'invite-pending',
      value: data.pending,
      label: Strings.pending,
    },
  ];

  function isNumeric(n) {
    return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
  }

  function mainLabel() {
    let showData = true;
    const { accepted, pending } = data;
    if (
      !(isNumeric(accepted) && isNumeric(pending)) ||
      (accepted === 0 && pending === 0) ||
      accepted < 0 ||
      pending < 0
    ) {
      showData = false;
    }
    return [
      {
        text: showData ? `${accepted}/${pending}` : Strings.noData,
        className: '',
      },
    ];
  }

  function itemLabel(d) {
    return [
      {
        text: d.data.label,
        className: 'bottom',
      },
      {
        text: d.data.value,
        className: 'top',
      },
    ];
  }

  return (
    <Box caption={Strings.acceptedPendingInvites}>
      <PiePlotComponent data={plotData} mainLabel={mainLabel} itemLabel={itemLabel} />
    </Box>
  );
};

InvitesPlot.propTypes = { data: PropTypes.any };

export default InvitesPlot;
