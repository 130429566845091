import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { ReferenceLine, ResponsiveContainer, Bar, Cell, Tooltip, XAxis, YAxis, ComposedChart } from 'recharts';

import { DATE_FORMAT_YEAR_MONTH_DAY } from '../../constants';
import {
  GRAPH_AXIS_LINE_COLOR,
  GRAPH_AXIS_LINE_WIDTH,
  GRAPH_REF_LINE_DASH,
  MINIMUM_PIXELS_PER_DAY,
  OPTIMUM_PIXELS_PER_DAY,
  RANGE_PICKER_HEIGHT,
} from './GraphConstants';
import './graph.scss';
import { CustomizedXAxisTick, CustomizedYAxisTick, calcDays, referenceAreas } from './GraphComponents';
import { RangePicker } from './CompositeGraph/RangePicker';
import { DEFAULT_DATE_RANGE_DAYS } from './CompositeGraph/Metrics';
import { Y_AXIS_LEFT_WIDTH, Y_AXIS_RIGHT_WIDTH } from './CompositeGraph/Constants';

const graphMaxBarSize = 10;
const graphBarFill = '#74B236';
const graphBarFillBad = '#DA547D';

const graphReferenceVerticalLineColor = '#eeeeee';
const graphReferenceHorizontalLineColor = '#979797';

export default function StepsGraphDaily(props) {
  const [graphWidth, setGraphWidth] = useState();
  const [dateRange, setDateRange] = useState({
    start: moment()
      .subtract(DEFAULT_DATE_RANGE_DAYS, 'days')
      .startOf('day'),
    end: moment()
      .add(1, 'days')
      .startOf('day'),
  });
  const [maxRange, setMaxRange] = useState({
    start: props.minDate.clone().startOf('day'),
    end: props.maxDate
      .clone()
      .add(1, 'days')
      .startOf('day'),
  });

  const graphContainerRef = useRef();

  useEffect(() => {
    const width = graphContainerRef?.current?.clientWidth;
    setGraphWidth(width);
    setDateRange({
      start: moment()
        .subtract(width / OPTIMUM_PIXELS_PER_DAY, 'days')
        .startOf('day'),
      end: moment()
        .add(1, 'days')
        .startOf('day'),
    });
  }, [graphContainerRef.current]);

  const fixDays = (rawData, days) => {
    const ret = [];

    for (let index = 0; index <= days.length - 1; index += 1) {
      const thisDay = days[index].format(DATE_FORMAT_YEAR_MONTH_DAY);
      const daySteps = rawData.filter(x => x.x === thisDay);
      if (daySteps.length > 0) ret.push({ date: days[index], x: thisDay, steps: daySteps[0].steps });
      else ret.push({ date: days[index], x: thisDay });
    }

    return ret;
  };

  const calcYAxisParams = rawData => {
    const dataMin = 0;
    const dataMax = Math.max(...rawData.map(x => x.steps), props.stepsRecommended);

    const axisTick = dataMax > 18000 ? 5000 : dataMax > 9000 ? 2000 : 1000;
    const axisMin = 0;
    const axisMax = dataMax - (dataMax % axisTick) + axisTick;
    const axisDelta = axisMax - axisMin;

    return {
      dataMin,
      dataMax,
      axisMin,
      axisMax,
      axisTick,
      axisTicks: axisDelta / axisTick + 1,
    };
  };

  const { graphData, stepsRecommended } = props;
  const rawData = graphData.map(d => {
    return {
      ...d,
      x: d.date.format(DATE_FORMAT_YEAR_MONTH_DAY),
    };
  });

  const days = calcDays(dateRange.start, dateRange.end);
  const yAxisParams = calcYAxisParams(rawData, days);
  const fixedData = fixDays(rawData, days);

  const badDay = day => {
    if (day.date >= moment()) return false;
    return !day.steps || day.steps < stepsRecommended;
  };

  const warningDays = fixedData.filter(d => d.date && badDay(d)).map(d => d.date.format(DATE_FORMAT_YEAR_MONTH_DAY));

  const CustomTooltip = ({ active, label }) => {
    if (active && fixedData) {
      const payload = fixedData.filter(d => d.x === label && d.steps != null);

      if (payload && payload.length > 0) {
        return (
          <div className="customTooltip">
            <div>
              <p className="customTooltipTitle">{moment(payload[0].date).format('ll')}</p>
              <div className="customTooltipDescr">{payload[0].steps} Steps</div>
            </div>
          </div>
        );
      }
    }
    return null;
  };

  const onBrushChange = (startTimestamp, endTimestamp) => {
    const start = moment(startTimestamp * 1000);
    const end = moment(endTimestamp * 1000);
    if (start.isValid() && end.isValid()) {
      const range = {
        start: start,
        end: end.add(1, 'day').startOf('day'),
      };
      setDateRange(range);
    }
  };

  return (
    <div className={`graphDiv ${props.hasBorder ? 'withBorder' : ''}`}>
      <div
        style={{
          height: `calc(100% - ${RANGE_PICKER_HEIGHT}px)`,
          minHeight: 100,
        }}
        ref={graphContainerRef}
      >
        <ResponsiveContainer width="100%" height="99%" debounce>
          <ComposedChart
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
            data={fixedData}
            defaultShowTooltip={false}
          >
            {/* reference areas to mimic alternate rows */}
            {referenceAreas(yAxisParams)}

            <YAxis
              type="number"
              dataKey="steps"
              name="hours"
              tick={
                <CustomizedYAxisTick
                  axisMax={yAxisParams.axisMax}
                  dateMin={dateRange.start}
                  dateMax={dateRange.end}
                  axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
                />
              }
              domain={[yAxisParams.axisMin, yAxisParams.axisMax]}
              tickCount={yAxisParams.axisTicks}
              tickSize={0}
              width={78}
              axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
            />
            <Tooltip content={<CustomTooltip />} dataKey="x" isAnimationActive={false} />

            <XAxis
              type="category"
              dataKey="x"
              allowDuplicatedCategory={false}
              height={60}
              interval={0}
              name="day"
              orientation="top"
              tickLine={false}
              tickSize={0}
              tick={
                <CustomizedXAxisTick
                  axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
                  axisMin={fixedData[0].x}
                />
              }
              axisLine={{ stroke: GRAPH_AXIS_LINE_COLOR, strokeWidth: GRAPH_AXIS_LINE_WIDTH }}
            />

            <ReferenceLine
              y={stepsRecommended}
              stroke={graphReferenceHorizontalLineColor}
              strokeDasharray={GRAPH_REF_LINE_DASH}
            />

            <Bar
              tooltipType="none"
              dataKey="steps"
              radius={3}
              fill={graphBarFill}
              fillOpacity={1}
              maxBarSize={graphMaxBarSize}
              onClick={e => props.onDayClick && props.onDayClick(e.date)}
              cursor="pointer"
            >
              {fixedData.map((entry, index) => {
                const color = entry.steps < stepsRecommended ? graphBarFillBad : graphBarFill;
                return <Cell key={`cell-${index}`} stroke={color} fill={color} />;
              })}
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <RangePicker
        startTimestamp={dateRange.start.unix()}
        endTimestamp={dateRange.end
          .clone()
          .subtract(1, 'days')
          .startOf('day')
          .unix()}
        dataStartTimestamp={maxRange.start.unix()}
        resolution="days"
        ticks={Math.round((maxRange.end.unix() - maxRange.start.unix()) / (24 * 60 * 60))}
        width="100%"
        leftPadding={Y_AXIS_LEFT_WIDTH + 1}
        rightPadding={Y_AXIS_RIGHT_WIDTH}
        onChange={onBrushChange}
        dateRange={maxRange}
        maxSelectionTicks={Math.floor(graphWidth / MINIMUM_PIXELS_PER_DAY)}
      />
    </div>
  );
}

StepsGraphDaily.propTypes = {
  graphData: PropTypes.array,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  onDayClick: PropTypes.func,
  stepsRecommended: PropTypes.number,
  hasBorder: PropTypes.bool,
};

StepsGraphDaily.defaultProps = { hasBorder: true };
