import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone/moment-timezone';

import styles from './TZ.module.scss';
import { actionsTZ } from './redux/actions';
import { openModalAction } from '../../actions/modal';
import TimezoneChangeModal from '../../modals/TimezoneChangeModal/TimezoneChangeModal';
import { TIMEZONES_ABBERAVIATIONS, GetAbbervationByTimezone } from '../../constants';
import Strings from '../../Strings';
import { TZ_UPDATE_USER_TIMEZONE_RESULT } from './redux/const';
import { notificationActions } from '../../components/Notification/redux/actions';

function TZ(props) {
  const [timezoneText, setTimezoneText] = useState(null);

  const fallbackToDefaultTimezone = () => {
    props.updateUserTimezone(TIMEZONES_ABBERAVIATIONS.PacificTime.timezone).then(resp => {
      if (resp.type === TZ_UPDATE_USER_TIMEZONE_RESULT) {
        window.location.reload();
      } else {
        props.showNotification('Error while updating timezone settings', 5000);
      }
    });
  };

  useEffect(() => {
    if (props.username) {
      const timezoneAbberavation = GetAbbervationByTimezone(props.timezone);
      if (timezoneAbberavation) {
        setTimezoneText(timezoneAbberavation.shortText);
        moment.tz.setDefault(timezoneAbberavation.timezone);
      } else {
        fallbackToDefaultTimezone();
      }
    }
  }, [props.timezone, props.username]);

  return timezoneText ? (
    <React.Fragment>
      <label htmlFor="timezoneId" className="visuallyhidden">
        {Strings.selectedTimezone} {timezoneText} {Strings.timepickerAL}
      </label>
      <div
        className={styles.container}
        onClick={props.openTimezoneModal}
        onKeyDown={e => {
          const charCode = e.which ? e.which : e.keyCode;
          if (charCode === 13) {
            this.onClick();
          }
        }}
        tabIndex={0}
        role="button"
        id="timezoneId"
      >
        <div className={styles.text}>{timezoneText}</div>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment />
  );
}

TZ.propTypes = {
  openTimezoneModal: PropTypes.func,
  timezone: PropTypes.string,
  updateUserTimezone: PropTypes.func,
  showNotification: PropTypes.func,
  username: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    timezone: state.auth?.profile?.preferences?.timezone,
    username: state.auth?.profile?.username,
  };
};

const mapDispatchToProps = dispatch => ({
  openTimezoneModal: () => dispatch(openModalAction(TimezoneChangeModal.MODAL_NAME)),
  updateUserTimezone: timezone => dispatch(actionsTZ.updateUserTimezone(timezone)),
  showNotification: (message, timeout) => dispatch(notificationActions.show(message, timeout)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TZ);
