import React from 'react';

import { TextInput } from '../components/Inputs/TextInput';
import { getFieldWithComponent } from './getFieldWithComponent';
import { ComponentWithTooltip } from '../utils';

function TextInputField(props) {
  const { input, meta, refLink, ...rest } = props;
  const error = typeof rest.error === 'boolean' ? rest.error && meta.error : meta.touched && meta.error;

  return <ComponentWithTooltip componentType={TextInput} {...rest} error={error} {...input} />;
}

const Component = getFieldWithComponent(TextInputField);

export { Component as TextInputField };
