import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.scss';

export class LoginCheckbox extends PureComponent {
  static propTypes = {
    isLinkInNewWindow: PropTypes.bool,
    link: PropTypes.string,
    name: PropTypes.string.isRequired,
    onCheckChange: PropTypes.any,
    ref: PropTypes.any,
    required: PropTypes.bool,
    text: PropTypes.string.isRequired,
  };

  static defaultProps = {
    isLinkInNewWindow: true,
    required: true,
  };

  render() {
    const { name, text, ref, link, isLinkInNewWindow, required } = this.props;

    return (
      <div className={styles.container}>
        <label className={styles.inputContainer}>
          <input
            name={name}
            type="checkbox"
            ref={ref}
            required={required ? 'required' : null}
            onChange={this.props.onCheckChange}
          />
          <div className={styles.fakeCheckBox} />
        </label>
        <div className={styles.text}>
          {link ? (
            <a href={link} target={isLinkInNewWindow ? '_blank' : ''}>
              {text}
              <div className={styles.arrow}></div>
            </a>
          ) : (
            text
          )}
        </div>
      </div>
    );
  }
}
