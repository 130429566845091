import PropTypes from 'prop-types';
import React from 'react';

import style from './index.module.scss';
import { Pill } from './Pill';

export function PillInfo({ time, data }) {
  return (
    <React.Fragment>
      <div className={style.time}>{time}</div>
      {data.map(med => (
        <Pill med={med} />
      ))}
    </React.Fragment>
  );
}

PillInfo.propTypes = {
  data: PropTypes.shape({ map: PropTypes.func }),
  time: PropTypes.any,
};
