import { API_CALL } from '../../../middleware/api';

export const GET_EXTERNAL_API_STATUS = 'GET_EXTERNAL_API_STATUS';
export const GET_EXTERNAL_API_STATUS_RESULT = `${GET_EXTERNAL_API_STATUS}/result`;

export const REGISTER_PUBLIC_KEY = 'REGISTER_PUBLIC_KEY';
export const REGISTER_PUBLIC_KEY_RESULT = `${REGISTER_PUBLIC_KEY}/result`;

export const DELETE_PUBLIC_KEY = 'DELETE_PUBLIC_KEY';
export const DELETE_PUBLIC_KEY_RESULT = `${DELETE_PUBLIC_KEY}/result`;

export const GET_EXTERNAL_API_CONFIGURATION = 'GET_EXTERNAL_API_CONFIGURATION';
export const GET_EXTERNAL_API_CONFIGURATION_RESULT = `${GET_EXTERNAL_API_CONFIGURATION}/result`;

export const SET_EXTERNAL_API_CONFIGURATION = 'SET_EXTERNAL_API_CONFIGURATION';
export const SET_EXTERNAL_API_CONFIGURATION_RESULT = `${SET_EXTERNAL_API_CONFIGURATION}/result`;

export const GET_EXTERNAL_API_LOGS = 'GET_EXTERNAL_API_LOGS';
export const GET_EXTERNAL_API_LOGS_RESULT = `${GET_EXTERNAL_API_LOGS}/result`;

export const actions = {
  getStatus: org_uuid => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: `/oauth2/external-api/configuration/status/${org_uuid}`,
      },
      type: GET_EXTERNAL_API_STATUS,
    };
  },

  registerPublicKey: (org_uuid, public_key) => {
    return {
      [API_CALL]: {
        method: 'PUT',
        endpoint: `/oauth2/external-api/configuration/register-public-key`,
        data: {
          public_key: public_key,
          org_uuid: org_uuid,
        },
      },
      type: REGISTER_PUBLIC_KEY,
    };
  },

  deletePublicKey: org_uuid => ({
    [API_CALL]: {
      endpoint: `/oauth2/external-api/configuration/delete-public-key/${org_uuid}`,
      method: 'DELETE',
    },
    type: DELETE_PUBLIC_KEY,
  }),

  getOrgConfiguration: org_uuid => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: `/external-api/configuration/${org_uuid}`,
      },
      type: GET_EXTERNAL_API_CONFIGURATION,
    };
  },

  setOrgConfiguration: (org_uuid, configuration) => {
    return {
      [API_CALL]: {
        method: 'PUT',
        endpoint: `/external-api/configuration/${org_uuid}`,
        data: configuration,
      },
      type: SET_EXTERNAL_API_CONFIGURATION,
    };
  },

  getMasterConfiguration: () => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: `/external-api/configuration/master`,
      },
      type: GET_EXTERNAL_API_CONFIGURATION,
    };
  },

  setMasterConfiguration: configuration => {
    return {
      [API_CALL]: {
        method: 'PUT',
        endpoint: `/external-api/configuration/master`,
        data: configuration,
      },
      type: SET_EXTERNAL_API_CONFIGURATION,
    };
  },

  getLogs: params => {
    return {
      [API_CALL]: {
        method: 'GET',
        endpoint: `/external-api/logs`,
        params: params,
      },
      type: GET_EXTERNAL_API_LOGS,
    };
  },
};
