import React, { useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './Dashboard.scss';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { actionsDashboard as actions } from './redux/actions';
import _ from 'lodash';
import { GetDefaultConfiguration, GetLayouts, GetWidgetsVisibility } from './DashboardsMetadata';
import { openModalAction } from '../../actions/modal';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
export const LS_DASHBOARD_LAYOUTS_ITEM = 'DashboardLayouts';
const DASHBOARD_INNER_MARGIN = 21;
const DASHBOARD_PADDING_LR = 0;
const DASHBOARD_PADDING_TB = 0;

function DashboardLayout(props) {
  const [mounted, setMounted] = useState(false);
  const [currentBreakpoint, setCurrentBreakpoint] = useState('l');
  const layoutsOld = React.useRef();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    // if widget is added, scroll to bottom of dashboard
    if (props.layouts?.l.length > layoutsOld.current?.l.length) {
      const added = props.layouts.l.filter(l => !layoutsOld.current.l.some(lOld => l.i === lOld.i));
      if (props.scrollbarRef?.current && added.length > 0) {
        const viewport = props.scrollbarRef.current.osInstance()?.elements()?.viewport;
        if (viewport) {
          const scrollTo = viewport.scrollHeight - viewport.clientHeight;
          viewport.scrollTo({ top: scrollTo, behavior: 'smooth' });
        }
      }
    }
    layoutsOld.current = props.layouts;
  }, [props.layouts]);

  const defaultConfig = React.useMemo(() => GetDefaultConfiguration(props.dashboardId, props.additionalData), [
    props.dashboardId,
    props.additionalData,
    props.configuration,
  ]);

  const onBreakpointChange = breakpoint => {
    setCurrentBreakpoint(breakpoint);
  };

  const fixAddedWidgets = layout => {
    layout.forEach(l => {
      const added = l.w === 1 && l.h === 1;
      const widget = props.widgets.find(w => w.id === l.i);
      if (added && widget !== undefined) {
        l.w = widget.defProps.w;
        l.h = widget.defProps.h;
        l.minW = widget.defProps.minW;
        l.minH = widget.defProps.minH;
      }
    });
  };

  const onLayoutChange = (layout, layouts) => {
    if (props.isReadOnly) return;

    fixAddedWidgets(layout);
    props.updateLayouts(layouts);
  };

  const getCurrentLayout = layouts => {
    if (layouts && currentBreakpoint === 'l') {
      return layouts.l;
    }
    if (layouts && currentBreakpoint === 'm') {
      return layouts.m;
    }
    if (layouts && currentBreakpoint === 's') {
      return layouts.s;
    }

    return undefined;
  };

  const generateDOM = () => {
    const currentLayout = getCurrentLayout(props.layouts);

    return currentLayout.map(l => {
      if (l.y < 0) return null;

      const widget = props.widgets.find(w => w.id === l.i);
      if (widget === undefined) return null;

      const className = `widgetContainer ${l.isResizable === false ? '' : ' widgetContainerSizable'}${
        widget.noPadding === true ? ' widgetContainerNoPadding' : ''
      }${widget.noBorder === true ? ' widgetContainerNoBorder' : ''}${
        widget.greyBackground === true ? ' widgetContainerGreyBackground' : ''
      }`;

      let config = props.configuration?.[l.i];
      if (!config && defaultConfig[l.i]) {
        config = defaultConfig[l.i];
        Object.entries(config).forEach(([key, value]) => {
          if (value.canBeSetByDashboard && props.configuration?.WidgetConfigForDashboard?.[key])
            config[key] = props.configuration.WidgetConfigForDashboard[key];
        });
      }
      return (
        <div key={l.i} className={className}>
          <div className="widget-content">
            {React.cloneElement(widget.render, {
              config,
            })}
          </div>
          {editMode && (
            <div className="remove-widget" onClick={() => props.removeWidget(l.i, props.permissions)}>
              <div className="remove-icon"></div>
            </div>
          )}
          {config && (
            <div className="edit-widget">
              <div className="edit-icon" onClick={() => props.configWidget(widget, config)}></div>
            </div>
          )}
        </div>
      );
    });
  };

  const {
    isReadOnly,
    layouts,
    breakpoints,
    cols,
    rowHeight,
    onDragStart,
    onDragStop,
    onResize,
    editMode,
    innerMargin,
  } = props;

  return (
    <div className="dashboardContainer">
      {!_.isEmpty(layouts) && (
        <ResponsiveReactGridLayout
          // style={style}
          className={`layout ${isReadOnly || !editMode ? 'readOnly' : 'editMode'}`}
          rowHeight={rowHeight}
          breakpoints={breakpoints}
          cols={cols}
          margin={[innerMargin, innerMargin]}
          containerPadding={[DASHBOARD_PADDING_LR, DASHBOARD_PADDING_TB]}
          isBounded
          initialLayout={[]}
          layouts={layouts}
          onLayoutChange={onLayoutChange}
          onBreakpointChange={onBreakpointChange}
          measureBeforeMount={false}
          // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
          // and set `measureBeforeMount={true}`.
          useCSSTransforms={mounted}
          compactType="vertical"
          preventCollision={false}
          draggableCancel=".clickable,.table-body,.os-scrollbar-handle,.graphDiv,.right,.section-header"
          isDraggable={!isReadOnly && editMode}
          isResizable={!isReadOnly && editMode}
          onDragStart={onDragStart || undefined}
          onDragStop={onDragStop ? onDragStop : () => {}}
          onResize={onResize || undefined}
        >
          {generateDOM()}
        </ResponsiveReactGridLayout>
      )}
    </div>
  );
}

DashboardLayout.propTypes = {
  dashboardId: PropTypes.string,
  widgets: PropTypes.array,
  defaultLayouts: PropTypes.object,
  breakpoints: PropTypes.object,
  cols: PropTypes.any,
  rowHeight: PropTypes.number,
  isReadOnly: PropTypes.bool,
  editMode: PropTypes.bool,
  additionalData: PropTypes.any,
  scrollbarRef: PropTypes.any,
  innerMargin: PropTypes.number,
};

DashboardLayout.defaultProps = {
  breakpoints: { l: 1200, m: 800, s: 0 },
  cols: { l: 12, m: 8, s: 4 },
  rowHeight: 100,
  isReadOnly: false,
  editMode: false,
  innerMargin: DASHBOARD_INNER_MARGIN,
};

const mapStateToProps = (state, ownProps) => {
  const { dashboardLayouts, dashboardFilters, configuration, editMode } = state.dashboardState;
  const { permissions } = state.auth;
  const filters = GetWidgetsVisibility(
    ownProps.dashboardId,
    dashboardFilters[ownProps.dashboardId],
    ownProps.additionalData,
    permissions,
  );
  const layouts = GetLayouts(
    ownProps.dashboardId,
    filters,
    dashboardLayouts[ownProps.dashboardId],
    ownProps.additionalData,
    permissions,
  );
  const config = configuration[ownProps.dashboardId];
  return {
    layouts,
    editMode: editMode[ownProps.dashboardId],
    permissions,
    configuration: config,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateLayouts: layouts => dispatch(actions.updateLayouts(ownProps.dashboardId, layouts)),
  removeWidget: (widgetId, permissions) =>
    dispatch(actions.changeVisibility(ownProps.dashboardId, widgetId, false, ownProps.additionalData, permissions)),
  configWidget: (widget, config) =>
    dispatch(
      openModalAction('widget-configuration', {
        dashboardId: ownProps.dashboardId,
        widget,
        configuration: config,
        additionalData: ownProps.additionalData,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
