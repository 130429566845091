export const PORTAL_CLASSIC = 'v1';
export const PORTAL_FDB = 'v2';
export const PORTAL_ESI = 'esi';

function getPortal() {
  return sessionStorage.getItem('version');
}

function isESIOrg() {
  return sessionStorage.getItem('org');
}

export function isOrgNameESI() {
  return isESIOrg() === PORTAL_ESI;
}

export function isEsi() {
  return getPortal() === PORTAL_ESI;
}

export function isClassic() {
  return getPortal() === PORTAL_CLASSIC;
}

export function isFDB() {
  return getPortal() === PORTAL_FDB;
}
