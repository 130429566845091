import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Switch from '../components/Switch/index';
import { getUserNotificationDelays } from '../utils';
import MobileInput from './MobileInput';
import { EMAIL_PATTERN } from '../constants';
import Strings from '../Strings';

class PatientNotificationsForm extends PureComponent {
  static propTypes = {
    patient: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      mobileNumber: PropTypes.string,
      proposedMobileNumber: PropTypes.string,
      emailAddress: PropTypes.string,
      proposedEmailAddress: PropTypes.string,
      preferences: PropTypes.shape({
        smsThreshold: PropTypes.number,
        emailThreshold: PropTypes.number,
        phoneThreshold: PropTypes.number,
        smsNoticeEnabled: PropTypes.bool,
        emailNoticeEnabled: PropTypes.bool,
        phoneNoticeEnabled: PropTypes.bool,
      }),
    }).isRequired,
    readOnlyMode: PropTypes.bool.isRequired,
    updateNotificationsFunc: PropTypes.func.isRequired,
    actionType: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.updateState = this.updateState.bind(this);
    this.onSaveAllChanges = this.onSaveAllChanges.bind(this);
    this.state = {
      updateInProcess: false,
      mobileNumber: '',
      emailAddress: '',
      mobileVerified: true,
      emailVerified: true,
      notificationsSMSDelay: 0,
      notificationsEmailDelay: 0,
      notificationsPhoneDelay: 0,
      smsNoticeEnabled: false,
      emailNoticeEnabled: false,
      phoneNoticeEnabled: false,
      statusClass: 'success',
      errorMsg: '',
    };
  }

  componentDidMount() {
    // eslint-disable-next-line
    this.setState({ smsNoticeEnabled: true });
    if (Object.keys(this.props.patient).length !== 0) {
      const { patient } = this.props;
      this.updateState(patient);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (Object.keys(newProps.patient).length !== 0) {
      const { patient } = newProps;
      this.updateState(patient);
    }
  }

  onChange(event, isSwitchEvent) {
    if (isSwitchEvent === true) {
      this.setState(event);
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  onSaveAllChanges() {
    if (this.state.mobileNumber.length === 0) {
      this.setState({
        errorMsg: Strings.errors.emptyPhoneNumber,
        statusClass: 'errors',
      });
      return;
    }
    if (!EMAIL_PATTERN.test(this.state.emailAddress)) {
      this.setState({
        errorMsg: Strings.errors.invalidEmail,
        statusClass: 'errors',
      });
      return;
    }
    this.setState({
      errorMsg: '',
      updateInProcess: true,
    });
    const notificationsData = {
      mobileNumber: this.state.mobileNumber,
      emailAddress: this.state.emailAddress,

      preferences: {
        sms_threshold: parseInt(this.state.notificationsSMSDelay, 10),
        email_threshold: parseInt(this.state.notificationsEmailDelay, 10),
        phone_threshold: parseInt(this.state.notificationsPhoneDelay, 10),
        sms_notice_enabled: this.state.smsNoticeEnabled,
        email_notice_enabled: this.state.emailNoticeEnabled,
        phone_notice_enabled: this.state.phoneNoticeEnabled,
      },
    };

    const { actionType } = this.props;

    this.props.updateNotificationsFunc(notificationsData).then(response => {
      if (response.type === `${actionType}/error`) {
        if (response.response && response.response.status === 500) {
          this.setState({
            errorMsg: Strings.errors.internalError,
            statusClass: 'errors',
            updateInProcess: false,
          });
        } else if (response.response && response.response.data) {
          const errors = response.response.data;
          this.setState({
            errorMsg: Object.keys(errors).map(fieldName => `${fieldName}: ${errors[fieldName]}`),
            statusClass: 'errors',
            updateInProcess: false,
          });
        }
      } else if (response.type === `${actionType}/result`) {
        this.setState({
          errorMsg: Strings.success.dataSavedSuccessfully,
          statusClass: 'success',
          updateInProcess: false,
        });
      }
    });
  }

  updateState(patient) {
    if (!patient || Object.keys(patient).length === 0) {
      return;
    }
    this.setState({
      mobileNumber: patient.mobileNumber || '',
      emailAddress: patient.emailAddress || '',

      mobileVerified: patient.mobile_verified,
      emailVerified: patient.email_verified,

      notificationsSMSDelay: patient.preferences.sms_threshold,
      notificationsEmailDelay: patient.preferences.email_threshold,
      notificationsPhoneDelay: patient.preferences.phone_threshold,
      smsNoticeEnabled: patient.preferences.sms_notice_enabled,
      emailNoticeEnabled: patient.preferences.email_notice_enabled,
      phoneNoticeEnabled: patient.preferences.phone_notice_enabled,
    });
  }

  render() {
    const {
      mobileNumber,
      emailAddress,
      mobileVerified,
      emailVerified,
      notificationsSMSDelay,
      notificationsEmailDelay,
      notificationsPhoneDelay,
      smsNoticeEnabled,
      emailNoticeEnabled,
      phoneNoticeEnabled,
      errorMsg,
      statusClass,
      updateInProcess,
    } = this.state;
    const { readOnlyMode } = this.props;

    return (
      <div className="settings user-profile">
        <div className="container">
          <div>
            <div className="caption">{Strings.notificationSettings}</div>
            <div className="fields">
              <div>
                <div className="label">
                  <label htmlFor="mobileNumber">
                    {Strings.mobileNumber} {mobileVerified ? '' : <span className="red">({Strings.notVerified})</span>}
                  </label>
                </div>

                <div className="field">
                  <MobileInput
                    name="mobileNumber"
                    country="US"
                    isContainerClass={false}
                    placeHolder={Strings.placeholder.phoneNumber}
                    value={mobileNumber}
                    onChange={number => this.setState({ mobileNumber: number })}
                  />
                </div>
              </div>

              <div>
                <div className="label">
                  <label htmlFor="emailAddress">
                    {Strings.emailAddress} {emailVerified ? '' : <span className="red">({Strings.notVerified})</span>}
                  </label>
                </div>
                <div className="field">
                  <input onChange={this.onChange} type="email" required name="emailAddress" value={emailAddress} />
                </div>
              </div>
              <div>
                <div className="label">
                  <label htmlFor="emailAddress">{Strings.alertBySMS}</label>
                </div>
                <div className="field">
                  <div className="delay-select">
                    <select name="notificationsSMSDelay" onChange={this.onChange} value={notificationsSMSDelay}>
                      {getUserNotificationDelays().map(item => (
                        <option key={item.value} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="delay-switch">
                    <Switch
                      flag={smsNoticeEnabled === true}
                      name="smsNoticeEnabled"
                      onChange={e => this.onChange(e, true)}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="label">
                  <label htmlFor="emailAddress">{Strings.alertByEmail}</label>
                </div>
                <div className="field">
                  <div className="delay-select">
                    <select name="notificationsEmailDelay" onChange={this.onChange} value={notificationsEmailDelay}>
                      {getUserNotificationDelays().map(item => (
                        <option key={item.value} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="delay-switch">
                    <Switch
                      flag={emailNoticeEnabled === true}
                      name="emailNoticeEnabled"
                      onChange={e => this.onChange(e, true)}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="label">
                  <label htmlFor="emailAddress">{Strings.alertByPhone}</label>
                </div>
                <div className="field">
                  <div className="delay-select">
                    <select name="notificationsPhoneDelay" onChange={this.onChange} value={notificationsPhoneDelay}>
                      {getUserNotificationDelays().map(item => (
                        <option key={item.value} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="delay-switch">
                    <Switch
                      flag={phoneNoticeEnabled === true}
                      name="phoneNoticeEnabled"
                      onChange={e => this.onChange(e, true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!readOnlyMode ? (
          <div className="buttons row">
            <button
              className="brand-blue profile"
              onClick={this.onSaveAllChanges}
              disabled={updateInProcess}
              role="button"
            >
              {updateInProcess ? Strings.inProcess : Strings.saveChanges}
            </button>
          </div>
        ) : null}
        {errorMsg ? <div className={`message ${statusClass}`}>{errorMsg}</div> : null}
      </div>
    );
  }
}

export default PatientNotificationsForm;
