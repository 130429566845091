import React, { PureComponent } from 'react';
import cn from 'classnames';

import PropTypes from 'prop-types';

import styles from '../Input.module.scss';

export const SELECT_TYPE_LEFT = 0;
export const SELECT_TYPE_RIGHT = 1;

export class SelectInput extends PureComponent {
  selectRef = React.createRef();
  static propTypes = {
    data: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    type: PropTypes.number,
  };

  state = { isSelected: false };

  componentDidMount() {
    this.selectRef && this.selectRef.current && this.selectRef.current.focus();
  }

  onChange = e => {
    const { onChange } = this.props;
    const select = e.target;
    const value = select.value;
    const text = select.options[select.selectedIndex] ? select.options[select.selectedIndex].text : '';

    if (onChange) {
      onChange(e, value, text);
    }
    this.setState({ isSelected: true });
  };

  renderOptions() {
    const { data, placeholder } = this.props;

    return [
      <option value="" key="placeholder-option" disabled hidden>
        {placeholder}
      </option>,
      ...data.map(e => {
        let optValue;
        let optText;

        if (typeof e === 'string') {
          optValue = e;
          optText = e;
        } else if (Array.isArray(e)) {
          optValue = e[0];
          optText = e[1];
        } else {
          optValue = e.value;
          optText = e.text;
        }

        return (
          <option className={styles.selectInputOption} key={optValue} value={optValue}>
            {optText}
          </option>
        );
      }),
    ];
  }

  render() {
    const { onChange, error, type = SELECT_TYPE_RIGHT, data, value, first, ...props } = this.props;
    const { isSelected } = this.state;

    return (
      <div>
        <select
          {...props}
          className={cn(styles.textInput, styles.selectInput, {
            [styles.inputError]: Boolean(error),
            [styles.left]: type === SELECT_TYPE_LEFT,
            [styles.isEmpty]: !isSelected && !value,
          })}
          onChange={this.onChange}
          placeholder={this.props.placeholder}
          value={value}
          ref={first && this.selectRef}
        >
          {this.renderOptions()}
        </select>
        {error ? <div className={styles.error}>{error}</div> : null}
      </div>
    );
  }
}
