export const MAX_GRAPH_ITEMS = 200;
export const CONDENSED_VIEW_THRESHOLD = 50;

export const GRAPH_ROW_BK_ALTERNATE = '#F4F8FC';
export const GRAPH_ROW_BK = '#fff';

export const GRAPH_AXIS_X_HEIGHT = 50;
export const GRAPH_AXIS_X_HEIGHT_CONDENSED = 25;

export const GRAPH_AXIS_FONT_SIZE = 14;
export const GRAPH_X_AXIS_FONT_COLOR = '#000';
export const GRAPH_X_AXIS_FONT_COLOR_REPEATED = '#9C9C9C';
export const GRAPH_Y_AXIS_FONT_COLOR = '#7F7F7F';

export const GRAPH_X_AXIS_FONT_COLOR_MOBILE = '#7F7F7F';
export const GRAPH_Y_AXIS_FONT_COLOR_MOBILE = '#7F7F7F';

export const GRAPH_AXIS_LINE_COLOR = '#d9d9d9';
export const GRAPH_AXIS_LINE_WIDTH = 2;

export const GRAPH_DOT_RADIUS_CONDENSED = 2;
export const GRAPH_DOT_RADIUS = 4;
export const GRAPH_ACTIVE_DOT_RADIUS = 6;

export const GRAPH_REF_LINE_COLOR = '#c7c7c7';
export const GRAPH_REF_LINE_DASH = '3 3';

export const FIRST_IN_MONTH_MARKER = '$';
export const FIRST_IN_DAY_MARKER = '#';

export const MINIMUM_PIXELS_PER_DAY = 20;
export const OPTIMUM_PIXELS_PER_DAY = 30;
export const OPTIMUM_PIXELS_PER_DAY_SLEEP = 30;

export const MINIMUM_PIXELS_PER_DAY_STEPS = 10;
export const OPTIMUM_PIXELS_PER_DAY_STEPS = 30;

export const X_VALUE_WEEK_PREFIX = 'week';

export const RANGE_PICKER_HEIGHT = 50;
export const MINI_RANGE_PICKER_HEIGHT = 10;
export const RANGE_PICKER_HEIGHT_MOBILE = 91;
export const RANGE_PICKER_MOBILE_WITH_TOGGLES_HEIGHT = 146;
export const RANGE_PICKER_TOP_MARGIN = 5;
