import { LIST_ALL_ACTIVITY_EVENTS, LIST_ALL_ACTIVITY_EVENTS_RESULT, CLEAR_ENTITIES } from '../actions/action-types';

export const eventsReducer = (reducer, types, actionToKey) => {
  const nextReducer = reducer(types, actionToKey);

  return (state, action) => {
    const newState = { ...nextReducer(state, action) };
    switch (action.type) {
      case LIST_ALL_ACTIVITY_EVENTS:
        return {
          ...newState,
          loading: true,
        };
      case LIST_ALL_ACTIVITY_EVENTS_RESULT:
        return {
          ...newState,
          loading: false,
          preparing: false,
        };
      case CLEAR_ENTITIES:
        if (action.entities && action.entities.indexOf('events') !== -1) {
          return {
            ...newState,
            objects: {},
            preparing: true,
          };
        }
        return newState;
      default:
        return newState;
    }
  };
};
