import { API_CALL } from '../../../../middleware/api';
import { DEVICE_DETAILS, DEVICE_DETAILS_CLEAR } from './constants';

export const actions = {
  getCapsDetails: (id, pageRequest) => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/esi/v1/caps/' + id,
      params: pageRequest,
    },
    type: DEVICE_DETAILS,
  }),

  getFillterData: type => ({
    [API_CALL]: {
      method: 'GET',
      endpoint: '/v1/caps/event-types',
      params: { filterBy: type },
    },
    type: 'GET_FILTER_DATA',
  }),
  clearCapsDetails: () => ({
    type: DEVICE_DETAILS_CLEAR,
  }),
};
