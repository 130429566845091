import React from 'react';

import './style.scss';
import Strings from '../../Strings';
import { getVariablesForBrand } from '../../utils/brand';
import { PORTAL_ESI } from '../../utils/portalType';

const Copyright = () => {
  let prefix =
    process.env.NODE_ENV == 'development' ||
    process.env.NODE_ENV == 'stage' ||
    process.env.NODE_ENV == 'int' ||
    process.env.NODE_ENV == 'production'
      ? ''
      : '/portal';

  const brandVariables = getVariablesForBrand();
  const privacy = brandVariables.name === PORTAL_ESI ? '/esi-patient-privacy' : '/privacy';
  const terms = brandVariables.name === PORTAL_ESI ? '/esi-patient-terms' : '/terms';
  return (
    <React.Fragment>
      <span style={{ color: '#FFF' }}>{Strings.rxcap}&copy;</span> {new Date().getFullYear()} {Strings.rxcapRights}
      &nbsp;
      <a target="_self" href={`${prefix}${privacy}`} className="whiteLink">
        {Strings.privacyPolicyText}
      </a>
      &nbsp;{Strings.and}&nbsp;
      <a target="_self" href={`${prefix}${terms}`} className="whiteLink">
        {Strings.termText}
      </a>
    </React.Fragment>
  );
};

export default Copyright;
