import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { clearInterval, setInterval } from 'worker-timers';

import { openModalAction } from '../../actions/modal';
import { Button } from '../../components/PageHeader/Button';
import Select from '../../components/Select';
import { CHAR_LIMIT } from '../../constants';
import Strings from '../../Strings';
import { getProgramDisplayName } from '../../utils/cmsPrograms';
import './TimerModal.scss';
import Wizard, { wizardTypes } from '../../containers/Modal/Wizard';
import { CONTINUE_TASK_RESULT, START_TASK_RESULT } from '../../pages/SuperUser/Patients/redux/constants';
import { actions } from '../../pages/SuperUser/Patients/redux/actions';

const DEFAULT_TASK_ID = 33;
class TimerModal extends PureComponent {
  static propTypes = {
    endTask: PropTypes.func.isRequired,
    onNavigate: PropTypes.func,
    data: PropTypes.shape({
      patientId: PropTypes.string.isRequired,
      taskList: PropTypes.array,
      taskStatus: PropTypes.shape({
        goal: PropTypes.any,
        loggedTime: PropTypes.number,
      }),
      programs: PropTypes.array,
    }),
    startTask: PropTypes.func,
    getBillableTime: PropTypes.func,
    continueTask: PropTypes.func,
    isTopModal: PropTypes.bool,
  };

  constructor(props) {
    super();
    this.state = {
      selectedTaskId: DEFAULT_TASK_ID,
      timerOn: false,
      timerStart: 0,
      timerTime: 0,
      notesArea: '',
      notes_char_left: CHAR_LIMIT,
      enrollmentId: props.data.programs.find(p => p.isDefault)?.enrollmentId,
      taskId: null,
    };
  }

  getState = () => {
    if (document.visibilityState === 'hidden') {
      return 'hidden';
    }
    if (document.hasFocus()) {
      return 'active';
    }
    return 'passive';
  };

  // Stores the initial state using the `getState()` function (defined above).
  pageState = this.getState();

  // Accepts a next state and, if there's been a state change, logs the
  // change to the console. It also updates the `state` value defined above.
  logStateChange = nextState => {
    const prevState = this.pageState;
    if (nextState !== prevState) {
      this.pageState = nextState;
    }
  };

  // Options used for all event listeners.
  opts = { capture: true };

  componentDidMount() {
    const { patientId, programs } = this.props.data;
    if (patientId && programs && programs.length > 0) {
      this.startTimer();
    }

    // These lifecycle events can all use the same listener to observe state
    // changes (they call the `getState()` function to determine the next state).
    ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume'].forEach(type => {
      window.addEventListener(type, () => this.logStateChange(this.getState(), this.opts));
    });

    // The next two listeners, on the other hand, can determine the next
    // state from the event itself.
    window.addEventListener(
      'freeze',
      () => {
        // In the freeze event, the next state is always frozen.
        this.logStateChange('frozen');
      },
      this.opts,
    );

    window.addEventListener(
      'pagehide',
      event => {
        // If the event's persisted property is `true` the page is about
        // to enter the back/forward cache, which is also in the frozen state.
        // If the event's persisted property is not `true` the page is
        // about to be unloaded.
        this.logStateChange(event.persisted ? 'frozen' : 'terminated');
      },
      this.opts,
    );
  }

  componentWillUnmount() {
    const { patientId, programs } = this.props.data;
    if (patientId && programs && programs.length > 0 && this.state.timerOn) {
      this.onEndTask();
    }

    ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume'].forEach(type => {
      window.removeEventListener(type, () => this.logStateChange(this.getState(), this.opts));
    });

    // The next two listeners, on the other hand, can determine the next
    // state from the event itself.
    window.removeEventListener(
      'freeze',
      () => {
        // In the freeze event, the next state is always frozen.
        this.logStateChange('frozen');
      },
      this.opts,
    );

    window.removeEventListener(
      'pagehide',
      event => {
        // If the event's persisted property is `true` the page is about
        // to enter the back/forward cache, which is also in the frozen state.
        // If the event's persisted property is not `true` the page is
        // about to be unloaded.
        this.logStateChange(event.persisted ? 'frozen' : 'terminated');
      },
      this.opts,
    );
  }

  onTaskChange = option => {
    this.setState({ selectedTaskId: option.value });
  };

  onProgramChange = option => {
    this.setState({ enrollmentId: option.value });
  };

  onStartTask = () => {
    const { selectedTaskId, notesArea } = this.state;
    const { patientId } = this.props.data;
    const data = {
      patientId: parseInt(patientId, 10),
      taskMetadataID: parseInt(selectedTaskId, 10),
      notes: notesArea.trim(),
      enrollmentId: this.state.enrollmentId,
    };

    this.props.startTask(data).then(resp => {
      this.props.getBillableTime(patientId);
      if (resp.type === START_TASK_RESULT) {
        this.setState({ taskId: resp.response.id });
        if (resp.response?.otherDoctorWorkingOnPatient && resp.response?.otherDoctorWorkingOnPatient !== '') {
          this.setState({ otherDoctorWorkingOnPatient: resp.response.otherDoctorWorkingOnPatient });
        } else {
          this.setState({ otherDoctorWorkingOnPatient: undefined });
        }
      }
    });
  };

  onClickStartTimer = () => {
    this.forceUpdate();
    this.startTimer();
  };

  startTimer = enrollmentId => {
    this.setState({
      timerOn: true,
      timerTime: this.state.timerTime,
      timerStart: Date.now() - this.state.timerStart,
    });
    this.timer = setInterval(() => {
      this.setState({ timerTime: Date.now() - this.state.timerStart });
    }, 1000);

    this.onStartTask(enrollmentId);
    this.autoStopcaller = setInterval(() => {
      this.autoStopCall();
    }, 30000);
  };

  stopTimer = () => {
    this.setState({
      timerOn: false,
      timerStart: 0,
      timerTime: 0,
    });
    this.timer && clearInterval(this.timer);
    this.setState({
      notesArea: '',
      selectedTaskId: DEFAULT_TASK_ID,
      taskId: null,
    });
  };

  onEndTask = () => {
    const { selectedTaskId, notesArea, enrollmentId } = this.state;
    const { patientId } = this.props.data;

    const data = {
      notes: notesArea.trim(),
      taskMetaDataId: parseInt(selectedTaskId, 10),
      patientId: parseInt(patientId, 10),
      enrollmentId,
    };

    this.props.endTask(data).then(() => {
      this.pageRequest = {
        offset: 0,
        search: '',
        patientId: parseInt(patientId, 10),
      };
      this.props.getBillableTime(patientId);
      this.props.getTaskHistory(this.pageRequest);
    });

    this.stopTimer();
    this.autoStopcaller && clearInterval(this.autoStopcaller);
  };

  autoStopCall = () => {
    const { notesArea, selectedTaskId, enrollmentId } = this.state;
    const { patientId } = this.props.data;

    const data = {
      notes: notesArea.trim(),
      taskMetaDataId: parseInt(selectedTaskId, 10),
      patientId: parseInt(patientId, 10),
      enrollmentId,
    };

    this.props.continueTask(data).then(resp => {
      if (
        resp.type === CONTINUE_TASK_RESULT &&
        resp.response?.otherDoctorWorkingOnPatient &&
        resp.response?.otherDoctorWorkingOnPatient !== ''
      ) {
        this.setState({ otherDoctorWorkingOnPatient: resp.response.otherDoctorWorkingOnPatient });
      } else {
        this.setState({ otherDoctorWorkingOnPatient: undefined });
      }
    });
  };

  onViewLogs = () => {
    const { patientId } = this.props.data;
    this.props.onNavigate(`/cap-patients/${patientId}/history`);
  };

  handleNotesArea = e => {
    const notes_char_count = e.target.value.length;
    if (notes_char_count <= CHAR_LIMIT) {
      this.setState({ notesArea: e.target.value });
      this.setState({ notes_char_left: CHAR_LIMIT - notes_char_count });
    }
  };

  render() {
    const { taskList, programs } = this.props.data;
    const { taskStatus } = this.props;
    let renamedTaskList;

    const { timerTime } = this.state;
    const seconds = `0${Math.floor(timerTime / 1000) % 60}`.slice(-2);
    const minutes = `0${Math.floor(timerTime / 60000) % 60}`.slice(-2);

    const renameKeys = (keysMap, obj) =>
      Object.keys(obj).reduce(
        (acc, key) => ({
          ...acc,
          ...{ [keysMap[key] || key]: obj[key] },
        }),
        {},
      );

    const keysMap = {
      id: 'value',
      taskName: 'label',
    };

    if (taskList) {
      renamedTaskList = taskList.map(item => {
        return renameKeys(keysMap, item);
      });
    }

    const timerContent = (
      <React.Fragment>
        {renamedTaskList?.length > 0 && (
          <Select
            value={this.state.selectedTaskId}
            data={renamedTaskList}
            onChange={this.onTaskChange}
            menuPositionFixed={false}
          />
        )}
        {programs && programs.length > 1 && (
          <div className="timerBoxExpandSection">
            <Select
              value={this.state.enrollmentId}
              data={programs.map(p => {
                return { label: getProgramDisplayName(p.name, p.subprogram), value: p.enrollmentId };
              })}
              onChange={this.onProgramChange}
              menuPositionFixed={false}
            />
          </div>
        )}
        <div className="task-note-container">
          <textarea
            rows="5"
            cols="60"
            value={this.state.notesArea}
            onChange={this.handleNotesArea}
            placeholder={Strings.placeholder.notesArea}
          ></textarea>
          <div className="task-notes-char-left">
            <p>
              {this.state.notes_char_left !== 1
                ? `${this.state.notes_char_left} characters left`
                : `${this.state.notes_char_left} character left`}
            </p>
          </div>
        </div>
      </React.Fragment>
    );

    const header = (
      <div className="timer-header-expanded">
        <div className="timer-header-expanded labels">
          <div className="label-with-timer">
            <div className="timer-label">{Strings.capPatient.goalThisMonth}:</div>
            <div className="timer blue">
              {taskStatus?.goal ? moment.utc(taskStatus?.goal * 1000 * 60).format('HH : mm : ss') : '-'}
            </div>
          </div>
          <div className="label-with-timer">
            <div className="timer-label">{Strings.capPatient.loggedThisMonth}:</div>
            <div className={`timer ${taskStatus?.loggedTime < taskStatus?.goal * 60 ? 'pink' : 'green'}`}>
              {taskStatus?.loggedTime ? moment.utc(taskStatus.loggedTime * 1000).format('HH : mm : ss') : '-'}
            </div>
          </div>
        </div>
        <Button class="brand-white-gray" onClick={this.onViewLogs} title={Strings.capPatient.viewLogs} />
      </div>
    );

    const timerSection = (
      <div className="label-with-timer horizontal">
        <div className="timer-label big">{Strings.timer}</div>
        <div className="timer blue">
          {minutes} : {seconds}
        </div>
        {this.state.otherDoctorWorkingOnPatient && (
          <div className="timer-label big">
            {Strings.capPatient.watchedBy} {this.state.otherDoctorWorkingOnPatient}
          </div>
        )}
      </div>
    );

    const bottomBar = (
      <div className="timer-header-expanded">
        {this.state.timerOn || programs?.length === 0 ? (
          <Button class="stop-button" onClick={this.onEndTask} title={Strings.capPatient.stopTimer} />
        ) : (
          <Button class="start-button" onClick={this.onClickStartTimer} title={Strings.capPatient.startTimer} />
        )}
        {timerSection}
      </div>
    );

    const pages = [
      {
        id: 'timer',
        title: header,
        titleMinimized: timerSection,
        content: timerContent,
      },
    ];

    return (
      <Wizard
        name="timer"
        //id={props.modalId}
        pages={pages}
        isTopModal={this.props.isTopModal}
        type={wizardTypes.floatingModal}
        customButtons={bottomBar}
        openMinimized
      />
    );
  }
}

const mapStateToProps = state => {
  const { schedules, patients } = state.superUser;

  return {
    schedules: schedules && schedules?.data?.data,
    isLoading: schedules && schedules?.isLoading,
    pagination: schedules && schedules?.data?.pagination,
    endTaskData: patients && patients?.endTaskData,
    taskStatus: patients && patients?.taskStatus,
  };
};

const mapDispatchToProps = dispatch => ({
  onNavigate: path => dispatch(push(path)),
  getTaskHistory: pageRequest => dispatch(actions.getTaskHistory(pageRequest)),
  openConfirmModal: data => dispatch(openModalAction('confirmation-modal', data)),
  startTask: data => dispatch(actions.startTask(data)),
  endTask: data => dispatch(actions.endTask(data)),
  continueTask: data => dispatch(actions.continueTask(data)),
  getBillableTime: patientId => dispatch(actions.getPatientBillableTime(patientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimerModal);
