import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';

import { MED_ICON_COLOR_FILTER, TIME_FORMAT_12_LOWERCASE } from '../../../constants';
import Strings from '../../../Strings';
import { getMedIcon } from '../../../utils/medIcons';
import { Row } from './Row';
import { RowImage } from './RowImage';
import { RowLabel } from './RowLabel';
import { Rows } from './Rows';
import { RowText } from './RowText';

export class Reminders extends PureComponent {
  render() {
    const { data } = this.props;

    const rows = [];
    data.forEach(e => {
      if (e.schedule_timezone) {
        const time = '';
        const m = moment.utc(e.reminder_at).tz(e.schedule_timezone ? e.schedule_timezone : time); // time is not defined revisit this code
        const title = m.isSame(moment(), 'day') ? Strings.today : m.format('MMM DD');

        rows.push(
          <Row>
            <RowImage image={getMedIcon(e?.format?.route)} style={{ filter: MED_ICON_COLOR_FILTER }} />
            <RowText>{e.name}</RowText>
            <RowLabel title={title}>{m.format(TIME_FORMAT_12_LOWERCASE)}</RowLabel>
          </Row>,
        );
      }
    });

    return <Rows rows={rows} />;
  }
}

Reminders.propTypes = { data: PropTypes.shape({ forEach: PropTypes.func }) };
