import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import _ from 'lodash';

import styles from './style.module.scss';
import { prependZeros } from '../../utils';

export class ManualDatePicker extends PureComponent {
  static propTypes = {
    minDate: PropTypes.object, // date
    maxDate: PropTypes.object, // date
    selected: PropTypes.object, // moment
    onChange: PropTypes.func,
  };

  static defaultProps = { selected: moment() };

  state = {
    current: false,
    currentMoment: this.props.selected,
  };

  onDayChange = e => {
    this.onDateChange(e.target.value, -1, -1);
  };

  onDayClick = () => {
    this.onDateChange('', -1, -1);
  };

  onMonthChange = e => {
    this.onDateChange(-1, e.target.value, -1);
  };

  onMonthClick = () => {
    this.onDateChange(-1, '', -1);
  };

  onYearChange = e => {
    this.onDateChange(-1, -1, e.target.value);
  };

  onYearClick = () => {
    this.onDateChange(-1, -1, '');
  };

  onDateChange(day, month, year) {
    const { current } = this.state;

    /* eslint-disable */
    if (day === -1) day = current.day;
    if (month === -1) month = current.month;
    if (year === -1) year = current.year;
    /* eslint-enable */
    const newDate = `${year}-${prependZeros(month)}-${prependZeros(day)}`;

    const newSelected = moment(newDate, 'YYYY-MM-DD');

    this.setState({
      current: {
        day,
        month,
        year,
      },
    });

    let isCorrect = true;

    if (day > 0 && month > 0 && year > 0 && newSelected.isValid()) {
      const { minDate, maxDate } = this.props;

      if (minDate) {
        const mMin = moment(minDate);

        if (mMin.isValid() && newSelected < mMin) {
          isCorrect = false;
        }
      }

      if (maxDate) {
        const mMax = moment(maxDate);

        if (mMax.isValid() && newSelected > mMax) {
          isCorrect = false;
        }
      }
    } else {
      isCorrect = false;
    }

    if (isCorrect) {
      this.fireOnChange(newSelected);
    } else {
      this.fireOnChange.cancel();
    }
  }

  fireOnChange = _.debounce(m => {
    const { onChange } = this.props;

    this.setState({ currentMoment: m });

    if (onChange) onChange(m);
  }, 1000);

  onKeyDown = e => {
    const key = e.key;

    if (key.length === 1 && /\D/.test(key)) {
      e.preventDefault();
    }
  };

  componentDidMount() {
    this.updateCurrentFromProps();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected) {
      this.updateCurrentFromProps();
    }
  }

  updateCurrentFromProps = () => {
    this.setState({ current: this.getObjectFromMoment(this.props.selected) });
  };

  getObjectFromMoment = momentData => {
    return {
      day: prependZeros(momentData.date()),
      month: prependZeros(momentData.month() + 1),
      year: momentData.year(),
    };
  };

  onBlur = () => {
    this.updateCurrentFromProps();
  };

  render() {
    const { current } = this.state;
    if (!current) return null;

    const { day, month, year } = current;

    return (
      <div className={styles.container}>
        <input
          onBlur={this.onBlur}
          type="text"
          maxLength={2}
          onKeyDown={this.onKeyDown}
          onChange={this.onMonthChange}
          onClick={this.onMonthClick}
          value={month}
          className={styles.month}
          tabIndex={0}
        />

        <input
          onBlur={this.onBlur}
          type="text"
          maxLength={2}
          onKeyDown={this.onKeyDown}
          onChange={this.onDayChange}
          onClick={this.onDayClick}
          value={day}
          className={styles.day}
          tabIndex={0}
        />
        <input
          onBlur={this.onBlur}
          type="text"
          maxLength={4}
          onKeyDown={this.onKeyDown}
          onChange={this.onYearChange}
          onClick={this.onYearClick}
          value={year}
          className={styles.year}
          tabIndex={0}
        />
      </div>
    );
  }
}
